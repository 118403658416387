import _      from 'lodash';
import moment from 'moment';
import React  from 'react';
import API    from 'API';

import { Avatar, Button, Chip, FormControl, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';

import DateTimePicker          from 'Components/Common/DatePickers/DateTimePicker';
import DatePicker              from 'Components/Common/DatePickers/DatePicker';
import ConfirmationDialog      from 'Components/Common/Dialogs/ConfirmationDialog';
import FALightIcon             from 'Components/Common/Icons/FontAwesome/FALightIcon';
import Textarea                from 'Components/Common/Inputs/Textarea';
import PaddedPaper             from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar                from 'Components/Common/SnackBars/SnackBar';
import LoadingCircle           from 'Components/Common/LoadingCircle/LoadingCircle';

import { formatValidationErrors, formatValidationWarnings } from 'Helpers/ErrorHelper';

const initialState = {
    leaderStaffList: [],
    staffList: [],
    categories: {},
    categoryList: [],
    subCategories: {},
    subCategoryList: [],
    customers: {},
    customerList: [],
    customerContacts: {},
    customerContactList: [],
    addressList: [],
    suppliers: {},
    supplierList: [],
    supplierContacts: {},
    supplierContactList: [],
    formData: {
        eventAllDay: false,
        ignoreWarnings: false,
        eventStart: null,
        eventFinish: null,
        category: null,
        subCategory: null,
        leader: null,
        meetingWith: null,
        staffAttendees: [],
        supplierAttendees: [],
        customerAttendees: [],
        notes: ''
    },
    formErrors: [],
    formWarnings: [],
    confirmationOpen: false,
    warningConfirmationOpen: false,
    snackbarOpen: false,
    isLoading: true
}

class AddCalendarEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getCategories();
        this.getStaff();
        this.getSuppliers();
        this.getCustomers();
    }
    getSuppliers = () => {
        API.get('/suppliers/all')
        .then((result) => {
            let supplierList = _.map(result.data, el => {
                return _.assign({
                    value: el.supp_id,
                    label: el.supp_company_name
                });
            });
            this.setState({
                supplierList: supplierList,
                suppliers: result.data
            })
        });
    }
    getSupplierContacts = (supplierId) => {
        if(supplierId) {
            let suppList = _.find(this.state.suppliers, {'supp_id': supplierId});
            let suppContPersonList = _.filter(suppList.active_contacts, {'supp_cont_type': 'Person'});
            let supplierContactList = _.map(suppContPersonList, (cont) => {
                return _.assign({
                    value: cont.supp_cont_id,
                    label: cont.supp_cont_first_name + ' ' + cont.supp_cont_last_name
                });
            });
            this.setState({
                supplierContactList: supplierContactList
            });
        } else {
            this.setState({
                supplierContactList: initialState.supplierContactList,
                formData: {
                    ...this.state.formData,
                    supplierAttendees: []
                }
            });
        }
    }
    getCustomers = () => {
        API.get('/customers')
        .then((result) => {
            let customerList = _.map(result.data, el => {
                el.registeredPostcode = _.result(_.find(el?.addresses, {'address_type': 'Registered'}), 'address_postcode');
                return _.assign({
                    value: el.cust_id,
                    label: el.cust_name + ' ('+el?.registeredPostcode+')'
                });
            });
            this.setState({
                customerList: customerList,
                customers: result.data,
                isLoading: false
            })
        });
    }
    getCustomerContacts = (customerId) => {
        if(customerId) {
            let custList = _.find(this.state.customers, {'cust_id': customerId});
            let customerContactList = [];
            let addressList         = [];
            custList.addresses.forEach((address) => {
                if (address.address_active){
                    addressList.push({
                        value: address.address_id,
                        label: `${address.address_name} (${address.address_type})`
                    });
                    customerContactList[address.address_id] = [];
                    _.map(address.active_contacts, (cont) => {
                        customerContactList[address.address_id].push({
                            value: cont.contact_id,
                            label: cont.contact_name
                        });
                    });
                }
            });
            this.setState({customerContactList,addressList});
        } else {
            this.setState({
                customerContactList: initialState.customerContactList,
                addressList:         initialState.addressList,
                formData: {
                    ...this.state.formData,
                    customerAttendees: []
                }
            });
        }
    }
    handleSubmit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            if(key === "eventStart" || key === "eventFinish") {
                newFormData.append(key, moment(this.state.formData[key]).format(this.state.formData.eventAllDay ? "YYYY-MM-DD" : "YYYY-MM-DD H:mm"));
            } else {
                newFormData.append(key, this.state.formData[key]);
            }
        });
        API.post('/calendar/events', newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else if(result.data.warnings && result.data.warnings.length > 0){           
                this.setState({
                    formWarnings: formatValidationWarnings(result.data.warnings),
                    warningConfirmationOpen: true
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                }, () => {
                    this.getCategories();
                    this.getStaff();
                    this.getSuppliers();
                    this.getCustomers();
                });
            }
            this.props.scrollToTop();
        });
    }
    getStaff = () => {
        API.get('/staff/all', { params: { active: true } })
        .then(result => {
            if(result.data) {
                let staffList = _.map(result.data, (staff) => {
                    return _.assign({
                        value: staff.staff_id,
                        label: staff.staff_first_name + ' ' + staff.staff_last_name,
                        disabled: false
                    });
                });
                let leaderStaffList = _.map(result.data, (staff) => {
                    return _.assign({
                        value: staff.staff_id,
                        label: staff.staff_first_name + ' ' + staff.staff_last_name
                    });
                });
                this.setState({
                    staffList: staffList,
                    leaderStaffList: leaderStaffList
                });
            }
        });
    }
    getCategories = () => {
        API.get('/calendar/categories/all', { params: { enabledOnly: true } }).then(result => {
            if(result.data) {
                let categoryList = _.map(result.data, (cat) => {
                    return _.assign({
                        value: cat.category_id,
                        label: cat.category_name
                    });
                });
                this.setState({
                    categoryList: categoryList,
                    categories: result.data
                });
            }
        });
    };
    getSubCategories = (categoryId) => {
        if(categoryId) {
        let catList = _.find(this.state.categories, {'category_id': categoryId});
        let subCategoryList = _.map(catList.children, (cat) => {
            return _.assign({
            value: cat.category_id,
            label: cat.category_name
            });
        });
        this.setState({
            subCategoryList: subCategoryList
        });
        } else {
            this.setState({
                subCategoryList: initialState.subCategoryList
            });
            this.setState({
                formData: {
                    ...this.state.formData,
                    subCategory: null
                }
            });
        }
    };
    handleCheckChange = name => event => {
        this.setState({
            formData: {
            ...this.state.formData,
            [name]: event.target.checked
            }
        });
    };
    handleDateChange = name => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: date
            }
        });
    };
    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: (selectedOption && selectedOption?.value) ? selectedOption?.value : null
            }
        }, 
        () => {
            if ((selectedOption && selectedOption?.value)){
                if(fieldName === 'supplier'){
                    this.getSupplierContacts(selectedOption && selectedOption.value);
                }
                else if(fieldName === 'customer'){
                    this.getCustomerContacts(selectedOption && selectedOption.value);
                }
                else if(fieldName === 'category'){
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            subCategory: null
                        }
                    },
                    () => {
                        this.getSubCategories(selectedOption && selectedOption.value);
                    });
                } 
                else if(fieldName === 'leader') {
                    let newStaffList = [...this.state.staffList];
                    let newStaffAttendeesList = [...this.state.formData.staffAttendees];
                    for (let i in newStaffList) {
                        if(newStaffList[i].value === selectedOption.value) {
                            newStaffList[i].disabled = (newStaffList[i].value === selectedOption.value);
                            if(this.state.formData.staffAttendees.includes(selectedOption.value)) {
                                newStaffAttendeesList = newStaffAttendeesList.filter(staffId => staffId !== selectedOption.value);
                            }
                        } else {
                            newStaffList[i].disabled = false;
                        }
                    }
                    this.setState({
                        staffList: newStaffList,
                        formData: {
                            ...this.state.formData,
                            staffAttendees: newStaffAttendeesList
                        }
                    });
                }
            }
        });
    };
    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };
    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }
    handleWarningConfirmationOpen = () => {
        this.setState({
            warningConfirmationOpen: true,
        });
    };
    handleWarningConfirmationClose = () => {
        this.setState({ 
            warningConfirmationOpen: false 
        });
    };
    handleWarningConfirmationSuccess = () => {
        this.setState({ 
            warningConfirmationOpen: false,
            formData: {
            ...this.state.formData,
            ignoreWarnings: true
            }
        }, () => {
            this.handleSubmit();
        });
    }
    render() {
        const { formData, formErrors, snackbarOpen, isLoading } = this.state;

        if (isLoading) return ( <LoadingCircle /> )

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Calendar Event
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Event Details
                            </Typography>
                            <form noValidate autoComplete="off">
                                <FormControlLabel
                                    control={
                                        <Switch name="eventAllDay"
                                                checked={this.state.formData.eventAllDay}
                                                onChange={this.handleCheckChange('eventAllDay')}
                                                value={true}
                                                color="primary"
                                                margin="normal" />
                                    }
                                    label={<span style={{color: 'rgba(0, 0, 0, 0.54)'}}>Is this an all day event?</span>}
                                />
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        {this.state.formData.eventAllDay ?
                                            <DatePicker
                                                type='date'
                                                id="eventStart"
                                                name="eventStart"
                                                label={(!this.state.formData.eventAllDay && "Event Start *") || "Event Date *"}
                                                disablePast={true}
                                                value={this.state.formData.eventStart}
                                                errorText={formErrors && formErrors['eventStart']}
                                                onChange={this.handleDateChange('eventStart')}
                                            /> : 
                                            <Grid item xs={12}>
                                                <DateTimePicker
                                                    id="eventStart"
                                                    name="eventStart"
                                                    label="Event Start"
                                                    required
                                                    value={this.state.formData.eventStart}
                                                    errorText={formErrors && formErrors['eventStart']}
                                                    onChange={this.handleDateChange('eventStart')}
                                                />
                                            </Grid> 
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {!this.state.formData.eventAllDay &&
                                            <DateTimePicker
                                                id="eventFinish"
                                                name="eventFinish"
                                                label="Event Finish"
                                                required
                                                value={this.state.formData.eventFinish}
                                                errorText={formErrors && formErrors['eventFinish']}
                                                onChange={this.handleDateChange('eventFinish')}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.categoryList} 
                                        label='Category *'
                                        value={this.state.formData.category}
                                        onChange={this.handleSelectChange('category')}
                                        error={formErrors && formErrors['category'] && true}
                                        errorText={formErrors && formErrors['category'] && formErrors['category']}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect 
                                    options={this.state.subCategoryList} 
                                    label='Sub Category *'
                                    value={this.state.formData.subCategory}
                                    onChange={this.handleSelectChange('subCategory')}
                                    error={formErrors && formErrors['subCategory'] && true}
                                    errorText={formErrors && formErrors['subCategory'] && formErrors['subCategory']}
                                />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.leaderStaffList} 
                                        label='Leader *'
                                        value={this.state.formData.leader}
                                        onChange={this.handleSelectChange('leader')}
                                        error={formErrors && formErrors['leader'] && true}
                                        errorText={formErrors && formErrors['leader']}
                                    />
                                </FormControl>
                                {this.state.formData.category && this.state.formData.category === 1 && (
                                <React.Fragment>
                                    <FormControl margin="normal" fullWidth>
                                        <AutoCompleteSelect 
                                            options={[
                                            {value: 'in-house', label: 'In-House'},
                                            {value: 'supplier', label: 'Supplier'},
                                            {value: 'customer', label: 'Customer'}
                                            ]} 
                                            label='Meeting With *'
                                            value={this.state.formData.meetingWith}
                                            onChange={this.handleSelectChange('meetingWith')}
                                            error={formErrors && formErrors['meetingWith'] && true}
                                            errorText={formErrors && formErrors['meetingWith']}
                                        />
                                    </FormControl>    
                                    {this.state.formData.meetingWith && this.state.formData.meetingWith === 'supplier' && (
                                    <React.Fragment>
                                        <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.supplierList} 
                                            label='Supplier *'
                                            value={this.state.formData.supplier}
                                            onChange={this.handleSelectChange('supplier')}
                                            error={formErrors && formErrors['supplier'] && true}
                                            errorText={formErrors && formErrors['supplier'] && formErrors['supplier']}
                                        />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <AutoCompleteMultiSelect 
                                                options={this.state.supplierContactList} 
                                                label='Supplier Attendees *'
                                                value={this.state.formData.supplierAttendees}
                                                onChange={this.handleMultiSelectChange('supplierAttendees')}
                                                error={formErrors && formErrors['supplierAttendees'] && true}
                                                errorText={formErrors && formErrors['supplierAttendees']}
                                            />
                                        </FormControl>
                                    </React.Fragment>
                                    )}    
                                    {this.state.formData.meetingWith && this.state.formData.meetingWith === 'customer' && (
                                    <React.Fragment>
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={this.state.customerList}
                                                label='Customer *'
                                                value={this.state.formData.customer}
                                                onChange={this.handleSelectChange('customer')}
                                                error={formErrors && formErrors['customer'] && true}
                                                errorText={formErrors && formErrors['customer'] && formErrors['customer']}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={this.state.addressList}
                                                label='Site *'
                                                value={this.state.formData.address}
                                                onChange={this.handleSelectChange('address')}
                                                error={formErrors && formErrors['address'] && true}
                                                errorText={formErrors && formErrors['address'] && formErrors['address']}
                                            />
                                        </FormControl>
                                        <FormControl margin="normal" fullWidth>
                                            <AutoCompleteMultiSelect 
                                                options={this.state.formData.address ? this.state.customerContactList[this.state.formData.address] : []} 
                                                label='Customer Attendees *'
                                                value={this.state.formData.customerAttendees}
                                                onChange={this.handleMultiSelectChange('customerAttendees')}
                                                error={formErrors && formErrors['customerAttendees'] && true}
                                                errorText={formErrors && formErrors['customerAttendees']}
                                            />
                                        </FormControl>
                                    </React.Fragment>
                                    )}
                                </React.Fragment>
                                )}
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteMultiSelect 
                                        options={this.state.staffList} 
                                        label='Staff Attendees'
                                        value={this.state.formData.staffAttendees}
                                        onChange={this.handleMultiSelectChange('staffAttendees')}
                                        error={formErrors && formErrors['staffAttendees'] && true}
                                        errorText={formErrors && formErrors['staffAttendees']}
                                    />
                                </FormControl>
                                <Textarea
                                    id="notes"
                                    name="notes"
                                    label="Notes"
                                    value={this.state.formData.notes}
                                    onChange={this.handleChange}
                                />
                                <div className='buttonRow'>
                                    <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                        disabled={Object.values(formData).every(x => (x === null || x === ""))}>
                                        Add
                                    </Button>
                                </div>
                                <ConfirmationDialog 
                                    open={this.state.confirmationOpen} 
                                    success={this.handleConfirmationSuccess} 
                                    close={this.handleConfirmationClose} 
                                    title="Add A New Calendar Event?" 
                                    message="Are you sure you want to add this new calendar event?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={snackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message="The new calendar event was successfully added"
                                />
                                <ConfirmationDialog 
                                    open={this.state.warningConfirmationOpen} 
                                    success={this.handleWarningConfirmationSuccess} 
                                    close={this.handleWarningConfirmationClose} 
                                    title={
                                        <React.Fragment>
                                            <FALightIcon icon='exclamation-triangle' /> Warning!
                                        </React.Fragment>
                                    }
                                    message={
                                        <React.Fragment>
                                            <Typography variant="body1" style={{marginBottom: 24}}>
                                                The following people are already attending an event between these times:
                                            </Typography>
                                            {Object.values(this.state.formWarnings).map(value => {
                                                let staffValues = value.split("|");
                                                return (
                                                <React.Fragment>
                                                    <Chip
                                                    avatar={<Avatar alt={staffValues[0]} src={staffValues[1]} style={{width: 32, height: 32}} />}
                                                    label={staffValues[0]}
                                                    variant="outlined"
                                                    style={{height: 35, marginRight: 8}}
                                                    />
                                                </React.Fragment>
                                                )
                                            })}
                                            <Typography variant="body1" style={{marginTop: 24}}>
                                                Would you like to proceed with this event anyway?
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default AddCalendarEvent;