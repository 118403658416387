import React, { Component } from 'react';

import _                    from 'lodash';
import moment               from 'moment';

import logo                 from 'Assets/Images/clenaware_logo_icon.png';

import {colors}             from 'Helpers/ColourHelper';

import { withStyles }       from '@material-ui/core/styles';
import { TableFooter, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, Link } from '@material-ui/core';
import AllIcon from 'Components/Common/Icons/AllIcon';
import icons from 'Helpers/IconHelper';

const styles = theme => ({
    tblHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    tblHeaderCell: {
        color:'white',
    },
    tblFooter: {
        backgroundColor: '#f2f2f2',
    }
});
const initialState = {
    colspan: ''
};
class CreditNote extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    trimPartDescription = partDescription => {
        if(partDescription) {
            if(partDescription.length > 30){
                partDescription = partDescription.substring(0, 25) + '...';
            }
        } else {
            partDescription = '';
        }
        return partDescription;
    }

    render() {
        const { colspan }       = this.state;
        const { classes, cn }   = this.props;
        const customerPhone     = cn.cn_cust_contact_phone && cn.cn_cust_contact_mobile ? cn.cn_cust_contact_phone + ' / ' + cn.cn_cust_contact_mobile : (cn.cn_cust_contact_phone ? cn.cn_cust_contact_phone : cn.cn_cust_contact_mobile);
        const deliveryPhone     = cn.cn_delivery_cust_contact_phone && cn.cn_delivery_cust_contact_mobile ? cn.cn_delivery_cust_contact_phone + ' / ' + cn.cn_delivery_cust_contact_mobile : (cn.cn_delivery_cust_contact_phone ? cn.cn_delivery_cust_contact_phone : cn.cn_delivery_cust_contact_mobile);
        
        return (
            <React.Fragment>
                <Grid container item spacing={1}>
                    <Grid item xs={5}>
                        <Grid container item spacing={1}>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Credit Note Date</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {moment(cn.cn_date).format("DD/MM/YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Created By</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {cn.cn_staff_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <Grid container item spacing={1}>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Reference</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1"  gutterBottom>
                                    {cn.full_reference}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1}>
                            {!!cn.cn_order_id && [
                                <Grid item xs={4}>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Order</strong>
                                    </Typography>
                                </Grid>,
                                <Grid item xs={8}>
                                    <Typography variant="body1" gutterBottom>
                                        {`${cn.order.order_reference}-${cn.order.order_reference_amendment}`}
                                    </Typography>
                                </Grid>]
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <br></br>
                <Grid container item spacing={1}>
                    <Grid item xs={5}>
                        <Grid container item spacing={1}>
                            <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                                Customer
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Name</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {cn.cn_cust_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Address</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {cn.cn_cust_address.split('\n').map((item, key) => {
                                        return  <span key={key}>
                                                    {item}{cn.cn_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                </span>
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Contact</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {cn.cn_cust_contact_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Phone Number</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {customerPhone}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Email</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {cn.cn_cust_contact_email}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <Grid container item spacing={1}>
                            <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                                Delivery Address
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Name</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {cn.cn_delivery_cust_address_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Address</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {cn.cn_delivery_cust_address.split('\n').map((item, key) => {
                                        return  <span key={key}>
                                                    {item}{cn.cn_delivery_cust_address.split('\n').length === key+1 ? null : <br/>}
                                                </span>
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Contact</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {cn.cn_delivery_cust_contact_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Phone Number</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {deliveryPhone}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Email</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    {cn.cn_delivery_cust_contact_email}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <br></br>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tblHeader}>
                                <TableCell className={classes.tblHeaderCell}></TableCell>
                                <TableCell className={classes.tblHeaderCell}>Part Number</TableCell>
                                <TableCell className={classes.tblHeaderCell}>Part Description</TableCell>
                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Quantity</TableCell>
                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Unit Price</TableCell>
                                {cn.cn_type == 'With Discount' &&
                                    <React.Fragment>
                                        <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Discount Rate</TableCell>
                                        <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>New Unit Price</TableCell>
                                        <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Discount Total</TableCell>
                                    </React.Fragment>
                                }
                                {_.find(cn.details, i => i.cnd_restock_fee) &&[
                                    <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Total Price</TableCell>,
                                    <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Restocking Fee</TableCell>]
                                }
                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'right'}}>Total Credit</TableCell>
                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'center'}}>Part Restock</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {cn.details && cn.details.map((rowData ,key) => (
                            <TableRow style={{borderBottom: '1px solid rgb(224, 224, 224)'}} key={key}>
                                <TableCell style={{height:65, borderBottom: 'none', verticalAlign: 'middle'}} align='center'>
                                    <img src={(rowData.part && rowData.part.default_image && rowData.part.default_image.library_thumbnail_url) ? rowData.part.default_image.thumbnail_file_path : logo} style={{overflow:'visible', maxHeight: 45, maxWidth: 45}} alt="" />
                                </TableCell>
                                <TableCell style={{whiteSpace:'nowrap', color: colors.red}}>{rowData.part.part_number}</TableCell>
                                <TableCell style={{whiteSpace:'nowrap', width:'16%'}}>
                                    <>
                                        <span style={{color: colors.red}}>{this.trimPartDescription(rowData.part.part_description)}</span><br/>
                                        {_.map(rowData.cnd_serial_numbers,       (i,idx) => { return <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{i.serialNumber}</Typography>})}
                                        {_.map(rowData.cnd_warranty_void_labels, (i,idx) => { return <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{i}</Typography>})}
                                        {!!rowData.cnd_notes && 
                                            <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>
                                                {rowData.cnd_notes}
                                            </Typography>
                                        }
                                    </>
                                </TableCell>
                                <TableCell style={{textAlign:'right'}}><span style={{color: colors.red}}>{rowData.cnd_quantity}</span></TableCell>
                                <TableCell style={{textAlign:'right'}}><span style={{color: colors.red}}>£{parseFloat(rowData.cnd_unit_price).toFixed(2)}</span></TableCell>
                                {cn.cn_type == 'With Discount' &&
                                    <React.Fragment>
                                        <TableCell style={{textAlign:'right'}}><span style={{color: colors.red}}>{`${parseFloat(rowData.cnd_discount_rate).toFixed(2)}%`}</span></TableCell>
                                        <TableCell style={{textAlign:'right'}}><span style={{color: colors.red}}>{`£${parseFloat(rowData.cnd_new_unit_price).toFixed(2)}`}</span></TableCell>
                                        <TableCell style={{textAlign:'right'}}><span style={{color: colors.red}}>{`£${parseFloat(rowData.cnd_discount_total).toFixed(2)}`}</span></TableCell>
                                    </React.Fragment>
                                }
                                {_.find(cn.details, i => i.cnd_restock_fee) && 
                                    <>
                                        <TableCell style={{textAlign:'right'}}><span style={{color: colors.red}}>{`£${(parseFloat(rowData.cnd_new_unit_price).toFixed(2) * parseFloat(rowData.cnd_quantity)).toFixed(2)}`}</span></TableCell>
                                        <TableCell style={{textAlign:'right'}}><span style={{color:colors.red}}>{`£${parseFloat(rowData.cnd_restock_total_price).toFixed(2)}`}</span></TableCell>
                                    </>
                                }
                                <TableCell style={{textAlign:'right', width:'10%'}}><span style={{color:colors.red}}>{`-£${parseFloat(rowData.cnd_total_price).toFixed(2)}`}</span></TableCell>
                                <TableCell className={classes.tblHeaderCell} style={{textAlign:'center'}}>
                                    <AllIcon
                                        icon={rowData.part.stockable ? ( rowData.cnd_exclude_stock_change ? icons.false : icons.true ) : icons.dash}
                                        color={rowData.part.stockable ? ( rowData.cnd_exclude_stock_change ? colors.red : colors.green ) : colors.disabled}
                                        noMargin
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colspan={_.find(cn.details, i => i.cnd_restock_fee) ? (cn.cn_type == 'With Discount' ? 9 : 6) : (cn.cn_type == 'With Discount' ? 7 : 4)}></TableCell>
                                <TableCell style={{textAlign:'right', paddingRight: '8px', color: colors.red}} colSpan={colspan}><strong>Sub Total</strong></TableCell>
                                <TableCell colspan={2} style={{textAlign:'right', color: colors.red}}><strong>-£{cn.cn_sub_total}</strong></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colspan={_.find(cn.details, i => i.cnd_restock_fee) ? (cn.cn_type == 'With Discount' ? 9 : 6) : (cn.cn_type == 'With Discount' ? 7 : 4)}></TableCell>
                                <TableCell style={{textAlign:'right', paddingRight: '8px', color: colors.red}} colSpan={colspan}><strong>VAT ({cn.vat.vat_value_percentage}%)</strong></TableCell>
                                <TableCell colspan={2} style={{textAlign:'right', color: colors.red}}><strong>-£{cn.cn_vat}</strong></TableCell>
                            </TableRow>
                            <TableRow className={classes.tblFooter}>
                                <TableCell colspan={_.find(cn.details, i => i.cnd_restock_fee) ? (cn.cn_type == 'With Discount' ? 9 : 6) : (cn.cn_type == 'With Discount' ? 7 : 4)}></TableCell>
                                <TableCell style={{textAlign:'right', paddingRight: '8px', color: colors.red}} colSpan={colspan}><strong>Total</strong></TableCell>
                                <TableCell colspan={2} style={{textAlign:'right', color: colors.red}}><strong>-£{cn.cn_total_incl_vat}</strong></TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                    <br></br>
                </Grid>
                <Grid container item spacing={1} className="mb-3">
                    <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                        Credit Note Notes
                    </Grid>
                    <Grid item xs={12}>
                        {cn.cn_notes ?
                            cn.cn_notes.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br/></span>
                            })
                        :
                            'No Notes'
                        }
                    </Grid>
                </Grid>
                <br></br>
            </React.Fragment>
        );
    }
}

export default (withStyles(styles)(CreditNote));