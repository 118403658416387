import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import CustomerReturnsDownloadPrevDocsDialog from 'Components/CustomerReturns/CustomerReturnsDownloadPrevDocsDialog/CustomerReturnsDownloadPrevDocsDialog';
import CustomerReturnsEmailPrevDocsDialog from 'Components/CustomerReturns/CustomerReturnsEmailPrevDocsDialog/CustomerReturnsEmailPrevDocsDialog';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';

import { Card, CardContent, CardHeader, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const initialState = {
    data:       {},
    overview:   {}
}

function DueTable({data, title, handleDownloadOpen, handleEmailOpen, handleCNDownloadOpen, handleCNEmailOpen}){
    return(
        <Grid item xs={12}>
            <ExpansionPanel styles={{width:'100%'}}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{title}</Typography> 
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <DataTable 
                        config={{
                            key: 'id',
                            pagination: false,
                            alternatingRowColours: true,
                            responsiveimportant: true,
                        }}
                        columns={[
                            {
                                heading:    'Date',
                                field:      rowData => `${moment(rowData.date).format("DD/MM/YYYY")}`, 
                                dataRef:    rowData => {return moment(rowData.date).format('YYYYMMDD')}
                            },
                            {
                                actionsCustomHeader:    true,
                                important:              true,
                                alignment:              'left',
                                heading:                'Reference',
                                actions: rowData => {
                                    let ret = [];
                                    ret.push(rowData.cn ? 
                                        { label: rowData.invoiceReference, link: `/returns/creditnote/${rowData.id}`, type:'plainLink', color: colors.red}:
                                        { label: rowData.invoiceReference, link: `/sales/order/invoice/payments/${rowData.orderId}`, type:'plainLink' }
                                    );
                                    if (rowData.orderReference) ret.push({ label: rowData.orderReference, link: `/sales/order/view/${rowData.orderId}`, type:'plainLink' });
                                    return ret;
                                },
                                dataRef: rowData => {return rowData.cn ? rowData.cn_id : rowData.invoice_id}
                            },
                            {
                                heading: 'Number',
                                field: rowData =>  <Typography variant="caption"  style={{width:100, overflow:'hidden',textOverflow: "ellipsis"}} >
                                                        {rowData.number}
                                                    </Typography>,
                                sizeToContent: true,
                                dataRef: rowData => {return rowData.number}
                            },
                            {
                                heading: 'Customer',
                                field: rowData =>  <div>
                                                        {rowData.cust_name}<br/>
                                                        {rowData.account}
                                                    </div>,
                                sizeToContent: true,
                                dataRef: rowData => {return rowData.account},
                                truncate: true
                            },
                            {
                                heading: 'Due',
                                field: rowData => 
                                <>
                                    {moment(rowData.due).format("DD/MM/YYYY")}<br/>
                                    Days: <span style={{color: rowData.dueDays > 0 ? colors.red : colors.green}}>{rowData.dueDays}</span>
                                </>,
                                alignment: 'center',
                                dataRef: rowData => {return moment(rowData.dueDays).format('YYYYMMDD')}
                            },
                            {
                                heading: 'Terms',
                                field: rowData => <div style={{textAlign:'center'}}>{rowData.termDays}</div>,
                                alignment: 'center',
                                dataRef: 'termDays',
                            },
                            {
                                heading: 'Payment Method',
                                field: rowData => <Grid container style={{justifyContent: 'center'}}>{_.map(_.uniq(rowData.paymentMethods), i => <Grid item><AllIcon style={{color: (rowData.paid) ? colors.green : colors.orange}} icon={icons[i] || icons.unknown} tooltip={i} /></Grid>)}</Grid>,
                                alignment: 'center',
                                dataRef: 'termDays',
                                totalLabel: i => <Typography variant='body2' style={{textAlign:'right', fontWeight: 'bold'}}>Total</Typography>
                            },
                            {
                                heading: 'Total',
                                field: rowData => `${!!rowData.cn ? '-' : ''}£${parseFloat(rowData.total).toFixed(2)}`,
                                style: {textAlign:'right'},
                                alignment: 'right',
                                totalRef: rowData => { 
                                    let tot = parseFloat(rowData.total)
                                    return rowData.cn ? 0 - tot : tot;
                                },
                                totalLabel: i => <Typography variant='body2' style={{textAlign:'right', fontWeight: 'bold'}}>{`${i < 0 ? '-' : ''}£${parseFloat(Math.sqrt(Math.pow(i,2))).toFixed(2)}`}</Typography>,
                                dataRef:    rowData => {return parseFloat(rowData.total)}
                            },
                            {
                                heading: 'Paid',
                                field: rowData => `£${parseFloat((rowData.payments) || 0).toFixed(2)}`,
                                style: {textAlign:'right'},
                                alignment: 'right',
                                totalRef: rowData => { 
                                    let tot = parseFloat((rowData.payments) || 0)
                                    return tot;
                                },
                                totalLabel: i => <Typography variant='body2' style={{textAlign:'right', fontWeight: 'bold'}}>{`${i < 0 ? '-' : ''}£${parseFloat(Math.sqrt(Math.pow(i,2))).toFixed(2)}`}</Typography>,
                                dataRef:    rowData => {return parseFloat((rowData.payments) || 0)}
                            },
                            {
                                heading: 'Outstanding',
                                field: rowData => `${!!rowData.cn ? '-' : ''}£${parseFloat( parseFloat(rowData.outstanding) ).toFixed(2)}`,
                                style: {textAlign:'right'},
                                alignment: 'right',
                                totalRef: rowData => { 
                                    let tot = parseFloat( parseFloat(rowData.outstanding))
                                    return rowData.cn ? 0 - tot : tot;
                                },
                                totalLabel: i => <Typography variant='body2' style={{textAlign:'right', fontWeight: 'bold'}}>{`${i < 0 ? '-' : ''}£${parseFloat(Math.sqrt(Math.pow(i,2))).toFixed(2)}`}</Typography>,
                                dataRef:    rowData => {return parseFloat( parseFloat(rowData.outstanding))}
                            },
                            {
                                actions: rowData => {
                                    return rowData.cn ? [
                                        {name: 'Download',         icon: 'download', onClick: () => { handleCNDownloadOpen(rowData.id)}},
                                        {name: 'Send to Customer', icon: 'envelope', onClick: () => { handleCNEmailOpen(rowData.id)}},
                                        {name: 'View',             icon: 'search',   link: {pathname: `/returns/creditnote/invoice/${rowData.id}`}},  
                                    ] : [
                                        {name: 'Download',         icon: 'download', onClick: () => handleDownloadOpen(rowData.orderId)},
                                        {name: 'Send to Customer', icon: 'envelope', onClick: () => handleEmailOpen(rowData.orderId)},
                                        {name: 'View',             icon: 'search',   link: `/sales/order/invoice/payments/${rowData.orderId}`},
                                    ]
                                }
                            }
                        ]}
                        rows={_.orderBy(data, rowData=>{moment(rowData.date).format('YYYYMMDD');}, 'asc')}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Grid>
    )
}

class AccountReportCredit extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getData();
    }

    getData() {
        Promise.all([
            API.get( '/reports/accountsReport')
        ]).then(([acc]) => {
            let account = acc.data;
            let credit  = [].concat(account.due, account.overDue, account.credit);
            this.setState({
                account,
                credit,
                overview : {
                    limit:     _.sumBy(_.uniqBy(credit,i=>i.cust_id), i=> i.creditLimit || 0 ),
                    remaining: parseFloat(_.sumBy(_.uniqBy(credit,i=>i.cust_id), i=> i.creditLimit || 0 ) - parseFloat(_.sumBy(credit, i => { return  parseFloat(i.cn ? 0 - i.outstanding : i.outstanding) }))),
                    credit:    _.sumBy(credit, i => { return parseFloat(i.cn ? 0 - i.outstanding : i.outstanding )})
                }
            })
        });
    }

    handleDownloadOpen   = orderId    => {this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md')};

    handleEmailOpen      = orderId    => {this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md')};

    handleCNDownloadOpen = creditNote => {this.props.deployDialog(<CustomerReturnsDownloadPrevDocsDialog cnId={creditNote} />, 'Download Previous Documents',  '', 'md')};

    handleCNEmailOpen    = creditNote => {this.props.deployDialog(<CustomerReturnsEmailPrevDocsDialog cnId={creditNote} />, 'Email Previous Documents',     '', 'md')};

    render() {

        const {account, overview, credit} = this.state;
        const { classes } =  this.props;

        return (
            <Grid container xs={12} style={{width: '100%'}}>
                {account ?
                    <>
                        <Grid item xs={12}>
                            <Grid container xs={12}>
                                <Grid item xs={9} style={{paddingRight: '1.5em'}}>
                                    <PaddedPaper style={{marginBottom:0, padding: 17}}>
                                        <Grid container spacing={2} style={{justifyContent: 'center'}}>
                                            <Grid item>
                                                Total Credit
                                                <Typography variant='h4' style={{textAlign: 'center'}}>{overview.credit < 0 ? '-' : ''}£{parseFloat(overview.credit).toFixed(2)}</Typography>
                                            </Grid>
                                            <Grid item >
                                                <br/>
                                                <AllIcon size='large' icon={'slash-forward'} noMargin />
                                            </Grid>
                                            <Grid item>
                                                Total Limit
                                                <Typography variant='h4' style={{textAlign: 'center'}}>{overview.limit < 0 ? '-' : ''}£{parseFloat(overview.limit).toFixed(2)}</Typography>
                                            </Grid>
                                            <Grid item >
                                                <br/>
                                                <AllIcon size='large' icon={'slash-forward'} noMargin />
                                            </Grid>
                                            <Grid item>
                                                Total Remaining
                                                <Typography variant='h4' style={{textAlign: 'center', color: overview.remaining < 0 ? colors.red : colors.green}}>£{parseFloat(Math.abs(overview.remaining)).toFixed(2)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                    <Grid container xs={12} style={{paddingTop: '1.5em'}}>
                                        <Grid item xs={4}>
                                            <PaddedPaper style={{padding: '1.2em'}}>
                                                <Grid container xs={12}>
                                                    <Grid item xs={8}>  
                                                        <Typography>Average Payment Days: </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography>{ parseFloat(_.meanBy(_.concat(account.completed, account.overDue), i => parseInt(i.dueDays))).toFixed(2) } </Typography>  
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={4} style={{paddingLeft:'1.5em'}}>
                                            <PaddedPaper style={{padding: '1.2em'}}>
                                                <Grid container xs={12}>
                                                    <Grid item xs={8}>  
                                                        <Typography>Average Term Days: </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography>{ parseFloat(_.meanBy(_.concat(account.completed, account.overDue), i => parseInt(i.termDays))).toFixed(2) } </Typography>  
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={4} style={{paddingLeft:'1.5em'}}>
                                            <PaddedPaper style={{padding: '1.2em'}}>
                                                <Grid container xs={12}>
                                                    <Grid item xs={6}>  
                                                        <Typography>Accounts: </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography>{ parseInt(_.uniqBy(credit, 'address' ).length)} </Typography>  
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <PaddedPaper style={{padding: '1em'}}>
                                        <div style={{marginBottom:10, textAlign:'center'}}>Break Down</div>
                                        <Grid container xs={12} spacing={0}>
                                            <Grid item xs={5}><Typography>Current:</Typography></Grid>
                                            <Grid item xs={2}>{_.filter(credit,  rowData => rowData.fromStart <= 30).length}</Grid>
                                            <Grid item xs={5}>
                                                <Typography>£{parseFloat(_.sumBy(_.filter(credit,  
                                                    rowData => {
                                                        return rowData.fromStart <= 30
                                                    }), 
                                                    rowData => {
                                                        return  parseFloat((rowData.cn) ? 0 - rowData.outstanding : rowData.outstanding);
                                                    })).toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            
                                            <Grid item xs={5}><Typography>Over 30 days:</Typography></Grid>
                                            <Grid item xs={2}>{_.filter(credit,  rowData => rowData.fromStart > 30 && rowData.fromStart <= 60).length}</Grid>
                                            <Grid item xs={5}>
                                                <Typography>£{parseFloat(_.sumBy(_.filter(credit,  
                                                    rowData => {
                                                        return rowData.fromStart > 30 && rowData.fromStart <= 60
                                                    }), 
                                                    rowData => {
                                                        return  parseFloat((rowData.cn) ? 0 - rowData.outstanding : rowData.outstanding);
                                                    })).toFixed(2)}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={5}><Typography>Over 60 days:</Typography></Grid>
                                            <Grid item xs={2}>{_.filter(credit,  rowData => rowData.fromStart > 60 && rowData.fromStart <= 90).length}</Grid>
                                            <Grid item xs={5}>
                                                <Typography>£{parseFloat(_.sumBy(_.filter(credit,  
                                                    rowData => {
                                                        return rowData.fromStart > 60 && rowData.fromStart <= 90
                                                    }), 
                                                    rowData => {
                                                        return  parseFloat((rowData.cn) ? 0 - rowData.outstanding : rowData.outstanding);
                                                    })).toFixed(2)}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={5}><Typography>Over 90 days:</Typography></Grid>
                                            <Grid item xs={2}>{_.filter(credit,  rowData => rowData.fromStart > 90 && rowData.fromStart <= 120).length}</Grid>
                                            <Grid item xs={5}>
                                                <Typography>£{parseFloat(_.sumBy(_.filter(credit,  
                                                    rowData => {
                                                        return rowData.fromStart > 90 && rowData.fromStart <= 120
                                                    }), 
                                                    rowData => {
                                                        return  parseFloat((rowData.cn) ? 0 - rowData.outstanding : rowData.outstanding);
                                                    })).toFixed(2)}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={5}><Typography>Older:</Typography></Grid>
                                            <Grid item xs={2}>{_.filter(credit,  rowData => rowData.fromStart > 120 ).length}</Grid>
                                            <Grid item xs={5}>
                                                <Typography>£{parseFloat(_.sumBy(_.filter(credit,  
                                                    rowData => {
                                                        return rowData.fromStart > 120 
                                                    }), 
                                                    rowData => {
                                                        return  parseFloat((rowData.cn) ? 0 - rowData.outstanding : rowData.outstanding);
                                                    })).toFixed(2)}
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '1em'}}>
                            <Grid container spacing={3} xs={12} style={{margin:0}}>
                                {_.map(_.keys(account), i => {
                                    const data = account[i];
                                    const icon = {
                                        due:       icons.creditDue,
                                        overDue:   icons.creditOverDue,
                                        credit:    icons.creditCreditNote,
                                        completed: icons.tick
                                    }[i];
                                    const title = {
                                        due:       'Due',
                                        overDue:   'Over Due',
                                        credit:    'Credit Notes Due',
                                        completed: 'Completed'
                                    }[i];
                                    let sum = i == 'completed' ? _.sumBy(data, i => parseFloat(i.total || 0) ) : _.sumBy(data, i => parseFloat(i.outstanding || 0) );
                                    return (
                                        <Grid item container xs={3} >
                                            <Card className={classes.card} >
                                                <CardHeader
                                                    avatar={<AllIcon icon={ icon } size='medium' style={{color: '#fff'}} />}
                                                    title={title}
                                                    titleTypographyProps={{variant:'h6'}}
                                                    className={classes.header}
                                                />
                                                <CardContent style={{padding:10}}>
                                                    <Grid container spacing={1} >
                                                        <Grid item xs={3}>
                                                            <PaddedPaper style={{padding:'1em'}}>
                                                                <Typography variant="h6" style={{textAlign:'center'}}>{data.length}</Typography>
                                                            </PaddedPaper>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <PaddedPaper style={{padding:'1em'}}>
                                                                <Typography variant="h6" style={{textAlign:'center'}}>{i =='credit'&& '-'}£{parseFloat(sum).toFixed(2)}</Typography>
                                                            </PaddedPaper>
                                                        </Grid>
{/*
                                                        <Grid item xs={12}>
                                                            Invoices / Credit Notes
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <PaddedPaper style={{padding:'0.5em', paddingTop:'1.8em', paddingBottom:'1.8em'}}>
                                                                <Typography variant="h6" style={{textAlign:'center'}}>{data.length}</Typography>
                                                            </PaddedPaper>
                                                        </Grid>
                                                        <Grid container item xs={9} alignContent='space-between'>
                                                        <Grid item xs={12}>
                                                                <PaddedPaper style={{padding:8}}>
                                                                    <Grid container spacing={3}> 
                                                                        <Grid item xs={2}><AllIcon icon={icons.products} size='small'/></Grid>
                                                                        <Grid item xs={4}>{parseFloat(_.sumBy(data,i=>parseFloat(i.productQty))) || 0}</Grid>
                                                                        <Grid item xs={6}>{i =='credit'&& '-'}£{parseFloat(Math.abs(_.sumBy(data,i=>parseFloat(i.productSale)))).toFixed(2)}</Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper style={{padding:8}}>
                                                                    <Grid container spacing={3}> 
                                                                        <Grid item xs={2}><AllIcon icon={icons.services} size='small'/></Grid>
                                                                        <Grid item xs={4}>{parseFloat(_.sumBy(data,i=>parseFloat(i.serviceQty))) || 0}</Grid>
                                                                        <Grid item xs={6}>{i =='credit'&& '-'}£{parseFloat(Math.abs(_.sumBy(data,i=>parseFloat(i.serviceSale)))).toFixed(2)}</Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                        </Grid>
*/}
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )
                                })}
                                {/*
                                <Card className={classes.card} >
                                    <CardHeader
                                        avatar={<AllIcon icon={ icon } size='medium' style={{color: '#fff'}} />}
                                        title={title}
                                        titleTypographyProps={{variant:'h6'}}
                                        className={classes.header}
                                    />
                                    <CardContent style={{padding:5}}>
                                        <Grid container spacing={1} style={{padding:'1em'}} >
                                            <Grid item xs={12}>
                                                <PaddedPaper style={{padding:'1em'}}>
                                                    <Typography variant="h4" style={{textAlign:'center'}}>{sum < 0 && '-'}£{parseFloat(Math.sqrt(Math.pow(sum,2))).toFixed(2)}</Typography>
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                Invoices / Credit Notes
                                            </Grid>
                                            <Grid item xs={3}>
                                                <PaddedPaper >
                                                    <Typography variant="h6" style={{textAlign:'center'}}>{data.length}</Typography>
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid container item xs={9} alignContent='space-between'>
                                                <Grid item xs={12}>
                                                    <PaddedPaper style={{padding:8}}>
                                                        <Grid container spacing={3}> 
                                                            <Grid item xs={4}>Products:</Grid>
                                                            <Grid item xs={2}>{_.sumBy(data,'products') || 0}</Grid>
                                                            <Grid item xs={6}>{psum < 0 && '-'}£{parseFloat(Math.sqrt(Math.pow(psum,2))).toFixed(2)}</Grid>
                                                        </Grid>
                                                    </PaddedPaper>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <PaddedPaper style={{padding:8}}>
                                                        <Grid container spacing={3}> 
                                                            <Grid item xs={4}>Services:</Grid>
                                                            <Grid item xs={2}>{_.sumBy(data,'services') || 0}</Grid>
                                                            <Grid item xs={6}>{ssum < 0 && '-'}£{parseFloat(Math.sqrt(Math.pow(ssum,2))).toFixed(2)}</Grid>
                                                        </Grid>
                                                    </PaddedPaper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '1.5em'}}>
                            <DueTable title={`Invoices Due (${account.due.length})`} data={account.due} handleDownloadOpen={this.handleDownloadOpen} handleEmailOpen={this.handleEmailOpen} handleCNDownloadOpen={this.handleCNDownloadOpen} handleCNEmailOpen={this.handleCNEmailOpen} />
                            <DueTable title={`Invoices Overdue (${account.overDue.length})`} data={account.overDue}    handleDownloadOpen={this.handleDownloadOpen} handleEmailOpen={this.handleEmailOpen} handleCNDownloadOpen={this.handleCNDownloadOpen} handleCNEmailOpen={this.handleCNEmailOpen} />
                            {/*<DueTable title={'Completed'}        data={account.completed}  handleDownloadOpen={this.handleDownloadOpen} handleEmailOpen={this.handleEmailOpen} handleCNDownloadOpen={this.handleCNDownloadOpen} handleCNEmailOpen={this.handleCNEmailOpen} />*/}
                            <DueTable title={`Credit Notes Due (${account.credit.length})`}           data={account.credit}     handleDownloadOpen={this.handleDownloadOpen} handleEmailOpen={this.handleEmailOpen} handleCNDownloadOpen={this.handleCNDownloadOpen} handleCNEmailOpen={this.handleCNEmailOpen} />
                        </Grid>
                    </>
                    
                     : <LoadingCircle />
                    
                }
            </Grid>
        )
    }
};

const styles = theme => ({
    card: {
        color: '#000',
        backgroundColor: '#FFF',
        padding: '0',
        width: '100%'
    },
    header: {
        background: 'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', 
        color: '#FFF'
    },
});

export default withStyles(styles)(AccountReportCredit);

