import React       from 'react';
import { connect } from 'react-redux';

import { Grid } from '@material-ui/core';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';

import StockLevels          from './StockLevels';
import StockTrackerOverview from './StockTrackerOverview';

const initialState = {
    stock:         [],
    table:         'Overview',
    isLoading:     true,
    incorrectOnly: false,
}

class StockTracker extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }   

    render() {

        return (
            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <TabBarSwitcher 
                        defaultTab={this?.props?.match?.params?.tab || 'Stock Tracker'}
                        tabs={[
                            {
                                label: 'Stock Tracker',
                                content: <StockTrackerOverview/>
                            },
                            {
                                label: 'Stock Levels',
                                content: <StockLevels/>
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) { return { deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)) } };

export default connect(null, mapDispatchToProps)(StockTracker);