import { Grid } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import API from 'API';

import SalesReportCompleteMonth from './SalesReportCompleteMonth';
import SalesReportCurrentMonth from './SalesReportCurrentMonth';
import SalesReportYear from './SalesReportYear';
import { colors } from 'Helpers/ColourHelper';

let initialState = () => ({
    isLoading: true,
    data: {},
})

class salesReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.year !== this.props.year || prevProps.current !== this.props.current){
            this.setState(initialState());
            this.getData();
        }
    }

    getData = () => {
        let params = {params: {year:this.props.year, current: this.props.current ? 1 : 0}}
        API.get('/reports/performanceReport/sales/year', params)
        .then(response => {
            this.setState({
                data: response.data, 
                isLoading: false
            });
        })
    }

    render() {

        const {year, current} = this.props;
        const {isLoading, data} = this.state;

        let currentMonth = moment().month() + 1;      

        return (
            <Grid container spacing={2}>

                {/* Year Report */}
                <SalesReportYear {...this.props} data={data} isLoading={isLoading}/>

                <Grid item xs={12} style={{marginTop: '1em', marginBottom: '1em', borderTop: `1px solid ${colors.disabled}`, padding: 0}}></Grid>
                
                {!!current &&
                    <SalesReportCurrentMonth
                        month={currentMonth} 
                        year={year}  
                        data={data}
                        isLoading={isLoading}
                    />
                }

                {/* Complete Months */}
                <>
                    {_.map(_.reverse(_.range(-2, current ? ( (currentMonth > 10) ? (currentMonth - 12) : currentMonth ) : 10))
                    , idx => 
                        <SalesReportCompleteMonth 
                            month={parseFloat(idx) <= 0 ? 12 + parseFloat(idx) : parseFloat(idx)} 
                            year={year}  
                            data={data}
                            isLoading={isLoading}
                        />
                    )}
                </>

               
            </Grid>
        )
    }
}

export default salesReport;