import React from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import API from "API";
import CiDataTable from "Components/Common/DataTables/CiDataTable";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import PhoneBookForm from "Components/Common/Voip/PhoneBookForm";
import { deploySnackBar } from "Actions/SnackBar/SnackBar";
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import { deployDialog } from "Actions/Dialog/Dialog";
import { Dial } from "Functions/MiscFunctions";

const initialState = () => ({
    access: {
        addContact: false,
        deleteContact: false,
        updateContact: false,
    },
    companyList: null,
    dataLoading: true,
    results: [],
    searchString: "",
    searchCustomers: false,
    searchSuppliers: false
});

class GlobalPhoneBook extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.timeout = null;
    }

    componentDidMount = () => {
        Promise.all([
            API.get('/staff/my/access/check/pb-add'), 
            API.get('/staff/my/access/check/pb-update'), 
            API.get('/staff/my/access/check/pb-delete') 
        ])
        .then(([addContact, updateContact, deleteContact]) => {
            this.setState(
                {
                    access: {
                        ...this.state.access,
                        addContact: addContact?.data?.has_access ?? false,
                        deleteContact: deleteContact?.data?.has_access ?? false,
                        updateContact: updateContact?.data?.has_access ?? false,
                    },
                },
                this.getSearchData
            );
        });
    };

    componentWillUnmount = () => {
        this.timeout && clearTimeout(this.timeout);
    };

    getSearchData = () => {
        const { searchString, searchCustomers, searchSuppliers } = this.state;
        this.setState(
            {
                dataLoading: true,
            },
            () => {
                API.get("/voip/phoneBook", { params: { searchString, searchCustomers: searchCustomers ? 1 : 0, searchSuppliers: searchSuppliers ? 1 : 0, withCompanies: this.state.companyList === null ? 1 : 0 } }).then((result) => {
                    if (result?.data) {
                        this.setState({
                            dataLoading: false,
                            companyList: this.state.companyList === null ? (result.data?.companies ?? []).map((el) => ({ label: el, value: el})) : this.state.companyList,
                            results: result.data?.contacts ?? [],
                        });
                    }
                });
            }
        );
    };

    handleContact = (data = null) => {
        let contact = null;
        if (data) {
            contact = {
                firstName: data?.fn,
                lastName: data?.ln,
                email: data?.em,
                mobile: data?.mb,
                telephone: data?.tl,
                position: data?.po,
                company: data?.cn,
            };
        }
        this.props.deployDialog(
            <PhoneBookForm callback={this.getSearchData} contact={contact} contactId={data?.id} companyList={this.state.companyList} />,
            contact ? "Update Contact" : "Add Contact",
            'standard',
            'md'
        );
    };

    handleDeleteContact = (id) => {
        API.put(`/voip/phoneBook/${id}/delete`).then((result) => {
            if (result?.data?.success) {
                this.getSearchData();
                this.props.deploySnackBar("success", "Contact deleted successfully");
            }
        });
    };

    onSearchChange = (fieldName, value) => {
        this.setState(
            {
                [fieldName]: value,
            },
            this.setSearch
        );
    };

    resetSearch = () => {
        this.setState(
            {
                ...initialState(),
                access: this.state.access,
            },
            this.getSearchData
        );
    };

    setSearch = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 250);
    };

    render = () => {
        const { access, dataLoading, results, searchCustomers, searchSuppliers, searchString } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Global Phone Book
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PaddedPaper>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    onChange={(e) => this.onSearchChange("searchString", e.target.value)}
                                    placeholder="Search:"
                                    value={searchString}
                                    margin="normal"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FAIcon type="light" icon="search" size="small" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <FormControlLabel
                                            className="m-0"
                                            control={<Switch color="primary" checked={searchCustomers} onChange={(e) => this.onSearchChange("searchCustomers", e.target.checked)} value="1" />}
                                            label={
                                                <Typography variant="body2" className="fw-400">
                                                    Include Customers
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className="m-0"
                                            control={<Switch color="primary" checked={searchSuppliers} onChange={(e) => this.onSearchChange("searchSuppliers", e.target.checked)} value="1" />}
                                            label={
                                                <Typography variant="body2" className="fw-400">
                                                    Include Suppliers
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} align="right">
                                <Grid container spacing={1} alignItems="center" justify="flex-end">
                                    <Grid item>
                                        <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                        >
                                            <FAIcon icon='undo' type='light' button />
                                            Reset Search
                                        </Button>
                                    </Grid>
                                    {access?.addContact && (
                                        <Grid item>
                                            <Button 
                                                onClick={() => this.handleContact()}
                                                variant="contained"
                                                color="primary"
                                            >
                                                <FAIcon icon='user-plus' type='light' buttonPrimary />
                                                Add Entry
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <CiDataTable
                            config={{
                                key: "id",
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: dataLoading,
                                responsiveImportance: true,
                                persistenceId: this.persistenceId,
                                title: "Global Phone Book",
                                options: {
                                    dataRef: true,
                                    reset: this.resetSearch,
                                    export: {
                                        title: `Global Phone Book`,
                                        name: `global-phone-book`,
                                        excel: true,
                                    },
                                },
                            }}
                            columns={[
                                {
                                    heading: "",
                                    field: (rowData) => <FAIcon icon={rowData.id ? 'address-book' : rowData.cu === 1 ? 'users' : 'truck'} size={17.5} noMargin />,
                                    important: true,
                                    alignment: 'center',
                                    sizeToContent: true
                                },
                                {
                                    heading: "Company",
                                    field: (rowData) => rowData.cn || "-",
                                    dataRef: "cn",
                                    important: true,
                                },
                                {
                                    heading: "Name",
                                    field: (rowData) => (
                                        <Grid container spacing={1}>
                                            {rowData?.si === 1 ? (
                                                <Grid item>
                                                    <FAIcon icon="building" size={15} noMargin />
                                                </Grid>
                                            ) : null}
                                            <Grid item>{!rowData.fn && !rowData.ln ? '-' : rowData?.fn + " " + rowData?.ln}</Grid>
                                        </Grid>
                                    ),
                                    dataRef: "fn",
                                    important: true,
                                },
                                {
                                    heading: "Email",
                                    field: (rowData) => rowData.em || "-",
                                    dataRef: "em",
                                    important: true,
                                },
                                {
                                    heading: "Position",
                                    field: (rowData) => rowData.po || "-",
                                    dataRef: "po",
                                    truncate: true
                                },
                                {
                                    heading: "Mobile",
                                    field: (rowData) => <Dial number={rowData?.mb || '-'} />,
                                    dataRef: "mb",
                                },
                                {
                                    heading: "Telephone",
                                    field: (rowData) => <Dial number={rowData?.tl || '-'} />,
                                    dataRef: "tl",
                                },
                                {
                                    actions: (rowData) => {
                                        return [
                                            {
                                                name: "Update Contact",
                                                disabled: !access.updateContact || !rowData.id,
                                                icon: "pencil",
                                                onClick: () => this.handleContact(rowData),
                                            },
                                            {
                                                name: "Delete Contact",
                                                disabled: !access.deleteContact || !rowData.id,
                                                icon: "trash-alt",
                                                onClick: () =>
                                                    this.props.deployConfirmation(`Are you sure you want to delete ${rowData.fn} ${rowData.ln}?`, `Delete Entry`, () =>
                                                        this.handleDeleteContact(rowData.id)
                                                    ),
                                            },
                                        ];
                                    },
                                },
                            ]}
                            rows={results}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployConfirmation: (msg, title, success) => dispatch(deployConfirmation(msg, title, success)),
        deployDialog: (content, title, variant = 'standard', size = 'md', style = {}) => dispatch(deployDialog(content, title, variant, size, style)),
        deploySnackBar: (type, message) => dispatch(deploySnackBar(type, message)),
    };
};

export default connect(null, mapDispatchToProps)(GlobalPhoneBook);
