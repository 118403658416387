import React from 'react';
import _ from "lodash";

import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import Alert from "Components/Common/Alert/Alert";
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { useDispatch } from "react-redux";
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import { useEffect, useState } from "react";
import API from "API";

const WildixPhoneBook = ({
    extension,
    handleClose,
    handleGetPresenceColor,
    handleGetPresenceClassName,
    handleGetPresenceIcon,
    handleGetPresenceText,
    handleGetPresencePriority,
    onDial,
    presence,
    roster,
}) => {
    const [access, setAccess] = useState(null);
    const dispatch = useDispatch();

    const getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/phi-listen'), 
            API.get('/staff/my/access/check/phi-whisper'),
            API.get('/staff/my/access/check/phi-barge')
        ])
        .then(([listen, whisper, barge]) =>  {
            setAccess({
                listen: (listen.data && listen.data.has_access) || false,
                whisper: (whisper.data && whisper.data.has_access) || false,
                barge: (barge.data && barge.data.has_access) || false
            });
        });
    };

    const handleDial = (to) => {
        onDial(to);
        handleClose();
    };

    const confirm = (message, title, callback) => {
        dispatch(deployConfirmation(message, title, callback));
    };

    useEffect(() => {
        getAccess();
    }, []); //eslint-disable-line

    return (
        <Dialog disableEscapeKeyDown disableBackdropClick fullWidth maxWidth="sm" onClose={handleClose} open>
            <DialogTitle>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography variant="h6">Colleagues</Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Close">
                            <IconButton onClick={handleClose}>
                                <FAIcon icon="times" button noMargin />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Box mb={2.5}>
                    <Grid container spacing={3}>
                        {(!_.isEmpty(roster) && (
                            <Grid item xs={12}>
                                <CiDataTable
                                    config={{
                                        key: "_extension",
                                        alternatingRowColours: true,
                                        isLoading: false,
                                        inline: true,
                                        responsiveImportance: true,
                                    }}
                                    columns={[
                                        {
                                            heading: "Name",
                                            field: (rowData) => <Box className="fw-400">{rowData?.getName()}</Box>,
                                            main: true,
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: "Ext.",
                                            field: (rowData) => <Box className="fw-400">{rowData?.getExtension()}</Box>,
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: "Status",
                                            field: (rowData) => {
                                                const p = presence?.[rowData.getExtension()] ?? null;
                                                const icon = p ? handleGetPresenceIcon(p, false) : "phone-slash";
                                                const color = p ? handleGetPresenceColor(p, false) : "#D61F26";
                                                const cn = p ? handleGetPresenceClassName(p, false) : "";
                                                const text = p ? handleGetPresenceText(p, false) : "Unavailable";
                                                return (
                                                    <Grid container spacing={1} wrap='nowrap'>
                                                        <Grid item>
                                                            <FAIcon type="solid" icon={icon ?? "phone-arrow-up-right"} color={color ?? "#D61F26"} size={15} noMargin />
                                                        </Grid>
                                                        <Grid item>
                                                            <Box className={cn}>
                                                                <strong>{text}</strong>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            },
                                        },
                                        {
                                            actions: (rowData) => [
                                                {
                                                    name: "Listen (Silent Intrusion)",
                                                    icon: "face-shush",
                                                    disabled: !access?.listen || !presence?.[rowData.getExtension()]?.isConnectedCallAvailable(),
                                                    hideIfDisabled: true,
                                                    className: !presence?.[rowData.getExtension()]?.isConnectedCallAvailable() ? "textDisabled" : "textDefault",
                                                    size: 20,
                                                    onClick: () => confirm(`Are you sure you want to listen to this call?`, `Silent Intrusion`, () => handleDial(`*86*${rowData.getExtension()}`)),
                                                },
                                                {
                                                    name: "Whisper (Partial Intrusion)",
                                                    icon: "face-hand-over-mouth",
                                                    disabled: !access?.whisper || !presence?.[rowData.getExtension()]?.isConnectedCallAvailable(),
                                                    hideIfDisabled: true,
                                                    className: !presence?.[rowData.getExtension()]?.isConnectedCallAvailable() ? "textDisabled" : "textDefault",
                                                    size: 20,
                                                    onClick: () =>
                                                        confirm(`Are you sure you want to whisper to ${rowData?.getName()}?`, `Partial Intrusion`, () =>
                                                            handleDial(`*87*${rowData.getExtension()}`)
                                                        ),
                                                },
                                                {
                                                    name: "Barge (Conference Intrusion)",
                                                    icon: "phone-plus",
                                                    disabled: !access?.barge || !presence?.[rowData.getExtension()]?.isConnectedCallAvailable(),
                                                    hideIfDisabled: true,
                                                    className: !presence?.[rowData.getExtension()]?.isConnectedCallAvailable() ? "textDisabled" : "textDefault",
                                                    size: 18,
                                                    onClick: () => confirm(`Are you sure you want to barge this call?`, `Conference Intrusion`, () => handleDial(`*85*${rowData.getExtension()}`)),
                                                },
                                                {
                                                    name: "Call",
                                                    icon: "circle-phone",
                                                    lib: "solid",
                                                    className: "textSuccess",
                                                    size: 22.5,
                                                    onClick: () => handleDial(rowData.getExtension()),
                                                },
                                            ],
                                        },
                                    ]}
                                    rows={_.orderBy(
                                        _.filter(roster, (r) => r.getExtension() !== extension),
                                        [(el) => el.getName()],
                                        ["asc"]
                                    )}
                                />
                            </Grid>
                        )) || (
                            <Grid item xs={12}>
                                <Alert severity="error">No other colleagues available to dial</Alert>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default WildixPhoneBook;
