import React, { Component } from 'react';
import { connect }          from 'react-redux';
import _                    from 'lodash';
import API                  from 'API';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import DataTable          from 'Components/Common/DataTables/CiDataTable';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AllIcon            from 'Components/Common/Icons/AllIcon';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import { Grid, Typography, FormControl, Button, Dialog, DialogContent, DialogTitle, DialogActions, List, ListItem, ListItemText, IconButton, ListItemSecondaryAction } from '@material-ui/core';

import icons      from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

const initialState = {
    isLoading:  true,
    parts:      [],
    formData:   {
        part:       null,
        template:   null,
    },
    partsFormData: {
        show:  false,
        id:    0,
        parts: null,
        part:  null
    }
};

class Parts extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        Promise.all([
            API.get('/customerReturns/greenaware/parts'),
            API.get('/customerReturns/greenaware/templates'),
            API.get('/parts/all')
        ]).then(([linkedParts, templates, parts]) => {
            this.setState({
                isLoading:      false,
                linkedParts:    linkedParts.data,
                parts:          _.map(parts.data, i => ({
                                    value: i.part_id,
                                    label: `${i.part_number} - ${i.part_description}`
                                })),
                templates:      _.map(templates.data, i => ({
                                    value: i.gt_id,
                                    label: i.gt_template_label
                                })),
            })
        });
    }

    delete = (id) => {
        API.post(`/customerReturns/greenaware/parts/${id}/delete`)
        .then(this.getData);
    }

    handleSubmit = () => {
        API.post(`/customerReturns/greenaware/parts`, this.state.formData)
        .then(res => {
            this.setState({
                formData: initialState.formData
            }, this.getData);
        })
    }

    handleDialogClose = () => {
        this.setState({
            partsFormData: initialState.partsFormData
        });
    }

    handleSubmitPartsParts = () => {
        API.post(`/customerReturns/greenaware/parts/${this.state.partsFormData.id}/changeLinkedParts`, {parts: _.map(this.state.partsFormData.parts, i => i.value)})
        .then(() => {
            this.handleDialogClose();
            this.getData();
        });
    }

    changeAddPartReturnPart = (e) => {
        this.setState({
            partsFormData: {
                ...this.state.partsFormData, 
                part: e ? e.value : null
            }
        });
    }

    addPartReturnPart = () => {
        this.setState({
            partsFormData: {
                ...this.state.partsFormData,
                parts: [...this.state.partsFormData.parts, _.find(this.state.parts, {value: this.state.partsFormData.part})],
                part:  null
            }
        });
    }

    removePartFromPart = index => () => {
        this.setState({
            partsFormData: {
                ...this.state.partsFormData,
                parts: _.filter(this.state.partsFormData.parts, (i, idx) => index !== idx)
            }
        })
    }

    render() {
        const { isLoading, parts, linkedParts, formData, templates, partsFormData }  = this.state;

        if (isLoading) return (<LoadingCircle />);

        return (
            <PaddedPaper>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <PaddedPaper>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>Add Part</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth margin="none">
                                        <AutoCompleteSelect
                                            label='Part *'
                                            options={_.filter(parts, i => !_.find(linkedParts, {gp_part_id: i.value}))}
                                            value={formData.part}
                                            onChange={(e) => this.setState({formData: {...formData, part: e.value}})}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth margin="none">
                                        <AutoCompleteSelect
                                            label='Template *'
                                            options={templates}
                                            value={formData.template}
                                            onChange={(e) => this.setState({formData: {...formData, template: e.value}})}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} className='buttonRow'>
                                    <Button 
                                        disabled={!formData.part || !formData.template} 
                                        onClick={()=>this.props.deployConfirmation('Are you sure you want to add this part to greenaware?','Add Part To Greenaware',this.handleSubmit)}
                                        variant="contained"
                                        color="primary"
                                    >Add</Button>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <DataTable
                            config={{
                                key: 'gp_id' 
                            }}
                            columns={[
                                {
                                    heading:       'Part',
                                    field:         rowData => 
                                        <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <img src={rowData.part?.default_image?.thumbnail_file_path || logo} alt={rowData.part?.part_description} style={{maxHeight:35, maxWidth:35}} />
                                        </div>,
                                    sizeToContent: true,
                                },
                                {
                                    heading:       'Part',
                                    field:         'part.part_number',
                                    sizeToContent: true,
                                },
                                {
                                    heading: '',
                                    field:   'part.part_description',
                                },
                                {
                                    heading:       'Template',
                                    field:         'template.gt_template_label',
                                    sizeToContent: true,
                                },
                                {
                                    actions: rowData => ([
                                        {
                                            name: 'parts',
                                            icon: icons.parts,
                                            onClick: () => {
                                                this.setState({
                                                    partsFormData: {
                                                        show:   true,
                                                        id:     rowData.gp_id,
                                                        parts:  _.map(rowData.parts, i => ({
                                                                    value: i.part_id,
                                                                    label: `${i.part_number} - ${i.part_description}`
                                                                }))
                                                    }
                                                });
                                            }
                                        },
                                        {
                                            name:    'delete',
                                            icon:    icons.delete,
                                            onClick: () => {
                                                this.props.deployConfirmation(
                                                    `Are you sure you want to remove ${rowData.part.part_number} from greenaware?`,
                                                    'Remove Part',
                                                    () => this.delete(rowData.gp_id)
                                                )
                                            }
                                        }
                                    ])
                                }
                            ]}
                            rows={_.orderBy(linkedParts, [i=>i.part.part_number], ['asc'])}
                        />
                    </Grid>
                </Grid>
                <Dialog 
                    open={partsFormData.show} 
                    onClose={this.handleDialogClose} 
                    maxWidth="xl" 
                    scroll="body"
                >
                    <DialogTitle>
                        <Typography>Add/Remove Part Packaging</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} style={{width: 500, alignItems: 'center'}}>
                            <Grid item xs={12}>
                                Add Parts
                            </Grid>
                            <Grid item xs={10}>
                                <FormControl fullWidth>
                                    <AutoCompleteSelect
                                        options={this.state.parts}
                                        value={partsFormData.part}
                                        onChange={this.changeAddPartReturnPart}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} className='buttonRow'>
                                <Button onClick={this.addPartReturnPart} variant='contained' color='primary' >Add</Button>
                            </Grid> 
                            <Grid item xs={12}>
                                <List>
                                    {this.state.partsFormData.parts && _.map(this.state.partsFormData.parts, (i, index) => 
                                        <ListItem key={index}>
                                            <ListItemText primary={i.label} />
                                            <ListItemSecondaryAction>
                                                <AllIcon icon={icons.delete} onClick={this.removePartFromPart(index)} noMargin />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </List>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handleSubmitPartsParts} autoFocus variant="contained" color="primary" disabled={!this.state.partsFormData?.parts?.length}>Submit</Button>
                    </DialogActions>
                </Dialog>
            </PaddedPaper>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success, cancel) => dispatch(deployConfirmation(message, title, success, cancel)),
})

export default connect(null, mapDispatchToProps)(Parts);