import * as SnackBarActionTypes from './Types/Types';

export function handleSnackBarDeployment(variant, message) {
    return (dispatch) => {
        dispatch({
            type: SnackBarActionTypes.DEPLOY_SNACKBAR,
            payload: {
                variant: variant,
                message: message
            }
        });
    }
}

export function deploySnackBar(variant, message) {
    return dispatch => {          
        dispatch(handleSnackBarDeployment(variant, message));
    }
}