//CustomerReturnsDownloadPrevDocsDialog
import React, { useState, useEffect }   from "react";
import { useDispatch }                  from "react-redux";
import _                                from 'lodash';
import API                              from "API";

import { pdfFromBase64 }                from "Helpers/PDFHelper";
import { statusHelper }                 from 'Helpers/CustomerReturnsHelper';

import FALightIcon                      from "Components/Common/Icons/FontAwesome/FALightIcon";
import LoadingCircle                    from "Components/Common/LoadingCircle/LoadingCircle";

import { makeStyles }                   from "@material-ui/core/styles";
import Typography                       from "@material-ui/core/Typography";
import Grid                             from "@material-ui/core/Grid";
import Button                           from "@material-ui/core/Button";

import { closeDialog }                  from "Actions/Dialog/Dialog";
import { deploySnackBar }               from "Actions/SnackBar/SnackBar";
import ReturnDownloadDialog from "Components/CustomerReturns/CustomerReturns/ViewCustomerReturns/ReturnDownloadDialog";
import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';

const useStyles = makeStyles(() => ({
    button: {
        textTransform: "capitalize",
    },
    buttonRow: {
        float: "right",
        margin: 0,
    },
}));

const initialState = {
    creditNote:     [],
    isLoading:      true,
    enablePayment:  false,
    order: {},
};

const CustomerReturnsDownloadPrevDocsDialog = ({ cnId }) => {
    
    const [state, setState] = useState(initialState),
        { creditNote, isLoading, order } = state,
        classes = useStyles(),
        dispatch = useDispatch(),
        handleDialogClose = () => dispatch(closeDialog()),
        handleDownload = (type, subType = null) => {
            let url;
            switch(type){
                default:  url = `/customerReturns/creditNote/${cnId}/prevPdf`; break;
            }
            API.post(url, {type: subType,}).then((result) => {
                if (result.data) {
                    pdfFromBase64(result.data.pdf, result.data.pdfName);
                    dispatch(
                        deploySnackBar("success", "PDF successfully downloaded")
                    );
                }
            });
        },
        handleOrderDownload = (type, id) => () => {
            API.post(`/sales/orders/${order.order_id}/prevPdf`, {
                type: type,
                id: id
            }).then((result) => {
                if (result.data) {
                    pdfFromBase64(result.data.pdf, result.data.pdfName);
                }
            });
        };

        useEffect(() => {
            API.get(`/customerReturns/creditNote/${cnId}`).then((result) => {
                if (result.data) {
                    if (result.data.status_change.length > 0) 
                        _.map(result.data.status_change, i => {i.icon = statusHelper(i.cnsc_status);});
                        if (!!result.data.order) {
                            API.get(`/sales/orders/${result.data.order.order_id}`).then((oResult) => {
                                setState((state) => ({
                                    ...state,
                                    creditNote: result.data,
                                    order: oResult.data,
                                    isLoading: false,
                                }));
                            });
                        } else {
                            setState((state) => ({
                                ...state,
                                creditNote: result.data,
                                isLoading: false,
                            }));
                        }
                }
            });
        }, [cnId]);

        return(
            <Grid container spacing={3}>
                {isLoading ? (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                ) : (
                    <>
                        {cnId ?  
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="caption">
                                        Credit Notes 
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Button
                                        startIcon={<FALightIcon icon="file-user" style={{color: '#F39C12'}} />} 
                                        className={classes.button}
                                        onClick={() => handleDownload('CreditNote','Acknowledgement')}
                                    >
                                        Credit Note Acknowledgement
                                    </Button>
                                </Grid>
                                {_.map(creditNote.status_change, i => 
                                <>
                                    {i.cnsc_status !== 'Approval' && i.cnsc_status !== 'Credit Note Awaiting Approval' && i.cnsc_status !== 'Credit Note' &&
                                        <Grid item xs={12} md={4}>
                                            <Button
                                                startIcon={<FALightIcon icon={i.icon} style={{color: '#F39C12'}} />}
                                                className={classes.button}
                                                onClick={() => handleDownload('CreditNote', i.cnsc_status)}
                                            >
                                                Credit Note {i.cnsc_status}
                                            </Button>
                                        </Grid>
                                    }
                                </>
                                )}
                                {creditNote.invoice &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">
                                                Credit Notes
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Button
                                                startIcon={<FALightIcon icon="file-invoice-dollar" style={{color: '#F39C12'}} />} 
                                                className={classes.button}
                                                onClick={() => handleDownload('CreditNote','Invoice')}
                                            >
                                                {creditNote.invoice.cni_invoice_number}
                                            </Button>
                                        </Grid>
                                    </>
                                }
                            </> : <></>
                        }
                        {
                            creditNote.order &&
                            <>
                        <Grid item xs={12}>
                            <Typography variant="caption">Order</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button
                                startIcon={
                                    <FALightIcon
                                        icon={icons.orderAwaitingApproval}
                                        color={colors.orange}
                                    />
                                }
                                className={classes.button}
                                onClick={handleOrderDownload("Order Acknowledgement")}
                            >
                                Order Acknowledgement
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button
                                startIcon={
                                    <FALightIcon
                                        icon={icons.userCheck}
                                        style={{ color: colors.orange }}
                                    />
                                }
                                className={classes.button}
                                onClick={handleOrderDownload("confirmation")}
                            >
                                Order Confirmation
                            </Button>
                        </Grid>
                        {order.payment_term?.payment_term_upfront_payment ?
                            <>
                                <Grid item xs={12} md={4}>
                                    <Button
                                        startIcon={
                                            <FALightIcon
                                                icon="credit-card"
                                                style={{ color: colors.orange }}
                                            />
                                        }
                                        className={classes.button}
                                        onClick={handleOrderDownload("awaiting-payment")}
                                        disabled={
                                            _.find(order.activity, ["order_log_type","Confirmed",]) ? false: true}
                                    >
                                        Order Awaiting Payment
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Button
                                        startIcon={
                                            <FALightIcon
                                                icon="receipt"
                                                style={{ color: colors.orange }}
                                            />
                                        }
                                        className={classes.button}
                                        onClick={handleOrderDownload("payment-confirmed")}
                                        disabled={
                                            _.find(order.activity, ["order_log_type","Payment Made"]) ? false : true}
                                    >
                                        Order Payment Confirmation
                                    </Button>
                                </Grid>
                            </>
                        :''}
                        {order.despatch && order.despatch.length > 0 && (
                            <>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Order Delivery Notes
                                </Typography>
                            </Grid>
                            {order.despatch.map((el) => (
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    key={el.desp_id}
                                >
                                    <Button
                                        startIcon={
                                            <FALightIcon
                                                icon={"truck"}
                                                style={{ color: colors.orange }}
                                            />
                                        }
                                        className={classes.button}
                                        onClick={handleOrderDownload(
                                                "delivery-note",
                                                el.desp_id
                                            )
                                        }
                                    >
                                        {el.full_reference}
                                    </Button>
                                </Grid>
                            ))}
                            </>
                        )}
                    </>
                }
                {order.invoices && order.invoices.length > 0 && (
                        <>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                Order Invoices
                            </Typography>
                        </Grid>
                        {order.invoices.map((el) => (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                key={el.invoice_id}
                            >
                                <Button
                                    startIcon={
                                        <FALightIcon
                                            icon={"file-invoice-dollar"}
                                            style={{ color: colors.orange }}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={handleOrderDownload(
                                            "invoice",
                                            el.invoice_id
                                        )
                                    }
                                >
                                    {el.invoice_number}
                                </Button>
                            </Grid>
                        ))}
                        </>
                    )}
                    {order.customer_returns && order.customer_returns.length > 0 && 
                        <>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Customer Returns
                                </Typography>
                            </Grid>
                            {_.map(order.customer_returns, cr => 
                                <Grid item xs={12} >
                                    <ReturnDownloadDialog
                                        cr={cr}
                                        subElement
                                    />
                                </Grid>
                            )}
                        </>
                    }
                        <Grid item xs={12}>
                            <div className={classes.buttonRow}>
                                <Button variant="outlined" color="default" onClick={handleDialogClose}>Close</Button>
                            </div>
                        </Grid>
                    </>
                )}
            </Grid>
        );
};

export default CustomerReturnsDownloadPrevDocsDialog;
