import React, { Component } from 'react';
import moment               from 'moment';
import _                    from 'lodash';
import { connect }          from 'react-redux';
import API                  from 'API';

import OpeningTimes from 'Components/Customers/OpeningTimes/OpeningTimes';
import Textarea     from 'Components/Common/Inputs/Textarea';

import { Button } from '@material-ui/core';

import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';

const initialState = {
    formData:   {
        openingTimes: [{
            day:       '',
            startTime: '00:00',
            endTime:   '00:00'
        }],
        addOpeningTimes:   false,
        openingTimesNotes: ''
    }, 
    formErrors: null,
}

class UpdateOpeningTimes extends Component {
    constructor(props) {
        super(props);

        let openingTimes = [];

        _.map(props.address.opening_times, el => {
            openingTimes.push({
                day:       el.ca_opening_day,
                startTime: el.ca_opening_start,
                endTime:   el.ca_opening_end
            });
        });

        this.state = {  
            ...initialState, 
            formData: {
                ...initialState.formData,
                openingTimesNotes:   props.address.address_opening_times,
                openingTimes:        openingTimes,
            }
        }
    }

    handleAddAccessTimes = field => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: JSON.parse(e.target.value)
            }
        });
    }
    handleDayChange = field => (addressIdx, idx) => day => {
        let newDay = [...this.state.formData[field]];
        newDay[idx] = {
            ...newDay[idx],
            day: day && day.value
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: newDay
            }
        });
    }
    handleTimeChange = field => (fieldName, addressIdx, idx) => time => {
        let newTime = [...this.state.formData[field]];
        newTime[idx] = {
            ...newTime[idx],
            [fieldName]: time &&  moment(time).format('HH:mm')
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: newTime
            }
        });
    }
    handleAddAccessTimeRow = field => () => {
        let item = initialState.formData[field][0];
        this.setState({
            formData:{
                ...this.state.formData,
                [field]: [...this.state.formData[field], item]
            }
        });
    }
    handleRemoveRow = field => (addressIdx, idx) => () => {
        const accessTimes = [...this.state.formData[field]]
        accessTimes.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                [field]: accessTimes
            }
        })
    }
    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    saveAccessTimes = () => {
        API.post(`/customers/addresses/${this.props.address.address_id}/updateOpening`, this.state.formData)
        .then(res => {
            this.props.onSave();
            this.props.closeDialog();
        })
    }

    render() {  
        const { formData, formErrors } = this.state;
        return(
            <>
                <OpeningTimes 
                    handleDayChange={this.handleDayChange('openingTimes')}
                    handleTimeChange={this.handleTimeChange('openingTimes')}
                    handleAddAccessTimes={this.handleAddAccessTimes('addOpeningTimes')}
                    handleAddAccessTimeRow={this.handleAddAccessTimeRow('openingTimes')}
                    handleRemoveRow={this.handleRemoveRow('openingTimes')}
                    addAccessTimes={formData.addAccessTimes}
                    formData={formData} 
                    formErrors={formErrors}
                    addressIdx={0}
                    update={true}
                />
                <Textarea
                    label='Opening Times Notes'
                    name='openingTimesNotes'
                    onChange={this.handleChange}
                    value={formData.openingTimesNotes}
                    margin="normal"
                    fullWidth
                    rows={4}
                />
                <div className='buttonRow'>
                    <Button
                        variant="outlined"
                        onClick={this.props.closeDialog}
                    >Close</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={()=>this.props.deployConfirmation('Are you sure you want to update the opening times?', 'Update Opening Times', this.saveAccessTimes)}
                    >Save</Button>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    deployDialog:       (content, title, size)    => dispatch(deployDialog(content, title, null, size)),
    closeDialog:        ()                        => dispatch(closeDialog())
});

export default connect(null, mapDispatchToProps)(UpdateOpeningTimes);