import React       from 'react';
import API         from 'API';
import _           from 'lodash';
import { connect } from 'react-redux';

import { Button, Checkbox, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import BackButton       from 'Components/Common/Buttons/BackButton';
import LoadingCircle    from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper      from 'Components/Common/Paper/PaddedPaper';
import SelectPartWithQR from 'Components/Common/Selects/SelectPartWithQR';
import AllIcon          from 'Components/Common/Icons/AllIcon';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import { downloadS3File } from 'Functions/MiscFunctions';


const initialState = {
    isLoading:          true,
    subAssembly:        {},
    requireJigs:        true,
    formData:           [{
        jig:    null,
        stages: [],
    }],
    jigs:               [],
}

class SubAssemblyCreateBuildJigs extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){ 
        this.getJigs();
    }

    getSubAssembly = () => {
        API.get('/subAssemblies/' + this.props.match.params.id)
        .then((result) => {
            if (_.sumBy(result.data.instruction.stages, i => i.jigs.length ) > 0) this.props.history.push(`/sub-assemblies/view/${this.props.match.params.id}`);
            this.setState({
                subAssembly: result.data,
                isLoading:   false,
                stages:      _.map(result.data.instruction.stages,  i => ({
                    id: i.sais_id,
                    name: i.sais_name,
                }))
            });
        });
    }

    getJigs = () => {
        API.get('/parts/all', {params: {searchPartType: 21, withDefaultImg: true}})
        .then(res => {
            this.setState({
                jigs: _.map(res.data, i => ({
                    value: i.part_id,
                    label: i.part_number + ' - ' + i.part_description,
                    part:  i,
                })),
            }, this.getSubAssembly );
        })
    }
    
    handleSubmit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.post('/subAssemblies/' + this.props.match.params.id + '/createBuildStageJigs', {jigs: JSON.stringify(_.filter(this.state.formData, i => !!i.jig)), requireJigs: this.state.requireJigs})
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                } else { window.location.href = `/sub-assemblies/view/${this.props.match.params.id}`; }
            });
        });
    }

    handleRemove = (i, j) => () => {
        let { formData } = this.state;
        formData[i].jigs = _.filter(formData[i].jigs, jig => jig !== j);
        this.setState({formData});
    }

    handleEditJig = idx => e => {
        let { formData } = this.state;
        if (formData.length === (idx + 1) ) 
        formData.push({
                jig:    null,
                stages: [],
            });

        formData[idx].jig = e.value;
        this.setState({formData});
    }

    checkJigStage = (idx, stageId) => () => {
        let { formData } = this.state;
        if (formData[idx].stages.includes(stageId)){
            formData[idx].stages = _.filter(formData[idx].stages, i => i !== stageId);
        } else {
            formData[idx].stages.push(stageId);
        }
        this.setState({formData});
    }

    handleRemoveJig = idx => () => {
        let { formData } = this.state;
        formData = _.filter(formData, (i, j) => j !== idx);
        this.setState({formData});
    }

    render(){
   
        const { subAssembly, isLoading, formData, jigs, stages, requireJigs } = this.state;

        if (isLoading) return (<LoadingCircle />);

        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Create Sub Assembly Build Stages
                    </Typography>
                </Grid>   
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container xs={12} style={{alignItems: 'center'}}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {subAssembly.part.part_number} - {subAssembly.part.part_description}
                                </Typography>
                                <Typography variant="h6">
                                    Current Version: v{subAssembly.sa_version} {subAssembly.part.part_outsourced_subassembly === 1 ? <strong style={{color:'orange'}}>Outsourced</strong> : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container style={{alignItems: 'center'}}>
                            <Grid item xs={8} style={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    checked={requireJigs}
                                    color="primary"
                                    onChange={() => this.setState({requireJigs: !requireJigs, formData: [{jig:null,stages:[]}]})}
                                />
                                <Typography variant='h6'>Require Jigs</Typography>
                            </Grid>
                            <Grid item style={{marginLeft: 'auto'}}>
                                {subAssembly.part?.pdf_drawing && subAssembly.part?.pdf_drawing?.part_doc_url ?
                                    <Button variant='contained' color='primary' onClick={() => downloadS3File(subAssembly.part.pdf_drawing.url)}>
                                        <AllIcon icon={icons.download} color={colors.white} />Process Document
                                    </Button> : 'No Process Document'
                                }
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {!!requireJigs && _.map(formData, (i, idx) => 
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container style={{alignItems: 'flex-end'}}>
                                <Grid item style={{width: i.jig ? 'calc(100% - 44px)': '100%'}}>
                                    <SelectPartWithQR 
                                        fullWidth
                                        label={i.jig ? "Jig" : 'Add Jig'}
                                        name="jig"
                                        parts={_.filter(jigs, j => !_.find(formData, {jig: j.value}))}
                                        onChange={this.handleEditJig(idx)}
                                        noClear
                                    />
                                </Grid>
                                {i.jig &&
                                    <Grid item style={{width: 44}}>
                                        <AllIcon 
                                            icon={icons.delete} 
                                            onClick={this.handleRemoveJig(idx)}
                                            noMargin
                                        />
                                    </Grid>
                                }
                            </Grid>
                            {!!i.jig &&
                                <Grid container xs={12} spacing={1}>
                                    {_.map(stages, (s, sidx) => 
                                        <Grid item xs={12}>
                                            <Checkbox
                                                checked={_.find(i.stages, {id: s.id})}
                                                color="primary"
                                                onChange={this.checkJigStage(idx, s.id)}
                                            />
                                            Stage {sidx + 1} - {s.name}
                                        </Grid>
                                    )}
                                </Grid>
                            }
                        </PaddedPaper>
                    </Grid>
                )}
                
                
                <Grid item xs={12}>
                    <PaddedPaper>
                        <div className='buttonRow' style={{marginTop: 0}}>
                            <BackButton props={this.props} />
                            <Button onClick={()=>this.props.deployConfirmation('Are you sure you want to add these jigs to this sub assembly?', 'Add Sub Assembly Jigs', this.handleSubmit)}
                                    variant="contained"
                                    color="primary"
                                    disabled={requireJigs && ( _.filter(formData, i => i.jig).length === 0 || _.filter(formData, i => i.jig).length !== _.filter(formData, i => i.stages.length > 0).length) }>
                                Update
                            </Button>
                        </div>
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {return {
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    deployDialog:       (content, title, variant, size = 'md', style)   => dispatch(deployDialog(content, title, variant, size, style)),
    closeDialog:        ()                                              => {dispatch(closeDialog())}
}}

const styles = theme => ({
    paperGrey: {        
        margin: 0,
        padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px `,
        backgroundColor:'#fefefe'
    }, 
    addPart: {
        display: 'flex',
        alignItems: 'center',
        justify: 'center',
        padding: theme.spacing(1),
    },
});


export default connect(null, mapDispatchToProps)( withStyles(styles)(SubAssemblyCreateBuildJigs));