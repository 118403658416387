import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DataTable from '../../Common/DataTables/DataTable';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar'
import Typography from '@material-ui/core/Typography';

const initialState = {
    formData: {
        code:'',
        name:''
    },
    formErrors:[],
    rows : [],
    snackbarOpen: false,
    confirmationOpen: false,
    isLoading: true
}

class PartTypes extends Component{
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getPartTypeList();
    }

    submit = () => {
        API.post('/parts/numbering/partTypes', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.getPartTypeList();
            }
            this.props.scrollToTop();
        });
    }

    getPartTypeList = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/parts/numbering/partTypes/all')
            .then((result) => {
                let partTypes = _.map(result.data, (el) => {
                    return _.assign({
                        id: el.part_type_id,
                        code:  el.part_type_code,
                        name:  el.part_type_name,
                    });
                });
                this.setState({
                    ...this.state,
                    rows: partTypes,
                    isLoading: false
                });
            })
        })
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    handleConfirmationOpen = () => {
        this.setState({ confirmationOpen: true });
    };

    handleConfirmationClose = () => {
        this.setState({ confirmationOpen: false });
    };

    handleConfirmationSuccess = () => {
        this.setState({ confirmationOpen: false });
        this.submit();
    }

    render(){
        const { formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Add Part Type
                            </Typography>
                            <form noValidate autoComplete="off">
                                <TextField
                                    id="code"
                                    name="code"
                                    label="Code *"
                                    value={this.state.formData.code}
                                    error={formErrors && formErrors['code'] && true}
                                    helperText={formErrors && formErrors['code']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Name *"
                                    value={this.state.formData.name}
                                    error={formErrors && formErrors['name'] && true}
                                    helperText={formErrors && formErrors['name']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <div className='buttonRow'>
                                    <Button 
                                        onClick={this.handleConfirmationOpen}
                                        variant="contained" 
                                        color="primary" 
                                        
                                    >
                                        Add
                                    </Button>
                                </div>
                                <ConfirmationDialog 
                                    open={this.state.confirmationOpen} 
                                    success={this.handleConfirmationSuccess} 
                                    close={this.handleConfirmationClose} 
                                    title="Add A New Part Type?" 
                                    message="Are you sure you want to add a new part type?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={this.state.snackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message='You have successfully added a new part type'
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <DataTable  
                        config={{
                            key: 'id',
                            alternatingRowColours: true,
                            isLoading: this.state.isLoading
                        }}
                        columns={[
                            {
                                heading: 'Code',
                                field: rowData => rowData.code,
                                sizeToContent: true
                            },
                            {
                                heading: 'Name',
                                field: rowData => rowData.name
                            }
                        ]}
                        rows={this.state.rows}
                    />
                </Grid>  
            </Grid>     
        );
    }
}

export default PartTypes;