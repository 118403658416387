import { DialogContent, Grid, Button, DialogActions, TextField, ExpansionPanelDetails, ExpansionPanel, ExpansionPanelSummary, Typography } from '@material-ui/core';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import React from 'react';
import _ from 'lodash';
import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea from 'Components/Common/Inputs/Textarea';
import MediaTable from 'Components/Common/DataTables/MediaTable';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { connect }        from 'react-redux';
import IconHelper from 'Helpers/IconHelper';
import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';
import SelectPartWithQR from 'Components/Common/Selects/SelectPartWithQR';

const initialState = ({inspection}) => ({
    formData: {
        serialNumber: inspection.cri_sn ?? null,
        warrantyVoidLabel: inspection.cri_wvl ?? null,
        condition:  null,
        notes: inspection.cri_notes ?? null,
        outcome: inspection.cri_outcome ?? null,
        files: [],
        existingFiles: _.map(inspection.files, f => f.crif_id),
        parts: JSON.parse(inspection.cri_parts) ?? [],
        _repair: {
            part: null,
            qty: 0
        },
        _service: {
            part: null,
            qty: 0
        },
    },
    lists: {
        conditions: [],
        parts: [],
    },  
    isLoading: true
})

class EditInspectionDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }
    componentDidMount() {
        this.getLists();
    }
    getLists = () => {
        Promise.all([
            API.get('/customerReturns/defaults/returnCondition'),
            API.get('/parts/all', { params: { use: 'partSearch', withStock: true } })
        ]).then(([res1, res2]) => {
            this.setState({
                formData: {
                    ...this.state.formData,
                    condition: _.find(res1.data, r => 
                        r.crrc_condition === this.props.inspection.cri_condition
                        && r.crrc_grade === this.props.inspection.cri_condition_grade    
                    ).crrc_id ?? null,
                },
                lists: {
                    conditions: _.map(_.orderBy(res1.data, r => _.findIndex(['pass', 'warning', 'fail'], i => i === r.crrc_grade), 'asc'), r => ({
                        value: r.crrc_id,
                        label: `${r.crrc_grade === 'pass' ? 'Pass' : r.crrc_grade === 'warning' ? 'Pass With Warning' : 'Fail'} - ${r.crrc_condition}`
                    })),
                    parts: _.map(res2.data, r => ({
                        value: r.part_id,
                        label: `${r.part_number} - ${r.part_description}`,
                        part: r
                    }))
                },
                isLoading: false
            })
        })
    }
    deleteMedia = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                existingFiles: _.filter(this.state.formData.existingFiles, f => f !== e.crif_id),
            }
        });
    }
    handleSave = () => {
        this.props.handleClose();
    }
    handleUploads = (drop, name, event) => {
        let newFiles = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles = this.state.formData.files;
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))
        this.setState({
            formData: {
                ...this.state.formData,
                files: allFiles
            }
        });
    }

    clearUploads = idx => {

        let currentFiles = Array.from(this.state.formData.files);
        currentFiles.splice(idx, 1);
        if(currentFiles.length === 0) currentFiles = '';

        this.setState({
            formData:{
                ...this.state.formData,
                files: currentFiles
            }
        });
    }
    submit = () => {
        let formData = new FormData();
        _.forEach(this.state.formData, (value, key) => {
            switch (key) {
                case 'parts':
                case 'existingFiles':
                    formData.append(key, JSON.stringify(value));
                    break;
                case 'files':
                    _.forEach(value, (file, idx) => {
                        formData.append(`files[]`, file);
                    });
                    break;
                default:
                    formData.append(key, value);
                    break;
            }
        })
        API.post(`/customerReturns/returns/${this.props.crId}/inspection/${this.props.inspection.cri_id}/update`, formData).then(res => {
            this.props.handleClose();
        } )
    }
    render() {
        const { formData, lists, isLoading } = this.state;

        return (  
            <>
                <DialogContent>
                    {isLoading ? <LoadingCircle /> :
                        <Grid container spacing={1}>
                            {(formData.serialNumber || formData.warrantyVoidLabel) &&
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={(formData.serialNumber ? 'Serial Number' : 'Warranty Void Label')}
                                            value={formData.serialNumber ?? formData.warrantyVoidLabel}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    label="Condition"
                                    value={formData.condition}
                                    onChange={condition => this.setState({formData: {...formData, condition: condition?.value}})}
                                    options={lists.conditions}
                                    fullWidth
                                    noClear
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Textarea
                                    label="Notes"
                                    value={formData.notes}
                                    onChange={e => this.setState({formData: {...formData, notes: e.target.value}})}
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        Attached Files
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <MediaTable
                                            config={{ itemPerRow: 3 }}
                                            media='file_path'
                                            items={_.filter(this.props.inspection.files, f => _.includes(formData.existingFiles, f.crif_id))}
                                            fields={[
                                                {
                                                    actions: i => ([
                                                        {
                                                            name:    'Delete',
                                                            icon:    IconHelper.delete,
                                                            onClick: (e) => this.props.deployConfirmation("Are you sure you want to delete this file?", 'Delete File', ()=>this.deleteMedia(e))
                                                        }
                                                    ])
                                                }
                                            ]}
                                        />
                                    </ExpansionPanelDetails>    
                                </ExpansionPanel>
                            </Grid>
                            <Grid item xs={12}>
                                <DragFileInputMulti
                                    id="files"
                                    name="files[]"
                                    label="Upload Media (.jpg, .gif, .png, .mp4, .mov, .pdf)*"
                                    file={formData.files}
                                    type="file"
                                    emptyText='No files uploaded'
                                    onChange={this.handleUploads}
                                    cancelOnClick={this.clearUploads}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    label='Outcome *'
                                    value={formData.outcome}
                                    onChange={e => {this.setState({formData: {...formData, outcome: e.value}})}}
                                    options={[
                                        {value: 'Refund',  label: 'Credit Note'},
                                        {value: 'Replace', label: 'Replace'},
                                        {value: 'Repair',  label: 'Repair'},
                                    ]}
                                    fullWidth
                                    noClear
                                />
                            </Grid>
                            {formData.outcome === 'Repair' &&
                                <>
                                    <Grid item xs={12}
                                        style={{
                                            border: `1px solid ${colors.disabled}`,
                                            borderRadius: '5px',
                                            marginTop: '1em'
                                        }}
                                    >
                                         <Grid container spacing={2} style={{alignItems: 'center', padding: '1em'}}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} style={{alignItems: 'center', padding: '1em'}}>
                                                    <Grid item xs={12}>
                                                        <Typography variant='body1' style={{fontWeight: 'Bold'}}>Repair Parts If Required</Typography>
                                                        <Typography variant='caption'>Include any predicted parts that will be needed to repair</Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <SelectPartWithQR
                                                            parts=      {_.filter(lists.parts, p => p.part.stockable)}
                                                            onChange=   {e => this.setState({
                                                                formData: {
                                                                    ...formData,
                                                                    _repair: {
                                                                        ...formData._repair,
                                                                        part: e?.value
                                                                    }
                                                                }
                                                            })}
                                                            value={formData._repair.part}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} style={{paddingBottom: 16}}>
                                                        <TextField
                                                            label='Qty *'
                                                            value={formData._repair.qty}
                                                            onChange=   {e => this.setState({
                                                                formData: {
                                                                    ...formData,
                                                                    _repair: {
                                                                        ...formData._repair,
                                                                        qty: parseFloat(e.target.value)
                                                                    }
                                                                }
                                                            })}
                                                            type='number'
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Button
                                                            color='primary'
                                                            variant='contained'
                                                            fullWidth
                                                            disabled={!formData._repair.part || !formData._repair.qty || formData._repair.qty <= 0 }
                                                            onClick={() => 
                                                                this.setState({
                                                                    formData: {
                                                                        ...formData,
                                                                        parts: [...formData.parts, formData._repair], _repair: {...formData._repair, part: null, qty: 0}
                                                                    }
                                                                })
                                                            }
                                                        >Add</Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {_.filter(formData.parts, i => _.find(lists.parts, p => p.value === i.part && p.part.stockable)).length > 0 &&
                                                            <Grid item xs={12}>
                                                                <Grid container style={{alignItems: 'center'}}>
                                                                    <Grid item xs={9}>
                                                                        <Typography variant='body1'>Repair Parts</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Typography variant='body1'>Qty</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1}></Grid>
                                                                    {_.map(formData.parts, (p,pIdx) => {
                                                                        let part = _.find(lists.parts, {value: p.part});
                                                                        if (part.part.stockable){
                                                                            return (
                                                                                <>
                                                                                    <Grid item xs={9}>
                                                                                        <Typography variant='body2'>{_.find(lists.parts, {value: p.part}).label}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={2}>
                                                                                        <Typography variant='body2'>{p.qty}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={1}>
                                                                                        <AllIcon
                                                                                            icon={IconHelper.delete}
                                                                                            onClick={() => this.setState({
                                                                                                formData: {
                                                                                                    ...formData,
                                                                                                    parts: formData.parts.filter((rp, rpIdx) => rpIdx !== pIdx)
                                                                                                }
                                                                                            })}
                                                                                        />
                                                                                    </Grid>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </Grid> 
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}
                                        style={{
                                            border: `1px solid ${colors.disabled}`,
                                            borderRadius: '5px',
                                            marginTop: '1em'
                                        }}
                                    >
                                        <Grid container spacing={2} style={{alignItems: 'center', padding: '1em'}}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2} style={{alignItems: 'center', padding: '1em'}}>
                                                    <Grid item xs={12}>
                                                        <Typography variant='body1' style={{fontWeight: 'Bold'}}>Repair Services If Required</Typography>
                                                        <Typography variant='caption'>Include any predicted labour time and delivery</Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <SelectPartWithQR
                                                            parts=      {_.filter(lists.parts, p => !p.part.stockable)}
                                                            onChange=   {e => this.setState({
                                                                formData: {
                                                                    ...formData,
                                                                    _service: {
                                                                        ...formData._service,
                                                                        part: e?.value
                                                                    }
                                                                }
                                                            })}
                                                            value={formData._service.part}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} style={{paddingBottom: 16}}>
                                                        <TextField
                                                            label='Qty *'
                                                            value={formData._service.qty}
                                                            onChange=   {e => this.setState({
                                                                formData: {
                                                                    ...formData,
                                                                    _service: {
                                                                        ...formData._service,
                                                                        qty: parseFloat(e.target.value)
                                                                    }
                                                                }
                                                            })}
                                                            type='number'
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Button
                                                            color='primary'
                                                            variant='contained'
                                                            fullWidth
                                                            disabled={!formData._service.part || !formData._service.qty || formData._service.qty <= 0 }
                                                            onClick={() => 
                                                                this.setState({
                                                                    formData: {
                                                                        ...formData,
                                                                        parts: [...formData.parts, formData._service], _service: {...formData._service, part: null, qty: 0}
                                                                    }
                                                                })
                                                            }
                                                        >Add</Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {_.filter(formData.parts, i => _.find(lists.parts, p => p.value === i.part && !p.part.stockable)).length > 0 &&
                                                            <Grid item xs={12}>
                                                                <Grid container style={{alignItems: 'center'}}>
                                                                    <Grid item xs={9}>
                                                                        <Typography variant='body1'>Repair Services</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Typography variant='body1'>Qty</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1}></Grid>
                                                                    {_.map(formData.parts, (p,pIdx) => {
                                                                        let part = _.find(lists.parts, {value: p.part});
                                                                        if (!part.part.stockable){
                                                                            return (
                                                                                <>
                                                                                    <Grid item xs={9}>
                                                                                        <Typography variant='body2'>{_.find(lists.parts, {value: p.part}).label}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={2}>
                                                                                        <Typography variant='body2'>{p.qty}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={1}>
                                                                                        <AllIcon
                                                                                            icon={IconHelper.delete}
                                                                                            onClick={() => this.setState({
                                                                                                formData: {
                                                                                                    ...formData,
                                                                                                    parts: formData.parts.filter((rp, rpIdx) => rpIdx !== pIdx)
                                                                                                }
                                                                                            })}
                                                                                        />
                                                                                    </Grid>
                                                                                </>
                                                                            )
                                                                        }
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </Grid> 
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={this.props.handleClose}
                    >Close</Button>
                    {!isLoading &&
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={formData.files.length === 0 && formData.existingFiles.length === 0}
                            onClick={ (e) => this.props.deployConfirmation("Are you sure you want to update this inspection?", 'Update Inspection', this.submit)}
                        >Save</Button>
                    }
                </DialogActions>
            </>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    }
}

export default connect(null, mapDispatchToProps)(EditInspectionDialog);