import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#0282C6',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
            opaque: 'rgba(2,130,198,0.1)'
        },
        secondary: {
            main: '#D61F26'
        },
        // error: will use the default color
    },
    overrides: {
        MuiAvatar: {
            fallback: {
                display: 'none'
            }
        },
        MuiBadge: {
            colorSecondary: {
                position: 'absolute',
                marginTop: -10,
                marginRight: -20,
                backgroundColor: '#D61F26',
                color: '#fff',
                boxShadow: '0 0 0 2px #fff',
                animation: 'shake 10s infinite',
                transform: 'translate3d(0, 0, 0)!important',
                transformOrigin: 'initial!important',
                backfaceVisibility: 'hidden',
            },
            colorPrimary: {
                position: 'absolute',
                marginTop: -10,
                marginRight: -20,
                backgroundColor: '#0282C6',
                color: '#fff',
                boxShadow: '0 0 0 2px #fff',
                animation: 'shake 10s infinite',
                transform: 'translate3d(0, 0, 0)!important',
                transformOrigin: 'initial!important',
                backfaceVisibility: 'hidden',
            }
        },
        MuiChip: {
            avatar: {
                marginLeft: '0!important'
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
                },
                '&:after': {
                    borderBottom: '2px solid #0282C6'
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '2px solid #0282C6'
                }
            },
            colorSecondary: {
                color: '#fff',
                '&::placeholder': {
                    color: '#fff',
                    opacity: 1
                },
                '&:before': {
                    borderBottom: '1px solid rgba(255,255,255,0.5)!important'
                },
                '&:after': {
                    borderBottom: '1px solid #fff!important'
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '1px solid #fff!important'
                }
            }
        },
        MuiTableCell: {
            head: {
                padding: '12px 8px'
            },
            body: {
                padding: '12px 8px'
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                backgroundColor: '#0282C6',
                color:'white'
            },
            expandIcon: {
                color: 'white'
            }
        },
        MuiCardContent: {
            root: {
                '&:last-child': {
                    paddingBottom: 16
                }
            }
        },
        MuiList: {
            root: {
                outline: 'none'
            }
        },
        MuiTypography: {
            overline: {
                lineHeight: 'initial'
            }
        }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true, // No more ripple, on the whole application 💣!
        }
    }
});
export default theme;