import { Button, Dialog, DialogTitle, Grid, Typography } from '@material-ui/core';
import API from 'API';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import TabBar from 'Components/Common/Navigation/TabBar';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import { getCurrency, getCurrencyChange, getPercentage } from 'Helpers/ReportsHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SetCategory from './Dialogs/SetCategory';
import ShowHistory from './Sections/ShowHistory';
import ViewMonthBudget from './Sections/ViewMonthBudget';
import ViewYearBudget from './Sections/ViewYearBudget';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';

const initialState = (props) => ({
    year: props.year,
    data: {},
    lists: {
        partCategories: [],
    },
    isLoading: true,
    view: 'view',
    viewCategory: 'all',
    dialogs: {
        setMonth: {
            open: false,
            data: {
                year: 0,
                month: 0,
                name: '',
            },
        },
        setCategory: {
            open: false,
            data: {
                year: 0,
                month: 0,
                name: '',
            },
        },
        setYear: {
            open: false,
        },
        showHistory: {
            open: false,
        }
    },
})

const fmonths = ['October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September' ];
const currentMonth = parseInt(moment().format('M'));


class MonthlyTarget extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getYear();
    }

    componentDidUpdate(prevProps) {
        if (this.props.year !== prevProps.year) {
            this.setState(initialState(this.props),this.getYear);
        }
    }

    getYear = () => {
        this.setState({isLoading: true}, () => {
            Promise.all([
                API.get(`/parts/catagories`),
                API.get('/performance/year/' + this.state.year),
                API.get('/performance/year/' + this.state.year + '/getMonths')
            ]).then(([pc, response, months]) => {
                this.setState({
                    data: response.data,
                    isLoading: false,
                    lists: {
                        ...this.state.lists,
                        partCategories: pc.data
                    },
                    months: months.data
                })
            })
        });
    }

   
    setCategoryTarget = (rowData) => () => {
        this.setState({
            dialogs: {
                ...this.state.dialogs,
                setCategory: {
                    open: true,
                    data: {
                        year: rowData.year,
                        name: rowData.name,
                        rowData: rowData,
                        id: rowData.id,
                    },
                },
            }
        })

    }

    closeDialogs = () => () => {
        this.setState({...this.state, dialogs: initialState().dialogs});
    }

    showHistory = () => {
        this.setState({
            dialogs: {
                ...this.state.dialogs,
                showHistory: {
                    open: true,
                },
            }
        })
    }


    render() {

        const { year, dialogs, isLoading, data, view, lists, months, viewCategory } = this.state;
        const { setMonth, setCategory, showHistory } = dialogs;

        if (isLoading) {
            return <LoadingCircle />
        }
        
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TabBar
                            tabs={[
                                {
                                    label: 'View Monthly Budget',
                                    value: 'view'
                                },
                                {
                                    label: 'View Yearly Budget',
                                    value: 'yView'
                                },
                                !!(this.props.current || this.props.future) &&
                                {
                                    label: 'Set Budget',
                                    value: 'set'
                                },
                                data && data?.yt_revision > 1 &&
                                    {
                                        label: 'History',
                                        value: 'history'
                                    }
                            ]}
                            currentTab={view} 
                            handleTabChange={(event,view) => {this.setState({view});}}
                        />
                        
                        {/* <TabBar
                            tabs={[
                                {
                                    label: 'All',
                                    value: 'all',
                                    divider: true
                                },
                                ..._.orderBy(_.map(_.filter(lists.partCategories, c => _.find(data.months, m => _.find(m.part_categories, pc => parseInt(pc.tympc_pc_id) === parseInt(c.pc_id)))),
                                m => ({
                                    label: m.pc_name,
                                    value: m.pc_id
                                })), ['label'], ['asc'])
                            ]}
                            currentTab={viewCategory} 
                            handleTabChange={(event,viewCategory) => {this.setState({viewCategory});}}
                        /> */}
                    </Grid>
                    {(view === 'view' || view === 'yView') &&
                        <Grid item xs={12} style={{padding: '2em', paddingTop: '1em', paddingBottom: 0}}>
                            <PaddedPaper >
                                <Grid container spacing={2}>
                                    <Grid item xs={5}>
                                        <AutoCompleteSelect
                                            options={
                                                [
                                                    {
                                                        label: 'All',
                                                        value: 'all',
                                                    },
                                                    ..._.orderBy(_.map(_.filter(lists?.partCategories, c => _.find(data?.months, m => _.find(m.part_categories, pc => parseInt(pc.tympc_pc_id) === parseInt(c.pc_id)))),
                                                    m => ({
                                                        label: m.pc_name,
                                                        value: m.pc_id
                                                    })), ['label'], ['asc'])
                                                ]
                                            }
                                            disableSort
                                            value={viewCategory}
                                            noClear
                                            onChange={({value}) => {this.setState({viewCategory: value});}}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Button
                                            variant="outlined"
                                            onClick={()=>this.setState({viewCategory: 'all'})}
                                        ><AllIcon icon={IconHelper.refresh}/> Reset Filter</Button>
                                    </Grid>
                                </ Grid>
                            </PaddedPaper>
                        </Grid>
                    }
                    <Grid item xs={12} style={{padding: '2em', paddingTop: '1em'}}>
                        <Grid container spacing={2}>
                            {view === 'history' &&
                                <Grid item xs={12}> 
                                    <ShowHistory year={year} />
                                </Grid>
                            }
                            {(view === 'view') &&
                                <>
                                    <ViewMonthBudget
                                        year={year}
                                        data={data}
                                        months={months}
                                        current={this.props.current}
                                        cid={viewCategory}
                                    />
                                </>
                            }
                            {(view === 'yView') &&
                                <>
                                    <ViewYearBudget
                                        year={year}
                                        data={data}
                                        months={months}
                                        current={this.props.current}
                                        cid={viewCategory}
                                    />
                                </>
                            }
                            {view === 'set' &&
                                <Grid item xs={12}>
                                    <CiDataTable
                                        config={{
                                            key: 'name',
                                            style: i => ({
                                                backgroundColor: (!i.set) && colors.importantInfo
                                            })
                                        }}
                                        rows={
                                            _.map(_.orderBy(lists.partCategories, 'pc_name', 'asc'), m => {
                                                let budget = 0, cost = 0, month = 0, set = 0;
                                                if (data?.months){
                                                    _.each(data.months, i => {
                                                        let _m = _.find(i.part_categories, j => parseInt(j.tympc_pc_id) === parseInt(m.pc_id));
                                                        if (_m) {
                                                            budget += parseFloat(_m.tympc_budget);
                                                            cost += parseFloat(_m.tympc_cost);
                                                            month++;
                                                        }
                                                    });
                                                }

                                                return ({
                                                    name:   m.pc_name,
                                                    months: `${month} / 12`,
                                                    budget: parseFloat(budget),
                                                    cost: parseFloat(cost),
                                                    year: year,
                                                    id: m.pc_id,
                                                    set: month > 0,
                                                })
                                            })
                                        }
                                        columns={[
                                            {
                                                heading: 'Part Category',
                                                field: 'name',
                                            },
                                            {
                                                heading: 'Budget Set',
                                                field: 'set',
                                                alignment: 'center',
                                                fieldFormat: 'boolean'
                                            },
                                            {
                                                heading: 'Sales',
                                                field: 'budget',
                                                fieldFormat: 'currency',
                                            },
                                            {
                                                heading: '% of Total',
                                                field: i => data?.yt_budget ? getPercentage(i.budget, data?.yt_budget, 2) : '-',
                                            },
                                            {
                                                heading: 'Cost',
                                                field: 'cost',
                                                fieldFormat: 'currency',
                                            },
                                            {
                                                heading: '% of Total',
                                                field: i => data?.yt_cost ? getPercentage(i.cost, data?.yt_cost, 2) : '-',
                                            },
                                            {
                                                heading: 'Margin £',
                                                field: i => i.budget - i.cost,
                                                fieldFormat: 'currency',
                                            },
                                            {
                                                heading: '% of Total',
                                                field: i => (data?.yt_cost || data?.yt_budget ) ? getPercentage(i.budget - i.cost, data?.yt_budget - data?.yt_cost, 2) : '-',
                                            },
                                            {
                                                heading: 'Margin %',
                                                field: i => getPercentage(i.budget - i.cost, i.budget, 2),
                                            },
                                            {
                                                heading: 'Set Budget',
                                                field: rowData =>  
                                                
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={
                                                                view === 'm' ?
                                                                this.setMonthTarget(rowData) :
                                                                this.setCategoryTarget(rowData)
                                                            }
                                                            fullWidth
                                                        >
                                                            {rowData.set ? 'Update' : 'Set'}
                                                        </Button>,
                                            }
                                        ]}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {setCategory.open && 
                    <Dialog
                        open={setCategory.open}
                        maxWidth="lg"
                        fullWidth
                    >
                        <DialogTitle
                            style={{
                                backgroundColor: colors.primary,
                                color: colors.white
                            }}
                        >Set Part Category Budget - {setCategory.data.name} </DialogTitle>
                        <SetCategory 
                            year={setCategory.data.year}
                            id={setCategory.data.id}
                            onClose={(update=0) => this.setState({dialogs: {...this.state.dialogs, setCategory: {open: false}}}, () => update && this.getYear())}
                        />
                    </Dialog>
                }
            </>
        )
    }
}

export default MonthlyTarget;

