import { Grid, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import PaddedPaper from 'Components/Common/Paper/PaddedPaper'
import Textarea from 'Components/Common/Inputs/Textarea'
import MediaTable from 'Components/Common/DataTables/MediaTable'
import _ from 'lodash'
import API from 'API'
import CiDataTable from 'Components/Common/DataTables/CiDataTable'
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle'
import React from 'react'
import AllIcon from 'Components/Common/Icons/AllIcon'
import IconHelper from 'Helpers/IconHelper'
import { colors } from 'Helpers/ColourHelper'
import { clenyDate } from 'Helpers/TimeHelper'


const initialState = () => ({
    repairGroup: {},
    isLoading: true,
})

class RepairOrder extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState()
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({ isLoading: true }, () => {
            API.get(`/customerReturns/repairOrders/${this.props.cq.repair.rog_id}`).then(res => {
                this.setState({ 
                    repairGroup: res.data,
                    isLoading: false 
                });
            });
        })
    }

    render() {

        const { repairGroup, isLoading } = this.state

        if (isLoading) return (
            <LoadingCircle />
        )

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'ro_id',
                            dropRow: {
                                droppable: i => i.ro_status !== 'Pending' && ( i.parts.length > 0 || i.notes.length > 0 ),
                                columns: [
                                    {
                                        colSpan: 5,
                                        field: i => {
                                            const inspection = _.find(this.state.inspections, {cri_id: i.ro_origin_id});
                                            return (
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper
                                                                    style={{
                                                                        backgroundColor: i.ro_status === 'Write Off' ? colors.red : 
                                                                            ( i.ro_status === 'Complete' ? colors.green : colors.orange),
                                                                        paddingTop: 8,
                                                                        paddingBottom: 8,
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant='body1'
                                                                        style={{
                                                                            color: colors.white,
                                                                        }}
                                                                    >
                                                                        Status: {i.ro_status}
                                                                    </Typography>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {inspection &&
                                                                    <ExpansionPanel>
                                                                        <ExpansionPanelSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                        >
                                                                            Pre-Repair/Inspection    
                                                                        </ExpansionPanelSummary>
                                                                        <ExpansionPanelDetails>
                                                                            <Grid container spacing={1}>
                                                                                <Grid item xs={12}>
                                                                                    <Textarea
                                                                                        label='Condition'
                                                                                        value={`${inspection.cri_condition_grade} - ${inspection.cri_condition}`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Textarea
                                                                                        label='Notes'
                                                                                        value={inspection.cri_notes}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <MediaTable
                                                                                        media='file_path'
                                                                                        config={{
                                                                                            key: 'crif_id',
                                                                                            itemPerRow: 3,
                                                                                        }}
                                                                                        items={inspection.files}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </ExpansionPanelDetails>
                                                                    </ExpansionPanel>
                                                                }
                                                                {
                                                                    (i.parts.length > 0 ||_.filter(i.notes, {ron_type : "Note"}).length > 0)  &&
                                                                        <ExpansionPanel>
                                                                            <ExpansionPanelSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                            >
                                                                                Repair
                                                                            </ExpansionPanelSummary>
                                                                            <ExpansionPanelDetails>
                                                                                <Grid container spacing={1}>
                                                                                    {i.parts.length > 0 &&
                                                                                        <Grid item xs={12}>
                                                                                            <CiDataTable
                                                                                                config={{
                                                                                                    key: 'rop_id'
                                                                                                }}
                                                                                                columns={[
                                                                                                    {
                                                                                                        heading: '',
                                                                                                        field: 'part.default_image.thumbnail_file_path',
                                                                                                        fieldFormat: 'image',
                                                                                                        sizeToContent: true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading: 'Part',
                                                                                                        field: i =>
                                                                                                            <Grid container >
                                                                                                                <Grid item xs={12}>
                                                                                                                    <Typography variant='body1'>{i.part.part_number}</Typography>
                                                                                                                </Grid>
                                                                                                                <Grid item xs={12}>
                                                                                                                    <Typography variant='body2'>{i.part.part_description}</Typography>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                    },
                                                                                                    {
                                                                                                        heading: 'Quantity',
                                                                                                        field: 'rop_qty',
                                                                                                        sizeToContent: true,
                                                                                                        fieldFormat: 'decimal:2',
                                                                                                    }
                                                                                                ]}
                                                                                                rows={i.parts}
                                                                                            />
                                                                                        </Grid>
                                                                                    }
                                                                                    {_.filter(i.notes, {ron_type : "Note"}).length > 0 &&
                                                                                        <Grid item xs={12}>
                                                                                            <ExpansionPanel>
                                                                                                <ExpansionPanelSummary
                                                                                                    expandIcon={<ExpandMoreIcon />}
                                                                                                >
                                                                                                    Notes
                                                                                                </ExpansionPanelSummary>
                                                                                                <ExpansionPanelDetails>
                                                                                                    <Grid container spacing={1}>
                                                                                                        {_.map(_.filter(i.notes, {ron_type : "Note"}), j => 
                                                                                                            <Grid item xs={12} key={j.ron_id}
                                                                                                                style={{
                                                                                                                    borderBottom: `1px solid ${colors.disabled}`,
                                                                                                                }}
                                                                                                            >
                                                                                                                <Grid container>
                                                                                                                    <Grid item xs={12}>
                                                                                                                        <Typography variant='caption'>{j.ron_staff} - {clenyDate(j.ron_datetime)}</Typography>
                                                                                                                    </Grid>
                                                                                                                    <Grid item xs={12}>
                                                                                                                        <Typography variant='body2' style={{whiteSpace: 'pre-wrap'}}>{j.ron_note}</Typography>
                                                                                                                    </Grid>
                                                                                                                    {j.files.length > 0 &&
                                                                                                                        <Grid item xs={12} style={{marginBottom: '0.5em'}}>
                                                                                                                            <MediaTable
                                                                                                                                config={{ 
                                                                                                                                    itemPerRow: 3,
                                                                                                                                    mobileItemPerRow: 3,
                                                                                                                                }}
                                                                                                                                media='file_path'
                                                                                                                                items={j.files}
                                                                                                                            />
                                                                                                                        </Grid>
                                                                                                                    }
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        )}
                                                                                                    </Grid>
                                                                                                </ExpansionPanelDetails>
                                                                                            </ExpansionPanel>
                                                                                        </Grid>
                                                                                    }
                                                                                </Grid>
                                                                            </ExpansionPanelDetails>
                                                                        </ExpansionPanel>
                                                                }
                                                                {i.ro_status === 'Write Off' &&
                                                                    <ExpansionPanel>
                                                                        <ExpansionPanelSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                        >
                                                                            Write Off  
                                                                        </ExpansionPanelSummary>
                                                                        <ExpansionPanelDetails>
                                                                            <Grid container spacing={1}>
                                                                                <Grid item xs={12}>
                                                                                    <Textarea
                                                                                        label='Reason'
                                                                                        value={i.write_off.rowo_reason}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Textarea
                                                                                        label='Notes'
                                                                                        value={i.write_off.rowo_notes}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    {i.write_off.files.length > 0 &&
                                                                                        <MediaTable
                                                                                            media='file_url'
                                                                                            config={{
                                                                                                key: 'rowof_id',
                                                                                                itemPerRow: 3,
                                                                                            }}
                                                                                            items={i.write_off.files}
                                                                                        />
                                                                                    }
                                                                                </Grid>
                                                                                {i.write_off.parts_used.length > 0 &&
                                                                                    <Grid item xs={12}>
                                                                                        {
                                                                                            _.filter(i.write_off.parts_used, j => j.part.stockable).length > 0 &&
                                                                                                <ExpansionPanel>
                                                                                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                                                        Parts Used
                                                                                                    </ExpansionPanelSummary>
                                                                                                    <ExpansionPanelDetails>
                                                                                                        <CiDataTable
                                                                                                            config={{
                                                                                                                key: 'rowopu_id'
                                                                                                            }}
                                                                                                            columns={[
                                                                                                                {
                                                                                                                    heading: '',
                                                                                                                    field: 'part.default_image.thumbnail_file_path',
                                                                                                                    fieldFormat: 'image',
                                                                                                                    sizeToContent: true,
                                                                                                                },
                                                                                                                {
                                                                                                                    heading: 'Part',
                                                                                                                    field: i =>
                                                                                                                        <Grid container >
                                                                                                                            <Grid item xs={12}>
                                                                                                                                <Typography variant='body1'>{i.part.part_number}</Typography>
                                                                                                                            </Grid>
                                                                                                                            <Grid item xs={12}>
                                                                                                                                <Typography variant='body2'>{i.part.part_description}</Typography>
                                                                                                                            </Grid>
                                                                                                                        </Grid>
                                                                                                                },
                                                                                                                {
                                                                                                                    heading: 'Quantity',
                                                                                                                    field: 'rowopu_qty',
                                                                                                                    sizeToContent: true,
                                                                                                                    fieldFormat: 'decimal:2',
                                                                                                                }
                                                                                                            ]}
                                                                                                            rows={_.filter(i.write_off.parts_used, j => j.part.stockable)}
                                                                                                        />
                                                                                                    </ExpansionPanelDetails>
                                                                                                </ExpansionPanel>
                                                                                        }
                                                                                        {
                                                                                            _.filter(i.write_off.parts_used, j => !j.part.stockable).length > 0 &&
                                                                                                <ExpansionPanel>
                                                                                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                                                        Services Used
                                                                                                    </ExpansionPanelSummary>
                                                                                                    <ExpansionPanelDetails>
                                                                                                        <CiDataTable
                                                                                                            config={{
                                                                                                                key: 'rowopu_id'
                                                                                                            }}
                                                                                                            columns={[
                                                                                                                {
                                                                                                                    heading: '',
                                                                                                                    field: 'part.default_image.thumbnail_file_path',
                                                                                                                    fieldFormat: 'image',
                                                                                                                    sizeToContent: true,
                                                                                                                },
                                                                                                                {
                                                                                                                    heading: 'Part',
                                                                                                                    field: i =>
                                                                                                                        <Grid container >
                                                                                                                            <Grid item xs={12}>
                                                                                                                                <Typography variant='body1'>{i.part.part_number}</Typography>
                                                                                                                            </Grid>
                                                                                                                            <Grid item xs={12}>
                                                                                                                                <Typography variant='body2'>{i.part.part_description}</Typography>
                                                                                                                            </Grid>
                                                                                                                        </Grid>
                                                                                                                },
                                                                                                                {
                                                                                                                    heading: 'Quantity',
                                                                                                                    field: 'rowopu_qty',
                                                                                                                    sizeToContent: true,
                                                                                                                    fieldFormat: 'decimal:2',
                                                                                                                }
                                                                                                            ]}
                                                                                                            rows={_.filter(i.write_off.parts_used, j => !j.part.stockable)}
                                                                                                        />
                                                                                                    </ExpansionPanelDetails>
                                                                                                </ExpansionPanel>
                                                                                        }
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>
                                                                        </ExpansionPanelDetails>
                                                                    </ExpansionPanel>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    }
                                ]
                            }
                        }}
                        rows={repairGroup.repair_orders}
                        columns={[
                            {
                                heading: '',
                                field: 'part.default_image.file_path',
                                fieldFormat: 'image',
                                sizeToContent: true,
                                alignment: 'center',
                            },
                            {
                                heading: 'Part Number',
                                field: 'part.part_number',
                                sizeToContent: true,
                            },
                            {
                                heading: 'Part Description',
                                field: i => 
                                    <>
                                        <Typography variant='body2'>{i.part.part_description}</Typography>
                                        {( (i.ro_sn) !== '[]' ) && <Typography variant='caption '>{(i.ro_sn)}</Typography> }
                                        {( (i.ro_wvl) !== '[]' ) && <Typography variant='caption '>{(i.ro_wvl)}</Typography> }
                                    </>,
                                sizeToContent: true,
                            },
                            {
                                heading: 'Reference',
                                field: 'ro_reference'
                            },
                            {
                                heading: 'Status',
                                field: i => <AllIcon
                                    icon={IconHelper[
                                        (
                                            (i.ro_status === 'Write Off' && 'broken') ||
                                            (i.ro_status === 'Complete' && 'true') ||
                                            (i.ro_status === 'In Progress' && 'dash') ||
                                            (i.ro_status === 'Pending' && 'timer')
                                        )
                                    ]}
                                    color={
                                        (
                                            (i.ro_status === 'Write Off' && colors.red) ||
                                            (i.ro_status === 'Complete' && colors.green) ||
                                            (i.ro_status === 'In Progress' && colors.orange) ||
                                            (i.ro_status === 'Pending' && colors.orange)
                                        )
                                    }
                                    tooltip={i.ro_status}
                                    noMargin
                                />,
                                sizeToContent: true,
                                alignment: 'center',
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default RepairOrder