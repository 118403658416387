import React        from 'react';
import _            from 'lodash';
import moment       from 'moment';
import { connect }  from 'react-redux';
import { Link }     from 'react-router-dom';
import API          from 'API';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { pdfDataFromBase64 }      from 'Helpers/PDFHelper';
import icons                      from 'Helpers/IconHelper'; 

import { handleChange } from 'Functions/FormFunctions';

import { List, ListItem, Button, Grid, Table, TableHead, TableBody, TableRow, TableCell, Typography, DialogActions, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import DataTable                   from 'Components/Common/DataTables/CiDataTable';
import FALightIcon                 from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon                 from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import Image                       from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle               from 'Components/Common/LoadingCircle/LoadingCircle';
import SelectSerialAndWarrantyList from 'Components/Sales/Order/Picking/SelectSerialAndWarrantyList';
import QrScanWrapper               from 'Components/Warehouse/Common/Inputs/QrScanWrapper';
import ConfirmationDialog          from 'Components/Common/Dialogs/ConfirmationDialog';
import NumberField                 from 'Components/Common/Inputs/NumberField';

import { deploySnackBar }                  from 'Actions/SnackBar/SnackBar';
import { playSound }                       from 'Actions/Sounds/Sounds';
import { printA4, printLabel, printMulti } from 'Actions/Print/Print';

const initialState = {
    currentPick: 0,
    step: 'scanPart',
    formData: {
        qty : '',
        serialNumbers: [],
        warrantyVoid: [],
        manualNumbers: [],
        pickStatus: ''
    },
    itemsPicked: 0,
    timeElapsed: 0,
    lists: {
        serialNumberList: [],
        completedPicks: []
    },
    despId: null,
    formErrors: {},
    isLoading: 0,
    showManualDuplicate: 0,
    forceManuel: 0,
    greenaware: [],
    scanData:'',
    confirmationDialog: {
        open:    false,
        title:   '',
        message: '',
        success: null
    },
    pickedParts: [],
    pickingDocuments: [],
    showAdditional: false,
    showPartLabels: false,
    partsPicked:    [],
    printingDialog: null,
}

class PickingDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.handleChange = handleChange.bind(this)
        this.scanning = true;
        this.timeout = null;
    }

    componentDidMount = () => {
        this.setState({
            ...this.state,
            currentPick: this.props.startIndex
        });
    }

    componentWillUnmount = () => {
        clearTimeout(this.timeout);
    }

    _handleKeyDown = (e) => {
        if (this.state.step === 'selectWarranty' || this.state.step === 'selectSerial') return;
        if (e.keyCode === 0) return;
        e.preventDefault();
        if (this.state.step === 'enterQty'){
            if (_.isInteger(parseInt(e.key)) || e.key === '.'){
                this.setState({
                    formData: {
                        ...this.state.formData,
                        qty: this.state.formData.qty + e.key 
                    }
                })
            } else if (e.key === 'Backspace') {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        qty: this.state.formData.qty.slice(0, -1)
                    }
                })
            } else if (e.key === 'Enter') {
                this.handleEnterQty();
            }
        }
        switch(e.key) {
            case ' ':
                if (this.state.step === 'complete' && this.state.pickingDocuments.length > 0){
                    this.setState({ showAdditional: true });
                }
            break;
            case 'Tab':
                if (this.state.step === 'complete'){
                    this.setState({ showPartLabels: true });
                }
            break;
            case 'F1':
                if (this.state.confirmationDialog.open) {
                    this.handleConfirmationClose();
                } else {
                    switch(this.state.step){
                        case 'confirmQty':
                            this.handleGoBack();
                        break;
                        case 'complete':
                            this.handlePrintGreenaware();
                        break;
                        default:
                            this.handleSkip();
                        break;
                    }
                }
                break;
            case 'F2':
                if (this.state.showAdditional || this.state.showPartLabels) {
                   // this.handlePrintAllAdditional();
                } else if (this.state.confirmationDialog.open) {
                    this.state.confirmationDialog.success();
                    this.handleConfirmationClose();
                } else {
                    switch(this.state.step){
                        case 'confirmQty':
                            this.handlePickItem();
                        break;
                        case 'complete':
                            this.handlePrint();
                        break;
                        default:
                            this.handleNoPick();
                        break;
                    }
                }
                break;
            case 'F3':
                if (this.state.showAdditional || this.state.showPartLabels) {
                    this.setState({ showAdditional: false, showPartLabels: false });
                } else if (this.state.step === 'complete') {
                    this.props.close();
                } else {
                    this.handleLabelPrint();
                }
                break;
            case 'Tab':
                if (this.state.step === 'complete'){
                    this.setState({ showPartLabels: true });
                }
                break;
        }

	}

    handleLabelPrint = () => {
        let item = this.props.pickingList[this.state.currentPick];
        this.printPartLabel(item.od_part_id, null)();
    }

    deployConfirmation = (message, title, success) => {
        this.setState({
            confirmationDialog: {
                open: true,
                message: message,
                title: title,
                success: success
            }
        })
    }

    handleConfirmationClose = () => {
        this.setState({
            confirmationDialog: {
                ...this.state.confirmationDialog,
                open: false
            }
        })
    }

    handleSkip = () => {
        this.deployConfirmation(
            "Are you sure you want to skip this pick?",
            "Skip Pick",
            this.skip
        );
    }

    skip = () => {
        this.setState({
            ...this.state,
            confirmationDialog: initialState.confirmationDialog,
            formData: {
                ...this.state.formData,
                qty: 0
            }
        }, 
        () => {
            this.handlePickSuccess('Skipped');
        })
    }

    handleNoPick = () => {
        this.deployConfirmation(
            "Are you sure this item doesn't require picking?", 
            "No Pick Required", 
            () => this.handlePickSuccess('Not Required')
        );
    }
    
    handleEnter = e => {
        if(e.key === 'Enter' && this.state.formData.qty !== '') {
            this.handleEnterQty();
        }
    }

    handleEnterQty = () => {
        this.setState({
            ...this.state,
            isLoading: 1
        },
        () => {
            // if the quantity picked equals amount required
            let amount =  (this.props.pickingList[this.state.currentPick].part.part_req_serial_number === 'Yes' && (this.props.pickingList[this.state.currentPick].part.part_model_type === null || this.props.pickingList[this.state.currentPick].part.part_model_type?.issued_serial_number?.length < parseInt(this.props.pickingList[this.state.currentPick].pick_detail.opd_quantity_to_follow))) ?
                            parseFloat( this.props.pickingList[this.state.currentPick].part.part_model_type?.issued_serial_number?.length) :
                            parseFloat(this.props.pickingList[this.state.currentPick].pick_detail.opd_quantity_to_follow);

            amount = parseFloat(amount) > parseFloat(this.props.pickingList[this.state.currentPick].pick_detail.opd_quantity_to_follow) ?  parseFloat(this.props.pickingList[this.state.currentPick].pick_detail.opd_quantity_to_follow) : parseFloat(amount);

            if(parseFloat(this.state.formData.qty) === parseFloat(amount)) {

                this.handlePickItem();
    
            // if the quantity picked is greater than amoutn required, (api will return errors if true)
            } else if (parseFloat(this.state.formData.qty) > parseFloat(amount) || parseFloat(this.state.formData.qty) < 0) {

                // handle pick success will return formErrors array from api
                this.handlePickSuccess();
    
            // if quantity is less user will need to confirm quantity is less
            } else {

                this.setState({
                    ...this.state,
                    step: 'confirmQty',
                    isLoading: 0
                });

            }
        });
    }

    handleGoBack = () => {
        this.setState({
            ...this.state,
            step: 'enterQty'
        });
    }

    // enter serials if req, enter warranty labels if req, handle next item
    handlePickItem = () => {
        // if 0 quantity no serial numbers or warranty labels need to be entered,
        // handle next item
        if(parseFloat(this.state.formData.qty) === 0) {
            this.handlePickSuccess('Skipped');
            return;
        }

        // does item require serial numbers?
        if(this.props.pickingList[this.state.currentPick].part.part_req_serial_number === 'Yes' || this.props.pickingList[this.state.currentPick].part.part_req_serial_number === 'Manual') {

            let serialNumberList = [], 
                serialNumbers    = [],
                manualNumbers    = [];

            // generate serial form data
            for(let i = 0; i < this.state.formData.qty; i++) {
                if (this.props.pickingList[this.state.currentPick].part.part_req_serial_number === 'Manual'){
                    manualNumbers.push('')
                } else {
                    serialNumbers.push('');
                }
            }

            this.setState({
                ...this.state,
                step: 'selectSerial',
                lists: {
                    ...this.state.lists,
                    serialNumberList
                },
                formData: {
                    ...this.state.formData,
                    serialNumbers,
                    manualNumbers
                },
                isLoading: 0,
                partsPicked: _.uniq([...this.state.partsPicked, this.props.pickingList[this.state.currentPick].od_part_id])
            }, ()=>{
                if (this.props.pickingList[this.state.currentPick]?.part?.part_model_type?.pmt_id) {
                    API.get(`/parts/serialNumbers/modelTypes/${this.props.pickingList[this.state.currentPick].part.part_model_type.pmt_id}/byStatus`, { params: { status: 'Issued' } })
                    .then(serialRes => {
                        if(serialRes.data){
                            serialRes.data.forEach(el => {
                                serialNumberList.push({
                                    value: el.psn_id,
                                    label: el.psn_serial_number
                                });
                            });
                            this.setState({
                                lists: {
                                    ...this.state.lists,
                                    serialNumberList
                                },
                            });
                        }
                    });
                }
            });    

        // does item require warranty void labels
        } else if(this.props.pickingList[this.state.currentPick].part.part_warranty_void_label === 'Yes') {

            let warrantyVoid = [];

            // generate warranty form data
            for(let i = 0; i < this.state.formData.qty; i++) {
                warrantyVoid.push('');
            }

            this.setState({
                ...this.state,
                step: 'selectWarranty',
                formData: {
                    ...this.state.formData,
                    warrantyVoid
                },
                lists: {
                    ...this.state.lists,
                    serialNumbersList: []
                },
                isLoading: 0
            });

        // pick success
        } else {

            this.handlePickSuccess();

        }
    }

    handleManualSerialChange = (i,value) => {
        let manualNumbers = this.state.formData.manualNumbers;
        manualNumbers[i] = value;

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                manualNumbers
            },
        });
    }

    handleSerialChange = (i, e) => {
        let serialNumbers    = this.state.formData.serialNumbers,
            serialNumberList = this.state.lists.serialNumberList;

        if(e) {
            // old selected serial needs undisabling from list if reselecting without clearing
            if(serialNumbers[i]) {
                let oldSerialIdx = _.findIndex(serialNumberList, {value: serialNumbers[i]});

                serialNumberList[oldSerialIdx] = {
                    ...serialNumberList[oldSerialIdx],
                    disabled: false
                }
            }

            let serialIdx = _.findIndex(serialNumberList, {value: e.value});

            serialNumberList[serialIdx] = {
                ...serialNumberList[serialIdx],
                disabled: true
            }

            serialNumbers[i] = e.value;

        } else {
            //undisable cleared serial number from list
            let serialVal = serialNumbers[i],
                serialIdx = _.findIndex(serialNumberList, {value: serialVal});

            serialNumberList[serialIdx] = {
                ...serialNumberList[serialIdx],
                disabled: false
            }

            serialNumbers[i] = '';
        }

        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                serialNumbers
            },
            lists: {
                ...this.state.lists,
                serialNumberList
            }
        });
    }

    handleWarrantyChange = (i, e) => {
        let warrantyVoid = this.state.formData.warrantyVoid;

        warrantyVoid[i] = e.target.value;

        this.setState({
            ...this.state,
            partsPicked: _.uniq([...this.state.partsPicked, this.props.pickingList[this.state.currentPick].od_part_id]),
            formData: {
                ...this.state.formData,
                warrantyVoid
            }
        });
    }

    handleStorePickedPart = part_id => {
        if (!this.state.pickedParts.includes(part_id) && parseInt(this.props.pickingList[this.state.currentPick].part.picking_documents_count) > 0) {
            API.get(`/parts/${part_id}/pickingDocuments`)
            .then((result) => {
                this.setState({
                    ...this.state,
                    pickedParts: _.uniq([...this.state.pickedParts, part_id]),
                    pickingDocuments: [...this.state.pickingDocuments, ...result.data]
                });
            })
        }
    }

    // this will be passed via the select serial and warranty dialog 
    handlePickSuccess = (pickStatus = 'Picked') => {
        if (pickStatus !== 'Skipped') {
            this.handleStorePickedPart(this.props.pickingList[this.state.currentPick].od_part_id)
        }
        this.setState({
            ...this.state,
            isLoading: 1
        },
        () => {
            let manualNumbers = [];
            _.forEach(this.state.formData.manualNumbers, n => {
                manualNumbers.push(n.trim());
            } )

            let formData = {
                ...this.state.formData,
                qty: pickStatus != 'Picked' ? 0 : this.state.formData.qty,
                pickStatus,
                forceManuel: this.state.forceManuel,
                manualNumbers
            };
            API.post(`/sales/orders/pick/${this.props.orderId}/${this.props.pickingList[this.state.currentPick].od_id}`, formData)
            .then(result => {
                if(result.data.errors) {
                    this.setState({
                        ...this.state,
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: 0
                    }, () => {
                        if (this.state.formErrors['manuelSerialNumber']){
                            var sn  = [];
                            _.forEach(this.state.formErrors['manuelSerialNumber'], i => {
                                if (!_.find(sn, {number: i.psn_serial_number})){
                                    sn.push({
                                        number: i.psn_serial_number,
                                        dates : [moment(i.psn_created_at_datetime).format("DD/MM/YYYY")]
                                    })
                                } else {
                                    sn[_.findIndex(sn,{number: i.psn_serial_number})].dates.push(moment(i.psn_created_at_datetime).format("DD/MM/YYYY"));
                                }
                            })
                            this.setState({
                                forceManuel:            0,
                                showManualDuplicate:    sn,
                            })
                        }
                    });                    
                } else if(result.data.success) {
    
                    this.setState({
                        ...this.state,
                        formData: initialState.formData,
                        formErrors: {},
                        forceManuel: 0,
                        confirmationDialog: initialState.confirmationDialog,
                    },
                    () => {
                        this.checkGreenaware();
                        this.handleNext(this.props.pickingList[this.state.currentPick].od_id);
                    });
    
                }
            });
        });
    }

    checkGreenaware = () => {
        API.get('/customerReturns/greenaware/returns', {params: {orderId: this.props.orderId}})
        .then(res => {
            this.setState({
                greenaware: res.data
            })
        })
    }

    handleNext = () => {
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                qty: ''
            },
            isLoading: 1
        },
        () => {
            if(this.state.currentPick + 1 !== this.props.pickingList.length) {
    
                this.setState({
                    ...this.state,
                    currentPick: this.state.currentPick + 1,
                    step: 'scanPart',
                    isLoading: 0
                });
                
            } else {
    
                this.handleCompletePick();
    
            }
        });
    }

    handleCompletePick = () => {
        API.post(`/sales/orders/pick/${this.props.orderId}/complete`)
        .then(result => {
            this.checkGreenaware();
            this.setState({
                ...this.state,
                step: 'complete',
                itemsRequired: result.data.stats.items_required,
                itemsPicked: result.data.stats.total_picked,
                timeElapsed: result.data.stats.time_elapsed,
                lists: {
                    ...this.state.lists,
                    completedPicks: result.data.completedPicks
                },
                despId: result.data.despId,
                isLoading: 0
            },
            () => {
                this.props.playSound('pickComplete', 1);
            });

        });
    }

    handlePrint = () => {
        this.timeout = setTimeout(() => {
            clearTimeout(this.timeout);
            API.post(`/sales/orders/${this.props.orderId}/prevPdf`, {'type': 'delivery-note', 'id': this.state.despId})
            .then(result => {
                if(result.data) {
                    this.props.printA4(pdfDataFromBase64(result.data.pdf), 'Delivery Note');
                }
            });
        }, 500);
    }

    handlePrintGreenaware = () => {
        this.timeout = setTimeout(() => {
            clearTimeout(this.timeout);
            API.post(`/sales/orders/${this.props.orderId}/prevPdf`, {'type': 'delivery-note', 'id': this.state.despId})
            .then(result => {
                if (result.data.additional && result.data.additional.length > 0) {
                    this.props.printMulti(_.map(_.uniqBy(result.data.additional, 'pdfName'), i => ({
                        printer:  1,
                        file:     pdfDataFromBase64(i.pdf), 
                        fileName: i.pdfName
                    })));
                }
            });
        },500);
    }

    handleManualDuplicateConfirmationSuccess = () => {
        this.setState({
            forceManuel:            1,
            showManualDuplicate:    0,
        }, ()=>this.handlePickSuccess());
    }

    handleManualDuplicateConfirmationClose = () => {
        this.setState({
            forceManuel:            0,
            showManualDuplicate:    0,
        });
    }

    handleScan = scannedPartNumber => {
        if(this.state.step === 'scanPart' || this.state.step === 'wrongPart') {

            // if correct part number enter quantity
            if(scannedPartNumber === this.props.pickingList[this.state.currentPick].od_part_number) {
                
                this.setState({
                    ...this.state,
                    step: 'enterQty',
                },
                () => {
                    this.props.playSound('pickSuccess', 1);
                });

            // if not correct part number show error)
            } else if(scannedPartNumber !== this.props.pickingList[this.state.currentPick].od_part_number && scannedPartNumber !== null) {

                this.setState({
                    ...this.state, 
                    step: 'wrongPart',
                },
                () => {
                    this.props.playSound('pickFailed', 1);

                    setTimeout(() => {
                        this.setState({
                            ...this.state,
                            step: 'scanPart'
                        });
                    }, 2000);
                });

            }
            
        }
    }

    handlePrintAllAdditional = () => {
        this.timeout = setTimeout(() => {
            clearTimeout(this.timeout);
            this.props.printMulti(_.map(_.uniqBy(this.state.pickingDocuments, 'doc_title'), i => ({
                printer:  1,
                url:      i.file_url_pdf || i.file_url_original, 
                fileName: i.doc_title
            })));
        }, 500);
    }

    handlePrintAllPartLabels = () => {
        this.timeout = setTimeout(() => {
            clearTimeout(this.timeout);
            Promise.all(
                _.map(this.props.pickingList, i =>
                    API.get('/parts/' + i.part.part_id + '/stockLabel',{
                        params: {
                        type: 'partLabel'
                        }
                    })
                )
            ).then( res => {
                this.props.printMulti(_.map(res, (i, idx) => ({
                    printer:  2,
                    file:     pdfDataFromBase64(i.data.pdf), 
                    fileName: this.props.pickingList[idx].part.part_number
                })));
            });
        }, 500);
    }

    printPartLabel = (id) => () => {
        this.timeout = setTimeout(() => {
            clearTimeout(this.timeout);
            API.get('/parts/' + id + '/stockLabel',{
                params: {
                type: 'partLabel'
                }
            })
            .then(result => {
                if(result.data) {
                    this.props.printLabel(pdfDataFromBase64(result.data.pdf), result.data.pdfName);
                }
            });
        }, 500)
    }

    render() {

        const { isLoading, step, formData:{ qty }, currentPick, itemsPicked, formErrors, greenaware, confirmationDialog, showAdditional, showPartLabels } = this.state;



        const { classes } = this.props;

        if (isLoading) return (<LoadingCircle />);

        return(
            <Grid container justify="center" className={classes.root} spacing={1}>
                {(step !== 'complete' && (
                    <>
                        <Grid container item className={classes.qrRow}>
                            <Grid item>
                                <Typography variant="h6" className="float-right">
                                    Pick {currentPick + 1} of {this.props.pickingList.length}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{textAlign: 'center'}}>
                                {this.props.pickingList[currentPick].od_part_number}
                            </Typography>
                            <Typography variant="h6" style={{textAlign: 'center'}}>
                                {this.props.pickingList[currentPick].od_part_description}
                                {this.props.pickingList[currentPick].od_electrical_connection ?
                                    <Grid container justify="center" alignContent='center' spacing={3} className={classes.connectionText}>
                                        <Grid item style={{float:'right'}}><FASolidIcon icon='plug' noMargin style={{color: colors.red}}/></Grid>
                                        <Grid item style={{paddingTop:'0.75em', color: colors.red}}>
                                            <Typography variant='body1' style={{fontWeight: 'bold'}}>{`${this.props.pickingList[currentPick].od_electrical_connection} Connection`}</Typography>
                                        </Grid>
                                        <Grid item alignContent='center'><FASolidIcon icon='plug' noMargin style={{color: colors.red}}/></Grid>
                                    </Grid>: '' 
                                }
                            </Typography>
                        </Grid>
                        {(step === 'selectSerial' && (
                            <Grid item xs={12}>
                                {this.props.pickingList[currentPick].part.part_req_serial_number === 'Manual' ?
                                    <SelectSerialAndWarrantyList
                                        odId={this.props.pickingList[currentPick].od_id}
                                        formData={this.state.formData.manualNumbers}
                                        serialNumberList={this.state.lists.serialNumberList}
                                        handleChange={this.handleManualSerialChange}
                                        handlePickSuccess={this.handlePickSuccess}
                                        formErrors={formErrors}
                                        pick={this.props.pickingList[currentPick]}
                                    /> : 
                                    <SelectSerialAndWarrantyList
                                        odId={this.props.pickingList[currentPick].od_id}
                                        formData={this.state.formData.serialNumbers}
                                        serialNumberList={this.state.lists.serialNumberList}
                                        handleChange={this.handleSerialChange}
                                        handlePickSuccess={this.handlePickSuccess}
                                        formErrors={formErrors}
                                        pick={this.props.pickingList[currentPick]}
                                    />
                                }
                            </Grid> 
                        )) || (
                            (step === 'selectWarranty' && (
                                <Grid item xs={12}>
                                    <SelectSerialAndWarrantyList
                                        odId={this.props.pickingList[currentPick].od_id}
                                        formData={this.state.formData.warrantyVoid}
                                        handleChange={this.handleWarrantyChange}
                                        handlePickSuccess={this.handlePickSuccess}
                                        formErrors={formErrors}
                                        pick={this.props.pickingList[currentPick]}
                                    />
                                </Grid>
                            ))
                        ) || (
                            <>
                                <Grid item xs={12} style={{textAlign: 'center'}}>
                                    <Image src={this.props.pickingList[currentPick].part?.default_image?.file_path_base64} className={classes.img} />
                                </Grid>
                                {step !== 'confirmQty' && (
                                    <Grid item xs={12} className={classes.qtyRow}>
                                        <Typography variant="h5" component="span">
                                            Required:
                                        </Typography>
                                        <Typography variant="h4" component="span" className="pl-1">
                                            <strong>{parseFloat(this.props.pickingList[currentPick].pick_detail.opd_quantity_to_follow)}</strong>
                                        </Typography>
                                        {(this.props.pickingList[currentPick].part.part_req_serial_number === 'Yes' && (this.props.pickingList[currentPick].part.part_model_type === null || this.props.pickingList[currentPick].part.part_model_type?.issued_serial_number?.length < parseInt(this.props.pickingList[currentPick].pick_detail.opd_quantity_to_follow))) &&
                                            <>
                                                <Typography variant="h5" component="span" style={{marginLeft: '0.5em'}}>
                                                    Available:
                                                </Typography>
                                                <Typography variant="h4" component="span" className="pl-1">
                                                    <strong>{parseFloat( this.props.pickingList[currentPick].part.part_model_type?.issued_serial_number?.length)}</strong>
                                                </Typography>
                                            </>
                                        }
                                    </Grid>
                                )}
                            </>
                        )}
                        <Grid item xs={12}>
                            {(step === 'enterQty' && (
                                <Grid container spacing={1} item >
                                    <Grid item xs={12}>
                                        <NumberField
                                            id="qty"
                                            name="qty"
                                            label={`Confirm Quantity * [Enter]`}
                                            error={formErrors?.['quantity'] && true}
                                            helperText={formErrors?.['quantity']}
                                            value={qty}
                                            type="number"
                                            variant="outlined"
                                            onChange={e => this.handleChange({target: {value: e, name: 'qty'}})}
                                            margin="none"
                                            inputProps={{
                                                min: 0,
                                                max: parseFloat( this.props.pickingList[currentPick].part.part_model_type?.issued_serial_number?.length) < parseFloat(this.props.pickingList[currentPick].pick_detail.opd_quantity_to_follow) ?
                                                    parseFloat(this.props.pickingList[currentPick].pick_detail.opd_quantity_to_follow) : parseFloat( this.props.pickingList[currentPick].part.part_model_type?.issued_serial_number?.length),
                                                pattern: "\d*"
                                            }}

                                            granularity={1}
                                            disableKeyboard={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button 
                                            variant="outlined"
                                            endIcon={<FALightIcon icon="step-forward" />}
                                            className="mr-1"
                                            onClick={this.handleSkip}
                                            style={{border: '1px solid black', height: '100%'}}
                                            fullWidth
                                        >
                                            [F1] Skip
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button 
                                            variant="outlined"
                                            endIcon={<FALightIcon icon="times" />}
                                            onClick={this.handleNoPick}
                                            style={{border: '1px solid black', height: '100%'}}
                                            fullWidth
                                        >
                                            Pick Never Required [F2]
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button 
                                            variant="contained"
                                            color='primary'
                                            endIcon={<FALightIcon icon={icons.print} white />}
                                            onClick={this.handleLabelPrint}
                                            style={{border: '1px solid black', height: '100%'}}
                                            fullWidth
                                        >
                                            Print Part Labels [F3]
                                        </Button>
                                    </Grid>
                                </Grid>
                            )) || (
                                (step === 'confirmQty' && (
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12} lg={6}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className="text-center"><Typography variant="h5"><strong>Required</strong></Typography></TableCell>
                                                        <TableCell className="text-center"><Typography variant="h5"><strong>Picked</strong></Typography></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className="text-center"><Typography variant="h6">{parseFloat(this.props.pickingList[currentPick].pick_detail.opd_quantity_to_follow)}</Typography></TableCell>
                                                        <TableCell className="text-center"><Typography variant="h6">{qty}</Typography></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                        <Grid item xs={12} className="mb-1">
                                            <Typography variant="h4" style={{textAlign: 'center'}}>
                                                <strong>Quantity {parseFloat(this.props.pickingList[currentPick].pick_detail.opd_quantity_to_follow) - qty} Short?</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button 
                                                variant="contained"
                                                color="secondary"
                                                onClick={this.handleGoBack}
                                                fullWidth
                                                className={classes.largeButton}
                                            >
                                                [F1] No

                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button 
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handlePickItem}
                                                fullWidth
                                                className={classes.largeButton}
                                            >
                                                Yes [F2]
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )
                            )) || (
                                (step !== 'selectSerial' && 
                                step !== 'selectWarranty' && (
                                    <>
                                        <Typography variant="h5" className={classes.actionText}>
                                            {step === 'scanPart' && 
                                                <strong>Please Scan QR Code</strong>
                                            }
                                            {step === 'wrongPart' && 
                                                <span className={classes.errorText}>Incorrect Part Please Try Again</span>
                                            }
                                        </Typography>
                                        <Grid container spacing={2} style={{marginTop: '1em'}}>
                                            <Grid item xs={6}>
                                                <Button 
                                                    variant="outlined"
                                                    endIcon={<FALightIcon icon="step-forward" />}
                                                    className="mr-1"
                                                    onClick={this.handleSkip}
                                                    style={{border: '1px solid black', height: '100%'}}
                                                    fullWidth
                                                >
                                                    [F1] Skip
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button 
                                                    variant="outlined"
                                                    endIcon={<FALightIcon icon="times" />}
                                                    onClick={this.handleNoPick}
                                                    style={{border: '1px solid black', height: '100%'}}
                                                    fullWidth
                                                >
                                                    Pick Never Required [F2]
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button 
                                                    variant="contained"
                                                    endIcon={<FALightIcon icon={icons.print} white />}
                                                    onClick={this.handleLabelPrint}
                                                    style={{border: '1px solid black', height: '100%'}}
                                                    fullWidth
                                                    color="primary"
                                                >
                                                    Print Part Label [F3]
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                ))
                            )}
                        </Grid>
                    </>
                )) || (
                    <Grid 
                        container 
                        spacing={3} 
                        justify="center" 
                        className={`${classes.root} text-center`}
                    >
                        <Grid container item xs={12} alignItems="center" justify="center">
                            <FALightIcon 
                                icon="check-circle"
                                size="xlarge"
                                className={classes.successTick}
                            />
                            <Typography variant="h3" className="pl-2">
                                Pick Complete
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="text-center"><Typography variant="h5"><strong>Required</strong></Typography></TableCell>
                                        <TableCell className="text-center"><Typography variant="h5"><strong>Picked</strong></Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="text-center"><Typography variant="h6">{this.props.totalQty}</Typography></TableCell>
                                        <TableCell className="text-center"><Typography variant="h6">{itemsPicked}</Typography></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table> 
                        </Grid>
                        <Grid item container spacing={1} xs={12} style={{marginTop: 'auto'}}> 
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className="mr-2"
                                    fullWidth
                                    onClick={()=>this.setState({showPartLabels: true})}
                                >
                                    Print {this.props.pickingList.length} Part Labels [Tab]
                                </Button>
                            </Grid>
                            {this.state.pickingDocuments.length > 0 &&
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className="mr-2"
                                        fullWidth
                                        onClick={()=>this.setState({showAdditional: true})}
                                    >
                                        Print {_.uniqBy(this.state.pickingDocuments, 'doc_title').length} Additional Documents [_]
                                    </Button>
                                </Grid>
                            }
                            {this.state.greenaware.length > 0 &&
                                <Grid item xs={12}> 
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className="mr-2"
                                        fullWidth
                                        onClick={this.handlePrintGreenaware}
                                    >
                                        [F1] Print Greenaware Notes
                                    </Button>
                                </Grid>
                            }
                            <Grid item xs={12}> 
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className="mr-2"
                                    onClick={this.handlePrint}
                                    fullWidth
                                >
                                    [F2] Print Delivery Note
                                </Button>
                            </Grid>
                            <Grid item xs={12}> 
                                <Link to="/sales/order/picking" className='linkStyle'>
                                    <Button 
                                        fullWidth
                                        variant="contained" 
                                        color="secondary"
                                        size="large"
                                    >
                                        [F3] Finish 
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                )}
                {this.state.showManualDuplicate.length &&
                    <Dialog
                        open={this.state.showManualDuplicate.length > 0}
                        maxWidth="md" 
                        scroll="body"
                    >
                        <DialogTitle>
                            <Typography>Duplicate Serial Numbers</Typography>
                        </DialogTitle>
                        <DialogContent>
                            {
                                [
                                    <Typography>Please note {this.state.showManualDuplicate.length > 1 ? 'these' : 'this'} serial number has been used before</Typography>,
                                    <DataTable  
                                        config={{
                                            key: 'number',
                                            alternatingRowColours: true,
                                            isLoading: this.state.dataLoading,
                                            
                                        }}
                                        columns={[
                                            {
                                                heading: 'Number',
                                                field: rowData => rowData.number,
                                                hideInResponsiveDialog: true,
                                                sizeToContent: true,
                                                import:true
                                            },
                                            {
                                                heading: 'Used',
                                                field: rowData => rowData.dates.map(
                                                    i => <>{i} <br></br></>
                                                ),
                                                hideInResponsiveDialog: true,
                                                sizeToContent: true,
                                                import:true
                                            },
                                        ]}
                                    rows={this.state.showManualDuplicate}
                                    />, 
                                    <DialogActions>
                                        <Button onClick={this.handleManualDuplicateConfirmationClose} variant="outlined" color="default">Cancel</Button>
                                        {/* <Button onClick={this.handleManualDuplicateConfirmationSuccess} autoFocus variant="contained" color="primary">Add</Button> */}
                                    </DialogActions>
                                ]
                            }
                        </DialogContent>
                    </Dialog>
                }
                <QrScanWrapper
                    handleScan={this.handleScan}
                    parentScan={this._handleKeyDown}
                />
                {confirmationDialog.open &&
                    <ConfirmationDialog
                        open={confirmationDialog.open}
                        title={confirmationDialog.title}
                        message={confirmationDialog.message}
                        success={()=>{this.handleConfirmationClose(); confirmationDialog.success()}}
                        close={this.handleConfirmationClose}
                        isPda={true}
                    />
                }
                {showPartLabels &&
                    <Dialog
                        open={showPartLabels}
                        onClose={()=>this.setState({showPartLabels: false})}
                        fullWidth={true} 
                        maxWidth="sm" 
                        scroll="body"
                    >
                        <DialogTitle>
                            <Typography>Parts Labels</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <List>
                                {_.map(_.uniqBy(this.props.pickingList, i => i.part.part_number), i =>
                                    <ListItem
                                        onClick={this.printPartLabel(i.part.part_id)}
                                    >
                                        {i.part.part_number} - {i.part.part_description}
                                    </ListItem>
                                )}
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>this.setState({showPartLabels: false})} variant="outlined" color="default">Close [F3]</Button>
                            <Button onClick={this.handlePrintAllPartLabels} autoFocus variant="contained" color="primary">Print All</Button>
                        </DialogActions>
                    </Dialog>
                }
                {showAdditional &&
                    <Dialog
                    open={showAdditional}
                    onClose={()=>this.setState({showAdditional: false})}
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                >
                    <DialogTitle>
                        <Typography>Additional Documents</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <List>
                            {_.map(_.uniqBy(this.state.pickingDocuments, 'doc_title'), i => 
                                <ListItem
                                    style={{color: colors.blue}}
                                    onClick={e => { e.preventDefault(); this.props.printA4(i.file_url_pdf || i.file_url_original, i.doc_title) }}
                                >v{i.latest_file.file_version} {i.doc_title}</ListItem>
                            )}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>this.setState({showAdditional: false})} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handlePrintAllAdditional} autoFocus variant="contained" color="primary">Print All</Button>
                    </DialogActions>
                </Dialog>
                }
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        playSound: (type, state) => {
            dispatch(playSound(type, state));
        },
        deploySnackBar: (variant, message) => {
            dispatch(deploySnackBar(variant, message));
        },
        printA4:    (data, file) => dispatch(printA4(data, file)),
        printLabel: (data, file) => dispatch(printLabel(data, file)),
        printMulti: (data) => dispatch(printMulti(data)),
    }
}

const styles = theme => ({
    root: {
        padding:    theme.spacing(1),
        alignItems: 'flex-start',
    },
    qrRow: {
        justifyContent: 'flex-end',
        maxHeight:      '50px',
    },
    img: {
        maxHeight: 100,
        maxWidth:  100,
        width:     '100%'
    }, 
    qtyRow: {
        textAlign: 'center'
    },
    actionText: {
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: 15,
        width: '80%',
        margin: '0 auto',
        textAlign: 'center'
    },
    confirmQtyInputContainer: {
        textAlign: 'center',
    }
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(PickingDialog));