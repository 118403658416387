import React from 'react';
import {
    Grid,
    Typography,
    Tooltip
} from '@material-ui/core';
import AllIcon from 'Components/Common/Icons/AllIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';


const TileStat = ({color, text, stat, icon, tooltip}) => {

    return (
        <PaddedPaper className="p-3">
            <Tooltip title={tooltip} disableHoverListener={!tooltip && true}>
                <Grid container alignItems='center' >      
                    <Grid item>
                        <AllIcon icon={icon} size={50} noMargin style={{color:color}} />
                    </Grid>
                    <Grid item xs>
                        <Grid container alignItems='center'>
                            <Grid item xs={12} align='right'>
                                <Typography variant="h6">
                                    {text}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align='right'>
                                <Typography variant="h5" style={{color: color}}>
                                    <b>{stat}</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>  
                </Grid>
            </Tooltip>
        </PaddedPaper>
    )
}

export default TileStat;