import React, {Component}        from 'react';
import API                       from 'API';
import _                         from 'lodash';
import { connect }               from 'react-redux';
import imageCompression          from 'browser-image-compression';
import { MAX_UPLOAD_LIMIT_SIZE } from 'Constants';

import DragFileInputMulti      from 'Components/Common/Inputs/DragFileInputMulti';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle           from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon                 from 'Components/Common/Icons/AllIcon';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';

import { Grid, FormControl, TextField, Button, Typography } from '@material-ui/core';

import { closeDialog }        from 'Actions/Dialog/Dialog';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar }     from 'Actions/SnackBar/SnackBar';

import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = {
    formData:   {
        quoteIds:      0,
        files:         [],
        type:          '',
        location:      '',
        desc:          '',
        source:        'Quote',
        customer:      0,
        serialNumbers: [],
    },
    typeList:   [],
    isLoading:  true,
    formErrors: [],
    lists:      {
        serialNumbers: [],
    }
}

class EngineerMediaDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.state.formData.quoteIds = this.props.quoteId;
        this.state.formData.customer = this.props.customerId;
    }

    componentDidMount(){
        this.getMediaTypes();
    }

    handleMultiCreateSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            },
            lists: {
                ...this.state.lists,
                [fieldName]: selectedOptions
            }
        });
    }

    getMediaTypes = () => {
        API.get('/parts/catagories')
        .then(res => {
            this.setState({
                typeList:  _.map(res.data, i => _.assign({value: i.pc_name, label: i.pc_name})),
                isLoading: false
            });
        });
    }

    handleSelectChange = field => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: e ? e.value : initialState.formData[field]
            }
        })
    }

    handleChange = field => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: e.target.value
            }
        })
    }

    handleUploads = (drop, name, event) => {
        let newFiles = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles = this.state.formData.files;
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))
        this.setState({
            formData: {
                ...this.state.formData,
                files: allFiles
            }
        });
    }

    clearUploads = idx => {

        let currentFiles = Array.from(this.state.formData.files);
        currentFiles.splice(idx, 1);
        if(currentFiles.length === 0) currentFiles = '';

        this.setState({
            formData:{
                ...this.state.formData,
                files: currentFiles
            }
        });
    }

    compressFileUploads = (engineerUploads) => new Promise(function(resolve, reject) {
        let newArray = [];
        if(engineerUploads.length > 0){
            _.each(engineerUploads, (upld, idx) => {
                const options = {
                    maxSizeMB: 5,
                    maxIteration: 50,
                    useWebWorker: true
                }
                // Compress jpeg and png image by reducing storage size before uploading to server 
                imageCompression(upld, options)
                .then(res => {
                    newArray.push(new File([res], upld['name'], {type: upld['type'], lastModified: upld['lastModified']}));
                    if(newArray.length === engineerUploads.length){
                        resolve(newArray)
                    }
                })
                .catch(e => {
                    newArray.push(new File([upld], upld['name'], {type: upld['type'], lastModified: upld['lastModified']}));
                    if(newArray.length === engineerUploads.length){
                        resolve(newArray)
                    }
                }); 
            })
        }
        else {
            resolve(false)
        }
    });

    handleSubmit = () => {
        this.setState({
            isLoading: true
        }, () => {
            this.compressFileUploads(this.state.formData.files)
            .then(res => {
                let newFormData = new FormData();
                if(res) _.each(res, (file) => { newFormData.append('file[]', file); })
                Object.keys(this.state.formData).forEach(key => {
                    if(key === 'file') {
                        newFormData.append('file[]', this.state.formData[key]);
                    } else if (key == 'serialNumbers') {
                        newFormData.append('serialNumbers[]', this.state.formData[key]);
                    } else if (key !== 'files') {
                        newFormData.append(key, this.state.formData[key]);
                    }
                    newFormData.append('cutsomerId', this.props.customerId);
                });
                API.post('/sales/orders/engineerMedia', newFormData)
                .then(res => {
                    if(res.data.errors && res.data.errors.length > 0) {
                        this.setState({
                            formErrors: formatValidationErrors(res.data.errors),
                            isLoading: false
                        });
                    } else {
                        this.setState({
                            formData: initialState.formData,
                            isLoading: false
                        }, () => {
                            this.props.deploySnackBar('Media uploaded successfully');
                        })
                    }
                });
            })
        })
    }

    render() {

        const { formData, typeList, isLoading, formErrors, lists } = this.state;

        if (isLoading) return ( <LoadingCircle/> )
        return (            
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="none">
                        <AutoCompleteSelect
                            label="Product Type *"
                            options={_.sortBy(typeList, i=> i.label)}
                            value={formData.type}
                            onChange={this.handleSelectChange('type')}
                            noClear 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="none">
                        <TextField
                            label="Location *"
                            value={formData.location}
                            onChange={this.handleChange('location')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="none">
                        <TextField
                            label="Description *"
                            value={formData.desc}
                            onChange={this.handleChange('desc')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="none">
                        <AutoCompleteMultiSelect
                            label="Serial Numbers"
                            value={formData.serialNumbers}
                            options={lists.serialNumbers}
                            onChange={this.handleMultiCreateSelectChange('serialNumbers')}
                            noClear
                            creatable
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <DragFileInputMulti
                        id="files"
                        name="files[]"
                        label="Upload Media (.jpg, .gif, .png, .mp4, .mov, .pdf)*"
                        file={formData.files}
                        type="file"
                        error={formErrors && formErrors['files'] && true}
                        errorText={formErrors && formErrors['files']}
                        onChange={this.handleUploads}
                        cancelOnClick={this.clearUploads}
                        emptyText='No files uploaded'
                    />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Typography variant="subtitle2" style={{ flex: 1 }}>
                        <AllIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                    </Typography>
                    <Button variant='outlined' onClick={this.props.closeDialog} >Close</Button>
                    <Button variant='contained' color='primary' onClick={()=>this.props.deployConfirmation(
                            'Are you sure you want to add these files?',
                            'Add Engineer Media',
                            this.handleSubmit
                        )} 
                        disabled={!formData.type || !formData.location || !formData.desc || !formData.files.length}
                    >Upload</Button>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                        => dispatch(closeDialog()),
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (msg)                     => dispatch(deploySnackBar('success', msg)),
})

export default connect(null, mapDispatchToProps)(EngineerMediaDialog);