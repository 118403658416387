import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import React, { Component } from 'react';
import API from '../../../../API';
import DataTable from '../../../Common/DataTables/DataTable';
import {colors} from 'Helpers/ColourHelper';

class PartsUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partsUsage: [],
            open: false
        }
    }

    componentDidMount(){
        // this.getPartsUsage();
    }

    getPartsUsage = () => {
        API.get(`/parts/${this.props.partId}/partsUsage`, {
            params: {
              origPart: this.props.partId
            }
        })
        .then(result => {
            let newArray = [];
            if(result.data) {
                _.map(result.data.Direct, (el) => {
                    newArray.push({
                        number: el.number,
                        description: el.description,
                        usage: el.usage,
                        status: el.status,
                        qty: el.qty,
                    });
                });
                _.map(result.data.Indirect, (el) => {
                    newArray.push({
                        number: el.number,
                        description: el.description,
                        usage: el.usage,
                        status: el.status,
                        qty: el.qty,
                    });
                });
            }
            this.setState({
                partsUsage: newArray,
                open: true
            });
        });
    }

    handleOpen = () => {
        if(!this.state.open){
            this.getPartsUsage();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {
        const { open } = this.state;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                    <Typography>Parts Usage</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open &&
                        <>
                            {this.state.partsUsage.length > 0 ? 
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={12}>
                                        <DataTable  
                                            config={{
                                                key: 'sa_id',
                                                alternatingRowColours: true,
                                                plainHeader: true,
                                                rowProps: rowData => {
                                                    return ({
                                                        style: {
                                                            color: rowData.status !== 'Active' && colors.red
                                                        }
                                                    });
                                                }
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Part Number',
                                                    field: rowData => rowData.number
                                                },
                                                {
                                                    heading: 'Part Description',
                                                    field: rowData => rowData.description
                                                },
                                                {
                                                    heading: 'Status',
                                                    field: rowData => rowData.status,
                                                    cellProps: rowData => {
                                                        return ({
                                                            style: {
                                                                color: rowData.status !== 'Active' ? colors.red : colors.green
                                                            }
                                                        });
                                                    }
                                                },
                                                {
                                                    heading: 'Usage',
                                                    field: rowData => rowData.usage,
                                                },
                                                {
                                                    heading: 'Quantity',
                                                    field: rowData => rowData.qty,
                                                }
                                            ]}
                                            rows={_.sortBy(this.state.partsUsage, function (pn) { return pn.number })}
                                        />
                                    </Grid>
                                </Grid>
                            :
                            <Typography>This part is not used within a sub assembly</Typography>
                            }
                        </>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default PartsUsage;
