//CustomerCreditStatements
import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Grid, Typography, Button, FormControl, Checkbox, FormControlLabel, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core/'
import { withStyles } from '@material-ui/core/styles';

import LoadingCircle           from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper             from 'Components/Common/Paper/PaddedPaper';
import AllIcon                 from 'Components/Common/Icons/AllIcon';
import DataTable               from 'Components/Common/DataTables/CiDataTable';
import EmailCreditLimitDialog  from 'Components/Customers/ViewCustomer/CustomerAccounts/EmailCreditLimitDialog';  
import UpdateCreditLimitDialog from 'Components/Customers/ViewCustomer/CustomerAccounts/UpdateCreditLimitDialog';
import CreditLimitHistory      from 'Components/Customers/ViewCustomer/CustomerAccounts/CreditLimitHistory';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';


import { colors }        from 'Helpers/ColourHelper';
import icons             from 'Helpers/IconHelper';
import { pdfFromBase64 } from "Helpers/PDFHelper";
import { clenyDate }     from 'Helpers/TimeHelper';

import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';
import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';

const initialState = {
    isLoading:      true,
    statements:     [],
    access:         {},
    formData:       { 
        allowPrimary: 0 
    },
    showEmailDialog: false
}

class CustomerCreditStatements extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount() { 
        this.getCreditStatements()
        this.getAccess()
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-customer-credit-limit'),
            API.get('/staff/my/access/check/process-customer-credit-limit'),
        ])
        .then(([updateCustomerCreditLimit, processCustomerCreditLimit]) => {
            this.setState({
                access: {
                    updateCustomerCreditLimit:  (updateCustomerCreditLimit?.data?.has_access)  || false,
                    processCustomerCreditLimit: (processCustomerCreditLimit?.data?.has_access) || false,
                },
            })
        });
    }

    getCreditStatements = () => {
        API.get('/accounts/creditStatements')
        .then( res => {
            this.setState({
                statements: _.map(res.data, i => { 
                    i.checked=0; 
                    i.email = 'niet';
                    return i; 
                }),
                isLoading:  false
            })
        })
    }

    handleDownloadStatement = id => {
        const statement = _.find(this.state.statements, {i:id} )
        API.post(`/customers/${statement.a.i}/accounts/addresses/${statement.a.d}/statement/download`).then((result) => {
            if (result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
                this.props.deploySnackBar("success", "PDF successfully downloaded")
            }
        });
    }

    editCreditLimit = id => {
        const statement = _.find(this.state.statements, {i:id} )
        this.props.deployDialog(<UpdateCreditLimitDialog customerId={statement.a.i} addressId={statement.a.d} limit={statement.c.l} callback={this.getCreditStatements} />, `Update ${statement.a.n}'s Credit Limit`, null, 'sm')
    }

    showHistory = id => {
        const statement = _.find(this.state.statements, {i:id} )
        this.props.deployDialog(<CreditLimitHistory customerId={statement.a.i} addressId={statement.a.d} />, `${statement.a.n}'s Credit History`, null, 'md');
    }


    handleEmailStatement = id => {
        const statement = _.find(this.state.statements, {i:id} )
        this.props.deployDialog(<EmailCreditLimitDialog customerId={statement.a.i} addressId={statement.a.d} callback={this.getCreditStatements}/>, 'Email Account Statement', '', 'md');
    }

    handleEmailAllStatements = () => {
        API.post('/accounts/creditStatements/email')
        .then( res => {if (res.data.success) this.getCreditStatements();})
    }

    handleProcessSetEmail = id => (v) => {
        let statements = this.state.statements;
        let indx = _.findIndex(statements, i => i.i == id);
        statements[indx].email = v?.value;
        this.setState({statements});
    }
    handleProcessCheck = id => {
        let statements = this.state.statements;
        let indx = _.findIndex(statements, i => i.i == id);
        if (statements[indx].checked){
            statements[indx].email = 'niet';
        } else {
            let primary = _.find( statements[indx].a.o, o => o.p);
            statements[indx].email = (primary?.i) ? primary.i : null;
        }
        statements[indx].checked = !statements[indx].checked;
        this.setState({statements});
    }

    handleCheckAll = () => { this.setState({statements: _.map(this.state.statements, i => {i.checked = 1;})}); }

    handleEmailChecked = () => {
        API.post('/accounts/creditStatements/email', {allowSet: this.state.formData.allowPrimary, email: _.map(_.filter(this.state.statements, i=>i.email != 'niet'), i=>{return _.assign({a: i.i, c: i.email})})})
        .then( res => {
            if (res.data.success) this.getCreditStatements();
            this.setState({showEmailDialog: false})
        })
    }

    handleSelectBox = v => { this.setState({formData: {allowPrimary: (v) ? v.value : 0}}); }

    render(){
        const { isLoading, statements, access, formData } = this.state;
        const { classes }               = this.props;
        return (
            <>
                {isLoading ? <LoadingCircle/> :
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h5" style={{marginBottom: '1.1em'}}>Customer Account Statements</Typography>
                            <PaddedPaper>
                                <Grid container spacing={2} style={{justifyContent: 'center'}}>
                                    <Grid item>
                                        Debit Due
                                        <Typography variant='h4' style={{textAlign: 'center'}}>£{parseFloat( _.sumBy(statements,i=>i.c.d[0]) ).toFixed(2)}</Typography>
                                    </Grid>
                                    <Grid item >
                                        <br/>
                                        <AllIcon size='large' icon={'slash-forward'} noMargin />
                                    </Grid>
                                    <Grid item>
                                        Debit Overdue
                                        <Typography variant='h4' style={{textAlign: 'center'}}>£{parseFloat( _.sumBy(statements,i=>i.c.o[0]) ).toFixed(2)}</Typography>
                                    </Grid>
                                    <Grid item >
                                        <br/>
                                        <AllIcon size='large' icon={'slash-forward'} noMargin />
                                    </Grid>
                                    <Grid item>
                                        Credit Due
                                        <Typography variant='h4' style={{textAlign: 'center'}}>-£{parseFloat( _.sumBy(statements,i=>i.c.m[0]) ).toFixed(2)}</Typography>
                                    </Grid>
                                    <Grid item >
                                        <br/>
                                        <AllIcon size='large' icon={'slash-forward'} noMargin />
                                    </Grid>
                                    <Grid item>
                                        Total
                                        <Typography variant='h4' style={{textAlign: 'center'}}>£{parseFloat( _.sumBy(statements,i=>i.c.c) ).toFixed(2)}</Typography>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={4}>
                            <PaddedPaper >
                                <Grid container>
                                    <Grid item xs={5}><Typography>Current</Typography></Grid>
                                    <Grid item xs={2}><Typography>{parseInt(_.sumBy(statements,i => i.c.b.ccurrent))}</Typography></Grid>
                                    <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(statements,i => i.c.b.current)).toFixed(2)}</Typography></Grid>
                                    <Grid item xs={5}><Typography>Over 30 Days</Typography></Grid>
                                    <Grid item xs={2}><Typography>{parseInt(_.sumBy(statements,i => i.c.b.co30))}</Typography></Grid>
                                    <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(statements,i => i.c.b.o30)).toFixed(2)}</Typography></Grid>
                                    <Grid item xs={5}><Typography>Over 60 Days</Typography></Grid>
                                    <Grid item xs={2}><Typography>{parseInt(_.sumBy(statements,i => i.c.b.co60))}</Typography></Grid>
                                    <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(statements,i => i.c.b.o60)).toFixed(2)}</Typography></Grid>
                                    <Grid item xs={5}><Typography>Over 90 Days</Typography></Grid>
                                    <Grid item xs={2}><Typography>{parseInt(_.sumBy(statements,i => i.c.b.co90))}</Typography></Grid>
                                    <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(statements,i => i.c.b.o90)).toFixed(2)}</Typography></Grid>
                                    <Grid item xs={5}><Typography>Older</Typography></Grid>
                                    <Grid item xs={2}><Typography>{parseInt(_.sumBy(statements,i => i.c.b.colder))}</Typography></Grid>
                                    <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(statements,i => i.c.b.older)).toFixed(2)}</Typography></Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <DataTable 
                                    config={{
                                        key: 'i',
                                        pagination: false,
                                        alternatingRowColours: true,
                                        responsiveimportant: true,
                                        isLoading: this.state.isLoading,
                                    }}
                                    columns={[
                                        {
                                            heading:                'Customer',
                                            field:                  rowData => `CS-${rowData.a.i}`,
                                            sizeToContent:          true,
                                            dataRef:                'a.i',
                                            totalLabel: <Typography variant='body1' style={{textAlign:'right', fontWeight: 'bold'}}>Total</Typography>
                                        },
                                        {
                                            heading: 'Account',
                                            field: rowData => <div style={{maxWidth:220, overflow:'hidden',textOverflow: "ellipsis"}}>
                                                <AllIcon icon={icons.info} size='small'tooltip={
                                                    <Typography style={{maxWidth:200, overflow:'hidden',textOverflow: "ellipsis"}} variant="body2">
                                                        <b>Customer:</b><br/>
                                                        <span>{rowData.a.c}</span><br/><br/>
                                                        <b>Accounts:</b><br/>
                                                        <span>{rowData.a.n}</span> <br/>
                                                        {rowData.a.a.split('\n').map((item, key) => {
                                                            return  <span key={key}>
                                                                        {item}{rowData.a.a.split('\n').length === key+1 ? null : <br/>}
                                                                    </span>
                                                        })}
                                                    </Typography>
                                            } />{rowData.a.n} </div>,
                                            dataRef: 'a.n',
                                            sizeToContent: true,
                                            totalRef:               rowData => {return 1}  
                                        },
                                        {
                                            heading:        'Due',
                                            field:          rowData => <AllIcon icon={`TextToIcon:${rowData.c.d[1]}`} tooltip={`£${parseFloat(rowData.c.d[0]).toFixed(2)}`} />,
                                            sizeToContent:  true,
                                            dataRef:        rowData => {return rowData.c.d[1]},
                                            alignment:      'center',
                                            totalRef:       rowData => {return parseFloat(rowData.c.d[0])},
                                            totalLabel:     rowData => <Typography variant='body1' style={{textAlign:'center', fontWeight: 'bold'}}>{`£${parseFloat(rowData).toFixed(2)}`}</Typography>
                                        },
                                        {
                                            heading:        'Over',
                                            field:          rowData => <AllIcon icon={`TextToIcon:${rowData.c.o[1]}`} tooltip={`£${parseFloat(rowData.c.o[0]).toFixed(2)}`} />,
                                            sizeToContent:  true,
                                            dataRef:        rowData => {return rowData.c.o[1]},
                                            alignment:      'center',
                                            totalRef:       rowData => {return parseFloat(rowData.c.o[0])},
                                            totalLabel:     rowData => <Typography variant='body1' style={{textAlign:'center', fontWeight: 'bold'}}>{`£${parseFloat(rowData).toFixed(2)}`}</Typography>
                                        },
                                        {
                                            heading:        'Credit',
                                            field:          rowData => <AllIcon icon={`TextToIcon:${rowData.c.m[1]}`} tooltip={`-£${parseFloat(rowData.c.m[0]).toFixed(2)}`} />,
                                            sizeToContent:  true,
                                            dataRef:        rowData => {return rowData.c.m[1]},
                                            alignment:      'center',
                                            totalRef:       rowData => {return parseFloat(rowData.c.m[0])},
                                            totalLabel:     rowData => <Typography variant='body1' style={{textAlign:'center', fontWeight: 'bold'}}>{`£${parseFloat(rowData).toFixed(2)}`}</Typography>
                                        },
                                        {
                                            heading: 'Total',
                                            field: rowData => <>{`£${parseFloat(rowData.c.c).toFixed(2)}`} <AllIcon icon={icons.info} size='small'tooltip={
                                                <Grid container>
                                                    <Grid item  xs={1}>
                                                        <AllIcon icon={icons.plus} size='small' style={{color:colors.green}}/> 
                                                    </Grid>
                                                    <Grid item xs={6} style={{color:colors.green, paddingLeft:'1em'}}>
                                                        &nbsp;{`£${parseFloat(rowData.c.p).toFixed(2)}`}
                                                    </Grid>
                                                    <Grid item  xs={5}></Grid>
                                                    <Grid item  xs={1}>
                                                        <AllIcon icon={icons.minus} size='small' style={{color:colors.red}}/> 
                                                    </Grid>
                                                    <Grid item xs={6} style={{color:colors.red, paddingLeft:'1em'}}>
                                                        {`£${parseFloat(rowData.c.m[0]).toFixed(2)}`}
                                                    </Grid>
                                                </Grid>
                                            }/></>,
                                            sizeToContent: true,
                                            dataRef:        'c.c',
                                            totalRef:       'c.c',
                                            totalLabel:     rowData => <Typography variant='body1' style={{textAlign:'center', fontWeight: 'bold'}}>{`£${parseFloat(rowData).toFixed(2)}`}</Typography>
                                        },
                                        {
                                            heading: 'Credit Limit',
                                            field: rowData => `£${parseFloat(rowData.c.g).toFixed(2)}`,
                                            sizeToContent: true,
                                            dataRef: 'c.g'
                                        },
                                        {
                                            heading:        'Terms',
                                            field:          rowData => <AllIcon icon={icons[rowData.u.t]} tooltip={rowData.u.t} />,
                                            sizeToContent:  true,
                                            dataRef:        'u.t',
                                            alignment:      'center',
                                        },
                                        {
                                            heading: 'Email To',
                                            field:          rowData =>  <FormControl fullWidth margin="normal" style={{width: 200}}>
                                                                            <AutoCompleteSelect 
                                                                                options={[{value: 'niet', label: "Don't Email"},...
                                                                                _.map(_.filter(rowData.a.o, 
                                                                                        i => i.e
                                                                                    ), i => {
                                                                                    return _.assign({
                                                                                        value: i.i,
                                                                                        label: `${i.n} (${i.e})`
                                                                                    })
                                                                                })]}
                                                                                name='emailTo'
                                                                                label=''
                                                                                error={!rowData.email}
                                                                                errorText={rowData.email ? '' : 'No Primary contact available'}
                                                                                value={rowData.email}
                                                                                onChange={this.handleProcessSetEmail(rowData.i)}
                                                                                disableSort
                                                                                clearable={false}
                                                                                disabled={!rowData.checked}
                                                                            />
                                                                        </FormControl>,
                                            sizeToContent:  true,
                                            alignment:      'left',
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Download',          icon: 'download',    onClick: () => this.handleDownloadStatement(rowData.i), disabled: !access.processCustomerCreditLimit },
                                                    {name: 'Send to Customer',  icon: 'envelope',    onClick: () => this.handleEmailStatement(rowData.i),    disabled: !access.processCustomerCreditLimit },
                                                    {name: 'Edit Credit Limit', icon: icons.edit,    onClick: () => this.editCreditLimit(rowData.i),         disabled: !access.updateCustomerCreditLimit },
                                                    {name: 'Show History',      icon: icons.history, onClick: () => this.showHistory(rowData.i)},
                                                    {name: 'View',              icon: 'search',      link: `/customers/view/${rowData.a.i}/Accounts`},
                                                    {type: 'checkBox',          checked: rowData.checked, onCheck: ()=>{this.handleProcessCheck(rowData.i)}   }
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={statements}
                                />
                                {access.processCustomerCreditLimit &&
                                    <Grid container xs={12} style={{marginTop: '1em', justifyContent: 'flex-end'}} justifyContent='flex-end'>
                                        <Grid item style={{textAlign: 'right'}}>
                                            {_.filter(statements, i => !i.email).length > 0 ?
                                                <p style={{color:colors.red}}>Some Accounts Don't Have Primary Contacts</p> :
                                                <></>
                                            }
                                            <Button 
                                                variant='contained' 
                                                color='primary' 
                                                onClick={()=>{this.setState({showEmailDialog: true})}}
                                                disabled={_.filter(statements, i => !i.email).length > 0}
                                            > Email All </Button>
                                        </Grid>
                                    </Grid>
                                }
                            </PaddedPaper>
                            <Dialog 
                                open={this.state.showEmailDialog}  
                                maxWidth="sm" 
                                scroll="body"
                            >
                                <DialogTitle>
                                    <Typography>{`Email ${_.filter(statements, i => i.email && i.email != 'niet').length} Statements`}</Typography>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container xs={12}>
                                        <Grid item xs={12}>
                                            <Typography>{`Are you sure you want to email ${_.filter(statements, i => i.email && i.email != 'niet').length} account statements?`}</Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect
                                                    options={[
                                                        {value: 0, label: 'Dont Set New Primary Contacts'},
                                                        {value: 1, label: 'Set New Primary Contacts'},
                                                        {value: 2, label: 'Overwrite Primary Contacts'}
                                                    ]} 
                                                    label='Primary Contacts'
                                                    value={this.state.formData.allowPrimary}
                                                    onChange={this.handleSelectBox}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button 
                                        onClick={()=>{this.setState({showEmailDialog: false})}}
                                        variant="outlined" 
                                        color="default" 
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        onClick={this.handleEmailChecked}
                                        variant="contained" 
                                        color="primary" 
                                    >
                                        Send
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Grid>
                }
            </>
        )
    }

}

const styles = theme => ({
    paperGrey: {
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(3),
        },
        backgroundColor:'#f8f9fa',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    paperDisabled: {
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(3),
        },
        backgroundColor:'#fcc6c5',
    },
    overCredit: {
        border: `1px solid ${colors.red}`,         
        color:  colors.red
    }
});

function mapDispatchToProps(dispatch) {
    return {
        deployDialog:           (content, title, variant, size) => { dispatch(deployDialog(content, title, variant, size))},
        deploySnackBar:         (variant, body)                 => { dispatch(deploySnackBar(variant, body))},
        deployConfirmation:     (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
        closeDialog:            ()                              => {dispatch(closeDialog())}
    }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(CustomerCreditStatements))