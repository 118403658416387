import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import moment               from 'moment';

import {
    Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Input, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemSecondaryAction,
    ListItemText, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ImageWithError          from 'Components/Common/ImageWithError/ImageWithError';
import DragFileInput           from 'Components/Common/Inputs/DragFileInput';
import DatePicker              from 'Components/Common/DatePickers/DatePicker';
import ConfirmationDialog      from 'Components/Common/Dialogs/ConfirmationDialog';
import AllIcon                 from 'Components/Common/Icons/AllIcon';
import FALightIcon             from 'Components/Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle           from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper             from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar                from 'Components/Common/SnackBars/SnackBar';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';


const initialState = {
    formData: {
        partType:               '',
        buildType:              '',
        newPartNumber:          'CS-',
        oldPartNumber:          '',
        description:            '',
        importCommodity1:       '',
        importCommodity2:       '',
        importCommodity3:       '',
        exportCommodity1:       '',
        exportCommodity2:       '',
        exportCommodity3:       '',
        nominalCode:            [],
        spareForPart:           [],
        associatedParts:        [],
        pointOfIntroduction:    moment().toString(),
        uniquePartNumber:       '',
        sparePart:              'No',
        product:                'Yes',
        reqSerialNumber:        'No',
        partDiscount:           'No',
        warrantyVoidLabel:      'No',
        warrantyTerms:          '',
        barcodeNumber:          '',
        barcodeImage:           '',
        technicalLibraryAccess: 'No',
        ecommerceProduct:       'No',
        ecommerceCategories:    [],
        shippingWidth:          '',
        shippingHeight:         '',
        shippingDepth:          '',
        shippingWeight:         '',
        partDepth:              '',
        partHeight:             '',
        partWeight:             '',
        electricalConnection:   'No',
        electricalConnections:  [],
        category:               0,
        exemptFromStock:        'No',
        stockThreshold:         0,
        linkedParts:            {},
        instructionManual:      null,
        requiresStockMovement:  'No'
    },
    catagories:                 [],
    partTypeList:               [],
    buildTypeList:              [],
    nominalList:                [],
    partList:                   [],
    ecList:                     [],
    warrantyTermsList:          [],
    ecomCategoryList:           [],
    formErrors:                 [],
    confirmationOpen:           false,
    snackbarOpen:               false,
    isLoading:                  true,
    showAssociatedSpareParts:   false
}

const styles = theme => ({
    ccGroup: {
        paddingBottom: '1px',
        marginBottom: '8px',
        '&:hover': {
            borderBottom: `2px solid ${colors.blue}`,
            paddingBottom: 0
        }
    },
    cc1: {
        paddingLeft: '8px',
        border: '1px solid rgba(212, 213, 231, 1)',
        borderRight: 'none',
        background:'rgba(212, 213, 231, 0.7)',
        '&:before': {
            borderBottom: '1px solid rgba(212, 213, 231, 1)'
        },
        '&:after': {
            borderBottom: '2px solid rgba(212, 213, 231, 1)'
        }
    },
    cc2: {
        paddingLeft: '8px',
        border: '1px solid rgba(182, 215, 235, 1)',
        borderRight: 'none',
        background: 'rgba(182, 215, 235, 0.7)',
        '&:before': {
            borderBottom: '1px solid rgba(182, 215, 235, 1)'
        },
        '&:after': {
            borderBottom: '2px solid rgba(182, 215, 235, 1)'
        }
    },
    cc3: {
        paddingLeft: '8px',
        border: '1px solid rgba(181, 222, 219, 1)',
        background:'rgba(181, 222, 219, 0.7)',
        '&:before': {
            borderBottom: '1px solid rgba(181, 222, 219, 1)'
        },
        '&:after': {
            borderBottom: '2px solid rgba(181, 222, 219, 1)'
        }
    }
});

class AddPart extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.creatCommodityRefs();
        this.populateDropdowns();
        this.getAllCategories();
    }

    creatCommodityRefs = () => {
        this.ccInput1 = React.createRef();
        this.ccInput2 = React.createRef();
        this.ccInput3 = React.createRef();

        this.ccExportInput1 = React.createRef();
        this.ccExportInput2 = React.createRef();
        this.ccExportInput3 = React.createRef();
    }

    populateDropdowns = () => {
        Promise.all([
            API.get('/parts/numbering/partTypes/all'),
            API.get('/parts/numbering/buildTypes/all'),
            API.get('/accounts/nominalCodes'),
            API.get('/parts/all',  {params: {withDefaultImg: true}}),
            API.get('/parts/warrantyTerms'),
            API.get(`/e-commerce/productCategories`),
            API.get('/parts/electricalConnections')
        ])
        .then(([partTypeRes, buildTypeRes, nominalRes, partRes, termRes, ecomCatRes, ecRes]) =>  {
            let partTypeList        = [];
            let buildTypeList       = [];
            let nominalList         = [];
            let partList            = [];
            let warrantyTermsList   = [];
            let ecomCategoryList    = [];
            let ecList              = [];

            if (ecRes.data){
                ecList = _.map(ecRes.data, (el) => {
                    return _.assign({
                        value: el.ec_id,
                        label: el.ec_name
                    })
                });
            }

            if(partTypeRes.data){
                partTypeList = _.map(partTypeRes.data, (el) => {
                    return _.assign({
                        value: el.part_type_id,
                        label: el.part_type_name,
                    });
                });
            }
            if(buildTypeRes.data){
                buildTypeList = _.map(buildTypeRes.data, (el) => {
                    return _.assign({
                        value: el.build_type_id,
                        label: el.build_type_name,
                    });
                });  
            }
            if(nominalRes.data){
                nominalList = _.map(nominalRes.data, (el) => {
                    return _.assign({
                        value: el.nominal_id,
                        label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                    });
                });
            }
            if(partRes.data){
                partList = _.map(partRes.data, (el) => {
                    return _.assign({
                        value: el.part_id,
                        label: el.part_number + ' - ' + el.part_description,
                        part:  el
                    });
                });
            }
            if(termRes.data){
                warrantyTermsList = _.map(termRes.data, (el) => {
                    return _.assign({
                        value: el.pwt_id,
                        label: el.pwt_name
                    });
                });
            }
            if(ecomCatRes.data){
                ecomCategoryList = _.map(ecomCatRes.data, el => {
                    return _.assign({
                        value: el.ecom_cat_id,
                        label: el.ecom_cat_name
                    });
                });
            }

            this.setState({
                partTypeList,
                buildTypeList,
                nominalList,
                partList,
                warrantyTermsList,
                ecomCategoryList,
                ecList,
                isLoading: false
            });
        });
    }

    getNextPartNumber = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, () => {
            if(this.state.formData.partType && this.state.formData.buildType){
                API.get('/parts/numbering/next', {
                    params: {
                        buildTypeId: this.state.formData.buildType,
                        partTypeId: this.state.formData.partType
                    }
                })
                .then((response) => {
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            newPartNumber: response.data.partNumber,
                            uniquePartNumber: response.data.uniquePartNumber
                        }
                    });
                });
            }
            else {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        newPartNumber: initialState.formData.newPartNumber
                    }
                }); 
            }
        })
    }

    handleDateChange = date => {
        this.setState({
            formData: {
                ...this.state.formData,
                pointOfIntroduction: date
            }
        });
    };

    handleChange = (name) => e => {
        // Import Commodity
        if(e.target.name === 'importCommodity1' && e.target.value.length === 2) {
            this.ccInput2.current.focus();
        } else if(e.target.name === 'importCommodity2') {
            if(e.target.value.length === 0) {
                this.ccInput1.current.focus();
            } else if(e.target.value.length === 2) {
                this.ccInput3.current.focus();
            }
        } else if(e.target.name === 'importCommodity3') {
            if(e.target.value.length === 0) {
                this.ccInput2.current.focus();
            }
        }
        // Export Commodity
        if(e.target.name === 'exportCommodity1' && e.target.value.length === 2) {
            this.ccExportInput2.current.focus();
        } else if(e.target.name === 'exportCommodity2') {
            if(e.target.value.length === 0) {
                this.ccExportInput1.current.focus();
            } else if(e.target.value.length === 2) {
                this.ccExportInput3.current.focus();
            }
        } else if(e.target.name === 'exportCommodity3') {
            if(e.target.value.length === 0) {
                this.ccExportInput2.current.focus();
            }
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        }, () => {
            if(name && name === 'ecommerceProduct'){
                if(this.state.formData.ecommerceProduct === 'No'){
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            ecommerceCategories: []
                        }
                    });
                }
            }
        });
    }

    handleToDecimalPlace = (decimal) => e => {
        let newVal = parseFloat(e.target.value).toFixed(decimal);

        let formData = {
            ...this.state.formData,
            [e.target.name]: newVal
        };

        this.setState({ formData });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if(fieldName === 'sparePart'){
                this.setState({
                    showAssociatedSpareParts: selectedOption && selectedOption.value === 'Yes' ? true : false
                });

            }
        });
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            if(key === 'nominalCode' || key === 'spareForPart' || key === 'ecommerceCategories' || key === 'associatedParts' || key === 'electricalConnections' || key === 'linkedParts') {
                newFormData.append(key, JSON.stringify(this.state.formData[key]))
            }else if(key === 'pointOfIntroduction')  {
                newFormData.append(key, moment(new Date(this.state.formData[key])).format('DD/MM/YYYY'));
            } else {
                newFormData.append(key, this.state.formData[key]);
            }
        });

        API.post('/parts', newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){         
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.creatCommodityRefs();
                this.populateDropdowns();
                this.getAllCategories();
            }
            this.props.scrollToTop();
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    clearFile = type => {
        this.setState({
            formData: {
                ...this.state.formData,
                [type]: ''
            }
        });
    }

    getAllCategories = () => {
        API.get('/parts/catagories')
        .then(res => {this.setState({catagories: res.data});});
    }

    handleLinkedPartsChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                linkedParts: {...this.state.formData.linkedParts, [e.value]: 1}
            }
        });
    }

    handleLinkedPartsRemove = part => () => {
        let linkedParts = {...this.state.formData.linkedParts};
        delete linkedParts[part];
        this.setState({
            formData: {
                ...this.state.formData,
                linkedParts
            }
        });
    }

    handleLinkedPartsChangeQty = part => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                linkedParts: {
                    ...this.state.formData.linkedParts,
                    [part]: parseFloat(e.target.value)
                }
            }
        });
    }
       
    render() {
        const { formErrors, showAssociatedSpareParts, isLoading, ecomCategoryList, catagories } = this.state;
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Part
                    </Typography>
                </Grid>
                {(isLoading && (
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <LoadingCircle />
                            </PaddedPaper>
                        </Grid>
                    </Grid>                    
                )) || (
                    <>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="body2" gutterBottom>
                                    <b>Part Details</b>
                                </Typography>
                                {formErrors && formErrors.generic && (
                                    <React.Fragment>
                                        <Typography component={"div"} style={{color: colors.red}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                <form noValidate autoComplete="off">
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.partTypeList} 
                                            label='Part Type *'
                                            onChange={this.getNextPartNumber('partType')}
                                            error={formErrors && formErrors['partType'] && true}
                                            errorText={formErrors && formErrors['partType']}
                                            value={this.state.formData.partType}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.buildTypeList} 
                                            label='Build Type *'
                                            onChange={this.getNextPartNumber('buildType')}
                                            error={formErrors && formErrors['buildType'] && true}
                                            errorText={formErrors && formErrors['buildType']}
                                            value={this.state.formData.buildType}
                                        />
                                    </FormControl>
                                    <TextField
                                        id="newPartNumber"
                                        name="newPartNumber"
                                        label="New Part Number"
                                        value={this.state.formData.newPartNumber}
                                        error={formErrors && formErrors['newPartNumber'] && true}
                                        helperText={formErrors && formErrors['newPartNumber']}
                                        onChange={this.handleChange()}
                                        margin="normal"
                                        fullWidth
                                        disabled
                                    />
                                    <TextField
                                        id="oldPartNumber"
                                        name="oldPartNumber"
                                        label="Old Part Number"
                                        value={this.state.formData.oldPartNumber}
                                        error={formErrors && formErrors['oldPartNumber'] && true}
                                        helperText={formErrors && formErrors['oldPartNumber']}
                                        onChange={this.handleChange()}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="description"
                                        name="description"
                                        label="Description *"
                                        value={this.state.formData.description}
                                        error={formErrors && formErrors['description'] && true}
                                        helperText={formErrors && formErrors['description']}
                                        onChange={this.handleChange()}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.warrantyTermsList} 
                                            label='Warranty Terms *'
                                            onChange={this.handleSelectChange('warrantyTerms')}
                                            error={formErrors && formErrors['warrantyTerms'] && true}
                                            errorText={formErrors && formErrors['warrantyTerms']}
                                            value={this.state.formData.warrantyTerms}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={[
                                                {value: 'No', label: 'No'},
                                                {value: 'Yes', label: 'Yes'}
                                            ]} 
                                            label='Spare Part *'
                                            onChange={this.handleSelectChange('sparePart')}
                                            error={formErrors && formErrors['sparePart'] && true}
                                            errorText={formErrors && formErrors['sparePart']}
                                            value={this.state.formData.sparePart}
                                        />
                                    </FormControl>
                                    {showAssociatedSpareParts &&
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteMultiSelect 
                                                options={this.state.partList} 
                                                label='Spare Part For'
                                                value={this.state.formData.spareForPart}
                                                onChange={this.handleMultiSelectChange('spareForPart')}
                                                error={formErrors && formErrors['spareForPart'] && true}
                                                errorText={formErrors && formErrors['spareForPart']}
                                            />
                                        </FormControl>
                                    }
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={[
                                                {value: 'No', label: 'No'},
                                                {value: 'Yes', label: 'Yes'}
                                            ]} 
                                            label='Electrical Connection *'
                                            onChange={this.handleSelectChange('electricalConnection')}
                                            error={formErrors && formErrors['electricalConnection'] && true}
                                            errorText={formErrors && formErrors['electricalConnection']}
                                            value={this.state.formData.electricalConnection}
                                        />
                                    </FormControl>

                                    {this.state.formData.electricalConnection === 'Yes' && 
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteMultiSelect 
                                                options={this.state.ecList} 
                                                label='Electrical Connections *'
                                                value={this.state.formData.electricalConnections}
                                                onChange={this.handleMultiSelectChange('electricalConnections')}
                                                error={formErrors && formErrors['electricalConnections']}
                                                errorText={formErrors && formErrors['electricalConnections']}
                                            />
                                        </FormControl>
                                    }
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteMultiSelect 
                                            options={this.state.nominalList} 
                                            label='Nominal Codes *'
                                            value={this.state.formData.nominalCode}
                                            onChange={this.handleMultiSelectChange('nominalCode')}
                                            error={formErrors && formErrors['nominalCode'] && true}
                                            errorText={formErrors && formErrors['nominalCode']}
                                        />
                                    </FormControl>
                                    <DatePicker
                                        type="date"
                                        id="poi"
                                        name="poi"
                                        label="Point of introduction *"
                                        value={this.state.formData.pointOfIntroduction}
                                        errorText={formErrors && formErrors['pointOfIntroduction']}
                                        onChange={this.handleDateChange}
                                        disableFuture={true}
                                    />
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={[
                                                {value: 'Yes', label: 'Product'},
                                                {value: 'No', label: 'Service'}
                                            ]} 
                                            label='Product/Service *'
                                            onChange={this.handleSelectChange('product')}
                                            error={formErrors && formErrors['product'] && true}
                                            errorText={formErrors && formErrors['product']}
                                            value={this.state.formData.product}
                                            noClear
                                        />
                                    </FormControl>
                                    {this.state.formData.product === 'Yes' &&
                                            <FormControl fullWidth margin="normal" component="fieldset">
                                                <FormLabel component="legend">Exempt From Stock Take</FormLabel>
                                                <RadioGroup
                                                    name="exemptFromStock"
                                                    value={this.state.formData.exemptFromStock}
                                                    onChange={this.handleChange()}
                                                    error={formErrors && formErrors['exemptFromStock'] && true}
                                                    errorText={formErrors && formErrors['exemptFromStock']}
                                                    row
                                                >
                                                    <FormControlLabel
                                                        value="No"
                                                        control={<Radio color="primary" />}
                                                        label="No"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={<Radio color="primary" />}
                                                        label="Yes"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                                {formErrors && formErrors['exemptFromStock'] &&
                                                    <FormHelperText style={{color: colors.red}}> {formErrors['exemptFromStock']}</FormHelperText>
                                                }
                                            </FormControl>
                                        }
                                         {
                                                this.state.formData.product === 'Yes' &&
                                                this.state.formData.exemptFromStock === 'No' &&
                                                <FormControl fullWidth margin="normal" component="fieldset">
                                                    <FormLabel component="legend">Does this require a stock movement?</FormLabel>
                                                    <RadioGroup
                                                        name="requiresStockMovement"
                                                        value={this.state.formData.requiresStockMovement}
                                                        onChange={this.handleChange()}
                                                        error={formErrors && formErrors['requiresStockMovement'] && true}
                                                        errorText={formErrors && formErrors['requiresStockMovement']}
                                                        row
                                                    >
                                                        <FormControlLabel
                                                            value="No"
                                                            control={<Radio color="primary" />}
                                                            label="No"
                                                            labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                            value="Yes"
                                                            control={<Radio color="primary" />}
                                                            label="Yes"
                                                            labelPlacement="end"
                                                        />
                                                    </RadioGroup>
                                                    {formErrors && formErrors['requiresStockMovement'] &&
                                                        <FormHelperText style={{color: colors.red}}> {formErrors['requiresStockMovement']}</FormHelperText>
                                                    }
                                                </FormControl>
                                            }
                                        {this.state.formData.product === 'Yes' && 
                                         this.state.formData.exemptFromStock === 'No' &&
                                            <>
                                                <TextField
                                                    id="stockThreshold"
                                                    name="stockThreshold"
                                                    label="Stock Take Threshold"
                                                    value={this.state.formData.stockThreshold }
                                                    error={formErrors && formErrors['stockThreshold'] && true}
                                                    helperText={formErrors && formErrors['stockThreshold']}
                                                    onChange={this.handleChange()}
                                                    fullWidth
                                                    margin="normal"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                                    }}
                                                    inputProps={{
                                                        min: '0',
                                                        max: '99'
                                                    }}
                                                    type="number"
                                                />
                                                {this.state.formData.stockThreshold > 0 &&
                                                    <FormHelperText>
                                                        Based on a stock of 100, this threshold will allow stock takes between {100 - this.state.formData.stockThreshold} and {100 + parseFloat(this.state.formData.stockThreshold)}
                                                    </FormHelperText>
                                                }
                                            </>
                                        }
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={_.map(catagories, i => {return _.assign({value: i.pc_id, label: i.pc_name})})} 
                                            label='Category *'
                                            onChange={this.handleSelectChange('category')}
                                            error={formErrors && formErrors['category'] && true}
                                            errorText={formErrors && formErrors['category']}
                                            value={this.state.formData.category}
                                            noClear
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal" component="fieldset">
                                        <FormLabel component="legend">Part Discount</FormLabel>
                                        <RadioGroup
                                            name="partDiscount"
                                            value={this.state.formData.partDiscount}
                                            onChange={this.handleChange()}
                                            row
                                        >
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio color="primary" />}
                                                label="No"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio color="primary" />}
                                                label="Yes"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl fullWidth margin="normal" component="fieldset">
                                        <FormLabel component="legend">Serial Number Required</FormLabel>
                                        <RadioGroup
                                            name="reqSerialNumber"
                                            value={this.state.formData.reqSerialNumber}
                                            onChange={this.handleChange()}
                                            row
                                        >
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio color="primary" />}
                                                label="No"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio color="primary" />}
                                                label="Yes - System Generated"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="Manual"
                                                control={<Radio color="primary" />}
                                                label="Yes - Manual"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                        {formErrors && formErrors['reqSerialNumber'] && 
                                            <FormHelperText>{formErrors['reqSerialNumber']}</FormHelperText>
                                        }
                                    </FormControl>
                                    <FormControl fullWidth margin="normal" component="fieldset">
                                        <FormLabel component="legend">Warranty Void Label</FormLabel>
                                        <RadioGroup
                                            name="warrantyVoidLabel"
                                            value={this.state.formData.warrantyVoidLabel}
                                            onChange={this.handleChange()}
                                            row
                                        >
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio color="primary" />}
                                                label="No"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio color="primary" />}
                                                label="Yes"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    <TextField
                                        id="barcodeNumber"
                                        name="barcodeNumber"
                                        label="Barcode Number"
                                        value={this.state.formData.barcodeNumber}
                                        error={formErrors && formErrors['barcodeNumber'] && true}
                                        helperText={formErrors && formErrors['barcodeNumber']}
                                        onChange={this.handleChange()}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <DragFileInput
                                        id="barcodeImage"
                                        name="barcodeImage"
                                        label='Upload Barcode Image (.png, .jpg)'
                                        file={this.state.formData.barcodeImage}
                                        errorText={formErrors && formErrors['barcodeImage']}
                                        onChange={this.handleFileChange}
                                        cancelOnClick={() => this.clearFile('barcodeImage')}
                                        emptyText='No barcode selected'
                                    />
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="body2" gutterBottom><b>Add Linked Parts</b></Typography>
                                <AutoCompleteSelect
                                    placeholder="Select Part To Add..."
                                    label="Parts To Link"
                                    fullWidth
                                    value={null}
                                    options={_.filter(this.state.partList, i => !_.find(_.keys(this.state.formData.linkedParts), j => parseInt(j) === parseInt(i.value) ))}
                                    onChange={this.handleLinkedPartsChange}
                                    noClear
                                    showPlaceHolder
                                />
                                {_.keys(this.state.formData.linkedParts).length > 0 &&
                                    <List>
                                         {_.map(_.orderBy(_.keys(this.state.formData.linkedParts), [part => _.find(this.state.partList, {value: parseInt(part)})?.part.part_number], ['asc']), part =>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <ImageWithError src={_.find(this.state.partList, {value: parseInt(part)})?.part?.default_image?.thumbnail_file_path} 
                                                        style={{maxWidth: '35px', maxHeight: '35px'}}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText 
                                                    primary={_.find(this.state.partList, {value: parseInt(part)})?.part.part_number}
                                                    secondary={_.find(this.state.partList, {value: parseInt(part)})?.part.part_description}  
                                                />
                                                <ListItemSecondaryAction>
                                                    <TextField
                                                        type='number'
                                                        value={this.state.formData.linkedParts[part]}
                                                        onChange={this.handleLinkedPartsChangeQty(part)}
                                                        error={!parseFloat(this.state.formData.linkedParts[part]) || parseFloat(this.state.formData.linkedParts[part]) <= 0}
                                                        helperText={!parseFloat(this.state.formData.linkedParts[part]) || parseFloat(this.state.formData.linkedParts[part]) <= 0 ? 'Invalid Quantity' : ''}
                                                    />
                                                    <AllIcon
                                                        icon={icons.delete}
                                                        onClick={this.handleLinkedPartsRemove(part)}
                                                        noMargin
                                                    />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )}
                                    </List>
                                }
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="body2" gutterBottom>
                                    <b>Commodity Codes</b>
                                </Typography>
                                <Grid container item spacing={3}>
                                    <Grid item lg={5}>
                                        <FormControl className={classes.ccGroup} style={{marginTop: 16}} >
                                            <InputLabel htmlFor="importCommodity1" shrink={true}>Import</InputLabel>
                                            <div style={{display:'flex-inline', marginTop:8}}>
                                                <FormControl error={formErrors && formErrors['importCommodityCode'] && true}  margin="normal">
                                                    <Input
                                                        id="importCommodity1"
                                                        name="importCommodity1"
                                                        className={classes.cc1}
                                                        value={this.state.formData.importCommodity1}
                                                        onChange={this.handleChange()}
                                                        onBlur={this.commodityCodeBlur}
                                                        variant="filled"
                                                        inputProps={{
                                                            ref:this.ccInput1,
                                                            size:2,
                                                            maxLength:2,
                                                            style: {
                                                                letterSpacing: 8
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl error={formErrors && formErrors['importCommodityCode'] && true} margin="normal">
                                                    <Input
                                                        id="importCommodity2"
                                                        name="importCommodity2"
                                                        className={classes.cc2}
                                                        value={this.state.formData.importCommodity2}
                                                        onChange={this.handleChange()}
                                                        onBlur={this.commodityCodeBlur}
                                                        variant="filled"
                                                        inputProps={{
                                                            ref:this.ccInput2,
                                                            size:2,
                                                            maxLength:2,
                                                            style: {
                                                                letterSpacing: 8
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl error={formErrors && formErrors['importCommodityCode'] && true}  margin="normal">
                                                    <Input
                                                        id="importCommodity3"
                                                        name="importCommodity3"
                                                        className={classes.cc3}
                                                        value={this.state.formData.importCommodity3}
                                                        onChange={this.handleChange()}
                                                        onBlur={this.commodityCodeBlur}
                                                        variant="filled"
                                                        inputProps={{
                                                            ref:this.ccInput3,
                                                            size:11,
                                                            maxLength:6,
                                                            style: {
                                                                letterSpacing: 8
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                                {formErrors && formErrors['importCommodityCode'] &&
                                                    <FormHelperText style={{color: colors.red}}>{formErrors['importCommodityCode']}</FormHelperText>
                                                }
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={7}>
                                        <FormControl className={classes.ccGroup} style={{marginTop: 16}} >
                                            <InputLabel htmlFor="exportCommodity1" shrink={true}>Export</InputLabel>
                                            <div style={{display:'flex-inline', marginTop:8}}>
                                                <FormControl error={formErrors && formErrors['exportCommodityCode'] && true}  margin="normal">
                                                    <Input
                                                        id="exportCommodity1"
                                                        name="exportCommodity1"
                                                        className={classes.cc1}
                                                        value={this.state.formData.exportCommodity1}
                                                        onChange={this.handleChange()}
                                                        onBlur={this.commodityCodeBlur}
                                                        variant="filled"
                                                        inputProps={{
                                                            ref:this.ccExportInput1,
                                                            size:2,
                                                            maxLength:2,
                                                            style: {
                                                                letterSpacing: 8
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl error={formErrors && formErrors['exportCommodityCode'] && true} margin="normal">
                                                    <Input
                                                        id="exportCommodity2"
                                                        name="exportCommodity2"
                                                        className={classes.cc2}
                                                        value={this.state.formData.exportCommodity2}
                                                        onChange={this.handleChange()}
                                                        onBlur={this.commodityCodeBlur}
                                                        variant="filled"
                                                        inputProps={{
                                                            ref:this.ccExportInput2,
                                                            size:2,
                                                            maxLength:2,
                                                            style: {
                                                                letterSpacing: 8
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl error={formErrors && formErrors['exportCommodityCode'] && true}  margin="normal">
                                                    <Input
                                                        id="exportCommodity3"
                                                        name="exportCommodity3"
                                                        className={classes.cc3}
                                                        value={this.state.formData.exportCommodity3}
                                                        onChange={this.handleChange()}
                                                        onBlur={this.commodityCodeBlur}
                                                        variant="filled"
                                                        inputProps={{
                                                            ref:this.ccExportInput3,
                                                            size:6,
                                                            maxLength:4,
                                                            style: {
                                                                letterSpacing: 8
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                                {formErrors && formErrors['exportCommodityCode'] &&
                                                    <FormHelperText style={{color: colors.red}}>{formErrors['exportCommodityCode']}</FormHelperText>
                                                }
                                            </div>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="body2" gutterBottom>
                                    <b>Technical Library</b>
                                </Typography>
                                <FormControl fullWidth margin="normal" component="fieldset">
                                    <FormLabel component="legend">Part File Available in Technical Library (PDF Only)</FormLabel>
                                    <RadioGroup
                                        name="technicalLibraryAccess"
                                        value={this.state.formData.technicalLibraryAccess}
                                        onChange={this.handleChange()}
                                        row
                                    >
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio color="primary" />}
                                            label="No"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio color="primary" />}
                                            label="Yes"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteMultiSelect 
                                        options={this.state.partList} 
                                        label='Associated Parts - Technical Library'
                                        value={this.state.formData.associatedParts}
                                        onChange={this.handleMultiSelectChange('associatedParts')}
                                        error={formErrors && formErrors['associatedParts'] && true}
                                        errorText={formErrors && formErrors['associatedParts']}
                                    />
                                </FormControl>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="body2" gutterBottom>
                                    <b>E-Commerce</b>
                                </Typography>
                                <FormControl fullWidth margin="normal" component="fieldset">
                                    <FormLabel component="legend">E-Commerce Product</FormLabel>
                                    <RadioGroup
                                        name="ecommerceProduct"
                                        value={this.state.formData.ecommerceProduct}
                                        onChange={this.handleChange('ecommerceProduct')}
                                        row
                                    >
                                        <FormControlLabel
                                            value="No"
                                            control={<Radio color="primary" />}
                                            label="No"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Yes"
                                            control={<Radio color="primary" />}
                                            label="Yes"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                    <AutoCompleteMultiSelect 
                                        options={ecomCategoryList} 
                                        label='Categories'
                                        value={this.state.formData.ecommerceCategories}
                                        onChange={this.handleMultiSelectChange('ecommerceCategories')}
                                        error={formErrors && formErrors['ecommerceCategories'] && true}
                                        errorText={formErrors && formErrors['ecommerceCategories']}
                                        disabled={this.state.formData.ecommerceProduct === 'No' && true}
                                    />
                                </FormControl>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="body2" gutterBottom>
                                    <b>Part Weight &amp; Dimensions</b>
                                </Typography>
                                <TextField
                                    id="partWeight"
                                    name="partWeight"
                                    label="Part Weight"
                                    value={this.state.formData.partWeight}
                                    onChange={this.handleChange()}
                                    onBlur={this.handleToDecimalPlace(7)}
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">g</InputAdornment>
                                    }}
                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                    inputProps={{
                                        min: '0'
                                    }}
                                    type="number"
                                    error={     formErrors && formErrors['partWeight']}
                                    helperText={formErrors && formErrors['partWeight']}
                                    fullWidth
                                />
                                <Typography variant="subtitle2" style={{flex: 1}}>
                                    <FALightIcon icon='info-circle' size='small' noMargin /> 1000g = 1kg
                                </Typography>
                                <Grid container item spacing={3}>
                                    <Grid item xs={4}>
                                        <TextField
                                            id="partWidth"
                                            name="partWidth"
                                            label="Width"
                                            value={this.state.formData.partWidth}
                                            onChange={this.handleChange()}
                                            onBlur={this.handleToDecimalPlace(0)}
                                            margin="normal"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                            }}
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            inputProps={{
                                                min: '0'
                                            }}
                                            type="number"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            id="partHeight"
                                            name="partHeight"
                                            label="Height"
                                            value={this.state.formData.partHeight}
                                            onChange={this.handleChange()}
                                            onBlur={this.handleToDecimalPlace(0)}
                                            margin="normal"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                            }}
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            inputProps={{
                                                min: '0'
                                            }}
                                            type="number"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            id="partDepth"
                                            name="partDepth"
                                            label="Depth"
                                            value={this.state.formData.partDepth}
                                            onChange={this.handleChange()}
                                            onBlur={this.handleToDecimalPlace(0)}
                                            margin="normal"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                            }}
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            inputProps={{
                                                min: '0'
                                            }}
                                            type="number"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="body2" gutterBottom>
                                    <b>Shipping Weight &amp; Dimensions</b>
                                </Typography>
                                <TextField
                                    id="shippingWeight"
                                    name="shippingWeight"
                                    label="Shipping Weight"
                                    value={this.state.formData.shippingWeight}
                                    onChange={this.handleChange()}
                                    onBlur={this.handleToDecimalPlace(7)}
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">g</InputAdornment>
                                    }}
                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                    inputProps={{
                                        min: '0'
                                    }}
                                    type="number"
                                    fullWidth
                                />
                                <Typography variant="subtitle2" style={{flex: 1}}>
                                    <FALightIcon icon='info-circle' size='small' noMargin /> 1000g = 1kg
                                </Typography>
                                <Grid container item spacing={3}>
                                    <Grid item xs={4}>
                                        <TextField
                                            id="shippingWidth"
                                            name="shippingWidth"
                                            label="Width"
                                            value={this.state.formData.shippingWidth}
                                            onChange={this.handleChange()}
                                            onBlur={this.handleToDecimalPlace(0)}
                                            margin="normal"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                            }}
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            inputProps={{
                                                min: '0'
                                            }}
                                            type="number"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            id="shippingHeight"
                                            name="shippingHeight"
                                            label="Height"
                                            value={this.state.formData.shippingHeight}
                                            onChange={this.handleChange()}
                                            onBlur={this.handleToDecimalPlace(0)}
                                            margin="normal"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                            }}
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            inputProps={{
                                                min: '0'
                                            }}
                                            type="number"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            id="shippingDepth"
                                            name="shippingDepth"
                                            label="Depth"
                                            value={this.state.formData.shippingDepth}
                                            onChange={this.handleChange()}
                                            onBlur={this.handleToDecimalPlace(0)}
                                            margin="normal"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">mm</InputAdornment>
                                            }}
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            inputProps={{
                                                min: '0'
                                            }}
                                            type="number"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <div className='buttonRow'>
                                    <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary"
                                        >
                                        Add
                                    </Button>
                                </div>
                            </PaddedPaper>
                        </Grid>
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen} 
                            success={this.handleConfirmationSuccess} 
                            close={this.handleConfirmationClose} 
                            title="Add A New Part?" 
                            message="Are you sure you want to add this new part?"
                        />
                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen}
                            onClose={this.handleSnackbarClose}
                            message='You have successfully added a new part'
                        />
                    </Grid>
                    </>
                )}
            </Grid>
        );
    }
}

export default withStyles(styles)(AddPart);