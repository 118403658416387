import React            from 'react';
import { connect }      from 'react-redux';
import { withStyles }   from '@material-ui/core/styles';
import moment           from 'moment';
import API              from 'API';

import {IconButton, Card, CardHeader, CardContent, CardActions, Avatar, Typography  } from '@material-ui/core';

import FALightIcon      from 'Components/Common/Icons/FontAwesome/FALightIcon';
import AddNoteDialog    from 'Components/CustomerReturns/ViewCreditNote/AddNoteDialog';

import { deployConfirmation }   from 'Actions/Confirmation/Confirmation';
import { deployDialog }         from 'Actions/Dialog/Dialog';

const styles = theme => ({
    paperGrey : {
        padding : theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding : theme.spacing(3),
        },
        backgroundColor :'#f8f9fa'
    },
});

class CreditNoteNote extends React.Component {
    constructor(props) {
        super(props);
    }

    delete = id => {
        API.post(`/misc/notes/${id}/delete`,{type:'credit-notes'})
        .then(res => {this.props.callback()});
    }

    render() {

        const { classes, note } = this.props;

        return (
            <Card className={classes.paperGrey}>
                <CardHeader
                    avatar={<Avatar alt={`${note.staff.staff_first_name}`} src={note.staff.staff_profile_photo} />}
                    title={note.cnn_staff_name}
                    subheader={`${moment(note.cnn_datetime_added).format("DD/MM/YYYY")}`}
                    action={
                        <>
                            <IconButton onClick={()=>{this.props.deployConfirmation('Are you sure you want to delete this note', 'Delete Note', ()=>{this.delete(note.cnn_id)}) }} >
                                <FALightIcon icon='times' size="small" button noMargin/>
                            </IconButton>
                            <IconButton onClick={()=>{this.props.deployDialog(<AddNoteDialog noteId={note?.cnn_id} callback={this.props.callback} note={note.cnn_text} />,'Update Credit Note Note', 'success')}}>
                                <FALightIcon icon='pencil' size="small" button noMargin/>
                            </IconButton>
                        </>
                    }
                />
                <CardContent>
                    {note?.cnn_type !== 'Note' && 
                        <Typography component="h6">{note?.cnn_type} Note</Typography>
                    }
                    <Typography component="p">
                        {note?.cnn_text.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                    </Typography>
                </CardContent>
                {!!note?.cnn_updated_by_staff_id && (
                    <CardActions className="float-right pl-2">
                        <Typography component="p" variant="body2" className="textSecondary">
                            <small>Updated by {note?.cnn_updated_by_staff_name} at {moment(note?.cnn_datetime_updated).format('DD/MM/YYYY')}</small>
                        </Typography>
                    </CardActions>
                )}
            </Card>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
        deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size))
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(CreditNoteNote))