import { Button, Grid, TextField, Typography } from "@material-ui/core";
import API from "API";
import DataTable from "Components/Common/DataTables/CiDataTable";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import React from "react";

const initialState = () => ({
    formData: {
        name: '',
    },
    conditions: [],
    isLoading: true
})

class RepairWriteOffConditions extends React.Component {
    constructor(props){
        super(props)
        this.state = initialState();
    }

    componentDidMount(){
        this.getConditions();
    }

    handleChange = name => event => {
        let formData = this.state.formData;
        formData[name] = event.target.value; 
        this.setState({ formData });
    }

    handleSelectChange = name => ({value}) => {
        let formData = this.state.formData;
        formData[name] = value;
        this.setState({ formData });
    }

    handleAdd = () => {
        const {formData} = this.state
        API.post('/customerReturns/defaults/writeOffReasons', formData)
        .then((res) => {
            this.setState(initialState(), 
                this.getConditions
            )
        })
    }

    getConditions = () => {
        API.get('/customerReturns/defaults/writeOffReasons')
        .then(({data})=>{
            this.setState({conditions: data, isLoading: false})
        })
    }

    render () {

        const {formData, conditions, isLoading} = this.state

        if (isLoading) return ( <LoadingCircle/> );

        return (
            <Grid container spacing={3}>
                <Grid item xs={5}>
                    <PaddedPaper>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    Add Write Off Reason
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label='Name *'
                                    value={formData.name}
                                    onChange={this.handleChange('name')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} className="buttonRow">
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={this.handleAdd}
                                    disabled={
                                        !formData.name
                                    }
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable
                            config={{
                                key: 'rowor_id'
                            }}
                            columns={[
                                {
                                    field: 'rowor_name',
                                    heading: 'Reason'
                                },
                               
                            ]}
                            rows={conditions}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
    }

}

export default RepairWriteOffConditions;