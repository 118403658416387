import React from "react";
import ActivityLog from 'Components/Common/ActivityLog/ActivityLog';
import { colors } from "Helpers/ColourHelper";
import IconHelper from "Helpers/IconHelper";
import _ from "lodash";

const aclToItem = {
    Created: {
        color: colors.primary,
        icon: IconHelper.plus,
    },
    Updated: {
        color: colors.primary,
        icon: IconHelper.edit,   
    },
    'Contacts Added': {
        color: colors.primary,
        icon: IconHelper.plus,
    },
    'Contact Removed': {
        color: colors.red,
        icon: IconHelper.minus,
    },
    'Survey Sent': {
        color: colors.primary,
        icon: IconHelper.email,
    },
    'Survey Ended': {
        color: colors.red,
        icon: IconHelper.false,
    }
}

export default function ({log}){
    return (
        <ActivityLog
            filter={
                [
                    {label: 'Survey', types: ['Survey Sent', 'Created', 'Updated', 'Survey Ended']},
                    {label: 'Contacts', types: ['Contacts Added', 'Contact Removed']},
                ]
            } 
            fields={{
                type:   'sl_type',
                date:   'sl_datetime',
                icon:   { color: 'color', icon: 'icon' },
                text:   'sl_text',
                staff:  'sl_staff',
            }}
            atl={_.map(log, i => ({
                ...i,
                color: aclToItem[i.sl_type]?.color,
                icon: aclToItem[i.sl_type]?.icon,
            }))}
        />
    )
}