import React, { PureComponent } from 'react';

import {Grid, Typography} from '@material-ui/core/';

import StockReportsOverview   from './sections/StockReportsOverview';
import StockReportsStock      from './sections/StockReportsStock';
import StockReportsPending    from './sections/StockReportsPending';
import StockReportsCosts      from './sections/StockReportsCosts';
import StockReportsLevels     from './sections/StockReportsLevels';
import StockReportsDeliveries from './sections/StockReportsDeliveries';
import StockReportsShortages  from './sections/StockReportsShortages'; 

import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';
import _ from 'lodash';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = { 
    access:     {
        'stockreport-overview':      false,
        'stockreport-stock':    false,
        'stockreport-pending':      false,
        'stockreport-costs':     false,
        'stockreport-levels': false,
        'stockreport-deliveries':  false,
        'stockreport-wos':  false
    },
    currentTab: 0,
    isLoading:  true,
}

class StockReports extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getAccess();
    }

    getAccess = () => {
        let accessArr = _.keys(this.state.access);
        Promise.all(_.map(accessArr, i => API.get(`/staff/my/access/check/${i}`)))
            .then(res => {
                let access = this.state.access;
                _.each(res, (i,idx) => {
                    access[accessArr[idx]] = i.data.has_access ?? false;
                });
                this.setState({access, isLoading: false});
            })
    }

    render() {

        const { access, isLoading } = this.state;

        if (isLoading) {
            return <LoadingCircle/>
        }

        return (
            <Grid container xs={12} style={{width: '100%'}}>
                <Grid item xs={12} style={{ marginBottom: '1.5em'}}>
                    {( this.props.partId || this.props.cid ) ?
                        <></> :
                        <Typography variant="h5">
                            Stock Reports
                        </Typography>
                    }
                </Grid>
                {_.sum(_.values(access)) > 0 ?
                    <Grid item xs={12} style={{ marginBottom: '1.5em'}}>
                        <TabBarSwitcher
                            tabs={_.filter([
                                {
                                    label:   'Overview',
                                    content: <StockReportsOverview partId={this.props.partId}/>
                                },
                                {
                                    label:   'Stock',
                                    content: <StockReportsStock partId={this.props.partId}/>
                                },
                                {
                                    label:   'Pending',
                                    content: <StockReportsPending partId={this.props.partId}/>
                                },
                                {
                                    label:   'Costs',
                                    content: <StockReportsCosts partId={this.props.partId}/>
                                },
                                {
                                    label:  'Levels',
                                    content: <StockReportsLevels partId={this.props.partId}/>
                                },
                                {
                                    label:  'Deliveries',
                                    content: <StockReportsDeliveries partId={this.props.partId}/>
                                },
                                {
                                    label:  'Works Order Shortages',
                                    content: <StockReportsShortages partId={this.props.partId}/>
                                }
                            ], (i,idx) => _.values(access)[idx])}
                        />
                    </Grid> :
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            You do not have access to any stock reports
                        </Typography>
                    </Grid>
                }
            </Grid>
        )
    }
};

export default StockReports;

