import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import API from 'API';
import { ACCOUNTS_EMAIL } from 'Constants';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import _, { assign, find, map } from 'lodash';

import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { Button, FormControl, Typography } from '@material-ui/core';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import { colors } from 'Helpers/ColourHelper';

const EmailCreditLimitDialog = ({ customerId, addressId, callback, onclose, full, range }) => {

    const dispatch = useDispatch();

    const initialState = {
        formErrors: {},
        formData: {
            type: '',
            id: '',
            emailTemplate: '',
            emailTo: '',
            emailName: '',
            emailAddress: '',
            emailCc: [],
            staff: [],
            emailText: '',
            to: range?.to,
            from: range?.from
        },
        emailTemplateList: [],
        customerContactList: [],
        staffList: [],
        countryList: [],
        isLoading: true,
        redirect: false
    };
    
    let [state, setState] = useState({...initialState});
    
    const {formErrors, formData, 
        formData:{emailTemplate, emailTo, emailCc, staff, emailText, addressCountry}, 
        emailTemplateList, customerContactList, staffList, isLoading, countryList} = state;
    
    const emailTemplateId = full ? 22 : 21;
        
    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleSelectChange = fieldName => selectedOption => {
        setState(state => ({
            ...state,
            formData:{
                ...formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }));
    }

    const handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [fieldName]: values
            }
        }));
    }

    const handleEmailDialogClose = () => {
        if (onclose) onclose();
        dispatch(closeDialog());
    }

    const handleSubmit = () => dispatch(deployConfirmation('Are you sure you want to send email to customer?', 'Send Email?', submit));

    const submit = () => {
        setState(state => ({...state, isLoading: true}));
        API.post(`/customers/${customerId}/accounts/addresses/${addressId}/statement/email`, {...formData, full})
        .then(result => {
            if(result.data.errors) {
                setState(state => ({
                    ...state, 
                    formErrors: formatValidationErrors(result.data.errors), 
                    isLoading: false
                }));
            } else if(result.data.success) {
                handleEmailSuccess();
            }
        });
    }

    const handleEmailSuccess = () => {
        if(callback) callback();
        handleEmailDialogClose();
        dispatch(deploySnackBar('success', 'You have successfully sent email'));
    }

    // undisable all when emailTo is cleared
    const resetFields = useCallback(() => {
        customerContactList.forEach(el => el.disabled = false);
        setState(state => ({...state, customerContactList}));

    }, [customerContactList])

    const setFields = useCallback(() => {
        let customer = find(customerContactList, { 'value': emailTo});
        let newContactList = [...customerContactList];
        let newEmailCcList = [...emailCc];

        for(let i in newContactList) {
            if(newContactList[i].value === emailTo) {
                newContactList[i].disabled = true;
                if(emailCc.includes(emailTo)) {
                    newEmailCcList = newEmailCcList.filter(id => id !== emailTo);
                }
            } else {
                newContactList[i].disabled = false;
            }
        }

        setState(state => ({
            ...state, 
            customerContactList: newContactList,
            formData: {
                ...state.formData,
                emailName: customer.name,
                emailAddress: customer.email,
                emailCc: newEmailCcList
            }
        }));

    }, [customerContactList, emailTo, emailCc])

    useEffect(() => {
        let country = find(countryList, { 'value': addressCountry});
        setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                addressCountryCode: country?.code
            }
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressCountry]);

    // Handle email formData prefill on emailTo change (required for send email on submit) and emailcc disabling
    useEffect(() => {
        if(emailTo && emailTo !== '') {
            setFields();
        } else {
            resetFields();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailTo]);

    // Handle prefill email text from on email template change 
    useEffect(() => {
        if(emailTemplateList && emailTemplateList.length > 0 && emailTemplate !== '') {
            let selectedEmailTemplate = find(emailTemplateList, { 'value': emailTemplate });

            setState(state => ({
                ...state,
                formData: {
                    ...state.formData,
                    emailText: selectedEmailTemplate ? selectedEmailTemplate.text : ''
                }
            }));
        }

    }, [emailTemplate, emailTemplateList]);
    
    // On load, get email templates, activ staff and customer contacts, then format for autocomplete select
    useEffect(() => {
        let promiseArr = [
            API.get('/sales/emailTemplates'),
            API.get(`/customers/${customerId}/contacts`),
            API.get('/staff/all'),
            API.get('/misc/countries/all'),
            API.get(`/customers/addresses/${addressId}`)
        ];

        Promise.all(promiseArr)
        .then(([emailRes, contactRes, staffRes, countryRes, addrRes]) => {
            let emailTemplateList = map(emailRes.data, el => {
                return assign({
                    value:  el.set_id,
                    label:  el.set_name,
                    text:   el.set_text
                });
            });

            let customerContactList = map(_.filter(contactRes.data, i => {return i.contact_email}), el => {
                    return assign({
                        value: el.contact_id,
                        label: el.contact_name + ' (' + el.contact_email + ')',
                        name: el.contact_name,
                        email: el.contact_email
                    });
            });

            let staffList = map(staffRes.data, el => {
                return assign({
                    value: el.staff_id,
                    label: el.staff_first_name + ' ' + el.staff_last_name,
                });
            });

            let countryList = map(countryRes.data, el => {
                return assign({
                    value: el.country_id,
                    label: el.country_name,
                    code: el.country_code,
                });
            });

            const customerContactId = addrRes.data.active_contacts[0].contact_id;
            
            setState(state => ({
                ...state, 
                formData: {
                    ...state.formData,
                    emailTemplate: emailTemplateId,
                    emailTo: customerContactId && parseInt(customerContactId)
                },
                emailTemplateList, 
                customerContactList,
                staffList,
                countryList,
                isLoading: false
            }));
        });

    }, [customerId, addressId]);

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <>
                    <br></br>
                    {formErrors && formErrors.generic && (
                        <React.Fragment>
                            <Typography component={"div"} style={{color: colors.red}}>
                                {formErrors.generic}
                            </Typography>
                        </React.Fragment>
                    )}
                    <Typography variant="body2" gutterBottom>
                        <>Clenaware Accounts {`<${ACCOUNTS_EMAIL}>`}</>:
                    </Typography>
                    <form noValidate autoComplete="off">
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect
                                id="emailTo"
                                name="emailTo"
                                options={customerContactList} 
                                label='To *'
                                value={emailTo}
                                onChange={handleSelectChange('emailTo')}
                                error={formErrors && formErrors['emailTo'] && true}
                                errorText={formErrors && formErrors['emailTo'] && formErrors['emailTo']}
                            />
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <AutoCompleteMultiSelect 
                                options={customerContactList} 
                                label='Email Cc'
                                value={emailCc}
                                onChange={handleMultiSelectChange('emailCc')}
                                error={formErrors && formErrors['emailCc'] && true}
                                errorText={formErrors && formErrors['emailCc']}
                            />
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <AutoCompleteMultiSelect 
                                options={staffList} 
                                label='Staff'
                                value={staff}
                                onChange={handleMultiSelectChange('staff')}
                                error={formErrors && formErrors['staff'] && true}
                                errorText={formErrors && formErrors['staff']}
                            />
                        </FormControl>
                        <FormControl error={formErrors && formErrors['emailTemplate'] && true} fullWidth margin="normal">
                            <AutoCompleteSelect
                                options={emailTemplateList} 
                                label='Email Template'
                                value={emailTemplate}
                                onChange={handleSelectChange('emailTemplate')}
                                error={formErrors && formErrors['emailTemplate'] && true}
                                errorText={formErrors && formErrors['emailTemplate'] && formErrors['emailTemplate']}
                            />
                        </FormControl>
                        <Textarea
                            id="emailText"
                            name="emailText"
                            label="Email Text *"
                            value={emailText}
                            rows={5}
                            error={formErrors && formErrors['emailText']}
                            onChange={handleChange}
                        />
                        <br></br>
                    </form>
                    <div className="buttonRow">
                        <Button 
                            onClick={handleEmailDialogClose} 
                            variant="contained" 
                            color="secondary"
                        >
                            Don't Send
                        </Button>
                        <Button 
                            onClick={handleSubmit} 
                            autoFocus 
                            variant="contained" 
                            color="primary" 
                            style={{backgroundColor:'#5da14d', color:'white'}}
                        >
                            Send
                        </Button>
                    </div>
                </>
            )}
        </React.Fragment>
    );
}

export default EmailCreditLimitDialog;