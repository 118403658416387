import React                         from 'react';
import { WEB_EMAIL, ACCOUNTS_EMAIL } from 'Constants';
import { connect }                   from 'react-redux';
import API                           from 'API';
import _                             from 'lodash';

import LoadingCircle           from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import Textarea                from 'Components/Common/Inputs/Textarea';
import AllIcon                 from 'Components/Common/Icons/AllIcon';

import {Typography, FormControl, Button, Grid} from '@material-ui/core';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';
import { stringMarkerReplace }    from 'Helpers/DocumentHelper';

import { closeDialog }        from 'Actions/Dialog/Dialog';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = {
    formErrors: {},
    formData:   {
        emailTemplate: '',
        emailTo:       '',
        emailName:     '',
        emailAddress:  '',
        emailCc:       [],
        staff:         [],
        emailText:     '',
    },
    emailTemplateList:   [],
    customerContactList: [],
    staffList:           [],
    isLoading:           true,
    redirect:            false
};

class GenericEmailDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getLists();
    }

    getLists = () => {
        const { customerId,  contactId, templateId, templates } = this.props;  
        let promise = [];

        switch(templates){
            case 'sales':
                promise.push(API.get('/sales/emailTemplates'));
            break;
            case 'engineers':
                promise.push(API.get('/engineers/emailTemplates'));
            break;
            default:
                promise.push(API.get('/email/emailTemplates'));
            break;
        }

        promise.push(API.get('/staff/all'));

        if ( customerId ) 
            promise.push(API.get(`/customers/${customerId}/contacts`));

        Promise.all(promise)
        .then(([templatesRes, staffRes, contactsRes]) => {

            let emailTemplateList = _.map(templatesRes.data, el => {

                let value = '', label = '', text = '';

                switch(templates){
                    case 'sales':
                        value = el.set_id
                        label = el.set_name
                        text  = el.set_text
                    break;
                    case 'engineers':
                        value = el.id
                        label = el.nm
                        text  = el.text
                    break;
                    default:
                        value = el.email_template_id
                        label = el.email_template_name
                        text  = el.email_template_text
                    break;
                }

                return _.assign({ value, label, text });
            });

            let customerContactList = customerId ? _.map(_.filter(contactsRes.data, i => {return i.contact_email}), el => {
                return _.assign({
                    value: el.contact_id,
                    label: el.contact_name + ' (' + el.contact_email + ')',
                    name: el.contact_name,
                    email: el.contact_email
                });
            }) : [];

            let staffList = _.map(staffRes.data, el => {
                return _.assign({
                    value: el.staff_id,
                    label: el.staff_first_name + ' ' + el.staff_last_name,
                });
            });

            this.setState({
                emailTemplateList,
                customerContactList,
                staffList,
                isLoading: false,
                formData: {
                    ...this.state.formData,
                    emailTemplate: templateId,
                    emailText:     stringMarkerReplace(_.find(emailTemplateList, {value: templateId}).text,  this.props.replace),
                    emailName:     templateId ? _.find(emailTemplateList, {value: templateId}).label : '',
                    emailTo:       contactId,
                    attached:      this.props.attached,
                    staff:         this.props.staff
                }
            })
        })
    }

    getTemplates = () => {
        API.get('/email/emailTemplates')
        .then(res => {
            this.setState({
                emailTemplateList: res.data,
                isLoading: false
            });
        })
    }

    handleSelectChange = field => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: e && e.value,
                emailText: field === 'emailTemplate' ? 
                    _.find(this.state.emailTemplateList, {value: e.value}).text : 
                    this.state.formData.emailText,
                emailAddress: field === 'emailTo' ? 
                    e.email :  this.state.formData.emailTo
            }
        })
    }


    handleMultiSelectChange = fieldName => e => {
        let values = e ? _.map(e, a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        })
    }

    handleChange = field => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: e.target.value
            }
        })
    }

    submit = () => {    
        this.setState({
            isLoading: true
        }, ()=> {

            let url    = this.props.url    || '/email/send';
            let params = this.props.params || {};

            let PostData = new FormData();
            _.each(_.keys(params),              i => { PostData.append(i, params[i]); });
            _.each(_.keys(this.state.formData), i => { PostData.append(i, this.state.formData[i]); });
            PostData.append('from', this.props.accounts ? ACCOUNTS_EMAIL : WEB_EMAIL);

            API.post(
                url,
                PostData
            ).then(res => {
                if(res.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(res.data.errors), 
                        isLoading: false
                    });
                } else {
                    this.props.onSend && this.props.onSend();
                    this.props.closeDialog();
                }
            })
        });
    }

    removeAttached = index => {
        this.setState({
            formData: {
                ...this.state.formData,
                attached: this.state.formData.attached.filter((el, i) => i !== index)
            }
        })
    }

    render(){
        const { isLoading, formData, formErrors, emailTemplateList, customerContactList, staffList } = this.state;
        const { emailTemplate, emailTo, emailCc, staff, emailText, attached } = formData;
        const { accounts } = this.props; 
        
        if ( isLoading ) return ( <LoadingCircle /> );

        return (
            <Grid container spacing={3}>
                {formErrors && formErrors.generic && 
                    <Grid item xs={12}>
                        <Typography component={"div"} style={{color: colors.red}}>
                            {formErrors.generic}
                        </Typography>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Typography variant="body2">
                        From: {accounts ?
                            <>Clenaware Accounts {`<${ACCOUNTS_EMAIL}>`}</>:
                            <>Clenaware Systems {`<${WEB_EMAIL}>`}</>
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="none">
                        <AutoCompleteSelect
                            id="emailTo"
                            name="emailTo"
                            options={_.unionBy(customerContactList, 'label')} 
                            label='To *'
                            value={emailTo}
                            onChange={this.handleSelectChange('emailTo')}
                            error={formErrors && formErrors['emailTo'] && true}
                            errorText={formErrors && formErrors['emailTo'] && formErrors['emailTo']}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl margin="none" fullWidth>
                        <AutoCompleteMultiSelect 
                            options={customerContactList} 
                            label='Email Cc'
                            value={emailCc}
                            onChange={this.handleMultiSelectChange('emailCc')}
                            error={formErrors && formErrors['emailCc'] && true}
                            errorText={formErrors && formErrors['emailCc']}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl margin="none" fullWidth>
                        <AutoCompleteMultiSelect 
                            options={staffList} 
                            label='Staff'
                            value={staff}
                            onChange={this.handleMultiSelectChange('staff')}
                            error={formErrors && formErrors['staff'] && true}
                            errorText={formErrors && formErrors['staff']}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl error={formErrors && formErrors['emailTemplate'] && true} fullWidth margin="none">
                        <AutoCompleteSelect
                            options={emailTemplateList} 
                            label='Email Template'
                            value={emailTemplate}
                            onChange={this.handleSelectChange('emailTemplate')}
                            error={formErrors && formErrors['emailTemplate'] && true}
                            errorText={formErrors && formErrors['emailTemplate'] && formErrors['emailTemplate']}
                        />
                    </FormControl>
                </Grid>
                { !!attached && attached.length > 0 && 
                    <Grid item xs={12}>
                        Attached 
                        <Grid container spacing={2} style={{marginTop: 5}}>
                            {_.map(attached, (i, index) => 
                                <Grid item >
                                    <Grid container spacing={1} style={{alignItems: 'center', border: `1px solid ${colors.disabled}`, backgroundColor: colors.blue}}>
                                        <Grid item style={{color: colors.white, padding: 7}}>{i.label}</Grid>
                                        <Grid item
                                            style={{backgroundColor: colors.darkBlue, padding: 7}}
                                        >
                                            <AllIcon
                                                icon={icons.close}
                                                size='small'
                                                toolTop='Remove'
                                                onClick={() => this.removeAttached(index)}
                                                color={colors.white}
                                                noMargin
                                                noPadding
                                                heavy
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>    
                            )}
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Textarea
                        id="emailText"
                        name="emailText"
                        label="Email Text *"
                        value={emailText}
                        rows={5}
                        error={formErrors && formErrors['emailText']}
                        onChange={this.handleChange('emailText')}
                        margin='none'
                    />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                    <Button variant='contained' color='primary' disabled={!formData.emailTo} onClick={this.submit}>Send</Button>
                </Grid>
            </Grid>
        )
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff
    };
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
    closeDialog:        ()                              => dispatch(closeDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(GenericEmailDialog);