import {
    Box,
    Grid,
    Tooltip,
    Typography
} from '@material-ui/core';
import API from 'API';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import TabBar from 'Components/Common/Navigation/TabBar';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { TabPanel } from 'Functions/MiscFunctions';
import { colors } from 'Helpers/ColourHelper';
import _ from 'lodash';
import moment from 'moment';
import React,{ useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import EquipmentHistoryRows from '../Misc/EquipmentHistoryRows';
import EquipmentCustomerReturns from './Tabs/EquipmentCustomerReturns';

const ViewEquipment = (props) => {

    const   [dataLoading, setDataLoading] = useState(true),
            [equip, setEquipment] = useState({}),
            // [equipmentId, setEquipmentId] = useState(0),
            [currentTab, setCurrentTab] = useState('overview'),
            [access, setAccess] = useState({customerEquipment: false});

    useEffect(() => {
        getAccess();
        getEquipment();
    }, []);

    const getAccess = () => {
        API.get('/staff/my/access/check/view-customer-tab-equipment')
        .then(result => {
            if(result.data?.has_access) {
                setAccess({...access, customerEquipment: true});
            }
        });
    }

    const getEquipment = () => {
        API.get(`/equipment/${props.match.params.equipmentId}`)
        .then(result => {

            setDataLoading(false);

            if(result.data) {

                setEquipment(result.data);
            }  
        });
    }

    let type = '';
    let linkUrl = null;
    switch(equip?.details?.type){
        case 'creditNote':
            type    = 'Credit Note';
            linkUrl = '/returns/creditnote/';
        break;
        case 'order':
            type    = 'Order';
            linkUrl = '/sales/order/view/';
        break;
        case 'customerReturn':
            type    = 'Customer Return';
            linkUrl = '/returns/customerReturn/';
        break;
        case 'manuallyAddedReturn':
            type = 'Manually Added Return';
        break;
        default:
            type    = null;
            linkUrl = null;
        break;
    }

    const tabItems = [
        {name: 'overview',          label: `Overview` },
        {name: 'customerReturns',   label: `Customer Returns` },
        {name: 'history',           label: `History` }
    ]

    const OrderDetails = () => {
        return (
            <>
            <Typography variant="h6" gutterBottom>
                {type}
            </Typography>
            <Typography variant="body2" style={{display: 'flex', alignItems: 'center'}}>
                <span style={{flex: 1}}>Reference: </span>
                <Link to={linkUrl + equip?.details?.id} className='blueLink' style={{flex: 2}}>
                    {equip?.details?.ref ? equip?.details?.ref : '-'}
                </Link>
            </Typography>
            <Typography variant="body2" style={{display: 'flex', alignItems: 'center'}}>
                <span style={{flex: 1}}>Order Date: </span>
                <span style={{flex: 2}}>{equip?.details?.date && moment(equip?.details?.date).format('DD/MM/YYYY')}</span>
            </Typography>
            <Typography variant="body2" style={{display: 'flex', alignItems: 'center'}}>
                <span style={{flex: 1}}>Picking Date: </span>
                <span style={{flex: 2}}>
                    {equip?.details?.despatchReq == 'Yes' ? moment(equip?.details?.pickingDate).format('DD/MM/YYYY') : <i> No Despatch</i>}
                </span>
            </Typography>
            <Typography variant="body2" style={{display: 'flex', alignItems: 'center'}}>
                <span style={{flex: 1}}>Despatch Date: </span>
                <span style={{flex: 2}}>
                    {equip?.details?.despatchReq == 'Yes' ? (equip?.details?.despatchDate ? moment(equip?.details?.despatchDate).format('DD/MM/YYYY') : '-') : <i> No Despatch</i>}
                </span>
            </Typography>
            <Typography variant="body2" style={{display: 'flex', alignItems: 'center'}}>
                <span style={{flex: 1}}>Delivery Date: </span>
                <span style={{flex: 2}}>
                    {equip?.details?.despatchReq == 'Yes' ? (equip?.details?.deliveryDate ? moment(equip?.details?.deliveryDate).format('DD/MM/YYYY') : '-') : <i> No Despatch</i>}
                </span>
            </Typography>
            <Typography variant="body2" paragraph  style={{display: 'flex', alignItems: 'center'}}>
                <span style={{flex: 1}}>Invoice Date: </span>
                <span style={{flex: 2}}>{equip?.invoiceD ? moment(equip?.invoiceD).format('DD/MM/YYYY') : '-'}</span>
            </Typography>
            </>
        )
    }
    
    return (
        (dataLoading && <LoadingCircle />) || (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        View Equipment
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Typography variant="h5">
                                    <Tooltip
                                        title={equip.m ? 'Manual' : 'System Generated'}
                                        placement="left"
                                    >
                                        <span>
                                        <AllIcon 
                                            icon={equip?.m ? 'keyboard' : 'laptop-code'}
                                            button
                                            noMargin
                                        />
                                        </span>
                                    </Tooltip>
                                    <b> {equip?.sn}</b>
                                </Typography>
                                <Typography variant="h6">
                                    {equip?.partNo} - {equip?.partDesc}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <TabBar
                        tabs={_.map(_.filter(tabItems, i => i?.label), i => ({label: i.label, value: i.name}))}
                        currentTab={currentTab} 
                        handleTabChange={(event, value) => setCurrentTab(value)}
                    />
                </Grid>
                <Grid item xs={12}>

                    { /* Overview */ }
                    <TabPanel value={currentTab} index={'overview'}>
                        <Grid container item spacing={3}>
                            <Grid item xs={6}>
                                <PaddedPaper>
                                    <Box>
                                        <Typography variant="h6" gutterBottom>
                                            Latest Details
                                        </Typography>
                                        
                                        {equip?.s == 'Condemned' ? (
                                            <Box style={{backgroundColor: colors.red, color: 'white', padding: 20}}>
                                                <Typography variant="h6">
                                                    <AllIcon icon="skull-crossbones" color="white"  />
                                                    <b>Condemned</b>
                                                </Typography>
                                                <Typography variant="body2" paragraph>
                                                    This equipment was condemned on <b>{moment(equip?.condemnedD).format('DD/MM/YYYY')}</b> 
                                                </Typography>

                                                {equip?.reason && (
                                                    <Typography variant="body2" paragraph>
                                                        <i>{equip?.reason}</i>
                                                    </Typography>
                                                )}
                                            </Box>
                                        )
                                        :
                                        equip?.moveD ? (
                                            <Box style={{backgroundColor: colors.darkBlue, color: 'white', padding: 20}}>
                                                <Typography variant="h6">
                                                    <AllIcon icon="person-dolly" color="white"/>
                                                    <b>Moved</b>
                                                </Typography>
                                                <Typography variant="body2" paragraph>
                                                    This equipment was moved on <b>{moment(equip?.moveD).format('DD/MM/YYYY')}</b>
                                                </Typography>
                                                {equip?.reason && (
                                                    <Typography variant="body2" paragraph>
                                                        <i>{equip?.reason}</i>
                                                    </Typography>
                                                )}
                                            </Box>
                                        )
                                        :
                                        (
                                            <>
                                            {(equip?.s == 'Sold' && !equip?.invoiceD) && (
                                                <Box style={{backgroundColor: colors?.orange, color: 'white', padding: 20}}>
                                                    <Typography variant="h6">
                                                        <AllIcon icon="check" color="white"/>
                                                        <b>Sold - Awaiting Invoice</b>
                                                    </Typography>
                                                    <Typography variant="body2" paragraph>
                                                        This equipment is waiting to be invoiced
                                                    </Typography>
                                                </Box>
                                            )}
                                            {(equip?.s == 'Sold' && equip?.invoiceD) && (
                                                <Box style={{backgroundColor: colors?.green, color: 'white', padding: 20}}>
                                                    <Typography variant="h6">
                                                        <AllIcon icon="check" color="white"/>
                                                        <b>Sold</b>
                                                    </Typography>
                                                    <Typography variant="body2" paragraph>
                                                        This equipment has been sold
                                                    </Typography>
                                                </Box>
                                            )}
                                            
                                            {equip?.s == 'Returned' && (
                                                <Box style={{backgroundColor: colors?.red, color: 'white', padding: 20}}>
                                                    <Typography variant="h6">
                                                        <AllIcon icon="box" color="white"/>
                                                        <b>Returned</b>
                                                    </Typography>
                                                    <Typography variant="body2" paragraph>
                                                        This equipment was returned via {type}
                                                    </Typography>
                                                </Box>
                                            )}

                                            {equip?.s == 'Written Off' && (
                                                <Box style={{backgroundColor: colors?.red, color: 'white', padding: 20}}>
                                                    <Typography variant="h6">
                                                        <AllIcon icon="square-fragile" color="white"/>
                                                        <b>Written Off</b>
                                                    </Typography>
                                                    <Typography variant="body2" paragraph>
                                                        This equipment was written off via {type}
                                                    </Typography>
                                                </Box>
                                            )}

                                            {equip?.s == 'Replaced' && (
                                                <Box style={{backgroundColor: colors?.red, color: 'white', padding: 20}}>
                                                    <Typography variant="h6">
                                                        <AllIcon icon="handshake" color="white"/>
                                                        <b>Replaced</b>
                                                    </Typography>
                                                    <Typography variant="body2" paragraph>
                                                        This equipment was replaced via {type}
                                                    </Typography>
                                                </Box>
                                            )}

                                            </>
                                        )}
                                        
                                    </Box>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={6}>
                                <PaddedPaper>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={6}>
                                            <Typography variant="h6" gutterBottom>
                                                Customer
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                {equip?.cust?.name ? 
                                                    <>
                                                    <Link to={{ pathname: `/customers/view/${equip?.cust?.id}/equipment`}} className='blueLink'>
                                                        <AllIcon icon='users' size='small' button style={{marginRight: 10}} color={colors.blue} /> {equip?.cust?.name}
                                                    </Link> 
                                                    <Typography variant="body2" style={{whiteSpace: 'pre-wrap'}}>
                                                        <small><b>Site Address:</b><br /></small>
                                                        {equip?.details?.addressNm}<br />
                                                        {equip?.details?.address.split('\n').map((item, key) => {
                                                            return  <span key={key}>
                                                                        {item}{equip?.details?.address.split('\n').length === key+1 ? null : <br/>}
                                                                    </span>
                                                        })}
                                                    </Typography>
                                                    </>
                                                :
                                                    <i>No Customer</i>
                                                }
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6}>
                                            {type && (
                                                <>
                                                {type == 'Order' ? 
                                                    <OrderDetails />
                                                    :
                                                    <>
                                                    <Typography variant="h6" gutterBottom>
                                                        {type}
                                                    </Typography>
                                                    <Typography variant="body2" style={{display: 'flex', alignItems: 'center'}}>
                                                        <span style={{flex: 1}}>Reference: </span>
                                                        <Link to={linkUrl + equip?.details?.id} className='blueLink' style={{flex: 2}}>
                                                            {equip?.details?.ref ? equip?.details?.ref : '-'}
                                                        </Link>
                                                    </Typography>
                                                    <Typography variant="body2" style={{display: 'flex', alignItems: 'center'}} paragraph={!equip?.details?.deliveryDate}>
                                                        <span style={{flex: 1}}>Date: </span>
                                                        <span style={{flex: 2}}>{equip?.details?.date && moment(equip?.details?.date).format('DD/MM/YYYY')}</span>
                                                    </Typography>
                                                    {equip?.invoiceD && (
                                                        <>
                                                        <Typography variant="h6" gutterBottom>
                                                            Invoice
                                                        </Typography>
                                                        <Typography variant="body2" paragraph  style={{display: 'flex', alignItems: 'center'}}>
                                                            <span style={{flex: 1}}>Invoice Date: </span>
                                                            <span style={{flex: 2}}>{equip?.invoiceD && moment(equip?.invoiceD).format('DD/MM/YYYY')}</span>
                                                        </Typography>
                                                        </>
                                                    )}
                                                    </>
                                                }
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </TabPanel>

                    { /* Customer Returns */ }
                    <TabPanel value={currentTab} index={'customerReturns'}>
                        <EquipmentCustomerReturns 
                            equipmentId={equip?.id}
                        />
                    </TabPanel>

                    { /* History */ }
                    <TabPanel value={currentTab} index={'history'}>
                        <PaddedPaper>
                            <EquipmentHistoryRows 
                                customerId={equip?.custId}
                                equipmentId={equip?.id}
                                history={equip?.history}
                                access={access}
                            />
                        </PaddedPaper>
                    </TabPanel>

                </Grid>
            </Grid>
        )
    );
}

export default ViewEquipment;