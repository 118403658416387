import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, ButtonGroup } from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import React from 'react';
import { connect } from 'react-redux';

import EmailTemplate from 'Assets/json/emailCampaignsTemplate.json';
import EmailEditorPreset from 'Assets/json/emailEditorPreset.json';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import EmailEditor from 'react-email-editor';
import Legend from '../EmailTemplates/Legend';
import InputHelper from 'Helpers/InputHelper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import _ from 'lodash';

const initialState = (props) => ({
    formData: {
        met_id: props.update ? props.update.met_id : null,
        name: props.update ? props.update.met_name : '',
        json: props.update ? JSON.parse(props.update.met_json) : null,
        html: '',
    },
    emailPreviewDialogOpen: false,
    emailPreviewDialogData: {
        email: [props.loggedInStaff.email],
        type: 'staff'
    },
    isUpdate: props.update ? true : false,
    hasLoaded: false,
    lists: {
        staff: [],
    },
})

class CreateOrUpdateEmailTemplateDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
        this.emailEditorRef = React.createRef();
        this.inputHelper = new InputHelper(this);
    }

    componentDidMount() {
        this.getLists();
    }

    getLists = () => {
        Promise.all([
            API.get('/staff/all', {params: {searchStatus: 'active'}})
        ]).then(([staff]) => {
            this.setState({
                lists: {
                    staff: staff.data,
                }
            });
        })
    }


    EmailEditorOnReady = () => {
        if (this.state.hasLoaded) return;
        this.setState({ hasLoaded: true }, ()=> {
            if (this.state.isUpdate) {
                this.emailEditorRef.current.editor.loadDesign(this.state.formData.json);
            } else {
                this.emailEditorRef.current.editor.loadDesign(EmailTemplate);
            }
        });
    }

    handleSave = () => {
        this.emailEditorRef.current.editor.exportHtml((emailData) => {

            const { html, design } = emailData;
            let { formData, isUpdate } = this.state;
            let url = isUpdate ? `/marketing/emailTemplates/${formData.met_id}` : '/marketing/emailTemplates';

            let newFormData = new FormData();
            newFormData.append('name', formData.name);
            newFormData.append('json', JSON.stringify(design));
            newFormData.append('html', html);

            API.post(url, newFormData)
            .then(
                this.props.onClose
            )

        });

    }   

    showEmailPreviewDialog = () => {
        this.setState({ 
            emailPreviewDialogOpen: true,
            emailPreviewDialogData: initialState(this.props).emailPreviewDialogData,
        });
    }   

    closeEmailPreviewDialog = () => {
        this.setState({ emailPreviewDialogOpen: false });
    }

    handleEmailPreviewDialogChange = name => (e) => {
        this.setState({
            previewFormData: {
                ...this.state.previewFormData,
                [name]: e.target.value,
            }
        });
    }

    sendEmailPreview = () => {
        this.emailEditorRef.current.editor.exportHtml((data) => {
            const {  html } = data;
            let formData = new FormData();
            formData.append('email', JSON.stringify(this.state.emailPreviewDialogData.email));
            formData.append('subject', this.state.formData.name ?? 'Email Template Preview');
            formData.append('html', html);
            API.post(`/marketing/campaigns/emailCampaign/emailPreview`, formData)
                .then(response => {
                    this.closeEmailPreviewDialog();
                })
        });
    }

    render() {
        const { formData, isUpdate, emailPreviewDialogOpen, emailPreviewDialogData, lists } = this.state;

        return (
            <Grid container spacing={1} style={{marginTop: 75}}>
                <Grid item xs={12}>
                    <TextField
                        label='Name *'
                        name='formData.name'
                        value={formData.name}
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}> <Legend /> </Grid>
                <Grid item xs={12}  style={{height: '75vh'}}>
                    <EmailEditor 
                        style={{height: '75vh'}}
                        ref={this.emailEditorRef}
                        onReady={this.EmailEditorOnReady}
                        tools={
                            EmailEditorPreset.tools
                        }
                        fonts={
                            EmailEditorPreset.fonts
                        }
                    />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={this.showEmailPreviewDialog}
                    >Send Preview</Button>
                    <Button
                        onClick={this.props.onClose}
                        variant='outlined'
                    >Close</Button>
                    <Button
                        onClick={
                            () => this.props.deployConfirmation(
                                `Are you sure you want to ${isUpdate ? 'update' : 'add'} this email template?`,
                                ` ${isUpdate ? 'Update' : 'Add'} Email Template?`,
                                this.handleSave
                            )
                        }
                        variant='contained'
                        color='primary'
                        disabled={!formData.name}
                    >{isUpdate ? 'Update' : 'Create'}</Button>
                </Grid>
                {emailPreviewDialogOpen &&
                    <Dialog
                        open={emailPreviewDialogOpen}
                        fullWidth
                        maxWidth='sm'
                    >
                        <DialogTitle>
                            Email Preview
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1} style={{alignItems: 'center'}}>
                                <Grid item xs={12}>
                                    <ButtonGroup
                                        fullWidth
                                    >
                                        <Button
                                            onClick={this.inputHelper.handleButtonChange('emailPreviewDialogData.type', 'staff')}
                                            variant={emailPreviewDialogData.type === 'staff' ? 'contained' : 'outlined'}
                                            color={emailPreviewDialogData.type === 'staff' ? 'primary' : 'default'}
                                        >Staff</Button>
                                        <Button
                                            onClick={this.inputHelper.handleButtonChange('emailPreviewDialogData.type', 'custom')}
                                            variant={emailPreviewDialogData.type === 'custom' ? 'contained' : 'outlined'}
                                            color={emailPreviewDialogData.type === 'custom' ? 'primary' : 'default'}
                                        >custom</Button>
                                    </ButtonGroup>
                                </Grid>
                                {emailPreviewDialogData.type === 'custom' &&
                                    <>
                                        <Grid item xs={11}>
                                            <TextField
                                                label="Email *"
                                                value={emailPreviewDialogData?.customerEmail}
                                                name='emailPreviewDialogData.customerEmail'
                                                onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <AllIcon
                                                noMargin
                                                icon={IconHelper.plus}
                                                onClick={() => {
                                                    this.inputHelper.handleArrayAddRemove('emailPreviewDialogData.email')(emailPreviewDialogData.customerEmail)
                                                    this.inputHelper.handleButtonChange('emailPreviewDialogData.customerEmail','')()
                                                }}
                                            />
                                        </Grid>
                                    </>
                                }   
                                {emailPreviewDialogData.type === 'staff' &&
                                    <Grid item xs={12}>
                                        <AutoCompleteSelect
                                            label="Staff *"
                                            options={_.map(_.filter(lists.staff, s =>!emailPreviewDialogData.email.includes(s.staff_email)), s => ({ label: `${s.staff_first_name} ${s.staff_last_name} (${s.staff_email})`, value: s.staff_email }))}
                                            noClear
                                            fullWidth
                                            onChange={e => this.inputHelper.handleArrayAddRemove('emailPreviewDialogData.email')(e.value)}
                                        />
                                    </Grid>
                                }
                                {
                                    emailPreviewDialogData.email.length > 0 &&
                                    <Grid item xs={12}>
                                        <List>
                                            {_.map(emailPreviewDialogData.email, e => 
                                                <ListItem
                                                    style={{
                                                        borderBottom: `1px solid ${colors.border}`,
                                                    }}
                                                >
                                                    <ListItemText primary={e} />
                                                    <ListItemSecondaryAction>
                                                        <AllIcon
                                                            icon={IconHelper.bin}
                                                            noMargin
                                                            onClick={() => this.inputHelper.handleArrayAddRemove('emailPreviewDialogData.email')(e)}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>    
                                            )}
                                        </List>
                                    </Grid>
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={this.closeEmailPreviewDialog}
                            >Close</Button>
                            <Button
                                variant="contained"
                                color='primary'
                                onClick={this.sendEmailPreview}
                                disabled={emailPreviewDialogData.email.length < 1}
                            >Send</Button>
                        </DialogActions>
                    </Dialog>
                }
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback) => {
            dispatch(deployConfirmation(content, title, callback))
        }
    }
}


function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(CreateOrUpdateEmailTemplateDialog);