import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import DatePicker from '../../Common/DatePickers/DatePicker';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import Link from '@material-ui/core/Link';
import Textarea from '../../Common/Inputs/Textarea';
import {colors} from 'Helpers/ColourHelper';

const styles = theme => ({
    td : {
        border: 'none'
    },
    [theme.breakpoints.down('xs')]: {
        responsiveTable: {
            maxWidth: '100%', 
            overflowX: 'auto'
        }
    }
});

class PartItems extends PureComponent {
    
    render() {
        const { classes, formErrors, partRows, handleAddRow, handleRemoveRow, handleScheduleSave, handleScheduleClose, handleScheduleOpen, generateDeliverySchedule, handleRowChange, handleRowDateChange,
            handleSelectRowChange, partList, frequency, handleNotesClose, handleNotesSave, handleNotesOpen } = this.props;
            
        return (
            <React.Fragment>
                <Typography variant="h6">
                    Part Items
                </Typography>
                <form noValidate autoComplete="off">            
                    <div className={classes.responsiveTable}>
                        <Table style={{minWidth: 900}}>
                            <TableBody>
                                {partRows.map((item, idx) => {
                                    let number = idx+1
                                    return (
                                        <React.Fragment key={`pr${idx}`}>
                                            <TableRow>
                                                <TableCell className={classes.td} style={{width:'30%'}}>
                                                    <FormControl fullWidth>
                                                        <AutoCompleteSelect 
                                                            options={partList} 
                                                            value={item.part}
                                                            label="Part *"
                                                            error={(formErrors && formErrors['partRows|part|'+idx] && true) || false}
                                                            errorText={formErrors && formErrors['partRows|part|'+idx]}
                                                            onChange={handleSelectRowChange(idx, 'part', 'partRows')}
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="quantity"
                                                        value={item.quantity}
                                                        label="Quantity"
                                                        error={(formErrors && formErrors['partRows|quantity|'+idx] && true) || false}
                                                        helperText={formErrors && formErrors['partRows|quantity|'+idx]}
                                                        onChange={handleRowChange(idx, 'partRows')}
                                                        onBlur={handleRowChange(idx, 'partRows', 3)}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="unitPrice"
                                                        value={item.unitPrice}
                                                        label="Unit Price"
                                                        error={(formErrors && formErrors['partRows|unitPrice|'+idx] && true) || false}
                                                        helperText={formErrors && formErrors['partRows|unitPrice|'+idx]}
                                                        onChange={handleRowChange(idx, 'partRows')}
                                                        onBlur={handleRowChange(idx, 'partRows', 5)}
                                                        type="number"
                                                        fullWidth
                                                        disabled
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="totalPrice"
                                                        value={item.totalPrice}
                                                        label="Total Price"
                                                        error={(formErrors && formErrors['partRows|totalPrice|'+idx] && true) || false}
                                                        helperText={formErrors && formErrors['partRows|totalPrice|'+idx]}
                                                        onChange={handleRowChange(idx, 'partRows')}
                                                        onBlur={handleRowChange(idx, 'partRows', 2)}
                                                        type="number"
                                                        disabled
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td} style={{textAlign:'right'}}>
                                                    {number > 1 &&
                                                        <IconButton onClick={handleRemoveRow(idx, 'partRows')}>
                                                            <FALightIcon icon='times' noMargin button />
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key={idx}>
                                                <TableCell className={classes.td}>
                                                    <FormControl fullWidth>
                                                        <AutoCompleteSelect 
                                                            options={item.nominalList}
                                                            value={item.nominalCode}
                                                            label='Nominal Code *'
                                                            error={(formErrors && formErrors['partRows|nominalCode|'+idx] && true) || false}
                                                            errorText={formErrors && formErrors['partRows|nominalCode|'+idx]}
                                                            onChange={handleSelectRowChange(idx, 'nominalCode', 'partRows')}
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="supplierReference"
                                                        value={item.supplierReference}
                                                        label="Supplier Reference"
                                                        error={(formErrors && formErrors['partRows|supplierReference|'+idx] && true) || false}
                                                        helperText={formErrors && formErrors['partRows|supplierReference|'+idx]}
                                                        onChange={handleRowChange(idx, 'partRows')}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="drawingNo"
                                                        label="Drawing No."
                                                        value={item.drawingNo}
                                                        error={(formErrors && formErrors['partRows|drawingNo|'+idx] && true) || false}
                                                        helperText={formErrors && formErrors['partRows|drawingNo|'+idx]}
                                                        onChange={handleRowChange(idx, 'partRows')}
                                                        onBlur={handleRowChange(idx, 'partRows', 3)}
                                                        fullWidth
                                                        disabled
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    {!item.disableAttachDrawing &&
                                                        <Typography>
                                                            <Link href={atob(item.drawingUrl)} variant="body1" className='blueLink' target="_blank" rel="noopener">
                                                                {item.drawingPdfName}
                                                            </Link>
                                                        </Typography>
                                                    }
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="attachPO"
                                                                checked={item.attachPO}
                                                                onChange={handleRowChange(idx, 'partRows')}
                                                                value="true"
                                                                disabled={item.disableAttachDrawing}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Attach to PO"
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td} style={{textAlign:'right'}}>
                                                    <div className='buttonRow'>
                                                        <Button variant="contained" size="small" color="secondary" style={{backgroundColor:item.scheduleButtonColor}} onClick={handleScheduleOpen(idx)}>
                                                            <FALightIcon icon='calendar-alt' buttonPrimary /> Schedule
                                                        </Button>
                                                        <Button variant="contained" size="small" style={{backgroundColor:item.notesButtonColor || '#fff', color: item.notesButtonColor ? '#fff': ''}} onClick={handleNotesOpen(idx)}>
                                                            <FALightIcon icon='file-signature' style={{color: item.notesButtonColor ? '#fff': ''}} /> Notes
                                                        </Button>
                                                    </div>    
                                                    {formErrors && formErrors['partRows|deliverySchedule|'+idx] &&
                                                        <React.Fragment>
                                                            <br></br>
                                                            <Typography component={"div"} style={{color: colors.red}}>
                                                                {formErrors && formErrors['partRows|deliverySchedule|'+idx]}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className={classes.td} colSpan={6}>
                                                    <Divider style={{border:'5px solid #f2f2f2'}} />
                                                </TableCell>
                                            </TableRow>

                                            <Dialog 
                                                open={item.notesDialogOpen} 
                                                onClose={handleNotesClose(idx)} 
                                                fullWidth={true} 
                                                maxWidth="sm" 
                                                disableBackdropClick={true} 
                                                disableEscapeKeyDown
                                                scroll="body"
                                            >
                                                <DialogContent>
                                                    <form noValidate autoComplete="off">
                                                        <Textarea
                                                            name="notes"
                                                            value={item.notes}
                                                            label="Notes"
                                                            rows={3}
                                                            error={formErrors && formErrors['partRows|notes|'+idx]}
                                                            onChange={handleRowChange(idx, 'partRows')}
                                                        />
                                                    </form>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleNotesClose(idx)} variant="outlined" color="default">Close</Button>
                                                    <Button onClick={handleNotesSave(idx)} style={{backgroundColor:'#5da14d', color:'white'}} autoFocus variant="contained">Save & Close</Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog open={item.dialogOpen} onClose={handleScheduleClose(idx)} fullWidth={true} maxWidth="sm" disableBackdropClick={true} scroll="body">
                                                <DialogTitle id="form-dialog-title">
                                                    Delivery Schedule
                                                </DialogTitle>
                                                <DialogContent>
                                                    <form noValidate autoComplete="off">
                                                        {formErrors && formErrors['stockReturnDetails'] && formErrors['stockReturnDetails'].split('\n').map((item, key) => {
                                                            return (
                                                                <React.Fragment key={key}>
                                                                    <Typography component={"div"} style={{color: colors.red}}>
                                                                        {item}<br/>
                                                                    </Typography>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                        <RadioGroup
                                                            name="deliveryType"
                                                            value={item.deliveryType}
                                                            onChange={handleRowChange(idx, 'partRows')}
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                value="delivery_date"
                                                                control={<Radio color="primary" />}
                                                                label="Delivery Date"
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value="delivery_schedule"
                                                                control={<Radio color="primary" />}
                                                                label="Schedule"
                                                                labelPlacement="end"
                                                            />
                                                            
                                                            <FormControlLabel
                                                                value="delivery_tbc"
                                                                control={<Radio color="primary" />}
                                                                label="Supplier to Confirm"
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                        {item.deliveryType === 'delivery_date' &&
                                                            <React.Fragment>
                                                                <small>The total quantity will be delivered on one delivery date</small>
                                                                <DatePicker
                                                                    type="date"
                                                                    id="deliveryDate"
                                                                    name="deliveryDate"
                                                                    label="Delivery Date *"
                                                                    value={item.deliveryDate}
                                                                    errorText={formErrors && formErrors['partRows|deliveryDate|'+idx]}
                                                                    onChange={handleRowDateChange(idx, 'deliveryDate')}
                                                                    autoOk={true}
                                                                />
                                                            </React.Fragment>
                                                        }
                                                        {item.deliveryType === 'delivery_schedule' &&
                                                            <React.Fragment>
                                                                <small>The total quantity will be delivered over various dates according to the delivery schedule.</small>
                                                                <FormControl fullWidth margin="normal">
                                                                    <AutoCompleteSelect 
                                                                        options={[
                                                                            {value: 'Weekly', label: 'Weekly'},
                                                                            {value: 'Monthly', label: 'Monthly'},
                                                                            {value: 'Bi-Monthly', label: 'Bi-Monthly'},
                                                                            {value: 'Quarterly', label: 'Quarterly'}
                                                                        ]} 
                                                                        label='Frequency *'
                                                                        onChange={handleSelectRowChange(idx, 'frequency', 'partRows')}
                                                                        error={formErrors && formErrors['partRows|frequency|'+idx] && true}
                                                                        errorText={formErrors && formErrors['partRows|frequency|'+idx]}
                                                                        value={frequency}
                                                                    />
                                                                </FormControl>
                                                                <DatePicker
                                                                    type="date"
                                                                    id="startingFrom"
                                                                    name="startingFrom"
                                                                    label="Starting from *"
                                                                    value={item.startingFrom}
                                                                    errorText={formErrors && formErrors['partRows|startingFrom|'+idx]}
                                                                    onChange={handleRowDateChange(idx, 'startingFrom')}
                                                                    autoOk={true}
                                                                />
                                                                <TextField
                                                                    id="duration"
                                                                    name="duration"
                                                                    label="For *"
                                                                    value={item.duration}
                                                                    error={formErrors && formErrors['partRows|duration|'+idx] && true}
                                                                    helperText={formErrors && formErrors['partRows|duration|'+idx]}
                                                                    onChange={handleRowChange(idx, 'partRows')}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                        <InputAdornment position="end" style={{width:'25%'}}>
                                                                            {item.durationPlaceholder}
                                                                        </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                                <Button onClick={() => generateDeliverySchedule(idx)} variant="contained" color="primary">
                                                                    Generate
                                                                </Button>
                                                                {item.deliveryTable}
                                                            </React.Fragment>
                                                        }
                                                    </form>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleScheduleClose(idx)} variant="outlined" color="default">Close</Button>
                                                    <Button 
                                                        onClick={handleScheduleSave(idx)} 
                                                        style={{backgroundColor:partRows[idx].validSchedule && '#5da14d', color:partRows[idx].validSchedule && 'white'}} 
                                                        autoFocus 
                                                        variant="contained"
                                                        disabled={!partRows[idx].validSchedule}
                                                        >
                                                            Save & Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </React.Fragment>
                                    );
                                })}
                                <TableRow>
                                    <TableCell className={classes.td} colSpan={5}>
                                        <Button 
                                            onClick={handleAddRow('partRows')}
                                            variant="contained"
                                            color="primary"
                                            size="small">
                                            Add Row
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(PartItems);
    