import _ from 'lodash';

export function convertFileUploadsName(name) {
    let result = name;
    switch (name){
        case 'both':
            result = 'Both (Original and PDF)';
        break;
        case 'original_only':
            result = 'Original Only';
        break;
        case 'pdf_only':
            result = 'PDF Only';
        break;
        default:
        break;
    }
    return result;
}

export function convertVideoUploadsName(name) {
    let result = name;
    switch (name){
        case 'both':
            result = 'Both (MOV and MP4)';
        break;
        case 'mov_only':
            result = 'MOV Only';
        break;
        case 'mp4_only':
            result = 'MP4 Only';
        break;
        default:
        break;
    }
    return result;
}

export function stringMarkerReplace( str, replaceObject, startMarker='{>', endMarker='<}' ){

    let tag    = '';
    let newStr = str;

    //extract markers from string
    _.each(str.split(startMarker), item => {
        tag = item.substring(0, item.indexOf(endMarker));
        let target  = `${startMarker}${tag}${endMarker}`;
        if (tag) {
            let replace = (tag in replaceObject) && replaceObject[tag]
            if (replace) newStr = newStr.replace(target, replace);
        }
    });

    return newStr;
}