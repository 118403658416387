import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import API from 'API';
import {
    Grid,
    Typography,
    Button
} from '@material-ui/core';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { toggleDialog } from 'Functions/MiscFunctions';
import { handleDateChange } from 'Functions/FormFunctions';
import VehicleIncidentForm from './VehicleIncidentForm';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { CLENAWARE_GREEN, CLENAWARE_ORANGE } from 'Constants';

const initialState = () => ({
    vehicleId: 0,
    incidents: [],
    dialog: {
        incident: false,
        incidentId: 0,
        readOnly: false
    },
    access: {
        updateVehicle: false,
    },
    isLoading: true,
    formErrors: []
})

class VehicleIncidents extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.toggleDialog = toggleDialog.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
    }

    componentDidMount() {
        const { vehicleId } = this.props;
        this.setState({
            ...this.state,
            vehicleId
        }, () => this.getAccess());
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-vehicle')
        ])
        .then(([manageRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateVehicle: (manageRes.data && manageRes.data.has_access) || false
                }
            }, () => this.loadComponentData());
        }) 
    }

    loadComponentData = () => {
        API.get(`/vehicles/${this.state?.vehicleId}/incidents`)
        .then(result =>  {

            if(result.data) {
                this.setState({
                    isLoading: false,
                    incidents: result.data
                });
            }
        });
    }

    openDialog = (id = 0, readOnly = false) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                incident: true,
                incidentId: id,
                readOnly
            },
        });
    }

    render() {

        const { vehicleId, incidents, dialog, isLoading, access } = this.state;
        const { activeVehicle } = this.props;
        return (
            <>
                <PaddedPaper>
                    {(isLoading && (
                        <LoadingCircle />
                    )) || (
                        <Grid container item spacing={3}>                        
                            <Grid item xs={12}>
                                <Grid container item spacing={3}>                        
                                    <Grid item xs>
                                        <Typography variant="h6" paragraph>
                                            <b>Incidents</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs align='right'>
                                        {access.updateVehicle && activeVehicle ?
                                            <Button 
                                                variant='outlined' 
                                                size="small" 
                                                onClick={() => this.openDialog()}
                                            >
                                                <AllIcon icon='plus' size="small" />
                                                Add Incident
                                            </Button>
                                        : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {incidents.length > 0 ?
                                <>
                                <Grid item xs={12}>
                                    <DataTable  
                                        config={{
                                            key: 'id',
                                            pagination: true,
                                            alternatingRowColours: false,
                                            responsiveImportance: true,
                                            isLoading: isLoading,
                                            plainPagination: true,
                                        }}
                                        columns={[
                                            {
                                                heading: 'Date',
                                                field: rowData => moment(rowData?.dateTime).format('DD MMM, YYYY'),                                   
                                                important: true,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'Time',
                                                field: rowData => moment(rowData?.dateTime).format('HH:mm'),                                   
                                                important: true,
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'Incident Type',
                                                field: rowData => rowData?.incidentType,
                                                important: true,
                                                sizeToContent: true,
                                                main: true,
                                                hideInResponsiveDialog: true
                                            },
                                            {
                                                heading: '',
                                                field: rowData => rowData?.incidentType == 'Penalty Charge Notice' ? <Typography variant="caption" style={{color: rowData?.pcnSettled ? CLENAWARE_GREEN : CLENAWARE_ORANGE}}>{rowData?.pcnSettled ? "SETTLED": "UNSETTLED"}</Typography> : '',
                                                important: true,
                                                hideInResponsiveDialog: true
                                            },
                                            {
                                                heading: 'Driver',
                                                field: rowData => rowData?.staff?.name,                                   
                                                important: true,
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Update', icon: 'pencil-alt', onClick: () => this.openDialog(rowData?.id), disabled: !access.updateVehicle || !activeVehicle},
                                                        {name: 'View', icon: 'search', onClick: () => this.openDialog(rowData?.id, true)},

                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={incidents}
                                    />
                                </Grid>
                                </>
                            :
                                <Grid item xs={12} align="center">
                                    <Typography variant="h6" paragraph color='textSecondary'>
                                        <em>No Incidents found</em>
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    )}
                </PaddedPaper>
                <SmallFormDialog
                    open={dialog?.incident}
                    onClose={() => this.toggleDialog('incident')}
                    content={
                        <VehicleIncidentForm 
                            onClose={() => this.toggleDialog('incident', true)}
                            incidentId={dialog?.incidentId}
                            refresh={() => {this.toggleDialog('incident', true); this.props.refresh();}}
                            readOnly={dialog?.readOnly}
                            vehicleId={vehicleId}
                        />
                    }
                    maxWidth='md'
                />
            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),

    }
}

export default connect(null, mapDispatchToProps)(VehicleIncidents)