import React from 'react';
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";

import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { useEffect, useState } from "react";

const WildixMenu = ({ handleClose, handleDisconnect, handleDeployStatusChange, handleToggleCallCentre }) => {
    const [disableDesktop, setDisableDesktop] = useState(false);
    const [popOut, setPopOut] = useState(false);
    const toggleCallNotificationPopOut = () => {
        let newPopOut = !popOut;
        localStorage.setItem("voipPopOut", newPopOut);
        setPopOut(newPopOut);
    };
    const toggleCallDesktopNotification = () => {
        let newDisableDesktop = !disableDesktop;
        localStorage.setItem("voipDisableDesktop", newDisableDesktop);
        setDisableDesktop(newDisableDesktop);
    };

    useEffect(() => {
        setPopOut(localStorage.getItem("voipPopOut") === "true");
        setDisableDesktop(localStorage.getItem("voipDisableDesktop") === "true");
    }, []);

    return (
        <>
            <Box align="left" mb={3}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControlLabel
                            className="m-0"
                            control={<Switch color="primary" checked={popOut} onChange={toggleCallNotificationPopOut} value="1" />}
                            label={
                                <Typography variant="body1" className="fw-400">
                                    Pop Out Call Notifications
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            className="m-0"
                            control={<Switch color="primary" checked={disableDesktop} onChange={toggleCallDesktopNotification} value="1" />}
                            label={
                                <Typography variant="body1" className="fw-400">
                                    Disable Desktop Notifications
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <List component="nav">
                <ListItem button onClick={handleToggleCallCentre}>
                    <ListItemIcon>
                        <FAIcon type="light" icon="user-headset" button size={20} noMargin />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{variant: 'body1'}} primary="Queued Calls" />
                </ListItem>
                <ListItem button onClick={handleDeployStatusChange}>
                    <ListItemIcon>
                        <FAIcon type="light" icon="user" button size={20} noMargin />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{variant: 'body1'}} primary="Change Status" />
                </ListItem>
                <ListItem button onClick={() => handleDisconnect(true)}>
                    <ListItemIcon>
                        <FAIcon type="light" icon="sign-out-alt" button size={20} noMargin />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{variant: 'body1'}} primary="Sign Out" />
                </ListItem>
                <ListItem button onClick={handleClose}>
                    <ListItemIcon>
                        <FAIcon type="light" icon="times" button size={20} noMargin />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{variant: 'body1'}} primary="Close" />
                </ListItem>
            </List>
        </>
    );
};

export default WildixMenu;
