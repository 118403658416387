import _            from 'lodash';
import moment       from 'moment';
import React        from 'react';
import API          from 'API';
import { Redirect } from 'react-router-dom';

import { Avatar, Button, Chip, FormControl, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';

import DateTimePicker          from 'Components/Common/DatePickers/DateTimePicker';
import DatePicker              from 'Components/Common/DatePickers/DatePicker';
import ConfirmationDialog      from 'Components/Common/Dialogs/ConfirmationDialog';
import FALightIcon             from 'Components/Common/Icons/FontAwesome/FALightIcon';
import Textarea                from 'Components/Common/Inputs/Textarea';
import PaddedPaper             from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar                from 'Components/Common/SnackBars/SnackBar';
import BackButton              from 'Components/Common/Buttons/BackButton';

import { formatValidationErrors, formatValidationWarnings } from 'Helpers/ErrorHelper';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = {
    leaderStaffList: [],
    staffList: [],
    categories: {},
    categoryList: [],
    subCategories: {},
    subCategoryList: [],
    customers: {},
    customerList: [],
    customerContacts: {},
    customerContactList: [],
    suppliers: {},
    supplierList: [],
    supplierContacts: {},
    supplierContactList: [],
    formData: {
        eventStart: null,
        eventFinish: null,
        category: null,
        subCategory: null,
        leader: null,
        meetingWith: null,
        staffAttendees: [],
        supplierAttendees: [],
        customerAttendees: []
    },
    formErrors: [],
    formWarnings: [],
    confirmationOpen: false,
    snackbarOpen: false,
    warningConfirmationOpen: false,
    redirect: false,
    isLoading: true
}

class UpdateCalendarEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount(){
        this.getSuppliers();
    }
    getInitialFormData = () => {
        API.get(`/calendar/events/${this.props.match.params.eventId}`)
        .then(result => {
            if (result.data.errors) {
                this.setState( { redirect: true });
            } else if (result.data) {
                let staffAttendees = [];
                _.map(result.data.staff_attendees, (staff) => {
                    staffAttendees.push(staff.staff_id);
                });
                let customerAttendees = [];
                _.map(result.data.customer_attendees, (contact) => {
                    customerAttendees.push(contact.customer_attendee_contact_id);
                });
                let supplierAttendees = [];
                _.map(result.data.supplier_attendees, (contact) => {
                    supplierAttendees.push(contact.supplier_attendee_contact_id);
                });
                this.setState({
                    formData: {
                        eventStart: result.data.calendar_event_start_datetime,
                        eventFinish: result.data.calendar_event_end_datetime,
                        category: result.data.calendar_event_parent_category_id,
                        subCategory: result.data.calendar_event_child_category_id,
                        leader: result.data.calendar_event_leading_staff_id,
                        meetingWith: result.data.calendar_event_with,
                        staffAttendees: staffAttendees,
                        customer: result.data.customer && result.data.customer.cust_id,
                        customerAttendees: customerAttendees,
                        supplier: result.data.supplier && result.data.supplier.supp_id,
                        supplierAttendees: supplierAttendees,
                        notes: result.data.calendar_event_notes,
                        eventAllDay: (result.data.calendar_event_start_datetime.endsWith('09:00:00') && (result.data.calendar_event_end_datetime.endsWith('17:30:00') && moment(result.data.calendar_event_end_datetime).isSame(moment(result.data.calendar_event_start_datetime), 'day')))
                    },
                    isLoading: false
                }, () => {
                    this.getStaff();
                    this.getSubCategories(this.state.formData.category);
                    this.getCustomerContacts(this.state.formData.customer);
                    this.getSupplierContacts(this.state.formData.supplier);
                });
            }
        });
    };
    getSuppliers = () => {
        API.get('/suppliers/all')
        .then((result) => {
            let supplierList = _.map(result.data, el => {
                return _.assign({
                    value: el.supp_id,
                    label: el.supp_company_name
                });
            });
            this.setState({
                supplierList: supplierList,
                suppliers: result.data
            }, () => {
                this.getCustomers();
            })
        });
    }
    getSupplierContacts = (supplierId) => {
        if(supplierId) {
        let suppList = _.find(this.state.suppliers, {'supp_id': supplierId});
        let supplierContactList = _.map(suppList.active_contacts, (cont) => {
                return _.assign({
                value: cont.supp_cont_id,
                label: cont.supp_cont_first_name + ' ' + cont.supp_cont_last_name
                });
            });
            this.setState({
                supplierContactList: supplierContactList
            });
        } else {
            this.setState({
                supplierContactList: initialState.supplierContactList,
                formData: {
                    ...this.state.formData,
                    supplierAttendees: []
                }
            });
        }
    }
    getCustomers = () => {
        API.get('/customers')
        .then((result) => {
            let customerList = _.map(result.data, el => {
                el.registeredPostcode = _.result(_.find(el?.addresses, {'address_type': 'Registered'}), 'address_postcode');
                return _.assign({
                    value: el.cust_id,
                    label: el.cust_name + ' ('+el?.registeredPostcode+')'
                });
            });
            this.setState({
                customerList: customerList,
                customers: result.data
            }, ()=>{
                this.getCategories();
            })
        });
    }
    getCustomerContacts = (customerId) => {
        if(customerId) {
        let custList = _.find(this.state.customers, {'cust_id': customerId});
        let customerContactList = [];
        custList.addresses.forEach((address) => {
                _.map(address.active_contacts, (cont) => {
                    customerContactList.push({
                        value: cont.contact_id,
                        label: `${cont.contact_name} (${address.address_type})`
                    });
                });
            });
            this.setState({
                customerContactList: customerContactList
            });
        } else {
            this.setState({
                customerContactList: initialState.customerContactList,
                formData: {
                    ...this.state.formData,
                    customerAttendees: []
                }
            });
        }
    }
    handleSubmit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            if(key === "eventStart" || key === "eventFinish") {
                newFormData.append(key, moment(this.state.formData[key]).format(this.state.formData.eventAllDay ? "YYYY-MM-DD" : "YYYY-MM-DD H:mm"));
            } else {
                newFormData.append(key, this.state.formData[key]);
            }
        });
        API.post(`/calendar/events/${this.props.match.params.eventId}`, newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            } else if(result.data.warnings && result.data.warnings.length > 0){           
                this.setState({
                    formWarnings: formatValidationWarnings(result.data.warnings),
                    warningConfirmationOpen: true
                });
            } else {
                this.setState({
                    snackbarOpen: true
                });
            }
            this.props.scrollToTop();
        });
    }
    getStaff = () => {
        API.get('/staff/all')
        .then(result => {
            if(result.data) {
                let staffList = _.map(result.data, (staff) => {
                    return _.assign({
                        value: staff.staff_id,
                        label: staff.staff_first_name + ' ' + staff.staff_last_name,
                        disabled: (staff.staff_status === 'inactive')
                    });
                });
                let leaderStaffList = _.map(result.data, (staff) => {
                    return _.assign({
                        value: staff.staff_id,
                        label: staff.staff_first_name + ' ' + staff.staff_last_name,
                        disabled: (staff.staff_status === 'inactive')
                    });
                });
                this.setState({
                    staffList: staffList,
                    leaderStaffList: leaderStaffList
                }, () => {
                    let leaderStaff = this.state.leaderStaffList.find(obj => {
                    return obj.value === this.state.formData.leader
                    });
                    this.handleSelectChange('leader')(leaderStaff);
                });
            }
        });
    }
    getCategories = () => {
        API.get('/calendar/categories/all').then(result => {
        if(result.data) {
            let categoryList = _.map(result.data, (cat) => {
                return _.assign({
                    value: cat.category_id,
                    label: cat.category_name
                });
            });
            this.setState({
                categoryList: categoryList,
                categories: result.data
            }, () => {
                this.getInitialFormData();
            });
        }
        });
    };
    getSubCategories = (categoryId) => {
        if(categoryId) {
        let catList = _.find(this.state.categories, {'category_id': categoryId});
        let subCategoryList = [];
        catList.children.forEach((cat) => {
            if(cat.category_enabled === 'Yes' || cat.category_id === this.state.formData.subCategory){
                subCategoryList.push({
                    value: cat.category_id,
                    label: cat.category_name
                });
            }
        });
        this.setState({
            subCategoryList: subCategoryList
        });
        } else {
            this.setState({
                subCategoryList: initialState.subCategoryList
            });
            this.setState({
                formData: {
                    ...this.state.formData,
                    subCategory: null
                }
            });
        }
    };
    handleCheckChange = name => event => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: event.target.checked
            }
        });
    };
    handleDateChange = name => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: date
            }
        }, () => {
            if(name === "eventStart" && this.state.formData.eventAllDay) {
                this.handleDateChange("eventFinish")(date);
            } else {
                if(name === "eventStart" && this.state.formData.eventFinish && moment(this.state.formData.eventFinish) < moment(this.state.formData.eventStart))
                    this.handleDateChange("eventFinish")(date);
                else if(name === "eventStart" && !this.state.formData.eventFinish)
                    this.handleDateChange("eventFinish")(date);
                else if(name === "eventFinish" && this.state.formData.eventFinish && moment(this.state.formData.eventFinish) < moment(this.state.formData.eventStart))
                    this.handleDateChange("eventStart")(date);
                else if(name === "eventFinish" && !this.state.formData.eventStart)
                    this.handleDateChange("eventStart")(date);
            }
        });
    };
    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, 
        () => {
            if(fieldName === 'category'){
                this.setState({
                    formData: {
                        ...this.state.formData,
                        subCategory: null
                    }
                },
                () => {
                    this.getSubCategories(selectedOption && selectedOption.value);
                });
            } else if(fieldName === 'leader') {
                let newStaffList = [...this.state.staffList];
                let newStaffAttendeesList = [...this.state.formData.staffAttendees];
                for (let i in newStaffList) {
                    if(newStaffList[i] && selectedOption && newStaffList[i].value === selectedOption.value) {
                        newStaffList[i].disabled = (newStaffList[i].disabled || (newStaffList[i].value === selectedOption.value));
                        if(this.state.formData.staffAttendees.includes(selectedOption.value)) {
                            newStaffAttendeesList = newStaffAttendeesList.filter(staffId => staffId !== selectedOption.value);
                        }
                    }
                }
                this.setState({
                    staffList: newStaffList,
                    formData: {
                        ...this.state.formData,
                        staffAttendees: newStaffAttendeesList
                    }
                });
            }
        });
    };
    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };
    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }
    handleWarningConfirmationOpen = () => {
        this.setState({
            warningConfirmationOpen: true,
        });
    };
    handleWarningConfirmationClose = () => {
        this.setState({ 
            warningConfirmationOpen: false 
        });
    };
    handleWarningConfirmationSuccess = () => {
        this.setState({ 
            warningConfirmationOpen: false,
            formData: {
                ...this.state.formData,
                ignoreWarnings: true
            }
        }, () => {
            this.handleSubmit();
        });
    }
    render() {
        const { formData, formErrors, snackbarOpen, isLoading } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/calendar/events/search" />
        } else {
            return ( isLoading ? <LoadingCircle /> :
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Update Calendar Event
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="h6">
                                    Event Details
                                </Typography>
                                <form noValidate autoComplete="off">
                                    <FormControlLabel
                                        control={
                                            <Switch name="eventAllDay"
                                                    checked={!!this.state.formData.eventAllDay}
                                                    onChange={this.handleCheckChange('eventAllDay')}
                                                    color="primary"
                                                    margin="normal" />
                                        }
                                        label={<span style={{color: 'rgba(0, 0, 0, 0.54)'}}>Is this an all day event?</span>}
                                    />
                                    {this.state.formData.eventAllDay ?
                                        <DatePicker
                                            type={this.state.formData.eventAllDay ? 'date' : 'datetime'}
                                            id="eventStart"
                                            name="eventStart"
                                            label="Event Start *"
                                            disablePast={true}
                                            value={this.state.formData.eventStart}
                                            errorText={formErrors && formErrors['eventStart']}
                                            onChange={this.handleDateChange('eventStart')}
                                        /> :
                                        <DateTimePicker
                                            required
                                            type={this.state.formData.eventAllDay ? 'date' : 'datetime'}
                                            id="eventStart"
                                            name="eventStart"
                                            label="Event Start "
                                            disablePast={true}
                                            value={this.state.formData.eventStart}
                                            errorText={formErrors && formErrors['eventStart']}
                                            onChange={this.handleDateChange('eventStart')}
                                        />
                                    }
                                    {!this.state.formData.eventAllDay && (
                                        <DateTimePicker
                                            required
                                            type={this.state.formData.eventAllDay ? 'date' : 'datetime'}
                                            id="eventFinish"
                                            name="eventFinish"
                                            label="Event Finish "
                                            disablePast={true}
                                            value={this.state.formData.eventFinish}
                                            errorText={formErrors && formErrors['eventFinish']}
                                            onChange={this.handleDateChange('eventFinish')}
                                        />
                                    )}
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.categoryList} 
                                            label='Category *'
                                            value={this.state.formData.category}
                                            onChange={this.handleSelectChange('category')}
                                            error={formErrors && formErrors['category'] && true}
                                            errorText={formErrors && formErrors['category'] && formErrors['category']}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.subCategoryList} 
                                            label='Sub Category *'
                                            value={this.state.formData.subCategory}
                                            onChange={this.handleSelectChange('subCategory')}
                                            error={formErrors && formErrors['subCategory'] && true}
                                            errorText={formErrors && formErrors['subCategory'] && formErrors['subCategory']}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" fullWidth>
                                        <AutoCompleteSelect 
                                            options={this.state.leaderStaffList} 
                                            label='Leader *'
                                            value={this.state.formData.leader}
                                            onChange={this.handleSelectChange('leader')}
                                            error={formErrors && formErrors['leader'] && true}
                                            errorText={formErrors && formErrors['leader']}
                                        />
                                    </FormControl>
                                    {this.state.formData.category && this.state.formData.category === 1 && (
                                    <React.Fragment>
                                        <FormControl margin="normal" fullWidth>
                                            <AutoCompleteSelect 
                                                options={[
                                                {value: 'in-house', label: 'In-House'},
                                                {value: 'supplier', label: 'Supplier', disabled: true},
                                                {value: 'customer', label: 'Customer', disabled: true}
                                                ]} 
                                                label='Meeting With *'
                                                value={this.state.formData.meetingWith}
                                                onChange={this.handleSelectChange('meetingWith')}
                                                error={formErrors && formErrors['meetingWith'] && true}
                                                errorText={formErrors && formErrors['meetingWith']}
                                            />
                                        </FormControl>    
                                        {this.state.formData.meetingWith && this.state.formData.meetingWith === 'supplier' && (
                                        <React.Fragment>
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect 
                                                    options={this.state.supplierList} 
                                                    label='Supplier *'
                                                    value={this.state.formData.supplier}
                                                    onChange={this.handleSelectChange('supplier')}
                                                    error={formErrors && formErrors['supplier'] && true}
                                                    errorText={formErrors && formErrors['supplier'] && formErrors['supplier']}
                                                />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteMultiSelect 
                                                    options={this.state.supplierContactList} 
                                                    label='Supplier Attendees *'
                                                    value={this.state.formData.supplierAttendees}
                                                    onChange={this.handleMultiSelectChange('supplierAttendees')}
                                                    error={formErrors && formErrors['supplierAttendees'] && true}
                                                    errorText={formErrors && formErrors['supplierAttendees']}
                                                />
                                            </FormControl>
                                        </React.Fragment>
                                        )}    
                                        {this.state.formData.meetingWith && this.state.formData.meetingWith === 'customer' && (
                                        <React.Fragment>
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect 
                                                    options={this.state.customerList}
                                                    label='Customer *'
                                                    value={this.state.formData.customer}
                                                    onChange={this.handleSelectChange('customer')}
                                                    error={formErrors && formErrors['customer'] && true}
                                                    errorText={formErrors && formErrors['customer'] && formErrors['customer']}
                                                />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteMultiSelect 
                                                    options={this.state.customerContactList} 
                                                    label='Customer Attendees *'
                                                    value={this.state.formData.customerAttendees}
                                                    onChange={this.handleMultiSelectChange('customerAttendees')}
                                                    error={formErrors && formErrors['customerAttendees'] && true}
                                                    errorText={formErrors && formErrors['customerAttendees']}
                                                />
                                            </FormControl>
                                        </React.Fragment>
                                        )}
                                    </React.Fragment>
                                    )}
                                    <FormControl margin="normal" fullWidth>
                                        <AutoCompleteMultiSelect 
                                            options={this.state.staffList}
                                            label='Staff Attendees'
                                            value={this.state.formData.staffAttendees}
                                            onChange={this.handleMultiSelectChange('staffAttendees')}
                                            error={formErrors && formErrors['staffAttendees'] && true}
                                            errorText={formErrors && formErrors['staffAttendees']}
                                        />
                                    </FormControl>
                                    <Textarea
                                        id="notes"
                                        name="notes"
                                        label="Notes"
                                        value={this.state.formData.notes}
                                        onChange={this.handleChange}
                                        InputLabelProps={{
                                            shrink: !!this.state.formData.notes,
                                        }}
                                    />
                                    <div className='buttonRow'>
                                        <BackButton props={this.props} />
                                        <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary"
                                            disabled={Object.values(formData).every(x => (x === null || x === ""))}>
                                            Update
                                        </Button>
                                    </div>
                                    <ConfirmationDialog 
                                        open={this.state.confirmationOpen} 
                                        success={this.handleConfirmationSuccess} 
                                        close={this.handleConfirmationClose} 
                                        title="Update Calendar Event?" 
                                        message="Are you sure you want to update this calendar event?"
                                    />
                                    <SnackBar
                                        variant="success"
                                        anchorOriginVertical='bottom'
                                        anchorOriginHorizontal='right'
                                        open={snackbarOpen}
                                        onClose={this.handleSnackbarClose}
                                        message="The calendar event was successfully updated"
                                    />
                                    <ConfirmationDialog 
                                        open={this.state.warningConfirmationOpen} 
                                        success={this.handleWarningConfirmationSuccess} 
                                        close={this.handleWarningConfirmationClose} 
                                        title={
                                            <React.Fragment>
                                                <FALightIcon icon='exclamation-triangle' /> Warning!
                                            </React.Fragment>
                                        }
                                        message={
                                            <React.Fragment>
                                                <Typography variant="body1" style={{marginBottom: 24}}>
                                                    The following people are already attending an event between these times:
                                                </Typography>
                                                {Object.values(this.state.formWarnings).map(value => {
                                                    let staffValues = value.split("|");
                                                    return (
                                                    <React.Fragment>
                                                        <Chip
                                                        avatar={<Avatar alt={staffValues[0]} src={staffValues[1]} style={{width: 32, height: 32}} />}
                                                        label={staffValues[0]}
                                                        variant="outlined"
                                                        style={{height: 35, marginRight: 8}}
                                                        />
                                                    </React.Fragment>
                                                    )
                                                })}
                                                <Typography variant="body1" style={{marginTop: 24}}>
                                                    Would you like to proceed with this event anyway?
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }
}

export default UpdateCalendarEvent;