import { Grid, TextField, Typography, Button } from "@material-ui/core";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import React from "react";
import API from "API";
import DataTable from "Components/Common/DataTables/CiDataTable";
import { colors } from "Helpers/ColourHelper";
import _ from "lodash";

const initialState = () => ({
    formData: {
        name: '',
        pass: 'pass'
    },
    conditions: [],
    isLoading: true
})

class ReceivedConditions extends React.Component {
    constructor(props){
        super(props)
        this.state = initialState();
    }

    componentDidMount(){
        this.getConditions();
    }

    handleChange = name => event => {
        let formData = this.state.formData;
        formData[name] = event.target.value; 
        this.setState({ formData });
    }

    handleSelectChange = name => ({value}) => {
        let formData = this.state.formData;
        formData[name] = value;
        this.setState({ formData });
    }

    handleAdd = () => {
        const {formData} = this.state
        API.post('/customerReturns/defaults/returnCondition', formData)
        .then((res) => {
            this.setState(initialState(), 
                this.getConditions
            )
        })
    }

    getConditions = () => {
        API.get('/customerReturns/defaults/returnCondition')
        .then(({data})=>{
            this.setState({conditions: data, isLoading: false})
        })
    }

    render () {

        const {formData, conditions, isLoading} = this.state

        if (isLoading) return null;

        return (
            <Grid container spacing={3}>
                <Grid item xs={5}>
                    <PaddedPaper>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    Add Received Condition
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label='Name *'
                                    value={formData.name}
                                    onChange={this.handleChange('name')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    value={formData.pass}
                                    onChange={this.handleSelectChange('pass')}
                                    label='Condition Grade *'
                                    fullWidth
                                    options={[
                                        {
                                            value: 'pass',
                                            label: 'Pass (Green)'
                                        },
                                        {
                                            value: 'warning',
                                            label: 'Pass with Warning (Amber)'
                                        },
                                        {
                                            value: 'fail',
                                            label: 'Fail (Red)'
                                        }
                                    ]}
                                    disableSort
                                    noClear
                                />
                            </Grid>
                            <Grid item xs={12} className="buttonRow">
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={this.handleAdd}
                                    disabled={
                                        !formData.name ||
                                        !formData.pass
                                    }
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable
                            config={{
                                key: 'crrc_id'
                            }}
                            columns={[
                                {
                                    field: 'crrc_condition',
                                    heading: 'Condition'
                                },
                                {
                                    field: r => r.crrc_grade === 'pass' ? 'Pass' : r.crrc_grade === 'warning' ? 'Pass With Warning' : 'Fail',
                                    heading: 'Grade',
                                    sizeToContent: true,
                                    style: r => ({
                                        color: r.crrc_grade === 'pass' ? colors.green : r.crrc_grade === 'warning' ? colors.orange : colors.red
                                    }),
                                    alignment: 'center'
                                }
                            ]}
                            rows={_.orderBy(conditions, [r => {return {pass: 0,  warning: 1, Fail:2}[r.crrc_grade]}, 'crrc_condition'], ['asc', 'asc'])}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
    }

}

export default ReceivedConditions;