import { Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import API from 'API';
import BarChart from 'Components/Common/Charts/BarChart';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import { getColorArrow, getColorCurrencyChange, getColorPercentageChange, getColorQtyChange, getCurrency, getPercentage, getPlainPercentage, getQty, months } from 'Helpers/ReportsHelper';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import MonthsBlock from './MonthsBlock';
import { getOrdinal } from 'Helpers/TimeHelper'; 

const currentMonth = moment().format('MMMM')

const initialState = (props={}) => ({
    isLoading: true,
    data: [],
    view: { value: 'year', allowCurrent: props.current },
    showCurrent: props.current
})

class PartCategoriesReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.year !== this.props.year) {
            this.setState(initialState(this.props), this.getData);
        }
    }

    getData = () => {
        this.setState({isLoading: true}, ()=>{
            let params = {params: {cid:this.props.cid, partId:this.props.partId, year:this.props.year, current: this.props.current}}
            if (this.state.view.value !== 'year') {
                params.params.month = this.state.view.value;
            }
            API.get('/reports/yoysReport/parts', params)
            .then(response => {
                this.setState({
                    data: response.data, 
                    isLoading: false
                });
            })
        });
    }

    setView = (view) => () => {
        this.setState({view}, this.getData);
    }


    render() {

        const {isLoading, data, view, showCurrent, views} = this.state;
        const {year, current} = this.props;

        let
            currentYear = `${year-1}/${year}`,
            lastYear = `${year-2}/${year-1}`;
        if (view.value !== 'year') {
            currentYear = `${months[view.value].slice(0,3)} ${view.value<10 ? year : (year-1)}`;
            lastYear = `${months[view.value].slice(0,3)} ${(view.value<10 ? year : (year-1)) - 1}`;
        }

        let mod = view.allowCurrent && showCurrent ? 'c' : 't';

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MonthsBlock {...this.props} view={view} showCurrent={showCurrent}
                        setCurrentView={(showCurrent,v)=>{this.setState({ showCurrent }, this.setView(v))}}
                        setView={v=>this.setView(v)()}
                    />
                </Grid>
                {isLoading ?
                    <Grid item xs={12}>
                        <LoadingCircle/>
                    </Grid> :
                    <>
                        <Grid item xs={12} lg={12}>
                            <PaddedPaper>
                                <BarChart
                                    data={
                                        _.map(
                                            _.orderBy(data.g, i => i.p.n, 'asc'),
                                            i => ({
                                                label: i.p.n,
                                                value: {
                                                    [currentYear] : i[mod].t.c,
                                                    [lastYear] :  i[mod].t.l,
                                                },
                                                color: {
                                                    [currentYear] : colors.primary,
                                                    [lastYear] :    colors.red,
                                                },
                                            })
                                        )
                                    } 
                                    yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                    tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                    style={{
                                        height: 300,
                                    }}
                                />
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Card>
                                <CardHeader
                                    // avatar={<AllIcon icon={ IconHelper.percent} size='medium' style={{color: '#fff'}} />}
                                    style={{background:'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', color: '#FFF'}}
                                    title={
                                        <Grid container spacing={1} style={{alignItems: 'flex-end'}}>
                                            <Grid item >
                                                <>Part Categories Report</>
                                            </Grid>
                                            <Grid item >
                                                <Typography variant="body1" style={{paddingBottom: 1}} >{currentYear} vs. {lastYear} {(this.props.current && mod === 'c') ? ` Up Until ${getOrdinal(moment().format('D'))} ${moment().format('MMMM')}` : ''}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    titleTypographyProps={{variant:'h6'}}
                                />
                                <CardContent style={{padding:5}}>
                                    <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                        <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                            <TableRow>
                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}></TableCell>
                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Qty</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Invoices</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>List Price</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Cost</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Margin £</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Margin %</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Discount £</b></Typography></TableCell>
                                                <TableCell align='center'   style={{width:'10%', borderBottom: 'none', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Discount %</b></Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography>
                                                                {currentYear}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography>
                                                                {lastYear}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            Difference
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            Difference %
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                    <Grid container style={{alignItems: 'center'}}>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getQty(data[mod].t.c.q)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getQty(data[mod].t.l.q)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorQtyChange(parseFloat(data[mod].t.c.q), parseFloat(data[mod].t.l.q))}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                        {getColorPercentageChange(parseFloat(data[mod].t.c.q),parseFloat(data[mod].t.l.q))}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                    <Grid container style={{alignItems: 'center'}}>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getQty(data[mod].t.c.i,0)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getQty(data[mod].t.l.i,0)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorQtyChange(parseFloat(data[mod].t.c.i), parseFloat(data[mod].t.l.i),false,0)}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                        {getColorPercentageChange(parseFloat(data[mod].t.c.i),parseFloat(data[mod].t.l.i))}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                    <Grid container style={{alignItems: 'center'}}>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(data[mod].t.c.p)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(data[mod].t.l.p)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange(parseFloat(data[mod].t.c.p) , parseFloat(data[mod].t.l.p))}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(parseFloat(data[mod].t.c.p) , parseFloat(data[mod].t.l.p))}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                    <Grid container style={{alignItems: 'center'}}>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(data[mod].t.c.t)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(data[mod].t.l.t)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange(parseFloat(data[mod].t.c.t) , parseFloat(data[mod].t.l.t))}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(parseFloat(data[mod].t.c.t) , parseFloat(data[mod].t.l.t))}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                    <Grid container style={{alignItems: 'center'}}>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(data[mod].t.c.c)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(data[mod].t.l.c)}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange(parseFloat(data[mod].t.c.c) , parseFloat(data[mod].t.l.c))}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(parseFloat(data[mod].t.c.c) , parseFloat(data[mod].t.l.c))}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>


                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                    <Grid container style={{alignItems: 'center'}}>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(parseFloat(data[mod].t.c.t - data[mod].t.c.c))}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(parseFloat(data[mod].t.l.t - data[mod].t.l.c))}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange(parseFloat(data[mod].t.c.t - data[mod].t.c.c) , parseFloat(data[mod].t.l.t - data[mod].t.l.c))}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(parseFloat(data[mod].t.c.t - data[mod].t.c.c) , parseFloat(data[mod].t.l.t - data[mod].t.l.c))}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            {getPercentage( parseFloat(parseFloat(data[mod].t.c.t - data[mod].t.c.c)) , parseFloat(data[mod].t.c.t) )}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {getPercentage(  parseFloat(parseFloat(data[mod].t.l.t - data[mod].t.l.c)) , parseFloat(data[mod].t.l.t) )}
                                                        </Grid>
                                                        <Grid item xs={12}
                                                            style={{color: 
                                                                parseFloat(
                                                                    getPlainPercentage( parseFloat(parseFloat(data[mod].t.c.t - data[mod].t.c.c)) , parseFloat(data[mod].t.c.t) ) -
                                                                    getPlainPercentage( parseFloat(parseFloat(data[mod].t.l.t - data[mod].t.l.c)) , parseFloat(data[mod].t.l.t) ) 
                                                                ) === 0 ? colors.green :
                                                                parseFloat(
                                                                    getPlainPercentage( parseFloat(parseFloat(data[mod].t.c.t - data[mod].t.c.c)) , parseFloat(data[mod].t.c.t) ) -
                                                                    getPlainPercentage( parseFloat(parseFloat(data[mod].t.l.t - data[mod].t.l.c)) , parseFloat(data[mod].t.l.t) ) 
                                                                ) > 0 ? colors.green : colors.red,
                                                                height: 28.04 * 2,
                                                                alignContent: 'center'
                                                            }}
                                                            container
                                                        >
                                                            <Grid item xs={12}>
                                                                {
                                                                    getColorArrow(
                                                                        parseFloat(
                                                                            getPlainPercentage( parseFloat(parseFloat(data[mod].t.c.t - data[mod].t.c.c)) , parseFloat(data[mod].t.c.t) ) -
                                                                            getPlainPercentage( parseFloat(parseFloat(data[mod].t.l.t - data[mod].t.l.c)) , parseFloat(data[mod].t.l.t) ) 
                                                                        )
                                                                    )
                                                                }
                                                                {
                                                                        parseFloat(
                                                                        getPlainPercentage( parseFloat(parseFloat(data[mod].t.c.t - data[mod].t.c.c)) , parseFloat(data[mod].t.c.t) ) -
                                                                        getPlainPercentage( parseFloat(parseFloat(data[mod].t.l.t - data[mod].t.l.c)) , parseFloat(data[mod].t.l.t) ) 
                                                                    ) === 0 ? '' :
                                                                    parseFloat(
                                                                        getPlainPercentage( parseFloat(parseFloat(data[mod].t.c.t - data[mod].t.c.c)) , parseFloat(data[mod].t.c.t) ) -
                                                                        getPlainPercentage( parseFloat(parseFloat(data[mod].t.l.t - data[mod].t.l.c)) , parseFloat(data[mod].t.l.t) ) 
                                                                    ) > 0 ? '+' : '-'
                                                                }
                                                                {
                                                                    parseFloat(
                                                                        Math.abs(
                                                                            parseFloat(
                                                                                getPlainPercentage( parseFloat(parseFloat(data[mod].t.c.t - data[mod].t.c.c)) , parseFloat(data[mod].t.c.t) ) -
                                                                                getPlainPercentage( parseFloat(parseFloat(data[mod].t.l.t - data[mod].t.l.c)) , parseFloat(data[mod].t.l.t) ) 
                                                                            )
                                                                        )
                                                                    ).toFixed(2)
                                                                }%
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                    <Grid container style={{alignItems: 'center'}}>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(parseFloat(data[mod].t.c.p - data[mod].t.c.t))}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getCurrency(parseFloat(data[mod].t.l.p - data[mod].t.l.t))}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {getColorCurrencyChange(
                                                                        parseFloat(
                                                                                parseFloat(parseFloat(data[mod].t.c.p) - parseFloat(data[mod].t.c.t)) 
                                                                        ),
                                                                        parseFloat(
                                                                            parseFloat(parseFloat(data[mod].t.l.p) - parseFloat(data[mod].t.l.t) )
                                                                        ),
                                                                        true
                                                                    )}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {
                                                                        getColorPercentageChange(
                                                                            parseFloat(parseFloat(parseFloat(data[mod].t.c.p) - parseFloat(data[mod].t.c.t)) ),
                                                                            parseFloat(parseFloat(parseFloat(data[mod].t.l.p) - parseFloat(data[mod].t.l.t)) ),
                                                                            true
                                                                        )
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  textAlign:'center', borderBottom: '0px solid black'}}>
                                                    <Grid container style={{alignItems: 'center'}} spacing={1}>
                                                        <Grid item xs={12}>
                                                            {parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.c.p) - parseFloat(data[mod].t.c.t))) / parseFloat(parseFloat(data[mod].t.c.p))) * 100).toFixed(2)}%
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.l.p) - parseFloat(data[mod].t.l.t))) / parseFloat(parseFloat(data[mod].t.l.p))) * 100).toFixed(2)}%
                                                        </Grid>
                                                        <Grid item xs={12}
                                                            style={{
                                                                color: (
                                                                    parseFloat(
                                                                        parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.c.p) - parseFloat(data[mod].t.c.t))) / parseFloat(parseFloat(data[mod].t.c.p))) * 100) -
                                                                        parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.l.p) - parseFloat(data[mod].t.l.t))) / parseFloat(parseFloat(data[mod].t.l.p))) * 100)
                                                                    ) < 0
                                                                ) ? colors.green : (
                                                                    parseFloat(
                                                                        parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.c.p) - parseFloat(data[mod].t.c.t))) / parseFloat(parseFloat(data[mod].t.c.p))) * 100) -
                                                                        parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.l.p) - parseFloat(data[mod].t.l.t))) / parseFloat(parseFloat(data[mod].t.l.p))) * 100)
                                                                    ) > 0
                                                                    ? colors.red : colors.green
                                                                ),
                                                                height: 28.04 * 2,
                                                                alignContent: 'center'
                                                            }}
                                                            container
                                                        >
                                                            <Grid item xs={12}>
                                                                {
                                                                    getColorArrow(
                                                                        parseFloat(
                                                                            parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.c.p) - parseFloat(data[mod].t.c.t))) / parseFloat(parseFloat(data[mod].t.c.p))) * 100) -
                                                                            parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.l.p) - parseFloat(data[mod].t.l.t))) / parseFloat(parseFloat(data[mod].t.l.p))) * 100)
                                                                        ), true
                                                                    )
                                                                }
                                                                {
                                                                    (
                                                                        parseFloat(
                                                                            parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.c.p) - parseFloat(data[mod].t.c.t))) / parseFloat(parseFloat(data[mod].t.c.p))) * 100) -
                                                                            parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.l.p) - parseFloat(data[mod].t.l.t))) / parseFloat(parseFloat(data[mod].t.l.p))) * 100)
                                                                        ) < 0
                                                                    ) ? '': (
                                                                        parseFloat(
                                                                            parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.c.p) - parseFloat(data[mod].t.c.t))) / parseFloat(parseFloat(data[mod].t.c.p))) * 100) -
                                                                            parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.l.p) - parseFloat(data[mod].t.l.t))) / parseFloat(parseFloat(data[mod].t.l.p))) * 100)
                                                                        ) > 0
                                                                        ? '+' : ''
                                                                    )
                                                                }
                                                                {
                                                                    parseFloat(
                                                                        parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.c.p) - parseFloat(data[mod].t.c.t))) / parseFloat(parseFloat(data[mod].t.c.p))) * 100) -
                                                                        parseFloat((parseFloat(parseFloat(parseFloat(data[mod].t.l.p) - parseFloat(data[mod].t.l.t))) / parseFloat(parseFloat(data[mod].t.l.p))) * 100)
                                                                    ).toFixed(2)
                                                                }%
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>

                                             

                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <CiDataTable
                                config={{
                                    key: 'i',
                                    pagination: false,
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                    isLoading: isLoading,
                                    sticky:true
                                }}
                                rows={_.orderBy(data.g, i => i.p.n, 'asc')}
                                columns={[
                                    {
                                        heading: 'Part Category',
                                        field:  i =>
                                        <Grid container spacing={0}
                                            onClick={()=>{
                                                this.props.history.push(`/parts/categories/${i.p.i}`);
                                            }}
                                            style={{cursor: 'pointer'}}
                                        >
                                            <Grid item xs={12}>
                                                <Typography variant="h6" style={{fontSize: '14px'}}>{i.p.n}</Typography>
                                            </Grid>
                                        </Grid>,
                                        dataRef: 'p.n',
                                        important: true,
                                        sizeToContent: true,
                                        truncate: true
                                    },
                                    {
                                        heading: '',
                                        field: () => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{currentYear}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{lastYear}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>Difference</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>Difference %</Typography>
                                                </Grid>
                                            </Grid>,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'invoice',
                                        field: i => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{parseInt(i[mod].i.c)}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{parseInt(i[mod].i.l)}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorQtyChange(parseFloat(i[mod].i.c), parseFloat(i[mod].i.l),false,0)}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(parseFloat(i[mod].i.c), parseFloat(i[mod].i.l))}</Typography>
                                                </Grid>
                                            </Grid>,
                                        dataRef: `${mod}.i.c`,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'qty',
                                        field: i => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{parseFloat(i[mod].q.c).toFixed(3)}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{parseFloat(i[mod].q.l).toFixed(3)}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorQtyChange(parseFloat(i[mod].q.c), parseFloat(i[mod].q.l))}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(parseFloat(i[mod].q.c), parseFloat(i[mod].q.l))}</Typography>
                                                </Grid>
                                            </Grid>,
                                        dataRef: `${mod}.q.c`,
                                        sizeToContent: true
                                    },
                                    ..._.map([
                                        {
                                            heading: 'List Price',
                                            k: 'p',
                                        },
                                        {
                                            heading: 'Net',
                                            k: 't',
                                        },
                                        {
                                            heading: 'Cost',
                                            k: 'c',
                                        },
                                    ], ({heading, k}) => 
                                        (
                                            {
                                                heading: heading,
                                                sizeToContent: true,
                                                dataRef: `${mod}.${k}.c`,
                                                field: i => 
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i[mod][k].c)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(i[mod][k].l)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}> 
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange(i[mod][k].c, i[mod][k].l )}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(i[mod][k].c, i[mod][k].l )}</Typography>
                                                        </Grid>
                                                    </Grid>,
                                            }
                                        )
                                    ),
                                    {
                                        heading: 'Margin £',
                                        sizeToContent: true,
                                        dataRef: i => parseFloat( i[mod].t.c - i[mod].c.c ),
                                        field: i => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(parseFloat( i[mod].t.c - i[mod].c.c ))}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(parseFloat( i[mod].t.l - i[mod].c.l ))}</Typography>
                                                </Grid>
                                                <Grid item xs={12}> 
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange(parseFloat( i[mod].t.c - i[mod].c.c ), parseFloat( i[mod].t.l - i[mod].c.l ) )}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(parseFloat( i[mod].t.c - i[mod].c.c ), parseFloat( i[mod].t.l - i[mod].c.l ) )}</Typography>
                                                </Grid>
                                            </Grid>,
                                    },
                                    {
                                        heading: 'Margin %',
                                        sizeToContent: true,
                                        dataRef: i => parseFloat( i[mod].t.c - i[mod].c.c ),
                                        field: i => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getPercentage( parseFloat(parseFloat(i[mod].t.c - i[mod].c.c)) , parseFloat(i[mod].t.c) )}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getPercentage( parseFloat(parseFloat(i[mod].t.l - i[mod].c.l)) , parseFloat(i[mod].t.l) )}</Typography>
                                                </Grid>
                                                <Grid item xs={12} style={{
                                                        height: 28.04 * 2,
                                                        alignContent: 'center'
                                                    }}
                                                    container
                                                >
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" style={{fontSize: '14px', color: 
                                                                parseFloat(
                                                                    getPlainPercentage( parseFloat(i[mod].t.c - i[mod].c.c) , parseFloat(i[mod].t.c) ) -
                                                                    getPlainPercentage( parseFloat(i[mod].t.l - i[mod].c.l) , parseFloat(i[mod].t.l) ) 
                                                                ) === 0 ? colors.green :
                                                                parseFloat(
                                                                    getPlainPercentage( parseFloat(i[mod].t.c - i[mod].c.c) , parseFloat(i[mod].t.c) ) -
                                                                    getPlainPercentage( parseFloat(i[mod].t.l - i[mod].c.l) , parseFloat(i[mod].t.l) ) 
                                                                ) > 0 ? colors.green : colors.red
                                                            }}
                                                        >
                                                            {
                                                                getColorArrow(
                                                                    parseFloat(
                                                                        getPlainPercentage( parseFloat(i[mod].t.c - i[mod].c.c) , parseFloat(i[mod].t.c) ) -
                                                                        getPlainPercentage( parseFloat(i[mod].t.l - i[mod].c.l) , parseFloat(i[mod].t.l) ) 
                                                                    )
                                                                )
                                                            }
                                                            {
                                                                    parseFloat(
                                                                    getPlainPercentage( parseFloat(i[mod].t.c - i[mod].c.c) , parseFloat(i[mod].t.c) ) -
                                                                    getPlainPercentage( parseFloat(i[mod].t.l - i[mod].c.l) , parseFloat(i[mod].t.l) ) 
                                                                ) === 0 ? '' :
                                                                parseFloat(
                                                                    getPlainPercentage( parseFloat(i[mod].t.c - i[mod].c.c) , parseFloat(i[mod].t.c) ) -
                                                                    getPlainPercentage( parseFloat(i[mod].t.l - i[mod].c.l) , parseFloat(i[mod].t.l) ) 
                                                                ) > 0 ? '+' : '-'
                                                            }
                                                            {
                                                                parseFloat(
                                                                    Math.abs(
                                                                        parseFloat(
                                                                            getPlainPercentage( parseFloat(i[mod].t.c - i[mod].c.c) , parseFloat(i[mod].t.c) ) -
                                                                            getPlainPercentage( parseFloat(i[mod].t.l - i[mod].c.l) , parseFloat(i[mod].t.l) ) 
                                                                        )
                                                                    )
                                                                ).toFixed(2)
                                                            }%
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>,
                                    },
                                    {
                                        heading: 'Discount £',
                                        sizeToContent: true,
                                        dataRef:i => parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)),
                                        field: i => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)))}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getCurrency(parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)))}</Typography>
                                                </Grid>
                                                <Grid item xs={12}> 
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorCurrencyChange(
                                                        parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), 
                                                        parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)),
                                                        true
                                                    )}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getColorPercentageChange(
                                                        parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), 
                                                        parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)),
                                                        true
                                                    )}</Typography>
                                                </Grid>
                                            </Grid>,
                                    },
                                    {
                                        heading: 'Discount %',
                                        sizeToContent: true,
                                        dataRef: i => parseFloat(getPlainPercentage(parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c))),
                                        field: i => 
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getPercentage(parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c))}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{fontSize: '14px'}}>{getPercentage(parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l))}</Typography>
                                                </Grid>
                                                <Grid item xs={12} style={{
                                                        height: 28.04 * 2,
                                                        alignContent: 'center'
                                                    }}
                                                    container
                                                >
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" style={{fontSize: '14px',color:
                                                                parseFloat(
                                                                    getPlainPercentage( parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c) ) -
                                                                    getPlainPercentage( parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l) ) 
                                                                ) === 0 ? colors.green :
                                                                parseFloat(
                                                                    getPlainPercentage( parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c) ) -
                                                                    getPlainPercentage( parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l) ) 
                                                                ) < 0 ? colors.green : colors.red}}
                                                            >
                                                                {
                                                                    getColorArrow(
                                                                        parseFloat(
                                                                            getPlainPercentage( parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c) ) -
                                                                            getPlainPercentage( parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l) ) 
                                                                        ), true
                                                                    )
                                                                }
                                                                {
                                                                        parseFloat(
                                                                        getPlainPercentage( parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c) ) -
                                                                        getPlainPercentage( parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l) ) 
                                                                    ) === 0 ? '' :
                                                                    parseFloat(
                                                                        getPlainPercentage( parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c) ) -
                                                                        getPlainPercentage( parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l) ) 
                                                                    ) > 0 ? '+' : '-'
                                                                }
                                                                {
                                                                    parseFloat(
                                                                        Math.abs(
                                                                            parseFloat(
                                                                                getPlainPercentage( parseFloat( parseFloat(i[mod].p.c) - parseFloat(i[mod].t.c)), parseFloat(i[mod].p.c) ) -
                                                                                getPlainPercentage( parseFloat( parseFloat(i[mod].p.l) - parseFloat(i[mod].t.l)), parseFloat(i[mod].p.l) ) 
                                                                            )
                                                                        )
                                                                    ).toFixed(2)
                                                                }%
                                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>,
                                    },
                                ]}
                            />
                        </Grid>
                    </>    
            }
            </Grid>
        )
    }
}

export default PartCategoriesReport;