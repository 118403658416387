import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import Textarea from 'Components/Common/Inputs/Textarea';
import { Button, TextField, Typography, Grid } from '@material-ui/core';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

export default function CompanyInformation() {
    const dispatch = useDispatch();
    const initialState = {
        formData: {
            name: '',
            details: ''
        },
        formErrors: {},
        confirmationOpen: false,
        access: {
            addCompanyInfo: false,
        },
        data: [],
        isLoading: true
    }
    const [state, setState] = useState({...initialState});
    const {formData, formErrors, confirmationOpen, access, data, isLoading} = state;
    const handleTextChange = e => {
        e.persist();
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [e.target.id]: e.target.value
            }
        }));
    }
    const handleConfirmationOpen = () => setState(state => ({...state, confirmationOpen: true}));
    const handleConfirmationClose = () => setState(state => ({...state, confirmationOpen: false}));
    const handleConfirmationSuccess = () => {
        API.post('/accounts/companyInformation', formData)
        .then(result => {
            if(result.data.errors) {
                setState(state => ({...state, formErrors: formatValidationErrors(result.data.errors), confirmationOpen: false}));
            } else {
                dispatch(deploySnackBar('success', 'You have successfully added company information'));
                getComponentData();
            }
        });
    }
    const getComponentData = () => {
        Promise.all([
            API.get('/staff/my/access/check/company-information:add-company-information'),
            API.get('/accounts/companyInformation')
        ])
        .then(([addRes, result]) => {
            setState(state => ({
                ...state,
                formData: {
                    name: '',
                    details: ''
                },
                access: {
                    addCompanyInfo: addRes.data.has_access,
                },
                confirmationOpen: false,
                data: result.data,
                isLoading: false
            }));
        });
    }
    useEffect(() => {
        getComponentData();
    }, [])
    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5">
                    Company Information
                </Typography>
            </Grid>
            {isLoading ? (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            ) : (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Grid container item spacing={3}>
                            {access.addCompanyInfo && 
                                <Grid item xs={12} md={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Add Information
                                        </Typography>
                                        <form noValidate autoComplete="off">
                                            <TextField 
                                                id="name"
                                                name="name"
                                                label="Name *"
                                                value={formData.name}
                                                onChange={handleTextChange}
                                                error={formErrors && formErrors['name'] && true}
                                                helperText={formErrors && formErrors['name']}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <Textarea
                                                id="details"
                                                name="details"
                                                label="Details *"
                                                value={formData.details}
                                                onChange={handleTextChange}
                                                error={formErrors && formErrors['details']}
                                            />
                                            <div className="buttonRow">
                                                <Button
                                                    onClick={handleConfirmationOpen}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!formData.name}
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                            <ConfirmationDialog 
                                                open={confirmationOpen}
                                                success={handleConfirmationSuccess}
                                                close={handleConfirmationClose}
                                                title="Add New Company Information?"
                                                message="Are you sure you want to add new company information?"
                                            />
                                        </form>
                                    </PaddedPaper>
                                </Grid>
                            } 
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <DataTable 
                                config={{
                                    key: 'company_info_id',
                                    responsiveImportance: true,
                                    isLoading,
                                    alternatingRowColours: true
                                }}
                                columns={[
                                    {
                                        heading: 'Name',
                                        field: rowData => rowData.company_info_name,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Details',
                                        field: rowData => rowData.company_info_details,
                                        truncate: true
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'Copy to Clipboard', type: 'copyToClipboard', data: rowData.company_info_details}
                                            ]
                                        }
                                    }
                                ]}
                                rows={data}
                            />
                        </PaddedPaper>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
}