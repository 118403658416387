import React, {Component} from 'react';

import { IconButton, TablePagination  } from '@material-ui/core';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const actionsStyles = theme => ({
    root: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2.5),
    },
    [theme.breakpoints.down('sm')]: {
        root: {
            marginLeft: 0,
            width: '100%',
            textAlign: 'center'
        },
    },
});

class TablePaginationActions extends Component {
    handleFirstPageButtonClick = event => {
      this.props.onChangePage(event, 0);
    };
  
    handleBackButtonClick = event => {
      this.props.onChangePage(event, this.props.page - 1);
    };
  
    handleNextButtonClick = event => {
      this.props.onChangePage(event, this.props.page + 1);
    };
  
    handleLastPageButtonClick = event => {
      this.props.onChangePage(
        event,
        Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
      );
    };
  
    render() {
      const { classes, count, page, rowsPerPage, theme } = this.props;
      return (
        <div className={classes.root}>
          <IconButton
            onClick={this.handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="First Page"
          >
            {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
          </IconButton>
          <IconButton
            onClick={this.handleBackButtonClick}
            disabled={page === 0}
            aria-label="Previous Page"
          >
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </IconButton>
          <IconButton
            onClick={this.handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Next Page"
          >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </IconButton>
          <IconButton
            onClick={this.handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Last Page"
          >
            {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
          </IconButton>
        </div>
      );
    }
}

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
    TablePaginationActions,
);

class Pagination extends Component {
    render() {
        return (
            <React.Fragment>
                <TablePagination
                    colSpan={this.props.colSpan}
                    count={this.props.count}
                    rowsPerPage={this.props.rowsPerPage}
                    page={this.props.count <= this.props.rowsPerPage && this.props.page !== 0 ? 0 : this.props.page}
                    onChangePage={this.props.onChangePage}
                    onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}   
                    labelDisplayedRows={({ from, to, count }) => { return this.props.isMobile ? '' :  `${from}-${to} of ${count}`; }}
                    labelRowsPerPage={(this.props.isMobile || this.props.noRowSelector) ? '' : 'Rows per page:'}                   
                    rowsPerPageOptions={(this.props.isMobile || this.props.noRowSelector)  ? [] : [5, 10, 25, 50, 100]} 
                    style={{borderBottom: this.props.plainPagination ? 0 : '', ...this.props.style}}
                />
            </React.Fragment>
        );
    }
}
  
export default Pagination;