import React, { Component } from 'react';
import _                    from 'lodash';
import moment               from 'moment';
import API                  from 'API';
import { Redirect }         from 'react-router-dom';

import { Button, Grid, FormControl, IconButton, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { pdfFromBase64 }          from 'Helpers/PDFHelper';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import DatePicker         from 'Components/Common/DatePickers/DatePicker';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FALightIcon        from 'Components/Common/Icons/FontAwesome/FALightIcon';
import Textarea           from 'Components/Common/Inputs/Textarea';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';

import AffectedParts from './AffectedParts';

const initialState = {
    psbData: [],
    partsList: [],
    formData:{
        sections: [{
            heading: '',
            dateIntroduced: [moment().format('DD/MM/YYYY')],
            overview: '',
            affectedParts: [{
                id: 0,
                version: 0,
                number: '',
                description: '',
                fields: '',
                filesIssued: {
                    pdf: false,
                    design: false,
                    stl: false
                },
                details: '',
                useDefaultImage: true,
                defaultImageURL: '',
                imgUpload: '',
                selectedPartImages: [],
                uploadedImageURLs: []
            }]
        }],
        previewBulletin: false
    },
    formErrors: [],
    isLoading: false,
    confirmationOpen: {
        removeUploadedImage: false,
        uploadedImageIDs: [],
        updateBulletin: false
    },
    redirect: false
}
const styles = theme => ({
    paperGrey: {        
        margin: 0,
        padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px `,
        backgroundColor:'#fefefe'
    }, 
    addSection: {
        display: 'flex',
        alignItems: 'center',
        justify: 'center',
        padding: theme.spacing(3),
    },
    sectionMargin: {        
        marginTop: theme.spacing(2)
    }
});
class UpdateProductionServiceBulletin extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState;
    }
    componentDidMount(){
        this.getProductionServiceBulletin(this.props.match.params.id);
        this.getPartsList();
    }
    getProductionServiceBulletin = psbId => {
        this.setState({isLoading: true}, () => {         
            API.get(`/researchDevelopment/productionServiceBulletin/${psbId}`)
            .then(result => {
                if(result.data) {
                    this.setState({
                        ...this.state,
                        psbData: result.data
                    }, 
                    () => {
                        if(this.state.psbData.psb_status === 'In Progress'){
                            this.getProductionServiceBulletinSections(psbId);
                        } else {
                            this.setState({isLoading: false})
                        }
                    });
                }
            });
        });
    }
    getProductionServiceBulletinSections = psbId => {
        API.get(`/researchDevelopment/productionServiceBulletin/${psbId}/sections`)
        .then(result => {
            let sections = [];
            result.data.production_service_bulletin_sections.forEach(psbs => {
                let affectedParts = [];
                psbs.production_service_bulletin_section_parts.forEach(psbsp => {
                    let selectedPartImages = [];
                    let uploadedImageURLs = [];
                    let currentUploadedImages = [];

                    psbsp.production_service_bulletin_section_part_images.forEach(pbspi => {
                        if(pbspi.psbspi_library_id !== null) {
                            selectedPartImages.push(pbspi.psbspi_library_id);
                        } else {
                            currentUploadedImages.push(pbspi.psbspi_uploaded_img_filename);
                            uploadedImageURLs.push(pbspi.psbspi_uploaded_img_url);
                        }
                    });

                    affectedParts.push({
                        id: psbsp.psbsp_part_id,
                        version: psbsp.part.latest_dco_details ? psbsp.part.latest_dco_details.dco_details_v_version : 0,
                        number: psbsp.part.part_number,
                        description: psbsp.part.part_description,
                        fields: psbsp.psbsp_fields,
                        filesIssued: {
                            pdf: (psbsp.psbsp_pdf_issued && true) || false,
                            design: (psbsp.psbsp_design_issued && true) || false,
                            stl: (psbsp.psbsp_stl_issued && true) || false
                        },
                        details: psbsp.psbsp_details,
                        useDefaultImage: psbsp.psbsp_use_default_image,
                        defaultImageURL: psbsp.part.default_image !== null  && psbsp.part?.default_image?.file_path ? psbsp.part?.default_image?.file_path : logo,
                        selectedPartImages,
                        uploadedImageURLs,
                        currentUploadedImages,
                        imgUpload: ''
                    }); 
                });

                sections.push({
                    heading: psbs.psbs_heading,
                    dateIntroduced: psbs.psbs_date_introduced.split('|'),
                    overview: psbs.psbs_overview,
                    affectedParts
                });
            });

            this.setState({
                formData:{
                    ...this.state.formData,
                    sections
                },
                isLoading: false
            });
        });
    }
    getPartsList = () => {
        API.get('/parts/all')
        .then(result => {
            let partsList = _.map(result.data, el => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description,
                });
            });
            this.setState({
                ...this.state,
                partsList
            });
        })
    }
    getPart = (sectionIdx, idx, partId) => {
        Promise.all([
            API.get(`/parts/${partId}`),
            API.get(`/researchDevelopment/changeOrders/part/${partId}/actualIssue`)
        ])
        .then(([partRes, issueRes]) => {
            let affectedParts = [...this.state.formData.sections[sectionIdx].affectedParts];
            affectedParts[idx] = {
                ...affectedParts[idx],
                version: issueRes.data,
                number: partRes.data.part_number,
                description: partRes.data.part_description,
                useDefaultImage: true,
                defaultImageURL: partRes.data.default_image !== null ? partRes.data.default_image.file_path : null,
                filesIssued:{
                    design: false,
                    pdf: false,
                    stl: false
                }
            };
            let sections = [...this.state.formData.sections];
            sections[sectionIdx] = {
                ...sections[sectionIdx],
                affectedParts
            };
            this.setState({
                formData:{
                    ...this.state.formData,
                    sections
                }
            });
        });
    }
    // Sections
    handleAddSection = () => {
        let item = initialState.formData.sections[0];
        this.setState({
            formData:{
                ...this.state.formData,
                sections: [...this.state.formData.sections, item]
            }
        });
    }
    handleRemoveSection = sectionIdx => {
        let sections = [...this.state.formData.sections];
        sections.splice(sectionIdx, 1);
        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        });
    }
    handleDateChange = (sectionIdx, dateIdx) => date => {
        let sections = [...this.state.formData.sections];
        let dateIntroduced = [...this.state.formData.sections[sectionIdx].dateIntroduced]
        dateIntroduced.splice(dateIdx, 1, moment(date).format('DD/MM/YYYY'));
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            dateIntroduced
        };
        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        });
    }
    handleAddDate = sectionIdx => {
        let sections = [...this.state.formData.sections];
        let newDate = [...this.state.formData.sections[sectionIdx].dateIntroduced]
        newDate.push(moment().format('DD/MM/YYYY'));
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            dateIntroduced: newDate
        };
        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        });
    }
    handleRemoveDate = (sectionIdx, dateIdx) => {
        let sections = [...this.state.formData.sections];
        let dateIntroduced = [...this.state.formData.sections[sectionIdx].dateIntroduced];
        dateIntroduced.splice(dateIdx, 1);
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            dateIntroduced
        };
        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        });
    }
    handleTextChange = sectionIdx => e => {
        let sections = [...this.state.formData.sections];
        sections[sectionIdx] = {
            ...sections[sectionIdx],
           [e.target.name]: e.target.value
        };
        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        });
    }
    // Parts
    handleAddPart = sectionIdx => {
        let item = initialState.formData.sections[0].affectedParts[0];
        let sections = [...this.state.formData.sections];
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            affectedParts: [...this.state.formData.sections[sectionIdx].affectedParts, item]
        };
        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        });
    }
    handleRemovePart = (sectionIdx, idx) => {
        let sections = [...this.state.formData.sections];
        let affectedParts = [...this.state.formData.sections[sectionIdx].affectedParts];
        affectedParts.splice(idx, 1);
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            affectedParts
        };
        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        });
    }
    handlePartSelectChange = (sectionIdx, idx) => e => {
        let partId = e ? e.value : 0;
        let affectedParts = [...this.state.formData.sections[sectionIdx].affectedParts];
        affectedParts[idx] = {
            ...affectedParts[idx],
            id: partId,
            selectedPartImages: []
        };
        let sections = [...this.state.formData.sections];
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            affectedParts
        };
        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        }, 
        () => {
            e && this.getPart(sectionIdx, idx, e.value);
        });
    }
    handlePartImagePickerSelection = (sectionIdx, idx, selectedPartImages) => {
        let affectedParts = [...this.state.formData.sections[sectionIdx].affectedParts];
        affectedParts[idx] = {
            ...affectedParts[idx],
            selectedPartImages
        };
        let sections = [...this.state.formData.sections];
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            affectedParts
        };
        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        });
    }
    handleImageUpload = (sectionIdx, idx) => (drop, name, event) => {
        const newFiles = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles = [...this.state.formData.sections[sectionIdx].affectedParts[idx].imgUpload];
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))

        let affectedParts = [...this.state.formData.sections[sectionIdx].affectedParts];
        affectedParts[idx] = {
            ...affectedParts[idx],
            imgUpload: allFiles
        };
        let sections = [...this.state.formData.sections];
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            affectedParts
        };
        this.setState({
            formData: {
                ...this.state.formData,
                sections
            }
        });
    }
    clearImageUpload = (sectionIdx, idx) => fileIdx => {
        let imgUpload = Array.from(this.state.formData.sections[sectionIdx].affectedParts[idx].imgUpload);

        imgUpload.splice(fileIdx, 1);
        // Changes component colour to original state after it has been fully cleared
        if(imgUpload.length === 0){
            imgUpload = '';
        }

        let affectedParts = [...this.state.formData.sections[sectionIdx].affectedParts];
        affectedParts[idx] = {
            ...affectedParts[idx],
            imgUpload
        };
        let sections = [...this.state.formData.sections];
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            affectedParts
        };
        this.setState({
            formData: {
                ...this.state.formData,
                sections
            }
        });
    }
    handleDeleteImage = (sectionIdx, idx, imageIdx) => {
        // remove from array before being sent to api
        let currentUploadedImages = this.state.formData.sections[sectionIdx].affectedParts[idx].currentUploadedImages;
        currentUploadedImages.splice(imageIdx, 1);

        // remove from frontend
        let uploadedImageURLs = this.state.formData.sections[sectionIdx].affectedParts[idx].uploadedImageURLs;
        uploadedImageURLs.splice(imageIdx, 1);

        let affectedParts = [...this.state.formData.sections[sectionIdx].affectedParts];
        affectedParts[idx] = {
            ...affectedParts[idx],
            currentUploadedImages,
            uploadedImageURLs
        };
        let sections = [...this.state.formData.sections];
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            affectedParts
        };
        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        });
    }
    handlePartDetails = (sectionIdx, idx) => e => {
        let affectedParts = [...this.state.formData.sections[sectionIdx].affectedParts];
        affectedParts[idx] = {
            ...affectedParts[idx],
            details: e.target.value
        };
        let sections = [...this.state.formData.sections];
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            affectedParts
        };
        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        });
    }
    handlePartFields = (sectionIdx, idx, field) => e => {
        let affectedParts = [...this.state.formData.sections[sectionIdx].affectedParts];
        affectedParts[idx] = {
            ...affectedParts[idx],
            fields: e.target.value
        };
        let sections = [...this.state.formData.sections];
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            affectedParts
        };
        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        });
    }
    handleDefaultImage = (sectionIdx, idx) => e => {
        let affectedParts = [...this.state.formData.sections[sectionIdx].affectedParts];
        affectedParts[idx] = {
            ...affectedParts[idx],
            useDefaultImage: JSON.parse(e.target.value)
        };
        let sections = [...this.state.formData.sections];
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            affectedParts
        };
        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        });
    }
    handleFilesIssued = (sectionIdx, idx) => e => {
        const { formData } = this.state;
        const { value } = e.target;
        let affectedParts = [...formData.sections[sectionIdx].affectedParts];
        let filesIssued = formData.sections[sectionIdx].affectedParts[idx].filesIssued;

        if(value === 'pdf') {
            filesIssued.pdf = !filesIssued.pdf;
        } else if(value === 'design') {
            filesIssued.design = !filesIssued.design;
        } else {
            filesIssued.stl = !filesIssued.stl;
        }

        affectedParts[idx] = {
            ...affectedParts[idx],
            filesIssued
        };
        
        let sections = [...formData.sections];
        sections[sectionIdx] = {
            ...sections[sectionIdx],
            affectedParts
        };

        this.setState({
            formData:{
                ...this.state.formData,
                sections
            }
        });
    }
    // Dialogs
    handleRemoveUploadedImage = (sectionIdx, idx, urlIdx) => this.setState({
        confirmationOpen:{
            ...this.state.confirmationOpen,
            removeUploadedImage: true,
            uploadedImageIDs: [sectionIdx, idx, urlIdx],
        }
    });
    handleRemoveUploadedImageSuccess = () => {
        const { confirmationOpen } = this.state;
        this.setState({
            confirmationOpen:{
                ...this.state.confirmationOpen,
                removeUploadedImage: false
            }
        }, () => {
            this.handleDeleteImage(confirmationOpen.uploadedImageIDs[0], confirmationOpen.uploadedImageIDs[1], confirmationOpen.uploadedImageIDs[2]);
        });
    }
    handleRemoveUploadedImageClose = () => {
        this.setState({
            confirmationOpen:{
                ...this.state.confirmationOpen,
                removeUploadedImage: false
            }
        });
    }
    handleUpdateBulletin = () => this.setState({
        confirmationOpen: {
            ...this.state.confirmationOpen,
            updateBulletin: true
        }
    });
    handleUpdateBulletinClose = () => this.setState({
        confirmationOpen: {
            ...this.state.confirmationOpen,
            updateBulletin: false
        }
    });
    handleUpdateBulletinSuccess = () => this.setState({
        confirmationOpen: {
            ...this.state.confirmationOpen,
            updateBulletin: false
        },
        formData:{
            ...this.state.formData,
            previewBulletin: false
        },
        formErrors: []
    }, () => this.handleSubmit());
    previewBulletin = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                previewBulletin: true
            }
        }, () => {
            this.handleSubmit();
        });
    }
    handleSubmit = () => {
        this.setState({
            isLoading: true
        }, () => {
            // Order dates and remove duplicates before submitting to db
            let sections = [...this.state.formData.sections];
            sections.forEach(el => {
                el.dateIntroduced = _.uniqBy(_.orderBy(el.dateIntroduced));
            });

            const formData = {
                ...this.state.formData,
                sections
            };            
            
            //Append files to newFormData
            let newFormData = new FormData();

            _.each(formData.sections, (section, sIdx) => {
                _.each(section.affectedParts, (affectedPart, idx) => {
                    if(affectedPart.imgUpload && affectedPart.imgUpload.length > 0) {                                       
                        Array.from(affectedPart.imgUpload).forEach(file => { 
                            newFormData.append(`${idx}|${sIdx}[]`, file);  
                        });              
                    }
                });
            });

            newFormData.append('formData', JSON.stringify(this.state.formData));

            API.post(`/researchDevelopment/productionServiceBulletin/${this.state.psbData.psb_id}/update`, newFormData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            })
            .then(result => {
                if(result.data.errors) {
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                } else if(result.data.pdf){
                    this.setState({
                        formErrors: [],
                        isLoading: false,
                    }, () => {
                        pdfFromBase64(result.data.pdf, result.data.pdfName);
                    });
                } else {
                    this.setState({
                        formErrors: [],
                        redirect: true
                    });
                }
            });
        });
    }
    render() {
        const { classes } = this.props;
        const { psbData, formData, formErrors } = this.state;
        return(
            <React.Fragment>
                {this.state.redirect && 
                    <Redirect to={{
                        pathname: "/research-development/production-service-bulletin/search",
                        state:{
                            year: psbData.psb_year,
                            month: psbData.psb_month,
                            snackbarOpen: true
                        }
                    }} 
                    />
                }
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Production & Service Bulletin: {psbData.psb_month && moment(psbData.psb_month, 'M').format('MMMM')} {psbData.psb_year && psbData.psb_year}
                        </Typography>
                    </Grid>
                    {this.state.isLoading ? 
                        (<LoadingCircle />
                    ) : (
                        <Grid item xs={6}>
                            {formData.sections.map((section, sectionIdx) => (
                                <PaddedPaper key={sectionIdx} style={{marginBottom:25}}>
                                    <Grid container>
                                        {formErrors && formErrors.generic && (
                                            <Grid item xs={12}>
                                                <Typography component={"div"} style={{color: colors.red, marginBottom: 5}}>
                                                    {formErrors.generic}
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid item xs={11}>
                                            <Typography variant="h6">
                                                Section {sectionIdx + 1}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} style={{textAlign:'center'}}>
                                            <IconButton onClick={() => this.handleRemoveSection(sectionIdx)} disabled={(sectionIdx+1) === 1}>
                                                <FALightIcon icon="trash-alt" noMargin button style={{height:16, width:16, color: (sectionIdx+1) === 1 ? '#fefefe' : 'inherit'}} />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth margin="normal">
                                                <TextField 
                                                    id="heading"
                                                    name="heading"
                                                    label="Heading *"
                                                    value={section.heading}
                                                    onChange={this.handleTextChange(sectionIdx)}
                                                    error={formErrors && formErrors['heading'+sectionIdx] && true}
                                                    helperText={formErrors && formErrors['heading'+sectionIdx]}
                                                />
                                            </FormControl>
                                        </Grid>
                                            {section.dateIntroduced.map((date, dateIdx) => (
                                                <React.Fragment key={dateIdx}>
                                                    <Grid item xs={3}>
                                                        <DatePicker 
                                                            id="dateIntroduced"
                                                            name="dateIntroduced"
                                                            label="Date Introduced *"
                                                            value={moment(date, 'DD/MM/YYYY').format()}
                                                            autoOk={true}
                                                            onChange={this.handleDateChange(sectionIdx, dateIdx)}
                                                            error={formErrors && formErrors['dateIntroduced'+sectionIdx] && true}
                                                            errorText={formErrors && formErrors['dateIntroduced'+sectionIdx]}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} style={{textAlign:'center'}}>
                                                        <IconButton onClick={() => this.handleRemoveDate(sectionIdx, dateIdx)} style={{position:'relative', top:29}} disabled={(dateIdx+1) === 1}>
                                                            <FALightIcon icon="trash-alt" noMargin button style={{height:16, width:16, color: (dateIdx+1) === 1 ? '#fefefe' : 'inherit'}} />
                                                        </IconButton>
                                                    </Grid>
                                                </React.Fragment>
                                            ))}
                                        <Grid item xs={12}>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                className={classes.sectionMargin}
                                                onClick={() => this.handleAddDate(sectionIdx)}
                                            >
                                                Add Date
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Textarea
                                                id="overview"
                                                name="overview"
                                                label="Overview *"
                                                value={section.overview}
                                                rows={5}
                                                onChange={this.handleTextChange(sectionIdx)}
                                                error={formErrors && formErrors['overview'+sectionIdx]}
                                            />
                                        </Grid>
                                        {/* Affected parts */}
                                        {section.affectedParts.map((affectedPart, idx) => (
                                            <AffectedParts
                                                key={idx}
                                                idx={idx}
                                                sectionIdx={sectionIdx}
                                                partsList={this.state.partsList}
                                                data={affectedPart}
                                                formErrors={formErrors}
                                                handlePartSelectChange={this.handlePartSelectChange}
                                                handlePartFields={this.handlePartFields}
                                                handleDefaultImage={this.handleDefaultImage}
                                                handleFilesIssued={this.handleFilesIssued}
                                                handleRemovePart={this.handleRemovePart}
                                                handlePartDetails={this.handlePartDetails}
                                                handleImageUpload={this.handleImageUpload}
                                                clearImageUpload={this.clearImageUpload}
                                                handlePartImagePickerSelection={this.handlePartImagePickerSelection}
                                                handleRemoveUploadedImage={this.handleRemoveUploadedImage}
                                            />
                                        ))}
                                        <Grid item xs={12}>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                className={classes.sectionMargin}
                                                onClick={() => this.handleAddPart(sectionIdx)}
                                            >
                                                Add Part
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            ))}
                            <Grid item xs={12} sm={6} lg={4}>
                                <PaddedPaper className={`${classes.paperGrey} ${classes.addSection} ${classes.sectionMargin}`} onClick={this.handleAddSection}>
                                    <FALightIcon icon='plus' noMargin button style={{width: '100%', height: '74px', color: '#ddd'}} />
                                </PaddedPaper>
                            </Grid>
                            <Button 
                                onClick={this.previewBulletin}
                                variant="outlined"
                                color="primary"
                                className={classes.sectionMargin}
                                style={{marginRight:10}}
                            >
                                Preview PDF
                            </Button>
                            <Button 
                                onClick={this.handleUpdateBulletin}
                                variant="contained"
                                color="primary"
                                className={classes.sectionMargin}
                            >
                                Update
                            </Button>
                        </Grid>
                        )}
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen.removeUploadedImage}
                        success={this.handleRemoveUploadedImageSuccess}
                        close={this.handleRemoveUploadedImageClose}
                        title="Delete Image?"
                        message="Are you sure you want to delete this image?"
                    />
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen.updateBulletin} 
                        success={this.handleUpdateBulletinSuccess} 
                        close={this.handleUpdateBulletinClose} 
                        title="Update Bulletin?" 
                        message="Are you sure you want to update this bulletin?"
                    />
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(UpdateProductionServiceBulletin);
