import React, { Component } from 'react';
import _                    from 'lodash';

import DataTable from 'Components/Common/DataTables/CiDataTable';

class StatusDataTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { searchResults, actions, status, isLoading, persistenceId } = this.props;
        return (
            <DataTable  
                config={{
                    key:                    'sr_id',
                    pagination:             true,
                    alternatingRowColours:  true,
                    persistenceId:          persistenceId,
                    isLoading:              isLoading
                }}
                columns={[
                    {
                        heading: 'Date',
                        field: 'sr_date',
                        sizeToContent: true,
                        fieldFormat: 'clenydate',
                    },
                    {
                        actionsCustomHeader:    true,
                        important:              true,
                        alignment:              'left',
                        heading:                'Reference',
                        actions: rowData => {
                            return [
                                { label: `${rowData.sr_reference}-${rowData.sr_reference_amendment}`, link: `/supplier-stock-return/view/${rowData.sr_id}`, type: 'plainLink' },
                            ]
                        },
                        truncate: true
                    },
                    {
                        heading: 'Company Name',
                        field: 'sr_supplier_name',
                        truncate: true
                    },
                    {
                        heading: status === 'Declined' ? 'Declined By' : 'Placed By',
                        field: 'sr_staff_name'
                    },
                    {
                        heading: 'Total Parts',
                        field: i => _.uniqBy(i.stock_return_details, 'srd_part_id').length,
                        alignment: 'right'
                    },
                    {
                        heading: 'Total Qty',
                        field: 'sr_total_parts',
                        alignment: 'right',
                        fieldFormat: 'decimal:3'
                    },
                    {
                        heading: 'stock deducted',
                        field: i => `${parseInt(_.uniqBy(_.filter(i.stock_return_details, j => parseInt(j.srd_deduct_stock)), j => parseInt(j.srd_part_id)).length)} / ${parseFloat(_.sumBy(_.filter(i.stock_return_details, j => parseInt(j.srd_deduct_stock)), j => parseFloat(j.srd_quantity))).toFixed(3)}`,
                        alignment: 'right',
                    },
                    {
                        heading: 'stock not deducted ',
                        field: i => `${parseInt(_.uniqBy(_.filter(i.stock_return_details, j => !parseInt(j.srd_deduct_stock)), j => parseInt(j.srd_part_id)).length)} / ${parseFloat(_.sumBy(_.filter(i.stock_return_details, j => !parseInt(j.srd_deduct_stock)), j => parseFloat(j.srd_quantity))).toFixed(3)}`,
                        alignment: 'right',
                    },
                    {
                        actions: actions
                    }
                ]}
                rows={searchResults}
            />
        );
    }
}

export default StatusDataTable;
