import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';

import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Order from 'Components/Sales/Order/ViewOrder/Order';
import StaffActions from 'Components/Sales/Order/ViewOrder/sections/StaffActions';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import EmailDialog from 'Components/Common/Dialogs/EmailDialog';

const DeclineOrderDialog = ({orderId, callback}) => {

    const dispatch = useDispatch();

    const initialState = {
        order: {},
        formData:{
            notesText: ''
        },
        emailDialog: {
            customerId: '',
            customerContactId: '',
            type: ''
        },
        isLoading: true,
        orderLocked: true
    };

    let [state, setState] = useState(initialState);

    const {order, formData, formData:{notesText}, emailDialog:{customerId, customerContactId, type}, isLoading, orderLocked} = state;

    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleClose = () => dispatch(closeDialog());

    const handleDecline = () => dispatch(deployConfirmation('Are you sure you want to decline this order', 'Decline Order?', handleDeclineSuccess));

    const handleDeclineSuccess = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));
        API.post(`/sales/orders/${orderId}/decline`, formData)
        .then(result => {
            if(result.data.errors) {
                setState(state => ({
                    ...state,
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false
                }));

            } else if(result.data.success) {
                setState(state => ({
                    ...state,
                    emailDialog: {
                        customerId: result.data.customerId,
                        customerContactId: result.data.contactId,
                        type: result.data.type
                    }
                }));
            }
        });

    }

    // handle email dialog
    useEffect(() => {
        if(customerId && customerContactId && type) {
            dispatch(deploySnackBar('success', 'You have successfully declined this order!'));
    
            const dialog = <EmailDialog 
                id={orderId}
                customerId={customerId}
                customerContactId={customerContactId}
                type={type}
            />
            
            dispatch(deployDialog(dialog, 'You Are About To Email - Order Declined Confirmation', 'warning'));
    
            if(callback) callback();
        }
    }, [orderId, customerId, customerContactId, type, dispatch, callback]);

    // handle load
    useEffect(() => {
        API.get('/sales/orders/' + orderId)
        .then(result => {
            setState(state => ({
                ...state,
                order: result?.data,
                isLoading: false
            }));
        })
    }, [orderId]);

    const lockOrder = orderLocked => {
        setState(state => ({
            ...state,
            orderLocked
        }));
    };

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} lg={12} style={{paddingTop: 0, paddingBottom: '1em'}}>
                            <StaffActions orderId={order.order_id} lockCallback={lockOrder} type={'Declining'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Order co={order} />
                        </Grid>
                        {orderLocked ? 
                            <>
                                <Grid item xs={12} md={12}>
                                    {orderLocked}
                                </Grid> 
                                <Grid item xs={12} className='buttonRow'>
                                    <Button onClick={handleClose} variant="outlined" color="default">Close</Button>
                                </Grid>
                            </>
                        :
                        [
                            <Grid item xs={12}>
                                <Typography variant="h6">Add Decline Notes?</Typography>
                            </Grid>,
                            <Grid item xs={12}>
                                <Textarea
                                    id="notesText"
                                    name="notesText"
                                    label="Notes"
                                    value={notesText}
                                    rows={5}
                                    onChange={handleChange}
                                />
                            </Grid>
                        ]
                        }
                    </Grid>
                    {!orderLocked &&
                        <Grid item xs={12} className='buttonRow'>
                            <Button onClick={handleClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={handleDecline} variant="contained" color="secondary">Decline</Button>
                        </Grid>
                    }
                </DialogContent>
            )}
        </React.Fragment>
    );
}

export default DeclineOrderDialog;