import React, {Component}  from 'react';
import _                   from 'lodash';
import PropTypes           from 'prop-types';
import Select              from 'react-select';
import createFilterOptions from "react-select-fast-filter-options";
import Creatable           from 'react-select/creatable';

import { Typography, TextField, FormControl } from '@material-ui/core';
import { withStyles }                         from '@material-ui/core/styles';

import { isTouchMonitor } from 'Functions/MiscFunctions';

import BasicSelect from './BasicSelect';

const components = {
    Control,
    NoOptionsMessage,
    SingleValue,
    ValueContainer,
};

const styles = theme => ({
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1, 2)}`,
    },
    singleValue: {
        maxWidth: '94%',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
    },
    placeholder: {
        position: 'absolute',
    }
});

function NoOptionsMessage(props) {
    return (
        <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div style={{height: 'auto', padding: props.variant === "filled" ? 4.5 : (props.variant === "outlined" ? 8 : 0)}} ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            error={props.selectProps.error}
            helperText={props.selectProps.errorText}
            label={props.selectProps.label}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    variant: props.selectProps.variant,
                    ...props.innerProps,
                }
            }}
            variant={props.selectProps.variant}
            style={{
                margin: props.selectProps.variant === "filled" || props.selectProps.variant === "outlined" ? 0 : undefined, 
            }}
            InputLabelProps={{
                style: {
                    color: props.selectProps.standardLabel && '#000'
                },
                shrink: !!props.hasValue || props.isFocused
            }}
            {...props.selectProps.textFieldProps}
            fullWidth
        />
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <Typography component='div' className={props.selectProps.classes.valueContainer}>{props.children}</Typography>;
}

class AutoCompleteSelect extends Component {

    constructor(props) {
        super(props);
        this.input = React.createRef();
        this.timeout = null
    }

    handleChange = (v) => {
        this.props.onChange(v);
        if(!this.props.selectRef){
            this.timeout = setTimeout(() => this.input?.current?.select.focus(), 50)
        }
    }

    componentWillUnmount(){
        if(this.timeout){
            clearTimeout(this.timeout)
        }
    }

    render() {
        const { classes, theme } = this.props;
        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary, 
                '& input': {
                    font: 'inherit',
                },
            }),
            menuList: base => ({
                ...base,
                padding: 0,
                width: this.props.menuSizeRef ? (this.props.menuSizeRef.current.clientWidth / 3) + 'px' : '100%',
            }),
            menu: base => ({
                ...base,
                borderRadius: 0,
                width:        "max-content",
                minWidth:     "100%",
            }),
            menuPortal: base => ({
                ...base,
                zIndex: 9999,
            }),
            dropdownIndicator: (base, props) => ({
                ...base,
                paddingRight: 0,
                marginRight: 0,
                paddingLeft: 0,
                transform: 'scale(0.75)',
                height: '30px',
                color: (props.selectProps.error ? '#f44336' : 'rgba(0, 0, 0, 1)'),
                overflow: 'hidden',
            }),
            indicatorSeparator: (base) => ({
                ...base,
                display: 'none',
            }),
            clearIndicator: (base) => ({
                ...base,
                paddingLeft: 5,
                paddingRight: 5,
                transform: 'scale(0.75)',
                height: '30px',
                cursor: 'pointer',
            }),
            option: (base, props) => ({
                ...base,
                backgroundColor: props.isSelected ? theme.palette.primary.main : (props.isFocused ? theme.palette.primary.opaque : '#ffffff'),  
                width: '100%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            })
        };

        const group = this.props.isGrouped ? _.findIndex(this.props.options, optGroup => { return _.find(optGroup.options, option => { return option.value === this.props.value }) }) : 0;

        const cleanValue = this.props.isGrouped ? (group !== -1  ? ((((typeof this.props.value === 'string' && this.props.value.length) || (typeof this.props.value === 'number') || (typeof this.props.value === 'boolean')) ? _.find(this.props.options[group].options, option => option.value === this.props.value) : this.props.value)) : this.props.value) : (((typeof this.props.value === 'string' && this.props.value.length) || (typeof this.props.value === 'number') || (typeof this.props.value === 'boolean')) ? this.props.options.find(option => option.value === this.props.value) : this.props.value);

        const filterOptions = createFilterOptions(
            this.props.options
        );

        const Element = this.props.creatable ? Creatable : Select;

        //stop keyboard from opening on touch monitors
        //if (isTouchMonitor() && !this.props.creatable) return ( <BasicSelect {...this.props}/> )
    
        return (
            <React.Fragment>
                <FormControl fullWidth={this.props.fullWidth}>
                    <Element
                        ref={this.props.selectRef ? this.props.selectRef : this.input}
                        isClearable={!this.props.noClear}
                        classes={classes}
                        styles={selectStyles}
                        filterOptions={filterOptions}
                        placeholder={this.props.showPlaceholder ? (this.props.placeholder ?? null) : null}
                        label={this.props.label}
                        components={components}
                        options={this.props.options && this.props.disableSort ? this.props.options : _.sortBy(this.props.options, function (opt) { if(opt.label && opt.label !== opt.value) { return opt.label.toLowerCase() } else { return '' } })}
                        onChange={this.handleChange}
                        defaultValue={this.props.defaultValue}
                        menuPortalTarget={document.body} 
                        value={cleanValue}
                        error={!!this.props.error}
                        errorText={this.props.errorText}
                        isOptionDisabled={(option) => option.disabled === true}
                        isDisabled={this.props.options.length === undefined || this.props.disabled}
                        IconComponent={false}
                        standardLabel={this.props.standardLabel}
                        blurInputOnSelect={true}
                        closeMenuOnScroll={this.props.closeMenuOnScroll || true}
                        menuPlacement="auto"
                        autoFocus={this.props.autoFocus ? this.props.autoFocus : false}
                        isSearchable={this.props.isSearchable ?? true}
                        formatOptionLabel={this.props.formatOptionLabel}
                        variant={this.props.variant}
                    />
                </FormControl>
            </React.Fragment>
        );
    }
}

AutoCompleteSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AutoCompleteSelect);