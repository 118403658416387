import React, { Component } from 'react';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AllIcon     from 'Components/Common/Icons/AllIcon';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core';

import { clenyDate } from 'Helpers/TimeHelper';
import { colors }    from 'Helpers/ColourHelper';
import icons         from 'Helpers/IconHelper';

const reviewOrder = {
    'Overview': 0,
    'Picks': 1,
    'Shortages': 2,
    'Write Offs': 3,
    'Additional Stock': 4,
    'Returned Stock': 5,
    'First Off Checks' : 6,
    'Build Time Sheet': 7,
    'Feedback': 8,
    'Notes': 9,
    'Activity Logs': 10,
    'Report': 11,
};

class Review extends Component {
    
    render(){

        const { worksOrder } = this.props;
        
        let allNotes = [];
        _.each(worksOrder.review.stages, s => {
            if (s.notes.length > 0) 
                allNotes = [...allNotes, ..._.map(s.notes, n => ({
                    date:  n.worsn_datetime,
                    note:  n.worsn_note,
                    staff: n.worsn_by,
                }))];
        });

        return (
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.review} size='xlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Stages</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{worksOrder.review.stages.length}</Typography>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={3}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.true} size='xlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Approved</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{_.filter(worksOrder.review.stages, 'wors_approved').length}</Typography>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={3}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.false} size='xlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Declined</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{_.filter(worksOrder.review.stages, i => !i.wors_approved && i.declined.length > 0).length}</Typography>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={3}>
                    <PaddedPaper style={{textAlign:'center'}}>
                        <AllIcon icon={icons.dash} size='xlarge' />
                        <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>Pending</Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>{_.filter(worksOrder.review.stages, i => !i.wors_approved && i.declined.length === 0).length}</Typography>
                    </PaddedPaper>
                </Grid>
                {allNotes.length > 0 && 
                    <Grid item xs={12}>
                        <ExpansionPanel styles={{width:'100%'}}>
                            <ExpansionPanelSummary expandIcon={<AllIcon noMargin icon={icons.expand} color={colors.white} />}>
                                <Typography>Notes ({allNotes.length})</Typography> 
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={1}>
                                    {_.map(allNotes, d =>
                                        <Grid item xs={12} style={{borderBottom: `1px solid ${colors.disabled}`, paddingTop: '1em', paddingBottom: '1em'}}>
                                            <Typography variant='body2' style={{color: colors.disabled}}>{d.staff} - {clenyDate(d.date)} - Notes Added</Typography>
                                            <Typography variant='body1' style={{paddingTop: '0.5em'}}>{d.note}</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                }
                {_.map(_.orderBy(worksOrder.review.stages, s => reviewOrder[s.wors_stage]), s => {
                        let notes = [];
                        notes = [...notes, ..._.map(_.filter(s.declined, d => d.worsd_reason), n => ({
                            date:  n.worsd_datetime,
                            note:  n.worsd_reason,
                            staff: n.worsd_by,
                            type:  'Declined'
                        }))];
                        if (s?.wors_note){
                            notes = [...notes, {
                                date:  s.wors_datetime,
                                staff: s.wors_approved_by,
                                note:  s.wors_note,
                                type:  'Approved'
                            }];
                        }
                        notes = _.orderBy(notes, ['date'], ['desc']);

                        return (
                            <Grid item xs={6}>
                                <PaddedPaper style={{height: '100%'}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5" gutterBottom style={{display: 'flex', alignItems: 'center'}}>
                                                <AllIcon 
                                                    size='large'
                                                    icon={s.wors_approved ? icons.true : (s.declined.length > 0 ? icons.false : icons.dash)} 
                                                    color={s.wors_approved ? colors.green : (s.declined.length > 0 ? colors.red : colors.disabled)}
                                                /> {s.wors_stage}
                                            </Typography>
                                        </Grid>
                                        {!!s.wors_approved ?
                                            <Grid item xs={12}>
                                                <Typography variant="body1" color="textSecondary">
                                                    Approved by {s.wors_approved_by} on {clenyDate(s.wors_datetime)}
                                                </Typography>
                                            </Grid> : 
                                            (s.declined.length > 0 ?
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" color="textSecondary">
                                                        Declined by {_.last(s.declined).worsd_by} on {clenyDate(_.last(s.declined).worsd_datetime)}
                                                    </Typography>
                                                </Grid> : 
                                                <Grid item xs={12}><Typography variant="body1" color="textSecondary"><br/></Typography></Grid>
                                            )
                                        }
                                        <Grid item xs={12} style={{marginTop: '1em'}}>
                                            <ExpansionPanel styles={{width:'100%'}}>
                                                <ExpansionPanelSummary expandIcon={<AllIcon noMargin icon={icons.expand} color={colors.white} />}>
                                                    <Typography>Notes ({notes.length})</Typography> 
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={1}>
                                                        {_.map(notes, d =>
                                                            <Grid item xs={12} style={{borderBottom: `1px solid ${colors.disabled}`, paddingTop: '1em', paddingBottom: '1em'}}>
                                                                <Typography variant='body2' style={{color: colors.disabled}}>{d.staff} -  {clenyDate(d.date)} - {d.type}</Typography>
                                                                <Typography variant='body1' style={{paddingTop: '0.5em'}}>{d.note}</Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                    </Grid>
                                    
                                </PaddedPaper>
                            </Grid>
                        )
                    }
                )}
            </Grid>
        )
    }
}


const mapDispatchToProps = dispatch => ({
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                              => dispatch(closeDialog())
})

export default connect(null, mapDispatchToProps)(Review)