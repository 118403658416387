import React from 'react';

import { Button, Grid, Typography } from '@material-ui/core';

import AllIcon from 'Components/Common/Icons/AllIcon';

import CustomerNote from './CustomerNote';

import icons from 'Helpers/IconHelper';

const initialState = { current: 0 };

class CustomerNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleNext = () => {
        let { current } = this.state;
        current = current + 1;

        if (current > (this.props.notes.length - 1 )) current = 0;

        this.setState({ current });
    }

    handlePrev = () => {
        let { current } = this.state;
        current = current - 1;

        if (current < 0) current = (this.props.notes.length - 1);

        this.setState({ current });
    }

    render() {
        const { current } = this.state;
        const { notes } = this.props;

        return (
            <Grid container style={{alignItems: 'center'}}>
                <Grid item><Typography variant="h6" gutterBottom className="float-left">Notes</Typography></Grid>
                {notes.length > 1 && <Grid item style={{marginLeft: 'auto'}}><Button onClick={this.handlePrev}><AllIcon size='small' noMargin icon={icons.previous} /></Button></Grid>}
                <Grid item style={{marginLeft: parseInt(notes.length) === 1 && 'auto'}}>{current + 1} / {notes.length}</Grid>
                {notes.length > 1 && <Grid item><Button onClick={this.handleNext}><AllIcon size='small' noMargin icon={icons.next} /></Button></Grid>}
                <Grid item xs={12}>
                    <CustomerNote note={notes[current]} deleteCallback={this.props.deleteCallback} editCallback={this.props.editCallback} deployDialog={this.props.deployDialog} />
                </Grid>
            </Grid>
        )
    }
}

export default CustomerNotes