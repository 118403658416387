import React, {Component}  from 'react';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import API from 'API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import { Button, FormControl, Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import {colors} from 'Helpers/ColourHelper';

const initialState = { 
    formData: {
        part: '',
        categories: [],
    },
    partList: [],
    categoryList: [],
    formErrors: [],
    confirmationOpen: false,
    isLoading: false
}

class AddEcommerceProduct extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.loadInitialData();
    }

    loadInitialData = () => {
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([ 
                API.get('/parts/all' , {params: {active: true, excludeEcommerce: true}}),
                API.get(`/e-commerce/productCategories`)
            ])
            .then(([partRes, categoryRes]) =>  {
                let partList = _.map(partRes.data, el => {
                    return _.assign({
                        value: el.part_id,
                        label: el.part_number + ' - ' + el.part_description
                    });
                });
                let categoryList = _.map(categoryRes.data, el => {
                    return _.assign({
                        value: el.ecom_cat_id,
                        label: el.ecom_cat_name
                    });
                });
                this.setState({
                    partList,
                    categoryList,
                    isLoading: false,
                });
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.post('/e-commerce/products', this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState
                    });
                    this.props.deploySnackBar("success", "You have successfully added a new e-commerce product")
                    this.loadInitialData();
                }
                this.props.scrollToTop();
            });
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }
       
    render() {
        const { formErrors, formData, partList, categoryList } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add E-Commerce Product
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={partList} 
                                        label='Part *'
                                        onChange={this.handleSelectChange('part')}
                                        error={formErrors && formErrors['part'] && true}
                                        errorText={formErrors && formErrors['part']}
                                        value={formData.part}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteMultiSelect 
                                        options={categoryList} 
                                        label='Category *'
                                        value={formData.categories}
                                        onChange={this.handleMultiSelectChange('categories')}
                                        error={formErrors && formErrors['categories'] && true}
                                        errorText={formErrors && formErrors['categories']}
                                    />
                                </FormControl>
                            </form>
                            <div className='buttonRow'>
                                <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                        disabled={this.state.isLoading}
                                       >
                                    Add
                                </Button>
                            </div>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add A New E-Commerce Product?"
                    message="Are you sure you want to add this new e-commerce product?"
                />
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, msg) => {
            dispatch(deploySnackBar(variant, msg))
        }
    }
}

export default connect(null, mapDispatchToProps)(AddEcommerceProduct);