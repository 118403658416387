import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FALightIcon from '../Icons/FontAwesome/FALightIcon';

class ErrorUnauthorized extends React.PureComponent {
    render(){
        return (
            <Grid container justify="center" alignItems="center" style={{height: 'calc(100vh - 210px)'}}>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={1} style={{padding: '100px 24px 100px 24px'}}>
                        <Grid item xs={12} align='center'>
                            <FALightIcon 
                                icon="exclamation-triangle"
                                size="xlarge"
                                noMargin
                                heading
                            />
                        </Grid>
                        <Grid item xs={12} align='center'>
                            <Typography variant="h6" gutterBottom>
                                Unauthorized Access
                            </Typography>
                            <Typography variant="caption" component="div" gutterBottom>
                                Please ask your Line Manager for permission if you need to access this part of MyClenaware
                            </Typography>  
                            <Typography variant="caption" component="div" gutterBottom>
                                For help and support, please contact Crushed Ice on 01933 664344
                            </Typography>  
                        </Grid>
                    </Grid>       
                </Grid>
            </Grid>
        )
    }
}

export default ErrorUnauthorized;