import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import UpdateStaffDetailsForm from './UpdateStaffDetailsForm';
import ChangeStaffPasswordForm from './ChangeStaffPasswordForm';
import UploadStaffSignatureForm from './UploadStaffSignatureForm';
import UploadStaffProfilePhotoForm from './UploadStaffProfilePhotoForm';
import UpdateStaffMfa from './UpdateStaffMfa';
import UpdateStaffAutoUpdates from './UpdateStaffAutoUpdates';
import UpdateStaffNotifications from './UpdateStaffNotifications';
import API from '../../../API';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import { Redirect } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import UpdateStaffLists from './UpdateStaffLists';

const initialState = {
    isLoading: true,
    accessUploadSignature: false,
    accessChangePassword: false,
    accessUploadProfilePhoto: false,
    accessMfa: false,
    redirect: false,
    staffData: [],
    staffFormKey: uuidv4()
}

class UpdateStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getStaff();
    }

    getStaff = () => {
        API.get('/staff/' + this.props.match.params.staffId).then(result => {
            if(result.data.errors) {
                this.setState({ redirect: true })
            } else {
                this.setState({
                    staffData: result.data,
                    isLoading: false,
                    staffFormKey: uuidv4()
                }, () => this.getAccess());
            }
        });
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-staff:upload-signature'), 
            API.get('/staff/my/access/check/update-staff:change-password'),
            API.get('/staff/my/access/check/update-staff:upload-profile-photo'),
            API.get('/staff/my/access/check/update-staff:multi-factor-auth')
        ])
        .then(([sigRes, passwordRes, picRes, mfaRes]) =>  {
            sigRes.data &&
                this.setState({
                    ...this.state,
                    accessUploadSignature: sigRes.data.has_access
                });
            passwordRes.data &&
                this.setState({
                    ...this.state,
                    accessChangePassword: passwordRes.data.has_access
                });
            picRes.data &&
                this.setState({
                    ...this.state,
                    accessUploadProfilePhoto: picRes.data.has_access
                });
            mfaRes.data &&
            this.setState({
                ...this.state,
                accessMfa: mfaRes.data.has_access
            });
        })
    }

    render() {
        const { scrollToTop, location } = this.props;
        if (this.state.redirect) {
            return <Redirect to="/staff/search" />
        } else if(this.state.isLoading) {
            return (
                <React.Fragment>
                    <LoadingCircle />
                </React.Fragment>
            )
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Update Staff
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} lg={6} spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <UpdateStaffDetailsForm key={this.state.staffFormKey} staffId={this.props.match.params.staffId} staffData={this.state.staffData} scrollToTop={scrollToTop} location={location} refresh={this.getStaff} />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" style={{marginBottom: 5}}>
                                            Staff Automated Email Updates
                                        </Typography>
                                        <UpdateStaffAutoUpdates staffId={this.props.match.params.staffId} staffData={this.state.staffData} scrollToTop={scrollToTop} />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" style={{marginBottom: 5}}>
                                            Staff Notifications
                                        </Typography>
                                        <UpdateStaffNotifications staffId={this.props.match.params.staffId} staffData={this.state.staffData} scrollToTop={scrollToTop} />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Typography variant="h6" style={{marginBottom: 5}}>
                                            Staff Lists
                                        </Typography>
                                        <UpdateStaffLists staffId={this.props.match.params.staffId} staffData={this.state.staffData} scrollToTop={scrollToTop} />
                                    </PaddedPaper>
                                </Grid>
                                {this.state.accessUploadProfilePhoto && (
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Typography variant="h6">
                                                Profile Photo
                                            </Typography>
                                            <UploadStaffProfilePhotoForm staffId={this.props.match.params.staffId} scrollToTop={scrollToTop} />
                                        </PaddedPaper>
                                    </Grid>
                                )}
                                {this.state.accessUploadSignature && (
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Typography variant="h6">
                                                Upload Signature
                                            </Typography>
                                            <UploadStaffSignatureForm staffId={this.props.match.params.staffId} scrollToTop={scrollToTop} />
                                        </PaddedPaper>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={6} alignItems='flex-start' spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {this.state.accessChangePassword && (
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Typography variant="h6">
                                                Change Password
                                            </Typography>
                                            <ChangeStaffPasswordForm staffId={this.props.match.params.staffId} myPassword={false} scrollToTop={scrollToTop} />
                                        </PaddedPaper>
                                    </Grid>
                                )}
                                {this.state.accessMfa && (
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Typography variant="h6">
                                                Multi-factor Authentication
                                            </Typography>                                        
                                            <UpdateStaffMfa staffId={this.props.match.params.staffId} scrollToTop={scrollToTop} />
                                        </PaddedPaper>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }
}

export default UpdateStaff;