import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import API from '../../../API';
import _ from 'lodash';
import { connect } from 'react-redux';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

const initialState = {
    initialFormData: {},
    allNotificationTypes: {},
    staffNotificationTypes: {},
    confirmationOpen: false,
    snackbarOpen: false,
    bouncedEmail: false,
}

function objectsHaveSameKeys(...objects) {
    const allKeys = objects.reduce((keys, object) => keys.concat(Object.keys(object)), []);
    const union = new Set(allKeys);
    return objects.every(object => union.size === Object.keys(object).length);
}

class UpdateStaffNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() {
        this.getComponentData();
    }
    getComponentData = () => {
        Promise.all([ 
            API.get(`/misc/notifications/types`),
            API.get(`/staff/${this.props.staffId}/notifications`),
        ])
        .then(([typesRes, notifRes]) =>  {
            this.setState({
                allNotificationTypes: typesRes.data,
            });
            _.map(notifRes.data, staffNotif => {
                this.setState({
                    staffNotificationTypes: {
                        ...this.state.staffNotificationTypes,
                        [staffNotif.notification_type_id]: true
                    },
                    initialFormData: {
                        ...this.state.initialFormData,
                        [staffNotif.notification_type_id]: true
                    }
                })
            })
        });
    }

    handleSnackbarClose = () => this.setState({ snackbarOpen: false });
    handleConfirmationClose = () => this.setState({ confirmationOpen: false });
    handleConfirmationOpen = () => this.setState({ confirmationOpen: true });
    handleConfirmationSuccess = () => this.setState({  confirmationOpen: false }, () => this.handleSubmit());
    handleSubmit = () => {
        let notificationTypes = Object.keys(this.state.staffNotificationTypes);
        API.put(`/staff/${this.props.staffId}/notifications`, notificationTypes)
        .then(result => {
            if(result.data){
                this.setState({
                    ...initialState
                }, ()=> {
                    this.props.deploySnackBar("success", "The staff member's notifications were successfully updated");
                    this.getComponentData();
                    this.props.scrollToTop();
                });
            }
        });
    }

    toggleNotification = (event) => {
        let notificationTypeId = event.target.name;
        if(this.state.staffNotificationTypes.hasOwnProperty(notificationTypeId)) {
            this.setState({
                staffNotificationTypes: _.omit(this.state.staffNotificationTypes, notificationTypeId)
            })
        } else {
            this.setState({
                staffNotificationTypes: {
                    ...this.state.staffNotificationTypes,
                    [notificationTypeId]: true
                }
            });
        }
    };

    render() {
        const { allNotificationTypes, staffNotificationTypes, initialFormData } = this.state;
        
        return(
            <React.Fragment>
                <FormControl component="fieldset">
                    <FormGroup>
                        {_.map(allNotificationTypes, notificationType => {
                            return (
                                <FormControlLabel
                                    key={notificationType.notification_type_id}
                                    control={
                                        <Checkbox 
                                            name={notificationType.notification_type_id.toString()}
                                            onClick={this.toggleNotification}
                                            checked={staffNotificationTypes.hasOwnProperty(notificationType.notification_type_id)}
                                            color="primary"
                                        />
                                    }
                                    label={notificationType.notification_type_name}
                                    labelPlacement="end"
                                />
                            )
                        })}
                    </FormGroup>
                </FormControl>
                <div className="buttonRow">
                    <Button 
                        onClick={this.handleConfirmationOpen}
                        variant="contained"
                        color="primary"
                        disabled={objectsHaveSameKeys(initialFormData, staffNotificationTypes)}
                        style={{float: 'right'}}
                    >
                        Update
                    </Button>
                </div>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Update Staff Member Notifications?" 
                    message="Are you sure you want to update this staff member's notifications?"
                />
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, msg) => {
            dispatch(deploySnackBar(variant, msg))
        }
    }
}

export default connect(null, mapDispatchToProps)(UpdateStaffNotifications);