import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from '../../../API';
import AdminChat from 'Components/Layout/AdminLayout/AdminChat';
import AdminLoadingBar from './AdminLoadingBar';
import AdminNotifications from './AdminNotifications';
import AdminSmartSearch from './AdminSmartSearch';
import AdminUser from './AdminUser';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from '../../Common/Icons/FontAwesome/FASolidIcon';
import QrReaderMenu from '../../Common/QrReader/QrReaderMenu';
import AddReminderForm from 'Components/Reminders/AddReminder/AddReminderForm';
import AddTaskForm from 'Components/Tasks/AddTask/AddTaskForm';
import WildixVoip from 'Components/Common/Voip/WildixVoip';
import { handleAppError } from '../../../Actions/UI/UI';
import { deploySnackBar } from '../../../Actions/SnackBar/SnackBar';
import { closeDialog, deployDialog } from '../../../Actions/Dialog/Dialog';
import { clearCookies, clearToken, setLastActivePage, forceStoredProfile, clearLastActivePage } from '../../../Functions/AuthFunctions';
import { clearPersistence } from 'Actions/StatePersistence/StatePersistence';
import { closePopNotifications} from 'Actions/PopNotification/PopNotification'
import { colors } from 'Helpers/ColourHelper';
import Calculator from 'Components/Common/Calculator/Calculator';


const drawerWidth = 285;

const styles = theme => ({
    toolbar: {
        paddingTop: 'calc(env(safe-area-inset-top) + 5px)',
        paddingRight: theme.spacing(3), // keep right padding when drawer closed
        backgroundColor: '#0282C6',
    },
    appBar: {
        position: 'fixed',
        top: 0,
        webkitOverflowScrolling: 'touch',
        webkitTransform: 'translateZ(0)',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: '0 4px 2px -2px rgba(0,0,0,0.2)'
    },  
    notificationBar: {
        paddingTop: 'calc(env(safe-area-inset-top) + 5px)',
        paddingRight: theme.spacing(3), // keep right padding when drawer closed
    },
    notificationAppBar: {
        position: 'fixed',
        top: 68,
        webkitOverflowScrolling: 'touch',
        webkitTransform: 'translateZ(0)',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: '0 4px 2px -2px rgba(0,0,0,0.2)'
    },
    appBarShift: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 35,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
    h5: {
        marginBottom: theme.spacing(2),
    },
    quicklinks: {
        // marginRight: 10
    },
    avatar: {
        width: 40,
        height: 40,
    },
    avatarSpacer: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    iconButton: {
        marginLeft: 10
    },
    dropDown: {
        marginTop: 52
    },
    dropDownItem: {
        fontSize: 13,
        padding: theme.spacing(1.5, 2)
    },
    search: {
        paddingRight: 24,
        borderRight: '1px solid rgba(255,255,255,0.5)'
    },
    userContainer: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    [theme.breakpoints.down('md')]: {
        search: {
            display: 'none'
        }
    },
    [theme.breakpoints.down('sm')]: {
        appBarShift: {
            display: 'none',
        },
        menuButton: {
            marginLeft: 5,
            marginRight: 20,
        },
        dropDown: {
            marginTop: window.matchMedia('(display-mode: standalone)').matches ? (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) ? `calc(env(safe-area-inset-top) + 15px)` : 45) : 45,
        },
        toolbar: {
            paddingRight: theme.spacing(2),
        },    
        iconButton: {
            marginLeft: 0
        },
        notificationAppBar: {
            top: 'calc(env(safe-area-inset-top) + 60px)',
        }
    },
    [theme.breakpoints.up('sm')]: {
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    [theme.breakpoints.down('xs')]: {
        menuButton: {
            marginLeft: 3,
            marginRight: 3,
        },
    },    
    ['@media (orientation: landscape) and (max-height: 767px)']: { // eslint-disable-line no-useless-computed-key
        dropDown: {
            marginTop: 53,
        },
    }
});

const initialState = {
    anchorEl: null,
    completed: 0,
    buffer: 10,
    accessIconCustomer: false,
    accessIconPart: false,
    accessIconSupplier: false,
    accessIconDocument: false,
    accessIconSales: false,
    accessIconReminders: false,
    accessIconTasks: false,
    scheduledUpdateColor: '#FFF',
    scheduledUpdateBackgroundColor: '#c62828',
    scheduledUpdateTime: false,
    scheduledUpdateTimeCountdown: false,
    accessGlobalSearch: false,
    calculatorOpen: false,
    stickyOpen: false
}

class AdminHeader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.countdown = false;
    }

    componentDidMount = () => {      
        this.setState({
            scheduledUpdateTime: this.props.ui.updateNotification
        }, () => {
            if(this.state.scheduledUpdateTime !== false) {
                this.startScheduledUpdateTime();
            }
            API.multiAccess([
                'customer-search',
                'part-search',
                'supplier-search',
                'document-search',
                'quotation-search',
                'reminders',
                'tasks',
                'global-search'
            ]).then(([customerRes, partRes, supplierRes, docRes, quoteRes, remindersRes, tasksRes, gsRes]) => {
                this.setState({
                    accessIconCustomer: customerRes,
                    accessIconPart: partRes,
                    accessIconSupplier: supplierRes,
                    accessIconDocument: docRes,
                    accessIconSales: quoteRes,
                    accessIconReminders: remindersRes,
                    accessIconTasks: tasksRes,
                    accessGlobalSearch: gsRes
                });
            });
        });

        document.addEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (e) => {
        if (e.key === 'Clear'){
            this.handleCalculator();
        }
    }

    componentWillUnmount = () => {
        if(this.countdown)       
            clearInterval(this.countdown);
    }
    
    componentDidUpdate = (prevProps) => {
        if(this.props.ui.updateNotification !== prevProps.ui.updateNotification) {
            this.setState({
                scheduledUpdateTime: this.props.ui.updateNotification
            }, () => {                
                if(this.state.scheduledUpdateTime !== false) {
                    this.startScheduledUpdateTime();
                }
            });
        }        
    }

    startScheduledUpdateTime = () => {
        this.countdown = setInterval(this.getScheduledUpdateTimeCountdown, 1000);
    }

    getScheduledUpdateTimeCountdown = () => {
        var scheduled = moment(this.state.scheduledUpdateTime, 'X').subtract(10, 'seconds');
        const now = moment();
        if(now.unix() < scheduled.unix()) {
            const countdown = moment(scheduled - now);
            const mins = parseInt(countdown.format('m'));
            const countdownText = mins !== 0 ? countdown.format("m [ minutes] [and] s [ seconds]") : countdown.format("s[ seconds]");
            this.setState({                
                //scheduledUpdateBackgroundColor: mins > 1 ? '#E65100' : '#C62828',
                scheduledUpdateTimeCountdown: this.state.updateNotification !== false ? countdownText : ''
            });
        } else {      
            if(this.countdown) {      
                clearInterval(this.countdown);
            }
            setLastActivePage(this.props.location.pathname);
            this.setState({                
                scheduledUpdateTimeCountdown: ''
            }, () => this.props.handleAppError("APP_UPDATE", ""))    
        }
    }
    
    handleUser = () => {
      this.props.history.push('/my-account');
    }
    
    handleLockSession = () => {
        forceStoredProfile();        
        setLastActivePage(this.props.location.pathname);
        clearToken();
        this.props.history.push('/login');
    }
    
    handleLogout = () => {
        clearCookies();
        clearToken();
        clearLastActivePage();
        this.props.history.push('/login');
    }

    handleQuickLinkNav = (title, path) => {
        this.props.clearPersistence()
        this.props.changePage(title, path);
        this.props.history.push(path);
    }

    handleClick = event => {
        this.props.closePopNotifications();
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    progress = () => {
        const { completed } = this.state;
        if (completed > 100) {
            this.setState({ 
                completed: 0, 
                buffer: 10
            });
        } else {
            const diff = Math.random() * 10;
            const diff2 = Math.random() * 10;
            this.setState({ 
                completed: completed + diff, 
                buffer: completed + diff + diff2 
            });
        }
    };

    handleUndockMessaging = () => {
        window.open(`/messaging`,`Messaging`,`directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,width=958,height=750`);
        this.handleClose();
    }

    handleKaching = () => {
        API.post('/staff/my/kaching')
        .then(res => { 
            if(res?.data) { 
                this.props.deploySnackBar("success", "Kaching!")
            }
        })
    }

    handleCalculator = () => {
        this.setState({ calculatorOpen: !this.state.calculatorOpen });
    }

    handleSticky = () => {
        this.setState({ stickyOpen: !this.state.stickyOpen });
    }

    render() {
        const { classes, toggleDrawer, ui } = this.props;
        const { calculatorOpen, accessGlobalSearch, anchorEl, accessIconCustomer, accessIconPart, accessIconSupplier, accessIconDocument, accessIconSales, accessIconReminders, accessIconTasks, scheduledUpdateTime, scheduledUpdateColor, scheduledUpdateBackgroundColor, scheduledUpdateTimeCountdown } = this.state;
        const loggedInStaff = this.props.loggedInStaff !== undefined ? this.props.loggedInStaff.firstName + ' ' + this.props.loggedInStaff.lastName : '';
        const isMobile = ui.device.isMobile;
        const mobileUserProfile = !isMobile ? '' : (
            <React.Fragment>
                <MenuItem onClick={this.handleUser} className={classes.dropDownItem}> 
                    <FALightIcon icon='user' button /> {loggedInStaff}
                </MenuItem>
                <Divider />
            </React.Fragment>
        );
        return (
            <React.Fragment>
                <CssBaseline />
                <AppBar
                    position="absolute"
                    className={classNames(classes.appBar, this.props.navOpen && classes.appBarShift)}
                >
                    <Toolbar disableGutters={!this.props.navOpen} className={classes.toolbar}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.props.openNav}
                            className={classNames(
                                classes.menuButton,
                                this.props.navOpen && classes.menuButtonHidden,
                            )}
                        >
                            <FALightIcon icon='bars' buttonPrimary noMargin />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={classes.title}
                        > 
                            {this.props.pageTitle}
                        </Typography>
                        <Grid item align="right" style={{minWidth:'initial'}}  className={`${classes.quicklinks} ${classes.search}`}>
                            {accessGlobalSearch &&
                                <AdminSmartSearch 
                                    accessIconCustomer={accessIconCustomer} 
                                    accessIconPart={accessIconPart} 
                                    accessIconSupplier={accessIconSupplier} 
                                    accessIconDocument={accessIconDocument} 
                                    accessIconSales={accessIconSales} 
                                    accessIconReminders={accessIconReminders} 
                                    accessIconTasks={accessIconTasks}
                                />
                            }
                        </Grid>
                        {!ui?.voip?.hasCalls && (
                            <>
                                <Grid item align="right" style={{minWidth:'initial'}} className={`${classes.quicklinks} not-xs not-sm ml-1`} >
                                    <Tooltip title='Dashboard'>
                                        <IconButton onClick={()=> this.handleQuickLinkNav('Dashboard', '/dashboard')}>
                                            <FASolidIcon icon='home' buttonPrimary noMargin />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                {accessIconCustomer &&
                                    <Grid item align="right" style={{minWidth:'initial'}} className={`${classes.quicklinks} not-xs not-sm`} >
                                        <Tooltip title='Customers'>
                                            <IconButton onClick={()=> this.handleQuickLinkNav('Customers', '/customers/search')}>
                                                <FASolidIcon icon='users' buttonPrimary noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }       
                                {accessIconSales &&
                                    <Grid item align="right" style={{minWidth:'initial'}} className={`${classes.quicklinks} not-xs not-sm`} >
                                        <Tooltip title='Sales'>
                                            <IconButton onClick={()=> this.handleQuickLinkNav('Sales', '/sales/quotation/search')}>
                                                <FASolidIcon icon='handshake' buttonPrimary noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                                {accessIconDocument &&
                                    <Grid item align="right" style={{minWidth:'initial'}} className={`${classes.quicklinks} not-xs not-sm`} >
                                        <Tooltip title='Documents'>
                                            <IconButton onClick={()=> this.handleQuickLinkNav('Documents', '/documents/search')}>
                                                <FASolidIcon icon='file-alt' buttonPrimary noMargin style={{height:'16px'}} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                                {accessIconPart &&
                                    <Grid item align="right" style={{minWidth:'initial'}} className={`${classes.quicklinks} not-xs not-sm`} >
                                        <Tooltip title='Parts'>
                                            <IconButton onClick={()=> this.handleQuickLinkNav('Parts', '/parts/search')}>
                                                <FASolidIcon icon='wrench' buttonPrimary noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                                {accessIconSupplier &&
                                    <Grid item align="right" style={{minWidth:'initial'}} className={`${classes.quicklinks} not-xs not-sm`} >
                                        <Tooltip title='Suppliers'>
                                            <IconButton onClick={()=> this.handleQuickLinkNav('Suppliers', '/suppliers/search')}>
                                                <FASolidIcon icon='truck' buttonPrimary noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                                {accessIconReminders &&
                                    <Grid item align="right" style={{minWidth:'initial'}} className={`${classes.quicklinks} not-xs not-sm`} >
                                        <Tooltip title='Reminders'>
                                            <IconButton onClick={()=> this.handleQuickLinkNav('Reminders', '/reminders')}>
                                                <FASolidIcon icon='alarm-clock' buttonPrimary noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                                {accessIconTasks &&
                                    <Grid item align="right" style={{minWidth:'initial'}} className={`${classes.quicklinks} not-xs not-sm`} >
                                        <Tooltip title='Tasks'>
                                            <IconButton onClick={()=> this.handleQuickLinkNav('Tasks', '/tasks')}>
                                                <FASolidIcon icon='tasks' buttonPrimary noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                                <Grid item align="right" style={{minWidth:'initial'}} className={classes.quicklinks}>
                                    <QrReaderMenu />
                                </Grid>
                                <Grid item align="right" style={{minWidth:'initial'}} className={classes.quicklinks}>
                                    <AdminNotifications />
                                </Grid>
                                <Grid item align="right" style={{minWidth:'initial'}} className={`${classes.quicklinks} not-xs not-sm`} >
                                    <AdminChat toggleDrawer={toggleDrawer} style={{minWidth:'initial'}} />
                                </Grid>
                                <Grid item align="right" style={{minWidth:'initial'}} className={`${classes.quicklinks} not-xs not-sm`} >
                                    <Tooltip title='Calculator ( Shift + numPad5 )'>
                                        <IconButton onClick={this.handleCalculator}
                                            style={
                                                calculatorOpen ?
                                                    {color: colors.primary, backgroundColor: colors.white} : {}
                                            }
                                        >
                                            <FASolidIcon 
                                                icon='calculator' 
                                                buttonPrimary 
                                                noMargin 
                                                style={
                                                    calculatorOpen ?
                                                        {color: colors.primary, backgroundColor: colors.white} : {}
                                                }
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                
                                {/* not read for viewer consumpson yet */}
                                {/*<Grid item align="right" style={{minWidth:'initial'}} className={`${classes.quicklinks} not-xs not-sm`} >

                                    <Tooltip title='Notes ( Shift + numPad2 )'>
                                        <IconButton onClick={this.handleSticky}
                                            style={
                                                stickyOpen ?
                                                    {color: colors.primary, backgroundColor: colors.white} : {}
                                            }
                                        >
                                            <FASolidIcon 
                                                icon='note-sticky' 
                                                buttonPrimary 
                                                noMargin 
                                                style={
                                                    stickyOpen ?
                                                        {color: colors.primary, backgroundColor: colors.white} : {}
                                                }
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                            */}

                                {/*
                                    component is no longer used

                                    <Grid item align="right" style={{minWidth:'initial'}} className={`${classes.quicklinks} not-xs not-sm`} >
                                        <Tooltip title='Kaching!'>
                                            <IconButton onClick={this.handleKaching}>
                                                <FASolidIcon icon='dollar-sign' buttonPrimary noMargin />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                */}
                            </>
                        )}
                        <Grid item align="right" className="ml-1 not-xs" xs>
                            <WildixVoip />
                        </Grid>
                        <Grid item align="right" className={classes.userContainer}>
                            <AdminUser />
                        </Grid>
                        <Typography
                            color="inherit"
                            variant="subtitle1"
                            noWrap
                            className={`not-xs`}
                        > 
                            {loggedInStaff}
                        </Typography>
                        <IconButton
                            aria-label="More"
                            aria-owns={anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleClick}
                            className={classes.iconButton}
                        >
                            <FALightIcon icon='ellipsis-v' buttonPrimary noMargin />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                            className={classes.dropDown}
                        >
                            {mobileUserProfile}
                            <MenuItem onClick={() => { this.handleClose(); this.props.deployDialog(<AddReminderForm callback={this.props.closeDialog} dialog />, "Add Reminder", "standard", "sm")}} className={`${classes.dropDownItem}`}>
                                <FALightIcon icon='alarm-clock' button /> Add Reminder
                            </MenuItem>
                            <MenuItem onClick={() => { this.handleClose(); this.props.deployDialog(<AddTaskForm callback={this.props.closeDialog} dialog />, "Add Task", "standard", "sm")}} className={`${classes.dropDownItem}`}>
                                <FALightIcon icon='tasks' button /> Add Task
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={this.handleUndockMessaging} className={`${classes.dropDownItem} not-xs`}>
                                <FALightIcon icon='comments-alt' button /> Messaging
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={this.handleUser} className={`${classes.dropDownItem} not-xs`}>
                                <FALightIcon icon='user' button /> My Account
                            </MenuItem>
                            <MenuItem onClick={this.handleLockSession} className={classes.dropDownItem}>
                                <FALightIcon icon='lock-alt' button /> Lock Session
                            </MenuItem>
                            <MenuItem onClick={this.handleLogout} className={classes.dropDownItem}>
                                <FALightIcon icon='sign-out-alt' button /> Logout
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                    <AdminLoadingBar />
                </AppBar>
                {scheduledUpdateTime !== false && scheduledUpdateTimeCountdown !== false && (
                    <AppBar
                        position="absolute"
                        className={classNames(classes.notificationAppBar, this.props.navOpen && classes.appBarShift)}
                        style={{zIndex: 9999}}
                    >
                        <Toolbar style={{height: 35, minHeight: 35, backgroundColor: scheduledUpdateBackgroundColor}} className={classes.notificationBar}>
                            <Grid container style={{paddingBottom: 3}}>
                                <Grid item style={{width: 30}}>
                                    <FALightIcon icon='exclamation-triangle' size={isMobile ? 'small' : ''} style={{color: scheduledUpdateColor}} /> 
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption" style={{color: scheduledUpdateColor}}>
                                        {(scheduledUpdateTimeCountdown !== '' && ( 
                                            <React.Fragment>
                                                {`An update ${!isMobile ? `for MyClenaware ` : ``} will begin in ${scheduledUpdateTimeCountdown} ${(!isMobile && (` - please save your work before the update starts!`)) || (``)}`}
                                            </React.Fragment>
                                        )) || (
                                            <React.Fragment>
                                                An update for MyClenaware is starting now...
                                            </React.Fragment>
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                            
                        </Toolbar>
                    </AppBar>
                )}
                <Calculator 
                    calculatorOpen={calculatorOpen}
                    popOut
                /> 
                {/* <StickyNotes
                    stickyOpen={stickyOpen}
                /> */}
            </React.Fragment>
        );
    }
}

AdminHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        ui: state.ui
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deploySnackBar:         (variant, message)              => dispatch(deploySnackBar(variant, message)),
        closeDialog:            ()                              => dispatch(closeDialog()),
        deployDialog:           (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
        handleAppError:         (error, message)                => dispatch(handleAppError(error, message)),
        clearPersistence:       ()                              => dispatch(clearPersistence()),
        closePopNotifications:  ()                              => dispatch(closePopNotifications())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminHeader));