import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid, CardActionArea, Typography, DialogContent, Dialog, Button } from '@material-ui/core';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import AllIcon    from 'Components/Common/Icons/AllIcon';
import icons from 'Helpers/IconHelper';
import { getMediaType } from 'Helpers/FileHelper';
import VideoWithError     from 'Components/Common/VideoWithError/VideoWithError';
import PDFViewer          from 'Components/Common/PDFViewer/PDFViewer';
import { map } from 'lodash';

const SimpleMediaCard = ({ actions, media, readOnly=false}) => {

    const [previewDialog, setPreviewDialog] = useState(null);

    const getMediaElement = () => {  
        switch (getMediaType(media?.fileUrl)){
            case 'image':
                return <ImageWithError src={media?.fileUrl} style={{height:'auto', maxHeight: 120, maxWidth: 120}} alt='Image Not Found' />
            case 'video':
                return  <AllIcon icon={icons.videos} size='xxxlarge' style={{height:'auto', maxHeight: 120, maxWidth: 120}} />
            case 'pdf':
                return  <AllIcon icon={icons.pdf} size='xxlarge' style={{height:'auto', maxHeight: 120, maxWidth: 120}} />
            case 'document':
                return  <AllIcon icon={icons.file} size='xxlarge' style={{height:'auto', maxHeight: 120, maxWidth: 120}} />
            default: 
                return  <AllIcon icon='file-circle-exclamation' size='xxlarge' style={{height:'auto', maxHeight: 120, maxWidth: 120}} color={'red'} heavy />
        }
    }

    const getBigMediaPreview = () => {    

        let newStyle = {height:'100%', maxHeight: 600, minHeight: 400, maxWidth: '100%'};

        switch (getMediaType(media?.fileUrl)){
            case 'image':
                return <ImageWithError src={media?.fileUrl} alt='Image Not Found' style={newStyle} />
            case 'video':
                return <VideoWithError src={media?.fileUrl} style={newStyle} />
            case 'document':
                return <AllIcon icon={icons.file} size='xxxlarge' style={newStyle} />
            case 'pdf':
                return <PDFViewer src={media?.fileUrl} /> 
            default: 
                return <AllIcon icon='file-circle-exclamation' size='xxlarge' color={'red'} heavy style={newStyle} />
        }
    }

    return (
        <>
        <Card style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <CardActionArea onClick={() => setPreviewDialog(true)}>
                <CardContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 120}}>
                    {getMediaElement()}
                </CardContent>
            </CardActionArea>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='body2' style={{fontSize: '0.90em'}}>
                            <b>Product Type: </b><br></br>
                            {media?.productType}
                        </Typography>
                        <Typography variant='body2' style={{fontSize: '0.90em'}}>
                            <b>Location: </b><br></br>
                            {media?.location}
                        </Typography>
                        <Typography variant='body2' style={{fontSize: '0.90em'}}>
                            <b>Description: </b><br></br>
                            {media?.desc}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} container spacing={1} style={{alignItems: 'center', justifyContent: 'center'}}>
                        {!readOnly && map(actions, (action, idx) => {
                            return (
                                <Grid item key={idx}>
                                    <AllIcon 
                                        icon={action.icon} 
                                        onClick={action.onClick} 
                                        tooltip={action.name}
                                        color={action.color}
                                        noMargin
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Dialog 
            open={previewDialog}  
            maxWidth="md" 
            scroll="body"
        >
            <DialogContent>
                <Grid container xs={12} style={{ justifyContent: 'center' }} >
                    <Grid item style={{padding:'1em'}}>
                        {getBigMediaPreview()}
                    </Grid>
                    <Grid item xs={12} className='buttonRow' style={{textAlign:'center', marginTop: '1em', marginBottom: '1em', justifyContent: 'center'}}>
                        <Button variant='outlined' onClick={()=>{setPreviewDialog(null)}}>Close</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
        </> 
    );
};

export default SimpleMediaCard;
