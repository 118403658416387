
import _ from 'lodash';
import API from 'API';

export async function getAccess(accessObj){
    await API.multiAccess(
        _.keys(accessObj)
    ).then(res => {
        _.each(res, (i,idx) => {
            accessObj[_.keys(accessObj)[idx]] = i;
        });
    })
    return accessObj;
}