import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import API from '../../../API';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';

const initialState = {
    availableDates: [],
    unavailableDates: [],
    selectedDate: '',
    formData:{
        date:{
            year: '',
            month: ''
        }
    },
    confirmationOpen: false,
    snackbarOpen: false
}

class AddProductionServiceBulletin extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState;
    }
    componentDidMount(){
        this.getAllProductionServiceBulletins();
    }
    getAllProductionServiceBulletins = () => {
        API.get('/researchDevelopment/productionServiceBulletin/all')
        .then(result => {
            let unavailableDates = [];
            result.data.forEach(el => {
                unavailableDates.push({
                    year: el.psb_year,
                    month: el.psb_month
                });
            });
            this.setState({
                ...this.state,
                unavailableDates
            }, () => this.generateDates());
        });
    }
    generateDates = () => {
        // Available dates include the previous 6 months and the next 6 months
        const from = moment().subtract(6, 'month');
        const to = moment().add(6, 'month');

        let year = null, month = null, availableDates = [];
        for(let m = moment(from); m.diff(to, 'months') <= 0; m.add(1, 'months')) {
            year = parseInt(m.format('YYYY'));
            month = parseInt(m.format('M'));

            let disabledDate = this.validateDates(year, month);

            availableDates.push({
                year,
                month,
                label: m.format('MMMM') + ' ' + year,
                disabled: disabledDate
            });

            this.setState({availableDates});
        }
    }
    validateDates = (year, month) => {
        let disableDate = false;
        this.state.unavailableDates.forEach(el => {
            if(el.year === year && el.month === month){
                disableDate = true;
            }
        });
        return disableDate;
    }
    handleDateChange = e => {
        let date = this.state.formData.date;

        date = {
            year: e && e.year,
            month: e && e.month
        };

        this.setState({
            formData:{
                date
            },
            selectedDate: e && e.label
        });
    }
    handleConfirmationOpen = () => this.setState({confirmationOpen: true});
    handleConfirmationClose = () => this.setState({confirmationOpen: false});
    handleConfirmationSuccess = () => this.setState({confirmationOpen: false}, () => this.handleSubmit());
    handleSnackbarClose = () => this.setState({snackbarOpen: false})
    handleSubmit = () => {
        API.post('/researchDevelopment/productionServiceBulletin', this.state.formData)
        .then(result => {
            this.setState({
                ...initialState, 
                snackbarOpen: true
            }, () => this.getAllProductionServiceBulletins());
        });
    }
    render() {
        return(
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Production & Service Bulletin
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Select Date
                            </Typography>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect 
                                    label="Date *"
                                    options={this.state.availableDates}
                                    value={this.state.selectedDate}
                                    onChange={this.handleDateChange}
                                    disableSort={true}
                                />
                            </FormControl>
                            <Button 
                                variant="contained"
                                color="primary"
                                style={{marginTop:10}}
                                onClick={this.handleConfirmationOpen}
                            >
                                Create
                            </Button>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add bulletin?" 
                    message="Are you sure you want to add this bulletin?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new bulletin'
                />
            </Grid>
        );
    }
}

export default AddProductionServiceBulletin;