import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import Button from '@material-ui/core/Button';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea from 'Components/Common/Inputs/Textarea';

import { useInput } from 'Functions/FormFunctions';
import { closeDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

const NotesDialog = ({type, id, updateText, callback}) => {

    const initialState = {
        formErrors: {},
        isLoading: false
    };

    let [state, setState] = useState(initialState);

    const { formErrors, isLoading } = state;

    const { value, bind, reset } = useInput(updateText ?? '');

    const dispatch = useDispatch();

    const close = () => dispatch(closeDialog());

    const submit = () => {
        
        setState(state => ({
            ...state,
            isLoading: true
        }));

        let formData = {
            type,
            id,
            notes: value
        };

        let url = updateText ? `/misc/notes/${id}` : '/misc/notes';

        API.post(url, formData)
        .then(result => {

            if(result.data.errors) {

                setState(state => ({
                    ...state,
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false
                }));

            } else if (result.data.success) {

                let snackBarMessage = !updateText ? 'You have successfully added notes!' : 'You have successfully updated notes!';

                reset();
                dispatch(deploySnackBar('success', snackBarMessage));
                close();
                if(callback) callback();

            }
        });
    }

    return (
        <>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <>
                    <Textarea 
                        id="notes"
                        name="notes"
                        label="Notes"
                        rows={5}
                        InputLabelProps={{
                            shrink: !!value,
                        }}
                        {...bind}
                        error={formErrors && formErrors['notes']}
                    />
                    <div className='buttonRow'>
                        <Button 
                            variant="outlined"
                            onClick={close}
                        >
                            Close
                        </Button>
                        <Button 
                            variant="contained"
                            color="primary"
                            onClick={submit}
                        >
                            {!updateText ? 'Add' : 'Update'}
                        </Button>
                    </div>
                </>
            )}
        </>
    );
}

export default NotesDialog;