import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import Radio from '@material-ui/core/Radio';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {colors} from 'Helpers/ColourHelper';

const initialState = { 
    formData: {
        part: '',
        supplier: '',
        supplierRef: '',
        noOfSuppliers: 0,
        nameOfSuppliers: '',
        costDefault: null,
        costs: [
            {
                quantity: '',
                totalCost: '',
                costPerUnit: ''
            }
        ]
    },
    partList: [],
    supplierList: [],
    existingSupplierList: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false
}

class AddCost extends Component {
    constructor(props) {
        super(props);
    
        this.state = initialState;
    }

    componentDidMount(){
        this.getParts();
    }

    getParts = () => {
        let partList;
        API.get('/parts/nonSubAssembly')
        .then((partRes) =>  {
            if(partRes.data){
                partList = _.map(partRes.data, el => {
                    return _.assign({
                        value: el.part_id,
                        label: el.part_number + ' - ' + el.part_description
                    });
                });
            }
            this.setState({
                partList: partList
            });
        });
    }

    getSuppliers = (partId) => {
        API.get('/suppliers/all', {
            params: {
                excludeCostForPart: partId
            }
        })
        .then((result) => {
            let supplierList = _.map(result.data, el => {
                return _.assign({
                    value: el.supp_id,
                    label: el.supp_company_name
                });
            });
            this.setState({
                supplierList: supplierList
            })
        });
    }

    getExistingSuppliers = (partId) => {
        API.get(`/parts/${partId}/suppliers`)
        .then((result) => {
            this.setState({
                existingSupplierList: result.data,
                formData: {
                    ...this.state.formData,
                    noOfSuppliers: result.data.length,
                    costDefault: result.data.length > 0 ? null : 0 // Set the default to first row if no exisitng suppliers
                }
            });
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, () => {
            if(fieldName === 'part'){
                this.getSuppliers(selectedOption && selectedOption.value);
                this.getExistingSuppliers(selectedOption && selectedOption.value);
            }
        });
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
           API.post('/costs', this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: true
                    });
                    this.getParts();
                }
                this.props.scrollToTop();
            }); 
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleAddCost = () => {
        const item = initialState.formData.costs[0];
        this.setState({
            formData:{
                ...this.state.formData,
                costs: [...this.state.formData.costs, item]
            }
        });
    }

    handleRemoveCost = (idx) => () => {
        const costs = [...this.state.formData.costs]
        costs.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                costs: costs
            }
        })
    }

    handleCostChange = (idx, decimals) => e => {
        const { name, value } = e.target;
        let newCosts =  [...this.state.formData.costs];
        let newVal = decimals ? parseFloat(value).toFixed(decimals) : value;

        newCosts[idx] = {
            ...newCosts[idx],
            [name]: newVal
        };
        // Calculate Cost Per Unit
        if(newCosts[idx].quantity > 0){
            let costPerUnit = newCosts[idx].totalCost / newCosts[idx].quantity;
            newCosts[idx] = {
                ...newCosts[idx],
                costPerUnit: !isNaN(costPerUnit) ? costPerUnit.toFixed(5).toString() : ''
            };
        }

        this.setState({
            formData: {
                ...this.state.formData,
                costs: newCosts
            }
        });
    };
       
    render() {
        const { formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <Typography variant="h5">
                        Add Cost
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Cost Details
                            </Typography>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.partList} 
                                        label='Part *'
                                        onChange={this.handleSelectChange('part')}
                                        error={formErrors && formErrors['part'] && true}
                                        errorText={formErrors && formErrors['part']}
                                        value={this.state.formData.part}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.supplierList} 
                                        label='Supplier *'
                                        onChange={this.handleSelectChange('supplier')}
                                        error={formErrors && formErrors['supplier'] && true}
                                        errorText={formErrors && formErrors['supplier']}
                                        value={this.state.formData.supplier}
                                    />
                                </FormControl>
                                <TextField
                                    id="supplierRef"
                                    name="supplierRef"
                                    label="Suppliers Reference *"
                                    value={this.state.formData.supplierRef}
                                    error={formErrors && formErrors['supplierRef'] && true}
                                    helperText={formErrors && formErrors['supplierRef']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Existing Suppliers ({this.state.formData.noOfSuppliers})
                            </Typography>
                            <List>
                                {this.state.existingSupplierList.length > 0 ?
                                    this.state.existingSupplierList.map((item, idx) => {
                                        return(
                                            <ListItem key={item.supp_id}>
                                                <ListItemText
                                                primary={item.supp_company_name}
                                                />
                                            </ListItem>
                                        )
                                    })
                                :
                                <Typography variant="subtitle1">No Suppliers</Typography>
                            }
                            </List>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>Qty</TableCell>
                                            <TableCell>Cost</TableCell>
                                            <TableCell>Cost Per Unit</TableCell>
                                            <TableCell style={{textAlign:'center'}}>Default</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.formData.costs.map((item, idx) => {
                                            let number = idx+1
                                            return (
                                                <TableRow key={idx}>
                                                    <TableCell>Cost {number}</TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="quantity"
                                                            value={this.state.formData.costs[idx].quantity}
                                                            error={formErrors && formErrors['costs|quantity|'+idx] && true}
                                                            helperText={formErrors && formErrors['costs|quantity|'+idx]}
                                                            onChange={this.handleCostChange(idx)}
                                                            onBlur={this.handleCostChange(idx, 3)}
                                                            type="number"
                                                            inputProps={{ min: 0 }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="totalCost"
                                                            value={this.state.formData.costs[idx].totalCost}
                                                            error={formErrors && formErrors['costs|totalCost|'+idx] && true}
                                                            helperText={formErrors && formErrors['costs|totalCost|'+idx]}
                                                            onChange={this.handleCostChange(idx)}
                                                            onBlur={this.handleCostChange(idx, 5)}
                                                            type="number"
                                                            inputProps={{ min: 0 }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="costPerUnit"
                                                            value={this.state.formData.costs[idx].costPerUnit}
                                                            error={formErrors && formErrors['costs|costPerUnit|'+idx] && true}
                                                            helperText={formErrors && formErrors['costs|costPerUnit|'+idx]}
                                                            onChange={this.handleCostChange(idx)}
                                                            type="number"
                                                            disabled
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{textAlign:'center'}}>                                                        
                                                        <Radio
                                                            // eslint-disable-next-line
                                                            checked={this.state.formData.costDefault == idx}
                                                            onChange={this.handleChange}
                                                            value={idx}
                                                            name="costDefault"
                                                            style={{margin:0}}
                                                            color="primary"
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {number > 1 &&
                                                            <IconButton onClick={this.handleRemoveCost(idx)}>
                                                                <FALightIcon icon='times' noMargin />
                                                            </IconButton>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                                {formErrors && formErrors['part_locked'] &&
                                    <Grid container xs={12} style={{paddingTop:'1em'}}>
                                        <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>
                                            This part is locked, please try again later.
                                        </Grid>
                                    </Grid>
                                }
                                <div style={{marginTop: 20}}>
                                    <Button onClick={this.handleAddCost}
                                            variant="outlined"
                                            color="primary">
                                        Add Cost
                                    </Button>
                                </div>
                                <div className='buttonRow'>
                                    <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.isLoading}
                                           >
                                        Add
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add A New Cost?" 
                    message="Are you sure you want to add this new cost?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new cost'
                />
            </Grid>
        );
    }
}

export default AddCost;
