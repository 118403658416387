import { Button, Grid, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React from 'react';
import API from 'API';
import _ from 'lodash';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { connect } from 'react-redux';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = props => ({
    PartCategories: props?.category?.parts_category_pivot.length > 0 ? _.map(props.category.parts_category_pivot, i => i.cmtpc_pc_id) : [],
    category: props.category,
    isLoading: true,
    selectedPartCategory: null,
    lists: {
        PartCategories: [],
    }
})

class AddPartCategoriesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getPartCategories();
    }

    getPartCategories = () => {
        API.get('/parts/catagories')
        .then(res => {
            this.setState({
                isLoading:  false,
                lists: {
                    PartCategories: _.map(res.data, i => ({
                        label: i.pc_name,
                        value: i.pc_id
                    }))
                }
            });
        });
    }
    
    submit = () => {
        API.post('/marketing/customerMarketing/addPartCatagories', {
            cId: this.state.category.cm_id,
            PartCategories: JSON.stringify(this.state.PartCategories)
        })
        .then(this.props.onClose)
    }

    render() {
        if (this.state.isLoading) return <LoadingCircle/>;
        return (
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <AutoCompleteSelect
                        options={_.filter(this.state.lists.PartCategories, i => !_.includes(this.state.PartCategories, i.value))}
                        value={this.state.selectedPartCategory}
                        onChange={({value}) => this.setState({selectedPartCategory: value})}
                        fullWidth
                        placeholder='Select Part Category'
                        showPlaceholder={true}
                        noClear
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        onClick={()=>{
                            this.setState({
                                PartCategories: [...this.state.PartCategories, this.state.selectedPartCategory],
                                selectedPartCategory: null
                            })
                        }}
                        disabled={!this.state.selectedPartCategory}
                    >Add</Button>
                </Grid>
                {this.state.PartCategories.length > 0 &&
                    <Grid item xs={12}>
                        <List>
                            {_.map(this.state.PartCategories, (i, idx) => 
                                <ListItem key={i}>
                                    <ListItemText primary={_.find(this.state.lists.PartCategories, {value: i}).label} />
                                    <ListItemSecondaryAction>
                                        <AllIcon
                                            icon={IconHelper.bin}
                                            noMargin
                                            onClick={()=>{
                                                this.setState({
                                                    PartCategories: _.filter(this.state.PartCategories, (j, jdx) => jdx !== idx)
                                                })
                                            }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                }
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant='outlined'
                        onClick={this.props.onClose}
                    >Close</Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={()=>this.props.deployConfirmation('Are you sure you want to link these part categories to this customer marketing sub-category?', 'Link Part Categories', this.submit)}
                    >Update</Button>
                </Grid>
            </Grid>
        )
    }
}


const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
})

export default connect(null, mapDispatchToProps) (AddPartCategoriesDialog);