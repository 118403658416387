import React, { PureComponent } from 'react';
import API                      from 'API';
import _                        from 'lodash';

import { Button, Grid, TextField, Typography } from '@material-ui/core';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';
import AllIcon from 'Components/Common/Icons/AllIcon';

const initialState = {
    selectedPackage: null,
    qty: 1,
    isLoading: true,
    packages: {},
    stockAlert: false,
}

class AddPackageRow extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getPackageList();
    }

    getPackageList = () => {
        API.get('/sales/packages', {params: {withDiscount: this.props.discountType === 'Without Discount' ? 0 : 1, noDiscount: this.props.discountType === 'Without Discount' ? 1 : 0, withStock: 1}})
        .then(res => {
            this.setState({
                packages: res.data, 
                isLoading: false
            });
        })
    }

    addPackage = () => {
        this.props.addPackage(_.find(this.state.packages, i => i.p_id === this.state.selectedPackage), this.state.qty);
        this.setState({selectedPackage: null, qty: 1, stockAlert: []});
    }

    checkStock = () => {
        const { selectedPackage, qty } = this.state;
        const pack = _.find(this.state.packages, i => i.p_id === selectedPackage);
        let lowStock = [];
        _.each(pack.details, i => {
            let req = parseFloat(i.pd_qty) * parseFloat(qty);
            let stock = parseFloat(i.part.stock.stock_available);
            i.part.stockable &&
                lowStock.push(
                    <Grid container style={{color: ((parseFloat(req) > parseFloat(stock)) && i.part.stockable) && colors.red}}>
                        <Grid item xs={4}>
                            {i.part.part_number} - {i.part.part_description}
                        </Grid>
                        <Grid item xs={8}>
                            <AllIcon icon={icons.stock} color={(parseFloat(req) > parseFloat(stock)) ? colors.red : colors.black} />{`Current Stock: ${ parseFloat(i.part.stock.stock_current_qty).toFixed(2)} | Available Stock: ${parseFloat(stock).toFixed(3)}`}
                        </Grid>
                    </Grid>
                );
        });
        this.setState({stockAlert: lowStock});
    }
    
    render() {

        const { selectedPackage, isLoading, packages, qty, stockAlert} = this.state;
        
        if (isLoading) return ( <LoadingCircle />);
        
        return(
                <Grid container spacing={2} style={{alignItems: 'flex-end'}}>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Add Sales Package
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <AutoCompleteSelect
                            label="Sales Package"
                            value={selectedPackage}
                            options={_.map(packages, i => ({value: i.p_id, label: i.p_name}))}
                            fullWidth
                            onChange={e => this.setState({selectedPackage: e.value}, this.checkStock)}
                            noClear
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Quantity (Whole Number Only)"
                            value={qty}
                            onChange={e => this.setState({qty: e.target.value},this.checkStock)}
                            onBlur={e => this.setState({qty: parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 1 })}
                            type="number"
                            fullWidth
                            InputProps={{
                                min: 1,
                                steps: 1
                            }}
                            disabled={!selectedPackage}
                        />
                    </Grid>
                    <Grid item xs={1} className='buttonRow'>
                        <Button 
                            fullWidth 
                            variant='contained' 
                            color='primary' 
                            disabled={!selectedPackage || parseInt(qty) < 1}
                            onClick={this.addPackage}    
                        >Add</Button>
                    </Grid>
                    {stockAlert.length > 0 &&
                        <Grid item xs={12} >
                            <Typography variant='body1' >
                                Sales Package Items:<br/>
                                {stockAlert}
                            </Typography>
                        </Grid>
                    }
                </Grid>
        );
    }
}

export default AddPackageRow;