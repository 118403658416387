import React, { PureComponent } from 'react';
import moment                   from 'moment';

import {Grid, FormControl, Button} from '@material-ui/core';

import DatePicker         from 'Components/Common/DatePickers/DatePicker';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import {getFiscalYearTimestamps} from 'Helpers/ReportsHelper';

const initialState = () => ({
    type:       'all',      
    from:       '1970-01-01', 
    to:         moment().endOf('year').format('YYYY-MM-DD'), 
    month:      moment().format('MMMM'), 
    year:       parseInt(getFiscalYearTimestamps().start.format('YYYY')),
    week:       parseInt(moment().format('W')),
    day:        'today',
    dateList:   [
        {label: 'day',          value: 'day',       from: moment().format('YYYY-MM-DD'),                         to: moment().format('YYYY-MM-DD')},
        {label: 'Week',         value: 'week',      from: moment().startOf('week').format('YYYY-MM-DD'),         to: moment().endOf('week').format('YYYY-MM-DD') },
        {label: 'Month',        value: 'month',     from: moment().startOf('month').format('YYYY-MM-DD'),        to: moment().endOf('month').format('YYYY-MM-DD') },
        {label: 'Year',         value: 'fyear',     from: getFiscalYearTimestamps().start.format('YYYY-MM-DD'),  to: getFiscalYearTimestamps().end.format('YYYY-MM-DD') },
        {label: 'All Time',     value: 'all',       from: '1970-01-01',                                          to: moment().endOf('year').format('YYYY-MM-DD') },
        {label: 'Custom',       value: 'custom',    from: moment().format('YYYY-MM-DD'),                         to: moment().format('YYYY-MM-DD') },
    ],
    formErrors: null, 
});

class DateRangeDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState(), 
            formErrors: this.props.error,
            to:         this.props.formData?.to   ? this.props.formData?.to   : moment().format('YYYY-MM-DD'), 
            from:       this.props.formData?.from ? this.props.formData?.from : moment().format('YYYY-MM-DD'), 
        };
    }

    handleChange = field => selectedValue => {
        this.setState({
            [field]:    selectedValue && selectedValue.value,
            from:       selectedValue && selectedValue.from,
            to:         selectedValue && selectedValue.to,
        }, this.handleChangeCallBack); 
    }

    callback = () => { this.props.callback({
            to:     this.state.to, 
            from:   this.state.from,
            type:   this.state.type
        }); 
    }

    getDays = () => {
        let days = [];
        days.push({
            label:  `Today (${moment().format('DD/MM/YYYY')})`, 
            value:  'today',
            from:   moment().format('YYYY-MM-DD'),
            to:     moment().format('YYYY-MM-DD')
        })
        for (var i=1; i < 7; i++){
            const now = moment().subtract(i, 'days');
            days.push({
                label:  `${now.format('dddd')} (${now.format('DD/MM/YYYY')})`, 
                value:  now.format('dddd'),
                from:   now.format('YYYY-MM-DD'),
                to:     now.format('YYYY-MM-DD')
            })
        }
        return days;
    }
        
    getWeeks = () => {
        let weeks  = [];
        for(var i=0;i<52;i++){
            const now = moment().subtract(i, 'weeks');
            weeks.push({
                label: `${!parseInt(i) ? `This Week` : now.format('[week ]W')} (${now.startOf('week').format('DD/MM/YYYY')} -  ${now.endOf('week').format('DD/MM/YYYY')})`,
                value: parseInt(now.format('W')),
                from:  now.startOf('week').format('YYYY-MM-DD'),
                to:    now.endOf('week').format('YYYY-MM-DD')
            })
        }
        return weeks;
    }

    getMonths = () => {
        let months  = [];
        for(var i=0; i < 12; i++){
            const now = moment().subtract(i, 'months');
            months.push(
                {
                    label:  now.format('MMMM YYYY'), 
                    value:  now.format('MMMM'),
                    from:   now.startOf('month').format('YYYY-MM-DD'),
                    to:     now.endOf('month').format('YYYY-MM-DD')
                }
            )
        }
        return months;
    }

    getYears = () => {
        let max = parseInt(moment().format('YYYY')), i = 2020, ret = [];
        let fiscalYear = getFiscalYearTimestamps();
        for (i;i<max;i++) {
            const sy = i, ey=(i+1);
            ret.push({
                value:  sy,
                label:  `${sy}/${ey}`, 
                from:   fiscalYear.start.set('year',sy).clone().format('YYYY-MM-DD'),
                to:     fiscalYear.end.set('year',ey).clone().format('YYYY-MM-DD')
            })
        }   
        if (parseInt(moment().quarter()) === 4) ret.push({
            value:  max,
            label:  `${max}/${max+1}`, 
            from:   fiscalYear.start.set('year',max).clone().format('YYYY-MM-DD'),
            to:     fiscalYear.end.set('year',max+1).clone().format('YYYY-MM-DD')
        })
        return ret;
    }

    handleDateChange = field => e => { 
        this.setState({
            [field]: moment(e).format('YYYY-MM-DD')
        }, this.handleChangeCallBack); 
    
    }

    handleChangeCallBack = () => {
        this.props.onChange && 
            this.props.onChange({
                to:     this.state.to, 
                from:   this.state.from,
                type:   this.state.type
             });
    }

    
    render() {
        
        const { formErrors, dateList, type, to, from, month, year, day, week } = this.state;

        return (
            <Grid container xs={12}>
                <Grid item xs={12} lg={12}>
                    <FormControl fullWidth margin="normal">
                        <AutoCompleteSelect 
                            options={dateList}
                            name='Timescale'
                            label='Timescale *'
                            error={formErrors && formErrors['Timescale'] && true}
                            errorText={formErrors && formErrors['Timescale']}
                            value={type}
                            onChange={this.handleChange('type')}
                            disableSort
                        />
                    </FormControl>
                </Grid>
                {type === 'custom' && 
                    <>
                        <Grid item xs={12} lg={6} style={{paddingRight: '0.75em'}}>
                            <DatePicker
                                type='date'
                                id="dateFrom"
                                name="dateFrom"
                                label="Date From"
                                autoOk={true}
                                value={from}
                                onChange={this.handleDateChange('from')}
                                error={formErrors && formErrors['dateFrom'] && true}
                                errorText={formErrors && formErrors['dateFrom']}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}  style={{paddingLeft: '0.75em'}}>
                            <DatePicker
                                type='date'
                                id="dateTo"
                                name="dateTo"
                                label="Date To"
                                autoOk={true}
                                value={to}
                                onChange={this.handleDateChange('to')}    
                                error={formErrors && formErrors['dateTo'] && true}
                                errorText={formErrors && formErrors['dateTo']}                                      
                            />
                        </Grid>
                    </>
                }
                {type === 'month' && 
                    <Grid item xs={12} lg={12}>
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect 
                                options={this.getMonths()}
                                name='monthScale'
                                label='Month *'
                                error={formErrors && formErrors['monthScale'] && true}
                                errorText={formErrors && formErrors['monthScale']}
                                value={month}
                                onChange={this.handleChange('month')}
                                disableSort
                            />
                        </FormControl>
                    </Grid>
                }
                {type === 'fyear' && 
                    <Grid item xs={12} lg={12}>
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect 
                                options={this.getYears()}
                                name='yearScale'
                                label='Year *'
                                error={formErrors && formErrors['yearScale'] && true}
                                errorText={formErrors && formErrors['yearScale']}
                                value={year}
                                onChange={this.handleChange('year')}
                                disableSort
                            />
                        </FormControl>
                    </Grid>
                }
                {type === 'day' && 
                    <Grid item xs={12} lg={12}>
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect 
                                options={this.getDays()}
                                name='dayScale'
                                label='Day *'
                                error={formErrors && formErrors['dayScale'] && true}
                                errorText={formErrors && formErrors['dayScale']}
                                value={day}
                                onChange={this.handleChange('day')}
                                disableSort
                            />
                        </FormControl>
                    </Grid>
                }
                {type === 'week' &&
                    <Grid item xs={12} lg={12}>
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect 
                                options={this.getWeeks()}
                                name='weekScale'
                                label='Week *'
                                error={formErrors && formErrors['weekScale'] && true}
                                errorText={formErrors && formErrors['weekScale']}
                                value={week}
                                onChange={this.handleChange('week')}
                                disableSort
                            />
                        </FormControl>
                    </Grid>
                }
                {this.props.callback &&
                    <Grid item xs={12} lg={12} >
                        <div className='buttonRow'>
                            {this.props.close ? 
                                <Button onClick={this.props.close} variant="outlined">
                                    Close
                                </Button>
                            : <></>}
                            <Button onClick={this.callback}
                                variant="contained"
                                color="primary">
                                {this.props.subLabel || 'Create Report'}
                            </Button>
                        </div>
                    </Grid>
                }
            </Grid>
        );
    }
    
}
export default DateRangeDialog;