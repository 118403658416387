import moment from 'moment';

export function clenyDate(time, showTime=0, justTime=0){  return moment(time).format( showTime ? 'DD/MM/YYYY [at] HH:mm' : (justTime ? 'HH:mm' : 'DD/MM/YYYY') ) }

export function getOrdinal (day) {
    const number = parseInt(day);
    switch (number) {
        case 1:
        case 21:
            return number + 'st';
        case 2:
        case 22:
            return number + 'nd';
        case 3:
        case 23:
            return number + 'rd';
        default:
            return number + 'th';
    }
}

export function formatSeconds(seconds, showHour=true, showMinute=true, showSeconds=true, roundup=false, separator=':') {
    let h = 0, m = 0, s = parseInt(seconds);
    let result = '';

    if (showHour) {
        h =  roundup && !showMinute ? Math.ceil(s / 3600) : Math.floor(s / 3600);
        s = s - (h*3600);
        if (!showMinute && !showSeconds) return h;
    }

    if (showMinute) {
        m = roundup && !showSeconds ? Math.ceil(s / 60) : Math.floor(s / 60);
        s = s - (m*60);
        if (!showHour && !showSeconds) return m;
    }

    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;

    switch (true){
        case showHour && showMinute && showSeconds:
            result = `${h}${separator}${m}${separator}${s}`;
        break;
        case showHour && showMinute && !showSeconds:
            result = `${h}${separator}${m}`;
        break;
        case !showHour && showMinute && showSeconds:
            result = `${m}${separator}${s}`;
        break;
        default: break;
    }

    return result;
}

export function isBetween(time, start, end){
    const t = moment(time).unix();
    const s = moment(start).unix();
    const e = moment(end).unix();

    return t >= s && t <= e;

    //return moment(time).isBetween(moment(start), moment(end));
}

export function getDifference(startTime, endTime, type='days'){
    const start = startTime ? moment(startTime) : moment();
    const end   = endTime   ? moment(endTime)   : moment();
    switch (type){
        case 'days':
            return Math.ceil(end.diff(start, 'seconds') / ( 60 * 60 * 24 ));
        default:
            return end.diff(start, type);
    }
}