import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import NotificationDialog from 'Components/Common/Dialogs/NotificationDialog';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Textarea from 'Components/Common/Inputs/Textarea';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { closeDialog } from 'Actions/Dialog/Dialog';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { 
    handleChange, 
    handleSelectChange, 
    handleMultiSelectChange, 
    handleConfirmationClose,
    handleConfirmationOpen, 
    handleConfirmationSuccess, 
} from 'Functions/FormFunctions';

const initialState = {
    confirmation: {
        submit: false,
        watchersRemoved: false,
    },
    formData: {
        taskId: false,
        taskTitle: '',
        taskAssign: '',
        taskWatchers: [],
        taskDeadline: "none",
        taskDatetime: moment().format('YYYY-MM-DD HH:mm'),
        taskNotes: '',
        taskPrivate: 0,
        taskReminder: 0,
        taskReminderDatetime: null
    },
    formErrors: [],
    formType: 'Add',
    isLoading: true,
    staffList: []
}

class AddTask extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState
        this.handleChange = handleChange.bind(this)
        this.handleSelectChange = handleSelectChange.bind(this)
        this.handleMultiSelectChange = handleMultiSelectChange.bind(this)
        this.handleConfirmationClose = handleConfirmationClose.bind(this)
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this)
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this)
    }

    componentDidMount = () => {
        API.get('/staff/all', 
            { 
                params: { 
                    active: true 
                } 
            }
        )
        .then(result => {
            if(result && result.data) {
                let staffList = _.map(result.data, staff => {
                    return _.assign({
                        value: staff.staff_id,
                        label: staff.staff_first_name + ' ' + staff.staff_last_name,
                    })
                })
                this.setState({
                    staffList
                }, () => this.loadComponentData())
            }
        })
    }

    loadComponentData = () => {
        let taskId = (this.props.match && this.props.match.params && this.props.match.params.id) || false,
            taskAssign = (this.props.loggedInStaff && this.props.loggedInStaff.id) || "",
            isLoading = taskId ? true : false,
            formType = taskId ? 'Update' : 'Add';

        this.setState({
            formData: {
                ...this.state.formData,
                taskId,
                taskAssign,
            },
            formType,
            isLoading
        }, () => {
            if(taskId) {
                API.get(`/tasks/${taskId}`)
                .then(result => {
                    if(result && result.data) {
                        if(result.data.errors) {
                            this.props.history.push('/tasks');
                        }
                        let taskWatchers = [];
                        _.each(result.data.watchers, staff => {
                            taskWatchers.push(staff.staff_id);
                        })
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                taskTitle: result.data.task_title,
                                taskDeadline: result.data.task_deadline_datetime ? "specific" : 'none',
                                taskDatetime: result.data.task_deadline_datetime ? result.data.task_deadline_datetime : moment(),
                                taskAssign: result.data.task_assigned_staff_id,
                                taskWatchers,
                            },
                            isLoading: false
                        })
                    }
                })
            }
        })

    }

    handleCheckedChange = e => {
        let name = e.target.name, val = e.target.checked;
        this.setState({
            confirmation: {
                ...this.state.confirmation,
                watchersRemoved: e.target.name === 'taskPrivate' ? !_.isEmpty(this.state.formData.taskWatchers) : false
            },
            formData: {
                ...this.state.formData,
                [name]: val ? 1 : 0,
                taskWatchers:  e.target.name === 'taskPrivate' && val ? [] : this.state.formData.taskWatchers
            }
        });
    }

    handleDateChange = name => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: moment(date).format('YYYY-MM-DD HH:mm'),
            }
        })
    }

    handleDeadlineChange = selectedOption => {
        let selection = selectedOption && selectedOption.value,
            isFriday = (moment().day() === 5),
            overwriteDateTime;

        switch(selection) {
            case 'none':
                overwriteDateTime = "none";
            break;
            case 'specific':
                overwriteDateTime = null;
            break;
            case 'hour':
                overwriteDateTime = moment().add(1, 'hours').format('YYYY-MM-DD HH:mm') + ':00';
            break;
            case 'day':
                overwriteDateTime = moment().add(1, 'days').format('YYYY-MM-DD HH:mm') + ':00';
            break;
            case 'week':
                overwriteDateTime = moment().add(1, 'weeks').format('YYYY-MM-DD HH:mm') + ':00';
            break;
            case 'tomorrow-morning':
                overwriteDateTime = moment().add(isFriday ? 3 : 1, 'days').format('YYYY-MM-DD') + ' 09:00:00';
            break;
            case 'tomorrow-afternoon':
                overwriteDateTime = moment().add(isFriday ? 3 : 1, 'days').format('YYYY-MM-DD') + ' 13:00:00';
            break;
            default:
                overwriteDateTime = null;
            break;
        }

        this.setState({
            formData: {
                ...this.state.formData,
                taskDeadline: selectedOption && selectedOption.value,
                taskDatetime: overwriteDateTime
            }
        })
    }
    
    submit = () => {
        if(this.state.formType === 'Update') {
            API.put(`/tasks/${this.state.formData.taskId}`, this.state.formData)
            .then(result => {
                if(result && result.data) {
                    if(result.data.errors) {
                        this.setState({
                            formErrors: formatValidationErrors(result.data.errors)
                        })
                    } else {
                        this.setState({
                            ...initialState,
                            staffList: this.state.staffList
                        }, () => {
                            this.loadComponentData();
                            this.props.deploySnackBar("success", "You have successfully created a new task")
                        })
                    }
                }
            })
        } else {
            API.post('/tasks', this.state.formData)
            .then(result => {
                if(result && result.data) {
                    if(result.data.errors) {
                        this.setState({
                            formErrors: formatValidationErrors(result.data.errors)
                        })
                    } else {
                        this.setState({
                            ...initialState,
                            staffList: this.state.staffList
                        }, () => {
                            this.props.deploySnackBar("success", "You have successfully created a new task")
                            if(this.props.callback) {
                                this.props.callback()
                            } else {
                                this.loadComponentData()
                            }
                        })
                    }
                }
            })
        }
    }

    render() {
        const { confirmation, formData, formErrors, formType, isLoading, staffList } = this.state;
        const { dialog } = this.props;
        return (
            (isLoading && (
                <LoadingCircle />
            )) || (
                <React.Fragment>
                    <Typography variant="h6">
                        Task Details
                    </Typography>
                    <form noValidate autoComplete="off">
                        <TextField
                            name="taskTitle"
                            label="Title *"
                            margin="normal" 
                            autoComplete="off"
                            error={formErrors && formErrors['taskTitle'] && true}
                            helperText={formErrors && formErrors['taskTitle']}
                            value={formData.taskTitle}
                            onChange={this.handleChange}
                            fullWidth
                        />
                        <FormControl margin="normal" fullWidth>
                            <AutoCompleteSelect
                                label='Deadline'
                                options={[
                                    {label: 'None', value: 'none'},
                                    {label: 'Date & Time', value: 'specific'},
                                ]} 
                                value={formData.taskDeadline}
                                onChange={this.handleDeadlineChange}
                                error={formErrors && formErrors['taskDeadline'] && true}
                            />
                        </FormControl>
                        {formData.taskDeadline === 'specific' && (
                            <FormControl fullWidth>
                                <DatePicker
                                    id="taskDatetime"
                                    name="taskDatetime"
                                    type="datetime"
                                    label="Date/Time *"
                                    value={formData.taskDatetime}
                                    errorText={formErrors && formErrors['taskDatetime']}
                                    onChange={this.handleDateChange('taskDatetime')}
                                    autoOk={true}
                                    disablePast={true}
                                />
                            </FormControl>
                        )}
                        <FormControl margin="normal" fullWidth>
                            <AutoCompleteSelect 
                                options={this.state.staffList} 
                                label='Assign To *'
                                value={formData.taskAssign}
                                onChange={(v) => this.handleSelectChange('taskAssign', v)}
                                error={formErrors && formErrors['taskAssign'] && true}
                                errorText={formErrors && formErrors['taskAssign']}
                            />
                        </FormControl>
                        {!formData.taskPrivate && (
                            <FormControl margin="normal" fullWidth>
                                <AutoCompleteMultiSelect 
                                    options={_.filter(staffList, function (el) { return el.value !== formData.taskAssign})} 
                                    label='Watchers'
                                    value={this.state.formData.taskWatchers}
                                    onChange={(v) => this.handleMultiSelectChange('taskWatchers', v)}
                                    error={formErrors && formErrors['taskWatchers'] && true}
                                    errorText={formErrors && formErrors['taskWatchers']}
                                    disabled={formData.taskPrivate}
                                />
                            </FormControl>
                        )}
                        <Textarea
                            name="taskNotes"
                            label={'Notes'}
                            value={formData.taskNotes}
                            onChange={this.handleChange}
                        />
                        <FormControl fullWidth margin="normal">
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={formData.taskPrivate === 1 ? true : false} 
                                        onChange={this.handleCheckedChange} 
                                        value={formData.taskPrivate}
                                        name="taskPrivate"
                                        color="primary"
                                    />
                                }
                                label="Private Task"
                            />
                        </FormControl>
                        <FormControl fullWidth margin="none">
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={formData.taskReminder === 1 ? true : false} 
                                        onChange={this.handleCheckedChange} 
                                        value={formData.taskReminder}
                                        name="taskReminder"
                                        color="primary"
                                    />
                                }
                                label="Set Reminder"
                            />
                        </FormControl>
                        {formData.taskReminder === 1 && (
                            <FormControl fullWidth>
                                <DatePicker
                                    id="taskReminderDatetime"
                                    name="taskReminderDatetime"
                                    type="datetime"
                                    label="Date/Time"
                                    value={formData.taskReminderDatetime}
                                    errorText={formErrors && formErrors['taskReminderDatetime']}
                                    onChange={this.handleDateChange('taskReminderDatetime')}
                                    autoOk={true}
                                    disablePast={true}
                                />
                            </FormControl>
                        )}
                        <div className='buttonRow'>
                            {dialog && 
                                <Button
                                    variant="outlined"
                                    color="default"
                                    onClick={() => this.props.closeDialog()}
                                >
                                    Close
                                </Button>
                            }
                            <Button onClick={() => this.handleConfirmationOpen('submit')}
                                variant="contained"
                                color="primary"
                                disabled={(formData.taskTitle.length === 0 || formData.taskAssign === "") && true}
                            >
                                {formType}
                            </Button>
                        </div>
                        <ConfirmationDialog 
                            open={confirmation.submit} 
                            success={() => this.handleConfirmationSuccess('submit')} 
                            close={() => this.handleConfirmationClose('submit')} 
                            title={`${formType} Task?`} 
                            message={`Are you sure you want to ${formType.toLowerCase()} this task?`}
                        />
                        <NotificationDialog 
                            open={confirmation.watchersRemoved} 
                            close={() => this.handleConfirmationClose('watchersRemoved')} 
                            title={`Watchers Removed`} 
                            message={`The watchers that were assigned to this task have been removed automatically as you've marked this task as private.`}
                        />
                    </form>
                </React.Fragment>
            )
        )
    }
}

const mapStateToProps = state => {
    return {
        loggedInStaff: state.staffAuth.staff
    };
}

const mapDispatchToProps = dispatch => {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTask);