import { FormControl, Grid, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core/';
import React, { Component } from 'react';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';

import _ from 'lodash';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = {
    pageType: '',
    isLoading: false,
    associatedResults: [],
    search: '',
    addresses: [],
    callback: null,
    loadingResults: false
}

const accountStatus = ['','Active - Verified','On Hold','Closed','Active - Not Verified']

class CustomerAddressSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            search: this.props.value
        }
        this.timeout = null;
    }

    componentDidMount(){
        this.getCustomerAddresses();
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    getCustomerAddresses = () => {
        API.get(`/customers/addresses`)
        .then((res) =>  {
            this.setState({
                addresses: res.data
            });
        })
    }

    filterResults = e => {
        this.setState({
            search: e.target.value,
            loadingResults: true
        }, 
        () => {
            this.timeout = setTimeout(this.getResults, 1000);
        })
    }

    cleanString(str) { return str.replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]', 'g'), '\\$&') }

    getResults = () => {

        var sites = _.filter(this.state.addresses, site => {
            if (!this.state.search.length) return 0;
            var ret = 0;
            if (this.cleanString(site.address_name.toLowerCase())
                .search(this.cleanString(this.state.search.toLowerCase())) > -1 )       ret = 1;
            if ( this.cleanString(site.address_postcode.toLowerCase())
                .search(this.cleanString(this.state.search.toLowerCase())) > -1 )       ret = 1;
            if ( site?.cust_name && this.cleanString(site?.cust_name?.toLowerCase())
                .search(this.cleanString(this.state.search.toLowerCase())) > -1 )       ret = 1;
            return ret;
        } );
        this.setState({
            associatedResults: sites,
            loadingResults: false
        });
    }

    handleClearSearch =() => {
        this.setState({
            search: ''
        }, ()=>{
            clearTimeout(this.timeout);
            this.getResults();
        })
    }

    handleCallBack = item => {
        this.props.callback(item); 
        if (!this.props.dontClear) {
            this.setState({
                associatedResults:[],
                search:''
            },  this.props.callback(item) );
        } else {
            this.props.callback(item);
        }
    }

    getColumns = () => {
        var slim    = this.props.slim;
        var columns = [];
        
        columns.push(
            {
                heading: 'Customer',
                field: 'cust_name',
                sizeToContent: true,
                truncate: true
            }
        )
        columns.push(
            {
                heading: 'Type',
                field: 'address_type',
                important: !slim,
                sizeToContent: true
            }
        );
        columns.push(
            {
                heading: 'Name',
                field: 'address_name',
                truncate:       true
            }
        );
        columns.push(
            {
                heading: 'Postcode',
                field: 'address_postcode',
                important: !slim,
                sizeToContent: true
            }
        );
        columns.push({
            actions: rowData => {
                return [
                    {
                        name:       `Add`, 
                        icon:       'check',
                        onClick:    this.handleCallBack
                    },
                ]
            }
        });

        

        return columns;
    }

    render(){

        const {associatedResults, loadingResults} = this.state;

        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={this.props.reset || this.props.clear ? 9 : 12}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="Search-Site-Addresses">{'Search Site Addresses'}</InputLabel>
                            <Input
                                id="Search-Site-Addresses"
                                onChange={this.filterResults}
                                value={this.state.search}
                                endAdornment={
                                    this.state.search &&
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="Clear Search"
                                        onClick={this.handleClearSearch}
                                      >
                                        <FALightIcon icon={'times'} noMargin button/>
                                      </IconButton>
                                    </InputAdornment>
                                  }
                            />
                        </FormControl>
                    </Grid>
                    {(this.props.reset || this.props.clear) &&
                        <Grid item alignItems='left' style={{paddingTop:'3em'}}>
                            {this.props.reset && <IconButton onClick={this.props.reset}><FALightIcon icon='undo' noMargin button /></IconButton> }
                            {this.props.clear && <IconButton onClick={this.props.clear}><FALightIcon icon='times' noMargin button /></IconButton>}
                        </Grid>  
                    }       
                </Grid>
                {loadingResults ? 
                    <LoadingCircle/> :
                    !associatedResults?.length ? <></>:
                    
                            <DataTable  
                                config={{
                                    pagination: false,
                                    isLoading: this.state.isLoading,
                                    responsiveImportance: true,
                                    alternatingRowColours: true
                                }}
                                columns={this.getColumns()}
                                rows={associatedResults}
                            />
                }
            </>
        )
    }
}

export default CustomerAddressSearch;