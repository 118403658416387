import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import Editable from 'Components/Common/Inputs/Editable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import NotificationDialogContent from 'Components/Common/Dialogs/NotificationDialogContent';
import Textarea from 'Components/Common/Inputs/Textarea';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { 
    handleChange,
    handleUpdateChange,
    handleConfirmationClose,
    handleConfirmationOpen, 
    handleConfirmationSuccess, 
} from 'Functions/FormFunctions';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import { Dial } from 'Functions/MiscFunctions';
import { Link } from 'react-router-dom';
import WildixCallTranscription from 'Components/Common/Voip/WildixCallTranscription';
import WildixCallRecordings from 'Components/Common/Voip/WildixCallRecording';

const styles = theme => ({
    alert: {
        marginBottom: 12
    },
    blank: {},
    overdue: {
        backgroundColor: '#d32f2f',
    },
    dueToday: {
        backgroundColor: '#E65100',
    },
    chip: {
        backgroundColor: '#E0E0E0!important',
    },
    chipOverdue: {
        backgroundColor: '#d32f2f!important',
        color: '#fff'
    },
    content: {
        padding: '0 16px 16px 16px',
        marginTop: -5
    },
    gridNotes: {
        padding: 8
    },
    editableTitle: {
        cursor: 'pointer',
        border: '1px solid #fff',
        padding: theme.spacing(1, 4, 1, 1),
        "&:hover": {
            border: '1px solid #ddd'
        }
    },
    headerArchived: {
        color: '#fff',
        backgroundColor: '#9E9E9E!important',
    },
    headerNew: {
        color: '#fff',
        backgroundColor: '#0288D1!important',
    },
    headerInProgress: {
        color: '#fff',
        backgroundColor: '#F57F17!important',
    },
    headerCompleted: {
        color: '#fff',
        backgroundColor: '#4CAF50!important',
    },
    sideBar: {
        backgroundColor: '#fafafa',
        borderLeft: '1px solid #ddd'
    },
    status: {
        color: '#fff'
    },
    title: {
        paddingTop: `${theme.spacing(2)}px!important`
    },
});

const initialState = {
    access: {
        callRecording: false,
        updateTask: false,
    },
    confirmation: {
        archive: false,
        delete: false,
    },
    editable: false,
    formData: {
        taskNotes: '',
    },
    initialUpdateData: {
        taskTitle: '',
        taskAssign: '',
        taskWatchers: [],
        taskDeadline: "none",
        taskDatetime: moment().format('YYYY-MM-DD HH:mm'),
        taskReminder: 0,
        taskReminderDatetime: moment().format('YYYY-MM-DD HH:mm')
    },
    updateData: {
        taskTitle: '',
        taskAssign: '',
        taskWatchers: [],
        taskDeadline: "none",
        taskDatetime: moment().format('YYYY-MM-DD HH:mm'),
        taskReminder: 0,
        taskReminderDatetime: moment().format('YYYY-MM-DD HH:mm')
    },
    updateErrors: [],
    isLoading: true,
    isWatcher: false,
    readOnly: true,
    showDatePicker: false,
    showReminderDatePicker: false,
    staffList: [],
    task: {}
}

class TaskDialog extends React.Component { 
    constructor(props) {
        super(props)
        this.state = initialState
        this.handleChange = handleChange.bind(this)
        this.handleUpdateChange = handleUpdateChange.bind(this)
        this.handleConfirmationClose = handleConfirmationClose.bind(this)
        this.handleConfirmationOpen = handleConfirmationOpen.bind(this)
        this.handleConfirmationSuccess = handleConfirmationSuccess.bind(this)
    }

    componentDidMount = () => {
        Promise.all([
            API.get('/staff/all', 
                { 
                    params: { 
                        active: true 
                    } 
                }
            ),
            API.get('/staff/my/access/check/delete-task'),
            API.get('/staff/my/access/check/update-task'),
            API.get('/staff/my/access/check/call-rec')
        ])
        .then(([staffListRes, deleteTask, updateTask, callRecording]) => {
            let staffList = _.map(staffListRes.data, staff => {
                return _.assign({
                    value: staff.staff_id,
                    label: staff.staff_first_name + ' ' + staff.staff_last_name,
                })
            })
            this.setState({
                access: {
                    callRecording: (callRecording && callRecording.data && callRecording.data.has_access) || false,
                    deleteTask: (deleteTask && deleteTask.data && deleteTask.data.has_access) || false,
                    updateTask: (updateTask && updateTask.data && updateTask.data.has_access) || false
                },
                staffList: staffList,
            }, () => this.loadComponentData())
        })
    }

    archive = () => {
        const { task } = this.state;
        API.get(`/tasks/${task.task_id}/archive`)
        .then(result => {
            if(result && result.data) {
                if(this.props.callback) {
                    this.props.callback()
                }
                this.props.deploySnackBar("success", "You have successfully archived this task")
                this.props.closeDialog()
            }
        })
    }

    delete = () => {
        const { task } = this.state;
        API.get(`/tasks/${task.task_id}/delete`)
        .then(result => {
            if(result && result.data) {
                if(this.props.callback) {
                    this.props.callback()
                }
                this.props.deploySnackBar("success", "You have successfully deleted this task")
                this.props.closeDialog()
            }
        })
    }

    handleNavigateAway = () => {
        this.props.closeDialog()
    }

    loadComponentData = () => {
        API.get(`/tasks/${this.props.id}`)
        .then(result => {
            if(result && result.data) {
                if(result.data.errors) {
                    this.props.deployDialog(<NotificationDialogContent title="Something went wrong" message="Sorry this task no longer exists" close={() => this.props.closeDialog()} />, "", "standard", "xs")
                } else {
                    let task = result.data;
                    let taskTitle = task.task_title;
                    let taskAssign = task.task_assigned_staff_id;
                    let isLoading = false;
                    let staffId = this.props.loggedInStaff.id;
                    let readOnly = result.data.task_assigned_staff_id !== staffId ? true : false;
                    let isWatcher = _.some(result.data.watchers, function(el) { return el.staff_id === staffId });
                    let editable = result && result.data && result.data.task_status !== "Completed" && this.state.access.updateTask && !readOnly;
                    let taskDatetime = result.data.task_deadline_datetime ? result.data.task_deadline_datetime : moment().format('YYYY-MM-DD HH:mm');
                    let taskDeadline = result.data.task_deadline_datetime ? 'specific' : 'none';
                    let taskReminder = result.data.task_reminder_datetime ? 1 : 0;
                    let taskReminderDatetime = result.data.task_reminder_datetime ? result.data.task_reminder_datetime : moment().format('YYYY-MM-DD HH:mm')
                    let taskWatchers = [];
                    _.each(result.data.watchers, staff => {
                        taskWatchers.push(staff.staff_id);
                    })
                    this.setState({
                        initialUpdateData: {
                            taskTitle,
                            taskAssign,
                            taskWatchers,
                            taskDeadline,
                            taskDatetime,
                            taskReminder,
                            taskReminderDatetime
                        },
                        updateData: {
                            taskTitle,
                            taskAssign,
                            taskWatchers,
                            taskDeadline,
                            taskDatetime,
                            taskReminder,
                            taskReminderDatetime
                        },
                        editable,
                        readOnly,
                        isWatcher,
                        isLoading,
                        task,
                    })
                }
            }
        })
    }

    handleReset = () => {
        this.setState({
            updateData: {
                taskTitle: this.state.initialUpdateData.taskTitle,
                taskAssign: this.state.initialUpdateData.taskAssign,
                taskWatchers: this.state.initialUpdateData.taskWatchers,
                taskDeadline: this.state.initialUpdateData.taskDeadline,
                taskDatetime: this.state.initialUpdateData.taskDatetime,
            }
        })
    }

    handleUpdateSelectChange = (name, selectedOption) => {
        const { task, updateData } = this.state;
        if(!selectedOption) {
            return;
        }
        if(selectedOption === Object(selectedOption)) {
            selectedOption = selectedOption.value
        }
        let data = updateData;
        data = {
            ...data,
            [name]: selectedOption
        }
        API.put(`/tasks/${task.task_id}`, data)
        .then(result => {
            if(result && result.data) {
                if(this.props.callback) {
                    this.props.callback()
                }
                if(name === "taskAssign") {
                    this.props.deploySnackBar("success", "You have re-assigned this task successfully")
                    this.props.closeDialog();
                } else {
                    this.loadComponentData();
                }
            }
        })
    }

    handleMultiSelectChange = (fieldName, selectedOptions) => {
        const { updateData } = this.state;
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            updateData: {
                ...updateData,
                [fieldName]: values
            }
        })
    }
    
    handleDateChange = name => date => {
        const { updateData } = this.state;
        this.setState({
            showDatePicker: false,
            showReminderDatePicker: false,
            updateData: {
                ...updateData,
                [name]: moment(date).format('YYYY-MM-DD HH:mm')
            }
        }, () => this.handleUpdateTask())
    }

    handleDeadlineChange = selectedOption => {
        const { updateData } = this.state;
        let selection = selectedOption && selectedOption.value,
            isFriday = (moment().day() === 5),
            overwriteDateTime;

        switch(selection) {
            case 'none':
                overwriteDateTime = this.state.initialUpdateData.taskDatetime;
            break;
            case 'specific':
                overwriteDateTime = null;
            break;
            case 'hour':
                overwriteDateTime = moment().add(1, 'hours').format('YYYY-MM-DD HH:mm') + ':00';
            break;
            case 'day':
                overwriteDateTime = moment().add(1, 'days').format('YYYY-MM-DD HH:mm') + ':00';
            break;
            case 'week':
                overwriteDateTime = moment().add(1, 'weeks').format('YYYY-MM-DD HH:mm') + ':00';
            break;
            case 'tomorrow-morning':
                overwriteDateTime = moment().add(isFriday ? 3 : 1, 'days').format('YYYY-MM-DD') + ' 09:00:00';
            break;
            case 'tomorrow-afternoon':
                overwriteDateTime = moment().add(isFriday ? 3 : 1, 'days').format('YYYY-MM-DD') + ' 13:00:00';
            break;
            default:
                overwriteDateTime = null;
            break;
        }

        this.setState({
            updateData: {
                ...updateData,
                taskDeadline: selectedOption && selectedOption.value,
                taskDatetime: overwriteDateTime
            }
        }, () => { 
            if(this.state.updateData.taskDeadline === "none") {
                this.handleUpdateTask();
            } else {
                this.setState({
                    showDatePicker: true,
                })
            }
        })
    }

    
    handleReminderChange = selectedOption => {
        const { updateData } = this.state;
        this.setState({
            updateData: {
                ...updateData,
                taskReminder: (selectedOption && selectedOption.value) || 0,
            }
        }, () => { 
            if(this.state.updateData.taskReminder === 0) {
                this.handleUpdateTask();
            } else {
                this.setState({
                    showReminderDatePicker: true,
                })
            }
        })
    }

    handleAddNotes = () => {
        const { formData, task } = this.state;
        API.post(`/tasks/${task.task_id}/note`, formData)
        .then(result => {
            if(result && result.data) {
                if(this.props.callback) {
                    this.props.callback()
                }
                this.setState({
                    formData: initialState.formData
                }, () => {
                    this.props.deploySnackBar("success", "You have successfully added a note to this task")
                    this.loadComponentData()
                })
            }
        })
    }

    handleDeployCallRecordings = (id) => {
        this.props.deployDialog(
            <WildixCallRecordings id={id} />,
            "Call Recordings",
            "standard",
            "xs"
        )
    }

    handleDeployCallTranscriptions = (id) => {
        this.props.deployDialog(
            <WildixCallTranscription id={id} />,
            "Call Transcription",
            "standard",
            "md"
        )
    }

    handleStatusChange = selectedOption => {
        const { task } = this.state;
        if(!selectedOption) {
            return;
        }
        if(selectedOption === Object(selectedOption)) {
            selectedOption = selectedOption.value
        }
        API.put(`/tasks/${task.task_id}/status`, 
            {
                taskStatus: selectedOption
            }
        ).then(result => {
            if(result && result.data) {
                if(this.props.callback) {
                    this.props.callback()
                }
                this.props.deploySnackBar("success", `You have successfully marked this task as ${selectedOption}`)
                this.loadComponentData();
            }
        })
    }

    handleUpdateTask = () => {
        const { task, initialUpdateData, updateData } = this.state;
        if(JSON.stringify(initialUpdateData) !== JSON.stringify(updateData)) {
            let data = updateData;
            if(data.taskTitle.length === 0) {
                data = {
                    ...data,
                    taskTitle: task.task_title
                }
            }
            API.put(`/tasks/${task.task_id}`, data)
            .then(result => {
                if(result && result.data) {
                    if(result.data.errors) {
                        this.setState({
                            updateErrors: formatValidationErrors(result.data.errors)
                        })
                    } else {
                        if(this.props.callback) {
                            this.props.callback()
                        }
                        this.setState({
                            updateErrors: [],
                        }, () => {
                            this.props.deploySnackBar("success", "You have successfully updated this task")
                            this.loadComponentData()
                        });
                    }
                }
            })
        }
    }

    render() {
        const { access:{callRecording, deleteTask}, confirmation, formData, isLoading, isWatcher, readOnly, staffList, task, updateData, updateErrors } = this.state;
        const { classes } = this.props;

        let alert = false, archived = false;

        if(task && task.task_status && task.task_status !== 'Completed' && task.task_deadline_datetime && moment(task.task_deadline_datetime, "YYYY-MM-DD HH:mm:ss").isBefore(moment())) {
            alert = 'overdue'
        }

        if(task && task.task_soft_delete === 1) {
            archived = true
        }

        return (
            (isLoading && (
                <LoadingCircle />
            )) || (
                <React.Fragment>
                    <DialogContent className={classes.content}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} className={archived ? classes.headerArchived : (task.task_status === 'New' ? classes.headerNew : (task.task_status === 'In Progress' ? classes.headerInProgress : classes.headerCompleted))}>
                                <Grid container spacing={3} justify='flex-end' alignItems='center'>
                                    <Grid item xs>
                                        <Typography variant='h6' className={classes.status}>
                                            {archived ? 'Archived' : task.task_status}
                                        </Typography>
                                    </Grid>
                                    {deleteTask && !readOnly && ( 
                                        <React.Fragment>
                                            <Grid item>
                                                <Tooltip title='Delete'>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => this.handleConfirmationOpen('delete')} 
                                                    >
                                                        <FALightIcon icon={'trash-alt'} style={{width: 20, height: 20}} noMargin buttonPrimary />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {task.task_status === "Completed" && !task.task_soft_delete && (
                                                <Grid item>
                                                    <Tooltip title={'Archive'}>
                                                        <IconButton
                                                            color="primary"
                                                            onClick={() => this.handleConfirmationOpen('archive')} 
                                                        >
                                                            <FALightIcon icon={'archive'} style={{width: 20, height: 20}} noMargin buttonPrimary />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                        </React.Fragment>
                                    )}  
                                    <Grid item>
                                        <Tooltip title="Close">
                                            <IconButton
                                                color="primary"
                                                onClick={() => this.props.closeDialog()} 
                                            >
                                                <FALightIcon icon="times" style={{width: 24, height: 24}} noMargin buttonPrimary />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className={classes.title}>
                                        <Editable
                                            text={(
                                                <Typography variant="h6" className={!readOnly && task.task_status !== 'Completed' && classes.editableTitle} style={{position: 'relative'}}>
                                                    {updateData.taskTitle}
                                                    {(!readOnly && task.task_status !== 'Completed') && (
                                                        <Tooltip title="Update Title">
                                                            <IconButton style={{position: 'absolute', right: 8, top: 16 }}>
                                                                <FALightIcon icon="pencil-alt" noMargin button />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Typography>
                                            )}
                                            type="input"
                                            disabled={readOnly || task.task_status === 'Completed'}
                                            onChange={this.handleUpdateTask}
                                        >
                                            <TextField 
                                                name="taskTitle"
                                                margin="none" 
                                                autoComplete="off"
                                                error={updateErrors && updateErrors['taskTitle'] && true}
                                                helperText={updateErrors && updateErrors['taskTitle']}
                                                value={updateData.taskTitle}
                                                onChange={this.handleUpdateChange}
                                                fullWidth
                                                required
                                                autoFocus
                                            />
                                        </Editable>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />   
                                    </Grid>
                                    {task.call_log && (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography variant="h6">
                                                    Linked Call Details
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={3} wrap="nowrap">
                                                    <Grid item xs>
                                                        <Grid container spacing={1}>
                                                            {task.call_log.call_name.replace(/^(\ )/, "") === "" ? null : (
                                                                <Grid item xs={12}>
                                                                    <Typography variant="body1">
                                                                        <strong>Contact:</strong> {task.call_log.call_name}
                                                                    </Typography>
                                                                </Grid>
                                                            )}
                                                            {_.isEmpty(task.call_log.call_company) ? null : (
                                                                <Grid item xs={12}>
                                                                    <Grid container spacing={1} wrap="nowrap">
                                                                        <Grid item>
                                                                            <Typography variant="body1">
                                                                                <strong>Company:</strong> 
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography variant="body1">
                                                                                <Link 
                                                                                    className="link"
                                                                                    onClick={this.handleNavigateAway}
                                                                                    to={task.call_log.call_cust_id ? `/customers/view/${task.call_log.call_cust_id}` : task.call_log.call_supp_id ? `/suppliers/view/${task.call_log.call_supp_id}` : `/`}
                                                                                    disabled={!task.call_log.call_cust_id || !task.call_log.call_supp_id}
                                                                                >
                                                                                    {!_.isEmpty(task.call_log?.customer?.cust_trading_name) ? task.call_log.customer.cust_trading_name : !_.isEmpty(task.call_log?.customer?.cust_name) ? task.call_log.customer.cust_name : task.call_log.call_company}
                                                                                </Link>
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            {task.call_log.customer_address && (
                                                                <Grid item xs={12}>
                                                                    <Grid container spacing={1} wrap="nowrap">
                                                                        <Grid item>
                                                                            <Typography variant="body1">
                                                                                <strong>{task.call_log.customer_address.address_type} Address:</strong>
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography variant="body1">
                                                                                <Link 
                                                                                    className="link"
                                                                                    onClick={this.handleNavigateAway}
                                                                                    to={`/customers/addresses/view/${task.call_log.customer_address.address_id}`}
                                                                                >
                                                                                    {task.call_log.customer_address.address_name}
                                                                                </Link>
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={1} wrap="nowrap">
                                                                    <Grid item>
                                                                        <Typography variant="body1">
                                                                            <strong>Phone Number:</strong>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="body1">
                                                                            <Dial number={task.call_log.call_number} />
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="body1">
                                                                    <strong>Date / Time:</strong> {moment(task.call_log.call_created_datetime).format('DD/MM/YYYY HH:mm')}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="body1">
                                                                    <strong>Staff:</strong> {task.call_log.staff.staff_first_name} {task.call_log.staff.staff_last_name}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {callRecording && (task?.call_log?.rc > 0 || task?.call_log?.tc > 0) ? (
                                                        <Grid item>
                                                            <Grid container spacing={3} direction='column' alignItems='flex-end'>
                                                                {task?.call_log?.rc > 0 && (
                                                                    <Grid item>
                                                                        <Button 
                                                                            variant="contained" 
                                                                            color="primary" 
                                                                            onClick={() => this.handleDeployCallRecordings(task.call_log.call_id)}
                                                                        >
                                                                            <FALightIcon icon='cassette-tape' buttonPrimary /> Call Recordings
                                                                        </Button>
                                                                    </Grid>
                                                                )}
                                                                {task?.call_log?.tc > 0 && (
                                                                    <Grid item>
                                                                        <Button 
                                                                            variant="contained" 
                                                                            color="primary" 
                                                                            onClick={() => this.handleDeployCallTranscriptions(task.call_log.call_id)}
                                                                        >
                                                                            <FALightIcon icon='file-lines' buttonPrimary /> Call Transcriptions
                                                                        </Button>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box className="content-light-white" p={2} my={1}>
                                                        <Typography variant="body1" gutterBottom>
                                                            <strong>Notes</strong>
                                                        </Typography>
                                                        {_.isEmpty(task.call_log.call_note) ? (
                                                            <em>
                                                                <FAIcon icon="exclamation-circle" type="light" size={14} />
                                                                No notes added for this call
                                                            </em>
                                                        ) : (
                                                            <Typography 
                                                                variant="body1" 
                                                                style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", overflowWrap: "break-word", overflow: "hidden" }}
                                                            >
                                                                {task.call_log.call_note}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />   
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} className={classes.gridNotes}>
                                        <Grid container spacing={1} alignItems='flex-start'>
                                            <Grid item xs={12}>
                                                <Typography variant="h6">
                                                    Notes
                                                </Typography>
                                            </Grid>
                                            {(!readOnly || isWatcher) && !task.task_soft_delete && (
                                                <React.Fragment>
                                                    <Grid item xs>
                                                        <Textarea
                                                            name="taskNotes"
                                                            label='Add Note'
                                                            value={formData.taskNotes}
                                                            onChange={this.handleChange}
                                                            margin="none"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={this.props.ui.device.isMobile ? 12 : false}>
                                                        <Button 
                                                            onClick={() => this.handleAddNotes()} 
                                                            variant="contained" 
                                                            color="primary"
                                                            disabled={_.isEmpty(formData.taskNotes)}
                                                            style={{marginTop: 7}}
                                                        >
                                                            <FALightIcon icon='plus' disabled={_.isEmpty(formData.taskNotes)} buttonPrimary />
                                                            Add
                                                        </Button>
                                                    </Grid>
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={_.isEmpty(task.notes) ? 1 : 2} style={{padding: 8}}>
                                            <Grid item xs={12}>
                                                <div className='SmallScroll' style={{paddingRight: 16, height: !this.props.ui.device.isMobile && 400, maxHeight: !this.props.ui.device.isMobile && 400, overflowX: 'hidden', overflowY: !this.props.ui.device.isMobile ? 'auto' : 'hidden'}}>
                                                    {(!_.isEmpty(task.notes) && (
                                                        <Grid container spacing={2}>
                                                            {_.map(task.notes, (note, idx) => (
                                                                <Grid key={`note-${idx}`} item xs={12} align='left'>
                                                                    <Grid container alignItems='flex-start' spacing={2}>
                                                                        {(idx > 0 && (
                                                                            <Grid item xs={12}>
                                                                                <Divider />
                                                                            </Grid>
                                                                        )) || (
                                                                            <Grid item xs={12} />
                                                                        )}
                                                                        <Grid item> 
                                                                            <Avatar alt={`${note.task_note_staff_name}`} src={note.staff.staff_profile_photo} />
                                                                        </Grid>
                                                                        <Grid item xs>
                                                                            <Typography variant="body1">
                                                                                {note.task_note_staff_name}
                                                                            </Typography>
                                                                            <Typography variant="caption" color="textSecondary" paragraph>
                                                                                {moment(note.task_note_created_datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {note.task_note_text && note.task_note_text.split('\n').map((item, key) => {
                                                                                    return <span key={key}>{item}<br/></span>
                                                                                })}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    )) || (((readOnly && !isWatcher) || archived) && (
                                                        <Grid item xs>
                                                            <Typography variant="body1">
                                                                There are no notes to show for this task
                                                            </Typography>
                                                        </Grid>
                                                    ))}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={3} className={classes.sideBar}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems='center' align='center'>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" component="div">
                                                    Status
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Editable
                                                    text={((readOnly || task.task_soft_delete) && (
                                                        <Chip
                                                            avatar={<Avatar alt='Status' className={archived ? classes.headerArchived : (task.task_status === 'New' ? classes.headerNew : (task.task_status === 'In Progress' ? classes.headerInProgress : classes.headerCompleted))} style={{width: 32, height: 32}}><FASolidIcon icon="traffic-light" noMargin white /></Avatar>}
                                                            label={archived ? 'Archived' : task.task_status}
                                                            clickable={false}
                                                            className={archived ? classes.headerArchived : (task.task_status === 'New' ? classes.headerNew : (task.task_status === 'In Progress' ? classes.headerInProgress : classes.headerCompleted))}
                                                        />
                                                    )) || (
                                                        <Tooltip title="Update Status" open={(readOnly || task.task_soft_delete) && false}>
                                                            <Chip
                                                                avatar={<Avatar alt='Status' className={task.task_status === 'New' ? classes.headerNew : (task.task_status === 'In Progress' ? classes.headerInProgress : classes.headerCompleted)} style={{width: 32, height: 32}}><FASolidIcon icon="pencil-alt" noMargin buttonPrimary /></Avatar>}
                                                                label={task.task_status}
                                                                clickable={!readOnly && !task.task_soft_delete}
                                                                className={task.task_status === 'New' ? classes.headerNew : (task.task_status === 'In Progress' ? classes.headerInProgress : classes.headerCompleted)}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    type="chip"
                                                    disabled={readOnly || task.task_soft_delete}
                                                    onChange={this.handleUpdateTask}
                                                >
                                                    <FormControl fullWidth>
                                                        <AutoCompleteSelect
                                                            options={[
                                                                {label: 'New', value: 'New'},
                                                                {label: 'In Progress', value: 'In Progress'},
                                                                {label: 'Completed', value: 'Completed'},
                                                            ]} 
                                                            value={task.task_status}
                                                            onChange={this.handleStatusChange}
                                                            disabled={readOnly}
                                                            disableSort
                                                            noClear
                                                            autoFocus
                                                        />
                                                    </FormControl>
                                                </Editable>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {((!readOnly && task.task_status !== "Completed") || task.task_deadline_datetime) && (
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='center' align='center'>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" component="div">
                                                        Deadline
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {(updateData.taskDeadline === 'specific' && this.state.showDatePicker && (
                                                        <FormControl fullWidth>
                                                            <DatePicker
                                                                id="taskDatetime"
                                                                name="taskDatetime"
                                                                type="datetime"
                                                                value={updateData.taskDatetime}
                                                                errorText={updateErrors && updateErrors['taskDatetime']}
                                                                onChange={this.handleDateChange('taskDatetime')}
                                                                autoOk={true}
                                                                margin="none"
                                                                open={this.state.showDatePicker}
                                                            />
                                                        </FormControl>
                                                    )) || (
                                                        <Editable
                                                            text={((readOnly || task.task_status === "Completed") && (
                                                                <Chip
                                                                    avatar={<Avatar alt='Due Date' style={{width: 32, height: 32}} className={alert === 'overdue' ? classes.overdue : classes.blank}><FASolidIcon icon='calendar-exclamation' noMargin white /></Avatar>}
                                                                    label={task.task_deadline_datetime ? moment(task.task_deadline_datetime).format("DD/MM/YYYY HH:mm") : ''}
                                                                    className={alert === 'overdue' ? classes.chipOverdue : classes.chip}
                                                                    clickable={false}
                                                                />
                                                            )) || (
                                                                <Tooltip title={`${task.task_deadline_datetime ? 'Update' : 'Add'} Deadline`}>
                                                                    <Chip
                                                                        avatar={<Avatar alt='Due Date' style={{width: 32, height: 32}} className={alert === 'overdue' ? classes.overdue : classes.blank}><FASolidIcon icon='pencil-alt' noMargin buttonPrimary /></Avatar>}
                                                                        label={task.task_deadline_datetime ? moment(task.task_deadline_datetime).format("DD/MM/YYYY HH:mm") : 'Add Deadline'}
                                                                        className={alert === 'overdue' ? classes.chipOverdue : classes.chip}
                                                                        clickable={!readOnly && task.task_status !== "Completed"}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                            type="chip"
                                                            disabled={readOnly || task.task_status === "Completed"}
                                                            
                                                        >
                                                            <FormControl fullWidth>
                                                                <AutoCompleteSelect
                                                                    options={[
                                                                        {label: 'None', value: 'none'},
                                                                        {label: 'Date & Time', value: 'specific'},
                                                                    ]} 
                                                                    value={updateData.taskDeadline}
                                                                    onChange={this.handleDeadlineChange}
                                                                    noClear
                                                                    autoFocus
                                                                />
                                                            </FormControl>
                                                        </Editable>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                     {((!readOnly && task.task_status !== "Completed") || task.task_reminder_datetime) && (
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='center' align='center'>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" component="div">
                                                        Reminder
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {(updateData.taskReminder && this.state.showReminderDatePicker && (
                                                        <FormControl fullWidth>
                                                            <DatePicker
                                                                id="taskReminderDatetime"
                                                                name="taskReminderDatetime"
                                                                type="datetime"
                                                                value={updateData.taskReminderDatetime}
                                                                errorText={updateErrors && updateErrors['taskReminderDatetime']}
                                                                onChange={this.handleDateChange('taskReminderDatetime')}
                                                                autoOk={true}
                                                                margin="none"
                                                                disablePast={true}
                                                                open={this.state.showReminderDatePicker}
                                                            />
                                                        </FormControl>
                                                    )) || (
                                                        (readOnly && (
                                                            <Chip
                                                                avatar={<Avatar alt='Reminder' style={{width: 32, height: 32}}><FASolidIcon icon='bell-exclamation' noMargin white /></Avatar>}
                                                                label={task.task_reminder_datetime ? moment(task.task_reminder_datetime).format("DD/MM/YYYY HH:mm") : ''}
                                                                clickable={false}
                                                            />
                                                        )) || (
                                                            <Editable
                                                                text={(
                                                                    <Tooltip title={`${task.task_reminder_datetime ? 'Update' : 'Add'} Reminder`}>
                                                                        <Chip
                                                                            avatar={<Avatar alt='Reminder' style={{width: 32, height: 32}}><FASolidIcon icon='pencil-alt' noMargin buttonPrimary /></Avatar>}
                                                                            label={task.task_reminder_datetime ? moment(task.task_reminder_datetime).format("DD/MM/YYYY HH:mm") : 'Add Reminder'}
                                                                            clickable={!readOnly && task.task_status !== "Completed"}
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                                type="chip"
                                                                disabled={readOnly || task.task_status === "Completed"}
                                                            >
                                                                <FormControl fullWidth>
                                                                    <AutoCompleteSelect
                                                                        options={[
                                                                            {label: 'None', value: 0},
                                                                            {label: 'Date & Time', value: 1},
                                                                        ]} 
                                                                        value={updateData.taskReminder}
                                                                        onChange={this.handleReminderChange}
                                                                        noClear
                                                                        autoFocus
                                                                    />
                                                                </FormControl>
                                                            </Editable>
                                                        )
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems='center' align='center'>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" component="div">
                                                    Assigned To
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {task.assignee && (
                                                    <Editable
                                                        text={((readOnly || task.task_status === "Completed") && (
                                                            <Chip
                                                                avatar={<Avatar alt={`${task.assignee.staff_first_name} ${task.assignee.staff_last_name}`} src={task.assignee.staff_profile_photo} style={{width: 32, height: 32}} />}
                                                                label={`${task.assignee.staff_first_name} ${task.assignee.staff_last_name}`}
                                                                className={classes.chip}
                                                                clickable={false}
                                                            />
                                                        )) || (
                                                            <Tooltip title="Re-assign Task">
                                                                <Chip
                                                                    avatar={<Avatar alt={`${task.assignee.staff_first_name} ${task.assignee.staff_last_name}`} style={{width: 32, height: 32}}><FASolidIcon icon='pencil-alt' noMargin buttonPrimary /></Avatar>}
                                                                    label={`${task.assignee.staff_first_name} ${task.assignee.staff_last_name}`}
                                                                    className={classes.chip}
                                                                    clickable={!readOnly && task.task_status !== "Completed"}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                        type="chip"
                                                        disabled={readOnly || task.task_status === "Completed"}
                                                    >
                                                        <FormControl fullWidth>
                                                            <AutoCompleteSelect
                                                                options={staffList} 
                                                                value={updateData.taskAssign}
                                                                onChange={(v) => this.handleUpdateSelectChange('taskAssign', v)}
                                                                disabled={readOnly}
                                                                disableSort
                                                                noClear
                                                                autoFocus
                                                            />
                                                        </FormControl>
                                                    </Editable>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {(!_.isEmpty(task.watchers) || (task.task_status !== "Completed" &&!readOnly)) && !task.task_private && (
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignItems='center' align='center'>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" component="div">
                                                        Watching
                                                    </Typography>
                                                </Grid>
                                                {(!readOnly && task.task_status !== "Completed" && (
                                                    <Grid item xs={12}>
                                                        <Editable
                                                            text={(<React.Fragment>
                                                                {!_.isEmpty(task.watchers) && _.map(task.watchers, staff => (
                                                                        <Chip
                                                                            avatar={<Avatar alt={`${staff.staff_first_name} ${staff.staff_last_name}`} src={staff.staff_profile_photo} style={{width: 32, height: 32}} />}
                                                                            label={`${staff.staff_first_name} ${staff.staff_last_name}`}
                                                                            className={classes.chip}
                                                                            key={staff.staff_id}
                                                                            style={{marginBottom:24}}
                                                                        />
                                                                    ))}
                                                                    <Tooltip title="Manage Watchers">
                                                                        <Chip
                                                                            avatar={<Avatar alt='Manage Watchers' style={{width: 32, height: 32}}><FASolidIcon icon="pencil-alt" noMargin buttonPrimary /></Avatar>}
                                                                            label='Manage Watchers'
                                                                            className={classes.chip}
                                                                            clickable={true}
                                                                        />
                                                                    </Tooltip>
                                                                </React.Fragment>
                                                            )}
                                                            type="textarea"
                                                            onChange={this.handleUpdateTask}
                                                        >
                                                            <FormControl fullWidth>
                                                                <AutoCompleteMultiSelect 
                                                                    options={_.filter(staffList, function (el) { return el.value !== updateData.taskAssign})} 
                                                                    value={updateData.taskWatchers}
                                                                    onChange={(v) => this.handleMultiSelectChange('taskWatchers', v)}
                                                                    autoFocus
                                                                />
                                                            </FormControl>
                                                        </Editable>
                                                    </Grid>
                                                )) || (
                                                    <Grid item xs={12}>
                                                        {_.map(task.watchers, staff => (
                                                            <Chip
                                                                avatar={<Avatar alt={`${staff.staff_first_name} ${staff.staff_last_name}`} src={staff.staff_profile_photo} style={{width: 32, height: 32}} />}
                                                                label={`${staff.staff_first_name} ${staff.staff_last_name}`}
                                                                className={classes.chip}
                                                                key={staff.staff_id}
                                                                style={{marginBottom:24}}
                                                            />
                                                        ))}
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} alignItems='center' align='center'>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" component="div">
                                                    About Task
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {task.task_status === "Completed" && (
                                                    <Typography variant="caption" component="div" paragraph>
                                                        Completed by {`${task.assignee.staff_first_name} ${task.assignee.staff_last_name}`}<br />{moment(task.task_completed_datetime).format("DD/MM/YYYY HH:mm")}
                                                    </Typography>
                                                )}
                                                <Typography variant="caption" component="div">
                                                    Created by {`${task.author.staff_first_name} ${task.author.staff_last_name}`}<br />{moment(task.task_created_datetime).format("DD/MM/YYYY HH:mm")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <ConfirmationDialog 
                        open={confirmation.archive} 
                        success={() => this.handleConfirmationSuccess('archive')} 
                        close={() => this.handleConfirmationClose('archive')} 
                        title={`Archive Task?`} 
                        message={`Are you sure you want to archive this task?`}
                    />
                    <ConfirmationDialog 
                        open={confirmation.delete} 
                        success={() => this.handleConfirmationSuccess('delete')} 
                        close={() => this.handleConfirmationClose('delete')} 
                        title={`Delete Task?`} 
                        message={`Are you sure you want to delete this task?`}
                    />
                </React.Fragment>
            )
        )
    }
}

function mapStateToProps(state){
    return {
        ui: state.ui,
        loggedInStaff: state.staffAuth.staff
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployDialog: (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),        
        closeDialog: () => dispatch(closeDialog())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TaskDialog))