import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import AutoCompleteMultiSelect from '../../Common/Selects/AutoCompleteMultiSelect';
import { convertFileUploadsName } from  './../../../Helpers/DocumentHelper';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import { Link, Redirect } from 'react-router-dom';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        associatedParts: [],
        associatedPickingParts: [],
        category: '',
        subCategory: '',
        title: '',
        fileUploadsDisplay: '',
        actualDocStatus: '',
        currentStatus: '',
        versionMatch: '',
        technicalLibraryAccess: ''
    },
    partsList: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: true,
    redirect: false
}

class UpdateDocumentParts extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getDocumentDetails(this.props.match.params.id);
    }

    getParts = () => {        
        API.get('/parts/all')
        .then((result) => {
            let partsList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description
                });
            });
            this.setState({
                partsList: partsList
            });
        })
    }

    getDocumentDetails = (documentId) => {
        API.get(`/documents/${documentId}`)
        .then((result) => {
            if (result.data.errors) {
                this.setState({ redirect: true })
            } else if (result.data) {
                this.getParts();
                const fileUploads = convertFileUploadsName(result.data.doc_file_uploads);
                let actual_document_status  = result.data.latest_update_order && result.data.latest_update_order.duo_version > 1 ? result.data.latest_update_order.duo_version : 1;
                let current_status       = result.data.latest_file && result.data.latest_file.file_version > 1 ? result.data.latest_file.file_version : 1;
                let associatedParts = [];
                let currentAssociatedParts = [];
                result.data.picking_parts.forEach(part => {
                    currentAssociatedParts.push(<Typography variant="body2" gutterBottom key={part.part_number}>{part.part_number + ' - ' + part.part_description}<br></br></Typography>);
                    associatedParts.push(part.part_id);
                });
                
                this.setState({
                    documentId: documentId,
                    formData: {
                        ...this.state.formData,
                        category: result.data.category.parent.doc_cat_name,
                        subCategory: result.data.category.doc_cat_name,
                        title: result.data.doc_title,
                        fileUploadsDisplay: fileUploads,
                        actualDocStatus:actual_document_status,
                        currentStatus: current_status,
                        versionMatch: (actual_document_status === current_status) ? 'Yes' : 'No',
                        associatedPickingParts: associatedParts,
                        currentAssociatedParts : currentAssociatedParts,
                        technicalLibraryAccess: result.data.doc_technical_library_access,
                    },
                    isLoading: false
                });
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    }

    submit = () => {
        API.post(`/documents/${this.state.documentId}/updatePickingParts`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.getDocumentDetails(this.state.documentId);
                this.getParts();
            }
            this.props.scrollToTop();
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    }

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions && selectedOptions.map(a => a.value);
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }
       
    render() {
        const { formErrors, formData, isLoading } = this.state;
        if (this.state.redirect) {
            return <Redirect to='/documents/search'/>
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Update Linked Part Files
                        </Typography>
                    </Grid>
                    {(isLoading && (
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <LoadingCircle />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>                    
                    )) || (
                        <React.Fragment>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Current Linked Part Files
                                        </Typography>
                                        {formErrors && formErrors.generic && (
                                            <React.Fragment>
                                                <Typography component={"div"} style={{color: colors.red}}>
                                                    {formErrors.generic}
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                        {formData.currentAssociatedParts && formData.currentAssociatedParts.length > 0 ? formData.currentAssociatedParts  : '-'}
                                        <br></br>
                                        <form autoComplete="off" noValidate>
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteMultiSelect 
                                                    options={this.state.partsList} 
                                                    label='Update Linked Part Files'
                                                    value={this.state.formData.associatedPickingParts}
                                                    onChange={this.handleMultiSelectChange('associatedPickingParts')}
                                                    error={formErrors && formErrors['associatedParts'] && true}
                                                    errorText={formErrors && formErrors['associatedParts']}
                                                />
                                            </FormControl>
                                            <div className='buttonRow'>
                                                <BackButton props={this.props} />
                                                <Button onClick={this.handleConfirmationOpen}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                    Update
                                                </Button>
                                            </div>
                                        </form>
                                        <ConfirmationDialog 
                                            open={this.state.confirmationOpen} 
                                            success={this.handleConfirmationSuccess} 
                                            close={this.handleConfirmationClose} 
                                            title="Update Linked Part Files?" 
                                            message="Are you sure you want to update the Linked Part Files?"
                                        />
                                        <SnackBar
                                            variant="success"
                                            anchorOriginVertical='bottom'
                                            anchorOriginHorizontal='right'
                                            open={this.state.snackbarOpen}
                                            onClose={this.handleSnackbarClose}
                                            message='You have successfully updated the Linked Part Files'
                                        />
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Document Details
                                        </Typography>
                                        <form autoComplete="off" noValidate>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="category" shrink={!!formData.category}>Category</InputLabel>
                                                <Input id="category" value={formData.category || ''} />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="subCategory" shrink={!!formData.subCategory}>Sub Category</InputLabel>
                                                <Input id="subCategory" value={formData.subCategory || ''} />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="title" shrink={!!formData.title}>Title</InputLabel>
                                                <Input id="title" value={formData.title || ''} />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="fileUploads" shrink={!!formData.fileUploadsDisplay}>File Uploads</InputLabel>
                                                <Input id="fileUploads" value={formData.fileUploadsDisplay || ''} />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="actualDocStatus" shrink={!!(formData.actualDocStatus)}>Actual Doc Status (Based on DUO)</InputLabel>
                                                <Input id="actualDocStatus" value={(formData.actualDocStatus && `v${formData.actualDocStatus}`) || ''} />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="currentStatus" shrink={!!(formData.currentStatus)}>Current Status of Docs</InputLabel>
                                                <Input id="currentStatus" value={(formData.currentStatus && `v${formData.currentStatus}`) || ''} />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="versionMatch" shrink={true}>Version Match (DUO &amp; Docs)</InputLabel>
                                                <Input id="versionMatch" value={formData.versionMatch} style={{color: formData.versionMatch === 'Yes' ? colors.green : colors.red}} />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="technicalLibraryAccess" shrink={true}>Available in Technical Library</InputLabel>
                                                <Input id="technicalLibraryAccess" value={formData.technicalLibraryAccess} />
                                            </FormControl>
                                        </form>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            );
        }
    }
}

export default UpdateDocumentParts;