import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class SupplierDetails extends PureComponent {
    
    render() {
        const { supplierDetails } = this.props;
        const loggedInStaff = this.props.loggedInStaff !== undefined ? this.props.loggedInStaff.firstName + ' ' + this.props.loggedInStaff.lastName : '';
        return (
            <PaddedPaper>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1">
                            Company
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="subtitle1">
                            {supplierDetails.company}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1">
                            Address
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="subtitle1">
                            {supplierDetails.address.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br/></span>
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1">
                            Order Placed By
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="subtitle1">
                            {loggedInStaff}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1">
                        Payment Terms
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="subtitle1">
                            {supplierDetails.paymentTerms}
                        </Typography>
                    </Grid>
                </Grid>
            </PaddedPaper>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
        loading: {
            ...state.loading
        }
    };
}

export default connect(mapStateToProps)(SupplierDetails);
    