import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import DatePicker from '../../Common/DatePickers/DatePicker';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';

class PurchaseOrderDetails extends PureComponent {
    
    render() {
        const { supplierList, supplierContactList, type, supplier, fao, faoEmail, orderDate, purchaseOrderDate, jobReference, attendanceDate, formErrors, handleSelectChange, handleDateChange, handleChange } = this.props;
        return (
            <form noValidate autoComplete="off">
                <Typography variant="h6">
                    Purchase Order Details
                </Typography>
                <FormControl fullWidth margin="normal">
                    <AutoCompleteSelect 
                        options={supplierList} 
                        label='Supplier *'
                        value={supplier}
                        onChange={handleSelectChange('supplier')}
                        error={formErrors && formErrors['supplier'] && true}
                        errorText={formErrors && formErrors['supplier']}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <AutoCompleteSelect
                        name="fao"
                        options={supplierContactList} 
                        label='FAO *'
                        value={fao}
                        onChange={handleSelectChange('fao')}
                        error={formErrors && formErrors['fao'] && true}
                        errorText={formErrors && formErrors['fao']}
                    />
                </FormControl>
                <TextField
                    id="faoEmail"
                    name="faoEmail"
                    label="FAO Email *"
                    value={faoEmail}
                    error={formErrors && formErrors['faoEmail'] && true}
                    helperText={formErrors && formErrors['faoEmail']}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                    disabled
                />
                <DatePicker
                    type="date"
                    id="purchaseOrderDate"
                    name="purchaseOrderDate"
                    label="Purchase Order Date *"
                    value={purchaseOrderDate}
                    errorText={formErrors && formErrors['purchaseOrderDate']}
                    onChange={handleDateChange('purchaseOrderDate')}
                    autoOk={true}
                />
                <DatePicker
                    type="date"
                    id="orderDate"
                    name="orderDate"
                    label="Order Date *"
                    value={orderDate}
                    errorText={formErrors && formErrors['orderDate']}
                    onChange={handleDateChange('orderDate')}
                    autoOk={true}
                />
                {type === 1 &&
                <React.Fragment>
                    <DatePicker
                        type="date"
                        id="attendanceDate"
                        name="attendanceDate"
                        label="Attendance Date *"
                        value={attendanceDate}
                        errorText={formErrors && formErrors['attendanceDate']}
                        onChange={handleDateChange('attendanceDate')}
                        autoOk={true}
                    />
                    <TextField
                        id="jobReference"
                        name="jobReference"
                        label="Job Reference *"
                        value={jobReference}
                        error={formErrors && formErrors['jobReference'] && true}
                        helperText={formErrors && formErrors['jobReference']}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                    />
                </React.Fragment>
                }
            </form>
        );
    }
}

export default PurchaseOrderDetails;
    