import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';

import WriteOffReasons   from './WriteOffReasons';
import AdditionalReasons from './AdditionalReasons';
import ReturnReasons     from './ReturnReasons';

const initialState = {
    formData: {
        reason: ''
    },
    reasons: {},
    formErrors: {},
    snackbarOpen: false,
    confirmationOpen: false,
    access: {
        addReason: false
    },
    isLoading: true
}

class WorksOrderReasons extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Works Order Reasons
                    </Typography>
                </Grid>         
                <Grid item xs={12}>
                    <TabBarSwitcher
                        defaultTab='Write Off'
                        tabs={[
                            {
                                label:   'Write Off',
                                content: <WriteOffReasons />,
                            },
                            {
                                label:   'Additional',
                                content: <AdditionalReasons />,
                            },
                            {
                                label:   'Return',
                                content: <ReturnReasons />,
                            },
                        ]}
                    />   
                </Grid>       
            </Grid>
        );
    }
}

export default WorksOrderReasons;