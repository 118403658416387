import React, { Component } from 'react';
import _ from 'lodash';

import { Typography } from '@material-ui/core';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import AllIcon from 'Components/Common/Icons/AllIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

class ViewReturn extends Component {
    constructor(props) {
        super(props);
    }
   
    render() {

        const { cr } = this.props;

        let currentStatus = null;

        switch (cr.cr_status) {
            case 'Awaiting Review':
                currentStatus = {
                    title: 'Awaiting Review',
                    color: colors.green,
                    icon:  icons.review,
                    body1: `Customer Return was received by ${_.find(cr.logs, {crl_type: 'Inspection Complete'})?.crl_staff} on ${clenyDate(_.find(cr.logs, {crl_type: 'Inspection Complete'})?.crl_datetime)}`,
                };
                break;
            case 'Awaiting Collection':
                currentStatus = {
                    title: 'Confirmed',
                    color: colors.green,
                    icon:  icons.truck,
                    body1: `Customer Return confirmed by ${_.find(cr.logs, {crl_type: 'Confirmed'})?.crl_staff} on ${clenyDate(_.find(cr.logs, {crl_type: 'Confirmed'})?.crl_datetime)}`,
                };
                break;
            case 'Awaiting Confirmation':
                currentStatus = {
                    title: 'Approved',
                    color: colors.green,
                    icon:  icons.tick,
                    body1: `Customer Return approved by ${_.find(cr.logs, {crl_type: 'Approved'})?.crl_staff} on ${clenyDate(_.find(cr.logs, {crl_type: 'Approved'})?.crl_datetime)}`,
                }
                break;
            case 'Cancelled':
                currentStatus = {
                    title: 'Cancelled',
                    color: colors.red,
                    icon:  icons.false,
                    body1: `Customer Return cancelled by ${_.find(cr.logs, {crl_type: 'Cancelled'})?.crl_staff} on ${clenyDate(_.find(cr.logs, {crl_type: 'Cancelled'})?.crl_datetime)}`,
                }
                break;
            case 'Declined':
                currentStatus = {
                    title: 'Declined',
                    color: colors.red,
                    icon:  icons.false,
                    body1: `Customer Return declined by ${_.find(cr.logs, {crl_type: 'Declined'})?.crl_staff} on ${clenyDate(_.find(cr.logs, {crl_type: 'Declined'})?.crl_datetime)}`,
                }
                break;
            case 'Awaiting Outcome':
            case 'Completed':
                currentStatus = {
                    title: 'Completed',
                    color: colors.green,
                    icon:  icons.tick,
                    body1: `Customer Return completed by ${_.find(cr.logs, {crl_type: 'Outcome Added'})?.crl_staff} on ${clenyDate(_.find(cr.logs, {crl_type: 'Outcome Added'})?.crl_datetime)}`,
                }
                break;
            default:
                currentStatus = {
                    title: 'Created',
                    color: colors.green,
                    icon:  icons.plus,
                    body1: `Customer Return created by ${cr.cr_staff_name} on ${clenyDate(cr.cr_date)}`,
                };
                break;
        }

        return (
            <PaddedPaper>
                <Typography variant="h4" gutterBottom style={{color: currentStatus.color}}><AllIcon icon={currentStatus.icon} color={currentStatus.color} size='md' />{currentStatus.title}</Typography>
                <Typography variant="body1">{currentStatus.body1}</Typography>
            </PaddedPaper>
        )
    }
}

export default ViewReturn;
