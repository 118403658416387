import { Box, Grid, Typography } from '@material-ui/core';
import API from 'API';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import _ from 'lodash';
import React from 'react';

const actionToFriendly = {
    'Viewing': 'is viewing this ',
    'Approving': 'is approving this ',
    'Cancelling': 'is cancelling this ',
    'Declining': 'is declining this ',
    'Confirming': 'is confirming this ',
    'Processing Payment': 'is processing a payment for this ',
    'Picking': 'is picking this ',
    'Despatching': 'is despatching this ',
    'Editing': 'is editing this ',
}

const actionToIcon = {
    'Viewing': <AllIcon heavy icon={icons.view} color={colors.green} size='small' />,
    'Approving': <AllIcon heavy icon={icons.warning} color={colors.orange} size='small' />,
    'Declining': <AllIcon heavy icon={icons.warning} color={colors.red} size='small' />,
    'Confirming': <AllIcon heavy icon={icons.warning} color={colors.orange} size='small' />,
    'Processing Payment': <AllIcon heavy icon={icons.warning} color={colors.orange} size='small' />,
    'Cancelling': <AllIcon heavy icon={icons.warning} color={colors.red} size='small' />,
    'Picking': <AllIcon heavy icon={icons.picking} color={colors.orange} size='small' />,
    'Despatching': <AllIcon heavy icon={icons.truck} color={colors.orange} size='small' />,
    'Editing': <AllIcon heavy icon={icons.edit} color={colors.red} size='small' />,
}

const actionToTheme = {
    'Viewing': 'Green',
    'Cancelling': 'Red',
    'Declining': 'Red',
    'Approving': 'Orange',
    'Confirming': 'Orange',
    'Processing Payment': 'Orange',
    'Picking': 'Orange',
    'Despatching': 'Orange',
    'Editing': 'Red',
}

const typeToFriendly = {
    'movement': 'stock movement',
}

const styles = {
    lockBox: {
        border: `1px solid ${colors.red}`,
        backgroundColor: `${colors.red}10`,
        color: colors.red,
        fontWeight: 'bold',
        padding: '1em',
        textAlign: 'center',
    },
    base: {
        border: '1px solid #ccc',
        borderRadius: 5,
        padding: '1em',
        fontWeight: 'bold',
        marginTop: '1em',
    },
    themeGreen: {
        borderColor: colors.green,
        color: colors.green,
        backgroundColor: `${colors.green}10`,
    },
    themeOrange: {
        borderColor: colors.orange,
        color: colors.orange,
        backgroundColor: `${colors.orange}10`,
    },
    themeRed: {
        borderColor: colors.red,
        color: colors.red,
        backgroundColor: `${colors.red}10`,
    }
};

class StaffActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = { actions: [] };
        this.timer = null;
    }

    componentDidMount() {
        this.startActions();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        this.endActions();
    }

    startActions = () => {
        const { type, id, action } = this.props;
        API.post(`/staff/actions/start`, { action, type, typeId: id }, {props: {noLoading: true}}).then(this.getActions);
    }

    endActions = () => {
        const { type, id } = this.props;
        API.post(`/staff/actions/end`, { type, typeId: id }, {props: {noLoading: true}});
    }

    getActions = () => {
        const { type, id } = this.props;
        API.get(`/staff/actions`, {props: {noLoading: true}, params: { type, typeId: id }}).then(res => {
            this.setState({actions:res.data.s}, ()=>{
                this.timer = setTimeout(this.startActions, 5000)
                if (this.props.lockCallback){
                    if (res.data.l || (this.props.lockWithView && res.data.v)){
                        this.props.lockCallback(this.getLockBlock())
                    } else {
                        this.props.lockCallback(null)
                    }
                }
            });
        });
    }

    getLockBlock = () => {
        const { type } = this.props;
        let _action = _.find(this.state.actions, i => this.props.lockWithView || i.t !== 'Viewing').t;
        return (
            <Box className={`${styles.lockBox} ${styles[`theme${actionToTheme[_action]}`]}`}>
                <AllIcon icon={icons.lock} buttonPrimary color={actionToTheme[_action].toLowerCase()} style={{paddingBottom: '0.3em'}} size='large' />
                <Typography variant='body1' style={{marginLeft: 5}}>Options unavailable while {typeToFriendly[type]} is {_action}</Typography>
            </Box>
        )
    }

    render() {
        const { actions }  = this.state;
        const { type } = this.props;
        if (actions.length === 0) return <></>;
        return (
            <Grid item xs={12}>
                {
                    _.map(actions, (action, index) => <>
                        <Box key={index} display="flex" alignItems="center" style={{...styles.base, ...styles[`theme${actionToTheme[action.t]}`]}}>
                            {actionToIcon[action.t]}{action.s} {actionToFriendly[action.t]} {typeToFriendly[type]}
                        </Box>
                    </>)
                }
            </Grid>
        )
    }
}

export default StaffActions;


