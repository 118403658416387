import React from "react";

import { Grid } from "@material-ui/core";
import InfoBox from 'Components/Common/reports/InfoBox';
import IconHelper from "Helpers/IconHelper";

class Overview extends React.Component {

    render () {

        const { campaign } = this.props;

        return (
            <Grid container>
                <Grid item xs={12} container spacing={3}>
                    <InfoBox 
                        title='Mailing Lists'
                        value={campaign?.active_mailing_lists?.length ?? 0}
                        icon={IconHelper.mailingList}
                        rowSize={4}
                    />
                    <InfoBox 
                        title='Contacts'
                        value={campaign?.ec_valid_contacts ?? 0}
                        icon={IconHelper.contacts}
                        rowSize={4}
                    />
                    <InfoBox 
                        title='Runs'
                        value={campaign?.runs?.length ?? 0}
                        icon={IconHelper.emailRuns}
                        rowSize={4}
                    />
                    <InfoBox 
                        title='Schedule'
                        value={campaign?.scheduled?.length ?? 0}
                        icon={IconHelper.day}
                        rowSize={4}
                    />
                </Grid>
            </Grid>
        )
    }

}

export default Overview;