import React from 'react';
import Typography from '@material-ui/core/Typography';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import Grid from '@material-ui/core/Grid';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import API from '../../../API';
import _ from 'lodash';
import { connect } from 'react-redux';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import TransactionDialog from './TransactionDialog';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import moment from 'moment';
import UnassignedBankTransactions from './UnassignedBankTransactions'
import AssignedBankTransactions from './AssignedBankTransactions'
import { downloadS3File } from 'Functions/MiscFunctions';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';
import { Alert } from '@material-ui/lab';

const initialState = () => ({
    formData: {
        transId: '',
        plaidDate: '',
        plaidAccountOwner: '',
        plaidAmount: '',
        plaidDescription: '',
        transactionRows:[{
            description: '',
            editNominal: true,
            nominalCode: '',
            net: '',
            vat: '',
            gross: '',
            existingNominalLabel: '',
            existingNominalId: '',
            existingNominalName: ''
        }],
        status: '',
        overallNet: '',
        overallVat: '',
        overallAmount: '',
        file:'',
        deleteTransactionId: '',
        assignedTo: '',
        receiptDate: null,
        statementDate: null,
        bms: ''
    },
    unassigned: false,
    formErrors: {},
    access: {
        allStaffTransactions: false
    },
    nominalList: [],
    staffList:[],
    statementList: [],
    transactionsAuthorisation: [],
    transactionsConfirmation: [],
    assignedTransactions: [],
    dialogTransactionOpen: false,
    isLoading: false,
    currentTab: 0,
    bankDetails: {},
    statementDetails: {},
    paymentDetails: {
        receiptFileUrl: '',
        confirmedBy: '',
        authorisedBy: ''
    },
    deleteOpen: false,
    selectedRowId: 0
});
class BankTransactions extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `BankTransactions:${this.props.match.params.id}`;
        this.state              = this.getInitialState(initialState());
    }
    componentDidMount(){
        this.setState({
            formData: initialState().formData,
        })
        this.loadInitialData();
    }

    loadInitialData = () => {
        if(this.props.match.url.includes("unassigned-bank-transactions")){
            this.loadDataUnassigned(this.props.match.params.id);
        } else {
            this.loadDataAssigned(this.props.match.params.id);
        }
        this.checkAccess();
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/unassigned-bank-transactions:all-staff'),
        ])
        .then(([staffRes]) =>  {
            staffRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        allStaffTransactions: staffRes.data.has_access
                    }
                });
        });
    }

    loadDataAssigned = (bmsId, openDialog = false) => {
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([ 
                API.get(`/accounts/banks/monthlyStatements/${bmsId}`),
                API.post('/accounts/banks/transactions', {'bmsId': bmsId}),
                API.get('/accounts/nominalCodes'),
                API.get('/staff/all', { params: { active: true } })
            ])
            .then(([statementRes, transRes, nomRes, staffRes]) =>  {

                let assignedTransactions = {};
                _.map(transRes.data, (el) => {
                    el.staffFullName = el.staff?.full_name;
                });
                assignedTransactions   = _.orderBy(transRes.data, 'bank_trans_date');

                // Nominal Codes
                let nominalList = [];
                nominalList = _.map(nomRes.data, (el) => {
                    return _.assign({
                        value: el.nominal_id,
                        label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`
                    });
                });
                // Staff
                let staffList = _.map(staffRes.data, (staff) => {
                    return _.assign({
                        value: staff.staff_id,
                        label: staff.staff_first_name + ' ' + staff.staff_last_name
                    });
                });
                // Set State
                this.setState({
                    statementDetails: statementRes.data,
                    assignedTransactions,
                    nominalList: nominalList,
                    staffList,
                    formErrors: {},
                    isLoading: false
                },
                () => {
                    API.get(`/accounts/banks/${this.state.statementDetails?.bms_bank_id}/monthlyStatementsByStatus`, { params: { status: 'Open' } }) 
                    .then(statementRes =>  {
                        if(statementRes.data){
                            let statementList = _.map(statementRes.data, (el) => {
                                return _.assign({
                                    value: el.bms_id,
                                    label: el.bms_month + ' ' + el.bms_year
                                });
                            });
                            this.setState({statementList});
                        }
                    });

                    if(openDialog){
                        let selectedRow = _.find(this.state.assignedTransactions, el => el.bank_trans_id == this.state.selectedRowId);
                        this.handleDialogTransactionOpen(selectedRow, "Completed");
                    }
                });
            });
        });
    }

    loadDataUnassigned = (bankId, openDialog = false) => {
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([ 
                API.get(`/accounts/banks/${bankId}`),
                API.get(`/accounts/banks/${bankId}/unassignedTransactions`),
                API.get('/accounts/nominalCodes'),
                API.get('/staff/all', { params: { active: true } }),
                API.get(`/accounts/banks/${bankId}/monthlyStatementsByStatus`, { params: { status: 'Open' } }) 
            ])
            .then(([bankRes, transRes, nomRes, staffRes, statementRes]) =>  {

                let nominalList = [];
                let authorisation = {};
                let confirmation = {};
                let completed = {};

                _.map(transRes.data, (el) => {
                    el.staffFullName = el.staff?.full_name;
                });

                // Transactions
                authorisation   = _.orderBy(_.filter(transRes.data, {'bank_trans_status': 'Authorisation'}), 'bank_trans_date');
                confirmation    = _.orderBy(_.filter(transRes.data, {'bank_trans_status': 'Confirmation'}), 'bank_trans_date');
                completed       = _.orderBy(_.filter(transRes.data, {'bank_trans_status': 'Completed'}), 'bank_trans_date');

                // Transactions By Staff
                let authorisationByStaff    = _.groupBy(authorisation, (el) => {return el.staff?.full_name});

                // Nominal Codes
                nominalList = _.map(nomRes.data, (el) => {
                    return _.assign({
                        value: el.nominal_id,
                        label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`
                    });
                });
                // Staff
                let staffList = _.map(staffRes.data, (staff) => {
                    return _.assign({
                        value: staff.staff_id,
                        label: staff.staff_first_name + ' ' + staff.staff_last_name
                    });
                });
                let statementList = _.map(statementRes.data, (el) => {
                    return _.assign({
                        value: el.bms_id,
                        label: el.bms_month + ' ' + el.bms_year
                    });
                });
                // Set State
                this.setState({
                    bankDetails: bankRes.data,
                    transactionsAuthorisation: authorisation,
                    transactionsConfirmation: confirmation,
                    transactionsCompleted: completed,
                    transactionsAuthorisationByStaff: authorisationByStaff,
                    nominalList: nominalList,
                    staffList,
                    formErrors: {},
                    isLoading: false,
                    unassigned: true,
                    statementList
                },
                () => {

                    if(openDialog){
                        let selectedRow = _.find(this.state.transactionsCompleted, el => el.bank_trans_id == this.state.selectedRowId);
                        this.handleDialogTransactionOpen(selectedRow, "Completed");
                    }
                });
            });
        });
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        }, ()=>this.savePageState());
    };

    handleAddRow = t => {
        const item = initialState().formData.transactionRows[0];
        this.setState({
            formData:{
                ...this.state.formData,
                transactionRows: [...this.state.formData.transactionRows, item]
            }
        },
        () => {
            this.calculateOverallAmount();
        });
    }
    handleRemoveRow = (idx) => () => {
        let rows = [...this.state.formData.transactionRows]
        rows.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                transactionRows: rows
            }
        },
        () => {
            this.calculateOverallAmount();
        });
    }

    calculateOverallAmount = () => {
        let overallNet = 0;
        let overallVat = 0;
        let overallAmount = 0;
        this.state.formData.transactionRows.forEach((row) => {
            if(!isNaN((row.net*1))){
                overallNet = overallNet + (row.net*1);
            }
            if(!isNaN((row.vat*1))){
                overallVat = overallVat + (row.vat*1);
            }
            if(!isNaN((row.gross*1))){
                overallAmount = overallAmount + (row.gross*1);
            }
        });
        this.setState({
            formData: {
                ...this.state.formData,
                overallNet: overallNet.toFixed(2),
                overallVat: overallVat.toFixed(2),
                overallAmount: overallAmount.toFixed(2)
            }
        });
    }

    calculateRowAmount = (idx) => {
        let newArray =  [...this.state.formData.transactionRows];
        let net     = (!newArray[idx].net || isNaN(newArray[idx].net)) ? 0 : parseFloat(newArray[idx].net).toFixed(2);
        let vat     = (!newArray[idx].vat || isNaN(newArray[idx].vat)) ? 0 : parseFloat(newArray[idx].vat).toFixed(2);
        let gross   = (parseFloat(net) + parseFloat(vat)).toFixed(2);

        newArray[idx] = {
            ...newArray[idx],
            gross
        };
        this.setState({
            formData: {
                ...this.state.formData,
                transactionRows: newArray 
            }
        },
        () => {
            this.calculateOverallAmount();
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value,
            }
        });
    };

    handleRowChange = (idx, decimals) => e => {
        const { name, value, checked } = e.target;
        let newArray    =  [...this.state.formData.transactionRows];
        let newVal      = name === 'editNominal' ? checked : ((decimals && !isNaN(value) && value !='') ? parseFloat(value).toFixed(decimals) : value);

        newArray[idx] = {
            ...newArray[idx],
            [name]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                transactionRows: newArray 
            }
        },
        () => {
            if(name == 'net' || name == 'vat'){
                this.calculateRowAmount(idx);
            }
        });
    };

    handleSelectRowChange = (idx, fieldName, array) => selectedOption => {
        let data =  [...this.state.formData[array]];
        let newVal = selectedOption && selectedOption.value;

        data[idx] = {
            ...data[idx],
            [fieldName]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                [array]: data
            }
        });
    }
    // Files
    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }
    clearFile = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                file: initialState().formData.file
            }
        });
    };

    // Dialog
    handleDialogTransactionOpen = (rowData, status) => {
        let transactionRows = [];
        if(rowData.children && rowData.children.length > 0){
            transactionRows = _.map(rowData.children, (el) => {
                return _.assign({
                    transId: el.bank_trans_id,
                    description: el.bank_trans_description,
                    editNominal:false,
                    nominalCode: el.bank_trans_nominal_id,
                    net: el.bank_trans_net,
                    vat: el.bank_trans_vat, 
                    gross: el.bank_trans_gross,
                    existingNominalLabel: el.nominal_code && `${el.nominal_code.nominal_code} - ${el.bank_trans_nominal_name} (${el.nominal_code.category.nom_cat_name})`,
                    existingNominalId: el.nominal_code && el.nominal_code.nominal_id,
                    existingNominalName: el.bank_trans_nominal_name
                });
            });

        }
        else {
            transactionRows = [{
                transId: rowData.bank_trans_id,
                description: rowData.bank_trans_description,
                editNominal:false,
                nominalCode: rowData.bank_trans_nominal_id,
                net: rowData.bank_trans_net,
                vat: rowData.bank_trans_vat,
                gross: rowData.bank_trans_gross,
                existingNominalLabel: rowData.nominal_code && `${rowData.nominal_code.nominal_code} - ${rowData.bank_trans_nominal_name} (${rowData.nominal_code.category.nom_cat_name})`,
                existingNominalId: rowData.nominal_code && rowData.nominal_code.nominal_id,
                existingNominalName: rowData.bank_trans_nominal_name
            }]
        }

        this.setState({
            dialogTransactionOpen: true,
            formData: {
                ...this.state.formData,
                transId: rowData.bank_trans_id,
                plaidDate: rowData.bank_trans_date,
                plaidAccountOwner: rowData.bank_trans_account_owner,
                plaidDescription: rowData.bank_trans_description,
                plaidAmount: rowData.bank_trans_gross,
                transactionRows: transactionRows,
                status: status,
                assignedTo: rowData.staff?.staff_id || '',
                receiptDate: rowData.bank_trans_receipt_date == '0000-00-00' ? null : rowData.bank_trans_receipt_date,
                statementDate: rowData.bank_trans_statement_date == '0000-00-00' ? null : rowData.bank_trans_statement_date,
                bms: rowData.bank_trans_bms_id,
            },
            paymentDetails: {
                ...this.state.paymentDetails,
                receiptFileUrl: rowData.receipt_file_url,
                confirmedBy: rowData.confirmed_by,
                authorisedBy: rowData.authorised_by
            },
            formErrors: {},
            selectedRowId: rowData?.bank_trans_id
        },
        () => {
            this.calculateOverallAmount();
        });
    };
    handleDialogTransactionClose = () => {this.setState({dialogTransactionOpen: false});};
    handleDialogTransactionSuccess = () => {
        this.setState({ dialogTransactionOpen: false });
        this.processTransactionSubmit();
    }
    processTransactionSubmit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            if(key === 'transactionRows'){
                newFormData.append(key, JSON.stringify(this.state.formData[key]))
            }
            else if(key === "receiptDate" || key === "statementDate") {
                newFormData.append(key, this.state.formData[key] ? moment(this.state.formData[key]).format("YYYY-MM-DD") : '');
            } else {
                newFormData.append(key, this.state.formData[key]);
            }
        });
        API.post(`/accounts/banks/transactions/${this.state.formData.transId}/process`, newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogTransactionOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    currentTab: this.state.currentTab
                }, () => {
                    this.props.deploySnackBar("success", "You have successfully updated this transaction");
                    this.loadInitialData();
                    this.checkAccess();
                });
            }
        });
    }

    handleDeleteOpen = (rowData) => {
        this.setState({
            formData:{
                ...this.state.formData,
                deleteTransactionId: rowData.bank_trans_id
            },
            deleteOpen: true,
        });
    }
    handleDeleteClose = () => {
        this.setState({ 
            deleteOpen: false 
        });
    }
    handleDeleteSuccess = () => {
        this.setState({ 
            deleteOpen: false 
        });
        API.post(`/accounts/banks/transactions/${this.state.formData.deleteTransactionId}/delete`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState
                }, () => {
                    this.props.deploySnackBar("success", "You have successfully deleted the transaction");
                    this.loadInitialData();
                    this.checkAccess();
                });
            }
        });
    }

    handleDateChange = fieldName => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: date
            }
        });
    };

    refreshTransaction = () => {
        if(this.props.match.url.includes("unassigned-bank-transactions")){
            this.loadDataUnassigned(this.props.match.params.id, true);
        } else {
            this.loadDataAssigned(this.props.match.params.id, true);
        }
    }

    render() {
        const { isLoading, formErrors, formData, currentTab, transactionsAuthorisation, transactionsConfirmation, transactionsCompleted, bankDetails, nominalList, paymentDetails, transactionsAuthorisationByStaff, staffList, access, statementDetails, assignedTransactions, unassigned, statementList } = this.state;
        const { classes } = this.props;
        const editable = (!statementDetails.bms_status || statementDetails?.bms_status == 'Open') ? true : false;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {unassigned && 'Unassigned'} Bank / Card Transactions
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="info">
                        <Typography>
                            Plaid integration removed on 10th June 2024 - no new transactions will be imported.
                        </Typography>
                    </Alert>
                </Grid>
                {isLoading ? (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                ) : (
                    <>
                    {unassigned ?
                        <UnassignedBankTransactions
                            bankDetails ={bankDetails}
                            currentTab={currentTab}
                            access={access}
                            transactionsAuthorisation={transactionsAuthorisation}
                            transactionsConfirmation={transactionsConfirmation}
                            transactionsCompleted={transactionsCompleted}
                            transactionsAuthorisationByStaff={transactionsAuthorisationByStaff}
                            handleTabChange={this.handleTabChange}
                            handleDialogTransactionOpen={this.handleDialogTransactionOpen}
                            handleDeleteOpen={this.handleDeleteOpen}
                            downloadPresignedFile={downloadS3File}
                            location={this.props.location}
                        />
                    :
                        <AssignedBankTransactions 
                            statementDetails={statementDetails} 
                            access={access}
                            transactions={assignedTransactions}
                            handleDeleteOpen={this.handleDeleteOpen}
                            downloadPresignedFile={downloadS3File}
                            handleDialogTransactionOpen={this.handleDialogTransactionOpen}
                            location={this.props.location}
                        />
                    }
                    <TransactionDialog
                        open={this.state.dialogTransactionOpen}
                        onClose={this.handleDialogTransactionClose}
                        classes={classes}
                        formData={formData}
                        handleDialogTransactionSuccess={this.handleDialogTransactionSuccess}
                        handleRowChange={this.handleRowChange}
                        handleRemoveRow={this.handleRemoveRow}
                        handleAddRow={this.handleAddRow}
                        handleSelectRowChange={this.handleSelectRowChange}
                        handleSelectChange={this.handleSelectChange}
                        nominalList={nominalList}
                        staffList={staffList}
                        formErrors={formErrors}
                        handleFileChange={this.handleFileChange}
                        clearFile={this.clearFile}
                        downloadPresignedFile={downloadS3File}
                        paymentDetails={paymentDetails}
                        handleDateChange={this.handleDateChange}
                        editable={editable}
                        statementList={statementList}
                        refreshTransaction={this.refreshTransaction}
                    />
                    <ConfirmationDialog 
                        open={this.state.deleteOpen} 
                        success={this.handleDeleteSuccess} 
                        close={this.handleDeleteClose} 
                        title="Delete Transaction?" 
                        message="Are you sure you want to delete this transaction?"
                    />
                    </>
                )}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state)),
    deploySnackBar:     (variant, msg)  => dispatch(deploySnackBar(variant, msg))
})

export default connect(mapStateToProps, mapDispatchToProps)(BankTransactions);