import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import {colors} from 'Helpers/ColourHelper';
import _ from 'lodash';
class CustomerDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.submit = false;
    }

    componentDidMount() {
        if (this.props?.focus) this.submit = setTimeout(() => this.custSelectDropDown?.focus(), 50)
    }

    render() {
        const { formErrors, customerContactList, company, customerContact, handleSelectChange, paymentTerms, address, customerPhone, customerMobile, customerEmail } = this.props;
        
        return (
            <form noValidate autoComplete="off">
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        Customer
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item lg={3}>
                        <Typography variant="subtitle1">
                            Company Name
                        </Typography>
                    </Grid>
                    <Grid item lg={9}>
                        <Typography variant="subtitle1">
                            {company}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item lg={3}>
                        <Typography variant="subtitle1" >
                            Address
                        </Typography>
                    </Grid>
                    <Grid item lg={9}>
                        <Typography variant="subtitle1">
                            {address && address.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br/></span>
                            })}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item lg={3}>
                        <Typography variant="subtitle1">
                            Payment Terms
                        </Typography>
                    </Grid>
                    <Grid item lg={9}>
                        <Typography variant="subtitle1">
                            {paymentTerms ?
                                paymentTerms
                                :
                                <Typography component={"div"} style={{ color: colors.red, fontSize: '0.85rem' }}>
                                    This customer does not have an assigned Payment Term
                                </Typography>
                            }

                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={12}>
                    <FormControl fullWidth margin="normal">
                        <AutoCompleteSelect
                            name="customerContact"
                            options={_.uniqBy(customerContactList, 'value')}
                            label='Customer Contact *'
                            value={customerContact}
                            onChange={handleSelectChange('customerContact')}
                            error={formErrors && formErrors['customerContact'] && true}
                            errorText={formErrors && formErrors['customerContact'] && formErrors['customerContact']}
                            selectRef={select => this.custSelectDropDown = select}
                            noClear
                        />
                    </FormControl>
                </Grid>
                <br></br>
                <Grid container item spacing={3}>
                    <Grid item lg={3}>
                        <Typography variant="subtitle1">
                            Phone Number
                        </Typography>
                    </Grid>
                    <Grid item lg={9}>
                        <Typography variant="subtitle1">
                            {customerPhone && customerMobile ? customerPhone + ' / ' + customerMobile : (customerPhone ? customerPhone : customerMobile)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item lg={3}>
                        <Typography variant="subtitle1">
                            Email Address *
                        </Typography>
                    </Grid>
                    <Grid item lg={9}>
                        <Typography variant="subtitle1">
                            {customerEmail}
                        </Typography>
                    </Grid>
                    {formErrors && formErrors['customerContactEmail'] ?
                        (
                            <Grid item lg={12}>
                                <Typography component={"div"} style={{ color: colors.red, fontSize: '0.75rem' }}>
                                    {formErrors['customerContactEmail']}
                                </Typography>
                            </Grid>
                        )
                        :
                        (customerContact != '' && customerEmail == '-' &&
                            (<Grid item lg={12}>
                                <Typography component={"div"} style={{ color: colors.red, fontSize: '0.75rem' }}>
                                    Customer Email Address cannot be empty
                                </Typography>
                            </Grid>)
                        )
                    }
                </Grid>
            </form>
        );
    }
}

export default CustomerDetails;
