//InvoiceCreditNote
import API from 'API';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Template from 'Components/CustomerReturns/Greenaware/Sections/Template';

import { AppBar, Grid, Tab, Tabs } from '@material-ui/core';


const initialState = {
    currentTab: 0,
    isLoading:  true,
    templates:  []
};

class Greenaware extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getTemplates();
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value,
        });
    };

    getTemplates = () => {
        API.get('/customerReturns/greenaware/templates')
        .then(res => {
            this.setState({
                templates:  res.data,
                isLoading:  false
            })
        });
    }

    render() {
        const { isLoading, currentTab, templates }  = this.state;

        if (isLoading) return (<LoadingCircle />);

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} style={{paddingBottom: 0}}>
                    {templates.length > 1 &&
                        <AppBar position="static" style={{backgroundColor:'white', paddingBottom: 0}}>
                            <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                                {_.map(templates, t => <Tab value={t.gt_id} label={t.gt_template_label} /> )}
                            </Tabs>
                        </AppBar>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Template template={ templates[currentTab]} custId={this.props.customerId} {...this.props}/>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
})

export default connect(null, mapDispatchToProps)(Greenaware);