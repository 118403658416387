import { Button, Grid, TextField, Typography } from '@material-ui/core';
import AllIcon from 'Components/Common/Icons/AllIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import IconHelper from 'Helpers/IconHelper';
import React from 'react';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { connect } from 'react-redux';

import API from 'API';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import BlueTrollyform from './BlueTrollyform';
import { act } from 'react';
import BlueTrollyCard from './BlueTrollyCard';


const initialState = () => ({
    isLoading: true,
    formData:{
        keyword: '',
    },
    parts: [],
})

class BlueTrollySearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();

        this.timeOut = null;
    }

    componentDidMount() {
        this.getParts();
    }

    componentWillUnmount() {
        clearTimeout(this.timeOut);
    }

    handleAdd = () => {
        this.props.deployDialog(
            <BlueTrollyform
                onClose={()=>{
                    this.getParts()
                    this.props.closeDialog()
                }}
            />,
            'Add Blue Trolley Item'
        )
    }

    editPart = (part) => {
        this.props.deployDialog(
            <BlueTrollyform
                partId={part.i}
                formData={part}
                onClose={()=>{
                    this.getParts()
                    this.props.closeDialog()
                }}
            />,
            'Update Blue Trolley Item'
        )
    }

    viewPart = (part) => {
        this.props.deployDialog(
            <BlueTrollyCard 
                part={part}
                OnClose={this.props.closeDialog}
            />,
            'View Blue Trolley Item'
        )
    }

    getParts = () => {
        API.get('/stock/blueTrolly',{params: this.state.formData}).then(res => {
            this.setState({
                parts: res.data,
                isLoading: false
            })
        })
    }

    archivePart = (part) => {
        this.props.deployConfirmation(
            'Are you sure you want to archive this part?',
            'Archive Part',
            () => {
                API.post(`/stock/blueTrolly/${part.i}/archive`).then(res => {
                    this.props.deploySnackBar('success', 'Part archived successfully');
                    this.getParts();
                })
            }
        )
    }

    searchParts = (e) => {
        clearTimeout(this.timeOut);
        this.setState({formData: {...this.state.formData, keyword: e.target.value}}, () => {
            this.timeOut = setTimeout(this.getParts, 500);
        });
    }

    render() {

        const { parts, isLoading } = this.state;

        if (isLoading) {
            return <LoadingCircle />
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>  
                        Blue Trolley Stock
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={3} style={{alignItems:'center'}}>
                            <Grid item xs={4}>
                                <TextField 
                                    placeholder="Search"
                                    value={this.state.formData.keyword}
                                    onChange={this.searchParts}
                                    fullWidth
                                />
                            </Grid> 
                            <Grid item style={{marginLeft: 'auto'}} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={this.handleAdd}
                                >
                                    <AllIcon icon={IconHelper.plus} white />
                                    Add Blue Trolley Item
                                </Button>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}> 
                    <PaddedPaper>
                        <CiDataTable
                            config={{
                                key: 'i',
                            }}
                            rows={parts}
                            columns={[
                                {
                                    heading: '',
                                    field: 'filePath',
                                    fieldFormat: 'image',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Part Number',
                                    field: 'partNumber',
                                    dataRef: 'partNumber',
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Part Description',
                                    field: 'description',
                                    dataRef: 'description',
                                },
                                {
                                    heading: 'supplier',
                                    field: 'supplier',
                                    dataRef: 'supplier'
                                },
                                {
                                    heading: 'Associated Parts',
                                    field: 'associatedPartIds',
                                    dataRef: i=>i.associatedPartIds.length,
                                    fieldFormat: 'count',
                                    alignment: 'center',
                                    sizeToContent: true,
                                },
                                {
                                    actions: i => ([
                                        {
                                            name: 'View',
                                            icon: IconHelper.view,  
                                            onClick: this.viewPart,
                                        },
                                        {
                                            name: 'Edit',
                                            icon: IconHelper.edit,  
                                            onClick: this.editPart,
                                        },
                                        {
                                            name: 'Archive',
                                            icon: IconHelper.bin,  
                                            onClick: this.archivePart,
                                        }
                                    ])
                                }
                            ]}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>

        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar:     (variant, msg)                              => {dispatch(deploySnackBar(variant, msg))},
        deployDialog:       (content, title, variant, size, style=null) => {dispatch(deployDialog(content, title, variant, size, style))},
        closeDialog:        ()                                          => {dispatch(closeDialog())},
        deployConfirmation: (message, title, success)                   => {dispatch(deployConfirmation(message, title, success))}
    }
}

export default connect(null, mapDispatchToProps)(BlueTrollySearch);