import API from 'API';
import React, { Component } from 'react';

import AllIcon from 'Components/Common/Icons/AllIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import { Button, Grid, Typography } from '@material-ui/core';

import MediaTable from 'Components/Common/DataTables/MediaTable';
import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { getPercentage } from 'Helpers/NumberHelper';

const initialState = {
    writeOff:  {},
    isLoading: true
}

class ViewWriteOff extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {this.getWriteOff();}

    getWriteOff = () => {
        API.get(`/parts/writeOffs/${this.props.match.params.id}`)
        .then(res => {
            this.setState({
                writeOff:  res.data, 
                isLoading: false
            })
        })
    }

    render(){
        const {writeOff, isLoading} = this.state;

        if (isLoading) return (<LoadingCircle/>)
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">View Write Off</Typography>
                </Grid>
                <Grid item xs={12} lg={9}>
                    <PaddedPaper>
                        <Grid container spacing={2} xs={12} style={{justifyContent: 'space-between'}}>
                            <Grid item container xs={12} spacing={2} style={{justifyContent: 'flex-start', alignItems: 'center'}}>
                                <Grid item>
                                    {writeOff && writeOff.part?.default_image?.thumbnail_file_path ?
                                        <img src={writeOff.part?.default_image?.thumbnail_file_path} alt={writeOff.part.part_description} style={{maxHeight:60, maxWidth:60}} />
                                    : 
                                        <img src={logo} alt="logo" style={{maxHeight:60, maxWidth:60}}/>
                                    }
                                </Grid>
                                <Grid item >
                                    <Typography variant='h5' style={{textAlign: 'left', marginTop:'auto'}}>{writeOff.part?.part_number}</Typography>
                                    <Typography variant='h5' style={{textAlign: 'left', marginTop:'auto'}}>{writeOff.part?.part_description}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <PaddedPaper style={{height: '100%'}}>
                        <Grid container  style={{fontWeight: 'bold'}}>
                            <Grid item xs={6}>Failed:</Grid>    <Grid item xs={6}>{writeOff.pwo_qty}</Grid>
                            <Grid item xs={6}>Batch Size:</Grid><Grid item xs={6}>{writeOff.pwo_batch_size}</Grid>
                            <Grid item xs={6}>Rate:</Grid>      <Grid item xs={6}>{getPercentage(writeOff.pwo_qty,writeOff.pwo_batch_size)}%</Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container spacing={2} style={{justifyContent: 'center'}}>
                            <Grid item xs={12}>
                                <Typography variant='h5'>Reason: {writeOff.pwo_reason}</Typography> 
                            </Grid>
                            {writeOff.file_url &&
                                <Grid item xs={12} lg={2}>
                                    <img 
                                        src={writeOff.file_url} 
                                        alt="Write Off" 
                                        style={{width: '100%'}}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} lg={writeOff.file_url ? 10 : 12}>
                                <Textarea
                                    margin='none'
                                    value={writeOff.pwo_notes}
                                    style={{width: '100%', height: '100%'}}
                                    label="Notes"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MediaTable
                                    config={{ itemPerRow: 3 }}
                                    media='file_path'
                                    items={writeOff.files}
                                />
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <div className='buttonRow'>
                        <Button variant="outlined" onClick={() => this.props.history.goBack()}>Back</Button>
                        <Button variant="contained" color="primary" onClick={() => this.props.history.push(`/parts/view/${writeOff.part.part_id}`)}><AllIcon icon={icons.parts} color={colors.white} size='small'/>View Part</Button>
                        {writeOff.works_order_write_off &&
                            <Button variant="contained" color="primary" onClick={() => this.props.history.push(`/WorksOrders/view/${writeOff.works_order_write_off.wobwo_wo_id}`)}><AllIcon icon={icons.worksOrder} color={colors.white} size='small'/>View Works Order</Button>
                        } 
                </div>
                </Grid>
            </Grid>
        )
    }
}

export default ViewWriteOff;