 
import React, {Component}  from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';

const styles = theme => ({
    content: {
        display: 'flex',
        alignItems: 'center',
    }
});

class ViewVehicleMiniProfileCard extends Component {
    render() {
        const { classes, vehicleData } = this.props;
        return (               
            <Grid container className={classes.root} spacing={3}>
                <Grid item>
                    <Avatar alt='Vehicle' src={vehicleData?.photo} style={{height: 80, width: 80, margin: 0}}>
                        <FASolidIcon icon='car' size='large' noMargin buttonPrimary />
                    </Avatar>
                </Grid>
                <Grid className={classes.content} item>
                    <div>
                            Vehicle          
                        <Typography variant="body2">                                        
                            {!vehicleData ? 'None' : vehicleData.reg} ({vehicleData && vehicleData.make}{vehicleData && vehicleData.model && ` ${vehicleData.model}`}{!vehicleData && 'Not assigned'})
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        )        
    }
}

export default withStyles(styles)(ViewVehicleMiniProfileCard);