import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import API from 'API';
import AllIcon from 'Components/Common/Icons/AllIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { clenyDate } from 'Helpers/TimeHelper';
import _ from 'lodash';
import React, { Component } from 'react';

const initialState = () => ({
    showUpdateNote: false,
    updateFormData: {
        notes: '',
        type: null
    }
})

const options = [
    { label: 'Created Notes', value: 'Created Notes' },
    { label: 'Approval Notes', value: 'Approval Notes' },
    { label: 'Decline Notes', value: 'Decline Notes' },
    { label: 'General Notes', value: 'Notes' },
]

class Notes extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    handleDelete = (id) => () => {
        API.post(`/customerReturns/returns/${this.props.cr.cr_id}/notes/${id}/remove`).then(this.props.refresh);
    }

    handleShowUpdate = (rowData) => () => {
        this.setState({
            showUpdateNote: rowData.crn_id,
            updateFormData: {
                notes: rowData.crn_note,
                type: rowData.crn_type
            }
        })
    }

    handleSelectChange = name => event => {
        let updateFormData = this.state.updateFormData;
        updateFormData[name] = event.value;
        this.setState({ updateFormData });
    }

    handleChange = name => event => {
        let updateFormData = this.state.updateFormData;
        updateFormData[name] = event.target.value;
        this.setState({ updateFormData });
    }

    handleUpdate = () => {
        API.post(`/customerReturns/returns/${this.props.cr.cr_id}/notes/${this.state.showUpdateNote}/update`, this.state.updateFormData)
        .then(res=>{
            this.setState(initialState(), this.props.refresh);
        });
    }

    render() {
        const { updateFormData } = this.state;
        const { cr, classes } = this.props;
    
        return (
            <Grid container spacing={2}>
                {_.map(
                    _.orderBy(cr.notes, ['crn_datetime'], ['desc']),
                    note => 
                        <Grid item xs={12} key={note.crn_id}>
                            <Card className={classes.paperGrey}>
                                <CardHeader
                                    avatar={<Avatar alt={`${note.staff.staff_first_name} ${note.staff.staff_last_name}`} src={note.staff.staff_profile_photo} />}
                                    title={`${note.staff.staff_first_name} ${note.staff.staff_last_name}`}
                                    subheader={`${clenyDate(note.crn_datetime)} (${note.crn_type})`}
                                    action={
                                        <>
                                            <AllIcon icon='times' size='small' noMargin 
                                                onClick={this.handleDelete(note.crn_id)}
                                            />
                                            <AllIcon icon='pencil' size='small' noMargin
                                                onClick={this.handleShowUpdate(note)}
                                            />
                                        </>
                                    }
                                />
                                <CardContent>
                                    <Typography component="p">
                                        { note.crn_note && note.crn_note.split('\n').map((item, key) => {
                                            return <span key={key}>{item}<br/></span>
                                        })}
                                    </Typography>
                                    {( note.crn_updated_staff_id ) ? (
                                        <CardActions className="float-right pl-2">
                                            <Typography component="p" variant="body2" className="textSecondary">
                                                <small>Updated by {note.crn_updated_staff} at {clenyDate(note.crn_updated_datetime)}</small>
                                            </Typography>
                                        </CardActions>
                                    ) : <></>}
                                </CardContent>
                            </Card>
                        </Grid>
                )}
                {this.state.showUpdateNote &&
                    <Dialog
                        open={this.state.showUpdateNote}
                        onClose={() => this.setState({ showUpdateNote: false })}
                    >
                        <DialogTitle>Update Note</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <AutoCompleteSelect
                                        options={options}
                                        value={updateFormData.type}
                                        onChange={this.handleSelectChange('type')}
                                        label='Note Type *'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Textarea
                                        label='Notes *'
                                        value={updateFormData.notes}
                                        onChange={this.handleChange('notes')}
                                        rows={4}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ showUpdateNote: false })} variant='outlined'>Close</Button>
                            <Button onClick={this.handleUpdate} color="primary" variant='contained' disabled={!updateFormData.type || !updateFormData.notes}>Update</Button>
                        </DialogActions>
                    </Dialog>
                }
            </Grid>
        )
    }
}

const styles = theme => ({
    paperGrey : {
        padding : theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding : theme.spacing(3),
        },
        backgroundColor :'#f8f9fa'
    },
});

export default withStyles(styles)(Notes);
