import React from 'react';

import { MAX_UPLOAD_LIMIT_SIZE } from 'Constants';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';

const PurchaseOrderDetails = ({file, purchaseOrderNumber, formErrors, handleChange, handleFileChange, clearFile, order}) => {
    return(
        <form noValidate autoComplete="off">
            <Grid container spacing={3}>
                <Grid item lg={12}>
                    <Typography variant="h5" gutterBottom>
                        Purchase Order Details
                    </Typography>
                    <TextField 
                        id="purchaseOrderNumber"
                        name="purchaseOrderNumber"
                        value={purchaseOrderNumber}
                        onChange={handleChange}
                        label="Purchase Order Number *"
                        error={formErrors && formErrors['purchaseOrderNumber'] && true}
                        helperText={formErrors && formErrors['purchaseOrderNumber']}
                        margin="normal"
                        fullWidth
                    />
                    <DragFileInput
                        id="file"
                        name="file"
                        label="File"
                        file={file}
                        errorText={formErrors && formErrors['file']}
                        onChange={handleFileChange}
                        cancelOnClick={clearFile}
                        emptyText='No file selected'
                    />
                    <Typography variant="subtitle2" style={{flex: 1}}>
                        <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                    </Typography>
                </Grid>
            </Grid>
        </form>
    );
}

export default PurchaseOrderDetails;