import React, { Component } from 'react';
import _ from 'lodash';
import API from '../../../API';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import { handleSelectChange } from 'Functions/FormFunctions';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from '../../Common/DataTables/CiDataTable';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

const initialState = {
    formData: {
        code:'',
        part:''
    },
    formErrors:[],
    snackbarOpen: false,
    confirmationOpen: false
}

class ModelTypes extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleSelectChange = handleSelectChange.bind(this);
    }

    submit = () => {
        API.post('/parts/serialNumbers/modelTypes', this.state.formData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.props.loadComponentData();
            }
            this.props.scrollToTop();
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    handleConfirmationOpen = () => {
        this.setState({ confirmationOpen: true });
    };

    handleConfirmationClose = () => {
        this.setState({ confirmationOpen: false });
    };

    handleConfirmationSuccess = () => {
        this.setState({ confirmationOpen: false });
        this.submit();
    }

    render(){
        const { formErrors } = this.state;
        const { partList, modelTypes } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Add Model Type
                            </Typography>
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        label='Part *'
                                        options={partList} 
                                        value={this.state.formData.part}
                                        onChange={e => this.handleSelectChange('part', e)}
                                        error={formErrors && formErrors['part'] && true}
                                        errorText={formErrors && formErrors['part']}
                                    />
                                </FormControl>
                                <TextField
                                    id="code"
                                    name="code"
                                    label="Code *"
                                    value={this.state.formData.code}
                                    error={formErrors && formErrors['code'] && true}
                                    helperText={formErrors && formErrors['code']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <div className='buttonRow'>
                                    <Button 
                                        onClick={this.handleConfirmationOpen}
                                        variant="contained" 
                                        color="primary" 
                                        
                                    >
                                        Add
                                    </Button>
                                </div>
                                <ConfirmationDialog 
                                    open={this.state.confirmationOpen} 
                                    success={this.handleConfirmationSuccess} 
                                    close={this.handleConfirmationClose} 
                                    title="Add A New Model Type?" 
                                    message="Are you sure you want to add a new model type?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={this.state.snackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message='You have successfully added a new model type'
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <DataTable  
                        config={{
                            key: 'pmt_id',
                            alternatingRowColours: true,
                            isLoading: this.state.isLoading
                        }}
                        columns={[
                            {
                                heading: 'Code',
                                field: rowData => rowData.pmt_code,
                                sizeToContent: true
                            },
                            {
                                heading: 'Part Number',
                                field: rowData => rowData.part.part_number,
                                sizeToContent: true
                            },
                            {
                                heading: 'Part Description',
                                field: rowData => rowData.part.part_description
                            }
                        ]}
                        rows={_.orderBy(modelTypes, [i=>i.pmt_code], ['asc'])}
                    />
                </Grid>  
            </Grid>     
        );
    }
}

export default ModelTypes;