import React, { Component } from 'react';
import API from 'API';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import ViewReturn from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturn/sections/ViewReturn';

const initialState = {
    isLoading:  true,
    customerReturns:  []
};

class ReplacementFor extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getCustomerReturn();
    }

    getCustomerReturn = () => {
        API.get(`/customerReturns/returns/${this.props.id}`)
        .then(res => {
            this.setState({ 
                cr: res.data, 
                isLoading: false 
            });
        })
    }

    render() {
        const { isLoading, cr }  = this.state;

        if (isLoading) return (<LoadingCircle />);

        return (
            <ViewReturn cr={cr}/>
        )
    }
}

export default ReplacementFor;