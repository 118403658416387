import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { deployDialog }   from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import LoadingCircle  from 'Components/Common/LoadingCircle/LoadingCircle';
import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';

import Template from 'Components/CustomerReturns/Greenaware/Sections/Template';


const initialState = {
    currentTab: 1,
    isLoading:  true,
    templates:  []
};

class Greenaware extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getTemplates();
    }

    getTemplates = () => {
        API.get('/customerReturns/greenaware/templates')
        .then(res => {
            this.setState({
                templates:  res.data,
                isLoading:  false
            })
        });
    }

    render() {
        const { isLoading, templates }  = this.state;

        if (isLoading) return (<LoadingCircle />);

        return (
            <TabBarSwitcher
                nopad
                disableMenu
                tabs={_.map(templates, t => ({
                    label:   t.gt_template_label,
                    content: <Template  template={t} orderId={this.props.id} />
                }))}
            />
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deploySnackBar:     (variant, msg)                          => dispatch(deploySnackBar(variant, msg)),
    deployDialog:       (content, title, variant, size = 'lg')  => dispatch(deployDialog(content, title, variant, size)),
})

export default connect(null, mapDispatchToProps)(Greenaware);