import React, {Component} from 'react';
import _                  from 'lodash';

import { Grid, TextField } from '@material-ui/core';

import AllIcon from 'Components/Common/Icons/AllIcon';

import { colors } from 'Helpers/ColourHelper'; 
import _icons from 'Helpers/IconHelper';

const initialState = {
    icon: ''
}

class SearchInput extends Component {
    constructor(props) {
        super(props);
        this.state = {icon: this.props.icon || '' };
    }

    handleSelect = icon => () => {
        this.setState({ icon });
        this.props.onChange && this.props.onChange(icon);
    }

    render() {

        const { icons, freeType } = this.props;

        return (
            <Grid container spacing={2}>
                {freeType &&
                    <Grid item xs={12} style={{display: 'flex', padding: 0}}>
                        <TextField
                            fullWidth
                            placeholder='Enter Icon Name'
                            value={this.state.icon}
                            onChange={e => this.handleSelect(e.target.value.replace(' ', '-'))()}
                            style={{marginBottom: 0}}
                        />
                        <AllIcon
                            icon={_icons.icons}
                            noMargin
                            onClick={()=>{
                                window.open('https://fontawesome.com/search?o=r&s=solid', '_blank');
                            }}
                        />
                    </Grid>
                }
                {_.map(icons, i => 
                    <Grid item xs={2}>
                        <AllIcon 
                            heavy={i === this.state.icon}
                            color={i === this.state.icon && colors.black}
                            icon={i} size={26} noMargin 
                            onClick={this.handleSelect(i)}    
                            noPadding
                            noHover
                        />
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default SearchInput;
