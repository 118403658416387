import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom'

/* Dashboard */
import Dashboard from 'Components/Warehouse/Dashboard/Dashboard';

/* Sales */
import OrderPicking from 'Components/Warehouse/Sales/Order/OrderPicking/OrderPicking';
import PickOrder    from 'Components/Warehouse/Sales/Order/OrderPicking/PickOrder';

/* Stock */
import Stock            from 'Components/Warehouse/Stock/Stock/Stock';
import StockMovements   from 'Components/Warehouse/Stock/StockMovement/StockMovements/StockMovements';
import StockMovement    from 'Components/Warehouse/Stock/StockMovement/StockMovement/StockMovement';
import StockCorrections from 'Components/Warehouse/Stock/StockCorrection/StockCorrections/StockCorrections';
import StockCorrection  from 'Components/Warehouse/Stock/StockCorrection/StockCorrection/StockCorrection';
import StockTakes       from 'Components/Warehouse/Stock/StockTake/StockTakes/StockTakes';
import StockTake        from 'Components/Warehouse/Stock/StockTake/StockTake/StockTake';

/* Works Orders */
import AllWorksOrder    from 'Components/Warehouse/WorksOrder/AllWorksOrder/AllWorksOrder';
import PickWorksOrder   from 'Components/Warehouse/WorksOrder/PickWorksOrder/PickWorksOrder';
import ShortWorksOrder  from 'Components/Warehouse/WorksOrder/ShortWorksOrder/ShortWorksOrder';
import WorksOrderBuilds from 'Components/Warehouse/WorksOrder/WorksOrderBuilds/WorksOrderBuilds';
import BuildWorksOrder  from 'Components/Warehouse/WorksOrder/WorksOrderBuilds/BuildWorksOrder';

/* Error Handling */
import Error404 from '../../Common/ErrorHandling/Error404';
import { handleAppError } from '../../../Actions/UI/UI';

const initialState = {
    networkOffline: false,
    lastPath: '',
}

class WarehouseRouteSwitcher extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidUpdate(prevProps) {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            this.props.changePath(this.props.location.pathname);
        }
    }
    
    render() {
        const { changePage } = this.props;
        return (
            <Switch>
                {/* Dashboard */}
                <Route exact path='/'                               render={(props) => <Dashboard           {...props} changePage={changePage}/>} />
                <Route exact path='/dashboard'                      render={(props) => <Dashboard           {...props} changePage={changePage}/>} />

                {/* Sales */}
                <Route exact path='/sales/order/picking'            render={(props) => <OrderPicking        {...props} />} />
                <Route exact path='/sales/order/picking/:orderId'   render={(props) => <PickOrder           {...props} />} />

                {/* Stock */}
                <Route exact path='/stock/activity'                  render={(props) => <Stock              {...props} />} />
                <Route exact path='/stock/movement'                  render={(props) => <StockMovements     {...props} />} />
                <Route exact path='/stock/movement/:id'              render={(props) => <StockMovement      {...props} />} />
                <Route exact path='/stock/correction'                render={(props) => <StockCorrections   {...props} />} />
                <Route exact path='/stock/correction/:id'            render={(props) => <StockCorrection    {...props} />} />
                <Route exact path='/stock/take'                      render={(props) => <StockTakes         {...props} />} />
                <Route exact path='/stock/take/:id'                  render={(props) => <StockTake          {...props} />} />

                {/* WorksOrder */}
                <Route exact path='/WorksOrders/all'                 render={(props) => <AllWorksOrder      {...props} />} />
                <Route exact path='/WorksOrders/pick/:id'            render={(props) => <PickWorksOrder     {...props} />} />
                <Route exact path='/WorksOrders/shortages/:id'       render={(props) => <ShortWorksOrder    {...props} />} />
                <Route exact path='/WorksOrders/builds'              render={(props) => <WorksOrderBuilds   {...props} />} />
                <Route exact path='/WorksOrders/view/:id'            render={(props) => <BuildWorksOrder    {...props} />} />

                {/* 404 - Redirect to Dashboard with 404 Error Dialog */}
                <Route component={Error404} />
            </Switch>
        );
    }
  
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError: (error, message) => dispatch(handleAppError(error, message))
    };
}

export default withRouter(connect(null, mapDispatchToProps)(WarehouseRouteSwitcher));