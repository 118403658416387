import React, { useEffect, useState } from "react";

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import AllIcon from "Components/Common/Icons/AllIcon";

import { colors } from 'Helpers/ColourHelper';
import { checkMailingListContact } from 'Helpers/EmailHelper';
import IconHelper from "Helpers/IconHelper";

let timeout = null;

export default function EmailField(props) {
    let { value } = props; 

    const [error, setError] = useState(false);
    const [warning, setWarning] = useState(false);
    const [bounce, setBounce] = useState(false);
    const [black, setBlack] = useState(false);
    const [complaint, setComplaint] = useState(false);
    //const [validation, setValidation] = useState(false);
    const [unsubed, setUnsubed] = useState(false);
    //const [loading, setLoading] = useState(true);

    const _useEffectFunc = async () => {
        setError(false);
        setWarning(false);
        setBounce(false);
        setBlack(false);
        setUnsubed(false);
        setComplaint(false);

        if (!value || value?.length < 7) return;

        let check = await checkMailingListContact(value);

        switch (check?.reason){
            case 'unsubscribed':
                setUnsubed(true);
                break;
            case 'blacklist':
                setBlack(true);
                break;
            case 'hard bounce':
            case 'soft bounce':
                setBounce(true);
                break;
            case 'invalid':
            case 'invalid ending':
                setError(true);
                break;
            case 'invalid domain':
                setWarning(true);
                break;
            case "complaint":
                setComplaint(true);
                break;
            default:
                break;
        }
        if (props.validationCallBack){
            props.validationCallBack(check?.reason ?? 'valid');
        }
    };

    useEffect(() => {
        clearTimeout(timeout);
        if (value?.length > 6){
            timeout = setTimeout(_useEffectFunc, 500);
        }
    }, [value]);

    return (
        <TextField
            {...props}
            InputProps={{
                ...props.InputProps,
                endAdornment: 
                    (warning || error || bounce || black || unsubed || complaint) ?
                        <InputAdornment position="start">
                            <AllIcon
                                color={black ? colors.black : ((error || bounce || unsubed || complaint) ? colors.red : colors.orange)}
                                icon={complaint ? IconHelper.emailComplaint : (bounce ? IconHelper.emailBounced : IconHelper.warning)}
                                noMargin
                                tooltip={complaint ? 'Email Address has had a complaint' : (black ? 'Email Address is Black Listed' : (error ? 'Invalid Email Address' : ( bounce ? 'Email Address has previously bounced' : (unsubed ? 'Email Address has Unsubscribed' : 'Email Address has warnings')))) }
                            />
                        </InputAdornment> : 
                        (
                            props.value  &&
                            <InputAdornment position="start">
                                <AllIcon
                                    color={colors.green}
                                    icon={IconHelper.true}
                                    noMargin
                                    tooltip={'Valid Email Address'}
                                />
                            </InputAdornment>
                        )
                
            }}
        />
    )
}