import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import API from '../../../API';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import FASolidIcon from '../../Common/Icons/FontAwesome/FASolidIcon';
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import moment from 'moment';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import _ from 'lodash';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {poActivityLog} from './../../../Helpers/ColourHelper'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import './../../../Assets/timeLine.css';
import PurchaseOrder from './../PurchaseOrder';
import { Link, Redirect } from 'react-router-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import FormControl from '@material-ui/core/FormControl';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import SnackBar from '../../Common/SnackBars/SnackBar';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import DataTable from '../../Common/DataTables/DataTable';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import { withStyles } from '@material-ui/styles';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';
import Textarea from '../../Common/Inputs/Textarea';
import MaterialLink from '@material-ui/core/Link';
import BackButton from 'Components/Common/Buttons/BackButton';
import { connect } from 'react-redux';
import {colors} from 'Helpers/ColourHelper';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    btnRow: {
        textAlign: 'center', 
    },
    btnRowBtn: {   
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    chip: {
        display: 'none',
    },
    poAlign: {
        marginLeft: '1.01em'
    },    
    topGap: {
        marginTop: theme.spacing(3)
    },       
    bottomGap: {
        marginBottom: theme.spacing(1.5)
    },      
    bottomGapLg: {
        marginBottom: theme.spacing(3)
    },  
    contentGap: {
        marginTop: theme.spacing(3)
    },
    expansionPanelDetails: {        
        width: 'calc(100% + 24px)!important',
        padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(5)}px ${theme.spacing(3)}px`,
    },
    statusUpdate: {
        textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        },
        stepper: {
            display: 'none',
        },
        chip: {
            display: 'flex',
            width: '160px',
            marginBottom: theme.spacing(3),
        },
        poAlign: {
            marginLeft: 0
        },
    },
    [theme.breakpoints.down('md')]: {        
        contentGap: {
            marginTop: 0,
        },
    }
});

const initialState = () => ({
    formData: {
        noteType: '',
        notesText: '',

        fileType: '',
        file: '',

        cancellationNotes:'',
    },
    formErrors: [],
    currentTab: 0,
    po: {},
    statuses: [],
    activeStep: 0,
    showPO: false,
    expanded: null,
    activityActionedBy: [],
    nextStep: '',
    barColor: [],
    redirect: false,
    successRedirect: false,
    latestPOId: 0,
    notes: [],
    files: [],
    showInvoiceType: false,
    // Expansion panels    
    expansionPanel: {
        approval: false,
        confirmation: false,
        delivery: false,
        invoice: false,
        completed: false,
        declined: false,
        cancelled: false,
    },
    expansionPanelDisabled: {
        approval: true,
        confirmation: true,
        delivery: true,
        invoice: true,
        completed: true,
        declined: true,
        cancelled: true,
    },
    // Dialogs
    dialogNotesOpen: false,
    dialogCancelOpen: false,
    dialogFilesOpen: false,
    // Snackbars
    snackbarNotesOpen: false,
    snackbarFilesOpen: false,
    snackbarCancelOpen: false,
    // Loading
    isLoading: true
});

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

class ViewPurchaseOrder extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `PurchaseOrder:View${this.props.match.params.id}`;
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.loadComponentData();
    }

    loadComponentData = () => {
        API.get('/purchaseOrders/' + this.props.match.params.id + '/mostRecentPOLinked')
        .then(result => {
            this.setState({
                latestPOId: result.data.latestPOId !== parseInt(this.props.match.params.id) ? result.data.latestPOId : this.props.match.params.id
            }, () => { 
                this.getPO();
                this.props.history.replace({pathname: '/purchase-order/view/' + this.state.latestPOId, state: this.props.location?.state});
            })
        });
    }

    handlePanel = (panel) => {        
        if(this.state.expansionPanelDisabled[panel] === false){
            this.setState({
                expansionPanel: {
                    ...this.state.expansionPanel,
                    [panel]: this.state.expansionPanel[panel] === true ? false : true,
                }
            });
        }
    }

    getPO = () => {
        API.get('/purchaseOrders/' + this.state.latestPOId)
        .then(result => {
            if(result.data.errors) {
                this.setState({ redirect: true });
            } else if(result.data) {
                // PROGRESS STEPS
                let statuses = ['Approval', 'Confirmation', 'Delivery', 'Invoice', 'Completed'];
                const activeStep = _.findIndex(statuses, function(el) { return el === result.data.po_status; });
                // BAR COLOURS - for the panels in Overview
                let barColor = [];
                statuses.forEach((status, idx) => {
                    if((activeStep && idx < activeStep) || activeStep === 4){
                        barColor[idx] = colors.green;
                    } 
                    else if(idx === activeStep){
                        barColor[idx] = 'orange';
                    } 
                    else {
                        barColor[idx] = 'white';
                    }
                });
               
                // NEXT STEP - link displayed under po
                let nextStep = `This parts order has been ${result.data.po_status.toLowerCase()}`;
                if(['Approval','Confirmation','Delivery','Invoice'].includes(result.data.po_status)){
                    nextStep =  <Typography style={{textAlign:'center'}} variant="body1" gutterBottom>
                                    This parts order is now awaiting {result.data.po_status.toLowerCase()}.<br></br>
                                    <Link to={`/purchase-order/${result.data.po_status.toLowerCase()}`} className='blueLink'>
                                        {result.data.po_status === 'Approval' ? 'Approve Purchase Order' : (result.data.po_status === 'Confirmation' ? 'Confirm Purchase Order' : `Confirm ${result.data.po_status}`)}
                                    </Link>
                                </Typography>;
                }
                
                // ACTIVITY
                let actionedBy = [];
                _.map(result.data.activity, (log) => {
                    let data = poActivityLog(log.po_log_type);
                    log.color = data['color'];
                    log.icon = data['icon'];
                    log.time = moment(log.po_log_datetime_added).format("DD/MM/YYYY");

                    switch(log.po_log_type){
                        case 'Approved':
                            if(!actionedBy['approved_by']){actionedBy['approved_by'] = []}
                            actionedBy['approved_by'].push(log);
                        break;
                        case 'Declined':
                            if(!actionedBy['declined_by']){actionedBy['declined_by'] = []}
                            actionedBy['declined_by'].push(log);
                        break;
                        case 'Confirmed':
                            if(!actionedBy['confirmed_by']){actionedBy['confirmed_by'] = []}
                            actionedBy['confirmed_by'].push(log);
                        break;
                        case 'Delivered':
                            if(!actionedBy['delivered_by']){actionedBy['delivered_by'] = []}
                            actionedBy['delivered_by'].push(log);
                        break;
                        case 'Invoice Received':
                            if(!actionedBy['invoice_by']){actionedBy['invoice_by'] = []}
                            actionedBy['invoice_by'].push(log);
                        break;
                        case 'Cancelled':
                            if(!actionedBy['cancelled_by']){actionedBy['cancelled_by'] = []}
                            actionedBy['cancelled_by'].push(log);
                        break;
                        default:
                        break;
                    }
                });
                // NOTES
                let notes = [];
                _.map(result.data.notes, (note) => {
                    switch(note.po_note_type){
                        case 'Approval':
                            if(!notes['Approval']){notes['Approval'] = []}
                            notes['Approval'].push(note);
                        break;
                        case 'Declined':
                            if(!notes['Declined']){notes['Declined'] = []}
                            notes['Declined'].push(note);
                        break;
                        case 'Invoice':
                            if(!notes['Invoice']){notes['Invoice'] = []}
                            notes['Invoice'].push(note);
                        break;
                        case 'Delivery':
                            if(!notes['Delivery']){notes['Delivery'] = []}
                            notes['Delivery'].push(note);
                        break;
                        case 'Cancelled':
                            if(!notes['Cancelled']){notes['Cancelled'] = []}
                            notes['Cancelled'].push(note);
                        break;
                        case 'Confirmation':
                            if(!notes['Confirmation']){notes['Confirmation'] = []}
                            notes['Confirmation'].push(note);
                        break;
                        default:
                        break;
                    }
                });
                // FILES
                let files = [];
                _.map(result.data.files, (file) => {
                    switch(file.po_file_type){
                        case 'Confirmation':
                            if(!files['Confirmation']){files['Confirmation'] = []}
                            files['Confirmation'].push(file);
                        break;
                        case 'Delivery':
                            if(!files['Delivery']){files['Delivery'] = []}
                            files['Delivery'].push(file);
                        break;
                        case 'Invoice':
                        case 'Pro forma':
                            if(!files['Invoice']){files['Invoice'] = []}
                            files['Invoice'].push(file);
                        break;
                        default:
                        break;
                    }
                });

                // Expand current status
                let defaultExpanded;
                if(actionedBy.declined_by){                    
                    defaultExpanded = 'declined';
                } else if(actionedBy.cancelled_by){
                    defaultExpanded = 'cancelled';
                } else {
                    defaultExpanded = statuses[activeStep].toLowerCase();
                }

                // Disable non-completed stages               
                const expansionPanelDisabled = {
                    ...this.state.expansionPanelDisabled
                }

                if(actionedBy['approved_by']) {
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by'])
                        barColor[0] = 'disabled';
                    expansionPanelDisabled['approval'] = false;
                }                    

                if(actionedBy['confirmed_by']) {
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by'])
                        barColor[1] = 'disabled';
                    expansionPanelDisabled['confirmation'] = false;
                }       
                    
                if(actionedBy['delivered_by']) {     
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by']) {       
                        barColor[2] = 'disabled';
                    }
                    expansionPanelDisabled['delivery'] = false;    
                }  
                
                if(actionedBy['invoice_by']) {     
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by']) {       
                        barColor[3] = 'disabled';
                    }                 
                    expansionPanelDisabled['invoice'] = false;
                }   

                if(result.data.po_completed_datetime && result.data.po_completed_datetime !== '0000-00-00 00:00:00') {
                    if(actionedBy['declined_by'] || actionedBy['cancelled_by'])
                        barColor[4] = 'disabled';
                    expansionPanelDisabled['completed'] = false;
                }       

                if(actionedBy['declined_by'])
                    expansionPanelDisabled['declined'] = false;

                if(actionedBy['cancelled_by'])
                    expansionPanelDisabled['cancelled'] = false; 

                if(['Approval','Confirmation','Delivery','Invoice'].includes(result.data.po_status)){
                    expansionPanelDisabled[result.data.po_status.toLowerCase()] = false;
                }
                
                this.setState({
                    po: result.data,
                    statuses: statuses,
                    activeStep: activeStep,
                    activityActionedBy: actionedBy,                    
                    expansionPanelDisabled,                      
                    expansionPanel: {
                        ...this.state.expansionPanel,
                        [defaultExpanded]: true
                    },
                    nextStep: nextStep,
                    barColor: barColor,
                    notes: notes,
                    files: files,
                    isLoading: false,
                });
            }
        });
    }

    downloadPO = (po_id) => {
        API.get('/purchaseOrders/' + po_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        }, ()=>this.savePageState());
    };

    viewPO = () => {
        this.setState(prevState => ({
            showPO: !prevState.showPO
        }),()=>this.savePageState());
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            this.setState({
                showInvoiceType: this.state.formData.fileType === 'Invoice' || (selectedOption && selectedOption.value === 'Invoice') ? true : false
            });
        });
    }
    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }
    clearFile = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                file: initialState().formData.file
            }
        });
    };

    // NOTES
    handleNotesOpen = () => {this.setState({dialogNotesOpen: true});};
    handleNotesClose = () => {this.setState({dialogNotesOpen: false});};
    handleNotesSuccess = () => {
        this.setState({ dialogNotesOpen: false });
        this.notesSubmit();
    }
    handleNotesSnackbarClose = () => {this.setState({ snackbarNotesOpen: false }); };
    notesSubmit = () => {
        API.post(`/purchaseOrders/${this.props.match.params.id}/notes`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogNotesOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarNotesOpen: true
                });
                this.loadComponentData();
            }
        });
    }
    // CANCEL
    handleCancelOpen = () => {this.setState({dialogCancelOpen: true});};
    handleCancelClose = () => {this.setState({dialogCancelOpen: false});};
    handleCancelSuccess = () => {
        this.setState({ dialogCancelOpen: false });
        this.cancelSubmit();
    }
    handleCancelSnackbarClose = () => {this.setState({ snackbarCancelOpen: false }); };
    cancelSubmit = () => {
        API.post(`/purchaseOrders/${this.props.match.params.id}/cancel`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogCancelOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarCancelOpen: true
                });
                this.loadComponentData();
            }
        });
    }
    // FILES
    handleFilesOpen = () => {this.setState({dialogFilesOpen: true});};
    handleFilesClose = () => {this.setState({dialogFilesOpen: false});};
    handleFilesSuccess = () => {
        this.setState({ dialogFilesOpen: false });
        this.filesSubmit();
    }
    handleFilesSnackbarClose = () => {this.setState({ snackbarFilesOpen: false }); };
    filesSubmit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            newFormData.append(key, this.state.formData[key]);
        });
        API.post(`/purchaseOrders/${this.props.match.params.id}/files`, newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogFilesOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarFilesOpen: true
                });
                this.loadComponentData();
            }
        });
    }

    displayNotes = (notes) => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} className={this.props.classes.bottomGap}>                      
                    <Typography variant="body1">
                        <FALightIcon icon='comment-alt-lines' /> Notes
                    </Typography>
                </Grid>
                {notes ?
                    notes.map(note => (
                        <Grid key={note.po_note_id} item xs={12} align='left' className={this.props.classes.bottomGapLg}>
                            <Card style={{boxShadow: 'none', border: '1px solid #DDD'}}>
                                <CardHeader
                                    avatar={
                                        <Avatar alt={`${note.staff.staff_first_name} ${note.staff.staff_last_name}`} src={note.staff.staff_profile_photo} />
                                    }
                                    title={note.po_note_staff_name}
                                    subheader={ moment(note.po_note_date_time, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                                />
                                <CardContent>
                                    <Typography variant="body2">
                                        {note.po_note_text && note.po_note_text.split('\n').map((item, key) => {
                                            return <span key={key}>{item}<br/></span>
                                        })}
                                    </Typography>
                                </CardContent>
                            </Card>                                
                        </Grid>
                    ))
                    :
                    (
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            No notes available to view
                        </Typography>
                    </Grid> 
                    )
                }
            </Grid>
        )
    }

    displayFiles = (files, fileTitle) => {
        const title = fileTitle;
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} className={this.props.classes.bottomGap}>                 
                    <Typography variant="body1">
                        <FALightIcon icon='download' /> {title}
                    </Typography>
                </Grid>
                {files ?
                    (
                        <Grid item xs={12}>
                            <DataTable  
                                config={{
                                    key: 'po_file_id',
                                    noHeader: true,
                                    withBorder: true,
                                    withBorderRadius: true,
                                }}
                                columns={[
                                    {
                                        field: rowData => <MaterialLink href={rowData.file_url} target="_blank" className='blueLink'>{rowData.po_file_filename}</MaterialLink>,
                                    }
                                ]}
                                rows={files}
                                />                                                                            
                        </Grid>
                    )
                    :
                    (
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            No files available to download
                        </Typography>
                    </Grid> 
                    )
                }
            </Grid>
        )
    } 

    render() {
        const { classes } = this.props;
        const { currentTab, activeStep, statuses, po, isLoading, expansionPanel, expansionPanelDisabled, activityActionedBy, nextStep, formData, formErrors, notes, files, successRedirect, redirect } = this.state;

        if (successRedirect) {
            return <Redirect 
                        to={{
                            pathname:`/purchase-order/view/${this.state.latestSrId}`,
                            state: {snackbarAmendOpen: true}
                        }}
                    />
        } else if (redirect) {
            return <Redirect to="/purchase-order/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Purchase Order Details
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={9}>
                            <PaddedPaper>
                                {(isLoading && (
                                    <LoadingCircle />               
                                )) || (
                                    <React.Fragment>
                                         {statuses[activeStep] &&
                                            <Grid item align='center' className='xs-only'>
                                                <Chip label={statuses[activeStep]} color='primary' size='medium' className={classes.chip}  />
                                            </Grid>  
                                        }          
                                        <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                                            {this.state.statuses.map(label => (
                                                <Step key={label}>
                                                    <StepLabel>{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                        <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25}}>
                                            {po.full_reference}
                                        </Typography>
                                        <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                            Purchase Order (v{parseInt(po.po_number_amendment)})<br />
                                            <Button variant="outlined"
                                                color="default"
                                                size="small"
                                                className={classes.btn}
                                                onClick={this.viewPO}
                                                style={{marginLeft:10}}
                                            >                                    
                                                <FALightIcon icon='search' />
                                                { !this.state.showPO ? 'View' : 'Hide' }
                                            </Button>
                                            <Button variant="outlined"
                                                color="default"
                                                size="small"
                                                className={classes.btn}
                                                onClick={() => this.downloadPO(po.po_id)}
                                            >
                                                <FALightIcon icon='download' />
                                                Download
                                            </Button> 
                                        </Typography>
                                        <br className='xs-only' />                                    
                                        { this.state.showPO ? <PurchaseOrder po={po} /> : null }
                                
                                        <AppBar position="static" style={{backgroundColor:'white', margin: '25px 0'}} >
                                            <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary" variant="scrollable">
                                                <Tab label="Overview" />
                                                <Tab label="Notes" />
                                                <Tab label="Activity Log" />
                                            </Tabs>
                                        </AppBar>
                                    {currentTab === 0 && (
                                        //Overview
                                        <TabContainer>
                                            {/* Approval */}
                                            <ExpansionPanel expanded={expansionPanel.approval} disabled={expansionPanelDisabled['approval']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('approval')} expandIcon={!expansionPanelDisabled['approval'] && <ExpandMoreIcon />} className={this.state.barColor[0]}>
                                                    <Typography>Approval</Typography>
                                                </ExpansionPanelSummary>                                            
                                                {!activityActionedBy.approved_by ? (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item align='center' xs={12}>                                              
                                                                <FALightIcon size='xlarge' icon='exclamation-circle' noMargin /> 
                                                                {nextStep}
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Approval)}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                ) : (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item xs={12} align='center'>
                                                                {activityActionedBy.approved_by &&
                                                                    <React.Fragment>
                                                                        <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                        {activityActionedBy.approved_by.map(approved_by => (
                                                                            <Typography key={approved_by.po_log_id} variant="body1">Approved by {approved_by.po_log_staff} on {approved_by.time}</Typography>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Approval)}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                )}
                                            </ExpansionPanel>
    
                                            {/* Confirmation */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.confirmation} disabled={expansionPanelDisabled['confirmation']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('confirmation')} expandIcon={!expansionPanelDisabled['confirmation'] && <ExpandMoreIcon />} className={this.state.barColor[1]}>
                                                    <Typography>Confirmation</Typography>
                                                </ExpansionPanelSummary>
                                                {!activityActionedBy.confirmed_by ? (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item align='center' xs={12}>                                               
                                                                <FALightIcon size='xlarge' icon='exclamation-circle' noMargin /> 
                                                                {nextStep}
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}  align='center'>
                                                                {this.displayNotes(notes.Confirmation)}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayFiles(files.Confirmation, 'Files')}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                ) : (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} align='center'>
                                                                {activityActionedBy.confirmed_by &&
                                                                    <React.Fragment>
                                                                        <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                        {activityActionedBy.confirmed_by.map(confirmed_by => (
                                                                            <Typography key={confirmed_by.po_log_id} variant="body1">Processed by {confirmed_by.po_log_staff} on {confirmed_by.time}</Typography>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}  align='center'>
                                                                {this.displayNotes(notes.Confirmation)}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayFiles(files.Confirmation, 'Files')}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                )}
                                            </ExpansionPanel>
                                            
                                            {/* Delivery */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.delivery} disabled={expansionPanelDisabled['delivery']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('delivery')} expandIcon={!expansionPanelDisabled['delivery'] && <ExpandMoreIcon />} className={this.state.barColor[2]}>
                                                    <Typography>Delivery</Typography>
                                                </ExpansionPanelSummary>
                                                {!activityActionedBy.delivered_by ? (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3} justify='center'>
                                                            <Grid item align='center' xs={12}>                                                 
                                                                <FALightIcon size='xlarge' icon='exclamation-circle' noMargin /> 
                                                                {nextStep}
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}  align='center'>
                                                                {this.displayNotes(notes.Delivery)}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayFiles(files.Delivery, 'Files')}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                ) : (
                                                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} align='center'>
                                                                {activityActionedBy.delivered_by &&
                                                                    <React.Fragment>
                                                                        <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                        {activityActionedBy.delivered_by.map(delivered_by => (
                                                                            <Typography key={delivered_by.po_log_id} variant="body1">Delivery processed by {delivered_by.po_log_staff} on {delivered_by.time}</Typography>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}  align='center'>
                                                                {this.displayNotes(notes.Delivery)}
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayFiles(files.Delivery, 'Files')}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                )}
                                            </ExpansionPanel>
    
                                            {/*` Invoice */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.invoice} disabled={expansionPanelDisabled['invoice']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('invoice')} expandIcon={!expansionPanelDisabled['invoice'] && <ExpandMoreIcon />} className={this.state.barColor[3]}>
                                                    <Typography>Invoice</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                    <Grid container spacing={3} justify='center'>
                                                        {!activityActionedBy.invoice_by ? (                                                    
                                                            <Grid item align='center' xs={12}>                                                    
                                                                <FALightIcon size='xlarge' icon='exclamation-circle' noMargin /> 
                                                                {nextStep}
                                                                <Divider className={classes.topGap} />
                                                            </Grid>
                                                        ) : (    
                                                            <Grid item xs={12} align='center'>
                                                                {activityActionedBy.invoice_by &&
                                                                    <React.Fragment>
                                                                        <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                        {activityActionedBy.invoice_by.map(invoice_by => (
                                                                            <Typography key={invoice_by.po_log_id} variant="body1">Invoiced by {invoice_by.po_log_staff} on {invoice_by.time}</Typography>
                                                                        ))}
                                                                    </React.Fragment>
                                                                }
                                                                <Divider className={classes.topGap} />                                                            
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={12} lg={6} align='center'>
                                                            {this.displayNotes(notes.Invoice)}
                                                        </Grid>                                                    
                                                        <Grid item xs={12} lg={6} align='center'>
                                                            {this.displayFiles(files.Invoice, 'Invoices')}
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>  
                                            </ExpansionPanel>
    
                                            {/* Completed */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.completed} disabled={expansionPanelDisabled['completed']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('completed')} expandIcon={!expansionPanelDisabled['completed'] && <ExpandMoreIcon />} className={this.state.barColor[4]}>
                                                    <Typography>Completed</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container>
                                                            <Grid item xs={12} align='center'>   
                                                                {po.po_completed_datetime && po.po_completed_datetime !== '0000-00-00 00:00:00' &&        
                                                                    <React.Fragment>   
                                                                        <FALightIcon size='xlarge' icon='check-circle' noMargin /> 
                                                                        <Typography variant="body1">Completed on {moment(po.po_completed_datetime).format("DD/MM/YYYY")}</Typography>
                                                                    </React.Fragment>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                            
                                            {/* Declined */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.declined} disabled={expansionPanelDisabled['declined']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('declined')} expandIcon={!expansionPanelDisabled['declined'] && <ExpandMoreIcon />} className={(po.po_status === 'Declined' && colors.red) || 'white'}>
                                                    <Typography>Declined</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container justify='center' spacing={3}>
                                                            <Grid item xs={12} align='center'>   
                                                                {activityActionedBy.declined_by && (
                                                                    <React.Fragment>
                                                                        {activityActionedBy.declined_by.map(declined_by => (                
                                                                            <React.Fragment>          
                                                                                <FALightIcon size='xlarge' icon='times-circle' noMargin /> 
                                                                                <Typography key={declined_by.po_log_id} variant="body1">Declined by {declined_by.po_log_staff} on {declined_by.time}</Typography>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </React.Fragment>
                                                                )}
                                                            </Grid>  
                                                            <Grid item xs={12} align='center'>
                                                                <Divider className={classes.bottomGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Declined)}
                                                            </Grid> 
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                            </ExpansionPanel>
    
                                            {/* Cancelled */}
                                            <ExpansionPanel className={classes.expansionPanel} expanded={expansionPanel.cancelled} disabled={expansionPanelDisabled['cancelled']}>
                                                <ExpansionPanelSummary onClick={() => this.handlePanel('cancelled')} expandIcon={!expansionPanelDisabled['cancelled'] && <ExpandMoreIcon />} className={(po.po_status === 'Cancelled' && colors.red) || 'white'}>
                                                    <Typography>Cancelled</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>  
                                                        <Grid container justify='center' spacing={3}>
                                                            <Grid item xs={12} align='center'>   
                                                                {activityActionedBy.cancelled_by && (
                                                                    <React.Fragment>
                                                                        {activityActionedBy.cancelled_by.map(cancelled_by => (                         
                                                                            <React.Fragment key={cancelled_by.po_log_id}>          
                                                                                <FALightIcon size='xlarge' icon='times-circle' noMargin /> 
                                                                                <Typography variant="body1">Cancelled by {cancelled_by.po_log_staff} on {cancelled_by.time}</Typography>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </React.Fragment>
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={12} align='center'>
                                                                <Divider className={classes.bottomGap} />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6} align='center'>
                                                                {this.displayNotes(notes.Cancelled)}
                                                            </Grid> 
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                            </ExpansionPanel>                                    
                                            
                                        </TabContainer>
                                    )}
                                        {currentTab === 1 && (
                                            //Notes
                                            <TabContainer>
                                            <Grid container spacing={3}>
                                                {po.notes && 
                                                    po.notes.map(note => (
                                                        <Grid item xs={12} key={note.po_note_id}>
                                                            <Card>
                                                                <CardHeader
                                                                    avatar={<Avatar alt={`${note.staff.staff_first_name} ${note.staff.staff_last_name}`} src={note.staff.staff_profile_photo} />}
                                                                    title={note.po_note_staff_name}
                                                                    subheader={`${moment(note.po_note_date_time, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")} (${note.po_note_type})`}
                                                                />
                                                                <CardContent>
                                                                    <Typography component="p">
                                                                        {note.po_note_text && note.po_note_text.split('\n').map((item, key) => {
                                                                            return <span key={key}>{item}<br/></span>
                                                                        })}
                                                                    </Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    ))
                                                }
                                                {!po.notes.length > 0 && (
                                                    <Grid item xs={12}>
                                                        <Card>
                                                            <CardHeader                                                       
                                                                title='No notes'
                                                            />
                                                            <CardContent>
                                                                <Typography component="p">
                                                                    There are no notes to show for this purchase order
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                )}
                                                </Grid>
                                            </TabContainer>
                                        )}
                                        {currentTab === 2 && (
                                            //Activity Log
                                            <Card>
                                                <CardContent>
                                                    <TabContainer>
                                                        <VerticalTimeline layout="1-column">
                                                            {po.activity && po.activity.map(log => (
                                                                <VerticalTimelineElement
                                                                    key={log.po_log_id}
                                                                    className="vertical-timeline-element--work"
                                                                    date={moment(log.po_log_datetime_added).format("DD/MM/YYYY") + ' - ' + log.po_log_staff}
                                                                    iconStyle={{ backgroundColor: log.color, color: 'white' }}
                                                                    icon={<FASolidIcon icon={log.icon} style={{color:'white'}} />}
                                                                >
                                                                    <h3 className="vertical-timeline-element-title">{log.po_log_type}</h3>
                                                                    {log.po_log_text && log.po_log_text.split('\n').map((item, key) => {
                                                                        return <span key={key}>{item}<br/></span>
                                                                    })}
                                                                </VerticalTimelineElement>
                                                            ))}
                                                        </VerticalTimeline>
                                                    </TabContainer>
                                                </CardContent>
                                            </Card>
                                        )}                               
                                        <Divider style={{marginBottom:20}} />
                                        <div className={`buttonRow ${classes.btnRow}`}>
                                            <Grid container justify='center' spacing={1}>
                                                <Grid item xs={12} md={2}>
                                                    <BackButton props={this.props} style={{width:'100%'}} />
                                                </Grid>
                                                <Grid item xs={12} md={2}>                                            
                                                    <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleNotesOpen}>
                                                        <FALightIcon icon='plus' buttonPrimary />Notes
                                                    </Button>
                                                </Grid>             
                                                <Grid item xs={12} md={3}>
                                                    <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleFilesOpen}><FALightIcon icon='plus' buttonPrimary />Files</Button>
                                                </Grid>                    
                                                {po.po_status !== 'Declined' && po.po_status !== 'Cancelled' &&
                                                    <React.Fragment>
                                                        {(po.po_status === 'Confirmation' || po.po_status === 'Delivery' || po.po_status === 'Invoice' || (po.po_status === 'Approval' && po.po_number_amendment === '01')) &&
                                                            <Grid item xs={12} md={5} lg={4}>
                                                                <Link to={`/purchase-order/update/${po.po_id}`} className={classes.link}>
                                                                    <Button variant="contained" className={classes.btnRowBtn} color="primary"><FALightIcon icon='pen' buttonPrimary /> Amend</Button>
                                                                </Link>
                                                            </Grid>
                                                        }
                                                        {po.po_status !== 'Completed' &&
                                                            <Grid item xs={12} md={2}>
                                                                <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleCancelOpen}><FALightIcon icon='ban' buttonPrimary /> Cancel</Button>
                                                            </Grid>
                                                        }
                                                    </React.Fragment>
                                                }
                                            </Grid>
                                        </div>
                                    </React.Fragment>
                                )}
                            </PaddedPaper>
                        </Grid>
                        {!isLoading && (
                            <Grid item xs={12} lg={3}>
                                <PaddedPaper>
                                    <Typography variant="h5">
                                        Previous Revisions
                                    </Typography>
                                    {po.revisions && po.revisions.length > 0 ?
                                        po.revisions.map((rev, idx) => (
                                            <React.Fragment key={idx}>
                                                <MaterialLink component="button" variant="body1" className='blueLink' onClick={() => this.downloadPO(rev.po_id)}>
                                                    {rev.full_reference || '-'}
                                                </MaterialLink>
                                                <br></br>
                                            </React.Fragment>
                                        ))
                                    :
                                        <Typography variant="body1">
                                            0 previous purchase orders
                                        </Typography>
                                    }
                                </PaddedPaper>
                            </Grid>
                        )}
                    </Grid>            
                    {/* NOTES */}
                    <Dialog 
                        open={this.state.dialogNotesOpen} 
                        onClose={this.handleNotesClose} 
                        fullWidth={true} 
                        maxWidth="sm" 
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title">Add Notes</DialogTitle>
                        <DialogContent>
                            <FormControl error={formErrors && formErrors['noteType'] && true} fullWidth margin="normal">
                                <AutoCompleteSelect
                                    options={[
                                        {value: 'Approval', label: 'Approval'},
                                        {value: 'Confirmation', label: 'Confirmation'},
                                        {value: 'Delivery', label: 'Delivery'},
                                        {value: 'Invoice', label: 'Invoice'},
                                        {value: 'Declined', label: 'Declined'},
                                        {value: 'Cancelled', label: 'Cancelled'}
                                    ]}
                                    label='Note Type *'
                                    value={formData.noteType}
                                    onChange={this.handleSelectChange('noteType')}
                                    error={formErrors && formErrors['noteType'] && true}
                                    errorText={formErrors && formErrors['noteType'] && formErrors['noteType']}
                                />
                            </FormControl>
                            <Textarea
                                id="notesText"
                                name="notesText"
                                label="Notes *"
                                value={this.state.formData.notesText}
                                rows={5}
                                error={formErrors && formErrors['notesText']}
                                onChange={this.handleChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleNotesClose} variant="outlined" color="default">Cancel</Button>
                            <Button onClick={this.handleNotesSuccess} autoFocus variant="contained" color="primary">Save</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarNotesOpen}
                        onClose={this.handleNotesSnackbarClose}
                        message='You have successfully added new notes'
                    />
    
                    {/* FILES */}
                    <Dialog 
                        open={this.state.dialogFilesOpen} 
                        onClose={this.handleFilesClose} 
                        fullWidth={true} 
                        maxWidth="sm" 
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title">Add Files</DialogTitle>
                        <DialogContent>
                            <FormControl error={formErrors && formErrors['fileType'] && true} fullWidth margin="normal">
                                <AutoCompleteSelect
                                    options={[
                                        {value: 'Confirmation', label: 'Confirmation'},
                                        {value: 'Delivery', label: 'Delivery'},
                                        {value: 'Invoice', label: 'Invoice'}
                                    ]}
                                    label='File Type *'
                                    value={formData.fileType}
                                    onChange={this.handleSelectChange('fileType')}
                                    error={formErrors && formErrors['fileType'] && true}
                                    errorText={formErrors && formErrors['fileType'] && formErrors['fileType']}
                                />
                            </FormControl>
                            {this.state.showInvoiceType && 
                                <FormControl error={formErrors && formErrors['invoiceType'] && true} fullWidth margin="normal">
                                    <RadioGroup
                                        name="invoiceType"
                                        value={this.state.formData.invoiceType}
                                        onChange={this.handleChange}
                                        row
                                    >
                                        <FormControlLabel
                                            value="Invoice"
                                            control={<Radio color="primary" />}
                                            label="Invoice"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Pro forma"
                                            control={<Radio color="primary" />}
                                            label="Pro forma"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                    {formErrors && formErrors['invoiceType'] && <FormHelperText>{formErrors['invoiceType']}</FormHelperText>}
                                </FormControl>
                            }
                            <DragFileInput
                                id="file"
                                name="file"
                                label="File *"
                                file={formData.file}
                                errorText={formErrors && formErrors['file']}
                                onChange={this.handleFileChange}
                                cancelOnClick={this.clearFile}
                                emptyText='No file selected'
                            />
                        </DialogContent>
                        <DialogActions>
                            <Typography variant="subtitle2" style={{flex: 1}}>
                                <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                            </Typography>
                            <Button onClick={this.handleFilesClose} variant="outlined" color="default">Cancel</Button>
                            <Button onClick={this.handleFilesSuccess} autoFocus variant="contained" color="primary">Upload</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarFilesOpen}
                        onClose={this.handleFilesSnackbarClose}
                        message='You have successfully added a new file'
                    />
                    {/* CANCEL */}
                    <Dialog 
                        open={this.state.dialogCancelOpen} 
                        onClose={this.handleCancelClose} 
                        fullWidth={true} 
                        maxWidth="sm" 
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="form-dialog-title" color="secondary">You Are About To <span style={{color:colors.red}}>Cancel</span> This Purchase Order</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1">Please feel free to leave any notes relating to this purchase order:</Typography>
                            <Textarea
                                id="cancellationNotes"
                                name="cancellationNotes"
                                label="Notes"
                                value={this.state.formData.cancellationNotes}
                                rows={5}
                                error={formErrors && formErrors['cancellationNotes']}
                                onChange={this.handleChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCancelClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={this.handleCancelSuccess} color="secondary" autoFocus variant="contained">Cancel</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarCancelOpen}
                        onClose={this.handleCancelSnackbarClose}
                        message='You have successfully cancelled this purchase order'
                    />
                </Grid>
            );
        }
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewPurchaseOrder));