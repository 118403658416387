import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import Textarea from 'Components/Common/Inputs/Textarea';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import React, { PureComponent } from 'react';

class DeliveryDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            show : {
                pickingNotes: props.pickingNotes ? true : false,
                despatchNotes: props.despatchNotes ? true : false,
            }
        };
    }

    show = (name) => () => {
        if (this.state.show[name]) {
            //clear value if closed
            this.props.handleChange({ target: { name, value: '' } });
        }
        this.setState({ show: { ...this.state.show, [name]: !this.state.show[name] } });
    }
    
    render() {
        const { formErrors, handleDateChange, handleChange, date, notes, order, pickingNotes } = this.props;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <form noValidate autoComplete="off">
                            <Grid container spacing={3}>
                                <Grid container item spacing={3}>
                                    <Grid item lg={12}>
                                        <Typography variant="h5" gutterBottom>
                                            {order ? 'Order' : 'Quote'} Details
                                        </Typography>
                                        { handleDateChange && (
                                            <DatePicker
                                                type="date"
                                                id="date"
                                                name="date"
                                                label={order ? 'Order Date *' : 'Quote Date *'}
                                                value={date}
                                                errorText={formErrors && formErrors['date']}
                                                onChange={order ? handleDateChange('orderDate') : handleDateChange}
                                                autoOk={true}
                                                disableFuture={true}
                                            />
                                        )}
                                        <Textarea
                                            id="notes"
                                            name="notes"
                                            label={order ? 'Order Notes' : 'Quotation Notes'}
                                            value={notes}
                                            rows={10}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </PaddedPaper>
                </Grid>
                {order &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Grid container spacing={3}>
                                        <Grid container item spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6">
                                                    {order ? 'Order' : 'Quote'} Picking Notes
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <Textarea
                                                    id="notes"
                                                    name="pickingNotes"
                                                    label={order ? 'Order Picking Notes' : 'Quotation Picking Notes'}
                                                    value={pickingNotes}
                                                    rows={10}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                        </PaddedPaper>
                    </Grid>
                }
            </Grid>
        );
    }
}

export default DeliveryDetails;
    