import * as keyDownActionTypes from 'Actions/KeyDown/Types/Types';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';

const initialState = {
    key: uuidv4(),
    functions: {},
    functionKey: null,
    function: null
}

const KeyDownReducer = (state = initialState, action) => {
    let functions = state.functions;
    let functionKey = state.functionKey;
    let func = state.function;

    switch(action.type) {
        case keyDownActionTypes.KEYDOWN_ADD:
            return {
                ...state,
                key: uuidv4(),
                functions: {
                    ...state.functions,
                    [action.key]: action.func
                },
                functionKey: action.key,
                function: action.func
            }
        case keyDownActionTypes.KEYDOWN_REMOVE:
            delete functions[action.key ? action.key : _.last(_.keys(state.functions))];
            functionKey = _.last(_.keys(functions));
            func = state.functions[functionKey];
            return {
                ...state,
                key: uuidv4(),
                function: func,
                functions, functionKey, 
            }
        case keyDownActionTypes.KEYDOWN_CLEAR:
            return initialState;
        case keyDownActionTypes.KEYDOWN_SET:
            functionKey = action.key ?? _.last(_.keys(state.functions));
            func = state.functions[functionKey];
            return {
                ...state,
                key: uuidv4(),
                function: func,
                functionKey, 
            }      
            case keyDownActionTypes.KEYDOWN_UNSET:
                functionKey = _.last( action.key ? _.filter(_.keys(state.functions), i => i !== action.key) : _.keys(state.functions).splice(-1) );
                func = state.functions[functionKey];
                return {
                    ...state,
                    key: uuidv4(),
                    function: func,
                    functionKey, 
                }     
        default:
            return state;
    }
}

export default KeyDownReducer;