export function getInitialState(initialState) {
    return {
        ...initialState,
        ...(this.hasPageState() && this.props?.statePersistence?.[this.persistenceId]),
        dataLoading: this.hasPageState() ? false : true,
        isLoading: this.hasPageState() ? false : (initialState?.isLoading ?? false)
    }
}

export function savePageState() {
    this.persistenceId !== null && this.props.setPersistence(this.persistenceId, this.state)
}

export function clearPageState() {
    this.props.clearPersistence(this.persistenceId);
}

export function hasPageState() {
    return !!this.props?.statePersistence?.[this.persistenceId]
}