import React  from 'react';
import { map, assign, find, get } from 'lodash';
import { connect } from 'react-redux';

import {
    Button,
    Grid,
    TextField,
    Typography,
    DialogActions,
    Link,
    TableCell,
    TableRow,
    Table,
    TableBody,
    TableHead,
    Tooltip
} from '@material-ui/core';

import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import { handleFileChange, handleDateChange, getFormData, handleSelectChange, handleAddRow, handleRemoveRow, handleRowSelectChange, handleRowMultiSelectChange, handleRowDateChange, handleChange, clearValue } from 'Functions/FormFunctions';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { downloadS3File, toggleDialog } from 'Functions/MiscFunctions';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import moment from 'moment';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import DataTable from 'Components/Common/DataTables/DataTable';

const initialState = () => ({
    insuranceId: 0,
    insurance: {},
    formData: {
        broker: 0,
        insuranceCompany: '',
        policyNumber: '',
        effectiveDate: null,
        endDate: null,
        certificateFile: null,
        vehiclesCovered: [],
        status: 'Active',
    },
    formErrors: [],
    suppList: [],
    vehicleList: [],
    staffList: [],
    isLoading: true,
    readOnly: false,
    renew: false,
    staffUnder25: []
});

class VehicleInsuranceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleFileChange = handleFileChange.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
        this.getFormData = getFormData.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.handleAddRow = handleAddRow.bind(this);
        this.handleRemoveRow = handleRemoveRow.bind(this);
        this.handleRowSelectChange = handleRowSelectChange.bind(this);
        this.handleRowMultiSelectChange = handleRowMultiSelectChange.bind(this);
        this.handleRowDateChange = handleRowDateChange.bind(this);
        this.handleChange = handleChange.bind(this);
        this.clearValue = clearValue.bind(this);
    }

    componentDidMount = () => {
        this.loadComponentData(); 
    }

    loadComponentData = () => {

        const { insuranceId, readOnly, renew } = this.props;

        Promise.all([ 
            API.get('/vehicles', { params: { activeOnly: true } }),
            API.get('/suppliers/all', {
                params: {
                    supplierType: 11 // Insurance
                }
            }),
            API.get('/staff/all', { params: { activeOnly: true } }),
        ])
        .then(([res, suppRes, staffRes]) => {
            let vehicleList = map(res.data, (v) => {
                return assign({
                    value: v.id,
                    label: v.regNumber,
                    desc: v.make + ' ' + v.model,
                    disabled: false
                });
            });

            let suppList = map(suppRes.data, (supp) => {
                return assign({
                    value: supp.supp_id,
                    label: supp.supp_company_name
                });
            });

            let staffUnder25 = [];

            let staffList = map(staffRes.data, el => {

                if(el.staff_dob != '0000-00-00' && moment().diff(el.staff_dob, 'years') < 25) {
                    staffUnder25.push(`${el.staff_first_name} ${el.staff_last_name}`);
                }

                return assign({
                    value: el.staff_id,
                    label: `${el.staff_first_name} ${el.staff_last_name}`, 
                });
            });  

            this.setState({
                isLoading: false,
                insuranceId,
                vehicleList,
                suppList,
                staffList,
                readOnly,
                renew,
                staffUnder25
            },
            () => {
                if (this.state.insuranceId) {
                    this.loadInsuranceData();
                }
            });  
            
        });
    }

    loadInsuranceData = () => {
        this.setState({
            isLoading: true,
        }, 
        () => {
            API.get('/vehicles/insurance/' + this.state.insuranceId)
            .then(insuranceRes => {

                const { renew } = this.state;
    
                let ins = insuranceRes.data;
    
                this.setState({
                    ...this.state,
                    isLoading: false,
                    insurance: ins,
                    formData: {
                        ...this.state.formData,
                        broker: ins.broker,
                        insuranceCompany: ins?.company,
                        policyNumber: renew ? '' : ins?.policyNumber,
                        effectiveDate: renew ? null : ins?.effectiveDate,
                        endDate: renew ? null : ins?.endDate,
                        vehiclesCovered: ins?.vehicles,
                        status: renew ? 'Active' : ins?.status,
                    }
                },
                () => {
                    this.updateVehicleList();
                });
            });
        });
    }

    handleSubmit = () => {
        const { insuranceId, renew } = this.state;
        const route = insuranceId && !renew  ? `/vehicles/insurance/${insuranceId}`: `/vehicles/insurance`;

        API.post(route, this.getFormData(), {
            params: {
                renewFromId: renew ? insuranceId : null
        }})
        .then(res => {
            if(res.data.errors && res.data.errors.length > 0) {
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors)
                });
            } else {
                this.setState({
                    ...initialState(),
                }, () => {
                    this.props.deploySnackBar("success", `The insurance was successfully ${insuranceId && !renew ? 'updated' : 'added'}`);
                    this.props.refresh();
                })
            }
        });
    }

    updateVehicleList = () => {
        
        let vehicleList = map(this.state.vehicleList, (v) => {

            let inList = find(this.state.formData.vehiclesCovered, el => el.vehicleId == v.value);

            return assign({
                ...v,
                disabled: inList ? true : false
            });
        });

        this.setState({
            vehicleList
        });  
    }

    clearPermittedDrivers = (idx) => {

        let vehiclesCovered = this.state.formData.vehiclesCovered;
        vehiclesCovered[idx].permittedDrivers = [];

        this.setState({
            formData: {
                ...this.state.formData,
                vehiclesCovered
            }
        });
    }

    render() {
        const { formErrors, formData, isLoading, insuranceId, insurance, vehicleList, readOnly, suppList, staffList, staffUnder25, renew } = this.state;
        return (   
            <>   
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {readOnly ? 'Insurance Details' : (renew ? 'Renew ' : (insuranceId ? 'Update ' : 'Add ')) + 'Insurance'}
                    </Typography>
                </Grid>     
                <Grid item xs={12}>
                    <form noValidate>
                        {(isLoading && (
                            <LoadingCircle />
                        )) || (
                            <Grid container item spacing={3}>
                                {formErrors && formErrors.generic && (
                                    <Grid item xs={12}>
                                        <Typography component={"div"} style={{color: 'red'}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <Grid container item spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextField 
                                                        name="insuranceCompany"
                                                        label={"Insurance Company " + (readOnly ? '' : '*')}
                                                        onChange={this.handleChange}
                                                        error={formErrors && formErrors['insuranceCompany'] && true}
                                                        helperText={formErrors && formErrors['insuranceCompany']}
                                                        value={formData.insuranceCompany}
                                                        margin="normal"
                                                        fullWidth
                                                        disabled={readOnly}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AutoCompleteSelect 
                                                        options={suppList} 
                                                        label={"Broker "}
                                                        onChange={v => this.handleSelectChange('broker', v)}
                                                        error={formErrors && formErrors['broker'] && true}
                                                        errorText={formErrors && formErrors['broker']}
                                                        value={formData?.broker}
                                                        noClear
                                                        fullWidth
                                                        disabled={readOnly}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField 
                                                        name="policyNumber"
                                                        label={"Policy Number " + (readOnly ? '' : '*')}
                                                        onChange={this.handleChange}
                                                        error={formErrors && formErrors['policyNumber'] && true}
                                                        helperText={formErrors && formErrors['policyNumber']}
                                                        value={formData.policyNumber}
                                                        margin="normal"
                                                        fullWidth
                                                        disabled={readOnly}
                                                        inputProps={{
                                                            className:"textDefault"
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container item spacing={3}>
                                                <Grid item xs={12}>
                                                    <Grid container item spacing={3}>
                                                        <Grid item xs={6}>
                                                            <DatePicker
                                                                type="date"
                                                                id="effectiveDate"
                                                                name="effectiveDate"
                                                                label={"Effective Date " + (readOnly ? '' : '*')}
                                                                value={formData.effectiveDate}
                                                                errorText={formErrors && formErrors['effectiveDate']}
                                                                onChange={date => this.handleDateChange('effectiveDate', date, 'YYYY-MM-DD')}
                                                                autoOk={true}
                                                                disabled={readOnly}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <DatePicker
                                                                type="date"
                                                                id="endDate"
                                                                name="endDate"
                                                                label={"End Date " + (readOnly ? '' : '*')}
                                                                value={formData.endDate}
                                                                errorText={formErrors && formErrors['endDate']}
                                                                onChange={date => this.handleDateChange('endDate', date, 'YYYY-MM-DD')}
                                                                autoOk={true}
                                                                disabled={readOnly}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                            <Typography variant="caption"><em>End date is the last day your policy covers you for</em></Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {insuranceId && !renew ?
                                                        <Link className='blueLink' variant="body2" style={{cursor: 'pointer'}} onClick={() => {downloadS3File(insurance?.fileUrl)}}>
                                                            <div>View Insurance Certificate</div>
                                                        </Link>
                                                    : null}
                                                    {!readOnly && (
                                                        <DragFileInput
                                                            id="certificateFile"
                                                            name="certificateFile"
                                                            label={"Certificate " + (readOnly ? '' : '*')}
                                                            file={formData.certificateFile}
                                                            errorText={formErrors && formErrors['certificateFile']}
                                                            onChange={this.handleFileChange}
                                                            cancelOnClick={() => this.clearValue('certificateFile')}
                                                            emptyText='No file selected'
                                                        />
                                                    )}
                                                </Grid>
                                                {insuranceId && !renew ?
                                                    <Grid item xs={12}>
                                                        <AutoCompleteSelect 
                                                            options={[
                                                                {value: 'Active', label: 'Active'},
                                                                {value: 'Inactive', label: 'Inactive'}
                                                            ]}
                                                            label={"Status " + (readOnly ? '' : '*')}
                                                            onChange={(v) => this.handleSelectChange('status', v)}
                                                            error={formErrors && formErrors['status'] && true}
                                                            errorText={formErrors && formErrors['status']}
                                                            value={formData.status}
                                                            fullWidth
                                                            noClear
                                                            disabled={readOnly}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                        />
                                                    </Grid>
                                                : null }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" paragraph>
                                        <b>Vehicles on Cover</b>
                                    </Typography>
                                    {formErrors && formErrors['vehiclesCovered'] && (
                                        <Typography variant='body2' style={{color: 'red'}} gutterBottom>
                                            {formErrors['vehiclesCovered']}
                                        </Typography>
                                    )}
                                    {readOnly ? 
                                        <>
                                        <DataTable  
                                            config={{
                                                key: 'vehicleReg',
                                                pagination: false,
                                                alternatingRowColours: false,
                                                responsiveImportance: true
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Vehicle',
                                                    field: rowData => <>{rowData?.vehicleReg}<br></br>{rowData?.vehicleDesc}</>,
                                                    main: true,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Type',
                                                    field: rowData => rowData?.vehicleType,
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Year of Make',
                                                    field: rowData => rowData?.vehicleYear,                                            
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Permitted Driver Type',
                                                    field: rowData => <>{rowData?.permittedDriverType} {rowData?.permittedDriverType == 'Any Drivers' && '*'}</>,                                            
                                                    important: true,
                                                },
                                                {
                                                    heading: 'Named Drivers',
                                                    field: rowData => <> {
                                                        rowData?.permittedDrivers ?
                                                            rowData?.permittedDrivers.map((d, index) => (
                                                                <span key={index}>{get(find(staffList, el => el.value == d), 'label')} {index+1 != rowData?.permittedDrivers.length && ','} </span>
                                                            ))
                                                        :
                                                            <span>-</span>
                                                        }
                                                    </>,                                            
                                                    important: true
                                                },
                                                {
                                                    heading: 'Date Added',
                                                    field: rowData => moment(rowData?.dateAdded).format('DD/MM/YYYY'),
                                                    important: true,
                                                },
                                            ]}
                                            rows={formData?.vehiclesCovered}
                                        />
                                        <br></br>
                                        <Typography variant="caption" paragraph>
                                            <em>* Any Drivers - Excluding drivers under 25 {' '}
                                                <Tooltip 
                                                    title={ staffUnder25.length > 0 ? map(staffUnder25, el => <Typography variant="body2">{el}</Typography>) : 'No staff under 25' } 
                                                    placement='right'
                                                >
                                                    <span><FALightIcon icon="info-circle" noMargin size={13} /></span>
                                                </Tooltip> 
                                            </em>
                                        </Typography>
                                        </>
                                    :
                                        map(formData?.vehiclesCovered, (v, idx) => {
                                            return (
                                                <Grid container item spacing={3} direction="row" alignItems="flex-end">
                                                    <Grid item xs={6} md={3}>
                                                        <AutoCompleteSelect 
                                                            options={vehicleList}
                                                            label={"Vehicle " + (readOnly ? '' : '*')}
                                                            onChange={(v) => this.handleRowSelectChange(idx, 'vehicleId', 'vehiclesCovered', v, this.updateVehicleList)}
                                                            error={formErrors && formErrors[idx+'|vehicleId'] && true}
                                                            value={v?.vehicleId}
                                                            fullWidth
                                                            disabled={readOnly}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                            formatOptionLabel={({label, desc}) => (
                                                                <>
                                                                    <Typography variant="body2">{label}</Typography>
                                                                    <Typography variant="caption">{desc}</Typography>
                                                                </>
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} md={2}>
                                                        <AutoCompleteSelect 
                                                            options={[
                                                                {value: 'Named Drivers', label: 'Named Drivers'},
                                                                {value: 'Any Drivers', label: 'Any Drivers'}
                                                            ]}
                                                            label={"Permitted Drivers " + (readOnly ? '' : '*')}
                                                            onChange={(v) => this.handleRowSelectChange(idx, 'permittedDriverType', 'vehiclesCovered', v, this.clearPermittedDrivers(idx))}
                                                            error={formErrors && formErrors[idx+'|permittedDriverType'] && true}
                                                            value={v?.permittedDriverType}
                                                            fullWidth
                                                            noClear
                                                            disabled={readOnly}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <AutoCompleteMultiSelect 
                                                            placeholder=" "
                                                            options={staffList} 
                                                            onChange={v => this.handleRowMultiSelectChange(idx, 'permittedDrivers', 'vehiclesCovered', v)}
                                                            error={formErrors && formErrors[idx+'|permittedDrivers'] && true}
                                                            value={v?.permittedDrivers}
                                                            fullWidth
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                            margin="none"
                                                            disabled={v?.permittedDriverType == 'Named Drivers' ? false : true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5} md={2}>
                                                        <DatePicker
                                                            type="date"
                                                            id="dateAdded"
                                                            name="dateAdded"
                                                            label={"Date Added " + (readOnly ? '' : '*')}
                                                            value={v?.dateAdded}
                                                            errorText={formErrors && formErrors[idx+'|dateAdded']}
                                                            onChange={date => this.handleRowDateChange('vehiclesCovered', idx, 'dateAdded', date, 'YYYY-MM-DD')}
                                                            autoOk={true}
                                                            disabled={readOnly}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                            margin="none"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} md={1} align="center">
                                                        {!readOnly && (
                                                            <AllIcon 
                                                                onClick={() => this.handleRemoveRow('vehiclesCovered', idx, this.updateVehicleList)}
                                                                type="light" 
                                                                noMargin 
                                                                icon='times'
                                                                size={15} 
                                                                tooltip="Delete"
                                                            />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            )}
                                        )
                                    }
                                </Grid>
                                {!readOnly && (
                                    <Grid item xs={12}>
                                        <Button 
                                            onClick={() => this.handleAddRow('vehiclesCovered', {id: 0, vehicleId: 0, dateAdded: null, permittedDriverType: [], permittedDrivers: []})}
                                            variant="outlined"
                                            color="default"
                                            size="small"
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={12} align="right">
                                    <DialogActions className='pr-0 pb-0'>
                                        <Button onClick={() => this.props.onClose()} variant="text">
                                            <AllIcon icon="times" size={15} />
                                            Close
                                        </Button>
                                        {!readOnly && (
                                            <Button onClick={() => this.props.deployConfirmation(`Are you sure you want to ${insuranceId && !renew ? 'update' : 'add'} this insurance?`, `${insuranceId && !renew ? 'Update' : 'Add'} Insurance?`, this.handleSubmit)}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={Object.values(formData).every(x => (x === null || x === ""))}>
                                                {insuranceId && !renew ? 'Update' : 'Add'}
                                            </Button>
                                        )}
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Grid>  
            </Grid>
            </>  
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(VehicleInsuranceForm);