import {
    Button,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    Box
} from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import TabBar from 'Components/Common/Navigation/TabBar';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import LinkEmailCampaignDialog from 'Components/Marketing/EmailCampaigns/NewEmailCampaign/LinkEmailCampaignDialog';
import NewEmailCampaignDialog from 'Components/Marketing/EmailCampaigns/NewEmailCampaign/NewEmailCampaignDialog';
import AddContactDialog from 'Components/Marketing/EmailCampaigns/ViewMailingList/AddContactDialog';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import AddAutomatedContactsDialog from './AddAutomatedContactDialog';
import Overview from './tabs/Overview';

import ActivityLog from './tabs/ActivityLog';
import AddContacts from './tabs/AddContacts';
import ContactsInvalid from './tabs/ContactsInvalid';
import ContactsManual from './tabs/ContactsManual';
import ContactsRemoved from './tabs/ContactsRemoved';
import ContactsSystem from './tabs/ContactsSystem';
import ContactsUnsubscribed from './tabs/ContactsUnsubscribed';
import ContactsWarning from './tabs/ContactsWarning';
import ContactsLapsed from './tabs/ContactsLapsed';

import DownloadCSV from 'Components/Common/Buttons/DownloadCSV';
import BackButton from 'Components/Common/Buttons/BackButton';
import { clenyDate } from 'Helpers/TimeHelper';

const initialState = () => ({
    isLoading: true,
    mailingList: {},
    updateFormData :{
        show: false,
        name: '',
        description: '',
    },
    newContactsCount: 0,
    currentTab: 'o',
    access: {
        view: false,
        update: false,
        inactivate: false,
        reactivate: false,
    }
})

class ViewMailingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getAccess();
        this.getMailingList();
    }

    getAccess = () => {
        Promise.all(
            _.map(_.keys(this.state.access), i =>  API.get(`/staff/my/access/check/${i}-mailing-lists`))
        ).then(res => {
            let access = this.state.access;
            _.each(res, (i,idx) => {
                access[_.keys(this.state.access)[idx]] = i.data.has_access ?? false;
            });
            this.setState({ access });
        })
    }

    getMailingList = () => {
        this.setState({dataLoading: true}, ()=>{
            API.get(`/marketing/campaigns/mailingLists/${this.props.match.params.id}`)
            .then(res => {
                this.setState({
                    mailingList: res.data, 
                    updateFormData: {
                        ...this.state.updateFormData,
                        name: res.data.n,
                        description: res.data.d,
                    },
                    dataLoading: false,
                    isLoading: false,
                });
            })
        });
    }

    removeContact = (contact) => {
        API.post(`/marketing/campaigns/mailingLists/${this.props.match.params.id}/contacts/${contact.m}/remove`)
        .then(this.getMailingList);
    }

    reinstateContact = (contact) => {
        API.post(`/marketing/campaigns/mailingLists/${this.props.match.params.id}/contacts/${contact.m}/reinstate`)
        .then(this.getMailingList);
    }

    addCampaign = () => {
        this.props.deployDialog(
            <LinkEmailCampaignDialog
                mlId={this.props.match.params.id}
                onClose={this.closeAddCampaign}
                onNewCampaign={this.addNewCampaign}
            />,
            'Link Email Campaign',
            'sm'
        )
    }

    addNewCampaign = () => {
        this.props.closeDialog();
        this.props.deployDialog(
            <NewEmailCampaignDialog
                defaultMailingList={this.props.match.params.id}
                onClose={this.closeAddCampaign}
            />,
            'Create New Email Campaign',
            'fs'
        )
    }

    closeAddCampaign = () => {
        this.props.closeDialog();
        this.getMailingList();
    }

    showAddContactDialog = () => {
        this.props.deployDialog(
            <AddContactDialog
                mlId={this.props.match.params.id}
                onClose={this.closeAddContactDialog}
                existingContacts={[...this.state.mailingList?.c?.c, ...this.state.mailingList?.c?.r, ...this.state.mailingList?.c?.o]}
            />,
            'Add Contacts',
            'md'
        )
    }

    closeAddContactDialog = () => {
        this.props.closeDialog();
        this.getMailingList();
    }

    showUpdateName = () => {
        this.setState({
            updateFormData: {
                name: this.state.mailingList.n,
                description: this.state.mailingList.d,
                show: !this.state.updateFormData.show,
            }
        })
    }

    updateMailingList = () => {
        API.post(`/marketing/campaigns/mailingLists/${this.props.match.params.id}/update`,  this.state.updateFormData)
        .then(()=>{ this.getMailingList(); this.showUpdateName(); } )
    }

    showAutomatedContactsDialog = () => {
        this.props.deployDialog(
            <AddAutomatedContactsDialog
                mailingList={this.state.mailingList}
                onUpdate={this.getMailingList}
            />,
            'Add Contacts'
        )
    }

    setTab = (event, tab) => {
        this.setState({currentTab: tab});
    }

    addContacts = () => {
        this.props.deployDialog(
            <Box style={{height: '100%', padding: '1em'}}>
                <AddContacts mailingList={this.state.mailingList} refresh={ () => {
                    this.props.closeDialog();
                    this.getMailingList();
                } } />
            </Box>,
            'Add Contacts',
            null,
            'md'
        )
    }

    deleteMailingList = () => {
        API.post(`/marketing/campaigns/mailingLists/${this.state.mailingList.i}/delete`)
        .then(this.getMailingList)
    }

    reactivateMailingList = () => {
        API.post(`/marketing/campaigns/mailingLists/${this.state.mailingList.i}/revive`)
        .then(this.getMailingList)
    }

    render() {
        const { isLoading, mailingList, updateFormData, currentTab, access } = this.state;

        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        return (
            <Grid container spacing={3}>
                {updateFormData.show &&
                    <Dialog
                        open={updateFormData.show}
                        maxWidth="sm"
                        fullWidth
                    >
                        <DialogTitle>Update Mailing List</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Name *"
                                        value={updateFormData.name}
                                        onChange={(e) => this.setState({updateFormData: {...updateFormData, name: e.target.value}})}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Textarea
                                        label="Description"
                                        value={updateFormData.description}
                                        onChange={(e) => this.setState({updateFormData: {...updateFormData, description: e.target.value}})}
                                        fullWidth
                                        rows={4}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={this.showUpdateName}
                            >Close</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!updateFormData.name}
                                onClick={()=>this.props.deployConfirmation(
                                    'Are you sure you want to update this mailing list?',
                                    'Update Mailing List',
                                    this.updateMailingList
                                )}
                            >Update</Button>
                        </DialogActions>
                    </Dialog>
                }   
                <Grid item xs={12}>
                    <Typography variant="h5">Mailing List</Typography>
                </Grid>
                {
                    (
                        mailingList?.c?.q?.length > 0 ||
                        mailingList?.c?.w?.length > 0 ||
                        mailingList.s === 'Deleted'
                    )
                }
                {
                    (
                        ( mailingList.s === 'Deleted') ||
                        ( mailingList?.c?.q?.length > 0 ) ||
                        ( mailingList?.c?.w?.length > 0 ) 
                    ) &&
                    <Grid item xs={12}>
                        <PaddedPaper
                            style={{
                                backgroundColor: (
                                    ( mailingList.s === 'Deleted' && colors.disabled ) ||
                                    ( mailingList?.c?.q?.length > 0 && colors.red ) ||
                                    ( mailingList?.c?.w?.length > 0 && colors.orange ) 
                                ),
                                color: mailingList.s === 'Deleted' ? colors.black : colors.white,
                                padding: 15
                            }}
                        >
                            <Typography variant="body1" style={{display: 'inline-block', marginLeft: 10, fontWeight: 'bold'}}>
                                <AllIcon icon={IconHelper.warning} white={mailingList.s !== 'Deleted'} size={20} heavy />
                                {
                                    ( mailingList.s === 'Deleted' && `This mailing list is inactive`) ||
                                    ( mailingList?.c?.q?.length > 0 && `This mailing list has invalid contacts` ) ||
                                    ( mailingList?.c?.w?.length > 0 && `This mailing list has contacts with warnings` )
                                }
                            </Typography>
                        </PaddedPaper>
                    </Grid>
                }
                <Grid item xs={8}>
                    <PaddedPaper style={{height: '100%', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center'}}>
                        <Typography variant="h5" style={{display: 'flex', width: '100%', marginBottom: 0, alignItems: 'flex-end'}}>{mailingList.p}
                            { ( access.update && mailingList.s !== 'Deleted' ) &&
                                <span style={{marginLeft: 'auto'}} ><AllIcon icon={IconHelper.edit} onClick={this.showUpdateName} noMargin /></span>
                            }
                        </Typography>
                        <Typography variant="h6" style={{display: 'flex', width: '100%'}}>{mailingList.n}</Typography>
                        {mailingList.d && <Typography variant="body1" gutterBottom style={{whiteSpace: 'pre-wrap'}}>{mailingList.d}</Typography>}
                        <Typography variant="body2" gutterBottom style={{whiteSpace: 'pre-wrap'}}>Date Added: {clenyDate(mailingList.o)}</Typography>
                        {
                            mailingList.t !== 'Manual' &&
                            <Typography variant="caption">
                                {(
                                    ( mailingList.t === 'Custom' && 'Based on custom search parameters' ) ||
                                    ( mailingList.t === 'PartOwnership'
                                        && '* Based on owners of a ' +  mailingList.l
                                    ) ||
                                    ( 
                                        (
                                            mailingList.t === 'CustomerDiscount'
                                                && '* Based on customers with a ' +  mailingList.l + '% discount'
                                        ) ||
                                        (
                                            (
                                                mailingList.t === 'CustomerSize' 
                                                    && `* Based on ${ mailingList.l} size customers ${ {
                                                        'Small': '(1 Site)',
                                                        'Medium': '(2-10 Sites)',
                                                        'Large': '(10+ Sites)'
                                                    }[mailingList.l] }`
                                            ) || 
                                            (
                                                (
                                                    mailingList.t === 'CustomerLegalStatus' 
                                                        && '* Based on customers with \'' +  mailingList.l + '\' legal status'
                                                ) || 
                                                (
                                                    (
                                                        mailingList.t === 'CustomerPaymentTerms'
                                                            && '* Based on customers with \'' +  mailingList.l + '\' payment terms'
                                                    ) || 
                                                    (
                                                        (
                                                            mailingList.t === 'ServicePlan'
                                                                && '* Based on customers ' +  (parseInt(mailingList.l) ? 'with a service plan' : 'without a service plan')
                                                        ) || 
                                                        (
                                                            (
                                                                mailingList.t === 'CustomerMarketing'
                                                                    && '* Based on customers with selected marketing preferences; ' +  _.map(mailingList.m, i => i.customer_marketing.cm_name).join(',')
                                                            ) || 
                                                            (
                                                                (
                                                                    mailingList.t === 'LegalStatus'
                                                                        && '* Based on customers with legal status - ' +  JSON.parse(mailingList.l).status
                                                                ) || 
                                                                
                                                                (
                                                                    mailingList.t === 'BusinessType'
                                                                        && '* Based on customers with Business Type - ' +  JSON.parse(mailingList.l).type
                                                                ) || mailingList.l
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )}
                            </Typography>
                        }
                        
                    </PaddedPaper>
                </Grid>
                <Grid item xs={4} style={{marginLeft: 'auto'}}>
                    <Grid container spacing={2}>
                        {
                            (
                                mailingList.t === 'manual' &&
                                mailingList.s !== 'Deleted' &&
                                access.update
                            ) &&
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={this.addContacts}
                                    >Add Contacts</Button>
                                </Grid>
                        }   
                        <Grid item xs={12}>
                            {mailingList.s === 'Deleted' ? 
                                (
                                    access.reactivate &&
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        color='primary'
                                        onClick={() => this.props.deployConfirmation(
                                            'Are you sure you want to reactivate this mailing list?',
                                            'Reactivate Mailing List',
                                            this.reactivateMailingList
                                        )}
                                    >Reactivate Mailing List</Button>
                                )
                                :
                                (
                                    access.inactivate &&
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        color='secondary'
                                        onClick={() => this.props.deployConfirmation(
                                            'Are you sure you want to inactivate this mailing list?',
                                            'Inactivate Mailing List',
                                            this.deleteMailingList
                                        )}
                                    >Inactivate Mailing List</Button>
                                )
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <DownloadCSV 
                                data={_.map(
                                    mailingList?.c?.c,
                                    i => ({
                                        'Name': i.contact.contact_name,
                                        'Email': i.contact.contact_email,
                                        'System Contact': i.contact.customer ? 'Yes' : 'No',
                                        'Customer': i.contact.customer ? i.contact.customer.cust_name : '',
                                    })
                                )} 
                                label={''} 
                                filename={`mailing_list_${mailingList.n.toLowerCase().split(' ').join('_')}.csv`}
                                variant='contained'
                                color='primary'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TabBar
                        handleTabChange={this.setTab}
                        currentTab={currentTab}
                        tabs={[
                            {
                                label:   'Overview',
                                value: 'o',
                            },
                            {
                                label:   `System Contacts (${_.filter(mailingList?.c?.c, i => i.contact.customer)?.length})`,
                                value: 'sc',
                            },
                            {
                                label:   `Manual Contacts (${_.filter(mailingList?.c?.c, i => !i.contact.customer)?.length})`,
                                value: 'mc',
                            },
                            {
                                label:   `Warning Contacts (${mailingList?.c?.w?.length})`,
                                value: 'wrn',
                            },
                            {
                                label:   `Invalid  Contacts (${mailingList?.c?.q?.length})`,
                                value: 'inv',
                            },
                            {
                                label:   `Removed Contacts (${mailingList?.c?.r?.length})`,
                                value: 'rc',
                            },
                            {
                                label:   `Unsubscribed Contacts (${mailingList?.c?.o?.length})`,
                                value: 'uc',
                            },
                            {
                                label:  `Lapsed Contacts (${mailingList?.c?.l?.length})`,
                                value: 'lc',
                            },
                            {
                                label:   `Activity Log`,
                                value: 'acl',
                            },
                        ]}
                    />
                    {currentTab === 'o' &&
                        <PaddedPaper>
                            <Overview mailingList={mailingList} refresh={this.getMailingList} dataLoading={this.state.dataLoading} />
                        </PaddedPaper>
                    }
                    {currentTab === 'sc' &&
                        <PaddedPaper>
                            <ContactsSystem mailingList={mailingList} refresh={this.getMailingList} dataLoading={this.state.dataLoading} />
                        </PaddedPaper>
                    }
                    {currentTab === 'mc' &&
                        <PaddedPaper>
                            <ContactsManual mailingList={mailingList} refresh={this.getMailingList} dataLoading={this.state.dataLoading} />
                        </PaddedPaper>
                    }
                    {currentTab === 'rc' &&
                        <PaddedPaper>
                            <ContactsRemoved mailingList={mailingList} refresh={this.getMailingList} dataLoading={this.state.dataLoading} />
                        </PaddedPaper>
                    }
                    {currentTab === 'lc' &&
                        <PaddedPaper>
                            <ContactsLapsed mailingList={mailingList} refresh={this.getMailingList} dataLoading={this.state.dataLoading} />
                        </PaddedPaper>
                    }
                    {currentTab === 'uc' &&
                        <PaddedPaper>
                            <ContactsUnsubscribed mailingList={mailingList} refresh={this.getMailingList} dataLoading={this.state.dataLoading} />
                        </PaddedPaper>
                    }
                    {currentTab === 'acl' &&
                        <PaddedPaper>
                            <ActivityLog mailingList={mailingList} refresh={this.getMailingList} dataLoading={this.state.dataLoading} />
                        </PaddedPaper>
                    }
                    {currentTab === 'wrn' &&
                        <PaddedPaper>
                            <ContactsWarning mailingList={mailingList} refresh={this.getMailingList} dataLoading={this.state.dataLoading} />
                        </PaddedPaper>
                    }
                    {currentTab === 'inv' &&
                        <PaddedPaper>
                            <ContactsInvalid history={this.props.history} mailingList={mailingList} refresh={this.getMailingList} dataLoading={this.state.dataLoading} />
                        </PaddedPaper>
                    }
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <BackButton history={this.props.history} />
                </Grid>
            </Grid>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback) => {
            dispatch(deployConfirmation(content, title, callback))
        },
        deployDialog: (content, title, size) => {
            dispatch(deployDialog(content, title, 'system', size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

export default connect(null, mapDispatchToProps)(ViewMailingList);