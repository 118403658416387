import React, {Component}  from 'react';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import {FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Button, FormControl, Grid, Typography } from '@material-ui/core';
import DatePicker from '../../Common/DatePickers/DatePicker';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import moment from 'moment';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from '../../Common/Selects/AutoCompleteMultiSelect';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';

import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        category: '',
        subCategory: '',
        title: '',
        fileUploads: 'both',
        originalDocument:  '',
        pdfDocument:  '',
        associatedParts: [],
        technicalLibraryAccess: 'No',
        dateAdded: moment().toString(),
    },
    showOriginalDocument: true,
    showPdfDocument: true,
    categoryList: [],
    subCategoryList: [],
    categories: {},
    partsList: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false,
    initialLoading: true
}


class AddDocument extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getCategories();
        this.getParts();
    }

    getParts = () => {        
        API.get('/parts/all' , {params: {active: true}})
        .then((result) => {
            let partsList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description
                });
            });
            this.setState({
                partsList: partsList
            });
        })
    }

    getCategories = () => {
        API.get('/documents/categories/all').then(result => {
            if(result.data) {
                let categoryList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.doc_cat_id,
                        label: el.doc_cat_name
                    });
                });
                this.setState({
                    categoryList: categoryList,
                    categories: result.data,
                    initialLoading: false
                });
            } else {
                /* Error */
            }
        });
    }

    getSubCategories = (categoryID) => {
        if(categoryID) {
            let catList = _.find(this.state.categories, { 'doc_cat_id': categoryID });
            let subCategoryList = _.map(catList.children, (el) => {
                return _.assign({
                    value: el.doc_cat_id,
                    label: el.doc_cat_name
                });
            });
            this.setState({
                subCategoryList: subCategoryList
            });
        } else {
            this.setState({
                subCategoryList: initialState.subCategoryList
            });
            this.setState({
                formData: {
                  ...this.state.formData,
                  subCategory: null
                }
            });
        }
    }
    
    handleDateChange = date => {
        this.setState({
            formData: {
                ...this.state.formData,
                dateAdded: date
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    }

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            let newFormData = new FormData();
            Object.keys(this.state.formData).forEach(key => {
                if(key === 'dateAdded'){
                    newFormData.append(key, moment(new Date(this.state.formData[key])).format('DD/MM/YYYY'))
                }
                else{
                    newFormData.append(key, this.state.formData[key])
                }
                
            });
            API.post('/documents', newFormData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: true
                    });
                    this.getCategories();
                    this.getParts();
                }
                this.props.scrollToTop();
            });
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    }

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: res && res.value
            }
        }, 
        () => {
            if(name === 'category'){
                this.setState({
                    formData: {
                      ...this.state.formData,
                      subCategory: null
                    }
                },
                () => {
                    this.getSubCategories(res && res.value);
                });
            } else if(name === 'fileUploads'){
                switch(this.state.formData.fileUploads) {
                    case 'both':
                        this.setState({showOriginalDocument: true, showPdfDocument: true});
                    break;
                    case 'pdf_only':
                        this.setState({showOriginalDocument: false, showPdfDocument: true});
                    break;
                    case 'original_only':
                        this.setState({showOriginalDocument: true, showPdfDocument: false, 
                            formData: {
                                ...this.state.formData,
                                technicalLibraryAccess: 'No'
                            }
                        });
                    break;
                    default:
                        this.setState({showOriginalDocument: false, showPdfDocument: false});
                    break;
                }
            }
        });
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    clearPdfDocument = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                pdfDocument: initialState.formData.pdfDocument
            }
        });
    }

    clearOriginalDocument = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                originalDocument: initialState.formData.originalDocument
            }
        });
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions && selectedOptions.map(a => a.value);
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }
       
    render() {
        const { formErrors, initialLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Document
                    </Typography>
                </Grid>
                {(initialLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                )) || (
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <Typography variant="h6">
                                        Document Details
                                    </Typography>
                                    {formErrors && formErrors.generic && (
                                        <React.Fragment>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {formErrors.generic}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                    <form noValidate autoComplete="off">
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={this.state.categoryList} 
                                                label='Category *'
                                                value={this.state.formData.category}
                                                onChange={this.handleSelectChange('category')}
                                                error={formErrors && formErrors['category'] && true}
                                                errorText={formErrors && formErrors['category'] && formErrors['category']}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={this.state.subCategoryList} 
                                                label='Sub Category *'
                                                value={this.state.formData.subCategory}
                                                onChange={this.handleSelectChange('subCategory')}
                                                error={formErrors && formErrors['subCategory'] && true}
                                                errorText={formErrors && formErrors['subCategory'] && formErrors['subCategory']}
                                            />
                                        </FormControl>
                                        <TextField
                                            id="title"
                                            name="title"
                                            label="Title *"
                                            value={this.state.formData.title}
                                            error={formErrors && formErrors['title'] && true}
                                            helperText={formErrors && formErrors['title']}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <FormControl error={formErrors && formErrors['fileUploads'] && true} fullWidth margin="normal">
                                            <AutoCompleteSelect
                                                options={[
                                                    {value: 'both', label: 'Both (Original and PDF)'},
                                                    {value: 'original_only', label: 'Original Only'},
                                                    {value: 'pdf_only', label: 'PDF Only'}
                                                ]}
                                                label='File Uploads *'
                                                value={this.state.formData.fileUploads}
                                                onChange={this.handleSelectChange('fileUploads')}
                                                error={formErrors && formErrors['fileUploads'] && true}
                                                errorText={formErrors && formErrors['fileUploads'] && formErrors['fileUploads']}
                                            />
                                        </FormControl>
                                        {this.state.showOriginalDocument &&
                                            <DragFileInput
                                                id="originalDocumentInput"
                                                name="originalDocument"
                                                label="Original Document (.doc, .docx) *"
                                                file={this.state.formData.originalDocument}
                                                errorText={formErrors && formErrors['originalDocument']}
                                                onChange={this.handleFileChange}
                                                cancelOnClick={this.clearOriginalDocument}
                                                emptyText='No document selected'
                                            />
                                        }
                                        {this.state.showPdfDocument &&
                                            <DragFileInput
                                                id="pdfDocumentInput"
                                                name="pdfDocument"
                                                label="PDF Document (.pdf) *"
                                                file={this.state.formData.pdfDocument}
                                                errorText={formErrors && formErrors['pdfDocument']}
                                                onChange={this.handleFileChange}
                                                cancelOnClick={this.clearPdfDocument}
                                                emptyText='No document selected'
                                            />
                                        }
                                        <FormControl margin="normal" fullWidth>
                                            <AutoCompleteMultiSelect 
                                                options={this.state.partsList} 
                                                label='Associated Parts'
                                                value={this.state.formData.associatedParts}
                                                onChange={this.handleMultiSelectChange('associatedParts')}
                                                error={formErrors && formErrors['associatedParts'] && true}
                                                errorText={formErrors && formErrors['associatedParts']}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth margin="normal" component="fieldset">
                                            <FormLabel component="legend">Available in Technical Library</FormLabel>
                                            <RadioGroup
                                                name="technicalLibraryAccess"
                                                value={this.state.formData.technicalLibraryAccess}
                                                onChange={this.handleChange}
                                                row
                                            >
                                                <FormControlLabel
                                                    value='No'
                                                    control={<Radio color="primary" />}
                                                    label="No"
                                                    labelPlacement="end"
                                                    disabled={this.state.formData.fileUploads === 'original_only' ?? true}
                                                />
                                                <FormControlLabel
                                                    value='Yes'
                                                    control={<Radio color="primary" />}
                                                    label="Yes"
                                                    labelPlacement="end"
                                                    disabled={this.state.formData.fileUploads === 'original_only' ?? true}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        <DatePicker
                                            type="date"
                                            id="poi"
                                            name="poi"
                                            label="Date Added *"
                                            value={this.state.formData.dateAdded}
                                            errorText={formErrors && formErrors['dateAdded']}
                                            onChange={this.handleDateChange}
                                            disableFuture={true}
                                        />
                                        <div className='buttonRow' style={{justifyContent: 'space-between', alignItems:'center'}}>
                                            <Typography variant="subtitle2">
                                                <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                                            </Typography>
                                            <Button onClick={this.handleConfirmationOpen}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={this.state.isLoading}
                                                >
                                                Add
                                            </Button>
                                        </div>
                                        <ConfirmationDialog 
                                            open={this.state.confirmationOpen} 
                                            success={this.handleConfirmationSuccess} 
                                            close={this.handleConfirmationClose} 
                                            title="Add A New Document?" 
                                            message="Are you sure you want to add this new document?"
                                        />
                                        <SnackBar
                                            variant="success"
                                            anchorOriginVertical='bottom'
                                            anchorOriginHorizontal='right'
                                            open={this.state.snackbarOpen}
                                            onClose={this.handleSnackbarClose}
                                            message='You have successfully added a new document'
                                        />
                                    </form>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

export default AddDocument;
