import React, { useState } from 'react';
import { useDispatch }     from 'react-redux';
import moment              from 'moment';
import API                 from 'API';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { colors }                 from 'Helpers/ColourHelper';

import {Typography, TextField, InputAdornment, Button, Grid } from '@material-ui/core';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import DatePicker    from 'Components/Common/DatePickers/DatePicker';

import { closeDialog }        from 'Actions/Dialog/Dialog';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deploySnackBar }     from 'Actions/SnackBar/SnackBar';

const FOCDialog = ({id, callback, type, amount = 0, paymentTerm, amountOutstanding}) => {
    const dispatch = useDispatch();
    const initialState = {
        formData: {
            paymentAmount:  0,
            paymentMethod:  'FOC',
            reason:         '',
            date:           moment()
        },
        formErrors:[],
        isLoading: false
    };
    let [state, setState] = useState(initialState);
    const {formData, formData:{date, reason}, isLoading, formErrors} = state;

    const handleClose = () => dispatch(closeDialog());

    const handleFormChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleDateChange = e => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                date: moment(e)
            }
        }));
    }

    const handleSubmit = () => dispatch(deployConfirmation('Are you sure you want to process payment', 'Process Payment?', submit));
    
    const submit = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));
        let url = '';
        switch(type){
            case 'invoice':
                url = `/sales/orders/invoice/${id}/processPayment`;
            break;
            default:
                url = `/sales/orders/${id}/processPayment`;
            break;
        }
        API.post(url, formData)
        .then(result => {
            if(result.data.errors) {
                setState(state => ({
                    ...state,
                    formErrors: formatValidationErrors(result.data.errors),
                    isLoading: false
                }));
            } else if(result.data.success) {
                if(callback) callback();
                dispatch(deploySnackBar('success', 'You have successfully processed the cash payment!'));
                handleClose();
            }
        });
    }

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <TextField
                            label="Payment Term"
                            value={paymentTerm}
                            margin="normal"
                            fullWidth
                            disabled
                            InputProps={{
                                className: 'disabledText'
                            }}
                        />
                        <TextField
                            label="Amount Outstanding"
                            value={amountOutstanding}
                            margin="normal"
                            fullWidth
                            disabled
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                className: 'disabledText'
                            }}
                        />
                        <TextField 
                                id="reason"
                                name="reason"
                                label="Reason: *"
                                value={reason}
                                error={formErrors && formErrors['reason'] && true}
                                helperText={formErrors && formErrors['reason']}
                                onChange={handleFormChange}
                                margin="normal"
                                type="text"
                                fullWidth
                            />
                        <DatePicker
                            type="date"
                            id="date"
                            name="date"
                            label="Payment Date *"
                            value={date}
                            // errorText={formErrors && formErrors['date']}
                            onChange={handleDateChange}
                            autoOk={true}
                        />
                    </Grid>
                    {!!parseFloat(amountOutstanding) &&
                        <Grid item xs={12}>
                            <br></br>
                            <Typography variant="body2" style={{float:'right', color:colors.red}} gutterBottom>
                                Amount Outstanding Must Be £0.00
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <div className="buttonRow">
                            <Button 
                                variant="outlined" 
                                color="default" 
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={handleSubmit}
                                disabled={parseFloat(amountOutstanding)}
                            >
                                Confirm
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
}

export default FOCDialog;