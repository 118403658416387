import React, { Component } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';
import moment from 'moment';

import API from 'API';

import { orderStatusIcons, orderPaymentMethodIcons } from 'Helpers/SalesHelper';
import { statusColour, colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';

import DataTable            from 'Components/Common/DataTables/CiDataTable';
import SnackBar             from 'Components/Common/SnackBars/SnackBar';
import PaddedPaper          from 'Components/Common/Paper/PaddedPaper';
import FALightIcon          from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon          from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import AllIcon              from 'Components/Common/Icons/AllIcon';
import AutoCompleteSelect   from 'Components/Common/Selects/AutoCompleteSelect';
import DatePicker           from 'Components/Common/DatePickers/DatePicker';
import StickyNotes          from 'Components/Common/StickyNotes/StickyNotes';
import OrderStats           from 'Components/Sales/Order/OrderStats/OrderStats';
import OrderSearchBlock     from 'Components/Sales/Order/OrderSearch/OrderSearchBlock';

import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import {DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE} from 'Constants'
import { gridPageChanged, gridRowsPerPageChanged } from 'Functions/FormFunctions'
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const styles = () => ({
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }
});

const initialState = () => ({
    searchCriteria: {
        keywords: '',
        status: 'All',
        dateFrom: null,
        dateTo: null,
    },
    access: {
        customerOrderSearch: false,
        viewOrder: false
    },
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
    order: {},
    orderStats: [],
    searchResults: {},
    isLoading: false,
    emailDialogOpen: false,
    customerName: ''
});
class CustomerOrderSearch extends Component {
    constructor(props) {
        super(props);    
        this.clearPageState             = clearPageState.bind(this);
        this.getInitialState            = getInitialState.bind(this);
        this.hasPageState               = hasPageState.bind(this);
        this.savePageState              = savePageState.bind(this);
        this.persistenceId              = 'CustomerOrder:Search';
        this.state                      = this.getInitialState(initialState());
        this.gridPageChanged            = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged     = gridRowsPerPageChanged.bind(this);
    }

    componentDidMount = () => {
        this.getSearchData();
        //this.getStats();
    }

   /* getStats = () => {
        this.setState({
            isLoading: true
        }, () => {
            let customerId = this.props.match.params.id;
            API.get(`/customers/${customerId}/sales/orders?slim=1`)
            .then(statsRes => {
                let orderStats = {
                    approval:           {all: {count: 0, sub_total: "0.00", vat: "0.00", total: "0.00"}},
                    cancelled:          {all: {count: 0, sub_total: "0.00", vat: "0.00", total: "0.00"}},
                    completed:          {all: {count: 0, sub_total: "0.00", vat: "0.00", total: "0.00"}},
                    confirmed:          {all: {count: 0, sub_total: "0.00", vat: "0.00", total: "0.00"}},
                    declined:           {all: {count: 0, sub_total: "0.00", vat: "0.00", total: "0.00"}},
                    dispatch:           {all: {count: 0, sub_total: "0.00", vat: "0.00", total: "0.00"}},
                    invoice:            {all: {count: 0, sub_total: "0.00", vat: "0.00", total: "0.00"}},
                    payment:            {all: {count: 0, sub_total: "0.00", vat: "0.00", total: "0.00"}},
                    paymentConfirmed:   {all: {count: 0, sub_total: "0.00", vat: "0.00", total: "0.00"}},
                    picking:            {all: {count: 0, sub_total: "0.00", vat: "0.00", total: "0.00"}},
                }
                let field = null;
                _.map(statsRes.data, i => {
                    switch (i.s){
                        case 'Awaiting Invoice Completion':
                        case 'Invoice':             field = 'invoice';            break;
                        case 'Awaiting Approval':   field = 'approval';           break;
                        case 'Cancelled':           field = 'cancelled';          break;
                        case 'Completed':           field = 'completed';          break;
                        case 'Awaiting Payment':    field = 'payment';            break;
                        case 'Declined':            field = 'declined';           break;
                        case 'Payment Confirmed':   field = 'paymentConfirmed';   break;
                        case 'Picking':             field = 'picking';            break;
                        case 'Awaiting Despatch':   field = 'dispatch';           break;
                    }
                    if (field){
                        let r = orderStats[field];
                        let a = r.all;
                        a.count++;
                        a.sub_total = parseFloat(parseFloat(a.sub_total) + parseFloat(i.tl.s)).toFixed(2);
                        a.vat       = parseFloat(parseFloat(a.vat)       + parseFloat(i.tl.v)).toFixed(2);
                        a.total     = parseFloat(parseFloat(a.total)     + parseFloat(i.tl.iv)).toFixed(2);
                        orderStats = {...orderStats, [field]: {all: a} };
                    }
                })
                this.setState({orderStats,isLoading: false });
            })
        });
    }*/


    getSearchData = () => {
        const params = {
            keywords: this.state.searchCriteria.keywords,
            status  : this.state.searchCriteria.status,
            dateFrom: this.state.searchCriteria.dateFrom !== null   ? moment(this.state.searchCriteria.dateFrom).format('YYYY-MM-DD')   : null,
            dateTo  : this.state.searchCriteria.dateTo !== null     ? moment(this.state.searchCriteria.dateTo).format('YYYY-MM-DD')     : null,
            slim    : 1
        }
        this.setState({
            isLoading: true
        }, () => {
            let customerId = this.props.match.params.id;
            Promise.all([
                API.get('/staff/my/access/check/customer-order-search'),
                API.get('/staff/my/access/check/view-customer-order'),
                API.get(`/customers/${customerId}/sales/orders`, {params}),
            ])
            .then(([searchAccessRes, viewAccessRes, searchRes]) => {
                let customer = _.first(searchRes.data, {'order_cust_id': customerId});
                _.map(searchRes.data, o => {
                     // Status Colour
                     o.color = statusColour(o.s);
                     o.icon = orderStatusIcons(o.s);
 
                     switch(o.s){
                         case 'Awaiting Approval':
                             o.toolTip1 = 'Order Acknowledgment';
                             o.toolTip2 = 'Awaiting Approval';
                         break;
                         case 'Confirmed':
                             o.toolTip1 = 'Order Confirmed'
                         break;
                         case 'Awaiting Payment':
                             o.toolTip1 = 'Awaiting Payment';
                         break;
                         case 'Payment Confirmed':
                             o.toolTip1 = 'Payment Confirmed';
                         break;
                         case 'Picking':
                             o.toolTip1 = 'Awaiting Picking';
                         break;
                         case 'Awaiting Despatch':
                             o.toolTip1 = 'Awaiting Despatch';
                         break;
                         case 'Invoice':
                             o.toolTip1 = 'Awaiting Invoice';
                         break;
                         case 'Completed':
                             o.toolTip1 = 'Order Completed';
                             o.toolTip2 = moment(o.d).format('DD/MM/YYYY');
                         break;
                         case 'Declined':
                             o.toolTip1 = 'Order Declined';
                             o.toolTip2 = moment(o.d).format('DD/MM/YYYY');
                         break;
                         case 'Cancelled':
                             o.toolTip1 = 'Order Cancelled';
                             o.toolTip2 = moment(o.d).format('DD/MM/YYYY');
                         break;
                         case 'Awaiting Invoice Completion':
                             o.toolTip1 = 'Awaiting Invoice Completion';
                         default:
                         break;
                     }
 
                     _.map(o.in, i => {
                         i.color     = (i.c) ? '#009933' : '#D9A200';
                         i.toolTip   = (i.m === 'Card') ? 'Barclaycard' : i.m;
                         i.icon      = orderPaymentMethodIcons(i.m);
                     }); 
                });
                
                this.setState({
                    ...this.state,
                    access: {
                        customerOrderSearch: (searchAccessRes.data && searchAccessRes.data.has_access) || false,
                        viewOrder: (viewAccessRes.data && viewAccessRes.data.has_access) || false
                    },
                    searchResults: searchRes.data,
                    customerName: customer && customer.order_cust_name,
                    isLoading: false
                });
            });
        });
    }

    handleSearchKeyword = (e) => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                keywords: e.target.value
            },
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            searchCriteria:initialState().searchCriteria,
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            //this.props.history.replace();
            this.getSearchData();
        });
    }

    handleDownloadOpen = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');

    handleEmailOpen = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md');

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                [fieldName]: selectedOption && selectedOption.value
            },
            //gridPagination: initialState().gridPagination
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    handleDateChange = name => date => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                [name]: date
            },
            //gridPagination: initialState().gridPagination
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    handleShowStickyNote = orderId => { this.props.deployDialog(<StickyNotes orderId={orderId} dialog/>, 'Sticky Notes', null,'xs') }

    handleSetSearch = props => {
        this.setState({
            searchCriteria: {...props}
        },this.getSearchData)
    }

    render() {
        const { access, customerName, searchCriteria, gridPagination } = this.state;
        const { classes } = this.props;
        if(customerName !== '') {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Customer Order Search
                        </Typography>
                    </Grid>
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={12}>
                                <OrderSearchBlock disableSites  callBack={this.handleSetSearch} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h5">
                                        <strong>{customerName}</strong>
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                        Customer Orders<br></br><br></br>
                                </Typography>
                                <DataTable  
                                    config={{
                                        key: 'order_id',
                                        pagination: true,
                                        page: gridPagination.page,
                                        rowsPerPage: gridPagination.rowsPerPage,
                                        gridPageChanged: this.gridPageChanged,
                                        gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                        alternatingRowColours: true,
                                        responsiveimportant: true,
                                        isLoading: this.state.isLoading
                                    }}
                                    columns={[
                                        {
                                            heading: 'Order Date',
                                            field: rowData => moment(rowData.d).format("DD/MM/YYYY"),
                                            important: true,
                                            sizeToContent: true
                                        },
                                        {
                                            actionsCustomHeader:    true,
                                            important:              true,
                                            alignment:              'left',
                                            heading:                'Reference',
                                            actions: rowData => {
                                                return [
                                                    { label: rowData.r, link: `/sales/order/view/${rowData.i}`,         type:'plainLink'},
                                                    { label: rowData.q, link: `/sales/quotation/view/${rowData.qi}`,    type:'plainLink', class: "textSecondary"}
                                                ]
                                            }
                                        },
                                        {
                                            heading: 'Invoice(s)',
                                            field:   rowData => _.map(rowData.inv, i =><span>{i.n} {i.si > 0 && `(${i.si})`}<br/></span>),
                                        },
                                        {
                                            heading: 'Discount',
                                            field: rowData => rowData.t,
                                            important: true,
                                            fieldFormat: 'boolean',
                                            tooltip: rowData => rowData.t,
                                            sizeToContent: true,
                                            alignment: 'center'
                                        },
                                        {
                                            heading: 'Delivery Address',
                                            field: rowData => <>
                                                                {rowData.dan}
                                                                {' '}
                                                                <Tooltip
                                                                    title={
                                                                        <div>
                                                                            <Typography variant="body2">
                                                                                <b>Delivery Address:</b><br />
                                                                                {rowData.da.split('\n').map((item, key) => {
                                                                                    return  <span key={key}>
                                                                                                {item}{rowData.da.split('\n').length === key+1 ? null : <br/>}
                                                                                            </span>
                                                                                })}
                                                                            </Typography>
                                                                        </div>

                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Link>
                                                                        <FASolidIcon 
                                                                            icon='info-circle'
                                                                            button 
                                                                            size='small'
                                                                            style={{
                                                                                color: 'rgba(0, 0, 0, 0.38)'
                                                                            }}
                                                                        />
                                                                    </Link>
                                                                </Tooltip>
                                                            </>,
                                            important: true
                                        },
                                        {
                                            heading: 'Status',
                                            field: rowData =>   <>
                                                                    <Tooltip
                                                                        title={
                                                                            <div>
                                                                                <Typography variant="body2">{rowData.toolTip1}</Typography>
                                                                                <Typography variant="body2">{rowData.toolTip2}</Typography>
                                                                            </div>
                                                                        }
                                                                        placement="left"
                                                                    >
                                                                        <IconButton
                                                                            className={classes.iconButton}
                                                                        >
                                                                            <FALightIcon 
                                                                                icon={rowData.icon}
                                                                                button
                                                                                style={{color: rowData.color}}
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>,
                                            important: true
                                        },
                                        {
                                            heading: 'Files',
                                            field: rowData => 
                                                <Grid container style={{width:'100%'}} spacing={1} xs={12} alignItems='flex-end' justify="center">
                                                    {!!rowData.f.ej && 
                                                        <Grid item xs={12} lg={6}>
                                                            <AllIcon 
                                                                icon='user-hard-hat'  
                                                                button 
                                                                noMargin
                                                                tooltip='Engineer Job Sheet'
                                                            />
                                                        </Grid>
                                                    }
                                                    {!!rowData.f.po && 
                                                        <Grid item xs={12} lg={6}>
                                                            <AllIcon 
                                                                icon='file-alt'  
                                                                button 
                                                                noMargin
                                                                tooltip='Purchase Order'
                                                            />
                                                        </Grid>
                                                    }
                                                </Grid>,
                                            alignment: 'center',
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'Total',
                                            field: rowData => ( 
                                                <React.Fragment>
                                                    {rowData.tl.iv}
                                                    {' '}
                                                    <Tooltip 
                                                        title={
                                                            <div style={{display: 'flex', width: 200}}>
                                                                <div style={{width: '50%', textAlign:'right'}}>
                                                                    <Typography variant="body2">Sub Total</Typography>
                                                                    <Typography variant="body2">VAT ({rowData.tl.vp}%)</Typography>
                                                                    <Typography variant="body2">Total</Typography>
                                                                </div>
                                                                <div style={{width: '50%', textAlign:'right'}}>
                                                                    <Typography variant="body2">£{rowData.tl.s}</Typography>
                                                                    <Typography variant="body2">£{rowData.tl.v}</Typography>
                                                                    <Typography variant="body2">£{rowData.tl.iv}</Typography>
                                                                </div>
                                                            </div>
                                                        } 
                                                        placement="left"
                                                    >
                                                        <Link>
                                                            <FASolidIcon 
                                                                icon='info-circle'
                                                                button 
                                                                size='small'
                                                                style={{
                                                                    color: 'rgba(0, 0, 0, 0.38)'
                                                                }}
                                                            />
                                                        </Link>
                                                    </Tooltip>
                                                </React.Fragment>
                                            ),
                                            important: true,
                                            alignment: 'right',
                                            fieldPrefix: '£',
                                        },
                                        {
                                            heading: 'Payment Method',
                                            field: rowData =>   <>
                                                                    {_.map(rowData.in, i => 
                                                                        <Tooltip
                                                                            title={<Typography variant="body2">{i.toolTip}</Typography>}
                                                                            placement="left"
                                                                        >
                                                                            <IconButton className={classes.iconButton}>
                                                                                <AllIcon 
                                                                                    icon={`${i.icon}`}
                                                                                    button
                                                                                    style={{
                                                                                        color: i.color
                                                                                    }}
                                                                                    noMargin
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                </>,
                                            important: true,
                                            alignment: 'center',
                                            sizeToContent: true
                                        },
                                        {
                                            heading:    'Recurring',
                                            field:      rowData => <>
                                                {!!rowData.ri && <Tooltip
                                                                    title={<Typography variant="body2">Recurring Invoice</Typography>}
                                                                    placement="left"
                                                                >
                                                                    <IconButton style={{padding:5}}>
                                                                        <AllIcon 
                                                                            icon={'sync-alt'}
                                                                            button
                                                                            noMargin
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>}
                                            </>,
                                            important: true,
                                            alignment: 'center',
                                            sizeToContent: true
                                        },
                                        {
                                            heading: 'Ordered By',
                                            field: rowData => rowData.sn,
                                            important: true
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Sticky Notes',           icon: icons.stickyNote, onClick: () => this.handleShowStickyNote(rowData.i), hide:rowData.n[0] === 0, color: (rowData.n[1] && colors.yellow)  },
                                                    {name: 'Download',               icon: 'download',       onClick: () => this.handleDownloadOpen(rowData.i)},
                                                    {name: 'Send Order to Customer', icon: 'envelope',       onClick: () => this.handleEmailOpen(rowData.i)},
                                                    {name: 'View',                   icon: 'search',         link: { pathname: '/sales/order/view/' + rowData.i, state: {searchCriteria, gridPagination}}, disabled: !access.viewOrder}
                                                    
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={_.orderBy(this.state.searchResults,['d'],['desc'])}
                                />
                            </PaddedPaper>
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.emailSnackbarOpen}
                                onClose={this.handleEmailSnackbarClose}
                                message='You have successfully sent the email'
                            />
                        </Grid>
                    </React.Fragment>
                </Grid>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   ()                          => dispatch(clearPersistence()),
    setPersistence:     (key, state)                => dispatch(setPersistence(key, state)),
    deployDialog:       (content, title, variant)   => dispatch(deployDialog(content, title, variant)),
    closeDialog:        ()                          => dispatch(closeDialog())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerOrderSearch));