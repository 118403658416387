import React, { PureComponent } from 'react';
import { connect }              from 'react-redux';
import _                        from 'lodash'
import API                      from 'API';

import { TextField, Grid, Typography, Button, FormControlLabel, Checkbox } from '@material-ui/core';

import {colors}                   from 'Helpers/ColourHelper';
import icons                      from 'Helpers/IconHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import DataTable from 'Components/Common/DataTables/DataTable';

import { closeDialog }        from 'Actions/Dialog/Dialog';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import EmailField from 'Components/Common/Inputs/EmailField';

const initialState = {
    formData: {
        position:  null,
        name:      null,
        email:     null,
        telephone: null,
        mobile:    null,
        unsubscribe: false,
        validation: null
    },
    existingContacts: [],
    formErrors: {}
}

class AddNewContactForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleChange = (fieldName) => (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: e ? e.target.value : null
            }
        }, this.checkForExistingContact)
    }

    checkForExistingContact = () => {
        const {formData} = this.state;
        let contacts = this.props.customer.active_contacts
        if (formData.name  && formData.name  !== '') contacts = _.filter(contacts, i => i.contact_name.toLowerCase() === formData.name.toLowerCase());
        if (formData.email && formData.email !== '') contacts = _.filter(contacts, i => i.contact_email.toLowerCase() === formData.email.toLowerCase());

        this.setState({
            existingContacts: ((formData.name  && formData.name  !== '') || (formData.email && formData.email !== '')) ? _.filter(contacts, rowData => !_.find(rowData.addresses, {address_id: this.props.site})) : [],
            formErrors: {
                ...this.state.formErrors,
                alreadyExists:  ((formData.name  && formData.name  !== '') || (formData.email && formData.email !== '')) ? _.filter(contacts, i => _.find(i.addresses, {address_id: this.props.site})).length > 0 : false
            }
        
        })
    }

    handleLinkExistingContact = (contact) => {
        API.post(`/customers/contacts/${contact.contact_id}/linkToAddress`, {addressId: this.props.site})
        .then(res => {
            if (res.data.errors) {this.setState({formErrors: formatValidationErrors(res.data.errors),})
            } else { this.props.callBack(contact.contact_id);}
        })
    }

    handleAddNewContact = () => {
        const { name, position, email, telephone, mobile, unsubscribe, validation } = this.state.formData;
        API.post(`/customers/contacts`, {
            name, position, email, telephone, mobile, unsubscribe, validation,
            customer:               this.props.customer.cust_id,
            associatedAddresses:    [this.props.site]
        })
        .then(res => {
            if (res.data.errors) {this.setState({formErrors: formatValidationErrors(res.data.errors),})
            } else { this.props.callBack(res.data.success.contact_id);}
        })
    }

    handleEmailValidation = (value) => {
        this.setState({
            formData:{
                ...this.state.formData,
                validation: value
            }
        });
    }

    render () {
        const { formData, formErrors, existingContacts } = this.state;
        const { customer }                               = this.props;
        const site                                       = _.find([...customer.site_addresses, ...customer.account_addresses, customer.registered_address, ...customer.trading_addresses], {address_id: this.props.site});

        return (
            <Grid container xs={12} spacing={2} style={{justifyContent: 'flex-end'}}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>{customer.cust_name}</Typography>
                    <Typography variant="body1" gutterBottom>{site.address_type}: {site.address_name}</Typography>
                </Grid>
                {existingContacts.length > 0 &&
                    <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>Existing Contacts</Typography>
                        <DataTable
                            config={{
                                key:      'contact_id',
                                plainHeader: true,
                            }}
                            columns={[
                                {
                                    heading: 'Name',
                                    field: rowData => rowData.contact_name,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Email',
                                    field: rowData => rowData.contact_email,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Mobile',
                                    field: rowData => rowData.contact_mobile,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Telephone',
                                    field: rowData => rowData.contact_telephone,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {
                                                name:       'Link To Site', 
                                                icon:       icons.link, 
                                                onClick:    () => this.props.deployConfirmation('Are you sure you want to link this contact to this site','Link Contact To Site',()=>this.handleLinkExistingContact(rowData)),
                                                disabled:   _.find(rowData.addresses, {address_id: this.props.site})
                                            }
                                        ]
                                    }
                                }
                            ]}
                            rows={existingContacts}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField 
                        label="Contact Name *"
                        value={formData.name}
                        onChange={this.handleChange('name')}
                        fullWidth
                        error={formErrors.name}
                        helperText={formErrors.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        label="Position"
                        value={formData.position}
                        onChange={this.handleChange('position')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <EmailField
                        id="email"
                        name="email"
                        label="Email"
                        value={formData.email}
                        onChange={this.handleChange('email')}
                        margin="normal"
                        fullWidth
                        validationCallBack={this.handleEmailValidation}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        label="Mobile"
                        value={formData.mobile}
                        onChange={this.handleChange('mobile')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        label="Telephone"
                        value={formData.telephone}
                        onChange={this.handleChange('telephone')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="unsubscribe"
                                name="unsubscribe"
                                label="Unsubscribe From All Marketing"
                                checked={formData.validation === 'unsubscribed' ? 1 : formData.unsubscribe}
                                onChange={()=>this.setState({formData: {...formData, unsubscribe: !formData.unsubscribe}})}
                                color='primary'
                                margin="normal"
                                fullWidth
                                disabled={formData.validation === 'unsubscribed'}
                            />
                        }
                        label={'Unsubscribe From All Marketing'}
                    />
                </Grid>
                {!!formErrors.alreadyExists && <Grid item xs={12} style={{textAlign:'right', color: colors.red}}> Contact already attached to this site</Grid>}
                <Grid item>
                    <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                </Grid>
                <Grid item>
                    <Button variant='contained' color='primary' onClick={()=>this.props.deployConfirmation('Are you sure you want to add this contact','Add New Contact',this.handleAddNewContact)} disabled={formErrors.alreadyExists || !((formData.name  && formData.name  !== '') && (formData.email && formData.email !== '')) } >Add</Button>
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
        closeDialog:        ()                        => dispatch(closeDialog())
    }
}

export default connect(null, mapDispatchToProps)(AddNewContactForm);