import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider,
    FormControl,
    Grid,
    Input,
    InputAdornment,
    InputLabel, Link,
    List, ListItem, ListItemSecondaryAction, ListItemText,
    TextField, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AllIcon from 'Components/Common/Icons/AllIcon';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import PickingDialog from './PickingDialog';

import { addKeyDown, removeKeyDown, setKeyDown } from 'Actions/KeyDown/KeyDown';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';


import { printA4 } from 'Actions/Print/Print';
import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons from 'Helpers/IconHelper';
import { pdfDataFromBase64 } from 'Helpers/PDFHelper';


const initialState = {
    order:                  {},
    pickingList:            {},
    totalQty:               0,
    pickingDialogOpen:      false,
    isLoading:              true,
    disabled:               false,
    disabledMessage:        '',
    partDialogOpen:         false,
    partsMissingWeight:     [],
    shippingMissingWeights: [],
    missingWeights:         [],
    formData: {
        partWeights:     {},
        shippingWeights: {}
    },
    formErrors:             [],
    parts:                  [],
    page:                   0,
	perPage:                3,
    orderLocked:            true,
    showPickNotes:          true
};

class PickOrder extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState;
        this.timeout = null;
    }

    componentDidMount = () => {
        this.startActions();
        this.loadInitialData()
    }

	componentWillUnmount = () => {
        clearTimeout(this.timeout);
        clearTimeout(this.timer);
        this.endActions();
		this.props.removeKeyDown()
	}

    startActions = () => {
        API.post(`/sales/orders/${this.props.match.params.orderId}/actions/start`, {action: 'Picking'}, {props: {noLoading: true}}).then(res => {
            this.getActions();
        });
    }

    endActions = () => {
        API.post(`/sales/orders/${this.props.match.params.orderId}/actions/end`,{}, {props: {noLoading: true}});
    }

    getActions = () => {
        API.get(`/sales/orders/${this.props.match.params.orderId}/actions`, {props: {noLoading: true}}).then(res => {
            this.setState({
                orderLocked: res.data.l
            }, ()=>{ this.timer = setTimeout(()=>this.startActions(), 5000) })
        });
    }

    loadInitialData = () => {
        this.setState({
            ...this.state,
            isLoading: true
        }, 
        () => {
            API.get(`/sales/orders/pick/${this.props.match.params.orderId}`, 
            {
                params: {
                    forPicking: true
                }
            })
            .then(result => {
                let totalQty                = 0, 
                    pickingList             = [],
                    disabled                = false,
                    disabledMessage         = '',
                    partsMissingWeight      = [],
                    shippingMissingWeights  = [],
                    partWeights             = {},
                    shippingWeights         = {}, 
                    missingWeights          = [];

                result.data.order_details.forEach(detail => {
                    totalQty += parseFloat(detail.pick_detail.opd_quantity_to_follow);
                    // remove from picking list
                    if(parseFloat(detail.pick_detail.opd_quantity_to_follow) > 0 
                        && detail.pick_detail.opd_status !== 'Not Required') {
                            pickingList.push(detail);
                    }
                });
                
                // if no items are required to pick, disabled
                // a pick with 0 items req can only be accessed via url manipulation
                if(totalQty === 0) {
                    disabled = true;
                    disabledMessage = 'No items left to pick for this order';
                }

                pickingList.forEach(item => {
                    // if part requires serial number and there is no serial numbers or if there is not enough 
                    // issued serial numbers in the system disable pick order and show message explaining why
                    if(item.part.part_req_serial_number === 'Yes' && (item.part.part_model_type === null || item.part.part_model_type?.issued_serial_number?.length < parseInt(item.pick_detail.opd_quantity_to_follow))) {
                        disabled = true;
                        disabledMessage = 'Item is missing serial numbers';
                    }
                    // Check if any parts have a weight of 0 - if they do, the user will need to enter weights

                    if(item.part.part_weight == 0 || item.part.part_shipping_weight == 0){
                        if (!_.find(missingWeights, {part_id:item.part.part_id})) missingWeights.push(item.part);
                        if (item.part.part_weight == 0 && item.part.part_shipping_weight == 0){
                            partWeights     = {...partWeights,      ...{[item.part.part_id]: ''}}
                            shippingWeights = {...shippingWeights,  ...{[item.part.part_id]: ''}}
                        } else {
                            if(item.part.part_weight == 0) {
                                partWeights     = {...partWeights,      ...{[item.part.part_id]: ''}}
                                shippingWeights = {...shippingWeights,  ...{[item.part.part_id]: item.part.part_shipping_weight}}
                            }
                            if(item.part.part_shipping_weight == 0) {
                                partWeights     = {...partWeights,      ...{[item.part.part_id]: item.part.part_weight }}
                                shippingWeights = {...shippingWeights,  ...{[item.part.part_id]: ''}}
                            }
                        }
                    }
                });

                // if order pick is in progress no other staff member will be able to pick
                if(result?.data?.order_pick?.op_status === 'In Progress' && result?.data?.order_pick?.op_staff_id !== this.props.loggedInStaff.id) {
                    disabled = true;
                    disabledMessage = 'Order is currently being picked by another member of staff'
                }

                this.setState({
                    ...this.state,
                    order: result.data,
                    pickingList,
                    totalQty,
                    isLoading: false,
                    disabled,
                    disabledMessage, 
                    partsMissingWeight,
                    partDialogOpen: missingWeights.length > 0 ? true : false,
                    formData: {
                        ...this.state.formData,
                        partWeights     : partWeights,
                        shippingWeights : shippingWeights
                    },
                    missingWeights,
                    shippingMissingWeights,
                    parts: _.take(result.data.order_details, this.state.perPage)
                }, () => {
                    this.state.partDialogOpen ? 
                        this.props.removeKeyDown():
                        this.props.addKeyDown(this._handleKeyDown);
                });
            });
        });
    }

    handleAcknowladgeNotes = () => {
        API.post(`/sales/orders/pick/${this.props.match.params.orderId}/acknowledgeNotes`)
        .then(result => {
            this.setState({
                ...this.state,
                showPickNotes: false
            });
        });
    }

    handleStartPick = () => {
        this.props.removeKeyDown();
        // api call to create order pick
        API.post(`/sales/orders/pick/${this.props.match.params.orderId}/start`)
        .then(() => {
            // on success open dialog
            this.setState({
                ...this.state,
                pickingDialogOpen: true
            });
        });
    }

    // closing dialog mid pick
    handleEndPick = () => {
        this.setState({
            ...this.state,
            pickingDialogOpen: false
        }, () => {
            this.props.addKeyDown(this._handleKeyDown);
        });
    }

    handlePartDialogClose = () => {this.setState({partDialogOpen: false}, ()=>this.props.addKeyDown(this._handleKeyDown));};

    handleChange = (part, type = 'shippingWeights') => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [type]: {
                    ...this.state.formData[type],
                    [part]: e.target.value
                }
            }
        });
    }
    handleWeightChange = (part, type = 'partWeights') => e => {
        let newVal = parseFloat(e.target.value).toFixed(7);
        this.setState({
            formData: {
                ...this.state.formData,
                [type]: {
                    ...this.state.formData[type],
                    [part]: newVal
                }
            }
        });
    }

    submitPartWeights = () => {
        API.post('/parts/updatePartWeights', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){     
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({partDialogOpen: false}, ()=>this.props.addKeyDown(this._handleKeyDown));
                this.props.deploySnackBar('success', 'You have successfully updated the part weight(s)');
                this.loadInitialData()
            }
        });
    }

    handleDownloadNotes = () => {
        this.timeout = setTimeout(() => {
            clearTimeout(this.timeout);
            API.get(`/sales/orders/${this.state.order.order_id}/pickingNotesPDF`)
            .then(result => {
                if(result.data) {
                    this.props.printA4(pdfDataFromBase64(result.data.pdf), 'Picking Notes');
                }
            });
        }, 500);
    }

    _handleKeyDown = (e) => {
        if (e.keyCode === 0 || e.keyCode === 192) return;
        if (this.state.partDialogOpen) return;
        e.preventDefault(); 
        if (!this.state.pickingDialogOpen) {
            switch(e.key) {
                case 'F1':
                    if (this.state.showPickNotes) {
                        window.history.back();
                    } else {
                        this.state.page > 0 && this.prevPage();
                    }
                    break;
                case 'F2':
                    if (this.state.showPickNotes) {
                        this.handleAcknowladgeNotes();
                    } else {
                        ((this.state.page + 1) * this.state.perPage) < this.state.order.order_details.length && this.nextPage();
                    }
                    break;
                case 'F3':
                    if (this.state.showPickNotes) {
                        this.handleDownloadNotes();
                    } else {
                        _.sumBy(this.state.parts, i => parseFloat(i.pick_detail.opd_quantity_to_follow)) > 0 && this.handleStartPick();
                    }
                    break;
            }
        }
	}

    nextPage = () => {
		const { page, perPage, order } = this.state;
		const newPage = page + 1;
		const newParts = _.take(_.slice(order.order_details, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			parts: newParts
		});
	}

	prevPage = () => {
		const { page, perPage, order } = this.state;
		const newPage = page - 1;
		const newParts = _.take(_.slice(order.order_details, newPage * perPage), perPage);
		this.setState({
			page: newPage,
			parts: newParts
		});
	}

    handleEndPick = () => {
        this.setState({
            ...this.state,
            pickingDialogOpen: false
        }, () => {
            this.loadInitialData();
        });
    }

    render = () => {
        const { parts, order, pickingList, totalQty, pickingDialogOpen, isLoading, disabled, disabledMessage, formData, formErrors, missingWeights, orderLocked, showPickNotes } = this.state;
        const { classes } = this.props;

        if (isLoading) {
            return <LoadingCircle />;
        }

        return(
            <>
                <Grid container spacing={1} style={{height: '100%'}}> 
                    <Grid item xs={12}>
                        <Typography variant='h4' style={{textAlign: 'center'}}>Pick Order</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant='h5' style={{textAlign: 'center'}}>{order.full_reference}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" style={{textAlign: 'center'}}><strong>{order?.order_cust_name}</strong></Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" style={{textAlign: 'center'}}><strong>{moment(order?.order_despatch_date).format('DD/MM/YYYY')}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            {_.map(parts, i => 
                                <ListItem key={i.order_id} className={classes.ListItem}>
                                    <ListItemText 
                                        primary={i.part.part_number} 
                                        secondary={i.part.part_description}
                                    />
                                    <ListItemSecondaryAction>
                                        {parseFloat(i.pick_detail.opd_quantity_to_follow) > 0 ?
                                            <Typography variant="body2">
                                                Qty:<br/>{i.pick_detail.opd_quantity_to_follow}
                                            </Typography> : 
                                            <AllIcon
                                                icon={icons.true}
                                                color={colors.green}
                                            />
                                        }
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                    {orderLocked ?
                        <Grid item xs={12} style={{marginTop: 'auto'}}>
                            {orderLocked}
                        </Grid> :
                        <Grid item xs={12} style={{marginTop: 'auto'}}>
                            <Grid container spacing={1} style={{justifyContent: 'space-between'}}> 
                                <Grid item xs={3} style={{marginRight: 'auto'}}>
                                    { order?.order_details?.length > this.state.perPage && this.state.page > 0 && 
                                            <Button variant='contained' color='primary'
                                                onClick={this.prevPage} fullWidth
                                            >[F1] Prev</Button>
                                        }
                                </Grid>
                                <Grid item xs={6}>
                                    {_.sumBy(parts, i => parseFloat(i.pick_detail.opd_quantity_to_follow)) > 0 &&
                                        <Button variant='contained' color='primary' onClick={this.handleStartPick} fullWidth>[F3] {this.scanData} <br/> Start Pick </Button>
                                    }
                                    </Grid>
                                <Grid item xs={3} style={{marginLeft: 'auto'}}>
                                    { order?.order_details?.length > this.state.perPage && ((this.state.page + 1) * this.state.perPage) < order?.order_details?.length &&
                                        <Button variant='contained' color='primary'
                                            onClick={this.nextPage} fullWidth
                                        >[F2] Next</Button>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                {showPickNotes &&
                    <Dialog
                        open={showPickNotes}
                        onClose={this.handleConfirmReadAndStartPick}
                        fullWidth={true}
                        scroll="body"
                        disableBackdropClick
                        disableEscapeKeyDown
                        fullScreen
                    >
                        <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.primary, color: colors.white, fontWeight: 'bold'}}> 
                            Order Notes
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">Please read and confirm you understand the following order notes</Typography>
                                </Grid>
                                <>
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Grid container item spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6">Order Notes</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {order.order_notes ?
                                                        order.order_notes.split('\n').map((item, key) => {
                                                            return <span key={key}>{item}<br/></span>
                                                        })
                                                    :
                                                        'No Notes'
                                                    }
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <PaddedPaper>
                                            <Grid container item spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6">Picking Notes</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <InputLabel shrink={true} style={{color: 'black'}}>Expected Picking Date</InputLabel>
                                                        <Input value={moment(order.order_picking_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {( order.order_picking_notes && order.order_picking_notes !== 'null' ) ?
                                                        order.order_picking_notes.split('\n').map((item, key) => {
                                                            return <span key={key}>{item}<br/></span>
                                                        })
                                                    :
                                                        'No Notes'
                                                    }
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <PaddedPaper>
                                            <Grid container item spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6">Courier Notes</Typography>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <FormControl margin="none" fullWidth>
                                                            <InputLabel shrink={true} style={{color: 'black'}}>Expected Despatch Date</InputLabel>
                                                            <Input value={moment(order.order_despatch_date !== "0000-00-00" && order.order_despatch_date !== null ? order.order_despatch_date : order.despatch[0].desp_despatched_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl margin="none" fullWidth>
                                                            <InputLabel shrink={true} style={{color: 'black'}}>Expected Delivery Date</InputLabel>
                                                            <Input value={moment(order.order_delivery_date !== "0000-00-00" && order.order_delivery_date !== null ? order.order_delivery_date : order.despatch[0].desp_delivery_date).format('DD/MM/YYYY')} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='calendar-alt' /></InputAdornment>} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl margin="none" fullWidth>
                                                            <InputLabel shrink={true} style={{color: 'black'}}>Courier</InputLabel>
                                                            <Input value={order?.despatch?.length > 0 ? _.uniq(_.map(order.despatch, i => i.courier?.cour_name )).join(', ') : order?.courier?.cour_name} disabled className='disabledText' startAdornment={<InputAdornment position="start"><FALightIcon icon='truck' /></InputAdornment>} />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} style={{marginTop: '1em'}}>
                                                    {order.order_courier_notes ?
                                                        order.order_courier_notes.split('\n').map((item, key) => {
                                                            return <span key={key}>{item}<br/></span>
                                                        })
                                                    :
                                                        'No Notes'
                                                    }
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </>
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{textAlign: 'center', paddingLeft: 20, paddingRight: 20}}>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Link to="/sales/order/picking">
                                        <Button fullWidth variant="outlined">
                                            [F1]<br/>Back 
                                        </Button>
                                    </Link>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button fullWidth onClick={this.handleDownloadNotes} variant="outlined" color="primary">[F3]<br/>Print</Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button fullWidth onClick={this.handleAcknowladgeNotes} variant="contained" color="primary">[F2]<br/>Acknowledge</Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                }
                <Dialog 
                    open={pickingDialogOpen} 
                    onClose={this.handleEndPick} 
                    fullScreen
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.blue,  fontWeight: 'bold', color: colors.white}}> {`Picking: ${order?.full_reference}`}</DialogTitle>
                    <DialogContent style={{width:'100vw', Maxwidth: '100vw'}}>
                        <PickingDialog 
                            orderId={order.order_id}
                            pickingList={pickingList} 
                            totalQty={totalQty}
                            startIndex={order?.order_pick?.op_current_pick ?? 0}
                            order={order}
                            close={this.handleEndPick}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog 
                    open={this.state.partDialogOpen} 
                    onClose={this.handlePartDialogClose} 
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" style={{backgroundColor: colors.yellow,  fontWeight: 'bold'}}> 
                        <FALightIcon icon='exclamation-triangle' noMargin /> Warning - Part Weight(s) Required!
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle2" gutterBottom>
                            The following parts have a weight of 0g - please update the parts below to continue picking (1000g = 1kg)
                        </Typography>

                        <Grid container spacing={1} style={{marginTop:'1em'}}>
                            <Grid item xs={2}><b>Part Number</b></Grid>
                            <Grid item xs={4}><b>Part</b></Grid>
                            <Grid item xs={3}><b>Part Weight</b></Grid>
                            <Grid item xs={3}><b>Shipping Weight</b></Grid>
                        </Grid>
                        <Divider />
                        {missingWeights.map((part, k) => ([
                            <Grid container key={k} spacing={1}  style={{padding:'10px 0 10px 0', display:'flex'}}>
                                <Grid container item xs={2} style={{alignContent:'center'}}>
                                    {part.part_number} 
                                </Grid>
                                <Grid container item xs={4} style={{alignContent:'center'}}>
                                    {part.part_description}
                                </Grid>
                                <Grid item xs={3}>
                                        <TextField
                                            id="partWeight"
                                            name="partWeight"
                                            value={formData.partWeights[part.part_id]}
                                            onChange={this.handleChange(part.part_id, 'partWeights')}
                                            onBlur={this.handleWeightChange(part.part_id)}
                                            error={formErrors && formErrors['partWeight'+part.part_id] && parseFloat(_.find(pickingList, {part: { part_id : part.part_id }}).part.part_weight) == 0.0000000 }
                                            helperText={ parseFloat(_.find(pickingList, {part: { part_id : part.part_id }}).part.part_weight) == 0.0000000 && formErrors && formErrors['partWeight'+part.part_id]}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>
                                            }}
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            inputProps={{
                                                min: 0
                                            }}
                                            type="number"
                                            fullWidth
                                            disabled={_.find(pickingList, {part: { part_id : part.part_id }}).part.part_weight > 0}
                                        />
                                </Grid>
                                <Grid item xs={3}>
                                        <TextField
                                            id="shippingWeight"
                                            name="shippingWeight"
                                            value={formData.shippingWeights[part.part_id]}
                                            onChange={this.handleChange(part.part_id)}
                                            onBlur={this.handleWeightChange(part.part_id, 'shippingWeights')}
                                            error={formErrors && formErrors['shippingWeight'+part.part_id] && parseFloat(_.find(pickingList, {part: { part_id : part.part_id }}).part.part_shipping_weight) == 0.0000000 }
                                            helperText={ parseFloat(_.find(pickingList, {part: { part_id : part.part_id }}).part.part_shipping_weight) == 0.0000000 && formErrors && formErrors['shippingWeight'+part.part_id]}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>
                                            }}
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            inputProps={{
                                                min: _.find(pickingList, {part: { part_id : part.part_id }}).part.part_weight 
                                            }}
                                            type="number"
                                            fullWidth
                                            disabled={_.find(pickingList, {part: { part_id : part.part_id }}).part.part_shipping_weight > 0}
                                        />
                                    

                                </Grid>
                            </Grid>
                        ,<Divider />]))}
                        <br/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.submitPartWeights} variant="contained" color="primary" style={{backgroundColor:'#5da14d', color:'white'}}>Update</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = state => ({
    loggedInStaff: state.staffAuth.staff
});

const mapDispatchToProps = dispatch => ({
    deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
    addKeyDown: (func) => dispatch(addKeyDown(func,'PickOrder')),
	setKeyDown: () => dispatch(setKeyDown('PickOrder')),
	removeKeyDown: () => dispatch(removeKeyDown('PickOrder')),
    printA4:    (data, file) => dispatch(printA4(data, file)),
});


const styles = theme => ({
    ListItem: {
		backgroundColor: colors.white, 
		borderRadius: 	 5,
		marginBottom: 	 '0.75em',
		boxShadow: 	  	 '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
	},
    lockBox: {
        border: `1px solid ${colors.red}`,
        backgroundColor: `${colors.red}10`,
        color: colors.red,
        fontWeight: 'bold',
        padding: theme.spacing(1),
        textAlign: 'center',
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PickOrder));