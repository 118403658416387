import React, { useState } from 'react';
import _                   from 'lodash';

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, TextField, InputAdornment, Checkbox } from '@material-ui/core';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea           from 'Components/Common/Inputs/Textarea';
import CiDataTable        from 'Components/Common/DataTables/CiDataTable';

import icons from 'Helpers/IconHelper';

export default function LinkedPartsDialog({ open, formData, setFormData, close, submit, warrantyTerms, allowDiscount, pickingDocuments }) {

    const [warrantyIDX, setWarrantyIDX] = useState(-1);
    const [_formData, set_FormData] = useState(formData);

    const handleChange = (name, value, idx) => () => {
        const newFormData = _.cloneDeep(formData);
        newFormData[idx][name] = value;
        set_FormData(newFormData);
        setFormData(newFormData);
    }

    const handleWarrantyChange = (name, value, idx) => () => {
        const newFormData = _.cloneDeep(_formData);
        newFormData[idx][name] = value;
        set_FormData(newFormData);
    }

    return(
        <>
            <Dialog 
                open={open} 
                onClose={close} 
                fullWidth={true} 
                scroll="body"
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="lg"
            >
                <DialogTitle> {formData?.length > 0 && 'Linked Parts'}{pickingDocuments?.length > 0 && formData?.length > 0 && ' / '}{pickingDocuments?.length > 0 && 'Linked Part Files'}</DialogTitle>
                <DialogContent>
                 {formData?.length > 0 &&
                    <><CiDataTable
                        config={{
                            key: 'part_id',
                        }}
                        columns={[
                            {
                                heading:       '',
                                field:         'thumbnail_url',
                                fieldFormat:   'image',
                                sizeToContent: true,
                            },
                            {
                                heading:       'Part',
                                field:         i => <>{i.part_number}<br/>{i.part_description}</>,
                                
                            },
                            {
                                heading:       'Quantity',
                                field:         i => <TextField 
                                                        value={i.quantity} 
                                                        onChange={e => handleChange('quantity',  e.target.value, i.rowIdx)()} 
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 0
                                                            }
                                                        }}
                                                        disabled={!i.include}
                                                    />,
                                style: {width: '10%'}
                            },
                            {
                                heading:       'Unit Price',
                                field:         i => <TextField
                                                        disabled={!i.include}
                                                        name="unitPrice"
                                                        value={i.price}
                                                        onChange={e => handleChange('price',  e.target.value, i.rowIdx)()} 
                                                        type="number"
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (<InputAdornment position="start">£</InputAdornment>),
                                                            inputProps: {
                                                                min: 0
                                                            }
                                                        }}
                                                    />,
                                style: {width: '10%'}
                            },
                            ...(allowDiscount ? [
                            {
                                heading:       'Discount Rate',
                                field:         i => <TextField
                                                        disabled={!i.include}
                                                        name="discountRate"
                                                        value={i.discountRate}
                                                        onChange={e => handleChange('discountRate',  e.target.value, i.rowIdx)()} 
                                                        type="number"
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                                                            inputProps: {
                                                                min: 0
                                                            }
                                                        }}
                                                    />,
                                style: {width: '10%'}
                            },
                            {
                                heading:       'New Unit Price',
                                field:         i => parseFloat(parseFloat(i.price) - (parseFloat(i.price) * (parseFloat(i.discountRate) / 100))),
                                sizeToContent: true,
                                fieldFormat:   'realNumber:currency',
                            },
                            {
                                heading:       'Discount Total',
                                field:         i => parseFloat( parseFloat( parseFloat(i.price) * (parseFloat(i.discountRate) / 100)) * parseFloat(i.quantity) ),
                                fieldFormat:   'realNumber:currency',
                                sizeToContent: true,
                            },
                            ] : []),
                            {
                                heading:       'Total Price',
                                field:         i => parseFloat( parseFloat(parseFloat(i.price) - (parseFloat(i.price) * (parseFloat(i.discountRate) / 100))) * parseFloat(i.quantity) ),
                                fieldFormat:   'realNumber:currency',
                                sizeToContent: true,
                            },
                            {
                                heading:       'Warranty',
                                field:         i => <Button
                                                        onClick={()=>{set_FormData(formData);setWarrantyIDX(i.rowIdx)}}
                                                        variant="outlined"
                                                        disabled={!i.include}
                                                    >Edit</Button>,
                                                alignment: 'right',
                                sizeToContent: true,
                            },
                            {
                                heading:       'Add?',
                                field:         i => <Checkbox 
                                                        checked={i.include} 
                                                        onChange={handleChange('include', !i.include, i.rowIdx)} 
                                                        color='primary'
                                                    />,
                                sizeToContent: true,
                                alignment:     'center',
                            }
                        ]}
                        rows={formData}
                    /><br/><br/></>}
                    {pickingDocuments?.length > 0 &&
                    <CiDataTable
                        config={{
                            key: 'doc_id'
                        }}
                        columns={[
                            {
                                heading: 'Category',
                                field: 'doc_cat_name'
                            },
                            {
                                heading: 'Sub Category',
                                field: 'subcategory_name'
                            },
                            {
                                heading: 'Title',
                                field: 'doc_title'
                            }, 
                            {
                                heading: 'Version',
                                field: 'latest_file.file_version',
                                fieldPrefix: 'v',
                                alignment: 'center'
                            },
                            {
                                actions: i => ([
                                    {
                                        icon: icons.download,
                                        label: 'PDF',
                                        name: 'View PDF',
                                        onClick: () => window.open(i.file_url_pdf, '_blank')
                                    }
                                ])
                            }
                        ]}
                        rows={pickingDocuments}
                    />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} variant="outlined" color="default">Close</Button>
                    <Button onClick={submit} color="primary" variant="contained" disabled={_.filter(formData, i => i.include).length === 0}>Add</Button>
                </DialogActions>
            </Dialog>
            {/* WARRANTY DIALOG */}
            {warrantyIDX !== -1 &&
                <Dialog 
                    open={warrantyIDX > -1} 
                    onClose={()=>setWarrantyIDX(-1)} 
                    fullWidth={true} 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="md"
                >
                    <DialogTitle>Part Warranty Terms</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2" gutterBottom>
                            The default warranty for {formData[warrantyIDX].part_number} {formData[warrantyIDX].part_description} is {_.find(warrantyTerms, {value: formData[warrantyIDX].part_warranty_terms}).name}
                        </Typography>
                        <AutoCompleteSelect
                            label="Warranty Terms *"
                            options={warrantyTerms}
                            fullWidth
                            value={_formData[warrantyIDX].part_warranty_terms}
                            onChange={e => handleWarrantyChange('part_warranty_terms', e.value, warrantyIDX)()}
                            noClear
                        />
                        <Textarea 
                            label="Notes"
                            value={_formData[warrantyIDX].notes}
                            rows={3}
                            onChange={e => handleWarrantyChange('notes', e.target.value, warrantyIDX)()}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setWarrantyIDX(-1)} variant="outlined" color="default">Close</Button>
                        <Button onClick={()=>{setWarrantyIDX(-1); setFormData(_formData)}} variant="contained" color="primary">Save</Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
}