import React, { useEffect, useState }   from 'react';
import { useDispatch }                  from 'react-redux';
import _                                from 'lodash';
import API                              from 'API';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { creditPaymentTerms }     from 'Helpers/SalesHelper';
import {colors}                   from 'Helpers/ColourHelper';

import { Grid, Typography, DialogContent, Button, FormControl, Box } from '@material-ui/core/';

import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Order from 'Components/Sales/Order/ViewOrder/Order';
import StaffActions from 'Components/Sales/Order/ViewOrder/sections/StaffActions';
import EmailDialog from 'Components/Common/Dialogs/EmailDialog';
import AllIcon from 'Components/Common/Icons/AllIcon';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import icons from 'Helpers/IconHelper';

const ApproveOrderDialog = ({orderId, paymentTermsList, callback}) => {

    const dispatch = useDispatch();

    const initialState = {
        order: {},
        formData: {
            paymentTermId:      '',
            ogPaymentTermId:    '',
            approvalNotes:      ''
        },
        formErrors: {},
        emailDialog: {
            customerId: '',
            customerContactId: '',
            type: '',
        },
        isLoading: true,
        orderLocked: true
    };

    let [state, setState] = useState(initialState);

    const {order, formData, formData:{paymentTermId, approvalNotes}, formErrors, emailDialog:{customerId, customerContactId, type}, isLoading, orderLocked} = state;

    const handleSelectChange = fieldName => selectedOption => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [fieldName]: selectedOption?.value
            }
        }));
    }

    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleClose = () => dispatch(closeDialog());

    const handleApproveConfirmation = () => dispatch(deployConfirmation('Are you sure you want to approve this order', 'Approve Order?', handleApproveSuccess));

    const handleApprove = () => {
        if (_.indexOf(creditPaymentTerms, paymentTermId) > -1){
            API.get(`/customers/${order.order_cust_id}/accounts/creditCheck`, {params:{amount:order.order_total_incl_vat}})
            .then( res => {
                if (res.data.overCredit) {
                    dispatch(deployConfirmation('This order will exceed the customers credit limit, Are you sure you want to approve this order?', 'Over Credit Limit Warning', handleApproveSuccess, false ,'warning'));
                    /*
                    dispatch(deployDialog(
                        <>
                            <Typography variant='body1'>
                                This order will exceed the customers credit limit, please check with accounts before approving.
                            </Typography>
                            <Grid item container xs={12} style={{justifyContent: 'flex-end', marginTop:'2em'}}>
                                <Grid item>
                                    <Button onClick={()=>{dispatch(closeDialog())}} variant="outlined" >Close</Button>
                                </Grid>
                                <Grid item style={{paddingLeft: '1em'}}>
                                    <Button variant="contained"  color='secondary' onClick={handleApproveSuccess}>Approve</Button>
                                </Grid>
                            </Grid>
                        </>,
                        'Over Credit Limit Warning','warning','sm',null
                    ));*/
                } else {handleApproveConfirmation();}
            })
        } else { handleApproveConfirmation(); }
    };

    const handleApproveSuccess = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));
        API.post(`/sales/orders/${orderId}/approve`, formData)
        .then(result => {
            if(result.data.errors) {
                const formErrors = formatValidationErrors(result.data.errors)
                if (formErrors['status']){
                    dispatch(deploySnackBar('error', formErrors['status']));
                    if(callback) callback();
                    handleClose();
                } else {
                    setState(state => ({
                        ...state,
                        formErrors,
                        isLoading: false
                    }));
                }
            } else if(result.data.success) {
                setState(state => ({
                    ...state,
                    emailDialog: {
                        customerId: result.data.customerId,
                        customerContactId: result.data.contactId,
                        type: result.data.type
                    }
                }));
            }
        });
    }

    // handle email dialog
    useEffect(() => {
        if(customerId && customerContactId && type) {
            dispatch(deploySnackBar('success', 'You have successfully approved this order!'));
    
            const dialog = <EmailDialog 
                                id={orderId}
                                customerId={customerId}
                                customerContactId={customerContactId}
                                type={type}
                                pickingDocuments={order.pickingDocuments}
                            />
            dispatch(deployDialog(dialog, 'You Are About To Email - Order Confirmation', 'success'));
    
            if(callback) callback();
        }
    }, [orderId, customerId, customerContactId, type, dispatch, callback]);

    // handle load
    useEffect(() => {
        API.get('/sales/orders/' + orderId)
        .then(result => {
            setState(state => ({
                ...state,
                order: result?.data,
                formData: {
                    ...state.formData,
                    paymentTermId:      result.data.order_payment_term_id,
                    ogPaymentTermId:    result.data.order_payment_term_id
                },
                isLoading: false
            }));
        })
    }, [orderId]);

    const lockOrder = orderLocked => {
        setState(state => ({
            ...state,
            orderLocked
        }));
    };

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} lg={12} style={{paddingTop: 0, paddingBottom: '1em'}}>
                            <StaffActions orderId={order.order_id} lockCallback={lockOrder} type={'Approving'}/>
                        </Grid>
                        <Grid item xs={12} style={{border:'1px solid rgba(224, 224, 224, 1)', padding: '15px'}}>
                            <Order co={order} />
                        </Grid>
                        {!orderLocked && [
                            <Grid item xs={12}>
                                <br></br>
                                <Typography variant="h6">Change Payment Term?</Typography>
                            </Grid>,
                            <Grid item xs={6}>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={paymentTermsList}
                                        label='Payment Term *'
                                        value={paymentTermId}
                                        onChange={handleSelectChange('paymentTermId')}
                                        error={formErrors && formErrors['paymentTerm'] && true}
                                        errorText={formErrors && formErrors['paymentTerm']}
                                    />
                                </FormControl>
                            </Grid>]
                        }
                        <Grid item xs={6}></Grid>
                        {orderLocked ?
                             <>
                             <Grid item xs={12} md={12}>
                                {orderLocked}
                             </Grid> 
                             <Grid item xs={12} className='buttonRow'>
                                 <Button onClick={handleClose} variant="outlined" color="default">Close</Button>
                             </Grid>
                         </> :
                            <Grid item xs={12}>
                                <Textarea
                                    id="approvalNotes"
                                    name="approvalNotes"
                                    label="Approval Notes"
                                    value={approvalNotes}
                                    rows={5}
                                    error={formErrors && formErrors['approvalNotes']}
                                    onChange={handleChange}
                                />
                            </Grid>
                        }
                    </Grid>
                    {!orderLocked &&
                        <Grid item xs={12} className='buttonRow'>
                            <Button onClick={handleClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={handleApprove} style={{backgroundColor:colors.green, color:'white'}} variant="contained">Approve</Button>
                        </Grid>
                    }
                </DialogContent>
            )}
        </React.Fragment>
    );
}

export default ApproveOrderDialog;