/*
    data: [{
        label: label
        value: value || {label: value}
        color: color || {label: color}
    }]
*/
import React, { PureComponent } from 'react';
import _                        from 'lodash';

import { Chart, registerables } from 'chart.js';
import { Bar }                  from 'react-chartjs-2';

import { getRandomColor, colors }         from 'Helpers/ColourHelper';

Chart.register(...registerables)

const initialState = {}

const colorOverride = {
    'Yes': colors.green,
    'yes': colors.green,
    'No': colors.red,
    'no': colors.red,
}

class BarChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {...initialState, ...this.props};

        this.usedColors = [];
    }

    getData = () => {
        this.usedColors = [];
        const data = this.props.data;
        let datasets = [];
        if (_.filter(data, i => { return _.isObject(i.value) }).length){
            _.each( _.first(data).value, (val, key) => {
                datasets.push(
                    {
                        label:              key,
                        data:               [],
                        backgroundColor:    _.map(data, i => { return i.color[key] || this.getColor(i) }), 
                    }
                )
            } )
            _.each(data, i => {
                _.each(i.value, (val, key) => {
                    datasets[ _.findIndex(datasets, {label:key}) ].data.push(val);
                })
            })
        } else {
            datasets.push(
                {
                    label:              this.props.label || '',
                    data:               _.map(data, i => i.value),
                    backgroundColor:    _.map(data, i => { return i.color || this.getColor(i) }), 
                }
            )
        }
        return {
            labels: _.map(data, i => i.label), 
            datasets
        }
    }

    getColor(i){
        if (colorOverride[i.label]){
            return colorOverride[i.label];
        }
        return this.getRandomColor();
    }

    getRandomColor(){
        let color = null;
        if (this.props.colors){
            color = this.props.colors[this.usedColors.length];
        } else {
            color = getRandomColor();
            while (this.usedColors.includes(color)){
                color = getRandomColor();
            }
        }
        this.usedColors.push(color);
        return color;
    }

    getOptions = () => {
        let options =   {
                            indexAxis: this.props.sidebar ? 'y' : 'x',
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                x: { 
                                    stacked: this.props.stacked,
                                },
                                y: { 
                                    stacked: this.props.stacked,
                                },
                            },
                            plugins:{
                                legend: {
                                    labels: {
                                        textTransform: 'capitalize'
                                    }
                                }
                            }
                        }
       
        if (this.props.tick){
            if (this.props.sidebar){
                options.scales.x.ticks = _.isFunction(this.props.tick) ? {callback: this.props.tick} : {stepSize: this.props.tick}
            } else {
                options.scales.y.ticks = _.isFunction(this.props.tick) ? {callback: this.props.tick} : {stepSize: this.props.tick}
            }
        }
        
        if (this.props.yLabel)
            options.scales = {
                ...options.scales,
                y: {
                    ...options.scales?.y,
                    ticks:{
                        ...options.scales?.y?.ticks,
                        callback: this.props.yLabel
                    }
                }
            };
        if (this.props.xLabel)
            options.scales = {
                ...options.scales,
                x: {
                    ...options.scales?.x,
                    ticks:{
                        ...options.scales?.x?.ticks,
                        callback: (e) => { 
                            let data = this.props.sidebar ? e : this.getData().labels[e];
                            return this.props.xLabel(data);
                        }
                    }                    
                }
            };
        
        if (this.props.hideLegend){
            options.plugins = {
                ...options.plugins,
                legend:{
                    ...options.plugins?.legend,
                    display:false
                }
            }
        }

        if (this.props.tLabel){
            options.plugins = {
                ...options.plugins,
                tooltip: {
                    ...options.plugins?.tooltips,
                    callbacks: {
                        ...options.plugins?.tooltips?.callbacks,
                        label: this.props.tLabel
                    }
                },
            }
        }

        if (_.find(this.props.data, i => i?.bold)){
            let mod = {
                textStrokeWidth: ({index}) => {
                    return this.props.data[index]?.bold ? 1 : 0;
                },
                textStrokeColor: ({index}) => {
                    return this.props.data[index]?.bold ? '#666666' : null;
                },
            }
            if (this.props.sidebar){
                options.scales.y.ticks = { ...options.scales?.y?.ticks, ...mod }
            } else {
                options.scales.x.ticks = { ...options.scales?.x?.ticks, ...mod }
            }
        }

        if (this.props.onPointClick || this.props.onClick){
            options = {
                ...options,
                onClick: (e, elements) => {
                    if (this.props.onClick){
                        this.props.onClick(e,elements)
                    }

                    if (this.props.onPointClick && elements.length > 0) {
                        this.props.onPointClick(
                            {
                                index: elements[0].index,
                                data: this.props.data[elements[0].index],
                            }
                        );
                    }
                }
            }
        }

        return options;
    }

    render () {
        return (
            <div style={this.props.style}>
                <Bar 
                    data={this.getData()} 
                    options={this.getOptions()}
                    style={this.props.style}
                />
            </div>
        )
    }

} 



export default BarChart;