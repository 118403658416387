import * as UIActionTypes from './Types/Types';
import uuidv4 from 'uuid/v4';

export function handleCallStatus(payload) {
    return (dispatch) => {
        dispatch({
            type: UIActionTypes.HANDLE_CALL_STATUS,
            payload,
        });
    };
}

export function handleWindowResize(innerWidth, innerHeight) {
    return (dispatch) => {
        dispatch({
            type: UIActionTypes.WINDOW_RESIZE,
            payload: [innerWidth, innerHeight],
        });
    }
}

export function handleUpdateNotification(scheduledTimestamp) {
    return (dispatch) => {
        dispatch({
            type: UIActionTypes.UPDATE_NOTIFICATION,
            payload: scheduledTimestamp,
        });
    }
}

export function handleError(error, message) {
    return (dispatch) => {
        dispatch({
            type: UIActionTypes.HANDLE_ERROR,
            payload: [error, message]
        });
    }
}

export function handleUnauthorized(pathname) {
    return (dispatch) => {
        dispatch({
            type: UIActionTypes.HANDLE_UNAUTHORIZED,
            payload: pathname
        });
    }
}

export function handleAppError(error, message) {
    return dispatch => {          
        dispatch(handleError(error, message));
    }
}

export function handleUserUnauthorized(pathname) {
    return dispatch => {          
        dispatch(handleUnauthorized(pathname));
    }
}

export function handleScrollToTop() {
    return (dispatch) => {
        dispatch({
            type: UIActionTypes.SCROLL_TO_TOP,
            payload: uuidv4()
        });
    }
}