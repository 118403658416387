import React, { Component } from 'react';
import API from 'API';
import _ from 'lodash';
import { connect } from 'react-redux';

import { Button, Grid, Typography } from '@material-ui/core';

import BackButton from 'Components/Common/Buttons/BackButton';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import { FullScreenDialog } from 'Components/Common/Dialogs/FullScreenDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SingleEmailDialog from 'Components/Common/Dialogs/SingleEmailDialog';
import InspectCollection from './InspectCollection';
import icons from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';


import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { colors } from 'Helpers/ColourHelper';

const initialState = () => ({
    isLoading: true,
    cr: {}
})

class PickCustomerReturn extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getCR();
    }

    getCR = () => {
        API.get(`/customerReturns/returns/${this.props.match.params.id}`)
        .then(res => {
            this.setState({ 
                cr: res.data, 
                isLoading: false 
            });
        })
    }

    handleInspect = row => {

        let crd = _.find(this.state.cr.details, i => i.crd_id === row.crcd_crd_id);

        let formData = {
            cr_id:          this.state.cr.cr_id,
            crcd_id:        row.crcd_id,
            crd_id:         row.crcd_crd_id,
            expected:       row.crcd_expected,
            received:       row.crcd_recieved,
            remaining:      row.crcd_remaining,
            part:           row.part,
            serialNumbers: _.filter(JSON.parse(crd.crd_serial_numbers ), i => !_.find(row.received, r => JSON.parse(r.crcdr_serial_numbers).includes(i))),
            warrantyLabels: _.filter(JSON.parse(crd.crd_warrenty_void_labels ), i => !_.find(row.received, r => JSON.parse(r.crcdr_warranty_void_labels).includes(i))),
        }

        this.props.deployDialog(
            <FullScreenDialog 
                open={1} 
                onClose={this.props.closeDialog} 
                title={`Inspecting ${row.part.part_number} - ${row.part.part_description}`} 
                content={<InspectCollection collection={formData} cr={this.state.cr} onClose={()=>{ this.props.closeDialog(); this.getCR(); }}/>}
            />, "", null, 'fs')

    }

    submit = () => {
        API.post(`/customerReturns/returns/${this.state.cr.cr_id}/completeInspection`)
        .then(this.handleEmail);
    }

    handleEmail = () => {

        let id = this.state.cr.cr_id,
            template = 12;

        Promise.all([
            API.get(`/customerReturns/emailTemplates/${template}`),
            API.get(`/customerReturns/returns/${id}`)
        ])
        .then(res => {
            this.props.deployDialog(
                <SingleEmailDialog
                    contactId={res[1].data.cr_collection_address_contact_id}
                    customerId={res[1].data.cr_cust_id}
                    subject={res[0].data.cret_name}
                    body={res[0].data.cret_text}
                    emailUrl={`/customerReturns/returns/${id}/sendEmail`}
                    postParams={{
                        templateId: template,
                    }}
                    onSubmit={()=>{ this.props.history.push(`/returns/customerReturn/inspection`)}}
                    onClose={()=>{ this.props.history.push(`/returns/customerReturn/inspection`)}}
                />, 'You Are About To Email - Customer Return Received', 'success', 'md'
            )
        })
    }

    handleComplete = (r) => {
        this.props.deployConfirmation('Are you sure you want mark this delivery item for no further deliveries?',  'Mark Item For No Further Deliveries',this.submitComplete(r))
    }

    submitComplete = (r) => () => {
        API.post(`/customerReturns/returns/${this.state.cr.cr_id}/${r.crcd_id}/complete`).then(this.getCR);    
    }
    
    render() {

        const { cr, isLoading } = this.state;

        if (isLoading) return ( <LoadingCircle/> )

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>Customer Return Delivery</Typography>
                </Grid>
                <Grid item xs={12} md={!!cr.cr_notes ? 7 : 12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h5" gutterBottom>{cr.cr_reference}-{cr.cr_reference_amendment < 10 ? '0' + cr.cr_reference_amendment : cr.cr_reference_amendment}</Typography>
                                <Typography variant='body1'>
                                    Collection Date: {clenyDate(cr.collection.crc_date)}<br/>
                                    Estimated Delivery Date: {clenyDate(cr.collection.crc_delivery_date)}
                                </Typography>
                            </PaddedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container style={{alignItems: 'center', justifyContent: 'space-around'}}>
                                    <Grid item>
                                        <Typography variant='body1' style={{textAlign: 'center'}}>
                                            <strong>Expected</strong><br/>
                                            { parseFloat(_.sumBy(cr.collection.details, i=>parseFloat(i.crcd_expected))).toFixed(3) }
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{fontSize: 40, textAlign: 'center'}}>/</Grid>
                                    <Grid item>
                                        <Typography variant='body1' style={{textAlign: 'center'}}>
                                            <strong>Received</strong><br/>
                                            { parseFloat(_.sumBy(cr.collection.details, i=>parseFloat(i.crcd_recieved))).toFixed(3) }
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{fontSize: 40, textAlign: 'center'}}>/</Grid>
                                    <Grid item>
                                        <Typography variant='body1' style={{textAlign: 'center'}}>
                                            <strong>Not Received</strong><br/>
                                            { parseFloat(_.sumBy(cr.collection.details, i=> i.crcd_status === 'Complete' ? parseFloat(i.crcd_remaining) : 0)).toFixed(3) }
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{fontSize: 40, textAlign: 'center'}}>/</Grid>
                                    <Grid item>
                                        <Typography variant='body1' style={{textAlign: 'center'}}>
                                            <strong>Remaining</strong><br/>
                                            { parseFloat(_.sumBy(cr.collection.details, i=>i.crcd_status !== 'Complete' ? parseFloat(i.crcd_remaining) : 0)).toFixed(3) }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>
                {cr.cr_notes && 
                    <>
                        <Grid item xs={12} md={5}>
                            <PaddedPaper style={{ backgroundColor: colors.importantInfo, height: '100%' }}>
                                <Typography variant="h5" gutterBottom>Return Reason / Notes</Typography>
                                <Typography variant='body1'>
                                    {cr.cr_notes.split('\n').map((item, key) => <>{item}<br/></>)}
                                </Typography>
                            </PaddedPaper>
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable
                            config={{
                                key: 'crcd_crc_id'
                            }}
                            columns={[
                                {
                                    heading:       '',
                                    field:         'part.default_image.file_path',
                                    fieldFormat:   'image',
                                    sizeToContent: true
                                },
                                {
                                    heading:       'Part',
                                    field:         i => <>{i.part.part_number} <br/> {i.part.part_description}</>,
                                },
                                {
                                    heading:       'Expected',
                                    field:         'crcd_expected',
                                    fieldFormat:   'decimal:3',
                                    sizeToContent: true
                                },
                                {
                                    heading:       'Received',
                                    field:         'crcd_recieved',
                                    fieldFormat:   'decimal:3',
                                    sizeToContent: true
                                },
                                {
                                    heading:       'Not Received',
                                    field:         i=> i.crcd_status === 'Complete' ? parseFloat(i.crcd_remaining) : 0,
                                    fieldFormat:   'decimal:3',
                                    sizeToContent: true
                                },
                                {
                                    heading:       'Remaining',
                                    field:         i=> i.crcd_status !== 'Complete' ? parseFloat(i.crcd_remaining) : 0,
                                    fieldFormat:   'decimal:3',
                                    sizeToContent: true,
                                    style:         i => (i.crcd_status !== 'Complete' ? parseFloat(i.crcd_remaining) : 0)> 0 ? {color: 'red'} : {}
                                },
                                {
                                    actions: i => (
                                        i.crcd_status === 'Complete' ?
                                            ((
                                                parseFloat(i.crcd_remaining) === 0 &&
                                                    [{
                                                        name: 'Completed',
                                                        icon: icons.tick,
                                                        onClick: () => {},
                                                        color: colors.green
                                                    }]
                                            ) || (
                                                (
                                                    parseFloat(i.crcd_recieved) > 0 &&
                                                        [
                                                            {
                                                                name: 'Completed',
                                                                icon: icons.tick,
                                                                onClick: () => {},
                                                                color: colors.green
                                                            },
                                                            {
                                                                name: 'Completed',
                                                                icon: icons.bin,
                                                                onClick: () => {},
                                                                color: colors.red
                                                            },
                                                        ]
                                                ) || 
                                                [{
                                                    name: 'Completed',
                                                    icon: icons.bin,
                                                    onClick: () => {},
                                                    color: colors.red
                                                }]
                                            )) :
                                        [
                                            {
                                                name:     'Inspect',
                                                icon:     icons.view,
                                                onClick:  this.handleInspect,
                                            },
                                            {
                                                name:     'Mark No Further Deliveries',
                                                icon:     icons.bin,
                                                onClick:  this.handleComplete,
                                            }
                                        ]
                                    ),
                                    alignment: 'center'
                                }
                            ]}
                            rows={cr.collection.details}
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} >
                    <PaddedPaper className='buttonRow' style={{marginTop: 0}}>
                        <BackButton props={this.props} body='Save & Close' />
                        <Button 
                            variant="contained" 
                            color="primary"
                            onClick={() => this.props.deployConfirmation('Are you sure you want to complete this return and submit for inspection?', 'Submit For Inspection', this.submit)}
                            disabled={_.filter(cr.collection.details, {crcd_status: 'Complete'}).length !== cr.collection.details.length}
                        >Send For Inspection</Button>
                    </PaddedPaper>
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, variant, size='md')  => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:  ()                                    => dispatch(closeDialog()),
    deployConfirmation: (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
});

export default connect(null, mapDispatchToProps)(PickCustomerReturn);
