import API from 'API';
import { find } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, FormControl, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FABrandIcon from 'Components/Common/Icons/FontAwesome/FABrandIcon';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DespatchDialog from 'Components/Sales/Order/DespatchDialog/DespatchDialog';
import BankTransferDialog from 'Components/Sales/Order/OrderPayment/Actions/BankTransferDialog';
import BarclayCardEPDQDialog from 'Components/Sales/Order/OrderPayment/Actions/BarclayCardEPDQDialog';
import CashDialog from 'Components/Sales/Order/OrderPayment/Actions/CashDialog';
import ChequeDialog from 'Components/Sales/Order/OrderPayment/Actions/ChequeDialog';
import PaypalProcessPaymentDialog from 'Components/Sales/Order/OrderPayment/Actions/PaypalProcessPaymentDialog';
import StripeProcessPaymentDialog from 'Components/Sales/Order/OrderPayment/Actions/StripeProcessPaymentDialog';
import StaffActions from 'Components/Sales/Order/ViewOrder/sections/StaffActions';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';


const useStyles = makeStyles(theme => ({
    iconContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        textTransform: 'capitalize'
    },
    buttonRow: {
        float: 'right',
        margin: 0
    }
}));

const SelectPaymentDialog = ({orderId, orderTotal, paymentTermId, paymentTermsList, callback}) => {
    const classes = useStyles();

    const initialState = {
        formData: {
            paymentTermId
        },
        paymentTerm: [],
        update: false,
        updated: false,
        isLoading: false,
        orderLocked: true
    };

    let [state, setState] = useState(initialState);

    const dispatch = useDispatch();

    const {formData, paymentTerm:{barclaycardepdq, cheque, banktransfer, cash, paypal, stripe} = {}, update, updated, isLoading, orderLocked} = state;

    const handleDialogClose = () => {
        if(updated && callback) callback();
        dispatch(closeDialog());
    }

    const handleProcessPayment = type => {
        let dialog = '',
            title = '',
            variant = '';

        let paymentTerm = find(paymentTermsList, {'value': formData.paymentTermId});

        switch(type) {
            case 'barclaycard':
                dialog = <BarclayCardEPDQDialog 
                            id={orderId}
                            callback={callback}
                            amount={orderTotal}
                            paymentTerm={paymentTerm.label}
                            paymentTermId={paymentTerm.value}
                            amountOutstanding={orderTotal}
                        />;
                title = 'Process BarclayCard Payment';
                variant = 'success';
            break;
            case 'paypal':
                dialog = <PaypalProcessPaymentDialog
                        id={orderId}
                        callback={callback}
                        amount={orderTotal}
                        paymentTerm={paymentTerm.label}
                        paymentTermId={paymentTerm.value}
                        amountOutstanding={orderTotal}
                    />;
                title = 'Process Paypal Payment';
                variant = 'success';
                break;
            case 'stripe':
                dialog = <StripeProcessPaymentDialog
                        id={orderId}
                        callback={callback}
                        amount={orderTotal}
                        paymentTerm={paymentTerm.label}
                        paymentTermId={paymentTerm.value}
                        amountOutstanding={orderTotal}
                    />;
                title = 'Process Stripe Payment';
                variant = 'success';
                break;
            case 'cheque':
                dialog = <ChequeDialog 
                            id={orderId}
                            callback={callback}
                            amount={orderTotal}
                            paymentTerm={paymentTerm.label}
                            paymentTermId={paymentTerm.value}
                            amountOutstanding={orderTotal}
                        />;
                title = 'Process Cheque Payment';
                variant = 'success';
            break;
            case 'transfer':
                dialog = <BankTransferDialog 
                            id={orderId}
                            callback={callback}
                            amount={orderTotal}
                            paymentTerm={paymentTerm.label}
                            paymentTermId={paymentTerm.value}
                            amountOutstanding={orderTotal}
                        />;
                title = 'Process Bank Transfer';
                variant = 'success';
            break;
            case 'cash':
                dialog = <CashDialog 
                            id={orderId}
                            callback={callback}
                            amount={orderTotal}
                            paymentTerm={paymentTerm.label}
                            paymentTermId={paymentTerm.value}
                            amountOutstanding={orderTotal}
                        />
                title = 'Process Cash Payment';
                variant = 'success';
            break;
            default:
                throw new Error('unhandled type ' + type);
        }

        dispatch(deployDialog(dialog, title, variant, 'md'));
    }

    const handleSelect = fieldName => selectedOption => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [fieldName]: selectedOption?.value
            },
            update: true,
            updated: true,
        }));
    }

    const updateOrder = useCallback(() => {
        API.post(`/sales/orders/${orderId}/paymentTerm`, formData)
        .then(result => {
            if(result?.data?.upfrontPayment) {
                setState(state => ({
                    ...state,
                    paymentTerm: result?.data?.paymentTerm,
                    update: false,
                    isLoading: false
                }));
            }
        });
    }, [orderId, formData])

    const dispatchOrder = useCallback(() => {
        updateOrder();

        let dialog = <DespatchDialog 
                        orderId={orderId} 
                        callback={callback} 
                        paymentConfirmed={false}
                    />;
        dispatch(deployDialog(dialog, 'Handle Despatch', '', 'xs'));
    }, [orderId, callback, updateOrder, dispatch]);

    const handleUpdate = useCallback(() => {
        let requireUpfront = find(paymentTermsList, {'value': formData.paymentTermId}).upfrontPayment;

        if(requireUpfront) {
            updateOrder();
        } else {
            dispatch(deployConfirmation('Payment term doesn\'t require upfront payment, please confirm you want to change payment term', 'Change Payment Term?', dispatchOrder, () => dispatch(closeDialog())));
        }

    }, [formData, paymentTermsList, updateOrder, dispatch, dispatchOrder])

    // handle open
    useEffect(() => {
        setState(state => ({...state, isLoading: true}));

        API.get(`/customers/paymentTerms/${formData.paymentTermId}`)
        .then(result => {
            
            if(update) {
                handleUpdate();
            } else {
                setState(state => ({
                    ...state,
                    paymentTerm: result?.data?.paymentTerm,
                    isLoading: false
                }));
            }
        });
    }, [orderId, formData, update, handleUpdate]);

    const lockOrder = orderLocked => {
        setState(state => ({
            ...state,
            orderLocked
        }));
    };

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <Grid container>
                    <Grid item xs={12} lg={12} style={{paddingTop: 0, paddingBottom: '1em'}}>
                        <StaffActions orderId={orderId} lockCallback={lockOrder} type={'Processing Payment'}/>
                    </Grid>
                    {orderLocked ?
                        <Grid item xs={12} md={12}>
                           {orderLocked}
                        </Grid> : <>
                        <Grid item xs={6}>
                            <FormControl margin="none" fullWidth>
                                <AutoCompleteSelect 
                                    options={paymentTermsList}
                                    label='Payment Term *'
                                    value={formData.paymentTermId}
                                    onChange={handleSelect('paymentTermId')}
                                    // error={formErrors && formErrors['paymentTerm'] && true}
                                    // errorText={formErrors && formErrors['paymentTerm']}
                                    noClear={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid container className={classes.iconContainer} spacing={3}>
                            {barclaycardepdq &&
                                <Grid item >
                                    <Button 
                                        startIcon={<FALightIcon icon="credit-card" style={{color: '#008cdd' }} />}
                                        className={classes.button}
                                        onClick={() => handleProcessPayment('barclaycard')}
                                    >
                                        BarclayCard EPDQ
                                    </Button>
                                </Grid>
                            }
                            {stripe &&
                                <Grid item >
                                    <Button 
                                        startIcon={<FABrandIcon icon="stripe-s" style={{color: '#008cdd' }} />}
                                        className={classes.button}
                                        onClick={() => handleProcessPayment('stripe')}
                                    >
                                        Stripe
                                    </Button>
                                </Grid>
                            }
                            {paypal &&
                                <Grid item >
                                    <Button 
                                        startIcon={<FABrandIcon icon="paypal" style={{color: '#008cdd' }} />}
                                        className={classes.button}
                                        onClick={() => handleProcessPayment('paypal')}
                                    >
                                        PayPal
                                    </Button>
                                </Grid>
                            }
                            
                            {cheque && 
                                <Grid item >
                                    <Button 
                                        startIcon={<FALightIcon icon="money-check-edit" style={{color: '#008cdd' }} />}
                                        className={classes.button}
                                        onClick={() => handleProcessPayment('cheque')}
                                    >
                                        Cheque
                                    </Button>
                                </Grid>
                            }
                            {banktransfer && 
                                <Grid item >
                                    <Button 
                                        startIcon={<FALightIcon icon="piggy-bank" style={{color: '#008cdd' }} />}
                                        className={classes.button}
                                        onClick={() => handleProcessPayment('transfer')}
                                    >
                                        Bank Transfer
                                    </Button>
                                </Grid>
                            }
                            {cash && 
                                <Grid item >
                                    <Button 
                                        startIcon={<FALightIcon icon="money-bill" style={{color: '#008cdd' }} />}
                                        className={classes.button}
                                        onClick={() => handleProcessPayment('cash')}
                                    >
                                        Cash
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </>}
                    <Grid item xs={12}>
                        <div className={classes.buttonRow}>
                            <Button 
                                variant="outlined" 
                                color="default" 
                                onClick={handleDialogClose}
                            >
                                Close
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
}

export default SelectPaymentDialog;