import _axios from 'axios';
import uuidv4 from 'uuid/v4';

import { store } from './index';
import { finishLoading, startLoading } from 'Actions/Loading/Loading';
import { API_URL, CLIENT_VERSION, IN_DEV } from 'Constants';
import { handleError, handleUpdateNotification } from 'Actions/UI/UI';
import { handleNotifications, handleIamActive } from 'Actions/Notifications/Notifications';
import { clearLastActivePage, clearToken, forceStoredProfile, getDecodedToken, loggedIn, getToken, setLastActivePage } from 'Functions/AuthFunctions';
import {deployPopNotification} from 'Actions/PopNotification/PopNotification';
import _ from 'lodash';

const axios = _axios.create({
    baseURL: API_URL,
    withCredentials: true
})

axios.interceptors.request.use(request => {
    /*
     * Specify Client Version to API
     */
    request.headers['Client-Version'] = CLIENT_VERSION;
    /*
     * Send token if getToken() returns one (user is logged in)
     */
    const token = getToken();
    if (token) {
        request.headers.Authorization = 'Bearer ' + token;
    }
    return request;
}, (error) => {
    return Promise.reject(error);
});

/*
 * Check for update
 */
axios.interceptors.response.use(response => {
    let nextUpdate = response.headers['update-scheduled-for'] || false;
    if(nextUpdate) {
        store.dispatch(handleUpdateNotification(nextUpdate));
    } else {
        store.dispatch(handleUpdateNotification(false));
    }
    if(response?.config?.props?.handleNotifications) {
        if(response.data) {
            let errorState = store?.getState().ui?.appError?.state ?? false;
            if(errorState && errorState === "TOKEN_EXPIRED") {
                store.dispatch(handleError(false, ""))
            }
            const data = response.data.pop;
            if (data.length > 0) store.dispatch(deployPopNotification(data));
            if (response.data) store.dispatch(handleNotifications(response.data))
        }
    } else {
        if(loggedIn()) {
            const decodedToken = getDecodedToken();
            store.dispatch(handleIamActive(decodedToken.id));
        }
    }
    return response;
}, (error) => {
    let nextUpdate = (error && error.response && error.response.headers['update-scheduled-for']) || false;
    if(nextUpdate) {
        store.dispatch(handleUpdateNotification(nextUpdate));
    } else {
        store.dispatch(handleUpdateNotification(false));
    }
    if(error?.response?.config?.props?.handleNotifications) {
        if(error.response.data) {
            store.dispatch(handleNotifications(error.response.data))
        }
    }
    return Promise.reject(error);
});

/*
 * Handle Loading Bar
 */
axios.interceptors.request.use(config => {
    config.requestId = uuidv4()    
    if(config.props && config.props.noLoading) {
        config.loadingBar = false;
    } else {
        config.loadingBar = true;
        store.dispatch(startLoading(config.requestId));
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
axios.interceptors.response.use(response => {
    if(response && response.config && response.config.loadingBar === true) {
        store.dispatch(finishLoading(response.config.requestId));
    }
    return response;
}, (error) => {
    if(error && error.config && error.config.loadingBar === true) {
        store.dispatch(finishLoading(error.config.requestId));
    }
    return Promise.reject(error);
});

class API {
    constructor() {
        this.lastPath = "";
        this.lastRequestCancellation = "";
    }
    
    url = API_URL;
    
    get = (path, config = {}) => {  
        if(config && config.props && config.props.cancellation) {

            /* Check for duplicate requests */
            if(path === this.lastPath) {
                this.lastRequestCancellation.cancel('Duplicate Search Request Cancelled');
            } 

            /* Set last request variables */
            this.lastPath = path
            this.lastRequestCancellation = _axios.CancelToken.source();

            /* Attach request cancellation token */
            config = {
                ...config,
                cancelToken: this.lastRequestCancellation.token
            }
        }        

        /* Send request */
        if(IN_DEV){
            return axios.get(API_URL + path, config);
        } else {
            return axios.get(API_URL + path, config).catch(this.handleError);
        }
    }
    
    post = (path, data = {}, config = {}) => {        
        if(IN_DEV){
            return axios.post(API_URL + path, data, config);
        } else {
            return axios.post(API_URL + path, data, config).catch(this.handleError);
        }
    }

    put = (path, data = {}, config = {}) => {
        if(IN_DEV){
            return axios.put(API_URL + path, data, config);
        } else {
            return axios.put(API_URL + path, data, config).catch(this.handleError);
        }
    }

    access = (reference, accessState = false, bool = false) => {
        const self = this;
        return new Promise(function(resolve) {
            let access = accessState;
            if(!access) {
                access = store.getState().staffAuth?.access;
            }
            if(!_.isEmpty(access)) {
                if(access.includes(reference)) {
                    if(bool) {
                        resolve(true)
                    } else {
                        resolve({
                            data: {
                                has_access: true
                            }
                        })
                    }
                } else {
                    if(bool) {
                        resolve(false)
                    } else {
                        resolve({
                            data: {
                                has_access: false
                            }
                        })
                    }
                }
            } else {
                self.get(`/staff/my/access/check/${reference}`)
                .then(res => {
                    if(res?.data) {
                        if(res.data?.has_access) {
                            if(bool) {
                                resolve(true)
                            } else {
                                resolve({
                                    data: {
                                        has_access: true
                                    }
                                })
                            }
                        } else {
                            if(bool) {
                                resolve(false)
                            } else {
                                resolve({
                                    data: {
                                        has_access: false
                                    }
                                })

                            }
                        }
                    } else {
                        if(bool) {
                            resolve(false)
                        } else {
                            resolve({
                                data: {
                                    has_access: false
                                }
                            })
                        }
                    }
                })
            }
        })
    }

    multiAccess = references => {
        const self = this;
        const access = store.getState().staffAuth?.access;
        return Promise.all(_.map(references, ref => self.access(ref, access, true)));
    }

    handleError = err => {
        forceStoredProfile();
        setLastActivePage(window.location.pathname);
        if((err.config && err.config.props && !err.config.props.ignoreErrors)
            || (err.config && !err.config.props)) {
                if(err.response) {
                    switch(err.response.status) {
                        /* Bad request */
                        case 400:
                            store.dispatch(handleError("BAD_REQUEST", err))
                        break;
                        /* Unauthorised */
                        case 401:
                            clearToken();
                            window.location = '/login'       
                        break;
                        /* Forbidden */
                        case 403:
                            store.dispatch(handleError("FORBIDDEN", err))
                        break;
                        /* Endpoint not found */
                        case 404:
                            clearLastActivePage();
                            store.dispatch(handleError("API_NOT_FOUND", err))
                        break;
                        /* Conflict */
                        case 409:
                            store.dispatch(handleError("APP_UPDATE", err))
                        break;
                        /* Internal server error */
                        case 500:
                            store.dispatch(handleError("INTERNAL_SERVER_ERROR", err))
                        break;
                        /* Service temporarily unavailable */
                        case 503:
                            store.dispatch(handleError("SERVICE_UNAVAILABLE", err))
                        break;
                        /* Other http error */
                        default:
                            /* No action */
                        break;
                    }
                } else {
                    store.dispatch(handleError("UNKNOWN_ERROR", err))
                }
        } else {
            if(err.response && err.response.status && err.response.status === 401) {
                store.dispatch(handleError("TOKEN_EXPIRED", ""))    
            }
        }
        
    }
}

export default new API();