import React from 'react';
import InputHelper from 'Helpers/InputHelper';
import { TextField, Typography, Grid, Button } from '@material-ui/core';
import EmailField from 'Components/Common/Inputs/EmailField';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import API from 'API';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import _ from 'lodash';
import Textarea from 'Components/Common/Inputs/Textarea';

const initialState = () => ({
    formData: {
        name: '',
        email: '',
        position: '',
        phone: '',
        mobile: '',
        sourceNotes: '',
        source: '',
        company: '',
    },
    formErrors: null,
    isLoading: true,
    sources: [],
})
class AddManualContactsDialog extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState();
        this.inputHelper = new InputHelper(this);
    }

    componentDidMount() {
        this.getSources()
    }

    getSources = () => {
        API.get('/customers/contacts/sources').then(res => {
            this.setState({
                sources: _.map(res.data, i => ({value: i.cs_source, label: i.cs_source})),
                isLoading: false,
            });
        });
    }

    submit = () => {
        API.post(`/customers/contacts`, this.state.formData)
        .then(res => {
            if (res.data.errors){
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors)
                })
            } else {
                this.setState(initialState(), this.getSources);
            }
        });
    }

    render(){

        const { formData, formErrors, sources, isLoading } = this.state;

        if (isLoading) {
            return <LoadingCircle />
        }

        return (
            <Grid container spacing={1}>
                {formErrors && 
                    <Grid item xs={12}>
                        <Typography color="error">{formErrors}</Typography>
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Name *"
                        name="formData.name"
                        value={formData.name}
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                        formErrors={formErrors && formErrors['name']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <EmailField
                        fullWidth
                        label="Email *"
                        name="formData.email"
                        value={formData.email}
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Company"
                        name="formData.company"
                        value={formData.company}
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Position"
                        name="formData.position"
                        value={formData.position}
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Telephone"
                        name="formData.phone"
                        value={formData.phone}
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Mobile"
                        name="formData.mobile"
                        value={formData.mobile}
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteSelect
                        label="Source *"
                        options={sources}
                        value={formData.source}
                        onChange={this.inputHelper.handleSelectChange('formData.source')}
                        fullWidth
                    />
                    <Textarea
                        label='Source Notes'
                        name='formData.sourceNotes'
                        value={formData.sourceNotes}
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                        fullWidth
                        rows={4}
                    />
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant="outlined"
                        onClick={this.props.onClose}
                    >Close</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={()=>this.props.deployConfirmation(
                            'Are you sure you want to add this contact?',
                            'Add Contact',
                            this.submit
                        )}
                        disabled={!formData.name || !formData.email || !formData.source}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback) => dispatch(deployConfirmation(content, title, callback)),
    }
}

export default connect(null, mapDispatchToProps)(AddManualContactsDialog);