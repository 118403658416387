import React, { PureComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {colors} from 'Helpers/ColourHelper';

class QuotationStats extends PureComponent {
    render = () => {
        const { stats } = this.props;
        return (
            <React.Fragment>
                <Typography variant="subtitle2">
                    <strong>Current</strong>
                </Typography>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={3} style={{textAlign:'right', borderRight: '3px solid #D9A200'}}>
                                <Typography variant="body2">
                                   Sub Total: £{stats && stats.open && stats.open.all && stats.open.all.sub_total ? stats.open.all.sub_total : 0}
                                </Typography>
                                <Typography variant="body2">
                                    VAT: £{stats && stats.open && stats.open.all && stats.open.all.vat ? stats.open.all.vat : 0}
                                </Typography>
                                <Typography variant="body2">
                                   Total: £{stats && stats.open && stats.open.all && stats.open.all.total ? stats.open.all.total : 0}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="h4" style={{color:'#D9A200'}}>
                                    {stats && stats.open && stats.open.all && stats.open.all.count ? stats.open.all.count : 0}
                                </Typography>
                                <Typography variant="body2" style={{color:'#D9A200'}}>
                                    Open
                                </Typography>
                            </Grid>
                                <Grid item xs={3} style={{textAlign:'right', borderRight: '3px solid #009933'}}>
                                <Typography variant="body2">
                                    Sub Total: £{stats && stats.ordered && stats.ordered.all && stats.ordered.all.sub_total ? stats.ordered.all.sub_total : 0}
                                </Typography>
                                <Typography variant="body2">
                                    VAT: £{stats && stats.ordered && stats.ordered.all && stats.ordered.all.vat ? stats.ordered.all.vat : 0}
                                </Typography>
                                <Typography variant="body2">
                                    Total: £{stats && stats.ordered && stats.ordered.all && stats.ordered.all.total ? stats.ordered.all.total : 0}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="h4" style={{color:'#009933'}}>
                                    {stats && stats.ordered && stats.ordered.all && stats.ordered.all.count ? stats.ordered.all.count : 0}
                                </Typography>
                                <Typography variant="body2" style={{color:'#009933'}}>
                                    Ordered
                                </Typography>
                            </Grid>
                            <Grid item xs={3} style={{textAlign:'right', borderRight: '3px solid #E74C3C'}}>
                                <Typography variant="body2">
                                    Sub Total: £{stats && stats.closed && stats.closed.all && stats.closed.all.sub_total ? stats.closed.all.sub_total : 0}
                                </Typography>
                                <Typography variant="body2">
                                    VAT: £{stats && stats.closed && stats.closed.all && stats.closed.all.vat ? stats.closed.all.vat : 0}
                                </Typography>
                                <Typography variant="body2">
                                    Total: £{stats && stats.closed && stats.closed.all && stats.closed.all.total ? stats.closed.all.total : 0}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="h4" style={{color:colors.red}}>
                                    {stats && stats.closed && stats.closed.all && stats.closed.all.count ? stats.closed.all.count : 0}
                                </Typography>
                                <Typography variant="body2" style={{color:colors.red}}>
                                    Closed
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

export default QuotationStats;
