import React, { Component }  from 'react';

import { Grid, Typography, Button, FormControlLabel, Switch } from '@material-ui/core';

import SearchInputWithButton from 'Components/Common/Inputs/SearchInputWithButton';
import Map                   from 'Components/Common/Map/Map';   
import PaddedPaper           from 'Components/Common/Paper/PaddedPaper';
import AllIcon               from 'Components/Common/Icons/AllIcon';

import AddressCard from './AddressCard';

import icons from 'Helpers/IconHelper';

import addressSiteMarker from 'Assets/Images/markers/addressSite.png'
import _ from 'lodash';

const initialState = {
    keywords:'',
    addresses:[],
    mapCenter: null,
    zoom: 6,
    showInactiveSites: true
}

class siteAddresses extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.state.addresses = this.props.addresses;
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        });
    };

    handleResetSearch = () => {
        this.setState({
            keywords: ''
        });
    }

    showInactiveSites = () => {
        this.setState({
            showInactiveSites: !this.state.showInactiveSites
        });
    }

    render(){
        const sites = this.state.addresses;

        return (
            <div>
                
                <Grid container spacing={3} xs={12}>
                    <Grid item xs={12}>
                        <Map 
                            disableScroll
                            showSwapMapType
                            center={this.state.mapCenter}
                            controllable
                            style={{cursor: 'default !important'}}
                            markers={_.map(_.filter(_.filter(sites, site => this.state.showInactiveSites ? 1 : (site.address_active ? 1 : 0) ),site => site.address_lang && site.address_long),i => ({
                                lat: i.address_lang,
                                lng: i.address_long,
                                marker: <img src={ addressSiteMarker } alt='marker' width={20} style={{marginTop: '-50%'}} />,
                                content: 
                                    <PaddedPaper>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}><Typography variant="body1" component="body1">{i.address_name}</Typography></Grid>
                                            <Grid item xs={12}>
                                                {i.address_line_one}
                                                {i.address_line_two   ? ', ' + i.address_line_two   : ''}
                                                {i.address_line_three ? ', ' + i.address_line_three : ''}
                                                {i.address_town       ? ', ' + i.address_town       : ''}
                                                {i.address_postcode   ? ', ' + i.address_postcode   : ''}
                                            </Grid>
                                            <Grid item xs={12}> {i.address_email}</Grid>
                                            <Grid item xs={12}> {i.address_phone}</Grid>
                                            <Grid item xs={12} className='buttonRow'>
                                                <Button variant='outlined' onClick={()=>{ window.location.href = `/customers/addresses/view/${i.address_id}` }} >
                                                    <AllIcon icon={icons.search}/>View 
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                            }))}
                            height={'40vh'}
                            zoom={this.state.zoom}
                        />
                    </Grid>
                    <Grid item spacing={3}>
                        <SearchInputWithButton 
                            placeholder="Search..."
                            value={this.state.keywords}
                            onChange={this.handleSearchKeyword}
                            onClick={this.handleResetSearch}
                        />
                    </Grid>
                    {_.filter(sites, site => !site.address_active).length > 0 &&
                        <Grid item spacing={3} xs={8}>
                            <FormControlLabel
                                style={{marginLeft: '1em'}}
                                control={
                                    <Switch label="Hide Inactive" color='primary'  value={this.state.showInactiveSites} onClick={this.showInactiveSites}/>
                                }
                                label="Hide Inactive"
                            />
                        </Grid>
                    }
                    <Grid container item spacing={3} xs={12} >
                        {sites.filter( site => {
                            if (!this.state.showInactiveSites && !site.address_active) return 0;
                            if ((!this.state.keywords)) return 1;
                            var ret = 0;
                            if ( site.address_name.toLowerCase().search(this.state.keywords.toLowerCase()) > -1 )           ret = 1;
                            if ( site.address_postcode.toLowerCase().search(this.state.keywords.toLowerCase()) > -1 )       ret = 1;
                            if ( site.address_town.toLowerCase().search(this.state.keywords.toLowerCase()) > -1 )           ret = 1;
                            return ret;
                        } ).map((item, idx) => {
                            return (
                                <Grid item xs={12} lg={4} key={item.address_id}>
                                    <AddressCard address={item} classes={this.props.classes}  idx={item.address_id} jumpToMap={(center) => this.setState({mapCenter: center, zoom: 14})}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </div>
        )
    }

}

export default (siteAddresses)