import React, { Component } from 'react';
import _                    from 'lodash';
import moment               from 'moment';
import { Redirect }         from 'react-router-dom';
import API                  from 'API';

import { Button, FormControl, Grid, IconButton, Radio, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';

import BackButton         from 'Components/Common/Buttons/BackButton';
import FALightIcon        from 'Components/Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { colors }                 from 'Helpers/ColourHelper';

const initialState = { 
    formData: {
        part: '',
        supplier: '',
        supplierRef: '',
        costDefault: null,
        costs: [
            {
                quantity: '',
                totalCost: '',
                costPerUnit: '',
                newCostDefault: false
            }
        ],
        deleteCostId: ''
    },
    part: {},
    supplierList: [],
    suppliers: [],
    formErrors: [],
    confirmationOpen: false,
    deleteOpen: false,
    snackbarOpen: false,
    isLoading: false,
    initialLoading: true,
    redirect: false
}

class UpdateCost extends Component {
    constructor(props) {
        super(props);
    
        this.state = initialState;
    }

    componentDidMount(){
        this.getPart(this.props.match.params.id); 
    }

    getPart = (partId) => {
        API.get(`/parts/${partId}`)
        .then((result) => {
            if (result.data.errors) {
                this.setState({ redirect: true });
            } else {
                this.setState({
                    part: result.data,
                    formData: {
                        ...this.state.formData,
                        part: result.data.part_id
                    }
                },
                () => {
                    this.getExistingSuppliers(partId);
                });
            }
        })
    }

    getDefaultCostForPart = (partId) => {
        API.get(`/parts/${partId}/costs/default`)
        .then((result) => {
            this.setState({
                formData: {
                    ...this.state.formData,
                    supplier: result.data.cost_supplier_id,
                    supplierRef: result.data.cost_reference
                }
            },
            () => {
                this.getCosts(result.data.cost_supplier_id);
            });
        });
    }

    getCosts = (supplierId) => {
        if(supplierId){
            API.get(`/parts/${this.state.formData.part}/costs`, {
                params: {
                    supplier: supplierId
                }
            })
            .then((result) => {
                let costs = _.map(result.data, el => {

                    let date_from = el.cost_date_from && el.cost_date_from !== '0000-00-00 00:00:00' && moment(el.cost_date_from).format("DD-MM-YYYY");
                    let date_to = el.cost_date_to && el.cost_date_to !== '0000-00-00 00:00:00' && moment(el.cost_date_to).format("DD-MM-YYYY");

                    return _.assign({
                        costId: el.cost_id,
                        quantity: el.cost_qty,
                        totalCost: el.cost,
                        costPerUnit: el.cost_per_unit,
                        dateFrom: date_from,
                        dateTo: date_from && date_to === '' ? 'Current' : date_to,
                        costDefault: el.cost_default
                    });
                });
                this.setState({
                    formData: {
                        ...this.state.formData,
                        costs: costs,
                        supplierRef: _.find(result.data, i => i.cost_date_to === "0000-00-00 00:00:00")?.cost_reference || _.first(result.data)?.cost_reference
                    },
                    initialLoading: false
                });
            });
        }
    }

    getExistingSuppliers = (partId) => {
        API.get(`/parts/${partId}/suppliers`)
        .then((result) => {
            let supplierList = _.map(result.data, el => {
                return _.assign({
                    value: el.supp_id,
                    label: el.supp_company_name
                });
            });
            this.setState({
                supplierList: supplierList,
                suppliers: result.data
            }
            , 
            () => {
                this.getDefaultCostForPart(partId);
            });
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, () => {
            if(fieldName === 'supplier'){
                this.getCosts(selectedOption && selectedOption.value);
            }
        });
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        let newDefault = _.findIndex(this.state.formData.costs, {'newCostDefault': true});
       
        this.setState({
            isLoading: true,
            formData: {
                ...this.state.formData,
                costDefault: newDefault
            }
        },
        () => {
            API.post('/costs/update', this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: true
                    });
                    this.getPart(this.props.match.params.id);
                }
                this.props.scrollToTop();
            });
        });
    }

    deleteCost = () => {
        API.post(`/costs/${this.state.formData.deleteCostId}/delete`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.getPart(this.props.match.params.id);
            }
        });
    }

    // Delete Cost
    handleDeleteOpen = (costId) => {
        this.setState({
            deleteOpen: true,
            formData: {
                ...this.state.formData,
                deleteCostId: costId
            }
        });
    };
    handleDeleteClose = () => {
        this.setState({ 
            deleteOpen: false 
        });
    };
    handleDeleteSuccess = () => {
        this.setState({ 
            deleteOpen: false 
        });
        this.deleteCost();
    }

    // Update Cost
    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleAddCost = () => {
        const item = initialState.formData.costs[0];
        this.setState({
            formData:{
                ...this.state.formData,
                costs: [...this.state.formData.costs, item]
            }
        });
    }

    handleRemoveCost = (idx) => () => {
        const costs = [...this.state.formData.costs]
        costs.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                costs: costs
            }
        })
    }

    handleCostChange = (idx, decimals) => e => {
        const { name, value } = e.target;
        let newCosts =  [...this.state.formData.costs];
        let newVal = decimals ? parseFloat(value).toFixed(decimals) : value;

        newCosts[idx] = {
            ...newCosts[idx],
            [name]: newVal
        };
        // Calculate Cost Per Unit
        if(newCosts[idx].quantity > 0){
            let costPerUnit = newCosts[idx].totalCost / newCosts[idx].quantity;
            newCosts[idx] = {
                ...newCosts[idx],
                costPerUnit: !isNaN(costPerUnit) ? costPerUnit.toFixed(5).toString() : ''
            };
        }

        this.setState({
            formData: {
                ...this.state.formData,
                costs: newCosts
            }
        });
    };

    handleCostDefaultChange = (idx) => e => {
        let costs =  [...this.state.formData.costs];
        
        let newCosts = costs.map((item) => {
            item.newCostDefault = false;
            return item;
        }); 
        newCosts[idx] = {
            ...newCosts[idx],
            newCostDefault: true
        };
        this.setState({
            formData: {
                ...this.state.formData,
                costs: newCosts
            }
        });
    };
       
    render() {
        const { formErrors, isLoading, initialLoading } = this.state;
        let maxRows = this.state.formData.costs.length;

        if (this.state.redirect) {
            return <Redirect to="/costs/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Typography variant="h5">
                            Update Cost
                        </Typography>
                    </Grid>
                    {(initialLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                    )) || (
                        <React.Fragment>
                            <Grid container item>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Cost Details
                                        </Typography>
                                        {formErrors && formErrors.generic && (
                                            <React.Fragment>
                                                <Typography component={"div"} style={{color: colors.red}}>
                                                    {formErrors.generic}
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                        <form noValidate autoComplete="off">
                                            <TextField
                                                id="part"
                                                name="part"
                                                label="Part"
                                                value={this.state.part.part_number + ' - ' + this.state.part.part_description}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                            />
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect 
                                                    options={this.state.supplierList} 
                                                    label='Supplier *'
                                                    onChange={this.handleSelectChange('supplier')}
                                                    error={formErrors && formErrors['supplier'] && true}
                                                    errorText={formErrors && formErrors['supplier']}
                                                    value={this.state.formData.supplier}
                                                />
                                            </FormControl>
                                            <TextField
                                                id="supplierRef"
                                                name="supplierRef"
                                                label="Suppliers Reference"
                                                value={this.state.formData.supplierRef}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                            />
                                        </form>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    {(isLoading && (
                                        <LoadingCircle />               
                                    )) || (
                                    <form noValidate autoComplete="off">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell>Qty</TableCell>
                                                    <TableCell>Cost</TableCell>
                                                    <TableCell>Cost Per Unit</TableCell>
                                                    <TableCell>Date From</TableCell>
                                                    <TableCell>Date To</TableCell>
                                                    <TableCell style={{textAlign:'center'}}>Default</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.formData.costs.map((item, idx) => {
                                                    if(idx > 0){
                                                        maxRows--;
                                                    }
                                                    // eslint-disable-next-line
                                                    let activeRowColor = this.state.formData.costs[idx].costDefault  == 'Yes' ? '#f1f8e9':'';
                                                    return (
                                                        <TableRow key={idx} style={{backgroundColor: activeRowColor}}>
                                                            <TableCell>Cost {maxRows}</TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="quantity"
                                                                    value={this.state.formData.costs[idx].quantity}
                                                                    error={formErrors && formErrors['costs|quantity|'+idx] && true}
                                                                    helperText={formErrors && formErrors['costs|quantity|'+idx]}
                                                                    onChange={this.handleCostChange(idx)}
                                                                    onBlur={this.handleCostChange(idx, 3)}
                                                                    type="number"
                                                                    disabled={this.state.formData.costs[idx] && !!this.state.formData.costs[idx].dateFrom}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="totalCost"
                                                                    value={this.state.formData.costs[idx].totalCost}
                                                                    error={formErrors && formErrors['costs|totalCost|'+idx] && true}
                                                                    helperText={formErrors && formErrors['costs|totalCost|'+idx]}
                                                                    onChange={this.handleCostChange(idx)}
                                                                    onBlur={this.handleCostChange(idx, 5)}
                                                                    type="number"
                                                                    disabled={this.state.formData.costs[idx] && !!this.state.formData.costs[idx].dateFrom}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="costPerUnit"
                                                                    value={this.state.formData.costs[idx].costPerUnit}
                                                                    error={formErrors && formErrors['costs|costPerUnit|'+idx] && true}
                                                                    helperText={formErrors && formErrors['costs|costPerUnit|'+idx]}
                                                                    onChange={this.handleCostChange(idx)}
                                                                    type="number"
                                                                    disabled
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                {this.state.formData.costs[idx].dateFrom ? this.state.formData.costs[idx].dateFrom : '-'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {this.state.formData.costs[idx].dateTo ? this.state.formData.costs[idx].dateTo : '-'}
                                                            </TableCell>
                                                            <TableCell style={{textAlign:'center'}}>
                                                                {this.state.formData.costs[idx] && !this.state.formData.costs[idx].dateFrom ?
                                                                    (<Radio
                                                                        // eslint-disable-next-line
                                                                        checked={this.state.formData.costs[idx].newCostDefault}
                                                                        onChange={this.handleCostDefaultChange(idx)}
                                                                        value={true}
                                                                        name="newCostDefault"
                                                                        style={{margin:0}}
                                                                        color="primary"
                                                                        />
                                                                    )
                                                                    :
                                                                    this.state.formData.costs[idx].costDefault 
                                                                }
                                                            </TableCell>
                                                            <TableCell style={{textAlign:'center'}}>
                                                                {this.state.formData.costs[idx] && !this.state.formData.costs[idx].dateFrom && this.state.formData.costs[idx].costId > 0 ?
                                                                <i onClick={() => this.handleDeleteOpen(this.state.formData.costs[idx].costId)}>
                                                                    <FALightIcon icon='trash-alt' link />
                                                                </i>
                                                                : 
                                                                this.state.formData.costs[idx] && !this.state.formData.costs[idx].dateFrom && !this.state.formData.costs[idx].costId ?
                                                                    <IconButton onClick={this.handleRemoveCost(idx)}>
                                                                        <FALightIcon icon='times' noMargin />
                                                                    </IconButton>
                                                                :
                                                                <FALightIcon icon='trash-alt' disabled />
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                        <div style={{marginTop: 20}}>
                                            <Button onClick={this.handleAddCost}
                                                    variant="outlined"
                                                    color="primary">
                                                Add Cost
                                            </Button>
                                        </div>
                                        <div className='buttonRow'>
                                            <BackButton props={this.props} />
                                            <Button onClick={this.handleConfirmationOpen}
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={this.state.isLoading}
                                                >
                                                Update
                                            </Button>
                                        </div>
                                    </form>
                                    )}
                                </PaddedPaper>
                            </Grid>
                            <ConfirmationDialog 
                                open={this.state.deleteOpen} 
                                success={this.handleDeleteSuccess} 
                                close={this.handleDeleteClose} 
                                title="Delete Cost?" 
                                message="Are you sure you want to delete this cost?"
                            />
                            <ConfirmationDialog 
                                open={this.state.confirmationOpen} 
                                success={this.handleConfirmationSuccess} 
                                close={this.handleConfirmationClose} 
                                title="Update Cost?" 
                                message="Are you sure you want to update this cost?"
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarOpen}
                                onClose={this.handleSnackbarClose}
                                message='You have successfully updated this cost'
                            />
                        </React.Fragment>
                    )}
                </Grid>
            );
        }
    }
}

export default UpdateCost;