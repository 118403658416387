import React from 'react';
import { useSelector } from "react-redux";
import _ from 'lodash';

import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import { capitalize } from "Functions/MiscFunctions";

const WildixCalls = ({ callData, calls, classes, getCallActions, getCallStatusClass, open }) => {
    const ui = useSelector((state) => state.ui);

    return (
        <Drawer
            anchor="right"
            classes={{
                paper: ui?.device?.isTablet ? classes.callsSmallHeaderPaper : classes.callsPaper,
            }}
            open={open}
            transitionDuration={0}
            variant="persistent"
        >
            <Box p={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} align="left">
                        <Typography variant="h6" paragraph>
                            Active Calls
                        </Typography>
                        <CiDataTable
                            config={{
                                key: "ch",
                                alternatingRowColours: true,
                                isLoading: false,
                                inline: true,
                                responsiveImportance: true,
                                noResultsText: "You currently have no active calls",
                            }}
                            columns={[
                                {
                                    heading: "Callee",
                                    field: (rowData) => {
                                        const data = callData?.find?.((c) => c.ch === rowData.getChannel())?.dt ?? null;
                                        return (
                                            <Box py={1}>
                                                {((data?.fn || data?.ln) && (
                                                    <>
                                                        <Typography variant="body2" className="fw-400 pr-2">
                                                            {data.fn} {data.ln} ({data.cn})
                                                        </Typography>
                                                    </>
                                                )) ||
                                                    (data?.cn && (
                                                        <>
                                                            <Typography variant="body2" className="fw-400 pr-2">
                                                                {data.cn}
                                                            </Typography>
                                                        </>
                                                    )) || (
                                                        <>
                                                            <Typography variant="body2" className="fw-400 pr-2">
                                                                {rowData.getCalleeName()}
                                                            </Typography>
                                                        </>
                                                    )}
                                            </Box>
                                        );
                                    },
                                    main: true,
                                },
                                {
                                    heading: "Phone Number",
                                    field: (rowData) => rowData.getCalleeNumber(),
                                    main: true,
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Direction",
                                    field: (rowData) => capitalize(rowData.getDirection()),
                                    alignment: "center",
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Recording",
                                    field: (rowData) => (rowData.isRinging() ? "-" : rowData.isRecorded()),
                                    fieldFormat: "boolean",
                                    alignment: "center",
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Duration",
                                    field: (rowData) => (rowData.isRinging() ? "-" : rowData.getFormattedDuration()),
                                    sizeToContent: true,
                                },
                                {
                                    heading: "Status",
                                    field: (rowData) => <span className={getCallStatusClass(rowData)}>{rowData.getFormattedState()}</span>,
                                    sizeToContent: true,
                                },
                                {
                                    actions: (rowData) => getCallActions(rowData),
                                },
                            ]}
                            rows={_.orderBy(calls, el => el.isRinging() ? 2 : el.isOnHold() ? 1 : 0, 'desc')}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Drawer>
    );
};

export default WildixCalls;
