import React from 'react';
import { connect } from 'react-redux';
import { addKeyDown, removeKeyDown, setKeyDown } from 'Actions/KeyDown/KeyDown';
import uuidv4 from 'uuid/v4';

class QrScanWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.scanning = false;
        this.scanData = '';
        this.state = {
            scanData: ''
        }
    }
    
    componentDidMount = () => {
        this.props.addKeyDown(this.handlePdaScan)
        this.scanning = false;
    }

    componentWillUnmount = () => {
        this.props.removeKeyDown();
    }

    handleClose = () => this.props.closeDialog()

    handlePdaScan = e => {
        if(e.keyCode === 0) {
            return;
        }
        const { handleScan } = this.props;
        if(e.keyCode === 192) {
            if(this.scanning) {
                this.scanning = false;
                handleScan(this.scanData);
                this.scanData = '';
            } else {
                this.scanning = true;
            }
        } else {
            if(this.scanning) {
                if (e.keyCode === 189 || e.keyCode === 173) {
                    this.setState({scanData: this.state.scanData + '-'})
                    this.scanData += '-';
                } else if (
                    e.keyCode === 32 ||
                    (e.keyCode >= 65 && e.keyCode <= 90) ||
                    (e.keyCode >= 48 && e.keyCode <= 57) ||
                    (e.keyCode >= 96 && e.keyCode <= 105)
                ) {
                    this.setState({scanData: this.state.scanData + e.key})
                    this.scanData += e.key;
                }
            } else {
                this.props.parentScan(e);
            }
        }
    }
    
    render = () => {
        const {children} = this.props;
        return children ? children : null;
    }
}

//common component so make keydown function unique to each instance
let scannerUUid = `QrScanner${uuidv4()}`;

const mapDespatchToProps = dispatch => ({
	addKeyDown: (func) => dispatch(addKeyDown(func,scannerUUid)),
	setKeyDown: () => dispatch(setKeyDown(scannerUUid)),
	removeKeyDown: () => dispatch(removeKeyDown(scannerUUid))
});

export default connect(null, mapDespatchToProps)(QrScanWrapper);
