import React, { Component } from 'react';
import API from 'API';
import _ from 'lodash';
import { specialCharacterWhiteList } from 'Constants';

import { Button, FormControl, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, Checkbox, FormGroup, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import CopyToClipboard from 'Components/Common/CopyToClipboard/CopyToClipboard';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import EmailField from 'Components/Common/Inputs/EmailField';
import CheckBox from 'Components/Common/Inputs/CheckBox';
import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = { 
    custid:null,
    formData: {
        customer: '',
        name: '',
        position: '',
        telephone: '',
        mobile: '',
        email: '',
        associatedAddresses: [],
        unsubscribe: false,
        validation: 'valid',
        manualConvert: [],
        manualNoConvert: [],
        manualDelete: []
    },
    customerList: [],
    manualContacts: [],
    customers: {},
    customerAddresses: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false,
    showAddAnotherOpen: false,
    manualContactsDialog: {
        open: false,
        contacts: [],
    }
}

class AddCustomerContact extends Component {
    constructor(props) {
        super(props);
        let mobile = initialState.formData.mobile,
            telephone = initialState.formData.telephone,
            number = this.props.match.params.number ? this.props.match.params.number : this.props.number ? this.props.number : null;
        if(number) {
            if(number.substring(0,2) === '07') {
                mobile = number;
            } else {
                telephone = number;
            }
        }
        this.state = {
            ...initialState,
            custid: this.props.match.params.custid ? this.props.match.params.custid : this.props.custid,
            formData: {
                ...initialState.formData,
                telephone,
                mobile
            }
        };
    }

    componentDidMount(){
       this.loadData();
    }

    loadData = () => {
        this.getCustomersWithAddresses();
        this.getManualContacts();
    }

    getManualContacts() {
        API.get('/marketing/contacts', {params: { active: 1 }})
        .then((result) => {
            if(result.data){
                this.setState({
                    manualContacts: result.data
                });
            }
        });
    }

    checkCustomerPreFill() {
        if (this.state.custid){
            this.setState({isLoading:true});
            API.get('/customers/' + this.state.custid)
                .then(result => {
                    if(result.data && !result.data.errors) {
                        this.setState({
                            formData:{
                                ...this.state.formData,
                                customer: result.data.cust_id
                            }
                        })
                    }
                    this.getCustomerAddresses(this.state.formData.customer)
                    this.setState({isLoading:false});
                });
        }
    }

    getCustomersWithAddresses() {
        this.setState({
            isLoading: true
        },
        () => {
            let customerList;
            API.get(`/customers/withAddresses`)
            .then((result) => {
                if(result.data){
                    customerList = _.map(result.data, el => {
                        el.registeredPostcode = _.result(_.find(el?.addresses, {'address_type': 'Registered'}), 'address_postcode');
                        return _.assign({
                            value: el.cust_id,
                            label: el.cust_name + ' ('+el?.registeredPostcode+')',
                        });
                    });
                }
                this.setState({
                    customerList: customerList,
                    customers: result.data,
                    isLoading:(this.state.custid)?true:false
                }, 
                () => {
                    // If been redirected from Add Customer and have a customer ID - populate the dropdown & addresses
                    if(this.props.location?.state?.customerId && this.props.location?.state?.customerId > 0){
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                customer: this.props.location?.state?.customerId
                            }
                        }, 
                        () => {
                            this.getCustomerAddresses(this.props.location?.state?.customerId)
                        });
                    }
                });
            }).then(() => {
                //do this check here to stop error where page tries to load customer before there address details are loaded in
                this.checkCustomerPreFill();
            });
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        }, this.checkForErrors);
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    doesStringContainNonAlphanumeric = (textString) => {
        const regex = specialCharacterWhiteList;
        return regex.test(textString);
    };

    checkForErrors = () => {
        let { formData, formErrors, specialCharacterError } = this.state;
        formErrors['name'] = (this.doesStringContainNonAlphanumeric(formData.name)) ? 'Address name contains invalid characters' : null;
        specialCharacterError = (formErrors['name']) ? true : false;
        this.setState({formErrors, specialCharacterError})
    }

    submit = () => {
        this.setState({
            isLoading: true,
        },
        () => {
            API.post(`/customers/contacts`, this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                } else {
                    this.handleHideShowAddAnotherToContact();
                }               
            });
        });
    }

    handleAddAnotherToContact = (resp = false) => {
        this.handleHideShowAddAnotherToContact()
        if(resp){
            this.setState({
                formData:{
                    ...initialState.formData,
                    customer: resp ? this.state.formData.customer : null
                },
                isLoading: false
            }, this.getManualContacts);
        } else {
            this.setState({
                ...initialState,
            }, this.loadData);   
        }
        this.props.scrollToTop();
    }

    handleHideShowAddAnotherToContact = () => {
        this.setState({showAddAnotherOpen: !this.state.showAddAnotherOpen });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if(fieldName === 'customer'){
                this.getCustomerAddresses(selectedOption && selectedOption.value)
            }
        });
    }

    getCustomerAddresses(customerId)  {
        let cust = _.find(this.state.customers, {'cust_id': customerId});
        this.setState({
            customerAddresses: customerId > 0 ? cust.addresses : [],
            formData: {
                ...this.state.formData,
                associatedAddresses: []
            }
        });
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.state.customerAddresses.map(n => n.address_id);
            this.setState({
                formData:{
                    ...this.state.formData,
                    associatedAddresses: newSelecteds
                }
            });
        } 
        else {
            this.setState({
                formData:{
                    ...this.state.formData,
                    associatedAddresses: []
                }
            });
        }
    }

    handleClick(e, name) {
        if ( e.target.nodeName == 'TD' || e.target.nodeName == 'INPUT') {
            const selectedAddresses = this.state.formData.associatedAddresses;

            const selectedIndex = selectedAddresses.indexOf(name);
            let newSelected = [];
        
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedAddresses, name);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedAddresses.slice(1));
            } else if (selectedIndex === selectedAddresses.length - 1) {
                newSelected = newSelected.concat(selectedAddresses.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedAddresses.slice(0, selectedIndex),
                    selectedAddresses.slice(selectedIndex + 1),
                );
            }
            this.setState({
                formData:{
                    ...this.state.formData,
                    associatedAddresses: newSelected
                }
            });
        }
    }

    handleEmailValidation = (value) => {
        this.setState({
            formData:{
                ...this.state.formData,
                validation: value
            }
        });
    }

    handleCheckManualContacts = () => {
        let manualContacts = this.state.manualContacts;
        let formData = this.state.formData;
        let manualContact = _.filter(manualContacts, {contact_email: formData.email});

        if (manualContact.length > 0){
            this.setState({
                manualContactsDialog: {
                    open: true,
                    contacts: manualContact
                }
            }); 
        } else {
            this.handleConfirmationOpen();
        }
    }

    render() {
        const { formErrors, formData, isLoading, customerAddresses } = this.state;
        const isSelected = name => this.state.formData.associatedAddresses.indexOf(name) !== -1;

        const AssociatedAdressRow = ({item, isItemSelected}) => {
            return (
                <TableRow
                    hover
                    onClick={e => {this.handleClick(e, item.address_id)}}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={item.address_id}
                    selected={isItemSelected}
                >
                    <TableCell padding="checkbox">
                        <Checkbox
                            checked={isItemSelected}
                            color="primary"
                        />
                    </TableCell>
                    <TableCell>
                        {item.address_type}
                    </TableCell>
                    <TableCell>
                        {item.address_name}
                    </TableCell>
                    <TableCell>
                        {item.address_line_one + ', ' + item.address_town + ', ' + item.address_postcode}
                    </TableCell>
                    <TableCell>
                        {item?.address_phone || '-'} {item?.address_phone && <CopyToClipboard copy={item?.address_phone}/>}
                    </TableCell>
                    <TableCell>
                        {item?.address_email || '-'} {item?.address_email && <CopyToClipboard copy={item?.address_email}/>}
                    </TableCell>
                </TableRow>
            );
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Contact
                    </Typography>
                </Grid>
                {(isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                )) || (
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <Typography variant="h6">
                                        Contact Details
                                    </Typography>
                                    {formErrors && formErrors.generic && (
                                        <React.Fragment>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {formErrors.generic}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                    <form noValidate autoComplete="off">
                                        <FormControl fullWidth margin="normal">
                                            <AutoCompleteSelect 
                                                options={this.state.customerList} 
                                                label='Customer *'
                                                onChange={this.handleSelectChange('customer')}
                                                error={formErrors && formErrors['customer'] && true}
                                                errorText={formErrors && formErrors['customer']}
                                                value={this.state.formData.customer}
                                            />
                                        </FormControl>
                                        <TextField
                                            id="name"
                                            name="name"
                                            label="Name *"
                                            value={formData.name}
                                            error={formErrors && formErrors['name'] && true}
                                            helperText={formErrors && formErrors['name']}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <TextField
                                            id="position"
                                            name="position"
                                            label="Position"
                                            value={formData.position}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <EmailField
                                            id="email"
                                            name="email"
                                            label="Email"
                                            value={formData.email}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            fullWidth
                                            validationCallBack={this.handleEmailValidation}
                                        />
                                        <Grid container item spacing={3}>
                                            <Grid item xs={12} lg={6}>
                                                <TextField
                                                    id="mobile"
                                                    name="mobile"
                                                    label="Mobile"
                                                    value={formData.mobile}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <TextField
                                                    id="telephone"
                                                    name="telephone"
                                                    label="Telephone"
                                                    value={formData.telephone}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormGroup
                                            style={{marginTop: '1em'}}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="unsubscribe"
                                                        name="unsubscribe"
                                                        label="Unsubscribe From All Marketing"
                                                        checked={formData.validation === 'unsubscribed' ? 1 : formData.unsubscribe}
                                                        onChange={()=>this.handleChange({target:{name:'unsubscribe', value: formData.unsubscribe ? 0 : 1}})}
                                                        color='primary'
                                                        margin="normal"
                                                        fullWidth
                                                        disabled={formData.validation === 'unsubscribed'}
                                                    />
                                                }
                                                label={'Unsubscribe From All Marketing'}
                                            />
                                        </FormGroup>
                                    </form>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={9}>
                                <PaddedPaper>
                                    <Typography variant="h6" gutterBottom>
                                        Associated Addresses
                                    </Typography>
                                    {customerAddresses && customerAddresses.length > 0 ?
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            indeterminate={formData.associatedAddresses.length > 0 && formData.associatedAddresses.length < customerAddresses.length}
                                                            checked={formData.associatedAddresses.length === customerAddresses.length}
                                                            onChange={this.handleSelectAllClick}
                                                            color="primary"
                                                        />
                                                    </TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Address</TableCell>
                                                    <TableCell>Phone Number</TableCell>
                                                    <TableCell>Email</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {/* rich wanted these ordeing by address name alphabeticaly w/ Registered, Trade and Accounts at the top */}
                                                {customerAddresses && customerAddresses.filter( item =>  item.address_type === 'Registered').map((item, idx) => 
                                                    <AssociatedAdressRow isItemSelected={isSelected(item.address_id)} item={item} />
                                                )}
                                                {customerAddresses && _.sortBy(customerAddresses.filter( item => item.address_type === 'Trading'),[ca => ca.address_name.toLowerCase()]).map((item, idx) => 
                                                    <AssociatedAdressRow isItemSelected={isSelected(item.address_id)} item={item} />
                                                )}
                                                {customerAddresses && _.sortBy(customerAddresses.filter( item => item.address_type === 'Accounts'),[ca => ca.address_name.toLowerCase()]).map((item, idx) => 
                                                    <AssociatedAdressRow isItemSelected={isSelected(item.address_id)} item={item} />
                                                )}
                                                {customerAddresses && _.sortBy(customerAddresses.filter( item => item.address_type === 'Site'),[ca => ca.address_name.toLowerCase()]).map((item, idx) => 
                                                    <AssociatedAdressRow isItemSelected={isSelected(item.address_id)} item={item} />
                                                )}
                                            </TableBody>
                                        </Table>
                                        :
                                        <Typography>
                                            No results were found
                                        </Typography>
                                    }
                                    <br></br>
                                    <Typography variant="body1" style={{color:colors.red}}>
                                        {formErrors && formErrors['associatedAddresses']}
                                    </Typography>
                                    {this.state.specialCharacterError && 
                                        <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom style={{color: 'red', textAlign: 'right'}}>
                                                Please remove special characters from the marked fields
                                            </Typography>
                                        </Grid>
                                    }
                                    <div className='buttonRow'>
                                        <Button onClick={this.handleCheckManualContacts} 
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.specialCharacterError}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen} 
                            success={this.handleConfirmationSuccess} 
                            close={this.handleConfirmationClose} 
                            title="Add Contact?" 
                            message="Are you sure you want to add this contact?"
                        />

                        <SnackBar
                            variant="success"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen}
                            onClose={this.handleSnackbarClose}
                            message='You have successfully added the contact'
                        />
                    </React.Fragment>
                )}
                <ConfirmationDialog 
                    open={this.state.showAddAnotherOpen}
                    success={()=>this.handleAddAnotherToContact(true)}
                    close={()=>this.handleAddAnotherToContact(false)}
                    title="Add Another Contact?" 
                    message="Do you want to add another contact to customer?"
                />
                {this.state.manualContactsDialog.open &&
                    <Dialog
                        open={this.state.manualContactsDialog.open}
                        onClose={() => this.setState({manualContactsDialog: {open: false, contacts: []}})}
                        maxWidth='md'
                        fullWidth
                    >
                        <DialogTitle>Manual Contacts</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant='body2'>
                                        This email address already exists in the manual contacts list
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Company</TableCell>
                                                <TableCell style={{width: '1%'}}>Add To Customer</TableCell>
                                                <TableCell style={{width: '1%'}}>Don't Add</TableCell>
                                                <TableCell style={{width: '1%'}}>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.manualContactsDialog.contacts.map((contact, idx) => (
                                                <TableRow key={idx}>
                                                    <TableCell>{contact.contact_name}</TableCell>
                                                    <TableCell>{contact?.contact_company ?? '-'}</TableCell>
                                                    <TableCell  style={{textAlign:'center', alignItems: 'center'}}>
                                                        <CheckBox 
                                                            checked={this.state.formData.manualConvert.includes(contact.contact_id)}
                                                            onClick={() => {
                                                                this.setState({
                                                                    formData: {
                                                                        ...this.state.formData,
                                                                        manualConvert: this.state.formData.manualConvert.includes(contact.contact_id) ? this.state.formData.manualConvert.filter(id => id !== contact.contact_id) : this.state.formData.manualConvert.concat(contact.contact_id),
                                                                        manualNoConvert: this.state.formData.manualNoConvert.includes(contact.contact_id) ? this.state.formData.manualNoConvert.filter(id => id !== contact.contact_id) : this.state.formData.manualNoConvert,
                                                                        manualDelete: this.state.formData.manualDelete.includes(contact.contact_id) ? this.state.formData.manualDelete.filter(id => id !== contact.contact_id) : this.state.formData.manualDelete
                                                                    }
                                                                })
                                                            }}
                                                            color='primary'
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{textAlign:'center', alignItems: 'center'}}>
                                                        <CheckBox 
                                                            checked={this.state.formData.manualNoConvert.includes(contact.contact_id)}
                                                            onClick={() => {
                                                                this.setState({
                                                                    formData: {
                                                                        ...this.state.formData,
                                                                        manualNoConvert: this.state.formData.manualNoConvert.includes(contact.contact_id) ? this.state.formData.manualNoConvert.filter(id => id !== contact.contact_id) : this.state.formData.manualNoConvert.concat(contact.contact_id),
                                                                        manualConvert: this.state.formData.manualConvert.includes(contact.contact_id) ? this.state.formData.manualConvert.filter(id => id !== contact.contact_id) : this.state.formData.manualConvert,
                                                                        manualDelete: this.state.formData.manualDelete.includes(contact.contact_id) ? this.state.formData.manualDelete.filter(id => id !== contact.contact_id) : this.state.formData.manualDelete
                                                                    }
                                                                })
                                                            }}
                                                            color='primary'
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{textAlign:'center', alignItems: 'center'}}>
                                                        <CheckBox 
                                                            checked={this.state.formData.manualDelete.includes(contact.contact_id)}
                                                            onClick={() => {
                                                                this.setState({
                                                                    formData: {
                                                                        ...this.state.formData,
                                                                        manualDelete: this.state.formData.manualDelete.includes(contact.contact_id) ? this.state.formData.manualDelete.filter(id => id !== contact.contact_id) : this.state.formData.manualDelete.concat(contact.contact_id),
                                                                        manualConvert: this.state.formData.manualConvert.includes(contact.contact_id) ? this.state.formData.manualConvert.filter(id => id !== contact.contact_id) : this.state.formData.manualConvert,
                                                                        manualNoConvert: this.state.formData.manualNoConvert.includes(contact.contact_id) ? this.state.formData.manualNoConvert.filter(id => id !== contact.contact_id) : this.state.formData.manualNoConvert
                                                                    }
                                                                })
                                                            }}
                                                            color='secondary'
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        manualContactsDialog: {
                                            open: false,
                                            contacts: []
                                        }
                                    })
                                }}
                                variant='outlined'
                            >Close</Button>
                            <Button
                                disabled={[...formData.manualConvert, ...formData.manualNoConvert, ...formData.manualDelete].length !== this.state.manualContactsDialog.contacts.length}
                                onClick={() => {
                                    this.handleConfirmationOpen();
                                    this.setState({
                                        manualContactsDialog: {
                                            open: false,
                                            contacts: []
                                        }
                                    })
                                }}
                                variant='contained'
                                color='primary'
                            >Submit</Button>
                        </DialogActions>
                    </Dialog>
                }
            </Grid>
        );
    }
}

export default AddCustomerContact;