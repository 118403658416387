import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import {colors} from 'Helpers/ColourHelper';

const styles = theme => ({
    td : {
        border: 'none'
    },
    [theme.breakpoints.down('xs')]: {
        responsiveTable: {
            maxWidth: '100%', 
            overflowX: 'auto'
        }
    }
});

class OrderItems extends PureComponent {
    
    render() {
        const { classes, formErrors, orderRows, handleAddRow, handleRemoveRow, handleSelectRowChange, handleRowChange, defaultItemsList, type, nominalList  } = this.props;
        return (
            <form noValidate autoComplete="off">
                <Typography variant="h6">
                    Order Items
                </Typography>
                <div className={classes.responsiveTable}>
                    <Table style={{minWidth: 900}}>
                        <TableBody>
                            {orderRows.map((item, idx) => {
                                let number = idx+1
                                return (
                                    <React.Fragment key={`or_${idx}`}>
                                        <TableRow>
                                            <TableCell className={classes.td} style={{width:'30%'}}>
                                            {type === 2 ?
                                                <TextField
                                                    name="description"
                                                    value={item.description}
                                                    label="Description *"
                                                    error={formErrors && formErrors['orderRows|description|'+idx] && true}
                                                    helperText={formErrors && formErrors['orderRows|description|'+idx]}
                                                    onChange={handleRowChange(idx, 'orderRows')}
                                                    fullWidth
                                                />
                                            :
                                                <FormControl fullWidth>
                                                    <AutoCompleteSelect 
                                                        options={defaultItemsList} 
                                                        value={item.description}
                                                        label="Description *"
                                                        error={formErrors && formErrors['orderRows|description|'+idx] && true}
                                                        errorText={formErrors && formErrors['orderRows|description|'+idx]}
                                                        onChange={handleSelectRowChange(idx, 'description', 'orderRows')}
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            }
                                            </TableCell>
                                            <TableCell className={classes.td} style={{width:'30%'}}>
                                                <FormControl fullWidth>
                                                    <AutoCompleteSelect 
                                                        options={type === 2 ? nominalList : item.nominalList} 
                                                        value={item.nominalCode}
                                                        label='Nominal Code *'
                                                        error={formErrors && formErrors['orderRows|nominalCode|'+idx] && true}
                                                        errorText={formErrors && formErrors['orderRows|nominalCode|'+idx]}
                                                        onChange={handleSelectRowChange(idx, 'nominalCode', 'orderRows')}
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell className={classes.td}>
                                                <TextField
                                                    name="quantity"
                                                    value={item.quantity}
                                                    label="Quantity"
                                                    error={formErrors && formErrors['orderRows|quantity|'+idx] && true}
                                                    helperText={formErrors && formErrors['orderRows|quantity|'+idx]}
                                                    onChange={handleRowChange(idx, 'orderRows')}
                                                    onBlur={handleRowChange(idx, 'orderRows', 2)}
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell className={classes.td}>
                                                <TextField
                                                    name="unitPrice"
                                                    value={item.unitPrice}
                                                    label="Unit Price"
                                                    error={formErrors && formErrors['orderRows|unitPrice|'+idx] && true}
                                                    helperText={formErrors && formErrors['orderRows|unitPrice|'+idx]}
                                                    onChange={handleRowChange(idx, 'orderRows')}
                                                    onBlur={handleRowChange(idx, 'orderRows', 2)}
                                                    type="number"
                                                    inputProps={{ min: 0 }}
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell className={classes.td}>
                                                <TextField
                                                    name="totalPrice"
                                                    value={item.totalPrice}
                                                    label="Total Price"
                                                    error={formErrors && formErrors['orderRows|totalPrice|'+idx] && true}
                                                    helperText={formErrors && formErrors['orderRows|totalPrice|'+idx]}
                                                    onChange={handleRowChange(idx, 'orderRows')}
                                                    onBlur={handleRowChange(idx, 'orderRows', 2)}
                                                    type="number"
                                                    disabled
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell className={classes.td}>
                                                {number > 1 &&
                                                    <IconButton onClick={handleRemoveRow(idx, 'orderRows')}>
                                                        <FALightIcon icon='times' noMargin button />
                                                    </IconButton>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                            <TableRow>
                                <TableCell className={classes.td} colSpan={5}>
                                    <Button 
                                        onClick={handleAddRow('orderRows')}
                                        variant="contained"
                                        color="primary"
                                        size="small">
                                        Add Row
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                {formErrors && formErrors['orderRows'] && formErrors['orderRows'].split('\n').map((item, key) => {
                    return (
                        <React.Fragment key={key}>
                            <Typography component={"div"} style={{color: colors.red}}>
                                {item}<br/>
                            </Typography>
                        </React.Fragment>
                    )
                })}
            </form>
        );
    }
}

export default withStyles(styles)(OrderItems);
    