import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = theme => ({
    td : {
        border: 'none',
    },
});

class QuoteTotals extends PureComponent {
    render(){
        const { classes, customerVatPercentage, vat, subTotal, overallPrice } = this.props;
        return(
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell align="right" className={classes.td}>
                            <Typography variant="subtitle1">
                                Sub Total
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.td}>
                            <TextField
                                name="subTotal"
                                value={subTotal}
                                type="number"
                                fullWidth
                                disabled
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                }}
                            />
                        </TableCell>
                        <TableCell colSpan="2" className={classes.td}></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right" className={classes.td}>
                            <Typography variant="subtitle1">
                                VAT ({customerVatPercentage})
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.td}>
                            <TextField
                                name="vat"
                                value={vat}
                                type="number"
                                fullWidth
                                disabled
                                error={!customerVatPercentage && true}
                                helperText={!customerVatPercentage && 'This customer does not have an assigned VAT'}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                }}
                            />
                        </TableCell>
                        <TableCell colSpan="2" className={classes.td}></TableCell>
                    </TableRow>
                    <TableRow style={{backgroundColor:'#f2f2f2'}}>
                        <TableCell align="right" className={classes.td}>
                            <Typography variant="subtitle1">
                                Total
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.td}>
                            <TextField
                                name="overallPrice"
                                value={overallPrice}
                                type="number"
                                fullWidth
                                disabled
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                }}
                            />
                        </TableCell>
                        <TableCell colSpan="2" className={classes.td}></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }
}

export default withStyles(styles)(QuoteTotals);