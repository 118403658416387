import React, {Component} from 'react';
import API                from 'API';
import { connect }        from 'react-redux';
import _                  from 'lodash';

import { Grid, Dialog, DialogTitle, DialogContent, List, ListItem, Checkbox, ListItemSecondaryAction, ListItemText, DialogActions, Button, Link, Typography } from '@material-ui/core';

import MediaTable from 'Components/Common/DataTables/MediaTable';
import AllIcon    from 'Components/Common/Icons/AllIcon';

import icons from 'Helpers/IconHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { deployDialog }       from 'Actions/Dialog/Dialog';
import { closeDialog }        from 'Actions/Dialog/Dialog';
import AddEngineerMedia from './AddEngineerMedia';
import { colors } from 'Helpers/ColourHelper';

const initialState = {
    formData:   {
        customerId: 0,
        siteId:     0,
        orderId:    0,
        quoteId:    0,
        returnId:   0,
    },
    formDataDelete: {
        emd_id:           null,
        showDeleteDialog: false,
        orderIds:         [],
        quoteIds:         [],
        returnIds:        [],
    },
    show: {
        addMedia: false,
        order:    false,
        quote:    false,
        return:   false,
        site:     false,
        references: false,
    },
    media:      [],
    isLoading:  true,
}

class ViewEngineerMedia extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.state.formData.customerId = this.props?.customerId ?? 0;
        this.state.formData.siteId = this.props?.siteId ?? 0;
        this.state.formData.orderId = this.props?.orderId ?? 0;
        this.state.formData.quoteId = this.props?.quoteId ?? 0;
        this.state.formData.returnId = this.props?.returnId ?? 0;
    }

    componentDidMount(){
        this.getMedia();
        this.calculateShow();
    }

    calculateShow = () => {
        let show = this.state.show;
        if (this.state.formData.siteId > 0) {
            show.order = true;
            show.quote = true;
            show.return = true;
            show.addMedia = true;
            show.references = true;
        }
        if (this.state.formData.customerId > 0) {
            show.order = true;
            show.quote = true;
            show.return = true;
            show.addMedia = true;
            show.site = true;
            show.references = true;
        }
        this.setState({ show });
    }

    getMedia = () => {
        this.setState({ isLoading: true },()=>{
            API.get('/sales/orders/engineerMedia', {params: {...this.state.formData}})
            .then(res => {
                let media = [];
                _.each(res.data, m => { 
                    _.each( m.descriptions, d => { 
                        media.push({ 
                            ...d, 
                            file_url: m.file_url,
                        }); 
                    }); 
                });
                this.setState({ media, isLoading: false });
            });
        });
    }

    deleteMedia = () => {
        API.post(`/sales/orders/engineerMedia/${ this.state.formDataDelete.emd_id }/delete`, this.state.formDataDelete)
        .then(() => {
            this.setState({ formDataDelete: initialState.formDataDelete }, () => {
                this.closeDeleteDialog();
                this.getMedia();
            });
        })
    }

    checkDeleteMedia = (e) => {
        this.setState({
            formDataDelete: {
                ...this.state.formDataDelete,
                emd_id:           e.emd_id,
                showDeleteDialog: 1,
            }
        })
    }

    handleCheck = (field, id) => {

        let item = this.state.formDataDelete[field];
        if (item.includes(id)) {item = item.filter(i => i !== id);}
        else { item.push(id); }

        this.setState({ 
            formDataDelete: {
                ...this.state.formDataDelete,
                [field]: item 
            }
        });
    }

    closeDeleteDialog = () => {
        this.setState({
            formDataDelete: {
                ...initialState.formDataDelete,
                orderIds: [],
                quoteIds: [],
            }
        });
    }

    handleAddMedia = () => {
        this.props.deployDialog(<AddEngineerMedia onSubmit={this.getMedia} {...this.state.formData} />, 'Add Media');
    }

    render () {
        const { isLoading, media, formDataDelete, formData, show } = this.state;
        const { slim } = this.props;

        return (

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MediaTable
                        config={{
                            isLoading:  isLoading,
                            key:        'emd_id',
                            itemPerRow: slim ? 4 : 5,
                            mobileItemPerRow: 1,
                            pagination: true,
                            filterInPagination: false,
                        }}
                        media='file_url'
                        fields={[
                            {
                                heading: 'Product Type',
                                field:   'emd_type',
                                format:  'newLine',
                            },
                            {
                                heading: 'Location',
                                field:   'emd_location',
                                format:  'newLine',
                            },
                            {
                                heading: 'Description',
                                field:   'emd_desc',
                                format:  'newLine',
                            },
                            {
                                heading: 'Serial Numbers',
                                field:   rowData => rowData?.serial_numbers?.length > 0  && <>
                                    <br/>
                                    <Grid container style={{justifyContent: 'space-between'}}>
                                        {_.map(rowData.serial_numbers, i => 
                                            <>
                                                <Grid item key={i.emdsn_serial_number}>
                                                    {i.emdsn_serial_number}
                                                </Grid>    
                                                <Grid item key={i.emdsn_serial_number}>
                                                    <AllIcon 
                                                        size='small'
                                                        icon={icons.serialNumber}
                                                        onClick={()=>window.open(`/parts/serial-numbers/history/${i.emdsn_serial_number}`,'_blank')}
                                                        noPadding 
                                                        noMargin
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </>,
                            },
                            show.references && 
                            {
                                field: rowData => 
                                    <Grid container>
                                        {( rowData?.orders?.length > 0 || rowData?.quotes?.length > 0 || rowData?.returns?.length > 0 ) && 
                                            <>
                                                <Grid item container>
                                                    <Grid item xs={12}><b style={{marginRight:'0.5em'}}>Orders:</b></Grid>
                                                    {rowData?.orders?.length > 0 ? _.map(rowData?.orders, o => 
                                                        <Grid item xs={12} container key={o.order_id} style={{justifyContent: 'space-between'}}>
                                                            <Grid item>{o.order_reference}</Grid>
                                                            <Grid item>
                                                                <AllIcon icon={icons.sales} noPadding noMargin onClick={()=>window.location.href = `/sales/order/view/${o.order_id}`} />
                                                            </Grid>
                                                        </Grid>
                                                    ) : 'None Linked'}
                                                </Grid>
                                                <Grid item container>
                                                    <Grid item xs={12}><b style={{marginRight:'0.5em'}}>Quotes:</b></Grid>
                                                    {rowData?.quotes?.length > 0 ? _.map(rowData?.quotes, o => 
                                                        <Grid item xs={12} container key={o.order_id} style={{justifyContent: 'space-between'}}>
                                                            <Grid item>{o.quote_reference}</Grid>
                                                            <Grid item>
                                                                <AllIcon icon={icons.sales} noPadding noMargin onClick={()=>window.location.href = `/sales/quotation/view/${o.quote_id}`} />
                                                            </Grid>
                                                        </Grid>
                                                    ) : 'None Linked'}
                                                </Grid>
                                                <Grid item container>
                                                    <Grid item xs={12}><b style={{marginRight:'0.5em'}}>Returns:</b></Grid>
                                                    {rowData?.returns?.length > 0 ? _.map(rowData?.returns, r => 
                                                        <Grid item xs={12} container key={r.cr_id} style={{justifyContent: 'space-between'}}>
                                                            <Grid item>{r.cr_reference}</Grid>
                                                            <Grid item>
                                                                <AllIcon icon={icons.returns} noPadding noMargin onClick={()=>window.location.href = `/returns/customerReturn/${r.cr_id}`} />
                                                            </Grid>
                                                        </Grid>
                                                    ) : 'None Linked'}
                                                </Grid>
                                            </>
                                        }
                                        {( rowData.emd_notes ) && 
                                             <Grid item container>
                                                <Grid item xs={12}><b style={{marginRight:'0.5em'}}>Notes:</b></Grid>
                                                <Grid item xs={12}>{rowData.emd_notes}</Grid>
                                            </Grid>
                                        }
                                    </Grid>
                            },
                            {
                                actions: rowData => [
                                    {
                                        name:    'Delete',
                                        icon:    icons.delete,
                                        onClick: (e) => this.checkDeleteMedia(e) 
                                    },
                                    {
                                        download: true,
                                    },
                                    {
                                        view: true,
                                    },
                                ]
                            }
                        ]}
                        filters={[
                            {
                                type:       'search',
                                dataRef:    i => {
                                    let ret = `${i.file_url}|${i.emd_location}|${i.emd_desc}`;
                                    _.each(i.orders, o => ret += `|${o.order_reference}`);
                                    _.each(i.quotes, o => ret += `|${o.quote_reference}`);
                                    _.each(i.serial_numbers, o => ret += `|${o.emdsn_serial_number}`);
                                    return ret;
                                },
                            },
                            {
                                type:       'select',
                                dataRef:    'emd_location',
                                label:      'Location',
                            },
                            {
                                type:       'select',
                                dataRef:    'emd_type',
                                label:      'Type',
                            },
                            show.order &&
                            {
                                type:       'select',
                                dataRef:    rowData => _.map(rowData.orders, o => o.order_reference),
                                label:      'Order',
                            },
                            show.quote &&
                            {
                                type:       'select',
                                dataRef:    rowData => _.map(rowData.quotes, o => o.quote_reference),
                                label:      'Quote',
                            },
                            show.return &&
                            {
                                type:       'select',
                                dataRef:    rowData => _.map(rowData.returns, o => o.cr_reference),
                                label:      'Returns',
                            },
                            {
                                type:       'select',
                                dataRef:    rowData => _.map(rowData.serial_numbers, o => o.emdsn_serial_number),
                                label:      'Serial Number',
                            },
                            show.site && {
                                type:       'select',
                                dataRef:    rowData => [..._.merge(
                                    _.map(rowData.orders, o => `${o.order_delivery_cust_address_name}`),
                                    _.map(rowData.quotes, o => `${o.quote_delivery_cust_address_name}`),
                                ),  ...rowData.site ? [`${rowData.site.address_postcode} | ${rowData.site.address_name}`] : []],
                                label:      'Site',
                            },
                            {
                                type:       'fileType',
                            },
                            {
                                type:       'reset',
                            },
                            show.addMedia &&
                            {
                                type:       'custom',
                                field:      <Button fullWidth variant='contained' color='primary' onClick={this.handleAddMedia}><AllIcon icon={icons.plus} color={colors.white}/>Add Media</Button>,
                            }
                        ]}
                        items={_.orderBy(media, [ 'emd_id' ], [ 'desc' ])}
                    />
                </Grid>
                {media.length === 0 &&
                    <Grid item xs={12} md={12} className='buttonRow'>
                        <Button variant='contained' color='primary' onClick={this.handleAddMedia}><AllIcon icon={icons.plus} color={colors.white}/>Add Media</Button>
                    </Grid>
                }
                <Dialog
                    open={formDataDelete.showDeleteDialog}
                    maxWidth='sm'
                    fullWidth
                >
                    <DialogTitle>Delete File</DialogTitle>
                    <DialogContent>
                        <Grid container>
                            {_.find(media, i => i.emd_id === formDataDelete.emd_id)?.orders.length > 0 && [
                                <Grid item xs={12}><b>From Orders</b></Grid>,
                                <Grid item xs={12}>
                                    <List dense>
                                        {_.map(_.find(media, i => i.emd_id === formDataDelete.emd_id)?.orders, o => 
                                                <ListItem
                                                    key={`${o.order_id}${formDataDelete.orderIds.length}`}
                                                    disablePadding
                                                >
                                                    <ListItemText primary={<Link target="_blank" href={`/sales/order/view/${o.order_id}`} >{o.order_reference}</Link>} />
                                                    <ListItemSecondaryAction key={`${o.order_id}${formDataDelete.orderIds.length}`}>
                                                            <Checkbox
                                                                edge='end'
                                                                onChange={()=>this.handleCheck('orderIds',o.order_id)}
                                                                checked={formDataDelete.orderIds.includes(o.order_id) ? true : false}
                                                                value={formDataDelete.orderIds.includes(o.order_id) ? true : false}
                                                                color='primary'
                                                            />
                                                    </ListItemSecondaryAction>    
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </Grid>
                            ]}
                            {_.find(media, i => i.emd_id === formDataDelete.emd_id)?.quotes?.length > 0 && [
                                <Grid item xs={12}><b>From Quotes</b></Grid>,
                                <Grid item xs={12}>
                                    <List dense>
                                        {_.map(_.find(media, i => i.emd_id === formDataDelete.emd_id)?.quotes, o => 
                                                <ListItem
                                                    disablePadding
                                                >
                                                    <ListItemText primary={<Link target="_blank" href={`/sales/quotation/view/${o.quote_id}`} >{o.quote_reference}</Link>} />
                                                    <ListItemSecondaryAction>
                                                            <Checkbox
                                                                edge='end'
                                                                onChange={()=>this.handleCheck('quoteIds',o.quote_id)}
                                                                checked={formDataDelete.quoteIds.includes(o.quote_id) ? true : false}
                                                                value={formDataDelete.quoteIds.includes(o.quote_id) ? true : false}
                                                                color='primary'
                                                            />
                                                    </ListItemSecondaryAction>    
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </Grid>
                            ]}
                            {(_.find(media, i => i.emd_id === formDataDelete.emd_id)?.orders.length === 0 && _.find(media, i => i.emd_id === formDataDelete.emd_id)?.quotes?.length === 0) && 
                                <Typography variant='body1'>Are you sure you want tot delete this engineer media? </Typography>
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDeleteDialog} variant='outlined'>
                            Cancel
                        </Button>
                        <Button 
                            onClick={this.deleteMedia} 
                            color="secondary" 
                            variant='contained'
                            disabled={!(_.find(media, i => i.emd_id === formDataDelete.emd_id)?.orders.length === 0 && _.find(media, i => i.emd_id === formDataDelete.emd_id)?.quotes?.length === 0) && formDataDelete.orderIds.length === 0 && formDataDelete.quoteIds.length === 0}
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
        deployDialog:       (content, title, size='md') => dispatch(deployDialog(content, title, size)),
        closeDialog:        ()                          => dispatch(closeDialog()),
    }
}

export default connect(null, mapDispatchToProps)(ViewEngineerMedia);