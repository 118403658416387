import React, { useState } from 'react';
import _ from 'lodash';
import ReactGiphySearchbox from 'react-giphy-searchbox'
import { Picker } from 'emoji-mart';
import "emoji-mart/css/emoji-mart.css";

import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import GifLogo from 'Assets/Logos/gif.png';
import ThreadFile from 'Components/Messages/ThreadFile';
import ThreadImage from 'Components/Messages/ThreadImage';
import ThreadOpenGraph from 'Components/Messages/ThreadOpenGraph';
import { GIPHY_API_KEY, GIPHY_RATING } from 'Constants';

const ThreadSend = ({classes, formData:{fileImg, fileMisc, image, openGraph, message}, handleClearFile, handleClearImage, handleClearOpenGraph, handleFileChange, handleEmojiPick, handleGiphyPick, handleMessageChange, handleSendMessage, handleSendNudge, sending}) => {

    let [emojiPicker, setEmojiPicker] = useState(null);
    let [giphyPicker, setGiphyPicker] = useState(null);

    const emojiPickerOpen = Boolean(emojiPicker),
          giphyPickerOpen = Boolean(giphyPicker);

    const handleGiphy = giphy => {
        setGiphyPicker(null);
        handleGiphyPick(giphy);
    }

    const onPressEnter = e => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            if((!sending && (!_.isEmpty(message.trim()) || !_.isEmpty(image) || fileMisc))) {
                handleSendMessage();
            }
        }
    }

    return (
        <Grid container>
            {(image && (
                <Grid item xs={12}>
                    <Box ml={0.5} mr={5}>
                        <ThreadImage
                            classes={classes}
                            image={image} 
                            handleClearImage={handleClearImage}
                        />
                    </Box>
                    <Box ml={0.5} mt={1} mb={fileMisc ? 0.5 : 1.5}>
                        <Divider />
                    </Box>
                </Grid>
            )) || (openGraph && (
                <Grid item xs={12}>
                    <Box ml={0.5} mr={5}>
                        <ThreadOpenGraph 
                            openGraph={openGraph} 
                            handleClearOpenGraph={handleClearOpenGraph} 
                        />
                    </Box>
                    <Box ml={0.5} mt={1} mb={fileMisc ? 0.5 : 1.5}>
                        <Divider />
                    </Box>
                </Grid>
            ))}
            {fileMisc && (
                <Grid item xs={12}>
                    <Box ml={0.5} mr={5}>
                        <ThreadFile
                            classes={classes}
                            fileName={fileMisc['name']} 
                            fileSize={fileMisc['size']} 
                            fileUrl="" 
                            handleClearFile={handleClearFile}
                        />
                    </Box>
                    <Box ml={0.5} mt={1.5} mb={1.5}>
                        <Divider />
                    </Box>
                </Grid>
            )}
            <Grid item xs={12}>
                <Grid container>
                    <Grid item className='pl-1'>
                        <ClickAwayListener onClickAway={() => setEmojiPicker(null)}>
                            <div>
                                <Tooltip title="Emojis">
                                    <IconButton
                                        aria-controls='emoji-menu'
                                        aria-haspopup="true"
                                        onClick={e => setEmojiPicker(e.currentTarget)}
                                    >
                                        <FAIcon type="light" icon="smile" noMargin button />
                                    </IconButton>
                                </Tooltip>
                                <Popper 
                                    id={emojiPickerOpen ? 'emoji-menu' : undefined} 
                                    open={emojiPickerOpen}
                                    anchorEl={emojiPicker}
                                    disablePortal={true} 
                                >
                                    <Picker 
                                        color='#ef3340'
                                        exclude={['flags']}
                                        showPreview={false}
                                        showSkinTones={false}
                                        onSelect={handleEmojiPick} 
                                        style={{
                                            whiteSpace: 'break-spaces',
                                            fontFamily: 'Roboto',
                                            fontSize: 12
                                        }}
                                    />
                                </Popper>
                            </div>
                        </ClickAwayListener>
                    </Grid>
                    <Grid item>
                        <ClickAwayListener onClickAway={() => setGiphyPicker(null)}>
                            <div>
                                <Tooltip title="Gifs">
                                    <IconButton
                                        aria-controls='giphy-menu'
                                        aria-haspopup="true"
                                        onClick={e => { handleClearImage(); setGiphyPicker(e.currentTarget)}}
                                    >
                                        <img src={GifLogo} width={20} height={20} alt='Gif' />
                                    </IconButton>
                                </Tooltip>
                                <Popper 
                                    id={giphyPickerOpen ? 'giphy-menu' : undefined} 
                                    open={giphyPickerOpen}
                                    anchorEl={giphyPicker}
                                    disablePortal={true} 
                                >
                                    <Paper>
                                        <Box p={2}>
                                            <ReactGiphySearchbox
                                                apiKey={GIPHY_API_KEY}
                                                onSelect={handleGiphy}
                                                masonryConfig={[{columns: 2, imageWidth: 140, gutter: 10}]}
                                                listWrapperClassName={classes.giphyContainer}
                                                rating={GIPHY_RATING}
                                            />
                                        </Box>
                                    </Paper>
                                </Popper>
                            </div>
                        </ClickAwayListener>
                    </Grid>
                    <Grid item className='pl-1'>
                        <label htmlFor="image">
                            <Tooltip title="Attach Image">
                                <IconButton component="span">
                                    <FAIcon type="light" icon="camera" noMargin button />
                                </IconButton>
                            </Tooltip>
                        </label>
                        <input 
                            accept='image/*' 
                            className={classes.fileUpload}
                            id="image" 
                            onChange={e => handleFileChange("fileImg", e)}
                            file={fileImg}
                            type="file" 
                        />
                    </Grid>
                    <Grid item className='pl-1'>
                        <label htmlFor="file">
                            <Tooltip title={`Attach File - Max File Size 500MB`}>
                                <IconButton component="span">
                                    <FAIcon type="light" icon="paperclip" noMargin button />
                                </IconButton>
                            </Tooltip>
                        </label>
                        <input 
                            className={classes.fileUpload}
                            id="file" 
                            onChange={e => handleFileChange("fileMisc", e)}
                            file={fileMisc}
                            type="file" 
                        />
                    </Grid>
                    <Grid item>
                        <Tooltip title="Send Nudge">
                            <IconButton
                                onClick={handleSendNudge}
                            >
                                <FAIcon type="light" icon="bell-exclamation" noMargin button />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems='center'>
                    <Grid item xs>
                        <TextField
                            placeholder="Start typing..."
                            name="message"
                            variant="standard"
                            className={classes.textArea}
                            value={message}
                            onChange={handleMessageChange}
                            rows={4}
                            rowsMax={4}
                            multiline
                            fullWidth
                            InputProps={{disableUnderline: true}}
                            onKeyDown={onPressEnter}
                            autoFocus
                        />
                    </Grid>
                    <Grid item>
                        <Box p={1} pb={0}>
                            <IconButton disabled={(sending || (_.isEmpty(message.trim()) && _.isEmpty(image) && !fileMisc)) && true} onClick={() => handleSendMessage()}>
                                <FAIcon disabled={(sending || (_.isEmpty(message.trim()) && _.isEmpty(image) && !fileMisc)) && true} type="light" icon="paper-plane" size={27.5} noMargin button />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ThreadSend;