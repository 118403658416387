import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Link, TextField, FormControl  } from '@material-ui/core';
import API from 'API';
import ViewOrder from 'Components/Sales/Order/ViewOrder/Order';
import AllIcon from 'Components/Common/Icons/AllIcon';
import InputHelper from 'Helpers/InputHelper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import Textarea from 'Components/Common/Inputs/Textarea';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import moment from 'moment';
import _ from 'lodash';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { connect }    from 'react-redux';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import EmailDialog from 'Components/Common/Dialogs/EmailDialog';
import { deployDialog } from 'Actions/Dialog/Dialog';

const initialState = (props) => ({
    order: null,
    formData:{
        orderId: props.orderId,
        despatchedDate: moment().format('YYYY-MM-DD'),
        deliveryDate: null,
        courier: '',
        trackingNumbers: [],
        courierDespatchNote: '',
        despatchNotes: '',
        courierNotes: '',
        noOfTrackingUrls: 0, 
        consignments: [],
    },
    formErrors: {

    },
    lists: {
        couriers: []
    },
    isLoading: true
});


class AddDespachDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
        this.inputHelper = new InputHelper(this, null, this.checkEffect);
    }

    componentDidMount() {
        this.getOrder();
    }

    checkEffect = () => {
        if (this.state.formData.noOfTrackingUrls !== this.state.formData.trackingNumbers.length){
            let trackingNumbers = [];
            for(let i = 0; i < this.state.formData.noOfTrackingUrls; i++) {
                trackingNumbers.push({number: '', url: ''})
            }
            this.setState({
                formData: {
                    ...this.state.formData,
                    trackingNumbers
                }
            });
        } 
    }

    getOrder = () => {
        Promise.all([
            API.get('/sales/orders/' + this.props.orderId),
            API.get('/sales/couriers/all')
        ]).then(([order, couriers]) => {
            this.setState({
                order: order.data,
                lists: {
                    couriers: couriers.data.couriers
                },
                isLoading: false
            });
        });
    }

    submit = () => {
        let { formData } = this.state;
        let newFormData = new FormData();
        Object.keys(formData).forEach(key => {
            if(key === 'courierDespatchNote') {
                newFormData.append('courierDespatchNote', formData[key]);
            } else if(key === 'trackingNumbers'){
                newFormData.append(key, JSON.stringify(formData[key]))
            }
            else {
                newFormData.append(key, formData[key]);
            }
        });
        API.post(`/sales/orders/despatch/addNoDespach`, newFormData)
        .then(result => {
            if (result.data.errors){
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            } else {
                this.props.deployDialog(
                    <EmailDialog 
                        id={result.data.despId}
                        customerId={result.data.customerId}
                        customerContactId={result.data.contactId}
                        type='noDespatched'
                    />,
                    'You Are About To Email - Order Despatched',
                    'success'
                )
                this.props.closeDialog();
            }
        })
    }

    render() {
        const { closeDialog, open, order } = this.props;
        const { formData, formErrors, lists, isLoading } = this.state;

        if (isLoading){
            return <LoadingCircle />
        }

        return (
            <Dialog
                open={open}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h6">Add Manual Despatch Note</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ExpansionPanel style={{marginTop:'1.5em'}}>
                                <ExpansionPanelSummary
                                    expandIcon={<AllIcon icon="chevron-down" noMargin button style={{color: 'black'}}/>}
                                    style={{backgroundColor: '#F1F1F1'}}
                                >
                                    <Typography noWrap variant="body2" style={{color: 'black'}}>
                                        <b>Order</b>
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <box>
                                        <ViewOrder co={this.state.order} />
                                    </box>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                        <Grid item xs={6} style={{borderRight: '1px solid #ddd'}}>
                            <Typography variant="h6" gutterBottom>Despatch Details</Typography>
                            <DatePicker 
                                type="date"
                                id="formData.despatchedDate"
                                name="formData.despatchedDate"
                                label="Despatch Date"
                                value={formData.despatchedDate}
                                onChange={this.inputHelper.handleDateChange("formData.despatchedDate")}
                            />
                            <DatePicker 
                                type="date"
                                id="formData.deliveryDate"
                                name="formData.deliveryDate"
                                label="Delivery Date *"
                                value={formData.deliveryDate}
                                onChange={this.inputHelper.handleDateChange('formData.deliveryDate')}
                                errorText={formErrors?.['deliveryDate']}
                                autoOk
                            />
                            <Textarea
                                id="formData.despatchNotes"
                                name="formData.despatchNotes"
                                label="Despatch Notes"
                                value={formData.despatchNotes}
                                rows={5}
                                error={formErrors && formErrors['despatchNotes']}
                                onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value), this.checkEffect)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom>Courier Details</Typography>
                            <FormControl error={formErrors && formErrors['courier'] && true} fullWidth margin="normal">
                                <AutoCompleteSelect
                                    options={_.map(lists.couriers, el => ({
                                        value: el.cour_id,
                                        label: el.cour_name
                                    }))} 
                                    label='Courier *'
                                    value={formData.courier}
                                    onChange={this.inputHelper.handleSelectChange('formData.courier')}
                                    error={formErrors && formErrors['courier'] && true}
                                    errorText={formErrors && formErrors['courier'] && formErrors['courier']}
                                />
                            </FormControl>
                            <FormControl error={formErrors && formErrors['courier'] && true} fullWidth margin="normal">
                                <AutoCompleteSelect
                                    options={[
                                        {value: 0, label: '0'},
                                        {value: 1, label: '1'},
                                        {value: 2, label: '2'},
                                        {value: 3, label: '3'},
                                        {value: 4, label: '4'},
                                        {value: 5, label: '5'}
                                    ]} 
                                    label="No. of Tracking URL's"
                                    value={formData.noOfTrackingUrls}
                                    onChange={this.inputHelper.handleSelectChange('formData.noOfTrackingUrls')}
                                />
                            </FormControl>
                            {formData.trackingNumbers.map((el, idx) => {
                                return (
                                    <span key={idx}>
                                        <TextField
                                            id={`trackingNumbers${idx}`}
                                            name={`trackingNumbers${idx}`}
                                            label="Tracking Number"
                                            value={el.number}
                                            error={formErrors?.['trackingNumbers|'+idx] ? true : false}
                                            helperText={formErrors?.['trackingNumbers|'+idx]}
                                            onChange={(e) => {this.inputHelper.handleSetArrayIndex('formData.trackingNumbers',idx)({...el, number: e.target.value}) }}
                                            margin="normal"
                                            fullWidth
                                        />
                                        {el.number && _.find(lists.couriers, {cour_id: formData.courier}).cour_tracking_url &&
                                            <Link href={`${_.find(lists.couriers, {cour_id: formData.courier}).cour_tracking_url}${el.number}`} variant="body2" className='blueLink' target='_blank' rel="noopener">
                                                {_.find(lists.couriers, {cour_id: formData.courier}).cour_tracking_url}{el.number}
                                            </Link>
                                        }
                                    </span>
                                )
                            })}
                            <DragFileInput
                                id="'formData.courierDespatchNote"
                                name="formData.courierDespatchNote"
                                label="Courier Despatch Note"
                                file={formData.courierDespatchNote}
                                errorText={formErrors && formErrors['courierDespatchNote']}
                                onChange={this.inputHelper.handleFileChange('formData.courierDespatchNote')}
                                cancelOnClick={this.inputHelper.handleClearFile('formData.courierDespatchNote')}
                                emptyText='No file selected'
                            />
                            <Textarea
                                id="courierNotes"
                                name="formData.courierNotes"
                                label="Courier Notes"
                                value={formData.courierNotes}
                                rows={5}
                                error={formErrors && formErrors['courierNotes']}
                                onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value), this.checkEffect)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={closeDialog}
                    >Cancel</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => this.props.deployConfirmation(
                                'Are you sure you want to add this despatch note?',
                                'Add Despatch Note',
                                this.submit
                            )
                        }
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success)               => dispatch(deployConfirmation(message, title, success)),
    deployDialog:       (content, title, variant)                => dispatch(deployDialog(content, title, variant)),
})

export default connect(null, mapDispatchToProps)(AddDespachDialog); 