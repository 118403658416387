import React, { useContext } from 'react';
import _              from 'lodash';
import { withRouter } from 'react-router-dom';
import API            from 'API';

import { Avatar, Box, Button, ClickAwayListener, Divider, Grid, IconButton, InputAdornment, Paper, Popper, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import FAIcon         from 'Components/Common/Icons/FontAwesome/FAIcon';
import LoadingCircle  from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon        from 'Components/Common/Icons/AllIcon';
import WildixContext  from "Context/WildixContext";

import icons      from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';
import { useDispatch } from 'react-redux';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

const styles = theme => ({
    container: {
        width: 500, 
    marginLeft: 170
    },
    paper: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginTop: 17,
        textAlign: 'left',
        maxHeight: 500,
        overflowY: 'scroll',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        '&::-webkit-scrollbar': {
            width: '0.3em!important'
        }
    },
    header: {
        backgroundColor: '#eee'
    },
    icon: {
        border: '1px solid #fff',
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            borderColor: '#ddd'
        }
    },
    result: {
        overflowX: 'hidden',
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        }
    },
    resultGrid: {
        padding: theme.spacing(0, 2, 0, 2)
    },
    popper: {
        zIndex: 99999
    },
    search: {
        margin: '0!important',
        width: 300
    }
})

const initialState = {
    anchorEl: null,
    isLoading: false,
    initialLoad: false,
    searchResults: [],
    searchString: ''
}

const Welcome = ({accessIconCustomer, accessIconPart, accessIconSupplier, accessIconDocument, accessIconSales, accessIconReminders, accessIconTasks, classes, handleRedirect, isLoading}) => (
    <Box p={3}>
        {(isLoading && (
            <LoadingCircle />
        )) || (
            <React.Fragment>
                <Typography variant="body1" gutterBottom>
                    Start typing to search...
                </Typography>
                <Box pt={2} pb={1}>
                    <Divider />
                </Box>
                <Grid container justify='center'>
                    {accessIconCustomer && (
                        <Grid item align='center'>
                            <Tooltip title="Customers">
                                <Box p={2.6} className={classes.icon} onClick={() => handleRedirect('/customers/search')}>
                                    <FAIcon type="light" icon='users' noMargin button fixedHeight />
                                </Box>
                            </Tooltip>
                        </Grid>
                    )}
                    {accessIconSales && (
                        <Grid item align='center'>
                            <Tooltip title="Sales">
                                <Box p={2.6} className={classes.icon} onClick={() => handleRedirect('/sales/quotation/search')}>
                                    <FAIcon type="light" icon='handshake' noMargin button fixedHeight />
                                </Box>
                            </Tooltip>
                        </Grid>
                    )}
                    {accessIconDocument && (
                        <Grid item align='center'>
                            <Tooltip title="Documents">
                                <Box p={2.6} className={classes.icon} onClick={() => handleRedirect('/documents/search')}>
                                    <FAIcon type="light" icon='file-alt' noMargin button fixedHeight />
                                </Box>
                            </Tooltip>
                        </Grid>
                    )}
                    {accessIconPart && (
                        <Grid item align='center'>
                            <Tooltip title="Parts">
                                <Box p={2.6} className={classes.icon} onClick={() => handleRedirect('/parts/search')}>
                                    <FAIcon type="light" icon='wrench' noMargin button fixedHeight />
                                </Box>
                            </Tooltip>
                        </Grid>
                    )}
                    {accessIconSupplier && (
                        <Grid item align='center'>
                            <Tooltip title="Suppliers">
                                <Box p={2.6} className={classes.icon} onClick={() => handleRedirect('/suppliers/search')}>
                                    <FAIcon type="light" icon='truck' noMargin button fixedHeight />
                                </Box>
                            </Tooltip>
                        </Grid>
                    )}
                    {accessIconReminders && (
                        <Grid item align='center'>
                            <Tooltip title="Reminders">
                                <Box p={2.6} className={classes.icon} onClick={() => handleRedirect('/reminders')}>
                                    <FAIcon type="light" icon='alarm-clock' noMargin button fixedHeight />
                                </Box>
                            </Tooltip>
                        </Grid>
                    )}
                    {accessIconTasks && (
                        <Grid item align='center'>
                            <Tooltip title="Tasks">
                                <Box p={2.6} className={classes.icon} onClick={() => handleRedirect('/tasks')}>
                                    <FAIcon type="light" icon='tasks' noMargin button fixedHeight />
                                </Box>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </React.Fragment>
        )}
    </Box>
)

const Results = ({classes, handleAction, searchResults, searchString}) => (
    (!_.isEmpty(searchResults) && (
        <React.Fragment>
            <Box pl={2} pr={2} pt={2} pb={1}>
                <Typography variant="h6" gutterBottom>
                    Search Results ({_.size(searchResults)})
                </Typography>
            </Box>
            {_.map(searchResults, (result, idx) => (
                    <Result 
                        key={idx} 
                        classes={classes} 
                        handleAction={handleAction}
                        {...result} 
                    />
            ))}
        </React.Fragment>
    )) || (
        <Box pl={3} pr={3} pt={2} pb={2.5}>
            <Grid container alignItems='center'>
                <Grid item>
                    <FAIcon type="light" icon="info-circle" className="textError" size="large" />
                </Grid>
                <Grid item xs>
                    <Box pl={2.5}>
                        <Typography variant="h6" gutterBottom>
                            Sorry
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            There were no results found matching 
                        </Typography>
                        <Typography variant="body2">
                            "{searchString}"
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
)



const colorText = (text) => {
    let _colors = [
        {
            color: colors.red,
            regex: /([^]*){red}([^]*){\/red}([^]*)/g,
            name: 'red'
        }
    ]
    let newText = [];
    let alterColor = false;

    _.forEach(_colors, i => {
        if(text.includes(`{${i.name}}`)) {
            alterColor = true;
        }
    })

    if (!alterColor) return textExtra(text);

    _.forEach(_colors, i => {
        let matches = i.regex.exec(text);
        if (matches) {
            newText.push(matches[1].includes(`{${i.name}}`) ? <>{colorText(matches[1])}</> : matches[1]);
            newText.push(<span style={{color: colors.red}}>{matches[2].replace(`{${i.name}}`,'').replace(`{/${i.name}}`,'')}</span>)
            newText.push(matches[3]);
          }
    })

    return textExtra(newText);
}

const textExtra = (text) => {
    let extras = [{
        key: 'bold',
        regex: /([^]*){bold}([^]*){\/bold}([^]*)/g,
        style: {fontWeight: 'bold'},
    }];
    let newText = [];
    let alterText = false;

    _.forEach(extras, i => {
        if(text.includes(`{${i.key}}`)) {
            alterText = true;
        }
    })

    
    if (!alterText) return text;
    _.forEach(extras, i => {
        let matches = i.regex.exec(text);
        if (matches) {
            newText.push(matches[1].includes(`{${i.name}}`) ? <>{colorText(matches[1])}</> : matches[1]);
            newText.push(<span style={i.style}>{matches[2].replace(`{${i.name}}`,'').replace(`{/${i.name}}`,'')}</span>)
            newText.push(matches[3]);
        }
    });

    return newText;
}

const Result = ({action, icon, img, handleAction, title, type, subtitle1, subtitle2, subtitle3, classes, extra, p}) => {
    const context = useContext(WildixContext);
    const dispatch = useDispatch();
    const canDial = context?.dial === null;
    const handleDialResult = (e) => {
        e.stopPropagation();
        if (p && !Array.isArray(p)) {
            context.useDial(p);
        } else {
            const handleClose = () => {
                dispatch(closeDialog());
                handleAction(null);
            }
            const options = p.map((number) => ({
                title: number,
                onClick: () => {
                    context.useDial(number, false);
                    handleClose();
                }
            }));
            const Component = ({handleClose, options}) => (
                <>  
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" align="center">
                                Please select the number you want to dial
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {options.map((option, idx) => (
                                    <Grid item xs={6} key={idx}>
                                        <Button variant="contained" color="primary" onClick={option.onClick} fullWidth>
                                            <FAIcon icon="circle-phone" size={17.5} buttonPrimary />
                                            {option.title}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button onClick={handleClose} variant="outlined" color="default" fullWidth>
                                <FAIcon icon="times" size={17.5} button />
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )
            dispatch(deployDialog(
                <Component handleClose={handleClose} options={options} />,
                title,
                'standard',
                'xs'
            ))
        }
    }
    return (
        <Box pt={1} pb={1} className={classes.result} onClick={() => handleAction(action)}>
            <Grid container spacing={1} alignItems='center' className={classes.resultGrid}>
                {p && canDial ? (
                    <Grid item>
                        {img && 
                            (
                                
                                <Avatar variant="square" className="bgLight" style={{
                                    marginBottom: 0,
                                    marginTop: 0
                                }}><ImageWithError alt="Product Image" style={{maxHeight:27.5, maxWidth:27.5}} src={img} /></Avatar>
                            )
                        }
                        
                        <Tooltip title={`Call ${title}`}>
                            <IconButton
                                className="mr-1"
                                size="small"
                                onClick={handleDialResult}
                            >
                                <FAIcon icon="circle-phone" size={27.5} type="solid" className="textSuccess" button noMargin />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                ) : (
                    <Grid item>
                        {(img && (
                            <Avatar variant="square" className="bgLight"><ImageWithError alt="Product Image" style={{maxHeight:35, maxWidth:35}} src={img} /></Avatar>
                        )) || (
                            <FAIcon type="light" icon={icon} />
                        )}
                    </Grid>
                )}
                <Grid item xs>
                    <Typography variant="caption" component="div" color="textSecondary">
                        {!!extra?.locked && <AllIcon icon={icons.lock} color={colors.disabled} size='small'/>}{`${type}`}
                    </Typography>
                    <Typography variant="body2" style={{color: extra?.red == true ? colors.red : (extra?.orange == true ? colors.orange : null)}}>
                        {title}
                    </Typography>
                    {subtitle1 && ( 
                        <Typography variant="caption" component="div" style={{color: extra?.red == true ? colors.red : (extra?.orange == true ? colors.orange : null)}}>
                            {colorText(subtitle1)}
                        </Typography>
                    )}
                    {subtitle2 && ( 
                        <Typography variant="caption" component="div" style={{color: extra?.red == true ? colors.red : (extra?.orange == true ? colors.orange : null)}}>
                            {colorText(subtitle2)}
                        </Typography>
                    )}
                    {subtitle3 && ( 
                        <Typography variant="caption" component="div" style={{color: extra?.red == true ? colors.red : (extra?.orange == true ? colors.orange : null)}}>
                            {colorText(subtitle3)}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}

class AdminSmartSearch extends React.PureComponent {
    constructor(props) {
        super(props);
        this.timeout = false;
        this.state = initialState;
    }

    componentWillUnmount = () => {
        if(this.timeout)
            clearTimeout(this.timeout);
    }

    handleAction = action => {
        if(!_.isEmpty(action)) {
            Object.keys(action).forEach(key => {
                if(key === "href") {
                    this.handleRedirect(action[key])
                }
            })
        }
        this.handleClose(); 
    }

    handleRedirect = href => { 
        this.handleClose()
        this.props.history.push(href)
    }

    getSearchResults = () => {
        const { searchString } = this.state;
        if(searchString.length >= 2) {
            API.get('/smartSearch', 
            {
                params: {
                    searchString
                },
                props: {
                    cancellation: true,
                    noLoading: true
                }
            })
            .then(result => {
                if(result && result.data) {
                    let isLoading = false,
                        searchResults = result.data;
                    this.setState({
                        isLoading,
                        searchResults
                    });
                } 
            });
        }
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchResults, 250);
    }

    onSearchStringChange = (event) => {
        const searchString = event.target.value,
              searchResults = [],
              isLoading = searchString.length >= 2 ? true : false;
        this.setState({
            isLoading,
            searchString,
            searchResults
        }, () => {
            this.setSearch();
        });
    }
    
    handleOpen = event => {
        const anchorEl = event.currentTarget
        this.setState({
            anchorEl
        });
    }

    handleClose = () => {
        if(document?.activeElement?.id === 'search') {
            return;
        }
        const anchorEl = null,
                searchResults = initialState.searchResults,
                searchString = initialState.searchString;
        this.setState({
            anchorEl,
            searchResults,
            searchString
        });
    }

    render() {
        const { accessIconCustomer, accessIconPart, accessIconSupplier, accessIconDocument, accessIconSales, accessIconReminders, accessIconTasks, classes } = this.props;
        const { anchorEl, initialLoad, isLoading, searchResults, searchString } = this.state;
        let open = Boolean(anchorEl);
        return (
            <ClickAwayListener onClickAway={this.handleClose}>
                <div>
                    <TextField
                        id='search'
                        fullWidth
                        onChange={this.onSearchStringChange}
                        onFocus={this.handleOpen}
                        value={searchString} 
                        color="secondary"
                        classes={{
                            root: classes.search
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><FAIcon type="light" icon="search" size="small" color="white" /></InputAdornment>,
                        }}
                        disabled={initialLoad}
                    /> 
                    <Popper 
                        open={open} 
                        anchorEl={anchorEl} 
                        className={classes.popper}
                        disablePortal={true} 
                        id={open ? 'smartSearchResults' : undefined} 
                    >
                        <div className={classes.container}>
                            <Paper className={classes.paper}>
                                {(!isLoading && _.size(searchString) >= 2 && (
                                    <Results 
                                        classes={classes} 
                                        searchResults={searchResults} 
                                        searchString={searchString}
                                        handleAction={this.handleAction}
                                    />
                                )) || (
                                    <Welcome
                                        accessIconCustomer={accessIconCustomer} 
                                        accessIconPart={accessIconPart} 
                                        accessIconSupplier={accessIconSupplier} 
                                        accessIconDocument={accessIconDocument} 
                                        accessIconSales={accessIconSales} 
                                        accessIconReminders={accessIconReminders} 
                                        accessIconTasks={accessIconTasks}
                                        classes={classes}
                                        isLoading={isLoading}
                                        handleRedirect={this.handleRedirect}
                                    />
                                )}
                            </Paper>
                        </div>
                    </Popper>
                </div>
            </ClickAwayListener>
        )
    }
}

export default withRouter(withStyles(styles)(AdminSmartSearch));