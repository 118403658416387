import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../../Common/Paper/PaddedPaper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import DatePicker from '../../../Common/DatePickers/DatePicker';
import API from './../../../../API';
import Textarea from '../../../Common/Inputs/Textarea';
import LoadingCircle from '../../../Common/LoadingCircle/LoadingCircle';
import BackButton from 'Components/Common/Buttons/BackButton';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    
}))(TableCell);

const initialState = {
    saf: {
        saf_reference: '',
        saf_type: '',
        saf_addition_date: '',
        saf_raised_by: '',
        saf_current_qty: 0,
        saf_addition_qty: 0,
        saf_addition_value: 0,
        saf_reason: '',
        saf_notes:'',
        part: {
            part_number: '',
            part_description: '',
        },    
    },
    isLoading: true,
    redirect: false
}

class ViewStockAddition extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getStockAdditionForm();
    }

    getStockAdditionForm = () => {
        let safId = this.props.match.params.id;
        API.get(`/stock/addition/${safId}`)
        .then(result => {
            if (result.data.errors) {
                this.setState({ redirect: true })
            } else if(result.data) {
                this.setState({
                    saf: result.data,
                    isLoading: false
                });
            }
        });
    }
       
    render() {
        const { saf, isLoading } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/stock/additions/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Stock Addition Details
                        </Typography>
                    </Grid>                
                    {(isLoading && (
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <LoadingCircle />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>                    
                    )) || (
                        <React.Fragment>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Main Details
                                        </Typography>
                                        <form>
                                            <TextField
                                                label="Reference"
                                                value={saf.saf_reference}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                                InputLabelProps={{
                                                    shrink: !!saf.saf_reference
                                                }}
                                            />
                                            <TextField
                                                label="Type"
                                                value={saf.saf_type}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                                InputLabelProps={{
                                                    shrink: !!saf.saf_type
                                                }}
                                            />
                                            <DatePicker
                                                type="date"
                                                id="saf_addition_date"
                                                name="saf_addition_date"
                                                label="Addition Date"
                                                value={saf.saf_addition_date}
                                                disableFuture={true}
                                                disabled
                                            />
                                            <TextField
                                                label="Raised By"
                                                value={saf.saf_raised_by}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                                InputLabelProps={{
                                                    shrink: !!saf.saf_raised_by
                                                }}
                                            />
                                            <Typography variant="h6" style={{marginTop:24}} gutterBottom>
                                                Affected Parts
                                            </Typography>
                                            <TextField
                                                label="Part Number"
                                                value={saf.part && saf.part.part_number + ' - ' + saf.part.part_description}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                                InputLabelProps={{
                                                    shrink: !!saf.part && !!saf.part.part_number
                                                }}
                                            />
                                            <FormControl margin="normal" fullWidth>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <CustomTableCell style={{textAlign:'center'}}>Stock Qty<br></br>Before Addition</CustomTableCell>
                                                            <CustomTableCell style={{textAlign:'center'}}>Addition Quantity</CustomTableCell>
                                                            <CustomTableCell style={{textAlign:'center'}}>Addition Value <br></br>Based on addition date</CustomTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell style={{textAlign:'center'}}>{saf.saf_current_qty}</TableCell>
                                                            <TableCell style={{textAlign:'center'}}>{saf.saf_addition_qty}</TableCell>
                                                            <TableCell style={{textAlign:'center'}}>{saf.saf_addition_value}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </FormControl>
                                            <TextField
                                                label="Addition Reason"
                                                value={saf.saf_reason}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                                InputLabelProps={{
                                                    shrink: !!saf.saf_reason
                                                }}
                                            />
                                            <Textarea
                                                id="notes"
                                                name="notes"
                                                label="Notes"
                                                value={saf.saf_notes}
                                                rows={10}
                                                disabled
                                                InputLabelProps={{
                                                    shrink: !!saf.saf_notes
                                                }}
                                            />
                                        </form>
                                        <div className='buttonRow'>
                                            <BackButton props={this.props} />
                                        </div>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            );
        }
    }
}

export default ViewStockAddition;

