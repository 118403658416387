import React, { PureComponent } from 'react';
import _                        from 'lodash';
import { withRouter }           from "react-router";

import AllIcon from 'Components/Common/Icons/AllIcon';

import { Box, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

const styles = theme => ({
    card: {
        color: '#000',
        backgroundColor: '#FFF',
        padding: '0'
    },
    row: {
        color: '#000',
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: '#FCFCFC',
            cursor: 'pointer'
        },
    },
    header: {
        background: ({title}) => title === 'Awaiting Invoice Payment' ? 'linear-gradient(90deg, #3D94D6 0%, rgb(38 175 241) 100%)' : 'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', 
        color: '#FFF'
    },
    legendWrapper: {
        padding: '0 1rem'
    },
    legendTitle: {
        marginBottom: '0.8rem'
    },
    legendItem: {
        display: 'grid',
        gridTemplateColumns: '30px 60px auto',
        marginBottom: '0.4rem'
    }

});
const initialState = {
    icon: ''
}

const ReportCell = ({data, field, hidePound, title, forceFixed=null, inverse=false}) => {
    const pound = (hidePound) ? '' : '£';
    let fixed = (hidePound) ? 0 : 2;
    if (forceFixed !== null) {
        fixed = forceFixed;
    }
    return (
        <TableCell align='center' style={{color:'black'}}>
            {data.in[field] ? 
                <Typography variant="h6" style={{color:'black', fontSize: title === 'Awaiting Invoice Payment' ? '20px':'17px'}}>
                    {`${(inverse ? '-' : '' )}${pound}${parseFloat(data.in[field]).toFixed(fixed)}`}
                </Typography> : <Typography variant="h6" style={{color:'black'}}>-</Typography>
            }
            {data.out[field] ? 
                <Typography variant="h6" style={{color: colors.red, fontSize: title === 'Awaiting Invoice Payment' ? '20px':'17px'}} >
                    {`${data.out[field] && fixed ? (inverse ? '' : '-' ) : ''}${pound}${parseFloat(data.out[field]).toFixed(fixed)}`}
                </Typography> : <Typography variant="h6" style={{color:'black'}}>-</Typography>
            }
            
            <Typography variant="h6" style={{color: (data.in[field] - data.out[field]) >= 0 ? 'black' : colors.red, fontSize: title === 'Awaiting Invoice Payment' ? '20px':'17px'}}>
                {`${(data.in[field] - data.out[field]) < 0 ? (inverse ? '' : '-' ) : (inverse && (data.in[field] - data.out[field]) !== 0 ? '-' : '' )}${pound}${parseFloat( Math.sqrt( Math.pow( ( data.in[field] - data.out[field] ) , 2) ) ).toFixed(fixed)}`}
            </Typography>
        </TableCell>
    )
}

class ReportTile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    render(){
        const { classes, data, title, labels} = this.props;

        return(
            <Card className={classes.card} >
                <CardHeader
                    avatar={<AllIcon icon={ title === 'Awaiting Invoice Payment' ? icons['sales'] : icons[`order${title.replace(' ','').replace(' ','')}`]} size='medium' style={{color: '#fff'}} />}
                    title={(title === 'Cancelled' ? 'Cancelled & Declined' : (title === 'Awaiting Invoice Payment' ? 'Sales (Invoices, Recurring Invoices & Credit Notes)' : (title === 'Awaiting Invoice' ? 'Invoice Awaiting Approval' : title))) }
                    titleTypographyProps={{variant:'h6'}}
                    className={classes.header}
                />
                {/* <Divider style={{backgroundColor:'black'}}/> */}
                <CardContent style={{padding:0, paddingBottom: '1.5em'}}>
                    <Grid container xs={12}>
                        <Grid item xs={12} style={{padding: '0.8rem 1.2rem', overflowX: 'auto'}}>
                            <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                    <TableRow>
                                        <TableCell align='center'   style={{width:'3%', borderBottom: 'none',}}></TableCell>
                                        <TableCell align='center'   style={{width:'7%', borderBottom: 'none', color:'black'}}></TableCell>
                                        <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Invoices / Credits</b></Typography></TableCell>
                                        <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Qty</b></Typography></TableCell>
                                        <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Stock</b></Typography></TableCell>
                                        <TableCell align='center'   style={{width:'20%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                        <TableCell align='center'   style={{width:'20%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Vat</b></Typography></TableCell>
                                        <TableCell align='center'   style={{width:'20%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Gross</b></Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {_.map(data, (i, idx) => 
                                        i &&
                                        <>
                                            { !(title === 'Awaiting Invoice Payment' && idx === 'total') && idx !== 'days' &&
                                                <TableRow class={classes.row} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
                                                    <TableCell align='center' style={{padding: '10px 10px 10px 15px', color:'black'}}>
                                                        <AllIcon icon={icons[idx]} size='medium' style={{color: 'black'}} noMargin/>
                                                        <Box style={{fontWeight: '600', color: '#3d3d3d', textTransform: 'capitalize'}}>
                                                            {idx}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem'}}>
                                                        <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                            <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} primaryColor='#33dd33' />
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                {title === 'Awaiting Invoice Payment' || title === 'Awaiting Invoice' ? 'Invoiced' : 'Ordered'}
                                                            </Typography>
                                                        </Box>
                                                        <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                            <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} swapOpacity secondaryColor={colors.red} />
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                Credited
                                                            </Typography>
                                                        </Box>
                                                        <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                            <AllIcon icon={'equals'} size='medium' style={{display: 'inline-block', paddingRight: '0.5rem', color: '#2d2d2d'}} />
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                Total
                                                            </Typography>
                                                        </Box>
                                                        {/* <Typography variant="h6">CN</Typography> */}
                                                        {/* <Typography variant="h6">TOT</Typography> */}
                                                    </TableCell>
                                                    <ReportCell data={i} field={'qty'}   title={title} hidePound />
                                                    <ReportCell data={i} field={'parts'} title={title} hidePound  forceFixed={3}/>
                                                    <ReportCell data={i} field={'partsStock'} title={title} hidePound  forceFixed={3} inverse/>
                                                    <ReportCell data={i} field={'net'}   title={title}/>
                                                    <ReportCell data={i} field={'vat'}   title={title}/>
                                                    <ReportCell data={i} field={'total'} title={title}/>
                                                </TableRow>
                                            }
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container style={{padding: '0.8rem 1.2rem'}}>
                                <Grid item xs={12}>
                                    <Grid container className={classes.legendWrapper}>
                                        <Grid item xs={12}>
                                            <Box className={classes.legendTitle}>
                                                <Typography variant="h6" align="left" style={{fontWeight: '600'}}>Legend</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid container>
                                            <Grid container item xs={12} >
                                                <Grid item xs={12} lg={title === 'Awaiting Invoice Payment' ? 6 : 12}>
                                                    <Box className={classes.legendItem}>
                                                        <Box>
                                                            <AllIcon icon={icons['day']} noMargin/>
                                                        </Box>
                                                        <Box style={{fontWeight: '600', color: '#3d3d3d'}}>
                                                            Day
                                                        </Box>
                                                        <Box>
                                                            {labels['day']}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} lg={title === 'Awaiting Invoice Payment' ? 6 : 12}>
                                                    <Box className={classes.legendItem}>
                                                        <Box>
                                                            <AllIcon icon={icons['week']} noMargin/>
                                                        </Box>
                                                        <Box style={{fontWeight: '600', color: '#3d3d3d'}}>
                                                            Week
                                                        </Box>
                                                        <Box>
                                                            {labels['week']}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} lg={title === 'Awaiting Invoice Payment' ? 6 : 12}>
                                                    <Box className={classes.legendItem}>
                                                        <Box>
                                                            <AllIcon icon={icons['month']} noMargin/>
                                                        </Box>
                                                        <Box style={{fontWeight: '600', color: '#3d3d3d'}}>
                                                            Month
                                                        </Box>
                                                        <Box>
                                                            {labels['month']}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} lg={title === 'Awaiting Invoice Payment' ? 6 : 12}>
                                                    <Box className={classes.legendItem}>
                                                        <Box>
                                                            <AllIcon icon={icons['year']} noMargin/>
                                                        </Box>
                                                        <Box style={{fontWeight: '600', color: '#3d3d3d'}}>
                                                            Year
                                                        </Box>
                                                        <Box>
                                                            {labels['year']}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

export default withRouter(withStyles(styles)(ReportTile));