import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import API from '../../../API';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar'
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import MeetingInviteForm from '../MeetingInviteForm/MeetingInviteForm';
import { withRouter } from 'react-router';

const initialState = {
    formData: {
        meetingName: '',
        inviteType: null,
        staffContact: null,
        supplier: null,
        supplierContact: null,
        customer: null,
        customerContact: null,
        sendSmsInvite: false,
        smsName: '',
        smsNumber: '',
        smsBody: 'Please click the following link to join your ClenaCare online support session.',
        sendEmailInvite: false,
        emailName: '',
        emailAddress: '',
        emailBody: 'Please click the following link to join your ClenaCare online support session.'
    },
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false
}

class NewMeeting extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() {
    }
    handleSubmit = () => {
        API.post('/meetings', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                }, () => {
                    this.props.history.push('/meetings/search');
                });
            }
            this.props.scrollToTop();
        });
    }
    handleCheckChange = name => event => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: event.target.checked
            }
        });
    };
    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    handleSelectChange = fieldName => (selectedOption, callback = null) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, () => {
            if(callback)
                callback();
        });
    };
    updateFormData = formData => {
        this.setState({
            formData: {
                ...this.state.formData,
                ...formData
            }
        })
    }
    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };
    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }
    render() {
        const { formData, formErrors, snackbarOpen } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Meeting
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Typography variant="h6">
                                    Meeting Details
                                </Typography>
                                <FormControl fullWidth>
                                    <TextField
                                        id="meetingName"
                                        name="meetingName"
                                        label="Meeting Name *"
                                        value={formData.meetingName}
                                        error={formErrors && formErrors['meetingName']}
                                        helperText={formErrors && formErrors['meetingName']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                </FormControl>
                                <MeetingInviteForm
                                  formData={formData}
                                  formErrors={formErrors}
                                  handleSelectChange={this.handleSelectChange}
                                  handleChange={this.handleChange}
                                  handleCheckChange={this.handleCheckChange}
                                  updateFormData={this.updateFormData}
                                  allowNone={true}
                                />
                                <div className='buttonRow'>
                                    <Button onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                        disabled={Object.values(formData).every(x => (x === null || x === ""))}>
                                        Create
                                    </Button>
                                </div>
                                <ConfirmationDialog 
                                    open={this.state.confirmationOpen} 
                                    success={this.handleConfirmationSuccess} 
                                    close={this.handleConfirmationClose} 
                                    title="Create A New Meeting?" 
                                    message="Are you sure you want to create a new meeting?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={snackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message="The new meeting was successfully created"
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(NewMeeting);