import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AddTaskForm from 'Components/Tasks/AddTask/AddTaskForm';

const AddTask = () => (
    <Grid container spacing={3}>
        <Grid item xs={12}>
            <Typography variant="h5">
                Add Task
            </Typography>
        </Grid>
        <Grid container item spacing={3}>
            <Grid item xs={12} lg={6}>
                <PaddedPaper>
                    <AddTaskForm />
                </PaddedPaper>
            </Grid>
        </Grid>
    </Grid>
)

export default AddTask;