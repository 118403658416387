import React, { PureComponent } from 'react';
import { Link }                 from 'react-router-dom';

import { Typography, Paper, TextField, Grid, IconButton } from '@material-ui/core/';

import FALightIcon     from 'Components/Common/Icons/FontAwesome/FALightIcon';
import CopyToClipboard from 'Components/Common/CopyToClipboard/CopyToClipboard';
import AllIcon         from 'Components/Common/Icons/AllIcon';
import Textarea        from 'Components/Common/Inputs/Textarea';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';
import { DialAdornment } from 'Functions/MiscFunctions';
import IconHelper from 'Helpers/IconHelper';

class AddressCard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = this.props.address;
    }

    componentDidUpdate = () => { this.setState(this.props.address); }

    render() {
        const { classes, address, idx } = this.props;
        return address ? (
            <Paper className={ address.address_active ? classes.paperGrey : classes.paperDisabled} style={{height: '100%'}}>
                <Grid container spacing={3}>
                    {!address.address_active &&
                        <Grid item xs={12} >
                            <Typography variant="h6" style={{textAlign: 'center'}}>
                                <AllIcon icon={IconHelper.warning} />
                                Site Is Inactive
                                <AllIcon icon={IconHelper.warning}  style={{marginLeft: 10}}/>
                            </Typography>
                        </Grid>
                    }
                    {!!address.address_unsubscribed &&
                        <Grid item xs={12} >
                            <Typography variant="h6" style={{textAlign: 'center'}}>
                                <AllIcon icon={IconHelper.ban} />
                                Site Is Unsubscribed
                                <AllIcon icon={IconHelper.ban}  style={{marginLeft: 10}}/>
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={6}>
                        <Typography variant="h6">
                            {address.address_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{textAlign:'right'}}>
                        {(address.address_type === 'Site' && !address.linked_accounts ) &&
                            <AllIcon icon='shop-slash' tooltip='Account Not Linked' style={{color: colors.red}} />
                        }
                        {this.props.jumpToMap && <AllIcon noMargin icon={icons.locationPin} onClick={()=>this.props.jumpToMap([parseFloat(address.address_lang), parseFloat(address.address_long)])}/> }
                        <Link to={`/customers/addresses/update/${address.address_id}`}>
                            <IconButton><FALightIcon icon='pencil-alt' noMargin button /></IconButton>
                        </Link>
                        <Link to={`/customers/addresses/view/${address.address_id}`}>
                            <IconButton><FALightIcon icon='search' noMargin button /></IconButton>
                        </Link>
                    </Grid>
                </Grid>
                <br></br>
                {(address.address_type === 'Accounts' && this.props.access.viewCreditLimit ) &&
                    <TextField
                        label="Credit Limit"
                        value={`£${address.credit_limit.ccl_gross}`}
                        margin="normal"
                        fullWidth
                        disabled
                        inputProps={{
                            className: classes.disabledText
                        }}
                        InputLabelProps={{
                            className: classes.disabledText
                        }}
                    />
                }
                <TextField
                    label="Address Line 1"
                    value={address.address_line_one}
                    margin="normal"
                    fullWidth
                    disabled
                    inputProps={{
                        className: classes.disabledText
                    }}
                    InputLabelProps={{
                        className: classes.disabledText
                    }}
                />
                <TextField
                    label="Address Line 2"
                    value={address.address_line_two}
                    margin="normal"
                    fullWidth
                    disabled
                    inputProps={{
                        className: classes.disabledText
                    }}
                    InputLabelProps={{
                        className: classes.disabledText
                    }}
                />
                <TextField
                    label="Address Line 3"
                    value={address.address_line_three}
                    margin="normal"
                    fullWidth
                    disabled
                    inputProps={{
                        className: classes.disabledText
                    }}
                    InputLabelProps={{
                        className: classes.disabledText
                    }}
                />
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            name="town"
                            label="City / Town"
                            value={address.address_town}
                            margin="normal"
                            fullWidth
                            disabled
                            inputProps={{
                                className: classes.disabledText
                            }}
                            InputLabelProps={{
                                className: classes.disabledText
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name="postcode"
                            label="Postcode"
                            value={address.address_postcode}
                            margin="normal"
                            fullWidth
                            disabled
                            inputProps={{
                                className: classes.disabledText
                            }}
                            InputLabelProps={{
                                className: classes.disabledText
                            }}
                        />
                    </Grid>
                </Grid>
                <TextField
                    label="County"
                    value={this.state.county?.county_name}
                    margin="normal"
                    fullWidth
                    disabled
                    inputProps={{
                        className: classes.disabledText
                    }}
                    InputLabelProps={{
                        className: classes.disabledText
                    }}
                />
                <TextField
                    label="Country"
                    value={address.country?.country_name}
                    margin="normal"
                    fullWidth
                    disabled
                    inputProps={{
                        className: classes.disabledText
                    }}
                    InputLabelProps={{
                        className: classes.disabledText
                    }}
                />
                <TextField
                    label="Phone"
                    value={address.address_phone}
                    margin="normal"
                    fullWidth
                    disabled
                    InputProps={{
                        startAdornment: <DialAdornment number={address.address_phone} />
                    }}
                    inputProps={{
                        className: classes.disabledText
                    }}
                    InputLabelProps={{
                        className: classes.disabledText
                    }}
                />
                <Grid container xs={12}>
                    <Grid item xs={address.address_email ? 11 : 12}>
                        <TextField
                            label={`Email`}
                            id={`Email${idx || ''}`}
                            value={address.address_email}
                            margin="normal"
                            fullWidth
                            disabled
                            inputProps={{
                                className: classes.disabledText
                            }}
                            InputLabelProps={{
                                className: classes.disabledText
                            }}
                        />
                    </Grid>
                    {address.address_email && 
                        <Grid item xs={1} style={{padding:'1em'}} >
                            <CopyToClipboard field={`Email${idx || ''}`}  disabled={1} />
                        </Grid>
                    }
                </Grid>
                {address.address_type === 'Registered' &&
                    <Grid container xs={12}>
                        <Grid item xs={11}>
                            <TextField
                                label={`Website${idx || ''}`} 
                                id={`Website${idx || ''}`} 
                                value={address.address_website}
                                margin="normal"
                                fullWidth
                                disabled
                                inputProps={{
                                    className: classes.disabledText
                                }}
                                InputLabelProps={{
                                    className: classes.disabledText
                                }}
                            />
                        </Grid>
                        <Grid item xs={1} style={{padding:'1em'}} >
                            <CopyToClipboard field={`Website${idx || ''}`}  disabled={1} />
                        </Grid>
                    </Grid>
                }
                 {address.address_notes && 
                    <Grid item xs={12}>
                        <Textarea
                            label="Notes"
                            displayOnly
                            value={address.address_notes}
                        />
                    </Grid>
                }
            </Paper>
        ) : (
            <></>
        )
    }
}

export default (AddressCard);
