import React from "react";

import { Grid } from "@material-ui/core";
import InfoBox from 'Components/Common/reports/InfoBox';
import IconHelper from "Helpers/IconHelper";
import _ from "lodash";

class Overview extends React.Component {

    render () {

        const { mailingList } = this.props;

        return (
            <Grid container>
                <Grid item xs={12} container spacing={3}>
                    <InfoBox 
                        title='System Contacts'
                        value={_.filter(mailingList?.c?.c, i => i.contact?.customer)?.length ?? 0}
                        icon={IconHelper.true}
                        rowSize={7}
                    />
                    <InfoBox 
                        title='Manual Contacts'
                        value={_.filter(mailingList?.c?.c, i => !i.contact?.customer)?.length ?? 0}
                        icon={IconHelper.true}
                        rowSize={7}
                    />
                    <InfoBox 
                        title='Warning Contacts'
                        value={mailingList?.c?.w?.length ?? 0}
                        icon={IconHelper.warning}
                        rowSize={7}
                    />
                    <InfoBox 
                        title={<>Invalid<br/>Contacts</>}
                        value={mailingList?.c?.q?.length ?? 0}
                        icon={IconHelper.warning}
                        rowSize={7}
                    />
                    <InfoBox 
                        title='Removed Contacts'
                        value={mailingList?.c?.r?.length ?? 0}
                        icon={IconHelper.false}
                        rowSize={7}
                    />
                    <InfoBox 
                        title='Unsubscribed Contacts'
                        value={mailingList?.c?.o?.length ?? 0}
                        icon={IconHelper.ban}
                        rowSize={7}
                    />
                    <InfoBox 
                        title='Lapsed Contacts'
                        value={mailingList?.c?.l?.length ?? 0}
                        icon={IconHelper.minus}
                        rowSize={7}
                    />
                </Grid>
            </Grid>
        )
    }

}

export default Overview;