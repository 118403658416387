import decode from 'jwt-decode';
import * as authActionTypes from '../Auth/Types/Types';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { loadStaffStatus } from 'Actions/Notifications/Notifications';
import API from 'API';

export function authStaff(token, profilePhoto) {
    return (dispatch) => {
        API.get('/staff/my/access')
        .then(result => {
            const loggedInStaff = decode(token);
            const staffProfilePhotoBase64 = profilePhoto;
            dispatch(deploySnackBar("success", ""))
            dispatch(loadStaffStatus())
            dispatch({
                type: authActionTypes.EXTRACT_TOKEN,
                payload: {
                    email: loggedInStaff.email,
                    firstName: loggedInStaff.firstName,
                    id: loggedInStaff.id,
                    lastName: loggedInStaff.lastName,
                    exp: loggedInStaff.exp,
                    token: token,
                    staffProfilePhoto: staffProfilePhotoBase64,
                    engId: loggedInStaff.engId
                }
            });
            dispatch({
                type: authActionTypes.ACCESS,
                payload: result.data
            })
        });
    }
}

export function refreshAccess() {
    return dispatch => {
        API.get('/staff/my/access')
        .then(result => {
            if (result) {
                dispatch({
                    type: authActionTypes.ACCESS,
                    payload: result.data
                })
            }
        })
    }
}