import { Button, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography, ExpansionPanel } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CiDataTable from "Components/Common/DataTables/CiDataTable";
import { colors } from "Helpers/ColourHelper";
import IconHelper from "Helpers/IconHelper";
import React from "react";
import _ from "lodash";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import LoadingCircle from "Components/Common/LoadingCircle/LoadingCircle";
import AllIcon from "Components/Common/Icons/AllIcon";
import { connect } from "react-redux";
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = (props) => ({
    isLoading: true,
    rows: [],
    writeOffs: _.map(props.writeOffs, i => ({
        ...i,
        foc: i.stockable ? 1 : 0
    })),
})

class RepairChangedDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.calculateRows();
    }

    calculateRows = () => {
        const { partRows, orderPartRows } = this.props;
        const { writeOffs } = this.state;

        let rows = _.map(orderPartRows, row => {
            let _test = _.find(partRows, { 'part': row.part });
            
            let _row = row;

            let wo = _.find(writeOffs, { 'part': row.part });
            if (wo?.foc) {
                _row.totalPrice = parseFloat(_row.totalPrice) - parseFloat(wo.totalPrice);
            }

            let _item =  {
                'part': row.part,
                'orderQty': parseFloat(parseFloat(_row?.quantity) ?? 0),
                'repairQty': _test ? parseFloat(parseFloat(_test?.quantity) ?? 0) : 0,
                'orderPrice': parseFloat(parseFloat(_row?.totalPrice) ?? 0),
                'repairPrice': _test ? parseFloat(parseFloat(_test?.totalPrice) ?? 0) : 0,
                ..._row
            };

            if (_test) {
                if (_item.orderQty !== _item.repairQty) {
                    _item = {..._item, type: 'Change'};
                } else {
                    _item = {..._item,  type: 'Same'};
                }
            } else {
                _item = {..._item,  type: 'Remove'};
            }

            return _item;
        });

        _.forEach(_.filter(partRows, j => !_.find(orderPartRows, {part: j.part})), (row, idx) => {
            let wo = _.find(writeOffs, { 'part': row.part });
            if (wo?.foc) {
                row.totalPrice = parseFloat(row.totalPrice) - parseFloat(wo.totalPrice);
            }
            let _item = {
                'part': row.part,
                'orderQty': 0,
                'repairQty': row.quantity,
                'orderPrice':0,
                'repairPrice': row.totalPrice,
                ...row
            };
            rows.push({..._item, type: 'New'});
        });

        this.setState({rows: rows, isLoading: false});
    }

    toggleFoc = (rowData) => {
        this.setState({
            writeOffs: _.map(this.state.writeOffs, i => {
                if (parseInt(i.part) === parseInt(rowData.part)) {
                    return {
                        ...i,
                        foc: !i.foc
                    }
                } else {
                    return i;
                }
            })
        }, () => {
            this.calculateRows();
        });
    }

    confirmPartRows = () => {
        const { rows, writeOffs } = this.state;
        
        let _rows = _.map(rows, i => {
            let wo = _.find(writeOffs, { 'part': i.part });
            if (wo?.foc) {
                i.quantity = parseFloat(i.repairQty) - parseFloat(wo.quantity);
            }
            return {...i, partDiscount: 'Yes'};
        })

        _.forEach(_.filter(writeOffs, i => i.foc), i => _rows.push({...i, repairQty: i.quantity }));

        this.props.onClose(_.filter(_.map(_rows, row => {
            let unitPrice = row.foc ? 0 : row.unitPrice;
            let discountRate = row.foc ? 0 : row.discountRate;
            let qty = parseFloat( row.repairQty );

            /* Work out decimal */
            let discountDecimal = (discountRate / 100);

            /* Discount amount for 1 unit */
            let unitDiscount = (unitPrice * discountDecimal).toFixed(2);
            let newUnitPrice = (unitPrice - unitDiscount).toFixed(2);

            /* Subtotal = before discount */
            let subTotal = (unitPrice * qty).toFixed(2);
            let discountTotal = (unitDiscount * qty).toFixed(2);

            /* totalPrice = after discount */
            let totalPrice = (newUnitPrice * qty).toFixed(2);

            return {
                ...row,
                quantity: qty,
                unitPrice: parseFloat(unitPrice).toFixed(2),
                discountRate: parseFloat(discountRate).toFixed(2),
                discountDecimal: parseFloat(discountDecimal).toFixed(2),
                unitDiscount: parseFloat(unitDiscount).toFixed(2),
                newUnitPrice: parseFloat(newUnitPrice).toFixed(2),
                subTotal: parseFloat(subTotal).toFixed(2),
                discountTotal: parseFloat(discountTotal).toFixed(2),
                totalPrice: parseFloat(totalPrice).toFixed(2),
            }

        }),i => parseFloat(i.quantity) > 0));
    }

    render() {
        const { rows, isLoading, writeOffs } = this.state;
        const { customerVatPercentage } = this.props;

        if (isLoading) {
            return <LoadingCircle/>
        }

        let totalOrder = _.sumBy(rows, i => parseFloat(i.orderPrice));
        let totalRepair = _.sumBy(rows, i => parseFloat(i.repairPrice));

        let orderVat = parseFloat(totalOrder) * (parseFloat(customerVatPercentage) / 100);
        let repairVat = parseFloat(totalRepair) * (parseFloat(customerVatPercentage) / 100);

        let totalOrderVat = parseFloat(totalOrder) + parseFloat(orderVat);
        let totalRepairVat = parseFloat(totalRepair) + parseFloat(repairVat);


        return (
            <Grid container spacing={1}>
                {writeOffs.length > 0 &&
                    <Grid item xs={12}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                Written Off Parts & Services (£{_.sumBy(writeOffs, i => i.foc ? 0 : parseFloat(i.totalPrice)).toFixed(2)}) 
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">Please Note: parts marked with FOC will be added as a seperate item to the order at a 100% discount</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CiDataTable
                                            config={{
                                                key: 'part'
                                            }}
                                            rows={_.orderBy(writeOffs, ['partNumber'], ['asc'])}
                                            columns={[
                                                {
                                                    field: 'partImage',
                                                    fieldFormat: 'image', 
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Part',
                                                    field: rowData =>
                                                    <>
                                                        <Typography variant="body2">{rowData.partNumber}</Typography>
                                                        <Typography variant="caption">{rowData.partDescription}</Typography>
                                                    </>,
                                                    truncate: true,
                                                },
                                                {
                                                    heading: 'Quantity',
                                                    field: 'quantity',
                                                    fieldFormat: 'decimal:2',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'Price',
                                                    field: 'totalPrice',
                                                    fieldFormat: 'currency',
                                                    sizeToContent: true,
                                                },
                                                {
                                                    heading: 'FOC',
                                                    field: rowData => 
                                                        <AllIcon 
                                                            icon={rowData.foc ? IconHelper.checkBoxChecked : IconHelper.checkBoxUnchecked}
                                                            color={rowData.foc ? colors.red : colors.green}
                                                            onClick={()=>this.toggleFoc(rowData)}
                                                            noMargin
                                                        />
                                                    ,
                                                    sizeToContent: true,
                                                }
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                }
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'part'
                        }}
                        rows={_.orderBy(_.filter(rows, i => i.type !== 'Same'), ['partNumber'], ['asc'])}
                        columns={[
                            {
                                field: 'partImage',
                                fieldFormat: 'image', 
                                sizeToContent: true,
                            },
                            {
                                heading: 'Part',
                                field: rowData =>
                                <>
                                    <Typography variant="body2">{rowData.partNumber}</Typography>
                                    <Typography variant="caption">{rowData.partDescription}</Typography>
                                </>,
                                truncate: true,
                            },
                            {
                                heading: 'type',
                                field: i => {
                                    switch (i.type) {
                                        case 'Same':
                                            return IconHelper.minus;
                                        case 'Change':
                                            return IconHelper.edit;
                                        case 'New':
                                            return IconHelper.plus;
                                        case 'Remove':
                                            return IconHelper.bin;
                                        default:
                                            return '';
                                    }
                                },
                                alignment: 'center',
                                sizeToContent: true,
                                fieldFormat: 'icon',
                                color: i => {
                                    switch (i.type) {                                        
                                        case 'Change':
                                            return colors.orange;
                                        case 'New':
                                            return colors.red;
                                        case 'Remove':
                                            return colors.green;
                                        case 'Same':
                                        default:
                                            return colors.disabled;
                                    }
                                }
                            },
                            {
                                heading: '',
                                field: i => <>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">Quoted</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">Repaired</Typography>
                                        </Grid>
                                    </Grid>
                                </>,
                                sizeToContent: true,
                            },
                            {
                                heading: 'Quantity',
                                field: i => 
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="caption"  style={{color: colors.black}}>
                                                {parseFloat(i.orderQty).toFixed(2)} {parseFloat(i.orderQty) !== parseFloat(i.repairQty) && `(${`${parseFloat(i.orderQty) > parseFloat(i.repairQty) ? '-' : '+'}${parseFloat(Math.abs(parseFloat(i.orderQty) - parseFloat(i.repairQty))).toFixed(2)}`})`}
                                            </Typography>        
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">
                                                {parseFloat(i.repairQty).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>,
                                sizeToContent: true,
                                style: i => {
                                    if (parseFloat(i.orderPrice) !== parseFloat(i.repairPrice)){
                                        return (
                                            parseFloat(i.orderPrice) > parseFloat(i.repairPrice) ?
                                            {color: colors.green} :
                                            {color: colors.red}
                                        )
                                    } else {
                                        return {color: colors.disabled};
                                    }
                                }
                            },
                            {
                                heading: 'Price',
                                field: i => 
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" style={{color: colors.black}}>
                                                £{parseFloat(parseFloat(i.orderPrice)).toFixed(2)} {parseFloat(i.orderPrice) !== parseFloat(i.repairPrice) && `(£${`${parseFloat(i.orderPrice) > parseFloat(i.repairPrice) ? '-' : '+'}${parseFloat(Math.abs(parseFloat(i.orderPrice) - parseFloat(i.repairPrice))).toFixed(2)}`})`}
                                            </Typography>        
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">
                                                £{parseFloat(i.repairPrice).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>,
                                sizeToContent: true,
                                style: i => {
                                    if (parseFloat(i.orderPrice) !== parseFloat(i.repairPrice)){
                                        return (
                                            parseFloat(i.orderPrice) > parseFloat(i.repairPrice) ?
                                            {color: colors.green} :
                                            {color: colors.red}
                                        )
                                    } else {
                                        return {color: colors.disabled};
                                    }
                                }
                            }
                        ]}
                    />
                </Grid>
                {parseFloat(totalOrder) !== parseFloat(totalRepair) &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Typography variant="body1">Subtotal:</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="caption">£{parseFloat(totalOrder).toFixed(2)} ({parseFloat(parseFloat(totalRepair) - parseFloat(totalOrder)) > 0 ? '+' : '-'}£{parseFloat(parseFloat(totalRepair) - parseFloat(totalOrder)).toFixed(2)})</Typography>
                                    <Typography variant="body1"
                                        style={{color: parseFloat(parseFloat(totalRepair) - parseFloat(totalOrder)) > 0 ? colors.red : colors.green}}
                                    >£{parseFloat(totalRepair).toFixed(2)}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body1">VAT ({customerVatPercentage}):</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="caption">£{parseFloat(orderVat).toFixed(2)} ({parseFloat(parseFloat(repairVat) - parseFloat(orderVat)) > 0 ? '+' : '-'}£{parseFloat(parseFloat(repairVat) - parseFloat(orderVat)).toFixed(2)})</Typography>
                                    <Typography variant="body1"
                                        style={{color: parseFloat(parseFloat(repairVat) - parseFloat(orderVat)) > 0 ? colors.red : colors.green}}
                                    >£{parseFloat(repairVat).toFixed(2)}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body1">Total:</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="caption">£{parseFloat(totalOrderVat).toFixed(2)} ({parseFloat(parseFloat(totalRepairVat) - parseFloat(totalOrderVat)) > 0 ? '+' : '-'}£{parseFloat(parseFloat(totalRepairVat) - parseFloat(totalOrderVat)).toFixed(2)})</Typography>
                                    <Typography variant="body1"
                                        style={{color: parseFloat(parseFloat(totalRepairVat) - parseFloat(totalOrderVat)) > 0 ? colors.red : colors.green}}
                                    >£{parseFloat(totalRepairVat).toFixed(2)}</Typography>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
                <Grid item xs={12} className="buttonRow">
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={
                            () => {
                                this.props.deployConfirmation(
                                    'Are you sure you want to apply these changes to the original order?',
                                    'Apply Changes',
                                    this.confirmPartRows
                                )
                            }
                        }
                    >
                        Accept Changes
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback, variant=null) => {
            dispatch(deployConfirmation(content, title, callback, variant=null))
        },
    }
}

export default connect(null, mapDispatchToProps)(RepairChangedDialog);
