import React, { PureComponent } from 'react';
import API                      from 'API';
import _                        from 'lodash';
import { connect }              from 'react-redux';

import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core/';

import DataTable            from 'Components/Common/DataTables/CiDataTable';
import AllIcon              from 'Components/Common/Icons/AllIcon';
import ImageWithError       from 'Components/Common/ImageWithError/ImageWithError';
import LoadingCircle        from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper          from 'Components/Common/Paper/PaddedPaper';
import { FullScreenDialog } from 'Components/Common/Dialogs/FullScreenDialog';
import ViewPart             from 'Components/Parts/ViewPart/ViewPart';

import { colors }                 from 'Helpers/ColourHelper';
import icons                      from 'Helpers/IconHelper';
import { colorNumbers, currency } from 'Helpers/NumberHelper';
import { pdfFromBase64 }          from "Helpers/PDFHelper";
import { getDifference }          from 'Helpers/TimeHelper';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

const initialState = {
    parts: [],
    costPerUnit: 0,
    isLoading: true,
};

const InfoBlock = ({title, icon, value, xs=2, color=null}) => {
    return (
        <Grid item xs={xs}>
            <PaddedPaper  style={{textAlign: 'center', padding: 10 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}><Typography variant="h6" style={{textAlign: 'center'}}>{title}</Typography></Grid>
                    <Grid item xs={12}><AllIcon icon={icon} noMargin size='large' /></Grid>
                    <Grid item xs={12}><Typography variant="h6"  style={{textAlign: 'center', color: color}}>{value}</Typography></Grid>
                </Grid>
            </PaddedPaper>
        </Grid>
    )
} 
class WorksOrderReport extends PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getPartsData();
        this.getSubAssemblyCost();
    }

    getPartsData() {
        API.get( '/reports/worksOrdersReport/parts' , {params: {woId: this.props.worksOrder.wo_id}})
        .then(res => { 
            this.setState({
                parts: _.filter(res.data, r => r.q !== this.props.worksOrder.part.part_id),
                isLoading: false,
            });
        });
    }

    getSubAssemblyCost(){
        API.get(`/parts/${this.props.worksOrder.part.part_id}/costs/byDate`, {params: {date: this.props.worksOrder.wo_complete ?? '9999-12-31'}})
        .then(res => {
            this.setState({
                costPerUnit: res.data.cost_per_unit,
            });
        });
    }

    downloadFeedback = () => {
        API.get('/worksOrders/' + this.props.worksOrder.wo_id + '/feedback/download')
        .then(result => {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        });
    }

    render() {

        const { isLoading, parts, costPerUnit } = this.state;

        if (isLoading) return (<LoadingCircle/>);

        const { worksOrder } = this.props;

        return (
            <Grid container xs={12} style={{width: '100%', alignItems: 'center'}} spacing={2}  >
                <Grid item xs={12}>
                    <Grid container xs={12} spacing={2} style={{alignItems: 'center'}}>
                        <Grid item xs={8}>
                            <PaddedPaper style={{ padding: 16 }}>
                                <Grid container xs={12} spacing={2} style={{alignItems: 'center'}}>
                                    <Grid item>
                                        <ImageWithError
                                            src={worksOrder?.part?.default_image?.file_path}
                                            style={{
                                                maxHeight: 100,
                                                maxWidth: 100,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h4">{worksOrder.part.part_number} - {worksOrder.part.part_description}</Typography>
                                        <Typography>Works Order Build Qty: {parseFloat(worksOrder.wo_qty).toFixed(3)}</Typography>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <InfoBlock title='Stock In'    icon={icons.stock}      value={colorNumbers(parseFloat(worksOrder.wo_status === 'Cancelled' ? 0 : parseInt(worksOrder.wo_qty) ).toFixed(3))}/>
                        <InfoBlock title='Cost'        icon={icons.poundSign}  value={currency(parseInt(worksOrder.wo_qty) * parseFloat(costPerUnit))} color={worksOrder.wo_status !== 'Completed' && colors.orange}/>
                        <InfoBlock 
                            title='Required'    
                            icon={icons.worksOrder} 
                            value={<>
                                <AllIcon icon={icons.parts} />{colorNumbers(parseInt(worksOrder.details.length))}<br/>
                                <AllIcon icon={icons.qty} />{colorNumbers(parseFloat(_.sumBy(parts, 'y')).toFixed(3))}
                            </>}
                        />
                        <InfoBlock 
                            title='Picked'      
                            icon={icons.picking}   
                            value={<>
                                <AllIcon icon={icons.parts} />{colorNumbers(parseInt(_.filter(parts, 'u').length))}<br/>
                                <AllIcon icon={icons.qty} />{colorNumbers(parseFloat(_.sumBy(parts, 'u')).toFixed(3))}
                            </>}
                        />
                        <InfoBlock 
                            title='Additional'      
                            icon={icons.plus}   
                            value={<>
                                <AllIcon icon={icons.parts} />{parseInt(_.filter(parts, 'o').length)}<br/>
                                <AllIcon icon={icons.qty} />{`+${parseFloat(_.sumBy(parts, 'o')).toFixed(3)}`}
                            </>}
                            color={colors.green}
                        />
                        <InfoBlock 
                            title='Returned'      
                            icon={icons.minus}   
                            value={<>
                                <AllIcon icon={icons.parts} />{parseInt(_.filter(parts, 'p').length)}<br/>
                                <AllIcon icon={icons.qty} />{parseFloat( 0 - _.sumBy(parts, 'p')).toFixed(3)}
                            </>}
                            color={colors.orange}
                        />
                        <InfoBlock 
                            title='Written Off'      
                            icon={icons.broken}   
                            value={<>
                                <AllIcon icon={icons.parts} />{parseInt(_.filter(parts, 'a').length)}<br/>
                                <AllIcon icon={icons.qty} />{parseFloat(_.sumBy(parts, 'a')).toFixed(3)}
                            </>}
                            color={colors.red}
                        />
                        <InfoBlock 
                            title='Stock Out'      
                            icon={icons.stock}   
                            value={<>
                                <AllIcon icon={icons.parts} />{colorNumbers(parseInt(_.filter(parts, 's').length))}<br/>
                                <AllIcon icon={icons.qty} />{colorNumbers(parseFloat((0 - (_.sumBy(parts, 's')))).toFixed(3))}
                            </>}
                        />
                        <InfoBlock 
                            title='Required'    
                            icon={icons.poundSign} 
                            value={currency(parseFloat(_.sumBy(parts, r => parseFloat(r.y) * parseFloat(r.t))).toFixed(3))}
                        />
                        <InfoBlock 
                            title='Picked'      
                            icon={icons.poundSign} 
                            value={currency(parseFloat(_.sumBy(parts, r => parseFloat(r.u) * parseFloat(r.t))).toFixed(3))}
                        />
                        <InfoBlock 
                            title='Additional'      
                            icon={icons.poundSign}   
                            value={<>+{currency(parseFloat(_.sumBy(parts, r => parseFloat(r.o) * parseFloat(r.t))).toFixed(3))}</>}
                            color={colors.green}
                        />
                        <InfoBlock 
                            title='Returned'      
                            icon={icons.poundSign}  
                            value={`-£${Math.abs(parseFloat(_.sumBy(parts, r => parseFloat(r.p) * parseFloat(r.t)))).toFixed(3)}`}
                            color={colors.orange}
                        />
                        <InfoBlock 
                            title='Written Off'      
                            icon={icons.poundSign} 
                            value={currency(parseFloat(_.sumBy(parts, r => parseFloat(r.a) * parseFloat(r.t))).toFixed(3))}
                            color={colors.red}
                        />
                        <InfoBlock 
                            title='Stock Out'      
                            icon={icons.poundSign} 
                            value={currency(parseFloat(0 - (_.sumBy(parts, r =>  (parseFloat(r.s)) * parseFloat(r.t)))).toFixed(3))}
                        />
                    </Grid>
                    <Grid container xs={12} style={{paddingTop: '1.5em'}}>
                        <Grid item xs={12}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<AllIcon noMargin icon={icons.arrowDown} color={colors.white}/>}>
                                    <Typography>Parts</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container xs={12} spacing={2} style={{alignItems: 'center'}}>
                                        
                                        <Grid item xs={12}>
                                            <DataTable
                                                config={{
                                                    key: 'q',
                                                    responsiveimportant: true,
                                                    showTotals:   true,
                                                    totalStyle:  {fontWeight: 'bold'},
                                                }}
                                                rows={_.orderBy(parts, 'w')}
                                                columns={[
                                                    {
                                                        field:        'r',
                                                        fieldFormat: 'image',
                                                        sizeToContent: true,
                                                    },
                                                    {
                                                        heading:  'Part/Description',
                                                        field:    i => <>{i.w}<br/>{i.e}</>,
                                                        truncate: 100,
                                                        
                                                    },
                                                    {
                                                        heading:      '',
                                                        field:         r => <>Qty<br/>Cost</> ,
                                                        sizeToContent: true,
                                                        alignment:     'left',
                                                        showTotalLabel: true
                                                    },
                                                    {
                                                        heading:      'Required',
                                                        field:        r => <>{colorNumbers(parseFloat(r.y).toFixed(3))}<br/>{currency(r.y * r.t)}</> ,
                                                        dataRef:      'y',
                                                        sizeToContent: true,
                                                        alignment:     'right',
                                                        showTotal:      true,
                                                        overwriteTotal: <>{colorNumbers(parseFloat(_.sumBy(parts, r => parseFloat(r.y))).toFixed(3))}<br/>{currency(_.sumBy(parts, r => parseFloat(r.y) * parseFloat(r.t)))}</> ,
                                                    },
                                                    {
                                                        heading:      'Picked',
                                                        field:        r => <>{colorNumbers(parseFloat(r.u).toFixed(3))}<br/>{currency(r.u * r.t)}</> ,
                                                        dataRef:       'u',
                                                        sizeToContent: true,
                                                        alignment:     'right',
                                                        showTotal:      true,
                                                        overwriteTotal: <>{colorNumbers(parseFloat(_.sumBy(parts, r => parseFloat(r.u))).toFixed(3))}<br/>{currency(_.sumBy(parts, r => parseFloat(r.u) * parseFloat(r.t)))}</> ,
                                                    },
                                                    {
                                                        heading:      'Additional',
                                                        field:        r => <>{parseFloat(r.o) > 0 && '+'}{parseFloat(r.o).toFixed(3)}<br/>{parseFloat(r.o) > 0 && '+'}{currency(r.o * r.t)}</> ,
                                                        dataRef:       'o',
                                                        sizeToContent: true,
                                                        alignment:     'right',
                                                        showTotal:      true,
                                                        style:         r => ({color: parseFloat(r.o) > 0 && colors.green}),
                                                        overwriteTotal: <span style={{color: colors.green}}>+{colorNumbers(parseFloat(_.sumBy(parts, r => parseFloat(r.o))).toFixed(3), false)}<br/>+{currency(_.sumBy(parts, r => parseFloat(r.o) * parseFloat(r.t)), 3, true, true)}</span> ,
                                                    },
                                                    {
                                                        heading:        'Returned',
                                                        field:          r => <>{parseFloat(0 - r.p).toFixed(3)}<br/>{parseFloat(0 - r.p) < 0 && '-'}£{parseFloat((r.p * r.t)).toFixed(3)}</> ,
                                                        dataRef:        'p',
                                                        sizeToContent:  true,
                                                        alignment:      'right',
                                                        showTotal:      true,
                                                        style:          r => ({color: parseFloat(0 - r.p) < 0 && colors.orange}),
                                                        overwriteTotal: <span style={{color: colors.orange}}>{(parseFloat(0 - _.sumBy(parts, r =>parseFloat(r.p))).toFixed(3))}<br/>-£{parseFloat(_.sumBy(parts, r => parseFloat(r.p) * parseFloat(r.t))).toFixed(3)}</span> ,
                                                    },
                                                    {
                                                        heading:        'Written Off',
                                                        field:          r => <>{parseFloat(r.a).toFixed(3)}<br/>£{parseFloat(r.a * r.t).toFixed(3)}</> ,
                                                        dataRef:        'a',
                                                        sizeToContent:  true,
                                                        alignment:      'right',
                                                        showTotal:      true,
                                                        style:          r => ({color: parseFloat(r.a) > 0 && colors.red}),
                                                        overwriteTotal: <span style={{color: colors.red}}>{(parseFloat(_.sumBy(parts, r => parseFloat(r.a))).toFixed(3))}<br/>£{parseFloat(_.sumBy(parts, r => parseFloat(r.a) * parseFloat(r.t))).toFixed(3)}</span> ,
                                                    },
                                                    {
                                                        heading:      'Stock',
                                                        field:         r => <>{colorNumbers(parseFloat(0 - r.s).toFixed(3))}<br/>{currency(0 - (r.s * r.t))}</> ,
                                                        dataRef:       's',
                                                        sizeToContent: true,
                                                        alignment:     'right',
                                                        showTotal:      true,
                                                        overwriteTotal: <>{colorNumbers(parseFloat(0 - (_.sumBy(parts, r => parseFloat(r.s)))).toFixed(3))}<br/>{currency(0 - (_.sumBy(parts, r => parseFloat(r.s) * parseFloat(r.t))))}</> ,
                                                    },
                                                    {
                                                        heading:      'Cost %',
                                                        field:         r => r.d ? (( r.d / _.sumBy(parts, 'd') ) * 100) : 0,
                                                        dataRef:       'd',
                                                        sizeToContent: true,
                                                        alignment:     'right',
                                                        fieldFormat:   'absolute:percentage',
                                                        showTotal:      true,
                                                    },
                                                    {
                                                        actions: rowData => ([
                                                            {
                                                                name: 'View',
                                                                icon: icons.search,
                                                                onClick: () => {
                                                                    this.props.deployDialog(
                                                                        <FullScreenDialog 
                                                                            open={1} 
                                                                            onClose={this.props.closeDialog} 
                                                                            title="Part Information" 
                                                                            content={<ViewPart id={rowData.q} dialog={true} />}
                                                                        />, 
                                                                        "", 
                                                                        null, 
                                                                        'fs'
                                                                    )
                                                                }
                                                            }, 
                                                        ])
                                                    }
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                        <Grid item xs={12}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<AllIcon noMargin icon={icons.arrowDown} color={colors.white}/>}>
                                    <Typography>Staff/Time Sheet</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <DataTable
                                        config={{
                                            key: 'id',
                                            showTotals:  true,
                                            totalStyle:  {fontWeight: 'bold'},
                                        }}
                                        columns={[
                                            {
                                                heading: 'Staff',
                                                field:   'name',
                                                showTotalLabel: true
                                            },
                                            {
                                                heading:       'Sessions',
                                                field:         'sessions',
                                                dataRef:       'sessions',
                                                alignment:     'center',
                                                sizeToContent: true,
                                                showTotal:      true,
                                            },
                                            {
                                                heading:       'Total Time',
                                                field:         'time',
                                                dataRef:       'time',
                                                sizeToContent: true,
                                                alignment:     'center',
                                                showTotal:      true,
                                                fieldFormat:   'formatSeconds',
                                            },
                                            {
                                                heading:       'Contributed',
                                                field:         'contribution',
                                                dataRef:       'contribution',
                                                sizeToContent: true,
                                                alignment:     'center',
                                                fieldFormat:   'percentage',
                                                showTotal:      true,
                                            }
                                        ]}
                                        rows={_.orderBy(_.map(_.uniqBy(worksOrder.build.time_sheets, 'wobt_staff_id'), i => {
                                            let rows = _.filter(worksOrder.build.time_sheets, {wobt_staff_id: i.wobt_staff_id});
                                            return {
                                                id:           i.wobt_staff_id,
                                                name:         i.wobt_staff_name,
                                                sessions:     rows.length,
                                                time:         _.sumBy(rows, r => parseInt(getDifference(r.wobt_start, r.wobt_end, 'seconds'))),
                                                last:         _.last(rows).wobt_end,
                                                contribution: ( _.sumBy(rows, r => parseInt(getDifference(r.wobt_start, r.wobt_end, 'seconds'))) / _.sumBy(worksOrder.build.time_sheets, r => parseInt(getDifference(r.wobt_start, r.wobt_end, 'seconds')))) * 100
                                            }
                                        }), 'name')}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                        <Grid item xs={12}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<AllIcon noMargin icon={icons.arrowDown} color={colors.white}/>}>
                                    <Typography>Checks</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={2} style={{alignItems: 'stretch', marginTop: '1em'}}>
                                        {_.map(worksOrder.build.stages, stage => 
                                            <Grid item xs={6}>
                                                <PaddedPaper style={{height: '100%'}}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}><Typography><b>Stage {stage.instruction.sais_number}: {stage.instruction.sais_name}</b></Typography></Grid>
                                                        <Grid item xs={12}><Typography><AllIcon icon={icons.true} color={colors.green}/> Approved: {stage.wobs_first_pass_supervisor || '-'}</Typography></Grid>
                                                        <Grid item xs={12}><Typography><AllIcon icon={icons.false} color={colors.red} /> Declined: {stage.declined.length}</Typography></Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>    
                                        )}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                        <Grid item xs={12}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<AllIcon noMargin icon={icons.arrowDown} color={colors.white}/>}>
                                    <Typography>Feedback</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={1} style={{paddingTop: '1.1em'}}>
                                        <Grid item xs={3}><PaddedPaper style={{padding: 8}}><AllIcon icon={icons.comments} /> Feedback: {worksOrder.feedback.length}</PaddedPaper></Grid>
                                        <Grid item xs={3}><PaddedPaper style={{padding: 8}}><AllIcon icon={icons.file} /> Files: {_.sumBy(worksOrder.feedback, r => r.files.length)}</PaddedPaper></Grid>
                                        {worksOrder.feedback.length > 0 && <Grid item xs={3}><Button style={{padding: 7}} variant='contained' color='primary' onClick={this.downloadFeedback}>Download Report</Button></Grid>}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
};

const mapDispatchToProps = dispatch => ({
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                              => dispatch(closeDialog())
})

export default connect(null, mapDispatchToProps)(WorksOrderReport)

