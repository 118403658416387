import React, { Component }                                          from 'react';
import { DEFAULT_PAGINATION_PAGE, DEFAULT_PAGINATION_ROWS_PER_PAGE } from 'Constants';
import _                                                             from 'lodash';
import { connect }                                                   from 'react-redux';
import API                                                           from 'API';

import { Button, FormControl, Grid, TextField, Typography } from '@material-ui/core';

import DataTable          from 'Components/Common/DataTables/CiDataTable';
import FALightIcon        from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AllIcon            from 'Components/Common/Icons/AllIcon';

import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { gridPageChanged, gridRowsPerPageChanged }                      from 'Functions/FormFunctions';
import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';
import { Dial }                                                         from 'Functions/MiscFunctions';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

const initialState = () => ({
    searchCriteria: {
        supplierType: '',
        keywords: ''
    },
    searchResults: {},
    access: {
        viewSupplier: false,
        updateSupplier: false
    },
    supplierTypeList: [],
    isLoading: true,
    gridPagination: {
        page: DEFAULT_PAGINATION_PAGE,
        rowsPerPage: DEFAULT_PAGINATION_ROWS_PER_PAGE
    },
});
class SupplierSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = 'Supplier:Search';
        this.state                  = this.getInitialState(initialState());
        this.gridPageChanged        = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }

    componentDidMount = () => {
        this.checkAccess();
        this.getSupplierTypeList();
        this.getSearchData();
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/view-supplier'), 
            API.get('/staff/my/access/check/update-supplier'),
        ])
        .then(([viewRes, updateRes]) =>  {
            viewRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        viewSupplier: viewRes.data.has_access
                    }
                });
            updateRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        updateSupplier: updateRes.data.has_access
                    }
                });
        });
    }

    getSupplierTypeList = () => {
        API.get('/suppliers/types/all')
        .then((result) => {
            let supplierTypeList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.supp_types_id,
                    label: el.supp_types_name,
                });
            });
            this.setState({
                supplierTypeList: supplierTypeList
            });
        })
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/suppliers/all' , {
                params: {
                    supplierType: this.state.searchCriteria.supplierType,
                    keywords: this.state.searchCriteria.keywords,
                },
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    })
                }
            });
        })
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            searchCriteria: {
                ...this.state.searchCriteria,
                [fieldName]: selectedOption && selectedOption.value,
            },
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    handleSearchKeyword = (e) => {
        this.setState({
            searchCriteria:{
                ...this.state.searchCriteria,
                keywords: e.target.value,
            },
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    reset = () => {
        this.clearPageState();
        this.setState({
            searchCriteria: initialState().searchCriteria,
            //gridPagination: initialState.gridPagination
        }, 
        () => {
            //this.props.history.replace();
            this.getSearchData();
        });
    }

    render() {
        const { access, searchCriteria, gridPagination } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Supplier Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.supplierTypeList} 
                                        label='Supplier Type'
                                        onChange={this.handleSelectChange('supplierType')}
                                        value={searchCriteria?.supplierType}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={searchCriteria?.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.reset}
                                            variant="outlined"
                                            color="default"
                                           
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key:                    'supp_id',
                                pagination:             true,
                                //page: gridPagination.page,
                                //rowsPerPage: gridPagination.rowsPerPage,
                                //gridPageChanged: this.gridPageChanged,
                                //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                persistenceId:          this.persistenceId,
                                responsiveImportance:   true,
                                isLoading:              this.state.isLoading,
                                alternatingRowColours:  true
                            }}
                            columns={[
                                {
                                    heading:       'Company Name',
                                    field:         rowData => rowData.supp_company_name,
                                    important:     true,
                                    main:          true,
                                    sizeToContent: true,
                                    truncate: true
                                },
                                {
                                    heading:       'Trading Name',
                                    field:         rowData => rowData.supp_trading_name ? rowData.supp_trading_name : '-',
                                    important:     true,
                                    main:          true,
                                    sizeToContent: true,
                                    truncate: true
                                },
                                {
                                    heading:   'Type',
                                    field:     rowData => rowData.type.supp_types_name,
                                    important: true,
                                    truncate:  true
                                },
                                {
                                    heading:       'Main Contact Number',
                                    field:         rowData => <Dial number={rowData.supp_telephone ? rowData.supp_telephone : '-'} />,
                                    sizeToContent: true
                                },
                                {
                                    heading:          'Main Email Address',
                                    field: rowData => rowData.supp_email,
                                    sizeToContent:    true
                                },
                                {
                                    heading:     'Parts',
                                    field:       'part_costs_count',
                                    alignment:   'center',
                                    fieldFormat: 'dashOnBlank'
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'parts',  icon: icons.parts,  link: `/suppliers/view/${rowData.supp_id}/parts`, hide:     rowData.part_costs_count == 0, color: rowData.default_part_costs_count > 0 ? colors.green : colors.disabled},
                                            {name: 'Update', icon: 'pencil-alt', link: '/suppliers/update/' + rowData.supp_id,     disabled: !access.updateSupplier},
                                            {name: 'View',   icon: 'search',     link: '/suppliers/view/' + rowData.supp_id,       disabled: !access.viewSupplier}
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(SupplierSearch);