import _ from 'lodash';
import React, { Component } from 'react';

import API from '../../../API';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import { handleSelectChange } from 'Functions/FormFunctions';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DataTable from '../../Common/DataTables/DataTable';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

import { connect } from 'react-redux';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

const initialState = () => ({
    connections:        [],
    formErrors:         [],
    formData:           {
        name: '',
        desc: ''
    },
    confirmationOpen:       0,
    deleteConfirmationOpen: 0,
    deleteId:               0,
    deleteSnackbarOpen:     false,
    access:                 {},
})

class ElectricalConnections extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount = () => {
        this.getElectricalConnections();
        this.getAccess()
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-electrical-connections'),
            API.get('/staff/my/access/check/add-electrical-connections'),
            API.get('/staff/my/access/check/delete-electrical-connections'),
        ])
        .then(([editRes, addRes, delRes]) => {
            this.setState({
                access:{
                    edit:   editRes.data.has_access, 
                    add:    addRes.data.has_access, 
                    delete: delRes.data.has_access, 
                }
            });
        });
    }

    getElectricalConnections = () => {
        API.get(`/parts/electricalConnections`).then(result => {
            this.setState({
                connections: result.data
            })
        }) 
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });        
    } 

    addElectricalConnection = () => {
        API.post(`/parts/electricalConnections`, this.state.formData)
        .then( result => {
            if (result.data.errors){
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                })
            } else {
                this.setState({
                    formData:   initialState().formData,
                    formErrors: initialState().formErrors
                }, ()=> {
                    this.props.deploySnackBar("success", "You have successfully added an electrical connection");
                    this.getElectricalConnections()
                    
                })
            }
            this.toggleConfirmation()
        })
    }

    toggleConfirmation = () => {
        this.setState({
            confirmationOpen: !this.state.confirmationOpen,
        })
    }

    handleShowDelete = (deleteId) => {
        this.setState({deleteId},()=>this.toggleDeleteConfirmation())
    }

    toggleDeleteConfirmation = () => {
        this.setState({
            deleteConfirmationOpen: !this.state.deleteConfirmationOpen,
            deleteId:               this.state.deleteConfirmationOpen ? 0 : this.state.deleteId
        })
    }

    delete = () => {
        API.post(`/parts/electricalConnections/${this.state.deleteId}/delete`,{})
        .then(res => {
            if (!res.error){
                this.props.deploySnackBar("success", "You have successfully deleted this electrical connection");
                
                this.toggleDeleteConfirmation();
                this.getElectricalConnections();
            }
        })
    }

    render(){
        const { formErrors, connections, formData } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Electrical Connections 
                    </Typography>
                </Grid>
                {this.state.access.add &&
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="h6">
                                    Add Electrical Connection
                                </Typography>
                                <form noValidate autoComplete="off">
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Name *"
                                        value={formData.name}
                                        error={formErrors && formErrors['name'] && true}
                                        helperText={formErrors && formErrors['name']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="desc"
                                        name="desc"
                                        label="Description"
                                        value={formData.desc}
                                        error={formErrors && formErrors['desc'] && true}
                                        helperText={formErrors && formErrors['desc']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <div className='buttonRow'>
                                        <Button 
                                            onClick={this.toggleConfirmation}
                                            variant="contained" 
                                            color="primary" 
                                            
                                        >
                                            Add
                                        </Button>
                                    </div>
                                    <ConfirmationDialog 
                                        open={this.state.confirmationOpen} 
                                        success={this.addElectricalConnection} 
                                        close={this.toggleConfirmation} 
                                        title="Add A New Electrical Connection?" 
                                        message="Are you sure you want to add a new electrical connection?"
                                    />
                                    <SnackBar
                                        variant="success"
                                        anchorOriginVertical='bottom'
                                        anchorOriginHorizontal='right'
                                        open={this.state.snackbarOpen}
                                        onClose={this.handleSnackbarClose}
                                        message='You have successfully added a new electrical connection'
                                    />
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12}>
                    <PaddedPaper>
                        <ConfirmationDialog 
                            open={this.state.deleteConfirmationOpen} 
                            success={this.delete} 
                            close={this.toggleDeleteConfirmation} 
                            title="Delete Electrical Connection?" 
                            message="Are you sure you want to delete this electrical connection?"
                        />
                        <SnackBar
                                variant="delete"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.deleteSnackbarOpen}
                                onClose={this.handleSnackbarClose}
                                message='You have deleted electrical connection'
                            />
                        <DataTable  
                            config={{
                                key: 'ec_id',
                                alternatingRowColours: true,
                                isLoading: this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Name',
                                    field: rowData => rowData.ec_name,
                                    sizeToContent: true,
                                },
                                {
                                    heading: 'Description',
                                    field: rowData => rowData.ec_desc,
                                    important: true
                                }, 
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Delete',    icon: 'trash-alt',      onClick: () => { this.handleShowDelete(rowData.ec_id) },        disabled: (rowData.parts.length || !this.state.access.delete) },
                                            {name: 'Update',    icon: 'pen',            link: '/parts/electrical-connections/edit/' + rowData.ec_id,    disabled: !this.state.access.edit },
                                            {name: 'View',      icon: 'search',         link: '/parts/electrical-connections/view/' + rowData.ec_id}
                                        ]
                                    }
                                }
                            ]}
                            rows={connections}
                        />
                    </PaddedPaper>
                </Grid>  
            </Grid>     
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deploySnackBar:     (variant, msg)  => dispatch(deploySnackBar(variant, msg))
})

export default connect(null, mapDispatchToProps)( ElectricalConnections );