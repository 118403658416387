import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import DataTable from '../../Common/DataTables/DataTable';
import FormControl from '@material-ui/core/FormControl';
import AutoCompleteMultiSelect from '../../Common/Selects/AutoCompleteMultiSelect';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        listItem: '',
        nominalCode: []
    },
    templates: [],
    nominalList: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    access: {
        addTemplate: false,
        updateTemplate: false
    },
    isLoading: true
}

class DefaultPartsOrderList extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.checkAccess();
        this.getDefaultList();
        this.getNominalCodes();
    }

    getNominalCodes = () => {
        API.get('/accounts/nominalCodes')
        .then(result => {
            let nominalList = _.map(result.data, (el) => {
                return _.assign({
                    value: el.nominal_id,
                    label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                });
            });
            this.setState({
                nominalList: nominalList
            });
        });
    };

    getDefaultList = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/partsOrdering/defaultList')
            .then(result => {
                if(result.data) {
                    this.setState({
                        ...this.state,
                        templates: result.data,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        })
    };

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/default-parts-order-list:add'), 
            API.get('/staff/my/access/check/update-default-parts-order-list'),
        ])
        .then(([addRes, updateRes]) =>  {
            addRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addTemplate: addRes.data.has_access
                    }
                });
            updateRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        updateTemplate: updateRes.data.has_access
                    }
                });
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        API.post('/partsOrdering/defaultList', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.checkAccess();
                this.getDefaultList();
            }
            this.props.scrollToTop();
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }
       
    render() {
        const { formErrors, access } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Default Parts Order Option
                    </Typography>
                </Grid>
                {access.addTemplate &&
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                {formErrors && formErrors.generic && (
                                    <React.Fragment>
                                        <Typography component={"div"} style={{color: colors.red}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                <form noValidate autoComplete="off">
                                    <TextField
                                        id="listItem"
                                        name="listItem"
                                        label="Name *"
                                        value={this.state.formData.listItem}
                                        error={formErrors && formErrors['listItem'] && true}
                                        helperText={formErrors && formErrors['listItem']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteMultiSelect 
                                            options={this.state.nominalList} 
                                            label='Nominal Codes *'
                                            value={this.state.formData.nominalCode}
                                            onChange={this.handleMultiSelectChange('nominalCode')}
                                            error={formErrors && formErrors['nominalCode'] && true}
                                            errorText={formErrors && formErrors['nominalCode']}
                                        />
                                    </FormControl>
                                    <div className='buttonRow'>
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary"
                                               >
                                            Add
                                        </Button>
                                    </div>
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                }    
                <Grid item xs={12}>    
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'ppo_list_id',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Name',
                                    field: rowData => rowData.ppo_list_description
                                },
                                {
                                    heading: 'Nominal Codes',
                                    field: rowData => (rowData.nominal_codes && rowData.nominal_codes.map(e => e.nominal_code).join(", ")) || '-'
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil-alt', link: '/parts-order/default-list/update/' + rowData.ppo_list_id, disabled: !access.updateTemplate},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.templates}
                        />
                    </PaddedPaper>
                </Grid>   
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add A New Default List Item?" 
                    message="Are you sure you want to add this new default list item?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new default list item'
                />
            </Grid>
        );
    }
}

export default DefaultPartsOrderList;