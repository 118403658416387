import React, { Component } from 'react';
import _                    from 'lodash';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { Card, CardContent, Grid } from '@material-ui/core/';

import ActivityLog from 'Components/Common/ActivityLog/ActivityLog';

const aclIcons = {
    'Created'                     : icons.plus,
    'Picked'                      : icons.orderPicking,
    'Pick Completed'              : icons.orderPicking,
    'Stock Shortage Created'      : icons.orderPicking,
    'Stock Shortage Picked'       : icons.orderPicking,
    'Stock Shortage Completed'    : icons.orderPicking,
    'Build Created'               : icons.worksOrder,
    'Cancelled'                   : icons.cancel,
    'Build Approved'              : icons.worksOrder,
    'Write Off Created'           : icons.orderPicking,
    'Write Off Picked'            : icons.orderPicking,
    'Write Off Completed'         : icons.orderPicking,
    'Stage Completed'             : icons.tick,
    'Completed'                   : icons.tick,
    'Notes Added'                 : icons.notes,
    'First Pass Approved'         : icons.supervisorApproval,
    'First Off Check Approved'    : icons.supervisorApproval,
    'Submitted For Review'        : icons.review,
    'First Pass Declined'         : icons.supervisorDecline,
    'First Off Check Declined'    : icons.supervisorDecline,
    'Important Note Added'        : icons.plus,
    'Important Note Removed'      : icons.minus,
    'Pick Never Required'         : icons.false,
    'Pick Skipped'                : icons.stepForward,
    'Picked In Full'              : icons.orderPicking,
    'Partially Picked'            : icons.orderPicking,
    'Written Off'                 : icons.broken,   
    'Write Off Picked In Full'    : icons.orderPicking,
    'Write Off Complete'          : icons.true,
    'Shortage Stock Missing'      : icons.unknown,
    'Pick Stock Missing'          : icons.unknown,
    'Shortage Partially Picked'   : icons.orderPicking,
    'Shortage Picked In Full'     : icons.orderPicking,
    'Stock Shortage Complete'     : icons.true,
    'Shortage Pick Skipped'       : icons.stepForward,
    'Shortage Never Required'     : icons.false,
    'Additional Stock Picked'     : icons.plus,
    'Stock Returned'              : icons.minus,
    'Added Sticky Note'           : icons.stickyNote,
    'Completed Sticky Note'       : icons.stickyNote,
    'Deleted Sticky Note'         : icons.stickyNote,
    'Feedback Added'              : icons.feedback,
    'Works Order started'         : icons.worksOrder,
    'Shortage - Partially Picked' : icons.orderPicking,
    'Pick - Never Required'       : icons.false,
    'Pick - Stock Missing'        : icons.unknown,
    'Shortage - Never Required'   : icons.false,
    'Shortage - Picked In Full'   : icons.orderPicking
}

class Logs extends Component {
    render(){

        const { logs, part_logs, details, additionals } = this.props.worksOrder;


        let parts  = _.map(
            [...details, ..._.filter(additionals, i => !_.find(details,{wod_part_id: i.part.part_id}))],
            i => ({
                id:   i.part.part_id,
                name: `${i.part.part_number} - ${i.part.part_description}`
            }));

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <ActivityLog 
                                disableAll
                                filter={
                                    [
                                        {label: 'Works Order', filter: i => i.wo } ,
                                        ..._.map(_.uniq([..._.map(logs, i => i.wol_staff), ..._.map(part_logs, i => i.wopl_staff)]), j => ({label: j, filter: i => i.s === j && i.t !== 'Works Order started'} )),
                                        {label: 'Picks',            filter: i => ['Pick Completed', 'Picked', 'Pick Never Required', 'Pick Skipped', 'Picked In Full' , 'Pick Stock Missing', 'Partially Picked' ].includes(i.t) },
                                        {label: 'Shortages',        filter: i => ['Stock Shortage Created', 'Stock Shortage Picked', 'Stock Shortage Completed', 'Shortage Stock Missing', 'Shortage Partially Picked', 'Shortage Picked In Full', 'Stock Shortage Complete', 'Shortage Pick Skipped', 'Shortage Never Required' ].includes(i.t) },
                                        {label: 'Write Offs',       filter: i => ['Write Off Created', 'Write Off Picked', 'Write Off Completed', 'Written Off', 'Write Off Picked In Full', 'Write Off Complete' ].includes(i.t) },
                                        {label: 'Additional Stock', filter: i => ['Additional Stock Picked'].includes(i.t) },
                                        {label: 'Returned Stock',   filter: i => ['Stock Returned'].includes(i.t) },
                                        {label: 'First Off Checks', filter: i => ['First Pass Approved', 'First Off Check Approved', 'First Pass Declined', 'First Off Check Declined'].includes(i.t) },
                                        {label: 'Feedback',         filter: i => ['Feedback Added'].includes(i.t) },
                                    ]
                                }
                                fields={{
                                    type:   't',
                                    date:   'd',
                                    icon:   { color: 'color', icon: 'icon' },
                                    text:   'b',
                                    staff:  's',
                                }}
                                atl={_.filter(_.orderBy([..._.map(logs, i => ({
                                    t:      i.wol_type,
                                    d:      i.wol_datetime,
                                    b:      i.wol_note,
                                    s:      i.wol_staff,
                                    si:     i.wol_staff_id,
                                    color:  colors.blue,
                                    icon:   aclIcons[i.wol_type],
                                    wo:     true,
                                    pid:    0
                                })), ..._.map(part_logs, i => {
                                    let part = _.find(parts, j => j.id === i.wopl_part_id);
                                    return {
                                        t:      i.wopl_type,
                                        d:      i.wopl_datetime,
                                        b:      `${part.name} \n ${i.wopl_note}`,
                                        s:      i.wopl_staff,
                                        si:     i.wol_staff_id,
                                        color:  colors.blue,
                                        icon:   aclIcons[i.wopl_type],
                                        wo:     false,
                                        pid:    i.wopl_part_id,
                                    }
                                })], 'd', 'asc'), i => i.t !== 'Stock Shortage Picked')}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

export default Logs;