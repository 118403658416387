import API from 'API';
import { restockFeeMultiplier } from 'Constants';
import _ from 'lodash';
import { all, create } from 'mathjs';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuidv4 from 'uuid/v4';

import DatePicker from 'Components/Common/DatePickers/DatePicker';
import EmailDialog from 'Components/Common/Dialogs/EmailCustomerReturnsDialog';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AddPartEntryRow from 'Components/CustomerReturns/AddPartEntryRow/AddPartEntryRow';
import PartItems from 'Components/CustomerReturns/PartItems/PartItems';
import SelectCustomerAddressForm from 'Components/Customers/SelectCustomerAddressForm/SelectCustomerAddressForm';
import CustomerAddressSearch from 'Components/Sales/CustomerAddressSearch';
import CustomerDetails from 'Components/Sales/CustomerDetails';
import OrderPartDetailsButton from 'Components/Sales/OrderPartDetailsButton';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { pdfFromBase64 } from "Helpers/PDFHelper";

import { Button, FormControl, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core/';
import UpdateOrderDetailsDialog from './UpdateOrderDetailsDialog';



const math = create(all, {});

const initialState = () => ({
    formErrors:         [],
    customerData:       [],
    customerList:       [],
    partList:           [],
    warrantyVoidLabels: [],
    isLoading:          true,
    updateUUid:         0,
    haveErrors:         false,
    order:              {},
    customerReturn:     {},
    formData: {
        cnId:                           null,
        orderId:                        null,
        outcomeId:                      null,
        discountType:                   null,
        customerId:                     null,
        customer:                       null,
        //customer
        company:                        '',
        addressId:                      '',
        address:                        '',
        paymentTermId:                  '',
        paymentTerms:                   '',
        customerContact:                '',
        customerPhone:                  '',
        customerMobile:                 '',
        customerEmail:                  '',
        // Delivery Customer
        deliveryCustomerAddressType:    '',
        deliveryCustomerAddress:        '',
        deliveryCustomerContact:        '',
        deliveryCustomerPhone:          '',
        deliveryCustomerMobile:         '',
        deliveryCustomerEmail:          '',
        // Part Rows
        partRows:                       [],
        subTotal:                       '',
        vat:                            '',
        overallPrice:                   '',
        customerVatRateId:              '',
        customerVatRate:                '',
        customerVatDecimal:             '',
        customerVatPercentage:          '',
        customerDiscountRate:           '',
        usedSerialNumbers:              [],
        //credit Note
        notes:                          '',
        date:                           null,         
    },
    showRestOfPage:     false,
    lists: {
        customerContactList:            [],
        customerAddressContactList:     [],
        customerAddressList:            [],
    },  
    totals: {
        subTotal:                       0,
        vat:                            0,
        discount:                       0,
        restock:                        0,
        total:                          0,
    },
    edit:                               null,
    disableSubmit:                      true,    
})


class AddCreditNote extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.populateDropdowns();
        if (this.props.match.params.orderId)    this.getOrder();
        if (this.props.match.params.cnId)       this.getCreditNote();
        if (this.props.match.params.custId)     this.getCustomer();
        if (this.props.match.params.siteId)     this.getSite();
        //if (this.props.match.params.crId)       this.getCustomerReturn();
        if (this.props.match.params.outcomeId)  this.getCustomerReturnOutcome();
    }

    getCustomer = () => {
        API.get(`/customers/${this.props.match.params.custId}`)
        .then(result => {
            this.setState({
                formData:{
                    ...this.state.formData,
                    customerId:     result.data.cust_id,
                    discountType:   (result.data?.discount_name?.cdn_id && result.data?.discount_name?.cdn_id != 11) ? 'With Discount' : 'Without Discount'
                }
            }, () => {
                this.checkPageData();
            });
        });
    }

    getSite = () => {
        API.get(`/customers/addresses/${this.props.match.params.siteId}`)
        .then((result) => {
            this.setState({
                formData:{
                    ...this.state.formData,
                    customerId:                     result.data.address_customer_id,
                    discountType:                   (result.data?.customer?.cust_discount_name_id && result.data?.customer?.cust_discount_name_id != 11) ? 'With Discount' : 'Without Discount', 
                    deliveryCustomerAddressType:    result.data?.address_type,
                    deliveryCustomerAddress:        result.data?.address_id,
                }
            }, () => {
                this.checkPageData();
            });
        });
    }

    displayImportantInfo = () => {
        if (this.state.formData.customer.cust_important_info && this.state.old_cust_id != this.state.formData.customer.cust_id) {
            this.setState({old_cust_id: this.state.formData.customer.cust_id}, () => { 
                this.props.deployDialog(
                    <>
                        <Typography component="p">
                            {this.state.formData.customer?.cust_important_info && this.state.formData.customer?.cust_important_info.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                        </Typography>
                        <Button variant='outlined' style={{float: 'right'}} onClick={this.props.closeDialog}>Close</Button>
                    </>
                , 'Important Customer Information', null, 'sm', {backgroundColor : '#fcc6c5', color: '#000'})
            });
        }
    }

    getCreditNote = () => {
        API.get(`/customerReturns/creditNote/${this.props.match.params.cnId}`)
        .then(res => {
            if (res.data){
                const c  = res.data;
                let discountType    = c.cn_type;
                let partRows        = [];
                _.each(c.details, i => {
                    let part = i.part;
                    part.quantity           = parseFloat(i.cnd_quantity);
                    part.unitPrice          = parseFloat(i.cnd_unit_price);
                    part.discountRate       = parseFloat(i.cnd_discount_rate);

                    if (parseFloat(i.od_discount_rate) > 0) discountType = 'With Discount';

                    part.discountTotal      = parseFloat(i.cnd_discount_total);
                    part.newUnitPrice       = parseFloat(i.cnd_new_unit_price);

                    part.subTotal           = parseFloat( part.newUnitPrice * part.quantity );
                    part.restockFeePrice    = parseFloat( i.cnd_restock_total_price );
                    part.totalPrice         = parseFloat( i.cnd_total_price);
                    

                    let sn = [];
                    _.each(i.cnd_serial_numbers, s => {sn.push({id: s.id, serialNumber: s.serialNumber});})

                    let warrantyVoidLabels          = [];
                    let RequireWarrantyVoidLabels   = false;

                    if (i.cnd_warranty_void_labels?.length > 0){
                        RequireWarrantyVoidLabels = true;
                        _.each(i.cnd_warranty_void_labels, i => { warrantyVoidLabels.push(i) });
                    }

                    part.RequireWarrantyVoidLabels  = RequireWarrantyVoidLabels;
                    part.warrantyVoidLabels         = warrantyVoidLabels;
                    part.serialNumbers              = sn;
                    part.restockFee                 = i.cnd_restock_fee;
                    part.cnNote                     = i.cnd_notes

                    part.restock = i.cnd_exclude_stock_change ? 0 : 1;
                    partRows.push(part);                    
                });

                let formData = {...this.state.formData,
                    date:                           c.cn_date,
                    notes:                          c.cn_notes,
                    cnId:                           c.cn_id,
                    orderId:                        c.cn_order_id,    
                    crId:                           c.cn_cr_id,    
                    customerId:                     c.cn_cust_id,
                    discountType, 
                    //customer
                    customerContact:                c.cn_cust_contact_id,
                    customerPhone:                  c.cn_cust_contact_phone,
                    customerMobile:                 c.cn_cust_contact_mobile,
                    customerEmail:                  c.cn_cust_contact_email,
                    // Delivery Customer
                    deliveryCustomerAddressType:    c.cn_delivery_cust_address_type || 'Site',
                    deliveryCustomerAddress:        c.cn_delivery_cust_address_id,
                    deliveryCustomerContact:        c.cn_delivery_cust_contact_id,
                    deliveryCustomerPhone:          c.cn_delivery_cust_contact_phone,
                    deliveryCustomerMobile:         c.cn_delivery_cust_contact_mobile,
                    deliveryCustomerEmail:          c.cn_delivery_cust_contact_email,
                    // Part Rows
                    partRows,
                };

                this.setState({formData, edit: c.full_reference}, ()=>{
                    this.checkPageData();
                    this.calculateTotals();   
                });
            }
        });
        
    }

    
    getCustomerReturnOutcome = () => {
        API.get(`/customerReturns/returns/outcome/${this.props.match.params.outcomeId}`)
        .then(res => {
            if (res.data) {
                let outcome = res.data;

                API.get(`/customerReturns/returns/${res.data.customer_return.cr_id}`)
                .then(res => {
                    if (res.data) {

                        const cr = res.data;
                        const order = cr.order;

                        let discountType = order.order_type;
                        let partRows = [];

                        _.each(outcome.details, detail => {
                            let part = detail.part;

                            part.quantity = parseFloat(detail.crod_qty);
                            part.unitPrice          = parseFloat(detail.crod_per_unit);
                            part.discountRate       = parseFloat(detail.crod_discount);
                            if (parseFloat(cr.cr_disocounted) > 0) discountType = 'With Discount';
                            part.discountTotal      = parseFloat(detail.crod_discount_total);
                            part.newUnitPrice       = parseFloat(detail.crod_new_unit_price);
        
                            part.restockFee         = parseFloat( detail.crod_restock_fee ) > 0;
                            part.restockFeePrice    = parseFloat( detail.crod_restock_fee );
                            part.subTotal           = parseFloat( detail.crod_total );
                            part.totalPrice         = parseFloat( detail.crod_total_with_restock_fee );

                            let serialNumbers             = [];
                            let warrantyVoidLabels        = [];
                            let RequireWarrantyVoidLabels = false;

                            let ssn  = (detail.crod_serial_numbers && detail.crod_serial_numbers !== '[]') ? [detail.crod_serial_numbers] : [];
                            let swvl = (detail.crod_waranty_void_labels && detail.crod_waranty_void_labels !== '[]') ? [detail.crod_waranty_void_labels] : [];

                            if (ssn.length > 0) {
                                _.each(order.order_details, orderDetail =>
                                    _.each(_.filter(orderDetail.serial_numbers, j => ssn.includes(j.psnsl_serial_number)), s => {
                                        serialNumbers.push({
                                            id:             s.psnsl_serial_number_id,
                                            serialNumber:   s.psnsl_serial_number
                                        });
                                    })
                                );
                            }

                            if (swvl.length){
                                _.each(swvl, i => {warrantyVoidLabels.push(i)});
                                RequireWarrantyVoidLabels = true;
                            }

                            part.RequireWarrantyVoidLabels  = RequireWarrantyVoidLabels;
                            part.warrantyVoidLabels         = warrantyVoidLabels;
                            part.serialNumbers              = serialNumbers;

                            part.cnNote             = detail.crod_notes
                            
                            part.restock = 1;
                            part.stockable = (part.part_product === 'No' || part.part_exempt_from_stock) ? false : true;
                            partRows.push(part);
                        });

                        //add non returned service items
                        _.each(_.filter(order.order_details, i => !i.part.stockable), i => {
                            let part = i.part;
                            part.quantity           = parseFloat(i.od_quantity);
                            part.unitPrice          = parseFloat(i.od_unit_price);
                            part.discountRate       = parseFloat(i.od_discount_rate);
                            if (parseFloat(i.od_discount_rate) > 0) discountType = 'With Discount';
                            
                            part.discountTotal      = parseFloat(i.od_discount_total);
                            part.newUnitPrice       = parseFloat(i.od_new_unit_price);
        
                            part.restockFeePrice    = 0;
                            part.subTotal           = parseFloat( i.od_total_price );
                            part.totalPrice         = parseFloat( parseFloat(i.od_total_price) - parseFloat(part.restockFeePrice) );
                            let sn = [];
                            if (part.part_req_serial_number == 'Yes' || part.part_req_serial_number == 'Manual'){
                                _.each(i.serial_numbers, s => {
                                    sn.push({
                                        id:             s.psnsl_serial_number_id,
                                        serialNumber:   s.psnsl_serial_number
                                    });
                                })
                            }
                            let warrantyVoidLabels          = [];
                            let RequireWarrantyVoidLabels   = false;
                            if (i.warranty_void_labels?.length > 0){
                                RequireWarrantyVoidLabels = true;
                                _.each(i.warranty_void_labels, i => { warrantyVoidLabels.push(i.pwvll_code) });
                            }
                            part.RequireWarrantyVoidLabels  = RequireWarrantyVoidLabels;
                            part.warrantyVoidLabels         = warrantyVoidLabels;
                            part.serialNumbers              = sn;
                            part.restockFee                 = false;
                            part.cnNote                     = i.od_notes
                            
                            part.restock = 1;
                            part.stockable = false;
                            partRows.push(part);
                        });

                        let _partsRows = {};

                        //group same parts together (if difffent cost/discount seperate out)
                        _.each(partRows, row => {
                            if (!_partsRows[`${row.part_number}${row.newUnitPrice}`]){
                                _partsRows[`${row.part_number}${row.newUnitPrice}`] = row;
                            } else {
                                _partsRows[`${row.part_number}${row.newUnitPrice}`].quantity = parseFloat(_partsRows[`${row.part_number}${row.newUnitPrice}`].quantity) + parseFloat(row.quantity);
                                _partsRows[`${row.part_number}${row.newUnitPrice}`].subTotal = parseFloat(_partsRows[`${row.part_number}${row.newUnitPrice}`].subTotal) + parseFloat(row.subTotal);
                                if (parseFloat(_partsRows[`${row.part_number}${row.newUnitPrice}`].restockFeePrice) || parseFloat(row.restockFeePrice)){
                                    _partsRows[`${row.part_number}${row.newUnitPrice}`].restockFeePrice = parseFloat(_partsRows[`${row.part_number}${row.newUnitPrice}`].restockFeePrice) + parseFloat(row.restockFeePrice);
                                    _partsRows[`${row.part_number}${row.newUnitPrice}`].restockFee = true;
                                }
                                _partsRows[`${row.part_number}${row.newUnitPrice}`].discountTotal = parseFloat(_partsRows[`${row.part_number}${row.newUnitPrice}`].discountTotal) + parseFloat(row.discountTotal);
                                _partsRows[`${row.part_number}${row.newUnitPrice}`].totalPrice = parseFloat(_partsRows[`${row.part_number}${row.newUnitPrice}`].totalPrice) + parseFloat(row.totalPrice);
                            }
                        })
                        
                        partRows = _.values(_partsRows);

                        let formData = {...this.state.formData,
                            orderId:                        cr.cr_order_id,
                            crId:                           cr.cr_id,
                            outcomeId:                      outcome.cro_id,
                            customerId:                     cr.cr_cust_id,
                            discountType, 
                            //customer
                            customerContact:                cr.cr_contact_id,
                            customerPhone:                  cr.cr_contact_phone,
                            customerMobile:                 cr.cr_contact_mobile,
                            customerEmail:                  cr.cr_contact_email,
                            // Delivery Customer
                            deliveryCustomerAddressType:    cr.cr_collection_address_type || 'Registered',
                            deliveryCustomerAddress:        cr.cr_collection_address_id,
                            deliveryCustomerContact:        cr.cr_collection_address_contact_id,
                            deliveryCustomerPhone:          cr.cr_collection_address_contact_phone,
                            deliveryCustomerMobile:         cr.cr_collection_address_contact_mobile,
                            deliveryCustomerEmail:          cr.cr_collection_address_contact_email,
                            // Part Rows
                            partRows,
                        };
        
                        this.setState({formData, order: order, customerReturn: cr}, this.checkPageData);

                    }
                });
            }
        })
    }
/*
    getCustomerReturn = () => {
        API.get(`/customerReturns/returns/${this.props.match.params.crId}`)
        .then(res => {
            if (res.data) {

                const cr = res.data;
                const order = cr.order;

                let discountType = order.order_type;
                let partRows = [];

                _.each(_.filter(cr.collection.details, i => parseFloat(i.crcd_recieved) > 0 ), col => {
                    let detail = _.find(cr.details, i => parseInt(i.crd_id) === parseInt(col.crcd_crd_id))
                    let part = detail.part;
                    let orderDetail = _.find(order.order_details, i => parseInt(i.od_id) === parseInt(detail.crd_od_id));

                    part.quantity           = parseFloat(col.crcd_recieved);

                    part.unitPrice          = parseFloat(detail.crd_unit_price);
                    part.discountRate       = parseFloat(detail.crd_discount);
                    if (parseFloat(detail.crd_discount) > 0) discountType = 'With Discount';
                    
                    part.discountTotal      = parseFloat(parseFloat(detail.crd_unit_price) * parseFloat(parseFloat(detail.crd_discount) / 100)) * parseFloat(col.crcd_recieved);
                    part.newUnitPrice       = parseFloat(detail.crd_new_unit_price);

                    part.restockFee         = parseFloat( detail.crd_restock_fee ) > 0;
                    part.restockFeePrice    = parseFloat( detail.crd_restock_fee ) > 0 ? parseFloat( parseFloat( parseFloat(parseFloat(detail.crd_new_unit_price) * parseFloat(col.crcd_recieved)) ) * restockFeeMultiplier ) : 0;
                    part.subTotal           = parseFloat( parseFloat(parseFloat(detail.crd_new_unit_price) * parseFloat(col.crcd_recieved)) );
                    part.totalPrice         = parseFloat( parseFloat(part.subTotal) - parseFloat(part.restockFeePrice) );

                    let serialNumbers             = [];
                    let warrantyVoidLabels        = [];
                    let RequireWarrantyVoidLabels = false;
                    _.each(col.received, i => {
                        let ssn  = JSON.parse(i.crcdr_serial_numbers);
                        let swvl = JSON.parse(i.crcdr_warranty_void_labels);

                        if (ssn.length > 0) {
                            _.each(_.filter(orderDetail.serial_numbers, i => ssn.includes(i.psnsl_serial_number)), s => {
                                serialNumbers.push({
                                    id:             s.psnsl_serial_number_id,
                                    serialNumber:   s.psnsl_serial_number
                                });
                            })
                        }

                        if (swvl.length){
                            _.each(swvl, i => {warrantyVoidLabels.push(i)});
                            RequireWarrantyVoidLabels = true;
                        }
                    })

                    part.RequireWarrantyVoidLabels  = RequireWarrantyVoidLabels;
                    part.warrantyVoidLabels         = warrantyVoidLabels;
                    part.serialNumbers              = serialNumbers;
                    part.cnNote                     = detail.crd_notes
                    
                    part.restock = 1;
                    partRows.push(part);
                });         

                _.each(_.filter(order.order_details, i => !i.part.stockable), i => {
                    let part = i.part;
                    part.quantity           = parseFloat(i.od_quantity);
                    part.unitPrice          = parseFloat(i.od_unit_price);
                    part.discountRate       = parseFloat(i.od_discount_rate);
                    if (parseFloat(i.od_discount_rate) > 0) discountType = 'With Discount';
                    
                    part.discountTotal      = parseFloat(i.od_discount_total);
                    part.newUnitPrice       = parseFloat(i.od_new_unit_price);

                    part.restockFeePrice    = 0;
                    part.subTotal           = parseFloat( i.od_total_price );
                    part.totalPrice         = parseFloat( parseFloat(i.od_total_price) - parseFloat(part.restockFeePrice) );
                    let sn = [];
                    if (part.part_req_serial_number == 'Yes' || part.part_req_serial_number == 'Manual'){
                        _.each(i.serial_numbers, s => {
                            sn.push({
                                id:             s.psnsl_serial_number_id,
                                serialNumber:   s.psnsl_serial_number
                            });
                        })
                    }
                    let warrantyVoidLabels          = [];
                    let RequireWarrantyVoidLabels   = false;
                    if (i.warranty_void_labels?.length > 0){
                        RequireWarrantyVoidLabels = true;
                        _.each(i.warranty_void_labels, i => { warrantyVoidLabels.push(i.pwvll_code) });
                    }
                    part.RequireWarrantyVoidLabels  = RequireWarrantyVoidLabels;
                    part.warrantyVoidLabels         = warrantyVoidLabels;
                    part.serialNumbers              = sn;
                    part.restockFee                 = false;
                    part.cnNote                     = i.od_notes
                    
                    part.restock = 1;
                    partRows.push(part);
                });
 
                let formData = {...this.state.formData,
                    orderId:                        cr.cr_order_id,
                    crId:                           cr.cr_id,
                    customerId:                     cr.cr_cust_id,
                    discountType, 
                    //customer
                    customerContact:                cr.cr_contact_id,
                    customerPhone:                  cr.cr_contact_phone,
                    customerMobile:                 cr.cr_contact_mobile,
                    customerEmail:                  cr.cr_contact_email,
                    // Delivery Customer
                    deliveryCustomerAddressType:    cr.cr_collection_address_type || 'Registered',
                    deliveryCustomerAddress:        cr.cr_collection_address_id,
                    deliveryCustomerContact:        cr.cr_collection_address_contact_id,
                    deliveryCustomerPhone:          cr.cr_collection_address_contact_phone,
                    deliveryCustomerMobile:         cr.cr_collection_address_contact_mobile,
                    deliveryCustomerEmail:          cr.cr_collection_address_contact_email,
                    // Part Rows
                    partRows,
                };

                this.setState({formData, order: order, customerReturn: cr}, this.checkPageData);
            }
        })
    }
*/
    getOrder = () => {
        API.get('/sales/orders/' + this.props.match.params.orderId)
        .then(res => {
            if (res.data){
                const cust = res.data;

                let discountType = cust.order_type;
                let partRows = [];
                let showOrderSN = false;
                _.each(cust.order_details, i => {
                    let part = i.part;
                    part.quantity           = parseFloat(i.od_quantity);
                    part.unitPrice          = parseFloat(i.od_unit_price);
                    part.discountRate       = parseFloat(i.od_discount_rate);
                    if (parseFloat(i.od_discount_rate) > 0) discountType = 'With Discount';
                    
                    part.discountTotal      = parseFloat(i.od_discount_total);
                    part.newUnitPrice       = parseFloat(i.od_new_unit_price);

                    part.restockFeePrice    = 0;
                    part.subTotal           = parseFloat( i.od_total_price );
                    part.totalPrice         = parseFloat( parseFloat(i.od_total_price) - parseFloat(part.restockFeePrice) );
                    let sn = [];
                    if (part.part_req_serial_number == 'Yes' || part.part_req_serial_number == 'Manual'){
                        _.each(i.serial_numbers, s => {
                            sn.push({
                                id:             s.psnsl_serial_number_id,
                                serialNumber:   s.psnsl_serial_number
                            });
                        })
                    }
                    let warrantyVoidLabels          = [];
                    let RequireWarrantyVoidLabels   = false;
                    if (i.warranty_void_labels?.length > 0){
                        RequireWarrantyVoidLabels = true;
                        _.each(i.warranty_void_labels, i => { warrantyVoidLabels.push(i.pwvll_code) });
                    }
                    part.RequireWarrantyVoidLabels  = RequireWarrantyVoidLabels;
                    part.warrantyVoidLabels         = warrantyVoidLabels;
                    part.serialNumbers              = sn;
                    part.restockFee                 = false;
                    part.cnNote                     = i.od_notes
                    
                    part.restock = 1;
                    partRows.push(part);
                    if (sn.length || i.warranty_void_labels?.length) showOrderSN = true;
                });           

                let formData = {...this.state.formData,
                    orderId:                        cust.order_id,
                    customerId:                     cust.customers.cust_id,
                    discountType, 
                    //customer
                    customerContact:                cust.order_cust_contact_id,
                    customerPhone:                  cust.order_cust_contact_phone,
                    customerMobile:                 cust.order_cust_contact_mobile,
                    customerEmail:                  cust.order_cust_contact_email,
                    // Delivery Customer
                    deliveryCustomerAddressType:    cust.order_delivery_cust_address_type || 'Registered',
                    deliveryCustomerAddress:        cust.order_delivery_cust_address_id,
                    deliveryCustomerContact:        cust.order_delivery_cust_contact_id,
                    deliveryCustomerPhone:          cust.order_delivery_cust_contact_phone,
                    deliveryCustomerMobile:         cust.order_delivery_cust_contact_mobile,
                    deliveryCustomerEmail:          cust.order_delivery_cust_contact_email,
                    // Part Rows
                    partRows,
                };

                this.setState({formData, order: cust}, ()=> {
                    if (showOrderSN) 
                    { this.showOrderSNPicker() }
                    else
                    { this.checkPageData(); }
                });
            }
        });
    }

    showOrderSNPicker   = () => {
        this.setState({isLoading: true})
        //check if serial numbers are avalible
        //show error if not
        this.props.deployDialog(
            <UpdateOrderDetailsDialog details={this.state.formData.partRows} callback={this.updateDetails}/>,
            'Confirm Serial Numbers/ Warranty Void Labels',
        )
    }

    updateDetails = partRows => {
        this.setState({formData: {...this.state.formData, partRows}, isLoading: false}, () => {this.checkPageData();})
    }

    populateDropdowns = () => {
        Promise.all([
            API.get('/customers'),
            API.get('/parts/all' , {params: {active: true, hasPrice: true, use: 'quotation'}}),
            API.get('/parts/warrantyTerms/warrantyVoidLabels')
        ])
        .then(([custRes, partsRes, wvlRes]) =>  {
            let customerList = [];
            if(custRes.data){
                customerList = _.map(custRes.data, (el) => {
                    el.registeredPostcode = _.result(_.find(el?.addresses, {'address_type': 'Registered'}), 'address_postcode');
                    return _.assign({
                        value: el.cust_id,
                        label: el.account_status.cas_allow_orders === 0 ? el.cust_name + ' | ' + el.cust_type + ' ('+el?.registeredPostcode+') ' + ' (' + el.account_status.cas_name + ')' : el.cust_name + ' | ' + el.cust_type + ' ('+el?.registeredPostcode+')' + ` | CS${el.cust_id}`,
                        disabled: el.account_status.cas_allow_orders === 0 ? true : false
                    });
                });
            }
            this.setState({
                warrantyVoidLabels: _.map(wvlRes.data, i => i.pwvl_code),
                customerData:       custRes.data,
                customerList:       customerList,
                partList:           partsRes.data,
                isLoading:          false
            });
        });
    }

    handleCustomerContactChange = f => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                customerContact: e.value,
                customerPhone:   e.phone,
                customerMobile:  e.mobile,
                customerEmail:   e.email,
            }
        })
    }

    handleSelectChange = field => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: e.value
            }
        },() => {
            this.checkPageData()
        })
    }

    checkPageData = () => {
        this.setState({showRestOfPage: false}, () => {
            if (this.state.formData.customerId && this.state.formData.discountType) {
                API.get(`/customers/${this.state.formData.customerId}`)
                .then(custRes => {
                    if (custRes.data){
                        let customer = custRes.data                  
                        let registeredAddress = customer.registered_address;
                        let customerContactList = _.map(registeredAddress.active_contacts, (el) => {
                            return _.assign({
                                value:  el.contact_id,
                                label:  el.contact_name,
                                mobile: el.contact_mobile,
                                phone:  el.contact_telephone,
                                email:  el.contact_email, 
                            });
                        });
                        this.setState({
                            showRestOfPage: true,
                            lists: {...this.state.lists,customerContactList},
                            formData: {
                                ...this.state.formData, 
                                customer,
                                customerVatRateId:      customer.vat_type.default_vat_value.vat_value_id,
                                customerVatRate:        customer.vat_type.default_vat_value.vat_value_percentage,
                                customerVatDecimal:     customer.vat_type.default_vat_value.vat_rate_decimal,
                                customerVatPercentage:  customer.vat_type.default_vat_value.vat_rate_percent,
                                customerDiscountRate:   customer.discount_name.default_discount_value.cdv_percentage,
                                company:                customer.cust_name,
                                paymentTermId:          customer.payment_term.payment_term_id,
                                paymentTerms:           customer.payment_term.payment_term_name,
                                address:                registeredAddress.address_line_one + "\n\r" +
                                                            (registeredAddress.address_line_two ? registeredAddress.address_line_two + "\n\r":'') +
                                                            (registeredAddress.address_line_three ? registeredAddress.address_line_three + "\n\r":'') +
                                                            registeredAddress.address_town + "\n\r" +
                                                            (registeredAddress.county && registeredAddress.county.county_name ? registeredAddress.county.county_name + "\n\r":'') +
                                                            (registeredAddress.country && registeredAddress.country.country_name ? registeredAddress.country.country_name + "\n\r":'') +
                                                            registeredAddress.address_postcode,
                            }
                        }, () => {
                            this.calculateTotals();
                            this.checkUsedSerialNumbers();
                            this.displayImportantInfo();
                        });
                    }
                });                
            } 
        });
    }

    handleAddressSearchCallBack = e => {
        let discountType    = e.cust_discount_name_id > 0 && e.cust_discount_name_id < 11 ? 'With Discount' : 'Without Discount',
        customerId          = e.cust_id;

        this.setState({
            formData: {
                ...this.state.formData,
                discountType,
                customerId
            }
        }, this.checkPageData)
    }

    handleAddressChange = (f,e) => {
        this.setState({
            formData: {
                ...this.state.formData, 
                deliveryCustomerAddressType:    e.type,
                deliveryCustomerAddress:        e.address, 
                deliveryCustomerContact:        e.contact
            }
        })
    }

    handleAddPart = e => {
        let {partRows} =  this.state.formData;
        partRows.push({...e, key: uuidv4(), restock: 1});
        this.setState({
            formData : {
                ...this.state.formData,
                partRows,
            }
        }, () => {
            this.checkUsedSerialNumbers();
            this.calculateTotals();
        } )

    }

    checkUsedSerialNumbers = () => {
        let {partRows}          =  this.state.formData;
        let usedSerialNumbers   = [];
        _.each(partRows, i => {_.each(i.serialNumbers , j => {usedSerialNumbers.push(j.id)})});
        this.setState({formData:{...this.state.formData, usedSerialNumbers}});
    }

    handleRemovePart = part => {
        let { partRows } = this.state.formData;
        _.pullAt(partRows, part.rowIdx);
        this.setState({formData: {...this.state.formData, partRows}},()=>{this.calculateTotals()});
    }

    handleEditPart = (part) => {
        let partRows                   = this.state.formData.partRows;
        partRows[part.rowNumber - 1]   = part;
        this.setState({formData: {...this.state.formData, partRows}},()=>{this.calculateTotals()});
    }

    calculateTotals = () => {
        let {subTotal, vat, discount, restock, total} = initialState().totals;
        _.each(this.state.formData.partRows, p => {
            subTotal = parseFloat(parseFloat(subTotal) + parseFloat(p.totalPrice)).toFixed(2);;
        })
        vat     = parseFloat(subTotal * this.state.formData.customerVatDecimal).toFixed(2);
        total   = parseFloat(parseFloat(subTotal) + parseFloat(vat)).toFixed(2);
        this.setState({totals: {subTotal, vat, discount, restock, total}, updateUUid: uuidv4()},this.validate);
    }

    handleNoteChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                notes: e.target.value
            }
        });
    }

    handleDateChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                date: moment(e).format('YYYY-MM-DD')
            }
        });
    }

    handleMove = (rowIdx, dir) => {
        let partRows = [...this.state.formData.partRows];
        let rowMoveUp, rowMoveDown;
        switch(dir) {
            case 'up':
                rowMoveUp = partRows[rowIdx];
                rowMoveDown = partRows[rowIdx - 1];
                partRows.splice(rowIdx, 1, rowMoveDown);
                partRows.splice(rowIdx - 1, 1, rowMoveUp);
            break;
            case 'down':
                rowMoveDown = partRows[rowIdx];
                rowMoveUp = partRows[rowIdx + 1];
                partRows.splice(rowIdx, 1, rowMoveUp);
                partRows.splice(rowIdx + 1, 1, rowMoveDown);
            break;
            default:
            break;
        }
        this.setState({
            formData: {
                ...this.state.formData,
                partRows
            }
        });
    }



    submit = (preview = false) => {
        const { orderId, crId, outcomeId, discountType, customerId, addressId, customerContact, deliveryCustomerAddressType, deliveryCustomerAddress, deliveryCustomerContact, 
            partRows, notes, date} = this.state.formData;
        let pr = [];
        _.each(partRows, i => {
            pr.push({
                id:                 i.part_id,
                quantity:           i.quantity,
                restockFee:         i.restockFee,
                restockFeePrice:    i.restockFeePrice,
                serialNumbers:      i.serialNumbers,
                warrantyVoidLabels: i.warrantyVoidLabels,
                subTotal:           math.round( math.round(i.quantity,2) * math.round(i.unitPrice,2), 2).toFixed(2),
                totalPrice:         i.totalPrice,
                unitPrice:          i.unitPrice,
                newUnitPrice:       i.newUnitPrice,
                discountTotal:      i.discountTotal,
                discountRate:       i.discountRate,
                cnNote:             i.cnNote, 
                restock:            i.restock,
            });
        });
        const { subTotal,vat, total } = this.state.totals;
        let formData = { orderId, crId, outcomeId, discountType, customerId, addressId, customerContact, deliveryCustomerAddressType, deliveryCustomerAddress, 
            deliveryCustomerContact, partRows: pr, notes, date, subTotal,vat, total, preview};

        let uri = (this.state.edit) ? `/customerReturns/creditNote/${this.state.formData.cnId}` : `/customerReturns/creditNote`;

        API.post(uri,formData)
        .then( res => {
            if (res.data.success){
                if (preview){
                    pdfFromBase64(res.data.pdf, res.data.pdfName);
                    this.setState({
                        ...this.state,
                        formErrors: initialState().formErrors,
                        haveErrors: false,
                    });
                } else {
                    this.handleEmail(res.data.success);
                }
            }
            if (res.data.errors){
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors),
                    haveErrors: true,
                });
            }
        })
    }

    handleEmail = cnId => {
        const { customerId, customerContact} = this.state.formData;
        let title = 'Credit Note Acknowledgement';
        const callBack  = () => {window.location.replace(`/returns/creditnote/search`);}
        const dialog = <EmailDialog id={cnId} customerId={customerId} customerContactId={customerContact} type={'creditNote'} subType={'Acknowledgement'} callback={callBack} onclose={callBack}/>
        this.props.deployDialog(dialog, `You Are About To Email - ${title}`, 'success')
    }

    validate = () => {
        let disableSubmit = this.state.formData.partRows.length === 0;
        if (this.state.formData.orderId) {
            if (this.state.order){
                disableSubmit = parseFloat(this.state.order.order_total_incl_vat) <  parseFloat(this.state.totals.total);
                this.setState({
                    disableSubmit,
                    formErrors: (disableSubmit) ? {...this.state.formErrors, overOrder: 'Credit note total cannot be more than order total'} : this.state.formErrors,
                    hasErrors: disableSubmit
                });
            } else {
                API.get('/sales/orders/' + this.state.formData.orderId)
                .then(res => {
                    this.setState({order:res.data}, () => {
                        disableSubmit = parseFloat(this.state.order.order_total_incl_vat) <  parseFloat(this.state.totals.total);
                        this.setState({
                            disableSubmit,
                            hasErrors: disableSubmit,
                            formErrors: (disableSubmit) ? {...this.state.formErrors, overOrder: 'Credit note total cannot be more than order total'} : this.state.formErrors
                        });
                    });
                });
            }
            
        } else {
            this.setState({disableSubmit});
        }
    }

    updateRows = partRows => {
        this.setState({formData:{...this.state.formData, partRows}})
    }

    render() {
        const { formErrors, formData, showRestOfPage, isLoading, customerList, lists, haveErrors, edit }    = this.state;
        const {partRows}                                                                                    = formData;

        return (
            <Grid container spacing={3}>
                {isLoading ?  <LoadingCircle /> : 
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{color:colors.red}}>
                                {edit ? `Update Credit Note: ${edit}` : 'Add Credit Note'}
                            </Typography>
                        </Grid>
                        {!!!edit &&
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        {formErrors && formErrors.generic && (
                                            <React.Fragment>
                                                <Typography component={"div"} style={{color: colors.red}}>
                                                    {formErrors.generic}
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                        <form noValidate autoComplete="off">
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect 
                                                    options={[
                                                        {value: 'With Discount', label: 'With Discount'},
                                                        {value: 'Without Discount', label: 'Without Discount'}
                                                    ]} 
                                                    label='Credit Note Type *'
                                                    onChange={this.handleSelectChange('discountType')}
                                                    error={formErrors && formErrors['discountType'] && true}
                                                    errorText={formErrors && formErrors['discountType']}
                                                    value={formData.discountType}
                                                    disabled={formData.discountType && formData.customer && true}
                                                    noClear={true}
                                                />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteSelect 
                                                    options={this.state.customerList} 
                                                    label='Customer *'
                                                    value={formData.customerId}
                                                    onChange={this.handleSelectChange('customerId')}
                                                    error={formErrors && formErrors['customerId'] && true}
                                                    errorText={formErrors && formErrors['customerId']}
                                                    noClear={true}
                                                />
                                            </FormControl>
                                        </form>
                                        { customerList.length ?
                                            <CustomerAddressSearch 
                                                callback={this.handleAddressSearchCallBack} 
                                                customers={customerList}
                                                disabledClosed
                                                disableOnHold
                                            /> :
                                            <Grid item xs={12}> <LoadingCircle /></Grid> 
                                        }
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        }
                        <Grid container spacing={3}>
                            {showRestOfPage &&
                                <Grid item container xs={12} spacing={3} style={{marginTop:'0.5em'}}>
                                    <Grid container item spacing={3}  style={{marginLeft:'0em'}}>
                                        <Grid item xs={12} lg={6}>
                                            <PaddedPaper>
                                                <CustomerDetails
                                                    company={formData.company}
                                                    address={formData.address}
                                                    paymentTerms={formData.paymentTerms}
                                                    customerContact={formData.customerContact}
                                                    customerPhone={formData.customerPhone}
                                                    customerMobile={formData.customerMobile}
                                                    customerEmail={formData.customerEmail}
                                                    customerContactList={lists.customerContactList}
                                                    handleSelectChange={this.handleCustomerContactChange}
                                                    formErrors={this.state.formErrors}
                                                    focus
                                                />
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <PaddedPaper>
                                                <SelectCustomerAddressForm
                                                    customerId= {formData.customerId}
                                                    addrType=   {formData.deliveryCustomerAddressType}
                                                    addrId=     {formData.deliveryCustomerAddress}
                                                    contId=     {formData.deliveryCustomerContact}
                                                    onChange=   {this.handleAddressChange}
                                                    name=       'deliveryCustomer'
                                                    header=     'Delivery Address'
                                                    formErrors= {formErrors}
                                                />
                                            </PaddedPaper>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={3}  style={{marginLeft:'1em', marginRight:'0em'}}>
                                            <PaddedPaper style={{width: '100%'}}>
                                                <AddPartEntryRow 
                                                    partList=                   {this.state.partList}
                                                    success=                    {this.handleAddPart}
                                                    discount=                   {this.state.formData.discountType}
                                                    discountRate=               {this.state.formData.customerDiscountRate}
                                                    usedSerialNumbers=          {formData.usedSerialNumbers}
                                                    warrantyVoidLabels=         {this.state.warrantyVoidLabels}
                                                />
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12} style={{marginLeft:'1em'}}>
                                            <PaddedPaper>
                                                <PartItems 
                                                    uuid=                       {this.state.updateUUid}
                                                    partRows=                   {partRows}
                                                    partList=                   {this.state.partList}
                                                    edit=                       {this.handleEditPart}
                                                    remove=                     {this.handleRemovePart}
                                                    usedSerialNumbers=          {formData.usedSerialNumbers}
                                                    discount=                   {this.state.formData.discountType}
                                                    discountRate=               {this.state.formData.customerDiscountRate}
                                                    move=                       {this.handleMove}
                                                    warrantyVoidLabels=         {this.state.warrantyVoidLabels}
                                                />
                                                {formErrors['partRows'] && 
                                                    <Typography component={"div"} style={{color: colors.red, textAlign:'left', padding:'1em'}}>
                                                        {formErrors['partRows']}
                                                    </Typography>
                                                }
                                                {formData.partRows.length > 1 &&
                                                    <Grid container xs={12} align="right" justify="flex-end" style={{}}>
                                                        <Grid item xs={3} style={{marginTop:'1em'}}>
                                                            <OrderPartDetailsButton   partRows={formData.partRows}  callback={this.updateRows}/>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12} lg={6} style={{marginLeft:'1em'}}>
                                            <PaddedPaper>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="right">
                                                            <Typography variant="subtitle1">
                                                                Sub Total
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell width="50%">
                                                            <Typography style={{color: colors.red}}>{parseFloat(this.state.totals.subTotal).toFixed(2) === '0.00' ? ( this.state.disableSubmit ? '' : '-£0.00' ) : `-£${parseFloat(this.state.totals.subTotal).toFixed(2)}`}</Typography>
                                                            {/*<TextField
                                                                name="subTotal"
                                                                value={this.state.totals.subTotal}
                                                                type="number"
                                                                fullWidth
                                                                style={{color: colors.red}}
                                                                InputProps={{
                                                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                                }}
                                                            />*/}
                                                        </TableCell>
                                                        <TableCell colSpan="2"></TableCell>
                                                    </TableRow>
                                                    {!!this.state.totals.discount &&
                                                        <TableRow>
                                                            <TableCell align="right">
                                                                <Typography variant="subtitle1">
                                                                    Discount
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography style={{color: colors.red}}>{`-£${this.state.totals.discount || ( this.state.disableSubmit ? '' : '0.00' )}`}</Typography>
                                                                {/*<TextField
                                                                    name="vat"
                                                                    value={this.state.totals.discount}
                                                                    type="number"
                                                                    fullWidth
                                                                    disabled
                                                                    
                                                                    InputProps={{
                                                                        startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                                    }}
                                                                />*/}
                                                            </TableCell>
                                                            <TableCell colSpan="2"></TableCell>
                                                        </TableRow>
                                                    }
                                                    <TableRow>
                                                        <TableCell align="right">
                                                            <Typography variant="subtitle1">
                                                                VAT ({this.state.formData.customerVatPercentage})
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography style={{color: colors.red}}>{this.state.totals.vat === '0.00' ? ( this.state.disableSubmit ? '' : '-£0.00' ) : `-£${this.state.totals.vat}`}</Typography>
                                                            {/*<TextField
                                                                name="vat"
                                                                value={this.state.totals.vat}
                                                                type="number"
                                                                fullWidth
                                                                disabled
                                                                error={!this.state.formData.customerVatPercentage && true}
                                                                helperText={!this.state.formData.customerVatPercentage && 'This customer does not have an assigned VAT'}
                                                                InputProps={{
                                                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                                }}
                                                            />*/}
                                                        </TableCell>
                                                        <TableCell colSpan="2"></TableCell>
                                                    </TableRow>
                                                    {!!this.state.totals.restock &&
                                                        <TableRow>
                                                            <TableCell align="right">
                                                                <Typography variant="subtitle1">
                                                                    Restock Fee
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography style={{color: colors.red}}>{this.state.totals.restock === '0.00' ? ( this.state.disableSubmit ? '' : '-£0.00' ) : `-£${this.state.totals.restock}`}</Typography>
                                                                {/*<TextField
                                                                    name="vat"
                                                                    value={this.state.totals.restock}
                                                                    type="number"
                                                                    fullWidth
                                                                    disabled
                                                                    InputProps={{
                                                                        startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                                    }}
                                                                />*/}
                                                            </TableCell>
                                                            <TableCell colSpan="2"></TableCell>
                                                        </TableRow>
                                                    }
                                                    <TableRow style={{backgroundColor:'#f2f2f2'}}>
                                                        <TableCell align="right">
                                                            <Typography variant="subtitle1">
                                                                Total
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography style={{color: colors.red}}>{this.state.totals.total === '0.00' ? ( this.state.disableSubmit ? '' : '-£0.00' ) : `-£${this.state.totals.total}`}</Typography>
                                                            {/*<TextField
                                                                name="overallPrice"
                                                                value={ this.state.totals.total}
                                                                type="number"
                                                                fullWidth
                                                                disabled
                                                                InputProps={{
                                                                    startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                                                }}
                                                            />*/}
                                                        </TableCell>
                                                        <TableCell colSpan="2"></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            {formErrors && formErrors['overOrder'] && 
                                                <Typography style={{color: colors.red}}>{formErrors['overOrder']}</Typography>
                                            }
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid container item spacing={3} style={{marginLeft:'0em'}}>
                                            <Grid item xs={12} lg={6}>
                                                <PaddedPaper>
                                                    <form noValidate autoComplete="off">
                                                        <Grid container spacing={3}>
                                                            <Grid container item spacing={3}>
                                                                <Grid item lg={12}>
                                                                    <Typography variant="h5" gutterBottom>Credit Note  Details</Typography>
                                                                    <DatePicker
                                                                        type="date"
                                                                        id="date"
                                                                        name="date"
                                                                        label={'Credit Note Date *'}
                                                                        value={formData.date}
                                                                        errorText={formErrors && formErrors['date']}
                                                                        onChange={this.handleDateChange}
                                                                        autoOk={true}
                                                                        disableFuture={true}
                                                                    />
                                                                    <Textarea
                                                                        id="notes"
                                                                        name="notes"
                                                                        label={'Credit Note Notes'}
                                                                        value={formData.notes}
                                                                        rows={10}
                                                                        onChange={this.handleNoteChange}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </form>
                                                    {haveErrors && 
                                                        <Typography style={{color: colors.red}}>
                                                            {formErrors['part_locked'] ?
                                                                'Some parts are locked, please try again later.':
                                                                'Some Fields Have Errors'
                                                            }    
                                                        </Typography>
                                                    }
                                                    <div className='buttonRow' style={{justifyContent: 'flex-end', flexDirection: 'row', alignItems:'center'}}>
                                                        <Button onClick={() => {this.submit(true)}}
                                                                variant="outlined"
                                                                color="default"
                                                                disabled={this.state.disableSubmit}
                                                        >
                                                            Preview
                                                        </Button>
                                                        <Button onClick={()=>{
                                                                    const title = edit ? 'Are you sure you want to update this credit note' : 'Are you sure you want to create this credit note';
                                                                    const btn   = edit ? 'Update Credit Note'                               : 'Add Credit Note';
                                                                    this.props.deployConfirmation(title, btn, this.submit, null)
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={this.state.disableSubmit}>
                                                            {edit ? 'Update' : 'Add'}
                                                        </Button>
                                                    </div>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                </Grid>
                            }
                        </Grid>
                    </>
                }
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {return {
    deployConfirmation: (message, title, success, cancel)               => dispatch(deployConfirmation(message, title, success, cancel)),
    deployDialog:       (content, title, variant, size = 'md', style)   => dispatch(deployDialog(content, title, variant, size, style)),
    closeDialog:        ()                                              => {dispatch(closeDialog())}
}}

export default connect(null, mapDispatchToProps)(AddCreditNote);