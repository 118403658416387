/* eslint-disable no-labels */
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import API from '../../../../API';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import _ from 'lodash';

class Prices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            price: {},
            discountList: [],
            open: false
        }
    }

    componentDidMount(){
        // this.getDefaultPrice();
    }

    getDefaultPrice = () => {
        API.get(`/parts/${this.props.partId}/prices/default`)
        .then((priceRes) =>  {
            if(priceRes.data){
                this.setState({
                    price: {
                        priceId: priceRes.data.price_id,
                        price: priceRes.data.price_total,
                        grossMargin: priceRes.data.price_margin,
                        dateFrom: moment(priceRes.data.price_date_from).format("DD-MM-YYYY"),
                        dateTo: 'Current'
                    }
                },
                () => {
                    this.getCustomerDiscount();
                });
            }
        })
    }
    getCustomerDiscount =() => {
        let discountList = [];
        Promise.all([ 
            API.get(`/accounts/customerDiscounts/byName`),
            API.get(`/parts/${this.props.partId}/costs/default`)
        ])
        .then(([discountRes, costRes]) =>  {
            if(discountRes.data){
                discountRes.data.forEach((name) => {
                    let currentValue = _.find(name.discount_values, {'current': true});
                    if(currentValue){
                        let discountTotal   = (this.state.price.price * currentValue.discount_value_decimal).toFixed(2);
                        let price           = (this.state.price.price - discountTotal);
                        let grossMargin 	= costRes.data ? (((price - costRes.data.cost_per_unit) / price) * 100).toFixed(2) : '-';

                        discountList.push({
                            discountId: currentValue.cdv_id,
                            type: name.cdn_name,
                            discount: currentValue.cdv_percentage,
                            price: price.toFixed(2),
                            grossMargin,
                            dateFrom: moment(currentValue.cdv_start_date).format("DD-MM-YYYY"),
                            dateTo: currentValue.cdv_end_date === '0000-00-00' ? 'Current' : moment(currentValue.cdv_end_date).format("DD-MM-YYYY")
                        });
                    }
                });
            }
            this.setState({
                discountList: discountList,
                open: true
            });
        })
    }

    handleOpen = () => {
        if(!this.state.open){
            this.getDefaultPrice();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {

        const { open } = this.state;
    
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                    <Typography>Prices</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open && <>
                        {this.state.price && this.state.price.priceId ?
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <DataTable  
                                        config={{
                                            key: 'priceId',
                                            alternatingRowColours: true,
                                            plainHeader: true
                                        }}
                                        columns={[
                                            {
                                                heading: 'Type',
                                                field: rowData => 'List',
                                                cellProps: rowData => {
                                                    return ({
                                                        style: {
                                                            width: '20%'
                                                        }
                                                    });
                                                },
                                            },
                                            {
                                                heading: 'Discount',
                                                field: rowData => 0,
                                                fieldSuffix: '%',
                                                alignment: 'right'
                                            },
                                            {
                                                heading: 'Price',
                                                field: rowData => rowData.price,
                                                fieldPrefix: '£',
                                                alignment: 'right'
                                            },
                                            {
                                                heading: 'Gross Margin',
                                                field: rowData => rowData.grossMargin,
                                                fieldSuffix: '%',
                                                alignment: 'right'
                                            },
                                            {
                                                heading: 'Date From',
                                                field: rowData => rowData.dateFrom,
                                                alignment: 'right'
                                            },
                                            {
                                                heading: 'Date to',
                                                field: rowData => rowData.dateTo,
                                                alignment: 'right'
                                            }
                                        ]}
                                        rows={[this.state.price]}
                                    />
                                    <div className='buttonRow'>
                                        <Link to={`/prices/view/${this.props.partId}`}>
                                            <Button variant="outlined">View Full Price Details</Button>
                                        </Link>
                                    </div>
                                    {this.props.partDiscount === 'Yes' &&
                                        <React.Fragment>
                                            <Typography variant="h6">Customer Discounts</Typography>
                                            <DataTable  
                                                config={{
                                                    key: 'discountId',
                                                    alternatingRowColours: true,
                                                    plainHeader: true
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Type',
                                                        field: rowData => rowData.type,
                                                        cellProps: rowData => {
                                                            return ({
                                                                style: {
                                                                    width: '20%'
                                                                }
                                                            });
                                                        },
                                                    },
                                                    {
                                                        heading: 'Discount',
                                                        field: rowData => rowData.discount,
                                                        fieldSuffix: '%',
                                                        alignment: 'right'
                                                    },
                                                    {
                                                        heading: 'Price',
                                                        field: rowData => rowData.price,
                                                        fieldPrefix: '£',
                                                        alignment: 'right'
                                                    },
                                                    {
                                                        heading: 'Gross Margin',
                                                        field: rowData => rowData.grossMargin,
                                                        fieldSuffix: '%',
                                                        alignment: 'right'
                                                    },
                                                    {
                                                        heading: 'Date From',
                                                        field: rowData => rowData.dateFrom,
                                                        alignment: 'right'
                                                    },
                                                    {
                                                        heading: 'Date to',
                                                        field: rowData => rowData.dateTo,
                                                        alignment: 'right'
                                                    }
                                                ]}
                                                rows={this.state.discountList}
                                            />
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Grid>
                        :
                            <Typography>There are 0 Prices found.</Typography>
                        }
                    </>}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default Prices;
