import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

const Editable = ({text, type, children, onChange, onEdit, onCancel, onSuccess, btnText, manualSave, disabled, ...props}) => {

    const [isEditing, setEditing] = useState(false);

    const handleKeyDown = (event, type) => {
        const { key } = event;
        const keys = ["Escape", "Tab"];
        const enterKey = "Enter";
        const allKeys = [...keys, enterKey];
        if((type === "textarea" && keys.indexOf(key) > -1) || (type !== "textarea" && allKeys.indexOf(key) > -1)) {
            setEditing(false);
            if(onChange) {
                onChange();
            }
        }
    }

    const handleOnCancel = () => {
        setEditing(false);
        if(onCancel) {
            onCancel();
        }
    }

    const handleOnSuccess = () => {
        setEditing(false);
        if(onSuccess) {
            onSuccess();
        }
    }

    return (
        <section {...props}>
            {(isEditing && (
                <div
                    onBlur={
                        () => { 
                            if(!manualSave) { 
                                setEditing(false); 
                                if(onChange) { 
                                    onChange(); 
                                }
                            }
                        }
                    }
                    onKeyDown={
                        e => { 
                            if(!manualSave) { 
                                handleKeyDown(e, type)
                            }
                        }
                    }
                >
                    {children}
                    {manualSave && (
                        <div 
                            className='buttonRow'
                        >
                            {onCancel && (
                                <Button
                                    variant="outlined"
                                    onClick={handleOnCancel}
                                    size="small"
                                >
                                    Cancel
                                </Button>
                            )}
                            {onSuccess && (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleOnSuccess}
                                    size="small"
                                >
                                    {btnText}
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            )) || (
                <div 
                    onClick={
                        () => { 
                            if(!disabled) { 
                                setEditing(true); 
                                if(onEdit) { 
                                    onEdit(); 
                                    setEditing(false); 
                                }
                            }
                        }
                    }
                >
                    {text}
                </div>
            )}
        </section>
    )
}

export default Editable;