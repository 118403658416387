import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TextField, Button, IconButton } from '@material-ui/core';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import { CLENAWARE_RED, CLENAWARE_GREEN } from 'Constants'

const DynamicTable = ({ withParts, handleChange, data, optionIdx, nestedIdx, type, readOnly }) => {

	const setDataForElement = (structure) => {
		handleChange(structure, 'data', optionIdx, nestedIdx, true)
		setTableStructure(structure);
	};

	const initialTableStructure = { columns: [], rows: [] };

  	const [tableStructure, setTableStructure] = useState(initialTableStructure);

	useEffect(() => {
		if(data){
			setDataForElement(data);

		} else {
			if (withParts) {
				setDataForElement({
					...tableStructure,
					columns: [
						...tableStructure.columns, 
						{
							type:'part', 
							name: 'Part'
						}
					],
				});
			} else {
				setDataForElement(initialTableStructure);
			}
		}
	}, []);

	const handleAddColumn = () => {
		setDataForElement({
			...tableStructure,
			columns: [
				...tableStructure.columns, 
				{
					type:'custom', 
					name: ''
				}
			],
		});
	};

	const handleAddRow = () => {
		setDataForElement({
			...tableStructure,
			rows: [
				...tableStructure.rows, 
				{
					type:'custom', 
					name: ''
				}
			],
		});
	};
	

	const handleRemoveColumnOrRow = (index, type) => {
		const tbl = {...tableStructure};
		tbl[type].splice(index, 1);
		setDataForElement(tbl);
	};

	const handleHeaderChange = (e, type, index) => {

		const { value } = e.target;

		const tbl = {...tableStructure};
		tbl[type][index].name = value;

		setDataForElement(tbl);
	};

	const renderColumnHeaders = () => {
		return(
			<>
			{tableStructure.rows.length > 0 && (
				<TableCell style={{border: '1px solid rgba(224, 224, 224, 1)'}}>&nbsp;</TableCell>
			)}
			{tableStructure.columns.map((column, colIndex) => (
				<>
				{column?.type === 'part' ? (
					<TableCell key={colIndex} style={{border: '1px solid rgba(224, 224, 224, 1)'}}>Part</TableCell>
				)
				:
				(
					<TableCell key={colIndex} style={{border: '1px solid rgba(224, 224, 224, 1)'}}>
						<TextField
							value={column?.name}
							onChange={(e) => handleHeaderChange(e, 'columns', colIndex)}
							disabled={readOnly}
						/>
						<IconButton onClick={() => handleRemoveColumnOrRow(colIndex, 'columns')} disabled={readOnly}>
							<FALightIcon button noMargin icon='trash-alt' size={15} fixedHeight color={CLENAWARE_RED} />
						</IconButton>
					</TableCell>
				)}
				</>
			))}
			</>
		)
	};

	const renderRowHeaders = () => {
		return(
			<>
			
			{tableStructure.rows.map((row, rowIndex) => (
				<TableRow key={rowIndex}>
					<TableCell style={{border: '1px solid rgba(224, 224, 224, 1)'}}>
						<TextField
							value={row?.name}
							onChange={(e) => handleHeaderChange(e, 'rows', rowIndex)}
							disabled={readOnly}
						/>
						<IconButton onClick={() => handleRemoveColumnOrRow(rowIndex, 'rows')}  disabled={readOnly}>
							<FALightIcon button noMargin icon='trash-alt' size={15} fixedHeight color={CLENAWARE_RED} />
						</IconButton>
					</TableCell>
					{tableStructure.columns.map((column, columnIndex) => (
						<TableCell key={columnIndex} style={{border: '1px solid rgba(224, 224, 224, 1)'}}>
							&nbsp;
						</TableCell>
					))}
				</TableRow>
			))}
			</>
		)
	};

	return (
		<>
			<TableContainer>
				<Table size="small">
					<TableHead style={{backgroundColor: '#fafafa'}}>
						<TableRow>
							{renderColumnHeaders()}
						</TableRow>

					</TableHead>
					<TableBody>
						{renderRowHeaders()}
					</TableBody>
				</Table>
			</TableContainer>
			{!readOnly && (
				<>
				<Button
					onClick={handleAddColumn}
					variant="text"
				>
					<FALightIcon icon='plus' button size={15} color={CLENAWARE_GREEN} />
					Add Column
				</Button>
				{type == "static" && (
					<Button
						onClick={handleAddRow}
						variant="text"
					>
						<FALightIcon icon='plus' button size={15} color={CLENAWARE_GREEN} />
						Add Row
					</Button>
				)}
				</>
			)}
		</>
	);
};

export default DynamicTable;
