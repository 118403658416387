import React from 'react';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import {TextField, Grid, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import API from 'API';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import { connect } from 'react-redux';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { makeStyles } from '@material-ui/core/styles';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';
import { gridPageChanged, gridRowsPerPageChanged } from 'Functions/FormFunctions'

const initialState = () => ({
    formData: {
        categoryName: '',
        categoryImage: ''
    },
    rows: [],
    formErrors:[],
    confirmationOpen: false,
    isLoading: true,
    displayOrderDialogOpen: false,
    categories: [],
});
const useStyles = makeStyles(theme => ({
    sortableList: {
        border: '1px solid #E0E0E0',
        borderRadius: '3px',
        width: '70%',
        listStyle: 'none',
        padding: 0
    },
    sortableItem: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '60px',
        padding: '0 20px',
        borderBottom: '1px solid #EFEFEF',
        backgroundColor: '#FFF',
        boxSizing: 'border-box',
        userSelect: 'none'
    }
}));

const SortableItem = SortableElement(({value}) => {
    const classes = useStyles();
    return (<li className={classes.sortableItem} style={{zIndex: 9999}}>{value}</li>)
});

const SortableList = SortableContainer(({items}) => {
    const classes = useStyles();
    return (
        <ul className={classes.sortableList}>
        {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
        </ul>
    );
});

class EcommerceProductCategories extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = 'Ecommerce:ProductCategories';
        this.state                  = this.getInitialState(initialState());
        this.gridPageChanged        = gridPageChanged.bind(this);
        this.gridRowsPerPageChanged = gridRowsPerPageChanged.bind(this);
    }

    componentDidMount = () => {
        !this.hasPageState() && this.getCategories();
    }

    submit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            newFormData.append(key, this.state.formData[key])  
        });
        API.post('/e-commerce/productCategories', newFormData)
        .then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState()
                });
                this.props.deploySnackBar("success", "You have successfully added a new product category")
                this.getCategories();
            }
            this.props.scrollToTop();
        });
    }
    
    getCategories = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/e-commerce/productCategories')
            .then((result) => {
                let categories = []
                result.data.forEach(cat  => {
                    categories.push(cat.ecom_cat_name);
                });

                this.setState({
                    ...this.state,
                    rows: result.data,
                    isLoading: false,
                    categories
                },()=>{this.savePageState();});
            })
        })
    }
    
    handleChange = (e) => {
        this.setState({
            formData: {
            ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    
    handleConfirmationOpen = () => {
        this.setState({ confirmationOpen: true });
    };

    handleConfirmationClose = () => {
        this.setState({ confirmationOpen: false });
    };

    handleConfirmationSuccess = () => {
        this.setState({ confirmationOpen: false });
        this.submit();
    }

    clearDocument = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: initialState().formData.name
            }
        });
    };

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    handleDialogClose = () => {this.setState({displayOrderDialogOpen: false});};
    handleDialogOpen = () => {
        this.setState({
            displayOrderDialogOpen: true
        });
    };
    handleDialogSuccess = () => {
        this.setState({ displayOrderDialogOpen: false });
        this.dialogSubmit();
    }
    dialogSubmit = () => {
        API.post('/e-commerce/productCategories/displayOrder', {'categories': this.state.categories})
        .then((result) => {
            this.setState({
                ...initialState()
            });
            this.props.deploySnackBar("success", "You have successfully saved the new display order")
            this.getCategories();
            this.props.scrollToTop();
        });
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState({
            categories: arrayMove(this.state.categories, oldIndex, newIndex),
        });
    };

    render() {
        const { formErrors, formData, displayOrderDialogOpen } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Product Categories
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Add Product Category
                            </Typography>
                            <form noValidate autoComplete="off">
                                <TextField
                                    id="categoryName"
                                    name="categoryName"
                                    label="Category Name *"
                                    value={formData.categoryName}
                                    error={formErrors && formErrors['categoryName'] && true}
                                    helperText={formErrors && formErrors['categoryName']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <DragFileInput
                                    id="categoryImage"
                                    name="categoryImage"
                                    label="Image File (.jpg, .jpeg, .tiff, .tif, .gif, .png) *"
                                    file={formData.categoryImage}
                                    errorText={formErrors && formErrors['categoryImage']}
                                    onChange={this.handleFileChange}
                                    cancelOnClick={this.clearDocument('categoryImage')}
                                    emptyText='No image selected'
                                />
                                <div className='buttonRow'>
                                    <Button 
                                        onClick={this.handleConfirmationOpen}
                                        variant="contained" 
                                        color="primary" 
                                       
                                        disabled={!formData.categoryName}
                                    >
                                        Add
                                    </Button>
                                </div>
                                <ConfirmationDialog 
                                    open={this.state.confirmationOpen} 
                                    success={this.handleConfirmationSuccess} 
                                    close={this.handleConfirmationClose} 
                                    title="Add A New Product Category?" 
                                    message="Are you sure you want to add a new product category?"
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <div className='buttonRow'>
                            <Button 
                                onClick={this.handleDialogOpen}
                                variant="contained" 
                                color="primary"
                            >
                                Change Display Order
                            </Button>
                        </div>
                        <DataTable  
                            config={{
                                key:                    'ecom_cat_id',
                                pagination:             true,
                                isLoading:              this.state.isLoading,
                                persistenceId:          this.persistenceId,
                                alternatingRowColours:  true,
                                responsiveImportance:   true
                            }}
                            columns={[
                                {
                                    heading: 'Category Name',
                                    field: rowData => rowData.ecom_cat_name,
                                    truncate: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil-alt', link: { pathname: '/ecommerce/product-categories/update/' + rowData.ecom_cat_id }},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.rows}
                        />
                    </PaddedPaper>
                </Grid>
                <Dialog 
                    open={displayOrderDialogOpen} 
                    onClose={this.handleDialogClose} 
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                >
                    <DialogTitle>
                        <Typography>Change Display Order (From Left to Right)</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <SortableList 
                            items={this.state.categories} 
                            onSortEnd={this.onSortEnd}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} variant="outlined" color="default">Close</Button>
                        <Button onClick={this.handleDialogSuccess} color="primary" autoFocus variant="contained">Save</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state)),
    deploySnackBar:     (variant, msg)  => dispatch(deploySnackBar(variant, msg))
})

export default connect(mapStateToProps, mapDispatchToProps)(EcommerceProductCategories);