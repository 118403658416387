import React, { PureComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import DataTable from 'Components/Common/DataTables/CiDataTable';
class QuotePartItems extends PureComponent {
    trimPartDescription = partDescription => {
        if(partDescription) {
            if(partDescription.length > 50){
                partDescription = partDescription.substring(0, 50) + '...';
            }
        } else {
            partDescription = '';
        }
        return partDescription;
    }
    render() {
        const { partRows, quotationType, handleViewPart, handleUpdatePart, handleRemovePart, handleMove } = this.props; 
        const columns = (quotationType === 'With Discount' ?
            [
                {
                    field: 'partImage',
                    sizeToContent: true,
                    fieldFormat: 'image'
                },
                {
                    heading: 'Part',
                    field: rowData =>   <React.Fragment>
                                           {rowData.partNumber} - {rowData.partDescription}
                                           <br/>
                                           <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{rowData.warrantyName && rowData.warrantyDuration !== null ? rowData.warrantyName : ''}</Typography>
                                           <Typography component="body2" style={{fontSize: '0.7rem', whiteSpace:'pre-wrap'}}>
                                                {rowData.notes && rowData.notes.split('\n').map((item, key) => {
                                                    return <span key={key}>{item}<br/></span>
                                                })}
                                            </Typography>
                                        </React.Fragment>,
                    sizeToContent: true,
                    truncate: true
                },
                {
                    heading: 'Quantity',
                    field: rowData => parseFloat(rowData.quantity),
                    alignment: 'right'
                },
                {
                    heading: 'Unit Price',
                    field: rowData => '£' + parseFloat(rowData.unitPrice).toFixed(2),
                    alignment: 'right'
                },
                {
                    heading: 'Discount Rate',
                    field: rowData => parseFloat(rowData.discountRate).toFixed(2) + '%',
                    alignment: 'right'
                },
                {
                    heading: 'New Unit Price',
                    field: rowData => '£' + parseFloat(rowData.newUnitPrice).toFixed(2),
                    alignment: 'right'
                },
                {
                    heading: 'Discount Total',
                    field: rowData => '£' + parseFloat(rowData.discountTotal).toFixed(2),
                    alignment: 'right'
                },
                {
                    heading: 'Total Price',
                    field: rowData => '£' + parseFloat(rowData.totalPrice).toFixed(2),
                    alignment: 'right'
                },
                {
                    actions: rowData => {
                        return [
                            {name: 'Update', icon: 'pencil-alt', onClick: () => handleUpdatePart(rowData)},
                            {name: 'View', icon: 'search', onClick: () => handleViewPart(rowData)},
                            {name: 'Delete', icon: 'trash-alt', onClick: () => handleRemovePart(rowData.rowIdx)},
                            {name: 'Move Up', icon: 'chevron-up', onClick: () => handleMove(rowData.rowIdx, 'up'), disabled: rowData.rowNumber === 1},
                            {name: 'Move Down', icon: 'chevron-down', onClick: () => handleMove(rowData.rowIdx, 'down'), disabled: rowData.rowNumber === partRows.length},
                        ]
                    }
                }
            ] 
                :
            [
                {
                    field: 'partImage',
                    sizeToContent: true,
                    fieldFormat: 'image'
                },
                {
                    heading: 'Part',
                    field: rowData =>   <React.Fragment>
                                           {rowData.partNumber} - {rowData.partDescription}
                                           <br/>
                                           <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>{rowData.warrantyName && rowData.warrantyDuration !== null ? rowData.warrantyName : ''}</Typography>
                                           <Typography component="body2" style={{fontSize: '0.7rem', whiteSpace:'pre-wrap'}}>
                                                {rowData.notes && rowData.notes.split('\n').map((item, key) => {
                                                    return <span key={key}>{item}<br/></span>
                                                })}
                                            </Typography>
                                        </React.Fragment>,
                    sizeToContent: true,
                    truncate: true
                },
                {
                    heading: 'Quantity',
                    field: rowData => parseFloat(rowData.quantity).toFixed(3),
                    alignment: 'right'
                },
                {
                    heading: 'Unit Price',
                    field: rowData => '£' + parseFloat(rowData.unitPrice).toFixed(2),
                    alignment: 'right'
                },
                {
                    heading: 'Total Price',
                    field: rowData => '£' + parseFloat(rowData.totalPrice).toFixed(2),
                    alignment: 'right'
                },
                {
                    actions: rowData => {
                        return [
                            {name: 'Update', icon: 'pencil-alt', onClick: () => handleUpdatePart(rowData)},
                            {name: 'View', icon: 'search', onClick: () => handleViewPart(rowData)},
                            {name: 'Delete', icon: 'trash-alt', onClick: () => handleRemovePart(rowData.rowIdx)},
                            {name: 'Move Up', icon: 'chevron-up', onClick: () => handleMove(rowData.rowIdx, 'up'), disabled: rowData.rowNumber === 1},
                            {name: 'Move Down', icon: 'chevron-down', onClick: () => handleMove(rowData.rowIdx, 'down'), disabled: rowData.rowNumber === partRows.length},
                        ]
                    }
                }
            ]);        
        return(
            <DataTable  
                config={{
                    key: 'part',
                }}
                columns={columns}
                rows={partRows}
            />
        );
    }
}

export default QuotePartItems;
