import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { Component } from 'react';
import { colors } from 'Helpers/ColourHelper';
import FALightIcon from '../Icons/FontAwesome/FALightIcon';

const materialTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#0282C6'
        },
        secondary: {
            main: '#D61F26'
        }
    },
    overrides: {
        MuiButton: {
            root: {
                '&:nth-child(2)': {
                    color: "white",
                    backgroundColor: 'rgba(2,130,198,1)',
                    '&:hover': {
                        color: "white",
                        backgroundColor: 'rgba(2,130,198,0.8)',
                    }
                }
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
                },
                '&:after': {
                    borderBottom: '2px solid #0282C6'
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: '2px solid #0282C6'
                }
            }
        }
    }
});

const initialState = {
    date: null,
    time: null
}

export default class _DateTimePicker extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() {
        if (this.props.value) {
            this.setState({
                date: new Date(this.props.value),
                time: new Date(this.props.value)
            });
        }
    
    }
    componentDidUpdate(prevProps) {
        if (!this.props.value && prevProps.value) {
            //reset from is value has been reset
            this.setState(initialState);
        }
        else if(this.props.value && this.props.value !== prevProps.value){
            this.setState({
                date: new Date(this.props.value),
                time: new Date(this.props.value)
            });
        }
    }
    dateChange = date => {
        this.setState({
            date: date
        }, () => {
            let fullDateTime = `${this.state.date && moment(this.state.date).format("YYYY-MM-DD")} ${(this.state.time && moment(this.state.time).format("H:mm")) || '00:00'}`;
            this.props.onChange(moment(fullDateTime));
        });
    }
    // dateChange = date => {
    //     this.setState({ date: date },this.onChange);
    // }
    timeChange = time => {
        this.setState({
            time: time
        }, () => {
            let fullDateTime = `${(this.state.date && this.state.date != 'Invalid Date') ? `${moment(this.state.date).format('YYYY-MM-DD')} `:`${moment().format('YYYY-MM-DD')} `}${(this.state.time && moment(this.state.time).format("H:mm")) || '00:00'}`;
            this.props.onChange(moment(fullDateTime));
        });
    }
    // timeChange = time => {
    //     this.setState({ time: time },this.onChange);
    // }

    // onChange(){
    //     if (this.props.onChange && (
    //         this.state.time != 'Invalid Date' &&
    //         this.state.date != 'Invalid Date'
    //     )) {
    //         this.props.onChange(moment(this.state.time).set({
    //             year: moment(this.state.date).year(),
    //             month: moment(this.state.date).month(),
    //             date: moment(this.state.date).date(),
    //             minute: moment(this.state.time).minute(),
    //             hour: moment(this.state.time).hour(),
    //         }).format());
    //     }
    // }
    render() {
        return (
            <React.Fragment>
                <FormControl fullWidth margin="normal" error={!!this.props.errorText}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <MuiThemeProvider theme={materialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        fullWidth
                                        id={this.props.id}
                                        name={`${this.props.name}Date`}
                                        label={`${this.props.label} Date${this.props.required ? " *" : ""}`}
                                        value={this.state.date}
                                        onChange={this.dateChange} 
                                        disableFuture={this.props.disableFuture}
                                        format={"dd/MM/yyyy"}
                                        autoOk={true}
                                        disabled={this.props.disabled}
                                        InputLabelProps={{
                                            error: !!this.props.errorText
                                        }}
                                        InputProps={{
                                            error: !!this.props.errorText,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FALightIcon icon='calendar-alt' style={{color: !!this.props.errorText && colors.red}} disabled={this.props.disabled} light noMargin />
                                                </InputAdornment>
                                            )
                                        }}
                                    />    
                                </MuiPickersUtilsProvider>
                            </MuiThemeProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MuiThemeProvider theme={materialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        fullWidth
                                        id={this.props.id}
                                        name={`${this.props.name}Time`}
                                        label={`${this.props.label} Time${this.props.required ? " *" : ""}`}
                                        value={this.state.time}
                                        onChange={this.timeChange} 
                                        disableFuture={this.props.disableFuture}
                                        format={"HH:mm"}
                                        ampm={false}
                                        autoOk={true}
                                        disabled={this.props.disabled}
                                        InputLabelProps={{
                                            error: !!this.props.errorText
                                        }}
                                        InputProps={{
                                            error: !!this.props.errorText,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FALightIcon icon='clock' style={{color: !!this.props.errorText && colors.red}} disabled={this.props.disabled} light noMargin />
                                                </InputAdornment>
                                            )
                                        }}
                                    />    
                                </MuiPickersUtilsProvider>
                            </MuiThemeProvider>
                        </Grid>
                    </Grid>
                    {this.props.errorText &&
                        <FormHelperText>{this.props.errorText}</FormHelperText>
                    }
                </FormControl>
            </React.Fragment>
        );
    }
}