import React from 'react';
import moment from 'moment';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { CLIENT_VERSION } from '../../../Constants';

const styles = theme => ({
    footer:{
        width:'100%',
    }
});

class AdminFooterMobile extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
        <footer className={`${classes.footer} sm-only`}>
            <Typography style={{textAlign:'center',marginTop:10,fontSize:13,color:'rgba(0,0,0,0.72)'}}>
                MyClenaware v{CLIENT_VERSION} by Clenaware Systems
            </Typography>
            <Typography style={{textAlign:'center',marginBottom:5,fontSize:11,color:'rgba(0,0,0,0.72)'}}>
                {`Copyright `}&copy;{` ${moment().year()} `}
                <Link color="inherit" href="http://www.crushedice.biz" target="_blank" rel="noopener">
                    Crushed Ice
                </Link>
            </Typography>
        </footer>
    );
  }
}

export default withStyles(styles)(AdminFooterMobile);