import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { Button, Grid } from '@material-ui/core/';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = {
    isLoading: true,
    stockCorrections: [],
    formErrors: [],
    access: {}
}


class OutstandingStockMovements extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => { 
        this.getAccess();    
        this.getData(); 
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/stock-correction:create'), 
            API.get('/staff/my/access/check/stock-correction:edit'), 
            API.get('/staff/my/access/check/stock-correction:delete'), 
        ]).then(([createRes, editRes, deleteRes]) => {
            this.setState({
                access: {
                    create: createRes.data.has_access || false,
                    edit: editRes.data.has_access     || false,
                    delete: deleteRes.data.has_access || false,
                }
            })
        });
    }

    getData = () => {
        let params = {outstandingOnly: true};
        Promise.all([
            API.get(`/stock/correction/all`,{params}),
        ]).then(([movementRes]) => {
            this.setState({
                stockCorrections: movementRes.data,
                isLoading: false
            });
        })
    }

    delete = id => {
        API.post(`/stock/correction/${id}/delete`)
        .then( res => {this.getData();})
    }

    createStockCorrection = () => {
        API.post('/stock/correction')
        .then( res => { window.location.href = `/stock/correction/edit/${res.data.sc_id}`; })
    }

    render() {

        const {isLoading, stockCorrections, access} = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {isLoading ? <LoadingCircle/>: 
                        <>
                            {access.create && <Button variant='contained' color='primary' onClick={this.createStockCorrection} style={{marginTop:'1em', marginBottom:'1em'}} >Create New Stock Correction</Button>}
                            <DataTable
                                config={{
                                    key: 'sc_id',
                                    alternatingRowColours:  true,
                                    responsiveimportant:    true,
                                    pagination:             true,
                                    filterMod: {
                                        filterInPagination:     true,
                                        colSpan:                3,
                                        clearable:              true,
                                    },
                                    style: rowData => ({
                                        color: moment(rowData.sc_date).diff(moment(),'days') < 0 && colors.red
                                    })
                                }}
                                columns={[
                                    {
                                        heading:    'Date',
                                        field:      rowData => clenyDate(rowData.sc_date),
                                        dataRef:    'sc_date',
                                        sizeToContent: true,
                                        filter:     'date',
                                        filterMod: {displayOrder: 1}
                                    },
                                    {
                                        heading:        'Created By',
                                        field:          rowData => rowData.sc_created_by,
                                        dataRef:        'sc_created_by',
                                        sizeToContent:  true,
                                        filter:         'custom',
                                        filterMod: {
                                            dataRef: (search, rowData) => {return `${rowData.sc_created_by}|${rowData.sc_ref}|${rowData.sc_template_id ? rowData.template.smt_name  : ''}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                            label:     'Keywords',
                                            styleGrid: {width: '50%'}
                                        }
                                    },
                                    {
                                        heading:    'Reference',
                                        field:      rowData => rowData.sc_ref,
                                        dataRef:    'sc_ref',
                                        truncate:   true
                                    },
                                    {
                                        heading:        '',
                                        field:          rowData =>  
                                            <>  
                                                {_.filter(rowData.details, i => i.scd_current_stock !== i.part.stock.stock_current_qty).length > 0 && 
                                                    <AllIcon 
                                                        icon={icons.warning}
                                                        color={colors.red}
                                                        tooltip='stock has changed since stock correction was created'
                                                        style={{padding: 0}}
                                                    />
                                                }
                                                {moment(rowData.sc_date).diff(moment(),'days') < 0 &&
                                                    <AllIcon 
                                                        icon={icons.overdue}
                                                        color={colors.red}
                                                        tooltip='stock correction is overdue'
                                                        style={{padding: 0}}
                                                    />
                                                }
                                            </>,
                                        alignment:      'right',
                                    },
                                    {
                                        heading:        'Parts',
                                        field:          rowData => rowData.details.length,
                                        dataRef:        rowData => rowData.details.length,
                                        sizeToContent:  true,
                                        alignment:      'center',
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'Delete', icon: icons.delete, onClick: () => { 
                                                    this.props.deployConfirmation(
                                                        'Are you sure you want to delete this stock correction?',
                                                        'Delete Stock Correction',
                                                        ()=>{this.delete(rowData.sc_id)}
                                                    ) }, disabled: !access.delete},
                                                {name: 'Edit',   icon: icons.edit, link: { pathname: '/stock/correction/edit/' + rowData.sc_id }, disabled: !access.edit}
                                            ]
                                        }
                                    }
                                ]}
                                rows={_.orderBy(stockCorrections, ['sc_date'],['desc'])}
                            />
                        </>
                    }
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation:     (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
})


export default connect(null,mapDispatchToProps)(OutstandingStockMovements);
