import React, { PureComponent }  from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FASolidIcon from '../../Common/Icons/FontAwesome/FASolidIcon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class SupplierContactCard extends PureComponent {
    
    render() {
        const { classes, idx, data, formErrors, handleContactChange, handleContactTypeChange, handleRemove } = this.props;
        return (
            <Paper className={classes.paperGrey}>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography variant="h6">
                                Contact {(idx+1)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{textAlign:'right'}}>
                            <IconButton onClick={handleRemove(idx)} disabled={(idx+1) === 1}>
                                <FASolidIcon icon='trash-alt' noMargin button style={{color: (idx+1) === 1 ? '#fefefe' : 'inherit'}} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <RadioGroup
                                name="contactType"
                                onChange={handleContactTypeChange(idx)}
                                row
                            >
                                <FormControlLabel
                                    value="Person"
                                    control={<Radio color="primary" />}
                                    label="Person"
                                    labelPlacement="end"
                                    checked={data.contactType && data.contactType === "Person" ? true : false}
                                />
                                <FormControlLabel
                                    value="Department"
                                    control={<Radio color="primary" />}
                                    label="Department"
                                    labelPlacement="end"
                                    checked={data.contactType && data.contactType === "Department" ? true : false}
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    {(data.contactType && data.contactType === 'Person') && (
                        <React.Fragment>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TextField
                                        name="firstName"
                                        label="First Name *"
                                        value={data.firstName}
                                        error={formErrors && formErrors['contacts|firstName|'+idx] && true}
                                        helperText={formErrors && formErrors['contacts|firstName|'+idx]}
                                        onChange={handleContactChange(idx)}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="lastName"
                                        label="Last Name"
                                        value={data.lastName}
                                        onChange={handleContactChange(idx)}
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                name="position"
                                label="Position in Company"
                                value={data.position}
                                onChange={handleContactChange(idx)}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                name="department"
                                label="Department"
                                value={data.department}
                                onChange={handleContactChange(idx)}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                name="directDial"
                                label="Direct Dial"
                                value={data.directDial}
                                error={formErrors && formErrors['contacts|directDial|'+idx] && true}
                                helperText={formErrors && formErrors['contacts|directDial|'+idx]}
                                onChange={handleContactChange(idx)}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                name="mobile"
                                label="Mobile Number"
                                value={data.mobile}
                                error={formErrors && formErrors['contacts|mobile|'+idx] && true}
                                helperText={formErrors && formErrors['contacts|mobile|'+idx]}
                                onChange={handleContactChange(idx)}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                value={data.email}
                                error={formErrors && formErrors['contacts|email|'+idx] && true}
                                helperText={formErrors && formErrors['contacts|email|'+idx]}
                                onChange={handleContactChange(idx)}
                                margin="normal"
                                fullWidth
                            />
                        </React.Fragment>
                    )}  
                    {(data.contactType && data.contactType === 'Department') && (
                        <React.Fragment>
                            <TextField
                                name="firstName"
                                label="Name *"
                                value={data.firstName}
                                error={formErrors && formErrors['contacts|firstName|'+idx] && true}
                                helperText={formErrors && formErrors['contacts|firstName|'+idx]}
                                onChange={handleContactChange(idx)}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                name="directDial"
                                label="Phone Number"
                                value={data.directDial}
                                error={formErrors && formErrors['contacts|directDial|'+idx] && true}
                                helperText={formErrors && formErrors['contacts|directDial|'+idx]}
                                onChange={handleContactChange(idx)}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                value={data.email}
                                error={formErrors && formErrors['contacts|email|'+idx] && true}
                                helperText={formErrors && formErrors['contacts|email|'+idx]}
                                onChange={handleContactChange(idx)}
                                margin="normal"
                                fullWidth
                            />
                        </React.Fragment>
                    )}
                    </form>          
                    
            </Paper>
        )
    }
}

export default SupplierContactCard;
