import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import React from 'react';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import _ from 'lodash';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import InfoIcon from 'Components/Common/Icons/InfoIcon';


const initialState = props => ({
    isLoading:  true,
    formData: {
        manual: props.contact.contact_id,
        system: null,
        customer: null,
    },
    customers:  [],
    manContact: props.contact,
    sysContact: {},
})

class MergeSystemContactDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getCustomers();
    }

    getCustomers = () => {
        API.get('/customers').then(r => {
            this.setState({
                customers: _.orderBy(_.map(r.data, i => {
                    let recommended = _.find(i.active_contacts, j => j.contact_email === this.props.contact.contact_email);
                    return {
                        value: i.cust_id,
                        label: `${i.cust_name}${ recommended ? ' (has matching email)' : ''}`,
                        recommended: recommended ? true : false,
                        contacts:  _.orderBy(_.map(i.active_contacts, j => ({
                            value: j.contact_id,
                            label: `${j.contact_name} (${j.contact_email})`,
                            recommended: j.contact_email === this.props.contact.contact_email ? true : false,
                            contact: j
                        })), 'recommended', 'desc')
                    }
                }), ['recommended', 'label'], ['desc', 'asc']),
                isLoading: false
            })
        })
    }

    setSysContact = e => {
        this.setState({
            sysContact: e.contact,
            formData: {
                ...this.state.formData,
                system: e.value
            }
        })
    }

    submit = () => {
        API.post('/marketing/contacts/mergeContacts', this.state.formData)
        .then(this.props.onClose)
    }

    render() {

        const { isLoading, formData, manContact, sysContact } = this.state;

        if (isLoading) {
            return <LoadingCircle />;
        }

        return (
            <Box>
                <Grid container spacing={3} style={{alignItems: 'center'}}>
                    <Grid item xs={12}>
                        <AutoCompleteSelect
                            label="Customer"
                            options={this.state.customers}
                            value={formData.customer}
                            onChange={e => this.setState({ formData: { ...formData, customer: e } })}
                            fullWidth
                            disableSort
                            noClear
                        />
                    </Grid>
                    {
                        formData.customer &&
                        <Grid item xs={12}>
                            <AutoCompleteSelect
                                label="Contact"
                                options={formData.customer.contacts}
                                value={formData.sysContact}
                                onChange={this.setSysContact}
                                fullWidth
                                disableSort
                                noClear
                            />
                        </Grid>
                    }
                    {formData.system &&
                        <>
                            <Grid item xs={5}>
                                <PaddedPaper>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1"
                                                style={{fontWeight: 'bold'}}
                                            >Manual Contact</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">{manContact.contact_name}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">{manContact.contact_email}</Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={2} style={{textAlign: 'center'}}>
                                <Typography variant="h6" style={{fontWeight: 'bold'}}>Merge Into</Typography>
                                <br/>
                                <AllIcon
                                    icon={IconHelper.arrowRight}
                                    heavy
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <PaddedPaper>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1"
                                                style={{fontWeight: 'bold'}}
                                            >System Contact</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">{sysContact.contact_name}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">{sysContact.contact_email}</Typography>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{fontWeight: 'bold'}}>
                                    <InfoIcon style={{marginRight: '1em'}} />This will merge all of the manual contacts links (mailing lists etc) into the system contact and delete the manual contact.
                                </Typography>
                            </Grid>
                        </>
                    }
                    <Grid item xs={12} className='buttonRow' >
                        <Button
                            variant='outlined'
                            onClick={this.props.onClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={()=>{
                                this.props.deployConfirmation(
                                    <>
                                        Are you sure you want to merge these contacts?, This will merge all of the manual contacts links (mailing lists etc) into the system contact and delete the manual contact.<br/><br/>
                                        <b>This action cannot be undone.</b>
                                    </>,
                                    'Merge Contacts',
                                    this.submit
                                )
                            }}
                            disabled={!formData.system || !formData.customer}
                        >
                            Merge
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (content, title, callback)      => dispatch(deployConfirmation(content, title, callback)),
})

export default connect(null, mapDispatchToProps)(MergeSystemContactDialog)