import React from 'react';
import { useDispatch } from 'react-redux';

import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

import { closeDialog } from 'Actions/Dialog/Dialog';

export default function ReduxConfirmationDialog(props) {
    const {message, success} = props;
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <DialogContentText>
                {message}
            </DialogContentText>
            <div className="buttonRow">
                <Button onClick={() => dispatch(closeDialog())} variant="outlined" color="default">Cancel</Button>
                <Button onClick={success} autoFocus variant="contained" color="primary">Yes</Button>
            </div>
        </React.Fragment>
    );
}