import React from 'react';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DownloadCSV from 'Components/Common/Buttons/DownloadCSV';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import _ from 'lodash';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import API from '../../../../API';
import DataTable from '../../../Common/DataTables/DataTable';
class SubAssemblies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subAssembly: {},
            masterExcel: [],
            open: false,
            isLoading: true,
        }
    }

    componentDidMount(){
        // this.getSubAssembly();
    }

    getSubAssembly = () => {
        let urls = [
            API.get(`/parts/${this.props.partId}/subAssembly`),
        ];
        if (this.props.showMasterExcel){
            urls.push(API.get("/reports/masterExcel"));
        }
        Promise.all(urls).then(result => {
            if (result[0].data) {
                this.setState({
                    subAssembly: result[0].data,
                    open:   true,
                    isLoading:   false,
                    masterExcel: this.props.showMasterExcel ?_.map(result[1].data, i => {
                        let isSub = _.find(result[0].data.sub_assembly_parts, { sa_part_id: i.mepo_part_id });
                        let isTop = parseInt(i.mepo_part_id) === parseInt(result[0].data.sa_part_id);
                        return {
                            part_number: i?.part?.part_number ?? 'CS-',
                            "Assembly (y)": !!isTop || !!isSub ? 'Y' : '',
                            'Qty': !!isSub ? isSub.sa_qty : '',
                        };

                    }) : [],
                });
            } else {
                this.setState({
                    open: true,
                    isLoading: false,
                })
            } 

        });
    };

    handleOpen = () => {
        if(!this.state.open){
            this.getSubAssembly();
        } else {
            this.setState({
                open: false
            });
        }
    }

    render() {

        const { open, isLoading } = this.state;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={this.handleOpen}>
                    <Typography>Sub Assemblies</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {open && !isLoading && 
                        <>
                            {this.state.subAssembly.sub_assembly_parts && this.state.subAssembly.sub_assembly_parts.length > 0 ? 
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <DataTable  
                                            config={{
                                                key: 'sa_id',
                                                alternatingRowColours: true,
                                                showTotals: true,
                                                plainHeader: true,
                                                headerCellProps: (column, rows) => {
                                                    return ({
                                                        style: {
                                                            textAlign: (['Qty', 'Cost Per Item', 'Total Cost', 'Cost %'].includes(column.heading) && 'right'),
                                                        }
                                                    });
                                                }
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Number',
                                                    field: rowData => rowData.part.part_number,
                                                    showTotalLabel: true
                                                },
                                                {
                                                    heading: 'Description',
                                                    field: rowData => rowData.part.part_description
                                                },
                                                {
                                                    heading: 'Part Version',
                                                    field: rowData => rowData.part.actual_part_version > 1 ? rowData.part.actual_part_version : 1,
                                                    fieldPrefix: 'v',
                                                },
                                                {
                                                    heading: 'Qty',
                                                    field: rowData => rowData.sa_qty,
                                                    fieldFormat: 'decimal:3',
                                                    showTotal: true,
                                                    alignment: 'right'
                                                },
                                                {
                                                    heading: 'Cost Per Item',
                                                    field: rowData => rowData.part.default_cost.cost_per_unit,
                                                    fieldFormat: 'decimal:5',
                                                    fieldPrefix: '£',
                                                    alignment: 'right'
                                                },
                                                {
                                                    heading: 'Total Cost',
                                                    field: rowData => (rowData.part.default_cost.cost_per_unit * rowData.sa_qty),
                                                    fieldFormat: 'decimal:5',
                                                    fieldPrefix: '£',
                                                    showTotal: true,
                                                    alignment: 'right'
                                                },
                                                {
                                                    heading: 'Cost %',
                                                    field: rowData => this.state.subAssembly.part.default_cost ? (((rowData.part.default_cost.cost_per_unit * rowData.sa_qty) / this.state.subAssembly.part.default_cost.cost) * 100) : 0,
                                                    fieldFormat: 'percentage',
                                                    showTotal: true,
                                                    overwriteTotal: '100.00%',
                                                    alignment: 'right'
                                                },
                                                {
                                                    actions: (rowData) => ([
                                                        {
                                                            name: "View",
                                                            icon: icons.subassemblies,
                                                            link: {
                                                                pathname: "/sub-assemblies/view/" + rowData.latest,
                                                            },
                                                            disabled: !rowData.latest,
                                                        },
                                                        {
                                                            name: "View",
                                                            icon: icons.parts,
                                                            link: { pathname: "/parts/view/" + rowData.sa_part_id },
                                                            disabled: rowData.part.part_outsourced_subassembly === 1,
                                                        },
                                                    ])
                                                },
                                            ]}
                                            rows={_.sortBy(this.state.subAssembly.sub_assembly_parts, function (pn) { return pn.part.part_number })}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">Current Version: v{this.state.subAssembly.sa_version}</Typography>
                                        <div className='buttonRow'>
                                            {this.props.showMasterExcel &&
                                                <DownloadCSV 
                                                    data    ={this.state.masterExcel} 
                                                    color='primary' 
                                                    variant='contained' 
                                                    filename={`Master Excel - ${this.state.subAssembly.part.part_number}.csv`} 
                                                >
                                                    <AllIcon icon={icons.excel} color={colors.white} buttonPrimary/>
                                                    Master Excel
                                                </DownloadCSV>
                                            }
                                            <Link to={`/sub-assemblies/history/${this.state.subAssembly.sa_id}`}>
                                                <Button variant="outlined">View Full Sub Assembly Details</Button>
                                            </Link>
                                        </div>
                                    </Grid>
                                </Grid>
                            :
                            <Typography>This part is not a sub assembly</Typography>
                            }

                        </>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default SubAssemblies;
