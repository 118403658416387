import React, {Component} from 'react';
import _ from 'lodash';
import {
    Button,
    Grid,
    Typography,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import API from 'API';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { toggleDialog } from 'Functions/MiscFunctions';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import FormCategoriesDialog from './FormCategoriesDialog';
import DataTable from 'Components/Common/DataTables/CiDataTable';

const initialState = () => ({
    searchResults: {},
    searchString: '',
    access: {
        manageCategories: false,
    },
    isLoading: true,
    dialog: {
        categories: false,
        id: 0,
    },
    categories: [],
});

class FormCategories extends Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.toggleDialog = toggleDialog.bind(this);
    }

    componentDidMount = () => {
        Promise.all([
            API.get('/staff/my/access/check/forms-categories:manage'),
        ])
        .then(([viewRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    manageCategories: (viewRes.data && viewRes.data.has_access) || false,
                }
            }, () => this.getSearchData());
        })        
    };

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/forms/configuration/categories', { params: { forSearch: true } })
            .then(result => {
                this.setState({
                    isLoading: false
                });
                if(result.data) {
                    this.setState({
                        categories: result.data
                    });
                } else {
                    /* Error */
                }
            });
        });
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.getSearchData();
        });
    }
    
    resetSearch = () => {
        this.setState({
            searchString: ''
        }, 
        () => {
            this.getSearchData();
        });
    }

    openDialog = (id = 0) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                categories: true,
                id
            },
        });
    }
    
    render() {
        const { access, dialog, categories } = this.state;
        return (
            <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Form Categories
                    </Typography>
                </Grid> 
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container item spacing={3}>
                            <Grid item xs align="right">
                                {access.manageCategories && (
                                    <Button 
                                        onClick={() => this.openDialog()}
                                        variant="outlined"
                                        color="default"
                                        size="small"
                                    >
                                        <FALightIcon icon='plus' button />
                                        Add Category
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {_.map(categories, area => {
                                    return (
                                        <ExpansionPanel key={area.id} expanded={true}>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography style={{color:'white'}}>{area.nm}</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails style={{padding: 0}}>
                                                {(area.cat && area.cat.length > 0 && (
                                                    <DataTable  
                                                        config={{
                                                            key: 'rowNumber',
                                                            nesting: true,
                                                            plainHeader: true,
                                                            rowProps: rowData => {
                                                                return ({
                                                                    style: {
                                                                        backgroundColor: rowData.children && `#fafafa`
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        columns={[
                                                            {
                                                                heading: '',
                                                                nestingDropdown: true,
                                                                field: rowData => rowData.nm,
                                                                style: rowData => {
                                                                    return ({
                                                                        fontWeight: (rowData.children && 'bold') || 'normal',
                                                                    })
                                                                }
                                                            },
                                                            {
                                                                heading: '',
                                                                field: rowData => rowData.type,
                                                                sizeToContent: true,
                                                            },
                                                            {
                                                                actions: rowData => {
                                                                    return [
                                                                        {name: 'Edit', icon: 'pencil-alt', onClick: () => this.openDialog(rowData.id), disabled: !access.manageCategories},
                                                                    ]
                                                                }
                                                            }

                                                        ]}
                                                        rows={area.cat}
                                                    />
                                                )) || (
                                                    <List>
                                                        <ListItem>
                                                            <ListItemText primary={<em>No categories to display</em>} />
                                                        </ListItem>
                                                    </List>
                                                )}
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
            </Grid>
            <SmallFormDialog
                open={dialog?.categories}
                onClose={() => this.toggleDialog('categories')}
                content={
                    <FormCategoriesDialog 
                        onClose={() => this.toggleDialog('categories', true)}
                        id={dialog?.id}
                        refresh={() => {this.toggleDialog('categories', true); this.getSearchData();}}
                    />
                }
                maxWidth='sm'
            />
            </>
        );
    }
}

export default FormCategories;