import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import API from '../../../../API';
import AutoCompleteSelect from '../../../Common/Selects/AutoCompleteSelect';
import CiDataTable from '../../../Common/DataTables/CiDataTable';
import PaddedPaper from '../../../Common/Paper/PaddedPaper';
import DatePicker from '../../../Common/DatePickers/DatePicker';
import logo from '../../../../Assets/Images/clenaware_logo_icon.png';
import { Link } from 'react-router-dom';
import { pdfFromBase64 } from '../../../../Helpers/PDFHelper';
import {colors} from 'Helpers/ColourHelper';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    isLoading: false,
    showTable: false,
    displayOptions: {},
    formData:{
        displayField: '',
        dateFrom: null,
        dateTo: null
    },
    deliveryReportData: []
});

class PartsDeliveryReport extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'report:PartsDeliveryReport';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        const displayOptions = [
            {
                value: 'Today',
                label: 'Today'
            },
            {
                value: 'This Week',
                label: 'This Week'
            },
            {
                value: 'This Month',
                label: 'This Month'
            },
            {
                value: 'Custom',
                label: 'Custom'
            }
        ];
        this.setState({displayOptions});
        this.hasPageState() && this.showResults();
    }

    handleChange = e => {
        if(e) {
            this.setState({
                formData:{
                    ...this.state.formData,
                    displayField: e.value
                }
            }, ()=> {this.savePageState();});
        } else {
            this.resetSearch();
        }
    }

    handleDateChange = name => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: date
            }
        }, ()=> {this.savePageState();});
    };

    showResults = () => {
        let formData = this.state.formData;
        API.post('/reports/partsDeliveryReport', {formData})
        .then(result => {
            this.setState({
                showTable: true,
                deliveryReportData: result.data
            });
        });
    }

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            showTable: false,
            deliveryReportData: {},
            formData: {
                displayField: '',
            }
        });
    }

    downloadPPO = ppoId => {
        API.get('/partsOrdering/' + ppoId + '/pdfPartsOrder')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    render() {
        const { formData } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Parts Delivery Report
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.displayOptions} 
                                        id="displayField"
                                        name="displayField"
                                        label='Display'
                                        value={formData.displayField}
                                        onChange={this.handleChange}
                                    />
                                </FormControl>
                                {this.state.formData.displayField === 'Custom' && (
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={6}>
                                            <DatePicker
                                                type='date'
                                                id="dateFrom"
                                                name="dateFrom"
                                                label="Date From"
                                                autoOk={true}
                                                value={formData.dateFrom}
                                                onChange={this.handleDateChange('dateFrom')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <DatePicker
                                                type='date'
                                                id="dateTo"
                                                name="dateTo"
                                                label="Date To"
                                                autoOk={true}
                                                value={formData.dateTo}
                                                onChange={this.handleDateChange('dateTo')}                                            
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                <div className='buttonRow'>
                                    <Button 
                                        onClick={this.showResults}
                                        color="primary"
                                        variant="contained"
                                        disabled={formData.displayField === ''}
                                    
                                    >
                                        Show
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item lg={12}>
                    {this.state.showTable === true && (
                        <PaddedPaper>
                            <CiDataTable  
                                config={{
                                    key: 'schedule_id',
                                    pagination: true,
                                    isLoading: this.state.isLoading,
                                    alternatingRowColours: true,
                                    rowProps: rowData => {
                                        return ({
                                            style: {
                                                color: rowData.status === 'Overdue' && colors.red,
                                            }
                                        });
                                    },
                                    options: {
                                        dataRef: true,
                                        reset: this.resetSearch,
                                        export: {
                                            title: `Parts Delivery Report`,
                                            name: `Report`,
                                            excel: true,
                                            pdf: true,
                                            print: true
                                        }
                                    }
                                }}
                                columns={[
                                    {
                                        field: rowData =>   <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                {rowData.thumbnail_url ? 
                                                                    <Link to={{pathname: '/library/parts/search', state: { part: rowData.part_id } }}>
                                                                        <img src={rowData.thumbnail_url} alt={rowData.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                                    </Link> : 
                                                                <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>}
                                                            </div>,
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Part Number',
                                        field: rowData => rowData.part_number,
                                        dataRef: 'part_number',
                                    },
                                    {
                                        heading: 'Part Description',
                                        field: rowData => rowData.part_description,
                                        dataRef: 'part_description',
                                    },
                                    {
                                        heading: 'Quantity',
                                        field: rowData => rowData.quantity,
                                        dataRef: 'quantity',
                                    },
                                    {
                                        heading: 'Received',
                                        field: rowData => rowData.received,
                                        dataRef: 'received',
                                    },
                                    {
                                        heading: 'Due',
                                        field: rowData => rowData.due,
                                        dataRef: 'due',
                                    },
                                    {
                                        heading: 'Supplier',
                                        field: rowData => rowData.supplier,
                                        dataRef: 'supplier',
                                    },
                                    {
                                        heading: 'Delivery Status',
                                        field: rowData => rowData.status,
                                        dataRef: 'status'
                                    },
                                    {
                                        heading: 'Date Due',
                                        field: rowData => rowData.delivery_date,
                                        fieldFormat: 'date',
                                        dataRef: 'delivery_date'
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'Download PO', icon: 'download', onClick: () => this.downloadPPO(rowData.ppo_id)},
                                                {name: 'View PO', icon: 'search', link: '/parts-order/view/' + rowData.ppo_id}
                                            ]
                                        }
                                    }
                                ]}
                                rows={this.state.deliveryReportData}
                            />
                        </PaddedPaper>
                    )}
                </Grid>
            </Grid>
        );
    }
}
const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(PartsDeliveryReport);
