import React, { useEffect, useState } from 'react';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import StatsTooltip from 'Components/Sales/Order/OrderStats/OrderStatsTooltip';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';

import {colors} from 'Helpers/ColourHelper';

const useStyles = makeStyles(() => ({
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap'
    },
    resultContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    tbc: {
        color: 'black'
    },
    pending: {
        color: '#F39C12'
    },
    completed: {
        color: colors.green
    },
    cancelled: {
        color: colors.red
    }
}));

const OrderStatsRow = ({approval, confirmed, payment, paymentConfirmed, picking, despatch, invoice, completed, declined, cancelled}) => {
    const initialState = {
        isLoading: true
    };
    let [state, setState] = useState(initialState);
    const {isLoading} = state;

    const classes = useStyles();

    useEffect(() => {
        if(approval && confirmed && payment && paymentConfirmed && picking && despatch && invoice && completed && declined && cancelled) {
            setState(state => ({
                ...state,
                isLoading: false
            }));
        }
    }, [approval, confirmed, payment, paymentConfirmed, picking, despatch, invoice, completed, declined, cancelled]);

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <CardContent style={{margin: 0}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Grid container item spacing={3} xs={12} style={{borderRight: '1.5px solid #c8c8c8'}} justify='center'>
                                <Grid item>
                                    <div className={classes.iconContainer}>
                                        <FALightIcon icon="file-user" size="small" style={{color: '#F39C12'}} />
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            Awaiting Approval
                                        </Typography>
                                    </div>
                                    <div className={classes.iconContainer}>
                                        <FALightIcon icon="user-check" size="small" style={{color: '#F39C12'}}/>
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            Order Confirmed
                                        </Typography>
                                    </div>
                                    <div className={classes.iconContainer}>
                                        <FALightIcon icon="credit-card" size="small" style={{color: '#F39C12'}} />
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            Awaiting Payment
                                        </Typography>
                                    </div>
                                    <div className={classes.iconContainer}>
                                        <FALightIcon icon="receipt" size="small" style={{color: '#F39C12'}} />
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            Payment Confirmed
                                        </Typography>
                                    </div>
                                    <div className={classes.iconContainer}>
                                        <FALightIcon icon="people-carry" size="small" style={{color: '#F39C12'}} />
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            Awaiting Picking
                                        </Typography>
                                    </div>
                                    <div className={classes.iconContainer}>
                                        <FALightIcon icon="truck" size="small" style={{color: '#F39C12'}} />
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            Awaiting Despatch
                                        </Typography>
                                    </div>
                                    <div className={classes.iconContainer}>
                                        <FALightIcon icon="file-invoice-dollar" size="small" style={{color: '#F39C12'}} />
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            Awaiting Invoice
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.resultContainer}>
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            {approval.count}
                                        </Typography>
                                        <StatsTooltip 
                                            subTotal={approval.sub_total}
                                            vat={approval.vat}
                                            total={approval.total}
                                        />
                                    </div>
                                    <div className={classes.resultContainer}>
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            {confirmed.count}
                                        </Typography>
                                        <StatsTooltip 
                                            subTotal={confirmed.sub_total}
                                            vat={confirmed.vat}
                                            total={confirmed.total}
                                        />
                                    </div>
                                    <div className={classes.resultContainer}>
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            {payment.count}
                                        </Typography>
                                        <StatsTooltip 
                                            subTotal={payment.sub_total}
                                            vat={payment.vat}
                                            total={payment.total}
                                        />
                                    </div>
                                    <div className={classes.resultContainer}>
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            {paymentConfirmed.count}
                                        </Typography>
                                        <StatsTooltip 
                                            subTotal={paymentConfirmed.sub_total}
                                            vat={paymentConfirmed.vat}
                                            total={paymentConfirmed.total}
                                        />
                                    </div>
                                    <div className={classes.resultContainer}>
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            {picking.count}
                                        </Typography>
                                        <StatsTooltip 
                                            subTotal={picking.sub_total}
                                            vat={picking.vat}
                                            total={picking.total}
                                        />
                                    </div>
                                    <div className={classes.resultContainer}>
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            {despatch.count}
                                        </Typography>
                                    </div>
                                    <div className={classes.resultContainer}>
                                        <Typography variant="subtitle2" className={classes.pending}>
                                            {invoice.count}
                                        </Typography>
                                        <StatsTooltip 
                                            subTotal={invoice.sub_total}
                                            vat={invoice.vat}
                                            total={invoice.total}
                                        />
                                   </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container item spacing={3} xs={12} justify='center'>
                                <Grid item>
                                    <div className={classes.iconContainer}>
                                        <FALightIcon icon="check" size="small" style={{color: colors.green}} />
                                        <Typography variant="subtitle2" className={classes.completed}>
                                            Completed
                                        </Typography>
                                    </div>
                                    <div className={classes.iconContainer}>
                                        <FALightIcon icon="user-times" size="small" style={{color: colors.red}} />
                                        <Typography variant="subtitle2" className={classes.cancelled}>
                                            Declined
                                        </Typography>
                                    </div>
                                    <div className={classes.iconContainer}>
                                        <FALightIcon icon="ban" size="small" style={{color: colors.red}} />
                                        <Typography variant="subtitle2" className={classes.cancelled}>
                                            Cancelled
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" className={classes.tbc}>
                                        <div className={classes.resultContainer}>
                                            <Typography variant="subtitle2" className={classes.completed}>
                                                {completed.count}
                                            </Typography>
                                            <StatsTooltip 
                                                subTotal={completed.sub_total}
                                                vat={completed.vat}
                                                total={completed.total}
                                                style={{float: 'right'}}
                                            />
                                        </div>
                                    </Typography>
                                    <div className={classes.resultContainer}>
                                        <Typography variant="subtitle2" className={classes.cancelled}>
                                            {declined.count}
                                        </Typography>
                                        <StatsTooltip 
                                            subTotal={declined.sub_total}
                                            vat={declined.vat}
                                            total={declined.total}
                                            style={{float: 'right'}}
                                        />
                                    </div>
                                    <div className={classes.resultContainer}>
                                        <Typography variant="subtitle2" style={{color:colors.red}}>
                                            {cancelled.count}
                                        </Typography>
                                        <StatsTooltip 
                                            subTotal={cancelled.sub_total}
                                            vat={cancelled.vat}
                                            total={cancelled.total}
                                            style={{float: 'right'}}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            )}
        </React.Fragment>
    );
}

export default OrderStatsRow;