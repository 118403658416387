import React from 'react';
import {Box, Grid, Button} from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import API from 'API';
import _ from 'lodash';

const initialState = {
    formData: {
        type: null,
        subType: null,
        file: null,
    },
    isLoading: false,
}

class AddFilesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleSelectChange = (field) => (e) => {
        const {formData} = this.state;
        formData[field] = e.value;
        this.setState({formData});
    }

    handleFileChange = field => (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: file
            }
        })
    }

    clearFile = field => () => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: ''
            }
        })
    }

    handleSubmit = () => {
        this.setState({isLoading: true}, () => {
            const {formData} = this.state;
            let newFormData = new FormData();
            newFormData.append('type', formData.type);
            newFormData.append('file', formData.file);
            newFormData.append('subType', formData.subType);
            
            API.post(`/customerReturns/returns/${this.props.cr.cr_id}/uploadFile`, newFormData).then(res => {
                if (res.data.success) {
                    this.props.onClose();
                }
            })

        });
    }

    render() {
        const { formData, isLoading } = this.state;

        if (isLoading) {
            return <LoadingCircle />;
        }

        return (
            <Box>
                <Grid container spacing={3} style={{alignItems: 'center'}}>
                    <Grid item xs={12}>
                        <AutoCompleteSelect
                            options={_.filter([
                                {label: 'Engineer Job Sheet', value: 'Engineer Job Sheet'},
                                !!this.props.cr?.collection && {label: 'Collection File', value: 'Collection'},
                            ], i => i)}
                            value={formData.type}
                            onChange={this.handleSelectChange('type')}
                            fullWidth
                            label='File Type *'
                        />
                    </Grid>
                    {formData.type === 'Engineer Job Sheet' && 
                        <Grid item xs={12}>
                            <AutoCompleteSelect
                                options={[
                                    { value: 'Other',                       label: 'Other' },
                                    { value: 'Machine Installation',        label: 'Machine Installation' },
                                    { value: 'Machine Maintenance Service', label: 'Machine Maintenance Service' },
                                    { value: 'Machine Breakdown Repair',    label: 'Machine Breakdown Repair' },
                                    { value: 'Machine Assessment',          label: 'Machine Assessment' },
                                ]}
                                value={formData.subType}
                                onChange={this.handleSelectChange('subType')}
                                fullWidth
                                label='Job Sheet Type *'
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <DragFileInput
                            label="File *"
                            file={formData.file}
                            type="file"
                            onChange={this.handleFileChange('file')}
                            cancelOnClick={this.clearUploads}
                            emptyText='No files uploaded'
                        />
                    </Grid>
                    <Grid item xs={8} >
                        <AllIcon icon={IconHelper.info} size='small'/> Combined maximum upload limit 1.5GB
                    </Grid>
                    <Grid item xs={4} className='buttonRow' >
                        <Button
                            variant='outlined'
                            onClick={this.props.onClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.handleSubmit}
                            disabled={!formData.type || !formData.file || (formData.type === 'Engineer Job Sheet' && !formData.subType)}
                        >
                            Upload
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default AddFilesDialog;