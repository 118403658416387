import React, { PureComponent }  from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import IconButton from '@material-ui/core/IconButton';
import {colors} from 'Helpers/ColourHelper';

const styles = theme => ({
    td : {
        border: 'none'
    },
    [theme.breakpoints.down('xs')]: {
        responsiveTable: {
            maxWidth: '100%', 
            overflowX: 'auto'
        }
    }
});

class OtherItems extends PureComponent {
    
    render() {
        const { classes, formErrors, otherRows, handleAddRow, handleRemoveRow, handleSelectRowChange, handleRowChange, defaultItemsList  } = this.props;
        return (
            <React.Fragment>
                <Typography variant="h6" style={{marginTop: 20}}>
                    Other Items
                </Typography>
                <form noValidate autoComplete="off">
                    <div className={classes.responsiveTable}>
                        <Table style={{minWidth: 900}}>
                            <TableBody>
                                {otherRows.map((item, idx) => {
                                    let number = idx+1
                                    return (
                                        <React.Fragment key={`or_${idx}`}>
                                            <TableRow>
                                                <TableCell className={classes.td} style={{width:'30%'}}>
                                                    <FormControl fullWidth>
                                                        <AutoCompleteSelect 
                                                            options={defaultItemsList} 
                                                            value={item.description}
                                                            label="Description"
                                                            error={formErrors && formErrors['otherRows|description|'+idx] && true}
                                                            errorText={formErrors && formErrors['otherRows|description|'+idx]}
                                                            onChange={handleSelectRowChange(idx, 'description', 'otherRows')}
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell className={classes.td} style={{width:'30%'}}>
                                                    <FormControl fullWidth>
                                                        <AutoCompleteSelect 
                                                            options={item.nominalList} 
                                                            value={item.nominalCode}
                                                            label='Nominal Code *'
                                                            error={formErrors && formErrors['otherRows|nominalCode|'+idx] && true}
                                                            errorText={formErrors && formErrors['otherRows|nominalCode|'+idx]}
                                                            onChange={handleSelectRowChange(idx, 'nominalCode', 'otherRows')}
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="quantity"
                                                        value={item.quantity}
                                                        label="Quantity"
                                                        error={formErrors && formErrors['otherRows|quantity|'+idx] && true}
                                                        helperText={formErrors && formErrors['otherRows|quantity|'+idx]}
                                                        onChange={handleRowChange(idx, 'otherRows')}
                                                        onBlur={handleRowChange(idx, 'otherRows', 3)}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="unitPrice"
                                                        value={item.unitPrice}
                                                        label="Unit Price"
                                                        error={formErrors && formErrors['otherRows|unitPrice|'+idx] && true}
                                                        helperText={formErrors && formErrors['otherRows|unitPrice|'+idx]}
                                                        onChange={handleRowChange(idx, 'otherRows')}
                                                        onBlur={handleRowChange(idx, 'otherRows', 5)}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="totalPrice"
                                                        value={item.totalPrice}
                                                        label="Total Price"
                                                        error={formErrors && formErrors['otherRows|totalPrice|'+idx] && true}
                                                        helperText={formErrors && formErrors['otherRows|totalPrice|'+idx]}
                                                        onChange={handleRowChange(idx, 'otherRows')}
                                                        onBlur={handleRowChange(idx, 'otherRows', 2)}
                                                        type="number"
                                                        disabled
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    {number > 1 &&
                                                        <IconButton onClick={handleRemoveRow(idx, 'otherRows')}>
                                                            <FALightIcon icon='times' noMargin button />
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                                <TableRow>
                                    <TableCell className={classes.td} colSpan={5}>
                                        <Button 
                                            onClick={handleAddRow('otherRows')}
                                            variant="contained"
                                            color="primary"
                                            size="small">
                                            Add Row
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </form>
                {formErrors && formErrors['otherRows'] && formErrors['otherRows'].split('\n').map((item, key) => {
                    return (
                        <React.Fragment key={key}>
                            <Typography component={"div"} style={{color: colors.red}}>
                                {item}<br/>
                            </Typography>
                        </React.Fragment>
                    )
                })}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(OtherItems);
    