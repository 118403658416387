import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import _ from 'lodash';
import API from 'API';

export default function CustomerMarketing({onSubmit, customerMarketing, logicData}) {

    let [category, setCategory] = React.useState(logicData?.category);
    let [subcategories, setSubcategories] = React.useState(logicData?.subcategories);

    const getEligibleContacts = () => {
        if (subcategories?.length > 0) {
            API.get(`/marketing/campaigns/mailingLists/eligibleContacts`, {params: {
                breakdown: 1,
                logicData: JSON.stringify({
                    ...logicData,
                    category: category,
                    subcategories: subcategories,
                }),
                logicType: 'CustomerMarketing',
            }})
            .then(({data}) => {
                onSubmit(data.contacts)({
                    ...logicData,
                    category: category,
                    subcategories: subcategories,
                });
            })
        }
    }

    useEffect(getEligibleContacts, [subcategories])
    useEffect(() => { setSubcategories([]); }, [category])

    return (
        <>
            <Grid item xs={12}>
                <AutoCompleteSelect
                    label="Category *"
                    value={category}
                    onChange={e => setCategory(e?.value)}
                    fullWidth
                    options={_.uniqBy(_.filter(customerMarketing,  i => i.customers.length > 0), i => i.cm_category).map(i => ({label: i.cm_category, value: i.cm_category}))}
                    disableSort
                    noClear
                />
            </Grid>
            {category &&
                <Grid item xs={12}>
                    <AutoCompleteMultiSelect
                        label="Subcategories *"
                        value={subcategories}
                        onChange={e => setSubcategories(_.map(e, i => i.value))}
                        fullWidth
                        options={_.filter(customerMarketing, i => i.cm_category === category && i.customers.length > 0).map(i => ({label: i.cm_name, value: i.cm_id}))}
                        disableSort
                        noClear
                    />
                </Grid>
            }
        </>
    )
}