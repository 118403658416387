import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { Grid, Typography, Button } from '@material-ui/core';
import React from 'react';
import API from 'API';
import moment from 'moment';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import _ from 'lodash';
import IconHelper from 'Helpers/IconHelper';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import InspectDialog from './InspectDialog';
import { connect } from 'react-redux';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { clenyDate } from 'Helpers/TimeHelper';

const initialState = props => ({
    customerReturn: {},
    isLoading: true
})

class ViewCustomerReturnInspection extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getCustomerReturn();
    }

    getCustomerReturn() {
        API.get(`/customerReturns/returns/${this.props.match.params.id}`).then(response => {this.setState({ cr: response.data, isLoading: false });})
    }

    viewInspection = (row) => {
        this.props.deployDialog(
            <InspectDialog
                delivery={row}
                cr={this.state.cr}
                onClose={this.onDialogClose}
            />,
            'Inspect Delivery',
            'success',
            'md'
        )
    }

    onDialogClose = () => {
        this.props.closeDialog();   
        this.getCustomerReturn();
    }

    submit = () => {
        API.post(`/customerReturns/returns/${this.props.match.params.id}/completeDeliveryInspection`).then(res => {
            this.props.history.push('/returns/customerReturn/inspection');
        })
    }

    render() {

        const { cr, isLoading } = this.state;
        if (isLoading){ return <LoadingCircle/> }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        Customer Return Inspection
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Typography variant="h5" gutterBottom>{cr.cr_reference}-{cr.cr_reference_amendment < 10 ? '0' + cr.cr_reference_amendment : cr.cr_reference_amendment}</Typography>
                        <Typography variant='body1' style={{marginBottom: '0.5em'}}>
                            Collection Date: {clenyDate(cr.collection.crc_date)} <AllIcon noMargin icon={icons.slashForward}/> Delivery Date: {clenyDate(cr.collection.crc_delivery_date)} <AllIcon noMargin icon={icons.slashForward}/> Inspection Date: {clenyDate(moment())}
                        </Typography>
                        <Typography variant='body1'>Expected Outcome: {cr.cr_expected_outcome === 'Refund' ? 'Credit Note' : cr.cr_expected_outcome}</Typography>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={9}>
                    <PaddedPaper>
                        <CiDataTable
                            config={{
                                key: 'cr_id',
                            }}
                            columns={[
                                {
                                    heading:       '',
                                    field:         'part.default_image.thumbnail_file_path',
                                    fieldFormat:   'image',
                                    sizeToContent: true,
                                },
                                {
                                    heading:       'Part Number',
                                    field:         'part.part_number',
                                    sizeToContent: true,
                                },
                                {
                                    heading:       'Part Description',
                                    field:         i => {
                                                        let sn = [];
                                                        let wvl = [];

                                                        _.each(i.received, j => {
                                                            sn  = [...sn,  ...JSON.parse(j.crcdr_serial_numbers)];
                                                            wvl = [...wvl, ...JSON.parse(j.crcdr_warranty_void_labels)];
                                                        })

                                                        return (    
                                                            <>
                                                                {i.part.part_description}<br />
                                                                {sn.length > 0 &&
                                                                    <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>
                                                                        {_.map(sn, j => <>{j}<br/></>)}
                                                                    </Typography>
                                                                }
                                                                {wvl.length > 0 &&
                                                                    <Typography variant="body2" style={{color: '#9E9E9E', fontSize: '0.7rem'}}>
                                                                        {_.map(wvl, j => <>{j}<br/></>)}
                                                                    </Typography>
                                                                }
                                                            </>
                                                        )
                                                    },
                                },
                                {
                                    heading:       'Expected',
                                    field:         'crcd_expected',
                                    fieldFormat:   'decimal:3',
                                    sizeToContent: true
                                },
                                {
                                    heading:       'Received',
                                    field:         'crcd_recieved',
                                    fieldFormat:   'decimal:3',
                                    sizeToContent: true
                                },
                                {
                                    heading:       'Inspected',
                                    field:         i => _.sumBy(i.inspections, j => parseFloat(j.cri_qty)),
                                    fieldFormat:   'decimal:3',
                                    sizeToContent: true
                                },
                                {
                                    actions: i => ([
                                        {
                                            name: 'Inspect',
                                            icon: IconHelper.inspection,
                                            onClick: this.viewInspection,
                                            color: parseFloat(i.crcd_recieved) === 0 ? null : ( i.inspections.length > 0 ? ( colors.green ) : colors.red),
                                            disabled: parseFloat(i.crcd_recieved) === 0 ||  i.inspections.length > 0
                                        }
                                    ])
                                }
                            ]}
                            rows={cr.collection.details}
                        />
                    </PaddedPaper>
                    <div style={{width: '100%'}} className='buttonRow'>
                        <BackButton />
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={_.find(_.filter(cr.collection.details, i => parseFloat(i.crcd_recieved) > 0 ), i =>  i.inspections.length === 0)}
                            onClick={
                                () => this.props.deployConfirmation(
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}><Typography variant='body1'>Are you sure you want to complete this inspection?</Typography></Grid>
                                    </Grid>,
                                    'Submit Inspection',
                                    this.submit
                                )
                            }
                        >Complete Inspection</Button>
                    </div>
                </Grid>
                <Grid item xs={3}>
                        {_.sumBy(cr.collection.details, i => i.inspections.length) > 0 && 
                            <Grid container spacing={2}>
                                {_.map(['Refund', 'Replace', 'Repair'], inspectionType => {
                                    let name = '';
                                    let icon = '';
                                    switch(inspectionType) {
                                        case 'Refund':
                                            name = 'Credit Note';
                                            icon = icons.creditNote;
                                            break;
                                        case 'Replace':
                                            name = 'Replace';
                                            icon = icons.sales;
                                            break;
                                        case 'Repair':
                                            name = 'Repair';
                                            icon = icons.parts;
                                            break;
                                        default:
                                            break;
                                    }

                                    
                                    if (_.sumBy(cr.collection.details, i => _.filter(i.inspections, j => j.cri_outcome === inspectionType).length) > 0) {
                                        let items = [];
                                        let topFilter = [];
                                        _.each(cr.collection.details, i => {
                                            _.each(_.filter(i.inspections, j => j.cri_outcome === inspectionType), j => {
                                                topFilter.push({...j, part: i.part});
                                            })
                                        })
                                        _.each(_.uniqBy(topFilter, e => `${e.cri_condition_grade} ${e.cri_part_id}`), e => {
                                            items.push({
                                                part: e.part.part_number,
                                                grade: e.cri_condition_grade,
                                                qty: _.sumBy(_.filter(topFilter, i => i.cri_condition_grade === e.cri_condition_grade && parseInt(i.cri_part_id) === parseInt(e.cri_part_id)), i => parseFloat(i.cri_qty))
                                            })
                                        })
                                        return (
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                                                            <AllIcon heavy icon={icon} size='small'/><Typography><strong>{name}</strong></Typography>
                                                        </Grid>
                                                        {
                                                            _.map(items, item =>
                                                                <>
                                                                    <Grid item xs={10}
                                                                        style={{color: (
                                                                            item.grade === 'pass' ? colors.green : (
                                                                                item.grade === 'fail' ? colors.red : colors.orange
                                                                            )
                                                                        )}}
                                                                    >
                                                                        {item.part}
                                                                    </Grid>    
                                                                    <Grid item style={{marginLeft: 'auto', color: (
                                                                            item.grade === 'pass' ? colors.green : (
                                                                                item.grade === 'fail' ? colors.red : colors.orange
                                                                            )
                                                                    )}}>{parseFloat(item.qty).toFixed(3)}</Grid>
                                                                </>
                                                            )
                                                        }
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        );
                                    }

                                })}
                            </Grid>
                        }
                    {/* {/* {_.sumBy(cr.collection.details, i => i.inspections.length) > 0 && 
                        <>
                        
                            <Grid container spacing={2}>
                                {_.sumBy(cr.collection.details, i => _.filter(i.inspections, j => j.cri_outcome === 'Refund').length) > 0 &&
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                                                    <AllIcon heavy icon={icons.creditNote} size='small'/><Typography><strong>Refund</strong></Typography>
                                                </Grid>
                                                {_.map(cr.collection.details, i => 
                                                    _.map(_.filter(i.inspections, j => j.cri_outcome === 'Refund'), j => 
                                                        <>
                                                            <Grid item xs={10}
                                                                style={{color: (
                                                                    j.cri_condition_grade === 'pass' ? colors.green : (
                                                                        j.cri_condition_grade === 'fail' ? colors.red : colors.orange
                                                                    )
                                                                )}}
                                                            >
                                                                {i.part.part_number}
                                                            </Grid>
                                                            <Grid item style={{marginLeft: 'auto', color: (
                                                                    j.cri_condition_grade === 'pass' ? colors.green : (
                                                                        j.cri_condition_grade === 'fail' ? colors.red : colors.orange
                                                                    )
                                                            )}}>{parseInt(j.cri_qty).toFixed(3)}</Grid>
                                                        </>
                                                    )
                                                )}
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                }
                                {_.sumBy(cr.collection.details, i => _.filter(i.inspections, j => j.cri_outcome === 'Replace').length) > 0 &&
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                                                    <AllIcon heavy icon={icons.sales} size='small'/><Typography><strong>Replace</strong></Typography>
                                                </Grid>
                                                {_.map(cr.collection.details, i => 
                                                    _.map(_.filter(i.inspections, j => j.cri_outcome === 'Replace'), j => 
                                                        <>
                                                            <Grid item xs={10}
                                                                style={{color: (
                                                                    j.cri_condition_grade === 'pass' ? colors.green : (
                                                                        j.cri_condition_grade === 'fail' ? colors.red : colors.orange
                                                                    )
                                                                )}}
                                                            >
                                                                {i.part.part_number}
                                                            </Grid>
                                                            <Grid item style={{marginLeft: 'auto', color: (
                                                                    j.cri_condition_grade === 'pass' ? colors.green : (
                                                                        j.cri_condition_grade === 'fail' ? colors.red : colors.orange
                                                                    )
                                                            )}}>{parseInt(j.cri_qty).toFixed(3)}</Grid>
                                                        </>
                                                    )
                                                )}
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                }
                                {_.sumBy(cr.collection.details, i => _.filter(i.inspections, j => j.cri_outcome === 'Repair').length) > 0 &&
                                    <Grid item xs={12}>
                                        <PaddedPaper>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                                                    <AllIcon heavy icon={icons.parts} size='small'/><Typography><strong>Repair</strong></Typography>
                                                </Grid>
                                                {_.map(cr.collection.details, i => 
                                                    _.map(_.uniqBy(
                                                        _.filter(i.inspections, j => j.cri_outcome === 'Repair')
                                                        , j => ` ${j.cri_condition_grade} ${j.part.part_number}`
                                                    ), j => {
                                                        let qty = _.sumBy(_.filter(i.inspections, i => i.cri_outcome === 'Repair' && i.cri_condition_grade === j.cri_condition_grade && i.part.part_number === j.part.part_number), i => parseInt(i.cri_qty));
                                                        return (
                                                            <>
                                                                <Grid item xs={10}
                                                                    style={{color: (
                                                                        j.cri_condition_grade === 'pass' ? colors.green : (
                                                                            j.cri_condition_grade === 'fail' ? colors.red : colors.orange
                                                                        )
                                                                    )}}
                                                                >
                                                                    {i.part.part_number}
                                                                </Grid>
                                                                <Grid item style={{marginLeft: 'auto', color: (
                                                                        j.cri_condition_grade === 'pass' ? colors.green : (
                                                                            j.cri_condition_grade === 'fail' ? colors.red : colors.orange
                                                                        )
                                                                )}}>{parseInt(qty).toFixed(3)}</Grid>
                                                            </>
                                                        )
                                                    })
                                                )}
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                } 
                            </Grid>
                        </>
                    } */}
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                        => dispatch(closeDialog()),
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    deployDialog: (content, title, variant, size='md') => dispatch(deployDialog(content, title, variant, size)),
})

export default connect(null, mapDispatchToProps)(ViewCustomerReturnInspection);