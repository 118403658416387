import React, {Component} from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import API from '../../../API';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CiDataTable from '../../Common/DataTables/CiDataTable';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import { gridPageChanged, gridRowsPerPageChanged } from 'Functions/FormFunctions'
import icons from 'Helpers/IconHelper';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    keywords: '',
    searchResults: {},
    access: {
        viewCost: false,
        updateCost: false
    },
    isLoading: true,
});

class CostSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Cost:Search';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        !this.hasPageState() && this.loadComponentData()
    }

    loadComponentData = () => {
        this.checkAccess();
        this.getSearchData()
    }


    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/view-cost'), 
            API.get('/staff/my/access/check/update-cost'),
        ])
        .then(([viewRes, updateRes]) =>  {
            viewRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        viewCost: viewRes.data.has_access
                    }
                });
            updateRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        updateCost: updateRes.data.has_access
                    }
                });
        });
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/costs/all' , {
                params: {
                    keywords: this.state.keywords && this.state.keywords,
                },
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    },()=>{
                        this.savePageState();
                    })
                }
            });
        })
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value,
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    reset = () => {
        this.setState({
            keywords: initialState().keywords,
        }, 
        () => {
            this.getSearchData();
            this.clearPageState();
        });
    }

    render() {
        const { access, gridPagination, keywords } = this.state;
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Cost Search
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <form noValidate autoComplete="off">
                                    <FormControl margin="normal" fullWidth>
                                        <TextField label="Keywords" fullWidth value={keywords} onChange={this.handleSearchKeyword} />
                                    </FormControl>
                                    <div className="buttonRow">
                                        <Button 
                                            onClick={this.reset}
                                            variant="outlined"
                                            color="default"
                                        >
                                            <FALightIcon icon='undo' button />
                                            Reset Search
                                        </Button>
                                    </div>
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <CiDataTable  
                                config={{
                                    key: 'cost_id',
                                    pagination: true,
                                    //page: gridPagination.page,
                                    //rowsPerPage: gridPagination.rowsPerPage,
                                    //gridPageChanged: this.gridPageChanged,
                                    //gridRowsPerPageChanged: this.gridRowsPerPageChanged,
                                    persistenceId: this.persistenceId,
                                    responsiveImportance: true,
                                    alternatingRowColours: true,
                                    isLoading: this.state.isLoading
                                }}
                                columns={[
                                    {
                                        heading: 'Number',
                                        field: rowData => rowData.part_number,
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Description',
                                        field: rowData => rowData.part_description,
                                        main: true,
                                        important: true,
                                        truncate: true
                                    },
                                    {
                                        heading: 'Default Cost',
                                        field: rowData => '£'+rowData.cost,
                                        alignment: 'right',
                                        sizeToContent: true
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                rowData.part_locked ?
                                                    {name: 'Locked', icon: icons.lock, onClick: () => {}, disabled: !access.updateCost || rowData.cost_type === 'Sub Assembly'} :
                                                    {name: 'Update', icon: 'pencil-alt', link: { pathname: '/costs/update/' + rowData.part_id, state: {keywords, gridPagination}}, disabled: !access.updateCost || rowData.cost_type === 'Sub Assembly'},
                                                {name: 'View', icon: 'search', link: { pathname: '/costs/view/' + rowData.part_id, state: {keywords, gridPagination}}, disabled: !access.viewCost}
                                            ]
                                        }
                                    }
                                ]}
                                rows={this.state.searchResults}
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(CostSearch);
