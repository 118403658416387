import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import Image              from 'Components/Common/ImageWithError/ImageWithError';
import NumberField        from 'Components/Common/Inputs/NumberField';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import BasicSelect        from 'Components/Common/Selects/BasicSelect';
import QrScanWrapper      from 'Components/Warehouse/Common/Inputs/QrScanWrapper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';

import { colors } from 'Helpers/ColourHelper';

import { playSound } from 'Actions/Sounds/Sounds';

import { Button, Grid, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';


const initialState = {
    part:      null,
    isLoading: true,
    formData:  {
        partId: 0,
        qty:    0,
        notes:  null,
        reason: '',
    },
    qrError:   null,
    confirmationDialog: {
        open:    false,
        title:   '',
        message: '',
        success: null
    }
}

class StockReturn extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getParts()
    }

    getParts = () => {
        API.get('/worksOrders/returnReasons/all').then(reasons => {
            let parts = [];
            _.each(this.props.stage.instruction.parts, i => {
                parts.push({
                    ...i.part,
                    max:    parseFloat(i.saisp_qty * this.props.worksOrder.wo_qty),
                });
            });

            _.each(this.props.worksOrder.additionals, i => {
                if (i.wobas_wobs_id === this.props.stage.wobs_id) {
                    let idx = _.findIndex(parts, {part_id: i.part.part_id});
                    if (idx > -1) {
                        parts[idx].max += parseFloat(i.wobas_qty)
                    } else {
                        parts.push({
                            ...i.part,
                            max: parseFloat(i.wobas_qty),
                        })
                    }
                }
            });
            _.each(this.props.worksOrder.returns, i => {
                let idx = _.findIndex(parts, {part_id: i.part.part_id});
                if (parts[idx]) {
                    parts[idx].max -= parseFloat(i.wobsr_qty);
                }
            });

            this.setState({
                isLoading: false,
                parts:     _.filter(parts, i => i.max !== 0),
                reasons:   _.map(reasons.data, i => ({ value: i.worr_reason, label: i.worr_reason })),
            })
        });
    }

    handleScan = partNumber => {
        if (partNumber) {
            let part = _.find(this.state.parts, {part_number: partNumber});
            if (part && part.part_id !== this.props.worksOrder.wo_part_id) {
                this.setState({
                    part:   part,
                    formData: {
                        ...this.state.formData,
                        partId: part.part_id,
                    }
                })
            } else {
                this.setState({
                    qrError: 'Part cannot be returned',
                    part:    null,
                    formData:  {
                        ...this.state.formData,
                        partId: initialState.formData.partId,
                    }
                })
            }
        }
    }


    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                qty: e
            }
        })
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleChange = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            }
        })
    }

    deployConfirmation = (message, title, success) => {
        this.setState({
            confirmationDialog: {
                open: true,
                message: message,
                title: title,
                success: success
            }
        })
    }

    handleConfirmationClose = () => {
        this.setState({
            confirmationDialog: {
                ...this.state.confirmationDialog,
                open: false
            }
        })
    }

    _handleKeyDown = (e) => {
        if (e.keyCode === 0) return;
        e.preventDefault();
        switch (e.key) {
            case 'F1':
                if (this.state.confirmationDialog.open) {
                    this.handleConfirmationClose();
                }
            break;
            case 'F2':
                if (this.state.confirmationDialog.open) {
                    this.state.confirmationDialog.success();
                    this.handleConfirmationClose();
                }
            break;
            case 'F3':
                this.handleClose();
            break;
            case 'Enter':
                !(!parseFloat(this.state.formData.qty) || parseFloat(this.state.formData.qty) > parseFloat(this.state.part.max) || !this.state.formData.reason) && 
                    this.deployConfirmation('Are you sure you want to return these parts? note: Stock will be effected', 'Return Stock', this.handleSubmit)
            break;
            default:
                if (this.state.part){
                    if (_.isInteger(parseInt(e.key)) || e.key === '.'){
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                qty: this.state.formData.qty + e.key 
                            }
                        })
                    } else if (e.key === 'Backspace') {
                        this.setState({
                            formData: {
                                ...this.state.formData,
                                qty: this.state.formData.qty.slice(0, -1)
                            }
                        })
                    } 
                }
            break;
        }
	}

    handleSubmit = () => {
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/build/${this.props.worksOrder.build.wob_id}/stockReturn`, {
            part:       this.state.formData.partId,
            quantity:   this.state.formData.qty,
            stage:      this.props.stage.wobs_id,
            reason:     this.state.formData.reason,
        })
        .then(res => {
            this.setState({
                formData: initialState.formData
            },this.handleClose);
        })
    }

    render(){
        const { isLoading, part, qrError, formData, confirmationDialog, reasons} = this.state;
        const { classes } = this.props;

        if (isLoading) return (<LoadingCircle/>);

        return (
            <Grid container justify="center" className={`${classes.root} text-center`} spacing={1}>
                {part ? 
                    <>
                        <Grid item xs={12} >
                            <Grid container justify="center" className="text-center" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" style={{textAlign: 'center'}}>
                                        {part.part_number}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{textAlign: 'center'}}>
                                        {part.part_description}<br></br>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{textAlign: 'center'}}>
                                    <Image src={part.default_image?.file_path} className={classes.img} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.qtyRow}>
                                <NumberField
                                    id="qty"
                                    name="qty"
                                    label={`Quantity Returning * (Max: ${part.max})`}
                                    value={formData.qty}
                                    type="number"
                                    variant="outlined"
                                    onChange={(e)=>{this.handleChange('qty', e)}}
                                    margin="normal"
                                    inputProps={{
                                        min: 0,
                                        pattern: "\d*",
                                        max: part.max,
                                    }}
                                    granularity={0.1}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BasicSelect
                                    fullWidth
                                    label='Reason *'      
                                    onChange={(e)=>{this.handleChange('reason', e.value)}}                    
                                    value={formData.reason}
                                    options={reasons}
                                    noClear
                                />
                            </Grid>
                            {parseFloat(formData.qty) > parseFloat(part.max) && 
                                <Grid item xs={12}>
                                    <Typography variant="h5" style={{color: colors.red}}>Cannot Return More Than Was Picked</Typography>
                                </Grid>
                            }
                            <Grid item xs={12} className={classes.qtyRow}>
                                <Button fullWidth variant='contained' color='primary' disabled={!parseFloat(formData.qty) || parseFloat(formData.qty) > parseFloat(part.max) || !formData.reason} onClick={()=>{
                                    this.deployConfirmation('Are you sure you want to return these parts? note: Stock will be effected', 'Return Stock', this.handleSubmit)
                                }}>Confirm [Enter]</Button>
                            </Grid>
                        </Grid>
                    </> :
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h4">Scan Part</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" style={{color: colors.red}}>{qrError}</Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={12} style={{marginTop: 'auto'}}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={this.handleClose}
                    >Close [F3]</Button>
                </Grid>
                <QrScanWrapper
                    handleScan={this.handleScan}
                    parentScan={this._handleKeyDown}
                />
                {confirmationDialog.open &&
                    <ConfirmationDialog
                        open={confirmationDialog.open}
                        title={confirmationDialog.title}
                        message={confirmationDialog.message}
                        success={()=>{
                            confirmationDialog.success();
                            this.handleConfirmationClose();
                        }}
                        close={this.handleConfirmationClose}
                        isPda={true}
                    />
                }
            </Grid>
        )
    }
}

const styles = theme => ({
    root: {
        padding:    theme.spacing(1),
        height:     '100%',
    },
    qrRow: {
        justifyContent: 'flex-end',
        maxHeight:      '50px',
    },
    img: {
        maxHeight: 100,
        maxWidth:  100,
        width:     '100%'
    }, 
    qtyRow: {
        textAlign: 'center',
        marginTop: '1em'
    },
    actionText: {
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: 15,
        width: '80%',
        margin: '0 auto',
        textAlign: 'center',
        marginTop: '1em'
    },
});

function mapDispatchToProps(dispatch) {
    return {
        playSound: (type, state) => {
            dispatch(playSound(type, state));
        },
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(StockReturn));