import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import PartTypes from './PartTypes';
import BuildTypes from './BuildTypes';
import PartNumbers from './PartNumbers';
import PartTypesSortOrder from './PartTypesSortOrder';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class PartNumbering extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState         = clearPageState.bind(this);
        this.getInitialState        = getInitialState.bind(this);
        this.hasPageState           = hasPageState.bind(this);
        this.savePageState          = savePageState.bind(this);
        this.persistenceId          = 'Part:Numbering';
        this.state                  = this.getInitialState({value: 0});
    }

    handleChange = (event, value) => {
        this.setState({ value },() => this.savePageState());
    };

    render() {
        const { scrollToTop } = this.props;
        const { value } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Part Numbering 
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <AppBar position="static" style={{backgroundColor:'white'}} >
                        <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" textColor="primary">
                            <Tab label="Part Types" />
                            <Tab label="Build Types" />
                            <Tab label="Part Numbers" />
                            <Tab label="Part Types Sort Order" />
                        </Tabs>
                        </AppBar>
                        { value === 0 && <TabContainer><PartTypes scrollToTop={scrollToTop}/></TabContainer> }
                        { value === 1 && <TabContainer><BuildTypes scrollToTop={scrollToTop}/></TabContainer> }
                        { value === 2 && <TabContainer><PartNumbers/></TabContainer> }
                        { value === 3 && <TabContainer><PartTypesSortOrder/></TabContainer> }
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(PartNumbering);