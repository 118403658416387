import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import AutoCompleteSelect from "Components/Common/Selects/AutoCompleteSelect";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";
import { closeDialog } from "Actions/Dialog/Dialog";

const initialState = () => ({
    code: "",
    message: "",
    until: "",
});

const WildixSetStatus = ({ currCode, currMessage, currUntil, changeStatus }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState());

    const { code, message, until } = state;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        changeStatus(state);
        handleCancel();
    };

    const handleCancel = () => {
        dispatch(closeDialog());
    };

    const handleParseDate = (date) => {
        var regex = /(\d{1,2})\/(\d{1,2})\/(\d{4})\s(\d{1,2}):(\d{1,2})/;

        var matches = regex.exec(date);
        if (matches === null) {
            return null;
        }

        var year = parseInt(matches[3]);
        var month = parseInt(matches[2]) - 1; // Careful, month starts at 0!
        var day = parseInt(matches[1]);
        var hours = parseInt(matches[4]);
        var minutes = parseInt(matches[5]);
        var seconds = 0;

        const d = new Date(year, month, day, hours, minutes, seconds);

        return (
            d.getFullYear() +
            "-" +
            (d.getMonth() < 10 ? "0" : "") +
            (d.getMonth() + 1) +
            "-" +
            (d.getDate() < 10 ? "0" : "") +
            d.getDate() +
            " " +
            (d.getHours() < 10 ? "0" : "") +
            d.getHours() +
            ":" +
            (d.getMinutes() < 10 ? "0" : "") +
            d.getMinutes()
        );
    };

    useEffect(() => {
        if (code !== currCode) {
            setState((state) => ({
                ...state,
                message: "",
                until: "",
            }));
        }
    }, [code, currCode]);

    useEffect(() => {
        setState({
            code: currCode,
            message: currMessage,
            until: currUntil ? handleParseDate(currUntil) : null,
        });
    }, [currCode, currMessage, currUntil]);

    return (
        <Grid container spacing={3} className="mb-0">
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <AutoCompleteSelect
                                label="Status *"
                                onChange={(o) => handleChange({ target: { name: "code", value: o?.value ?? "" } })}
                                options={[
                                    { value: 0, label: "Available", icon: "phone", color: "#5da14d" },
                                    // {value: 1, label: 'Away', icon: 'phone-slash', color: '#ff9a00'},
                                    { value: 2, label: "Do Not Disturb", icon: "phone-slash", color: "#D61F26" },
                                ]}
                                formatOptionLabel={({ label, icon, color }) => (
                                    <Box pt={1} pb={1} pl={1}>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <FAIcon type="solid" icon={icon} color={color} size={15} button />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body2" component="div">
                                                    <strong>{label}</strong>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                variant="outlined"
                                value={code}
                                noClear
                                noMargin
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField InputLabelProps={{shrink: true}} name="message" label="Message" value={message} onChange={handleChange} margin="none" variant="outlined" fullWidth />
                    </Grid>
                    {code !== 0 && (
                        <Grid item xs={12}>
                            <TextField InputLabelProps={{shrink: true}} name="until" label="Until" value={until} onChange={handleChange} margin="none" variant="outlined" fullWidth type="datetime-local" />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <Button onClick={handleCancel} 
                            variant="outlined"
                            color="default" 
                            fullWidth 
                            size="large"
                        >
                            <FAIcon icon="times" size={15} button />
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs>
                        <Button disabled={code === "" || code === currCode} className="textLight" onClick={handleSubmit} variant="contained" color="primary" fullWidth size="large">
                            <FAIcon icon="check" size={15} buttonPrimary />
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default WildixSetStatus;
