import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
    Grid,
    Typography,
    Button,
    TextField,

} from '@material-ui/core';
import API from 'API';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { CLENAWARE_RED, CLENAWARE_GREEN } from 'Constants';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import { statusColour } from 'Helpers/ColourHelper';

const initialState = {
    searchResults: [],
    searchString: '',
}

class JobsSearch extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getSearchData()     
    };

    getSearchData = () => {
        this.setState({
            dataLoading: true
        }, () => {
            API.get('/engineers/jobs', { params: { searchString: this.state.searchString } }).then(result => {
                this.setState({
                    dataLoading: false
                });
                if(result.data) {
                    this.setState({
                        searchResults: result.data
                    });
                } else {
                    /* Error */
                }
            });
        });
    }

    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.getSearchData();
        });
    }
    
    resetSearch = () => {
        this.setState({
            searchString: ''
        }, 
        () => {
            this.getSearchData();
        });
    }
    
    render() {
        const { dataLoading } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Jobs Search
                    </Typography>
                </Grid> 
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <TextField id="nameSearch" label="Job Reference / Customer" fullWidth value={this.state.searchString} onChange={this.onSearchStringChange} />
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"                                   
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'id',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: dataLoading,
                                responsiveImportance: true
                            }}
                            columns={[
                                {
                                    actionsCustomHeader:    true,
                                    important: true,
                                    alignment: 'left',
                                    heading: 'Job Reference',
                                    actions: rowData => {
                                        return [
                                            { label: rowData?.ref,          link: `/engineers/jobs/view/${rowData?.id}`,        type:'plainLink'},
                                            { label: rowData?.order?.ref,   link: `/sales/order/view/${rowData?.order?.id}`,    type:'plainLink', class: "textSecondary"},
                                        ]
                                    }
                                },
                                {
                                    heading: 'Customer',
                                    field: rowData => rowData?.cust?.nm,
                                    hideInResponsiveDialog: true,
                                },
                                {
                                    heading: 'Job Sheets',
                                    field: rowData => rowData?.jobSheets?.count,
                                    hideInResponsiveDialog: true,
                                    alignment: 'center',
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => rowData?.st,
                                    hideInResponsiveDialog: true,
                                    alignment: 'center',
                                    cellProps: rowData => {
                                        return ({
                                            style: {
                                                color: statusColour(rowData?.st)
                                            }
                                        });
                                    },
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'View', icon: 'search', link: `/engineers/jobs/view/${rowData?.id}`},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(JobsSearch);