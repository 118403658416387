import React from 'react';
import API   from 'API';

import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import BankAccountList from './BankAccountsList';
// import PlaidLink       from './PlaidLink';

const styles = theme => ({
    settingsHeading: {
        paddingTop: theme.spacing(2)
    }
});

const initialState = {
        access: {
            addBankAccount: false
        },
        bankAccounts: [],
        linkToken: '',
        formErrors: {},
        data: [],
        isLoading: false
}

class AddBankAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.checkAccess();
        this.getBankAccounts();
        //this.createLinkToken();
    }

    getBankAccounts = () => {
        API.get('/accounts/banks')
        .then((res) =>  {
            if(res.data){
                this.setState({
                    bankAccounts: res.data
                });
            }
        });
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/add-bank-account'), 
        ])
        .then(([nameRes]) =>  {
            nameRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addBankAccount: nameRes.data.has_access
                    }
                });
        });
    }

    // createLinkToken = () => {
    //     API.post('/accounts/plaid/linkToken')
    //     .then((result) => {
    //         if(result.data){
    //             this.setState({
    //                 linkToken: result.data.link_token ? result.data.link_token : ''
    //             });
    //         }
    //     });
    // }

    render() {
        const { isLoading, access, linkToken, bankAccounts } = this.state;
        return(
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Banks / Cards
                    </Typography>
                </Grid>
                {isLoading ? (
                    <Grid item xs={12}>
                        <LoadingCircle />
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Grid container item spacing={3}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        {/* {access.addBankAccount && (
                                            <Grid item xs={12} style={{display: 'flex', justifyContent:'flex-end'}}>
                                                <PlaidLink 
                                                    token={linkToken}
                                                    getBankAccounts={this.getBankAccounts}
                                                    updateMode={false}
                                                />
                                            </Grid>
                                        )} */}
                                        <br></br>
                                        <BankAccountList 
                                            bankAccounts={bankAccounts}
                                            getBankAccounts={this.getBankAccounts}
                                        />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

export default (withStyles(styles)(AddBankAccount));