import React from 'react';

import { Divider, FormControl, FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { isTouchMonitor } from 'Functions/MiscFunctions';

import FALightIcon          from '../Icons/FontAwesome/FALightIcon';
import TouchScreenFileInput from './TouchScreenFileInput';

const style = theme => ({
    dropzone: {
        //height: 105,
        marginTop: 24,
        backgroundColor: '#fff',
        whiteSpace: 'nowrap', 
        //overflow: 'hidden', 
        textOverflow: 'ellipsis',
        textAlign: 'center',
        padding: '8px'
    },
    dropzoneArea: {
        border: '1px dashed rgb(187, 186, 186)',
        borderRadius: 4,
    },      
    dropzoneDisabled: {
        backgroundColor: 'rgba(187, 186, 186, 0.1)',
        color: 'rgba(0,0,0,0.5)',
        cursor: 'default'
    },    
    dropzoneHover: {
        color: '#fff',
        backgroundColor: '#0282C6'
    },  
    dropzoneHasFile: {
        backgroundColor: '#e8f5e9'
    },  
    fileInput: {
        display: 'none'
    }
});

const initialState = {
    fileKey: 1,
    dropzoneHover: false
}

class DragFileInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.fileInputRef = React.createRef();
    }

    componentDidMount = () => {
        this.setState({
            fileKey: Math.random().toString(36),
        });
    }

    handleChange = (drop, name, event) => {
        if(drop) event.preventDefault();     
        if(this.props.disabled) return;
        this.props.onChange(drop, name, event)
        this.setState({
            dropzoneHover: false
        });
    }

    handleClearFile = fileIdx => {
        this.props.cancelOnClick &&
            this.setState({
                fileKey: Math.random().toString(36),
            }, () => this.props.cancelOnClick(fileIdx));
    }
    
    handleDragOver = (event) => {
        event.preventDefault();        
        if(this.props.disabled) return;
        this.setState({
            dropzoneHover: true 
        });
    }

    handleDragLeave = () => {
        this.setState({
            dropzoneHover: false
        });
    }
    
    handleOpenDialog = () => {
        if(this.props.disabled) return;
        this.fileInputRef.current.click();
    }

    render() {
        const { fileKey, dropzoneHover } = this.state;
        const { classes, disableTouchMonitor } = this.props;

        if ( isTouchMonitor() && !disableTouchMonitor ) return (<TouchScreenFileInput {...this.props} />);

        return (
            <FormControl 
                        error={!!this.props.errorText}
                        fullWidth 
                        key={`file-${fileKey}`}
                        margin="normal"                         
            >                
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor={this.props.id} shrink={true}>
                            {this.props.label}
                        </InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <div
                            className={`${classes.dropzone} ${classes.dropzoneArea} ${dropzoneHover ? classes.dropzoneHover : ''} ${this.props.file ? classes.dropzoneHasFile : ''} ${this.props.disabled ? classes.dropzoneDisabled : ''}`}
                            onDragOver={this.handleDragOver}
                            onDragLeave={this.handleDragLeave}
                            onDrop={(event) => this.handleChange(true, this.props.name, event)} 
                        >
                            <div onClick={this.handleOpenDialog} style={{cursor: 'pointer'}}>
                                <input 
                                    className={`${classes.fileInput}`}
                                    disabled={this.props.disabled}
                                    file={this.props.file}        
                                    id={this.props.id}   
                                    name={this.props.name}
                                    onChange={(event) => this.handleChange(false, this.props.name, event)} 
                                    ref={this.fileInputRef}
                                    type="file"
                                    capture={this.props.capture}
                                    accept={this.props.accept}
                                />
                                {(dropzoneHover && (
                                    <FALightIcon icon="file-check" style={{marginBottom: 5}} buttonPrimary />
                                )) || (
                                    <FALightIcon icon={this.props.capture ? 'camera' : "upload"} style={{marginBottom: 5, color: this.props.disabled ? 'rgba(0,0,0,0.2)' : ''}} button/>
                                )}
                                {(this.props.placeholder && (                                 
                                    <Typography variant="body2">
                                        {this.props.placeholder}
                                    </Typography>
                                )) || (
                                    (dropzoneHover && (
                                        <Typography variant="body2">
                                            Drop file to upload it.
                                        </Typography>
                                    )) || (
                                        <Typography variant="body2">
                                            Choose a file or drag it here.
                                        </Typography>
                                    )
                                )}
                            </div>
                            <Divider style={{margin: '8px 0'}} />
                            {(this.props.file && this.props.file.name && (
                                <span onClick={this.handleClearFile}>
                                    {this.props.cancelOnClick && <FALightIcon icon='times-circle' size='small' style={{marginRight: 5}} button/> }
                                    {this.props.file.name}
                                </span>
                            )) || this.props.emptyText}
                        </div>
                        {this.props.errorText &&
                            <FormHelperText>{this.props.errorText}</FormHelperText>
                        }
                    </Grid>
                </Grid>
            </FormControl>
        )
    }
}

export default withStyles(style)(DragFileInput);