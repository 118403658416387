import { Grid } from "@material-ui/core";
import CiDataTable from "Components/Common/DataTables/CiDataTable";
import React from "react";
import _ from "lodash";
import AllIcon from "Components/Common/Icons/AllIcon";
import IconHelper from "Helpers/IconHelper";
import { colors } from "Helpers/ColourHelper";

export default function ({campaign}){

    let responses = [];

    _.each(campaign?.runs, r => {
        responses =
            [
                ...responses,
                ..._.filter(_.map(r.responses, i => ({
                    ...i,
                    run: r.ecr_run_count,
                })), i => i.email)
            ]
        
    });

    const [typeFilter] = React.useState('All');

    return (
        <Grid
            container
            spacing={2}
        >
{/*
            <InfoBox
                onClick={()=>setTypeFilter('All')}
                title='All'
                value={responses.length}
                icon={IconHelper.email}
                rowSize={4}
                color={typeFilter === 'All' && colors.green}
                border={typeFilter === 'All' && `1px solid ${colors.green}`}
            />
            <InfoBox
                onClick={()=>setTypeFilter('Sent')}
                title='Sent'
                value={_.filter(responses, i => i.email.email_status === 'Sent').length}
                icon={IconHelper.email}
                rowSize={4}
                color={typeFilter === 'Sent' && colors.orange}
                border={typeFilter === 'Sent' && `1px solid ${colors.orange}`}
            />
            <InfoBox
                onClick={()=>setTypeFilter('Opened')}
                title='Opened'
                value={_.filter(responses, i => i.email.email_status === 'Opened').length}
                icon={IconHelper.emailOpen}
                rowSize={4}
                color={typeFilter === 'Opened' && colors.green}
                border={typeFilter === 'Opened' && `1px solid ${colors.green}`}
            />
            <InfoBox
                onClick={()=>setTypeFilter('OptOut')}
                title='Unsubscribed'
                value={_.filter(responses, i => i.email.email_status === 'OptOut').length}
                icon={IconHelper.ban}
                rowSize={4}
                color={typeFilter === 'OptOut' && colors.red}
                border={typeFilter === 'OptOut' && `1px solid ${colors.red}`}
            />
*/}
            <Grid item xs={12}>
                <CiDataTable    
                    config={{
                        key: 'ecr_id',
                        style: i => ({
                            color: i.email.email_status === 'OptOut' ? colors.red : colors.black,
                        })
                    }}
                    rows={
                        _.filter(
                            responses,
                            i => typeFilter === 'All' || i.email.email_status === typeFilter
                        )
                    }
                    columns={[
                        {
                            heading: '',
                            field: i => 
                                <AllIcon
                                    icon={
                                        i.email.email_status === 'Opened' ? 
                                            IconHelper.emailOpen :
                                            i.email.email_status === 'OptOut' ?
                                                IconHelper.ban :
                                                IconHelper.email
                                    }
                                    color={
                                        i.email.email_status === 'Opened' ? 
                                            colors.green :
                                            i.email.email_status === 'OptOut' ?
                                                colors.red :
                                                colors.orange
                                    }
                                />,
                            alignment: 'center',
                            dataRef: 'email.email_status',
                            sizeToContent: true,
                        },
                        {
                            heading: 'Sent',
                            field: 'email.email_datetime_added',
                            dataRef: 'email.email_datetime_added',
                            fieldFormat: 'clenydate',
                            sizeToContent: true,
                            alignment: 'center',
                        },
                        {
                            heading: 'Opened',
                            field: 'email.email_datetime_opened',
                            dataRef: 'email.email_datetime_opened',
                            fieldFormat: 'clenydate',
                            sizeToContent: true,
                            alignment: 'center',
                        },
                        {
                            heading: 'Name',
                            field: 'contact.contact_name',
                            dataRef: 'contact.contact_name',
                            sizeToContent: true,
                        },
                        {
                            heading: 'Email',
                            field: 'email.email_to',
                            dataRef: 'email.email_to',
                        },
                        {
                            heading: 'Status',
                            field: i => i.email.email_status === 'OptOut' ? 'Unsubscribed' : i.email.email_status,
                            dataRef: 'email.email_status',
                            sizeToContent: true,
                        },
                        {
                            heading: 'Run',
                            field: 'run',
                            dataRef: 'run',
                            sizeToContent: true,
                            alignment: 'center',
                        }
                    ]}
                />
            </Grid>
        </Grid>
    );
}