import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import { Link, Redirect } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from '../../Common/Selects/AutoCompleteMultiSelect';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        listItem: '',
        nominalCode: '',
        type: ''
    },
    formErrors: [],
    nominalList: [],
    typeList: [],
    confirmationOpen: false,
    snackbarOpen: false,
    redirect: false
}

class UpdateDefaultPurchaseOrderList extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getDefaultList();
    }

    populateDropdowns = () => {
        Promise.all([ 
            API.get('/accounts/nominalCodes'), 
            API.get('/purchaseOrders/types'),
        ])
        .then(([nomRes, typeRes]) =>  {
            let nominalList = _.map(nomRes.data, (el) => {
                return _.assign({
                    value: el.nominal_id,
                    label: `${el.nominal_code} - ${el.nominal_name} (${el.category.nom_cat_name})`,
                });
            });
            let typeList = _.map(typeRes.data, (el) => {
                return _.assign({
                    value: el.po_type_id,
                    label: el.po_type_name
                });
            });
            this.setState({
                nominalList: nominalList,
                typeList: typeList
            });
        });
    }

    getDefaultList = () => {
        API.get(`/purchaseOrders/defaultList/${this.props.match.params.id}`)
        .then(result => {
            if (result.data.errors) {
                this.setState({ redirect: true });
            } else if(result.data) {
                this.populateDropdowns();
                let nominalCodes = [];
                _.map(result.data.nominal_codes, (nominal) => {
                    nominalCodes.push(nominal.nominal_id);
                });
                this.setState({
                    formData: {
                        ...this.state.formData,
                        listItem: result.data.po_list_description,
                        nominalCode: nominalCodes,
                        type: result.data.type.po_type_id
                    }
                });
            }
        });
    };

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        API.post(`/purchaseOrders/defaultList/${this.props.match.params.id}`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.getDefaultList();
                this.populateDropdowns();
            }
            this.props.scrollToTop();
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }
       
    render() {
        const { formErrors } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/purchase-order/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Update Default Purchase Order List
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                {formErrors && formErrors.generic && (
                                    <React.Fragment>
                                        <Typography component={"div"} style={{color: colors.red}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                <form noValidate autoComplete="off">
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteSelect 
                                            options={this.state.typeList} 
                                            label='Type *'
                                            onChange={this.handleSelectChange('type')}
                                            error={formErrors && formErrors['type'] && true}
                                            errorText={formErrors && formErrors['type']}
                                            value={this.state.formData.type}
                                        />
                                    </FormControl>
                                    <TextField
                                        id="listItem"
                                        name="listItem"
                                        label="Name *"
                                        value={this.state.formData.listItem}
                                        error={formErrors && formErrors['listItem'] && true}
                                        helperText={formErrors && formErrors['listItem']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <FormControl fullWidth margin="normal">
                                        <AutoCompleteMultiSelect 
                                            options={this.state.nominalList} 
                                            label='Nominal Codes *'
                                            value={this.state.formData.nominalCode}
                                            onChange={this.handleMultiSelectChange('nominalCode')}
                                            error={formErrors && formErrors['nominalCode'] && true}
                                            errorText={formErrors && formErrors['nominalCode']}
                                        />
                                    </FormControl>
                                    <div className='buttonRow'>
                                        <BackButton props={this.props} />
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary">
                                            Update
                                        </Button>
                                    </div>
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>    
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen} 
                        success={this.handleConfirmationSuccess} 
                        close={this.handleConfirmationClose} 
                        title="Update Default List Option?" 
                        message="Are you sure you want to update this default list option?"
                    />
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarOpen}
                        onClose={this.handleSnackbarClose}
                        message='You have successfully updated this default list option'
                    />
                </Grid>
            );
        }
        
    }
}

export default UpdateDefaultPurchaseOrderList;