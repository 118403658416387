import React, { Component } from 'react';
import API                  from 'API';
import { connect }          from 'react-redux';

import { Button, Grid, Typography } from '@material-ui/core';

import { closeDialog } from 'Actions/Dialog/Dialog';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import AllIcon       from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = {status:'Queued'}

class SinglePrintDialog extends Component {
    constructor(props){
        super(props);
        this.state = initialState;
        this.timeout = null;
    }

    componentDidMount() {
        this.getStatus();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    getStatus = () => {
        API.post(`/print/${this.props.id}/status`).then(res => {
            this.setState({status: res.data});
            if (res.data !== 'Completed' && res.data !== 'Error') { this.timeout = setTimeout(this.getStatus, 2000); }
            if (res.data === 'Completed') { this.timeout = setTimeout(this.handleClose, 1000); }
        });
    }

    handleClose = () => {
        if (this.props.onClose) { this.props.onClose(); }
        this.props.closeDialog();
    }

    render() {

        const { status } = this.state;

        return (
            <Grid container style={{justifyContent: 'center'}} spacing={2}>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant='h4'>{status === 'Sent' ? 'Received' : status}</Typography>
                </Grid>
                <Grid item xs={12}  style={{textAlign: 'center'}}>
                    {status === 'Completed' ? <AllIcon size='xxxlarge' icon={icons.true} color={colors.green} /> : (
                        status === 'Error' ? <AllIcon size='xxxlarge' icon={icons.false} color={colors.red} /> : <LoadingCircle />
                    )}
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button variant='outlined' onClick={this.handleClose}>Close</Button>
                </Grid>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        closeDialog: () => dispatch(closeDialog()),
    }
}
export default connect(null, mapDispatchToProps)(SinglePrintDialog)