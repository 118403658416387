import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SubCategoryIcon from '@material-ui/icons/SubdirectoryArrowRight';
import _ from 'lodash';
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Grid from '@material-ui/core/Grid';

class CalendarCategoryList extends React.Component {

  render() {
    const { categories, handleCheckChange, disabledCategoryAccess } = this.props;
    return (
        _.map(categories, (category,catIdx) => {
            return (
                <ExpansionPanel key={category.category_id}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{category.category_name}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{padding: 0}}>
                        {(category.children && category.children.length > 0 && (
                            <Grid container spacing={3}>
                                 <Grid item xs={12}>
                                    <List>
                                        {_.map(category.children, (childCategory, subCatIdx) => {
                                            return (
                                                <ListItem key={childCategory.category_id} disabled={childCategory.category_enabled === 'No'}>
                                                    <ListItemIcon
                                                    style={{
                                                        color: childCategory.category_colour
                                                    }}
                                                    >
                                                        <SubCategoryIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        disableTypography
                                                        primary={
                                                            <React.Fragment>
                                                            {childCategory.category_name}
                                                            </React.Fragment>
                                                        }
                                                    />
                                                    {disabledCategoryAccess &&
                                                        <ListItemSecondaryAction>
                                                            <Tooltip title='Enable Sub-Category'>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={childCategory.category_enabled === 'Yes'}
                                                                            onChange={handleCheckChange(catIdx, subCatIdx, childCategory.category_id)}
                                                                            value="disableImageFile"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </ListItemSecondaryAction>
                                                    }
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                        )) || (
                            <List>
                                <ListItem>
                                    <ListItemText primary={<em>No sub-categories to display</em>} />
                                </ListItem>
                            </List>
                        )}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );
        })
    );
  }
  
}

export default CalendarCategoryList;