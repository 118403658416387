import React, {Component}  from 'react';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, FormControl, Grid, Typography, Slide } from '@material-ui/core';
import SnackBar from './../../Common/SnackBars/SnackBar';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import * as lists from './../../../Helpers/SupplierHelper';
import AddressLookup from '../../Common/AddressLookup/AddressLookup';
import MaterialLink from '@material-ui/core/Link';
import SupplierContactCard from './SupplierContactCard';
import { withStyles } from '@material-ui/core/styles';
import Textarea from '../../Common/Inputs/Textarea';
import uuidv4 from 'uuid/v4';
import {colors} from 'Helpers/ColourHelper';

const styles = theme => ({
    paperGrey: {        
        margin: 0,
        padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px `,
        backgroundColor:'#fefefe'
    }, 
    addContact: {
        display: 'flex',
        alignItems: 'center',
        justify: 'center',
        padding: theme.spacing(1),
    },
    partCardMargin: {        
        marginTop: theme.spacing(3)
    }
});

const initialState = {
    formId: uuidv4(), 
    formData: {
        supplierType: '',
        companyName: '',
        tradingName: '',
        selectedAddress: null,
        addressOne: '',
        addressTwo:  '',
        addressThree:  '',
        city:  '',
        country:  243,
        county: '',
        postcode: '',
        showAddress: false,
        showAddressLookup: true,
        mainTelephone: '',
        fax: '',
        mainEmail:  '',
        website:  '',
        vat:  '',
        trackingUrl:  '',
        notes:  '',
        contacts: [
            {
                firstName: '',
                lastName: '',
                position: '',
                department: '',
                directDial: '',
                mobile: '',
                email: '',
                contactType: 'Person'
            }
        ],
        orderType: '',
        leadTime: '',
        paymentTerms: '',
        accountNumber: '',
        orderingNotes: '',

        onlineUrl: '',
        onlineUsername: '',
        onlinePassword: '',
        onlineNotes: '',
    },
    supplierTypeList: [],
    countyList: [],
    countryList: [],
    paymentTermList: [],
    leadTimeList: [],
    orderTypeList: [],
    access: {
        viewOnlineInformation: false
    },
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false,
    confirmationMsg: []
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class AddSupplier extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount(){
        this.populateDropdowns();
        this.checkAccess();
    }
    checkAccess = () => {
        API.get('/staff/my/access/check/view-supplier:online-information')
        .then((res) =>  {
            res.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        viewOnlineInformation: res.data.has_access
                    }
                });
        });
    }
    populateDropdowns = () => {
        let supplierTypeList = [];
        let countyList = [];
        let countryList = [];
        Promise.all([
            API.get('/suppliers/types/all'), 
            API.get('/misc/counties/all'),
            API.get('/misc/countries/all')
        ])
        .then(([typeRes, countyRes, countryRes]) =>  {
            if(typeRes.data){
                supplierTypeList = _.map(typeRes.data, el => {
                    return _.assign({
                        value: el.supp_types_id,
                        label: el.supp_types_name,
                    });
                });
            }
            if(countyRes.data){
                countyList = _.map(countyRes.data, el => {
                    return _.assign({
                        value: el.county_id,
                        label: el.county_name,
                    });
                });
            }
            if(countryRes.data){
                countryList = _.map(countryRes.data, el => {
                    return _.assign({
                        value: el.country_id,
                        label: el.country_name,
                    });
                });
            }
            this.setState({
                supplierTypeList: supplierTypeList,
                countyList: countyList,
                countryList: countryList
            });
        });
        let paymentTermList = _.map(lists.paymentTerms, el => {
            return _.assign({
                value: el,
                label: el,
            });
        });
        let leadTimeList = _.map(lists.leadTimes, el => {
            return _.assign({
                value: el,
                label: el,
            });
        });
        let orderTypeList = _.map(lists.orderTypes, el => {
            return _.assign({
                value: el,
                label: el,
            });
        });
        this.setState({
            leadTimeList: leadTimeList,
            paymentTermList: paymentTermList,
            orderTypeList: orderTypeList
        });
    }
    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }, 
        () => {
            if(fieldName == 'country'){
                this.setState({
                    formData: {
                        ...this.state.formData,
                        county: ''
                    }
                })
            }
        });
    }
    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    }
    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.post('/suppliers', this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false,
                        formData: {
                            ...this.state.formData,
                            showAddress: true,
                            showAddressLookup: false,
                        }
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        formId: (initialState.formId + 1),
                        snackbarOpen: true
                    });
                    this.populateDropdowns();
                }
                this.props.scrollToTop();
            });
        });
    }
    handleConfirmationOpen = (e) => {
        API.get('/suppliers/checkExistingNames', { params: { companyName: this.state.formData.companyName, tradingName: this.state.formData.tradingName } })
        .then((result) => {

            this.setState({
                confirmationOpen: true,
                confirmationMsg: result.data
            });
        });
    }
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }
    handleAddContact = () => {
        const item = initialState.formData.contacts[0];
        this.setState({
            formData:{
                ...this.state.formData,
                contacts: [...this.state.formData.contacts, item]
            }
        });
    }
    handleRemoveContact = (idx) => () => {
        const contacts = [...this.state.formData.contacts]
        contacts.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                contacts: contacts
            }
        })
    }
    handleContactTypeChange = idx => e => {
        const { value } = e.target;
        let newContacts = [...this.state.formData.contacts];
        newContacts[idx] = {
            ...newContacts[idx],
            contactType: value
        }
        this.setState({
            formData: {
                ...this.state.formData,
                contacts: newContacts
            }
        });
    }
    handleContactChange = idx => e => {
        const { name, value } = e.target;
        let newContacts =  [...this.state.formData.contacts];
        newContacts[idx] = {
            ...newContacts[idx],
            [name]: value
        };
        this.setState({
            formData: {
                ...this.state.formData,
                contacts: newContacts
            }
        });
    }
    handleSelectedAddress = e => {
        if(e && e.value){
            this.setState({
                formData: {
                    ...this.state.formData,
                    addressOne: e.value.line1,
                    addressTwo:  e.value.line2,
                    addressThree:  e.value.line3,
                    city:  e.value.town,
                    county: e.value.county,
                    postcode: e.value.postcode,
                    showAddress: true,
                    selectedAddress: e,
                }
            });
        }
        else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    selectedAddress: null
                }
            });
        }
    }
    handleManuallyEnterAddress = e => {
        e.preventDefault();
        
        this.setState({
            formData: {
                ...this.state.formData,
                showAddress:        this.state.formData.showAddress && this.state.formData.showAddressLookup ? true : !this.state.formData.showAddress,
                showAddressLookup:  this.state.formData.showAddress && this.state.formData.showAddressLookup ? false : !this.state.formData.showAddressLookup,
                addressOne:         this.state.formData.showAddress ? '' : this.state.formData.addressOne,
                addressTwo:         this.state.formData.showAddress ? '' : this.state.formData.addressTwo,
                addressThree:       this.state.formData.showAddress ? '' : this.state.formData.addressThree,
                city:               this.state.formData.showAddress ? '' : this.state.formData.city,
                county:             this.state.formData.showAddress ? '' : this.state.formData.county,
                postcode:           this.state.formData.showAddress ? '' : this.state.formData.postcode
            }
        });
    }
    render() {
        const { classes } = this.props;
        const { formErrors, confirmationMsg } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Supplier
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Typography variant="h6">
                                    Supplier Details
                                </Typography>
                                {formErrors && formErrors.generic && (
                                    <React.Fragment>
                                        <Typography component={"div"} style={{color: colors.red}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.supplierTypeList} 
                                        label='Supplier Type *'
                                        onChange={this.handleSelectChange('supplierType')}
                                        error={formErrors && formErrors['supplierType'] && true}
                                        errorText={formErrors && formErrors['supplierType']}
                                        value={this.state.formData.supplierType}
                                    />
                                </FormControl>
                                <TextField
                                    id="companyName"
                                    name="companyName"
                                    label="Company Name *"
                                    value={this.state.formData.companyName}
                                    error={formErrors && formErrors['companyName'] && true}
                                    helperText={formErrors && formErrors['companyName']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    id="tradingName"
                                    name="tradingName"
                                    label="Trading Name"
                                    value={this.state.formData.tradingName}
                                    error={formErrors && formErrors['tradingName'] && true}
                                    helperText={formErrors && formErrors['tradingName']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />

                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.countryList} 
                                        label='Country'
                                        onChange={this.handleSelectChange('country')}
                                        error={formErrors && formErrors['country'] && true}
                                        errorText={formErrors && formErrors['country']}
                                        value={this.state.formData.country}
                                    />
                                </FormControl>
                                {this.state.formData.country === 243 && this.state.formData.showAddressLookup && // United Kingdom
                                    <AddressLookup 
                                        key={this.state.formId}
                                        handleSelectedAddress={this.handleSelectedAddress}
                                        inlineLayout={false}
                                        value={this.state.formData.selectedAddress}
                                    />
                                }
                                <MaterialLink component="button" variant="caption" className='blueLink' onClick={this.handleManuallyEnterAddress}>
                                    {this.state.formData.showAddressLookup ? 'Manually enter address': 'Use address lookup (UK Only)' }
                                </MaterialLink>
                                {(this.state.formData.country !== 243 || (this.state.formData.country === 243 && this.state.formData.showAddress) || this.state.formData.showAddress)  &&
                                    <React.Fragment>
                                        <TextField
                                            id="addressOne"
                                            name="addressOne"
                                            label="Address Line 1 *"
                                            value={this.state.formData.addressOne}
                                            error={formErrors && formErrors['addressOne'] && true}
                                            helperText={formErrors && formErrors['addressOne']}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <Grid container item spacing={3}>
                                            <Grid item xs={12} lg={6}>
                                                <TextField
                                                    id="addressTwo"
                                                    name="addressTwo"
                                                    label="Address Line 2"
                                                    value={this.state.formData.addressTwo}
                                                    error={formErrors && formErrors['addressTwo'] && true}
                                                    helperText={formErrors && formErrors['addressTwo']}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <TextField
                                                    id="addressThree"
                                                    name="addressThree"
                                                    label="Address Line 3"
                                                    value={this.state.formData.addressThree}
                                                    error={formErrors && formErrors['addressThree'] && true}
                                                    helperText={formErrors && formErrors['addressThree']}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item spacing={3}>
                                            <Grid item xs={12} lg={6}>
                                                <TextField
                                                    id="city"
                                                    name="city"
                                                    label="City *"
                                                    value={this.state.formData.city}
                                                    error={formErrors && formErrors['city'] && true}
                                                    helperText={formErrors && formErrors['city']}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <TextField
                                                    id="postcode"
                                                    name="postcode"
                                                    label="Postcode"
                                                    value={this.state.formData.postcode}
                                                    error={formErrors && formErrors['postcode'] && true}
                                                    helperText={formErrors && formErrors['postcode']}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        {this.state.formData.country === 243 &&
                                            <TextField
                                                id="county"
                                                name="county"
                                                label="County"
                                                value={this.state.formData.county}
                                                error={formErrors && formErrors['county'] && true}
                                                helperText={formErrors && formErrors['county']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                        }
                                    </React.Fragment>
                                }

                                <TextField
                                    id="mainTelephone"
                                    name="mainTelephone"
                                    label="Main Telephone *"
                                    value={this.state.formData.mainTelephone}
                                    error={formErrors && formErrors['mainTelephone'] && true}
                                    helperText={formErrors && formErrors['mainTelephone']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    id="fax"
                                    name="fax"
                                    label="Main Fax Number"
                                    value={this.state.formData.fax}
                                    error={formErrors && formErrors['fax'] && true}
                                    helperText={formErrors && formErrors['fax']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    id="mainEmail"
                                    name="mainEmail"
                                    label="Main Email Address"
                                    value={this.state.formData.mainEmail}
                                    error={formErrors && formErrors['mainEmail'] && true}
                                    helperText={formErrors && formErrors['mainEmail']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    id="website"
                                    name="website"
                                    label="Website Address"
                                    value={this.state.formData.website}
                                    error={formErrors && formErrors['website'] && true}
                                    helperText={formErrors && formErrors['website']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    id="vat"
                                    name="vat"
                                    label="VAT Number"
                                    value={this.state.formData.vat}
                                    error={formErrors && formErrors['vat'] && true}
                                    helperText={formErrors && formErrors['vat']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={3}>
                        {this.state.formData.contacts.map((item, idx) => (
                            <Grid item xs={12} key={idx}>                            
                                <SupplierContactCard 
                                    classes={classes}
                                    idx={idx}
                                    data={item}
                                    handleRemove={this.handleRemoveContact}
                                    handleContactChange={this.handleContactChange}
                                    handleContactTypeChange={this.handleContactTypeChange}
                                    formErrors={formErrors}
                                />
                            </Grid>
                        ))} 
                        <Grid item xs={12} md={6} lg={4}>
                            <PaddedPaper className={`${classes.paperGrey} ${classes.addContact}`} onClick={this.handleAddContact}>
                                <FALightIcon icon='plus' noMargin button style={{width: '100%', height: '74px', color: '#ddd'}} />
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                </Grid>                
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Typography variant="h6">
                                    Ordering Information
                                </Typography>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.orderTypeList} 
                                        label='How to Order *'
                                        value={this.state.formData.orderType}
                                        onChange={this.handleSelectChange('orderType')}
                                        error={formErrors && formErrors['orderType'] && true}
                                        errorText={formErrors && formErrors['orderType']}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.leadTimeList} 
                                        label='General Lead Time *'
                                        value={this.state.formData.leadTime}
                                        onChange={this.handleSelectChange('leadTime')}
                                        error={formErrors && formErrors['leadTime'] && true}
                                        errorText={formErrors && formErrors['leadTime']}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.paymentTermList} 
                                        label='Payment Terms *'
                                        value={this.state.formData.paymentTerms}
                                        onChange={this.handleSelectChange('paymentTerms')}
                                        error={formErrors && formErrors['paymentTerms'] && true}
                                        errorText={formErrors && formErrors['paymentTerms']}
                                    />
                                </FormControl>
                                <TextField
                                    id="accountNumber"
                                    name="accountNumber"
                                    label="Account Number"
                                    value={this.state.formData.accountNumber}
                                    error={formErrors && formErrors['accountNumber'] && true}
                                    helperText={formErrors && formErrors['accountNumber']}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    fullWidth
                                />
                                <Textarea
                                    id="orderingNotes"
                                    name="orderingNotes"
                                    label="Ordering Notes"
                                    value={this.state.formData.orderingNotes}
                                    rows={10}
                                    error={formErrors && formErrors['orderingNotes']}
                                    onChange={this.handleChange}
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                {this.state.access.viewOnlineInformation &&
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <form noValidate autoComplete="off">
                                    <Typography variant="h6">
                                        Online Information
                                    </Typography>
                                    <TextField
                                        id="onlineUrl"
                                        name="onlineUrl"
                                        label="Online URL"
                                        value={this.state.formData.onlineUrl}
                                        error={formErrors && formErrors['onlineUrl'] && true}
                                        helperText={formErrors && formErrors['onlineUrl']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="onlineUsername"
                                        name="onlineUsername"
                                        label="Online Username"
                                        value={this.state.formData.onlineUsername}
                                        error={formErrors && formErrors['onlineUsername'] && true}
                                        helperText={formErrors && formErrors['onlineUsername']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="onlinePassword"
                                        name="onlinePassword"
                                        label="Online Password"
                                        value={this.state.formData.onlinePassword}
                                        error={formErrors && formErrors['onlinePassword'] && true}
                                        helperText={formErrors && formErrors['onlinePassword']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <Textarea
                                        id="onlineNotes"
                                        name="onlineNotes"
                                        label="Notes"
                                        value={this.state.formData.onlineNotes}
                                        rows={10}
                                        error={formErrors && formErrors['onlineNotes']}
                                        onChange={this.handleChange}
                                    />
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                }
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Typography variant="h6">
                                    General
                                </Typography>
                                <Textarea
                                    id="notes"
                                    name="notes"
                                    label="Notes"
                                    value={this.state.formData.notes}
                                    rows={10}
                                    error={formErrors && formErrors['notes']}
                                    onChange={this.handleChange}
                                />
                                <div className='buttonRow'>
                                    <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.isLoading}
                                           >
                                        Add
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={this.state.confirmationOpen} 
                    TransitionComponent={Transition}
                    onClose={this.handleConfirmationClose} 
                    scroll="body"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>Add a New Supplier?</DialogTitle>
                    <DialogContent>
                        <DialogContentText component="div">
                            Are you sure you want to add this new supplier?
                            {confirmationMsg.length > 0 &&
                                <>
                                    <br></br><br></br>
                                    <Typography variant="body1"><b>Similar existing suppliers:</b></Typography>
                                    {confirmationMsg.map((supplier, idx) => {
                                        return (<Typography variant="body2" key={idx}>{supplier.supp_company_name}{supplier.supp_trading_name && ' | ' + supplier.supp_trading_name}</Typography>)
                                    })}
                                </>
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirmationClose} variant="outlined" color="default">Cancel</Button>
                        <Button onClick={this.handleConfirmationSuccess} autoFocus variant="contained" color="primary">Yes</Button>
                    </DialogActions>
                </Dialog>
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new supplier'
                />
            </Grid>
        );
    }
}

export default withStyles(styles)(AddSupplier);