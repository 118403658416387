import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import _ from 'lodash';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const docTypes = [
    'Supplier Documents',
    'Customer Documents',
    'Engineer Documents',
    'Other Documents'
]

export default function ({quotationId, onClose}){
    const dispatch = useDispatch();
    const confirmSubmit = () => dispatch(deployConfirmation('Are your sure you want to upload this file', 'Upload File?', handleSubmit));
    const [file, setFile] = useState(null);
    const [type, setType] = useState(null);
    const handleFileChange = (drop, name, event) => {
        const file = drop === true ? event?.dataTransfer?.files[0] : event?.target?.files[0];
        setFile(file ?? null);
    }
    const handleSubmit = () => {
        let formData = new FormData();
        formData.append('type', type);
        formData.append('file', file);
        API.post(`/sales/quotations/${quotationId}/files`, formData)
        .then(onClose)
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <AutoCompleteSelect
                    value={type}
                    onChange={e => setType(e?.value)}
                    label="Type *"
                    options={_.map(docTypes, d => ({value: d, label: d}))}
                    fullWidth
                    noClear
                />
            </Grid>
            <Grid item xs={12}>
                <DragFileInput 
                    id="file"
                    name='file'
                    label="Upload File:"
                    file={file}
                    onChange={handleFileChange}
                    emptyText="No file selected"
                    noClear
                />
            </Grid>
            <Grid item xs={12} className='buttonRow'>
                <Button
                    onClick={onClose}
                    variant="outlined"
                >Close</Button>
                <Button
                    color='primary'
                    variant="contained"
                    onClick={confirmSubmit}
                    disabled={!file || !type}
                >Submit</Button>
            </Grid>
        </Grid>
    )
}