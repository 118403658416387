import { Box, Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import API from 'API';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import MediaTable from 'Components/Common/DataTables/MediaTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { colors } from 'Helpers/ColourHelper';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deployConfirmation as _deployConfirmation } from 'Actions/Confirmation/Confirmation';

export default function (props){

    const { repair } = props;  

    const [ repairStatus, setRepairStatus ] = useState();
    const [ linkedQuote, setLinkedQuote ] = useState(null);
    const [ customerApproval, setCustomerApproval ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ cr, setCr ] = useState(null);
    const [ parts, setParts ] = useState([]);
    const [ quotes, setQuotes ] = useState([]);

    let dispatch = useDispatch();
    const deployConfirmation = (content, title, onConfirm) => { dispatch(_deployConfirmation(content, title, onConfirm)) }

    const submit = () => {
        setIsLoading(true);
        API.post(`/customerReturns/repairOrders/${props.rogId}/approve`, {
            status: repairStatus,
            customerApproval: customerApproval,
            quote_id: linkedQuote
        })
        .then(res => {
            setIsLoading(false);
            props.onClose();
        })
    }

    if (isLoading && !cr) {
        Promise.all([
            API.get(`/customerReturns/returns/${repair.customer_return_outcome.cro_cr_id}`),
            API.get('/parts/all', { params: { use: 'partSearch', withStock: true } }),
            API.get(`/customers/${repair.customer_return_outcome.customer_return.cr_cust_id}/sales/quotations`, {params: {for: 'customerReturn'}}),
        ]).then(res => {
            setCr(res[0].data);
            setParts(res[1].data);
            setQuotes(_.map(res[2].data, q => ({
                value: q.quote_id,
                label: `${q.full_reference} ${q.quote_status === 'Ordered' ? `(Order Created)` : ''}`
            })));
            setIsLoading(false);
        })
    }
    if (isLoading) return (<LoadingCircle />)


    return (
        <Grid container spacing={1}>
            <Grid item xs={12} >
                <Box>
                    <Box
                        style={{
                            border: '1px solid rgb(224, 224, 224)',
                            padding: '1.5em'
                        }}
                    >
                        <Grid container spacing={3} style={{alignItems: 'stretch'}}>
                            <Grid item xs={5}>
                                <Grid container item spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Return Date</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {moment(cr.cr_date).format("DD/MM/YYYY")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Created By</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cr.cr_staff_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Return For</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {(cr.cr_outcome || cr.cr_expected_outcome) === "refund" || (cr.cr_outcome || cr.cr_expected_outcome) === "Refund" ? "Credit Note" : (cr.cr_outcome || cr.cr_expected_outcome)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={5}>
                                <Grid container item spacing={1}>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Reference</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cr.cr_reference+'-'+(cr.cr_reference_amendment < 10 ? '0'+cr.cr_reference_amendment : cr.cr_reference_amendment)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Order Reference</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cr.order.order_reference+'-'+cr.order.order_reference_amendment}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={5}>
                                <Grid container item spacing={1}>
                                    <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                                        Customer
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Name</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cr.cr_cust_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Address</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cr.cr_address.split('\n').map((item, key) => {
                                                return  <span key={key}>
                                                            {item}{cr.cr_address.split('\n').length === key+1 ? null : <br/>}
                                                        </span>
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Contact</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cr.cr_contact_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Phone Number</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cr.cr_contact_phone} {cr.cr_contact_phone && cr.cr_contact_mobile ? ' / ' : null} {cr.cr_contact_mobile}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Email</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cr.cr_contact_email	}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={5}>
                                <Grid container item spacing={1}>
                                    <Grid item xs={12} style={{backgroundColor:'#0282C6',color:'white', textAlign: 'center'}}>
                                        Collection Address
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Name</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cr.cr_collection_address_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Address</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cr.cr_collection_address.split('\n').map((item, key) => {
                                                return  <span key={key}>
                                                            {item}{cr.cr_collection_address.split('\n').length === key+1 ? null : <br/>}
                                                        </span>
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Contact</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cr.cr_collection_address_contact_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Phone Number</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cr.cr_collection_address_contact_phone} {cr.cr_collection_address_contact_phone && cr.cr_collection_address_contact_mobile ? ' / ' : null} {cr.cr_collection_address_contact_mobile}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>Email</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {cr.cr_collection_address_contact_email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}>
                                <CiDataTable
                                    config={{
                                        key: 'cro_id',
                                        dropRow: {
                                            droppable: true,
                                            columns:[
                                                {
                                                    colSpan: 4,
                                                    field: i => {
                                                        let inspections = [];
                                                        _.each(_.filter(cr.collection.details, {crcd_part_id: i.ro_part_id}), d => {
                                                            _.each(d.inspections, inspection => {
                                                                inspections.push(inspection)
                                                            })
                                                        })
                                                        let inspection = inspections[_.findIndex(_.filter(repair.repair_orders, ro => ro.part.part_id === i.ro_part_id), {ro_id: i.ro_id})];
                                                        if (i.ro_sn && i.ro_sn !== '[]') {
                                                            inspections = _.find(inspections, {cri_sn: i.ro_sn})
                                                        }
                                                        if (i.ro_wvl && i.ro_wvl !== '[]') {
                                                            inspections = _.find(inspections, {cri_wvl: i.ro_wvl})
                                                        }
                                                        if (!inspection) {
                                                            inspection = _.first(inspections);
                                                        }

                                                        let setParts = [];
                                                        let setServices = [];
                                                        
                                                        if (inspection.cri_parts){
                                                            _.each(JSON.parse(inspection.cri_parts), p => {
                                                                let part = _.find(parts, {part_id: p.part});
                                                                if (part?.stockable){
                                                                    setParts.push({
                                                                        ...part,
                                                                        qty: p.qty,
                                                                    })
                                                                } else {
                                                                    setServices.push({
                                                                        ...part,
                                                                        qty: p.qty,
                                                                    })
                                                                }
                                                            });
                                                        }
                                                        
                                                        return (
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <Grid container spacing={1}>
                                                                        <Grid item xs={3}>
                                                                            <strong>
                                                                                Condition:
                                                                            </strong>
                                                                        </Grid>
                                                                        <Grid item xs={9}
                                                                            style={{
                                                                                color: (
                                                                                    (inspection.cri_condition_grade === 'fail' && colors.red) ||
                                                                                    (
                                                                                        (inspection.cri_condition_grade === 'pass' && colors.green) ||
                                                                                        colors.orange    
                                                                                    )
                                                                                )
                                                                            }}
                                                                        >
                                                                            {
                                                                                inspection && inspection.cri_condition
                                                                            }
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <strong>
                                                                                Notes:
                                                                            </strong>
                                                                        </Grid>
                                                                        <Grid item xs={9}
                                                                            style={{
                                                                                whiteSpace: 'pre-wrap'
                                                                            }}
                                                                        >
                                                                            {
                                                                                inspection && inspection.cri_notes
                                                                            }
                                                                        </Grid>
                                                                        {(setParts.length > 0 || setServices.length > 0) &&
                                                                            <Grid item xs={12}>
                                                                                {setParts.length > 0 &&
                                                                                    <ExpansionPanel>
                                                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                                            Recommended Parts
                                                                                        </ExpansionPanelSummary>
                                                                                        <ExpansionPanelDetails>
                                                                                            <CiDataTable 
                                                                                                config={{
                                                                                                    key: 'part_id',
                                                                                                }}
                                                                                                columns={[
                                                                                                    {
                                                                                                        heading:       '',
                                                                                                        field:         'default_image.file_path',
                                                                                                        fieldFormat:   'image',
                                                                                                        sizeToContent: true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading:      'Part Number',
                                                                                                        field:        'part_number',
                                                                                                        sizeToContent: true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading:      'Part Description',
                                                                                                        field:        'part_description',
                                                                                                        truncate:     true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading:      'Qty',
                                                                                                        field:        'qty',
                                                                                                        sizeToContent: true,
                                                                                                        fieldFormat: 'decimal:3'
                                                                                                    }
                                                                                                ]}
                                                                                                rows={setParts}
                                                                                            />
                                                                                        </ExpansionPanelDetails>
                                                                                    </ExpansionPanel>
                                                                                }
                                                                                {setServices.length > 0 &&
                                                                                    <ExpansionPanel>
                                                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                                            Recommended Services
                                                                                        </ExpansionPanelSummary>
                                                                                        <ExpansionPanelDetails>
                                                                                            <CiDataTable 
                                                                                                config={{
                                                                                                    key: 'part_id',
                                                                                                }}
                                                                                                columns={[
                                                                                                    {
                                                                                                        heading:       '',
                                                                                                        field:         'default_image.file_path',
                                                                                                        fieldFormat:   'image',
                                                                                                        sizeToContent: true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading:      'Part Number',
                                                                                                        field:        'part_number',
                                                                                                        sizeToContent: true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading:      'Part Description',
                                                                                                        field:        'part_description',
                                                                                                        truncate:     true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading:      'Qty',
                                                                                                        field:        'qty',
                                                                                                        sizeToContent: true,
                                                                                                        fieldFormat: 'decimal:3'
                                                                                                    }
                                                                                                ]}
                                                                                                rows={setServices}
                                                                                            />
                                                                                        </ExpansionPanelDetails>
                                                                                    </ExpansionPanel>
                                                                                }
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                {/* <Grid item xs={4}>
                                                                    <Grid container spacing={1}>
                                                                        {setParts.length > 0 &&
                                                                            <Grid item xs={12}>
                                                                                <PaddedPaper>
                                                                                    <Grid container spacing={1}>
                                                                                        <Grid item xs={12}>
                                                                                            <strong>Recommended Parts</strong>
                                                                                        </Grid>
                                                                                        {_.map(setParts, p => 
                                                                                            <>
                                                                                                <Grid item xs={9}>
                                                                                                    {p.part_number}
                                                                                                </Grid>
                                                                                                <Grid item xs={3}>
                                                                                                    * {parseFloat(p.qty).toFixed(2)}
                                                                                                </Grid>
                                                                                            </>
                                                                                        )}
                                                                                    </Grid>
                                                                                </PaddedPaper>
                                                                            </Grid>
                                                                        }
                                                                        {setServices.length > 0 &&
                                                                            <Grid item xs={12}>
                                                                                <PaddedPaper>
                                                                                    <Grid container spacing={1}>
                                                                                        <Grid item xs={12}>
                                                                                            <strong>Recommended Services</strong>
                                                                                        </Grid>
                                                                                        {_.map(setServices, p => 
                                                                                            <>
                                                                                                <Grid item xs={9}>
                                                                                                    {p.part_number}
                                                                                                </Grid>
                                                                                                <Grid item xs={3}>
                                                                                                    * {parseFloat(p.qty).toFixed(2)}
                                                                                                </Grid>
                                                                                            </>
                                                                                        )}
                                                                                    </Grid>
                                                                                </PaddedPaper>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid> */}
                                                                {inspection.files.length > 0 &&
                                                                    <Grid item xs={12}>
                                                                        <MediaTable
                                                                            media='file_path'
                                                                            config={{
                                                                                key: 'crif_id',
                                                                                itemPerRow: 3,
                                                                            }}
                                                                            items={inspection.files}
                                                                        />
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        )
                                                    },
                                                }
                                            ]
                                        }
                                    }}
                                    columns={[
                                        {
                                            heading: '',
                                            field: 'part.default_image.file_path',
                                            fieldFormat: 'image',
                                            sizeToContent: true,
                                            alignment: 'center',
                                        },
                                        {
                                            heading: 'Part',
                                            field: ({part}) => <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant='body2'>{part.part_number}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant='caption'>{part.part_description}</Typography>
                                                </Grid>
                                            </Grid>
                                        },
                                        {
                                            heading: 'Warranty Void Label',
                                            field: ({ro_wvl}) => ((ro_wvl) !== '[]') && (ro_wvl),
                                        },
                                        {
                                            heading: 'Serial Number',
                                            field: ({ro_sn}) => ((ro_sn) !== '[]') && (ro_sn),
                                        },
                                    ]} 
                                    rows={repair.repair_orders}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <AutoCompleteSelect
                    label='Approve Repair *'
                    options={[
                        {
                            value: 'Approve Repair',
                            label: 'Approve Repair'
                        },
                        {
                            value: 'Write Off',
                            label: 'Write Off'
                        }
                    ]}
                    fullWidth
                    value={repairStatus}
                    onChange={e => setRepairStatus(e.value)}
                    noClear
                />
            </Grid>
            {
                repairStatus === 'Approve Repair' &&
                <Grid item xs={12}>
                    <AutoCompleteSelect
                        label='Is Customer Approval Needed? *'
                        options={
                            _.sumBy(cr.collection.details, ro => _.sumBy(ro.inspections, i => i.cri_parts ? JSON.parse(i.cri_parts).length : 0)) > 0 ? 
                            [
                                {
                                    value: 'No',
                                    label: 'No Approval Required'
                                }, 
                                {
                                    value: 'New Quote',
                                    label: 'Generate New Quote'
                                },
                                {
                                    value: 'Existing Quote',
                                    label: 'Link Existing Quote'
                                }
                            ] :
                            [
                                {
                                    value: 'No',
                                    label: 'No Approval Required / No Parts Required'
                                }
                            ]
                        }
                        value={customerApproval}
                        onChange={e => setCustomerApproval(e.value)}
                        fullWidth
                        noClear
                    />
                </Grid>
            }
            {
                customerApproval === 'Existing Quote' &&
                <>
                    <Grid item xs={12}>
                        <AutoCompleteSelect
                            label='Quotation *'
                            options={quotes}
                            value={linkedQuote}
                            onChange={e => setLinkedQuote(e.value)}
                            fullWidth
                            noClear
                        />
                    </Grid>
                </>
            }
            
            <Grid item xs={12} className='buttonRow'>
                <Button
                    onClick={props.onClose}
                    variant='outlined'
                >Close</Button>
                {repairStatus === 'Approve Repair' ? ( (customerApproval === 'New Quote') ? 
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() =>
                            {
                                props.onClose();
                                props.history.push(`/sales/quotation/add/repair/${repair.rog_id}`)}
                            }
                    >
                        Generate Quote
                    </Button>
                    :
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={
                            !repairStatus ||
                            (
                                repairStatus === 'Approve Repair' &&
                                !customerApproval
                            ) ||
                            (
                                customerApproval === 'Existing Quote' &&
                                !linkedQuote
                            )
                        }
                        onClick={ () =>
                            deployConfirmation(
                                'Are you sure you want to approve this repair?',
                                'Approve Repair',
                                submit
                            )
                        }
                    >
                        Approve
                    </Button> ) :
                    repairStatus === 'Write Off' &&
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={()=>props.writeOff(props.cr)}
                    >
                        Write Off
                    </Button>
                }
            </Grid>
        </Grid>
    )
}