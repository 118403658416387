import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import moment from 'moment';

class ViewStaffLastLogin extends React.Component {
    render() {
        const { dateTime, isLoading } = this.props;
        const formattedDateTime = ((dateTime && dateTime !== '0000-00-00 00:00:00') && `${moment(new Date(dateTime)).format("dddd, MMMM Do YYYY [at] HH:mmA")}`) || 'Never';
        return ( 
            <React.Fragment>
                {(isLoading && (
                    <LoadingCircle />
                )) || (   
                    <Grid container alignItems='center' spacing={1}>
                        <Grid item align='center'>
                            <FALightIcon icon='unlock' style={{marginLeft: 8}} size='large' />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                Last login
                            </Typography>  
                            <Typography variant="body2">
                                {formattedDateTime}
                            </Typography>  
                        </Grid>         
                    </Grid>
                )}
            </React.Fragment>
        )
    }
}

export default ViewStaffLastLogin;