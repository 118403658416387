import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { find } from 'lodash';
import API from "API";

import EmailDialog from 'Components/Common/Dialogs/EmailDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import {colors} from 'Helpers/ColourHelper';

import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';

const useStyles = makeStyles(theme => ({
    button: {
        textTransform: 'capitalize'
    },
    buttonRow: {
        float: 'right',
        margin: 0
    }
}));

const initialState = {
    order: [],
    isLoading: true,
    enablePayment: false,
    enableDespatch: false
};

const OrderEmailPrevDocsDialog = ({ orderId }) => {

    const [state, setState] = useState(initialState),
        { order, isLoading, enablePayment } = state,
        classes = useStyles(),
        dispatch = useDispatch(),
        handleDialogClose = () => dispatch(closeDialog());

    const handleEmail = (type, id) => {
        let title = '', dialogType = 'success';

        let accountsEmail = false;
        switch(type) {
            case 'quote':
                title = 'Quotation'
            break;
            case 'acknowledgement':
                title = 'Order Acknowledgment';
            break;
            case 'confirmation':
                title = 'Order Confirmation';
            break;
            case 'awaiting-payment':
                title = 'Order Awaiting Payment';
            break;
            case 'payment-confirmed':
                title = 'Order Payment Confirmation';
            break;
            case 'completed':
                title = 'Order Completed';
            break;
            case 'declined':
                title = 'Order Declined Confirmation';
                dialogType = 'warning';
            break;
            case 'cancelled':
                title = 'Order Cancelled Confirmation';
                dialogType = 'warning';
            break;
            case 'despatched':
                title = 'Delivery Note';
            break;
            case 'invoice':
                title = 'Invoice';
                accountsEmail = true;

            break;
            case 'commercialInvoice':
                title = 'Commercial Invoice';
            break;
            case 'pickingDocuments':
                title = 'Linked Part Files';
            break;
            default:
                title = 'Order';
            break;
        }

        const dialog = <EmailDialog
                            customerId={order.order_cust_id}
                            customerContactId={order.order_cust_contact_id}
                            type={type}
                            id={id} 
                            accountsEmail={accountsEmail}
                            pickingDocuments={order.pickingDocuments}
                        />
        dispatch(deployDialog(dialog, `You Are About To Email - ${title}`, dialogType));
    }

    useEffect(() => {
        API.get(`/sales/orders/${orderId}`).then((result) => {
            if (result.data) {
                let enablePayment = result.data?.payment_term?.payment_term_upfront_payment;
                let enableDespatch = result.data?.order_despatch_date ? true : false;
                setState((state) => ({
                    ...state,
                    order: result.data,
                    isLoading: false,
                    enablePayment,
                    enableDespatch
                }));
            }
        });
    }, [orderId]);

    return(
        <Grid container spacing={3}>
            {isLoading ? (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            ) : (
                <>
                    <Grid item xs={12}>
                        <Typography variant="caption">
                            Quotes
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            startIcon={<FALightIcon icon="file" style={{color: '#D9A200'}} />}
                            className={classes.button}
                            onClick={() => handleEmail('quote', order.order_quote_id)}
                            disabled={order.order_quote_id !== 0 ? false : true}
                        >
                            Original Quotation
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption">
                            Orders
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            startIcon={<FALightIcon icon="file-user" style={{color: '#F39C12'}} />}
                            className={classes.button}
                            onClick={() => handleEmail('acknowledgement', order.order_id)}
                        >
                            Order Acknowledgement
                        </Button>
                    </Grid>
                    {!find(order.activity, ['order_log_type', 'Declined']) && !find(order.activity, ["order_log_type","Cancelled"]) &&
                        <Grid item xs={12} md={4}>
                            <Button
                                startIcon={<FALightIcon icon="user-check" style={{color: '#F39C12'}} />}
                                className={classes.button}
                                onClick={() => handleEmail('confirmation', order.order_id)}
                                disabled={find(order.activity, ['order_log_type', 'Approved']) ? false : true}
                            >
                                Order Confirmation
                            </Button>
                        </Grid>
                    }
                    {enablePayment ?
                        <>
                            <Grid item xs={12} md={4}>
                                <Button
                                    startIcon={<FALightIcon icon="credit-card" style={{color: '#F39C12'}} />}
                                    className={classes.button}
                                    onClick={() => handleEmail('awaiting-payment', order.order_id)}
                                    disabled={find(order.activity, ['order_log_type', 'Confirmed']) ? false : true}
                                >
                                    Order Awaiting Payment
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Button
                                    startIcon={<FALightIcon icon="receipt" style={{color: '#F39C12'}} />}
                                    className={classes.button}
                                    onClick={() => handleEmail('payment-confirmed', order.order_id)}
                                    disabled={find(order.activity, ['order_log_type', 'Payment Made']) ? false : true}
                                >
                                    Order Payment Confirmation
                                </Button>
                            </Grid>
                        </>
                    :''}
                    {find(order.activity, ['order_log_type', 'Declined']) &&
                        <Grid item xs={12} md={4}>
                            <Button
                                startIcon={<FALightIcon icon="user-times" style={{color: colors.red}} />}
                                className={classes.button}
                                onClick={() => handleEmail('declined', order.order_id)}
                            >
                                Order Declined
                            </Button>
                        </Grid>
                    }
                    {find(order.activity, ['order_log_type', 'Cancelled']) &&
                        <Grid item xs={12} md={4}>
                            <Button
                                startIcon={<FALightIcon icon="ban" style={{color: colors.red}} />}
                                className={classes.button}
                                onClick={() => handleEmail('cancelled', order.order_id)}
                            >
                                Order Cancelled
                            </Button>
                        </Grid>
                    }
                    {order.despatch && order.despatch.length > 0 && (
                        <>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                Delivery Notes
                            </Typography>
                        </Grid>
                        {order.despatch.map((el) => (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                className={classes.iconContainer}
                                key={el.desp_id}
                            >
                                <Button
                                    startIcon={
                                        <FALightIcon
                                            icon={"truck"}
                                            style={{ color: "#F39C12" }}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={() =>
                                        handleEmail(el.full_reference.includes('/MD/') ? "noDespatched" : "despatched", el.desp_id)
                                    }
                                >
                                    {el.full_reference}
                                </Button>
                            </Grid>
                        ))}
                        </>
                    )}
                    {order.invoices && order.invoices.length > 0 && (
                        <>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                Invoices
                            </Typography>
                        </Grid>
                        {order.invoices.map((el) => (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                className={classes.iconContainer}
                                key={el.invoice_id}
                            >
                                <Button
                                    startIcon={
                                        <FALightIcon
                                            icon={"file-invoice-dollar"}
                                            style={{ color: "#F39C12" }}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={() =>
                                        handleEmail("invoice", el.invoice_id)
                                    }
                                >
                                    {el.invoice_number}
                                </Button>
                            </Grid>
                        ))}
                        </>
                    )}
                    {order.commercial_invoices && order.commercial_invoices.length > 0 && (
                        <>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                Commercial Invoices
                            </Typography>
                        </Grid>
                        {order.commercial_invoices.map((el) => (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                key={el.commercial_id}
                            >
                                <Button
                                    startIcon={
                                        <FALightIcon
                                            icon={"file-invoice"}
                                            style={{ color: "#F39C12" }}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={() =>
                                        handleEmail(
                                            "commercialInvoice",
                                            el.commercial_id
                                        )
                                    }
                                >
                                    {el.commercial_invoice_number}
                                </Button>
                            </Grid>
                        ))}
                        </>
                    )}
                    {order.pickingDocuments.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Linked Part Files
                                </Typography>
                            </Grid>
                            {order.pickingDocuments.map((el) => (
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    key={el.doc_id}
                                >
                                    <Button
                                        startIcon={
                                            <FALightIcon
                                                icon={"file-pdf"}
                                                style={{ color: "#F39C12" }}
                                            />
                                        }
                                        className={classes.button}
                                        onClick={() =>
                                            handleEmail(
                                                "pickingDocuments",
                                                el.doc_id
                                            )
                                        }
                                    >
                                        {el.doc_title}
                                    </Button>
                                </Grid>
                            ))}
                        </>
                    )}
                    <Grid item xs={12}>
                        <div className={classes.buttonRow}>
                            <Button variant="outlined" color="default" onClick={handleDialogClose}>Close</Button>
                        </div>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

export default OrderEmailPrevDocsDialog;