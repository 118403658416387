import API from 'API';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core/';

import logo from 'Assets/Images/clenaware_logo_icon.png';

const initialState = {
    lists: {
        parts:   [],
    },
    formDataCorrection: {
        notes: ''
    },
    formDataDetail: {
        part: 0
    },
    stockCorrection: {},
    details: [],
    access: {},
    showEditDialog: false,
    isLoading: true,
    subLoading: false,
    toUpdate:[],
}


class ViewStockCorrection extends Component {
    constructor(props) {
        super(props);
        this.state = {...initialState, id: this.props.match.params.scId }
    }

    componentDidMount() { this.getData();}

    getData = () => {
        Promise.all([
            API.get('/parts/withStockActivity'),
            API.get(`/stock/correction/${this.props.match.params.scId}`),
        ]).then(([partsRes, stockCorrectionRes]) => {
            this.setState({
                stockCorrection: stockCorrectionRes.data,
                isLoading: false
            })
        })
    }


    handleSelectChange = fd => f => e => {
        let value = e ? e.value : null;
        this.setState({
            [fd]: {
                ...this.state[fd],
                [f]: value
            }
        });
    }
    
    loadPartImage = (rowData) => {
        this.setState({ 
            partImageDialog: true,
            partImageDialogData: rowData.default_image?.file_path
        });
    };

    render() {
        const {isLoading, stockCorrection} = this.state;
        
        if (isLoading) return( <LoadingCircle/> );
        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container xs={12}>
                            <Grid container item spacing={3} xs={6}>
                                <Grid item lg={3}>
                                    <Typography variant="subtitle1">
                                        Stock Correction:
                                    </Typography>
                                </Grid>
                                <Grid item lg={9}>
                                    <Typography variant="subtitle1">
                                        {stockCorrection.sc_ref}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3} xs={6}>
                                <Grid item lg={3}>
                                    <Typography variant="subtitle1">
                                        Date:
                                    </Typography>
                                </Grid>
                                <Grid item lg={9}>
                                    <Typography variant="subtitle1">
                                        {clenyDate(stockCorrection.sc_date)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3} xs={6} style={{marginTop:'1em'}}>
                                <Grid item lg={3}>
                                    <Typography variant="subtitle1">
                                        Created By:
                                    </Typography>
                                </Grid>
                                <Grid item lg={9}>
                                    <Typography variant="subtitle1">
                                        {stockCorrection.sc_created_by}
                                    </Typography>
                                </Grid>
                            </Grid>       
                            <Grid container item spacing={3} xs={6} style={{marginTop:'1em'}}>
                                <Grid item lg={3}>
                                    <Typography variant="subtitle1">
                                        {stockCorrection.sc_submitted_by ? 'Submitted By:': 
                                            (stockCorrection.sc_deleted_by ? 'Deleted By:': 
                                                'Last Updated By:')}
                                    </Typography>
                                </Grid>
                                <Grid item lg={9}>
                                    <Typography variant="subtitle1">
                                        {stockCorrection.sc_submitted_by || 
                                            (stockCorrection.sc_deleted_by || 
                                                (stockCorrection.details.length > 0 ? 
                                                    (
                                                        _.first(_.orderBy(stockCorrection.details, [i=>i.scd_updated_by ? i.scd_updated_datetime : i.scd_created_datetime],['asc'])).scd_updated_by ||
                                                        _.first(_.orderBy(stockCorrection.details, [i=>i.scd_updated_by ? i.scd_updated_datetime : i.scd_created_datetime],['asc'])).scd_created_by
                                                    ) : 
                                                    '-'
                                                )
                                            )
                                        } 
                                    </Typography>
                                </Grid>
                            </Grid>       
                            <Grid container item spacing={3} xs={6}>
                                <Grid item lg={3}>
                                    <Typography variant="subtitle1">
                                        Created At:
                                    </Typography>
                                </Grid>
                                <Grid item lg={9}>
                                    <Typography variant="subtitle1">
                                        {clenyDate(stockCorrection.sc_created_datetime)}
                                    </Typography>
                                </Grid>
                            </Grid>         
                            <Grid container item spacing={3} xs={6}>
                                <Grid item lg={3}>
                                    <Typography variant="subtitle1">
                                        {stockCorrection.sc_submitted_by ? 'Submitted At:': 
                                            (stockCorrection.sc_deleted_by ? 'Deleted At:': 
                                                'Last Updated At:')}
                                    </Typography>
                                </Grid>
                                <Grid item lg={9}>
                                    <Typography variant="subtitle1">
                                        {
                                            stockCorrection.details.length > 0 ?
                                                clenyDate(stockCorrection.sc_submitted_datetime || 
                                                    (stockCorrection.sc_deleted_datetime || 
                                                        (
                                                            _.first(_.orderBy(stockCorrection.details, [i=>i.scd_updated_by ? i.scd_updated_datetime : i.scd_created_datetime],['asc'])).scd_updated_datetime ||
                                                            _.first(_.orderBy(stockCorrection.details, [i=>i.scd_updated_by ? i.scd_updated_datetime : i.scd_created_datetime],['asc'])).scd_created_datetime
                                                        ) 
                                                    )
                                                ) : '-'
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {_.filter(stockCorrection.details, rowData => rowData.scd_current_stock - rowData.scd_new_stock < 0 ).length > 0 &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h4"><AllIcon size='large' icon={icons.plus} style={{color:colors.green}} /> Stock In</Typography>
                            <br/>
                            <DataTable
                                config={{
                                    key: 'scd_id',
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                }}
                                columns={[
                                    {
                                        field: rowData =>   <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                {rowData.part && rowData.part.default_image?.thumbnail_file_path ?
                                                                    <Button onClick={() => this.loadPartImage(rowData)}>
                                                                        <img src={rowData.part.default_image?.thumbnail_file_path} alt={rowData.part.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                                    </Button> 
                                                                : 
                                                                    <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                                                                }
                                                            </div>,
                                        sizeToContent: true,
                                        alignment: 'center',
                                    },
                                    {
                                        heading: 'Number',
                                        field: rowData => rowData.part.part_number,
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Description',
                                        field: rowData => rowData.part.part_description,
                                        important: true,
                                        main: true,
                                        truncate: true
                                    },
                                    {
                                        heading: <>Pending <br/> Sales Stock</>,
                                        field: rowData => parseFloat(rowData.scd_pending_stock_sales),
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>Pending <br/> Ordered Stock</>,
                                        field: rowData => parseFloat(rowData.scd_pending_stock_delivery),
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>Pending <br/> Suppler Stock</>,
                                        field: rowData => parseFloat(rowData.scd_pending_supplier),
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>Pending <br/> Stock Change</>,
                                        field: rowData => parseFloat(rowData.scd_pending_stock_pending),
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>Pending <br/> Total</>,
                                        field: rowData => parseFloat(rowData.scd_pending_stock_sales) + parseFloat(rowData.scd_pending_stock_delivery) + parseFloat(rowData.scd_pending_supplier) + parseFloat(rowData.scd_pending_stock_pending),
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>Starting <br/>Stock</>,
                                        field: rowData => parseFloat(rowData.scd_current_stock),
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>New <br/>Stock</>,
                                        field: rowData => rowData.scd_new_stock,
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>Stock <br/>In</>,
                                        field: rowData => `${rowData.scd_current_stock - rowData.scd_new_stock > 0 ? '-' : '+'}${Math.abs(rowData.scd_current_stock - rowData.scd_new_stock)}`,
                                        important: true,
                                        main: true,
                                        style: rowData => ({color: rowData.scd_current_stock - rowData.scd_new_stock > 0 ? colors.red : colors.green}),
                                        alignment: 'right',
                                        sizeToContent: true
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'View', icon: 'search', link: {pathname: `/parts/view/${rowData.scd_part_id}`}},
                                            ]
                                        } 
                                    }
                                ]}
                                rows={_.orderBy(_.filter(stockCorrection.details, rowData => rowData.scd_current_stock - rowData.scd_new_stock < 0 ), ['part_number'], ['asc'])}
                            />
                        </PaddedPaper>
                    </Grid>
                }
                {_.filter(stockCorrection.details, rowData => rowData.scd_current_stock - rowData.scd_new_stock > 0 ).length > 0 &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h4"><AllIcon size='large' icon={icons.minus} style={{color:colors.red}} /> Stock Out</Typography>
                            <br/>
                            <DataTable
                                config={{
                                    key: 'scd_id',
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                }}
                                columns={[
                                    {
                                        field: rowData =>   <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                                {rowData.part && rowData.part.default_image?.thumbnail_file_path ?
                                                                    <Button onClick={() => this.loadPartImage(rowData)}>
                                                                        <img src={rowData.part.default_image?.thumbnail_file_path} alt={rowData.part.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                                    </Button> 
                                                                : 
                                                                    <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                                                                }
                                                            </div>,
                                        sizeToContent: true,
                                        alignment: 'center',
                                    },
                                    {
                                        heading: 'Number',
                                        field: rowData => rowData.part.part_number,
                                        important: true,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Description',
                                        field: rowData => rowData.part.part_description,
                                        important: true,
                                        main: true,
                                        truncate: true
                                    },
                                    {
                                        heading: <>Pending <br/> Sales Stock</>,
                                        field: rowData => parseFloat(rowData.scd_pending_stock_sales),
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>Pending <br/> Ordered Stock</>,
                                        field: rowData => parseFloat(rowData.scd_pending_stock_delivery),
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>Pending <br/> Suppler Stock</>,
                                        field: rowData => parseFloat(rowData.scd_pending_supplier),
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>Pending <br/> Stock Change</>,
                                        field: rowData => parseFloat(rowData.scd_pending_stock_pending),
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>Pending <br/> Total</>,
                                        field: rowData => parseFloat(rowData.scd_pending_stock_sales) + parseFloat(rowData.scd_pending_stock_delivery) + parseFloat(rowData.scd_pending_supplier) + parseFloat(rowData.scd_pending_stock_pending),
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>Starting <br/>Stock</>,
                                        field: rowData => parseFloat(rowData.scd_current_stock),
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>New <br/>Stock</>,
                                        field: rowData => rowData.scd_new_stock,
                                        important: true,
                                        main: true,
                                        alignment: 'right',
                                    },
                                    {
                                        heading: <>Stock <br/>Out</>,
                                        field: rowData => `${rowData.scd_current_stock - rowData.scd_new_stock > 0 ? '-' : '+'}${Math.abs(rowData.scd_current_stock - rowData.scd_new_stock)}`,
                                        important: true,
                                        main: true,
                                        style: rowData => ({color: rowData.scd_current_stock - rowData.scd_new_stock > 0 ? colors.red : colors.green}),
                                        alignment: 'right',
                                        sizeToContent: true
                                    },
                                    {
                                        actions: rowData => {
                                            return [
                                                {name: 'View', icon: 'search', link: {pathname: `/parts/view/${rowData.scd_part_id}`}},
                                            ]
                                        } 
                                    }
                                ]}
                                rows={_.orderBy(_.filter(stockCorrection.details, rowData => rowData.scd_current_stock - rowData.scd_new_stock > 0 ), ['part_number'], ['asc'])}
                            />
                        </PaddedPaper>
                    </Grid>
                }
                <Grid item xs={12} style={{paddingTop:'1.5em'}}>
                    <PaddedPaper>
                        <Textarea
                            id="notes"
                            name="notes"
                            label='Stock Correction Notes'
                            value={stockCorrection.sc_notes || 'No Notes Added'}
                            noMargin
                            disabled
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    <div class='buttonRow' style={{marginTop:0}}>
                        <Button variant='outlined' onClick={
                            ()=>{this.props.history.push(`/stock/correction/`);}
                        }>Back</Button>
                    </div>
                </Grid>
                <Dialog
                    open={this.state.partImageDialog}
                    onClose={this.handlePartImageDialogClose}
                    scroll="body"
                    disableBackdropClick	
                    disableEscapeKeyDown
                >                
                    <DialogContent>
                        <ImageWithError src={this.state.partImageDialogData} alt='Part Image' style={{maxWidth: 500}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{
                            this.setState({partImageDialog: false})
                        }} variant="outlined" color="default">Close</Button>
                    </DialogActions>
                </Dialog>           
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                               => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size)  => dispatch(deployDialog(content, title, variant,size)),
    deployConfirmation: (message, title, success)        => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)                   => dispatch(deploySnackBar(variant, msg)),
})

export default connect(null, mapDispatchToProps)(ViewStockCorrection);
