
import moment from 'moment';
import { colors } from './ColourHelper';
import _ from 'lodash';
import React from 'react';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from './IconHelper';

export const getFiscalYearTimestamps = () => {
    const startMonthName = "October";
    const endMonthName = "September";
    return (parseInt(moment().quarter()) === 4) ?
        {
            start:  moment().month(startMonthName).startOf('month'),
            end:    moment().add(1, 'year').month(endMonthName).endOf('month')
        } : {
            start:  moment().subtract(1, 'year').month(startMonthName).startOf('month'),
            end:    moment().month(endMonthName).endOf('month')
        }
};

export const getTaxYearTimestamps = () => {
    return {
        start:  (moment().isBefore(moment().day(5).month('April'))) ? moment().subtract(1, 'year').set('date', 6).month('April') :  moment().set('date', 6).month('April'),
        end:    (moment().isBefore(moment().day(5).month('April'))) ? moment().set('date', 5).month('April')                     :  moment().add(1, 'year').set('date', 5).month('April')
    }
};

export const getDatesBetweenTwoDates = (from, to) => {
    if (from === to) return [from];
    var dates = [];
    let startDate = moment(from), endDate = moment(to)
    dates.push(startDate.format('YYYY-MM-DD'));
    startDate.add(1, 'days');
    while(startDate.format('M/D/YYYY') !== endDate.format('M/D/YYYY')) {
        dates.push(startDate.format('YYYY-MM-DD'));
        startDate = startDate.add(1, 'days');
    }
    dates.push(endDate.format('YYYY-MM-DD'));
    return dates;
}

export const months = [null, 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const fmonths = ['October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September' ];

function _getPlainCurrency(value) {
    return parseFloat((_.isFinite(parseFloat(value))) ? parseFloat(value) : 0.00).toFixed(2);
}

export const getPlainCurrency = _getPlainCurrency

export const getCurrency = (value) => {
    let v = parseFloat(_getPlainCurrency(value));
    return `${v < 0 ? '-' : ''}£${parseFloat(Math.abs(v)).toFixed(2)}`;
}

function _getColorCurrency (value, reverseColor=false, withArrow=true) {
    let v = parseFloat(_getPlainCurrency(value));
    let color = (v === 0) ? colors.green :
        ( (v < 0) ? 
            (reverseColor ? colors.green : colors.red) :    
            (reverseColor ? colors.red : colors.green)
        );
    let sign = (v === 0) ? '' : ( (v < 0) ? '-' : '+');
    let arrow = (withArrow) ? _getColorArrow(v, reverseColor) : '';
    sign = <>{arrow}{sign}</>;
    return (
        <span style={{color: color}}>{sign}£{parseFloat(Math.abs(v)).toFixed(2)}</span>
    )
}

export const getColorCurrency = _getColorCurrency;

export const getCurrencyChange = (v1,v2) => {
    return parseFloat(parseFloat(parseFloat(_getPlainCurrency(v1)) - parseFloat(_getPlainCurrency(v2))).toFixed(2));
}

export const getColorCurrencyChange = (v1,v2, reverseColor=false, withArrow=true) => { 
    return _getColorCurrency(getCurrencyChange(v1,v2), reverseColor, withArrow);
}

function _getPlainPercentage(v,c) {
    let p = (v / c) * 100;

    if ((parseFloat(v) === 0 && parseFloat(c) === 0)) { return 0 }
    if (!_.isFinite(p)){
        return (parseFloat(v) > 0) ? 100 : -100;
    }
    return parseFloat(p).toFixed(2);
}

export const getPlainPercentage = _getPlainPercentage

export const getPercentage = (v,c) => {
    return `${parseFloat(_getPlainPercentage(v,c)).toFixed(2)}%`;
}

export const getColorPercentage = (v,c, reverseColor=false, withArrow=true) => {
    let p = parseFloat( _getPlainPercentage(v,c) );
    let color = (p === 0) ? colors.green :
        ( (p < 0) ? 
            (reverseColor ? colors.green : colors.red) :    
            (reverseColor ? colors.red : colors.green)
        );
    let sign = (p === 0) ? '' : ( (p < 0) ? '-' : '+');
    let arrow = (withArrow) ? _getColorArrow(p, reverseColor) : '';
    sign = <>{arrow}{sign}</>;
    return (
        <span style={{color: color}}>{sign}{parseFloat(Math.abs(p)).toFixed(2)}%</span>
    )
}

function _getPlainPercentageChange(v1,v2) {
    return _getPlainPercentage((_getPlainCurrency(v1) - _getPlainCurrency(v2)),Math.abs(_getPlainCurrency(v2)))
}

export const getPercentageChange = (v1,v2) => {
    return `${parseFloat(_getPlainPercentageChange(v1,v2)).toFixed(2)}%`;
}

export const getColorPercentageChange = (v1,v2, reverseColor=false, withArrow=true, wholeNumber=false) => {
    let p = parseFloat( _getPlainPercentageChange(v1,v2) );
    let color = (p === 0) ? colors.green :
        ( (p < 0) ? 
            (reverseColor ? colors.green : colors.red) :    
            (reverseColor ? colors.red : colors.green)
        );
    let sign = (p === 0) ? '' : ( (p < 0) ? '-' : '+');
    let arrow = (withArrow) ? _getColorArrow(p, reverseColor) : '';
    sign = <>{arrow}{sign}</>;
    return (
        <span style={{color: color}}>{sign}{
            wholeNumber ?
                Math.round(Math.abs(p)) :
                parseFloat(Math.abs(p)).toFixed(2)
            }%</span>
    )
}

function _getPlainQty (v, tf=3) {
    return parseFloat(v).toFixed(tf);
}

export const getPlainQty = _getPlainQty

export const getQty = (v, tf=3) => {
    return parseFloat(_getPlainQty(v)).toFixed(tf);
}

export const getColorQty = (v, reverseColor=false, tf=3,  withArrow=true) => {
    let color = (v === 0) ? colors.green :
        ( (v < 0) ? 
            (reverseColor ? colors.green : colors.red) :    
            (reverseColor ? colors.red : colors.green)
        );
    let sign = (v === 0) ? '' : ( (v < 0) ? '-' : '+');
    let arrow = (withArrow) ? _getColorArrow(v, reverseColor) : '';
    sign = <>{arrow}{sign}</>;
    return (
        <span style={{color: color}}>{sign}{parseFloat(Math.abs(v)).toFixed(tf)}</span>
    )
}

export const getQtyChange = (v1,v2, tf=3) => {
    return parseFloat(parseFloat(parseFloat(v1) - parseFloat(v2)).toFixed(tf));
}

export const getColorQtyChange = (v1,v2, reverseColor=false, tf=3, withArrow=true) => {
    let v = parseFloat(getQtyChange(v1,v2));
    let color = (v === 0) ? colors.green :
        ( (v < 0) ? 
            (reverseColor ? colors.green : colors.red) :    
            (reverseColor ? colors.red : colors.green)
        );
    let sign = (v === 0) ? '' : ( (v < 0) ? '-' : '+');
    let arrow = (withArrow) ? _getColorArrow(v, reverseColor) : '';
    sign = <>{arrow}{sign}</>;
    return (
        <span style={{color: color}}>{sign}{parseFloat(Math.abs(v)).toFixed(tf)}</span>
    )
}

function _getColorArrow(v, reverse=false, size='small', withDash=false){
    return ( (v === 0 && !withDash) ? 
        '' : 
        <AllIcon color={ (v === 0) ? colors.grey : (reverse ? v < 0 : v > 0) ? colors.green : colors.red } size={size} icon={IconHelper[(v === 0) ? 'dash' : (reverse ? v < 0 : v > 0) ? 'arrowStemUp' : 'arrowStemDown']} noMargin noPadding style={{marginRight: 2}}/> ) ;
}

export const getMarginPercent = (price, cost, toFixed=2) => {
    return `${parseFloat(_getPlainPercentage((price - cost), price)).toFixed(toFixed)}%`;
}

export const getColorArrow = _getColorArrow;