import { Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import API from '../../../API';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import ViewStaffMiniProfile from '../../Staff/ViewStaff/ViewStaffMiniProfileCard';
const styles = theme => ({
    dialogPaper: {
        width: '75vh',
        maxWidth: '75vh',
        height: '75vh',
        maxHeight: '75vh',
    },
    profileContainer: {
        width: 115,
    },
    centered: {
        height: '100%',
        marginTop: '40%',
        textAlign: 'center',
    },
    staffProfile: {
        fontSize: '0.7em'
    },
    badge: {
        marginBottom: theme.spacing(1)
    },
    darr: {
        display: 'none',
        margin: 0,
    },
    rarr: {
        margin: '0 25px',
    },
    [theme.breakpoints.down('sm')]: {
        darr: {
            display: 'block',
        },
        rarr: {
            display: 'none',
        }
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ViewEventDialog extends React.Component {
    constructor(props) {
        super(props);        
        this.state = {
            leader: {},
            creator: {},
            event: {},
            showCancelledMsg: false,
            showPastMsg: false,
            showEditBtn: false,
            isLoading: true,
            redirect: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.eventId !== prevProps.eventId) {
            this.setState({
                isLoading: true,
            }, () => this.getEvent(this.props.eventId));
        }
    }

    getEvent = (eventId) => {
        API.get(`/calendar/events/${eventId}`)
        .then(result => {
            if(result.data.errors) {
                this.setState({ redirect: true });
            } else {
                let staffAttendees = _.map(result.data.staff_attendees, (staff) => {
                    return _.assign({
                        id: staff.staff_id,
                        name: staff.full_name,
                        avatar: staff.staff_profile_photo
                    });
                });
                let customerAttendees = _.map(result.data.customer_attendees, (cust) => {
                    return _.assign({
                        id: cust.customer_attendee_contact_id,
                        name: cust.contact_name
                    });
                });
                let supplierAttendees = _.map(result.data.supplier_attendees, (supp) => {
                    return _.assign({
                        id: supp.supplier_attendee_contact_id,
                        name: supp.full_name
                    });
                });
                this.setState({
                    showSupplier: result.data.supplier ? true : false,
                    showCustomer: result.data.customer ? true : false,
                    showEditBtn: result.data.can_edit && (!moment().isAfter(moment(result.data.calendar_event_start_datetime)) && result.data.calendar_event_status !== 'Cancelled') ? true : false,
                    showPastMsg: moment().isAfter(moment(result.data.calendar_event_start_datetime)) && result.data.calendar_event_status !== 'Cancelled' ? true : false,                
                    showCancelledMsg: result.data.calendar_event_status === 'Cancelled' ? true : false,
                    event: {
                        title: `${result.data.sub_category.category_name} (${result.data.parent_category.category_name})`,
                        startsAtTime: moment(result.data.calendar_event_start_datetime).format('HH:mm'),
                        endsAtTime: moment(result.data.calendar_event_end_datetime).format('HH:mm'),                    
                        startsAtDate: moment(result.data.calendar_event_start_datetime).format('ddd MMM Do YYYY'),
                        endsAtDate: moment(result.data.calendar_event_end_datetime).format('ddd MMM Do YYYY'),
                        details: result.data.calendar_event_notes,
                        link: result.data.calendar_event_link,
                        supplierName: result.data.supplier ? result.data.supplier.supp_company_name : '',
                        customerName: result.data.customer ? result.data.customer.cust_name : '',
                        editEventOnClick: `/calendar/update-event/${eventId}`,
                        staffAttendees: staffAttendees,
                        customerAttendees: customerAttendees,
                        supplierAttendees: supplierAttendees
                    },
                    leader: result.data.leader,
                    creator: result.data.creator,
                    isLoading: false,
                });
            }
        });
    };

    render() {
        const { classes } = this.props;
        const { event } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/calendar/full" />
        } else {
            return (
                <Dialog
                    open={this.props.open}
                    TransitionComponent={Transition}
                    onClose={this.props.onClose}
                    classes={{paper: classes.dialogPaper}}
                    maxWidth='md'
                    disableEscapeKeyDown
                    disableBackdropClick	
                >
                    {(this.state.isLoading && (
                        <div className={classes.centered}>
                            <LoadingCircle />    
                        </div>
                    )) || (   
                        <React.Fragment>                     
                            <DialogTitle>
                            <Grid container spacing={3}>
                                    <Grid item xs={12} align="center" sm>   
                                        {event.title || ''}
                                    </Grid>
                                </Grid>                                     
                            </DialogTitle>
                            <Typography component={DialogContent} variant="body2">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} align="center">
                                            <Typography variant="h6">
                                                <div className="xs-only sm-only">
                                                    {event.startsAtTime}<br/>{event.startsAtDate}<br />
                                                    <strong>&darr;</strong><br />
                                                    {event.endsAtTime}<br /> {event.endsAtDate}
                                                </div>
                                                <div className="not-xs not-sm">
                                                    {event.startsAtDate} ({event.startsAtTime}) <strong>&rarr;</strong> {event.endsAtDate} ({event.endsAtTime})
                                                </div>                                           
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='xs' align="center">                                
                                            {!this.state.showPastMsg && !this.state.showCancelledMsg && 
                                                <Chip size="small" label='Upcoming Event' style={{color:'white', backgroundColor:'#e8ab3d'}} />
                                            }
                                            {this.state.showCancelledMsg && 
                                                <Chip size="small" label='Cancelled' style={{color:'white', backgroundColor:'#d14039'}} />
                                            }
                                            {this.state.showPastMsg && 
                                                <Chip size="small" label='Past Event' style={{color:'white', backgroundColor:'#5da14d'}} />
                                            }  
                                        </Grid>  
                                        <Grid item xs={12} sm={4} className={classes.staffProfile} align="center">
                                                <ViewStaffMiniProfile staffData={this.state.leader} header='Leading' vertical/>
                                            
                                        </Grid>
                                        <Grid item sm={4} className='not-xs' align="center">                                
                                            {!this.state.showPastMsg && !this.state.showCancelledMsg && 
                                                <Chip size="medium" label='Upcoming Event' style={{marginTop: '2em', color:'white', backgroundColor:'#e8ab3d'}} />
                                            }
                                            {this.state.showCancelledMsg && 
                                                <Chip size="medium" label='Cancelled' style={{marginTop: '2em', color:'white', backgroundColor:'#d14039'}} />
                                            }
                                            {this.state.showPastMsg && 
                                                <Chip size="medium" label='Past Event' style={{marginTop: '2em', color:'white', backgroundColor:'#5da14d'}} />
                                            }  
                                        </Grid>  
                                        <Grid item xs={12} sm={4} className={classes.staffProfile} align="center">
                                                <ViewStaffMiniProfile staffData={this.state.creator} header='Created by' vertical/>
                                        </Grid>
                                        {this.state.showSupplier && 
                                            <React.Fragment>
                                                <Grid item xs={12} align="center">
                                                    <strong>{event.supplierName} Attendees</strong>
                                                </Grid>
                                                <Grid item xs={12} align="center">
                                                    {_.map(event.supplierAttendees, attendee => {
                                                        return (
                                                            <Chip
                                                                label={attendee.name}
                                                                variant="outlined"
                                                                style={{height: 35, marginRight: 10, marginBottom: 8}}
                                                                key={attendee.id}
                                                            />                                                    
                                                        );
                                                    })}
                                                </Grid>
                                            </React.Fragment>
                                        }
                                        {this.state.showCustomer && 
                                            <React.Fragment>
                                                <Grid item xs={12} align="center">
                                                    <strong>{event.customerName} Attendees</strong>
                                                </Grid>
                                                <Grid item xs={12} align="center">
                                                    {_.map(event.customerAttendees, attendee => {
                                                        return (
                                                            <Chip
                                                                label={attendee.name}
                                                                variant="outlined"
                                                                style={{height: 35, marginRight: 10, marginBottom: 8}}
                                                                key={attendee.id}
                                                            />    
                                                        )
                                                    })}
                                                </Grid>
                                            </React.Fragment>
                                        }
                                        <Grid item xs={12} align="center">
                                            <strong>Staff Attendees</strong>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            {_.map(event.staffAttendees, attendee => {
                                                return (
                                                    <Chip
                                                        avatar={<Avatar alt={attendee.name} src={attendee.avatar} style={{width: 32, height: 32}} />}
                                                        label={attendee.name}
                                                        variant="outlined"
                                                        style={{height: 35, marginRight: 10, marginBottom: 8}}
                                                        key={attendee.id}
                                                    />
                                                );
                                            })}
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <strong>Details</strong>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            {event.details ? 
                                                event.details && event.details.split('\n').map((item, key) => {
                                                    return <span key={key}>{item}<br/></span>
                                                })
                                            : 
                                                <em>No details were specified for this event</em>
                                            }
                                            {event.link &&
                                                <Button component={Link} to={event.link} variant="outlined" color="primary">View Details</Button>
                                            }
                                        </Grid>
                                    </Grid>
                            </Typography>
                            <DialogActions>                        
                                {this.state.showEditBtn && 
                                    <Button component={Link} to={`/calendar/events/update/${this.props.eventId}`} variant="outlined" color="primary">
                                        <FALightIcon icon='pencil-alt' style={{color: '#0282C6'}}/>
                                            Edit Event
                                        </Button>
                                }
                                <Button onClick={this.props.onClose} variant="outlined" color="default">Close</Button>
                            </DialogActions>
                        </React.Fragment>
                    )}
                </Dialog>
            );
        }
    }
}

export default withStyles(styles)(ViewEventDialog);