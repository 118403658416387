import React from 'react';
import moment from 'moment';
import {colors} from 'Helpers/ColourHelper';

export function statusColour(status) {
	let colour = '';
	
    switch (status){
		case 'Completed':
			colour = '#009933';
		break;
		case 'Declined':
		case 'Cancelled':
			colour = colors.red;
		break;
		case 'Approval':
		case 'Confirmation':
		case 'Awaiting Approval':
		case 'Confirmed':
        case 'Invoice':
        case 'Invoiced':
        case 'Credit Note Awaiting Approval':
        case 'Credit Note':
			colour = '#F39C12';
		break;
		case 'N/A':
        default:
			colour = '#333333';
        break;
	}
	
    return colour;
}

export function statusHelper(status){
    switch(status) {
        case 'Awaiting Approval':
            return 'file';
        case 'Confirmed':
            return 'user-check';
        case 'Completed':
            return 'check';
        case 'Approval':
            return 'file-user';
        case 'Declined':
            return 'user-times';
        case 'Cancelled':
            return 'ban';
        case 'Credit Note Awaiting Approval':
            return 'file-invoice-dollar';
        case 'Credit Note':
            return 'file-check';
        default:
            return 'question-circle';
    }
}