import React, { Component } from 'react';
import { connect }          from 'react-redux';
import API                  from 'API';

import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';

import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { InputAdornment, Grid, TextField, Button } from '@material-ui/core/';

const initialState = {
    formData: {
        creditLimitNet:   0,
        creditLimitGross: 0,
    },
    formErrors:  [],
    vat:         0,
    isLoading:   true
}

class UpdateCreditLimitDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.state.formData.creditLimitNet = this.props.limit || 0;
    }

    componentDidMount() {
        this.getVat();
    }

    handleChange = (e) => {            
        this.setState({
            formData: {
                ...this.state.formData,
                creditLimitNet:     e.target.value,
                creditLimitGross:   parseFloat( parseInt(e.target.value) + ( e.target.value * this.state.vat ) ).toFixed(0)
            }
        });
    }

    getVat = () => {
        API.get(`/customers/${this.props.customerId}`)
        .then( res => {
            this.setState({
                vat:        parseFloat(res.data.vat_type.default_vat_value.vat_rate_decimal),
                isLoading:  false
            }, () => {this.handleChange({target:{value:this.state.formData.creditLimitNet}})})
        });
    }

    submit = () => {
        API.post(`/customers/${this.props.customerId}/accounts/addresses/${this.props.addressId}/creditLimit`, this.state.formData)
        .then(res => {
            if (res.data.success) {
                this.props.closeDialog();
                this.props.callback();
            }
        })
    }

    render() {
        const { formErrors, isLoading} = this.state;
        return (
            <>
                {isLoading ? <LoadingCircle/> : 
                    <>
                        <Grid container spacing={3} xs={12}>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    name="creditLimitNet"
                                    label="Credit Limit Net *"
                                    value={this.state.formData.creditLimitNet}
                                    error={formErrors && formErrors['CreditLimitNet'] && true}
                                    helperText={formErrors && formErrors['CreditLimitNet']}
                                    onChange={this.handleChange}
                                    margin="none"
                                    fullWidth
                                    type='number'
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        inputProps: { min: 0 },
                                        startAdornment: (
                                        <InputAdornment  position="start">
                                            £
                                        </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    name="creditLimitGross"
                                    label="Credit Limit Gross"
                                    value={this.state.formData.creditLimitGross || 0}
                                    margin="none"
                                    fullWidth
                                    type='number'
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        inputProps: { min: 0 },
                                        startAdornment: (
                                        <InputAdornment  position="start">
                                            £
                                        </InputAdornment>
                                        )
                                    }}
                                    disabled
                                />
                            </Grid>
                            <Grid item container xs={12} style={{justifyContent: 'flex-end'}}>
                                <Grid item>
                                    <Button onClick={this.props.closeDialog} variant="outlined" >Close</Button>
                                </Grid>
                                <Grid item style={{paddingLeft: '1em'}}>
                                    <Button variant="contained"  color='primary' onClick={() => {
                                        this.props.deployConfirmation('Are you sure you want to change this credit limit', 'Change Credit Limit', this.submit)
                                    }}>Save</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </>
                }
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployDialog: (content, title, variant, size)           => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:  ()                                        => dispatch(closeDialog()),
    deployConfirmation: (message, title, success, cancel)   => dispatch(deployConfirmation(message, title, success, cancel)),
})

export default connect(null, mapDispatchToProps)(UpdateCreditLimitDialog);