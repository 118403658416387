import {
    Box,
    Button,
    Checkbox,
    ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid,
    Paper,
    Table, TableBody,
    TableCell,
    TableRow,
    TextField, Typography
} from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import ListOrganiser from 'Components/Common/Inputs/ListOrganiser';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import TabBar from 'Components/Common/Navigation/TabBar';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import InfoBox from 'Components/Common/reports/InfoBox';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import InputHelper from 'Helpers/InputHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import AddPartCategoriesDialog from './AddPartCategoriesDialog';

const initialState = () => ({
    isLoading: true,
    formData: {
        category: null,
        name: '',
        addCustomers: false,
        partCategories: [],
    },
    addCustFormData: {
        category: null,
        subCategory: null,
        addType: 'all',
        customers: [],
        ogCustomers: [],
    },
    categories: [],
    OgCategories: [],
    customers: [],
    currentTab: 'cat',
    filter: {
        customer: '',
        selected: 'All',
    },
    reOrderStore: [],
    lists: {
        partCategories: [],
    }
});

class CustomerMarketing extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();

        this.InputHelper = new InputHelper(this);
    }

    componentDidMount() {
        this.getPartCategories(); 
        this.getCustomerMarketingCategories();
    }

    getPartCategories = () => {
        API.get('/parts/catagories')
        .then(res => {
            this.setState({
                lists: {
                    partCategories: _.map(res.data, i => ({
                        label: i.pc_name,
                        value: i.pc_id
                    }))
                }
            });
        });
    }

    submit = () => {
        API.post('/marketing/customerMarketing', {
            ...this.state.formData,
            partCategories: JSON.stringify(this.state.formData.partCategories)
        })
        .then(res => {
            this.setState({
                formData: initialState().formData
            }, ()=>{
                this.getCustomerMarketingCategories();
            });
        });
    }

    getCustomerMarketingCategories = () => {
        Promise.all([
            API.get('/marketing/customerMarketing'),
            API.get('/customers')
        ])
        .then(([res1, res2]) => {
            this.setState({
                isLoading: false,
                categories: _.orderBy(res1.data, ['cm_order']),
                OgCategories: _.orderBy(res1.data, ['cm_order']),
                customers: res2.data
            })
        })
    }

    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value,
            formData: initialState().formData,
            addCustFormData: initialState().addCustFormData
        });
    }

    linkCustomers = () => {
        this.setState({
            isLoading: true
        }, ()=> {
            API.post('/marketing/customerMarketing/addCustomers', {...this.state.addCustFormData, customers: JSON.stringify(this.state.addCustFormData.customers)})
            .then(res => {
                this.setState({
                    addCustFormData: initialState().addCustFormData,
                }, ()=>{
                    this.getCustomerMarketingCategories();
                });
            })
        })
    }

    reorder = (category=null) => () => {
        this.props.deployDialog(
            <>
                <ListOrganiser
                    options={_.map(_.orderBy(category ? _.filter(this.state.categories, i => i.cm_category === category && i.cm_name) : _.uniqBy(this.state.categories, 'cm_category'), !category ? 'cm_order' : 'cm_sub_order', 'asc') , i => ({
                        label: category ? i.cm_name : i.cm_category,
                        value:  category ? i.cm_id : i.cm_category,
                    }))}
                    itemStyle={{
                        border: `1px solid ${colors.disabled}`,
                        backgroundColor: colors.primary,
                        color: '#fff',
                        padding: '0.5em 1em',
                        margin: 1,
                        borderRadius: 4,
                    }}
                    onChange={
                        v => {
                            this.setState({
                                reOrderStore: v
                            })
                        }
                    }
                />
                <Box className='buttonRow'>
                    <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                    <Button color='primary' variant='contained' onClick={this.saveOrder(category)}>Save</Button>
                </Box>
            </>,
            'Re-order Marketing Categories',
            'sm'
        )
    }

    saveOrder = (category=0) => () => {
        this.setState({
            isLoading: true
        }, ()=>{
            API.post('/marketing/customerMarketing/reOrder', {
                categories: JSON.stringify(_.map(this.state.reOrderStore, i => i.value)),
                category: category,
            }).then(()=>{
                this.getCustomerMarketingCategories();
                this.props.closeDialog();
            })
        })
    }

    addPartCategory = (category) => () => {
        this.props.deployDialog(
            <AddPartCategoriesDialog
                category={category}
                onClose={()=>{
                    this.getCustomerMarketingCategories();
                    this.props.closeDialog();
                }}
            />
            , 'Link Part Categories', 'sm')
    }

    render() {

        const { isLoading, formData, categories, currentTab, addCustFormData, filter } = this.state;

        if (isLoading) {
            return <LoadingCircle />
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5'>
                        Customer Marketing
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <TabBar
                            tabs={[
                                { label: 'Marketing Categories', value: 'cat' },
                                { label: 'Marketing Sub Categories', value: 'subCat' },
                                { label: 'Linked Customers', value: 'addCust' },
                            ]}
                            currentTab={currentTab} 
                            handleTabChange={this.handleTabChange}
                        />
                        <Grid container spacing={2} style={{padding: '2em'}}>
                            {currentTab === 'cat' &&
                                <Grid item xs={5}>
                                    <PaddedPaper>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Category *"
                                                    name='formData.category'
                                                    value={formData.category ?? ''}
                                                    onChange={e=>this.setState(this.InputHelper.setNestedValue(e.target.name, e.target.value))}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='buttonRow'>
                                                <Button color='primary' variant='contained'
                                                    onClick={()=>{
                                                        this.props.deployConfirmation(
                                                            'Are you sure you want to add this new marketing category?',
                                                            'Add New Marketing Category',
                                                            this.submit
                                                        )
                                                    }}
                                                    disabled={
                                                        !formData.category ||
                                                        _.find(categories, i => i.cm_category.toLowerCase() === formData.category.toLowerCase() )
                                                    }
                                                >Add</Button>
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                            }
                            {currentTab === 'subCat' &&
                                <Grid item xs={5}>
                                    <PaddedPaper>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <AutoCompleteSelect
                                                    label="Category *"
                                                    options={[..._.map(_.uniqBy(categories, 'cm_category'), i=>({
                                                        label: i.cm_category,
                                                        value: i.cm_category
                                                    })),{ label: formData.category, value: formData.category }]}
                                                    value={formData.category}
                                                    onChange={this.InputHelper.handleSelectChange('formData.category')}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Sub Category *"
                                                    name='formData.name'
                                                    value={formData.name}
                                                    onChange={e=>this.setState(this.InputHelper.setNestedValue(e.target.name, e.target.value))}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {/*}
                                                <Grid item xs={12}>
                                                    <CheckBox
                                                        color='primary'
                                                        label={_.find(_.uniqBy(categories, 'cm_category'), {cm_category: formData.category}) ? 'Add all customers in category to this new subcategory' : 'Add all customer to this new category'}
                                                        name='addCustomers'
                                                        checked={formData.addCustomers}
                                                        onChange={this.InputHelper.handleButtonChange('addCustomers', !formData.addCustomers)}
                                                    />
                                                </Grid>
                                            */}
                                            <Grid item xs={12}>
                                                <AutoCompleteMultiSelect
                                                    label="Part Categories"
                                                    options={this.state.lists.partCategories}
                                                    value={formData.partCategories}
                                                    onChange={this.InputHelper.handleMultiSelectChange('formData.partCategories')}
                                                    fullWidth
                                                    noClear
                                                />
                                            </Grid>
                                            <Grid item xs={12} className='buttonRow'>
                                                <Button color='primary' variant='contained'
                                                    onClick={()=>{
                                                        this.props.deployConfirmation(
                                                            'Are you sure you want to add this new marketing subcategory?',
                                                            'Add New Marketing Subcategory',
                                                            this.submit
                                                        )
                                                    }}
                                                    disabled={
                                                        !formData.category ||
                                                        !formData.name ||
                                                        _.find(categories, i=> i.cm_category === formData.category && i.cm_name.toLowerCase() === formData.name.toLowerCase())
                                                    }
                                                >Add</Button>
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                            }
                            {(currentTab === 'cat' || currentTab === 'subCat') &&
                                <Grid item xs={12} className='buttonRow'>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        onClick={this.reorder()}
                                    >Re-order Categories</Button>
                                </Grid>
                            }
                            {(currentTab === 'cat' || currentTab === 'subCat') &&
                                <Grid item xs={12}>
                                    {_.map( _.orderBy(_.uniqBy(categories, 'cm_category'), 'cm_order', 'asc'), c => 
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary
                                                expandIcon={
                                                    <AllIcon 
                                                        icon={IconHelper.arrowDown}
                                                        white
                                                        noMargin
                                                    />
                                                }
                                            >
                                                <Typography>
                                                    {c.cm_category}
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails style={{padding: 15}}>
                                                <Table
                                                    style={{width: '100%'}}
                                                >
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell
                                                                colSpan={4}
                                                                style={{borderBottom: 'none', textAlign: 'right'}}
                                                         
                                                            >
                                                                <Button
                                                                    color='primary'
                                                                    variant='contained'
                                                                    onClick={this.reorder(c.cm_category)}
                                                                >Re-order Sub-Categories</Button>
                                                            </TableCell>
                                                        </TableRow>
                                                        {_.map(_.orderBy(_.filter(categories, i => i.cm_category === c.cm_category && i.cm_name ), 'cm_sub_order', 'asc'), (s,idx) =>
                                                            <TableRow>
                                                                <TableCell
                                                                    style={{
                                                                        padding: 7,
                                                                        width: 1
                                                                    }}
                                                                >
                                                                    <AllIcon
                                                                        icon={IconHelper.dropRowReverse}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={{padding: 7}}>
                                                                    {s.cm_name}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        width: 'size-to-content',
                                                                        padding: 7,
                                                                        textAlign: 'right'
                                                                    }}
                                                                >
                                                                    {s.customers.length} Customers
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{padding: 7,width: 1}}
                                                                >
                                                                    <AllIcon
                                                                        icon={IconHelper.parts}
                                                                        color={s.parts_category_pivot.length > 0 ? colors.green : colors.red}
                                                                        noMargin
                                                                        onClick={this.addPartCategory(s)}
                                                                        tooltip='Update Part Categories'
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                        
                                                    </TableBody>
                                                </Table>
                                                {/*}
                                                <List
                                                    fullWidth
                                                    style={{width: '100%'}}
                                                >
                                                    {_.filter(categories, i => i.cm_category === c.cm_category && i.cm_name ).length > 0 ?
                                                        <>
                                                            <ListItem fullWidth >
                                                                <Grid item xs={12} className='buttonRow'>
                                                                    <Button
                                                                        color='primary'
                                                                        variant='contained'
                                                                        onClick={this.reorder(c.cm_category)}
                                                                    >Re-order Sub-Categories</Button>
                                                                </Grid>
                                                            </ListItem>
                                                            {_.map(_.orderBy(_.filter(categories, i => i.cm_category === c.cm_category && i.cm_name ), 'cm_sub_order', 'asc'), s =>
                                                                <ListItem
                                                                    fullWidth
                                                                    >
                                                                    <ListItemAvatar>
                                                                        <AllIcon
                                                                            icon={IconHelper.dropRowReverse}
                                                                        />
                                                                    </ListItemAvatar>
                                                                    <ListItemText>
                                                                        {s.cm_name}
                                                                    </ListItemText>
                                                                    <ListItemSecondaryAction>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item >
                                                                                {s.customers.length} Customers <br/>
                                                                            </Grid>
                                                                            <Grid item >
                                                                                <AllIcon
                                                                                    icon={IconHelper.parts}
                                                                                    noMargin
                                                                                    onClick={this.addPartCategory(s.cm_id)}
                                                                                    noPadding
                                                                                    tooltip='Add Part Category'
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                                            
                                                            ) }
                                                        </>
                                                        : 
                                                        <ListItem fullWidth >
                                                            <ListItemText primary={<em>No sub-categories to display</em>} />
                                                        </ListItem>
                                                    }
                                                </List>
                                                */}
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>    
                                    )}
                                </Grid>
                            }
                            {
                                currentTab === 'addCust' &&
                                <>
                                    <Grid item xs={5}>
                                        <PaddedPaper>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant='h6'>
                                                        Linked Customer Marketing
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AutoCompleteSelect
                                                        label="Category *"
                                                        options={[..._.map(_.uniqBy(_.filter(categories, i => i.cm_name), 'cm_category'), i=>({
                                                            label: i.cm_category,
                                                            value: i.cm_category
                                                        }))]}
                                                        value={addCustFormData.category}
                                                        onChange={(e) => 
                                                            {
                                                                this.InputHelper.handleValueChange('addCustFormData.customers')([]);
                                                                this.InputHelper.handleValueChange('addCustFormData.ogCustomers')([]);
                                                                this.InputHelper.handleSelectChange('addCustFormData.category')(e);
                                                                this.InputHelper.handleSelectChange('addCustFormData.subCategory')(null);
                                                            }
                                                        }
                                                        fullWidth
                                                    />
                                                </Grid>
                                                {addCustFormData.category &&
                                                    <>
                                                        <Grid item xs={12}>
                                                            <AutoCompleteSelect
                                                                label="Sub Category *"
                                                                options={[..._.map(_.filter(categories, {cm_category: addCustFormData.category}), i=>({
                                                                    label: i.cm_name,
                                                                    value: i.cm_name
                                                                }))]}
                                                                value={addCustFormData.subCategory}
                                                                onChange={(e) => 
                                                                    {
                                                                        this.InputHelper.handleValueChange('addCustFormData.customers')(
                                                                            _.map(
                                                                                _.find(categories, {cm_category: addCustFormData.category, cm_name: e.value}).customers,
                                                                                i => i.ctcm_c_id
                                                                            )
                                                                        )
                                                                        this.InputHelper.handleValueChange('addCustFormData.ogCustomers')(
                                                                            _.map(
                                                                                _.find(categories, {cm_category: addCustFormData.category, cm_name: e.value}).customers,
                                                                                i => i.ctcm_c_id
                                                                            )
                                                                        )
                                                                        this.InputHelper.handleSelectChange('addCustFormData.subCategory')(e)
                                                                    }
                                                                }
                                                                fullWidth
                                                                noClear
                                                            />
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                    {!!addCustFormData.category && !!addCustFormData.subCategory &&
                                        <>
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={5}>
                                                <Grid container spacing={2}>
                                                    <InfoBox
                                                        onClick={this.InputHelper.handleButtonChange('filter.selected','All')}
                                                        title='All'
                                                        value={this.state.customers.length}
                                                        icon={IconHelper.customers}
                                                        rowSize={3}
                                                        color={filter.selected === 'All' && colors.primary}
                                                        border={filter.selected === 'All' && `1px solid ${colors.primary}`}
                                                    />
                                                    <InfoBox
                                                        onClick={this.InputHelper.handleButtonChange('filter.selected','Removed')}
                                                        title='Removed'
                                                        value={this.state.customers.length - _.filter(_.filter(this.state.customers, c => _.find(_.filter(categories, {cm_category: addCustFormData.category}),i => _.find(i.customers, {ctcm_c_id: c.cust_id}))), c => (!filter.customer || (filter.customer && c.cust_name.toLowerCase().includes(filter.customer.toLowerCase()))) && addCustFormData.ogCustomers.includes(c.cust_id)).length}
                                                        icon={IconHelper.false}
                                                        rowSize={3}
                                                        color={filter.selected === 'Removed' && colors.red}
                                                        border={filter.selected === 'Removed' && `1px solid ${colors.red}`}
                                                    />
                                                    <InfoBox
                                                        onClick={this.InputHelper.handleButtonChange('filter.selected','Added')}
                                                        title='Added'
                                                        value={_.filter(_.filter(this.state.customers, c => _.find(_.filter(categories, {cm_category: addCustFormData.category}),i => _.find(i.customers, {ctcm_c_id: c.cust_id}))), c => (!filter.customer || (filter.customer && c.cust_name.toLowerCase().includes(filter.customer.toLowerCase()))) && addCustFormData.ogCustomers.includes(c.cust_id)).length}
                                                        icon={IconHelper.true}
                                                        rowSize={3}
                                                        color={filter.selected === 'Added' && colors.green}
                                                        border={filter.selected === 'Added' && `1px solid ${colors.green}`}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                    {!!addCustFormData.category && !!addCustFormData.subCategory &&
                                        <Grid item xs={12}>
                                            <PaddedPaper>
                                                <Grid container spacing={2} style={{alignItems: 'flex-end'}}>
                                                    <Grid item xs={6}>
                                                        <Typography variant='h6'>
                                                            Customers
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}></Grid>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            label='Search Customers'
                                                            fullWidth
                                                            value={filter.customer}
                                                            onChange={e=>this.setState(this.InputHelper.setNestedValue(e.target.name, e.target.value))}
                                                            name='filter.customer'
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={2}>
                                                        <AutoCompleteSelect
                                                            label="Customers *"
                                                            options={[
                                                                { label: 'All Customers In System', value: 'all' },
                                                                { label: 'System Customers In Category', value: 'in' },
                                                            ]}
                                                            value={addCustFormData.addType}
                                                            onChange={(e) => 
                                                                {
                                                                    this.InputHelper.handleValueChange('addCustFormData.customers')(
                                                                        _.map(
                                                                            _.find(categories, {cm_category: addCustFormData.category, cm_name: addCustFormData.subCategory}).customers,
                                                                            i => i.ctcm_c_id
                                                                        )
                                                                    )
                                                                    this.InputHelper.handleSelectChange('addCustFormData.addType')(e)
                                                                }
                                                            }
                                                            fullWidth
                                                            noClear
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                    </Grid>
                                                    <Grid item xs={4} className='buttonRow' style={{marginTop: 0}}>
                                                        <Button color='primary' variant='contained'
                                                            onClick={
                                                                () => {
                                                                    this.props.deployConfirmation(
                                                                        'Are you sure you want to link these customers to this marketing subcategory?',
                                                                        'Link Customers',
                                                                        this.linkCustomers
                                                                    )
                                                                }
                                                            }
                                                            disabled={
                                                                !addCustFormData.category ||
                                                                !addCustFormData.subCategory ||
                                                                !addCustFormData.addType ||
                                                                addCustFormData.customers.length === 0
                                                            }
                                                        >
                                                            Update Customer Marketing
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {/*base filter on the original marketing, so that adding an item that was originally removed now shows under the removed filter */}
                                                        <CiDataTable
                                                            config={{
                                                                key: 'cust_id',
                                                                filter: true,
                                                            }}
                                                            rows={
                                                                _.filter(
                                                                    addCustFormData.addType === 'all' ? this.state.customers : 
                                                                    _.filter(this.state.customers, c => _.find(
                                                                        _.filter(categories, {cm_category: addCustFormData.category}),
                                                                        i => _.find(i.customers, {ctcm_c_id: c.cust_id})
                                                                    ))
                                                                    , c => (!filter.customer || (filter.customer && c.cust_name.toLowerCase().includes(filter.customer.toLowerCase()))) &&
                                                                        ( filter.selected === 'All' || (
                                                                            filter.selected === 'Added' && addCustFormData.ogCustomers.includes(c.cust_id)
                                                                        ) || (
                                                                            filter.selected === 'Removed' && !addCustFormData.ogCustomers.includes(c.cust_id)
                                                                        )
                                                                ))
                                                            }
                                                            columns={[
                                                                {
                                                                    heading: 'Customer',
                                                                    field: 'cust_name',
                                                                },
                                                                {
                                                                    heading: 'Removed',
                                                                    field: c => 
                                                                        <Checkbox
                                                                            noMargin
                                                                            color='secondary'
                                                                            name='remove'
                                                                            checked={!addCustFormData.customers.includes(c.cust_id)}
                                                                            onClick={()=>this.InputHelper.handleArrayAddRemove('addCustFormData.customers')(c.cust_id)}
                                                                        />,
                                                                    alignment: 'center',
                                                                    sizeToContent: true,
                                                                },
                                                                {
                                                                    heading: 'Added',
                                                                    field: c => 
                                                                        <Checkbox
                                                                            noMargin
                                                                            color='primary'
                                                                            name='customers'
                                                                            checked={addCustFormData.customers.includes(c.cust_id)}
                                                                            onClick={()=>this.InputHelper.handleArrayAddRemove('addCustFormData.customers')(c.cust_id)}
                                                                        />,
                                                                    alignment: 'center',
                                                                    sizeToContent: true,
                                                                }
                                                            ]}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className='buttonRow'>
                                                        <Button color='primary' variant='contained'
                                                            onClick={
                                                                () => {
                                                                    this.props.deployConfirmation(
                                                                        'Are you sure you want to link these customers to this marketing subcategory?',
                                                                        'Link Customers',
                                                                        this.linkCustomers
                                                                    )
                                                                }
                                                            }
                                                            disabled={
                                                                !addCustFormData.category ||
                                                                !addCustFormData.subCategory ||
                                                                !addCustFormData.addType ||
                                                                addCustFormData.customers.length === 0
                                                            }
                                                        >
                                                            Update Customer Marketing
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                
                                                
                                            </PaddedPaper>
                                        </Grid>
                                    }
                                </>
                            }
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    deployDialog:       (content, title, size)    => dispatch(deployDialog(content, title, null, size)),
    closeDialog:        ()                        => dispatch(closeDialog()),
})

export default connect(null, mapDispatchToProps) (CustomerMarketing);