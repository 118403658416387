import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ViewIcon from '@material-ui/icons/Launch';
import _ from 'lodash';
import React from 'react';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';

const initialState = props => ({
    dialog: {
        open: false,
        title: '',
        content: null,
        size: 'md'
    }
})
class StaffAccessItems extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }
    // showDashboardAccess = (dashboard) => {
    //     const { dashboardAccess } = this.props;
    //     this.setState({
    //         dialog: {
    //             ...this.state.dialog,
    //             open: true,
    //             title: `Dashboard Access: ${dashboard}`,
    //             content: <StaffDashboardAccess unfilteredDashboardAccess={this.props.unfilteredDashboardAccess} staffAccess={this.props.staffAccess} dashboardAccess={dashboardAccess[dashboard]} staffId={this.props.staffId} onClose={()=>this.setState({
    //                 dialog: {
    //                     ...this.state.dialog,
    //                     open: false,
    //                 }
    //             })} />,
    //             size: 'md'
    //         }
    //     })
    // }

    render() {
        const { accessItems, toggleAccess, checkAllToggle, staffAccess, showPageExample, dataLoading, dashboardAccess } = this.props;
        return (
            dataLoading ?
                <LoadingCircle />
            :
                <React.Fragment>
                    {_.map(accessItems, item => {
                        let nestedDepth = (this.props.nestedDepth ? this.props.nestedDepth : 1);
                        let dashItem = _.find(_.keys(dashboardAccess), k => `dashboard-${k.toLowerCase()}-dashboard` === item.access_reference );
                        return (
                            <React.Fragment key={item.access_id}>
                                {(nestedDepth === 1) ? 
                                    (
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography component="div" style={{color:'white'}}>
                                                    <Checkbox style={{color:'white'}}
                                                            name={item.access_id.toString()}
                                                            onClick={checkAllToggle}
                                                            checked={staffAccess.hasOwnProperty(item.access_id)} />
                                                    {item.access_name}
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            {item.children && (
                                                <ExpansionPanelDetails>
                                                    <List style={{width:'100%'}}>
                                                        {item.children && (
                                                            <StaffAccessItems unfilteredDashboardAccess={this.props.unfilteredDashboardAccess} toggleAccess={toggleAccess} staffAccess={staffAccess} accessItems={item.children} nestedDepth={nestedDepth + 1} showPageExample={showPageExample} dashboardAccess={dashboardAccess} staffId={this.props.staffId}/>
                                                        )}
                                                    </List>
                                                </ExpansionPanelDetails>
                                            )}
                                        </ExpansionPanel>
                                    )
                                    :
                                    (
                                        <React.Fragment>
                                            <ListItem style={{paddingLeft: (50 * (nestedDepth - 2))}} key={item.access_id} dense>
                                                <Checkbox color="primary"
                                                        name={item.access_id.toString()}
                                                        onClick={toggleAccess}
                                                        checked={staffAccess.hasOwnProperty(item.access_id)} />
                                                <ListItemText>
                                                    {item.access_name}
                                                </ListItemText>
                                                {(item.access_route_example || dashItem) && (
                                                    <ListItemSecondaryAction>
                                                        {/*dashItem && (dashboardAccess[dashItem].length > 0) &&
                                                            <IconButton onClick={() => this.showDashboardAccess(dashItem)}>
                                                                <ViewDashboard style={{color:'rgba(2,130,198,0.7)'}} />
                                                            </IconButton>
                                                        */}
                                                        {item.access_route_example && (
                                                            <IconButton onClick={() => { showPageExample(item.access_route_example) }}>
                                                                <ViewIcon style={{color:'rgba(2,130,198,0.7)'}} />
                                                            </IconButton>
                                                        )}
                                                    </ListItemSecondaryAction>
                                                )}
                                            </ListItem>
                                            {item.children && (
                                                <StaffAccessItems unfilteredDashboardAccess={this.props.unfilteredDashboardAccess} toggleAccess={toggleAccess} staffAccess={staffAccess} accessItems={item.children} nestedDepth={nestedDepth + 1} showPageExample={showPageExample} dashboardAccess={dashboardAccess}  staffId={this.props.staffId} />
                                            )}
                                        </React.Fragment>
                                    )
                                } 
                            </React.Fragment>
                        )
                    })}
                    {this.state?.dialog?.open &&
                        <Dialog
                            open={this.state.dialog.open}
                            maxWidth={this.state.dialog.size}
                            fullWidth
                        >
                            <DialogTitle>{this.state.dialog.title}</DialogTitle>
                            <DialogContent>
                                {this.state.dialog.content}
                            </DialogContent>
                        </Dialog>
                    }
                </React.Fragment>
        ) 
    }
}

export default StaffAccessItems;