export const API_URL = 'https://api.myclenaware.crushedice.biz/v1';
export const TRANSFERS_URL = 'https://transfers.myclenaware.crushedice.biz/';
export const ADDRESS_LOOKUP_URL = 'https://address-lookup.crushedice.biz';
export const ADDRESS_LOOKUP_API_KEY = 'mf9tjSa3SLRqdnwEvPQsL3AuwQ9zdj47WYRnHypekqwmxGg65tbge2uZfcJXMf93MtprrDEF6HuHLPj2sKXMs8tHAprGqgUApxLMT7krurF8B6wukSe5zAtGmpw52pYt';
export const RECAPTCHA_SITE_KEY = '6LdOb68UAAAAAIgrC5vTu1mqvMCeW2QmkfMBS0EN';
export const IN_DEV = false;
export const SHOW_DEV_ALERT = true;
export const MAX_UPLOAD_LIMIT_SIZE = '1.5GB';
export const CF_COOKIE_DOMAIN = 'crushedice.biz';
export const SHORTLINK_BASE_URL = 'clen-uk.crushedice.biz';
export const CLIENT_VERSION = '1.1';
export const S3_BUCKET_URL = 'https://files.myclenaware.crushedice.biz/';
export const GIPHY_API_KEY = 'HmxaePDRvV6kqBEwn4jRmrc1YPA6eSOM';
export const GIPHY_RATING = 'R';
export const TROLL_IMAGE = 'https://s3.eu-west-2.amazonaws.com/files.myclenaware.co.uk/public/troll.png';
export const DEFAULT_PAGINATION_PAGE = 0;
export const DEFAULT_PAGINATION_ROWS_PER_PAGE = 100;

export const CLENAWARE_RED     = '#E74C3C';
export const CLENAWARE_BLUE    = '#0282C6';
export const CLENAWARE_GREEN   = '#5da14d';
export const CLENAWARE_GREY    = '#dcdcdc';
export const CLENAWARE_ORANGE  = '#F39012';
export const CLENAWARE_YELLOW  = '#F1C40F';

export const specialCharacterWhiteList = /[^a-zA-Z0-9/\-,.'"&();| ]/;

/* Google Maps */
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBdMAuM3q0ZITknoZiSHelTzJBLrHBmFrM';

/* error tracking */
/*
export const SENTRY_DSN = "https://47ee28c493d04dcfb2b69d6bc3563b2b@o1421402.ingest.sentry.io/6769147";
export const SENTRY_ENV = 'dev';
export const SENTRY_TRACES_SAMPLE_RATE = 1.0;
*/

export const TEST_TOUCH_SCREEN_ELEMENTS = false;
export const FORCE_WAREHOUSE = false;
export const MANUAL_QR_CODE_ENTRY = true;

export const restockFeeMultiplier = 0.25;

export const DEMO_CUST_ID = 7;

/* VoIP */
export const VOIP_DEBUG = false;
export const VOIP_DEBUG_NUMBER = "01933666244";
export const VOIP_APP_ID = "oauth2-clenaware-0131201001718108893";
export const VOIP_REDIRECT_URL = "https://myclenaware.crushedice.biz";
export const VOIP_PBX_URL = "https://clenaware.wildixin.com";
export const VOIP_PRIORITY_QUEUES = ["Equipment_Sales", "Service_Technical", "Spare_Parts", "Accounts"];

/* emails */
export const DEFAULT_EMAIL = 'default@crushedice.biz';
export const WEB_EMAIL = 'web@crushedice.biz';
export const ACCOUNTS_EMAIL = 'accounts@crushedice.biz';
export const SALES_EMAIL = 'sales@crushedice.biz';
export const WEB_SERVICE_EMAIL = 'webService@crushedice.biz';

/* contact info */
export const WEB_PHONE = '+44 (0) 1933 666 244';
export const WEB_FAX =  '+44 (0) 1933 665 584';

export const FORM_SYSTEM_CAT_ID_MACHINE_INSTALLATION = 4;

