import React from 'react';
import API from 'API';
import _ from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';

import { Divider, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

import AllIcon from 'Components/Common/Icons/AllIcon';
import SearchInputWithButton from 'Components/Common/Inputs/SearchInputWithButton';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import CustomerAccount from './CustomerAccount';
import LinkInvoicesDialog from './LinkInvoicesDialog';

import { SystemColors, colors } from 'Helpers/ColourHelper';

import { deployDialog } from 'Actions/Dialog/Dialog';


const initialState = {
    customer:       {},
    invoices:       {},
    access:         {},
    addresses:      [],
    selected:       null,
    isLoading:      false,
    singleAddress:  false,
    keywords:       '',
    notLinked:      [],
    notLinkedCn:    [],
    totals:         {
        debit:  0, 
        credit: 0, 
    },
    showInactiveSites: true
}

class CustomerAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            access:         this.props.access,
            customer:       this.props.customer,
            selected:       this.props.customer.account_addresses.length === 1 && _.first( this.props.customer.account_addresses ).address_id,
            singleAddress:  this.props.customer.account_addresses.length === 1
        }
    }

    componentDidMount() {
        if (!this.state.selected) this.setState({isLoading:true});
        this.getAddressList(); 
        this.getNotLinked(); 
        this.getNotLinkedCn();
    }

    getAddressList = () => {
        API.get(`/customers/${this.props.customer.cust_id}/accounts/addresses`)
        .then(res => {
            this.setState({
                addresses: res.data, 
                totals: {
                    debit:  _.sumBy(res.data,'d'),
                    credit: _.sumBy(res.data,'c'),
                    total:  _.sumBy(res.data,'t'),
                    gross:  _.sumBy(res.data,'g'),
                },
                isLoading: false
            });
        })
    }

    getNotLinked = () => {
        API.get(`/customers/${this.props.customer.cust_id}/accounts/addresses/invoicesNotLinked`)
        .then(res => { this.setState({notLinked: res.data}); })
    }

    getNotLinkedCn = () => {
        API.get(`/customers/${this.props.customer.cust_id}/accounts/addresses/creditNotesNotLinked`)
        .then(res => { this.setState({notLinkedCn: res.data}); })
    }

    handleSelectAddress       = i  => { this.setState({selected: i});}
    handleRemoveSelectAddress = () => { this.setState({selected: 0});}

    handleSearchKeyword = (e) => {
        this.setState({keywords: e.target.value});
    };

    handleResetSearch = () => {
        this.setState({keywords: ''});
    }

    render(){
        const { isLoading, addresses, notLinked, notLinkedCn, totals} = this.state;
        const { classes }                      = this.props;
        return (
            <>
                {isLoading ? <LoadingCircle/> :
                    <>
                        {!!notLinked.length && 
                            <Grid xs={12} spacing={3} className={classes.hover} style={{padding:'1em', color:'white', backgroundColor: colors.red, marginTop: '-0.2em'}}>
                                <Typography style={{fontWeight:'bold'}}
                                    onClick={()=>{this.props.deployDialog(  <LinkInvoicesDialog 
                                                                                addresses={addresses} 
                                                                                invoices={notLinked} 
                                                                                callback={this.getNotLinked} 
                                                                                customer={this.props.customer.cust_id}
                                                                            />, 'Link Invoices', null, 'lg')}}
                                >{notLinked.length} Invoices Aren't Linked to Accounts, Click to assign</Typography>
                            </Grid>
                        }
                        {!!notLinkedCn.length && 
                            <Grid xs={12} spacing={3} className={classes.hover} style={{padding:'1em', color:'white', backgroundColor: colors.red, marginTop: !!notLinked.length || this.state.selected ? '' : '-0.2em', borderTop: !!notLinked.length ? '1px solid white': ''}}>
                                <Typography style={{fontWeight:'bold'}}
                                    onClick={()=>{this.props.deployDialog(  <LinkInvoicesDialog 
                                                                                addresses={addresses} 
                                                                                invoices={notLinkedCn} 
                                                                                callback={this.getNotLinkedCn} 
                                                                                customer={this.props.customer.cust_id}
                                                                                creditNote={1}
                                                                            />, 'Link Credit Notes', null, 'lg')}}
                                >{notLinkedCn.length} Credit Notes Aren't Linked to Accounts, Click to assign</Typography>
                            </Grid>
                        }
                        {this.state.selected ? 
                            <CustomerAccount back={!this.state.singleAddress && this.handleRemoveSelectAddress} aid={this.state.selected} cid={this.props.customer.cust_id} /> :
                            <Grid container spacing={5} xs={12} style={{margin:0, marginTop: '0.5em'}} >
                                <Grid item container spacing={3} xs={12}>
                                    <Grid item xs={9}>
                                        <Grid container xs={12}>
                                            <Grid item xs={10}>
                                                <PaddedPaper >
                                                    <Grid container spacing={2} style={{justifyContent: 'center'}}>
                                                        <Grid item>
                                                            Total Credit
                                                            <Typography variant='h4' style={{textAlign: 'center'}}>{(totals.debit - totals.credit) < 0 ? '-' : ''}£{parseFloat(Math.sqrt(Math.pow((totals.debit - totals.credit),2))).toFixed(2)}</Typography>
                                                        </Grid>
                                                        <Grid item >
                                                            <br/>
                                                            <AllIcon size='large' icon={'slash-forward'} noMargin />
                                                        </Grid>
                                                        <Grid item>
                                                            Total Limit
                                                            <Typography variant='h4' style={{textAlign: 'center'}}>£{parseFloat(totals.gross).toFixed(2)}</Typography>
                                                        </Grid>
                                                        <Grid item >
                                                            <br/>
                                                            <AllIcon size='large' icon={'slash-forward'} noMargin />
                                                        </Grid>
                                                        <Grid item>
                                                            Total Remaining
                                                            <Typography variant='h4' style={{
                                                                textAlign: 'center', 
                                                                color: (totals.gross - ( totals.debit - totals.credit )) < 0 ? 
                                                                    colors.red : 
                                                                    (parseFloat(Math.sqrt(Math.pow(totals.gross - ( totals.debit - totals.credit ),2))) < ( totals.gross / 10 ) ? colors.orange : colors.green)}}
                                                            >£{parseFloat(Math.sqrt(Math.pow(totals.gross - ( totals.debit - totals.credit ),2))).toFixed(2)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid item xs={2} style={{paddingLeft:'1.5em'}}>
                                                <PaddedPaper style={{textAlign:'center', padding: 10}}>
                                                    <div style={{marginBottom:5}}>Avg. Payment / Term Days</div>
                                                    <Grid container xs={12} spacing={1} justifyContent='center' style={{justifyContent: 'center'}}>
                                                        <Grid item style={{fontSize:31, color: parseInt(_.meanBy(_.filter(addresses, i=>i.m[1]), i=>{return i.m[1]})) < parseInt(_.meanBy(_.filter(addresses, i=>i.m[0]), i=>{return i.m[0]})) && colors.red}} >
                                                            {_.isNaN(parseInt(_.meanBy(_.filter(addresses, i=>i.m[0]), i=>{return i.m[0]}))) ? 0 : parseInt(_.meanBy(_.filter(addresses, i=>i.m[0]), i=>{return i.m[0]}))}
                                                        </Grid>
                                                        <Grid item style={{fontSize:31}} >/</Grid>
                                                        <Grid item style={{fontSize:31}} >{_.isNaN(parseInt(_.meanBy(_.filter(addresses, i=>i.m[1]), i=>{return i.m[1]}))) ? 0 : parseInt(_.meanBy(_.filter(addresses, i=>i.m[1]), i=>{return i.m[1]}))}</Grid>
                                                    </Grid> 
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                        
                                        <Grid container spacing={3} style={{marginTop:'0.5em'}}>
                                            <Grid item xs={4}>
                                                <PaddedPaper style={{padding: '0.5em'}}>
                                                    <Grid container>
                                                        <Grid item><Typography>Due:</Typography></Grid>
                                                        <Grid item style={{paddingLeft:'1em', paddingRight:'1em'}}><Typography>{_.sumBy(addresses, i=>{return i.l.d})}</Typography></Grid>
                                                        <Grid item><Typography>£{_.sumBy(addresses, i=>{return i.l.t.d}).toFixed(2)}</Typography></Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <PaddedPaper style={{padding: '0.5em'}}>
                                                    <Grid container>
                                                        <Grid item><Typography>Overdue:</Typography></Grid>
                                                        <Grid item style={{paddingLeft:'1em', paddingRight:'1em'}}><Typography>{_.sumBy(addresses, i=>{return i.l.o})}</Typography></Grid>
                                                        <Grid item><Typography>£{_.sumBy(addresses, i=>{return i.l.t.o}).toFixed(2)}</Typography></Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <PaddedPaper style={{padding: '0.5em'}}>
                                                    <Grid container>
                                                        <Grid item><Typography>Credit Notes Due:</Typography></Grid>
                                                        <Grid item style={{paddingLeft:'1em', paddingRight:'1em'}}><Typography>{_.sumBy(addresses, i=>{return i.l.n})}</Typography></Grid>
                                                        <Grid item><Typography>-£{_.sumBy(addresses, i=>{return i.l.t.n}).toFixed(2)}</Typography></Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <PaddedPaper style={{padding: '0.6em'}}>
                                            <div style={{marginBottom:10, textAlign:'center'}}>Break Down</div>
                                            <Grid container xs={12} spacing={0}>
                                                <Grid item xs={5}><Typography>Current:</Typography></Grid>       
                                                <Grid item xs={2}>{parseInt(_.sumBy(addresses, i=>{return i.b.ccurrent}))}</Grid>
                                                <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(addresses, i=>{return i.b.current})).toFixed(2)}</Typography></Grid>
                                                <Grid item xs={5}><Typography>Over 30 days:</Typography></Grid>  
                                                <Grid item xs={2}>{parseInt(_.sumBy(addresses, i=>{return i.b.co30}))}</Grid>
                                                <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(addresses, i=>{return i.b.o30})).toFixed(2)}</Typography></Grid>
                                                <Grid item xs={5}><Typography>Over 60 days:</Typography></Grid>  
                                                <Grid item xs={2}>{parseInt(_.sumBy(addresses, i=>{return i.b.co60}))}</Grid>
                                                <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(addresses, i=>{return i.b.o60})).toFixed(2)}</Typography></Grid>
                                                <Grid item xs={5}><Typography>Over 90 days:</Typography></Grid>  
                                                <Grid item xs={2}>{parseInt(_.sumBy(addresses, i=>{return i.b.co90}))}</Grid>
                                                <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(addresses, i=>{return i.b.o90})).toFixed(2)}</Typography></Grid>
                                                <Grid item xs={5}><Typography>Older:</Typography></Grid>         
                                                <Grid item xs={2}>{parseInt(_.sumBy(addresses, i=>{return i.b.colder}))}</Grid>
                                                <Grid item xs={5}><Typography>£{parseFloat(_.sumBy(addresses, i=>{return i.b.older})).toFixed(2)}</Typography></Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} style={{paddingTop: 4, paddingBottom: 4 }}>
                                    <Divider />
                                </Grid>

                                <Grid item container spacing={3} xs={12}>
                                    <Grid item >
                                        <SearchInputWithButton 
                                            placeholder="Search..."
                                            value={this.state.keywords}
                                            onChange={this.handleSearchKeyword}
                                            onClick={this.handleResetSearch}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {_.filter(addresses, site => site.z).length > 0 &&
                                            <FormControlLabel
                                                style={{marginLeft: '1em'}}
                                                control={
                                                    <Switch label="Hide Inactive" color='primary'  value={this.state.showInactiveSites} onClick={()=>{
                                                        this.setState({showInactiveSites: !this.state.showInactiveSites});
                                                    }}/>
                                                }
                                                label="Hide Inactive"
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                {_.map( _.orderBy( _.filter( 
                                    _.filter(
                                        addresses,
                                        i => !this.state.showInactiveSites ? !i.z : true
                                    )
                                    , i => {return ((!this.state.keywords || this.state.keywords === '')) ? true :(i.a.toLowerCase().search(this.state.keywords.toLowerCase()) > -1);}), 't', 'desc'
                                    ), i => 
                                    <Grid item xs={4}>
                                        <PaddedPaper className={i.z ? classes.paperRed : classes.paperGrey} onClick={()=>{this.handleSelectAddress(i.i)}}>
                                            <Grid container xs={12} style={{height:'100%'}} alignContent='space-between'>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5">{i.a}</Typography>
                                                    {!!i.z && 
                                                        <Typography variant='h6' style={{textAlign: 'center', marginTop: 5}}> 
                                                            <AllIcon icon='warning' size='small' />
                                                            <span >Site Is Inactive</span>
                                                            <AllIcon icon='warning' size='small' style={{marginLeft:8}}/>
                                                            
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <PaddedPaper className={  i.t > i.g && classes.overCredit } style={{marginTop: '1em', padding: '1em'}}>
                                                        <div style={{width: '100%', height: '100%', position: 'relative'}}>
                                                            <Grid container xs={12} style={{justifyContent: 'center'}}>
                                                                <Grid item>
                                                                    Credit
                                                                    <Typography variant='h5' style={{textAlign: 'right'}}>{i.t < 0 && '-'}£{parseFloat(Math.sqrt(Math.pow(i.t,2))).toFixed(2)}</Typography>
                                                                </Grid>
                                                                <Grid item >
                                                                    <br/>
                                                                    <AllIcon size='medium' icon={'slash-forward'} style={{color: i.t > i.g && SystemColors().red}} />
                                                                </Grid>
                                                                <Grid item>
                                                                    Limit
                                                                    <Typography variant='h5'>£{parseFloat(i.g).toFixed(2)}</Typography>
                                                                </Grid>
                                                                <Grid item >
                                                                <br/>
                                                                    <AllIcon size='medium' icon={'slash-forward'} style={{color: i.t > i.g && SystemColors().red}} />
                                                                </Grid>
                                                                <Grid item>
                                                                    Remaining
                                                                    <Typography variant='h5' style={{color: ((i.g - i.t) < 0) ? colors.red : colors.green}} >£{parseFloat(Math.sqrt(Math.pow(i.g - i.t,2))).toFixed(2)}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </PaddedPaper>
                                                    <Grid container spacing={3} xs={12}  style={{margin:0,marginTop: '1em'}}>
                                                        <Grid item xs={3}>
                                                            <PaddedPaper style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
                                                                <AllIcon style={{margin: 0}} icon='alarm-clock' size='medium'/>
                                                                <Typography style={{textAlign: 'center', marginTop: '0.5em'}} variant='h5'>{i.l.d}</Typography>
                                                            </PaddedPaper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <PaddedPaper style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
                                                                <AllIcon style={{margin: 0}} icon='alarm-exclamation' size='medium'/>
                                                                <Typography style={{textAlign: 'center', marginTop: '0.5em'}} variant='h5'>{i.l.o}</Typography>
                                                            </PaddedPaper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <PaddedPaper style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
                                                                <AllIcon style={{margin: 0}} icon='minus' size='medium'/>
                                                                <Typography style={{textAlign: 'center', marginTop: '0.5em'}} variant='h5'>{i.l.n}</Typography>
                                                            </PaddedPaper>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <PaddedPaper style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
                                                                <AllIcon style={{margin: 0}} icon='check' size='medium'/>
                                                                <Typography style={{textAlign: 'center', marginTop: '0.5em'}} variant='h5'>{i.l.c}</Typography>
                                                            </PaddedPaper>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                )}
                            </Grid>
                        }
                    </>
                }
            </>
        )
    }

}

const styles = theme => ({
    paperGrey: {
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(3),
        },
        backgroundColor:'#f8f9fa',
        '&:hover': {
            cursor: 'pointer'
        },
        height: '100%'
    },
    paperRed: {
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(3),
        },
        backgroundColor: colors.importantInfo,
        '&:hover': {
            cursor: 'pointer'
        },
        height: '100%'
    },
    paperDisabled: {
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            padding: theme.spacing(3),
        },
        backgroundColor:'#fcc6c5',
    },
    overCredit: {
        border: `1px solid ${SystemColors().red}`,         
        color:  SystemColors().red
    },
    hover: {'&:hover': {cursor: 'pointer'}}
});

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, variant, size) => {
            dispatch(deployDialog(content, title, variant, size))
        },
    }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(CustomerAccounts))