import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, TextField, Typography } from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import InfoIcon from 'Components/Common/Icons/InfoIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import InfoBox from 'Components/Common/reports/InfoBox';
import { checkMailingListContacts } from 'Helpers/EmailHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import IconHelper from 'Helpers/IconHelper';
import InputHelper from 'Helpers/InputHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import CSVContactsDialog from './CSVContactsDialog';

import CustomMailingList from './ContactAdding/CustomMailingList';
import CustomerMarketing from './ContactAdding/CustomerMarketing';
import Manual from './ContactAdding/Manual';
import ServicePlan from './ContactAdding/ServicePlan';
import BusinessType from './ContactAdding/BusinessType';
import LegalStatus from './ContactAdding/LegalStatus';

const initialState = () => ({
    
    csvFormData: {
        csv: null,
    },
    dialogFormData: {
        showDialog: false,
    },
    eligibleContacts: {
        contacts: [],
        toAdd: [],
        unsubed: [],
        na: [],
        invalid: [],
        duplicate: [],

        loading: false,
    },
    lists: {
        discounts: [],
        contacts: [],
        emailValidation: {
            error: [],
            warning: {},
        }
    },
    formErrors: {},

    

    isLoading: true,
    contactsLoading: false,
    formData: {
        name: '',
        description: '',
        logicType: 'manual',
        logicData: null,
        contacts: [],
    },
    contacts: {
        dontAdd: [],
        toAdd: [],
    },
    eligibleContactsDialog: {
        open: false,
        withSub: false,
    },
    addLoading: false,
})

class NewMailingListDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.inputHelper = new InputHelper(this);
    }

    componentDidMount() {
        this.getLists();
    }

    getLists = () => {
        Promise.all([
            API.get('/accounts/customerDiscounts/byName'),
            API.get('/customers/paymentTerms'),
            API.get('/parts/all' , {params: {active: true}}),
            API.get('/parts/catagories'),
            API.get('/marketing/campaigns/mailingLists/eligibleContacts', {params: {breakdown: true}}),
            API.get(`/marketing/contacts/emailValidation/all`),
            API.get('/marketing/customerMarketing')
        ]).then(([discounts, paymentTerms, parts, partCats, contacts, validation, customerMarketing]) => {

            let warnings = {};
            _.each(_.filter(validation.data, (validation) => validation.eav_type === 'domains'), i => {
                warnings[i.eav_primary] = JSON.parse(i.eav_secondary)
            });
            this.setState({
                isLoading: false,
                lists: {
                    discounts: _.map(discounts.data, i => ({label: i.cdn_name, value: i.cdn_id})),
                    paymentTerms: _.map(paymentTerms.data, i => ({label: i.payment_term_name, value: i.payment_term_id})),
                    parts: _.map(parts.data, i => ({label: i.part_number + ' - ' + i.part_description, value: i.part_id})),
                    partCategories: _.map(partCats.data, i => ({label: i.pc_name, value: i.pc_id})),
                    contacts: contacts.data.contacts,
                    emailValidation: {
                        error: _.map(_.filter(validation.data, (validation) => validation.eav_type === 'ending'), i => i.eav_primary),
                        warning: warnings,
                        bounce: _.map(_.filter(validation.data, (validation) => validation.eav_type === 'bounce'), i => i.eav_primary),
                        black: _.map(_.filter(validation.data, (validation) => validation.eav_type === 'black'), i => i.eav_primary),
                    },
                    customerMarketing: customerMarketing.data,
                }
            })
        })
    }

    resetContacts = () => {
        this.setState({
            contacts: initialState().contacts,
            formData: {
                ...this.state.formData,
                logicData: null,
            }
        })
    }

    checkContacts = contacts => (logicData = null) => {
        this.setState({
            contactsLoading: true,
            contacts: initialState().contacts,
        }, async () => {
            this.setState({
                formData: {
                    ...this.state.formData,
                    logicData: logicData,
                }
            }, async () => {
                let data = await checkMailingListContacts(contacts)
                this.setState({
                    contacts: {
                        toAdd: data.toAdd,
                        dontAdd: data.dontAdd,
                    },
                    contactsLoading: false,
                })
            })
        })
    }

    showEligibleContactsPreview = withSub => () => {
        this.setState({
            eligibleContactsDialog: {
                open: !this.state.eligibleContactsDialog.open,
                withSub: withSub,
            }
        })
    }

    handleClose = () => {
        this.setState(initialState(), this.props.onClose);
    }

    onContactsDialogSubmit = (contacts) => (forceCommit=false) => () => {
        this.setState({
            contacts: contacts,
            dialogFormData: {
                ...this.state.dialogFormData,
                showDialog: false,
            }
        })
    }

    showCsvContactsDialog = () => {
        this.setState({
            dialogFormData: {
                ...this.state.dialogFormData,
                showDialog: true,
            }
        })
    }

  

    handleSubmit = () => {
        this.setState({
            addLoading: true,
        }, ()=> {
            let formData = {...this.state.formData};

            if (formData.logicType === 'CustomerMarketing' || formData.logicType === 'Custom' || formData.logicType === 'ServicePlan' || formData.logicType === 'BusinessType' || formData.logicType === 'LegalStatus'){
                formData.logicData = JSON.stringify(formData.logicData);
            }

            API.post(`/marketing/campaigns/mailingLists`, {...formData, contacts: JSON.stringify(_.map(this.state.contacts.toAdd, j => ({
                contact_id: j.contact_id,
                contact_email: j.contact_email,
                contact_name: j.contact_name,
                valid: j.valid,
            })))})
            .then(res => {
                if (res.data.errors){
                    this.setState({formErrors: formatValidationErrors(res.data.errors)});
                } else {
                    this.props.onClose();
                    window.location.href = `/marketing/mailingLists/${res.data.ml.ml_id}`;
                }
            })
        })
    }

    render() {
        const { formData, lists, isLoading, formErrors, eligibleContactsDialog, csvFormData, dialogFormData, contacts, contactsLoading } = this.state;

        if(isLoading) {
            return (
                <LoadingCircle />
            )
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField 
                        label="Mailing List Name *"
                        value={formData.name}
                        name='formData.name'
                        onChange={e => this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                        fullWidth
                        error={!!formErrors.name}
                        helperText={formErrors.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Textarea 
                        label="Description"
                        value={formData.description}
                        name='formData.description'
                        onChange={e => this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                        rows={4}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteSelect
                        error={!!formErrors.logicType}
                        helperText={formErrors.logicType}
                        label="Contact Adding *"
                        value={formData.logicType}
                        onChange={e => this.setState(this.inputHelper.setNestedValue('formData.logicType', e.value), this.resetContacts)}
                        fullWidth
                        options={[
                            {
                                label: 'Manually',
                                value: 'manual',
                            },
                            {
                                label: 'By Service Plan Status',
                                value: 'ServicePlan',
                            },
                            {
                                label: 'Customer Marketing',
                                value: 'CustomerMarketing',
                            },
                            {
                                label: 'By Business Type',
                                value: 'BusinessType',
                            },
                            {
                                label: 'By Legal Status',
                                value: 'LegalStatus',
                            },
                            {
                                label: 'Custom',
                                value: 'Custom',
                            }
                        ]}
                        disableSort
                        noClear
                    />
                </Grid>
                {( formData.logicType == 'manual' && [...contacts.toAdd, ...contacts.dontAdd].length > 0) &&
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"s
                            onClick={this.resetContacts}
                            fullWidth
                        >Re-Upload</Button>
                    </Grid>
                }
                {( formData.logicType == 'manual' && [...contacts.toAdd, ...contacts.dontAdd].length === 0) &&
                    <Manual contacts={lists.contacts} onSubmit={this.checkContacts}  />
                }
                {formData.logicType === 'ServicePlan' &&
                    <ServicePlan contacts={lists.contacts} onSubmit={this.checkContacts} logicData={formData.logicData} />
                }
                {formData.logicType === 'CustomerMarketing' &&
                    <CustomerMarketing customerMarketing={lists.customerMarketing} onSubmit={this.checkContacts} logicData={formData.logicData} />
                }
                { formData.logicType == 'BusinessType' &&
                    <BusinessType contacts={lists.contacts} onSubmit={this.checkContacts}  />
                }
                { formData.logicType == 'LegalStatus' &&
                    <LegalStatus contacts={lists.contacts} onSubmit={this.checkContacts}  />
                }
                {formData.logicType === 'Custom' &&
                    <CustomMailingList contacts={lists.contacts} onSubmit={this.checkContacts} logicData={formData.logicData} />
                }

                {([...contacts.toAdd, ...contacts.dontAdd].length > 0 || contactsLoading) &&
                    (contactsLoading ?
                        <LoadingCircle/>: 
                        <>
                            <InfoBox
                                rowSize={3}
                                icon={IconHelper.customers}
                                value={[...contacts.toAdd, ...contacts.dontAdd].length ?? 0}
                                title="Contacts"
                            />
                            <InfoBox
                                rowSize={3}
                                icon={IconHelper.plus}
                                value={contacts.toAdd.length ?? 0}
                                title="Eligible"
                            />
                            <InfoBox
                                rowSize={3}
                                icon={IconHelper.false}
                                value={contacts.dontAdd.length ?? 0}
                                title={<>Not Eligible <InfoIcon info='This includes; unsubscribed contacts, accounts contacts, invalid email addresses, and duplicate email addresses.'/></>}
                            />
                        </>   
                    )
                }

                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant="outlined"
                        onClick={this.handleClose}
                    >
                        Close
                    </Button>
                    {
                        [...contacts.toAdd, ...contacts.dontAdd].length > 0 &&
                        (
                            contacts.dontAdd.length > 0 ?
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.showCsvContactsDialog}
                                >
                                    Check Contacts
                                </Button> :
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={this.showEligibleContactsPreview(false)}
                                >
                                    Preview Eligible Contacts
                                </Button>  
                        )
                    }
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.showEligibleContactsPreview(true)}
                        disabled={
                            !formData.name ||
                            !formData.logicType ||
                            contacts.dontAdd.length > 0 ||
                            contacts.toAdd.length === 0
                        }
                    >
                        Add
                    </Button>
                </Grid>
                {
                    eligibleContactsDialog.open &&
                    <Dialog
                        open={eligibleContactsDialog.open}
                        maxWidth="md"
                        fullWidth
                    >
                        <DialogTitle>{eligibleContactsDialog.withSub ? 'Create Mailing List?' : 'Eligible Contacts'}</DialogTitle>
                        <DialogContent>
                            {this.state.addLoading ?
                                <LoadingCircle /> :
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">
                                            The following contacts will be added to the mailing list:
                                        </Typography>
                                        <List>
                                            {_.map(contacts.toAdd, (contact, i) => 
                                                <ListItem key={i}>
                                                    <ListItemText primary={`${contact.contact_name} ${contact?.customer?.cust_name ? `(${contact.customer.cust_name})` : ''}`} secondary={contact.contact_email} />
                                                </ListItem>
                                            )}
                                        </List>
                                    </Grid>
                                </Grid>
                            }
                        </DialogContent>
                        {!this.state.addLoading &&
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    onClick={this.showEligibleContactsPreview(false)}
                                >{eligibleContactsDialog.withSub ? 'Cancel':'Close'}</Button>
                                {
                                    eligibleContactsDialog.withSub &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleSubmit}
                                    >
                                        Create Mailing List
                                    </Button>
                                }
                            </DialogActions>
                        }
                    </Dialog>
                }
                {dialogFormData.showDialog &&
                    <CSVContactsDialog
                        open={dialogFormData.showDialog}
                        eligibleContacts={[...contacts.toAdd, ...contacts.dontAdd]}
                        contacts={lists.contacts}
                        onSubmit={this.onContactsDialogSubmit}
                    />
                }
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback, variant=null) => {
            dispatch(deployConfirmation(content, title, callback, variant=null))
        },
        deployDialog: (content, title, size, variant=null) => {
            dispatch(deployDialog(content, title, variant, size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

export default connect(null, mapDispatchToProps)(NewMailingListDialog);