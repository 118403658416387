import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { colors } from 'Helpers/ColourHelper';

import Image              from 'Components/Common/ImageWithError/ImageWithError';
import Textarea           from 'Components/Common/Inputs/Textarea';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import Qr                 from 'Components/Common/QrReader/QrReader';
import NumberField        from 'Components/Common/Inputs/NumberField';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = {
    isLoading: true,
    qrError:   null,
    showQr:    true,
    stage:     {},
    parts:     [],
    formData:  {
        part:   {},
        partId: 0,
        qty:    0,
        notes:  null,
        reason: '',
    }
}

class Addition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            stage: this.props.stage,
        };
    }

    componentDidMount() { this.getReasons();}

    getReasons = () => {
        API.get('/worksOrders/returnReasons/all')
        .then(reasons => {
            this.setState({
                reasons:   _.map(reasons.data, i => ({ value: i.worr_reason, label: i.worr_reason })),
            }, this.getParts);
        });
    }

    getParts = () => {
        let parts = [];
        _.each(this.state.stage.instruction.parts, i => {
            parts.push({
                id:     i.part.part_id,
                number: i.part.part_number,
                desc:   i.part.part_description,
                img:    i.part?.default_image?.file_path,
                max:    parseFloat(i.saisp_qty * this.props.worksOrder.wo_qty),
            });
        });

        _.each(_.filter(this.props.worksOrder.pick.details, {wopd_status: 'Not Required'}), i => {
            _.remove(parts, {id: i.part.part_id});
        });

        _.each(_.filter(this.props.worksOrder?.shortage?.details, {wossd_status: 'Not Required'}), i => {
            _.remove(parts, {id: i.part.part_id});
        });

        _.each(this.props.worksOrder.additionals, i => {
            if (i.wobas_wobs_id === this.state.stage.wobs_id) {
                let idx = _.findIndex(parts, {id: i.part.part_id});
                if (idx > -1) {
                    parts[idx].max += parseFloat(i.wobas_qty)
                } else {
                    parts.push({
                        id:     i.part.part_id,
                        number: i.part.part_number,
                        desc:   i.part.part_description,
                        img:    i.part?.default_image?.file_path,
                        max:    parseFloat(i.wobas_qty),
                    })
                }
            }
        });

        _.each(this.props.worksOrder.returns, i => {
            if (i.wobsr_wobs_id === this.state.stage.wobs_id) {
                let idx = _.findIndex(parts, {id: i.part.part_id});
                if (parts[idx]) {
                    parts[idx].max -= parseFloat(i.wobsr_qty);
                }
            }
        });

        this.setState({
            isLoading: false,
            parts: _.filter(parts, i => i.max !== 0),
        })
    
    }

    handleScan = partNumber => {
        if (partNumber) {
            let part = _.find(this.state.parts, {number: partNumber});
            if (part) {
                this.setState({
                    qrError: null,
                    showQr: false,
                    formData: {
                        ...this.state.formData,
                        part:   part,
                        partId: part.id,
                    }
                })
            } else {
                this.setState({
                    qrError: 'Part cannot be returned'
                })
            }
        };
    }

    handleChange = (name, value) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            }
        })
    }

    handleSubmit = () => {
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/build/${this.props.worksOrder.build.wob_id}/stockReturn`, {
            part:       this.state.formData.partId,
            quantity:   this.state.formData.qty,
            stage:      this.state.stage.wobs_id,
            notes:      this.state.formData.notes,
            reason:     this.state.formData.reason,
        })
        .then(res => {
            this.setState({
                formData: initialState.formData
            },this.props.callBack);
        })
    }

    render() {

        const { isLoading, stage, showQr, qrError,  formData, reasons } = this.state;
        const { classes } = this.props;

        if (isLoading) return ( <LoadingCircle /> )

        return (
            <Grid container justify="center" className={`${classes.root} text-center`} style={{height: '100%', marginTop: 70}} spacing={8}>
                <Grid container item className={classes.qrRow}>
                    <Grid item xs={6}>
                        {showQr && <Qr handleScan={this.handleScan} width="100%" /> }
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>
                {qrError && 
                    <Grid item container xs={12} style={{alignItems: 'center'}}>
                        <Grid item container justify="center" className="text-center" spacing={3}>
                            <Typography variant="h5" color="error">{qrError}</Typography>
                        </Grid>
                    </Grid>
                }
                {formData.partId > 0 && 
                <>
                    <Grid item container xs={12} style={{alignItems: 'center'}}>
                        <Grid item container justify="center" className="text-center" spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3">
                                    {formData.part.number}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    {formData.part.desc}<br></br>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify="center" className="text-center" spacing={3}>
                                    <Grid item xs={12}>
                                        <Image src={formData.part.img} className={classes.img} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify="center" className="text-center" spacing={3}>
                                    <Grid item style={{width: 400}}>
                                        <NumberField
                                            id="qty"
                                            name="qty"
                                            label={`Maximum Quantity ${formData.part.max}`}
                                            value={formData.qty}
                                            type="number"
                                            variant="outlined"
                                            onChange={(e)=>{this.handleChange('qty', e)}}
                                            margin="normal"
                                            className={classes.confirmQtyInputContainer}
                                            inputProps={{
                                                className: classes.confirmQtyInput,
                                                min: 0,
                                                pattern: "\d*",
                                                max: formData.part.max,
                                            }}
                                            granularity={1}
                                            hidePlusMinusButtons
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{padding: 0, margin: 0}}></Grid>
                                    <Grid item style={{width: 400}}>
                                        <AutoCompleteSelect
                                            fullWidth
                                            label='Reason *'      
                                            onChange={(e)=>{this.handleChange('reason', e.value)}}                    
                                            value={formData.reason}
                                            options={reasons}
                                            noClear
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{width: 400}}>
                                <Textarea
                                    name        = 'notes'
                                    label       = 'Notes'
                                    onChange={(e)=>{this.handleChange('notes', e.target.value)}}
                                    value       = {this.state.formData.notes}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {formData.qty > formData.part.max && 
                                    <span style={{color: colors.red}}>You cannot return more stock than has been picked</span>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant='contained' color='primary' disabled={!parseFloat(formData.qty) || formData.qty > formData.part.max || !formData.reason} onClick={()=>{
                                    this.props.deployConfirmation('Are you sure you want to return these parts? Note: Stock will be effected', 'Return Stock', this.handleSubmit)
                                }}>Confirm</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>}
            </Grid>
        )
    }
}

const styles = theme => ({
    root: {
        height: '80vh',
        padding: theme.spacing(1)
    },
    img: {
        maxHeight: 330,
        maxWidth: '100%'
    },
    qrRow: {
        maxHeight: '30vw',
        maxWidth: '30vw',
        zIndex: 99999,
        position:'fixed',
        top: '6em',
        left: '5em'
    },
    confirmQtyInputContainer: {
        maxWidth: 400,
    },
    confirmQtyInput: {
        textAlign: 'center',
        maxWidth: 400,
        fontSize: 40,
    },
    [theme.breakpoints.down('sm')]: {
        img: {
            maxHeight: 240,
            maxWidth:  240
        },
        actionText: {
            fontSize: '2rem',
        },
        confirmQtyInputContainer: {
            maxWidth: 120,
        },
        confirmQtyInput: {
            maxWidth: 100,
            padding: 20,
            fontSize: 30,
        },
        largeButton: {
            fontSize: 18
        }
    }
});


function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(Addition))