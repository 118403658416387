import React from 'react';
import Typography from '@material-ui/core/Typography';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Grid from '@material-ui/core/Grid';
import API from '../../../API';
import DataTable from '../../Common/DataTables/CiDataTable';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import BackButton from 'Components/Common/Buttons/BackButton';
import {colors} from 'Helpers/ColourHelper';

class ViewPrice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rows:{},
            part:{},
            currentCost: '',
            isLoading: true,
            redirect: false
        };
    }

    componentDidMount(){
        this.getData(this.props.match.params.id);
    }

    getData = (partId) => {        
        Promise.all([ 
            API.get(`/parts/${partId}/prices`),
            API.get(`/parts/${partId}/costs/default`),
            API.get(`/parts/${partId}`)
        ])
        .then(([result, defaultRes, partRes]) =>  {
            if (partRes.data.errors) {
                this.setState({ redirect: true })
            } else if(result.data){
                Object.keys(result.data).forEach(idx => {
                    let dateFrom = result.data[idx].price_date_from;
                    let dateTo = result.data[idx].price_date_to;
                    result.data[idx].price_date_from = dateFrom && moment(dateFrom).format("DD-MM-YYYY");
                    result.data[idx].price_date_to = dateTo && dateTo === '0000-00-00' ? '' : moment(dateTo).format("DD-MM-YYYY");
                })
                this.setState({
                    isLoading: false,
                    rows: result.data,
                    currentCost: defaultRes.data?.cost_per_unit || '-',                
                    part: partRes.data
                })
            }
        })
    }

    handleChange = (e) => {
        this.setState({
            formData: {
            ...this.state.formData,
            [e.target.name]: e.target.value
            }
        });
    }

    downloadFile = rowData => {
        window.open(rowData.download_url, '_blank');
    }

    render() {
        const { isLoading } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/prices/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Price Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            {(isLoading && (
                                <LoadingCircle />
                            )) || (
                                <React.Fragment>
                                    <Typography variant="h5" gutterBottom style={{marginBottom:25}}>
                                        {this.state.part.part_number + ' - ' + this.state.part.part_description}
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom style={{marginBottom:20}}>
                                        Current Cost: £{this.state.currentCost}
                                    </Typography>
                                    <DataTable 
                                        config={{
                                            key: 'price_id',
                                            pagination: false,
                                            alternatingRowColours: true,
                                            rowProps: rowData => {
                                                return ({
                                                    style: {
                                                        color: rowData.price_default === 'Yes' && colors.green
                                                    }
                                                });
                                            }
                                        }}
                                        columns={[
                                            {
                                                heading: '',
                                                field: rowData => `Price ${rowData.rowNumberReversed}:`
                                            },
                                            {
                                                heading: 'Price',
                                                field: rowData => '£'+rowData.price_total
                                            },
                                            {
                                                heading: 'Gross Margin',
                                                field: rowData => rowData.price_margin+'%'
                                            },
                                            {
                                                heading: 'Change Type',
                                                field: rowData => rowData.price_change_type,
                                                dataRef: 'price_change_type'
                                            },
                                            {
                                                heading: 'Date From',
                                                field: rowData => rowData.price_date_from ? rowData.price_date_from : '-'
                                            },
                                            {
                                                heading: 'Date To',
                                                field: rowData => rowData.price_date_to === '' ? 'Current' : rowData.price_date_to
                                            },
                                            {
                                                actions: rowData => {
                                                    return [
                                                        {name: 'Download', icon: 'download', onClick: this.downloadFile, disabled: !rowData.download_url},
                                                    ]
                                                }
                                            }
                                        ]}
                                        rows={this.state.rows}
                                    />
                                    <div className='buttonRow'>
                                        <BackButton props={this.props} variant="outlined" />
                                    </div>
                                </React.Fragment>
                            )}
                        </PaddedPaper>
                    </Grid>
                </Grid>
            );
        }
    }
}
export default ViewPrice;
