import React        from 'react';
import API          from 'API';
import { find }     from 'lodash';
import {  connect } from 'react-redux';

import { FormControl, Button, Typography, TextField, Grid } from '@material-ui/core';

import DataTable      from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper    from 'Components/Common/Paper/PaddedPaper';
import FALightIcon    from 'Components/Common/Icons/FontAwesome/FALightIcon';
import ImageWIthError from 'Components/Common/ImageWithError/ImageWithError';

import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    access: {
        courierSearch: false,
        viewCourier: false,
        updateCourier: false
    },
    searchName: '',
    searchResults: [],
    isLoading: true
})
class CourierSearch extends React.Component {

    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Courier:courierSearch';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.checkAccess();
        !this.hasPageState() && this.setQuery();
    }

    setQuery = () => {
        const { state = {} } = this.props.location;
        this.setState({
            searchName: state?.searchName || initialState().searchName,
        },
        () => {
            this.getSearchData();
        });
    };

    checkAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/view-courier'),
            API.get('/staff/my/access/check/update-courier')
        ])
        .then(([viewRes, updateRes]) => {
            this.setState({
                ...this.state.access,
                access: {
                    viewCourier: viewRes.data && viewRes.data.has_access,
                    updateCourier: updateRes.data && updateRes.data.has_access
                }
            })
        })
    }

    getSearchData = () => {
        API.get('/couriers', {
            params: {
                searchName: this.state.searchName,
            },
            props: {
                cancellation: true
            }
        })
        .then(result => {
            if(result?.data?.success) {
                this.setState({
                    searchResults: result.data.couriers,
                    isLoading: false
                }, ()=> {
                    this.savePageState();
                });
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            searchName: e.target.value
        }, 
        () => {
            
            this.getSearchData();
        });
    }

    resetSearch = () => {
        this.setState({
            searchName: ''
        },
        () => {
            this.props.history.replace();
            this.clearPageState();
            this.getSearchData();
        });
    }

    getLogoSrc = files => {
        let logo = find(files, {'cour_file_type': 'Logo'});
        return logo ? logo.file_url : '';
    }

    render() {
        const { searchName, isLoading, access, searchResults } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Courier Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField  
                                        id="searchName"
                                        name="searchName"
                                        label="Keywords"
                                        value={searchName}
                                        onChange={this.handleChange}
                                        fullWidth 
                                    />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button 
                                        onClick={this.resetSearch}
                                        variant="outlined"
                                        color="default"
                                    >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'cour_id',
                                isLoading: isLoading,
                                responsiveImportance: true,
                            }}
                            columns={[
                                {
                                    heading: '',
                                    field: rowData => <ImageWIthError src={this.getLogoSrc(rowData.file)} style={{maxWidth: 50, maxHeight: 50}} />,
                                    sizeToContent: true,
                                    alignment: 'center'
                                },
                                {
                                    heading: 'Supplier',
                                    field: rowData => rowData.supplier.supp_company_name,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Name',
                                    field: rowData => rowData.cour_name,
                                    important: true,
                                },
                                {
                                    heading: 'Tracking URL',
                                    field: rowData => rowData.cour_tracking_url,
                                    dataRef: 'cour_tracking_url',
                                    truncate: true
                                },
                                {
                                    heading: 'zones',
                                    field:   rowData => rowData.zones.length,
                                    dataRef: rowData => rowData.zones.length,
                                    alignment: 'center'
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil-alt', link: { pathname: '/courier/update/' + rowData.cour_id, state: {searchName}}, disabled: !access.updateCourier},
                                            {name: 'View', icon: 'search', link: { pathname: '/courier/view/' + rowData.cour_id, state: {searchName}}, disabled: !access.viewCourier}
                                        ]
                                    }
                                }
                            ]}
                            rows={searchResults}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(CourierSearch);