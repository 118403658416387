import React, { Component } from 'react';
import { connect }          from 'react-redux';
import API                  from 'API';
import _                    from 'lodash';

import { Button, FormControl, Grid, TextField, Typography } from '@material-ui/core';

import DataTable   from 'Components/Common/DataTables/CiDataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { clearPageState, getInitialState, hasPageState, savePageState } from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    keywords: '',
    searchResults: {},
    access: {
        updateStock: false
    },
    isLoading: true
});
class MinMaxStockLevels extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'stock:MinMaxStockLevels';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount = () => {
        this.getSearchData();
        this.getAccess();
    }

    getAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/update-stock-levels'),
        ])
        .then(([updateRes]) =>  {
            updateRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        updateStock: updateRes.data.has_access
                    }
                });
        })
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/stock/all' , {
                params: {
                    keywords: this.state.keywords,
                }
            })
            .then((result) => {
                this.setState({
                    ...this.state,
                    searchResults: result.data,
                    isLoading: false
                })
            })
        })
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            [fieldName]: selectedOption && selectedOption.value
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState()
            this.getSearchData();
        });
    };

    reset = () => {
        this.setState({
            keywords: ''
        }, 
        () => {
            this.clearPageState();
            this.getSearchData();
        });
    }

    render() {
        const { access } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Stock Level Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.reset}
                                            variant="outlined"
                                            color="default"
                                    >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'stock_id',
                                pagination:             true,
                                alternatingRowColours:  true,
                                isLoading:              this.state.isLoading,
                                responsiveImportance:   true,
                                persistenceId:          this.persistenceId,
                            }}
                            columns={[
                                {
                                    heading: 'Number',
                                    field: rowData => rowData.part.part_number,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Description',
                                    field: rowData => rowData.part.part_description,
                                    main: true,
                                    important: true,
                                    truncate: true
                                },
                                {
                                    heading: 'Minimum Level',
                                    field: rowData => rowData.stock_minimum_level,
                                    important: true,
                                    sizeToContent: true,
                                    alignment: 'right'
                                },
                                {
                                    heading: 'Maximum Level',
                                    field: rowData => rowData.stock_maximum_level,
                                    important: true,
                                    sizeToContent: true,
                                    alignment: 'right'
                                },
                                {
                                    heading: 'Percentage',
                                    field: rowData => rowData.stock_spare_parts_percentage,
                                    fieldSuffix: '%',
                                    sizeToContent: true,
                                    alignment: 'right'
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil-alt', link: '/stock/update/' + rowData.stock_part_id, disabled: !access.updateStock},
                                        ]
                                    }
                                }
                            ]}
                            rows={_.sortBy(this.state.searchResults, rowData => rowData.part.part_number)}
                        />
                    </PaddedPaper>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(MinMaxStockLevels);