import _ from 'lodash';
import API from 'API';

import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { store } from '../index';

export function formatValidationErrors(errorArray) {
    let validation = [];
    for (let i = 0; i < errorArray.length; i++) {
        if(errorArray[i].field === undefined){
            validation['generic'] = errorArray[i].msg
        }
        else if(Array.isArray(errorArray[i].field)){
            for (let x = 0; x < errorArray[i].field.length; x++) {
                validation[errorArray[i].field[x]] = errorArray[i].msg      
            };
        }
        else {
            validation[errorArray[i].field] = errorArray[i].msg
        }               
    }
    if(validation && validation['generic']) {
        store.dispatch(deploySnackBar("error", validation['generic']))
    }
    return validation;
}

export function formatValidationWarnings(warningArray) {
    let validation = [];
    for (let i = 0; i < warningArray.length; i++) {
        if(warningArray[i].field === undefined){
            validation['generic'] = warningArray[i].msg
        }
        else if(Array.isArray(warningArray[i].field)){
            for (let x = 0; x < warningArray[i].field.length; x++) {
                validation[warningArray[i].field[x]] = warningArray[i].msg      
            };
        }
        else {
            validation[warningArray[i].field] = warningArray[i].msg
        }               
    }
    return validation;
}

export function checkAccess(access){
    if (!_.isArray(access)) access = [access];
    access = _.filter(access, i => !!i);
    if (access.length === 0) return false;
    let promise = [];
    _.each(access, a => promise.push(API.get(`/staff/my/access/check/${a}`)));
    return Promise.all(promise).then((res) => {
        if (res.length === 1) return res[0].data.has_access;
        return _.map(res, r => r.data.has_access);
    });
}