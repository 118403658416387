import API from 'API';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import icons from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';

import { Grid } from '@material-ui/core/';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = {
    isLoading:  true,
    TableData:  {
        completed: [],
        deleted:   [],
    },
}


class CompletedStockTake extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => { 
        this.getData(); 
    }

    getData = () => {
        Promise.all([
            API.get(`/stock/take/all`,{params:{status:'Completed'}}),
            API.get(`/stock/take/all`,{params:{status:'Deleted'}}),
        ]).then(([completedRes, deletedRes]) => {
            this.setState({
                TableData: {
                    completed: completedRes.data,
                    deleted:   deletedRes.data
                },
                isLoading: false
            });
        })
    }

    render() {

        const {isLoading, TableData} = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {isLoading ? <LoadingCircle/>: 
                        <>
                            <DataTable
                                config={{
                                    key: 'st_id',
                                    alternatingRowColours:  true,
                                    responsiveimportant:    true,
                                    pagination: true,
                                    filterInPagination: true,
                                    filterMod: {
                                        filterInPagination:     true,
                                        colSpan:                3,
                                        clearable:              true,
                                    },
                                                
                                }}
                                columns={[
                                    {
                                        heading:        'Date Created',
                                        field:          rowData => clenyDate(rowData.st_date),
                                        dataRef:        'st_date',
                                        sizeToContent:  true,
                                        filter:         'custom',
                                        filterMod: {
                                            dataRef: (search, rowData) => {return `${rowData.st_date}|${rowData.full_reference}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                            label:     'Keywords',
                                            
                                        }
                                    },
                                    {
                                        heading:        'Date Completed',
                                        field:          rowData => clenyDate(_.find(rowData.statuses, {sts_status:'Completed'}).sts_at),
                                        dataRef:        rowData => clenyDate(_.find(rowData.statuses, {sts_status:'Completed'}).sts_at),
                                        sizeToContent:  true,
                                    },
                                    {
                                        heading:        'Reference',
                                        field:          rowData => rowData.full_reference,
                                        dataRef:        'sc_ref'
                                    },
                                    {
                                        heading:    'Counted By',
                                        field:      rowData => rowData.st_taker,
                                        sizeToContent: true,
                                        dataRef:    'st_taker'
                                    },
                                    {
                                        heading:    'Reviewed By',
                                        field:      rowData => rowData.st_reviewer,
                                        sizeToContent: true,
                                        dataRef:    'st_reviewer'
                                    },
                                    {
                                        heading:        'Number of Counts',
                                        field:          rowData => rowData.st_checks,
                                        dataRef:        'st_checks',
                                        sizeToContent:  true,
                                        alignment:      'center'
                                    },
                                    {
                                        heading:        'Parts',
                                        field:          rowData => rowData.details.length,
                                        dataRef:        rowData => rowData.details.length,
                                        sizeToContent:  true,
                                        alignment:      'center'
                                    },
                                    {
                                        heading:        '100% Correct',
                                        field:          rowData => _.filter(rowData.details,i=>i.std_threshold == 'Correct').length,
                                        dataRef:        rowData => _.filter(rowData.details,i=>i.std_threshold == 'Correct').length,
                                        sizeToContent:  true,
                                        alignment:      'center'
                                    },
                                    {
                                        heading:        'Within Threshold',
                                        field:          rowData => _.filter(rowData.details,i=>i.std_threshold == 'Within').length,
                                        dataRef:        rowData => _.filter(rowData.details,i=>i.std_threshold == 'Within').length,
                                        sizeToContent:  true,
                                        alignment:      'center'
                                    },
                                    {
                                        heading:        'Outside Threshold',
                                        field:          rowData => _.filter(rowData.details,i=>i.std_threshold == 'Outside').length,
                                        dataRef:        rowData => _.filter(rowData.details,i=>i.std_threshold == 'Outside').length,
                                        sizeToContent:  true,
                                        alignment:      'center'
                                    },
                                    {
                                        actions: rowData => {return([{name: 'View', icon: icons.view, link:`/stock/take/view/${rowData.st_id}`},])}
                                    }
                                ]}
                                rows={_.orderBy(TableData.completed, [i=>_.find(i.statuses, {sts_status:'Completed'}).sts_at],['desc'])}
                            />
                        </>
                    }
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    deployConfirmation:     (message, title, success)       => dispatch(deployConfirmation(message, title, success)),
})


export default connect(null,mapDispatchToProps)(CompletedStockTake);
