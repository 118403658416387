import { Button, Grid, TextField, Typography } from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import EmailField from 'Components/Common/Inputs/EmailField';
import { colors } from 'Helpers/ColourHelper';
import { checkMailingListContacts } from 'Helpers/EmailHelper';
import InputHelper from 'Helpers/InputHelper';
import React from 'react';
import { connect } from 'react-redux';

const initialState = (props) => ({
    formData: {
        contact_name: null,
        contact_email: null
    },
    lists: props.lists,
    existingContacts: props.existingContacts,
    error: null,
    warning: null,
    checked: false
})

class AddContactsManual extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
        this.inputHelper = new InputHelper(this, 'formData');
    }

    checkContact = async () => {
        if (this.state.formData.contact_email) {
            let check = await checkMailingListContacts([this.state.formData], this.state.existingContacts, this.props.lists.emailValidation)

            let error = null;
            let warning = null;

            switch(1){
                case check?.breakdown.na:
                    error = 'This contact is not eligible to be added to the mailing list.';
                    break;
                case check?.breakdown.warning?.length:
                    warning = 'This contact may have a faulty email address.';
                    break;
                case check?.breakdown.invalid?.length:
                    error = 'This contact has a faulty email address.';
                    break;
                case check?.breakdown.inListDuplicate?.length:
                case check?.breakdown.outListDuplicate?.length:
                    error = 'This contact is already in the mailing list.';
                    break;
                default: break;
            }
            this.setState({ error, warning, checked: true })
        } else {
            this.setState({
                error: null,
                warning: null,
                checked: false
            })
        }
    }

    submit = () => {
        API.post(`/marketing/campaigns/mailingLists/${this.props.mailingList.i}/addContacts`,{contacts: JSON.stringify([this.state.formData])}).then(this.props.refresh)
    }

    render() {

        const { formData, error, warning, checked } = this.state;

        return (
            <Grid container spacing={3} style={{padding: '1em'}}>
                <Grid item xs={12}>
                    <EmailField
                        label='Email *'
                        value={formData.contact_email}
                        name='contact_email'
                        fullWidth
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                        onBlur={this.checkContact}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='Name *'
                        value={formData.contact_name}
                        name='contact_name'
                        fullWidth
                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                    />
                </Grid>
                {error &&
                    <Grid item xs={12}>
                        <Typography style={{color: colors.red, textAlign: 'right', width: '100%'}}>
                            {error}
                        </Typography>
                    </Grid>
                }
                {warning &&
                    <Grid item xs={12}>
                        <Typography style={{color: colors.orange, textAlign: 'right', width: '100%'}}>
                            {warning}
                        </Typography>
                    </Grid>
                }
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant='outlined'
                        onClick={this.props.refresh}
                    >Close</Button>
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={!formData.contact_name || !formData.contact_email || error || !checked }
                        onClick={
                            () => this.props.deployConfirmation(
                                'Are you sure you want to add this contact to the mailing list?',
                                'Add Contact?',
                                this.submit
                            )   
                        }
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
        )
    }


}

const mapDispatchToProps = dispatch => ({
    deployConfirmation:     (body, title, callback)     => dispatch(deployConfirmation(body, title, callback)),
})


export default connect(null, mapDispatchToProps)(AddContactsManual);