import * as SoundActionTypes from 'Actions/Sounds/Types/Types';

 const initialState = {    
    src: null,
    status: 'stopped',
    state: null
};

function SoundReducer(state = initialState, action) {
    switch (action.type) {
        case SoundActionTypes.PLAY_SOUND:
            return {
                ...state,
                src: action.payload.src,
                status: action.payload.status,
                state: action.payload.state
            };
        case SoundActionTypes.STOP_SOUND:
            return {
                ...state,
                src: action.payload.src,
                status: action.payload.status
            };
        default:
            return state;
    }
}

 export default SoundReducer;