import FormControl from '@material-ui/core/FormControl';
import Textarea from '../../Common/Inputs/Textarea';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';

class EmailDetails extends PureComponent {
    
    render() {
        const { templateList, staffList, formErrors, handleChange, handleSelectChange, emailTemplate, emailText, approvedRequiredBy } = this.props;
        return (
            <form noValidate autoComplete="off">
                <Typography variant="h6">
                    Email Details
                </Typography>
                <FormControl fullWidth margin="normal">
                    <AutoCompleteSelect 
                        options={templateList} 
                        label='Email Template'
                        value={emailTemplate}
                        onChange={handleSelectChange('emailTemplate')}
                    />
                </FormControl>
                <Textarea
                    id="emailText"
                    name="emailText"
                    label="Email Text *"
                    value={emailText}
                    rows={10}
                    error={formErrors && formErrors['emailText']}
                    onChange={handleChange}
                />
                <FormControl margin="normal" fullWidth>
                    <AutoCompleteSelect 
                        options={staffList} 
                        label='Approval Required By *'
                        onChange={handleSelectChange('approvedRequiredBy')}
                        error={formErrors && formErrors['approvedRequiredBy'] && true}
                        errorText={formErrors && formErrors['approvedRequiredBy']}
                        value={approvedRequiredBy}
                    />
                </FormControl>
            </form>
        );
    }
}

export default EmailDetails;
    