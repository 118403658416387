import React, { Component } from 'react'; 
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { Button, FormControl, Grid, TextField } from '@material-ui/core';

const initialState = {
    status:             'All',
    site:               null,
    keywords:           '',
    dateFrom:           null,
    dateTo:             null,
}

class OrderSearchBlock extends Component {
    constructor(props) {
        super(props);    
        this.timeout = false;
        this.state   = {...initialState, ...props.formData};
        this.timeout = false;
    }

    componentWillUnmount = () => { if(this.timeout) clearTimeout(this.timeout) }

    handleReset = () => { this.setState(initialState,this.handleReturnChange) }

    handleReturnChange = () => { 
        clearTimeout(this.timeout);
        this.timeout  = setTimeout(() => {
            this.props.callBack({...this.state}); 
        },400);
    }

    handleSelectChange = (f) => (e) => {
        this.setState({
            [f]: e.value
        }, this.handleReturnChange);
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, this.handleReturnChange);
    };

    handleDateChange = name => date => {
        this.setState({
            [name]: date
        }, this.handleReturnChange);
    };

    render(){

        const {status, keywords, dateFrom, dateTo, site} = this.state;
        const Element = this.props.noPad ? Grid : PaddedPaper;
        const bigSize = 12;
        const subSize = Math.floor(100/6 );

        return (
            <Grid container xs={bigSize}>
                <Element style={{width:'100%'}}>
                    <form noValidate autoComplete="off">
                        <Grid container xs={12} spacing={3}>
                            <Grid item style={{width: `${subSize}%`}}>
                                <FormControl margin="normal" fullWidth style={{margin:0}}>
                                    <TextField label="Keywords" fullWidth value={keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                            </Grid>
                            {!this.props.disableStatus &&
                                <Grid item style={{width: `${subSize}%`}}>
                                    <FormControl margin="normal" fullWidth style={{margin:0}}>
                                        <AutoCompleteSelect 
                                            options={[
                                                {value: 'All',               label: 'All'},
                                                {value: 'Awaiting Approval', label: 'Awaiting Approval'},
                                                {value: 'Cancelled',         label: 'Cancelled'},
                                                {value: 'Completed',         label: 'Completed'},
                                                {value: 'Confirmed',         label: 'Confirmed'},
                                                {value: 'Awaiting Payment',  label: 'Awaiting Payment'},
                                                {value: 'Declined',          label: 'Declined'},
                                                {value: 'Payment Confirmed', label: 'Payment Confirmed'},
                                                {value: 'Picking',           label: 'Picking'},
                                                {value: 'Invoice',           label: 'Invoice'},
                                                {value: 'Awaiting Despatch', label: 'Awaiting Despatch'}
                                            ]} 
                                            label='Status'
                                            onChange={this.handleSelectChange('status')}
                                            value={status}
                                        />
                                    </FormControl>
                                </Grid>
                            }
                            {(!this.props.disableSites && this.props.sites) &&
                                <Grid item style={{width: `${subSize}%`}}>
                                    <FormControl margin="normal" fullWidth style={{margin:0}}>
                                        <AutoCompleteSelect 
                                            options={this.props.sites} 
                                            label='Site'
                                            onChange={this.handleSelectChange('site')}
                                            value={site}
                                            />
                                    </FormControl>
                                </Grid>
                            }
                           
                            {!this.props.disableDate &&
                                <>
                                    <Grid item style={{width: `${subSize}%`}}>
                                        <DatePicker
                                            type='date'
                                            id="dateFrom"
                                            name="dateFrom"
                                            label="Date From"
                                            autoOk={true}
                                            value={dateFrom}
                                            onChange={this.handleDateChange('dateFrom')}
                                            margin='none'
                                        />
                                    </Grid>
                                    <Grid item style={{width: `${subSize}%`}}>
                                        <DatePicker
                                            type='date'
                                            id="dateTo"
                                            name="dateTo"
                                            label="Date To"
                                            autoOk={true}
                                            value={dateTo}
                                            onChange={this.handleDateChange('dateTo')}  
                                            margin='none'                                          
                                        />
                                    </Grid>
                                </>
                            }
                            {!this.props.disableReset &&
                                <Grid item   style={{marginLeft:'auto'}}>
                                    <div className='buttonRow'>
                                        <Button onClick={this.handleReset}
                                                variant="outlined"
                                                color="default"
                                        >
                                            <FALightIcon icon='undo' button />
                                            Reset Search
                                        </Button>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </form>
                </Element>
            </Grid>
        )
    }

}

export default OrderSearchBlock;