import API from 'API';
import _, { assign, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { creditPaymentTerms } from 'Helpers/SalesHelper';

import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DespatchDialog from 'Components/Sales/Order/DespatchDialog/DespatchDialog';
import Order from 'Components/Sales/Order/ViewOrder/Order';
import StaffActions from 'Components/Sales/Order/ViewOrder/sections/StaffActions';


import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import { Button, FormControl, Grid, Typography } from '@material-ui/core/';

const ProcessOrderDialog = ({orderId, callback}) => {

    const initialState = {
        formErrors:{},
        formData:{
            paymentTermId: '',
            ogPaymentTermId: '',
            courierId: '',
            totalPrice: '',
            confirmationNotes: ''
        },
        order: [],
        paymentTermsList: [],
        couriersList: [],
        isLoading: true,
        orderLocked: true
    };

    let [state, setState] = useState(initialState);

    const {formErrors, formData, formData:{paymentTermId, ogPaymentTermId, confirmationNotes}, order, paymentTermsList, orderLocked, isLoading} = state;

    const dispatch = useDispatch();

    const handleProcessClose = () => dispatch(closeDialog());

    const handleProcessOrderConfirmation = () => {
        dispatch(deployConfirmation('Are you sure you want to process this order?', 'Process Order?', handleProcessSuccess, handleProcessClose))
    }

    const handleProcess = () => {
        if (parseInt(ogPaymentTermId) !== parseInt(paymentTermId) && _.indexOf(creditPaymentTerms, paymentTermId) > -1 && _.indexOf(ogPaymentTermId, paymentTermId) < 0 ){
            API.get(`/customers/${order.order_cust_id}/accounts/creditCheck`, {params:{amount:order.order_total_incl_vat}})
            .then( res => {
                if (res.data.overCredit) {
                    dispatch(deployDialog(
                        <>
                            <Typography variant='body1'>
                                This order will exceed the customers credit limit, please check with accounts before proceeding.
                            </Typography>
                            <Grid item container xs={12} style={{justifyContent: 'flex-end', marginTop:'2em'}}>
                                <Grid item>
                                    <Button onClick={dispatch(closeDialog())} variant="outlined" >Close</Button>
                                </Grid>
                                <Grid item style={{paddingLeft: '1em'}}>
                                    <Button variant="contained"  color='secondary' onClick={handleProcessOrderConfirmation}>Continue</Button>
                                </Grid>
                            </Grid>
                        </>,
                        'Over Credit Limit Warning','warning','sm',null
                    ));
                } else {handleProcessOrderConfirmation();}
            })
        } else { handleProcessOrderConfirmation(); }
    };

    const handleProcessSuccess = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));
        API.post(`/sales/orders/${orderId}/process`, formData)
        .then(result => {
            if(result.data.errors) {
                const formErrors = formatValidationErrors(result.data.errors)
                if (formErrors['status']){
                    dispatch(deploySnackBar('error', formErrors['status']));
                    if(callback) callback();
                    handleProcessClose();
                } else {
                    setState(state => ({
                        ...state,
                        formErrors,
                        isLoading: false
                    }));
                }
            } else if(result.data.success) {
                if(result.data.despatchDialog) {
                    const dialog = <DespatchDialog 
                        orderId={orderId} 
                        callback={callback} 
                        paymentConfirmed={false}
                        noClose
                    />
                    dispatch(deployDialog(dialog, 'Despatch Dialog', '', 'xs'));
                } else {
                    if(callback) callback();
                    dispatch(deploySnackBar('success', 'Successfully processed order'));
                    handleProcessClose();
                }
            }
        });
    }

    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleDialogSelect = fieldName => selectedOption => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }));
    }

    useEffect(() => {
        Promise.all([
            API.get(`/sales/orders/${orderId}`),
            API.get('/customers/paymentTerms'),
        ])
        .then(([orderRes, paymentRes]) => {
            let paymentTermsList = [];
            paymentTermsList = map(paymentRes.data, el => {
                return assign({
                    value: el.payment_term_id,
                    label: el.payment_term_name
                });
            });

            setState(state => ({
                ...state,
                formData: {
                    ...state.formData,
                    paymentTermId:      orderRes.data.order_payment_term_id,
                    ogPaymentTermId:    orderRes.data.order_payment_term_id,
                    totalPrice:         orderRes.data.order_total_incl_vat
                },
                order: orderRes.data,
                paymentTermsList,
                isLoading: false
            }));
        });
    }, [orderId]);

    const lockOrder = orderLocked => {
        setState(state => ({
            ...state,
            orderLocked
        }));
    };

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <React.Fragment>
                    <Grid container style={{padding: 8}}>
                        <Grid item xs={12} lg={12} style={{paddingTop: 0, paddingBottom: '1em'}}>
                            <StaffActions orderId={order.order_id} lockCallback={lockOrder} type={'Confirming'}/>
                        </Grid>
                        <Grid item xs={12} style={{border:'1px solid rgba(224, 224, 224, 1)', padding: '15px'}}>
                            <Order co={order} />
                        </Grid>
                        {orderLocked ?
                            <>
                                <Grid item xs={12} md={12}>
                                    {orderLocked}
                                </Grid>
                                <Grid item xs={12} className='buttonRow'>
                                    <Button onClick={handleProcessClose} variant="outlined" color="default">Close</Button>
                                </Grid>
                            </>
                        :
                            <>
                                <Grid item xs={12}>
                                    <br></br>
                                    <Typography variant="h6">Change Payment Term?</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl margin="normal" fullWidth>
                                        <AutoCompleteSelect 
                                            options={paymentTermsList}
                                            label='Payment Term *'
                                            value={paymentTermId}
                                            onChange={handleDialogSelect('paymentTermId')}
                                            error={formErrors && formErrors['paymentTerm'] && true}
                                            errorText={formErrors && formErrors['paymentTerm']}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" style={{flex: 1}}>
                                        <FALightIcon icon='info-circle' size='small' />
                                        Please ensure you are happy with this payment term
                                    </Typography>
                                    <Textarea
                                        id="confirmationNotes"
                                        name="confirmationNotes"
                                        label="Confirmation Notes"
                                        value={confirmationNotes}
                                        rows={5}
                                        error={formErrors && formErrors['confirmationNotes']}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                    {!orderLocked &&
                        <div className="buttonRow">
                            <Button onClick={handleProcessClose} variant="outlined" color="default">Close</Button>
                            <Button onClick={handleProcess} style={{backgroundColor:'#5da14d', color:'white'}} variant="contained">Process</Button>
                        </div>
                    }
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default ProcessOrderDialog;