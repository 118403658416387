import _ from 'lodash';
import API from 'API';

const _isValidEmail = (email) => {
    if (String(email).indexOf(' ') > -1) {
        return false;
    }
    let regex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
    return regex.test(String(email).toLowerCase());
}

export function isValidEmail(email) {
    return _isValidEmail(email);
}

export function isMalformedEmail(email) {
    // Check if email is malformed e.g test@text.cp.uk, this doesn't always mean the email is incorrect, just that i could be
    let split = email.split('.')
    let test = split[split.length - 1];
    if (test.length === 2) {
        test = split[split.length - 2];
    }

    return ['com', 'org', 'net', 'edu', 'gov', 'mil', 'co', 'nhs'].includes(test);
}

const _checkEmail = (email, validation) => {

    //error
    if (!_isValidEmail(email)) {
        return {
            email,
            EmailAddressError: true,
        }
    }
    if (!_.find(validation.error, i => email.endsWith(i))) {
        return {
            email,
            EmailAddressError: true,
        }
    }
    //black
    if (_.find(validation.black, i => email.includes(`${i}.`))) {
        return {
            email,
            EmailAddressBlack: true,
        }
    }
    //warning
    if (_.find(_.keys(validation.warning), i => email.indexOf(i) > 0 && !_.find(validation.warning[i], j => email.endsWith(j)))) {
        return {
            email,
            EmailAddressWarning: true,
        }
    }
    //bounce
    if (_.find(validation.bounce, i => i.trim().toLowerCase() === email.trim().toLowerCase())){
        return {
            email,
            EmailAddressBounce: true,
        }
    }
    //complaint
    if (_.find(validation.complaint, i => i.trim().toLowerCase() === email.trim().toLowerCase())){
        return {
            email,
            EmailAddressComplaint: true,
        }
    }
    //valid
    return {
        email,
        EmailAddressValid: true,
    }

}

const _checkEmails = (emails, validation, bounce) => {
    if (!Array.isArray(emails)) {
        emails = [emails];
    }

    let _emails = _.map(emails, email => _checkEmail(email, validation))

    return {
        valid: _emails.filter(email => email.EmailAddressValid).map(i=>i.email),
        error: _emails.filter(email => email.EmailAddressError).map(i=>i.email),
        warning: _emails.filter(email => email.EmailAddressWarning).map(i=>i.email),
        bounce: _emails.filter(email => email.EmailAddressBounce).map(i=>i.email),
        black: _emails.filter(email => email.EmailAddressBlack).map(i=>i.email),
        complaint: _emails.filter(email => email.EmailAddressComplaint).map(i=>i.email),
    }
}

export async function checkEmail(email, validation=null){
    if (!validation){
        return await API.get(`/marketing/contacts/emailValidation/all`)
        .then(res => {
            let warnings = {};
            _.each(_.filter(res.data, (validation) => validation.eav_type === 'domains'), i => {
                warnings[i.eav_primary] = JSON.parse(i.eav_secondary)
            });

            validation = {
                error: _.map(_.filter(res.data, (validation) => validation.eav_type === 'ending'), i => i.eav_primary),
                bounce: _.map(_.filter(res.data, (validation) => validation.eav_type === 'bounce'), i => i.eav_primary),
                black: _.map(_.filter(res.data, (validation) => validation.eav_type === 'black'), i => i.eav_primary),
                complaint: _.map(_.filter(res.data, (validation) => validation.eav_type === 'complaint'), i => i.eav_primary),
                warning: warnings
            }

            let ret = _checkEmail(email, validation);

            if (ret.EmailAddressError){
                return 'error';
            } else if (ret.EmailAddressWarning){
                return 'warning';
            } else if (ret.EmailAddressBlack){
                return 'black';
            } else if (ret.EmailAddressBounce){
                return 'bounce';
            } else if (ret.EmailAddressComplaint){
                return 'complaint';
            } else {
                return 'valid';
            }
        })
    }
    let ret = _checkEmail(email, validation);
    
    if (ret.EmailAddressError){
        return 'error';
    } else if (ret.EmailAddressWarning){
        return 'warning';
    } else if (ret.EmailAddressBounce){
        return 'bounce';
    } else if (ret.EmailAddressBlack){
        return 'black';
    } else if (ret.EmailAddressComplaint){
        return 'complaint';
    } else {
        return 'valid';
    }
}

export async function checkEmails(email, validation=null){
    if (!validation){
        return await API.get(`/marketing/contacts/emailValidation/all`)
        .then(res => {
            let warnings = {};
            _.each(_.filter(res.data, (validation) => validation.eav_type === 'domains'), i => {
                warnings[i.eav_primary] = JSON.parse(i.eav_secondary)
            });

            validation = {
                error: _.map(_.filter(res.data, (validation) => validation.eav_type === 'ending'), i => i.eav_primary),
                bounce: _.map(_.filter(res.data, (validation) => validation.eav_type === 'bounce'), i => i.eav_primary),
                black: _.map(_.filter(res.data, (validation) => validation.eav_type === 'black'), i => i.eav_primary),
                complaint: _.map(_.filter(res.data, (validation) => validation.eav_type === 'complaint'), i => i.eav_primary),
                warning: warnings
            }

            return _checkEmails(email, validation);
        })
    }
    return _checkEmails(email, validation);
}

export async function checkMailingListContact(email){
    return await API.post('/marketing/contacts/checkContacts', {
        contacts: JSON.stringify([{contact_email: email}])
    }, {loadingBar: false}).then(
        async res => {
            return res.data.contacts[0];
        }
    )
}

export async function checkMailingListContacts(contacts, existingContacts = [], validation=null){
    return await API.post('/marketing/contacts/checkContacts', {
        contacts: JSON.stringify(_.map(contacts, c => ({contact_email: c.contact_email, contact_name: c.contact_name, contact_id: c?.contact_id ?? 0, contact_customer_id: c?.contact_customer_id ?? 0, customer: _.isObject(c.customer) ? c?.customer?.cust_name ?? 'Manual Contact' : c.customer, valid: c?.valid ?? false, reason: c?.reason ?? ''}))),
        existingContacts: JSON.stringify(_.map(existingContacts, c => ({contact_email: c.contact_email, contact_name: c.contact_name, contact_id: c?.contact_id ?? 0, contact_customer_id: c?.contact_customer_id ?? 0, customer: _.isObject(c.customer) ? c?.customer?.cust_name ?? 'Manual Contact' : c.customer, valid: c?.valid ?? false,  reason: c?.reason ?? ''}))),
    }).then(
        async res => {
            return {
                contacts: res.data.contacts,
                toAdd: res.data.valid,  
                dontAdd: res.data.invalid,
                breakdown: res.data.breakdown,
            }
        });
/*
    return await API.get('/marketing/campaigns/mailingLists/eligibleContacts', {params: {breakdown: true}}).then(
        async res => {
            let _contacts = res.data.contacts;
            let faultyEmails = [], unsubed = [], inListDuplicate = [], outListDuplicate = [],  newContacts = [], eligible = [], accounts = [], warnings = [], inactive = [];
            let validation = await checkEmails(_.uniq(_.map(contacts, i => i.contact_email)), validation);

            let emails = {};
            _.each(_.uniq(_.map(contacts, i => i.contact_email)), i => {
                emails[i] = {
                    black: validation.black.includes(i),
                    valid: validation.valid.includes(i),
                    error: validation.error.includes(i),
                    warning: validation.warning.includes(i) || validation.bounce.includes(i),
                    optOut: _.find(contacts, {contact_email: i,  contact_email_opt_out: 1}),
                    accounts: _.filter(contacts, {contact_email: i})?.addresses?.length > 0 && (_.filter(contacts, {contact_email: i}).length === _.filter(_.filter(contacts, {contact_email: i}), a =>
                                                                                                        a.addresses.length === 1 &&
                                                                                                        a.addresses[0].address_type === "Accounts"
                                                                                                    ).length)               
                }
            })
            _.each(contacts, contact => {
                if (_.find(existingContacts, {contact_email: contact.contact_email})){
                    outListDuplicate.push(contact);
                } else if (_.filter(contacts, i => i.contact_email && i.contact_email.toLowerCase() === contact.contact_email.toLowerCase()).length > 1){
                    inListDuplicate.push(contact);
                } else if (emails[contact.contact_email]?.error){
                    faultyEmails.push(contact);
                } else if (emails[contact.contact_email]?.optOut){
                    unsubed.push(contact);
                } else if (emails[contact.contact_email]?.black){
                    accounts.push(contact);
                } else if (emails[contact.contact_email]?.accounts){
                    accounts.push(contact);
                } else if (contact.addresses?.length > 0 && contact.addresses?.length === _.filter(contact.addresses, i => !i.address_active).length){
                    inactive.push(contact);
                } else if (!contact?.valid && emails[contact.contact_email]?.warning) {
                    warnings.push(contact);
                } else if (_.find(_contacts, {contact_email: contact.contact_email})){
                    eligible.push({...contact, newContactId: _.find(_contacts, {contact_email: contact.contact_email}).contact_id});
                } else {
                    newContacts.push(contact);
                }
            });

            return {
                contacts: contacts,
                toAdd: [...eligible, ...newContacts],
                dontAdd: [...unsubed,...accounts,...warnings,...faultyEmails,...inListDuplicate,...outListDuplicate,...inactive],
                unsubed: unsubed,
                na: [...accounts, ...warnings],
                invalid: faultyEmails,
                duplicate: [...inListDuplicate, ...outListDuplicate],
                breakDown: {
                    eligible: eligible,
                    newContacts: newContacts,
                    unsubed: unsubed,
                    accounts: accounts,
                    warnings: warnings,
                    faultyEmails: faultyEmails,
                    inListDuplicate: inListDuplicate,
                    outListDuplicate: outListDuplicate,
                    inactive: inactive
                },
            }
        }
    )
    */
}
