import React from 'react';
import API from 'API';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { Grid, Link, Typography } from '@material-ui/core';
import IconHelper from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import _ from 'lodash';

const initialState = {
    isLoading: true,
    attachments: null
}

class ViewAttachments extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getAttachments();
    }

    componentDidUpdate(prevProps){
        if ((prevProps.source !== this.props.source) || (prevProps.source_data !== this.props.source_data)){
            this.setState(initialState, () => {
                this.getLinked();
            })
        }
    }

    getAttachments = () => {
        API.get(`/email/${this.props.emailId}/attachments`)
        .then(r => {
            this.setState({
                isLoading: false,
                attachments: r.data,
            })
        })
    }

    download = (attachment) => () => {    
        pdfFromBase64(attachment.file, attachment.name);
    }

    render(){
        const { isLoading, attachments } = this.state;

        if (!isLoading && attachments.length > 0){
            return (
                <Grid item xs={12}
                    style={{
                        paddingLeft: '1em',
                        borderBottom: `1px solid ${colors.primary}10`,
                        display: 'flex',
                    }}
                >
                    <AllIcon
                        icon={IconHelper.paperclip}
                        size='small'
                        noMargin
                        style={{
                            marginRight: 6,
                            marginTop: 5
                        }}
                    />
                    <Grid container>
                        {_.map(
                            attachments,
                            (attachment, i) => (
                                <Grid item key={i} style={{paddingLeft: 5}}>
                                    <Typography variant="caption">
                                        <Link
                                            onClick={this.download(attachment)}
                                            style={{cursor: 'pointer'}}
                                        >
                                            {attachment.name}
                                        </Link>{i < attachments.length - 1 ? ',' : ''}
                                    </Typography>
                                </Grid>
                            )
                        )}
                    </Grid>
                </Grid>
            )
        }

        return <></>

    }

}

export default ViewAttachments;