import React, { useState, useEffect } from 'react';
import API from 'API';
import { 
    Grid, RadioGroup, FormControlLabel, Radio, DialogActions, Button, Typography
} from '@material-ui/core';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { CLENAWARE_BLUE } from 'Constants';
import { withStyles } from '@material-ui/core/styles';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';
import { map } from 'lodash';

const styles = (theme) => ({
    tableCell: {
        backgroundColor: CLENAWARE_BLUE,
        color: '#ffffff'
    }
})

const CreateLinkedFormDialog = ({ formId, systemCatID, closeDialog, callBack }) => {

    const [availableForms, setAvailableForms] = useState([]);
    const [formData, setFormData] = useState({ 
        prePopulate: "No", 
        formConfigID: 0
    });

    useEffect(() => {
        
        API.get(`/forms/${formId}/relatedFormsToLink`, { 
            params: {
                systemCatID
            }
        })
        .then(res => {
            if(res?.data){

                let availableForms = [];
                map(res.data, (form) => {
                    availableForms.push({
                        label: form.nm,
                        value: form.id
                    });
                });
                setAvailableForms(availableForms);
            }
        })
	}, []);

    const submit = () => {

        API.post(`/forms/${formId}/createLinkedForm`, { 
            prePopulate: formData.prePopulate,
            formConfigID: formData.formConfigID,
            systemCatID
        })
        .then(result => {
            if(callBack){
                callBack();
            }
        });
    }

    return (
        <>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <AutoCompleteSelect 
                    options={availableForms} 
                    label='Form *'
                    fullWidth
                    onChange={(o) => setFormData({...formData, formConfigID: o?.value})}
                    value={formData.formConfigID}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" >
                    Pre-populate from the Pre-Installation?
                </Typography>
                <RadioGroup
                    name="prePopulate"
                    value={formData.prePopulate}
                    onChange={(e) => setFormData({...formData, prePopulate: e.target.value})}
                    row
                    label="Pre-populate? *"
                >
                    <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        label="Yes"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        label="No"
                        labelPlacement="end"
                    />
                </RadioGroup>
                
            </Grid>

        </Grid>
        <DialogActions className='pr-0 pb-0 pt-3'>
            <Button 
                color="default"
                onClick={() => closeDialog()}
                variant="outlined"
            >
                Cancel
            </Button>
            <Button 
                color="primary"
                onClick={() => submit()}
                variant="contained"
                // /style={{backgroundColor:'#5da14d', color:'white'}}
                disabled={!formData.formConfigID}
            >
                Submit
            </Button>
        </DialogActions>
        </> 
    );

};

export default withStyles(styles)(CreateLinkedFormDialog);