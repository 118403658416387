import React       from 'react';
import API         from 'API';
import _           from 'lodash';
import { connect } from 'react-redux';

import { Grid, Typography, Checkbox } from '@material-ui/core/';

import DataTable     from 'Components/Common/DataTables/CiDataTable';
import AllIcon       from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';

import { colors }                 from 'Helpers/ColourHelper';
import { checkAccess }            from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';
import { colorNumbers, currency } from 'Helpers/NumberHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = {
    stock:         [],
    table:         'Overview',
    isLoading:     true,
    incorrectOnly: false,
    access:        {}
}

const InfoBlock = ({title, icon, value}) => {
    return (
        <Grid item style={{width: `${(100 / 7)}%`}}>
            <PaddedPaper  style={{textAlign: 'center', height: '100%'}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}><Typography variant="h6" style={{textAlign: 'center'}}>{title}</Typography></Grid>
                    <Grid item xs={12}><AllIcon icon={icon} noMargin size='large' /></Grid>
                    <Grid item xs={12}><Typography variant="h6"  style={{textAlign: 'center'}}>{value}</Typography></Grid>
                </Grid>
            </PaddedPaper>
        </Grid>
    )
} 

class StockReportsOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getData();
        this.getAccess();
    }

    getAccess = async () => {
        this.setState({access: {
            correctStock: await checkAccess('stock-tracker:correct-stock')
        }});
    }

    getData = () => {
        API.get('/reports/stocksReport/overview', {params: {partId: this.props.partId}})
        .then(res => {
            this.setState({
                data:      _.filter(res.data, 'w'), 
                isLoading: false
            });
        })
    }

    correctPendingStock = (part) => () => {API.post(`/stock/tracker/correctStock/${part.q}`).then(this.getData)}

    confirmStockFix = r => {
        this.props.deployConfirmation(
            <Grid container>
                <Grid item xs={12} style={{paddingBottom: '0.5em'}}>
                    <Typography variant='h6'>The following stock will be changed</Typography>
                    <Typography variant='body2'>Note: This will change the numbers stored in the database without effecting stock activity</Typography>
                </Grid>
                <Grid item container xs={12}>
                    {(parseFloat(r.i) !== parseFloat(r.o)) && <>
                        <Grid item xs={5}><b>Current Stock</b></Grid>
                        <Grid item xs={1}>{parseFloat(r.i).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon heavy icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><b>{parseFloat(r.o).toFixed(3)}</b></Grid>
                    </>}
                    {(parseFloat(r.s) !== parseFloat(r.d)) && <>
                        <Grid item xs={5}><b>Pending Stock</b></Grid>
                        <Grid item xs={1}>{parseFloat(r.s).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon heavy icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><b>{parseFloat(r.d).toFixed(3)}</b></Grid>
                    </>}
                    {(parseFloat(r.f) !== parseFloat(r.g)) && <>
                        <Grid item xs={5}><b>Predicted Stock</b></Grid>
                        <Grid item xs={1}>{parseFloat(r.f).toFixed(3)}</Grid> 
                        <Grid item xs={1} style={{textAlign: 'center'}}><AllIcon heavy icon={icons.arrowRight} noMargin size='small' /></Grid>
                        <Grid item xs={5}><b>{parseFloat(r.g).toFixed(3)}</b></Grid>
                    </>}
                </Grid>
            </Grid>,
            'Are you sure you want to fix this parts stock?',
            this.correctPendingStock(r));
    }

    handleStockCorrection = (r) => {
        API.post('/stock/correction/withPart', {partId: r.q})
        .then(res => {
            window.location = '/stock/correction/edit/' + res.data.sc_id;
        })
    }

    render() {
        const { data, isLoading, access, incorrectOnly } = this.state;

        if (isLoading) return <LoadingCircle />;

        const hasIncorrect = _.filter(data, r => (parseFloat(r.i).toFixed(3) !== parseFloat(r.o).toFixed(3)) || (parseFloat(r.s).toFixed(3) !== parseFloat(r.d).toFixed(3)) || (parseFloat(r.f).toFixed(3) !== parseFloat(r.g).toFixed(3))).length > 0;

        return (
            <Grid container xs={12} spacing={2} style={{width: '100%'}}>
                <Grid item xs={12}>
                    <Grid container spacing={2} >
                        <InfoBlock
                            title={<>Total<br/>Stock</>}
                            icon={icons.stock}
                            value={<>
                                <AllIcon icon={icons.parts}/>{colorNumbers(data.length)}<br/>
                                <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(_.sumBy(data, 'o')).toFixed(3))}
                            </>}
                        />
                        <InfoBlock
                            title={<>Pending<br/>Incoming</>}
                            icon={icons.plus}
                            value={<>
                                <AllIcon icon={icons.parts}/>{colorNumbers(_.filter(_.filter(data, 'w'), i => i.p !== 0 ).length)}<br/>
                                <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(_.sumBy(data, 'p')).toFixed(3))}
                            </>}
                        />
                        <InfoBlock
                            title={<>Pending<br/>Outgoing</>}
                            icon={icons.minus}
                            value={<>
                                <AllIcon icon={icons.parts}/>{colorNumbers(0 - _.filter(_.filter(data, 'w'), i => i.a !== 0 ).length)}<br/>
                                <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(_.sumBy(data, 'a')).toFixed(3))}
                            </>}
                        />
                        <InfoBlock
                            title={<>Total<br/>Pending</>}
                            icon={icons.stock}
                            value={<>
                                <AllIcon icon={icons.parts}/>{colorNumbers(_.filter(_.filter(data, 'w'), i => i.p !== 0 || i.a !== 0 ).length)}<br/>
                                <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(_.sumBy(data, 'd')).toFixed(3))}
                            </>}
                        />
                        <InfoBlock
                            title={<>Total<br/>Predicted</>}
                            icon={icons.stock}
                            value={<>
                                <AllIcon icon={icons.parts}/>{colorNumbers(data.length)}<br/>
                                <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(_.sumBy(data, 'g')).toFixed(3))}
                            </>}
                        />
                        <InfoBlock
                            title={<>Total<br/>Available</>}
                            icon={icons.stock}
                            value={<>
                                <AllIcon icon={icons.parts}/>{colorNumbers(data.length)}<br/>
                                <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(_.sumBy(data, i => parseFloat(i.o) + parseFloat(i.a))).toFixed(3))}
                            </>}
                        />
                        <InfoBlock
                            title={<>Incorrect<br/>Parts</>}
                            icon={icons.warning}
                            value={<>
                                <AllIcon icon={icons.parts}/>{colorNumbers(_.filter(data, r => (parseFloat(r.i) !== parseFloat(r.o)) || (parseFloat(r.s) !== parseFloat(r.d)) || (parseFloat(r.f) !== parseFloat(r.g))).length)}<br/>
                                <AllIcon icon={icons.qty}/>{colorNumbers(parseFloat(_.sumBy(data, r =>  parseFloat(r.g) - parseFloat(r.f)).toFixed(3)))}
                            </>}
                        />
                        <InfoBlock
                            title='Stock Value'
                            icon={icons.poundSign}
                            value={currency(_.sumBy(data, r => r.o * r.t))}
                        />
                        <InfoBlock
                            title='Incoming Value'
                            icon={icons.poundSign}
                            value={currency(_.sumBy(data, r => r.p * r.t))}
                        />
                        <InfoBlock
                            title='Outgoing Value'
                            icon={icons.poundSign}
                            value={currency(_.sumBy(data, r => r.a * r.t))}
                        />
                        <InfoBlock
                            title='Pending Value'
                            icon={icons.poundSign}
                            value={currency(_.sumBy(data, r => r.d * r.t))}
                        />
                        <InfoBlock
                            title='Predicted Value'
                            icon={icons.poundSign}
                            value={currency(_.sumBy(data, r => r.g * r.t))}
                        />
                        <InfoBlock
                            title='Available Value'
                            icon={icons.poundSign}
                            value={currency(_.sumBy(data, r => parseFloat(parseFloat(r.o) + parseFloat(r.a)) * parseFloat(r.t) || 0))}
                        />
                        <InfoBlock
                            title='Incorrect Value'
                            icon={icons.poundSign}
                            value={currency(_.sumBy(data, r => parseFloat(r.g) !== parseFloat(r.f) ? (parseFloat(r.g) - parseFloat(r.f)) * r.t : 0))}
                        />
                    </Grid>
                </Grid>
                
                <Grid item xs={12} style={{paddingTop: '1.5em'}}> 
                    {hasIncorrect &&
                        <span style={{textAlign: 'right', width: '100%', marginLeft: '85%', display: 'flex', alignItems: 'center'}}><Checkbox checked={incorrectOnly} onChange={e => this.setState({incorrectOnly: !incorrectOnly})} /><Typography variant='h6'>Incorrect Only</Typography></span>
                    }
                    <DataTable 
                        config={{
                            key: 'q',
                            pagination: true,
                            responsiveimportant: true,
                            rowsPerPage: 25,
                            filter: true,
                            filterMod: {
                                filterInPagination:     true,
                                clearable:              true,
                                colSpan:                4,
                            },
                            sticky:true
                        }}
                        columns={[
                            {
                                field:        'r',
                                fieldFormat: 'image',
                                sizeToContent: true,
                                filter:         'custom',
                                filterMod: {
                                    dataRef:   (search, rowData) => {return `${rowData.w}|${rowData.e}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                    label:     'Search Parts'
                                }
                            },
                            {
                                heading:  'Part/Description',
                                field:    i => <>{i.w}<br/>{i.e}</>,
                                truncate: true,
                            },
                            {
                                heading:       'Stock +',
                                field:         'y',
                                dataRef:       'y',
                                fieldFormat:   'colorNumbers:decimal:3',
                                sizeToContent: true,
                                alignment:     'right',
                            },
                            {
                                heading:       'Stock -',
                                field:         'u',
                                dataRef:       'u',
                                fieldFormat:   'colorNumbers:decimal:3',
                                sizeToContent: true,
                                alignment:     'right',
                            },
                            {
                                heading:       'Stock',
                                field:         'o',
                                dataRef:       'o',
                                fieldFormat:   'colorNumbers:decimal:3',
                                sizeToContent: true,
                                info:          r => parseFloat(r.o).toFixed(3) !== parseFloat(r.i).toFixed(3) && <>System: {parseFloat(r.i).toFixed(3)} <br/> Calculated: {parseFloat(r.o).toFixed(3)}</>,
                                style:         r => parseFloat(r.o).toFixed(3) !== parseFloat(r.i).toFixed(3) ? {border: `2px solid ${colors.red}`} : {borderRight: `1px solid ${colors.disabled}`, borderLeft: `1px solid ${colors.disabled}`},
                                alignment:     'right',
                            },
                            {
                                heading:       'Pending +',
                                field:         'p',
                                dataRef:       'p',
                                fieldFormat:   'colorNumbers:decimal:3',
                                sizeToContent: true,
                                alignment:     'right',
                            },
                            {
                                heading:       'Pending -',
                                field:         'a',
                                dataRef:       'a',
                                fieldFormat:   'colorNumbers:decimal:3',
                                sizeToContent: true,
                                alignment:     'right',
                            },
                            {
                                heading:       'Pending',
                                field:         'd',
                                dataRef:       'd',
                                fieldFormat:   'colorNumbers:decimal:3',
                                sizeToContent: true,
                                info:          r => parseFloat(r.d).toFixed(3) !== parseFloat(r.s).toFixed(3) && <>System: {parseFloat(r.s).toFixed(3)} <br/> Calculated: {parseFloat(r.d).toFixed(3)}</>,
                                style:         r => parseFloat(r.d).toFixed(3) !== parseFloat(r.s).toFixed(3) ? {border: `2px solid ${colors.red}`} : {borderRight: `1px solid ${colors.disabled}`, borderLeft: `1px solid ${colors.disabled}`},
                                alignment:     'right',
                            },
                            {
                                heading:       'Predicted',
                                field:         'g',
                                dataRef:       'g',
                                fieldFormat:   'colorNumbers:decimal:3',
                                sizeToContent: true,
                                info:          r => parseFloat(r.g).toFixed(3) !== parseFloat(r.f).toFixed(3) && <>System: {parseFloat(r.f).toFixed(3)} <br/> Calculated: {parseFloat(r.g).toFixed(3)}</>,
                                style:         r => parseFloat(r.g).toFixed(3) !== parseFloat(r.f).toFixed(3) ? {border: `2px solid ${colors.red}`} : {borderRight: `1px solid ${colors.disabled}`, borderLeft: `1px solid ${colors.disabled}`},
                                alignment:     'right',
                            },
                            {
                                heading:       'Available',
                                field:         i => parseFloat(i.o) + parseFloat(i.a),
                                dataRef:       i => parseFloat(i.o) + parseFloat(i.a),
                                fieldFormat:   'colorNumbers:decimal:3',
                                sizeToContent: true,
                                alignment:     'right',
                                style:         {borderRight: `1px solid ${colors.disabled}`, borderLeft: `1px solid ${colors.disabled}`}
                            },
                            {
                                actions: r => access?.correctStock && hasIncorrect ?
                                [
                                    {
                                        icon:       icons.true,
                                        name:       'Fix Stock',
                                        onClick:    this.confirmStockFix,
                                        disabled:   !((parseFloat(r.i).toFixed(3) !== parseFloat(r.o).toFixed(3)) || (parseFloat(r.s).toFixed(3) !== parseFloat(r.d).toFixed(3)) || (parseFloat(r.g).toFixed(3) !== parseFloat(r.f).toFixed(3)) )
                                    },
                                    {
                                        name:    'Stock Correction',
                                        icon:    icons.stock,
                                        onClick: this.handleStockCorrection,
                                    },
                                    {
                                        name:   'View',
                                        icon:   icons.search,
                                        link: `/parts/view/${r.q}`,
                                    }, 
                                ] : [
                                    {
                                        name:    'Stock Correction',
                                        icon:    icons.stock,
                                        onClick: this.handleStockCorrection,
                                    },
                                    {
                                        name:   'View',
                                        icon:   icons.search,
                                        link: `/parts/view/${r.q}`,
                                    }, 
                                ]
                            }
                        ]}
                        rows={_.orderBy(_.filter(incorrectOnly ?  _.filter(data, r => (parseFloat(r.i).toFixed(3) !== parseFloat(r.o).toFixed(3)) || (parseFloat(r.s).toFixed(3) !== parseFloat(r.d).toFixed(3))) : data, 'w'), 'w')}
                    />
                </Grid>
            </Grid>
        )
    }
};


function mapDispatchToProps(dispatch) { return { deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)) } };

export default connect(null, mapDispatchToProps)(StockReportsOverview);