import React       from 'react';
import API         from 'API';
import _           from 'lodash';
import { connect } from 'react-redux';

import { Button, Grid, Typography } from '@material-ui/core';

import DataTable     from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';

import { colors } from 'Helpers/ColourHelper';
import icons      from 'Helpers/IconHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = {
    lowInStock:     [],
    notLowInStock:  [],
    isLoading:      true,
}

class LowInStock extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }   

    componentDidMount() {
        this.getLowInStock();
    }

    getLowInStock() {
        API.get(`/stock/lowInStock`)
        .then(res => {
            let data = _.map(res.data, i => { return {...i, checked: false, diff: parseFloat(parseFloat(i.stock.stock_minimum_level) - parseFloat(i.stock.stock_predicted))} });
            this.setState({
                lowInStock:    _.filter(data, 'diff'),
                notLowInStock: _.filter(data, i => i.diff <= 0),
                isLoading: false
            });
        })
    }

    handleProcessCheck = f => id => {
        this.setState({ 
            [f]: _.map(this.state[f], i => {
                if (i.plis_id === id) i.checked = !i.checked;
                return i;
            })
        })
    }

    checkAll = f => () => {
        this.setState({ 
            [f]: _.map(this.state[f], i => {
                i.checked = true;
                return i;
            })
        })
    }

    dealWithChecked = () => {
        let promise = _.map( _.filter( _.merge( this.state.notLowInStock, this.state.lowInStock ), {checked: true} ), i => {
            return API.post(`/stock/lowInStock/${i.plis_id}`);
        } );
        if ( promise.length > 0 ) 
            Promise.all(promise).then(e=>{this.getLowInStock()});
    }

    dismissAllNotLowInStock = () => {
        let promise = _.map(this.state.notLowInStock, i => {
            return API.post(`/stock/lowInStock/${i.plis_id}`);
        })
        if ( promise.length > 0 ) 
            Promise.all(promise).then(e=>{this.getLowInStock()});
    }

    dealWithPart = id => {
        API.post(`/stock/lowInStock/${id}`).then(e=>{this.getLowInStock()});
    }

    render() {
        const { lowInStock, notLowInStock, isLoading } = this.state;

        if ( isLoading ) return ( <LoadingCircle /> );
        return (
            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>Low In Stock</Typography>
                </Grid>
                {_.filter(_.merge(lowInStock, notLowInStock), {checked: true}).length > 0 &&
                    <Grid item container xs={12} style={{justifyContent: 'flex-end'}}>
                        <Grid item xs={3}>
                            <PaddedPaper>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <b>Checked:</b>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {_.filter(_.merge(lowInStock, notLowInStock), {checked: true}).length}
                                    </Grid>
                                    {lowInStock.length > 0 && notLowInStock.length > 0 &&
                                        <>
                                            <Grid item xs={6}>
                                                <b>Low Checked:</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {_.filter(lowInStock, {checked: true}).length}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <b>Not Low Checked:</b>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {_.filter(notLowInStock, {checked: true}).length}
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <Button
                                            style={{marginTop: '1em'}}
                                            variant="contained"
                                            color="primary"
                                            onClick={()=> this.props.deployConfirmation('Are you sure, you want to deal with the checked parts?','Deal With Parts',this.dealWithChecked)}
                                            fullWidth
                                        >Mark Dealt With</Button>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                }
                {lowInStock.length > 0 && [ 
                    /*
                    <Grid item xs={12}>
                        <div className='buttonRow'>
                            <Button 
                                variant="contained"
                                color="primary"
                                onClick={this.checkAll('lowInStock')}
                            >Check All</Button>
                        </div>
                    </Grid>,*/
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <DataTable
                                config={{
                                    key:         'plis_id',
                                    pagination:  true,
                                    rowsPerPage: 50,
                                    filter:     true,
                                    filterMod:  {
                                        filterInPagination:     true,
                                        clearable:              true,
                                        colSpan:                3,
                                    },
                                }}
                                columns={[
                                    {
                                        field:         'part.default_image.thumbnail_file_path',
                                        fieldFormat:   'image',
                                        sizeToContent: true,
                                        filter:        'custom',
                                        filterMod:     {
                                            dataRef:    (search, i) => {return `${i.part.part_number}|${i.part?.part_description}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                                            label:      'Keywords',
                                            styleGrid:  {width: '50%'},
                                        }
                                    },
                                    {
                                        heading: 'Part/ Description',
                                        field:   rowData => <>{rowData.part?.part_number}<br/>{rowData.part?.part_description}</>,
                                        dataRef: 'part.part_number'
                                    },
                                    {
                                        heading:       'Current Stock',
                                        field:         'stock.stock_current_qty',
                                        dataRef:       'stock.stock_current_qty',
                                        fieldFormat:   'decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading:       'Pending Stock',
                                        field:         'stock.stock_pending',
                                        dataRef:       'stock.stock_pending',
                                        fieldFormat:   'decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading:       'Predicted Stock',
                                        field:         'stock.stock_predicted',
                                        dataRef:       'stock.stock_predicted',
                                        fieldFormat:   'decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading:       'Low Stock Level',
                                        field:         'stock.stock_minimum_level',
                                        dataRef:       'stock.stock_minimum_level',
                                        fieldFormat:   'decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true
                                    },
                                    {
                                        heading:       'Stock Below',
                                        field:         rowData => rowData.stock.stock_predicted - rowData.stock?.stock_minimum_level,
                                        dataRef:       rowData => rowData.stock.stock_predicted - rowData.stock?.stock_minimum_level,
                                        style:         rowData => ({color: (rowData.stock?.stock_minimum_level - rowData.stock?.stock_predicted) !== 0 && colors.red}),
                                        fieldFormat:   'decimal:3',
                                        alignment:     'right',
                                        sizeToContent: true
                                    },
                                    {
                                        actions: rowData => ([
                                            {
                                                name: 'View',
                                                icon: icons.search,
                                                link: `/parts/view/${rowData.part.part_id}`,
                                            },
                                            {
                                                name:    'Deal With',
                                                icon:    icons.tick,
                                                onClick: () => this.props.deployConfirmation('Are you sure, you want to deal with this part?','Deal With Part',()=>this.dealWithPart(rowData.plis_id))
                                            },
                                            { 
                                                type: 'checkBox',                   
                                                checked: rowData.checked, 
                                                onCheck: ()=>{this.handleProcessCheck('lowInStock')(rowData.plis_id)},  
                                            }
                                        ])
                                    }
                                ]}
                                rows={_.orderBy(_.filter(lowInStock, 'diff'), [i=>i.part?.part_number], ['asc'])}
                            />
                        </PaddedPaper>
                    </Grid>
                ]}
                {notLowInStock.length > 0 && 
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container style={{alignItems: 'flex-end'}}>
                                <Grid item xs={6}>
                                    <Typography variant="h6" gutterBottom>No Longer Low In Stock</Typography>
                                </Grid>
                                <Grid item xs={6} className='buttonRow' style={{marginTop: 0}}>
                                    <Button variant='contained' color='primary' onClick={this.dismissAllNotLowInStock}>Dismiss All</Button>
                                </Grid>
                                <Grid item xs={12}>
                                <DataTable
                                    config={{
                                        key:         'plis_id',
                                        pagination:  true,
                                        rowsPerPage: 50
                                    }}
                                    columns={[
                                        {
                                            field:         'part.default_image.thumbnail_file_path',
                                            fieldFormat:   'image',
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'Part/ Description',
                                            field:   rowData => <>{rowData.part?.part_number}<br/>{rowData.part?.part_description}</>,
                                            dataRef: 'part.part_number'
                                        },
                                        {
                                            heading:       'Current Stock',
                                            field:         'stock.stock_current_qty',
                                            dataRef:       'stock.stock_current_qty',
                                            fieldFormat:   'decimal:3',
                                            alignment:     'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading:       'Pending Stock',
                                            field:         'stock.stock_pending',
                                            dataRef:       'stock.stock_pending',
                                            fieldFormat:   'decimal:3',
                                            alignment:     'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading:       'Predicted Stock',
                                            field:         'stock.stock_predicted',
                                            dataRef:       'stock.stock_predicted',
                                            fieldFormat:   'decimal:3',
                                            alignment:     'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading:       'Low Stock Level',
                                            field:         'stock.stock_minimum_level',
                                            dataRef:       'stock.stock_minimum_level',
                                            fieldFormat:   'decimal:3',
                                            alignment:     'right',
                                            sizeToContent: true
                                        },
                                        {
                                            heading:       'Stock Below',
                                            field:         rowData => rowData.stock.stock_predicted - rowData.stock?.stock_minimum_level,
                                            dataRef:       rowData => rowData.stock.stock_predicted - rowData.stock?.stock_minimum_level,
                                            style:         rowData => ({color: (rowData.stock?.stock_minimum_level - rowData.stock?.stock_predicted) !== 0 && colors.green}),
                                            fieldFormat:   'decimal:3',
                                            alignment:     'right',
                                            sizeToContent: true
                                        },
                                        {
                                            actions: rowData => ([
                                                {
                                                    name: 'View',
                                                    icon: icons.search,
                                                    link: `/parts/view/${rowData.part.part_id}`,
                                                },
                                                {
                                                    name:    'Deal With',
                                                    icon:    icons.tick,
                                                    onClick: () => this.props.deployConfirmation('Are you sure, you want to deal with this part?','Deal With Part',()=>this.dealWithPart(rowData.plis_id))
                                                },
                                                { 
                                                    type: 'checkBox',                   
                                                    checked: rowData.checked, 
                                                    onCheck: ()=>{this.handleProcessCheck('notLowInStock')(rowData.plis_id)},  
                                                }
                                            ])
                                        }
                                    ]}
                                    rows={_.orderBy(notLowInStock, [i=>i.part?.part_number], ['asc'])}
                                />
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
            </Grid>
        )
    }
}

function mapDispatchToProps(dispatch) { return { deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)) } };

export default connect(null, mapDispatchToProps)(LowInStock);