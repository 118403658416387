import React, { Component, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import API from 'API';
import _ from 'lodash';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { Grid, Button, Box, Typography } from '@material-ui/core';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { closeDialog } from "Actions/Dialog/Dialog";
import { deployConfirmation } from "Actions/Confirmation/Confirmation";
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import moment from 'moment';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from "Actions/SnackBar/SnackBar";
import Textarea from 'Components/Common/Inputs/Textarea';

const LinkEquipmentToCustomerDialog = ({ equip, callBack }) => {

    const   dispatch = useDispatch(),
            [isLoading, setIsLoading] = useState(true),
            [formErrors, setFormErrors] = useState({}),
            // Lists
            [customers, setCustomers] = useState([]),
            [addresses, setAddresses] = useState([]),
            [reasons, setReasons] = useState([]),
            // Form Data
            [custId, setCustId] = useState(0),
            [moveDate, setMoveDate] = useState(null),
            [addressId, setAddressId] = useState(0),
            [reasonId, setReasonId] = useState(0),
            [notes, setNotes] = useState('');

    useEffect(() => {
        Promise.all([
            API.get(`/customers/withAddresses`),
            API.get('/equipment/reasons')
        ])
        .then(([custRes, reasonRes]) => {

            // Customer List
            let c = _.map(custRes.data, c => {
                return _.assign({ 
                    value: c.cust_id,
                    label: c.cust_name
                });
            })

            let custAddresses = [];
            if(custRes?.data) {
                _.forEach(custRes.data, c => {
                    _.forEach(_.filter(c.addresses, e => e.address_type === 'Site'), a => {
                        custAddresses.push({
                            value: a.address_id,
                            label: a?.address_name + ' | ' + a.address_line_one + ' | ' + a.address_town + ' | ' + a.address_postcode,
                            cust_id: c.cust_id
                        })
                    })
                })
            }

            // Reason List
            let r = _.map(reasonRes.data, r => {
                return _.assign({
                    value: r.id,
                    label: r.name
                });
            })
            
            setIsLoading(false);
            setCustomers(c);
            setAddresses(custAddresses);
            setReasons(r);
        })
    }, []);

    const handleSubmit = () => {
        API.post(`/equipment/${equip?.id}/linkCustomer`, {
            customerId: custId,
            addressId,
            moveDate,
            reasonId,
            notes
        })
        .then(res => {
            if(res.data.errors){
                setFormErrors(formatValidationErrors(res.data.errors));

            }
            if (res.data.success) {
                if(callBack) {
                    callBack();
                }
                dispatch(deploySnackBar("success", "You have successfully linked this equipment."));
                dispatch(closeDialog());
            }
        })
    };

    return (
        (isLoading && <LoadingCircle />) || (
            <Box>
                <Grid container spacing={1} className="pb-3">
                    <Grid item xs={12}>
                        <Grid container item spacing={1}>
                            <Grid item xs={6}>
                                <Typography variant="caption" paragraph>
                                    <b>Current customer:</b>
                                </Typography>
                                <div style={{display: 'flex'}}>
                                    <div style={{width: '30%'}}>
                                        <Typography variant="body2" gutterBottom>Serial Number:</Typography>
                                        <Typography variant="body2" gutterBottom>Customer:</Typography>
                                        <Typography variant="body2" gutterBottom>Address:</Typography>
                                    </div>
                                    <div style={{width: '70%'}}>
                                        <Typography variant="body2" gutterBottom>{equip?.sn}</Typography>
                                        <Typography variant="body2" gutterBottom>{equip?.cust?.name}</Typography>
                                        <Typography variant="body2" gutterBottom>{equip?.addressName}</Typography>
                                        <Typography variant="body2" gutterBottom>
                                            {equip?.address?.split('\n').map((item, key) => {
                                                return  <span key={key}>
                                                            {item}{equip?.address?.split('\n').length === key+1 ? null : <br/>}
                                                        </span>
                                            })}
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container item spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="caption" paragraph>
                                            <b>Change the customer this equipment is linked to:</b>
                                        </Typography>

                                        <AutoCompleteSelect
                                            label='Customer *'
                                            onChange={(o) => setCustId(o?.value ?? "")}
                                            options={customers}
                                            value={custId}
                                            error={formErrors && formErrors['customerId'] && true}
                                            errorText={formErrors && formErrors['customerId']}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AutoCompleteSelect
                                            label='Site Address *'
                                            onChange={(o) => setAddressId(o?.value ?? "")}
                                            options={custId ? _.filter(addresses, {cust_id: custId}) : []}
                                            value={addressId}
                                            fullWidth
                                            error={formErrors && formErrors['addressId'] && true}
                                            errorText={formErrors && formErrors['addressId']}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DatePicker
                                            type="date"
                                            id="date"
                                            name="moveDate"
                                            label={"Move Date *"}
                                            value={moveDate}
                                            onChange={date => setMoveDate(moment(date).format('YYYY-MM-DD'))}
                                            autoOk={true}
                                            inputProps={{
                                                className:"textDefault"
                                            }}
                                            error={formErrors && formErrors['moveDate'] && true}
                                            errorText={formErrors && formErrors['moveDate']}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AutoCompleteSelect
                                            label='Reason *'
                                            onChange={ (o) => setReasonId(o?.value ?? "")}
                                            options={ reasons }
                                            value={reasonId}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Textarea
                                            label="Notes"
                                            value={notes}
                                            onChange={e => setNotes(e.target.value)}
                                            fullWidth
                                            rows={5}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="buttonRow">
                            <Button color="default" onClick={() => dispatch(closeDialog())} variant="outlined">
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                className="textLight"
                                onClick={() => dispatch(deployConfirmation(`Are you sure you want to link ${equip?.sn} to ${_.find(customers, {value: custId})?.label}?`, `Link Customer`, handleSubmit))}
                                variant="contained"
                                disabled={!custId || !addressId}
                            >
                                Update
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        )
    )
};

export default LinkEquipmentToCustomerDialog;