import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import API from 'API';
import BarChart from 'Components/Common/Charts/BarChart';
import LineChart from 'Components/Common/Charts/LineChart';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { getColorCurrency, getColorPercentage, months } from 'Helpers/ReportsHelper';
import _ from 'lodash';
import React from 'react';

const initialState = () => ({
    isLoading: true,
    data: [],
})

const ConsistentSalesRow = ({icon, data, comparator, showRed, showComparator}) => {
	return (
		<>
			<Grid item xs={showComparator ? 2 : 3} style={{textAlign:'center'}}><AllIcon icon={icon} size='xs' /></Grid>
			<Grid item xs={showComparator ? 6 : 9} style={{color: showRed && colors.red}}>{data < 0 ? '-' : ''}£{parseFloat(Math.abs(data)).toFixed(2)}</Grid>
            {showComparator && <Grid item xs={4}>{comparator}</Grid> }
        </>
	)
}

class SalesReportCompleteMonth extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.year !== this.props.year || prevProps.month !== this.props.month) {
            this.setState(initialState(), this.getData);
        }
    }

    getData = () => {
        let params = {params: {cid:this.props.cid, partId:this.props.partId, year:this.props.year, month: this.props.month}}
        API.get('/reports/yoysReport/sales/month', params)
        .then(response => {
            this.setState({
                data: response.data, 
                isLoading: false
            });
        })
    }

    render() {
        const {isLoading, data} = this.state;
        const {year, month} = this.props;

        let
            currentYear = `${months[month]} ${month >= 10 ? (year-1) : year}`,
            lastYear = `${months[month]} ${month >= 10 ? (year-2) : (year-1)}`;


        return (
            <Grid item xs={12}>
                <Card style={{color: '#000',backgroundColor: '#FFF',padding: 0}} >
                    <CardHeader
                        avatar={<AllIcon icon={`TextToIcon:${`${month}`.padStart(2,'0')}`} size='medium' style={{color: '#fff'}} noMargin/>}
                        title={months[parseInt(month)]}
                        titleTypographyProps={{variant:'h6'}}
                        style={{background: 'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', color: '#FFF'}}
                    />
                    <CardContent style={{padding:15}}>
                    {isLoading ? 
                        <Grid item xs={12}>
                            <LoadingCircle/>
                        </Grid>
                    :
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>{currentYear}</b></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PaddedPaper style={{padding: 10}}>
                                            <Typography gutterBottom variant='caption' style={{paddingBottom: 8}}><b>Total Month</b></Typography>
                                            <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                <ConsistentSalesRow icon={IconHelper.products} data={data.c.p.c} comparator={getColorPercentage((data.c.p.c - data.c.p.l ), data.c.p.l)} />
                                                <ConsistentSalesRow icon={IconHelper.services} data={data.c.s.c} comparator={getColorPercentage((data.c.s.c - data.c.s.l ), data.c.s.l)} />
                                                <ConsistentSalesRow icon={IconHelper.total} data={data.c.t.c} comparator={getColorPercentage((data.c.t.c - data.c.t.l ), data.c.t.l)} />
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>{lastYear}</b></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PaddedPaper style={{padding: 10}}>
                                            <Typography variant='caption'><b>Total Month</b></Typography>
                                            <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                <ConsistentSalesRow icon={IconHelper.products} data={data.t.p.l} />
                                                <ConsistentSalesRow icon={IconHelper.services} data={data.t.s.l} />
                                                <ConsistentSalesRow icon={IconHelper.total} data={data.t.t.l} />
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Difference</b></Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <PaddedPaper style={{padding: 10}}>
                                            <Typography gutterBottom variant='caption' style={{paddingBottom: 8}}><b>Month Products</b></Typography>
                                            <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                <Grid item={3}>
                                                    <AllIcon icon={data.t.p.c > data.t.p.l ? IconHelper.arrowStemUp : ( data.t.p.c < data.t.p.l ? IconHelper.arrowStemDown : IconHelper.dash )} size={65} color={data.t.p.c > data.t.p.l ? colors.green :  ( data.t.p.c < data.t.p.l ? colors.red : colors.grey )} />
                                                </Grid>
                                                <Grid item={9}>
                                                    <Typography variant='body1'>{getColorPercentage((data.t.p.c - data.t.p.l ), data.t.p.l, false, false)}</Typography>
                                                    <Typography variant='h5'>{getColorCurrency((data.t.p.c - data.t.p.l ), false, false)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <PaddedPaper style={{padding: 10}}>
                                            <Typography gutterBottom variant='caption' style={{paddingBottom: 8}}><b>Month Services</b></Typography>
                                            <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                <Grid item={3}>
                                                    <AllIcon icon={data.t.s.c > data.t.s.l ? IconHelper.arrowStemUp : ( data.t.s.c < data.t.s.l ? IconHelper.arrowStemDown : IconHelper.dash )} size={65} color={data.t.s.c > data.t.s.l ? colors.green :  ( data.t.s.c < data.t.s.l ? colors.red : colors.grey )} />
                                                </Grid>
                                                <Grid item={9}>
                                                    <Typography variant='body1'>{getColorPercentage((data.t.s.c - data.t.s.l ), data.t.s.l, false, false)}</Typography>
                                                    <Typography variant='h5'>{getColorCurrency((data.t.s.c - data.t.s.l ), false, false)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <PaddedPaper style={{padding: 10}}>
                                            <Typography gutterBottom variant='caption' style={{paddingBottom: 8}}><b>Month Total</b></Typography>
                                            <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                <Grid item={3}>
                                                    <AllIcon icon={data.t.t.c > data.t.t.l ? IconHelper.arrowStemUp : ( data.t.t.c < data.t.t.l ? IconHelper.arrowStemDown : IconHelper.dash )} size={65} color={data.t.t.c > data.t.t.l ? colors.green :  ( data.t.t.c < data.t.t.l ? colors.red : colors.grey )} />
                                                </Grid>
                                                <Grid item={9}>
                                                    <Typography variant='body1'>{getColorPercentage((data.t.t.c - data.t.t.l ), data.t.t.l, false, false)}</Typography>
                                                    <Typography variant='h5'>{getColorCurrency((data.t.t.c - data.t.t.l ), false, false)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Day On Day</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Running Total</b></Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <PaddedPaper style={{padding: 10}}>
                                    <BarChart
                                        data={_.map(_.orderBy(_.keys(data.b), i=>i, 'asc'), idx => {

                                            let monthData = data.b[idx];
                                            let nullMonth = null;

                                            return {
                                                label: parseInt(idx),
                                                value: {
                                                    [currentYear] : nullMonth ? null : parseFloat(monthData['t']['c']),
                                                    [lastYear] :   parseFloat(monthData['t']['l']),
                                                },
                                                color: {
                                                    [currentYear] : colors.primary,
                                                    [lastYear] :    colors.red,
                                                },
                                            }
                                            
                                        })}
                                        yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                        tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                        style={{
                                            height: 200,
                                        }}
                                    />
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={6}>
                                <PaddedPaper style={{padding: 10}}>
                                    <LineChart
                                        data={
                                            [
                                                {
                                                    label:'0',
                                                    value: {
                                                        [currentYear] : 0,
                                                        [lastYear] :   0,
                                                    },
                                                    color: {
                                                        [currentYear] : colors.primary,
                                                        [lastYear] :    colors.red,
                                                    },
                                                },
                                                ..._.map(_.orderBy(_.keys(data.b2), i=>i, 'asc'), idx => {

                                            let monthData = data.b2[idx];
                                            let nullMonth = null;

                                            return {
                                                label: parseInt(idx),
                                                value: {
                                                    [currentYear] : nullMonth ? null : parseFloat(monthData['t']['c']),
                                                    [lastYear] :   parseFloat(monthData['t']['l']),
                                                },
                                                color: {
                                                    [currentYear] : colors.primary,
                                                    [lastYear] :    colors.red,
                                                },
                                            }
                                            
                                        })]}
                                        yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                        tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                        style={{
                                            height: 200,
                                        }}
                                        noPoints
                                    />
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    }
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default SalesReportCompleteMonth;