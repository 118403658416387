import React, { Component } from 'react';
import API from 'API';
import _ from 'lodash';

import { Box, Typography } from '@material-ui/core';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';

import { withStyles } from '@material-ui/core/styles';
import AllIcon from 'Components/Common/Icons/AllIcon';

const initialState = {
    actions: []
};

const typeToAction = {
    'Viewing': 'is viewing this order',
    'Approving': 'is approving this order',
    'Cancelling': 'is cancelling this order',
    'Declining': 'is declining this order',
    'Confirming': 'is confirming this order',
    'Processing Payment': 'is processing a payment for this order',
    'Picking': 'is picking this order',
    'Despatching': 'is despatching this order',
    'Editing': 'is editing this order',
}

const typeToIcon = {
    'Viewing': <AllIcon heavy icon={icons.view} color={colors.green} size='small' />,
    'Approving': <AllIcon heavy icon={icons.warning} color={colors.orange} size='small' />,
    'Declining': <AllIcon heavy icon={icons.warning} color={colors.red} size='small' />,
    'Confirming': <AllIcon heavy icon={icons.warning} color={colors.orange} size='small' />,
    'Processing Payment': <AllIcon heavy icon={icons.warning} color={colors.orange} size='small' />,
    'Cancelling': <AllIcon heavy icon={icons.warning} color={colors.red} size='small' />,
    'Picking': <AllIcon heavy icon={icons.picking} color={colors.orange} size='small' />,
    'Despatching': <AllIcon heavy icon={icons.truck} color={colors.orange} size='small' />,
    'Editing': <AllIcon heavy icon={icons.edit} color={colors.red} size='small' />,
}

const typeToTheme = {
    'Viewing': 'Green',
    'Cancelling': 'Red',
    'Declining': 'Red',
    'Approving': 'Orange',
    'Confirming': 'Orange',
    'Processing Payment': 'Orange',
    'Picking': 'Orange',
    'Despatching': 'Orange',
    'Editing': 'Red',
}

class StaffActions extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;

        this.timer = null;
    }

    componentDidMount() {
        this.startActions();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        this.endActions();
    }

    startActions = () => {
        API.post(`/sales/orders/${this.props.orderId}/actions/start`, {action: this.props.type}, {props: {noLoading: true}}).then(res => {
            this.getActions();
        });
    }

    endActions = () => {
        API.post(`/sales/orders/${this.props.orderId}/actions/end`,{}, {props: {noLoading: true}});
    }

    getActions = () => {
        API.get(`/sales/orders/${this.props.orderId}/actions`, {props: {noLoading: true}}).then(res => {
            this.setState({
                actions: res.data.s
            },() => {
                this.timer = setTimeout(()=>this.startActions(), 5000)
                this.props.lockCallback && this.props.lockCallback(res.data.l ? this.getLockBlock() : null);
            });
        });
    }

    getLockBlock = () => {
        let type = _.find(this.state.actions, i => i.t !== 'Viewing').t;
        return (
            <Box className={`${this.props.classes.lockBox} ${this.props.classes[`theme${typeToTheme[type]}`]}`}>
                <AllIcon icon={icons.lock} buttonPrimary color={typeToTheme[type].toLowerCase()} style={{paddingBottom: '0.3em'}} size='large' />
                <Typography variant='body1' style={{marginLeft: 5}}>Options unavailable while order is {type}</Typography>
            </Box>
        )
    }

    render() {
        const { actions }  = this.state;
        const { classes } = this.props;

        return (
            <>
                {
                    _.map(actions, (action, index) => <>
                        <Box key={index} display="flex" alignItems="center" className={`${classes.base} ${classes[`theme${typeToTheme[action.t]}`]}`}>
                            {typeToIcon[action.t]}{action.s} {typeToAction[action.t]}
                        </Box>
                    </>)
                }
            </>
        )
    }
}

const styles = theme => ({
    lockBox: {
        border: `1px solid ${colors.red}`,
        backgroundColor: `${colors.red}10`,
        color: colors.red,
        fontWeight: 'bold',
        padding: theme.spacing(1),
        textAlign: 'center',
    },
    base: {
        border: '1px solid #ccc',
        borderRadius: 5,
        padding: theme.spacing(1),
        fontWeight: 'bold',
        marginTop: theme.spacing(1),
    },
    themeGreen: {
        borderColor: colors.green,
        color: colors.green,
        backgroundColor: `${colors.green}10`,
    },
    themeOrange: {
        borderColor: colors.orange,
        color: colors.orange,
        backgroundColor: `${colors.orange}10`,
    },
    themeRed: {
        borderColor: colors.red,
        color: colors.red,
        backgroundColor: `${colors.red}10`,
    }
});


export default (withStyles(styles)(StaffActions));