import React, { useEffect, useState } from 'react';
import API from 'API';
import { Button, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { closeDialog } from 'Actions/Dialog/Dialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import IconHelper from 'Helpers/IconHelper';

const ReusableElementsDialog = ({ formConfigId }) => {
    const [reusableElements, setReusableElements] = useState([]);

    useEffect(() => {
        
        API.get(`/forms/configuration/elements/reusable`, {
            params: {
                formConfigId
            },
        })
        .then((response) => {
            setReusableElements(response.data?.elmnts);
        });

    }, [formConfigId]);

    const dispatch = useDispatch();

    const close = () => dispatch(closeDialog());

    return (
        <>
        <div>
            <List dense>
                {reusableElements.map((element) => (
                    <ListItem key={element.id} >
                        <ListItemIcon> 
                            <FAIcon icon={IconHelper[element.elType]} noMargin  />

                        </ListItemIcon>
                        <ListItemText 
                            primary={
                                <span style={{backgroundColor: 'aliceblue', padding: '4px'}}>{element.ref}</span>
                            } 
                            secondary={element.text} 
                        />
                    </ListItem>
                ))}
            </List>
        </div>
        <div className='buttonRow'>
            <Button 
                variant="outlined"
                onClick={close}
            >
                Close
            </Button>
        </div>
        </>
    );
};

export default ReusableElementsDialog;
