import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import DatePicker from '../../Common/DatePickers/DatePicker';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';

class PartOrderDetails extends PureComponent {  
    render() {
        const { supplierContactList, fao, faoEmail, orderDate, purchaseOrderDate, formErrors, handleSelectChange, handleDateChange, handleChange } = this.props;
        return (
            <PaddedPaper>
                <Grid container spacing={3}>
                    <Grid item lg={12}>
                        <Typography variant="h6" gutterBottom>
                            Parts Order Details
                        </Typography>
                        <form noValidate autoComplete="off">
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect
                                    name="fao"
                                    options={supplierContactList} 
                                    label='FAO *'
                                    value={fao}
                                    onChange={handleSelectChange('fao')}
                                    error={formErrors && formErrors['fao'] && true}
                                    errorText={formErrors && formErrors['fao']}
                                />
                            </FormControl>
                            <TextField
                                id="faoEmail"
                                name="faoEmail"
                                label="FAO Email *"
                                value={faoEmail}
                                error={formErrors && formErrors['faoEmail'] && true}
                                helperText={formErrors && formErrors['faoEmail']}
                                onChange={handleChange}
                                margin="normal"
                                fullWidth
                            />
                            <DatePicker
                                type="date"
                                id="purchaseOrderDate"
                                name="purchaseOrderDate"
                                label="Purchase Order Date *"
                                value={purchaseOrderDate}
                                errorText={formErrors && formErrors['purchaseOrderDate']}
                                onChange={handleDateChange('purchaseOrderDate')}
                                autoOk={true}
                            />
                            <DatePicker
                                type="date"
                                id="orderDate"
                                name="orderDate"
                                label="Order Date *"
                                value={orderDate}
                                errorText={formErrors && formErrors['orderDate']}
                                onChange={handleDateChange('orderDate')}
                                autoOk={true}
                            />
                        </form>
                    </Grid>
                </Grid>
            </PaddedPaper>
        );
    }
}

export default PartOrderDetails;
    