import React from 'react';
import API from 'API';
import { connect } from 'react-redux';
import { Link }   from 'react-router-dom';
import {
    Grid,
    TextField,
    Typography,
    Box,
    IconButton,
    Tooltip
} from '@material-ui/core';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import FALightIcon        from 'Components/Common/Icons/FontAwesome/FALightIcon';
import { TabPanel, toggleDialog } from 'Functions/MiscFunctions';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import TabBar from 'Components/Common/Navigation/TabBar';
import { handleTabChange, handleSelectChange } from 'Functions/FormFunctions';
import _ from 'lodash';
import ChangelogView from 'Components/Common/Changelog/ChangelogView';
import { FullScreenDialog }             from 'Components/Common/Dialogs/FullScreenDialog';
import { deployDialog, closeDialog }    from 'Actions/Dialog/Dialog';
import FormBuilder                      from 'Components/Common/Forms/FormBuilder/FormBuilder';
import Elements from 'Components/Common/Forms/FormBuilder/Elements';
import Alert from 'Components/Common/Alert/Alert';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import InformationForm from 'Components/Common/Forms/FormBuilder/InformationForm';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { CLENAWARE_BLUE } from 'Constants'

const initialState = () => ({
    formData: {
        pages: [],
    },
    currentTab: 'form',
    isLoading: true,
    formId: '',
    changelogId: '',
    associatedParts: '',
    associatedDocuments: '',
    linkedParts: '',
    access: {
        manageForms: false
    },
    dialog: {
        informationForm: false,
        informationDialogData: null
    },
    dataSets: [],
});

class FormView extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();      
        this.handleTabChange = handleTabChange.bind(this);
        this.handleSelectChange = handleSelectChange.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
    }

    componentDidMount = () => {
        const formId = this.props.formId || this.props.match.params.formId;
        this.setState({
            formId
        }, () => {
            this.loadComponentData();
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match?.params?.formId !== this.props.match?.params?.formId) {
            const formId = this.props.match.params.formId;
            this.setState({
                currentTab: 'form',
                isLoading: true,
                formId
            }, () => {
                this.loadComponentData();
            })
        }
    }

    loadComponentData = () => {

        this.checkAccess();

        Promise.all([
            API.get(`/forms/configuration/${this.state.formId}`,{ 
                params: {
                    enabledOnly: true
                }
            })
        ])
        .then(([
            result
        ]) =>  {
            if (result && result.data.errors) {
                if(this.props.history) {
                    this.props.history.push('/forms');
                }
            } else if (result && result.data) {

                let form            = result.data,
                    isLoading           = false,
                    changeLogOptionList = [];

                // Format associated parts into a comma separated list
                let associatedParts = [];
                let infoAssocParts = [];
                _.map(form.assocParts, (part) => {

                    associatedParts.push(part.number + ' - ' + part.desc);

                    infoAssocParts.push({
                        value: part.id,
                        label: part.number + ' - ' + part.desc
                    });
                });
                form.dataSets['associatedParts'] = infoAssocParts;


                // Format associated documents into a comma separated list
                let associatedDocuments = _.map(form.assocDocs, (doc) => {
                    return doc.subCategory  + ' - ' + doc.title;
                }).join(', ');

                // Format linked parts into a comma separated list
                let linkedParts = _.map(form.linkedParts, (part) => {
                    return part.number + ' - ' + part.desc;
                }).join(', ');

                this.setState({
                    form,
                    formData: {
                        pages: form.pages
                    },
                    isLoading,
                    changeLogOptionList,
                    associatedParts: associatedParts.join(', '),
                    associatedDocuments,
                    linkedParts,
                    dataSets: form.dataSets
                });
            }
        });
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/forms-search:manage-forms'),
        ])
        .then(([manageRes]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    manageForms: manageRes.data && manageRes.data.has_access
                }
            })
        });
    }

    openForm = (id = null) => {
        this.props.deployDialog(
            <FullScreenDialog 
                open={1} 
                onClose={this.props.closeDialog} 
                title={`${id ? 'Update': ' Add'} Form`} 
                withMargin
                style={{backgroundColor: '#fafafa'}}
                content={
                    <FormBuilder 
                        onClose={this.props.closeDialog}
                        formId={id}
                        refresh={this.loadComponentData}
                        history={this.props.history}
                    />
                }
            />, 
            "", 
            null, 
            'fs'
        )
    }

    handleElementChange = (pIdx, gIdx, eIdx, formData) => {

        let pages       = this.state.formData.pages;
        let groups      = pages[pIdx].groups;
        let elements    = groups[gIdx]?.elements
        
        elements[eIdx].answer = formData;

        this.setState({
            formData: {
                ...this.state.formData,
                pages
            },
        });
    }

    handleElementSelectChange = (pIdx, gIdx, eIdx, selectedOption) => {
        let pages       = this.state.formData.pages;
        let groups      = pages[pIdx].groups;
        let elements    = groups[gIdx]?.elements
        
        elements[eIdx].answer = selectedOption.value;

        this.setState({
            formData: {
                ...this.state.formData,
                pages
            },
        });
    }

    handleInformationOpen = (info) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                informationForm: true,
                informationDialogData: info ? info : null,
            },
        });
    }

    render() {
        const { isLoading, form, currentTab, formId, associatedParts, associatedDocuments, linkedParts, formData, access, dialog, dataSets } = this.state;

        return (
            <>
            {(isLoading && (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            )) || (
                <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            View Form
                        </Typography>
                    </Grid> 
                    {!form?.isLatest && (
                        <Grid item xs={12}>
                            <Box borderBottom='1px solid #ddd'>
                                <Alert 
                                    severity="warning" 
                                    variant="standard" 
                                    elevation={0}
                                >
                                    You are currently viewing a previous revision. {' '}
                                    <Link to={{ pathname: `/forms/view/`+form?.latest?.id}} style={{color: 'inherit', textDecoration: 'none'}}>
                                        <b>View Latest v{form?.latest?.v}</b>
                                    </Link> 
                                </Alert>
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TabBar 
                            tabs={[
                                {value: 'form',  label: `Form` },
                                {value: 'changelog', label: `Change Log` },
                            ]}
                            currentTab={currentTab} 
                            handleTabChange={this.handleTabChange}
                            disableMenu
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TabPanel value={currentTab} index={'form'}> 
                            <Grid container spacing={3} item>
                                <Grid item xs={12}>
                                    <Typography variant="body1" paragraph>
                                        <b>Form Details</b>
                                    </Typography>
                                    <Grid container spacing={3} item>
                                        <Grid item xs={12} lg={6}>
                                            <PaddedPaper>
                                                <Grid container spacing={1} item>
                                                    {form?.isLatest && access?.manageForms ? (
                                                        <Grid item xs={12} align='right'>
                                                            <IconButton 
                                                                onClick={() => this.openForm(formId)}
                                                            >
                                                                <FALightIcon icon='pencil-alt' noMargin button />
                                                            </IconButton>
                                                        </Grid>
                                                    ) : null}
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Type of Form"
                                                            disabled
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                            value={form?.sysCat?.nm}
                                                            margin="normal"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    {form?.linkedProductOrService !== 'N/A' &&
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                label={`Linked ${form?.linkedProductOrService == 'Product' ? 'Products' : 'Services'}`}
                                                                disabled
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                                value={linkedParts || ''}
                                                                margin="normal"
                                                                fullWidth
                                                                multiline
                                                            />
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1} item>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Area"
                                                                    disabled
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    inputProps={{
                                                                        className:"textDefault"
                                                                    }}
                                                                    value={form?.area?.nm}
                                                                    margin="normal"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Category"
                                                                    disabled
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    inputProps={{
                                                                        className:"textDefault"
                                                                    }}
                                                                    value={form?.cat?.nm || ''}
                                                                    margin="normal"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Sub Category"
                                                                    disabled
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    inputProps={{
                                                                        className:"textDefault"
                                                                    }}
                                                                    value={form?.subCat?.nm || ''}
                                                                    margin="normal"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Name"
                                                            disabled
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                            value={form?.nm || ''}
                                                            margin="normal"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Description"
                                                            disabled
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                            value={form?.desc || ''}
                                                            margin="normal"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1} item>
                                                            <Grid item xs={2}>
                                                                <TextField
                                                                    label="Version"
                                                                    disabled
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    inputProps={{
                                                                        className:"textDefault"
                                                                    }}
                                                                    value={'v'+form?.v}
                                                                    margin="normal"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    label="Status"
                                                                    disabled
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    inputProps={{
                                                                        className:"textDefault"
                                                                    }}
                                                                    value={form?.st || ''}
                                                                    margin="normal"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    label="Created By"
                                                                    disabled
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    inputProps={{
                                                                        className:"textDefault"
                                                                    }}
                                                                    value={form?.s?.nm || ''}
                                                                    margin="normal"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <PaddedPaper>
                                                <Grid container spacing={1} item>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Associated Parts"
                                                            disabled
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                            value={associatedParts || ''}
                                                            margin="normal"
                                                            fullWidth
                                                            multiline
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Associated Documents"
                                                            disabled
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            inputProps={{
                                                                className:"textDefault"
                                                            }}
                                                            value={associatedDocuments || ''}
                                                            margin="normal"
                                                            fullWidth
                                                            multiline
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} item justify="center">
                                        <Grid item xs={12}  align="center">
                                            <Typography variant="body1" paragraph>
                                                <b>FORM</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={8} align="center">
                                            {formData?.pages?.map((page, pIdx) => {
                                                return (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} align="right">
                                                            <Typography variant="body2" >
                                                                <b>Page {pIdx + 1}</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <PaddedPaper>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12} align="center">
                                                                        <Grid item xs={12}>
                                                                            <Grid container item spacing={3}>
                                                                                <Grid item xs={12} align="left">
                                                                                    {page.groups?.map((group, gIdx) => {
                                                                                        return (
                                                                                            <React.Fragment key={gIdx}>
                                                                                            <Box style={{backgroundColor: CLENAWARE_BLUE, border: '1px solid rgb(241, 241, 241)', color: 'white'}} p={2} mt={1}>
                                                                                                <Grid container spacing={3} style={{justifyContent: 'space-between'}}>
                                                                                                    <Grid item align="left">
                                                                                                        <Typography variant="subtitle1" gutterBottom>
                                                                                                            {group?.nm}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Box>
                                                                                            <Box p={2}>
                                                                                                <Grid container spacing={3}>
                                                                                                    {group?.elements.map((e, eIdx) => {
                                                                                                        return (
                                                                                                            <React.Fragment key={eIdx}>
                                                                                                                <Grid item xs={12} align="left">
                                                                                                                    <Typography variant="body1">
                                                                                                                        <b>{e?.nm}</b>
                                                                                                                        {e?.elConfig?.isRequired ? <span style={{color:'red'}}> *</span> : null}
                                                                                                                        {' '}
                                                                                                                        {e?.info && e?.info?.length > 0 && (
                                                                                                                            <AllIcon icon='circle-info' noMargin fixedHeight button size={14} onClick={() => this.handleInformationOpen(e?.info)} />
                                                                                                                        )}
                                                                                                                    </Typography>
                                                                                                                    {e?.description &&
                                                                                                                        <Typography variant="caption" paragraph>
                                                                                                                            {e?.description}
                                                                                                                        </Typography>
                                                                                                                    }
                                                                                                                    <Elements 
                                                                                                                        element={e}
                                                                                                                        eIdx={eIdx}
                                                                                                                        dataSets={dataSets}
                                                                                                                        handleChange={(formData) => this.handleElementChange(pIdx, gIdx, eIdx, formData)}
                                                                                                                        handleSelectChange={(formData) => this.handleElementSelectChange(pIdx, gIdx, eIdx, formData)}
                                                                                                                    />
                                                                                                                    {e?.elConfig?.resultType == 'pre-populated' && e?.elConfig?.resultTypeData && (
                                                                                                                        <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                                                                                                                            <b>Result pre-populated:</b> {e?.elConfig?.resultTypeData?.form?.label} - {e?.elConfig?.resultTypeData?.ref}
                                                                                                                        </Typography>
                                                                                                                    )}
                                                                                                                    {e?.elConfig?.resultType == 'reusable' && (
                                                                                                                        <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                                                                                                                            <b>Result reusable ref:</b> {e?.elConfig?.resultTypeData?.ref}
                                                                                                                        </Typography>
                                                                                                                    )}
                                                                                                                </Grid>
                                                                                                                
                                                                                                                <Grid item xs={12}>
                                                                                                                    {_.map(e?.elConfig.options, (option, oIdx) => (

                                                                                                                        <Box style={{
                                                                                                                            marginLeft: '8.5%', 
                                                                                                                            display: (_.isArray(e?.answer) && _.find(e?.answer, el => el.checked === option.value) || option?.value == e?.answer) ? 'block' : 'none'
                                                                                                                        }}>
                                                                                                                            {_.map(option?.nestedElements, (n, nIdx) => {
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                    <Grid container spacing={1} key={eIdx} style={{marginBottom: 30}}>
                                                                                                                                        <Grid item xs={12}>
                                                                                                                                            <Typography variant="body1" gutterBottom>
                                                                                                                                                <b>{n?.formData?.nm}</b>
                                                                                                                                                {n?.formData?.elConfig?.isRequired ? <span style={{color:'red'}}> *</span> : null}
                                                                                                                                                {' '}
                                                                                                                                                {n?.formData?.info && n?.formData?.info?.length > 0 && (
                                                                                                                                                    <AllIcon icon='circle-info' noMargin fixedHeight button size={15} onClick={() => this.handleInformationOpen(n?.formData?.info)} />
                                                                                                                                                )}
                                                                                                                                            </Typography>
                                                                                                                                            {n?.formData?.description &&
                                                                                                                                                <Typography variant="caption" gutterBottom>
                                                                                                                                                    {n?.formData?.description}
                                                                                                                                                </Typography>
                                                                                                                                            }
                                                                                                                                        </Grid>
                                                                                                                                        <Grid item xs={8}>
                                                                                                                                            <Elements 
                                                                                                                                                element={n?.formData}
                                                                                                                                                eIdx={eIdx}
                                                                                                                                                dataSets={dataSets}
                                                                                                                                            />
                                                                                                                                            {n?.formData?.elConfig?.resultType == 'pre-populated' && n?.formData?.elConfig?.resultTypeData && (
                                                                                                                                                <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                                                                                                                                                    <b>Result pre-populated:</b> {n?.formData?.elConfig?.resultTypeData?.form?.label} - {n?.formData?.elConfig?.resultTypeData?.ref}
                                                                                                                                                </Typography>
                                                                                                                                            )}
                                                                                                                                            {n?.formData?.elConfig?.resultType == 'reusable' && (
                                                                                                                                                <Typography variant="caption"  style={{backgroundColor: 'aliceblue', padding: '4px'}}>
                                                                                                                                                    <b>Result reusable ref:</b> {n?.formData?.elConfig?.resultTypeData?.ref}
                                                                                                                                                </Typography>
                                                                                                                                            )}
                                                                                                                                        </Grid>
                                                                                                                                        <Grid item xs={4} align="right" >
                                                                                                                                        </Grid>
                                                                                                                                    </Grid>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            })}
                                                                                                                        </Box>
                                                                                                                    ))}
                                                                                                                </Grid>
                                                                                                            </React.Fragment>
                                                                                                        )
                                                                                                    })}
                                                                                                </Grid>
                                                                                                
                                                                                            </Box>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </PaddedPaper>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={currentTab} index={'changelog'}> 
                            <ChangelogView
                                changeLogs={form?.cl}
                            />
                        </TabPanel>
                    </Grid>
                </Grid>
                {/* Information Dialog */}
                <SmallFormDialog
                    open={dialog.informationForm}
                    onClose={() => this.toggleDialog('informationForm', true)}
                    title={`Information`}
                    content={
                    <InformationForm 
                        data={dialog?.informationDialogData}
                        toggleDialog={this.toggleDialog}
                        readOnly={true}
                    />}
                    maxWidth='sm'
                />
                </>
            )}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deployDialog: (content, title, variant, size='md')  => {dispatch(deployDialog(content, title, variant, size))},
        closeDialog:  ()                                    => {dispatch(closeDialog())}
    };
}

export default connect(null, mapDispatchToProps)(FormView);