import React from 'react';
import { Grid, Typography } from '@material-ui/core/';
import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';
import Campaigns from './Tabs/Campaigns';
import CampaignsOverview from './Tabs/CampaignsOverview';
import CampaignPromotions from './Tabs/CampaignPromotions';

export default function(){

    let [tab, setTab] = React.useState( 'Campaign Overview');
    let [campaignId, setCampaignId] = React.useState(null);

    const setCampaignReport = (data) => {
        setCampaignId(data.id);
        setTab('Campaigns');
    }
    
    return (
        <Grid container xs={12} style={{width: '100%'}}>
            <Grid item xs={12} style={{ marginBottom: '1.5em'}}>
                <Typography variant="h5">
                    Marketing Reports
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TabBarSwitcher
                    onChange={setTab}
                    tab={tab}
                    tabs={[
                            {
                                label:   'Campaign Overview',
                                content: <CampaignsOverview  setCampaignReport={setCampaignReport}/>
                            },
                            {
                                label:   'Campaigns',
                                content: <Campaigns campaignId={campaignId}/>
                            },
                            {
                                label:   'Campaign Promotions',
                                content: <CampaignPromotions />
                            },
                        ]}
                />
            </Grid>
        </Grid>
    )
}


