import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import API from 'API';

import { Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'

import BackButton from 'Components/Common/Buttons/BackButton';
import MinMaxCalculator from 'Components/Stock/MinMaxCalculator/MinMaxCalculator';

import { formatValidationErrors } from 'Helpers/ErrorHelper';

import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar from 'Components/Common/SnackBars/SnackBar';

const initialState = {
    formData:  {
        part: '',
        sparePartsPercentage: '',
        minLevel: '',
        maxLevel: '',
        calculatorPercentage: ''
    },
    showResults: false,
    calculatorResults: {},
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    redirect: false
}

class UpdateStock extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getStock();
    }

    getStock = () => {
        API.get(`/stock/${this.props.match.params.id}`)
        .then((result) =>  {
            if (result.data.errors) {
                this.setState({ redirect: true });
            } else {
                this.setState({
                    formData:{
                        ...this.state.formData,
                        part: result.data.part.part_number + ' - ' + result.data.part.part_description,
                        minLevel: result.data.stock_minimum_level,
                        maxLevel: result.data.stock_maximum_level,
                        sparePartsPercentage: result.data.stock_spare_parts_percentage
                    }
                });
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        API.post(`/stock/${this.props.match.params.id}`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.getStock();
            }
            this.props.scrollToTop();
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        });
    }

    handleDecimalChange = decimals => e => {
        const { name, value } = e.target;
        let newVal = parseFloat(value).toFixed(decimals);
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: newVal
            }
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    calculate = () => {
        API.post(`/stock/${this.props.match.params.id}/calculator`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    calculatorResults: result.data,
                    showResults: true
                });
            }
        });
    }
       
    render() {
        const { formErrors, calculatorResults } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/stock/min-max-levels" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Update Stock Levels
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                <Typography variant="h6">
                                    Stock Level Details
                                </Typography>
                                <FormControl margin="normal" fullWidth>
                                    <TextField
                                        name="part"
                                        label='Part'
                                        value={this.state.formData.part}
                                        disabled
                                    />
                                </FormControl>
                                <Grid container spacing={3}>
                                    <Grid item lg={6}>
                                        <FormControl margin="normal">
                                            <TextField
                                                name="minLevel"
                                                label='Minimum Level *'
                                                value={this.state.formData.minLevel}
                                                error={formErrors && formErrors['minLevel'] && true}
                                                helperText={formErrors && formErrors['minLevel']}
                                                onChange={this.handleChange}
                                                onBlur={this.handleDecimalChange(3)}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormControl margin="normal">
                                            <TextField
                                                name="maxLevel"
                                                label='Maximum Level *'
                                                value={this.state.formData.maxLevel}
                                                error={formErrors && formErrors['maxLevel'] && true}
                                                helperText={formErrors && formErrors['maxLevel']}
                                                onChange={this.handleChange}
                                                onBlur={this.handleDecimalChange(3)}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <FormControl margin="normal">
                                    <TextField
                                        name="sparePartsPercentage"
                                        label='Spare Parts Percentage'
                                        value={this.state.formData.sparePartsPercentage}
                                        error={formErrors && formErrors['sparePartsPercentage'] && true}
                                        helperText={formErrors && formErrors['sparePartsPercentage']}
                                        onChange={this.handleChange}
                                        onBlur={this.handleDecimalChange(0)}
                                        type="number"
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment  position="end">
                                                %
                                              </InputAdornment>
                                            ),
                                        }}
                                        // eslint-disable-next-line react/jsx-no-duplicate-props
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                                <div className='buttonRow'>
                                    <BackButton props={this.props} />
                                    <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary">
                                        Update
                                    </Button>
                                </div>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                       {/*} <Grid item xs={12} lg={6}>
                            <PaddedPaper style={{backgroundColor:'#616161'}}>
                                <PaddedPaper>
                                    <Typography variant="h6" >
                                        Stock Level Calculator
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        If the part is used in other parts then the calculator will automatically calculate the spare part figures.<br></br>
                                        If the part is not used in other parts then it will just display the spare part figures based on the current min / max levels.
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={6}>
                                            <FormControl margin="normal" fullWidth>
                                                <TextField
                                                    name="calculatorPercentage"
                                                    label='Spare Parts Percentage'
                                                    value={this.state.formData.calculatorPercentage}
                                                    error={formErrors && formErrors['calculatorPercentage'] && true}
                                                    helperText={formErrors && formErrors['calculatorPercentage']}
                                                    onChange={this.handleChange}
                                                    onBlur={this.handleDecimalChange(0)}
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment  position="end">
                                                            %
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                                    inputProps={{ min: 0 }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} lg={6}><br></br>
                                            <Button onClick={this.calculate}
                                                    variant="outlined">
                                                Calculate
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {this.state.showResults &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Table style={{marginTop:20}}>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell style={{fontWeight:'bold'}}>Based on Direct Usage Parts:</TableCell>
                                                            <TableCell style={{fontWeight:'bold'}}>Min</TableCell>
                                                            <TableCell>{calculatorResults.parts_min}</TableCell>
                                                            <TableCell style={{fontWeight:'bold'}}>Max</TableCell>
                                                            <TableCell>{calculatorResults.parts_max}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{fontWeight:'bold'}}>Based on X%:</TableCell>
                                                            <TableCell style={{fontWeight:'bold'}}>Min</TableCell>
                                                            <TableCell>{calculatorResults.percentage_min}</TableCell>
                                                            <TableCell style={{fontWeight:'bold'}}>Max</TableCell>
                                                            <TableCell>{calculatorResults.percentage_max}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{fontWeight:'bold'}}>Total:</TableCell>
                                                            <TableCell style={{fontWeight:'bold'}}>Min</TableCell>
                                                            <TableCell>{calculatorResults.total_min}</TableCell>
                                                            <TableCell style={{fontWeight:'bold'}}>Max</TableCell>
                                                            <TableCell>{calculatorResults.total_max}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Typography variant="subtitle2" gutterBottom style={{fontWeight:'bold'}}>
                                                    Directly Used By:
                                                </Typography>
                                                {_.map(calculatorResults.direct_parts, el => {
                                                    return (
                                                        <Typography variant="body2" gutterBottom style={{color: el.status !== 'Active' ? colors.red : ''}}>
                                                            {el.part}
                                                        </Typography>
                                                    )
                                                })}
                                            </Grid>
                                            <Grid item xs lg={6}>
                                                <Typography variant="subtitle2" gutterBottom style={{fontWeight:'bold'}}>
                                                    Indirectly Used By:
                                                </Typography>
                                                {_.map(calculatorResults.indirect_parts, el => {
                                                    return (
                                                        <Typography variant="body2" gutterBottom style={{color: el.status !== 'Active' ? colors.red : ''}}>
                                                            {el.part}
                                                        </Typography>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>
                                    }
                                </PaddedPaper>
                            </PaddedPaper>
                        </Grid>*/}
                        <Grid item xs={12} lg={6}>
                            <MinMaxCalculator 
                                id= {this.props.match.params.id}
                                min={this.state.formData.minLevel}
                                max={this.state.formData.maxLevel}
                            />
                        </Grid>
                    </Grid>
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen} 
                        success={this.handleConfirmationSuccess} 
                        close={this.handleConfirmationClose} 
                        title="Update Stock Levels?" 
                        message="Are you sure you want to update these stock levels?"
                    />
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarOpen}
                        onClose={this.handleSnackbarClose}
                        message='You have successfully updated these stock levels'
                    />
                </Grid>
            );
        }
    }
}

export default UpdateStock;
