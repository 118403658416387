import { Button, FormControl, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import API from 'API';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import BackButton from 'Components/Common/Buttons/BackButton';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import { incotermsList } from 'Helpers/SalesHelper';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PartItems from './PartItems';

const styles = theme => ({
    td : {
        textAlign: 'right'
    },
    label: {
        color: 'black'
    },
    underline: {
        "&&&:before": {
          borderBottom: "none"
        },
        "&&:after": {
          borderBottom: "none"
        }
      },
      subTotals : {
        fontWeight: 'bold'
    },
});

const initialState = {
    formData:  {
        orderId: '',
        despatchId: '',
        invoiceNumber: '',
        bolNumber: '',
        sageInvoiceNumber: '',
        dateOfExport: moment().toString(),
        orderNumber: '',
        deliveryReference: '',
        accountReference: '',
        customsBroker: '',

        vatRateDecimal: '',
        netValueOfGoods: '',
        netFreightCost: '',
        netInsuranceCost: '',
        subTotal: '',
        totalVAT: '',
        totalGross: '',

        incoterms: '',
        incotermDestination:'',
        totalNumberOfPackages: '',
        totalNetWeight: '',
        freightType: 'Freight Prepaid',

        // From
        fromEORINo:'',

        // Sold To
        customerName: '',
        customerAddress: '',
        customerContact: '',
        customerPhone: '',
        customerMobile: '',
        customerEmail: '',
        customerVatRegNo: '',
        customerEORINo: '',
        // Deliver To
        deliveryCustomerAddress: '',
        deliveryCustomerContact: '',
        deliveryCustomerTel: '',
        deliveryCustomerEmail: '',

        vatValueId: '',
        // Declaration
        declarationName: '',
        declarationSignature: '',
        declarationJobTitle: '',
        partRows: [{
            partId: '',
            partNumber: '',
            partDescription: '',

            warrantyId: '',
            warrantyName: '',
            warrantyDuration: '',
            serialNumbers: '',
            warrantyLabels: '',

            quantity: '',
            hsNumber: '',
            countryOfOrigin: '',
            unitWeight: '',
            unitOfMeasure: '',
            discount:'',
            unitPrice: '',
            unitDiscount: '',
            newUnitPrice: '',
            subTotal: '',
            discountTotal: '',
            totalAmount: '',
            notes: ''
        }],
        previewMode: false,
    },
    lists: {
        customerAddressList: [],
        customerContactList: [],
        deliveryAddressList: [],
        deliveryContactList: [],
    },
    customer: {},
    customerContacts: {},
    deliveryCustomerContacts: {},
    customerAddresses: [],
    customerContactList: [],
    registeredContactList: [],
    formErrors: [],
    invoiceNumberList: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: false,
    allowSubmit: true
}

class AddCommercialInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getInitialData();
    }

    getInitialData = () => {
        
        this.setState({
            ...this.state,
            isLoading: true
        },
        () => {
            Promise.all([ 
                API.get(`/sales/orders/commercialInvoice/fromDespatch/${this.props.match.params.despatchId}`),
            ])
            .then(([result]) =>  {
                if(result.data) {
                    let allowSubmit = ( result.data.commercial_cust_eori_number != '' && result.data.commercial_cust_vat_reg_number != '' && 
                                        result.data.commercial_declaration_job_title != '' && result.data.commercial_declaration_signature != '')
                    
                    let partRows = [];
                    result.data.details.forEach((el) => {
                        let partRow = {
                            partId: el.commercial_detail_part_id,
                            partNumber: el.commercial_detail_part_number,
                            partDescription: el.commercial_detail_part_description,

                            warrantyId: el.commercial_detail_warranty_id,
                            warrantyName: el.commercial_detail_warranty_name,
                            warrantyDuration: el.commercial_detail_warranty_duration,
                            serialNumbers: el.commercial_detail_serial_numbers,
                            warrantyLabels: el.commercial_detail_warranty_labels,

                            quantity: el.commercial_detail_quantity,
                            hsNumber: el.commercial_detail_hs_number,
                            countryOfOrigin: el.commercial_detail_country_of_origin,
                            unitWeight: el.commercial_detail_unit_weight,
                            unitOfMeasure: el.commercial_detail_unit_of_measure,
                            discount: el.commercial_detail_discount_rate,
                            unitPrice: el.commercial_detail_unit_price,
                            unitDiscount: el.commercial_detail_unit_discount,
                            newUnitPrice: el.commercial_detail_new_unit_price,
                            subTotal: el.commercial_detail_sub_total,
                            discountTotal: el.commercial_detail_discount_total,
                            totalAmount: el.commercial_detail_total_amount,
                            notes: el.commercial_detail_notes
                        }
                        partRows.push(partRow);
                    });

                    let invoiceNumberList = _.map(result.data?.invoiceNumbers, (el) => {
                        return _.assign({
                            value: el.invoiceNumber,
                            label: el.invoiceNumber
                        });
                    });

                    this.setState({
                        isLoading: false,
                        allowSubmit,
                        snackbarOpen: !allowSubmit,
                        invoiceNumberList,
                        invoiceToSageList: result.data?.invoiceNumbers,
                        formData: {
                            ...this.state.formData,
                            orderId: result.data.commercial_order_id,
                            despatchId: result.data.commercial_despatch_id,
                            invoiceNumber: result.data.commercial_invoice_number,
                            bolNumber: null,
                            orderNumber: result.data.commercial_po_details,
                            deliveryReference: result.data.commercial_delivery_reference,
                            accountReference: result.data.commercial_account_reference,
                            // Totals
                            vatRateDecimal: result.data.vatRateDecimal,
                            netValueOfGoods: result.data.commercial_net_value_of_goods,
                            subTotal: result.data.commercial_net_value_of_goods,
                            // Sold To
                            customerId: result.data.commercial_cust_id,
                            customerName: result.data.commercial_cust_name,
                            customerAddress: result.data.commercial_cust_address,
                            customerContact: result.data.commercial_cust_contact_name,
                            customerPhone: result.data.commercial_cust_contact_phone,
                            customerMobile: result.data.commercial_cust_contact_mobile,
                            customerEmail: result.data.commercial_cust_contact_email,
                            customerEORINo: result.data.commercial_cust_eori_number,
                            customerVatRegNo: result.data.commercial_cust_vat_reg_number,
                            // Deliver To
                            deliveryCustomerAddress: result.data.commercial_delivery_cust_address,
                            deliveryCustomerContact: result.data.commercial_delivery_cust_contact_name,
                            deliveryCustomerTel: result.data.commercial_delivery_cust_contact_tel,
                            deliveryCustomerEmail: result.data.commercial_delivery_cust_contact_email,
                            vatValueId: result.data.commercial_vat_value_id,
                            // Declaration
                            declarationName: result.data.commercial_declaration_name,
                            declarationSignature: result.data.commercial_declaration_signature,
                            declarationJobTitle: result.data.commercial_declaration_job_title,
                            partRows
                        }
                    }, 
                    () => {
                        this.calculateTotalInvoiceValue();
                    });
                }
                else {
                    window.location.href = `/sales/order/dispatch`;
                }
            });
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData:{
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        },
        () => {
            if(fieldName == 'invoiceNumber' && selectedOption){

                //get sage number
                let sin = _.find(this.state.invoiceToSageList, {invoiceNumber:this.state.formData?.invoiceNumber}).sageInvoiceNumber;

                this.setState({
                    formData:{
                        ...this.state.formData,
                        bolNumber:          sin ? sin : null,
                        sageInvoiceNumber:  sin ? sin : null,

                    }
                });
            }
        });
    }

    handleChange  = decimals => e => {
        let { name, value } = e.target;
        
        let newVal = (decimals && value != '') ? parseFloat(value).toFixed(decimals) : value;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: newVal
            }
        },
        () => {
            if (name == 'sageInvoiceNumber') {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        bolNumber: newVal
                    }
                });
            }
            if(name == 'netFreightCost' || name == 'netInsuranceCost' || name == 'totalVAT' ){
                this.calculateTotalInvoiceValue()
            }
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true
        });
    };
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };
    handleConfirmationSuccess = () => {
        this.setState({ 
            formData: {
                ...this.state.formData,
                previewMode: false
            },
            confirmationOpen: false 
        });
        this.submit();
    }

    submit = () => {
        this.setState({
            formErrors: [],
            isLoading: true,
            
        },
        () => {
            const formData = {
                ...this.state.formData,
                dateOfExport: (moment(new Date(this.state.formData.dateOfExport)).format('DD/MM/YYYY')),
            }
            API.post(`/sales/orders/commercialInvoice`, formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                    this.props.scrollToTop();
                }
                else {
                    if(result.data.pdf){
                        this.setState({
                            isLoading: false
                        },
                        () => {
                            pdfFromBase64(result.data.pdf, result.data.pdfName);
                        });
                    }
                    else {
                        this.props.deploySnackBar("success", "You have successfully created the commercial invoice");
                        this.setState({
                            isLoading: false,
                        }, () => {
                            window.location.href = `/sales/order/view/`+this.state.formData.orderId;
                        });
                    }
                }
            });
        });
    }

    handlePreview = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                previewMode: true
            }
        },
        () => {
            this.submit();
        });
    }

    handleDateChange = fieldName => date => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: moment(date).format('YYYY-MM-DD')
            }
        });
    };

    handleRowChange = (idx, array, decimals) => e => {
        const { name, value } = e.target;
        let newArray =  [...this.state.formData[array]];
        let newVal = ((decimals && !isNaN(value)) ? parseFloat(value).toFixed(decimals) : value);

        newArray[idx] = {
            ...newArray[idx],
            [name]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                [array]: newArray 
            }
        });
    };

    calculateTotalInvoiceValue = () => {
        let totalGross = 0;

        let netFreightCost      = this.state.formData.netFreightCost || 0;
        let netInsuranceCost    = this.state.formData.netInsuranceCost || 0;
        let totalVAT            = this.state.formData.totalVAT || 0;

        let subTotal            = (parseFloat(this.state.formData.netValueOfGoods) + parseFloat(netFreightCost) + parseFloat(netInsuranceCost)).toFixed(2);
        totalGross              = (parseFloat(subTotal) + parseFloat(totalVAT)).toFixed(2);

        this.setState({
            formData: {
                ...this.state.formData,
                subTotal,
                totalGross
            }
        });
    }
       
    render() {
        const { classes } = this.props;
        const { formErrors, formData, invoiceNumberList } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Commercial Invoice
                    </Typography>
                </Grid>
                {(this.state.isLoading && (
                    <div>
                        <LoadingCircle />    
                    </div>
                )) || (  
                    <React.Fragment>
                        <Grid container item spacing={3}>
                            <Grid container item spacing={3}>
                                <Grid item xs={4}>
                                    <PaddedPaper>
                                        <Grid container item spacing={1}>
                                            <Grid item xs={4}>
                                                <b>From:</b>
                                            </Grid>
                                            <Grid item xs={8}>
                                                Clenaware Systems Limited <br />
                                                44 Huxley Close <br />
                                                Park Farm Industrial Estate <br />
                                                Wellingborough <br />
                                                Northamptonshire <br />
                                                NN8 6AB <br/>
                                                United Kingdom
                                            </Grid>
                                            <Grid item xs={4}>
                                                <b>Contact:</b>
                                            </Grid>
                                            <Grid item xs={8}>
                                                Richard Harris
                                            </Grid>
                                            <Grid item xs={4}>
                                                <b>Tel:</b>
                                            </Grid>
                                            <Grid item xs={8}>
                                                01933 666244 / 01933 665584
                                            </Grid>
                                            <Grid item xs={4}>
                                                <b>Email:</b>
                                            </Grid>
                                            <Grid item xs={8}>
                                                rharris@clenaware.co.uk
                                            </Grid>
                                            <Grid item xs={4}>
                                                <b>VAT Reg No: </b>
                                            </Grid>
                                            <Grid item xs={8}>
                                                978060588
                                            </Grid>
                                            <Grid item xs={4}>
                                                <b>EORI No: </b>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField 
                                                    name="fromEORINo"
                                                    value={formData.fromEORINo}
                                                    onChange={this.handleChange()}
                                                    error={formErrors && formErrors['fromEORINo'] && true}
                                                    helperText={formErrors && formErrors['fromEORINo']}
                                                    margin="none"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={4}>
                                    <PaddedPaper>
                                        <Grid container item spacing={1}>
                                            <Grid item xs={4}>
                                                <b>Sold To:</b>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formData.customerName} <br />
                                                {formData.customerAddress && formData.customerAddress.split('\n').map((item, key) => {
                                                    return <Typography variant="body2" key={key}>{item}</Typography>
                                                })}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <b>Contact:</b>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formData.customerContact}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <b>Tel:</b>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formData.customerPhone && formData.customerMobile ? 
                                                formData.customerPhone + ' / ' + formData.customerMobile
                                                : 
                                                (formData.customerPhone ? formData.customerPhone : formData.customerMobile)
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                <b>Email:</b>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formData.customerEmail}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <b>VAT Reg No: </b>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formData.customerVatRegNo}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <b>EORI No: </b>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formData.customerEORINo}
                                            </Grid>
                                        </Grid>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={4}>
                                    <PaddedPaper>
                                        {invoiceNumberList?.length > 0 ? 
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteSelect
                                                    options={invoiceNumberList}
                                                    label='Invoice Number *'
                                                    value={formData.invoiceNumber}
                                                    onChange={this.handleSelectChange('invoiceNumber')}
                                                    error={formErrors && formErrors['invoiceNumber'] && true}
                                                    errorText={formErrors && formErrors['invoiceNumber'] && formErrors['invoiceNumber']}
                                                />
                                            </FormControl>
                                        :
                                            <TextField 
                                                name="invoiceNumber"
                                                label="Invoice Number"
                                                value={formData.invoiceNumber}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                                InputProps={{
                                                    className: 'disabledText'
                                                }}
                                            />
                                        }
                                        <TextField 
                                            id="bolNumber"
                                            name="bolNumber"
                                            label='BOL Number'
                                            value={formData.bolNumber || ''}
                                            onChange={this.handleChange()}
                                            error={formErrors && formErrors['bolNumber'] && true}
                                            helperText={formErrors && formErrors['bolNumber']}
                                            margin="normal"
                                            disabled={invoiceNumberList.length > 0 || true}
                                            fullWidth
                                            onInput = {(e) =>{
                                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,7)
                                            }}
                                            type='number'
                                        />
                                        <TextField 
                                            id="sageInvoiceNumber"
                                            name="sageInvoiceNumber"
                                            label={`Sage Invoice Number ${invoiceNumberList.length > 0 ? '' : '*'}`}
                                            value={formData.sageInvoiceNumber}
                                            onChange={this.handleChange()}
                                            error={formErrors && formErrors['sageInvoiceNumber'] && true}
                                            helperText={formErrors && formErrors['sageInvoiceNumber']}
                                            margin="normal"
                                            disabled={invoiceNumberList.length > 0 && _.find(this.state.invoiceToSageList, {invoiceNumber:this.state.formData?.invoiceNumber})?.sageInvoiceNumber !== 0}
                                            fullWidth
                                            onInput = {(e) =>{
                                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,7)
                                            }}
                                            type='number'
                                        />
                                        <DatePicker
                                            type="date"
                                            id="dateOfExport"
                                            name="dateOfExport"
                                            label="Date of Export"
                                            value={formData.dateOfExport}
                                            errorText={formErrors && formErrors['dateOfExport']}
                                            onChange={this.handleDateChange('dateOfExport')}
                                            autoOk={true}
                                        />
                                        <TextField 
                                            name="orderNumber"
                                            label="Order Number"
                                            value={formData.orderNumber}
                                            onChange={this.handleChange()}
                                            error={formErrors && formErrors['orderNumber'] && true}
                                            helperText={formErrors && formErrors['orderNumber']}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <TextField 
                                            name="deliveryReference"
                                            label="Delivery Ref"
                                            value={formData.deliveryReference}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                            InputProps={{
                                                className: 'disabledText'
                                            }}
                                        />
                                        <TextField 
                                            name="accountReference"
                                            label="Account Reference"
                                            value={formData.accountReference}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                            InputProps={{
                                                className: 'disabledText'
                                            }}
                                        />
                                        <TextField 
                                            name="customsBroker"
                                            label="Customs Broker"
                                            value={formData.customsBroker}
                                            onChange={this.handleChange()}
                                            error={formErrors && formErrors['customsBroker'] && true}
                                            helperText={formErrors && formErrors['customsBroker']}
                                            margin="normal"
                                            fullWidth
                                        />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container item justify="flex-end">
                                    <Grid item xs={12}>
                                        <PartItems
                                            partRows={formData.partRows}
                                            handleRowDateChange={this.handleRowDateChange}
                                            handleRowChange={this.handleRowChange}
                                            formErrors={formErrors}
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={6}>
                                <PaddedPaper>
                                    <Typography variant="subtitle1">
                                        <b>Deliver To:</b>
                                    </Typography>
                                    <Textarea
                                        id="deliveryCustomerAddress"
                                        name="deliveryCustomerAddress"
                                        value={formData.deliveryCustomerAddress}
                                        onChange={this.handleChange()}
                                        error={formErrors && formErrors['deliveryCustomerAddress']}
                                    />
                                    <TextField 
                                        name="deliveryCustomerContact"
                                        label="Contact"
                                        value={formData.deliveryCustomerContact}
                                        onChange={this.handleChange()}
                                        error={formErrors && formErrors['deliveryCustomerContact'] && true}
                                        helperText={formErrors && formErrors['deliveryCustomerContact']}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField 
                                        name="deliveryCustomerTel"
                                        label="Tel"
                                        value={formData.deliveryCustomerTel}
                                        onChange={this.handleChange()}
                                        error={formErrors && formErrors['deliveryCustomerTel'] && true}
                                        helperText={formErrors && formErrors['deliveryCustomerTel']}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField 
                                        name="deliveryCustomerEmail"
                                        label="Email"
                                        value={formData.deliveryCustomerEmail}
                                        onChange={this.handleChange()}
                                        error={formErrors && formErrors['deliveryCustomerEmail'] && true}
                                        helperText={formErrors && formErrors['deliveryCustomerEmail']}
                                        margin="normal"
                                        fullWidth
                                    />
                                </PaddedPaper>
                                <br/>
                                <PaddedPaper>
                                    <Typography variant="subtitle1">
                                        <b>Declaration Statement:</b>
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        I/we hereby certify that the information on this invoice is true and correct and that the contents of this shipment are as stated above. 
                                    </Typography>
                                    <br />
                                    <Grid container item spacing={3}>
                                        <Grid item xs={3}>
                                            Date:
                                        </Grid>
                                        <Grid item xs={9}>
                                            {moment().format("DD/MM/YYYY")}
                                        </Grid>
                                        <Grid item xs={3}>
                                            Name:
                                        </Grid>
                                        <Grid item xs={9}>
                                            {formData.declarationName}
                                        </Grid>
                                        <Grid item xs={3}>
                                            Job Title:
                                        </Grid>
                                        <Grid item xs={9}>
                                            {formData.declarationJobTitle}
                                        </Grid>
                                        <Grid item xs={3}>
                                            Company:
                                        </Grid>
                                        <Grid item xs={9}>
                                            Clenaware Systems Limited
                                        </Grid>
                                        <Grid item xs={3}>
                                            Signature:
                                        </Grid>
                                        <Grid item xs={9}>
                                            <ImageWithError type="SIGNATURE" src={formData.declarationSignature} style={{maxHeight: 100}} />
                                            {formErrors && formErrors['declaration'] && 
                                                <Typography variant="small" component={"div"} style={{color: colors.red}}>
                                                    {formErrors && formErrors['declaration']}
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <PaddedPaper>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={5}></Grid>
                                        <Grid item xs={7}>
                                            <TextField 
                                                name="netValueOfGoods"
                                                label="Net Value of Goods"
                                                value={formData.netValueOfGoods}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">£</InputAdornment>
                                                    ),
                                                    className: 'disabledText'
                                                }}
                                            />
                                            <TextField 
                                                name="netFreightCost"
                                                label="Net Freight Cost"
                                                value={formData.netFreightCost}
                                                onChange={this.handleChange()}
                                                onBlur={this.handleChange(2)}
                                                error={formErrors && formErrors['netFreightCost'] && true}
                                                helperText={formErrors && formErrors['netFreightCost']}
                                                margin="normal"
                                                fullWidth
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">£</InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <TextField 
                                                name="netInsuranceCost"
                                                label="Net Insurance Cost"
                                                value={formData.netInsuranceCost}
                                                onChange={this.handleChange()}
                                                onBlur={this.handleChange(2)}
                                                error={formErrors && formErrors['netInsuranceCost'] && true}
                                                helperText={formErrors && formErrors['netInsuranceCost']}
                                                margin="normal"
                                                fullWidth
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">£</InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <TextField 
                                                name="subTotal"
                                                label="Sub Total"
                                                value={formData.subTotal}
                                                onChange={this.handleChange()}
                                                onBlur={this.handleChange(2)}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">£</InputAdornment>
                                                    ),
                                                    className: 'disabledText'
                                                }}
                                            />
                                            <TextField 
                                                name="totalVAT"
                                                label="Total VAT"
                                                value={formData.totalVAT}
                                                onChange={this.handleChange()}
                                                onBlur={this.handleChange(2)}
                                                margin="normal"
                                                error={formErrors && formErrors['totalVAT'] && true}
                                                helperText={formErrors && formErrors['totalVAT']}
                                                fullWidth
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">£</InputAdornment>
                                                    )
                                                }}
                                            />
                                            <TextField 
                                                name="totalGross"
                                                label="Total Gross"
                                                value={formData.totalGross}
                                                margin="normal"
                                                error={formErrors && formErrors['totalGross'] && true}
                                                helperText={formErrors && formErrors['totalGross']}
                                                fullWidth
                                                disabled
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">£</InputAdornment>
                                                    ),
                                                    className: 'disabledText'
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                                <br />
                                <PaddedPaper>
                                    <TextField 
                                        name="totalNumberOfPackages"
                                        label="Total Number Of Packages / Pallets"
                                        value={formData.totalNumberOfPackages}
                                        onChange={this.handleChange()}
                                        error={formErrors && formErrors['totalNumberOfPackages'] && true}
                                        helperText={formErrors && formErrors['totalNumberOfPackages']}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField 
                                        name="totalNetWeight"
                                        label="Total Gross Weight (Kgs)"
                                        value={formData.totalNetWeight}
                                        onChange={this.handleChange()}
                                        onBlur={this.handleChange(3)}
                                        error={formErrors && formErrors['totalNetWeight'] && true}
                                        helperText={formErrors && formErrors['totalNetWeight']}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <FormControl margin="normal" fullWidth>
                                        <AutoCompleteSelect
                                            options={incotermsList}
                                            label='Incoterms'
                                            value={formData.incoterms}
                                            onChange={this.handleSelectChange('incoterms')}
                                            error={formErrors && formErrors['incoterms'] && true}
                                            errorText={formErrors && formErrors['incoterms'] && formErrors['incoterms']}
                                        />
                                    </FormControl>
                                    <TextField 
                                        name="incotermDestination"
                                        label={<><span>Incoterm Destination </span><small>(Please refer to details above)</small></>}
                                        value={formData.incotermDestination}
                                        onChange={this.handleChange()}
                                        error={formErrors && formErrors['incotermDestination'] && true}
                                        helperText={formErrors && formErrors['incotermDestination']}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <RadioGroup
                                        name="freightType"
                                        onChange={this.handleChange()}
                                        row
                                    >
                                        <FormControlLabel
                                            value="Freight Prepaid"
                                            control={<Radio color="primary" />}
                                            label="Freight Prepaid"
                                            labelPlacement="end"
                                            checked={formData.freightType == "Freight Prepaid" ? true : false}
                                        />
                                        <FormControlLabel
                                            value="Collect"
                                            control={<Radio color="primary" />}
                                            label="Collect"
                                            labelPlacement="end"
                                            checked={formData.freightType == "Collect" ? true : false}
                                        />
                                    </RadioGroup>
                                    <br /><br />
                                    <div className='buttonRow'>
                                        <BackButton props={this.props} />
                                        <Button onClick={this.handlePreview}
                                                variant="contained"
                                                color="primary"
                                                disabled={!this.state.allowSubmit}>
                                            Preview
                                        </Button>
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary"
                                                disabled={!this.state.allowSubmit}>
                                            Submit
                                        </Button>
                                    </div>
                                </PaddedPaper>
                                
                            </Grid>
                        </Grid>
                        {/* CONFIRMATION DIALOG */}
                        <ConfirmationDialog 
                            open={this.state.confirmationOpen} 
                            success={this.handleConfirmationSuccess} 
                            close={this.handleConfirmationClose} 
                            title="Create Commercial Invoice?" 
                            message="Are you sure you want to create this commercial invoice?"
                        />
                        <SnackBar
                            variant="error"
                            anchorOriginVertical='bottom'
                            anchorOriginHorizontal='right'
                            open={this.state.snackbarOpen}
                            onClose={this.handleSnackbarClose}
                            message={<span>ATTENTION - some of the following fields are missing:<br />
                                        VAT Registration, EORI Number, Staff Job Title, Staff Signature </span>}
                        />
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, msg) => {
            dispatch(deploySnackBar(variant, msg))
        },
        deployDialog: (content, title, variant, size) => {
            dispatch(deployDialog(content, title, variant, size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(AddCommercialInvoice));