import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import API from 'API';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import MediaTable from 'Components/Common/DataTables/MediaTable';
import InfoIcon from 'Components/Common/Icons/InfoIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import Quotation from 'Components/Sales/Quotation/ViewQuotation/Quotation';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import _ from 'lodash';
import React from 'react';

const initialState = () => ({
    isLoading: true,
    repair: null,
    order: null,
    inspections: [],
    parts: [],
})

class OutcomeRepair extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState();
    }

    componentDidMount(){
        this.props.outcome.cro_outcome_id && this.getOrder();
        this.getInspections();
    }

    getInspections = () => {
        API.get('/parts/all', { params: { use: 'partSearch', withStock: true, withImage: true } }).then(res => {
            this.setState({ parts: res.data }, ()=>{
                let inspections = [];
                _.each(this.props.cr.collection.details, i => {
                    _.each(i.inspections, j => {
                        if (j.cri_outcome === 'Repair'){
                            inspections.push(j);
                        }
                    });
                });
                this.setState({ inspections }, this.getRepair);
            });
        });
    }

    getRepair = () => {
        API.get(`/customerReturns/repairOrders/${this.props.cr.outcomes_repair.repair_order.rog_id}`)
        .then(res => {
            this.setState({
                repair: res?.data,
                isLoading: false,
            })
        })
    }

    getOrder = () => {
        API.get(`/sales/orders/${this.props.outcome.cro_outcome_id}`)
        .then(result => {
            this.setState({
                order: result?.data,
            });
        })
    }

    render(){

        const { isLoading, repair } = this.state;

        if (isLoading){
            return <LoadingCircle/>;
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {this.props.outcome.cro_outcome_id ? 
                        <>
                            {this.props.outcome.cro_outcome_id > 1 &&
                                <Grid item xs={12} style={{marginBottom: '2em'}}>
                                    <CiDataTable
                                        rows={[this.props.outcome.order]}
                                        config={{
                                            key: 'order_id',
                                        }}
                                        columns={[
                                            {
                                                heading: 'Order Date',
                                                field: 'order_date',
                                                sizeToContent: true,
                                                alignment: 'center',
                                                fieldFormat: 'clenydate',
                                            },
                                            {
                                                heading: 'Reference',
                                                field: i => <>{i.order_reference }-{i.order_reference_amendment}</>,
                                            },
                                            {
                                                heading: 'Discount',
                                                field: i => i.order_type === 'With Discount',
                                                fieldFormat: 'boolean',
                                                sizeToContent: true,
                                                alignment: 'center'
                                            },
                                            {
                                                heading: 'Customer',
                                                field: i => 
                                                    <>
                                                        {i.order_cust_name}<br/>
                                                        {i.order_delivery_cust_address_name} <InfoIcon info={
                                                            <span style={{whiteSpace: 'pre-wrap'}}>
                                                                <b>Delivery Address</b><br/>
                                                                {i.order_delivery_cust_address}
                                                            </span>
                                                        }/>
                                                    </>,
                                            },
                                            {
                                                heading: 'Total',
                                                field: 'order_total_incl_vat',
                                                fieldFormat: 'currency',
                                                sizeToContent: true,
                                                alignment: 'right',
                                                info: rowData => 
                                                    <div style={{display: 'flex', width: 200}}>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">Sub Total</Typography>
                                                            <Typography variant="body2">VAT ({parseInt(this.props.cr.cr_vat_rate * 100).toFixed(2)}%)</Typography>
                                                            <Typography variant="body2">Total</Typography>
                                                        </div>
                                                        <div style={{width: '50%', textAlign:'right'}}>
                                                            <Typography variant="body2">£{rowData.order_sub_total}</Typography>
                                                            <Typography variant="body2">£{rowData.order_vat}</Typography>
                                                            <Typography variant="body2">£{rowData.order_total_incl_vat}</Typography>
                                                        </div>
                                                    </div>
                                            },
                                            {
                                                actions: i => ([
                                                    {
                                                        name: 'View',
                                                        link: `/sales/order/view/${i.order_id}`,
                                                        icon: IconHelper.view
                                                    }
                                                ])
                                            }
                                        ]}
                                    />
                                </Grid>
                            }
                        </>
                    : 
                        <PaddedPaper>
                            <Typography variant='h6' style={{textAlign: 'center'}}>
                                Outcome Has Not Been Linked To A Order
                            </Typography>
                        </PaddedPaper>
                    }
                </Grid>
                {repair?.rog_quote_id &&
                    <Grid item xs={12}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                                Linked Quote
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Quotation cqId={ repair?.rog_quote_id } />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                }
                <Grid item xs={12}>
                    <CiDataTable
                        config={{
                            key: 'ro_id',
                            dropRow: {
                                droppable: true,
                                columns: [
                                    {
                                        colSpan: 5,
                                        field: i => {
                                            const inspection = _.find(this.state.inspections, {cri_id: i.ro_origin_id});
                                            
                                            let partsUsed = [];
                                            let servicesUsed = [];
                                            
                                            if (inspection.cri_parts){
                                                _.each(JSON.parse(inspection.cri_parts), i => {
                                                    let part = _.find(this.state.parts, {part_id: i.part});
                                                    if (part.stockable){
                                                        partsUsed.push({...part, qty: i.qty});
                                                    } else {
                                                        servicesUsed.push({...part, qty: i.qty});
                                                    }
                                                });
                                            }

                                            return (
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper
                                                                    style={{
                                                                        backgroundColor: i.ro_status === 'Write Off' ? colors.red : 
                                                                            ( i.ro_status === 'Complete' ? colors.green : colors.orange),
                                                                        paddingTop: 8,
                                                                        paddingBottom: 8,
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant='body1'
                                                                        style={{
                                                                            color: colors.white,
                                                                        }}
                                                                    >
                                                                        Status: {i.ro_status}
                                                                    </Typography>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {inspection &&
                                                                    <ExpansionPanel>
                                                                        <ExpansionPanelSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                        >
                                                                            Pre-Repair/Inspection    
                                                                        </ExpansionPanelSummary>
                                                                        <ExpansionPanelDetails>
                                                                            <Grid container spacing={1}>
                                                                                <Grid item xs={12}>
                                                                                    <Textarea
                                                                                        label='Condition'
                                                                                        value={`${inspection.cri_condition_grade} - ${inspection.cri_condition}`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Textarea
                                                                                        label='Notes'
                                                                                        value={inspection.cri_notes}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <MediaTable
                                                                                        media='file_path'
                                                                                        config={{
                                                                                            key: 'crif_id',
                                                                                            itemPerRow: 3,
                                                                                        }}
                                                                                        items={inspection.files}
                                                                                    />
                                                                                </Grid>
                                                                                { ( partsUsed.length > 0 || servicesUsed.length > 0 ) &&
                                                                                    <Grid item xs={12}>
                                                                                        {partsUsed.length > 0 &&
                                                                                            <ExpansionPanel >
                                                                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                                                    Recommended Parts
                                                                                                </ExpansionPanelSummary>
                                                                                                <ExpansionPanelDetails>
                                                                                                    <CiDataTable
                                                                                                        config={{
                                                                                                            key: 'part_id',
                                                                                                        }}
                                                                                                        columns={[
                                                                                                            {
                                                                                                                heading:       '',
                                                                                                                field:         'default_image.file_path',
                                                                                                                fieldFormat:   'image',
                                                                                                                sizeToContent: true,
                                                                                                            },
                                                                                                            {
                                                                                                                heading:      'Part Number',
                                                                                                                field:        'part_number',
                                                                                                                sizeToContent: true,
                                                                                                            },
                                                                                                            {
                                                                                                                heading:      'Part Description',
                                                                                                                field:        'part_description',
                                                                                                                truncate:     true,
                                                                                                            },
                                                                                                            {
                                                                                                                heading:      'Qty',
                                                                                                                field:        'qty',
                                                                                                                sizeToContent: true,
                                                                                                                fieldFormat: 'decimal:3'
                                                                                                            }
                                                                                                        ]}
                                                                                                        rows={partsUsed}
                                                                                                    />
                                                                                                </ExpansionPanelDetails>
                                                                                            </ExpansionPanel>
                                                                                        }   
                                                                                        {servicesUsed.length > 0 &&
                                                                                            <ExpansionPanel >
                                                                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                                                    Recommended Services
                                                                                                </ExpansionPanelSummary>
                                                                                                <ExpansionPanelDetails>
                                                                                                    <CiDataTable
                                                                                                        config={{
                                                                                                            key: 'part_id',
                                                                                                        }}
                                                                                                        columns={[
                                                                                                            {
                                                                                                                heading:       '',
                                                                                                                field:         'default_image.file_path',
                                                                                                                fieldFormat:   'image',
                                                                                                                sizeToContent: true,
                                                                                                            },
                                                                                                            {
                                                                                                                heading:      'Part Number',
                                                                                                                field:        'part_number',
                                                                                                                sizeToContent: true,
                                                                                                            },
                                                                                                            {
                                                                                                                heading:      'Part Description',
                                                                                                                field:        'part_description',
                                                                                                                truncate:     true,
                                                                                                            },
                                                                                                            {
                                                                                                                heading:      'Qty',
                                                                                                                field:        'qty',
                                                                                                                sizeToContent: true,
                                                                                                                fieldFormat: 'decimal:3'
                                                                                                            }
                                                                                                        ]}
                                                                                                        rows={servicesUsed}
                                                                                                    />
                                                                                                </ExpansionPanelDetails>
                                                                                            </ExpansionPanel>
                                                                                        } 
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>
                                                                        </ExpansionPanelDetails>
                                                                    </ExpansionPanel>
                                                                }
                                                                {
                                                                    (i.parts.length > 0 ||_.filter(i.notes, {ron_type : "Note"}).length > 0)  &&
                                                                        <ExpansionPanel>
                                                                            <ExpansionPanelSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                            >
                                                                                Repair
                                                                            </ExpansionPanelSummary>
                                                                            <ExpansionPanelDetails>
                                                                                <Grid container spacing={1}>
                                                                                    {i.parts.length > 0 &&
                                                                                        <Grid item xs={12}>
                                                                                            <CiDataTable
                                                                                                config={{
                                                                                                    key: 'rop_id'
                                                                                                }}
                                                                                                columns={[
                                                                                                    {
                                                                                                        heading: '',
                                                                                                        field: 'part.default_image.thumbnail_file_path',
                                                                                                        fieldFormat: 'image',
                                                                                                        sizeToContent: true,
                                                                                                    },
                                                                                                    {
                                                                                                        heading: 'Part',
                                                                                                        field: i =>
                                                                                                            <Grid container >
                                                                                                                <Grid item xs={12}>
                                                                                                                    <Typography variant='body1'>{i.part.part_number}</Typography>
                                                                                                                </Grid>
                                                                                                                <Grid item xs={12}>
                                                                                                                    <Typography variant='body2'>{i.part.part_description}</Typography>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                    },
                                                                                                    {
                                                                                                        heading: 'Quantity',
                                                                                                        field: 'rop_qty',
                                                                                                        sizeToContent: true,
                                                                                                        fieldFormat: 'decimal:2',
                                                                                                    }
                                                                                                ]}
                                                                                                rows={i.parts}
                                                                                            />
                                                                                        </Grid>
                                                                                    }
                                                                                    {_.filter(i.notes, {ron_type : "Note"}).length > 0 &&
                                                                                        <Grid item xs={12}>
                                                                                            <ExpansionPanel>
                                                                                                <ExpansionPanelSummary
                                                                                                    expandIcon={<ExpandMoreIcon />}
                                                                                                >
                                                                                                    Notes
                                                                                                </ExpansionPanelSummary>
                                                                                                <ExpansionPanelDetails>
                                                                                                    <Grid container spacing={1}>
                                                                                                        {_.map(_.filter(i.notes, {ron_type : "Note"}), j => 
                                                                                                            <Grid item xs={12} key={j.ron_id}
                                                                                                                style={{
                                                                                                                    borderBottom: `1px solid ${colors.disabled}`,
                                                                                                                }}
                                                                                                            >
                                                                                                                <Grid container>
                                                                                                                    <Grid item xs={12}>
                                                                                                                        <Typography variant='caption'>{j.ron_staff} - {clenyDate(j.ron_datetime)}</Typography>
                                                                                                                    </Grid>
                                                                                                                    <Grid item xs={12}>
                                                                                                                        <Typography variant='body2' style={{whiteSpace: 'pre-wrap'}}>{j.ron_note}</Typography>
                                                                                                                    </Grid>
                                                                                                                    {j.files.length > 0 &&
                                                                                                                        <Grid item xs={12} style={{marginBottom: '0.5em'}}>
                                                                                                                            <MediaTable
                                                                                                                                config={{ 
                                                                                                                                    itemPerRow: 3,
                                                                                                                                    mobileItemPerRow: 3,
                                                                                                                                }}
                                                                                                                                media='file_path'
                                                                                                                                items={j.files}
                                                                                                                            />
                                                                                                                        </Grid>
                                                                                                                    }
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        )}
                                                                                                    </Grid>
                                                                                                </ExpansionPanelDetails>
                                                                                            </ExpansionPanel>
                                                                                        </Grid>
                                                                                    }
                                                                                </Grid>
                                                                            </ExpansionPanelDetails>
                                                                        </ExpansionPanel>
                                                                }
                                                                {/* {i.ro_status === 'Complete' &&
                                                                        <ExpansionPanel>
                                                                            <ExpansionPanelSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                            >
                                                                                Post-Repair/Inspection
                                                                            </ExpansionPanelSummary>
                                                                            <ExpansionPanelDetails>
                                                                                <Grid container spacing={1}>
                                                                                    <Grid item xs={12}>
                                                                                        <Textarea
                                                                                            label='Notes'
                                                                                            value={_.find(i.notes, {ron_type: "Post Repair"}).ron_note}
                                                                                        />
                                                                                    </Grid>
                                                                                    {_.find(i.notes, {ron_type: "Post Repair"}).files.length > 0 &&
                                                                                        <Grid item xs={12}>
                                                                                            <MediaTable
                                                                                                media='file_path'
                                                                                                config={{
                                                                                                    key: 'ronf_id',
                                                                                                    itemPerRow: 3,
                                                                                                }}
                                                                                                items={_.find(i.notes, {ron_type: "Post Repair"}).files}
                                                                                            />
                                                                                        </Grid>
                                                                                    }
                                                                                </Grid>
                                                                            </ExpansionPanelDetails>
                                                                        </ExpansionPanel>
                                                                } */}
                                                                {i.ro_status === 'Write Off' &&
                                                                    <ExpansionPanel>
                                                                        <ExpansionPanelSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                        >
                                                                            Write Off  
                                                                        </ExpansionPanelSummary>
                                                                        <ExpansionPanelDetails>
                                                                            <Grid container spacing={1}>
                                                                                <Grid item xs={12}>
                                                                                    <Textarea
                                                                                        label='Reason'
                                                                                        value={i.write_off.rowo_reason}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Textarea
                                                                                        label='Notes'
                                                                                        value={i.write_off.rowo_notes}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    {i.write_off.files.length > 0 &&
                                                                                        <MediaTable
                                                                                            media='file_url'
                                                                                            config={{
                                                                                                key: 'rowof_id',
                                                                                                itemPerRow: 3,
                                                                                            }}
                                                                                            items={i.write_off.files}
                                                                                        />
                                                                                    }
                                                                                </Grid>
                                                                                {i.write_off.parts_used.length > 0 &&
                                                                                    <Grid item xs={12}>
                                                                                        {
                                                                                            _.filter(i.write_off.parts_used, j => j.part.stockable).length > 0 &&
                                                                                                <ExpansionPanel>
                                                                                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                                                        Parts Used
                                                                                                    </ExpansionPanelSummary>
                                                                                                    <ExpansionPanelDetails>
                                                                                                        <CiDataTable
                                                                                                            config={{
                                                                                                                key: 'rowopu_id'
                                                                                                            }}
                                                                                                            columns={[
                                                                                                                {
                                                                                                                    heading: '',
                                                                                                                    field: 'part.default_image.thumbnail_file_path',
                                                                                                                    fieldFormat: 'image',
                                                                                                                    sizeToContent: true,
                                                                                                                },
                                                                                                                {
                                                                                                                    heading: 'Part',
                                                                                                                    field: i =>
                                                                                                                        <Grid container >
                                                                                                                            <Grid item xs={12}>
                                                                                                                                <Typography variant='body1'>{i.part.part_number}</Typography>
                                                                                                                            </Grid>
                                                                                                                            <Grid item xs={12}>
                                                                                                                                <Typography variant='body2'>{i.part.part_description}</Typography>
                                                                                                                            </Grid>
                                                                                                                        </Grid>
                                                                                                                },
                                                                                                                {
                                                                                                                    heading: 'Quantity',
                                                                                                                    field: 'rowopu_qty',
                                                                                                                    sizeToContent: true,
                                                                                                                    fieldFormat: 'decimal:2',
                                                                                                                }
                                                                                                            ]}
                                                                                                            rows={_.filter(i.write_off.parts_used, j => j.part.stockable)}
                                                                                                        />
                                                                                                    </ExpansionPanelDetails>
                                                                                                </ExpansionPanel>
                                                                                        }
                                                                                        {
                                                                                            _.filter(i.write_off.parts_used, j => !j.part.stockable).length > 0 &&
                                                                                                <ExpansionPanel>
                                                                                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                                                        Services Used
                                                                                                    </ExpansionPanelSummary>
                                                                                                    <ExpansionPanelDetails>
                                                                                                        <CiDataTable
                                                                                                            config={{
                                                                                                                key: 'rowopu_id'
                                                                                                            }}
                                                                                                            columns={[
                                                                                                                {
                                                                                                                    heading: '',
                                                                                                                    field: 'part.default_image.thumbnail_file_path',
                                                                                                                    fieldFormat: 'image',
                                                                                                                    sizeToContent: true,
                                                                                                                },
                                                                                                                {
                                                                                                                    heading: 'Part',
                                                                                                                    field: i =>
                                                                                                                        <Grid container >
                                                                                                                            <Grid item xs={12}>
                                                                                                                                <Typography variant='body1'>{i.part.part_number}</Typography>
                                                                                                                            </Grid>
                                                                                                                            <Grid item xs={12}>
                                                                                                                                <Typography variant='body2'>{i.part.part_description}</Typography>
                                                                                                                            </Grid>
                                                                                                                        </Grid>
                                                                                                                },
                                                                                                                {
                                                                                                                    heading: 'Quantity',
                                                                                                                    field: 'rowopu_qty',
                                                                                                                    sizeToContent: true,
                                                                                                                    fieldFormat: 'decimal:2',
                                                                                                                }
                                                                                                            ]}
                                                                                                            rows={_.filter(i.write_off.parts_used, j => !j.part.stockable)}
                                                                                                        />
                                                                                                    </ExpansionPanelDetails>
                                                                                                </ExpansionPanel>
                                                                                        }
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>
                                                                        </ExpansionPanelDetails>
                                                                    </ExpansionPanel>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid item xs={4}>
                                                        <PaddedPaper>
                                                            <VerticalTimeline layout="1-column">
                                                                {_.map(i.acl, (log, key) => (
                                                                    <VerticalTimelineElement
                                                                        key={key}
                                                                        className="vertical-timeline-element--work"
                                                                        date={clenyDate(log.roa_datetime)}
                                                                        iconStyle={{ backgroundColor: (
                                                                            ( log.roa_type === 'Created' || log.roa_type === 'Completed' ) ?
                                                                                colors.primary : 
                                                                                (
                                                                                    log.roa_type === 'Approved' ?
                                                                                        colors.green :
                                                                                        (
                                                                                            log.roa_type === 'Written Off' ?
                                                                                            colors.red :
                                                                                            colors.red
                                                                                        )
                                                                                )
                                                                        ), color: "white" }}
                                                                        icon={<FASolidIcon icon={(
                                                                            log.roa_type === 'Created' ?
                                                                                IconHelper.plus : 
                                                                                (
                                                                                    ( log.roa_type === 'Approved' || log.roa_type === 'Completed' ) ?
                                                                                        IconHelper.true :
                                                                                        (
                                                                                            log.roa_type === 'Written Off' ?
                                                                                            IconHelper.minus :
                                                                                            IconHelper.edit
                                                                                        )
                                                                                )
                                                                        )} style={{ color: "white" }} />}
                                                                    >
                                                                        <Grid container spacing={1}>
                                                                            <Grid item xs={12}>
                                                                                <Typography
                                                                                    variant='body2'
                                                                                    style={{marginTop: 0}}
                                                                                >{log.roa_staff}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography
                                                                                    variant='body2'
                                                                                    style={{marginTop: 0}}
                                                                                >{log.roa_type}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </VerticalTimelineElement>
                                                                ))}
                                                            </VerticalTimeline>
                                                        </PaddedPaper>
                                                    </Grid> */}
                                                </Grid>
                                            )
                                        }
                                    }
                                ]
                            }
                        }}
                        columns={[
                            {
                                heading: '',
                                field: 'part.default_image.thumbnail_file_path',
                                sizeToContent: true,
                                fieldFormat: 'image',
                                alignment: 'center',
                            },
                            {
                                heading: 'Part',
                                field: i => 
                                    <>
                                        <Typography variant='body1'>{i.part.part_number}</Typography>
                                        <Typography variant='body2'>{i.part.part_description}</Typography>
                                    </>,
                            },
                            {
                                heading: 'Serial Number',
                                field: i => (i.ro_sn) !== '[]' && (i.ro_sn),
                                sizeToContent: true,
                            },
                            {
                                heading: 'Warranty Void Label',
                                field: i => (i.ro_wvl) !== '[]' && (i.ro_wvl),
                                sizeToContent: true,
                            },
                            {   
                                heading: 'reference',
                                field: i => i.ro_reference,
                            },
                            {
                                heading: 'Status',
                                field: 'ro_status',
                                sizeToContent: true,
                            }
                        ]}
                        rows={repair.repair_orders}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default OutcomeRepair;