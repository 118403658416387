import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import DatePicker from '../../Common/DatePickers/DatePicker';
import moment from 'moment';
import API from '../../../API';
import _ from 'lodash';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';
import {colors} from 'Helpers/ColourHelper';
const initialState = {
    formData: {
        partId: '',
        designFile:  '',
        pdfDrawing:  '',
        poi: moment().toString(),
        affectedFiles: '',
        is3DPrinted: '',
        numberOf3DParts: '',
        printed3DFiles: []
    },
    showDesignDocument: true,
    showPdfDocument: true,
    showFileUpload: false,
    partsList: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    // File Confirmation Dialog 
    fileConfOpen: false,
    fileConIdx: '',
    fileConfType: '',
    fileConfFilename: '',
    isLoading: false
}

class AddPartFiles extends Component {
    constructor(props) {
        super(props);
    
        this.state = initialState;
    }

    componentDidMount(){
        this.getOutstandingDCO();
    }

    getOutstandingDCO = () => {
        API.get(`/researchDevelopment/changeOrders/outstanding`)
        .then((result) => {
            let partsList = _.map(_.filter(result.data,i=> !parseInt(i.part_locked)), (el) => {
                return _.assign({
                    value: el.part_id,
                    label: el.part_number + ' - ' + el.part_description
                });
            });
            this.setState({
                partsList: partsList
            });
        });
    }

    getPartDetails = (partId) => {
        if(partId){
            API.get(`/researchDevelopment/changeOrders/part/${partId}`)
            .then((result) => {
                let version = Math.max(result.data.dco_details_design_issue, result.data.dco_details_pdf_issue);
                let staffInitials = this.props.loggedInStaff !== undefined ? this.props.loggedInStaff.firstName.charAt(0) + this.props.loggedInStaff.lastName.charAt(0) : '';
                let filenameStart = (`${moment().format('DDMMYY')}_v${version}_${result.data.part_number}_`).toLowerCase().replace('-', '_');
                let filenameEnd = (`_${result.data.part_description}_${staffInitials}`).toLowerCase().replace('-', '_');

                var printed3DFiles = [];
                for (let i = 1; i <= parseInt(result.data.dco_details_number_of_3D_parts); i++) {
                    let partNo = i.toString().padStart(2, '0');
                    printed3DFiles.push({
                        stlFilename: filenameStart + partNo + filenameEnd,
                        singleFilename: filenameStart + partNo + filenameEnd,
                        productionFilename: filenameStart + partNo + filenameEnd,
                        stlFilenameConfirmed: false,
                        singleFilenameConfirmed: false,
                        productionFilenameConfirmed: false
                    });
                }

                this.setState({
                    formData: {
                        ...this.state.formData,
                        poi: moment(new Date(result.data.dco_poi)).toString(),
                        affectedFiles: result.data.dco_details_affected_files,
                        is3DPrinted: result.data.dco_details_part_is_3D_printed,
                        numberOf3DParts: result.data.dco_details_number_of_3D_parts,
                        printed3DFiles: printed3DFiles
                    },
                    showFileUpload: true
                });
                
                switch(result.data.dco_details_affected_files){
                    case 'Both':
                        this.setState({showDesignDocument: true, showPdfDocument: true});
                    break;
                    case 'PDF Only':
                        this.setState({showDesignDocument: false, showPdfDocument: true});
                    break;
                    case 'Design Only':
                        this.setState({showDesignDocument: true, showPdfDocument: false});
                    break;
                    default:
                    break;
                }
            });
        }
        else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    poi: moment().toString(),
                    affectedFiles: ''
                },
                showFileUpload: false,
                showDesignDocument: true, 
                showPdfDocument: true
            });
        }
    }

    handleDateChange = date => {
        this.setState({
            formData: {
                ...this.state.formData,
                poi: date
            }
        });
    };

    handleChange = (e) => {
        const name = e.target.name;
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        }, 
        () => {
            if(name === 'fileUploads'){
                switch(this.state.formData.fileUploadsDisplay){
                    case 'both':
                        this.setState({showDesignDocument: true, showPdfDocument: true});
                    break;
                    case 'pdf_only':
                        this.setState({showDesignDocument: false, showPdfDocument: true});
                    break;
                    case 'original_only':
                        this.setState({showDesignDocument: true, showPdfDocument: false});
                    break;
                    default:
                    break;
                }
            }

        });
    }
    handle3DChange = (idx, fieldname) => e => {
        let files =  [...this.state.formData.printed3DFiles];

        files[idx] = {
            ...files[idx],
            [fieldname]: e.target.value
        };

        this.setState({
            formData: {
                ...this.state.formData,
                printed3DFiles: files 
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            let newFormData = new FormData();
            Object.keys(this.state.formData).forEach(key => {
                if(key === 'poi'){
                    newFormData.append(key, moment(new Date(this.state.formData[key])).format('DD/MM/YYYY'))
                }
                else if(key === 'printed3DFiles'){
                    Array.prototype.forEach.call(this.state.formData.printed3DFiles, (_filesArr, partIdx) => {
                        for (let fileKey in _filesArr) {
                        newFormData.append('printed3DFiles['+partIdx+']['+fileKey+']', _filesArr[fileKey])
                        }
                    });
                }
                else {
                    newFormData.append(key, this.state.formData[key])
                }
            });

            API.post(`/researchDevelopment/changeOrders/part/${this.state.formData.partId}/files`, newFormData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        ...initialState,
                        snackbarOpen: true
                    });
                    this.getOutstandingDCO();
                }
                this.props.scrollToTop();
            });
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    // File Confirmation Dialog
    handleFileConfClose = () => {
        this.setState({ 
            fileConfOpen: false 
        });
    };
    handleFileConfChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleFileConfSuccess = () => {
        let files =  [...this.state.formData.printed3DFiles];

        files[this.state.fileConIdx] = {
            ...files[this.state.fileConIdx],
            [this.state.fileConfType]: this.state.fileConfFilename,
            [`${this.state.fileConfType}Confirmed`]: true
        };

        this.setState({ 
            fileConfOpen: false,
            formData: {
                ...this.state.formData,
                printed3DFiles: files 
            }
        });
    }


    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: res && res.value
            }
        }, 
        () => {
            if(name === 'partId'){
                this.getPartDetails(res && res.value);
            }
        });
    };

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    handle3DFileChange = (idx, filename) => (drop, name, event) => {
        let files =  [...this.state.formData.printed3DFiles];

        files[idx] = {
            ...files[idx],
            [name]: drop === true ? event.dataTransfer.files[0] : event.target.files[0]
        };

        this.setState({
            formData: {
                ...this.state.formData,
                printed3DFiles: files
            },
            fileConfOpen: true,
            fileConIdx: idx, 
            fileConfType: filename,
            fileConfFilename: files[idx][filename],
        });
    }
    
    clearPdfDocument = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                pdfDrawing: initialState.formData.pdfDrawing
            }
        });
    };

    clearOriginalDocument = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                designFile: initialState.formData.designFile
            }
        });
    };

    clear3DFile = (idx, fieldname) => event => {
        let files =  [...this.state.formData.printed3DFiles];

        files[idx] = {
            ...files[idx],
            [fieldname]: null
        };
        this.setState({
            formData: {
                ...this.state.formData,
                printed3DFiles: files 
            }
        });
    };

    displayFileInputs(numberOfParts) {
        let table = []
        for (let i = 0; i < parseInt(numberOfParts); i++) {
            table.push(
                <React.Fragment key={i}>
                    <Typography variant="h6">
                        Part {i+1}
                    </Typography>
                    <Grid container item spacing={3} alignItems='flex-end'>
                        <Grid item xs={12} lg={6}>
                            <DragFileInput
                                id={`stlFile${i}`}
                                name="stlFile"
                                label="STL File (.stl) *"
                                file={(this.state.formData.printed3DFiles[i] && this.state.formData.printed3DFiles[i].stlFile) || ''}
                                errorText={this.state.formErrors && this.state.formErrors['printed3DFiles|stlFile|'+i]}
                                onChange={this.handle3DFileChange(i, 'stlFilename')}
                                cancelOnClick={this.clear3DFile(i, 'stlFile')}
                                emptyText='No file selected'
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    name="stlFilename"
                                    value={(this.state.formData.printed3DFiles[i] && this.state.formData.printed3DFiles[i].stlFilename) || ''}
                                    label="STL Filename *"
                                    error={this.state.formErrors && this.state.formErrors['printed3DFiles|stlFilename|'+i] && true}
                                    helperText={this.state.formErrors && this.state.formErrors['printed3DFiles|stlFilename|'+i]}
                                    onChange={this.handle3DChange(i, 'stlFilename')}
                                    fullWidth
                                    InputProps={{
                                        style: ({color: this.state.formData.printed3DFiles[i].stlFilenameConfirmed ? colors.green : colors.red}),
                                        endAdornment: (
                                        <InputAdornment  position="end">
                                            .stl
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3} alignItems='flex-end'>
                        <Grid item xs={12} lg={6}>
                            <DragFileInput
                                id={`singleFile${i}`}
                                name="singleFile"
                                label="Single Part File (.mfp) *"
                                file={(this.state.formData.printed3DFiles[i] && this.state.formData.printed3DFiles[i].singleFile) || ''}
                                errorText={this.state.formErrors && this.state.formErrors['printed3DFiles|singleFile|'+i]}
                                onChange={this.handle3DFileChange(i, 'singleFilename')}
                                cancelOnClick={this.clear3DFile(i, 'singleFile')}
                                emptyText='No file selected'
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    name="singleFilename"
                                    value={(this.state.formData.printed3DFiles[i] && this.state.formData.printed3DFiles[i].singleFilename) || ''}
                                    label="Single Part Filename *"
                                    error={this.state.formErrors && this.state.formErrors['printed3DFiles|singleFilename|'+i] && true}
                                    helperText={this.state.formErrors && this.state.formErrors['printed3DFiles|singleFilename|'+i]}
                                    onChange={this.handle3DChange(i, 'singleFilename')}
                                    fullWidth
                                    InputProps={{
                                        style: ({color: this.state.formData.printed3DFiles[i].singleFilenameConfirmed ? colors.green : colors.red}),
                                        endAdornment: (
                                        <InputAdornment  position="end">
                                            .mpf
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3} alignItems='flex-end'>
                        <Grid item xs={12} lg={6}>
                            <DragFileInput
                                id={`productionFile${i}`}
                                name="productionFile"
                                label="Production File (.mpf) *"
                                file={(this.state.formData.printed3DFiles[i] && this.state.formData.printed3DFiles[i].productionFile) || ''}
                                errorText={this.state.formErrors && this.state.formErrors['printed3DFiles|productionFile|'+i]}
                                onChange={this.handle3DFileChange(i, 'productionFilename')}
                                cancelOnClick={this.clear3DFile(i, 'productionFile')}
                                emptyText='No file selected'
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    name="productionFilename"
                                    value={(this.state.formData.printed3DFiles[i] && this.state.formData.printed3DFiles[i].productionFilename) || ''}
                                    label="Production Filename *"
                                    error={this.state.formErrors && this.state.formErrors['printed3DFiles|productionFilename|'+i] && true}
                                    helperText={this.state.formErrors && this.state.formErrors['printed3DFiles|productionFilename|'+i]}
                                    onChange={this.handle3DChange(i, 'productionFilename')}
                                    fullWidth
                                    InputProps={{
                                        style: ({color: this.state.formData.printed3DFiles[i].productionFilenameConfirmed ? colors.green : colors.red}),
                                        endAdornment: (
                                        <InputAdornment  position="end">
                                            .mpf
                                        </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Dialog 
                        open={this.state.fileConfOpen} 
                        onClose={this.handleFileConfClose}
                        disableBackdropClick={true}
                        disableEscapeKeyDown
                        scroll="body"
                    >
                        <DialogTitle>Confirm Filename</DialogTitle>
                        <DialogContent>
                            <form noValidate autoComplete="off">
                                <DialogContentText>
                                    Please confirm the filename for the file you have uploaded.
                                </DialogContentText>
                                <TextField
                                    name="fileConfFilename"
                                    value={(this.state.fileConfFilename) || ''}
                                    label="Filename"
                                    onChange={this.handleFileConfChange}
                                    fullWidth
                                />
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleFileConfSuccess} color="primary" variant="contained">
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>

                </React.Fragment>
            )
        }
        return table
    }
       
    render() {
        const { formErrors } = this.state;
        
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Add Part Files
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Select Part
                            </Typography>
                            {formErrors && formErrors.generic && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red}}>
                                        {formErrors.generic}
                                    </Typography>
                                </React.Fragment>
                            )}
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={this.state.partsList} 
                                        label='Part *'
                                        value={this.state.formData.partId}
                                        onChange={this.handleSelectChange('partId')}
                                        error={formErrors && formErrors['partId'] && true}
                                        errorText={formErrors && formErrors['partId'] && formErrors['partId']}
                                    />
                                </FormControl>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                {this.state.formData.is3DPrinted && this.state.formData.is3DPrinted === 'Yes' &&
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                3D Printing Files
                            </Typography>
                            <Typography variant="subtitle2">
                                Filenames will be red in colour until you have uploaded a file and confirmed the filename
                            </Typography>
                            <br></br>
                            <form noValidate autoComplete="off">
                                {this.displayFileInputs(this.state.formData.numberOf3DParts)}
                            </form>
                            {formErrors && formErrors.uniqueFilenames && (
                                <React.Fragment>
                                    <Typography component={"div"} style={{color: colors.red, textAlign: 'right'}}>
                                        {formErrors.uniqueFilenames}
                                    </Typography>
                                </React.Fragment>
                            )}
                        </PaddedPaper>
                    </Grid>
                }
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <Typography variant="h6">
                                Part File Details
                            </Typography>
                            <form noValidate autoComplete="off">
                                <DatePicker
                                    type="date"
                                    id="poi"
                                    name="poi"
                                    label="Point of Introduction *"
                                    value={this.state.formData.poi}
                                    errorText={formErrors && formErrors['poi']}
                                    onChange={this.handleDateChange}
                                    disableFuture={true}
                                    disabled={true}
                                />
                                {this.state.showPdfDocument &&
                                    <DragFileInput
                                        id="pdfDrawingInput"
                                        name="pdfDrawing"
                                        label="PDF Drawing (.pdf) *"
                                        file={this.state.formData.pdfDrawing}
                                        errorText={formErrors && formErrors['pdfDrawing']}
                                        onChange={this.handleFileChange}
                                        cancelOnClick={this.clearPdfDocument}
                                        emptyText='No document selected'
                                        disabled={!this.state.showFileUpload}
                                    />
                                }
                                {this.state.showDesignDocument &&
                                    <DragFileInput
                                        id="designFileInput"
                                        name="designFile"
                                        label="Design File (.doc, .docx) *"
                                        file={this.state.formData.designFile}
                                        errorText={formErrors && formErrors['designFile']}
                                        onChange={this.handleFileChange}
                                        cancelOnClick={this.clearOriginalDocument}
                                        emptyText='No document selected'
                                        disabled={!this.state.showFileUpload}
                                    />
                                }
                                {formErrors && formErrors['part_locked'] &&
                                    <Grid container xs={12} style={{paddingTop:'1em'}}>
                                        <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>
                                            This part is locked, please try again later.
                                        </Grid>
                                    </Grid>
                                }
                                <div className='buttonRow' style={{justifyContent: 'space-between', alignItems:'center'}}>
                                    <Typography variant="subtitle2">
                                        <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                                    </Typography>
                                    <Button onClick={this.handleConfirmationOpen}
                                            variant="contained"
                                            color="primary"
                                            disabled={!this.state.showFileUpload || this.state.isLoading}>
                                        Add
                                    </Button>
                                </div>
                            </form>
                            <ConfirmationDialog 
                                open={this.state.confirmationOpen} 
                                success={this.handleConfirmationSuccess} 
                                close={this.handleConfirmationClose} 
                                title="Add these Part File(s)?" 
                                message="Are you sure you want to add this Part File(s)?"
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarOpen}
                                onClose={this.handleSnackbarClose}
                                message='You have successfully added these part file(s)'
                            />
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}

export default connect(mapStateToProps)(AddPartFiles);