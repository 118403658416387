import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import BigCalendar from 'react-big-calendar';
import API from '../../../API';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import './CalendarStyles.css';

moment.locale('ko', {
    week: {
        dow: 1,
        doy: 1,
    },
});
const localizer = BigCalendar.momentLocalizer(moment);

const initialState = {
    categories: {},
    activeCategories: {},
    topCategoryList: [],
    dateRange: {
        from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    },
    categoriesLoading: true
}

class ViewCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount(){
       this.getCategories(); 
    }
    eventStyleGetter = (event, start, end, select) => {
        return {
            style: {
                color: ((event.cancelled && '#999999') || 'white'),
                backgroundColor: ((event.cancelled && '#eeeeee') || event.colour),
                borderRadius: 0,
                fontSize: 12,
                marginBottom: 0,
                textDecoration: (event.cancelled && 'line-through')
            }
        }
    }
    getCategories = () => {
        this.setState({
            categoriesLoading: true
        }, () => {
            API.get('/calendar/categories/all', { params: { enabledOnly: true } }).then(result => {
                if(result.data) {
                    let categoryList = _.map(result.data, (cat) => {
                        return _.assign({
                            value: cat.category_id,
                            label: cat.category_name
                        });
                    });
                    let activeCategories = {};
                    _.map(result.data, (cat) => {
                        if(cat.children) {
                            _.map(cat.children, (child) => {
                            if(cat.children) {
                                activeCategories[child.category_id] = true
                            }
                            });
                        }
                    });
                    this.setState({
                        ...this.state,
                        topCategoryList: categoryList,
                        categories: result.data,
                        activeCategories: activeCategories,
                        categoriesLoading: false
                    },
                    () => {
                        this.props.setActiveCategories(this.state.activeCategories);
                    });
                } else {
                    this.setState({
                        categoriesLoading: false
                    });
                }
            })
        })
    };
    toggleParentCategory = (id) => {
        let parent = this.state.categories.find(x => x.category_id === id);

        let anyActive = false;
        _.map(parent.children, child => {
            if(this.state.activeCategories[child.category_id] === true)
                anyActive = true;
        });

        let childrenIds = {};
        _.map(parent.children, child => {
            childrenIds[child.category_id] = !anyActive;
        });

        this.setState({
            activeCategories: {
                ...this.state.activeCategories,
                ...childrenIds
            }
        },
        () => {
            this.props.setActiveCategories(this.state.activeCategories);
        });
    }
    toggleCategory = (id) => {
        this.setState({
            activeCategories: {
                ...this.state.activeCategories,
                [id]: !this.state.activeCategories[id]
            }
        },
        () => {
            this.props.setActiveCategories(this.state.activeCategories);
        });
    }
    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={9}>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} className='calendar-grid'>
                            <div className='calendar-wrapper'>
                                <BigCalendar  
                                    popup={true}
                                    localizer={localizer}
                                    startAccessor="start"
                                    endAccessor="end"
                                    events={this.props.events}
                                    onSelectEvent={this.props.onSelectEvent}
                                    eventPropGetter={this.eventStyleGetter}
                                    onRangeChange={this.props.handleDateRangeChange} 
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Clicking the Category & Sub-Category headings will show/hide the categories </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={3}>
                    {(this.state.categoriesLoading && (
                        <LoadingCircle padding='large' />
                    )) || (
                        <React.Fragment>
                            {_.map(this.state.categories, category => {
                                return (
                                    <React.Fragment key={category.category_id}>
                                    {category.children && category.children.length > 0 && (
                                        <div style={{marginBottom: 16}}>
                                        <Typography variant="h6" gutterBottom>
                                            <span style={{cursor: 'pointer'}} onClick={() => this.toggleParentCategory(category.category_id)}>{category.category_name}</span>
                                        </Typography>
                                        {_.map(category.children, childCategory => {
                                            return (
                                                <Chip
                                                key={childCategory.category_id}
                                                avatar={
                                                    <Avatar
                                                    style={{
                                                        width: 32, 
                                                        height: 32,
                                                        backgroundColor: (this.state.activeCategories[childCategory.category_id] && childCategory.category_colour) ||
                                                                            `rgba(${ parseInt(childCategory.category_colour.substring(1,3),16) }, ${ parseInt(childCategory.category_colour.substring(3,5),16) }, ${ parseInt(childCategory.category_colour.substring(5,7),16) }, 0.1)`
                                                    }}
                                                    />
                                                }
                                                label={childCategory.category_name}
                                                onClick={() => this.toggleCategory(childCategory.category_id)}
                                                style={{
                                                    cursor: 'pointer',
                                                    marginRight: 8,
                                                    marginBottom: 8,
                                                    color: (this.state.activeCategories[childCategory.category_id] && childCategory.category_colour) ||
                                                            `rgba(${ parseInt(childCategory.category_colour.substring(1,3),16) }, ${ parseInt(childCategory.category_colour.substring(3,5),16) }, ${ parseInt(childCategory.category_colour.substring(5,7),16) }, 0.4)`,
                                                    paddingRight: 5,
                                                    backgroundColor: (this.state.activeCategories[childCategory.category_id] && `rgba(${ parseInt(childCategory.category_colour.substring(1,3),16) }, ${ parseInt(childCategory.category_colour.substring(3,5),16) }, ${ parseInt(childCategory.category_colour.substring(5,7),16) }, 0.15)`) ||
                                                                        `rgba(${ parseInt(childCategory.category_colour.substring(1,3),16) }, ${ parseInt(childCategory.category_colour.substring(3,5),16) }, ${ parseInt(childCategory.category_colour.substring(5,7),16) }, 0.1)`
                                                }}
                                                />
                                            )
                                        })}
                                        </div>
                                    )}
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
        );
    }
}

export default ViewCalendar;