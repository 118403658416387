import React       from 'react';
import { connect } from 'react-redux';
import API         from 'API';
import _           from 'lodash';

import { Typography, Grid, FormControl, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import PaddedPaper             from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';

import { formatValidationErrors }           from 'Helpers/ErrorHelper';
import { pdfFromBase64, pdfDataFromBase64 } from 'Helpers/PDFHelper';

import { printA4, printLabel } from 'Actions/Print/Print';

const styles = theme => ({
    letterLlinks: {
        textDecoration: 'none',
        color: '#2E85C2',
        minWidth: '26px'
    },
});

const initialState = {
    formData: {
        status: '',
        part: [],
        size: '',
        quantity: '1'
    },
    formErrors: [],
    parts: [],
    partsWithCodes: [],
    partsList: [],
    partLetters: [],
    results: [],
}

class PrintAllStockLabels extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getParts();
    }


    getPartLetters = () => {
        API.get('/parts/numbering/partTypes/all')
        .then((result) => {
            let partLetters = _.map(result.data, el => {
                return el.part_type_code;
            });
            this.setState({
                ...this.state,
                partLetters
            });
        })
    }

    getParts = () => {
        API.get('/parts/all')
        .then((result) => {
            this.setState({
                parts: result.data
            }, 
            () => {
                this.getPartLetters();
            });
        });
    }

    getPartsByStatus = () => {
        let parts = this.state.parts.filter(val => {
            return this.state.formData.status.includes(val.part_status);
        });

        let partsWithCodes = [];
        _.map(parts, (el) => {
            if(typeof partsWithCodes[el.part_type.part_type_code] === 'undefined'){
                partsWithCodes[el.part_type.part_type_code] = [];
            }
            partsWithCodes[el.part_type.part_type_code].push(el.part_id);
        });

        
        let partsList = _.map(parts, (el) => {
            return _.assign({
                value: el.part_id,
                label: el.part_number + ' - ' + el.part_description
            });
        });
        this.setState({
            partsList: partsList,
            partsWithCodes: partsWithCodes
        });
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        });
    };

    handleChange = (event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value
            }
        });
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        }, 
        () => {
            if(fieldName === 'status'){
                this.getPartsByStatus();
            }
        });
    }

    reset = () => {
        this.setState({
            formData: initialState.formData
        });
    }

    download = () => {
        this.setState({
            formErrors: []
        }, 
        () => {
            API.post('/stock/printAllLabels', this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                    });
                }
                else {
                    pdfFromBase64(result.data.pdf, 'stock_label.pdf');
                }
            });
        });
    }

    print = () => {
        this.setState({
            formErrors: []
        }, 
        () => {
            API.post('/stock/printAllLabels', this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                    });
                }
                else {
                    if (this.state.formData.size === 'part_label') {
                        this.props.printLabel(pdfDataFromBase64(result.data.pdf),'stock_label.pdf')
                    } else {
                        this.props.printA4(pdfDataFromBase64(result.data.pdf),'stock_label.pdf')
                    }
                }
            });
        });
    }

    selectLetter = letter => () =>  {
        let partIds = _.map(this.state.partsWithCodes[letter], (id) => {
            return id;
        });
        this.setState({
            formData: {
                ...this.state.formData,
                part: partIds
            }
        });
    }

    selectAll = () => {
        let partIds = _.map(this.state.partsList, (el) => {
            return el.value;
        });
        this.setState({
            formData: {
                ...this.state.formData,
                part: partIds
            }
        });
    }

    deselectAll = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                part: []
            }
        });
    }

    render() {
        const { classes } = this.props;
        const { formData, formErrors, partLetters } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Print All Stock Labels
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        name="size"
                                        options={[
                                            {value: 'part_label', label: 'Part Label'},
                                            {value: 'a4',         label: 'A4'},
                                            {value: 'box_label',  label: 'Box Label'},
                                            {value: 'long_label', label: 'Long Label'}
                                        ]} 
                                        label='Size *'
                                        onChange={this.handleSelectChange('size')}
                                        value={this.state.formData.size}
                                        error={formErrors && formErrors['size'] && true}
                                        errorText={formErrors && formErrors['size']}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteMultiSelect
                                        name="status"
                                        options={[
                                            {value: 'Active', label: 'Active'},
                                            {value: 'Replaced', label: 'Replaced'},
                                            {value: 'Discontinued', label: 'Discontinued'}
                                        ]} 
                                        label='Part Status *'
                                        value={this.state.formData.status}
                                        onChange={this.handleMultiSelectChange('status')}
                                        error={formErrors && formErrors['status'] && true}
                                        errorText={formErrors && formErrors['status']}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteMultiSelect
                                        ignoreTouchMonitor
                                        name="part"
                                        options={this.state.partsList}
                                        label='Part(s) *'
                                        value={formData.part}
                                        onChange={this.handleMultiSelectChange('part')}
                                        error={formErrors && formErrors['part'] && true}
                                        errorText={formErrors && formErrors['part']}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        name="quantity"
                                        options={[
                                            {value: '1', label: '1'},
                                            {value: '2', label: '2'},
                                            {value: '3', label: '3'},
                                            {value: '4', label: '4'},
                                            {value: '5', label: '5'},
                                            {value: '6', label: '6'},
                                            {value: '7', label: '7'},
                                            {value: '8', label: '8'},
                                            {value: '9', label: '9'},
                                            {value: '10', label: '10'},
                                            {value: '11', label: '11'},
                                            {value: '12', label: '12'},
                                            {value: '13', label: '13'},
                                            {value: '14', label: '14'},
                                            {value: '15', label: '15'},
                                            {value: '16', label: '16'},
                                            {value: '17', label: '17'},
                                            {value: '18', label: '18'},
                                            {value: '19', label: '19'},
                                            {value: '20', label: '20'}
                                        ]} 
                                        label='Quantity *'
                                        onChange={this.handleSelectChange('quantity')}
                                        value={this.state.formData.quantity}
                                        error={formErrors && formErrors['quantity'] && true}
                                        errorText={formErrors && formErrors['quantity']}
                                    />
                                </FormControl>
                                <Typography>
                                    <Button className={classes.letterLlinks} onClick={this.selectAll}>
                                        Select All
                                    </Button>
                                    <Button className={classes.letterLlinks} onClick={this.deselectAll}>
                                        Deselect All
                                    </Button>
                                </Typography>
                                <Typography>
                                    {_.map(partLetters, letter => {
                                        return (
                                            <Button key={`btn-${letter}`} className={classes.letterLlinks} onClick={this.selectLetter(letter)}>
                                            {letter}
                                            </Button>
                                        );
                                    })}
                                </Typography>
                                <div className='buttonRow'>
                                    <Button onClick={this.print}
                                            variant="contained"
                                            color="primary">
                                        Print
                                    </Button>
                                    <Button onClick={this.download}
                                            variant="contained"
                                            color="primary">
                                        Download
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        printA4:    (data, file) => dispatch(printA4(data, file)),
        printLabel: (data, file) => dispatch(printLabel(data, file)),
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(PrintAllStockLabels));