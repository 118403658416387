import React, {Component} from 'react';
import API                from 'API';
import _                  from 'lodash';
import { Redirect }       from 'react-router-dom';
import { connect }        from 'react-redux';
import moment             from 'moment';

import { Typography, Grid, TextField, Button, FormControl, InputAdornment, Checkbox } from '@material-ui/core';
import MaterialLink from '@material-ui/core/Link';

import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import DataTable          from 'Components/Common/DataTables/CiDataTable';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AddressLookup      from 'Components/Common/AddressLookup/AddressLookup';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import AccessTimes        from 'Components/Customers/AccessTimes/AccessTimes';
import OpeningTimes       from 'Components/Customers/OpeningTimes/OpeningTimes';
import BackButton         from 'Components/Common/Buttons/BackButton';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import Textarea           from 'Components/Common/Inputs/Textarea';
import ImageWIthError     from 'Components/Common/ImageWithError/ImageWithError';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import {colors}                   from 'Helpers/ColourHelper';
import icons                      from 'Helpers/IconHelper';
import { getZonesFromPostcode }        from 'Helpers/AddressHelper';
import InfoIcon from 'Components/Common/Icons/InfoIcon';

const initialState = {
    formData: {
        name: '',
        addressOne: '',
        addressTwo:  '',
        addressThree:  '',
        town:  '',
        county: '',
        country:  243,
        postcode: '',
        phone: '',
        email: '',
        website: '',
        showAddress: true,
        showAddressLookup: false,
        contactId: '',
        accessTimesNotes: '',
        openingTimesNotes: '',
        accessTimes: [{
            day: '',
            startTime: '00:00',
            endTime: '00:00'
        }],
        openingTimes: [{
            day: '',
            startTime: '00:00',
            endTime: '00:00'
        }],
        addAccessTimes: false,
        addOpeningTimes: false,
        linkedSite:     null,
    },
    courierFormData: [],
    couriers: [],
    contacts: {},
    formErrors: [],
    countryList: [],
    confirmationOpen: false,
    snackbarOpen: false,
    deleteOpen: false,
    isLoading: true,
    redirect: false,
    access: {
        viewContact: false,
        updateContact: false
    },
    inactivate: false,
    address: {},
    siteAddreses: {},
    accountAddreses: {},
    addressId: 0,
}

class UpdateCustomerAddress extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.state.addressId = this.props.match.params.id;
    }
    componentDidMount(){
        this.checkAccess();
        this.getCustomerAddress(this.props.match.params.id);
        this.getCouriers();
    }

    getCouriers(){
        API.get('/couriers').then(res => {
            this.setState({ couriers: res.data.couriers });
        })
    }

    componentDidUpdate(){ 
        if (this.state.addressId != this.props.match.params.id){
            this.setState({addressId: this.props.match.params.id});
            this.getCustomerAddress(this.props.match.params.id);
        }
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/view-customer-contact'),
            API.get('/staff/my/access/check/update-customer-contact'), 
            API.get('/staff/my/access/check/view-customer-credit-limit'),
            API.get('/staff/my/access/check/update-customer-credit-limit')
        ])
        .then(([viewContact, updateContact, viewCreditLimit, updateCreditLimit]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    viewContact:        viewContact.data        ? viewContact.data.has_access           : false,
                    updateContact:      updateContact.data      ? updateContact.data.has_access         : false,
                    viewCreditLimit:    viewCreditLimit.data    ? viewCreditLimit.data.has_access       : false,
                    updateCreditLimit:  updateCreditLimit.data  ? updateCreditLimit.data.has_access     : false,
                }
            });
        });
    }

    getCustomer = () => {
        API.get(`/customers/${this.state.formData.customerId}`)
        .then(res => {
            this.setState({
                siteAddreses:    _.map(_.filter(res.data.site_addresses, i =>!i.linked_accounts), i => _.assign({value: i.address_id, label: i.address_name})),
                accountAddreses: _.map(res.data.account_addresses, i => _.assign({value: i.address_id, label: i.address_name})),
                isLoading: false
            })
        })
    }


    getCustomerAddress = (addressId) => {
        API.get(`/customers/addresses/${addressId}`)
        .then((result) => {
            if (result.data.errors) {
                this.setState({ redirect: true })
            } else if (result.data) {
                this.getCountries();
                let accessTimes = [];
                let openingTimes = [];
                _.map(result.data.access_times, el => {
                    accessTimes.push({
                        day: el.ca_access_day,
                        startTime: el.ca_access_start,
                        endTime: el.ca_access_end
                    });
                });
                _.map(result.data.opening_times, el => {
                    openingTimes.push({
                        day:       el.ca_opening_day,
                        startTime: el.ca_opening_start,
                        endTime:   el.ca_opening_end
                    });
                });
                this.setState({
                    formData: {
                        ...this.state.formData,
                        name:               result.data.address_name,
                        addressOne:         result.data.address_line_one,
                        addressTwo:         result.data.address_line_two,
                        addressThree:       result.data.address_line_three,
                        town:               result.data.address_town,
                        county:             result.data.county && result.data.county.county_name,
                        country:            result.data.address_country_id,
                        postcode:           result.data.address_postcode,
                        phone:              result.data.address_phone,
                        email:              result.data.address_email,
                        website:            result.data.address_website,
                        customerId:         result.data.address_customer_id,
                        active:             result.data.address_active,
                        creditLimitNet:     result.data.credit_limit?.ccl_net,
                        creditLimitGross:   result.data.credit_limit?.ccl_gross,
                        vat:                parseFloat(result.data.customer.vat_value.vat_value_percentage),
                        showAddress:        true,
                        accessTimes,
                        openingTimes,
                        accessTimesNotes:   result.data.address_access_times,
                        openingTimesNotes:  result.data.address_opening_times,
                    },
                    courierFormData: _.map(result.data.zones, i => _.assign({
                        courier: i.cz_cour_id,
                        zone:    i.cz_id
                    })),
                    contacts: result.data.active_contacts,
                    addressType: result.data.address_type,
                    address: result.data,
                    
                },() => {
                    if (this.state.addressType === 'Accounts'){
                        this.checkAddressInactivate()
                    } else {
                        this.getCustomer();
                    }
                });
            }
        });
    };
    checkAddressInactivate = () => {
        API.get(`/customers/${ this.state.formData.customerId }/accounts/addresses/${ this.props.match.params.id }/check`)
        .then(res => {
            this.setState({
                inactivate: res.data.allowInactivate ? false : res.data
            }, this.getCustomer)
        })
    }
    getCountries = () => {
        let countryList = [];
        API.get('/misc/countries/all')
        .then((countryRes) =>  {
            if(countryRes.data){
                countryList = _.map(countryRes.data, el => {
                    return _.assign({
                        value: el.country_id,
                        label: el.country_name,
                    });
                });
            }
            this.setState({
                countryList: countryList
            });
        });
    }
    handleAddAccessTimes = field => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: JSON.parse(e.target.value)
            }
        });
    }
    handleDayChange = field => (addressIdx, idx) => day => {
        let newDay = [...this.state.formData[field]];
        newDay[idx] = {
            ...newDay[idx],
            day: day && day.value
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: newDay
            }
        });
    }
    handleTimeChange = field => (fieldName, addressIdx, idx) => time => {
        let newTime = [...this.state.formData[field]];
        newTime[idx] = {
            ...newTime[idx],
            [fieldName]: time &&  moment(time).format('HH:mm')
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: newTime
            }
        });
    }
    handleAddAccessTimeRow = field => () => {
        let item = initialState.formData[field][0];
        this.setState({
            formData:{
                ...this.state.formData,
                [field]: [...this.state.formData[field], item]
            }
        });
    }
    handleRemoveRow = field => (addressIdx, idx) => () => {
        const accessTimes = [...this.state.formData[field]]
        accessTimes.splice(idx, 1)
        this.setState({
            formData:{
                ...this.state.formData,
                [field]: accessTimes
            }
        })
    }
    handleChange = (e) => {
        if (e.target.name === 'creditLimitNet') {            
            this.setState({
                formData: {
                    ...this.state.formData,
                    creditLimitNet:     e.target.value,
                    creditLimitGross:   parseFloat( parseInt(e.target.value) + ( e.target.value * ( this.state.formData.vat / 100 ) ) ).toFixed(0)
                }
            });
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [e.target.name]: e.target.value
                }
            });
        }   
    }
    handleSelectedAddress = e => {
        if(e && e.value){
            this.setState({
                formData: {
                    ...this.state.formData,
                    addressOne: e.value.line1,
                    addressTwo:  e.value.line2,
                    addressThree:  e.value.line3,
                    town:  e.value.town,
                    county: e.value.county,
                    postcode: e.value.postcode,
                    showAddress: true,
                    selectedAddress: e,
                }
            });
        }
        else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    selectedAddress: null
                }
            });
        }
    }
    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption?.value
            }
        }, 
        () => {
            if(fieldName == 'country'){
                this.setState({
                    formData: {
                        ...this.state.formData,
                        county: ''
                    }
                })
            }
        });
    }
    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    }
    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    }
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }
    submit = () => {
        API.post(`/customers/addresses/${this.props.match.params.id}`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    formData: {
                        ...this.state.formData,
                        showAddress: true,
                        showAddressLookup: false,
                    }
                });
            }
            else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.getCustomerAddress(this.props.match.params.id);
                this.getCountries();
            }
            this.props.scrollToTop();
        });
    }

    deleteContact() {
        API.post(`/customers/contacts/${this.state.formData.contactId}/delete`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState(initialState);
                this.setState({
                    snackbarOpen: true
                });
                this.getCustomerAddress(this.props.match.params.id);
                this.getCountries();
            }
        });
    }
    handleDeleteOpen = (rowData) => {
        this.setState({
            formData:{
                ...this.state.formData,
                contactId: rowData.contact_id
            },
            deleteOpen: true,
        });
    }
    handleDeleteClose = () => {
        this.setState({ 
            deleteOpen: false 
        });
    }
    handleDeleteSuccess = () => {
        this.setState({ 
            deleteOpen: false 
        });
        this.deleteContact();
    }

    handleManuallyEnterAddress = e => {
        e.preventDefault();
        
        this.setState({
            formData: {
                ...this.state.formData,
                showAddress:        this.state.formData.showAddress && this.state.formData.showAddressLookup ? true : !this.state.formData.showAddress,
                showAddressLookup:  this.state.formData.showAddress && this.state.formData.showAddressLookup ? false : !this.state.formData.showAddressLookup,
                addressOne:         this.state.formData.showAddress ? '' : this.state.formData.addressOne,
                addressTwo:         this.state.formData.showAddress ? '' : this.state.formData.addressTwo,
                addressThree:       this.state.formData.showAddress ? '' : this.state.formData.addressThree,
                town:               this.state.formData.showAddress ? '' : this.state.formData.town,
                county:             this.state.formData.showAddress ? '' : this.state.formData.county,
                postcode:           this.state.formData.showAddress ? '' : this.state.formData.postcode
            }
        });
    }

    handleSiteActivity = e => {
        this.props.deployConfirmation(`Are you sure you want to ${this.state.formData.active ? 'deactivate' : 'activate'} this site?`, `${this.state.formData.active ? 'Deactivate' : 'Activate'} Site?`, this.handleChangeSiteActivity)
    }

    handleChangeSiteActivity = () => {
        API.post(`/customers/addresses/${this.props.match.params.id}/changeActivity`)
        .then(res => {if (res.data) this.getCustomerAddress(this.props.match.params.id);});
    }

    unlinkSite = id => { 
        API.post(`/customers/${this.state.formData.customerId}/addresses/${id}/links/unlink`)
        .then(res => {if (res.data) this.getCustomerAddress(this.props.match.params.id);});
    }

    linkSite   = () => { 
        const accountId = (this.state.addressType === 'Accounts') ? this.state.addressId :  this.state.formData.linkedSite;
        const siteId    = (this.state.addressType !== 'Accounts') ? this.state.addressId :  this.state.formData.linkedSite;
        API.post(`/customers/${this.state.formData.customerId}/addresses/${accountId}/links/link`, {siteId})
        .then(res => {
            if (res.data){
                this.setState({linkedSite:0})
                this.getCustomerAddress(this.props.match.params.id)
            }
        }) 
    }

    handleTogglePrimary = (contactId, type, remove) => {
        API.post(`/customers/addresses/${this.props.match.params.id}/changePrimaryContact`, {contactId, type, remove})
        .then (res => {this.getCustomerAddress(this.props.match.params.id);});
    }

    getLogoSrc = files => {
        let logo = _.find(files, {'cour_file_type': 'Logo'});
        return logo ? logo.file_url : '';
    }

    handleCourierCheck = id => {
        let data = this.state.courierFormData;
        let idx = _.findIndex(data, {courier: id});
        if (idx >= 0) {
            data.splice(idx, 1);
        } else {
            data.push({courier: id, zone: 0});
        }
        this.setState({courierFormData: data});
    }

    handleCourierZoneChange = (id) => e => {
        let zone = e ? e.value : 0;
        let data = this.state.courierFormData;
        let idx = _.findIndex(data, {courier: id});
        data[idx].zone = zone;
        this.setState({courierFormData: data});
    }

    handleCourierUpdate = () => {
        this.setState({isLoading: true}, () => {
            API.post(`/customers/addresses/${this.props.match.params.id}/changeCouriers`, { couriers: _.map(this.state.courierFormData, i => i.zone) })
            .then(res => this.getCustomerAddress(this.props.match.params.id))
        });
    }

    checkZones = async () => {
        let zones = await getZonesFromPostcode( this.state.formData.postcode );
        if (this.state.formData.country !== 243) return;
        this.setState({
            courierFormData: _.uniqBy([...zones, ...this.state.courierFormData], i => i.courier)
        });
    }

    render() {
        const { formData, formErrors, isLoading, access, address, siteAddreses, couriers, courierFormData, inactivate } = this.state;
        
        if (this.state.redirect) {
            return <Redirect to="/customers/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Update {this.state.addressType} Address
                        </Typography>
                    </Grid>
                    {(isLoading && (
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <LoadingCircle />
                                    </PaddedPaper>
                                </Grid>
                            </Grid>                    
                    )) || (
                        <React.Fragment>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        {formErrors && formErrors.generic && (
                                            <Typography component={"div"} style={{color: colors.red, marginBottom:15}}>
                                                {formErrors.generic}
                                            </Typography>
                                        )}
                                        {this.state.addressType === 'Site' && 
                                            <FormControl fullWidth margin="normal">
                                                <AutoCompleteSelect 
                                                    name="Active"
                                                    options={[{value: 1, label: 'Active'},{value: 0, label: 'Inactive'}]} 
                                                    label='Active'
                                                    onChange={this.handleSiteActivity}
                                                    value={formData.active}
                                                />
                                            </FormControl>
                                        }
                                        {this.state.addressType === 'Accounts' && 
                                            <>
                                                <FormControl fullWidth margin="normal" disabled={!!inactivate}>
                                                    <AutoCompleteSelect 
                                                        name="Active"
                                                        options={[{value: 1, label: 'Active'},{value: 0, label: 'Inactive'}]} 
                                                        label='Active'
                                                        onChange={this.handleSiteActivity}
                                                        value={formData.active}
                                                        disabled={!!formData.active && !!inactivate}
                                                    />
                                                </FormControl>
                                                {!!formData.active && (!!inactivate &&
                                                    <Typography variant='caption'>
                                                        <InfoIcon
                                                            info={
                                                                <Grid container spacing={1}>
                                                                    {inactivate?.sites.length > 0 &&
                                                                        <Grid item xs={12}>
                                                                            <Typography variant='body2'>
                                                                                <b>Linked Sites</b><br/>
                                                                                {_.map(inactivate.sites, i => <span> - {i.n}<br/></span>)}
                                                                            </Typography>
                                                                        </Grid>
                                                                    }
                                                                    {inactivate?.invoices.length > 0 &&
                                                                        <Grid item xs={12}>
                                                                            <Typography variant='body2'>
                                                                                <b>Linked Invoices</b><br/>
                                                                                {_.map(inactivate.invoices, i => <span> - {i.n}<br/></span>)}
                                                                            </Typography>
                                                                        </Grid>
                                                                    }
                                                                    {inactivate?.credits.length > 0 &&
                                                                        <Grid item xs={12}>
                                                                            <Typography variant='body2'>
                                                                                <b>Linked Credit Notes</b><br/>
                                                                                {_.map(inactivate.credits, i => <span> - {i.n}<br/></span>)}
                                                                            </Typography>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            }
                                                        /> {inactivate.onlyAccount ? 
                                                            'Cannot Inactivate Account address while it is the only active accounts address':
                                                            'Accounts address cannot be inactivated while it has linked sites or outstanding invoices / credit notes'}
                                                    </Typography>
                                                )}
                                            </>
                                        }
                                        {!formData.active ? 
                                            <Typography >You can only edit active addresses</Typography> :
                                            <form noValidate autoComplete="off" style={{marginTop:'1em'}}>
                                                <TextField
                                                    name="name"
                                                    label="Name *"
                                                    value={formData.name}
                                                    error={formErrors && formErrors['name'] && true}
                                                    helperText={formErrors && formErrors['name']}
                                                    onChange={this.handleChange}
                                                    margin="none"
                                                    fullWidth
                                                    disabled={this.state.addressType == 'Registered' || !formData.active}
                                                />
                                                {( this.state.addressType === 'Accounts' && access.viewCreditLimit ) && 
                                                    <Grid container spacing={3} xs={12} style={{marginTop:'1em'}}>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                name="creditLimitNet"
                                                                label="Credit Limit Net *"
                                                                value={formData.creditLimitNet}
                                                                error={formErrors && formErrors['CreditLimitNet'] && true}
                                                                helperText={formErrors && formErrors['CreditLimitNet']}
                                                                onChange={this.handleChange}
                                                                margin="none"
                                                                fullWidth
                                                                type='number'
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                InputProps={{
                                                                    inputProps: { min: 0 },
                                                                    startAdornment: (
                                                                      <InputAdornment  position="start">
                                                                        £
                                                                      </InputAdornment>
                                                                    )
                                                                }}
                                                                disabled={!access.updateCreditLimit}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <TextField
                                                                name="creditLimitGross"
                                                                label="Credit Limit Gross"
                                                                value={formData.creditLimitGross || 0}
                                                                margin="none"
                                                                fullWidth
                                                                type='number'
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                InputProps={{
                                                                    inputProps: { min: 0 },
                                                                    startAdornment: (
                                                                      <InputAdornment  position="start">
                                                                        £
                                                                      </InputAdornment>
                                                                    )
                                                                }}
                                                                disabled
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    
                                                }
                                                <FormControl fullWidth margin="normal">
                                                    <AutoCompleteSelect 
                                                        name="country"
                                                        options={this.state.countryList} 
                                                        label='Country'
                                                        onChange={this.handleSelectChange('country')}
                                                        value={formData.country}
                                                    />
                                                </FormControl>
        
                                                {formData.country === 243 && this.state.formData.showAddressLookup && // United Kingdom
                                                    <AddressLookup 
                                                        handleSelectedAddress={this.handleSelectedAddress}
                                                        inlineLayout={false}
                                                        value={formData.selectedAddress}
                                                    />
                                                }
                                                <MaterialLink component="button" variant="caption" className='blueLink' onClick={this.handleManuallyEnterAddress}>
                                                    {this.state.formData.showAddressLookup ? 'Manually enter address': 'Use address lookup (UK Only)' }
                                                </MaterialLink>
        
                                                {(formData.country !== 243 || (formData.country === 243 && formData.showAddress) || formData.showAddress)  &&
                                                    <React.Fragment>
                                                        <TextField
                                                            name="addressOne"
                                                            label="Address Line 1 *"
                                                            value={formData.addressOne}
                                                            error={formErrors && formErrors['addressOne'] && true}
                                                            helperText={formErrors && formErrors['addressOne']}
                                                            onChange={this.handleChange}
                                                            margin="normal"
                                                            fullWidth
                                                        />
                                                        <TextField
                                                            name="addressTwo"
                                                            label="Address Line 2"
                                                            value={formData.addressTwo}
                                                            onChange={this.handleChange}
                                                            margin="normal"
                                                            fullWidth
                                                        />
                                                        <TextField
                                                            name="addressThree"
                                                            label="Address Line 3"
                                                            value={formData.addressThree}
                                                            onChange={this.handleChange}
                                                            margin="normal"
                                                            fullWidth
                                                        />
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    name="town"
                                                                    label="City / Town *"
                                                                    value={formData.town}
                                                                    onChange={this.handleChange}
                                                                    error={formErrors && formErrors['town'] && true}
                                                                    helperText={formErrors && formErrors['town']}
                                                                    margin="normal"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    name="postcode"
                                                                    label="Postcode"
                                                                    value={formData.postcode}
                                                                    onChange={this.handleChange}
                                                                    margin="normal"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        {formData.country === 243 &&
                                                            <FormControl fullWidth margin="normal">
                                                                <TextField
                                                                    name="county"
                                                                    label="County"
                                                                    value={formData.county || ''}
                                                                    onChange={this.handleChange}
                                                                    margin="normal"
                                                                    fullWidth
                                                                />
                                                            </FormControl>
                                                        }
                                                    </React.Fragment>
                                                }
                                                <TextField
                                                    name="phone"
                                                    label="Phone"
                                                    value={formData.phone}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                <TextField
                                                    name="email"
                                                    label="Email"
                                                    value={formData.email}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                {this.state.addressType === 'Registered' &&
                                                    <TextField
                                                        name="website"
                                                        label="Website"
                                                        value={formData.website}
                                                        onChange={this.handleChange}
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                }
                                                {this.state.addressType === 'Site' && 
                                                    <>
                                                        <AccessTimes 
                                                            handleDayChange={this.handleDayChange('accessTimes')}
                                                            handleTimeChange={this.handleTimeChange('accessTimes')}
                                                            handleAddAccessTimes={this.handleAddAccessTimes('addAccessTimes')}
                                                            handleAddAccessTimeRow={this.handleAddAccessTimeRow('accessTimes')}
                                                            handleRemoveRow={this.handleRemoveRow('accessTimes')}
                                                            addAccessTimes={formData.addAccessTimes}
                                                            formData={formData} 
                                                            formErrors={formErrors}
                                                            addressIdx={0}
                                                            update={true}
                                                        />
                                                        <Textarea
                                                            label='Access Times Notes'
                                                            name='accessTimesNotes'
                                                            onChange={this.handleChange}
                                                            value={formData.accessTimesNotes}
                                                            margin="normal"
                                                            fullWidth
                                                            rows={4}
                                                        />
                                                    </>
                                                }
                                                {this.state.addressType === 'Site' && 
                                                    <>
                                                        <OpeningTimes 
                                                            handleDayChange={this.handleDayChange('openingTimes')}
                                                            handleTimeChange={this.handleTimeChange('openingTimes')}
                                                            handleAddAccessTimes={this.handleAddAccessTimes('addOpeningTimes')}
                                                            handleAddAccessTimeRow={this.handleAddAccessTimeRow('openingTimes')}
                                                            handleRemoveRow={this.handleRemoveRow('openingTimes')}
                                                            addAccessTimes={formData.addOpeningTimes}
                                                            formData={formData} 
                                                            formErrors={formErrors}
                                                            addressIdx={0}
                                                            update={true}
                                                        />
                                                        <Textarea
                                                            label='Opening Times Notes'
                                                            name='openingTimesNotes'
                                                            onChange={this.handleChange}
                                                            value={formData.openingTimesNotes}
                                                            margin="normal"
                                                            fullWidth
                                                            rows={4}
                                                        />
                                                    </>
                                                }
                                                
                                            </form>
                                        }
                                        <div className='buttonRow'>
                                            <BackButton props={this.props} />
                                            {!!formData.active &&
                                                <Button onClick={this.handleConfirmationOpen}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                    Update
                                                </Button>
                                            }
                                        </div>
                                    </PaddedPaper>
                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6" gutterBottom>Couriers</Typography>
                                        <div className='buttonRow'>
                                        <Button onClick={this.checkZones}> <AllIcon icon={icons.location }/> Check Postcode </Button>
                                        </div>
                                        <Grid container spacing={2} style={{alignItems: 'center'}}>
                                            {_.map(_.filter(couriers, c => c.zones.length > 0), (i, idx) => {
                                                let cz = _.find(courierFormData, {courier: i.cour_id})
                                                return (
                                                    <Grid item container xs={12} spacing={1} style={{alignItems: 'center', borderBottom: (idx + 1) !== _.filter(couriers, c => c.zones.length > 0).length && `1px solid ${colors.disabled}`}}>
                                                        <Grid item>
                                                            <Checkbox 
                                                                checked={!!cz} 
                                                                value={!!cz}
                                                                onChange={() => this.handleCourierCheck(i.cour_id)}
                                                            />
                                                        </Grid>
                                                        <Grid item style={{paddingTop: '0.5em'}}>
                                                            <ImageWIthError src={this.getLogoSrc(i.file)} style={{maxWidth: 64}} />
                                                        </Grid>    
                                                        <Grid item >{i.cour_name}</Grid>
                                                        <Grid item xs={5} style={{marginLeft: 'auto'}}>
                                                            <AutoCompleteSelect 
                                                                fullWidth
                                                                options={_.map(i.zones, z => ({label: z.cz_name, value: z.cz_id}))}
                                                                onChange={this.handleCourierZoneChange(i.cour_id)}
                                                                value={ cz ? cz?.zone : null }
                                                                noClear
                                                                disabled={!cz}
                                                            />
                                                        </Grid>
                                                    </Grid>    
                                                )}
                                            )}
                                        </Grid>
                                        <div className='buttonRow'>
                                            <Button
                                                disabled={!_.filter(courierFormData, i => i.courier && i.zone).length}
                                                variant="contained"
                                                color="primary"
                                                onClick={()=>this.props.deployConfirmation('Are you sure you want to update the couriers?', 'Update Address Couriers', this.handleCourierUpdate)}
                                            >Update Couriers</Button>
                                        </div>
                                    </PaddedPaper>
                                </Grid>
                                
                                {address.address_type === 'Site' &&
                                    <>
                                         {!!address.linked_accounts &&
                                            <Grid item xs={12} lg={6}>
                                                <PaddedPaper>
                                                    <Grid container item xs={12} >
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" className="float-left">
                                                                Linked Account
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={10} style={{padding:10}}>
                                                            <Typography variant="h6" className="float-left">
                                                                {address.linked_accounts.address_name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1} className='buttonRow' style={{margin:0}}>
                                                            <AllIcon noMargin toolTip='Update' icon='pen' link={`/customers/addresses/update/${address.linked_accounts.address_id}`} />
                                                        </Grid>
                                                        <Grid item xs={1} className='buttonRow' style={{margin:0}}>
                                                            <AllIcon noMargin toolTip='View' icon='search' link={`/customers/addresses/view/${address.linked_accounts.address_id}`} />
                                                        </Grid>
                                                    </Grid>
                                                </PaddedPaper>
                                            </Grid>
                                        }
                                    </>
                                }
                            
                                {this.state.addressType === 'Accounts' && 
                                    <Grid item xs={12} lg={6}>
                                        <PaddedPaper >
                                            <Grid container xs={12} style={{alignItems: 'flex-end'}}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" className="float-left">
                                                        Link A Site
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} lg={10}>
                                                    <FormControl fullWidth margin="none">
                                                        <AutoCompleteSelect 
                                                            name="SiteAddress"
                                                            options={siteAddreses} 
                                                            label='Site Address'
                                                            onChange={this.handleSelectChange('linkedSite')}
                                                            value={formData.linkedSite}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={2}  style={{marginRight:'auto', textAlign:'right'}}>
                                                    <Button variant="contained" color="primary" onClick={this.linkSite}>Link Site</Button>
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                        <PaddedPaper style={{marginTop:'1.5em'}}>
                                            <Grid container item xs={12} >
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" className="float-left">
                                                        Linked Sites
                                                    </Typography>
                                                </Grid>
                                                <Grid item spacing={3} xs={12}>
                                                    <DataTable 
                                                         config={{
                                                            noHeader: true,
                                                            pagination: true,
                                                            rowsPerPage: 10
                                                        }}
                                                        columns={
                                                            [
                                                                {
                                                                    heading:   'Site',
                                                                    field:     rowData => rowData.address_name,
                                                                    fullWidth: true,
                                                                },
                                                                {
                                                                    actions: rowData => { return [
                                                                        {name: 'View',   icon: 'search', link: '/customers/addresses/view/'+ rowData.address_id},
                                                                        {name: 'Edit',   icon: 'pen',    link: '/customers/addresses/update/'+ rowData.address_id},
                                                                        {name: 'Unlink', icon: 'unlink', onClick: () => {this.unlinkSite(rowData.address_id)}},
                                                                    ]},
                                                                    sizeToContent:  true,
                                                                    important:      true,
                                                                    alignment:      'right'
                                                                },
                                                            ]
                                                        }
                                                        rows={address.linked_sites}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {!!formData.active &&
                                    <PaddedPaper>
                                        <Typography variant="h6" gutterBottom>
                                            Contacts
                                        </Typography>
                                        <DataTable  
                                            config={{
                                                key: 'contact_id',
                                                pagination: false
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Name',
                                                    field: rowData => rowData.contact_name
                                                },
                                                {
                                                    heading: 'Position',
                                                    field: rowData => rowData.contact_position
                                                },
                                                {
                                                    heading: 'Telephone',
                                                    field: rowData => rowData.contact_telephone
                                                },
                                                {
                                                    heading: 'Mobile',
                                                    field: rowData => rowData.contact_mobile
                                                },
                                                {
                                                    heading: 'Email',
                                                    field: rowData => rowData.contact_email
                                                },
                                                {
                                                    heading: 'Primary Invoice',
                                                    field: rowData => rowData.contact_id === _.first(address.primary_invoice_contact)?.contact_id,
                                                    fieldFormat: 'boolean',
                                                    important: true,
                                                    sizeToContent: true,
                                                    alignment: 'center'
                                                },
                                                {
                                                    heading: 'Primary Account',
                                                    field: rowData => rowData.contact_id === _.first(address.primary_account_contact)?.contact_id,
                                                    fieldFormat: 'boolean',
                                                    important: true,
                                                    sizeToContent: true,
                                                    alignment: 'center'
                                                },
                                                {
                                                    actions: rowData => {
                                                        
                                                        let ret = [];
                                                        if (this.state.addressType === 'Accounts'){
                                                            let pi = rowData.contact_id === _.first(address.primary_invoice_contact)?.contact_id;
                                                            let pa = rowData.contact_id === _.first(address.primary_account_contact)?.contact_id;
                                                            let cc = !!_.find(address.primary_invoice_c_c, {contact_id: rowData.contact_id});
                                                            let hideInvoice = _.first(address.primary_invoice_contact)?.contact_id && !pi;
                                                            let hideAccount = _.first(address.primary_account_contact)?.contact_id && !pa;

                                                            ret.push({name: `${pi ? 'Remove':'Set'} Primary Invoice`, icon: icons.invoice,    onClick: () => { this.handleTogglePrimary(rowData.contact_id,'invoice', pi) }, disabled: hideInvoice,  color: pi && colors.green});
                                                            ret.push({name: `${pa ? 'Remove':'Set'} Primary Account`, icon: icons.account,    onClick: () => { this.handleTogglePrimary(rowData.contact_id,'account', pa) }, disabled: hideAccount,  color: pa && colors.green});
                                                            ret.push({name: `${cc ? 'Remove':'Set'} CC Invoice`,      icon: icons.carbonCopy, onClick: () => { this.handleTogglePrimary(rowData.contact_id,'cc', cc) },      disabled: !hideInvoice, color: cc && colors.green});
                                                        }

                                                        ret.push({name: 'Delete', icon: 'trash-alt',  onClick: this.handleDeleteOpen});
                                                        ret.push({name: 'Update', icon: 'pencil-alt', link: '/customers/contacts/update/' + rowData.contact_id, disabled: !access.updateContact});
                                                        ret.push({name: 'View',   icon: 'search',     link: '/customers/contacts/view/' + rowData.contact_id,   disabled: !access.viewContact});

                                                        return ret;
                                                    }
                                                }
                                            ]}
                                            rows={this.state.contacts}
                                        />
                                    </PaddedPaper>
                                }
                            </Grid>
                            <ConfirmationDialog 
                                open={this.state.confirmationOpen} 
                                success={this.handleConfirmationSuccess} 
                                close={this.handleConfirmationClose} 
                                title="Update Address?" 
                                message="Are you sure you want to update this address?"
                            />
                            <ConfirmationDialog 
                                open={this.state.deleteOpen} 
                                success={this.handleDeleteSuccess} 
                                close={this.handleDeleteClose} 
                                title="Delete Contact?" 
                                message="Are you sure you want to delete this contact?"
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarOpen}
                                onClose={this.handleSnackbarClose}
                                message='You have successfully updated the address'
                            />
                        </React.Fragment>
                    )}
                </Grid>
            )
        }
    }
}


const mapDispatchToProps = dispatch => ({
    deployConfirmation:       (content, title, callback) => dispatch(deployConfirmation(content, title, callback)),
})

export default connect(null, mapDispatchToProps)(UpdateCustomerAddress);
