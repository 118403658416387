import React  from 'react';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import icons      from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

import AllIcon from 'Components/Common/Icons/AllIcon';
import CameraDialog from './CameraDialog';

import { base64ImgtoFile } from 'Functions/MiscFunctions';

const initialState = {
    fileKey: 1,
    dropzoneHover: false,
    showCameraDialog: false
}

class TouchScreenFileInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.fileInputRef = React.createRef();
    }

    componentDidMount = () => {
        this.setState({
            fileKey: Math.random().toString(36),
        });
    }

    handleUploadClick = () => {
        if(this.props.disabled) return;
        this.fileInputRef.current.click();
    }

    hideShowCamera = () => {
        if(this.props.disabled) return;
        this.setState({
            showCameraDialog: !this.state.showCameraDialog
        })
    }

    handleCameraSave = (imgUri) => {
        this.hideShowCamera();
        this.props.onChange(false, this.props.name, {target: {files: [base64ImgtoFile(imgUri,`capture_${uuidv4()}.jpeg`)]}});
    }

    handleUploadSave = (event) => {
        this.props.onChange(false, this.props.name, event);
    }

    handleClearFile = (idx=null) => {
        this.props.cancelOnClick && this.props.cancelOnClick(idx);
        this.props.handleClearFile && this.props.handleClearFile(idx);
    }

    render() {
        const { showCameraDialog } = this.state;
        const { classes, disabled, errorText, multiple } = this.props;

        
        const files = this.props.file && ( _.isArray(this?.props?.file) ? this?.props?.file?.length > 0 : true ) ? _.map(multiple && ( _.isArray(this?.props?.file) && this?.props?.file?.length >= 1 ) ? this.props.file : [this.props.file], (i, idx) => ({name: i?.name || i, idx})) : [];


        return (
            <>
                <Grid container xs={12} className={`${classes.dropzone} ${disabled ? classes.dropzoneDisabled : classes.dropzoneArea}`}>
                        <Grid item xs={6} onClick={this.hideShowCamera} style={{borderRight: '1px solid rgb(187, 186, 186)'}}>
                            <AllIcon noMargin icon={icons.camera} size='medium'/><br/>
                            capture
                        </Grid>
                        <Grid item xs={6} onClick={this.handleUploadClick}>
                            <AllIcon noMargin icon={icons.upload} size='medium'/><br/>
                            Upload
                        </Grid>
                        <Grid item xs={12} style={{borderTop: '1px solid rgb(187, 186, 186)', marginTop: 4, paddingTop: 8}}>
                            {files.length > 0 ?
                                <Grid container spacing={1}>
                                    <Grid item container xs={6} spacing={1}>
                                        {_.map(_.filter(files, i => !!(_.isString(i?.name) ? i?.name : i.name[0].name ).startsWith('capture_')), (i, idx) => 
                                            <Grid item xs={12} onClick={()=>this.handleClearFile(i.idx)}>
                                                <AllIcon icon='times-circle' size='small' style={{marginRight: 5}} />
                                                {_.isString(i?.name) ? i?.name : i.name[0].name}
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item container xs={6} spacing={1}>
                                        {_.map(_.filter(files, i => !(_.isString(i?.name) ? i?.name : i.name[0].name ).startsWith('capture_')), (i, idx) => 
                                            <Grid item xs={12} onClick={()=>this.handleClearFile(i.idx)}>
                                                <AllIcon icon='times-circle' size='small' style={{marginRight: 5}} />
                                                {_.isString(i?.name) ? i?.name : i.name[0].name}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid> 
                                : <>No File Selected</>
                            }
                        </Grid>
                        {errorText && <Grid item xs={12} style={{color: colors.red}}>{errorText}</Grid>}
                        <input 
                            className={`${classes.fileInput}`}
                            disabled={this.props.disabled}
                            file={this.props.file}        
                            id={this.props.id}   
                            name={this.props.name}
                            onChange={this.handleUploadSave} 
                            ref={this.fileInputRef}
                            type="file"
                            capture={this.props.capture}
                            multiple={multiple}
                        />
                        {showCameraDialog &&
                            <CameraDialog 
                                open={true}
                                title={'Capture Image'}
                                overlay={null}
                                save={this.handleCameraSave}
                                close={this.hideShowCamera}
                            />
                        }
                </Grid>
            </>
        )
    }
}

const style = theme => ({
    dropzone: {
        backgroundColor: '#fff',
        textAlign: 'center',
        padding: '16px',
    },
    dropzoneArea: {
        border: '1px dashed rgb(187, 186, 186)',
        borderRadius: 4,
    },      
    dropzoneDisabled: {
        backgroundColor: 'rgba(187, 186, 186, 0.1)',
        color: 'rgba(0,0,0,0.5)',
        cursor: 'default'
    },    
    fileInput: {
        display: 'none'
    }
});



export default withStyles(style)(TouchScreenFileInput);