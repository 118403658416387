import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';

import API from 'API';
import {
    Grid,
    Typography,
    Button
} from '@material-ui/core';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import DataTable from 'Components/Common/DataTables/DataTable';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import { toggleDialog } from 'Functions/MiscFunctions';
import { CLENAWARE_GREEN } from 'Constants';
import VehicleAllocationForm from './VehicleAllocationForm';
import DatePicker         from 'Components/Common/DatePickers/DatePicker';

const initialState = () => ({
    vehicleId: 0,
    allocations: [],
    dialog: {
        allocation: false,
        allocationId: 0,
    },
    access: {
        updateVehicle: false,
    },
    isLoading: true,
    formData:{
        dateFilter: null
    }
})

class VehicleAllocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.toggleDialog = toggleDialog.bind(this);
    }

    componentDidMount() {
        const { vehicleId } = this.props;
        this.setState({
            ...this.state,
            vehicleId
        }, () => this.getAccess());
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/update-vehicle')
        ])
        .then(([updateRes]) =>  {
            this.setState({
                ...this.state,
                access: {
                    updateVehicle: (updateRes.data && updateRes.data.has_access) || false
                }
            }, () => this.getSearchData());
        }) 
    }

    getSearchData = () => {
        this.setState({
            isLoading: true
        }, 
        () => {
            API.get(`/vehicles/${this.props.vehicleId}/allocations`, { params: this.state.formData})
            .then(result =>  {

                if(result.data) {
                    this.setState({
                        isLoading: false,
                        allocations: result.data
                    });
                }
            });
        });
    }

    openAllocationDialog = (id = 0) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                allocation: true,
                allocationId: id
            },
        });
    }

    resetSearch = () => {
        this.setState({
            formData:{
                ...this.state.formData,
                dateFilter: null
            }
        }, 
        () => {
            this.getSearchData();
        });
    }

    handleDateChange = (name, date)  => {
        this.setState({
            formData:{
                ...this.state.formData,
                [name]: moment(date).format('YYYY-MM-DD')
            }
        }, 
        () => {
            this.getSearchData();
        });
    };

    render() {

        const { vehicleId, allocations, dialog, isLoading, access, formData } = this.state;
        const { activeVehicle } = this.props;
        return (
            <>
                <PaddedPaper>
                    <Grid container item spacing={3}>                        
                        <Grid item xs={12}>
                            <Grid container item spacing={3}>
                                <Grid item xs>
                                    <Typography variant="h6" paragraph>
                                        <b>Vehicle Allocation</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs align='right'>
                                    {access.updateVehicle && activeVehicle ?
                                        <Button 
                                            variant='outlined' 
                                            size="small" 
                                            onClick={() => this.openAllocationDialog()}>
                                            <AllIcon icon='plus' size="small" />
                                            New Allocation
                                        </Button>
                                    : null}
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item spacing={3} direction="row" alignItems="flex-end">
                                <Grid item xs={8} md={3}>
                                    <DatePicker
                                        type='date'
                                        id="dateFilter"
                                        name="dateFilter"
                                        label="Date Filter"
                                        margin="none"
                                        autoOk={true}
                                        value={formData?.dateFilter}
                                        onChange={(d) => this.handleDateChange('dateFilter', d)}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button 
                                        onClick={this.resetSearch}
                                        variant="outlined"
                                        color="default"
                                        margin="none"
                                    >
                                        <AllIcon icon='undo' button noMargin />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {allocations.length > 0 ?
                            <>
                            <Grid item xs={12}>
                                <DataTable  
                                    config={{
                                        key: 'id',
                                        pagination: true,
                                        alternatingRowColours: false,
                                        responsiveImportance: true,
                                        isLoading: isLoading,
                                        rowProps: (row) => ({
                                            style: {
                                                backgroundColor: row?.current && 'rgb(241, 248, 233)'
                                            }
                                        }),
                                    }}
                                    columns={[
                                        {
                                            heading: '',
                                            field: rowData =>   <AllIcon 
                                                                    icon={rowData?.allocatedTo == 'Pool Vehicle' ? 'car-side' : (rowData?.allocatedTo == 'Engineer' ? 'user-hard-hat': 'user')}
                                                                    noMargin
                                                                    heavy={rowData?.current && true}
                                                                    style={{color: rowData?.current ? CLENAWARE_GREEN : undefined }}
                                                                />,
                                            sizeToContent: true,
                                            hideInResponsiveDialog: true
                                        },
                                        {
                                            heading: 'Allocated To',
                                            field: rowData => rowData?.allocatedTo,
                                            main: true,
                                            important: true,
                                            hideInResponsiveDialog: true
                                        },
                                        {
                                            heading: 'Name',
                                            field: rowData => rowData?.allocateDetails ? rowData?.allocateDetails?.fn + ' ' + rowData?.allocateDetails?.ln : '-',
                                            important: true
                                        },
                                        {
                                            heading: 'Overnight Postcode',
                                            field: rowData => rowData?.overnightPostcode,
                                        },
                                        {
                                            heading: 'Start Date',
                                            field: rowData => moment(rowData?.startDate).format('MMM D, YYYY'),                                   
                                            important: true,
                                            sizeToContent: true,
                                        },
                                        {
                                            heading: 'End Date',
                                            field: rowData => rowData?.endDate !== null ? moment(rowData?.endDate).format('MMM D, YYYY') : <em>Ongoing</em>,                                          
                                            important: true,
                                            sizeToContent: true,
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Update', icon: 'pencil-alt', onClick: () => this.openAllocationDialog(rowData?.id), disabled: !access.updateVehicle || !activeVehicle},
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={allocations}
                                />
                            </Grid>
                            </>
                        :
                            <Grid item xs={12} align="center">
                                <Typography variant="h6" paragraph color='textSecondary'>
                                    <em>No allocation history found</em>
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </PaddedPaper>
                <SmallFormDialog
                    open={dialog?.allocation}
                    onClose={() => this.toggleDialog('allocation')}
                    content={
                        <VehicleAllocationForm 
                            onClose={() => this.toggleDialog('allocation', true)}
                            allocationId={dialog?.allocationId}
                            refresh={() => {this.toggleDialog('allocation', true); this.props.refresh();}}
                            vehicleId={vehicleId}
                        />
                    }
                    maxWidth='sm'
                />
            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),

    }
}

export default connect(null, mapDispatchToProps)(VehicleAllocation)