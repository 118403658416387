import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import PaddedPaper from "Components/Common/Paper/PaddedPaper";
import ImageWithError from "Components/Common/ImageWithError/ImageWithError";
import Textarea from "Components/Common/Inputs/Textarea";
import CiDataTable from "Components/Common/DataTables/CiDataTable";


export default function BlueTrollyCard({part, OnClose}) {

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}> 
                <PaddedPaper>
                    <Grid container spacing={2} style={{alignItems: 'center'}}>
                        <Grid item >
                            <ImageWithError 
                                src={part.filePath}
                                alt={part.partNumber}
                                style={{maxWidth: '75px', maxHeight: '75px', width: 'auto', marginRight: '1em'}}    
                            />
                        </Grid>
                        <Grid item >
                            <Typography variant="h6">{part.partNumber}</Typography>
                            <Typography variant="body1">{part.description}</Typography> 
                            <Typography variant="body2">Supplier: {part.supplier}</Typography> 
                        </Grid>
                    </Grid>
                </PaddedPaper>
            </Grid>
            <Grid item xs={12}> 
                <Textarea
                    value={part.notes}
                    label="Notes"
                    fullWidth
                    rows={3}
                    disabled
                />
            </Grid>
            <Grid item xs={12}> 
                <Typography variant="h6" gutterBottom>Associated Parts</Typography>
                <CiDataTable
                    config={{
                        key: 'i',
                    }}
                    rows={part.associatedParts}
                    columns={[
                        {
                            heading: '',
                            field: 'p',
                            fieldFormat: 'image',
                            sizeToContent: true,
                        },
                        {
                            heading: 'Part Number',
                            field: 'n',
                            dataRef: 'n',
                            sizeToContent: true,
                        },
                        {
                            heading: 'Description',
                            field: 'd',
                            dataRef: 'd',
                        }
                    ]}
                />
            </Grid>
            <Grid item xs={12} className="buttonRow">
                <Button
                    variant="outlined"
                    onClick={OnClose}
                >
                    Close
                </Button>
            </Grid>
        </Grid>
    )
}