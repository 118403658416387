
import React, { Component } from 'react';
import { Redirect }         from 'react-router-dom';
import API                  from 'API';
import _                    from 'lodash';

import { Button, FormControl, Grid, TextField, Typography, InputAdornment, Dialog, DialogTitle, DialogContent, ListItem, ListItemAvatar, List, ListItemText, ListSubheader, DialogActions } from '@material-ui/core';

import PaddedPaper             from 'Components/Common/Paper/PaddedPaper';
import ConfirmationDialog      from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar                from 'Components/Common/SnackBars/SnackBar';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle           from 'Components/Common/LoadingCircle/LoadingCircle';
import ReviewDialog            from 'Components/Customers/UpdateCustomer/ReviewDialog';
import { FullScreenDialog }    from 'Components/Common/Dialogs/FullScreenDialog';
import ViewQuotation           from 'Components/Sales/Quotation/ViewQuotation/ViewQuotation';
import ViewOrder               from 'Components/Sales/Order/ViewOrder/ViewOrder';
import BackButton              from 'Components/Common/Buttons/BackButton';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import Textarea from 'Components/Common/Inputs/Textarea';
import CheckBox from 'Components/Common/Inputs/CheckBox';

const initialState = { 
    originalAccountStatus: '',
    formData: {
        customerId: '',
        // Business Details
        businessType: '',
        businessName: '',
        businessNameOG: '',
        tradingName: '',
        legalStatus: '',
        companyRegistrationNumber: '',
        companyVATNumber: '',
        companyEORINumber: '',
        closeCustomerNotes: '',
        // link customers 
        customers: [],
        // Account Information
        vatRate: '',
        discountRate: '',
        paymentTerm: '',
        accountStatus: '',
        allowQuotes: false,
        allowOrders: false,
        showCreditLimit: false,
        creditLimitNet: 0,
        creditLimitGross: 0,
        vat: 0,
        monthlyServicePlan: 0,
        yearlyServicePlan: 0,
        changeSites: []
    },
    customerOriginal: {},
    vatList: [],
    discountList: [],
    paymentTermsList: [],
    accountStatusList: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    isLoading: true,
    redirect: false,
    reviewQuotes: false,
    reviewOrders: false,
    reviewDialog: false,
    quoteDialog: false,
    quoteData: {},
    orderDialog: false,
    updateAddressDialog: false,
    orderData: {},
    access: {},
    lists: {
        customers:  [] 
    }
}

class UpdateCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount(){
        this.getCustomer(this.props.match.params.id);
        this.checkAccess();
        this.getCustomers();
    }

    getCustomers = () => {
        API.get('/customers')
        .then(res => {
            this.setState({
                lists: {
                    ...this.state.lists,
                    customers: _.map(res.data, i => _.assign({
                        value: i.cust_id,
                        label: `${i.cust_name} | CS${i.cust_id} | ${_.find(i.addresses, {address_type: 'Registered'}).address_postcode}`
                    }) )
                }
            })
        })
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/view-customer-credit-limit'),
            API.get('/staff/my/access/check/update-customer-credit-limit'),
        ]).then(([viewCreditLimit, updateCreditLimit]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    viewCreditLimit:    viewCreditLimit.data    ? viewCreditLimit.data.has_access       : false,
                    updateCreditLimit:  updateCreditLimit.data  ? updateCreditLimit.data.has_access     : false,
                }
            });
        });
    }
    getCustomer = (customerId) => {
        API.get(`/customers/${customerId}`)
        .then((res) =>  {
            if (res.data.errors) {
                this.setState({ redirect: true });
            } else {
                this.populateDropdowns();
                this.setState({
                    originalAccountStatus: res.data.account_status && res.data.account_status.cas_id,
                    formData: {
                        ...this.state.formData,
                        customerId:                 customerId,
                        // Business Details
                        businessType:               res.data.cust_type,
                        businessName:               res.data.cust_name,
                        businessNameOG:             res.data.cust_name,
                        tradingName:                res.data.cust_trading_name,
                        oldTradingName:             res.data.cust_trading_name,
                        legalStatus:                res.data.cust_legal_status,
                        companyRegistrationNumber:  res.data.cust_reg_number,
                        companyVATNumber:           res.data.cust_vat_number,
                        companyEORINumber:          res.data.cust_eori_number,
                        // link customers 
                        customers:                  res.data.linked.length > 0 ? 
                                                        _.map(res.data.linked, i => i.clc_linked_cust_id ) : 
                                                        initialState.formData.customers,
                        // Account Information
                        vatRate:                    res.data.vat_type && res.data.vat_type.vat_type_id,
                        discountRate:               res.data.discount_name && res.data.discount_name.cdn_id,
                        paymentTerm:                res.data.payment_term && res.data.payment_term.payment_term_id,
                        accountStatus:              res.data.account_status && res.data.account_status.cas_id,
                        allowQuotes:                res.data.account_status && res.data.account_status.cas_allow_quotes,
                        allowOrders:                res.data.account_status && res.data.account_status.cas_allow_orders,
                        showCreditLimit:            (res.data.account_addresses.length === 1),
                        creditLimitNet:             res.data.account_addresses[0].credit_limit.ccl_net,
                        creditLimitGross:           res.data.account_addresses[0].credit_limit.ccl_gross,
                        vat:                        res.data.vat_type.default_vat_value.vat_rate_decimal,
                        monthlyServicePlan:         res.data.cust_service_plan ?? 0,
                        yearlyServicePlan:          res.data.cust_yearly_service_plan ?? 0,
                    },
                    quoteCount: res.data.quotation_count,
                    orderCount: res.data.order_count,
                    isLoading: false,
                    customerOriginal: res.data
                });
            }
        })
    }
    populateDropdowns = () => {
        let vatList = [];
        let discountList = [];
        let paymentTermsList = [];
        let accountStatusList = [];
        Promise.all([
            API.get('/accounts/vatRates/byType'),
            API.get('/accounts/customerDiscounts/byName'),
            API.get('/customers/paymentTerms'),
            API.get('/customers/accountStatuses')
        ])
        .then(([vatRes, discountRes, paymentTermRes, accountRes]) =>  {
            if(vatRes.data){
                vatRes.data.forEach((type) => {
                    let currentValue = _.find(type.vat_values, {'current': true});
                    if(currentValue){
                        vatList.push({
                            value: type.vat_type_id,
                            label: type.vat_type_name + ' | ' + currentValue.vat_value_percentage + '%',
                        });
                    }
                });
            }
            if(discountRes.data){
                discountRes.data.forEach((name) => {
                    let currentValue = _.find(name.discount_values, {'current': true});
                    if(currentValue){
                        discountList.push({
                            value: name.cdn_id,
                            label: name.cdn_name + ' | ' + currentValue.cdv_percentage + '%',
                        });
                    }
                });
            }
            if(paymentTermRes.data){
                paymentTermsList = _.map(paymentTermRes.data, el => {
                    return _.assign({
                        value: el.payment_term_id,
                        label: el.payment_term_name,
                    });
                });
            }
            if(accountRes.data){
                accountStatusList = _.map(accountRes.data, el => {
                    return _.assign({
                        value: el.cas_id,
                        label: el.cas_name,
                        allowQuotes: el.cas_allow_quotes,
                        allowOrders: el.cas_allow_orders
                    });
                });
            }
            this.setState({
                vatList: vatList,
                discountList: discountList,
                paymentTermsList: paymentTermsList,
                accountStatusList
            });
        });
    }
    handleChange = (e) => {
        if (e.target.name === 'creditLimitNet') {            
            this.setState({
                formData: {
                    ...this.state.formData,
                    creditLimitNet:     e.target.value,
                    creditLimitGross:   parseFloat( parseInt(e.target.value) + ( e.target.value * this.state.formData.vat ) ).toFixed(0)
                }
            });
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [e.target.name]: e.target.value
                }
            });
        }   
    }
    handleSelectChange = fieldName => selectedOption => {
        switch(fieldName) {
            case 'accountStatus':
                this.setState({
                    formData: {
                        ...this.state.formData,
                        accountStatus: selectedOption && selectedOption.value,
                        allowQuotes: selectedOption && selectedOption.allowQuotes,
                        allowOrders: selectedOption && selectedOption.allowOrders
                    }
                });
            break;
            default:
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [fieldName]: selectedOption && selectedOption.value,
                    }
                });
            break;
        }
    }
    handleFullScreenDialog = rowData => {
        if(rowData.quote_id) {
            this.setState({
                quoteDialog: true,
                quoteData: rowData
            });
        } else {
            this.setState({
                orderDialog: true,
                orderData: rowData
            });
        }
    }
    handleFullScreenDialogClose = () => this.setState({quoteDialog: false, orderDialog: false});
    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    }
    submit = () => {
        API.post(`/customers/${this.state.formData.customerId}`, {...this.state.formData, changeSites: JSON.stringify(this.state.formData.changeSites)})
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true,
                    lists:        this.state.lists
                },()=>this.getCustomer(this.props.match.params.id));
            }
            this.props.scrollToTop();
        });
    }
    handleConfirmationOpen = (e) => {
        const {originalAccountStatus, formData: {accountStatus, allowQuotes, allowOrders}, quoteCount, orderCount} = this.state;

        if(originalAccountStatus !== accountStatus && 
            ((allowQuotes === 0 && quoteCount > 0) || (allowOrders === 0 && orderCount > 0))) {
                let reviewQuotes = allowQuotes === 0 && quoteCount > 0 ? true : false; // Review quotes if we are switching access off and we still have active quotes
                let reviewOrders = allowOrders === 0 && orderCount > 0 ? true : false; // Review orders if we are switching access off and we still have active orders
                this.setState({
                    reviewDialog: true,
                    reviewQuotes,
                    reviewOrders
                });
        } else {
            this.setState({
                confirmationOpen: true
            });
        }
    }
    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    }
    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        if (this.state.formData.businessNameOG == this.state.formData.businessName){
            this.submit();
        } else {
            this.handleConfirmationNameClose();
        }
    }

    handleConfirmationNameSuccess = () => {
        this.handleConfirmationNameClose();
        this.submit();
    }

    handleConfirmationNameClose = () => {
        this.setState({ confirmationNameOpen: !this.state.confirmationNameOpen });
    }

    handleReviewDialogClose = () => this.setState({reviewDialog: false});

    handleReviewDialogSuccess = () => {
        this.handleReviewDialogClose();
        this.handleConfirmationSuccess();
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }

    handleConfirmUpdateAddress = () => {
        this.setState({ updateAddressDialog: !this.state.updateAddressDialog });
    }

    render() {
        const { formErrors, isLoading, quoteDialog, quoteData, orderDialog, orderData, access, updateAddressDialog, customerOriginal } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/customers/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <Typography variant="h5">
                            Update Customer
                        </Typography>
                    </Grid>
                    {(isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                    )) || (
                        <React.Fragment>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Business Details
                                        </Typography>
                                        {formErrors && formErrors.generic && (
                                            <React.Fragment>
                                                <Typography component={"div"} style={{color: colors.red}}>
                                                    {formErrors.generic}
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                        <form noValidate autoComplete="off">
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteSelect 
                                                    options={[
                                                    {value: 'Dealer', label: 'Dealer'},
                                                    {value: 'End User', label: 'End User'},
                                                    {value: 'Group', label: 'Group'}
                                                    ]} 
                                                    label='Business Type *'
                                                    value={this.state.formData.businessType}
                                                    onChange={this.handleSelectChange('businessType')}
                                                    error={formErrors && formErrors['businessType'] && true}
                                                    errorText={formErrors && formErrors['businessType']}
                                                />
                                            </FormControl>
                                            <TextField
                                                id="businessName"
                                                name="businessName"
                                                label="Business Name *"
                                                value={this.state.formData.businessName}
                                                error={formErrors && formErrors['businessName'] && true}
                                                helperText={formErrors && formErrors['businessName']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <TextField
                                                id="tradingName"
                                                name="tradingName"
                                                label="Business Trading Name"
                                                value={this.state.formData.tradingName}
                                                error={formErrors && formErrors['tradingName'] && true}
                                                helperText={formErrors && formErrors['tradingName']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteSelect 
                                                    options={[
                                                    {value: 'Limited Company', label: 'Limited Company'},
                                                    {value: 'Public Limited Company', label: 'Public Limited Company'},
                                                    {value: 'Sole Trader', label: 'Sole Trader'},
                                                    {value: 'Partnership', label: 'Partnership'},
                                                    {value: 'Consumer', label: 'Consumer'},
                                                    {value: 'Other', label: 'Other'}
                                                    ]} 
                                                    label='Legal Status'
                                                    value={this.state.formData.legalStatus}
                                                    onChange={this.handleSelectChange('legalStatus')}
                                                    error={formErrors && formErrors['legalStatus'] && true}
                                                    errorText={formErrors && formErrors['legalStatus']}
                                                />
                                            </FormControl>
                                            <TextField
                                                id="companyRegistrationNumber"
                                                name="companyRegistrationNumber"
                                                label="Company Registration Number"
                                                value={this.state.formData.companyRegistrationNumber}
                                                error={formErrors && formErrors['companyRegistrationNumber'] && true}
                                                helperText={formErrors && formErrors['companyRegistrationNumber']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <TextField
                                                id="companyVATNumber"
                                                name="companyVATNumber"
                                                label="Company VAT Number"
                                                value={this.state.formData.companyVATNumber}
                                                error={formErrors && formErrors['companyVATNumber'] && true}
                                                helperText={formErrors && formErrors['companyVATNumber']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <TextField
                                                id="companyEORINumber"
                                                name="companyEORINumber"
                                                label="Company EORI Number"
                                                value={this.state.formData.companyEORINumber}
                                                error={formErrors && formErrors['companyEORINumber'] && true}
                                                helperText={formErrors && formErrors['companyEORINumber']}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                fullWidth
                                            />
                                        </form>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Linked Customer's
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteMultiSelect 
                                                    options={this.state.lists.customers} 
                                                    label='customers'
                                                    value={this.state.formData.customers}
                                                    onChange={this.handleMultiSelectChange('customers')}
                                                    error={formErrors && formErrors['customers'] && true}
                                                    errorText={formErrors && formErrors['customers']}
                                                />
                                            </FormControl>
                                        </Typography>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <Typography variant="h6">
                                            Account Information
                                        </Typography>
                                        <form noValidate autoComplete="off">
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteSelect 
                                                    options={this.state.vatList}
                                                    label='VAT *'
                                                    value={this.state.formData.vatRate}
                                                    onChange={this.handleSelectChange('vatRate')}
                                                    error={formErrors && formErrors['vatRate'] && true}
                                                    errorText={formErrors && formErrors['vatRate']}
                                                />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteSelect 
                                                    options={this.state.discountList}
                                                    label='Discount *'
                                                    value={this.state.formData.discountRate}
                                                    onChange={this.handleSelectChange('discountRate')}
                                                    error={formErrors && formErrors['discountRate'] && true}
                                                    errorText={formErrors && formErrors['discountRate']}
                                                />
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteSelect 
                                                    options={this.state.paymentTermsList}
                                                    label='Payment Term *'
                                                    value={this.state.formData.paymentTerm}
                                                    onChange={this.handleSelectChange('paymentTerm')}
                                                    error={formErrors && formErrors['paymentTerm'] && true}
                                                    errorText={formErrors && formErrors['paymentTerm']}
                                                />
                                            </FormControl>
                                            { ( this.state.formData.showCreditLimit && access.viewCreditLimit) && 
                                                <Grid container spacing={3} xs={12} style={{marginTop:'1em'}}>
                                                    <Grid item xs={12} lg={6}>
                                                        <TextField
                                                            name="creditLimitNet"
                                                            label="Credit Limit Net *"
                                                            value={this.state.formData.creditLimitNet}
                                                            error={formErrors && formErrors['CreditLimitNet'] && true}
                                                            helperText={formErrors && formErrors['CreditLimitNet']}
                                                            onChange={this.handleChange}
                                                            margin="none"
                                                            fullWidth
                                                            type='number'
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            InputProps={{
                                                                inputProps: { min: 0 },
                                                                startAdornment: (
                                                                <InputAdornment  position="start">
                                                                    £
                                                                </InputAdornment>
                                                                )
                                                            }}
                                                            disabled={!access.updateCreditLimit}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <TextField
                                                            name="creditLimitGross"
                                                            label="Credit Limit Gross"
                                                            value={this.state.formData.creditLimitGross || 0}
                                                            margin="none"
                                                            fullWidth
                                                            type='number'
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            InputProps={{
                                                                inputProps: { min: 0 },
                                                                startAdornment: (
                                                                <InputAdornment  position="start">
                                                                    £
                                                                </InputAdornment>
                                                                )
                                                            }}
                                                            disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                            } 
                                            <FormControl margin="normal" fullWidth>
                                                <AutoCompleteSelect 
                                                    options={this.state.accountStatusList}
                                                    label='Account Status *'
                                                    value={this.state.formData.accountStatus}
                                                    onChange={this.handleSelectChange('accountStatus')}
                                                    error={formErrors && formErrors['accountStatus'] && true}
                                                    errorText={formErrors && formErrors['accountStatus']}
                                                />
                                            </FormControl>
                                            {this.state.formData.accountStatus === 3 &&
                                             this.state.originalAccountStatus !== 3 &&
                                                <Textarea
                                                    label="Close Customer Notes *"
                                                    value={this.state.formData.closeCustomerNotes}
                                                    name="closeCustomerNotes"
                                                    onChange={this.handleChange}
                                                    rows={3}
                                                />
                                            }
                                            <Grid container item spacing={3}>
                                                <Grid item xs={12} lg={6}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <AutoCompleteSelect 
                                                            options={[
                                                                {value: 1, label: 'Yes'},
                                                                {value: 0, label: 'No'}
                                                            ]}
                                                            label='Monthly Service Plan *'
                                                            value={this.state.formData.monthlyServicePlan}
                                                            onChange={this.handleSelectChange('monthlyServicePlan')}
                                                            error={formErrors && formErrors['monthlyServicePlan'] && true}
                                                            errorText={formErrors && formErrors['monthlyServicePlan']}
                                                            noClear
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <FormControl margin="normal" fullWidth>
                                                        <AutoCompleteSelect 
                                                            options={[
                                                                {value: 1, label: 'Yes'},
                                                                {value: 0, label: 'No'}
                                                            ]}
                                                            label='Yearly Service Plan *'
                                                            value={this.state.formData.yearlyServicePlan}
                                                            onChange={this.handleSelectChange('yearlyServicePlan')}
                                                            error={formErrors && formErrors['yearlyServicePlan'] && true}
                                                            errorText={formErrors && formErrors['yearlyServicePlan']}
                                                            noClear
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <div className='buttonRow'>
                                                <BackButton props={this.props} />
                                                <Button onClick={
                                                            customerOriginal.cust_trading_name !== this.state.formData.tradingName ?
                                                                this.handleConfirmUpdateAddress :
                                                                this.handleConfirmationOpen
                                                            }
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={
                                                            (
                                                                this.state.formData.accountStatus === 3 &&
                                                                this.state.originalAccountStatus !== 3 &&
                                                                !this.state.formData.closeCustomerNotes
                                                            )
                                                        }
                                                    >
                                                    Update
                                                </Button>
                                            </div>
                                        </form>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                            {quoteDialog && 
                                <FullScreenDialog
                                    open={quoteDialog}
                                    onClose={this.handleFullScreenDialogClose}
                                    title="Quote"
                                    content={<ViewQuotation id={quoteData.quote_id} />}
                                />
                            }
                            {orderDialog &&
                                <FullScreenDialog
                                    open={orderDialog}
                                    onClose={this.handleFullScreenDialogClose}
                                    title="Order"
                                    content={<ViewOrder id={orderData.order_id} />}
                                />
                            }
                            {updateAddressDialog &&
                                <Dialog
                                    open={updateAddressDialog}
                                    maxWidth='md'
                                >
                                    <DialogTitle>Do you want the following address names to change from "{customerOriginal.cust_trading_name}" to  "{this.state.formData.tradingName}"</DialogTitle>
                                    <DialogContent> 
                                        {_.filter(
                                                        [
                                                            customerOriginal.registered_address,
                                                            ...customerOriginal.account_addresses,
                                                            ...customerOriginal.trading_addresses,
                                                            ...customerOriginal.site_addresses,
                                                        ], i => {
                                                            return i.address_name === customerOriginal.cust_trading_name
                                                        }
                                                    ).length === 0 ?
                                                <Typography 
                                                    variant='caption'
                                                    style={{marginTop: '1em'}}
                                                >
                                                    No address found with the name "{customerOriginal.cust_trading_name}".
                                                </Typography> :
                                                <>
                                                    <Typography variant='caption' style={{marginTop: '1em'}}>
                                                        Please tick the addresses you want to change
                                                    </Typography>
                                                </>
                                            }
                                        <List>
                                            {
                                                _.map(
                                                    _.filter(
                                                        [
                                                            customerOriginal.registered_address,
                                                            ...customerOriginal.account_addresses,
                                                            ...customerOriginal.trading_addresses,
                                                            ...customerOriginal.site_addresses,
                                                        ], i => {
                                                            return i.address_name === customerOriginal.cust_trading_name
                                                        }
                                                    ), i => 
                                                        <ListItem>
                                                            <ListItemAvatar>
                                                                <CheckBox
                                                                    color="primary"
                                                                    checked={this.state.formData.changeSites.includes(i.address_id)}
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            formData: {
                                                                                ...this.state.formData,
                                                                                changeSites: this.state.formData.changeSites.includes(i.address_id) ? 
                                                                                    this.state.formData.changeSites.filter(a => a !== i.address_id) : 
                                                                                    this.state.formData.changeSites.concat(i.address_id)
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                            </ListItemAvatar>
                                                            <ListItemText primary={`${i.address_type}`} secondary={`${i.address_line_one}, ${i.address_postcode}`} />
                                                        </ListItem>
                                                )
                                            }
                                        </List>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            color='primary'
                                            variant='contained'
                                            onClick={()=>{
                                                this.handleConfirmUpdateAddress();
                                                this.handleConfirmationOpen();
                                            }}
                                        >
                                            {this.state.formData.changeSites.length > 0 ? 'Update Addresses' : 'No Change'}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            }
                            <ReviewDialog 
                                customerId=                 {this.state.formData.customerId}
                                reviewQuotes=               {this.state.reviewQuotes}
                                reviewOrders=               {this.state.reviewOrders}
                                reviewDialog=               {this.state.reviewDialog}
                                handleReviewDialogClose=    {this.handleReviewDialogClose}
                                handleFullScreenDialog=     {this.handleFullScreenDialog}
                                success=                    {this.handleReviewDialogSuccess}
                                accountStatus={{
                                    oldStatus: this.state.originalAccountStatus,
                                    newStatus: this.state.formData.accountStatus
                                }}
                            />
                            <ConfirmationDialog 
                                open={this.state.confirmationOpen} 
                                success={this.handleConfirmationSuccess} 
                                close={this.handleConfirmationClose} 
                                title="Update Customer?" 
                                message="Are you sure you want to update this customer?"
                            />
                            <ConfirmationDialog 
                                open={this.state.confirmationNameOpen} 
                                success={this.handleConfirmationNameSuccess} 
                                close={this.handleConfirmationNameClose} 
                                title="Change Business Name" 
                                message="Name changes will not effect any existing order or quotations"
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarOpen}
                                onClose={this.handleSnackbarClose}
                                message='You have successfully update this customer'
                            />
                        </React.Fragment>
                    )}
                </Grid>
            );
        }
    }
}

export default UpdateCustomer;