import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import {colors} from 'Helpers/ColourHelper';

const styles = theme => ({
    td : {
        border: 'none'
    },
});

class ReturnItems extends PureComponent {
    
    render() {
        const { classes, formErrors, returnRows ,returnType, overallPrice, overallQuantity, handleAddRow, handleRemoveRow, handleSelectRowChange, handleRowChange, partList } = this.props;
        return (
            <form noValidate autoComplete="off">
                <Typography variant="h6">
                    Return Items
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.td}>Deduct Stock</TableCell>
                            <TableCell className={classes.td}>Part</TableCell>
                            {returnType === 'Credit' &&
                                <TableCell className={classes.td}>Nominal Code</TableCell>
                            }
                            <TableCell className={classes.td}>Quantity</TableCell>
                            {returnType === 'Credit' &&
                                <React.Fragment>
                                    <TableCell className={classes.td}>Unit Price</TableCell>
                                    <TableCell className={classes.td}>Total Price</TableCell>
                                </React.Fragment>
                            }
                            <TableCell className={classes.td}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {returnRows.map((item, idx) => {
                            let number = idx+1
                            return (
                                <React.Fragment key={idx}>
                                    <TableRow>
                                        <TableCell className={classes.td} style={{width:'7%'}}>
                                            <Checkbox
                                                name="deductStock"
                                                checked={item.deductStock}
                                                onChange={handleRowChange(idx)}
                                                color="primary"
                                                value="true"
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{width:returnType === 'Credit'? '20%':'30%'}}>
                                            <FormControl fullWidth>
                                                <AutoCompleteSelect 
                                                    label='Part'
                                                    options={partList} 
                                                    value={item.part}
                                                    error={formErrors && formErrors['returnRows|part|'+idx] && true}
                                                    errorText={formErrors && formErrors['returnRows|part|'+idx]}
                                                    onChange={handleSelectRowChange(idx, 'part')}
                                                />
                                            </FormControl>
                                        </TableCell>
                                        {returnType === 'Credit' &&
                                            <TableCell className={classes.td} style={{width:'20%'}}>
                                                <FormControl fullWidth>
                                                    <AutoCompleteSelect 
                                                        label='Nominal Code'
                                                        options={item.nominalList} 
                                                        value={item.nominalCode}
                                                        error={formErrors && formErrors['returnRows|nominalCode|'+idx] && true}
                                                        errorText={formErrors && formErrors['returnRows|nominalCode|'+idx]}
                                                        onChange={handleSelectRowChange(idx, 'nominalCode', 'returnRows')}
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            </TableCell>
                                        }
                                        <TableCell className={classes.td}>
                                            <TextField
                                                name="quantity"
                                                label="Quantity"
                                                value={item.quantity}
                                                error={formErrors && formErrors['returnRows|quantity|'+idx] && true}
                                                helperText={formErrors && formErrors['returnRows|quantity|'+idx]}
                                                onChange={handleRowChange(idx)}
                                                onBlur={handleRowChange(idx, 3)}
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                fullWidth
                                            />
                                        </TableCell>
                                        {returnType === 'Credit' &&
                                            <React.Fragment>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="unitPrice"
                                                        label="Unit Price"
                                                        value={item.unitPrice}
                                                        error={formErrors && formErrors['returnRows|unitPrice|'+idx] && true}
                                                        helperText={formErrors && formErrors['returnRows|unitPrice|'+idx]}
                                                        onChange={handleRowChange(idx)}
                                                        onBlur={handleRowChange(idx, 5)}
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.td}>
                                                    <TextField
                                                        name="totalPrice"
                                                        label="Total Price"
                                                        value={item.totalPrice}
                                                        error={formErrors && formErrors['returnRows|totalPrice|'+idx] && true}
                                                        helperText={formErrors && formErrors['returnRows|totalPrice|'+idx]}
                                                        onChange={handleRowChange(idx)}
                                                        onBlur={handleRowChange(idx, 2)}
                                                        type="number"
                                                        fullWidth
                                                        disabled
                                                    />
                                                </TableCell>
                                            </React.Fragment>
                                        }                                    
                                        <TableCell className={classes.td} style={{width: 100}}>
                                            {number > 1 &&
                                                <IconButton onClick={handleRemoveRow(idx)}>
                                                    <FALightIcon icon='times' noMargin button />
                                                </IconButton>
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{borderBottom: '1px solid #ddd'}}>
                                        <TableCell className={classes.td} style={{width: 100}}></TableCell>
                                        <TableCell className={classes.td} colSpan={returnType === 'Credit' ? 5 : 2}>
                                            <TextField
                                                name="returnDescription"
                                                label="Return Description"
                                                placeholder="Please enter the description of this return..."
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                value={item.returnDescription}
                                                error={formErrors && formErrors['returnRows|returnDescription|'+idx] && true}
                                                helperText={formErrors && formErrors['returnRows|returnDescription|'+idx]}
                                                multiline
                                                onChange={handleRowChange(idx)}
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td} style={{width: 100}}></TableCell>
                                    </TableRow>                                
                                </React.Fragment>
                            );
                        })}
                        <TableRow style={{backgroundColor:'#f2f2f2'}}>
                            <TableCell align="center" className={classes.td}>
                                <Typography variant="subtitle1">
                                    Totals
                                </Typography>
                            </TableCell>
                            <TableCell colSpan={returnType === 'Credit' ? 2 : 1}  className={classes.td}>  
                            </TableCell>
                            <TableCell className={classes.td}>
                                <TextField
                                    name="overallQuantity"
                                    value={overallQuantity}
                                    type="number"
                                    disabled
                                />
                            </TableCell>
                            {returnType === 'Credit' &&
                                <React.Fragment>
                                    <TableCell className={classes.td}></TableCell>
                                    <TableCell className={classes.td}>
                                        <TextField
                                            name="quantityPrice"
                                            value={overallPrice}
                                            type="number"
                                            disabled
                                        />
                                    </TableCell>
                                </React.Fragment>
                            }
                            <TableCell colSpan="2" className={classes.td}></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                {formErrors && formErrors['returnRows'] && formErrors['returnRows'].split('\n').map((item, key) => {
                    return (
                        <React.Fragment key={key}>
                            <Typography component={"div"} style={{color: colors.red}}>
                                {item}<br/>
                            </Typography>
                        </React.Fragment>
                    )
                })}
                <div style={{marginTop: 20}}>
                    <Button onClick={handleAddRow}
                            variant="outlined"
                            color="primary">
                        Add Row
                    </Button>
                </div>
            </form>
        );
    }
}

export default withStyles(styles)(ReturnItems);
    