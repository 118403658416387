import React, { PureComponent } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AccessTimesForm from '../AccessTimes/AccessTimesForm';

class AccessTimes extends PureComponent {
    render() {
        const {formData, formErrors, handleAddAccessTimes, handleDayChange, handleTimeChange, handleAddAccessTimeRow, handleRemoveRow, addAccessTimes, addressIdx, update} = this.props;
        return(
            <React.Fragment>
                <Typography variant="body1" style={{marginTop:30, fontSize:'1.1rem'}}>
                    {update ? 'Access Times' : 'Add Access Times?'}
                </Typography>
                {!update && (
                    <RadioGroup
                        name="addAccessTimes"
                        onChange={handleAddAccessTimes}
                        row
                    >
                        <FormControlLabel
                            value={true}
                            control={<Radio color="primary" />}
                            label="Yes"
                            labelPlacement="end"
                            checked={addAccessTimes ? true : false}
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label="No"
                            labelPlacement="end"
                            checked={!addAccessTimes ? true : false}
                        />
                    </RadioGroup>
                )}  
                {(addAccessTimes || update)  && (
                    <Grid container spacing={3} style={{marginTop:20}}>

                        {formData.accessTimes && formData.accessTimes.length > 0 ?
                            <React.Fragment>
                                <Grid item lg={4}>
                                    <Typography variant="body1">Day</Typography>
                                </Grid>
                                <Grid item lg={4}>
                                    <Typography variant="body1" style={{marginLeft:'-15px'}}>Start</Typography>
                                </Grid>
                                <Grid item lg={4}>
                                    <Typography variant="body1" style={{marginLeft:'-30px'}}>End</Typography>
                                </Grid>
                                {formData.accessTimes.map((accessTime, idx) => (
                                    <AccessTimesForm
                                        key={idx}
                                        idx={idx}
                                        addressIdx={addressIdx}
                                        data={accessTime}
                                        handleTimeChange={handleTimeChange}
                                        handleDayChange={handleDayChange}
                                        handleRemoveRow={handleRemoveRow}
                                        formErrors={formErrors}
                                        update={update}
                                    />
                                ))}
                            </React.Fragment>
                            :
                            <Grid item lg={12}>
                                <Typography variant="body1">No Access Times</Typography>
                            </Grid>
                        }
                        <Grid item lg={12}>
                            <Button variant="outlined" color="primary" onClick={() => handleAddAccessTimeRow(addressIdx)}>Add row</Button>
                        </Grid>
                    </Grid>
                )}
            </React.Fragment>
        );
    }
}

export default AccessTimes;