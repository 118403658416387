import React, { PureComponent } from 'react';
import { connect }              from 'react-redux';

import FormControl  from '@material-ui/core/FormControl';
import Grid         from '@material-ui/core/Grid';
import Typography   from '@material-ui/core/Typography';

import {colors} from 'Helpers/ColourHelper';
import icons    from 'Helpers/IconHelper';

import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import AddNewContactForm  from 'Components/Sales/Order/AddNewContactForm';
import _                  from 'lodash';

import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
class CustomerDeliveryDetails extends PureComponent {
    
    render() {
        const { formErrors, deliveryCustomerEmail, deliveryCustomerPhone, deliveryCustomerMobile, deliveryCustomerContact, deliveryCustomerAddress, deliveryCustomerAddressType, handleSelectChange, lists, handleNewContact  } = this.props;
        return (
            <form noValidate autoComplete="off">
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        Delivery Customer
                    </Typography>
                    <FormControl fullWidth margin="normal">
                        <AutoCompleteSelect 
                            options={[
                                {value: 'Registered', label: 'Registered'},
                                {value: 'Accounts', label: 'Accounts'},
                                {value: 'Trading', label: 'Trading'},
                                {value: 'Site', label: 'Site'}
                            ]} 
                            label='Address Type'
                            onChange={handleSelectChange('deliveryCustomerAddressType')}
                            value={deliveryCustomerAddressType}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <AutoCompleteSelect 
                            options={lists.customerAddressList} 
                            label='Delivery Address *'
                            value={deliveryCustomerAddress}
                            onChange={handleSelectChange('deliveryCustomerAddress')}
                            error={formErrors && formErrors['deliveryCustomerAddress'] && true}
                            errorText={formErrors && formErrors['deliveryCustomerAddress']}
                        />
                    </FormControl>
                    <Grid container xs={12} style={{alignItems: 'flex-end'}}>
                        <Grid item xs={!!handleNewContact ? 11 : 12}>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect 
                                    options={_.uniqBy(lists.customerContactList, 'value')}
                                    label='Delivery Contact *'
                                    value={deliveryCustomerContact}
                                    onChange={handleSelectChange('deliveryCustomerContact')}
                                    error={formErrors && formErrors['deliveryCustomerContact'] && true}
                                    errorText={formErrors && formErrors['deliveryCustomerContact']}
                                />
                            </FormControl>
                        </Grid>
                        {!!handleNewContact &&
                            <Grid item style={{marginLeft: 'auto'}}>
                                <AllIcon icon={icons.addContact} size='large' noMargin onClick={()=>this.props.deployDialog(
                                        <AddNewContactForm
                                            customer={this.props.customer}
                                            site=    {deliveryCustomerAddress}         
                                            callBack={handleNewContact}        
                                        />,
                                        'Add Contact',
                                        'md'
                                    )}
                                    disabled={!deliveryCustomerAddress || !deliveryCustomerAddressType}
                                />
                            </Grid>
                        }
                    </Grid>
                    
                </Grid>
                <br></br>
                <Grid container item spacing={3}>
                    <Grid item lg={3}>
                        <Typography variant="subtitle1">
                            Phone Number
                        </Typography>
                    </Grid>
                    <Grid item lg={9}>
                        <Typography variant="subtitle1">
                            {deliveryCustomerPhone && deliveryCustomerMobile ? deliveryCustomerPhone + ' / ' + deliveryCustomerMobile: (deliveryCustomerPhone ? deliveryCustomerPhone : deliveryCustomerMobile)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item lg={3}>
                        <Typography variant="subtitle1">
                            Email Address *
                        </Typography>
                    </Grid>
                    <Grid item lg={9}>
                        <Typography variant="subtitle1">
                            {deliveryCustomerEmail}
                        </Typography>
                    </Grid>
                     {formErrors && formErrors['customerDeliveryContactEmail'] ?
                        (
                            <Grid item lg={12}>
                                <Typography component={"div"} style={{color: colors.red, fontSize: '0.75rem'}}>
                                    {formErrors['customerDeliveryContactEmail']}
                                </Typography>
                            </Grid>
                        )
                    :
                        (deliveryCustomerContact != '' && deliveryCustomerEmail == '-' &&
                            (<Grid item lg={12}>
                                <Typography component={"div"} style={{color: colors.red, fontSize: '0.75rem'}}>
                                Delivery Email Address cannot be empty
                                </Typography>
                            </Grid>)
                        )
                    }
                </Grid>
            </form>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, size) => {
            dispatch(deployDialog(content, title, null, size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        },
    }
}

export default connect(null, mapDispatchToProps)(CustomerDeliveryDetails);

    