import React, {Component}        from 'react';
import { Redirect }              from 'react-router-dom';
import API                       from 'API';
import { connect }               from 'react-redux';
import moment                    from 'moment';
import { MAX_UPLOAD_LIMIT_SIZE } from 'Constants';

import { Typography, Grid, Button, Table, TableCell, TableHead, TableRow, TableBody, FormControlLabel, FormControl, Switch,
         TextField, Dialog, DialogActions, DialogTitle, DialogContent, Radio, RadioGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import FALightIcon        from 'Components/Common/Icons/FontAwesome/FALightIcon';
import DatePicker         from 'Components/Common/DatePickers/DatePicker';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';
import DragFileInput      from 'Components/Common/Inputs/DragFileInput';
import PartsPurchaseOrder from 'Components/PartsOrdering/PartsPurchaseOrder';
import DeliverySchedule   from 'Components/PartsOrdering/DeliverySchedule';
import BackButton         from 'Components/Common/Buttons/BackButton';
import Textarea           from 'Components/Common/Inputs/Textarea';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';

import {colors}                   from 'Helpers/ColourHelper';
import { pdfFromBase64 }          from 'Helpers/PDFHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    poAlign: {
        marginLeft: '1.01em'
    },
    heading: {
        paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        },
        poAlign: {
            marginLeft: 0
        },
    },
});

const SwitchColorToggle = withStyles({
    switchBase: {
        color: colors.red,
        '&$checked': {
            color: colors.green,
        },
        '&$checked + $track': {
            backgroundColor: colors.green,
        },
    },
    checked: {},
    track: {},
})(Switch);

const initialState = () => ({
    formData: {
        deliveries: [],
        notes: '',
        file: ''
    },
    ppo: {},
    formErrors: [],
    showPPO: false,
    showDS: false,
    confirmationOpen: false,
    dialogConfirmationTable: '',
    receivedQty: false,
    deliveryDate: false,
    disableUpdateButton: true,
    disableConfirmationSuccess: false,
    snackbarOpen: false,
    redirect: false,
    successRedirect: false,
    successMsg: '',
    path: '',
    isLoading: true
});

class UpdatePartsOrderDelivery extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `UpdatePartsOrder:Delivery:${this.props.match.params.id}`;
        this.state              = this.getInitialState(initialState());
        this.timeout            = false;
    }

    componentDidMount(){
        this.getPPO();
    }

    componentWillUnmount = () => {
        if(this.timeout) 
            clearTimeout(this.timeout)
    }

    getPPO = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.get('/partsOrdering/' + this.props.match.params.id)
            .then(result => {
                if (result.data.errors) {
                    this.setState({ redirect: true });
                } else if(result.data) {
                    let deliveries = [];
                    result.data.parts_order_details.forEach((el, idx) => {
                        if(el.ppo_detail_part_id > 0){
                            let remainderStock = (el.received_total_qty - el.original_part_quantity).toFixed(2);
                            let scheduleStockStatus ='';
                            switch(el.ppo_detail_schedule_stock_status){
                                case 'Exact':
                                    scheduleStockStatus = <span style={{color:colors.green}}>Exact</span>;
                                break;
                                case 'Under':
                                    scheduleStockStatus = <span style={{color:'orange'}}>Under ({remainderStock})</span>;
                                break;
                                case 'Over':
                                    scheduleStockStatus = <span style={{color:colors.red}}>Over ({remainderStock})</span>;
                                break;
                                default:
                                break;
                            }

                            this[`dateRef${idx}`] = React.createRef();

                            deliveries.push({
                                detailId: el.ppo_detail_id,
                                partId: el.ppo_detail_part_id,
                                partNumber: el.ppo_detail_part_number,
                                partDescription: el.ppo_detail_description,
                                quantity: el.ppo_detail_qty,
                                unitPrice: el.ppo_detail_unit_price,
                                totalPrice: el.ppo_detail_total_price,
                                deliveryStatus: el.ppo_detail_schedule_delivery_status,
                                stockStatus: el.ppo_detail_schedule_stock_status,
                                deliverySchedule: el.delivery_schedule,
                                receivedDeliveries: el.received_deliveries,
                                receivedTotalQty: el.received_total_qty,
                                scheduleTotalQty: el.schedule_total_qty,
                                scheduleStockStatus: scheduleStockStatus,
                                originalPartQuantity: el.original_part_quantity,

                                receivedQty: '',
                                receivedDate: null,
                                deliveryComplete: false,
                                amendPO: null,
                                openDatepicker: false
                            });
                        }
                    });
                    this.setState({
                        formData: {
                            ...this.state.formData,
                            deliveries: deliveries,
                            amendPO: null
                        },
                        ppo: result.data,
                        isLoading: false
                    });
                }
            });
        });
    }

    downloadPPO = (ppoId) => {
        API.get('/partsOrdering/' + ppoId + '/pdfPartsOrder')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
    downloadDS = (ppoId) => {
        API.get('/partsOrdering/' + ppoId + '/pdfDeliverySchedule')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
    viewPPO = () => {
        this.setState(prevState => ({
            showPPO:    !prevState.showPPO,
            showDS:     false
        }),()=>this.savePageState());
    }
    viewDS = () => {
        this.setState(prevState => ({
            showDS:     !prevState.showDS,
            showPPO:    false
        }),()=>this.savePageState());
    }

    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleRowChange = (idx, name) => e => { 
        const { value } = e.target;
        let newDeliveries =  [...this.state.formData.deliveries];
        newDeliveries[idx] = {
            ...newDeliveries[idx],
            [name]: value
        };

        // Dialog button disabled if an amend option is not selected
        let disableConfirmationSuccess = value === null ? true : false;
        
        this.setState({
            formData: {
                ...this.state.formData,
                deliveries: newDeliveries
            },
            disableConfirmationSuccess
        });
    }

    validateForm = () => {
        const { receivedQty, deliveryDate, formData } = this.state;
        let disableUpdateButton = true;

        // If any delivery is marked as complete, the update button will not be disabled
        formData.deliveries.forEach(e => {
            if (e.deliveryComplete) {
                disableUpdateButton = false;
            }
        });

        if (receivedQty && deliveryDate) {
            this.setState({ disableUpdateButton: false });
        } else {
            this.setState({ disableUpdateButton });
        }
    }

    handleCheckChange = (idx, name) => e => {
        const { checked } = e.target;
        let newDeliveries =  [...this.state.formData.deliveries];
        newDeliveries[idx] = {
            ...newDeliveries[idx],
            [name]: checked,
            amendPO: null
        };
        this.setState({
            formData: {
                ...this.state.formData,
                deliveries: newDeliveries,
            }
        }, () => {
            this.validateForm();
        });
    };

    handleDecimalChange = (idx, decimals) => e => {
        const { name, value } = e.target;
        let newDeliveries =  [...this.state.formData.deliveries];
        let newVal = decimals ? parseFloat(value).toFixed(decimals) : value;
        let receivedQty = value > 0 ? true : false;

        newDeliveries[idx] = {
            ...newDeliveries[idx],
            [name]: newVal
        };

        this.setState({
            formData: {
                ...this.state.formData,
                deliveries: newDeliveries
            },
            receivedQty
        }, () => {
            this.validateForm();
        });
    };

    handleDateChange = idx => date => {

        let newDeliveries =  [...this.state.formData.deliveries];
        let deliveryDate = date !== null ? true : false;
        newDeliveries[idx] = {
            ...newDeliveries[idx],
            receivedDate: date,
            openDatepicker: false
        };
        this.setState({
            formData: {
                ...this.state.formData,
                deliveries: newDeliveries
            },
            deliveryDate
        }, () => {
            this.validateForm();
        });
    };

    handleConfirmationOpen = (e) => {

        let disableConfirmationSuccess = false;
        let showConfirmationTable = false;
        this.state.formData.deliveries.forEach((val, idx) => {

            let receivedTotalQty    = parseFloat(val.receivedTotalQty);
            let receivedQty         = parseFloat(val.receivedQty);
            let quantity            = parseFloat(val.quantity);

            let new_total_qty = receivedTotalQty;
            if((receivedQty !== null && val.receivedDate !== null) || val.deliveryComplete === 1){
                
                if(receivedQty) {
                    new_total_qty = receivedTotalQty + receivedQty;
                }
                if(new_total_qty > quantity || (new_total_qty === quantity && !val.deliveryComplete) || (new_total_qty < quantity && val.deliveryComplete)){
                    disableConfirmationSuccess = true;
                }
                if(val.amendPO !== null) {
                    disableConfirmationSuccess = false;
                }
                showConfirmationTable = true;
            }
        });

        this.setState({
            confirmationOpen: true,
            disableConfirmationSuccess,
            showConfirmationTable
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    submit = () => {
        let newDeliveries =  [...this.state.formData.deliveries];
        newDeliveries.forEach((val,idx) => {
            newDeliveries[idx] = {
                ...newDeliveries[idx],
                receivedDate: val.receivedDate !== null ? (moment(val.receivedDate).format('DD/MM/YYYY')) : null
            };
        });
        const formData = {
            ...this.state.formData,
            deliveries: newDeliveries
        }

        let newFormData = new FormData();
        Object.keys(formData).forEach(key => {
            if(key === 'deliveries'){
                newFormData.append(key, JSON.stringify(formData[key]))
            }
            else{
                newFormData.append(key, formData[key])
            }
        });

        API.post(`/partsOrdering/${this.props.match.params.id}/delivery`, newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            }
            else {
                if(result.data.full_reference){
                    this.setState({
                        successRedirect: true,
                        path: '/parts-order/invoice',
                        successMsg: 'You have successfully completed supplier stock return delivery ' + result.data.full_reference
                    });
                } 
                else if(result.data.ppo_id){
                    this.props.history.push('/parts-order/delivery/update/' + result.data.ppo_id);
                    window.location.reload();
                }
            }
            this.props.scrollToTop();
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false }); 
    };

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    tabFromQty = idx => (e) => {
        if(e.keyCode && e.keyCode === 9){
            e.preventDefault();
            this.setIsOpen(true, idx, () => {
                document.getElementById("receivedDate" + idx).focus();
            });
        }
    }
    
    setIsOpen = (val, idx, callback = null) => {
        this.timeout = setTimeout(() => {
            let deliveries =  [...this.state.formData.deliveries];
            deliveries[idx] = {
                ...deliveries[idx],
                openDatepicker: val
            };
            this.setState({
                formData: {
                    ...this.state.formData,
                    deliveries: deliveries
                }
            }, () => {
                if(typeof callback === 'function') 
                    callback();
            });
        }, 100)
    }

    clearFile = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                file: initialState().formData.file
            }
        });
    };
    
    render() {
        const { classes } = this.props;
        const { ppo, formData, formErrors, isLoading } = this.state;
        if (this.state.successRedirect === true) {
            return <Redirect 
                        to={{
                            pathname: this.state.path,
                            state: {successMsg: this.state.successMsg},
                        }}
                    />
        } else if (this.state.redirect === true) {
            return <Redirect to="/parts-order/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" >
                            Update Parts Order Delivery
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                {(isLoading && (
                                    <LoadingCircle />                 
                                )) || (
                                    <React.Fragment>
                                        <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25}}>
                                            {ppo.full_reference}
                                        </Typography>
                                        <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                            Purchase Order (v{parseInt(ppo.ppo_number_amendment)})<br className='xs-only' />
                                            <Button variant="outlined"
                                                    color="default"
                                                    size="small"
                                                    className={classes.btn}
                                                    onClick={this.viewPPO}
                                                    style={{marginLeft:10}}
                                            >                                    
                                                <FALightIcon icon='search' />
                                                {this.state.showPPO ? 'Hide' : 'View'}
                                            </Button>
                                            <Button variant="outlined"
                                                    color="default"
                                                    size="small"
                                                    className={classes.btn}
                                                    onClick={() => this.downloadPPO(ppo.ppo_id)}
                                            >
                                                <FALightIcon icon='download' />
                                                Download
                                            </Button> 
                                        </Typography>
                                        <br className='xs-only' />
                                        <Typography variant="body1" align="center" gutterBottom style={{marginBottom:40}}>
                                            Delivery Schedule (v{parseInt(ppo.ppo_schedule_version)})<br className='xs-only' />
                                            <Button variant="outlined"
                                                    color="default"
                                                    size="small"
                                                    onClick={this.viewDS}
                                                    className={classes.btn}
                                                    style={{marginLeft:10}}
                                            >
                                                <FALightIcon icon='search' />
                                                {this.state.showDS ? 'Hide' : 'View'}
                                            </Button>
                                            <Button variant="outlined"
                                                    color="default"
                                                    size="small"
                                                    className={classes.btn}
                                                    onClick={() => this.downloadDS(ppo.ppo_id, parseInt(ppo.ppo_schedule_version))}
                                            >                                            
                                                <FALightIcon icon='download' />
                                                Download
                                            </Button> 
                                        </Typography>
                                        { this.state.showPPO ? <PartsPurchaseOrder ppo={ppo} /> : null }
                                        { this.state.showDS ? <DeliverySchedule ppo={ppo} /> : null }
                                        <form noValidate autoComplete="off">
                                            {formData.deliveries && formData.deliveries.map((ssrd, idx) => (
                                                <div key={idx} style={{maxWidth: '100%', margin: 12, border: '1px solid #ddd', overflowY: 'hidden', overflowX:'auto'}}>
                                                    <div style={{margin: 12, minWidth: 900, fontSize: '0.8125rem', display:(ssrd.deliveryStatus !== 'Completed'?'':'none')}}>
                                                        <Grid container item spacing={3} style={{backgroundColor:'#0282C6', color:'white'}}>
                                                            <Grid item xs={6}>
                                                                {ssrd.partNumber + ' - ' + ssrd.partDescription}
                                                            </Grid>
                                                            <Grid item xs={6} align="right">
                                                                {ssrd.deliveryStatus}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container item spacing={3} style={{marginTop:8}}>
                                                            <React.Fragment>
                                                                <Grid item xs={3} style={{backgroundColor:'#f5f5f5', textAlign:'right'}}>
                                                                    Schedule
                                                                </Grid>
                                                                <Grid item xs={6} style={{backgroundColor:'#f5f5f5', textAlign:'right'}}>
                                                                    Received Deliveries
                                                                </Grid>
                                                                <Grid item xs={3} style={{backgroundColor:'#f5f5f5', textAlign:'right'}}></Grid>
                                                            </React.Fragment>
                                                            <React.Fragment>
                                                                <Grid item xs={3} style={{textAlign:'right',borderRight: '1px solid #ddd'}}>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={6} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                            <b>Quantity</b>
                                                                        </Grid>
                                                                        <Grid item xs={6} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                            <b>Delivery Date</b>
                                                                        </Grid>
                                                                        {ssrd.deliverySchedule && ssrd.deliverySchedule.map((ds, idx) => (
                                                                            <React.Fragment key={idx}>
                                                                                <Grid item xs={6} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                                    {parseFloat(ds.schedule_quantity).toFixed(2)}
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                                    {ds.schedule_delivery_date === '0000-00-00' ? 'TBC' : moment(ds.schedule_delivery_date).format('DD/MM/YYYY')}
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        ))}
                                                                        <Grid item xs={6} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                            <b>{ssrd.scheduleTotalQty}</b>
                                                                        </Grid>
                                                                        <Grid item xs={6} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={9}>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                            <b>Quantity</b>
                                                                        </Grid>
                                                                        <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                            <b>Delivery Date</b>
                                                                        </Grid>
                                                                        <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}></Grid>
                                                                        {ssrd.receivedDeliveries && ssrd.receivedDeliveries.map((deliveries, didx) => (
                                                                            <React.Fragment key={`rd-${didx}`}>
                                                                                <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                                    {parseFloat(deliveries.delivery_received_quantity).toFixed(2)}
                                                                                </Grid>
                                                                                <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                                    {moment(deliveries.delivery_received_date).format('DD/MM/YYYY')}
                                                                                </Grid>
                                                                                <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                                    Schedule (v{deliveries.delivery_received_schedule_version})
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        ))}
                                                                        {ssrd.deliveryStatus !== 'Completed' &&
                                                                            <React.Fragment>
                                                                                <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                                    <FormControl margin="normal">
                                                                                        <TextField
                                                                                            name="receivedQty"
                                                                                            value={this.state.formData.deliveries[idx].receivedQty}
                                                                                            error={formErrors && formErrors['deliveries|received|'+ssrd.partId] && true}
                                                                                            helperText={formErrors && formErrors['deliveries|received|'+ssrd.partId]}
                                                                                            onChange={this.handleDecimalChange(idx)}
                                                                                            onBlur={this.handleDecimalChange(idx, 3)}
                                                                                            type="number"
                                                                                            inputProps={{ min: 0 }}
                                                                                            onKeyDown={this.tabFromQty(idx)}
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                                <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                                    <DatePicker
                                                                                        type="receivedDate"
                                                                                        id={'receivedDate' + idx}
                                                                                        name="receivedDate"
                                                                                        value={this.state.formData.deliveries[idx].receivedDate}
                                                                                        errorText={formErrors && formErrors['deliveries|received|'+ssrd.partId]}
                                                                                        onChange={this.handleDateChange(idx)}
                                                                                        autoOk={true}
                                                                                        disableFuture={true}
                                                                                        open={this.state.formData.deliveries[idx].openDatepicker}
                                                                                        onOpen={() => this.setIsOpen(true, idx)}
                                                                                        onClose={() => this.setIsOpen(false, idx)}
                                                                                        ref={this[`dateRef${idx}`]}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                                    <FormControl margin="normal">
                                                                                        <FormControlLabel
                                                                                            control={
                                                                                                <SwitchColorToggle
                                                                                                    name="deliveryComplete"
                                                                                                    checked={this.state.formData.deliveries[idx].deliveryComplete}
                                                                                                    onChange={this.handleCheckChange(idx, 'deliveryComplete')}
                                                                                                    value="1"
                                                                                                />
                                                                                            }
                                                                                            label="Delivery Complete?"
                                                                                        />
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </React.Fragment>
                                                            <React.Fragment>
                                                                <Grid item xs={3} style={{backgroundColor:'#f5f5f5'}}>
                                                                    <span style={{float:'left'}}>Order Total</span> <span style={{float:'right'}}>{ssrd.quantity}</span>
                                                                </Grid>
                                                                <Grid item xs={3} style={{backgroundColor:'#f5f5f5', textAlign:'right'}}>
                                                                    {parseFloat(ssrd.receivedTotalQty).toFixed(2)}
                                                                </Grid>
                                                                <Grid item xs={6} style={{backgroundColor:'#f5f5f5', textAlign:'right'}}>
                                                                    <b>{ssrd.scheduleStockStatus}</b>
                                                                </Grid>
                                                            </React.Fragment>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            ))}
                                            {formErrors && formErrors['part_locked'] &&
                                                <Grid container xs={12} style={{paddingTop:'1em'}}>
                                                    <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>
                                                        Some parts are locked, please try again later.
                                                    </Grid>
                                                </Grid>
                                            }
                                            <div className='buttonRow'>
                                                {formErrors && formErrors['general'] && formErrors['general'].split('\n').map((item, key) => {
                                                    return (
                                                        <React.Fragment key={key}>
                                                            <Typography component={"div"} style={{color: colors.red}}>
                                                                {item}<br/>
                                                            </Typography>
                                                        </React.Fragment>
                                                    )
                                                })}
                                                <BackButton props={this.props} />
                                                    <Button 
                                                        onClick={this.handleConfirmationOpen}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={this.state.disableUpdateButton}
                                                    >
                                                        Update
                                                    </Button>
                                            </div>
                                        </form>
                                    </React.Fragment>
                                )}
                            </PaddedPaper>
                        </Grid>
                </Grid>
                <Dialog 
                    open={this.state.confirmationOpen} 
                    onClose={this.handleConfirmationClose} 
                    fullWidth={true} 
                    maxWidth="md" 
                    scroll="body"
                    disableBackdropClick	
                    disableEscapeKeyDown
                >
                        <DialogTitle id="form-dialog-title" color="secondary">Are You Sure You Want To Update This Delivery?</DialogTitle>
                        <DialogContent>
                            {formData.deliveries && this.state.showConfirmationTable && 
                                <Table>
                                    <TableHead>
                                        <TableRow style={{backgroundColor:'#f2f2f2'}}>
                                            <TableCell>Part</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>Delivery Status</TableCell>
                                            <TableCell>Amend PO?</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {formData.deliveries.map((val, idx) => {
                                        return (
                                            <ConfirmationTableRow 
                                                key={idx}
                                                val={val}
                                                idx={idx}
                                                handleRowChange={this.handleRowChange}
                                            />
                                        )
                                    })}
                                    </TableBody>
                                </Table>
                            }
                            <DragFileInput
                                id="file"
                                name="file"
                                label="Delivery Note"
                                file={formData.file}
                                errorText={formErrors && formErrors['file']}
                                onChange={this.handleFileChange}
                                cancelOnClick={this.clearFile}
                                emptyText='No file selected'
                            />
                            <Textarea
                                id="notes"
                                name="notes"
                                label="Notes"
                                value={this.state.formData.notes}
                                rows={5}
                                error={formErrors && formErrors['notes']}
                                onChange={this.handleChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Typography variant="subtitle2" style={{flex: 1}}>
                                <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                            </Typography>
                            <Button onClick={this.handleConfirmationClose} variant="outlined" color="default">Cancel</Button>
                            <Button 
                                onClick={this.handleConfirmationSuccess} 
                                color="primary" 
                                variant="contained"
                                disabled={this.state.disableConfirmationSuccess}
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarOpen}
                        onClose={this.handleSnackbarClose}
                        message='You have successfully updated this parts order return delivery'
                    />
                </Grid>
            );
        }
    }
}

class ConfirmationTableRow extends Component {
    render () {
        const { val, idx, handleRowChange } = this.props;

        let receivedTotalQty    = parseFloat(val.receivedTotalQty);
        let receivedQty         = parseFloat(val.receivedQty);
        let quantity            = parseFloat(val.quantity);
        let new_total_qty       = receivedTotalQty;

        if((receivedQty !== null && val.receivedDate !== null) || val.deliveryComplete === 1){

            if(receivedQty) {
                new_total_qty = receivedTotalQty + receivedQty;
            }
            let status = 'Exact';
            let colour = colors.green;
            if(new_total_qty > quantity){
                status = 'Over';
                colour = colors.red;
            } else if(new_total_qty < quantity){
                status = 'Under';
                colour = 'orange';
            }

            return(
                <TableRow>
                    <TableCell>{val.partNumber}</TableCell>
                    <TableCell style={{color:colour}}>{new_total_qty +' / '+ quantity}</TableCell>
                    {(quantity === new_total_qty || new_total_qty > quantity) && val.deliveryComplete === false ?
                            <TableCell>In Progress <br></br><small style={{color:colors.red}}> Should this be marked as complete?</small></TableCell>
                        :
                        (val.deliveryComplete === false ?
                            <TableCell>In Progress</TableCell>
                            :
                            <TableCell>Complete</TableCell>)
                    }
                    <TableCell>
                    {val.deliveryComplete && (status === 'Over' || status === 'Under') ? 
                        <RadioGroup
                            name="amendPO"
                            value={val.amendPO || ''}
                            onChange={handleRowChange(idx, 'amendPO')}
                            row
                        >
                            <FormControlLabel
                                value="Yes"
                                control={<Radio color="primary" />}
                                label="Yes"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="No"
                                control={<Radio color="primary" />}
                                label="No"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                        :
                        '-'
                    }
                    </TableCell>
                </TableRow>
            )
        }
        else {
            return null;
        }
    }
    
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)               => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)        => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpdatePartsOrderDelivery));