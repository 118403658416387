import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import TextField from '@material-ui/core/TextField';
import API from '../../../API';
import Button from '@material-ui/core/Button';
import CiDataTable from '../../Common/DataTables/CiDataTable';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import SnackBar from '../../Common/SnackBars/SnackBar';
import AdditionalInviteDialog from './../AdditionalInviteDialog/AdditionalInviteDialog';
import _ from 'lodash';
import Chip from '@material-ui/core/Chip';
import { SHORTLINK_BASE_URL } from '../../../Constants';

import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    searchResults: {},
    searchString: '',
    dateFrom: null,
    dateTo: null,
    access: {
        updateEvent: false,
        finishMeeting: false
    },
    finishMeetingConfirmationOpen: false,
    finishMeetingSnackbarOpen: false,
    finishMeetingId: null,
    additionalInvite: {
        id: null,
        name: null,
        open: false
    },
    isLoading: true
});

class MeetingSearch extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Meeting:Search';
        this.state              = this.getInitialState(initialState());
    }
    componentDidMount(){
        !this.hasPageState() && this.getSearchData();
    }
    getSearchData = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/meetings/all', {
                props: {
                    cancellation: true
                },
                params: {
                    searchString: this.state.searchString
                }
            }).then(result => {
                if(result?.data) {
                    result.data.forEach(meeting => {
                        meeting.status = '';
                        meeting.status_value = '';
                        if(meeting.meeting_status === 'Active') {
                            meeting.status_value = 'Active';
                            meeting.status = <Chip size="small" label='Active' style={{color:'white', backgroundColor:'#5da14d'}} />
                        }
                        else {
                            meeting.status_value = 'Finished';
                            meeting.status = <Chip size="small" label='Finished' style={{color:'white', backgroundColor:'#d14039'}} />
                        }
                    });
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    },()=> {
                        this.savePageState();
                    });
                } else {
                    /* Error */
                }
            });
        })
    }
    finishMeeting() {
        API.post(`/meetings/${this.state.finishMeetingId}/finish`)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0) {
                alert('Unable to finish meeting');
            } else {
                let rowIndex = this.state.searchResults.findIndex(x => x.meeting_id === this.state.finishMeetingId);
                let newSearchResults = [...this.state.searchResults];
                newSearchResults[rowIndex].status = <Chip size="small" label='Finished' style={{color:'white', backgroundColor:'#d14039'}} />
                newSearchResults[rowIndex].meeting_status = 'Finished';
                this.setState({
                    ...this.state,
                    finishMeetingSnackbarOpen: true,
                    searchResults: newSearchResults
                }, () => { this.savePageState(); });
            }
        });
    }
    handleDateChange = name => date => {
        this.setState({
            [name]: date
        }, 
        () => {
            this.getSearchData();
        });
    };
    onSearchStringChange = (event) => {
        this.setState({
            searchString: event.target.value
        },
        () => {
            this.getSearchData();
        });
    }
    resetSearch = () => {
        this.setState({
            searchString: '',
            dateFrom: null,
            dateTo: null
        }, 
        () => {
            this.getSearchData();
        });
    }
    handleFinishMeetingSnackbarClose = () => {
        this.setState({
            finishMeetingSnackbarOpen: false
        });
    };
    handleFinishMeetingConfirmationOpen = rowData => {
        this.setState({
            finishMeetingConfirmationOpen: true,
            finishMeetingId: rowData.meeting_id
        });
    };
    handleFinishMeetingConfirmationClose = () => {
        this.setState({ 
            finishMeetingConfirmationOpen: false 
        });
    };
    handleFinishMeetingConfirmationSuccess = () => {
        this.setState({ 
            finishMeetingConfirmationOpen: false 
        });
        this.finishMeeting();
    }
    viewMeeting = (eventId) => {
        this.setState({
            viewMeetingId: eventId,
            viewMeetingOpen: true
        });
    }
    viewMeetingClose = () => {
        this.setState({
            viewMeetingOpen: false
        });
    }
    openAdditionalInviteDialog = meeting => {
        this.setState({
            additionalInvite: {
                id: meeting.meeting_id,
                name: meeting.meeting_name,
                open: true
            }
        });
    }
    closeAdditionalInviteDialog = () => {
        this.setState({
            additionalInvite: {
                id: null,
                name: null,
                open: false
            }
        });
    }
    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Meeting Search
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <TextField id="keywordSearch" label="Keywords" fullWidth value={this.state.searchString} onChange={this.onSearchStringChange} />
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                           >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <CiDataTable  
                            config={{
                                key:                    'meeting_id',
                                pagination:             true,
                                isLoading:              this.state.isLoading,
                                responsiveImportance:   true,
                                alternatingRowColours:  true,
                                persistenceId:          this.persistenceId,
                                options: {
                                    dataRef: true,
                                    reset: this.resetSearch,
                                    export: {
                                        title: `Meetings List`,
                                        name: `Meetings`,
                                        excel: true,
                                        pdf: true,
                                        print: true
                                    }
                                }
                            }}
                            columns={[
                                {
                                    heading: 'Meeting Name',
                                    field: rowData => rowData.meeting_name,
                                    dataRef: 'meeting_name',
                                    main: true,
                                    important: true,
                                },
                                {
                                    heading: 'Invitations Sent',
                                    field: rowData => ((rowData.invites && rowData.invites.length) || 0),
                                    dataRef: 'invites_count',
                                    main: true,
                                    important: true,
                                    tooltip: rowData => (rowData.invites && rowData.invites.length && (
                                        <React.Fragment>
                                            {_.map(rowData.invites, invite => {
                                                return (
                                                    <React.Fragment>
                                                        {invite.inv_send_sms === 1 && (
                                                            <div>
                                                                SMS Invite for {invite.inv_sms_name} - {SHORTLINK_BASE_URL}/{invite?.sms_shortlink?.sl_code}
                                                            </div>
                                                        )}
                                                        {invite.inv_send_email === 1 && (
                                                            <div>
                                                                Email Invite for {invite.inv_email_name} - {SHORTLINK_BASE_URL}/{invite?.email_shortlink?.sl_code}
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </React.Fragment>
                                    )) || 'No Invitations Sent'
                                },
                                {
                                    heading: 'Status',
                                    field: rowData => rowData.status,
                                    important: true,
                                    dataRef: 'status_value'
                                },
                                {
                                    heading: 'Created',
                                    field: rowData => rowData.meeting_created_datetime,
                                    fieldFormat: 'date',
                                    dataRef: 'meeting_created_datetime',
                                    sizeToContent: true
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Join Meeting', icon: 'sign-in', linkExternal: rowData.meeting_megameeting_host_link, disabled: (!rowData.meeting_megameeting_host_link || rowData.meeting_status === 'Finished')},
                                            {name: 'Invite', icon: 'plus-circle', onClick: this.openAdditionalInviteDialog, disabled: (rowData.meeting_status === 'Finished')},
                                            {name: 'Finish', icon: 'times-circle', onClick: this.handleFinishMeetingConfirmationOpen, disabled: (rowData.meeting_status === 'Finished')},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.searchResults}
                        />
                    </PaddedPaper>
                </Grid>
                <ConfirmationDialog 
                    open={this.state.finishMeetingConfirmationOpen} 
                    success={this.handleFinishMeetingConfirmationSuccess} 
                    close={this.handleFinishMeetingConfirmationClose} 
                    title="Finish Meeting?"
                    message="Are you sure you want to finish the meeting?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.finishMeetingSnackbarOpen}
                    onClose={this.handleFinishMeetingSnackbarClose}
                    message="The meeting was finished"
                />
                <AdditionalInviteDialog
                    open={this.state.additionalInvite.open}
                    onClose={this.closeAdditionalInviteDialog}
                    meetingName={this.state.additionalInvite.name}
                    meetingId={this.state.additionalInvite.id}
                    scrollToTop={this.props.scrollToTop}
                />
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(MeetingSearch);
