import React       from 'react';
import ReactHowler from 'react-howler';
import { connect } from 'react-redux';
import uuidv4      from 'uuid/v4';

import MUIStylesProvider from '@material-ui/styles/ThemeProvider';

import { stopSound }                              from 'Actions/Sounds/Sounds';
import { handleAppError, handleUserUnauthorized } from 'Actions/UI/UI';

import ErrorDialog from 'Components/Common/ErrorHandling/ErrorDialog';

import RequireAuth from '../../RequireAuth';
import theme       from '../../Theme/Theme';

import WarehouseContentWrapper from './WarehouseContentWrapper';
import WarehouseHeader         from './WarehouseHeader';

import PrintDialog from 'Components/Warehouse/Common/Dialogs/PrintDialog';
import Calculator from 'Components/Common/Calculator/Calculator';

const initialState = {
    pageTitle: '',
    pagePath: '',
    calculatorOpen: false,
    appError: {
        key: uuidv4(),
        detail: "",
        state: false,
    },
    print: {
        key:     uuidv4(),
        open:    false,
        printer: '',
        url:     '',
        file:    null
    },
    keyDown: {
        key: uuidv4(),
        function: null
    }
}

class WarehouseLayout extends React.PureComponent {
    constructor() {
        super();
        this.state = initialState;
    }

    componentDidMount(){
        document.addEventListener('keydown', this.handleKeyDown);
        this.setState({
            pagePath: this.props.history.location.pathname,
            appError: {
                ...this.state.appError,
                key: uuidv4(),
                detail: this.props.ui.appError.detail,
                state: this.props.ui.appError.state
            },
        });
    }

    componentDidUpdate(prevProps){
        if(this.props.ui.appError.state !== prevProps.ui.appError.state) {
            this.setState({
                appError: {
                    ...this.state.appError,
                    key: uuidv4(),
                    state: this.props.ui.appError.state,
                    detail: this.props.ui.appError.detail
                }
            });
        }
        if(this.props.print.key !== prevProps.print.key) { 
            this.setState({
                print: {
                    ...this.state.print,
                    key: uuidv4(),
                    open:     this.props.print.open,
                    printer:  this.props.print.printer,
                    url:      this.props.print.url,
                    file:     this.props.print.file,
                    multi:    this.props.print.multi,
                    fileName: this.props.print.fileName
                }
            });
        }
        if(this.props.keyDown.key !== prevProps.keyDown.key) {
            this.setState({
                keyDown: {
                    ...this.state.keyDown,
                    key: uuidv4(),
                    function:     this.props.keyDown.function,
                }
            });
        }
    }

    handleKeyDown = event => {
        if (event.keyCode === 9) {
            //hyjack tab to always display calculator
            this.handleCalculator();
            event.preventDefault();
        } else {
            if (this.state.keyDown.function) {
                this.state.keyDown.function(event);
            }
        }
    }

    handleCalculator = () => {
        this.setState({
            calculatorOpen: !this.state.calculatorOpen
        });
    }

    changePage = (title, path) => {
        if(this.props.ui.unauthorized) {
            this.props.handleUserUnauthorized(false);
        }
        if(title !== this.state.pageTitle) {
            this.setState({
                pageTitle: title
            });
        } 
    }

    changePath = path => {
        this.setState({
            pagePath: path,
        });
    }

    render() {
        const { sound, stopSound } = this.props;
        const { appError, print, calculatorOpen } = this.state;
        return (
            <React.Fragment>
                <MUIStylesProvider theme={theme}>
                    <WarehouseHeader    
                        history={this.props.history}
                        location={this.props.location}
                        pageTitle={this.state.pageTitle}
                        updateNotification={this.props.ui.updateNotification}
                        changePage={this.changePage}
                    />
                    <WarehouseContentWrapper    
                        changePath={this.changePath}     
                        changePage={this.changePage}    
                        pagePath={this.state.pagePath}
                        scrollHeight={this.props.ui.device.height}
                        updateNotification={this.props.ui.updateNotification}
                        unauthorized={this.props.ui.unauthorized}
                    />
                    {appError.state && (
                        <ErrorDialog
                            key={appError.key}
                            state={appError.state}
                            detail={appError.detail}
                            history={this.props.history}
                            closeError={() => this.props.handleAppError(false, "")}
                        />
                    )}
                    {sound.src !== null && (
                        <ReactHowler
                            src={sound.src}
                            playing={sound.status === 'playing'}
                            onEnd={stopSound}
                        />
                    )}
                    {print.open &&
                        <PrintDialog
                            key={print.key}
                            url={print.url}
                            file={print.file}
                            printer={print.printer}
                            multi={print.multi}
                            fileName={print.fileName}
                        />
                    }
                </MUIStylesProvider>
                <Calculator 
                    calculatorOpen={calculatorOpen}
                    popOut
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state){
    return {
        sound:              state.sound,
        ui:                 state.ui,
        print:              state.print,
        keyDown:            state.keyDown
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleAppError:         (error, message)    => dispatch(handleAppError(error, message)),
        handleUserUnauthorized: (pathname)          => dispatch(handleUserUnauthorized(pathname)),
        stopSound:              ()                  => dispatch(stopSound())
    };
}
export default RequireAuth(connect(mapStateToProps, mapDispatchToProps)(WarehouseLayout));