import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const getName = name => {
    if(!name) return "someone";
    name = name.split(' ');
    name = name[0]
    return name;
}

const ThreadNudge = ({classes, myNudge, name}) => (
    <Box mt={1}>
        <Grid container alignItems='center' spacing={1}>
            <Grid item xs>
                <Paper className={classes.nudge}>
                    <Box p={2}>
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item align='left'>
                                <FAIcon type="light" icon="bell-exclamation" color="white" noMargin />
                            </Grid>
                            <Grid item xs align='left'>
                                <Typography variant="body2" component="div" className={classes.fileDetails} gutterBottom>
                                    {(myNudge && `You nudged ${getName(name)}`) || `${getName(name)} nudged you!`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    </Box>
)

export default ThreadNudge;