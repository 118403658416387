import React from 'react';

import {
    Avatar,
    Box,
    Grid,
    Hidden,
    Link,
    Table,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { statusColour } from 'Helpers/ColourHelper';

const initialState = () => ({
    
})

class JobOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    render() {
        const { job } = this.props;

        return (
            <Box mt={3}>
                <Grid container item spacing={3}>
                    
                    <Grid item xs={12} lg={8}>
                        <Grid container item spacing={3}>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Grid container item spacing={1} alignItems="center">                        
                                        <Grid item xs={12} md={9}>
                                            <Grid container spacing={3}>
                                                <Hidden smDown>
                                                    <Grid item>
                                                        <Avatar style={{height: 75, width: 75, margin: 0}}>
                                                            <FASolidIcon icon='user-helmet-safety' size='large' noMargin buttonPrimary />
                                                        </Avatar>
                                                    </Grid>
                                                </Hidden>
                                                <Grid item>
                                                    <Typography variant="h6" >                                        
                                                        <b>{job?.ref}</b>
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Job Reference
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2}>
                                            Customer
                                        </Grid>
                                        <Grid item xs={10}>
                                            <b>{job?.cust?.nm}</b>
                                        </Grid>
                                        <Grid item xs={2}>
                                            Order
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Link href={`/sales/order/view/${job?.order?.id}`}>
                                                {job?.order?.ref}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container item spacing={3}>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} alignContents="center">
                                                <Grid item>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h4" style={{color: statusColour(job?.st)}}>
                                                        {job?.st}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Status
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" >
                                                Forms
                                            </Typography>
                                            {job?.forms?.length > 0 &&
                                                <Table size='small'>
                                                    {job?.forms?.map((form, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell>
                                                                Job Sheet {form?.js?.number}
                                                            </TableCell>
                                                            <TableCell>
                                                                {form?.nm}
                                                            </TableCell>
                                                            <TableCell style={{color: statusColour(form?.s)}}>
                                                                {form?.stage ? form?.stage?.nm : form?.s}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </Table>
                                            }
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default JobOverview;