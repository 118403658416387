import React       from 'react';
import { connect } from 'react-redux';

import { ListItem, IconButton, List, Menu } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import FALightIcon     from 'Components/Common/Icons/FontAwesome/FALightIcon';
import { ReduxAvatar } from 'Components/Common/Badges/Avatar';

const styles = theme => ({
    list: {
        paddingTop: 0,
        paddingBottom: 0,
    },
})

const initialState = {
    anchorEl: null
}

class WarehouseUser extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    }
    handleLogout = () => {
        this.props.handleLogout();
    }

    render() {
        const { anchorEl } = this.state;
        const { classes } = this.props;
        let user = {
            name:    this.props.loggedInStaff.firstName + ' ' + this.props.loggedInStaff.lastName,
            picture: this.props.loggedInStaff.staffProfilePhoto,
            status:  2
        };

        return (
            user && (
                <React.Fragment>
                    <IconButton
                        aria-controls='team-menu'
                        aria-haspopup="true"
                        size="small"
                        onClick={this.handleClick}
                    >
                        {ReduxAvatar(user, {size:'md'})}
                    </IconButton>
                    <Menu
                        classes={{list: classes.list}}
                        id="team-menu"
                        anchorEl={anchorEl}
                        className="dd-menu"
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                    >
                    <List disablePadding>
                        <ListItem onClick={this.handleLogout} className={classes.dropDownItem}>
                            <FALightIcon icon='sign-out-alt' button /> Logout
                        </ListItem>
                    </List>
                    
                </Menu>
               </React.Fragment>
            )
        )
    }
}

function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff,
    };
}


export default connect(mapStateToProps)(withStyles(styles)(WarehouseUser));