import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import InfoIcon from 'Components/Common/Icons/InfoIcon';
import InfoBox from 'Components/Common/reports/InfoBox';
import { checkMailingListContacts } from 'Helpers/EmailHelper';
import IconHelper from 'Helpers/IconHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import CSVContactsDialog from 'Components/Marketing/MailingLists/CSVContactsDialog';
import { parseRow } from 'Helpers/CsvHelper.js';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import API from 'API';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = props => ({
    view: 'csv',
    contacts:{
        toAdd: [],
        dontAdd: [],
    },
    lists: {
        contacts: props.lists.contacts,
        existingContacts: props.existingContacts,
    },
    eligibleContactsDialog: {
        open: false,
        withSub: false,
    },
    dialogFormData: {
        showDialog: false,
    },
    contactsLoading: true,
})

class AddContactsCSV extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    handleFileChange =async (drop, name, event) => {
        const csv = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        var reader = new FileReader();
        reader.onload = this.csvUpload;
        reader.readAsText(csv);
    }

    csvUpload = e => {
        let data = e.target.result.split('\n');

        let head = data[0].replaceAll('\r','').split(',');
        let body = [];

        for (let i = 1; i < data.length; i++) {
            let row = parseRow(data[i]);
            let obj = {};
    
            for (let j = 0; j < row.length; j++) {
                obj[String(head[j].replaceAll('"','').replaceAll("'",''))] = row[j].replaceAll('"','').replaceAll("'",'');
            }
    
            body.push(obj);
        }

        if (head[0] !== 'Name' || head[1] !== 'Email' ){
            this.props.deployDialog(
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            The CSV file must contain the following columns in the following order: Name, Email.<br/> Please check your CSV file and try again.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="contained"
                            color='secondary'
                            onClick={this.props.closeDialog}
                        >Ok</Button>
                    </Grid>
                </Grid>,
                'Invalid CSV File',
                'sm',
                'error'
            )
        } else if (body.length < 1){
            this.props.deployDialog(
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            The CSV file must contain at least one contact.<br/> Please check your CSV file and try again.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="contained"
                            color='secondary'
                            onClick={this.props.closeDialog}
                        >Ok</Button>
                    </Grid>
                </Grid>,
                'Invalid CSV File',
                'sm',
                'error'
            )
        } else {
            this.setContacts(_.map(_.filter(body, c => c?.Email), (c, idx) => {
                let _contact = _.find(this.state.lists.contacts, {contact_email: c.Email.replaceAll('\r','').trim(), contact_name: c.Name.replaceAll('\r','').trim()});
                return {...(_contact ? _contact : {contact_id: 0, contact_email: c.Email.replaceAll('\r','').trim(), contact_name: c.Name.replaceAll('\r','').trim()}) ,idx};
            }));
        }
    }

    setContacts = async contacts => {
        let data = await checkMailingListContacts(contacts, this.props.existingContacts)
        this.setState({
            contacts: {
                toAdd: data.toAdd,
                dontAdd: data.dontAdd,
            },
            view: 'contacts',
        })
    }

    setCsvView = () => {
        this.setState({
            view: 'csv',
            contacts: {
                toAdd: [],
                dontAdd: [],
            }
        })
    }

    showEligibleContactsPreview = withSub => () => {
        this.setState({
            eligibleContactsDialog: {
                open: !this.state.eligibleContactsDialog.open,
                withSub: withSub,
            },
            contactsLoading: false,
        })
    }

    showCsvContactsDialog = () => {
        this.setState({
            dialogFormData: {
                ...this.state.dialogFormData,
                showDialog: true,
            }
        })
    }

    onContactsDialogSubmit = (contacts) => (forceCommit=false) => () => {
        this.setState({
            contacts: contacts,
            dialogFormData: {
                ...this.state.dialogFormData,
                showDialog: false,
            }
        })
    }

    handleSubmit = () => {
        this.setState({contactsLoading: true},()=>{
            let contacts = this.state.contacts.toAdd;
            API.post(`/marketing/campaigns/mailingLists/${this.props.mailingList.i}/addContacts`,{contacts: JSON.stringify(contacts)}).then(this.props.refresh)
        })
    }

    render(){

        const { view, contacts, eligibleContactsDialog, dialogFormData, lists } = this.state;

        return (
            <Grid container spacing={2}>
                {view === 'csv' ?
                    <>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='body1' style={{fontWeight: 'bold', marginBottom: '1em'}}>Note: Uploaded CSV must have the following headings:</Typography>
                                        <DataTable
                                            config={{
                                                plainHeader: true,
                                                noHeader: true,
                                            }}
                                            columns={[
                                                {
                                                    field:   'c',
                                                    sizeToContent: true,
                                                    style: { fontWeight: 'bold', borderRight: `1px solid ${colors.disabled}` },
                                                },
                                                {
                                                    field: 'n',
                                                },
                                            ]}
                                            rows={[
                                                {c: 'A1', n: 'Name' },
                                                {c: 'A2', n: 'Email' },
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DragFileInput
                                            accept=".csv"
                                            onChange={this.handleFileChange}
                                            label='Upload CSV *'
                                        />
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid> 
                        <Grid item xs={12} className='buttonRow'>
                            <Button
                                variant='outlined'
                                onClick={this.props.refresh}
                            >Close</Button>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                onClick={this.setCsvView}
                                fullWidth
                            >Re-Upload</Button>
                        </Grid>
                        <InfoBox
                            rowSize={3}
                            icon={IconHelper.customers}
                            value={[...contacts.toAdd, ...contacts.dontAdd].length ?? 0}
                            title="Contacts"
                        />
                        <InfoBox
                            rowSize={3}
                            icon={IconHelper.plus}
                            value={contacts.toAdd.length ?? 0}
                            title="Eligible"
                        />
                        <InfoBox
                            rowSize={3}
                            icon={IconHelper.false}
                            value={contacts.dontAdd.length ?? 0}
                            title={<>Not Eligible <InfoIcon info='This includes; unsubscribed contacts, accounts contacts, invalid email addresses, and duplicate email addresses.'/></>}
                        />
                       <Grid item xs={12} className='buttonRow'>
                            <Button
                                variant='outlined'
                                onClick={this.props.refresh}
                            >Close</Button>
                            {
                                [...contacts.toAdd, ...contacts.dontAdd].length > 0 &&
                                (
                                    contacts.dontAdd.length > 0 ?
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={this.showCsvContactsDialog}
                                        >
                                            Check Contacts
                                        </Button> :
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={this.showEligibleContactsPreview(false)}
                                        >
                                            Preview Eligible Contacts
                                        </Button>  
                                )
                            }
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.showEligibleContactsPreview(true)}
                                disabled={
                                    contacts.dontAdd.length > 0 ||
                                    contacts.toAdd.length === 0
                                }
                            >
                                Add
                            </Button>
                        </Grid>
                        {
                            eligibleContactsDialog.open &&
                            <Dialog
                                open={eligibleContactsDialog.open}
                                maxWidth="md"
                                fullWidth
                            >
                                <DialogTitle>{eligibleContactsDialog.withSub ? 'Create Mailing List?' : 'Eligible Contacts'}</DialogTitle>
                                <DialogContent>
                                    {this.state.contactsLoading ?
                                        <LoadingCircle/> : 
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="caption">
                                                    The following contacts will be added to the mailing list:
                                                </Typography>
                                                <List>
                                                    {_.map(contacts.toAdd, (contact, i) => 
                                                        <ListItem key={i}>
                                                            <ListItemText primary={`${contact.contact_name} ${contact?.customer?.cust_name ? `(${contact.customer.cust_name})` : ''}`} secondary={contact.contact_email} />
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </Grid>
                                        </Grid>
                                    }
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        onClick={this.showEligibleContactsPreview(false)}
                                    >{eligibleContactsDialog.withSub ? 'Cancel':'Close'}</Button>
                                    {
                                        eligibleContactsDialog.withSub &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleSubmit}
                                        >
                                            Add
                                        </Button>
                                    }
                                </DialogActions>
                            </Dialog>
                        }
                        {dialogFormData.showDialog &&
                            <CSVContactsDialog
                                open={dialogFormData.showDialog}
                                eligibleContacts={[...contacts.toAdd, ...contacts.dontAdd]}
                                contacts={lists.contacts}
                                onSubmit={this.onContactsDialogSubmit}
                            />
                        }
                    </>
                }
            </Grid>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        deployDialog: (content, title, size, variant=null) => {
            dispatch(deployDialog(content, title, variant, size))
        },
        closeDialog: () => {
            dispatch(closeDialog())
        }
    }
}

export default connect(null, mapDispatchToProps)(AddContactsCSV);