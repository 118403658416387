import React, {Component} from 'react';
import { Redirect }       from 'react-router-dom';
import API                from 'API';

import { Typography, Grid, Button, TextField } from '@material-ui/core';

import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';
import BackButton         from 'Components/Common/Buttons/BackButton';

import { colors }                 from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = {
    formData: {
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        town: '',
        county: '',
        postcode: ''
    },
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    redirect: false
}

class UpdateDefaultAddress extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getAddress();
    }

    getAddress = () => {
        API.get(`/partsOrdering/addresses/${this.props.match.params.id}`)
        .then(result => {
            if(result.data.errors) {
                this.setState({ redirect: true });
            } else if(result.data) {
                this.setState({
                    formData: {
                        ...this.state.formData,
                        companyName: result.data.ppo_address_company_name,
                        addressLine1: result.data.ppo_address_line_one,
                        addressLine2: result.data.ppo_address_line_two,
                        addressLine3: result.data.ppo_address_line_three,
                        town: result.data.ppo_address_town,
                        county: result.data.ppo_address_county,
                        postcode: result.data.ppo_address_postcode
                    }
                });
            }
        });
    };

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        API.post(`/partsOrdering/addresses/${this.props.match.params.id}`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.getAddress();
            }
            this.props.scrollToTop();
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }
       
    render() {
        const { formErrors } = this.state;
        if (this.state.redirect) {
            return <Redirect to="/parts-order/default-addresses" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Update Parts Order Address
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                {formErrors && formErrors.generic && (
                                    <React.Fragment>
                                        <Typography component={"div"} style={{color: colors.red}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                <form noValidate autoComplete="off">
                                    <TextField
                                        id="companyName"
                                        name="companyName"
                                        label="Company Name *"
                                        value={this.state.formData.companyName}
                                        error={formErrors && formErrors['companyName'] && true}
                                        helperText={formErrors && formErrors['companyName']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="addressLine1"
                                        name="addressLine1"
                                        label="Address Line 1 *"
                                        value={this.state.formData.addressLine1}
                                        error={formErrors && formErrors['addressLine1'] && true}
                                        helperText={formErrors && formErrors['addressLine1']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="addressLine2"
                                        name="addressLine2"
                                        label="Address Line 2"
                                        value={this.state.formData.addressLine2}
                                        error={formErrors && formErrors['addressLine2'] && true}
                                        helperText={formErrors && formErrors['addressLine2']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="addressLine3"
                                        name="addressLine3"
                                        label="Address Line 3"
                                        value={this.state.formData.addressLine3}
                                        error={formErrors && formErrors['addressLine3'] && true}
                                        helperText={formErrors && formErrors['addressLine3']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="town"
                                        name="town"
                                        label="Town *"
                                        value={this.state.formData.town}
                                        error={formErrors && formErrors['town'] && true}
                                        helperText={formErrors && formErrors['town']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="county"
                                        name="county"
                                        label="County *"
                                        value={this.state.formData.county}
                                        error={formErrors && formErrors['county'] && true}
                                        helperText={formErrors && formErrors['county']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="postcode"
                                        name="postcode"
                                        label="Postcode *"
                                        value={this.state.formData.postcode}
                                        error={formErrors && formErrors['postcode'] && true}
                                        helperText={formErrors && formErrors['postcode']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <div className='buttonRow'>
                                    <BackButton props={this.props} />
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary"
                                               >
                                            Update
                                        </Button>
                                    </div>
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>    
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen} 
                        success={this.handleConfirmationSuccess} 
                        close={this.handleConfirmationClose} 
                        title="Update Default Address?" 
                        message="Are you sure you want to update this default address?"
                    />
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarOpen}
                        onClose={this.handleSnackbarClose}
                        message='You have successfully updated this default address'
                    />
                </Grid>
            );
        }
    }
}

export default UpdateDefaultAddress;