import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../../Common/Paper/PaddedPaper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import API from '../../../../API';
import { formatValidationErrors } from '../../../../Helpers/ErrorHelper';
import DataTable from '../../../Common/DataTables/DataTable';
import ConfirmationDialog from './../../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../../Common/SnackBars/SnackBar';
import LoadingCircle from './../../../Common/LoadingCircle/LoadingCircle';

const initialState = {
    formData: {
        reason: ''
    },
    defaultList: {},
    formErrors: {},
    snackbarOpen: false,
    confirmationOpen: false,
    access: {
        addReason: false
    },
    isLoading: true
}

class StockWriteOffDefaultReasons extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.checkAccess();
        this.getReasons(); 
    }

    getReasons = () => {
        API.get('/stock/writeOff/defaultReasons')
        .then(result => {
            if(result.data) {
                this.setState({
                    defaultList: result.data,
                    isLoading: false
                });
            }
        });
    };

    checkAccess = () => {
        API.get('/staff/my/access/check/stock-write-off-default-reasons:add')
        .then((res) =>  {
            res.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addReason: res.data.has_access
                    }
                });
        });
    }

    submit = () => {
        API.post('/stock/writeOff/defaultReasons', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.checkAccess();
                this.getReasons();
            }
            this.props.scrollToTop();
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    };

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true
        });
    };

    handleConfirmationClose = () => {
        this.setState({
            confirmationOpen: false
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: false
        });
        this.submit();
    };

    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: res.value
            }
        });
    };

    render() {
        const { defaultList, access, formData, formErrors, isLoading, snackbarOpen, confirmationOpen } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Default Stock Write Off Reasons
                    </Typography>
                </Grid>                
                {(isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                )) || (
                    <React.Fragment>
                        {access.addReason && (
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <form onChange={this.handleChange} noValidate autoComplete="off">
                                            <TextField
                                                id="reason"
                                                name="reason"
                                                label="Reason *"
                                                value={formData.reason}
                                                error={formErrors && formErrors['reason'] && true}
                                                helperText={formErrors && formErrors['reason']}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <div className='buttonRow'>
                                                <Button 
                                                    onClick={this.handleConfirmationOpen}
                                                    variant="contained" 
                                                    color="primary"
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                            <ConfirmationDialog 
                                                open={confirmationOpen} 
                                                success={this.handleConfirmationSuccess} 
                                                close={this.handleConfirmationClose} 
                                                title="Add A New Default Reason?" 
                                                message="Are you sure you want to add a new default reason?"
                                            />
                                            <SnackBar
                                                variant="success"
                                                anchorOriginVertical='bottom'
                                                anchorOriginHorizontal='right'
                                                open={snackbarOpen}
                                                onClose={this.handleSnackbarClose}
                                                message="You have successfully added a new default reason"
                                            />
                                        </form>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <DataTable  
                                    config={{
                                        key: 'swo_default_id',
                                        alternatingRowColours: true
                                    }}
                                    columns={[
                                        {
                                            heading: 'Existing Reasons',
                                            field: rowData => rowData.swo_default_reason
                                        }
                                    ]}
                                    rows={defaultList}
                                />
                            </PaddedPaper>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

export default StockWriteOffDefaultReasons;