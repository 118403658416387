import React from 'react';
import _     from 'lodash';
import API   from 'API';

import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';

const initialState = {
    options:      [],
    timeout:      null,
    searchParams: {},
    selected:     []
}

class SearchMultiSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...initialState, searchParams: this.props.searchParams};
    }        
    
    componentWillUnmount() {
        clearTimeout(this.state.timeout);
    }

    handleKeyDown = (event) => {
        
        if (event.target?.value){
            this.setState({
                value: event.target?.value,
                timeout: setTimeout(this.handleSearch,500)
            })
        } else {
            this.state.timeout && clearTimeout(this.state.timeout);
        }
    }

    handleSearch = () => {
        const keywords = this.props.keywords || 'keywords'

        const params = {
            [keywords]:  this.state.value,
            ...this.state.searchParams
        }

        API.get(this.props.url, {params})
        .then(res => {this.refactor(res.data)})
    }

    refactor = (res) => {
        this.setState({
            options: _.map(res,item => {
                if (this.props.refactor) {
                    let ret = {};
                    _.each(_.keys(this.props.refactor), key => {
                        const mold = this.props.refactor[key];
                        ret[key] = _.isFunction(mold) ? mold(item) : item[mold];
                    })
                    return ret;
                } else {
                    return {
                        value: item.value,
                        label: item.label
                    }
                }
            })
        })
    }
 
    handleOnChange = e => {
        this.setState({selected: e ? e : []});
        this.props.onChange(e);
    }

    render() {

        const { options } = this.state;

        return (
            <AutoCompleteMultiSelect
                name={this.props.name}
                label={this.props.label}
                value={this.props.value}
                onChange={this.handleOnChange}
                noClear={this.props.noClear}
                options={[...this.state.selected,...options]}
                onKeyDown={this.handleKeyDown}
                onKeyUp={this.handleKeyDown}
            />
        )
    }
}
  
export default SearchMultiSelect;