import React      from 'react';
import API        from 'API';
import _          from 'lodash';
import PropTypes  from 'prop-types';
import classNames from 'classnames';

import { CssBaseline, Divider, Drawer, IconButton, List } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import FALightIcon   from 'Components/Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import AdminFooterMobile from './AdminFooterMobile';
import AdminNavItem      from './AdminNavItem';

import logo from 'Assets/Images/logo.png';

const drawerWidth = 285;

const styles = theme => ({
    pwaBarSpacer: {
        minHeight: 'calc(env(safe-area-inset-top) - 10px)',
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
        whiteSpace: 'nowrap',
        transition: '.3s'
    },
    drawerPaperClose: {
        width: theme.spacing(7),  
        overflowX: 'hidden',
        transition: '.3s'
    },
    toolbarIcon: {
        position: 'absolute',
        top: '50%',
        marginTop: '-29px',
        right: 10,
    },
    logo:{
        height:'90%',
        paddingTop: 4,
    },
    logoContainer:{
        position: 'relative',
        width: drawerWidth,
        height: 68,
        padding: theme.spacing(1),
    },
    navHeaderContainer:{
        position: 'fixed',
        width: '100%',
        zIndex: 9999999,
        backgroundColor: '#fff',
        webkitOverflowScrolling: 'touch',
        webkitTransform: 'translateZ(0)'
    },
    [theme.breakpoints.down('sm')]: {
        drawerPaper: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            minHeight: '100%',
            borderRight: 0,
        },
        drawerPaperClose: {
            width: theme.spacing(7),
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        },
        logoContainer: {
            width: '100%',
            borderRight: 0,
        },
    },
    [theme.breakpoints.down('xs')]: {
        logoContainer: {
            height: 60,
        },
        drawerPaper: {
            borderRight: 0,
        },
        drawerPaperClose: {
            width: 0,
        },
    },
    ['@media (orientation: landscape) and (max-height: 767px)']: { // eslint-disable-line no-useless-computed-key
        drawerPaper: {
            borderRight: 0,
        },
        drawerPaperClose: {
            width: 0,
        },
        notchMenuFix: {
            marginLeft: 'env(safe-area-inset-left)',
            marginRight: 'env(safe-area-inset-right)',
        },
    },
});

const initialState = {
    navItems: {},
    navLoading: true,
    selectedNavId: false,
    scrollHeight: window.innerHeight,
}

class AdminNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        API.get('/staff/my/navigation')
        .then((result) => {
            if(result.data){
                this.setState({
                    ...this.state,
                    navItems: result.data,
                    navLoading: false,
                    scrollHeight: this.props.scrollHeight,
                })
            }
        })
    }
    
    componentDidUpdate(prevProps){
        if(this.props.scrollHeight !== prevProps.scrollHeight){
            this.setState({
                scrollHeight: this.props.scrollHeight,
            });
        }
    }

    handleClick = (navId) => {
        if(this.state.selectedNavId !== navId){
            this.setState({
                selectedNavId: navId,
            });
        } else {
            this.setState({
                selectedNavId: false,
            });
        }
    }

    render() {
        const { navLoading, scrollHeight } = this.state;
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
                <Drawer
                    variant="permanent"
                    classes={{
                    paper: classNames(classes.drawerPaper, !this.props.navOpen && classes.drawerPaperClose),
                    }}
                    open={this.props.navOpen}
                    style={{height: window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${scrollHeight}px)` : `${scrollHeight}px`}}
                >
                    <React.Fragment>
                        <div className={classes.navHeaderContainer}>
                        <div className={classes.pwaBarSpacer} />
                            <div className={classes.navHeader}>
                                <div className={classes.logoContainer}>
                                    <img alt="MyClenaware - by Clenaware Systems" className={classes.logo} src={logo} />
                                    <div className={classes.toolbarIcon}>
                                        <IconButton onClick={this.props.closeNav}>
                                            <FALightIcon icon='angle-left' size='large' button noMargin />
                                        </IconButton>
                                    </div>
                                </div>
                                <Divider />
                            </div>
                        </div>                   
                        <div className={classes.pwaBarSpacer} />
                        <div style={{paddingTop:40, backgroundColor:'#fff'}}>&nbsp;</div>     
                        {(navLoading && (
                            <div style={{textAlign: 'center', paddingTop: 32}}>
                                <LoadingCircle padding={!this.props.navOpen ? 'xs' : false} size={this.props.navOpen ? 'large' : 'small'} />
                            </div>
                        )) || (
                            <List onClick={this.props.openNav} className={classes.notchMenuFix}>
                                {this.state.navItems.length > 0 && this.state.navItems.map(mainNav => {
                                    return (
                                        <React.Fragment key={mainNav.access_id}>
                                            {typeof(mainNav.children) === "undefined" ?                                                 
                                                <AdminNavItem navId={mainNav.access_id} history={this.props.history} selectedNavId={this.state.selectedNavId} onClick={this.handleClick} name={mainNav.access_name} icon={mainNav.access_icon} link={mainNav.access_route} changePage={this.props.changePage} pagePath={this.props.pagePath} /> 
                                            :   
                                                (_.some(mainNav.children, function (nv) { return nv.access_display_on_nav === 1 }) && (
                                                    <AdminNavItem navId={mainNav.access_id} history={this.props.history} selectedNavId={this.state.selectedNavId} onClick={this.handleClick} name={mainNav.access_name} navOpen={this.props.navOpen} icon={mainNav.access_icon} submenu={mainNav.children} changePage={this.props.changePage} pagePath={this.props.pagePath} />
                                                )) || (
                                                    <AdminNavItem navId={mainNav.access_id} history={this.props.history} selectedNavId={this.state.selectedNavId} onClick={this.handleClick} name={mainNav.access_name} icon={mainNav.access_icon} link={mainNav.access_route} changePage={this.props.changePage} pagePath={this.props.pagePath} />    
                                                )
                                            }
                                        </React.Fragment>
                                    );
                                })}
                                {this.props.navOpen &&
                                    <AdminFooterMobile />
                                }
                            </List>
                        )}
                    </React.Fragment>
                </Drawer>
            </React.Fragment>
        );
    }
}

AdminNav.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminNav);