import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography, TextField } from '@material-ui/core';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import React from 'react';
import CreateOrUpdateContactDialog from './CreateOrUpdateContactDialog';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import API from 'API';
import IconHelper from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';
import _ from 'lodash';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import LinkContactToCustomerDialog from './LinkContactToCustomerDialog';
import AddManualContactsDialog from './AddManualContactsDialog';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import InputHelper from 'Helpers/InputHelper';
import AllIcon from 'Components/Common/Icons/AllIcon';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import MergeSystemContactDialog from './MergeSystemContactDialog';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';

const initialState = () => ({
    isLoading: true,
    contacts: [],
    contactDialogData: {
        open: false,
        contact: null,
    },
    linkContactDialogData: {
        open: false,
        contact: null,
    },
    addManualContactsDialogData: {
        open: false,
    },
    searchFormData: {
        keyword: null,
        active: '1',
        sources: [],
    },
    access: {
        'update-customer-contact': false,
        'merge-customer-contact': false,
    },
    lists: {
        sources: [],
    }
})

class ManualContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.inputHelper = new InputHelper(this, 'searchFormData', this.getContacts);
    }

    componentDidMount() {
        this.inputHelper.handleSetAccess(_.keys(this.state.access));
        this.getContacts();
    }

    getContacts = () => {
        API.get('/marketing/contacts', {params: {...this.state.searchFormData, sources: JSON.stringify(this.state.searchFormData.sources)}})
        .then((response) => {
            this.setState({
                contacts: response.data,
                isLoading: false,
                lists: {
                    sources: this.state.lists.sources.length > 0 ? this.state.lists.sources : _.map(
                        _.uniqBy(response.data, 'contact_source'),
                        i => ({label: i.contact_source, value: i.contact_source})
                    ),
                }
            });
        });
    }

    handleCreateOrUpdateContact = (contact) => () => {
        this.setState({
            contactDialogData: {
                open: true,
                contact: contact,
            }
        });
    }

    handleContactDialogClose = () => {
        this.setState({
            contactDialogData: {
                open: false,
                contactId: null,
            }
        }, this.getContacts);
    }

    handleLinkContact = (contact) => {
        this.setState({
            linkContactDialogData: {
                open: true,
                contact: contact,
            }
        });
    }

    handleLinkContactDialogClose = () => {
        this.setState({
            linkContactDialogData: {
                open: false,
                contactId: null,
            }
        }, this.getContacts);
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            searchFormData: {
                ...this.state.searchFormData,
                [name]: value
            }
        }, this.getContacts)
    }

    deleteContact(contact) {
        API.post(`/customers/contacts/${contact.contact_id}/delete`, {contactId: contact.contact_id})
        .then(this.getContacts);
    }
    restoreContact(contact) {
        API.post(`/customers/contacts/${contact.contact_id}/restore`, {contactId: contact.contact_id})
        .then(this.getContacts);
    }

    handleUploadCSVDialog = () => {
        this.setState({
            uploadCSVDialogData: {
                open: true,
            }
        });
    }

    handleUploadCSVDialogClose = () => {
        this.setState({
            uploadCSVDialogData: {
                open: false,
            }
        }, this.getContacts);
    }

    addManualContacts = () => {
        this.props.deployDialog(
            <AddManualContactsDialog
                onClose={() => {this.props.closeDialog(); this.getContacts()}}
            />,
            'Add Contacts',
            'system',
            'md'
        )

        this.setState({
            addManualContactsDialogData: {
                open: !this.state.addManualContactsDialogData.open,
            }
        })
    }

    mergeContacts = (contact) => {
        this.props.deployDialog(
            <MergeSystemContactDialog 
                contact={contact} 
                onClose={() => {
                    this.getContacts();
                    this.props.closeDialog();
                }}
            />,
            'Merge To System Contact',
            null,
            'md'
        )
    }

    render() {

        const { isLoading, contactDialogData, linkContactDialogData, contacts, searchFormData, addManualContactsDialogData, access, lists} = this.state;

        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <PaddedPaper>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    placeholder='Keyword Search...'
                                    name='keyword'
                                    value={searchFormData.keyword}
                                    onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value),this.getContacts)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteMultiSelect
                                    label='Sources'
                                    value={searchFormData.sources}
                                    options={lists.sources}
                                    fullWidth
                                    onChange={this.inputHelper.handleMultiSelectChange('sources')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    label='active?'
                                    value={searchFormData.active}
                                    options={[
                                        {label: 'Active', value: '1'},
                                        {label: 'Inactive', value: '0'},
                                        {label: 'All', value: 'all'}
                                    ]}
                                    fullWidth
                                    disableSort
                                    noClear
                                    onChange={this.inputHelper.handleSelectChange('active')}
                                />
                            </Grid>
                            <Grid item xs={12} className='buttonRow'>
                                <Button
                                    variant="outlined"
                                    onClick={()=>this.inputHelper.handleSetState(initialState().searchFormData)}
                                ><AllIcon icon={IconHelper.refresh} />Reset Search</Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.addManualContacts}
                                >Add Contacts</Button>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {/*}
                <Grid item
                    style={{
                        marginLeft: 'auto',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleUploadCSVDialog}
                    >Upload CSV</Button>
                </Grid>
                <Grid item >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleCreateOrUpdateContact()}
                    >Create Manual Contact</Button>
                </Grid>
                */}
                {/*}
                <Grid item 
                    style={{
                        marginLeft: 'auto',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.addManualContacts}
                    >Add contacts</Button>
                </Grid>
                */}
                <Grid item xs={12}>
                    <PaddedPaper >
                        <DataTable
                            config={{
                                key: 'contact_id',
                                pagination: true,
                                options: {
                                    export: {
                                        title: `Manual Contacts`,
                                        name: `Manual Contacts`,
                                        csv: true,
                                        excel: true,
                                    },
                                }
                            }}
                            rows={_.orderBy(contacts, ['contact_name'], ['asc'])}
                            columns={[
                                {
                                    heading: 'Name',
                                    field: 'contact_name',
                                    dataRef: 'contact_name'
                                },
                                {
                                    heading: 'Email',
                                    field: 'contact_email',
                                    dataRef: 'contact_email'
                                },
                                {
                                    heading: 'Company',
                                    field: 'contact_company',
                                    dataRef: 'contact_company',
                                },
                                {
                                    heading: 'Source',
                                    field: 'contact_source',
                                    dataRef: 'contact_source',
                                    info: 'contact_source_data',
                                    sizeToContent: true,
                                },
                                {
                                    actions: r => ([
                                        {
                                            name: r.contact_status == 'Active' ? 'Delete' : 'Restore', 
                                            icon: r.contact_status == 'Active' ? 'trash-alt' : 'trash-restore', 
                                            onClick: () => {
                                                this.props.deployConfirmation(
                                                    r.contact_status == 'Active' ? 
                                                        "Are you sure you want to delete this contact?" : 
                                                        "Are you sure you want to restore this contact?", 
                                                    r.contact_status == 'Active' ? 
                                                        "Delete Contact" :
                                                        "Restore Contact",
                                                    () => r.contact_status == 'Active' ? 
                                                        this.deleteContact(r) : this.restoreContact(r)
                                                )
                                            }
                                        },
                                        {
                                            name: 'Edit',
                                            link: `/customers/contacts/update/${r.contact_id}`,
                                            icon: IconHelper.edit,
                                            disabled: !access['update-customer-contact']
                                        },
                                        {
                                            name: 'View',
                                            link: `/customers/contacts/view/${r.contact_id}`,
                                            icon: IconHelper.view,
                                        }
                                    ])
                                } 
                            ]}
                        />
                    </PaddedPaper>
                </Grid>
                {contactDialogData.open &&
                    <Dialog
                        open={contactDialogData.open}
                        fullWidth
                        maxWidth='sm'
                    >
                        <DialogTitle
                            style={{
                                backgroundColor: colors.primary,
                            }}
                        >
                            <Typography variant="h5"
                                style={{color: colors.white}}
                            >{!!contactDialogData.contact ? 'Update' : 'Create'} Manual Contact</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <CreateOrUpdateContactDialog
                                update={contactDialogData.contact}
                                onClose={
                                    this.handleContactDialogClose
                                }
                            />
                        </DialogContent>
                    </Dialog>
                }
                {linkContactDialogData.open &&
                    <Dialog
                        open={linkContactDialogData.open}
                        fullWidth
                        maxWidth='sm'
                    >
                        <DialogTitle
                            style={{
                                backgroundColor: colors.primary,
                            }}
                        >
                            <Typography variant="h5"
                                style={{color: colors.white}}
                            >Link Contact to Customer</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <LinkContactToCustomerDialog
                                contact={linkContactDialogData.contact}
                                onClose={
                                    this.handleLinkContactDialogClose
                                }
                            />
                        </DialogContent>
                    </Dialog>
                }
                {/* {
                    addManualContactsDialogData.open &&
                        <Dialog
                            open={addManualContactsDialogData.open}
                            fullWidth
                            maxWidth='md'
                        >
                            <DialogTitle
                                style={{
                                    backgroundColor: colors.primary,
                                }}
                            >
                                <Typography variant="h5"
                                    style={{color: colors.white}}
                                >Add Contacts</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <AddManualContactsDialog
                                    onClose={() => {this.addManualContacts(); this.getContacts()}}
                                />
                            </DialogContent>
                        </Dialog>
                } */}
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback)      => dispatch(deployConfirmation(content, title, callback)),
        deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
        closeDialog:        ()                              => dispatch(closeDialog())
    }
}

export default connect(null, mapDispatchToProps)(ManualContacts);