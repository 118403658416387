import React, {Component}  from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import API from '../../../API';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar';
import DataTable from '../../Common/DataTables/DataTable';
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        town: '',
        county: '',
        postcode: ''
    },
    addresses: [],
    formErrors: [],
    confirmationOpen: false,
    snackbarOpen: false,
    access: {
        addAddress: false,
        updateAddress: false
    },
    isLoading: true
}

class DefaultAddresses extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.checkAccess();
        this.getTemplates();
    }

    getTemplates = () => {
        this.setState({
            isLoading: true
        }, () => {
            API.get('/purchaseOrders/addresses')
            .then(result => {
                if(result.data) {
                    this.setState({
                        ...this.state,
                        addresses: result.data,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        })
    };

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/purchase-order-default-addresses:add'), 
            API.get('/staff/my/access/check/update-default-purchase-order-address'),
        ])
        .then(([addRes, updateRes]) =>  {
            addRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addAddress: addRes.data.has_access
                    }
                });
            updateRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        updateAddress: updateRes.data.has_access
                    }
                });
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    submit = () => {
        API.post('/purchaseOrders/addresses', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.checkAccess();
                this.getTemplates();
            }
            this.props.scrollToTop();
        });
    }

    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }
       
    render() {
        const { formErrors, access } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Default Addresses
                    </Typography>
                </Grid>
                {access.addAddress &&
                    <Grid container item spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <PaddedPaper>
                                {formErrors && formErrors.generic && (
                                    <React.Fragment>
                                        <Typography component={"div"} style={{color: colors.red}}>
                                            {formErrors.generic}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                <form noValidate autoComplete="off">
                                    <TextField
                                        id="companyName"
                                        name="companyName"
                                        label="Company Name *"
                                        value={this.state.formData.companyName}
                                        error={formErrors && formErrors['companyName'] && true}
                                        helperText={formErrors && formErrors['companyName']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="addressLine1"
                                        name="addressLine1"
                                        label="Address Line 1 *"
                                        value={this.state.formData.addressLine1}
                                        error={formErrors && formErrors['addressLine1'] && true}
                                        helperText={formErrors && formErrors['addressLine1']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="addressLine2"
                                        name="addressLine2"
                                        label="Address Line 2"
                                        value={this.state.formData.addressLine2}
                                        error={formErrors && formErrors['addressLine2'] && true}
                                        helperText={formErrors && formErrors['addressLine2']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="addressLine3"
                                        name="addressLine3"
                                        label="Address Line 3"
                                        value={this.state.formData.addressLine3}
                                        error={formErrors && formErrors['addressLine3'] && true}
                                        helperText={formErrors && formErrors['addressLine3']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="town"
                                        name="town"
                                        label="Town *"
                                        value={this.state.formData.town}
                                        error={formErrors && formErrors['town'] && true}
                                        helperText={formErrors && formErrors['town']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="county"
                                        name="county"
                                        label="County *"
                                        value={this.state.formData.county}
                                        error={formErrors && formErrors['county'] && true}
                                        helperText={formErrors && formErrors['county']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <TextField
                                        id="postcode"
                                        name="postcode"
                                        label="Postcode *"
                                        value={this.state.formData.postcode}
                                        error={formErrors && formErrors['postcode'] && true}
                                        helperText={formErrors && formErrors['postcode']}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        fullWidth
                                    />
                                    <div className='buttonRow'>
                                        <Button onClick={this.handleConfirmationOpen}
                                                variant="contained"
                                                color="primary">
                                            Add
                                        </Button>
                                    </div>
                                </form>
                            </PaddedPaper>
                        </Grid>
                    </Grid>
                }    
                <Grid item xs={12}>    
                    <PaddedPaper>
                        <DataTable  
                            config={{
                                key: 'ppo_address_id',
                                pagination: true,
                                alternatingRowColours: true,
                                isLoading: this.state.isLoading
                            }}
                            columns={[
                                {
                                    heading: 'Company',
                                    field: rowData => rowData.address_company_name
                                },
                                {
                                    heading: 'Address Line 1',
                                    field: rowData => rowData.address_line_one
                                },
                                {
                                    heading: 'Town',
                                    field: rowData => rowData.address_town
                                },
                                {
                                    heading: 'Postcode',
                                    field: rowData => rowData.address_postcode
                                },
                                {
                                    actions: rowData => {
                                        return [
                                            {name: 'Update', icon: 'pencil-alt', link: '/purchase-order/default-address/update/' + rowData.address_id, disabled: !access.updateAddress},
                                        ]
                                    }
                                }
                            ]}
                            rows={this.state.addresses}
                        />
                    </PaddedPaper>
                </Grid>    
                <ConfirmationDialog 
                    open={this.state.confirmationOpen} 
                    success={this.handleConfirmationSuccess} 
                    close={this.handleConfirmationClose} 
                    title="Add A New Default Address?" 
                    message="Are you sure you want to add this new default address?"
                />
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully added a new default address'
                />
            </Grid>
        );
    }
}

export default DefaultAddresses;