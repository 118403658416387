import React  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import {
    Button,
    Grid,
    TextField,
    Typography,
    Avatar,
    Link
} from '@material-ui/core';

import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import { handleFileChange, handleDateChange, handleChangeUc, handleAddRow, handleRowEdit, handleRemoveRow, handleRowFileChange, getFormData, handleRowDateChange, clearValue } from 'Functions/FormFunctions';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import AllIcon from 'Components/Common/Icons/AllIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import { downloadS3File, toggleDialog } from 'Functions/MiscFunctions';
import { SmallFormDialog } from 'Components/Common/Dialogs/SmallFormDialog';
import EngineerExternalProfileForm from './EngineerExternalProfileForm';
import EngineerQualificationForm from './EngineerQualificationForm';
import BackButton from 'Components/Common/Buttons/BackButton';

const initialState = () => ({
    engineerId: 0,
    en: {},
    formData: {
        type: 'Internal',
        // Personal Details
        staff: '',
        supplierContact: '',
        mobileNumber: '',
        photo: null,
        // External Profiles
        externalProfiles: [], // {name: '', details: '', file: null}
        // Qualifications
        qualifications: [], // {name: '', details: '', expiryDate: null, file: null }
    },
    dialog: {
        externalProfile: false,
        externalProfileId: 0,
        qualification: false,
        qualificationId: 0,
    },
    formErrors: [],
    staffList: [],
    supplierList: [],
    isLoading: true
});

class EngineerForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
        this.handleFileChange = handleFileChange.bind(this);
        this.handleDateChange = handleDateChange.bind(this);
        this.handleChangeUc = handleChangeUc.bind(this);
        this.handleAddRow = handleAddRow.bind(this);
        this.handleRowEdit = handleRowEdit.bind(this);
        this.handleRemoveRow = handleRemoveRow.bind(this);
        this.handleRowDateChange = handleRowDateChange.bind(this);
        this.handleRowFileChange = handleRowFileChange.bind(this);
        this.getFormData = getFormData.bind(this);
        this.toggleDialog = toggleDialog.bind(this);
        this.clearValue = clearValue.bind(this);
    }

    componentDidMount = () => {
        
        this.loadComponentData(); 
    }

    loadComponentData = () => {
        Promise.all([
            API.get('/staff/excluding', { params: { activeOnly: true, excluding: 'engineer' } }),
            API.get('/suppliers/thirdPartyEngineers'),
        ]).then(([staffRes, suppRes]) => {

            const staffList = !staffRes.data ? [] :
                _.map(staffRes.data, el => {
                    return _.assign({
                        value: el.staff_id,
                        label: `${el.staff_first_name} ${el.staff_last_name}`, 
                    });
            });

            let supplierList = [];
            _.map(suppRes.data, el => {
                supplierList.push({
                    label: el?.name,
                    options: _.map(el?.contacts, (c) => ({
                        value: c.id,
                        label: c?.name,
                        mobile: c?.mobile,
                    }))
                });
            });

            this.setState({
                isLoading: false,
                staffList,
                supplierList,
                engineerId: this.props.match.params.engineerId
            },
            () => {
                if (this.state.engineerId) {
                    this.loadEngineerData();
                }
            });            
        });
    }

    loadEngineerData = () => {
        this.setState({
            isLoading: true,
        }, 
        () => {
            API.get('/engineers/' + this.state.engineerId)
            .then(engineerRes => {
    
                let en = engineerRes.data;
    
                this.setState({
                    ...this.state,
                    isLoading: false,
                    en,
                    formData: {
                        ...this.state.formData,
                        // Personal Details
                        mobileNumber: en?.mobile,
                        externalProfiles: en?.externalProfiles,
                        qualifications: en?.qualifications,
                    }
                });
            });
        });

    }

    handleChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        })
    }

    handleSelectChange = fieldName => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: selectedOption && selectedOption.value
            },
        }, 
        () => {
            if(fieldName == 'type' && selectedOption?.value == 'External'){
                
                this.setState({
                    formData: {
                        ...this.state.formData,
                        mobileNumber: '',
                    },
                });
            }
        });
    }

    handleSubmit = () => {
        const { engineerId } = this.state;
        const route = engineerId ? `/engineers/${engineerId}`: `/engineers`;

        API.post(route, this.getFormData())
        .then(res => {
            if(res.data.errors && res.data.errors.length > 0) {
                this.setState({
                    formErrors: formatValidationErrors(res.data.errors)
                });
            } else {
                this.setState({
                    ...initialState(),
                }, () => {
                    this.props.deploySnackBar("success", `The engineer was successfully ${engineerId ? 'updated' : 'added'}`);
                    this.loadComponentData();
                })
            }
        });
    }

    openExternalProfileDialog = (id) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                externalProfile: true,
                externalProfileId: id
            },
        });
    }

    handleProfileDelete = (id) => {

        API.post(`/engineers/${this.state.engineerId}/externalProfile/${id}/delete`)
        .then(res => {
            if(res.data.errors && res.data.errors.length > 0) {

            } else {
                this.props.deploySnackBar("success", "The profile was successfully deleted");
                this.loadComponentData();
            }
        });
    }

    openQualificationDialog = (id) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                qualification: true,
                qualificationId: id
            },
        });
    }

    handleQualificationDelete = (id) => {

        API.post(`/engineers/${this.state.engineerId}/qualification/${id}/delete`)
        .then(res => {
            if(res.data.errors && res.data.errors.length > 0) {

            } else {
                this.props.deploySnackBar("success", "The qualification was successfully deleted");
                this.loadComponentData();
            }
        });
    }

    render() {
        const { history } = this.props;
        const { formErrors, formData, isLoading, staffList, engineerId, en, dialog, supplierList } = this.state;
        return (   
            <>   
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {engineerId ? 'Update' : 'Add' } Engineer
                    </Typography>
                </Grid>     
                <Grid item xs={12}>
                    <form onSubmit={this.handleSubmit} noValidate>
                        {(isLoading && (
                            <PaddedPaper>
                                <LoadingCircle />
                            </PaddedPaper>            
                        )) || (
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <Typography variant="body1" gutterBottom>
                                        <b>Personal Details</b>
                                    </Typography>
                                    <PaddedPaper>                                
                                        <Grid container item spacing={1}>
                                            <Grid item xs={12}>
                                                {formErrors && formErrors.generic && (
                                                    <React.Fragment>
                                                        <Typography component={"div"} style={{color: 'red'}}>
                                                            {formErrors.generic}
                                                        </Typography>
                                                    </React.Fragment>
                                                )}
                                            </Grid> 
                                            <Grid item xs={12}>
                                                {engineerId ? 
                                                    <Grid container item spacing={3} alignContent='center'>
                                                        <Grid item xs={2} md={1}>
                                                            <Avatar alt={`${en?.engineer?.fn} ${en?.engineer?.ln}`} src={en?.photo}  />
                                                        </Grid>
                                                        <Grid item xs={10} md={11}>
                                                            <TextField 
                                                                label='Name'
                                                                disabled={true}
                                                                value={en?.engineer?.fn + ' ' + en?.engineer?.ln}
                                                                margin="none"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField 
                                                                label='Company'
                                                                disabled={true}
                                                                value={en?.engineer?.company}
                                                                margin="normal"
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                inputProps={{
                                                                    className:"textDefault"
                                                                }}
                                                            />
                                                        </Grid>
                                                        {en?.type === 'Internal' && (
                                                            <Grid item xs={12}>
                                                                <TextField 
                                                                    name="mobileNumber"
                                                                    label={"Work Mobile " + (engineerId ? '' : '*')}
                                                                    onChange={this.handleChange}
                                                                    error={formErrors && formErrors['mobileNumber'] && true}
                                                                    helperText={formErrors && formErrors['mobileNumber']}
                                                                    value={formData.mobileNumber}
                                                                    margin="normal"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                :
                                                    <Grid container item spacing={1}>
                                                        <Grid item xs={12}>
                                                            <AutoCompleteSelect 
                                                                options={[
                                                                    {value: 'Internal', label: 'Internal'},
                                                                    {value: 'External', label: 'External'},
                                                                ]} 
                                                                label="Type"
                                                                onChange={this.handleSelectChange('type')}
                                                                value={formData.type}
                                                                fullWidth
                                                                noClear
                                                            />
                                                        </Grid>
                                                        {formData.type === 'Internal' ?
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <AutoCompleteSelect 
                                                                        options={staffList} 
                                                                        label={"Staff Member " + (engineerId ? '' : '*')}
                                                                        onChange={this.handleSelectChange('staff')}
                                                                        error={formErrors && formErrors['staff'] && true}
                                                                        errorText={formErrors && formErrors['staff']}
                                                                        value={formData.staff}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField 
                                                                        name="mobileNumber"
                                                                        label={"Work Mobile " + (engineerId ? '' : '*')}
                                                                        onChange={this.handleChange}
                                                                        error={formErrors && formErrors['mobileNumber'] && true}
                                                                        helperText={formErrors && formErrors['mobileNumber']}
                                                                        value={formData.mobileNumber}
                                                                        margin="normal"
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </>
                                                        :
                                                            <Grid item xs={12}>
                                                                <AutoCompleteSelect 
                                                                    options={supplierList} 
                                                                    label={"Third Party " + (engineerId ? '' : '*')}
                                                                    onChange={this.handleSelectChange('supplierContact')}
                                                                    error={formErrors && formErrors['supplierContact'] && true}
                                                                    errorText={formErrors && formErrors['supplierContact']}
                                                                    value={formData.supplierContact}
                                                                    fullWidth
                                                                    isGrouped={true}
                                                                />
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                }
                                            </Grid>
                                            
                                            <Grid item xs={12}>
                                                <DragFileInput
                                                    id="photoInput"
                                                    name="photo"
                                                    label="Engineer Photo (.png, .jpg, .jpeg, .bmp, .gif)"
                                                    file={formData.photo}
                                                    errorText={formErrors && formErrors['photo']}
                                                    onChange={this.handleFileChange}
                                                    cancelOnClick={() => this.clearValue('photo')}
                                                    emptyText='No photo selected'
                                                />
                                            </Grid>
                                            {engineerId &&
                                                <Grid item xs={12} >
                                                    <div className='buttonRow'>
                                                        <BackButton props={history} />
                                                        <Button onClick={() => this.props.deployConfirmation('Are you sure you want to update this engineer?', 'Update engineer?', this.handleSubmit)}
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={Object.values(formData).every(x => (x === null || x === ""))}>
                                                            Update
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            }
                                        </Grid>  
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" gutterBottom>
                                        <b>External Profiles </b>
                                        <Button
                                            onClick={engineerId ? () => this.openExternalProfileDialog(0) : () => this.handleAddRow('externalProfiles', {name: '', details: '', file: null})}
                                            variant="outlined"
                                            size='small'
                                        >
                                            <AllIcon type="light" icon='plus' button size={15} />
                                            Add
                                        </Button>
                                    </Typography>
                                    <Typography variant="caption" paragraph>
                                        Enter the details of any external profiles linked to this engineer            
                                    </Typography>
                                    <Grid container item spacing={3}>
                                        {formData?.externalProfiles?.length > 0 ?
                                            formData?.externalProfiles.map((prof, idx) => {
                                                return (
                                                    <Grid key={idx} item xs={12} md={4}>
                                                        <PaddedPaper>
                                                            <Grid container item spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <Grid container item spacing={1} alignItems='center'>
                                                                        <Grid item xs={10}>
                                                                            {engineerId ? 
                                                                                <Typography variant="h6">
                                                                                    {prof?.nm}
                                                                                </Typography>
                                                                            :
                                                                                <TextField 
                                                                                    name="name"
                                                                                    label="Name *"
                                                                                    onChange={e => this.handleRowEdit(idx, 'name', 'externalProfiles', e?.target?.value ?? '')}
                                                                                    error={formErrors && formErrors['exProfile|'+idx] && true}
                                                                                    helperText={formErrors && formErrors['exProfile|'+idx]}
                                                                                    value={prof?.name}
                                                                                    margin="normal"
                                                                                    fullWidth
                                                                                />
                                                                            }
                                                                        </Grid>
                                                                        <Grid item xs={1} align='right'>
                                                                            {engineerId &&
                                                                                <AllIcon 
                                                                                    onClick={() => this.openExternalProfileDialog(prof?.id)}
                                                                                    type="light" 
                                                                                    noMargin 
                                                                                    icon='pencil-alt'
                                                                                    size={15} 
                                                                                    tooltip="Edit Profile"
                                                                                />
                                                                            }
                                                                        </Grid>
                                                                        <Grid item xs={1} align='right'>
                                                                            <AllIcon 
                                                                                onClick={prof?.id ? 
                                                                                    () => this.props.deployConfirmation('Are you sure you want to delete this profile?', 'Delete Profile?', () => this.handleProfileDelete(prof?.id)) 
                                                                                    : 
                                                                                    () => this.handleRemoveRow('externalProfiles', idx)
                                                                                }
                                                                                type="light" 
                                                                                noMargin 
                                                                                icon='trash-alt'
                                                                                size={15} 
                                                                                tooltip="Delete Profile"
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Textarea
                                                                        id="details"
                                                                        name="details"
                                                                        label="Details"
                                                                        value={prof?.details}
                                                                        onChange={e => this.handleRowEdit(idx, 'details', 'externalProfiles', e?.target?.value ?? '')}
                                                                        error={formErrors && formErrors['details']}
                                                                        disabled={engineerId && true}
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        inputProps={{
                                                                            className:"textDefault"
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {engineerId ? 
                                                                        <Link component="div" variant="body2" className='blueLink' style={{cursor: 'pointer'}} onClick={() => {downloadS3File(prof?.fileUrl)}}>
                                                                            {prof?.file}
                                                                        </Link>
                                                                    :
                                                                        <DragFileInput
                                                                            name="file"
                                                                            file={prof?.file}
                                                                            onChange={(drop, name, event) => this.handleRowFileChange(drop, name, event, 'externalProfiles', idx)}
                                                                            cancelOnClick={() => this.clearValue('file', idx, 'externalProfiles')}
                                                                            emptyText='No file selected'
                                                                        />
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </PaddedPaper>
                                                    </Grid>
                                                )
                                            })
                                        :
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Typography variant="body2">
                                                        <em>No External Profiles</em>       
                                                    </Typography>
                                                </PaddedPaper>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" gutterBottom>
                                        <b>Qualifications </b>
                                        <Button
                                            onClick={engineerId ? () => this.openQualificationDialog(0) : () => this.handleAddRow('qualifications', {name: '', details: '', expiryDate: null, file: null})}
                                            variant="outlined"
                                            size='small'
                                        >
                                            <AllIcon type="light" icon='plus' button size={15} />
                                            Add
                                        </Button>
                                    </Typography>
                                    <Typography variant="caption" paragraph>
                                        Enter the details of any qualifications linked to this engineer            
                                    </Typography>
                                    <Grid container item spacing={3}>
                                        {formData?.qualifications?.length > 0 ?
                                            formData?.qualifications.map((qual, idx) => {
                                                return (
                                                    <Grid key={idx} item xs={12} md={4}>
                                                        <PaddedPaper>
                                                            <Grid container item spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <Grid container item spacing={1} alignItems='center'>
                                                                        <Grid item xs={10}>
                                                                            <TextField 
                                                                                name="name"
                                                                                label={"Name " + (engineerId ? '' : '*')}
                                                                                onChange={e => this.handleRowEdit(idx, 'name', 'qualifications', e?.target?.value ?? '')}
                                                                                error={formErrors && formErrors['qual|'+idx] && true}
                                                                                helperText={formErrors && formErrors['qual|'+idx]}
                                                                                value={qual?.name}
                                                                                margin="normal"
                                                                                fullWidth
                                                                                disabled={engineerId && true}
                                                                                InputLabelProps={{
                                                                                    shrink: true
                                                                                }}
                                                                                inputProps={{
                                                                                    className:"textDefault"
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={1} align='right'>
                                                                            {engineerId &&
                                                                                <AllIcon 
                                                                                    onClick={() => this.openQualificationDialog(qual?.id)}
                                                                                    type="light" 
                                                                                    noMargin 
                                                                                    icon='pencil-alt'
                                                                                    size={15} 
                                                                                    tooltip="Edit Qualification"
                                                                                />
                                                                            }
                                                                        </Grid>
                                                                        <Grid item xs={1} align='right'>
                                                                            <AllIcon 
                                                                                onClick={qual?.id ? 
                                                                                    () => this.props.deployConfirmation('Are you sure you want to delete this qualification?', 'Delete Qualification?', () => this.handleQualificationDelete(qual?.id)) 
                                                                                    : 
                                                                                    () => this.handleRemoveRow('qualifications', idx)
                                                                                }
                                                                                type="light" 
                                                                                noMargin 
                                                                                icon='trash-alt'
                                                                                size={15} 
                                                                                tooltip="Delete Qualification"
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Textarea
                                                                        id="details"
                                                                        name="details"
                                                                        label="Details"
                                                                        value={qual?.details}
                                                                        onChange={e => this.handleRowEdit(idx, 'details', 'qualifications', e?.target?.value ?? '')}
                                                                        error={formErrors && formErrors['details']}
                                                                        disabled={engineerId && true}
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        inputProps={{
                                                                            className:"textDefault"
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <DatePicker
                                                                        type="date"
                                                                        name="expiryDate"
                                                                        label="Expiry Date"
                                                                        value={qual?.expiryDate !== '0000-00-00' ? qual?.expiryDate : null}
                                                                        onChange={date => this.handleRowDateChange('qualifications', idx, 'expiryDate', date, 'YYYY-MM-DD')}
                                                                        autoOk={true}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {engineerId ? 
                                                                        <Link component="div" variant="body2" className='blueLink' style={{cursor: 'pointer'}} onClick={() => {downloadS3File(qual?.fileUrl)}}>
                                                                            {qual?.file}
                                                                        </Link>
                                                                    :
                                                                        <DragFileInput
                                                                            name="file"
                                                                            file={qual?.file}
                                                                            onChange={(drop, name, event) => this.handleRowFileChange(drop, name, event, 'qualifications', idx)}
                                                                            cancelOnClick={() => this.clearValue('file', idx, 'qualifications')}
                                                                            emptyText='No file selected'
                                                                        />
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </PaddedPaper>
                                                    </Grid>
                                                )
                                            })
                                        :
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Typography variant="body2">
                                                        <em>No Qualifications</em> 
                                                    </Typography>
                                                </PaddedPaper>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                {!engineerId &&
                                    <Grid item xs={12} align="right">
                                        <Button onClick={() => this.props.deployConfirmation('Are you sure you want to add this new engineer?', 'Add a new engineer?', this.handleSubmit)}
                                                variant="contained"
                                                color="primary"
                                                disabled={Object.values(formData).every(x => (x === null || x === ""))}>
                                            Add
                                        </Button>
                                    </Grid>
                                }
                            </Grid> 
                        )}
                    </form>
                </Grid>  
            </Grid>
            <SmallFormDialog
                open={dialog.externalProfile}
                onClose={() => this.toggleDialog('externalProfile')}
                content={
                    <EngineerExternalProfileForm 
                        onClose={() => this.toggleDialog('externalProfile', true)}
                        profileId={dialog?.externalProfileId}
                        engineerId={engineerId}
                        refresh={this.loadComponentData}
                    />
                }
                maxWidth='sm'
            />
            <SmallFormDialog
                open={dialog.qualification}
                onClose={() => this.toggleDialog('qualification')}
                content={
                    <EngineerQualificationForm 
                        onClose={() => this.toggleDialog('qualification', true)}
                        qualificationId={dialog?.qualificationId}
                        engineerId={engineerId}
                        refresh={this.loadComponentData}
                    />
                }
                maxWidth='sm'
            />
            </>  
        );
    }
}

const mapDispatchToProps = dispatch => (
    {
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        deployConfirmation: (message, title, success)   => dispatch(deployConfirmation(message, title, success)),
    }
)

export default connect(null, mapDispatchToProps)(EngineerForm);