import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

import MessagesHeader from 'Components/Messages/MessagesHeader';
import Messages from 'Components/Messages/Messages';
import theme from 'Components/Theme/Theme';
import { loadStaffStatus } from 'Actions/Notifications/Notifications';

const useStyles = makeStyles({
    container: {
        marginTop: 68,
        width: '100%'
    }
})

const MessagePopout = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(loadStaffStatus())
    }, [dispatch])

    return (
        <ThemeProvider theme={theme}>
            <MessagesHeader />
            <Box className={classes.container}>
                <Messages open={true} />
            </Box>
        </ThemeProvider>
    )
}

export default MessagePopout;