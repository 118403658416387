import { Grid, InputAdornment, TextField, Button } from '@material-ui/core';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import React from 'react';
import _ from 'lodash';
import API from 'API';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { connect }          from 'react-redux';
import { closeDialog } from 'Actions/Dialog/Dialog';
import moment from 'moment';
import DatePicker                        from 'Components/Common/DatePickers/DatePicker';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

const initialState = {
    isLoading: true,
    formData: {
        paymentAmount:    0.00,
        outStanding:      0.00,
        request:          0.00,
        requestRemaining: 0.00,
        requestAmount:    0.00,
        requestTotal:     0.00,
        date:             moment().format('YYYY-MM-DD'),
    },
    payments: [],
    formErrors: {},
}

class AssignRequestedPaymentDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...initialState, formData: {...initialState.formData, paymentAmount: props.invoice.amount_outstanding, outStanding: props.invoice.amount_outstanding}};
    }

    componentDidMount() {
        this.getPayments();
    }

    getPayments = () => {
        API.get(`/accounts/customerPaymentRequests`, {params: { customer: this.props.invoice.invoice_cust_id, status: 'Paid'  }}) 
        .then(res => {
            this.setState({
                payments: _.map(res.data, i => ({ label: `£${parseFloat(i.remaining_funds).toFixed(2)}`, value: i.crp_id, item: i })), 
                isLoading: false
            });
        })
    }

    handleAmountChange = (toFixed=false) => e => {
        const { value } = e.target;

        this.setState(state => ({
            ...state,
            formData: {
                ...state.formData,
                paymentAmount:      toFixed ? parseFloat(value).toFixed(2) : value,
                requestRemaining:   parseFloat(this.state.formData.requestTotal - value).toFixed(2),
            }
        }));
    }

    handleSelectPayment = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                request: e ? e.value : null,
                requestRemaining: e ? ( e.item.remaining_funds ) - this.state.formData.paymentAmount : null,
                requestAmount:    e ? ( e.item.remaining_funds ) : null,
                requestTotal:     e ? ( e.item.remaining_funds ) : null,
                date:             e ? moment(e.item.payments.crpp_payment_datetime).format('YYYY-MM-DD') : null,
            }
        })
    }

    submit = () => {
        API.post(`/sales/orders/invoice/${this.props.invoice.invoice_id}/processPayment`, {
            invoice:          this.props.invoice.invoice_id,
            requestedPayment: this.state.formData.request,
            paymentAmount:    this.state.formData.paymentAmount,
            paymentType:      'Assign',
            paymentMethod:    'fromRequest',
            date:             this.state.formData.date,
        })
        .then(res => {
            if (res.data.errors){
                this.setState({ formErrors: formatValidationErrors(res.data.errors) });
            } else {
                this.props.closeDialog();
                this.props.callback();
            }
        })
    }

    handleDateChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                date: moment(e).format('YYYY-MM-DD')
            }
        });
    }

    render() {
        const { isLoading, formData, payments, formErrors } = this.state;

        if ( isLoading ) return ( <LoadingCircle /> );

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Payment Outstanding"
                        name="outStanding"
                        value={formData.outStanding}
                        disabled
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <AutoCompleteSelect
                        options={payments}
                        onChange={this.handleSelectPayment}
                        label="Payment Request *"
                        fullWidth
                        value={formData.request}
                    />
                </Grid>
                {!!formData.request && [
                    <Grid item xs={12}>
                        <TextField
                            label="Payment Amount *"
                            name="paymentAmount"
                            type='number'
                            value={formData.paymentAmount}
                            onChange={this.handleAmountChange(false)}
                            onBlur={this.handleAmountChange(true)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                            }}
                            fullWidth
                            error={!!formErrors['paymentAmount']}
                            helperText={formErrors['paymentAmount']}
                        />
                    </Grid>,
                    <Grid item xs={6}>
                        <TextField
                            label="Request Remaining"
                            name="requestRemaining"
                            value={formData.requestRemaining}
                            disabled
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                            }}
                            fullWidth
                        />
                    </Grid>,
                    <Grid item xs={6}>
                        <TextField
                            label="Invoice Remaining"
                            name="requestRemaining"
                            value={parseFloat(formData.outStanding - formData.paymentAmount).toFixed(2)}
                            disabled
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>,
                            }}
                            fullWidth
                        />
                    </Grid>,
                    <Grid item xs={12}>
                        <DatePicker
                            type="date"
                            id="date"
                            name="date"
                            label="Payment Date *"
                            value={formData.date}
                            onChange={this.handleDateChange}
                            autoOk={true}
                        />
                    </Grid>
                ]}
                <Grid item xs={12} className='buttonRow'>
                    <Button 
                        variant='outlined'
                        onClick={this.props.closeDialog}
                    >Close</Button>
                    <Button 
                        variant='contained' 
                        color='primary'
                        disabled={ (formData.outStanding - formData.paymentAmount) < 0 || formData.requestRemaining < 0 || formData.paymentAmount <= 0 || !formData.request }
                        onClick={this.submit}
                    >Assign Payment</Button>
                </Grid>
            </Grid>
        );

    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog: () => dispatch(closeDialog()),
})

export default connect(null, mapDispatchToProps)(AssignRequestedPaymentDialog);