import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { Grid, Button, Box } from '@material-ui/core';
import API from 'API';

const initialState = props => ({
    isLoading: true,
    campaigns: [],
    formData: {
        mlId: props.mlId,
        campaignId: null
    }
})

class LinkEmailCampaignDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getCampaigns();
    }

    getCampaigns = () => {
        API.get('/marketing/campaigns/emailCampaign', {params: {excludeMlId: this.state.formData.mlId}})
        .then(res => {
            this.setState({
                campaigns: res.data,
                isLoading: false
            })
        })
    }

    handleSubmit = () => {
        API.post('/marketing/campaigns/linkEmailCampaignToMailingList', this.state.formData)
        .then(res => {
            this.props.onClose();
        })
    }

    render() {
        const { isLoading, campaigns } = this.state;

        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        return (
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <AutoCompleteSelect
                            label="Email Campaign"
                            options={_.map(campaigns, i => ({
                                value: i.i,
                                label: i.n
                            }))}
                            value={this.state.formData.campaignId}
                            onChange={({value})=>{
                                this.setState({
                                    formData: {
                                        ...this.state.formData,
                                        campaignId: value
                                    }
                                })
                            }}
                            noClear
                            disableSort
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        {/*}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.props.onNewCampaign}
                        >
                            Create New Email Campaign
                        </Button>
                        */}
                        <Button
                            variant="outlined"
                            onClick={this.props.onClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                ()=>{
                                    this.props.deployConfirmation(
                                        'Are you sure you want to link this email campaign to this mailing list?',
                                        'Link Email Campaign to Mailing List',
                                        this.handleSubmit
                                    )
                                }
                            }
                            disabled={!this.state.formData.campaignId}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (content, title, callback) => {
            dispatch(deployConfirmation(content, title, callback))
        },
    }
}

export default connect(null, mapDispatchToProps)(LinkEmailCampaignDialog);