import React from 'react';

import _ from 'lodash';
import API from 'API';

import { formatValidationErrors } from 'Helpers/ErrorHelper';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MaterialLink from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import DataTable from 'Components/Common/DataTables/DataTable';

const styles = theme => ({

});

const initialState = {
    formData: {
        type: '',
        quotation: {
            category: '',
            subCategory: '',
            document: ''
        },
        order: {
            category: '',
            subCategory: '',
            document: ''
        },
        deleteLegalId: ''
    },
    legalDocuments: [],
    formErrors: [],
    categoryList: [],
    subCategoryList: [],
    documentList: [],
    categories: {},
    documents: {},
    quotationConfirmationOpen: false,
    quotationSuccessSnackbarOpen: false,
    orderConfirmationOpen: false,
    orderSuccessSnackbarOpen: false,
    deleteOpen: false,
    deleteSnackbarOpen:false
}

class LegalDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getCategories();
        this.getDocuments();
        this.getLegalDocuments();
    }

    getCategories = () => {
        API.get('/documents/categories/all').then(result => {
            if(result.data) {
                let categoryList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.doc_cat_id,
                        label: el.doc_cat_name
                    });
                });
                this.setState({
                    categoryList: categoryList,
                    categories: result.data
                });
            }
        });
    };

    getDocuments = () => {
        API.get('/documents/all').then(result => {
            if(result.data) {
                this.setState({
                    documents: result.data
                });
            }
        });
    };

    getLegalDocuments = () => {
        API.get('/sales/legalDocuments')
        .then(result => {
            if(result.data) {
                let legalDocs = [];
                _.map(result.data, (doc) => {
                    if(!legalDocs[doc.legal_type]){legalDocs[doc.legal_type] = []}
                    legalDocs[doc.legal_type].push({
                        document: doc.document.doc_title,
                        url: doc.document.latest_file.file_pdf_url,
                        version: doc.document && doc.document.latest_file && doc.document.latest_file.file_version,
                        legalId: doc.legal_id
                    });
                });
                this.setState({
                    legalDocuments: legalDocs
                });
            }
        });
    };

    handleSelectChange = (type, fieldName) => selectedOption => {
        this.setState({
            formData: {
                ...this.state.formData,
                [type]: {
                    ...this.state.formData[type],
                    [fieldName]: selectedOption && selectedOption.value
                }
            }
        }, 
        () => {
            if(fieldName === 'category'){
                 this.setState({
                    formData: {
                        ...this.state.formData,
                        [type]: {
                            ...this.state.formData[type],
                            subCategory: null,
                            document: null
                        }
                    }
                },
                () => {
                    this.populateSubCategories(selectedOption && selectedOption.value, type);
                });
            }
            else if(fieldName === 'subCategory'){
                this.setState({
                    formData: {
                        ...this.state.formData,
                        [type]: {
                            ...this.state.formData[type],
                            document: null
                        }
                    }
                },
                () => {
                    this.populateDocuments(selectedOption && selectedOption.value, type);
                });
            }
        });
    };

    populateSubCategories = (categoryID) => {
        if(categoryID){
            let catList = _.find(this.state.categories, { 'doc_cat_id': categoryID });

            let subCategoryList = _.map(catList.children, (el) => {
                return _.assign({
                    value: el.doc_cat_id,
                    label: el.doc_cat_name
                });
            });
            this.setState({
                subCategoryList: subCategoryList
            });
        }
        else {
            this.setState({
                subCategoryList: initialState.subCategoryList,
                documentList: initialState.documentList,
                formData: {
                    ...this.state.formData,
                    subCategory: null,
                    document: null
                }
            });
        }
    };

    populateDocuments = (subCategoryID, type) => {
        if(subCategoryID){
            let docList = _.filter(this.state.documents, { 'doc_category_id': subCategoryID });

            let documentList = _.map(docList, (el) => {
                return _.assign({
                    value: el.doc_id,
                    label: el.doc_title
                });
            });
            this.setState({
                documentList: documentList
            });
        }
        else {
            this.setState({
                documentList: initialState.documentList,
                formData: {
                    ...this.state.formData,
                    [type]: {
                        ...this.state.formData[type],
                        document: null
                    }
                }
            });
        }
    };

    handleChange = (event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value
            }
        });
    }

    handleConfirmationOpen = type => {
        switch(type){
            case 'quotation':
                this.setState({
                    quotationConfirmationOpen: true
                });
            break;
            case 'order':
                this.setState({
                    orderConfirmationOpen: true
                });
            break;
            default:
            break;
        }
    };

    handleConfirmationClose = type => {
        switch(type){
            case 'quotation':
                this.setState({
                    quotationConfirmationOpen: false
                });
            break;
            case 'order':
                this.setState({
                    orderConfirmationOpen: false
                });
            break;
            default:
            break;
        }
    };

    handleConfirmationSuccess = type => () => {
        let quotationConfirmationOpen = false;
        let orderConfirmationOpen = false;
        this.setState({ 
            quotationConfirmationOpen,
            orderConfirmationOpen,
            formErrors: [],
            formData: {
                ...this.state.formData,
                type: type
            },
        }, 
        () => {
            this.submit(type);
        });
    }

    submit = type => {
        let quotationSuccessSnackbarOpen = false,
            orderSuccessSnackbarOpen = false;

        switch(type){
            case 'quotation':
                quotationSuccessSnackbarOpen = true;
            break;
            case 'order':
                orderSuccessSnackbarOpen = true;
            break;
            default:
            break;
        }
        API.post('/sales/legalDocuments', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState,
                    quotationSuccessSnackbarOpen,
                    orderSuccessSnackbarOpen
                });
                this.getCategories();
                this.getDocuments();
                this.getLegalDocuments();
            }
        });
    }

    downloadFile = (fileUrl) => {
        window.open(fileUrl, '_blank');
    }

    deleteDocument() {
        API.post(`/sales/legalDocuments/delete`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState,
                    deleteSnackbarOpen: true
                });
                this.getCategories();
                this.getDocuments();
                this.getLegalDocuments();
            }
        });
    }

    handleDeleteOpen = (rowData) => {
        this.setState({
            formData:{
                ...this.state.formData,
                deleteLegalId: rowData.legalId
            },
            deleteOpen: true,
        });
    }

    handleDeleteClose = () => {
        this.setState({ 
            deleteOpen: false 
        });
    }

    handleDeleteSuccess = () => {
        this.setState({ 
            deleteOpen: false 
        });
        this.deleteDocument();
    }

    handleSnackbarClose = () => {
        this.setState({ orderSuccessSnackbarOpen: false, quotationSuccessSnackbarOpen: false, deleteSnackbarOpen: false });
    }

    render() {
        const { legalDocuments, formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Legal Documents
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Typography variant="h6" gutterBottom>
                                    Quotations
                                </Typography>
                                <Typography color="textSecondary" gutterBottom>
                                    The terms & conditions document(s) that will be sent alongside each quotation
                                </Typography>
                                <br></br>
                                <Typography color="textPrimary" gutterBottom>
                                    Current Document(s):
                                </Typography>
                                <DataTable 
                                    config={{
                                        pagination: false,
                                        alternatingRowColours: true
                                    }}
                                    columns={[
                                        {
                                            heading: 'Document',
                                            field: rowData =>   <MaterialLink component="button" variant="body1" className='blueLink' onClick={() => {this.downloadFile(rowData.url)}}>
                                                                    {(rowData.document + ' (v' + rowData.version + ')') || '-'}
                                                                </MaterialLink>
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Delete', icon: 'times', onClick: this.handleDeleteOpen},
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={legalDocuments.quotation}
                                />
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.categoryList} 
                                        label='Category'
                                        onChange={this.handleSelectChange('quotation', 'category')}
                                        value={this.state.formData.quotation.category}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.subCategoryList} 
                                        label='Sub Category'
                                        onChange={this.handleSelectChange('quotation', 'subCategory')}
                                        value={this.state.formData.quotation.subCategory}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.documentList} 
                                        label='Document *'
                                        onChange={this.handleSelectChange('quotation', 'document')}
                                        error={formErrors && formErrors['document'] && true}
                                        errorText={formErrors && formErrors['document']}
                                        value={this.state.formData.quotation.document}
                                    />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button 
                                        onClick={() => this.handleConfirmationOpen('quotation')}
                                        variant="contained"
                                        color="primary">
                                        Add
                                    </Button>
                                </div>
                                <ConfirmationDialog 
                                    open={this.state.quotationConfirmationOpen} 
                                    success={this.handleConfirmationSuccess('quotation')} 
                                    close={() => this.handleConfirmationClose('quotation')} 
                                    title="Add The Legal Document For Quotations?" 
                                    message="Are you sure you want to add the legal document for quotations?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={this.state.quotationSuccessSnackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message='You have successfully added the legal document for quotations'
                                />

                                <ConfirmationDialog 
                                    open={this.state.deleteOpen} 
                                    success={this.handleDeleteSuccess} 
                                    close={this.handleDeleteClose} 
                                    title="Delete Document?" 
                                    message="Are you sure you want to delete this document?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={this.state.deleteSnackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message='You have successfully deleted the document'
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <Typography variant="h6" gutterBottom>
                                    Orders
                                </Typography>
                                <Typography color="textSecondary" gutterBottom>
                                    The terms & conditions document(s) that will be sent alongside each order
                                </Typography>
                                <br></br>
                                <Typography color="textPrimary" gutterBottom>
                                    Current Document(s):
                                </Typography>
                                <DataTable 
                                    config={{
                                        pagination: false,
                                        alternatingRowColours: true
                                    }}
                                    columns={[
                                        {
                                            heading: 'Document',
                                            field: rowData =>   <MaterialLink component="button" variant="body1" className='blueLink' onClick={() => {this.downloadFile(rowData.url)}}>
                                                                    {(rowData.document + ' (v' + rowData.version + ')') || '-'}
                                                                </MaterialLink>
                                        },
                                        {
                                            actions: rowData => {
                                                return [
                                                    {name: 'Delete', icon: 'times', onClick: this.handleDeleteOpen},
                                                ]
                                            }
                                        }
                                    ]}
                                    rows={legalDocuments.order}
                                />
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.categoryList} 
                                        label='Category'
                                        onChange={this.handleSelectChange('order', 'category')}
                                        value={this.state.formData.order.category}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.subCategoryList} 
                                        label='Sub Category'
                                        onChange={this.handleSelectChange('order', 'subCategory')}
                                        value={this.state.formData.order.subCategory}
                                    />
                                </FormControl>
                                <FormControl margin="normal" fullWidth>
                                    <AutoCompleteSelect 
                                        options={this.state.documentList} 
                                        label='Document *'
                                        onChange={this.handleSelectChange('order', 'document')}
                                        error={formErrors && formErrors['document'] && true}
                                        errorText={formErrors && formErrors['document']}
                                        value={this.state.formData.order.document}
                                    />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button 
                                        onClick={() => this.handleConfirmationOpen('order')}
                                        variant="contained"
                                        color="primary">
                                        Add
                                    </Button>
                                </div>
                                <ConfirmationDialog 
                                    open={this.state.orderConfirmationOpen} 
                                    success={this.handleConfirmationSuccess('order')} 
                                    close={() => this.handleConfirmationClose('order')} 
                                    title="Add The Legal Document For Order?" 
                                    message="Are you sure you want to add the legal document for order?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={this.state.orderSuccessSnackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message='You have successfully added the legal document for order'
                                />

                                <ConfirmationDialog 
                                    open={this.state.deleteOpen} 
                                    success={this.handleDeleteSuccess} 
                                    close={this.handleDeleteClose} 
                                    title="Delete Document?" 
                                    message="Are you sure you want to delete this document?"
                                />
                                <SnackBar
                                    variant="success"
                                    anchorOriginVertical='bottom'
                                    anchorOriginHorizontal='right'
                                    open={this.state.deleteSnackbarOpen}
                                    onClose={this.handleSnackbarClose}
                                    message='You have successfully deleted the document'
                                />
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default (withStyles(styles)(LegalDocuments));
