import {
    Box,
    Button,
    DialogActions,
    Grid,
    Typography
} from '@material-ui/core';
import API from 'API';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';
import MediaTable from 'Components/Common/DataTables/MediaTable';
import SimpleMediaCard from 'Components/Common/EngineerMedia/SimpleMediaCard';
import AllIcon from 'Components/Common/Icons/AllIcon';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import icons from 'Helpers/IconHelper';
import { each, map, merge, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const FormEngineerMediaList = ({ attachmentList, removeFromList, readOnly }) => {

    return (
        <Grid item xs={12}>
            <Grid container item spacing={1}>
                {attachmentList.length === 0 ?
                    <Grid item xs={12}>
                        <Typography variant="caption" paragraph>
                            <i>No Engineer Media Added</i>
                        </Typography>
                    </Grid>
                :
                <>
                    {map(attachmentList, (info, idx) => (
                        <>
                            <Grid item xs={4} key={idx}>
                                <SimpleMediaCard 
                                    media={info} 
                                    actions={[
                                        {
                                            name: 'Remove',
                                            icon: 'times',
                                            color: 'red',
                                            onClick: () => removeFromList(info?.id)
                                        }
                                    ]}
                                    readOnly={readOnly}
                                />
                            </Grid>
                        </>
                    ))}
                </>
                }   
            </Grid>
        </Grid>
    )
}

const EngineerMediaDialog = ({ engineerMedia, toggleDialog, customerId, readOnly, formId, callBack}) => {

    const [engineerMediaList, setEngineerMediaList] = useState([]); 
    const [attachmentList, setAttachmentList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

	useEffect(() => {
        setIsLoading(true);
        API.get('/sales/orders/engineerMedia', {params: { customerId, slim: true }})
        .then(res => {

            map(res.data, i => {
                i.type = 'Engineer Media';
            })

            setEngineerMediaList(res.data);

            if(engineerMedia){
                setAttachmentList(engineerMedia);
            }
            setIsLoading(false);
        })
	}, []);

    const addToList = (object) => {
        let list = [...attachmentList];
        list.push(object);
        setAttachmentList(list);
    }

    const removeFromList = (id) => {
        
        let infoList = [...attachmentList];

        let idx = infoList.findIndex((el) => {
            return el.id === id;
        });

        infoList.splice(idx, 1);
        setAttachmentList(infoList);
    }

    const submit = () => {

        API.post(`/forms/${formId}/attachments`, { attachments: attachmentList, type: 'Engineer Media' })
        .then(result => {
            if(result.data?.success){
                toggleDialog('informationForm', true);
                dispatch(deploySnackBar('success', 'Engineer Media updated successfully'));

                if(callBack){
                    callBack();
                }
            }

        });
        
    }

    return (
        <>
        <Grid container item spacing={3}>
            {(isLoading && (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            )) || (
                <>
                {!readOnly && (
                    <Grid item xs={6}>
                        <Grid container item spacing={3}>
                            <Grid item xs={12}>
                                <MediaTable
                                    config={{
                                        isLoading:  isLoading,
                                        key:        'id',
                                        itemPerRow: 4,
                                        mobileItemPerRow: 1,
                                        pagination: true,
                                        filterInPagination: false,
                                    }}
                                    media='fileUrl'
                                    fields={[
                                        {
                                            heading: 'Product Type',
                                            field:   'productType',
                                            format:  'newLine',
                                        },
                                        {
                                            heading: 'Site',
                                            field:   i => 
                                                i.site ? 
                                                    `${i.site.name} (${i.site.postcode})`:
                                                (
                                                    i?.orders?.length > 0 ?
                                                        `${ i.orders[0].delAddressName} (${i.orders[0].delAddressPostcode})`:
                                                        (
                                                            i?.quotes?.length > 0 ?
                                                                `${ i.quotes[0].delAddressName} (${i.quotes[0].delAddressPostcode})` :
                                                                'No Site'
                                                        )
                                                ),
                                            format:  'newLine',
                                        },
                                        {
                                            heading: 'Location',
                                            field:   'location',
                                            format:  'newLine',
                                        },
                                        {
                                            heading: 'Description',
                                            field:   'desc',
                                            format:  'newLine',
                                        },
                                        {
                                            heading: 'Serial Numbers',
                                            field:   rowData => rowData?.serialNumbers?.length > 0  && <>
                                                <br/>
                                                <Grid container style={{justifyContent: 'space-between'}}>
                                                    {map(rowData.serialNumbers, i => 
                                                        <>
                                                            <Grid item key={i.sn}>
                                                                {i.sn}
                                                            </Grid>    
                                                            <Grid item key={i.sn}>
                                                                <AllIcon 
                                                                    size='small'
                                                                    icon={icons.serialNumber}
                                                                    onClick={()=>window.open(`/parts/serial-numbers/history/${i.sn}`,'_blank')}
                                                                    noPadding 
                                                                    noMargin
                                                                />
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </>,
                                        },
                                        {
                                            field: rowData => 
                                                <Grid container>
                                                    {( rowData?.orders?.length > 0 || rowData?.quotes?.length > 0 ) && 
                                                        <>
                                                            <Grid item container>
                                                                <Grid item xs={12}><b style={{marginRight:'0.5em'}}>Orders:</b></Grid>
                                                                {rowData?.orders?.length > 0 ? map(rowData.orders, o => 
                                                                    <Grid item xs={12} container key={o.id} style={{justifyContent: 'space-between'}}>
                                                                        <Grid item>{o.ref}</Grid>
                                                                        <Grid item>
                                                                            <AllIcon icon={icons.sales} noPadding noMargin onClick={()=>window.location.href = `/sales/order/view/${o.id}`} />
                                                                        </Grid>
                                                                    </Grid>
                                                                ) : 'None Linked'}
                                                            </Grid>
                                                            <Grid item container>
                                                                <Grid item xs={12}><b style={{marginRight:'0.5em'}}>Quotes:</b></Grid>
                                                                {rowData?.quotes?.length > 0 ? map(rowData.quotes, o => 
                                                                    <Grid item xs={12} container key={o.id} style={{justifyContent: 'space-between'}}>
                                                                        <Grid item>{o.ref}</Grid>
                                                                        <Grid item>
                                                                            <AllIcon icon={icons.sales} noPadding noMargin onClick={()=>window.location.href = `/sales/quotation/view/${o.id}`} />
                                                                        </Grid>
                                                                    </Grid>
                                                                ) : 'None Linked'}
                                                            </Grid>
                                                        </>
                                                    }
                                                    {( rowData.notes ) && 
                                                        <Grid item container>
                                                            <Grid item xs={12}><b style={{marginRight:'0.5em'}}>Notes:</b></Grid>
                                                            <Grid item xs={12}>{rowData.notes}</Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                        },
                                        {
                                            actions: rowData => [
                                                {
                                                    download: true,
                                                },
                                                {
                                                    view: true,
                                                },
                                                {
                                                    name:    'Add',
                                                    icon:    icons.plus,
                                                    onClick: (e) => addToList(e) 
                                                },
                                            ]
                                        }
                                    ]}
                                    filters={[
                                        {
                                            type:       'search',
                                            dataRef:    i => {
                                                let ret = `${i.fileUrl}|${i.location}|${i.desc}`;
                                                each(i.orders, o => ret += `|${o.ref}`);
                                                each(i.quotes, o => ret += `|${o.ref}`);
                                                each(i.serialNumbers, o => ret += `|${o.sn}`);
                                                return ret;
                                            },
                                        },
                                        {
                                            type:       'select',
                                            dataRef:    'location',
                                            label:      'Location',
                                        },
                                        {
                                            type:       'select',
                                            dataRef:    'productType',
                                            label:      'Type',
                                        },
                                        {
                                            type:       'select',
                                            dataRef:    rowData => map(rowData.orders, o => o.ref),
                                            label:      'Order',
                                        },
                                        {
                                            type:       'select',
                                            dataRef:    rowData => map(rowData.quotes, o => o.ref),
                                            label:      'Quote',
                                        },
                                        {
                                            type:       'select',
                                            dataRef:    rowData => map(rowData.serialNumbers, o => o.sn),
                                            label:      'Serial Number',
                                        },
                                        {
                                            type:       'select',
                                            dataRef:    rowData => [...merge(
                                                map(rowData.orders, o => `${o.delAddressPostcode} | ${o.delAddressName}`),
                                                map(rowData.quotes, o => `${o.delAddressPostcode} | ${o.delAddressName}`),
                                            ),  ...rowData.site ? [`${rowData.site.postcode} | ${rowData.site.name}`] : []],
                                            label:      'Site',
                                        },
                                        {
                                            type:       'fileType',
                                        },
                                        {
                                            type:       'reset',
                                        }
                                    ]}
                                    items={orderBy(engineerMediaList, [ 'id' ], [ 'desc' ])}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={readOnly ? 12 : 6}>
                    {readOnly ? 
                        <Grid container item spacing={1}>
                            <FormEngineerMediaList attachmentList={attachmentList} removeFromList={removeFromList} readOnly={readOnly} />
                        </Grid>
                    :
                        <Box style={{backgroundColor: '#f2f2f2', padding: 15}}>
                            <Typography variant="caption" paragraph>
                                <b>Engineer Media List</b>
                            </Typography>
                            <Grid container item spacing={1}>
                                <FormEngineerMediaList attachmentList={attachmentList} removeFromList={removeFromList} readOnly={readOnly} />
                            </Grid>
                        </Box>
                    }
                </Grid>
                </>
            )}
        </Grid>
        <DialogActions className='pr-0 pb-0'>
            <Button 
                onClick={() => toggleDialog('informationForm', true)}
                variant="text"
            >
                <FALightIcon icon="times" size={15} button />
                Close
            </Button>
            {!readOnly && (
                <Button 
                    color="primary"
                    onClick={() => submit()}
                    variant="contained"
                    disabled={isLoading}
                >
                    <FALightIcon icon="check" size={15} color="white" />
                    Update
                </Button>
            )}
        </DialogActions>
        </> 
    );
};

export default EngineerMediaDialog;
