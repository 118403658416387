import React from 'react';
import _ from 'lodash';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import DataTable from '../../Common/DataTables/DataTable';
import moment from 'moment';

class CustomerDiscountList extends React.Component {

    render() {
        const { discounts } = this.props;
        return (
            _.map(discounts, (name, idx) => {
                return (
                    <ExpansionPanel key={idx}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{name.cdn_name}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} >
                                    {(name.discount_values && name.discount_values.length > 0 && (
                                        <DataTable  
                                            config={{
                                                key: 'cdv_id',
                                                responsiveImportance: true,
                                                plainHeader: true,
                                                rowProps: row => {
                                                    return ({
                                                        style: {
                                                            backgroundColor: row.current === true && 'rgb(241, 248, 233)'
                                                        }
                                                    });
                                                }
                                            }}
                                            columns={[
                                                {
                                                    heading: 'Percentage',
                                                    field: rowData => rowData.cdv_percentage,
                                                    fieldSuffix: '%',
                                                },
                                                {
                                                    heading: 'Start Date',
                                                    field: rowData => moment(new Date(rowData.cdv_start_date)).format('DD/MM/YYYY'),
                                                    sizeToContent: true,
                                                    important: true,
                                                    alignment: 'right'
                                                },
                                                {
                                                    heading: 'End Date',
                                                    field: rowData => (rowData.cdv_end_date === '0000-00-00' && !rowData.current)  ?
                                                                            '-'
                                                                        :
                                                                        (rowData.cdv_end_date === '0000-00-00' ? 'Current' :  moment(new Date(rowData.cdv_end_date)).format('DD/MM/YYYY')),
                                                    sizeToContent: true,
                                                    important: true,
                                                    alignment: 'right'
                                                }
                                            ]}
                                            rows={name.discount_values}
                                        />
                                    )) || (
                                        <List>
                                            <ListItem>
                                                <ListItemText primary={<em>No discounts to display</em>} />
                                            </ListItem>
                                        </List>
                                    )}
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            })
        );
    }
}

export default CustomerDiscountList;