import React from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const MessagesLanding = ({toggleDrawer}) => (
    <Box p={1.5}>
        {toggleDrawer && (
            <div align='right'>
                <IconButton onClick={() => toggleDrawer('chat')}>
                    <FAIcon type="light" icon="times" noMargin button />
                </IconButton>
            </div>
        )}
        <div style={{position: 'absolute', top: '50%', height: 200, marginTop: -100, textAlign: 'center', width: 'calc(100% - 24px)'}}>
            <FAIcon type="duo" icon='comment-alt-lines' size={100} style={{color: '#777'}} noMargin /><br /><br />
            <Typography variant="body2">
                Select a colleague to start a<br />conversation
            </Typography>
        </div>
    </Box>
)

export default MessagesLanding;