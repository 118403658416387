import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import InfoBox from 'Components/Common/reports/InfoBox';
import IconHelper from 'Helpers/IconHelper';
import React from 'react';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { getPercentage } from 'Helpers/ReportsHelper';


const initialState = () => ({})

class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    render() {

        const { survey, pendingContacts } = this.props;

        let uniqPending = [];
        _.each(pendingContacts, i => {
            uniqPending = [
                ...uniqPending,
                ..._.map(i.contacts, c => c.id)
            ]
        })

    
        return (
            <Grid container spacing={2}>
                <InfoBox
                    rowSize={5}
                    icon={IconHelper.survey}
                    value={pendingContacts.length}
                    title="Pending Surveys Invites"
                />
                <InfoBox
                    rowSize={5}
                    icon={IconHelper.contacts}
                    value={_.uniq(uniqPending).length}
                    title="Pending Contacts"
                />
                <InfoBox
                    rowSize={5}
                    icon={IconHelper.contacts}
                    value={_.uniqBy([...survey.contacts_sent, ...survey.contacts_partial, ...survey.contacts_complete], i => i.sc_contact_id).length}
                    title="Contacts Added"
                />
                <InfoBox
                    rowSize={5}
                    icon={IconHelper.emailRuns}
                    value={_.sumBy([...survey.contacts_sent, ...survey.contacts_partial, ...survey.contacts_complete], i => i.emails.length)}
                    title="Emails Sent"
                />
                <InfoBox
                    rowSize={5}
                    icon={IconHelper.percent}
                    value={getPercentage(survey.contacts_complete.length, [...survey.contacts_sent, ...survey.contacts_partial, ...survey.contacts_complete].length)}
                    _value={`
                        ${[...survey.contacts_sent, ...survey.contacts_partial, ...survey.contacts_complete].length ? parseFloat(
                            (
                                _.sumBy([...survey.contacts_sent, ...survey.contacts_partial], i => parseFloat(_.filter(i.form.results, r => r.f_result_value !== '' && r.f_result_value !== null ).length / this.props.survey.form.elements_count) * 100) +
                                survey.contacts_complete.length * 100
                            ) /
                            [...survey.contacts_sent, ...survey.contacts_partial, ...survey.contacts_complete].length
                        ).toFixed(2): '0.00'}%`
                    }
                    title="Total progress"
                />
                <InfoBox
                    rowSize={5}
                    icon={IconHelper.survey}
                    value={[...survey.contacts_sent, ...survey.contacts_partial, ...survey.contacts_complete, ...survey.contacts_removed].length}
                    title="Survey Invites Sent"
                />
                <InfoBox
                    rowSize={5}
                    icon={IconHelper.email}
                    value={survey.contacts_sent.length}
                    title="Survey Invites Not Started"
                />
                <InfoBox
                    rowSize={5}
                    icon={IconHelper.emailOpen}
                    value={survey.contacts_partial.length}
                    title="Started Survey Invites"
                />
                <InfoBox
                    rowSize={5}
                    icon={IconHelper.true}
                    value={survey.contacts_complete.length}
                    title="Completed Survey Invites"
                />
                <InfoBox
                    rowSize={5}
                    icon={IconHelper.false}
                    value={survey.contacts_removed.length}
                    title="Removed Survey Invites"
                />
                
            </Grid>
        )
    }
}

export default Overview;