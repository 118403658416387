import React, { useState, useEffect } from 'react';
import { useDispatch }                from 'react-redux';
import _                              from 'lodash';
import API                            from "API";

import EmailDialog   from 'Components/Common/Dialogs/EmailCustomerReturnsDialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import FALightIcon   from 'Components/Common/Icons/FontAwesome/FALightIcon';

import { statusHelper } from 'Helpers/CustomerReturnsHelper';

import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';

const useStyles = makeStyles(theme => ({
    button: {
        textTransform: 'capitalize'
    },
    buttonRow: {
        float: 'right',
        margin: 0
    }
}));

const initialState = {
    creditNote:     {},
    isLoading:      true,
};

const CustomerReturnsEmailPrevDocsDialog = ({ cnId }) => {

    const [state, setState] = useState(initialState),
        { creditNote, isLoading } = state,
        classes = useStyles(),
        dispatch = useDispatch(),
        handleDialogClose = () => dispatch(closeDialog());

    const handleEmail = (type, subType) => {
        let title = '', dialogType = 'success', customerId = creditNote.cn_cust_id, customerContactId = creditNote.cn_cust_contact_id;

        switch(subType) {
            case 'Approval':
            case 'Acknowledgement':  
                title = `Credit Note ${subType}`;
            break;
            default: title = type ===  'creditNote' ? ' Credit Note' : 'Customer Return'; break;
        }

        const dialog = <EmailDialog 
                            id=                 {cnId}
                            customerId=         {customerId}
                            customerContactId=  {customerContactId}
                            type=               {'creditNote'}
                            subType=            {subType}
                        />
        dispatch(deployDialog(dialog, `You Are About To Email - ${title}`, dialogType));
    }

    useEffect(() => {
        API.get(`/customerReturns/creditNote/${cnId}`).then((result) => {
            if (result.data) {
                if (result.data.status_change.length > 0) 
                    _.map(result.data.status_change, i => {i.icon = statusHelper(i.cnsc_status);});
                
                setState((state) => ({
                    ...state,
                    creditNote: result.data,
                    isLoading: false,
                }));
            }
        });
    }, [cnId]);

    return(
        <Grid container spacing={3}>
            {isLoading ? (
                <Grid item xs={12}>
                    <LoadingCircle />
                </Grid>
            ) : (
                <>
                    {cnId ?  
                        <>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Credit Notes 
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Button
                                    startIcon={<FALightIcon icon="file-user" style={{color: '#F39C12'}} />}
                                    className={classes.button}
                                    onClick={() => handleEmail('CreditNote','Acknowledgement')}
                                >
                                    Credit Note Acknowledgement
                                </Button>
                            </Grid>
                            {_.map(creditNote.status_change, i => 
                                <>
                                    {i.cnsc_status !== 'Approval' && i.cnsc_status !== 'Credit Note Awaiting Approval' && i.cnsc_status !== 'Credit Note' &&
                                        <Grid item xs={12} md={4}>
                                            <Button
                                                startIcon={<FALightIcon icon={i.icon} style={{color: '#F39C12'}} />}
                                                className={classes.button}
                                                onClick={() => handleEmail('CreditNote', i.cnsc_status)}
                                            >
                                                Credit Note {i.cnsc_status}
                                            </Button>
                                        </Grid>
                                    }
                                </>
                            )}
                            {creditNote.invoice &&
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">
                                            Credit Notes
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button
                                            startIcon={<FALightIcon icon="file-invoice-dollar" style={{color: '#F39C12'}} />} 
                                            className={classes.button}
                                            onClick={() => handleEmail('CreditNote', 'Invoice')}
                                        >
                                            {creditNote.invoice.cni_invoice_number}
                                        </Button>
                                    </Grid>
                                </>
                            }
                        </> : <></>
                    }
                    <Grid item xs={12}>
                        <div className={classes.buttonRow}>
                            <Button variant="outlined" color="default" onClick={handleDialogClose}>Close</Button>
                        </div>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

export default CustomerReturnsEmailPrevDocsDialog;