import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import API from '../../../API';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import SnackBar from './../../Common/SnackBars/SnackBar'
import {colors} from 'Helpers/ColourHelper';

const initialState = {
    formData: {
        currentPassword: '',
        password:'',
        confirmPassword: '',
    },
    formErrors: {},
    confirmationOpen: false,
    snackbarOpen: false
}

class ChangeStaffPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    handleSubmit = () => {
        API.put(this.props.staffId ? '/staff/' + this.props.staffId + '/password' : '/staff/my/password', this.state.formData).then(result => {
            if(result.data.errors) {
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
            }
            this.props.scrollToTop();
        })
    }

    handleChange = (event) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value
            }
        })
    }

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.handleSubmit();
    }

    render() {
        const { myPassword } = this.props;
        const { formErrors, formData, snackbarOpen } = this.state;
        return (
            <React.Fragment>
                {formErrors && formErrors.generic && (
                    <React.Fragment>
                        <Typography component={"div"} style={{color: colors.red}}>
                            {formErrors.generic}
                        </Typography>
                    </React.Fragment>
                )}
                <form onChange={this.handleChange} noValidate autoComplete="off">
                    {myPassword &&
                        <TextField
                            type="password"
                            name="currentPassword"
                            label="Current Password"
                            margin="normal"
                            autoComplete="off"
                            value={(formData.currentPassword && formData.currentPassword) || ''}
                            error={formErrors && formErrors['currentPassword'] && true}
                            helperText={formErrors && formErrors['currentPassword']}
                            fullWidth
                        />
                    }
                    <TextField
                        type="password"
                        name="password"
                        label="New Password"
                        margin="normal"
                        autoComplete="off"
                        value={(formData.password && formData.password) || ''}
                        error={formErrors && formErrors['password'] && true}
                        helperText={formErrors && formErrors['password']}
                        fullWidth
                    />
                    <TextField
                        type="password"
                        name="confirmPassword"
                        label="Confirm New Password"
                        margin="normal"
                        autoComplete="off"
                        value={(formData.confirmPassword && formData.confirmPassword) || ''}
                        error={formErrors && formErrors['confirmPassword'] && true}
                        helperText={formErrors && formErrors['confirmPassword']}
                        fullWidth
                    />
                    <div className='buttonRow'>
                        <Button onClick={this.handleConfirmationOpen}
                                variant="contained"
                                color="primary"
                                disabled={!(formData.password && formData.confirmPassword)}>
                            Change
                        </Button>
                    </div>
                    <ConfirmationDialog 
                        open={this.state.confirmationOpen} 
                        success={this.handleConfirmationSuccess} 
                        close={this.handleConfirmationClose} 
                        title="Update Password?" 
                        message={`Are you sure you want to update ${this.props.staffId ? "this staff member's" : "your"} password?`}
                    />
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={snackbarOpen}
                        onClose={this.handleSnackbarClose}
                        message={`${this.props.staffId ? "The staff member's " : "Your "} password was successfully changed`}
                    />
                </form>
            </React.Fragment>
        )
    }
}

export default ChangeStaffPasswordForm;