import FormControl from '@material-ui/core/FormControl';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Typography from '@material-ui/core/Typography';
import React, { PureComponent } from 'react';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';

class DeliveryDetails extends PureComponent {
    
    render() {
        const { formErrors, lists, staffList, deliverySupplierFao, deliverySupplierAddress, deliveryCustomerFao, deliveryCustomerAddress,
            deliveryCustomerAddressType, deliveryCustomer, deliveryDefaultFao, deliveryDefaultAddress, deliveryTo,
            showDeliveryToCustomer, showDeliveryToDefault, showDeliveryToSupplier, handleSelectChange } = this.props;
        return (
            <PaddedPaper>
                <Typography variant="h6">
                    Delivery Details
                </Typography>
                <form noValidate autoComplete="off">
                    <FormControl fullWidth margin="normal">
                        <AutoCompleteSelect 
                            options={[
                                {value: 'default', label: 'Default'},
                                {value: 'customer', label: 'Customer'},
                                {value: 'supplier', label: 'Supplier'}
                            ]} 
                            label='Delivery To'
                            onChange={handleSelectChange('deliveryTo')}
                            error={formErrors && formErrors['deliveryTo'] && true}
                            errorText={formErrors && formErrors['deliveryTo']}
                            value={deliveryTo}
                        />
                    </FormControl>
                    {/* Default */
                    showDeliveryToDefault &&
                    <React.Fragment>
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect 
                                options={lists.defaultAddressList} 
                                label='Delivery Address *'
                                value={deliveryDefaultAddress}
                                onChange={handleSelectChange('deliveryDefaultAddress')}
                                error={formErrors && formErrors['deliveryDefaultAddress'] && true}
                                errorText={formErrors && formErrors['deliveryDefaultAddress']}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <AutoCompleteSelect 
                                options={staffList} 
                                label='Delivery FAO *'
                                value={deliveryDefaultFao}
                                onChange={handleSelectChange('deliveryDefaultFao')}
                                error={formErrors && formErrors['deliveryDefaultFao'] && true}
                                errorText={formErrors && formErrors['deliveryDefaultFao']}
                            />
                        </FormControl>
                    </React.Fragment>
                    }
                    {/* Customer */
                    showDeliveryToCustomer &&
                        <React.Fragment>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect 
                                    options={lists.customerList} 
                                    label='Customer'
                                    value={deliveryCustomer}
                                    onChange={handleSelectChange('deliveryCustomer')}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect 
                                    options={lists.customerAddressTypeList} 
                                    label='Address Type'
                                    value={deliveryCustomerAddressType}
                                    onChange={handleSelectChange('deliveryCustomerAddressType')}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect 
                                    options={lists.customerAddressList} 
                                    label='Delivery Address *'
                                    value={deliveryCustomerAddress}
                                    onChange={handleSelectChange('deliveryCustomerAddress')}
                                    error={formErrors && formErrors['deliveryCustomerAddress'] && true}
                                    errorText={formErrors && formErrors['deliveryCustomerAddress']}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect 
                                    options={lists.customerContactList} 
                                    label='Delivery FAO *'
                                    value={deliveryCustomerFao}
                                    onChange={handleSelectChange('deliveryCustomerFao')}
                                    error={formErrors && formErrors['deliveryCustomerFao'] && true}
                                    errorText={formErrors && formErrors['deliveryCustomerFao']}
                                />
                            </FormControl>
                        </React.Fragment>
                    }
                    {/* Supplier */
                    showDeliveryToSupplier &&
                        <React.Fragment>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect 
                                    options={lists.supplierList} 
                                    label='Delivery Address *'
                                    value={deliverySupplierAddress}
                                    onChange={handleSelectChange('deliverySupplierAddress')}
                                    error={formErrors && formErrors['deliverySupplierAddress'] && true}
                                    errorText={formErrors && formErrors['deliverySupplierAddress']}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <AutoCompleteSelect 
                                    options={lists.deliverySupplierContactList} 
                                    label='Delivery FAO *'
                                    value={deliverySupplierFao}
                                    onChange={handleSelectChange('deliverySupplierFao')}
                                    error={formErrors && formErrors['deliverySupplierFao'] && true}
                                    errorText={formErrors && formErrors['deliverySupplierFao']}
                                />
                            </FormControl>
                        </React.Fragment>
                    }
                </form>
            </PaddedPaper>
        );
    }
}

export default DeliveryDetails;
    