import React, { Component } from 'react';
import API from 'API';
import { connect } from 'react-redux';
import _ from 'lodash';

import { colors } from 'Helpers/ColourHelper';
import icons from 'Helpers/IconHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

import AllIcon from 'Components/Common/Icons/AllIcon';

import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { closeDialog } from 'Actions/Dialog/Dialog';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import { statusHelper } from 'Helpers/CustomerReturnsHelper';

const initialState = {
    isLoading: false,
    order: {}
}

class ReturnDownloadDialog extends Component {
    constructor(props) {
        super(props);    
        this.state = initialState;
    }

    componentDidMount() {
        if (!this.props.subElement){
            this.setState({
                isLoading: true
            }, this.findOrder)
        }
    }

    handleDownload = status => () => {
        API.get(`/customerReturns/returns/${this.props.cr.cr_id}/prevDownload`, {params: {status}})
        .then(res => {
            pdfFromBase64(res.data.pdf, res.data.filename);
        });
    }

    handleOrderDownload = (type, id) => () => {
        this.props.cr?.order?.order_id &&
        API.post(`/sales/orders/${this.props.cr.order.order_id}/prevPdf`, {
            type: type,
            id: id
        }).then((result) => {
            if (result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    findOrder () {
        this.props.cr?.order?.order_id &&
        API.get(`/sales/orders/${this.props.cr.order.order_id}`).then((result) => {
            if (result.data) {
                this.setState({
                    order: result.data,
                    isLoading: false,
                });
            }
        });
    }

    handleCNDownload = (type, cnId) => {
        API.post(`/customerReturns/creditNote/${cnId}/prevPdf`, {type: type,}).then((result) => {
            if (result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    render(){

        let { classes, cr } = this.props;
        let { isLoading, order } = this.state;

        if (isLoading) {
            return (
                <LoadingCircle/>
            )
        }

        let customerReturns = 0;

        switch(cr.cr_status){
            case 'Declined':
                customerReturns = -1;
                break;
            case 'Cancelled':
                customerReturns = -2;
                break;
            case 'Awaiting Confirmation':
                customerReturns = 1;
                break;
            case 'Awaiting Collection':
                customerReturns = 2;
                break;
            case 'Awaiting Review':
                customerReturns = 3;
                break;
            case 'Awaiting Outcome':
            case 'Completed':
                customerReturns = 99;
                break;
            default: break;
        }

        return (
            <Grid container spacing={3}>
                {!this.props.subElement ?
                    <Grid item xs={12}>
                        <Typography variant="caption">Customer Returns</Typography>
                    </Grid> : 
                    <Grid item xs={12}>
                        <Typography variant="caption">{cr.cr_reference}-{cr.cr_reference_amendment > 9 ? cr.cr_reference_amendment : `0${cr.cr_reference_amendment}`}</Typography>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={5}>
                            <Button
                                startIcon={
                                    <AllIcon
                                        icon={icons.orderAwaitingApproval}
                                        color={colors.orange}
                                    />
                                }
                                className={classes.button}
                                onClick={this.handleDownload("Acknowledgement")}
                            >
                                Customer Return Acknowledgement
                            </Button>
                        </Grid>
                        {(customerReturns > 0) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.true}
                                            color={colors.orange}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleDownload("Approved")}
                                >
                                    Customer Return Confirmation
                                </Button>
                            </Grid>
                        }       
                        {(customerReturns > 1) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.truck}
                                            color={colors.orange}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleDownload("Awaiting Collection")}
                                >
                                    Customer Return Collection Confirmation	
                                </Button>
                            </Grid>
                        }
                        {(customerReturns > 2) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.boxes}
                                            color={colors.orange}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleDownload("Received")}
                                >
                                    Customer Return Received Confirmation
                                </Button>
                            </Grid>
                        }
                        {(customerReturns > 2) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.inspection}
                                            color={colors.orange}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleDownload("Inspection")}
                                >
                                    Customer Return Inspection Report
                                </Button>
                            </Grid>
                        }
                        {(customerReturns > 2 && cr.outcomes_repair && cr?.outcomes_repair?.repair_order?.rog_status === 'Completed') &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.repair}
                                            color={colors.orange}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleDownload("Repair")}
                                >
                                    Customer Return Repair Report
                                </Button>
                            </Grid>
                        }
                         {(customerReturns > 2 && cr.outcomes_write_off) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.broken}
                                            color={colors.orange}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleDownload("Write Off")}
                                >
                                    Customer Return Write Off Report
                                </Button>
                            </Grid>
                        }
                        {(customerReturns > 3) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.true}
                                            color={colors.green}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleDownload("Completed")}
                                >
                                    Customer Return Complete Confirmation
                                </Button>
                            </Grid>
                        }
                        {(customerReturns === -1) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.false}
                                            color={colors.red}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleDownload("Declined")}
                                >
                                    Customer Return Declined
                                </Button>
                            </Grid>
                        }
                        {(customerReturns === -2) &&
                            <Grid item xs={12} md={5}>
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={icons.false}
                                            color={colors.red}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleDownload("Cancelled")}
                                >
                                    Customer Return Cancelled
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                {!this.props.subElement &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant="caption">Order</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button
                                startIcon={
                                    <AllIcon
                                        icon={icons.orderAwaitingApproval}
                                        color={colors.orange}
                                    />
                                }
                                className={classes.button}
                                onClick={this.handleOrderDownload("Order Acknowledgement")}
                            >
                                Order Acknowledgement
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button
                                startIcon={
                                    <AllIcon
                                        icon={icons.userCheck}
                                        style={{ color: colors.orange }}
                                    />
                                }
                                className={classes.button}
                                onClick={this.handleOrderDownload("confirmation")}
                            >
                                Order Confirmation
                            </Button>
                        </Grid>
                        {order.payment_term?.payment_term_upfront_payment ?
                            <>
                                <Grid item xs={12} md={4}>
                                    <Button
                                        startIcon={
                                            <AllIcon
                                                icon="credit-card"
                                                style={{ color: colors.orange }}
                                            />
                                        }
                                        className={classes.button}
                                        onClick={this.handleOrderDownload("awaiting-payment")}
                                        disabled={
                                            _.find(order.activity, ["order_log_type","Confirmed",]) ? false: true}
                                    >
                                        Order Awaiting Payment
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Button
                                        startIcon={
                                            <AllIcon
                                                icon="receipt"
                                                style={{ color: colors.orange }}
                                            />
                                        }
                                        className={classes.button}
                                        onClick={this.handleOrderDownload("payment-confirmed")}
                                        disabled={
                                            _.find(order.activity, ["order_log_type","Payment Made"]) ? false : true}
                                    >
                                        Order Payment Confirmation
                                    </Button>
                                </Grid>
                            </>
                        :''}
                        {order.despatch && order.despatch.length > 0 && (
                            <>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Delivery Notes
                                </Typography>
                            </Grid>
                            {order.despatch.map((el) => (
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    key={el.desp_id}
                                >
                                    <Button
                                        startIcon={
                                            <AllIcon
                                                icon={"truck"}
                                                style={{ color: colors.orange }}
                                            />
                                        }
                                        className={classes.button}
                                        onClick={this.handleOrderDownload(
                                                "delivery-note",
                                                el.desp_id
                                            )
                                        }
                                    >
                                        {el.full_reference}
                                    </Button>
                                </Grid>
                            ))}
                            </>
                        )}
                    </>
                }
                {order.invoices && order.invoices.length > 0 && (
                        <>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                Invoices
                            </Typography>
                        </Grid>
                        {order.invoices.map((el) => (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                key={el.invoice_id}
                            >
                                <Button
                                    startIcon={
                                        <AllIcon
                                            icon={"file-invoice-dollar"}
                                            style={{ color: colors.orange }}
                                        />
                                    }
                                    className={classes.button}
                                    onClick={this.handleOrderDownload(
                                            "invoice",
                                            el.invoice_id
                                        )
                                    }
                                >
                                    {el.invoice_number}
                                </Button>
                            </Grid>
                        ))}
                        </>
                    )}
                {order.credit_notes && order.credit_notes.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    Credit Notes 
                                </Typography>
                            </Grid>
                            {_.map(order.credit_notes, el => (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">
                                            {el.cn_reference}-{el.cn_reference_amendment > 9 ? el.cn_reference_amendment : `0${el.cn_reference_amendment}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button
                                            startIcon={<AllIcon icon="file-user" style={{color: '#F39C12'}} />} 
                                            className={classes.button}
                                            onClick={() => this.handleCNDownload('Acknowledgement', el.cn_id)}
                                        >
                                            Credit Note Acknowledgement
                                        </Button>
                                    </Grid>
                                    {_.map(el.status_change, i => 
                                        <>
                                            {i.cnsc_status !== 'Approval' && i.cnsc_status !== 'Credit Note Awaiting Approval' && i.cnsc_status !== 'Credit Note' &&
                                                <Grid item xs={12} md={4}>
                                                    <Button
                                                        startIcon={<AllIcon icon={statusHelper(i.cnsc_status)} style={{color: '#F39C12'}} />}
                                                        className={classes.button}
                                                        onClick={() => this.handleCNDownload(i.cnsc_status, el.cn_id)}
                                                    >
                                                        Credit Note {i.cnsc_status}
                                                    </Button>
                                                </Grid>
                                            }
                                        </>
                                    )}
                                    {el.invoice &&
                                        <>
                                            <Grid item xs={12} md={4}>
                                                <Button
                                                    startIcon={<AllIcon icon="file-invoice-dollar" style={{color: '#F39C12'}} />} 
                                                    className={classes.button}
                                                    onClick={() => this.handleCNDownload('Invoice', el.cn_id)}
                                                >
                                                    {el.invoice.cni_invoice_number}
                                                </Button>
                                            </Grid>
                                        </>
                                    }
                                </>
                            ))}
                        </>
                    )}
                {!this.props.subElement &&
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant="outlined"
                            onClick={this.props.closeDialog}
                        >Close</Button>
                    </Grid>
                }
            </Grid>
        )
    }

}

const styles = {
    button: {
        textTransform: "capitalize",
    },
    buttonRow: {
        float: "right",
        margin: 0,
    },
}


const mapDispatchToProps = dispatch => ({
    closeDialog:  ()                                    => dispatch(closeDialog()),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(ReturnDownloadDialog));