
import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import ReturnDownloadDialog from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturns/ReturnDownloadDialog';
import ReturnEmailDialog from 'Components/CustomerReturns/CustomerReturns/ViewCustomerReturns/ReturnEmailDialog';
import OrderDownloadPrevDocsDialog from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';

import { colors, statusColour } from 'Helpers/ColourHelper';
import { statusColour as cnStatusColour, statusHelper as cnStatusHelper } from 'Helpers/CustomerReturnsHelper';
import { orderStatusIcons } from 'Helpers/SalesHelper';

import { deployDialog } from 'Actions/Dialog/Dialog';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { Grid, IconButton, Link, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import CustomerReturnsDownloadPrevDocsDialog from 'Components/CustomerReturns/CustomerReturnsDownloadPrevDocsDialog/CustomerReturnsDownloadPrevDocsDialog';
import CustomerReturnsEmailPrevDocsDialog from 'Components/CustomerReturns/CustomerReturnsEmailPrevDocsDialog/CustomerReturnsEmailPrevDocsDialog';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    searchResults:  [],
    isLoading:      false,
    formData:       {
        keywords: '',
        dateFrom: null,
        dateTo:   null,
    }
})

const styles = () => ({
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }
});

class WarrantyVoidLabelsHistory extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Warranty:History';
        this.state              = this.getInitialState(initialState());
        this.timeout            = false;
    }

    componentWillUnmount = () => {
        if(this.timeout) 
            clearTimeout(this.timeout)
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        }, this.setSearch);
    }

    handleDateChange = field => e => {
        this.setState({
            formData:{
                ...this.state.formData,
                [field]: e
            },
        }, this.setSearch);
    }

    handleSelectChange = field => e => {
        this.setState({
            formData:{
                ...this.state.formData,
                [field]: e.value
            }
        }, this.setSearch);
    }

    setSearch = () => {
        if(this.timeout) 
            clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getSearchData, 200);
    }

    getSearchData = () => {
        this.setState({isLoading: true}, ()=>{
            const formData = this.state.formData;
            if ((formData.keywords && formData.keywords !== '' && formData.keywords.length > 3) || formData.dateFrom || formData.dateTo){
                API.get(`/parts/warrantyTerms/warrantyVoidLabels/searchHistory`, {params: formData})
                .then(res => {
                   if (res.data) {
                        _.map(res.data, r => {
                            // Status Colour
                            r.color =   (r.or) ? statusColour(r.o.s)        : cnStatusColour(r.cn?.s || r.cr?.s);
                            r.icon =    (r.or) ? orderStatusIcons(r.o.s)    : cnStatusHelper(r.cn?.s || r.cr?.s);
                            if (r.or) {
                                switch(r.o.s){
                                    case 'Awaiting Approval':
                                        r.toolTip1 = 'Order Acknowledgment';
                                        r.toolTip2 = 'Awaiting Approval';
                                    break;
                                    case 'Confirmed':
                                        r.toolTip1 = 'Order Confirmed'
                                    break;
                                    case 'Awaiting Payment':
                                        r.toolTip1 = 'Awaiting Payment';
                                    break;
                                    case 'Payment Confirmed':
                                        r.toolTip1 = 'Payment Confirmed';
                                    break;
                                    case 'Picking':
                                        r.toolTip1 = 'Awaiting Picking';
                                    break;
                                    case 'Awaiting Despatch':
                                        r.toolTip1 = 'Awaiting Despatch';
                                    break;
                                    case 'Invoice':
                                        r.toolTip1 = 'Awaiting Invoice';
                                    break;
                                    case 'Completed':
                                        r.toolTip1 = 'Order Completed';
                                    break;
                                    case 'Declined':
                                        r.toolTip1 = 'Order Declined';
                                    break;
                                    case 'Cancelled':
                                        r.toolTip1 = 'Order Cancelled';
                                    break;
                                    case 'Awaiting Invoice Completion':
                                        r.toolTip1 = 'Awaiting Invoice Completion';
                                    default:
                                    break;
                                }
                            } else {
                                r.toolTip1  = r.cn.s
                            }
                        });
                        this.setState({searchResults:res.data, isLoading: false}, ()=>this.savePageState());
                   }
                })
            } else {
                this.setState({searchResults:initialState().searchResults, isLoading: false}, ()=>this.savePageState());
            }
        });
    }

    handleDownloadOpen      = orderId => this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={orderId} />, 'Download Previous Documents', '', 'md');

    handleEmailOpen         = orderId => this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={orderId} />, 'Email Previous Documents', '', 'md');

    handleCNDownloadOpen    = creditNote => this.props.deployDialog(<CustomerReturnsDownloadPrevDocsDialog cnId={creditNote} />, 'Download Previous Documents', '', 'md');

    handleCNEmailOpen       = creditNote => this.props.deployDialog(<CustomerReturnsEmailPrevDocsDialog cnId={creditNote} />,  'Email Previous Documents',   '', 'md');

    handleCRDownloadOpen = creditReturn => {
        API.get(`/customerReturns/returns/${creditReturn}`).
        then(res =>{
            this.props.deployDialog(<ReturnDownloadDialog cr={res.data}/>, 'Download Previous Documents', '', 'md');
        });
    } 

    handleCREmailOpen = creditReturn => {
        API.get(`/customerReturns/returns/${creditReturn}`).
        then(res =>{
            this.props.deployDialog(<ReturnEmailDialog cr={res.data} />, 'Previous Emails', '', 'md');
        });
    } 


    render(){
        const { searchResults, formData, isLoading }   = this.state;
        const { classes } = this.props;
        return (
            <Grid container spacing={3} xs={12}>
                <Grid item xs={12} lg={6}>
                    <PaddedPaper>
                        <Typography variant="h6">
                            Warranty Void Labels History
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={6}>
                                <DatePicker
                                    type='date'
                                    id="dateFrom"
                                    name="dateFrom"
                                    label="Date From"
                                    autoOk={true}
                                    value={formData?.dateFrom}
                                    onChange={this.handleDateChange('dateFrom')}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <DatePicker
                                    type='date'
                                    id="dateTo"
                                    name="dateTo"
                                    label="Date To"
                                    autoOk={true}
                                    value={formData?.dateTo}
                                    onChange={this.handleDateChange('dateTo')}                                            
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            id="keywords"
                            name="keywords"
                            label="Warranty Void Label"
                            value={formData.keywords}
                            onChange={this.handleChange}
                            margin="normal"
                            fullWidth
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12}>
                    {isLoading ? <LoadingCircle /> : <>
                        {((formData.keywords && formData.keywords !== '' && formData.keywords.length > 3) || formData.dateFrom || formData.dateTo) &&
                            <DataTable 
                                config={{
                                    key: 'i',
                                    pagination: true,
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                }}
                                columns={[
                                    {
                                        heading: 'Date',
                                        field: rowData => 
                                            <div style={{color: rowData.or ? '' : colors.red}}>
                                                {moment((rowData.or) ? rowData.o.d : rowData.cn.d).format("DD/MM/YYYY")}
                                            </div>,
                                        important: true,
                                        sizeToContent: true,
                                    },
                                    {
                                        actionsCustomHeader:    true,
                                        important:              true,
                                        alignment:              'left',
                                        heading:                'Reference',
                                        actions: rowData => {
                                            return (rowData.or) ? 
                                                [{ label: rowData.o.r,  link: `/sales/order/view/${rowData.o.i}`,       type:'plainLink'}] :
                                                ( rowData.cn?.r ? 
                                                    [{ label: rowData.cn.r,  link: `/returns/creditnote/${rowData.cn.i}`,   type:'plainLink',   color: "#E74C3C"}] :
                                                    [{ label: rowData.cr.r,  link: `/returns/customerReturn/${rowData.cr.i}`,   type:'plainLink',   color: "#E74C3C"}]
                                                )
                                        }
                                    },
                                    {
                                        heading: 'Discount',
                                        field: rowData => {
                                            return ((rowData.or) ? rowData.o.t : rowData.cn.t) == 'With Discount' ? 
                                                <AllIcon icon='check' style={{color:colors.green}}/> : <AllIcon icon='times' style={{color:colors.red}}/>;
                                        },
                                        important: true,
                                        sizeToContent: true,
                                        fieldFormat: 'boolean',
                                        tooltip: rowData => (rowData.or) ? rowData.o.t : rowData.cn.t,
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Customer',
                                        field: rowData => <>
                                                            <div style={{color: rowData.or ? '' : colors.red}}>
                                                                {rowData.c.n}
                                                                {' '}
                                                                <Tooltip
                                                                    title={
                                                                        <div>
                                                                            <Typography variant="body2">
                                                                                <b>Delivery Address:</b><br />
                                                                                { rowData?.o?.da || ( rowData?.cn?.da || rowData?.cr?.da ) }
                                                                            </Typography>
                                                                        </div>
    
                                                                    }
                                                                    placement="right"
                                                                >
                                                                    <Link>
                                                                        <AllIcon
                                                                            heavy
                                                                            icon='info-circle'
                                                                            button
                                                                            size='small'
                                                                            style={{
                                                                                color: 'rgba(0, 0, 0, 0.38)'
                                                                            }}
                                                                        />
                                                                    </Link>
                                                                </Tooltip>
                                                            </div>
                                                        </>,
                                        important: true,
                                        dataRef: 'c',
                                        truncate: true,
                                    },
                                    {
                                        heading: 'Warranty Void Label',
                                        field: rowData => <div style={{color: rowData.or ? '' : colors.red}}>{rowData.s}</div>,
                                        important: true,
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'type',
                                        field: rowData => <Tooltip
                                            title={
                                                <div >Warranty Void Label</div>
                                            }
                                            placement="left"
                                        >
                                            <IconButton className={classes.iconButton}>
                                                <AllIcon 
                                                    icon={'tag'}
                                                    button
                                                    style={{color: rowData.or ? '' : colors.red}}
                                                    noMargin
                                                />
                                            </IconButton>
                                        </Tooltip>,
                                        important: true,
                                        sizeToContent: true,
                                        alignment: 'center'
                                    },
                                    {
                                        heading: 'Status',
                                        field: rowData =>   <>
                                                                <Tooltip
                                                                    title={
                                                                        <div>
                                                                            <Typography variant="body2">{rowData.toolTip1}</Typography>
                                                                            <Typography variant="body2">{rowData.toolTip2}</Typography>
                                                                        </div>
                                                                    }
                                                                    placement="left"
                                                                >
                                                                    <IconButton className={classes.iconButton}>
                                                                        <AllIcon 
                                                                            icon={`${rowData.icon}`}
                                                                            button
                                                                            style={{
                                                                                color: rowData.color
                                                                            }}
                                                                            noMargin
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>,
                                        important: true,
                                        alignment: 'center',
                                        sizeToContent: true,
                                    },
                                    {
                                        actions: rowData => {
                                            return (rowData.or) ? 
                                                [
                                                    {name: 'Download',                      icon: 'download',       onClick: () => this.handleDownloadOpen(rowData.o.i)},
                                                    {name: 'Send Order to Customer',        icon: 'envelope',       onClick: () => this.handleEmailOpen(rowData.o.i)},
                                                    {name: 'View',                          icon: 'search',         link: '/sales/order/view/' + rowData.o.i},
                                                    {name: 'View this Customers Orders',    icon: 'file-search',    link: `/customers/view/${rowData.c.i}/Orders`}
                                                ] :
                                                (
                                                    rowData.cn?.i ? 
                                                        [
                                                            {name: 'Download',                      icon: 'download',       onClick: () => this.handleCNDownloadOpen(rowData.cn.i)},
                                                            {name: 'Send Order to Customer',        icon: 'envelope',       onClick: () => this.handleCNEmailOpen(rowData.cn.i)},
                                                            {name: 'View',                          icon: 'search',         link: {pathname: `/returns/creditnote/${rowData.cn.i}`}}, 
                                                            {name: 'View this Customers Orders',    icon: 'file-search',    link: `/customers/view/${rowData.c.i}/CreditNote`}
                                                        ] : [
                                                            {name: 'Download',                      icon: 'download',       onClick: () => this.handleCRDownloadOpen(rowData.cr.i)},
                                                            {name: 'Send Order to Customer',        icon: 'envelope',       onClick: () => this.handleCREmailOpen(rowData.cr.i)},
                                                            {name: 'View',                          icon: 'search',         link: {pathname: `/returns/customerReturn/${rowData.cr.i}`}}, 
                                                            {name: 'View this Customers Orders',    icon: 'file-search',    link: `/customers/view/${rowData.c.i}/CustomerReturns`}
                                                        ]
                                                )
                                        }
                                    }
                                ]}
                                rows={searchResults}
                            />
                        } </>}
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                       => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                => dispatch(setPersistence(key, state)),
    deployDialog:       (content, title, variant)   => dispatch(deployDialog(content, title, variant)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WarrantyVoidLabelsHistory));