import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import API from '../../../API';
import Button from '@material-ui/core/Button';
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import {colors} from 'Helpers/ColourHelper';
import _ from 'lodash';
import SupplierStockReturn from './../SupplierStockReturn';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import DatePicker from '../../Common/DatePickers/DatePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import SnackBar from '../../Common/SnackBars/SnackBar';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Textarea from '../../Common/Inputs/Textarea';
import { green, red } from '@material-ui/core/colors';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import BackButton from 'Components/Common/Buttons/BackButton';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    formData: {
        deliveries: [],
        notes: ''
    },
    ssr: {},
    formErrors: [],
    showSSR: false,
    confirmationOpen: false,
    dialogConfirmationTable: '',
    disableConfirmationSuccess: false,
    receivedQty: false,
    deliveryDate: false,
    disableUpdateButton: true,
    snackbarOpen: false,
    redirect: false,
    successRedirect: false,
    successMsg: ''
});

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    poAlign: {
        marginLeft: '1.01em'
    },
    heading: {
        paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        },
        poAlign: {
            marginLeft: 0
        },
    },
});

const SwitchColorToggle = withStyles({
    switchBase: {
        color: red[500],
        '&$checked': {
            color: green[500],
        },
        '&$checked + $track': {
            backgroundColor: green[500],
        },
    },
    checked: {},
    track: {},
})(Switch);



class UpdateSupplierStockReturnDelivery extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `UpdateSupplierStockReturn:Delivery:${this.props.match.params.id}`;
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        !this.hasPageState() && this.getSSR();
    }

    getSSR = () => {
        API.get('/supplierStockReturns/' + this.props.match.params.id)
        .then(result => {
            if(result.data.errors) {
                this.setState({ redirect: true });
            } else if(result.data) {
                let deliveries = _.map(result.data.stock_return_details, el => {
                    let totalStock = parseFloat(el.srd_quantity),
                        receivedStock = parseFloat(el.received_total_qty),
                        remainderStock = (totalStock - receivedStock).toFixed(2),
                        scheduleStockStatus = ''; 
                    
                    if (totalStock > receivedStock) {
                        scheduleStockStatus = <span style={{color:'orange'}}>Under ({remainderStock})</span>;
                    } else if (totalStock < receivedStock) {
                        scheduleStockStatus = <span style={{color:colors.red}}>Over ({remainderStock})</span>;
                    } else {
                        scheduleStockStatus = <span style={{color:colors.green}}>Exact <FALightIcon icon='check' size='small' noMargin style={{color:colors.green}}/></span>;
                    }
                    return _.assign({
                        srdId: el.srd_id,
                        quantity: el.srd_quantity,
                        deliveryStatus: el.srd_delivery_status,
                        partId: el.srd_part_id,
                        partNumber: el.srd_part_number,
                        partDescription: el.srd_part_description,
                        receivedDeliveries: el.received_deliveries,
                        receivedTotalQty: el.received_total_qty,
                        receivedQty: '',
                        receivedDate: null,
                        deliveryComplete: false,
                        scheduleStockStatus
                    });
                });
                this.setState({
                    formData: {
                        ...this.state.formData,
                        deliveries: deliveries
                    },
                    ssr: result.data
                },()=>this.savePageState());
            }
        });
    }

    downloadSSR = (sr_id) => {
        API.get('/supplierStockReturns/' + sr_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    viewSSR = () => {
        this.setState(prevState => ({
            showSSR: !prevState.showSSR
        }),()=>this.savePageState());
    }

    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    validateForm = () => {
        const { receivedQty, deliveryDate, formData } = this.state;
        let disableUpdateButton = true;

        // If any delivery is marked as complete, the update button will not be disabled
        formData.deliveries.forEach(e => {
            if (e.deliveryComplete) {
                disableUpdateButton = false;
            }
        });

        if (receivedQty && deliveryDate) {
            this.setState({ disableUpdateButton: false });
        } else {
            this.setState({ disableUpdateButton });
        }
    }

    handleCheckChange = (idx, name) => e => {
        const { checked } = e.target;
        let newDeliveries =  [...this.state.formData.deliveries];
        newDeliveries[idx] = {
            ...newDeliveries[idx],
            [name]: checked
        };
        this.setState({
            formData: {
                ...this.state.formData,
                deliveries: newDeliveries
            },
        }, () => {
            this.validateForm();
        });
    }

    handleDecimalChange = (idx, decimals) => e => {
        const { name, value } = e.target;
        let newDeliveries =  [...this.state.formData.deliveries];
        let newVal = decimals ? parseFloat(value).toFixed(decimals) : value;
        let receivedQty = value > 0 ? true : false;

        newDeliveries[idx] = {
            ...newDeliveries[idx],
            [name]: newVal
        };
        this.setState({
            formData: {
                ...this.state.formData,
                deliveries: newDeliveries
            },
            receivedQty: receivedQty
        }, () => {
            this.validateForm();
        });
    };

    handleDateChange = idx => date => {

        let newDeliveries =  [...this.state.formData.deliveries];
        let deliveryDate = date !== null ? true : false;

        newDeliveries[idx] = {
            ...newDeliveries[idx],
            receivedDate: date
        };
        this.setState({
            formData: {
                ...this.state.formData,
                deliveries: newDeliveries
            },
            deliveryDate
        }, () => {
            this.validateForm();
        });
    }

    handleConfirmationOpen = (e) => {
        let msg = [];
        let hide_submit = false;
        this.state.formData.deliveries.forEach((val, idx) => {

            let receivedTotalQty    = parseFloat(val.receivedTotalQty);
            let receivedQty         = parseFloat(val.receivedQty);
            let quantity            = parseFloat(val.quantity);

            let new_total_qty = receivedTotalQty;
            if((receivedQty && val.receivedDate !== null) || val.deliveryComplete === 1){
                if(receivedQty) {
                    new_total_qty = receivedTotalQty + receivedQty;
                }
                let colour = colors.green;
                if(new_total_qty > quantity){
                    colour = colors.red;
                } else if(new_total_qty < quantity){
                    colour = 'orange';
                }
                if(new_total_qty > quantity || (new_total_qty === quantity && !val.deliveryComplete) || (new_total_qty < quantity && val.deliveryComplete)){
                    hide_submit = true;
                }
                let row =   (<TableRow key={idx}>
                                <TableCell>{val.partNumber}</TableCell>
                                <TableCell style={{color:colour}}>{new_total_qty +' / '+ quantity}</TableCell>
                        
                                {(new_total_qty > quantity) ?
                                    <TableCell style={{color:colors.red}}>The received quantity cannot be over <br></br>the expected quantity</TableCell>
                                    :
                                    (quantity === new_total_qty) && val.deliveryComplete === false ?
                                        <TableCell>In Progress <br></br><small style={{color:colors.red}}> Should this be marked as complete?</small></TableCell>
                                        :
                                        (new_total_qty < quantity && val.deliveryComplete === true) ?
                                            <TableCell>Complete <br></br><small style={{color:colors.red}}>The received quantity cannot be under <br></br>the expected quantity</small></TableCell>
                                            :
                                            (val.deliveryComplete === false) ?
                                                <TableCell>In Progress</TableCell>
                                                :
                                                <TableCell>Complete</TableCell>
                                } 
                            </TableRow>)
                msg.push(row);
            }
        });

        let string;
        if(msg){
            string =   <Table>
                            <TableHead>
                                <TableRow style={{backgroundColor:'#f2f2f2'}}>
                                    <TableCell>Part</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Delivery Staus</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {msg}
                            </TableBody>
                        </Table>;
        }

        this.setState({
            confirmationOpen: true,
            dialogConfirmationTable: string,
            disableConfirmationSuccess: hide_submit ? true : false
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    submit = () => {
        let newDeliveries =  [...this.state.formData.deliveries];
        newDeliveries.forEach((val,idx) => {
            newDeliveries[idx] = {
                ...newDeliveries[idx],
                receivedDate: (moment(val.receivedDate).format('DD/MM/YYYY'))
            };
        });
        const formData = {
            ...this.state.formData,
            deliveries: newDeliveries
        }
        API.post(`/supplierStockReturns/${this.props.match.params.id}/delivery`, formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors)
                });
            }
            else {
                if(result.data.full_reference){
                    this.setState({
                        successRedirect: true,
                        successMsg: 'You have successfully completed supplier stock return delivery ' + result.data.full_reference
                    });
                } 
                else {
                    this.setState({
                        ...initialState(),
                        snackbarOpen: true
                    });
                    this.getSSR();
                }
            }
            this.props.scrollToTop();
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false }); 
    };
    
    render() {
        const { classes } = this.props;
        const { ssr, formData, formErrors } = this.state;
        if (this.state.successRedirect === true) {
            return <Redirect 
                        to={{
                            pathname:`/supplier-stock-return/completed`,
                            state: {successMsg: this.state.successMsg}
                        }}
                    />
        } else if (this.state.redirect === true) {
            return <Redirect to="/supplier-stock-return/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Supplier Stock Return Delivery
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25}}>
                                    {ssr.full_reference}
                                </Typography>
                                <br></br>
                                <Typography variant="body1" align="center" gutterBottom style={{marginBottom:50}}>
                                    Supplier Stock Return (v{parseInt(ssr.sr_reference_amendment)})<br/>
                                    <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={this.viewSSR}
                                            style={{marginLeft:10}}>
                                        {this.state.showSSR ? 'Hide' : 'View'}
                                    </Button>
                                    <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={() => this.downloadSSR(ssr.sr_id)}>
                                        Download
                                    </Button> 
                                </Typography>
                                { this.state.showSSR ? <SupplierStockReturn ssr={ssr} /> : null }

                                {formErrors && formErrors['general'] && formErrors['general'].split('\n').map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <Typography component={"div"} style={{color: colors.red}}>
                                                {item}<br/>
                                            </Typography>
                                        </React.Fragment>
                                    )
                                })}

                                {formData.deliveries && formData.deliveries.map((ssrd, idx) => (
                                    <div key={idx} style={{maxWidth: '100%', margin: 12, border: '1px solid #ddd', overflowY: 'hidden', overflowX:'auto'}}>
                                        <div style={{margin: 12, minWidth: 900, fontSize: '0.8125rem', display:(ssrd.deliveryStatus !== 'Completed'?'':'none')}}>
                                            <form noValidate autoComplete="off">
                                                <Grid container spacing={3} style={{backgroundColor:'#0282C6', color:'white'}}>
                                                    <Grid item xs={6}>
                                                        {ssrd.partNumber + ' - ' + ssrd.partDescription}
                                                    </Grid>
                                                    <Grid item xs={6} align="right">
                                                        {ssrd.deliveryStatus}
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} style={{marginTop:8}}>
                                                    <React.Fragment>
                                                        <Grid item xs={3} style={{backgroundColor:'#f5f5f5', textAlign:'right'}}>
                                                            Expected Quantity
                                                        </Grid>
                                                        <Grid item xs={3} style={{backgroundColor:'#f5f5f5', textAlign:'right'}}>
                                                            Received Quantity
                                                        </Grid>
                                                        <Grid item xs={3} style={{backgroundColor:'#f5f5f5', textAlign:'right'}}>
                                                            Delivery Date
                                                        </Grid>
                                                        <Grid item xs={3} style={{backgroundColor:'#f5f5f5', textAlign:'right'}}></Grid>
                                                    </React.Fragment>
                                                    <React.Fragment>
                                                        <Grid item xs={3} style={{textAlign:'right',borderRight: '1px solid #ddd'}}>
                                                            {ssrd.quantity}
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Grid container spacing={3}>
                                                                {ssrd.receivedDeliveries && ssrd.receivedDeliveries.map((deliveries, id) => (
                                                                    <React.Fragment key={`del_${id}`}>
                                                                        <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                            {deliveries.sr_delivery_quantity}
                                                                        </Grid>
                                                                        <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                            {moment(deliveries.sr_delivery_date).format('DD/MM/YYYY')}
                                                                        </Grid>
                                                                        <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}></Grid>
                                                                    </React.Fragment>
                                                                ))}
                                                                {ssrd.deliveryStatus !== 'Completed' &&
                                                                    <React.Fragment>
                                                                        <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                            <FormControl margin="normal">
                                                                                <TextField
                                                                                    name="receivedQty"
                                                                                    value={this.state.formData.deliveries[idx].receivedQty}
                                                                                    error={formErrors && formErrors['deliveries|received|'+idx] && true}
                                                                                    helperText={formErrors && formErrors['deliveries|received|'+idx]}
                                                                                    onChange={this.handleDecimalChange(idx)}
                                                                                    onBlur={this.handleDecimalChange(idx, 3)}
                                                                                    type="number"
                                                                                    inputProps={{ min: 0 }}
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                            <DatePicker
                                                                                type="receivedDate"
                                                                                id="receivedDate"
                                                                                name="receivedDate"
                                                                                value={this.state.formData.deliveries[idx].receivedDate}
                                                                                errorText={formErrors && formErrors['deliveries|received|'+idx]}
                                                                                onChange={this.handleDateChange(idx)}
                                                                                autoOk
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4} style={{textAlign:'right', borderBottom:'1px solid #ddd'}}>
                                                                            <FormControl margin="normal">
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <SwitchColorToggle
                                                                                            name="deliveryComplete"
                                                                                            checked={!!this.state.formData.deliveries[idx].deliveryComplete}
                                                                                            onChange={this.handleCheckChange(idx, 'deliveryComplete')}
                                                                                            value="1"
                                                                                        />
                                                                                    }
                                                                                    label="Delivery Complete?"
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                    <React.Fragment>
                                                        <Grid item xs={3} style={{backgroundColor:'#f5f5f5'}}>
                                                            <span style={{float:'left'}}>Total</span> <span  style={{float:'right'}}>{ssrd.quantity}</span>
                                                        </Grid>
                                                        <Grid item xs={3} style={{backgroundColor:'#f5f5f5', textAlign:'right'}}>
                                                            {ssrd.receivedTotalQty}
                                                        </Grid>
                                                        <Grid item xs={6} style={{backgroundColor:'#f5f5f5', textAlign: 'right'}}><b>{ssrd.scheduleStockStatus}</b></Grid>
                                                    </React.Fragment>
                                                </Grid>
                                            </form>
                                        </div>
                                    </div>
                                ))}
                                {formErrors && formErrors['part_locked'] &&
                                    <Grid container xs={12} style={{paddingTop:'1em'}}>
                                        <Grid item xs={12} style={{color: colors.red, textAlign:'right'}}>
                                            Some parts are locked, please try again later.
                                        </Grid>
                                    </Grid>
                                }
                                <div className='buttonRow'>
                                    <BackButton props={this.props} />
                                    <Button 
                                        onClick={this.handleConfirmationOpen}
                                        variant="contained"
                                        color="primary"
                                        disabled={this.state.disableUpdateButton}
                                    >
                                        Update
                                    </Button>
                                </div>
                            </PaddedPaper>
                        </Grid>
                </Grid>
                <Dialog 
                    open={this.state.confirmationOpen} 
                    onClose={this.han} 
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                        <DialogTitle id="form-dialog-title" color="secondary">Are You Sure You Want To Update This Delivery?</DialogTitle>
                        <DialogContent>
                            {this.state.dialogConfirmationTable}
                            <Textarea
                                id="notes"
                                name="notes"
                                label="Notes"
                                value={this.state.formData.notes}
                                rows={5}
                                error={formErrors && formErrors['notes']}
                                onChange={this.handleChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleConfirmationClose} variant="outlined" color="default">Cancel</Button>
                            <Button onClick={this.handleConfirmationSuccess} color="primary" autoFocus variant="contained" disabled={this.state.disableConfirmationSuccess}>Yes</Button>
                        </DialogActions>
                    </Dialog>
                    <SnackBar
                        variant="success"
                        anchorOriginVertical='bottom'
                        anchorOriginHorizontal='right'
                        open={this.state.snackbarOpen}
                        onClose={this.handleSnackbarClose}
                        message='You have successfully updated this supplier stock return delivery'
                    />
                </Grid>
            );
        }
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpdateSupplierStockReturnDelivery));