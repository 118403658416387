import React        from 'react';
import _            from 'lodash';
import moment       from 'moment';
import API          from 'API';
import { Redirect } from 'react-router-dom';

import { Grid, Typography  } from '@material-ui/core';

import BackButton    from 'Components/Common/Buttons/BackButton';
import DataTable     from 'Components/Common/DataTables/DataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';

class ViewCost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            part: {},
            isLoading: true,
            redirect: false
        };
    }

    componentDidMount(){
        this.getData(this.props.match.params.id);
    }

    getData = (partId) => {
        this.setState({
            isLoading: true
        }, () => {
            Promise.all([ 
                API.get(`/parts/${partId}/costs`, {
                    params: {
                        bySupplier: true
                    }
                }), 
                API.get(`/parts/${partId}`)
            ])
            .then(([costsResult, partResult]) =>  {
                if (partResult.data.errors) {
                    this.setState({ redirect: true });
                } else {
                    Object.keys(costsResult.data).forEach(key => {
                        Object.keys(costsResult.data[key].costs).forEach(cost_key => {
                            costsResult.data[key].costs[cost_key].date_from = costsResult.data[key].costs[cost_key].date_from && moment(costsResult.data[key].costs[cost_key].date_from).format("DD-MM-YYYY");
                            costsResult.data[key].costs[cost_key].date_to = costsResult.data[key].costs[cost_key].date_to && moment(costsResult.data[key].costs[cost_key].date_to).format("DD-MM-YYYY");
                        });
                    });
                    // Order with the default supplier first
                    var rows = _.orderBy(costsResult.data, ['default'],['asc']);
    
                    this.setState({
                        ...this.state,
                        rows: rows,
                        part: partResult.data,
                        isLoading: false
                    });
                }
            });
        })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/costs/search" />
        } else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Cost Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            {(this.state.isLoading && (
                                <LoadingCircle />
                            )) || (
                                <React.Fragment>
                                    <Typography variant="h5" style={{marginBottom:25}}>
                                        {this.state.part.part_number + ' - ' + this.state.part.part_description}
                                    </Typography>
                                    {_.map(this.state.rows, (row, idx) => { 
                                        return (
                                            <React.Fragment key={idx}>
                                                <Typography variant="subtitle1">
                                                    <strong>Supplier: {row.company_name}</strong> 
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    Reference: {row.reference}
                                                </Typography>
                                                <DataTable 
                                                    config={{
                                                        key: 'cost_id',
                                                        pagination: false,
                                                        alternatingRowColours: true,
                                                        plainHeader: true,
                                                        rowProps: row => {
                                                            return ({
                                                                style: {
                                                                    backgroundColor: row.default === 'Yes' && 'rgb(241, 248, 233)'
                                                                }
                                                            });
                                                        }}
                                                    }
                                                    columns={[
                                                        {
                                                            heading: '',
                                                            field: row => `Cost ${row.rowNumberReversed}:`
                                                        },
                                                        {
                                                            heading: 'Qty',
                                                            field: row => row.qty
                                                        },
                                                        {
                                                            heading: 'Cost',
                                                            field: row => '£'+row.cost
                                                        },
                                                        {
                                                            heading: 'Cost Per Unit',
                                                            field: row => '£'+row.cost_per_unit
                                                        },
                                                        {
                                                            heading: 'Date From',
                                                            field: row => row.date_from ? row.date_from : '-'
                                                        },
                                                        {
                                                            heading: 'Date To',
                                                            field: row => row.date_from && row.date_to === '' ? 'Current' : (row.date_to ? row.date_to : '-')
                                                        },
                                                        {
                                                            heading: 'Default',
                                                            field: row => row.default
                                                        },
                                                    ]}
                                                    rows={row.costs}
                                                />
                                                <br></br><br></br>
                                            </React.Fragment>
                                        );
                                    })}
                                    <div className='buttonRow'>
                                        <BackButton props={this.props} />
                                    </div>
                                </React.Fragment>
                            )}
                        </PaddedPaper>
                    </Grid>
                </Grid>
            );
        }
    }
}

export default ViewCost;