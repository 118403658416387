import { Grid, Typography } from '@material-ui/core';
import CiDataTable from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import { getCurrency, getCurrencyChange, getPercentage } from 'Helpers/ReportsHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const currentMonth = parseInt(moment().format('M'));   
const currentYear = parseInt(moment().format('YYYY'));
const currentDay = parseInt(moment().format('D'));

const initialState = props => ({
    data: {},
    months: props.months,
    isLoading: true
})

class ViewMonthBudget extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cid !== this.props.cid) {
            this.setState(initialState(this.props), this.getData);
        }
    }

    getData = () => {
        let data = this.props.data;
        let _data = {
            budget: 0,
            cost: 0,
            months: [],
            days: []
        }
        if (this.props.cid !== 'all') {
            _.each(data?.months, i => {
                let item = _.find(i.part_categories, j => j.tympc_pc_id === this.props.cid);
                if (item) {
                    _data.budget += parseFloat(item.tympc_budget);
                    _data.cost += parseFloat(item.tympc_cost);
                    _data.months.push({
                        month: i.ytm_month,
                        budget: parseFloat(item.tympc_budget),
                        cost: parseFloat(item.tympc_cost),
                    });
                }
            });
        } else {
            _data.budget = data?.yt_budget;
            _data.cost = data?.yt_cost;
            _data.months = _.map(data?.months, i => ({
                month: i?.ytm_month ?? 0,
                budget: i?.ytm_budget ?? 0,
                cost: i?.ytm_cost ?? 0,
            }));
        }
        _.each(_.orderBy(this.state.months, [i => parseInt(i.year), i => parseInt(i.month)], ['asc','asc']), m => {
            let monthData = _.find(_data.months, i => parseInt(i.month) === parseInt(m.month));
            let lastDay = _.last(_data.days);
            _data.days = [..._data.days, ...this.getDays({
                budget: parseFloat(monthData?.budget) ?? 0,
                cost: parseFloat(monthData?.cost) ?? 0,
                ...m
            }, lastDay?.runningBudget, lastDay?.runningCost, lastDay?.runningMargin)];
        });

        this.setState({data: _data, isLoading: false});
    }

    getDays = (j, runningBudget = 0, runningCost = 0, runningMargin= 0) => {

        let days = [];  

        _.each(_.range(1, parseInt(j.days) + 1), d => {
            let date = moment(`${j.year}-${j.month}-${d}`);
            let type = (date.isoWeekday() === 6 || date.isoWeekday() === 7) ?
                'Weekend' : (
                    (j.bankHolidays && j.bankHolidays.includes(d)) ?
                        'Bank Holiday' : null
                );
            let budget = !type ? parseFloat(j.budget / j.workingDays) : 0;
            let cost = !type ? parseFloat(j.cost / j.workingDays) : 0;
            let margin = parseFloat(budget - cost);

            runningBudget +=  parseFloat(budget);
            runningCost +=  parseFloat(cost);
            runningMargin +=  parseFloat(margin);

            days.push({
                day: d,
                month: j.name,
                date: date,
                backgroundColor: type ? colors.red : colors.green,
                color: type ? colors.white : colors.white,
                type: type ? type : 'Weekday',
                name: date.format('dddd'),
                clenyDate: clenyDate(`${j.year}-${j.month}-${d}`),

                budget: budget,
                cost: cost,
                margin: margin,
                pmargin: getPercentage(margin, budget, 2),

                runningBudget: runningBudget,
                runningCost: runningCost,
                runningMargin: runningMargin,
            })
        })

        return days;
    }

    render() {

        const { data, months, isLoading } = this.state;
        const { cid } = this.props;

        if (isLoading) return <LoadingCircle />;

        return (
            <>
                {data ?
                    _.map([
                        {
                            title: `Per Year (${1})`,
                            div: 1,
                        },
                        {
                            title: `Per Month (${12})`,
                            div: 12,
                        },
                        {
                            title: `Per Week (${52})`,
                            div: 52,
                        },
                        {
                            title: `Per working day (${_.sumBy(months, i => parseInt(i.workingDays))})`,
                            div: _.sumBy(months, i => parseInt(i.workingDays))
                        },
                        
                    ], ({title, div}) => 
                        <Grid item xs={3}>
                            <PaddedPaper>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" gutterBottom>
                                            <b>{title}</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant='body2' >
                                            <>Sales</>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant='body2' >
                                            {getCurrency(data.budget / div)}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography variant='body2' >
                                            <>Cost</>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant='body2' >
                                            {getCurrency(data.cost / div)}
                                        </Typography>
                                    </Grid>
                                
                                    <Grid item xs={4}>
                                        <Typography variant='body2' >
                                            <>Margin £</> 
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant='body2' >
                                        £{getCurrencyChange( (data.budget / div) , (data.cost / div))} 
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant='body2' >
                                            <>Margin %</> 
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant='body2' >
                                            {getPercentage( (data.budget / div) - (data.cost / div), (data.budget / div), 2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    ) :
                    <Grid item xs={12} >
                        <PaddedPaper style={{backgroundColor: colors.importantInfo}}>
                            <Typography variant="h5">
                                No Budget Set
                            </Typography>
                        </PaddedPaper>  
                    </Grid>
                }
                {!!data &&
                    <Grid item xs={12}>
                        <CiDataTable
                            config={{
                                key: 'day',
                                style: i => ({
                                    backgroundColor: i.backgroundColor,
                                    color: i.color,
                                    fontWeight: i.clenyDate === `${currentDay.toString().padStart(2,'0')}/${currentMonth.toString().padStart(2,'0')}/${currentYear}` && 'bolder',
                                }),
                                sticky: true,
                            }}
                            columns={[
                                {
                                    heading: 'Date',
                                    field: 'clenyDate',
                                },
                                {
                                    heading: 'Month',
                                    field: 'month',
                                },
                                {
                                    heading: 'Day',
                                    field: 'name'
                                },
                                {
                                    heading: 'Type',
                                    field: 'type'
                                },
                                {
                                    heading: 'Sales',
                                    field: 'budget',
                                    fieldFormat: 'currency'
                                },
                                {
                                    heading: 'Sales (Running)',
                                    field: 'runningBudget',
                                    fieldFormat: 'currency'
                                },
                                {
                                    heading: 'cost',
                                    field: 'cost',
                                    fieldFormat: 'currency'
                                },
                                {
                                    heading: 'cost (Running)',
                                    field: 'runningCost',
                                    fieldFormat: 'currency'
                                },
                                {
                                    heading: 'Margin £',
                                    field: 'margin',
                                    fieldFormat: 'currency'
                                },
                                {
                                    heading: 'Margin (Running)',
                                    field: 'runningMargin',
                                    fieldFormat: 'currency'
                                },
                                {
                                    heading: 'Margin %',
                                    field: 'pmargin',
                                },
                            ]}
                            rows={data.days}
                        />
                    </Grid>
                }
            </>
        );
    }
}

export default ViewMonthBudget;