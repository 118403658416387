import React from 'react';
import Typography from '@material-ui/core/Typography';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import ConfirmationDialog from './../../Common/Dialogs/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import SnackBar from './../../Common/SnackBars/SnackBar'
import Button from '@material-ui/core/Button';
import API from '../../../API';
import AutoCompleteSelect from '../../Common/Selects/AutoCompleteSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import _ from 'lodash';
import VideoCategoryList from './VideoCategoryList';
import LoadingCircle from '../../Common/LoadingCircle/LoadingCircle';
import { 
    connect 
} from 'react-redux';

import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

const initialState = () => ({
    formData: {
        addCategory: {
            type: 'category',
            name: ''
        },
        addSubCategory: {
            type: 'subCategory',
            name: ''
        }
    },
    formErrors: {
        addCategory: {},
        addSubCategory: {}
    },
    snackbarOpen: {
        addCategory: false,
        addSubCategory: false
    },
    confirmationOpen: {
        addCategory: false,
        addSubCategory: false
    },
    categories: {},
    topCategoryList: [],
    currentTab: 0,
    access: {
        addVideoCategory: false
    },
    isLoading: true,
});

class VideoCategories extends React.Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'Video:Categories';
        this.state              = this.getInitialState(initialState());
    }
    componentDidMount(){
        this.checkAccess();
        this.getCategories();
    }
    getCategories = () => {
        API.get('/videos/categories/all').then(result => {
            if(result.data) {
                let topCategoryList = _.map(result.data, (el) => {
                    return _.assign({
                        value: el.video_cat_id,
                        label: el.video_cat_name
                    });
                });
                this.setState({
                    categories: result.data,
                    isLoading: false,
                    topCategoryList: topCategoryList
                });
            }
        });
    };
    checkAccess = () => {
        API.get('/staff/my/access/check/video-categories:add')
        .then((addCategoryRes) =>  {
            addCategoryRes.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addVideoCategory: addCategoryRes.data.has_access
                    }
                });
        });
    }
    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        }, () => {this.savePageState()});
    };
    /*
     * Add Category
     */
    addCategory = () => {
        API.post('/videos/categories', this.state.formData.addCategory).then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addCategory: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.setState({
                    ...initialState,
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addCategory: true
                    }
                });
                this.checkAccess();
                this.getCategories();
            }
            this.props.scrollToTop();
        });
    }
    handleAddCategoryChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                addCategory: {
                    ...this.state.formData.addCategory,
                    [e.target.name]: e.target.value
                }
            }
        });
    };
    handleAddCategorySnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addCategory: false
            }
        });
    };
    handleAddCategoryConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addCategory: true
            }
        });
    };
    handleAddCategoryConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addCategory: false
            }
        });
    };
    handleAddCategoryConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addCategory: false
            }
        });
        this.addCategory();
    };
    /*
     * Add Sub-Category
     */
    addSubCategory = () => {
        API.post('/videos/categories', this.state.formData.addSubCategory).then((result) => {
            if(result.data.errors) {
                this.setState({
                    formErrors: {
                        ...this.state.formErrors,
                        addSubCategory: formatValidationErrors(result.data.errors)
                    }
                });
            } else {
                this.setState({
                    ...initialState,
                    currentTab: 1,
                    snackbarOpen: {
                        ...this.state.snackbarOpen,
                        addSubCategory: true
                    }
                });
                this.checkAccess();
                this.getCategories();
            }
            this.props.scrollToTop();
        });
    }
    handleAddSubCategoryChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                addSubCategory: {
                    ...this.state.formData.addSubCategory,
                    [e.target.name]: e.target.value
                }
            }
        });
    };
    handleAddSubCategorySnackbarClose = () => {
        this.setState({
            snackbarOpen: {
                ...this.state.snackbarOpen,
                addSubCategory: false
            }
        });
    };
    handleAddSubCategoryConfirmationOpen = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addSubCategory: true
            }
        });
    };
    handleAddSubCategoryConfirmationClose = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addSubCategory: false
            }
        });
    };
    handleAddSubCategoryConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: {
                ...this.state.confirmationOpen,
                addSubCategory: false
            }
        });
        this.addSubCategory();
    };
    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                addSubCategory: {
                    ...this.state.formData.addSubCategory,
                    [name]: res.value,
                }
            }
        });
    };
  render() {
    const { access, currentTab, formData, formErrors, isLoading, snackbarOpen, confirmationOpen } = this.state;
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5">
                    Video Categories
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    {isLoading && (
                        <Grid item xs={12} style={{paddingTop: 24}}>
                            <LoadingCircle />
                        </Grid>
                    )}
                    {access.addVideoCategory && (
                        <React.Fragment>
                            <AppBar position="static" style={{backgroundColor:'white'}} >
                                <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary">
                                    <Tab label="Add Category" />
                                    <Tab label="Add Sub-Category" />
                                </Tabs>
                            </AppBar>
                            {currentTab === 0 && (
                                <TabContainer>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12} lg={6}>
                                            <PaddedPaper>
                                                <Typography variant="h6">
                                                    Add Video Category
                                                </Typography>
                                                <form onChange={this.handleAddCategoryChange} noValidate autoComplete="off">
                                                    <TextField
                                                        id="name"
                                                        name="name"
                                                        label="Category Name *"
                                                        value={formData.addCategory.name}
                                                        error={formErrors.addCategory && formErrors.addCategory['name'] && true}
                                                        helperText={formErrors.addCategory && formErrors.addCategory['name']}
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                    <div className='buttonRow'>
                                                        <Button 
                                                            onClick={this.handleAddCategoryConfirmationOpen}
                                                            variant="contained" 
                                                            color="primary" 
                                                           
                                                            disabled={!this.state.formData.addCategory.name}
                                                        >
                                                            Add
                                                        </Button>
                                                    </div>
                                                    <ConfirmationDialog 
                                                        open={confirmationOpen.addCategory} 
                                                        success={this.handleAddCategoryConfirmationSuccess} 
                                                        close={this.handleAddCategoryConfirmationClose} 
                                                        title="Add A New Video Category?" 
                                                        message="Are you sure you want to add a new video category?"
                                                    />
                                                    <SnackBar
                                                        variant="success"
                                                        anchorOriginVertical='bottom'
                                                        anchorOriginHorizontal='right'
                                                        open={snackbarOpen.addCategory}
                                                        onClose={this.handleAddCategorySnackbarClose}
                                                        message="You have successfully added a new video category"
                                                    />
                                                </form>
                                            </PaddedPaper>
                                        </Grid>
                                    </Grid>
                                </TabContainer>
                            )}
                            {currentTab === 1 && (
                                <TabContainer>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12} lg={6}>
                                            <PaddedPaper>
                                                <Typography variant="h6">
                                                    Add Video Sub-Category
                                                </Typography>
                                                <form onChange={this.handleAddSubCategoryChange} noValidate autoComplete="off">
                                                    <FormControl error={formErrors.addSubCategory && formErrors.addSubCategory['topCategory'] && true} fullWidth margin="normal">
                                                        <AutoCompleteSelect 
                                                            options={this.state.topCategoryList} 
                                                            label='Top Category *'
                                                            onChange={this.handleSelectChange('topCategory')}
                                                        />
                                                        {formErrors.addSubCategory && formErrors.addSubCategory['topCategory'] &&
                                                            <FormHelperText>{formErrors.addSubCategory['topCategory']}</FormHelperText>
                                                        }
                                                    </FormControl>
                                                    <TextField
                                                        id="name"
                                                        name="name"
                                                        label="Sub-Category Name *"
                                                        value={this.state.formData.addSubCategory.name}
                                                        error={formErrors.addSubCategory && formErrors.addSubCategory['name'] && true}
                                                        helperText={formErrors.addSubCategory && formErrors.addSubCategory['name']}
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                    <div className='buttonRow'>
                                                        <Button 
                                                            onClick={this.handleAddSubCategoryConfirmationOpen}
                                                            variant="contained" 
                                                            color="primary" 
                                                           
                                                            disabled={!this.state.formData.addSubCategory.name && !this.state.formData.addSubCategory.topCategory}
                                                        >
                                                            Add
                                                        </Button>
                                                    </div>
                                                    <ConfirmationDialog 
                                                        open={confirmationOpen.addSubCategory} 
                                                        success={this.handleAddSubCategoryConfirmationSuccess} 
                                                        close={this.handleAddSubCategoryConfirmationClose} 
                                                        title="Add a New Video Sub-Category?"
                                                        message="Are you sure you want to add a new video sub-category?"
                                                    />
                                                    <SnackBar
                                                        variant="success"
                                                        anchorOriginVertical='bottom'
                                                        anchorOriginHorizontal='right'
                                                        open={snackbarOpen.addSubCategory}
                                                        onClose={this.handleAddSubCategorySnackbarClose}
                                                        message="You have successfully added a new video sub-category"
                                                    />
                                                </form>
                                            </PaddedPaper>
                                        </Grid>
                                    </Grid>
                                </TabContainer>
                            )}
                        </React.Fragment>
                    )}
                    <div className='padding3 paddingTop0'>
                        <VideoCategoryList categories={this.state.categories} />
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
  }
}
const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoCategories);