import API from 'API';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import ImageWithError from 'Components/Common/ImageWithError/ImageWithError';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import SelectPartWithQR from 'Components/Common/Selects/SelectPartWithQR';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons from 'Helpers/IconHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import {
    Button, Dialog, DialogActions, DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup
} from '@material-ui/core/';

import logo from 'Assets/Images/clenaware_logo_icon.png';

const initialState = {
    lists: {
        parts:   [],
        staff:   []
    },
    formData: {
        productType: 0,
        part:        null,
        category:    null,
        str:         null,
        stts:        null,
        notes:       '',
    },
    details: [],
    access: {},
    isLoading: true,
    formErrors: {},
}

class NewStockTake extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;  
    }

    componentDidMount() { this.getData(); }

    getData = () => {
        Promise.all([
            API.get('/parts/all',{params:{withDefaultImg:true, isStockAble:true}}),
            API.get('/staff/all', {params:{active:true}}),
        ]).then(([PartsRes, staffRes]) => {
            this.setState({
                lists: {
                    parts: PartsRes.data,
                    staff: staffRes.data,
                },
                isLoading: false,
            });
        })
    }

    handleChangeProductType = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                productType: parseInt(e.target.value),
            }
        });
    }

    handleSelectChange = type => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [type]: e ? e.value : null,
            }
        });
    }

    addPartType = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                category: null,
            },
            details: [
                ...this.state.details,
                ..._.filter(this.state.lists.parts, { part_type_id: this.state.formData.category }),
            ],
        })
    }

    addPart = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                part: null,
            },
            details: [
                ...this.state.details,
                _.find(this.state.lists.parts, {part_id: this.state.formData.part}),
            ],
        })
    }

    loadPartImage = (rowData) => {
        this.setState({ 
            partImageDialog: true,
            partImageDialogData: rowData.default_image?.file_path
        });
    };

    deleteRow = id => {
        this.setState({
            details: _.filter(this.state.details, i=>i.part_id !== id),
        });
    }

    submit = () => {
        API.post('/stock/take', {
            details:     _.map(this.state.details, i=>i.part_id), 
            takeStaff:   this.state.formData.stts,
            reviewStaff: this.state.formData.str,
            notes:       this.state.formData.notes,
        })
        .then(result => {
            if(result.data.errors) {
                this.setState({ formErrors: formatValidationErrors(result.data.errors) });
                this.props.deployDialog(
                    <>
                        The following parts have errors.
                        <br/>
                        <br/>
                        <DataTable
                            config={{
                                key: 'field',
                                alternatingRowColours: true,
                                responsiveimportant: true,
                            }}
                            columns={[
                                {
                                    field: rowData => 
                                        <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            {rowData && rowData.default_image?.thumbnail_file_path ?
                                                <Button >
                                                    <img src={rowData.default_image?.thumbnail_file_path} alt={rowData.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                </Button> 
                                            : 
                                                <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                                            }
                                        </div>,
                                    sizeToContent: true,
                                    alignment: 'center',
                                },
                                {
                                    heading: 'Number',
                                    field: rowData => rowData.part_number,
                                    important: true,
                                    sizeToContent: true
                                },
                                {
                                    heading: 'Description',
                                    field: rowData => rowData.part_description,
                                    important: true,
                                    main: true,
                                },   
                                {
                                    heading: 'Issue',
                                    field: rowData => {
                                        if (rowData.error == 'stockTake')
                                            return 'Part Already In A Stock Take';
                                        if (rowData.error == 'locked')
                                            return 'Part Is Locked';
                                        return `Part has pending stock`
                                    },
                                    important: true,
                                    main: true,
                                    alignment: 'center',
                                },
                                {
                                    heading: 'Quantity',
                                    field: rowData => _.isNumber(rowData.error) ? rowData.error : '-',
                                    main: true,
                                    alignment: 'center',
                                }
                            ]}
                            rows={_.map(_.filter(result.data.errors, i=> i.err === 'stock_exists' || i.err === 'part_in_stock_take' || i.err === 'part_locked'), i=>{
                                return _.assign({
                                    ..._.find(this.state.lists.parts,{part_id: i.field}),
                                    ...i,
                                    error: i.msg
                                })
                            })}
                        />
                        <div className='buttonRow'>
                            <Button onClick={this.props.closeDialog} variant="outlined" > Close </Button>
                        </div>
                    </>
                    , 'Parts Have Pending Stock', 'Error', 'md');
            } else {
                window.location.href = `/stock/take`;
            }
        });
    }

    clearForm = () => { this.setState({details: initialState.details}); }

    handleNoteChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                notes: e.target.value
            }
        })
    }

    render(){
        const { lists, formData, isLoading, formErrors, details } = this.state;

        if (isLoading) return (<LoadingCircle />);
        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <Grid container xs={12}>
                            {details.length == 0 &&
                                <Grid item xs={12}>
                                    <RadioGroup
                                        name="changeProductType"
                                        onChange={this.handleChangeProductType}
                                        row
                                    >
                                        <FormControlLabel
                                            value={0}
                                            control={<Radio color="primary" />}
                                            label="Parts"
                                            labelPlacement="end"
                                            checked={formData.productType ? false : true}
                                        />
                                        <FormControlLabel
                                            value={1}
                                            control={<Radio color="primary" />}
                                            label="Category's"
                                            labelPlacement="end"
                                            checked={formData.productType ? true : false}
                                        />
                                    </RadioGroup>
                                </Grid>
                            }
                            {formData.productType == 0 &&
                                <Grid item xs={12}>
                                    <SelectPartWithQR
                                        parts=      {_.map(_.filter(_.filter(lists.parts, i => !i.part_locked), i => !_.find(details, j => j.part_id == i.part_id)), i => 
                                            _.assign({value: i.part_id, label: `${i.part_number} - ${i.part_description}`, part: i})
                                        )} 
                                        label=      'Part *'
                                        onChange=   {this.handleSelectChange('part')}
                                        error=      {formErrors && formErrors['part'] && true}
                                        value=      {formData.part || null}
                                        margin=     'none'
                                    />
                                    <div className='buttonRow'>
                                        {details.length > 0 && <Button onClick={this.clearForm} variant="outlined"> Clear Form </Button>}
                                        <Button onClick={this.addPart} variant="contained" color="primary" disabled={!formData.part}> Add </Button>
                                    </div>
                                </Grid>
                            }
                            {formData.productType == 1 &&
                                <Grid item xs={12}>
                                    <FormControl fullWidth margin="none">
                                        <AutoCompleteSelect 
                                            options={
                                                _.map(
                                                    _.filter(
                                                        _.uniqBy(lists.parts, i => i.part_type_id), 
                                                        i => !_.find(
                                                            _.uniqBy(details, j => j.part_type_id),
                                                            l => l.part_type_id == i.part_type_id
                                                        )
                                                    ), 
                                                    i => _.assign({
                                                        value: i.part_type_id,
                                                        label: `${i.part_type.part_type_code} - ${i.part_type.part_type_name}`
                                                    })
                                                )
                                            } 
                                            label='Category *'
                                            onChange={this.handleSelectChange('category')}
                                            value={formData.category || null}
                                        />
                                    </FormControl>
                                    <div className='buttonRow'>
                                        {details.length > 0 && <Button onClick={this.clearForm} variant="outlined"> Clear Form </Button>}
                                        <Button onClick={this.addPartType} variant="contained" color="primary"> Add </Button>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Grid lg={6}></Grid>
                <Grid item xs={12} lg={6}>
                    <PaddedPaper>
                        <FormControl fullWidth margin="none">
                            <AutoCompleteSelect 
                                options={_.map(lists.staff, i => _.assign({
                                    value: i.staff_id,
                                    label: `${i.staff_first_name} ${i.staff_last_name}`
                                }))}
                                label='Staff To Count Stock *'
                                onChange={this.handleSelectChange('stts')}
                                value={formData.stts || null}
                            />
                        </FormControl>
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PaddedPaper>
                        <FormControl fullWidth margin="none">
                            <AutoCompleteSelect 
                                options={_.map(lists.staff, i => _.assign({
                                    value: i.staff_id,
                                    label: `${i.staff_first_name} ${i.staff_last_name}`
                                }))}
                                label='Staff To Review *'
                                onChange={this.handleSelectChange('str')}
                                value={formData.str || null}
                            />
                        </FormControl>
                    </PaddedPaper>
                </Grid>
                    {details.length > 0 &&
                        <>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <DataTable
                                        config={{
                                            key: 'idx',
                                            alternatingRowColours: true,
                                            responsiveimportant: true,
                                            isLoading: isLoading,
                                        }}
                                        columns={[
                                            {
                                                field: rowData => 
                                                    <div style={{width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                        {rowData && rowData.default_image?.thumbnail_file_path ?
                                                            <Button onClick={() => this.loadPartImage(rowData)}>
                                                                <img src={rowData.default_image?.thumbnail_file_path} alt={rowData.part_description} style={{maxHeight:35, maxWidth:35}} />
                                                            </Button> 
                                                        : 
                                                            <img src={logo} alt="logo" style={{maxHeight:35, maxWidth:35}}/>
                                                        }
                                                    </div>,
                                                sizeToContent: true,
                                                alignment: 'center',
                                            },
                                            {
                                                heading: 'Number',
                                                field: rowData => rowData.part_number,
                                                important: true,
                                                sizeToContent: true
                                            },
                                            {
                                                heading: 'Description',
                                                field: rowData => rowData.part_description,
                                                important: true,
                                                main: true,
                                            },   
                                            {
                                                actions: rowData => {return([{name: 'Remove',  icon: icons.delete,  onClick: ()=>{this.deleteRow(rowData.part_id)}},])}
                                            }
                                        ]}
                                        rows={_.orderBy(details, ['part_number'], ['asc'])}
                                    />
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <Textarea
                                        id="notes"
                                        name="notes"
                                        label='Stock Take Notes'
                                        value={formData.notes}
                                        rows={10}
                                        onChange={this.handleNoteChange}
                                        noMargin
                                    />
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={12}>
                                <PaddedPaper>
                                    <div className='buttonRow'><Button onClick={()=>{
                                        this.props.deployConfirmation('Are you sure you want to create this stock take?', 'Create Stock Take', this.submit)
                                    }} 
                                    variant="contained" 
                                    color="primary"
                                    disabled={!formData.stts || !formData.str}> Create </Button></div>
                                </PaddedPaper>
                            </Grid>
                        </>
                    }
                <Dialog
                    open={this.state.partImageDialog}
                    onClose={this.handlePartImageDialogClose}
                    scroll="body"
                    disableBackdropClick	
                    disableEscapeKeyDown
                >                
                    <DialogContent>
                        <ImageWithError src={this.state.partImageDialogData} alt='Part Image' style={{maxWidth: 500}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{
                            this.setState({partImageDialog: false})
                        }} variant="outlined" color="default">Close</Button>
                    </DialogActions>
                </Dialog>  
            </Grid>
        )
    }

}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                               => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size)  => dispatch(deployDialog(content, title, variant,size)),
    deployConfirmation: (message, title, success)        => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)                   => dispatch(deploySnackBar(variant, msg)),
})

export default connect(null, mapDispatchToProps)(NewStockTake);