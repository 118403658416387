import API from 'API';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DataTable from 'Components/Common/DataTables/CiDataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons from 'Helpers/IconHelper';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography } from '@material-ui/core/';

const initialState = {
    id: 0,
    lists: {
        parts:   [],
        reasons: [],
    },
    formDataTemplate:   {
        name: '',
        desc: ''
    },
    formDataDetail:   {
        part:   null,
        qty:    0,
        in:     1,
        reason: null,
    },
    formDataEditDetail:   {
        part:   null,
        qty:    0,
        in:     1,
        reason: null,
        idx:    0
    },
    details:    [],
    isLoading:  true,
    access: {},
    showEditDialog: false,
    formErrors: []
}


class StockMovementTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {...initialState, id: this.props.match.params.smtId == 'new' ? 0 : this.props.match.params.smtId }
    }

    componentDidMount() {
        this.getAccess();
        this.getData();
        this.state.id && this.getTemplate();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.smtId != prevProps.match.params.smtId) 
            this.setState({id: this.props.match.params.smtId == 'new' ? 0 : this.props.match.params.smtId},()=>{this.state.id && this.getTemplate();});
    }

    getAccess = () => {
        Promise.all([
            API.get('/staff/my/access/check/template-stock-movement'),
            API.get('/staff/my/access/check/delete-template-stock-movement'),
        ])
        .then(([viewRes, deleteRes]) => {
            this.setState({
                access: {
                    add:    (viewRes?.data?.has_access)   || false,
                    delete: (deleteRes?.data?.has_access) || false,
                },
            })
        });
    }
        
    getTemplate = () => {
        API.get(`/stock/movement/templates/${this.state.id}`)
        .then(templates => {
            this.setState({
                formDataTemplate:   {
                    name: templates.data.smt_name,
                    desc: templates.data.smt_desc
                },
                details:        _.map(templates.data.details, (i,idx) => _.assign({
                                                                            part:   i.smtd_part_id,
                                                                            qty:    i.smtd_qty,
                                                                            in:     i.smtd_in,
                                                                            reason: i.smtd_reason_id,
                                                                            idx
                                                                        })
                )
            }, ()=> {
                if (templates.data.in_use.length > 0) {
                    this.props.deployDialog(
                        <>
                            <Typography variant='body1' style={{padding: '1em 0'}}>
                                The following stock movements use this template, they need to be completed before you can edit it:
                            </Typography>
                            <DataTable 
                                config={{
                                    key: 'part',
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                }}
                                columns={[
                                    {
                                        heading:    'Reference',
                                        field:      rowData => rowData.sm_ref,
                                        dataRef:    'sm_ref',
                                        
                                    },
                                    {
                                        actions: rowData => ([
                                            {name: 'View',   icon: icons.search,   onClick: ()=>{this.props.history.push(`/stock/movement/edit/${rowData.sm_id}`);}},
                                        ])
                                    },
                                ]}
                                rows={_.orderBy(templates.data.in_use, ['sm_ref'], ['asc'])}
    
                            />
                            <div className='buttonRow' style={{padding: '1em', paddingTop:0}}>
                                <Button variant='outlined' onClick={()=>{this.props.closeDialog();this.props.history.push(`/stock/movement/templates`);} }>Back</Button>
                            </div>
                        </>, 'Template In Use', 'warning', 'md');
                }
            })
        })
    }

    getData = () => {
        Promise.all([
            API.get('/parts/withStockActivity', {params:{includeLocked: true}}),
            API.get('/stock/movement/reasons/all'),
        ]).then(([partsRes, reasonsRes]) => {
            this.setState({
                lists: {
                    parts:      _.map(partsRes.data, i => _.assign({
                        value: i.part_id,
                        label: `${i.part_number} - ${i.part_description}`, 
                        part:  i
                    })),
                    reasons:    _.map(reasonsRes.data, i => _.assign({
                        value: i.smr_id,
                        label: i.smr_reason
                    }))
                },
                isLoading: false
            })
        })
    }

    handleChange = fd => e => {
        this.setState({
            [fd]: {
                ...this.state[fd],
                [e.target.name]: e.target.value
            }
        });
    }

    handleSelectChange = fd => f => e => {
        let value = e ? e.value : null;
        this.setState({
            [fd]: {
                ...this.state[fd],
                [f]: value
            }
        });
    }

    addPart = () => {
        this.setState({
            details: [
                ...this.state.details,
                {
                    ...this.state.formDataDetail,
                    idx:  this.state.details.length 
                }
            ],
            formDataDetail: initialState.formDataDetail
        })
    }
    getTableColumns = () => {
        const {lists:{parts, reasons}, lists, formDataEditDetail}  = this.state;

        return [
            {
                heading:        'Part',
                field:          rowData => _.find(parts,i=>i.value==rowData.part).part.part_number,
                dataRef:        'part',
                sizeToContent:  true,
                style:          {width: '6%'}
            },
            {
                heading:    'Description',
                field:      rowData => _.find(parts,i=>i.value==rowData.part).part.part_description,
                dataRef:    'part',
                style:      {width: '59%'}
            },
            {
                heading:        'Stock',
                field:          rowData => parseFloat(rowData.qty).toFixed(3),
                dataRef:        'qty',
                alignment:      'center',
                sizeToContent:  true,
                style:          {width: '5%'}
            },
            {
                heading:    'Reason',
                field:      rowData => _.find(reasons,i=>i.value==rowData.reason).label,
                dataRef:    'reason',
                alignment:  'left',
                style:      {width: '22%'}
            },
            {
                actions: rowData => {
                    return([
                        {name: 'Edit',   icon: icons.edit,   onClick: () => {
                                                                                this.setState({
                                                                                    formDataEditDetail: rowData, 
                                                                                    showEditDialog: true
                                                                                })
                                                                            }
                        },
                        {name: 'Delete',   icon: icons.delete,   onClick: ()=>{this.deleteRow(rowData.idx)}},
                    ])
                }
            }
        ]
    }

    updatePart = () => {
        let {formDataEditDetail} = this.state;
        if (parseFloat(formDataEditDetail.qty) == 0){
            this.deleteRow(formDataEditDetail.idx);
        } else {
            let details = this.state.details;
            details[formDataEditDetail.idx] = formDataEditDetail;
            this.setState({
                details, 
                showEditDialog: false
            });
        }
    }

    deleteRow = idx => {
        this.setState({
            details: _.map(_.filter(this.state.details, i => i.idx != idx), (i,idx) => _.assign({...i,idx})),
            showEditDialog: false
        });
    }

    createTemplate = () => {
        let route = this.state.id ? `/stock/movement/templates/${this.state.id}/update` : '/stock/movement/templates';
        API.post(route, {...this.state.formDataTemplate, details: this.state.details})
        .then((res) => { 
            if (res.data.errors)  this.setState({formErrors: formatValidationErrors(res.data.errors)});
            if (!res.data.errors) this.props.history.push(`/stock/movement/templates`); 
        });
    }

    render() {
        const {formDataTemplate, formDataDetail, formDataEditDetail, isLoading, lists, details, showEditDialog, formErrors} = this.state;
        
        if (isLoading) return( <LoadingCircle/> );
        return (
            <Grid container xs={12}>
                <Grid item xs={12} lg={5}>
                    <PaddedPaper>
                        <TextField
                            fullWidth
                            margin="normal"
                            name="name"
                            label='Template Name *'
                            value={formDataTemplate.name}
                            onChange={this.handleChange('formDataTemplate')}
                            error={formErrors.name}
                            helperText={formErrors.name}
                        />
                        <Textarea
                            id="desc"
                            name="desc"
                            label='Description'
                            value={formDataTemplate.desc}
                            onChange={this.handleChange('formDataTemplate')}
                            noMargin
                        />
                    </PaddedPaper>
                </Grid>
                <Grid item xs={12} lg={12} style={{marginTop:'1.5em'}}>
                    <PaddedPaper>
                        <Grid container xs={12} spacing={3} style={{marginRight: 0, alignItems: 'flex-end'}}>
                            <Grid item xs={12} style={{paddingBottom: 10}}>
                                <Typography variant="h6">
                                    Add Part
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{paddingTop: 0}}>
                                <FormControl fullWidth margin="none">
                                    <AutoCompleteSelect 
                                        options={_.filter(lists.parts, i => !i.part_locked)} 
                                        label='Part *'
                                        onChange={this.handleSelectChange('formDataDetail')('part')}
                                        value={formDataDetail.part}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} style={{paddingTop: 0}}>
                                <FormControl fullWidth margin="none">
                                    <AutoCompleteSelect 
                                        options={[
                                            {value: 1, label: 'Stock In'},
                                            {value: 0, label: 'Stock Out'}
                                        ]} 
                                        label='Movement'
                                        onChange={this.handleSelectChange('formDataDetail')('in')}
                                        value={formDataDetail.in}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} style={{paddingTop: 0}}>
                                <TextField
                                    fullWidth
                                    margin="none"
                                    name="qty"
                                    label='Qty *'
                                    value={formDataDetail.qty}
                                    onChange={this.handleChange('formDataDetail')}
                                    type="number"
                                    inputProps={{min: 0}}
                                />
                            </Grid>
                            <Grid item xs={3} style={{paddingTop: 0}}>
                                <FormControl fullWidth margin="none">
                                    <AutoCompleteSelect 
                                        options={lists.reasons} 
                                        label='Reason *'
                                        onChange={this.handleSelectChange('formDataDetail')('reason')}
                                        value={formDataDetail.reason}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1} style={{paddingTop: 0, paddingRight: 0}}>
                                <Button fullWidth variant='contained' color='primary' 
                                        onClick={this.addPart} disabled={!formDataDetail.part || formDataDetail.qty <= 0 || !formDataDetail.reason}
                                >Add</Button>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {_.filter(details, i=>i.in).length > 0 &&
                    <Grid item xs={12} style={{paddingTop:'1.5em'}}>
                        <PaddedPaper>
                            <Typography variant="h4"><AllIcon size='large' icon={icons.plus} style={{color:colors.green}} /> Stock In</Typography>
                            <br/>
                            <DataTable
                                config={{
                                    key: 'part',
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                }}
                                columns={this.getTableColumns()}
                                rows={_.filter(details,i=>i.in)}
                            />
                        </PaddedPaper>
                    </Grid>
                }
                {_.filter(details, i=>!i.in).length > 0 &&
                    <Grid item xs={12} style={{paddingTop:'1.5em'}}>
                        <PaddedPaper>
                            <Typography variant="h4"><AllIcon size='large' icon={icons.minus} style={{color:colors.red}} /> Stock Out</Typography>
                            <br/>
                            <DataTable
                                config={{
                                    key: 'part',
                                    alternatingRowColours: true,
                                    responsiveimportant: true,
                                }}
                                columns={this.getTableColumns()}
                                rows={_.filter(details,i=>!i.in)}
                            />
                        </PaddedPaper>
                    </Grid>
                }
                {details.length > 0 &&
                    <Grid item xs={12} style={{paddingTop:'1.5em'}}>
                        <div className='buttonRow' style={{padding: '1em', paddingTop:0}}>
                            <Button variant='outlined' onClick={()=>{this.props.history.push(`/stock/movement/templates`);} }>Back</Button>
                            <Button variant='contained' color='primary' onClick={this.createTemplate}>{this.state.id ? 'Update Template' : 'Create Template'}</Button>
                        </div>
                    </Grid>
                }

                <Dialog 
                    open={showEditDialog} 
                    maxWidth="xs" 
                    scroll="body"
                >
                    <DialogTitle>
                        <Typography variant='h5'>Update Part</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container xs={12}>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={lists.parts} 
                                        label='Part *'
                                        onChange={this.handleSelectChange('formDataEditDetail')('part')}
                                        value={formDataEditDetail.part}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={[
                                            {value: 1, label: 'Stock In'},
                                            {value: 0, label: 'Stock Out'}
                                        ]} 
                                        label='Movement'
                                        onChange={this.handleSelectChange('formDataEditDetail')('in')}
                                        value={formDataEditDetail.in}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    name="qty"
                                    label='Qty'
                                    value={formDataEditDetail.qty}
                                    onChange={this.handleChange('formDataEditDetail')}
                                    type="number"
                                    inputProps={{min: 0}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <AutoCompleteSelect 
                                        options={lists.reasons} 
                                        label='Reason *'
                                        onChange={this.handleSelectChange('formDataEditDetail')('reason')}
                                        value={formDataEditDetail.reason}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <div className='buttonRow' style={{padding: '1em', paddingTop:0}}>
                            <Button variant='outlined'  
                                onClick={()=>{this.setState({formDataEditDetail: initialState.formDataEditDetail, showEditDialog: false})}}
                            >Close</Button>
                            <Button variant='contained' color='primary' 
                                onClick={this.updatePart}
                            >Update</Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                               => dispatch(closeDialog()),
    deployDialog:       (content, title, variant, size)  => dispatch(deployDialog(content, title, variant,size)),
    deployConfirmation: (message, title, success)        => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, msg)                   => dispatch(deploySnackBar(variant, msg)),
})

export default connect(null, mapDispatchToProps)(StockMovementTemplate);
