import React from 'react';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import _ from 'lodash';
import API from 'API';
import { Grid, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, ListItemText, ListItemSecondaryAction, ListItem, ListItemAvatar, List, Button } from '@material-ui/core';
import CheckBox from 'Components/Common/Inputs/CheckBox';
import AllIcon from 'Components/Common/Icons/AllIcon';
import IconHelper from 'Helpers/IconHelper';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';

const initialState = props => {
    let marketing = {};
    _.each(props.customerMarketing, i => {
        marketing[i] = true;
    });
    return {
        formData: {
            marketing: marketing,
            custId: props.custId
        },
        isLoading: true,
        lists: {
            categories: []
        }
    }
}

class CustomerMarketingDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
    }
    componentDidMount() {
        this.getMarketing();
    }
    getMarketing = () => {
        API.get('/marketing/customerMarketing')
        .then(res => {
            let m = {};
            _.each(res.data, i => {
                m[i.cm_id] = false;
            })
            this.setState({
                lists: {
                    categories: _.filter(_.filter(res.data, i => i.cm_name) , i => this.props.marketing.includes(i.cm_id))
                },
                formData: {
                    ...this.state.formData,
                    marketing: {...m, ...this.state.formData.marketing}
                },
                isLoading: false
            })
        })
    }
    checkSubCategory = (id) => () => {
        this.setState({
            formData: {
                ...this.state.formData,
                marketing: {
                    ...this.state.formData.marketing,
                    [id]: !this.state.formData.marketing[id]
                }
            }
        })
    }
    submit = () => {
        this.setState({isLoading: true}, ()=>{
            API.post(`/customers/${this.props.custId}/marketing`, {marketing: JSON.stringify(this.state.formData.marketing)})
            .then(this.props.onClose)
        })
    }
    render() {
        const { isLoading, lists, formData } = this.state;

        if (isLoading) return <LoadingCircle/>;
        return (
            <Grid container
                style={{padding: '20px'}}
            >
                <Grid item xs={12}>
                    {_.map(_.uniqBy(lists.categories, 'cm_category'), (category, i) => 
                        _.filter(lists.categories, i => i.cm_category === category.cm_category && i.cm_name).length > 0 &&
                            <ExpansionPanel key={i} defaultExpanded={true}>
                                <ExpansionPanelSummary dense expandIcon={<AllIcon icon={IconHelper.arrowDown} white noMargin />}>
                                    <Typography variant='h6'>{category.cm_category}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <List fullWidth style={{width: '100%'}}>
                                        {
                                            _.map(_.filter(_.filter(lists.categories, {cm_category: category.cm_category}), i => i.cm_name), (item, j) =>
                                                <ListItem key={`${i}_${j}`} dense fullWidth>
                                                    <ListItemAvatar>
                                                        <CheckBox 
                                                            color='primary'
                                                            checked={formData.marketing[item.cm_id]}
                                                            onClick={this.checkSubCategory(item.cm_id)}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={item.cm_name} />
                                                    <ListItemSecondaryAction>
                                                        <Typography variant='body2'>{item.mailing_lists.length} Mailing Lists</Typography>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                    )}
                </Grid>
                <Grid item xs={12} className='buttonRow'>
                    <Button
                        variant='outlined'
                        onClick={this.props.onClose}
                    >Close</Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={()=>this.props.deployConfirmation(
                            'Are you sure you want to update this customers marketing?',
                            'Update Marketing',
                            this.submit
                        )}
                    >
                        Update Marketing
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    deployConfirmation: (content, title, callback) => dispatch(deployConfirmation(content, title, callback))
})

export default connect(null, mapDispatchToProps) (CustomerMarketingDialog);
