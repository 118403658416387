import { TableFooter } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../Common/Paper/PaddedPaper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { Component } from 'react';

const styles = theme => ({
    tblHeader: {
        backgroundColor: theme.palette.primary.main,
    },
    tblHeaderCell: {
        color:'white',
    },
    tblFooter: {
        backgroundColor: '#f2f2f2',
    }
});
class PurchaseOrder extends Component {
    render() {
        const { classes, po } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <Grid container item spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Company Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {po.po_supplier_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {po.po_supplier_address && po.po_supplier_address.split('\n').map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            FAO
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {po.po_supplier_contact}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Grid container item spacing={3}>
                                    <Grid item xs={5}>
                                        <Typography variant="body1">
                                            Purchase Order Number 
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant="body1">
                                            {po.full_reference}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant="body1">
                                            Purchase Order Date
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant="body1">
                                            {moment(po.po_date).format("DD/MM/YYYY")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant="body1">
                                            Order Date 
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant="body1">
                                            {moment(po.po_order_date).format("DD/MM/YYYY")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant="body1">
                                            Order Placed By
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant="body1">
                                            {po.po_placed_by}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant="body1">
                                            Payment Terms
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography variant="body1">
                                            {po.po_supplier_payment_terms}
                                        </Typography>
                                    </Grid>
                                    {po.po_type_id === 1 &&
                                        <React.Fragment>
                                            <Grid item xs={5}>
                                                <Typography variant="body1">
                                                    Attendance Date
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography variant="body1">
                                                    {moment(po.po_attendance_date).format("DD/MM/YYYY")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography variant="body1">
                                                    Job Reference 
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography variant="body1">
                                                    {po.po_job_reference}
                                                </Typography>
                                            </Grid>
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <br></br>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.tblHeader}>
                                        <TableCell className={classes.tblHeaderCell}>Description</TableCell>
                                        <TableCell className={classes.tblHeaderCell}>Nominal Code</TableCell>
                                        <TableCell align='right' className={classes.tblHeaderCell}>Quantity</TableCell>
                                        <TableCell align='right' className={classes.tblHeaderCell}>Unit Price</TableCell>
                                        <TableCell align='right' className={classes.tblHeaderCell}>Total Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {po.order_details && po.order_details.map(row => (
                                    <TableRow key={row.po_detail_id}>
                                        <TableCell>{row.po_detail_description}</TableCell>
                                        <TableCell>{row.nominal_code && row.nominal_code.nominal_code + ' - ' + row.po_detail_nominal_name + ' ('+row.nominal_code.category.nom_cat_name+')'}</TableCell>
                                        <TableCell align='right'>{parseFloat(row.po_detail_qty).toFixed(2)}</TableCell>
                                        <TableCell align='right'>£{parseFloat(row.po_detail_unit_price).toFixed(2)}</TableCell>
                                        <TableCell align='right'>£{parseFloat(row.po_detail_total_price).toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow className={classes.tblFooter}>
                                        <TableCell colSpan={4} style={{textAlign:'right'}}>Total Net Value Excluding VAT (GBP)</TableCell>
                                        <TableCell align='right'>£{parseFloat(po.po_total_price).toFixed(2)}</TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                            <br></br> 
                        </Grid>
                        <Grid container item spacing={3}>
                            <Grid item xs={6}>
                                <Grid container item spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Company Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {po.po_invoice_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {po.po_invoice_address && po.po_invoice_address.split('\n').map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            FAO
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {po.po_invoice_fao}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container item spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Company Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {po.po_delivery_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {po.po_delivery_address && po.po_delivery_address.split('\n').map((item, key) => {
                                                return <span key={key}>{item}<br/></span>
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" gutterBottom>
                                            FAO
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            {po.po_delivery_fao}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                    <br></br>
                </Grid>
            </Grid>
        );
    }
}

export default (withStyles(styles)(PurchaseOrder));