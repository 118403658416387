import API from 'API';
import _, { assign, find, map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';

import DatePicker from 'Components/Common/DatePickers/DatePicker';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar } from 'Actions/SnackBar/SnackBar';

import DeliveryNotePreview from './DeliveryNotePreview';

import EmailDialog from 'Components/Common/Dialogs/EmailDialog';

import StaffActions from 'Components/Sales/Order/ViewOrder/sections/StaffActions';

import { Button, FormControl, Grid, Link, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const ProcessDespatchDialog = ({despatchId, callback, classes}) => {

    const initialState = {
        formErrors:{},
        formData:{
            despatchedDate: moment().format('YYYY-MM-DD'),
            deliveryDate: null,
            courier: '',
            trackingNumbers: [],
            courierDespatchNote: '',
            despatchNotes: '',
            courierNotes: '',
            noOfTrackingUrls: 0, 
            consignments: [],
        },
        emailDialog: {
            customerId: '',
            customerContactId: '',
            type: ''
        },
        trackingLink: '',
        couriers: {},
        couriersList: [],
        isLoading: true,
        orderId: '',
        showTrackingNumber: false,
        cd: {}
    };

    let [state, setState] = useState(initialState);

    const {formErrors, formData, emailDialog, showTrackingNumber, orderLocked, emailDialog:{customerId, customerContactId, type}, formData:{courier, trackingNumbers, courierDespatchNote, despatchNotes, courierNotes, deliveryDate, despatchedDate, noOfTrackingUrls}, trackingLink, couriers, couriersList, isLoading, orderId, cd} = state;

    const dispatch = useDispatch();

    const handleProcessClose = () => dispatch(closeDialog());

    const handleProcess = () => dispatch(deployConfirmation('Are you sure you want to process this despatch?', 'Process Despatch?', handleProcessSuccess, handleProcessClose));

    const handleProcessSuccess = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));
        let newFormData = new FormData();
        Object.keys(formData).forEach(key => {
            if(key === 'courierDespatchNote') {
                newFormData.append('courierDespatchNote', formData[key]);
            } else if(key === 'trackingNumbers'){
                newFormData.append(key, JSON.stringify(formData[key]))
            }
            else {
                newFormData.append(key, formData[key]);
            }
        });
        API.post(`/sales/orders/despatch/${despatchId}/process`, newFormData)
        .then(result => {
            if(result.data.errors) {
                const formErrors = formatValidationErrors(result.data.errors)
                if (formErrors['status']){
                    dispatch(deploySnackBar('error', formErrors['status']));
                    if(callback) callback();
                    handleProcessClose();
                } else {
                    setState(state => ({
                        ...state,
                        formErrors,
                        isLoading: false
                    }));
                }
            } else if(result.data.success) {
                setState(state => ({
                    ...state,
                    emailDialog: {
                        customerId: result.data.customerId,
                        customerContactId: result.data.contactId
                    }
                }));
            }
        });
    }

    const handleChange = e => {
        const {name, value} = e.target;
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: value
            }
        }));
    }

    const handleDateChange = name => date => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: moment(date).format("YYYY-MM-DD")
            }
        }));
    };

    const handleSelectChange = fieldName => selectedOption => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [fieldName]: selectedOption && selectedOption.value
            }
        }));
    }

    const handleTrackingChange = (idx, e) => {
        let trackingNumbers = formData.trackingNumbers;
        trackingNumbers[idx] = {
            ...trackingNumbers[idx],
            number: e.target.value
        };
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                trackingNumbers
            }
        }));
    }

    const handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                [name]: file
            }
        }));
    }

    const clearFile = () => {
        setState(state => ({
            ...state,
            formData: {
                ...formData,
                courierDespatchNote: ''
            }
        }));
    };

    useEffect(() => {
        let trackingNumbers = [];
        for(let i = 0; i < noOfTrackingUrls; i++) {
            trackingNumbers.push({number: '', url: ''})
        }

        setState(state => ({
            ...state,
            formData: {
                ...formData,
                trackingNumbers
            }
        }));
    }, [noOfTrackingUrls]);

    // handle email dialog
    useEffect(() => {
        if(customerId && customerContactId) {
            dispatch(deploySnackBar('success', 'You have successfully despatched this delivery'));
    
            const dialog = <EmailDialog 
                id={despatchId}
                customerId={customerId}
                customerContactId={customerContactId}
                type='despatched'
            />
            
            dispatch(deployDialog(dialog, 'You Are About To Email - Order Despatched', 'success'));
    
            if(callback) callback();
        }
    }, [emailDialog]);


    useEffect(() => {
        let details = find(couriers, { 'cour_id': courier });

        let trackingNumbers = formData.trackingNumbers;

        _.each(trackingNumbers, (el, sIdx) => {
            el.url = details?.cour_tracking_url ? details.cour_tracking_url + el.number : ''
        });

        setState(state => ({
            ...state,
            formData: {
                ...formData,
                trackingNumbers
            }
        }));
    }, [courier,JSON.stringify(trackingNumbers)]);

    useEffect(() => {
        Promise.all([
            API.get(`/sales/orders/despatch/${despatchId}`),
            API.get('/sales/couriers/all')
        ])
        .then(([despRes, courRes]) => {

            let couriersList = map(courRes.data.couriers, el => {
                return assign({
                    value: el.cour_id,
                    label: el.cour_name
                });
            });
            setState(state => ({
                ...state,
                formData: {
                    ...state.formData,
                    courier: despRes?.data?.desp_cour_id,
                    courierNotes: despRes?.data?.desp_courier_notes,
                    deliveryDate: despRes?.data?.desp_delivery_date !== null ? moment(despRes.data.desp_delivery_date).format('YYYY-MM-DD') : null,
                },
                couriers: courRes.data.couriers,
                couriersList,
                isLoading: false,
                orderId: despRes?.data?.desp_order_id,
                cd: despRes?.data,
            }));
        });
    }, [despatchId]);

    const lockOrder = orderLocked => {
        setState(state => ({
            ...state,
            orderLocked
        }));
    };

    return (
        <React.Fragment>
            {isLoading ? (
                <LoadingCircle />
            ) : (
                <React.Fragment>
                    {formErrors && formErrors['stockReturnDetails'] && formErrors['stockReturnDetails'].split('\n').map((item, key) => {
                        return (
                            <React.Fragment key={key}>
                                <Typography component={"div"} style={{color: colors.red}}>
                                    {item}<br/>
                                </Typography>
                            </React.Fragment>
                        )
                    })}
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <StaffActions orderId={orderId} lockCallback={lockOrder} type={'Despatching'}/>
                            <DeliveryNotePreview order={cd.desp_order_id} despatch={cd.desp_id}/>
                        </Grid>
                        {orderLocked ? <></> : <>
                            <Grid item xs={6} style={{borderRight: '1px solid #ddd'}}>
                                <Typography variant="h6" gutterBottom>Despatch Details</Typography>
                                <DatePicker 
                                    type="date"
                                    id="despatchedDate"
                                    name="despatchedDate"
                                    label="Despatch Date"
                                    value={despatchedDate}
                                    onChange={handleDateChange('despatchedDate')}
                                />
                                <DatePicker 
                                    type="date"
                                    id="deliveryDate"
                                    name="deliveryDate"
                                    label="Delivery Date *"
                                    value={deliveryDate}
                                    onChange={handleDateChange('deliveryDate')}
                                    errorText={formErrors?.['deliveryDate']}
                                    autoOk
                                />
                                <Textarea
                                    id="despatchNotes"
                                    name="despatchNotes"
                                    label="Despatch Notes"
                                    value={despatchNotes}
                                    rows={5}
                                    error={formErrors && formErrors['despatchNotes']}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom>Courier Details</Typography>
                                <FormControl error={formErrors && formErrors['courier'] && true} fullWidth margin="normal">
                                    <AutoCompleteSelect
                                        options={couriersList} 
                                        label='Courier *'
                                        value={courier}
                                        onChange={handleSelectChange('courier')}
                                        error={formErrors && formErrors['courier'] && true}
                                        errorText={formErrors && formErrors['courier'] && formErrors['courier']}
                                    />
                                </FormControl>
                                <FormControl error={formErrors && formErrors['courier'] && true} fullWidth margin="normal">
                                    <AutoCompleteSelect
                                        options={[
                                            {value: 0, label: '0'},
                                            {value: 1, label: '1'},
                                            {value: 2, label: '2'},
                                            {value: 3, label: '3'},
                                            {value: 4, label: '4'},
                                            {value: 5, label: '5'}
                                        ]} 
                                        label="No. of Tracking URL's"
                                        value={noOfTrackingUrls}
                                        onChange={handleSelectChange('noOfTrackingUrls')}
                                    />
                                </FormControl>
                                {trackingNumbers.map((el, idx) => {
                                    return (
                                        <span key={idx}>
                                            <TextField
                                                id={`trackingNumbers${idx}`}
                                                name={`trackingNumbers${idx}`}
                                                label="Tracking Number"
                                                value={el.number}
                                                error={formErrors?.['trackingNumbers|'+idx] ? true : false}
                                                helperText={formErrors?.['trackingNumbers|'+idx]}
                                                onChange={(e) => {handleTrackingChange(idx, e) }}
                                                margin="normal"
                                                fullWidth
                                            />
                                            {el.number && el.url &&
                                                <Link href={el.url} variant="body2" className='blueLink' target='_blank' rel="noopener">
                                                    {el.url}
                                                </Link>
                                            }
                                        </span>
                                    )
                                })}
                            <DragFileInput
                                id="courierDespatchNote"
                                name="courierDespatchNote"
                                label="Courier Despatch Note"
                                file={courierDespatchNote}
                                errorText={formErrors && formErrors['courierDespatchNote']}
                                onChange={handleFileChange}
                                cancelOnClick={clearFile}
                                emptyText='No file selected'
                            />
                            <Textarea
                                id="courierNotes"
                                name="courierNotes"
                                label="Courier Notes"
                                value={courierNotes}
                                rows={5}
                                error={formErrors && formErrors['courierNotes']}
                                onChange={handleChange}
                            />
                        </Grid>
                        </>}
                    </Grid>
                    <div className="buttonRow">
                        <Button onClick={handleProcessClose} variant="outlined" color="default">Close</Button>
                        {!orderLocked && <Button onClick={handleProcess} style={{backgroundColor:colors.green, color:'white'}} variant="contained">Process</Button>}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

const styles = theme => ({ hover: {'&:hover': {cursor: 'pointer'}}});
export default withStyles(styles)(ProcessDespatchDialog);