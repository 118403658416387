import React from 'react';

import AllCustomersMap from './Tabs/AllCustomersMap';
import { Typography, Grid } from '@material-ui/core';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

/*
    I plan to tab this out to multiple maps but for now it will be a single element
*/
export default function(){
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    variant='h5'
                >Customers Map</Typography>
            </Grid>
            <Grid item xs={12}>
                <PaddedPaper><AllCustomersMap /></PaddedPaper>
            </Grid>
        </Grid>
    );
};