import { Button, ButtonGroup, DialogActions, FormControlLabel, Grid, InputAdornment, List, ListItem, ListItemAvatar, ListItemSecondaryAction, 
    ListItemText, Switch, TextField, Typography, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import API from 'API';
import addressAccounts from 'Assets/Images/markers/addressAccounts.png';
import addressRegistered from 'Assets/Images/markers/addressRegistered.png';
import addressSite from 'Assets/Images/markers/addressSite.png';
import addressSiteService from 'Assets/Images/markers/addressSiteService.png';
import addressTrading from 'Assets/Images/markers/addressTrading.png';
import AllIcon from 'Components/Common/Icons/AllIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Map from 'Components/Common/Map/Map';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { Dial } from 'Functions/MiscFunctions';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import InputHelper from 'Helpers/InputHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import moment from 'moment';

const defaultFilter = {
    keyWords: '',
    servicePlan: 'all',
    status: 'active',
    addressType: [ 'Site' ]
}

const initialState = props => ({
    addresses: [],
    prevFilter: null,
    filter: _.cloneDeep(defaultFilter),
    lists: {
        addressType: ['Site', 'Registered', 'Trading', 'Accounts' ],
        servicePlan: [ { value: 'all', label: 'All' }, { value: 'active', label: 'Active' }, { value: 'inactive', label: 'Inactive' } ],
        status: [{ value: 'active', label: 'Active' },{ value: 'hold', label: 'On Hold'},{ value: 'closed', label: 'Closed'},{ value: 'all', label: 'All'}]
    },
    isLoading: true,
    mapSettings: {
        uuid: null,
        mapCenter: null
    },
    pinned: [],
    showAdvancedFilter: false,
    pinnedOnly: false,
    access: {
        'add-order': false,
        'add-quotation': false,
        'add-customer-contact': false,
        'add-credit-note': false,
    },
    page: 0,
    showPinned: {
        show: false,
        name: '',
    },
    savedPin: null,
    savedPins: []
});

class AllCustomersMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(props);
        this.inputHelper = new InputHelper(this, null, this.SearchFilter);
        this.timeout = null;
    }

    componentDidMount() {
        this.getCustomers();
        this.inputHelper.handleSetAccess(_.keys(this.state.access))
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    getSavedPins = () => {
        API.get('/customers/map/pinned')
        .then(res => {
            this.setState({
                savedPins: _.map(res.data, i => ({value: i.cmsp_id, label: i.cmsp_name, pins: JSON.parse(i.cmsp_pins)}))
            })
        })
    }

    SearchFilter = () => {
        //stop page uneccesarily reloading
        if (this.state.prevFilter !== JSON.stringify(this.state.filter)) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {this.getCustomers();}, 750);
        }

        if (this.state.pinnedOnly && this.state.pinned.length === 0) {
            this.setState({
                pinnedOnly: false
            })
        }        
    }

    getCustomers = () => {
        API.get('/customers/map', {params: {
            keyWords: this.state.filter.keyWords,
            servicePlan: this.state.filter.servicePlan,
            status: this.state.filter.status,
            addressType: JSON.stringify(this.state.filter.addressType)
        }})
        .then(res => {
            this.setState({
                addresses: res?.data,
                mapSettings: {
                    ...this.state.mapSettings,
                    uuid: uuidv4(),
                },
                prevFilter: JSON.stringify(this.state.filter),
                isLoading: false,
                page: 0
            },this.getSavedPins);
        });
    }

    savePinned = () => {
        API.post('/customers/map/pinned', {
            name: this.state.showPinned.name,
            pinned: JSON.stringify(_.map(this.state.pinned, i => i.i)),
        })
        .then(res => {
            this.setState({
                showPinned: {
                    show: false,
                    name: '',
                },
                savedPin: res.data.cmsp_id,
                savedPins: [...this.state.savedPins, {value: res.data.cmsp_id, label: res.data.cmsp_name, pins: JSON.parse(res.data.cmsp_pins)}]
            }, this.getSavedPins)
        })
    }

    setPinned = (v) => {
        if (v) {
            API.get('/customers/map', {params: {
                ids: JSON.stringify(v.pins)
            }}).then(res => {
                this.setState({
                    savedPin: v.value,
                    pinned: res.data,
                    pinnedOnly: true,
                })
            })            
        } else {
            this.setState({
                savedPin: null,
                pinned: [],
            })
        }
    }

    deleteSavedPins = () => {
        API.post(`/customers/map/pinned/${this.state.savedPin}/delete`)
        .then(res => {
            this.setState({
                savedPin: null,
                pinned: [],
                pinnedOnly: false,
            }, this.getSavedPins)
        })
    }

    clearPinned = () => {
        this.setState({
            pinned: [],
            pinnedOnly: false,
            savedPin: null
        })
    }

    render() {

        const { addresses, isLoading, filter, mapSettings, pinned, showAdvancedFilter, lists, pinnedOnly, access, page, showPinned, savedPin, savedPins } = this.state;

        if (isLoading) return <LoadingCircle/>;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Map 
                        uuid={mapSettings.uuid}
                        showSwapMapType
                        showStreetView
                        controllable
                        style={{cursor: 'default !important'}}
                        markers={_.map(pinnedOnly ? pinned : addresses, i => ({
                            lat:    i.lat,
                            lng:    i.lng,
                            marker: <img src={( i.t === 'Accounts' && addressAccounts ) || (
                                        ( i.t === 'Registered' && addressRegistered ) || (
                                            ( i.t === 'Trading' && addressTrading ) || 
                                            (i.s ? addressSiteService : addressSite )
                                        )
                                        )} width={20} style={{marginTop: '-50%'}} 
                                    />,
                            content: 
                                <PaddedPaper>
                                    <Grid container spacing={1}>
                                        <Grid item xs={9}>
                                            <Typography variant='body1'
                                                style={{fontWeight: 'bold'}}
                                            >{i.n}</Typography>
                                            {!!i.s &&
                                                <Typography variant='caption'><AllIcon solid icon={IconHelper.service} size='xsmall'/>Service Plan Site</Typography>
                                            }
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Grid container>
                                                <Grid item>
                                                    <AllIcon
                                                        style={{padding: 3, margin: 2}}
                                                        noPadding
                                                        size='small'
                                                        icon={IconHelper.building}
                                                        noMargin
                                                        link={`/customers/addresses/view/${i.i}`}
                                                        tooltip={'View Site'}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <AllIcon
                                                        style={{padding: 3, margin: 2}}
                                                        noPadding
                                                        size='small'
                                                        icon={IconHelper.customers}
                                                        noMargin
                                                        link={`/customers/view/${i.c.i}`}
                                                        tooltip={'View Customer'}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <AllIcon
                                                        style={{padding: 3, margin: 2}}
                                                        noPadding
                                                        size='small'
                                                        icon={IconHelper.thumbTack}
                                                        heavy={_.find(pinned, {i: i.i})}
                                                        noMargin
                                                        onClick={()=>{
                                                            _.find(pinned, {i: i.i}) ?
                                                                this.inputHelper.handleRemoveArrayIndex('pinned', _.findIndex(pinned, {i: i.i}))() :
                                                                this.inputHelper.handleAddArray('pinned')(i);
                                                            //update marker
                                                            this.inputHelper.handleValueChange('mapSettings.uuid', uuidv4())
                                                        }}
                                                        tooltip={'Pin Address'}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='caption'>Address</Typography>
                                            <Typography variant='body2'>{i.a.split('\n').join(', ')}</Typography>
                                        </Grid>
                                        {i.p.e &&
                                            <Grid item xs={12}>
                                                <Typography variant='caption'>Email</Typography>
                                                <Typography variant='body2'>{i.p.e}</Typography>
                                            </Grid>
                                        }
                                        {i.p.p &&
                                            <Grid item xs={12}>
                                                <Typography variant='caption'>Phone</Typography>
                                                <Typography variant='body2'><Dial number={i.p.p || '-'} /></Typography>
                                            </Grid>
                                        }
                                        {i.p.n &&
                                            <Grid item xs={12}
                                                style={{paddingTop: 0}}
                                            >
                                                <Textarea
                                                    style={{marginTop: 0}}
                                                    fullWidth
                                                    disabled
                                                    value={i.p.n}
                                                    label='Notes'
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <ButtonGroup fullWidth variant="contained"  color='primary'>
                                                <Button
                                                style={{
                                                    padding: `5px 4px`,
                                                    fontSize: 12,
                                                    textAlign: 'center'
                                                }}
                                                component="a"   href={`/sales/quotation/add/customer/site/${i.i}`}  disabled={!access['add-quotation'] || i.c.a}>
                                                    Add <br/>Quotation
                                                </Button>
                                                <Button
                                                style={{
                                                    padding: `5px 4px`,
                                                    fontSize: 12,
                                                    textAlign: 'center'
                                                }}
                                                component="a"  href={`/sales/order/add/customer/site/${i.i}`} disabled={!access['add-order'] || i.c.a}>
                                                    Add <br/>Order
                                                </Button>
                                                <Button
                                                style={{
                                                    padding: `5px 4px`,
                                                    fontSize: 12,
                                                    textAlign: 'center'
                                                }}
                                                component="a" href={`/returns/creditnote/add/customer/site/${i.i}`}  disabled={!access['add-credit-note'] || i.c.a}>
                                                    Add <br/>Credit Note
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                        }))}
                        height={650}
                        center={mapSettings.mapCenter}
                        zoom={5}
                    />
                </Grid>
                <Grid item xs={12}
                    style={{
                        marginTop: '1em',
                    }}
                >
                    <PaddedPaper>
                        <Grid container spacing={2}>
                            {
                                ( pinned.length > 0 && pinnedOnly) ?
                                <>
                                    <Grid item>
                                        {(savedPin && _.isEqual(_.map(pinned, i => i.i), _.find(savedPins, {value: savedPin}).pins)) ?
                                            <Button 
                                                variant='outlined'
                                                color='secondary'
                                                onClick={()=>this.props.deployConfirmation(
                                                    'Are you sure you want to delete these saved pins?',
                                                    'Delete Saved Pins',
                                                    this.deleteSavedPins
                                                )}    
                                            >
                                                Delete Saved Pins
                                            </Button> : 
                                            <ButtonGroup variant='outlined'>
                                                <Button
                                                    color='secondary'
                                                    onClick={this.clearPinned}
                                                >
                                                    clear
                                                </Button>
                                                <Button
                                                    color='primary'
                                                    onClick={this.inputHelper.handleButtonChange('showPinned', {show: true, name: `${this.props.loggedInStaff.firstName} ${this.props.loggedInStaff.lastName} - ${moment().format('DD/MM/YYYY HH:mm')}`})}
                                                >
                                                    Save
                                                </Button>
                                            </ButtonGroup>
                                        }
                                    </Grid>
                                </> 
                                :
                                (savedPins.length > 0 &&
                                    <Grid item xs={3}
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        <AutoCompleteSelect
                                            options={savedPins}
                                            value={savedPin}
                                            placeholder='Saved Pins'
                                            showPlaceholder
                                            fullWidth
                                            isSearchable={false}
                                            onChange={this.setPinned}
                                        />    
                                        {savedPin &&
                                            <AllIcon
                                                size='small'
                                                tooltip='Remove Saved Pins'
                                                icon={IconHelper.close}
                                                noMargin
                                                onClick={()=>this.props.deployConfirmation(
                                                    'Are you sure you want to delete these saved pins?',
                                                    'Delete Saved Pins',
                                                    this.deleteSavedPins
                                                )}  
                                            />
                                        }
                                    </Grid>
                                )
                            }
                            {(pinned.length > 0) &&
                                <Grid item style={{marginLeft: 'auto'}}>
                                    <ButtonGroup>
                                        <Button
                                            variant={!pinnedOnly ? 'contained' : 'outlined'}
                                            color={!pinnedOnly ? 'primary' : 'default'}
                                            onClick={this.inputHelper.handleButtonChange('pinnedOnly', 0)}
                                        >
                                            <AllIcon icon={IconHelper.search} white={!pinnedOnly}/>
                                            Search
                                        </Button>
                                        <Button
                                            variant={pinnedOnly ? 'contained' : 'outlined'}
                                            color={pinnedOnly ? 'primary' : 'default'}
                                            onClick={this.inputHelper.handleButtonChange('pinnedOnly', 1)}
                                        >
                                            <AllIcon heavy icon={IconHelper.thumbTack} white={pinnedOnly}/>  
                                            Pinned
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            }
                            {!pinnedOnly &&
                                <Grid item xs={12}
                                    style={showAdvancedFilter ? {  
                                        border: `1px solid ${colors.disabled}50`,
                                        borderRadius: 5,
                                        padding: '10px 14px',
                                        margin: '1em',
                                    } : {}}
                                >
                                    <TextField
                                        placeholder='Keyword Search...'
                                        fullWidth
                                        name='filter.keyWords'
                                        value={filter.keyWords}
                                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value), this.SearchFilter)}
                                        variant={showAdvancedFilter ? 'standard' : 'outlined'}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AllIcon 
                                                        icon={IconHelper[filter.keyWords ? 'arrowLeft' : 'search']} 
                                                        noMargin 
                                                        noPadding 
                                                        onClick={this.inputHelper.handleButtonChange('filter.keyWords', '')} 
                                                    />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <AllIcon 
                                                        icon={IconHelper.filter} 
                                                        heavy={showAdvancedFilter}
                                                        noMargin 
                                                        noPadding 
                                                        onClick={this.inputHelper.handleCheck('showAdvancedFilter')}
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {showAdvancedFilter &&
                                        <>
                                            <Grid container spacing={4}
                                                style={{
                                                    padding: '1em',
                                                    paddingTop: '2em',
                                                }}
                                            >
                                                <Grid item  xs={5}>
                                                    <Typography variant='caption'
                                                        style={{paddingBottom: 10}}
                                                    >Address Types</Typography>
                                                    <Grid container spacing={1}>
                                                        {_.map(lists.addressType, i => 
                                                            <Grid item>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={filter.addressType.includes(i)}
                                                                            onChange={()=>this.inputHelper.handleArrayAddRemove('filter.addressType')(i)}
                                                                            name="filter.addressType"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label={i}
                                                                />
                                                            </Grid>
                                                        )}                                                        
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography variant='caption'>Service Plan Status</Typography>
                                                    <AutoCompleteSelect
                                                        name='filter.servicePlan'
                                                        value={filter.servicePlan}
                                                        onChange={this.inputHelper.handleSelectChange('filter.servicePlan')}
                                                        options={lists.servicePlan}
                                                        noClear
                                                        disableSort
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography variant='caption'>Customer Status</Typography>
                                                    <AutoCompleteSelect
                                                        name='filter.status'
                                                        value={filter.status}
                                                        onChange={this.inputHelper.handleSelectChange('filter.status')}
                                                        options={lists.status}
                                                        noClear
                                                        disableSort
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className='buttonRow'
                                                    style={{marginTop: 0}}
                                                >
                                                    <Button
                                                        onClick={this.inputHelper.handleButtonChange('filter', _.cloneDeep(defaultFilter))}
                                                        variant='outlined'
                                                    >
                                                        Reset Filter
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <List>
                                    {(!pinnedOnly ? addresses : pinned).length > 25 &&
                                        <ListItem
                                            style={{borderBottom: `1px solid ${colors.disabled}50`}}
                                        >
                                            <Grid container spacing={2}
                                                style={{
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <Grid item>
                                                    <AllIcon
                                                        noMargin 
                                                        icon={IconHelper.previous}
                                                        size='small'
                                                        onClick={this.inputHelper.handleButtonChange('page', (page-1))}    
                                                        disabled={page === 0}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body1'>
                                                        {page === 0 ? 0 : (page * 25)}-{(page + 1) * 25} / {(!pinnedOnly ? addresses : pinned).length}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <AllIcon
                                                        noMargin 
                                                        icon={IconHelper.next}
                                                        size='small'
                                                        onClick={this.inputHelper.handleButtonChange('page', (page+1))}    
                                                        disabled={page === (Math.floor((!pinnedOnly ? addresses : pinned).length / 25))}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    }
                                    {_.map(_.slice((!pinnedOnly ? addresses : pinned), (page * 25), ((page + 1) * 25)), i =>
                                        <ListItem
                                            style={{borderBottom: `1px solid ${colors.disabled}50`}}
                                        >
                                            <ListItemAvatar>
                                                <img src={( i.t === 'Accounts' && addressAccounts ) || (
                                                    ( i.t === 'Registered' && addressRegistered ) || (
                                                        ( i.t === 'Trading' && addressTrading ) || 
                                                        (i.s ? addressSiteService : addressSite )
                                                    )
                                                    )} width={22} 
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={i.n}
                                                secondary={i.a.split('\n').join(', ')}
                                            />
                                            <ListItemSecondaryAction>
                                                <AllIcon
                                                    icon={IconHelper.locationPin}
                                                    noMargin
                                                    onClick={this.inputHelper.handleButtonChange('mapSettings.mapCenter', [ parseFloat(i.lat), parseFloat(i.lng) ])}
                                                    tooltip={'Jump To'}
                                                />
                                                <AllIcon
                                                    icon={IconHelper.building}
                                                    noMargin
                                                    link={`/customers/addresses/view/${i.i}`}
                                                    tooltip={'View Address'}
                                                />
                                                <AllIcon
                                                    icon={IconHelper.customers}
                                                    noMargin
                                                    link={`/customers/view/${i.c.i}`}
                                                    tooltip={'View Customer'}
                                                />
                                                <AllIcon
                                                    icon={IconHelper.thumbTack}
                                                    heavy={_.find(pinned, {i: i.i})}
                                                    noMargin
                                                    onClick={()=>{
                                                        _.find(pinned, {i: i.i}) ?
                                                            this.inputHelper.handleRemoveArrayIndex('pinned', _.findIndex(pinned, {i: i.i}))() :
                                                            this.inputHelper.handleAddArray('pinned')(i)
                                                    }}
                                                    tooltip={'Pin Address'}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                    {(!pinnedOnly ? addresses : pinned).length > 25 &&
                                        <ListItem>
                                            <Grid container spacing={2}
                                                style={{
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <Grid item>
                                                    <AllIcon
                                                        noMargin 
                                                        icon={IconHelper.previous}
                                                        size='small'
                                                        onClick={this.inputHelper.handleButtonChange('page', (page-1))}    
                                                        disabled={page === 0}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body1'>
                                                        {page === 0 ? 0 : (page * 25)}-{(page + 1) * 25} / {(!pinnedOnly ? addresses : pinned).length}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <AllIcon
                                                        noMargin 
                                                        icon={IconHelper.next}
                                                        size='small'
                                                        onClick={this.inputHelper.handleButtonChange('page', (page+1))}    
                                                        disabled={page === (Math.floor((!pinnedOnly ? addresses : pinned).length / 25))}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {showPinned.show &&
                    <Dialog
                        open={showPinned.show}
                        show={showPinned.show}
                        maxWidth='sm'
                        fullWidth
                    >
                        <DialogTitle>
                            Save Pinned Addresses
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth
                                label='Name'
                                name='showPinned.name'
                                value={showPinned.name}
                                onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant='outlined'
                                onClick={this.inputHelper.handleButtonChange('showPinned', false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={this.savePinned}
                                disabled={!showPinned.name}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </Grid>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedInStaff: state.staffAuth.staff,
    };
}

function mapDispatchToProps(dispatch){
    return {
        deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllCustomersMap);
