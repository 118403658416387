import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import AddReminderForm from 'Components/Reminders/AddReminder/AddReminderForm';

const AddReminder = () => (
    <Grid container spacing={3}>
        <Grid item xs={12}>
            <Typography variant="h5">
                Add Reminder
            </Typography>
        </Grid>
        <Grid container item spacing={3}>
            <Grid item xs={12} lg={8}>
                <PaddedPaper>
                    <AddReminderForm />
                </PaddedPaper>
            </Grid>
        </Grid>
    </Grid>
)

export default AddReminder;