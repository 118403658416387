import { Grid, Typography } from '@material-ui/core';
import TabBar from 'Components/Common/Navigation/TabBar';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import MonthlyTarget from './MonthlyTarget';

const startYear = 2024;
const endYear = (moment().isAfter(`${moment().year()}-10-01`) ?  (moment().year() + 1) : moment().year()) + 1;
const currentYear = (moment().isAfter(`${moment().year()}-10-01`) ?  (moment().year() + 1) : moment().year())

const initialState = () => ({
    year: currentYear,
    isLoading: true,
})

class MonthlyTargets extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState();
    }

    
    render() {

        const years = _.reverse(_.range(startYear, (endYear + 1)));

        return (
            <>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            Performance Budgets
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TabBar
                            tabs={_.map(years, i => ({label: `${i-1}/${i}`, value: i}))}
                            currentTab={this.state.year} 
                            handleTabChange={(event,year) => {
                                this.setState({year: year});
                            }}
                        />
                        <PaddedPaper style={{padding: 0}}>
                            <MonthlyTarget 
                                year={this.state.year} 
                                current={currentYear === this.state.year}    
                                future={currentYear < this.state.year}    
                            />
                        </PaddedPaper>
                    </Grid> 
                </Grid>
            </>
        );
    }
}

export default MonthlyTargets;

