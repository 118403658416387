//ProccessRecurringInvoices
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import API from 'API';
import _ from 'lodash';

import { closeDialog } from 'Actions/Dialog/Dialog';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import DatePicker from 'Components/Common/DatePickers/DatePicker';
import DragFileInput from 'Components/Common/Inputs/DragFileInput';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import DataTable from 'Components/Common/DataTables/DataTable';
import AllIcon from 'Components/Common/Icons/AllIcon';

import {colors} from 'Helpers/ColourHelper';

import {Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';

const initialState = () => ({
    formData: {
        date:   null,
        file:   null,
        email:  1,
    },
    formErrors:     {},
    list:           [],
    confirmOpen:    false,
    isLoading:      false,
    showError:      false,
    checking:       false,
    processing:     false,
    showChecked:    false,
});

class ProccessRecurringInvoices extends Component {
    constructor(props) {
        super(props);   
        this.state = {...initialState()};
    }

    componentDidMount = () => {
        this.setState({
            list: this.props.invoiceList
        });
    }

    handleDateChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                date: e
            }
        });
    }

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                file
            }
        });
    }
    clearFile = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                file: null
            }
        });
    }

    deployConfirmation = () => {
        this.setState({
            confirmOpen: !this.state.confirmOpen,
        });
    }

    setLoading =    () => { this.setState({isLoading: !this.state.isLoading}); }
    setProcessing = () => { this.setState({processing: !this.state.processing}); }

    submit = () => {
        this.setLoading();
        this.deployConfirmation();
        this.setProcessing();
        const formData = this.state.formData;
        let newFormData = new FormData();
        Object.keys(formData).forEach(key => {
            if (key === 'date') {
                newFormData.append(key, moment(formData[key]).format('YYYY-MM-DD') );
            } else {
                newFormData.append(key, formData[key]);
            }
        });
        API.post('/sales/orders/invoice/recurring/process', newFormData)
        .then(result => {
            if (result.data.errors){
                if (!_.find(result.data.errors, {err: "random_error"})){
                    this.setProcessing();
                    let csv     = [];
                    let nocsv   = [];
                    if (_.find(result.data.errors, {err: "csv_missing"})?.msg){
                        let allcsv  = _.find(result.data.errors, {err: "csv_missing"})?.msg
                        csv         = _.filter(allcsv, i => { return !i.ref } );
                        nocsv       = _.filter(allcsv, i => { return i.ref } );
                    }
                    let inv = _.find(result.data.errors, {err: "inv_missing"})?.msg;
                    let extra = _.find(result.data.errors, {err: "csv_extra"})?.msg;
                    if (extra){extra = _.values(extra)}
                    this.setState({
                        formErrors:{csv,inv,nocsv,extra},
                        showError: true
                    });
                }
            } else {
                if (this.props.callBack) this.props.callBack();
                this.props.closeDialog();
            }       
            this.setLoading();
        });
    }

    handleCheckChange = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                email: this.state.formData.email ? 0 : 1
            }
        });
    }

    handleCheck = () => {
        this.show('checking');
        const formData = this.state.formData;
        let newFormData = new FormData();
        Object.keys(formData).forEach(key => {
            if (key === 'date') {
                newFormData.append(key, moment(formData[key]).format('YYYY-MM-DD') );
            } else {
                newFormData.append(key, formData[key]);
            }
        });
        API.post('/sales/orders/invoice/recurring/check', newFormData)
        .then(result => {
            if (result.data.errors){
                if (!_.find(result.data.errors, {err: "random_error"})){
                    this.setProcessing();
                    let csv     = [];
                    let nocsv   = [];
                    if (_.find(result.data.errors, {err: "csv_missing"})?.msg){
                        let allcsv  = _.find(result.data.errors, {err: "csv_missing"})?.msg
                        csv         = _.filter(allcsv, i => { return !i.ref } );
                        nocsv       = _.filter(allcsv, i => { return i.ref } );
                    }
                    let inv = _.find(result.data.errors, {err: "inv_missing"})?.msg;
                    let extra = _.find(result.data.errors, {err: "csv_extra"})?.msg;
                    if (extra){extra = _.values(extra)}
                    this.setState({
                        formErrors:{csv,inv,nocsv, extra},
                        showError: true
                    }, () => {this.show('showError');});
                }
            } else {
                this.show('showChecked');
            }      
        });
    }

    show = e => {
        this.setState({
            showError:      false,
            checking:       false,
            processing:     false,
            showChecked:    false,
            [e]:            true
        })
    }

    render() {

        const {formData, formErrors, list, confirmOpen, isLoading, showError, processing, checking, showChecked } = this.state;

        return (
            <>
                {isLoading ? <LoadingCircle /> : 
                (
                    (checking && 
                        <>
                            <Typography variant='body2'>Checking CSV...</Typography>
                            <LoadingCircle />
                        </>
                    ) || 
                    (processing && 
                        <>
                            <Typography variant='body2'>Estimated: {(list.length * 0.2) > 60 ? `${parseFloat((list.length * 0.2) / 60).toFixed(2)} minutes` : `${parseFloat(list.length * 0.2).toFixed(2)} seconds`}<br /></Typography>
                            <LoadingCircle />
                        </>  
                    ) || 
                    (showChecked && 
                        <>
                            <Typography variant='body2'>CSV was checked with no errors, Do you want to process these items?</Typography>
                            <br></br>
                            <Grid  container xs={12}>
                                <Grid item xs={6}><b>Invoices</b></Grid>
                                <Grid item xs={6}>{list.length}</Grid>
                                <Grid item xs={6}><b>Date</b></Grid>
                                <Grid item xs={6}>{moment(formData.date).format('DD/MM/YYYY')}</Grid>
                                <Grid item xs={6}><b>Email?</b></Grid>
                                <Grid item xs={6}>{formData.email ? <AllIcon icon='check'/> : <AllIcon icon='times'/>}</Grid>
                            </Grid>
                            <br></br>
                            <div className='buttonRow'>
                                <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                                <Button variant='contained' color='primary' 
                                    onClick={this.submit} 
                                >Process</Button>
                            </div>
                        </>  
                    ) || 
                    (showError &&
                        <div>
                            <Typography variant='body2' >
                                There was an issue with this process, fix all the errors listed below and retry.
                            </Typography>
                            <br></br>
                            {formErrors?.inv && 
                                <>
                                    <Typography variant='body1'>
                                        Invoice's Not Present
                                    </Typography>
                                    <Typography variant='body2'>
                                        The following invoices are not listed in the CSV but are present in the system.
                                    </Typography>
                                    <br></br>
                                    <DataTable
                                        config={{
                                            key: 'i',
                                            responsiveImportance: true,
                                            alternatingRowColours: true,
                                            isLoading: isLoading
                                        }}
                                        columns={[
                                            {
                                                heading: 'Invoice Ref',
                                                field: rowData => rowData.ref,
                                                alignment: 'left'
                                            },
                                            {
                                                heading: 'Customer',
                                                field: rowData => rowData.cus,
                                                alignment: 'left'
                                            },
                                            {
                                                heading: 'Total',
                                                field: rowData => < div>£{rowData.tot}</div>,
                                                alignment: 'left'
                                            },
                                        ]}
                                        rows={formErrors?.inv}
                                    />
                                    <br></br>
                                </>
                            }
                            {!!formErrors?.extra &&
                                <>
                                    <Typography variant='body1'>
                                        CSV Extra Invoices
                                    </Typography>
                                    <Typography variant='body2'>
                                        The following are extra invoices included in the CSV but not present in the system.
                                    </Typography>
                                    <br></br>
                                    <DataTable
                                        config={{
                                            key: 'sage_invoice_number',
                                            responsiveImportance: true,
                                            alternatingRowColours: true,
                                            isLoading: isLoading
                                        }}
                                        columns={[
                                            {
                                                heading: 'Customer',
                                                field: i => i.account_ref ?? 'Missing',
                                                alignment: 'left'
                                            },
                                            {
                                                heading: 'Account Address',
                                                field: i => i.acc || 'Missing',
                                                alignment: 'left'
                                            },
                                            {
                                                heading: 'Total',
                                                field: i => i.gross_amount || 0,
                                                alignment: 'center',
                                                fieldFormat: 'currency'
                                            },
                                            {
                                                heading: 'Sage No.',
                                                field: i => i.sage_invoice_number || 'Missing',
                                                alignment: 'center'
                                            },
                                        ]}
                                        rows={formErrors?.extra}
                                    />
                                    <br></br>
                                </>
                            }
                            {!!formErrors?.csv?.length && 
                                <>
                                    <Typography variant='body1'>
                                        CSV Incorrect Data
                                    </Typography>
                                    <Typography variant='body2'>
                                        The following have a difference between the CSV and the system.
                                    </Typography>
                                    <br></br>
                                    <DataTable
                                        config={{
                                            key: 'sage_invoice_number',
                                            responsiveImportance: true,
                                            alternatingRowColours: true,
                                            isLoading: isLoading
                                        }}
                                        columns={[
                                            {
                                                heading: 'Customer',
                                                field: rowData => <div style={{color: rowData.ref ? colors.red: 'inherit'}}>{rowData.account_ref}</div>,
                                                alignment: 'left'
                                            },
                                            {
                                                heading: 'Account Address',
                                                field: rowData => 
                                                    <div style={{color: rowData.ref ? colors.red: 'inherit'}}>
                                                        {rowData.acc ?  <>
                                                                            <div style={{color:colors.red}}><AllIcon size='small' noMargin icon='file-csv' style={{color:colors.red, marginRight:4}}/>{rowData.account_address_name}</div>
                                                                            <AllIcon size='small' icon='clenAware' noMargin/> {rowData.acc}
                                                                        </> : 
                                                                        <>{rowData.account_address_name}</>}
                                                    </div>,
                                                alignment: 'left'
                                            },
                                            {
                                                heading: 'Total',
                                                field: rowData => 
                                                    <div style={{color: rowData.ref ? colors.red: 'inherit'}}>
                                                        {rowData.tot ?
                                                            <>
                                                                <div style={{color:colors.red}}><AllIcon size='small' noMargin icon='file-csv' style={{color:colors.red, marginRight:4}}/>£{parseFloat(rowData.gross_amount).toFixed(2)}</div>
                                                                <AllIcon size='small' icon='clenAware' noMargin/> £{parseFloat(rowData.tot).toFixed(2)}
                                                            </> : <>
                                                                £{parseFloat(rowData.gross_amount).toFixed(2)}
                                                            </> }
                                                    </div>,
                                                alignment: 'center'
                                            },
                                            {
                                                heading: 'Sage No.',
                                                field: rowData => (!rowData.sage) ? 
                                                    <div style={{color:rowData.ref ? colors.red: 'inherit'}}>{rowData.sage_invoice_number}</div> :
                                                    <div style={{color:colors.red}}>Missing</div>,
                                                alignment: 'center'
                                            },
                                        ]}
                                        rows={formErrors?.csv}
                                    />
                                    <br></br>
                                </>
                            }
                            {!!formErrors?.nocsv?.length && 
                                <>
                                    <Typography variant='body1'>
                                        CSV Records Not In System
                                    </Typography>
                                    <Typography variant='body2'>
                                        The following invoices are listed in the CSV but not present in the system.
                                    </Typography>
                                    <br></br>
                                    <DataTable
                                        config={{
                                            key: 'sage_invoice_number',
                                            responsiveImportance: true,
                                            alternatingRowColours: true,
                                            isLoading: isLoading
                                        }}
                                        columns={[
                                            {
                                                heading: 'Customer',
                                                field: rowData => rowData.account_ref,
                                                alignment: 'left'
                                            },
                                            {
                                                heading: 'Account Address',
                                                field: rowData => 
                                                    <div>
                                                        {rowData.acc ?  <>
                                                                            <div ><AllIcon size='small' noMargin icon='file-csv' style={{color:colors.red, marginRight:4}}/>{rowData.account_address_name}</div>
                                                                            <AllIcon size='small' icon='clenAware' noMargin/> {rowData.acc}
                                                                        </> : 
                                                                        <>{rowData.account_address_name}</>}
                                                    </div>,
                                                alignment: 'left'
                                            },
                                            {
                                                heading: 'Total',
                                                field: rowData => 
                                                    <div>
                                                        {rowData.tot ?
                                                            <>
                                                                <div ><AllIcon size='small' noMargin icon='file-csv' style={{color:colors.red, marginRight:4}}/>£{parseFloat(rowData.gross_amount).toFixed(2)}</div>
                                                                <AllIcon size='small' icon='clenAware' noMargin/> £{parseFloat(rowData.tot).toFixed(2)}
                                                            </> : <>
                                                                £{parseFloat(rowData.gross_amount).toFixed(2)}
                                                            </> }
                                                    </div>,
                                                alignment: 'center'
                                            },
                                            {
                                                heading: 'Sage No.',
                                                field: rowData => rowData.sage_invoice_number,
                                                alignment: 'center'
                                            },
                                        ]}
                                        rows={formErrors?.nocsv}
                                    />
                                    <br></br>
                                </>
                            }
                             <div className='buttonRow'>
                                <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                            </div>
                        </div>
                    ) ||
                    (
                        <>
                            <DatePicker
                                type="date"
                                id="invoiceDate"
                                name="invoiceDate"
                                label="Invoice Date *"
                                value={formData.date}
                                errorText={formErrors && formErrors['invoiceDate']}
                                onChange={this.handleDateChange}
                                autoOk={true}
                            />
                            <DragFileInput
                                id="file"
                                name="file"
                                label="Upload Monthly Statement *"
                                file={formData.file}
                                errorText={formErrors && formErrors['file']}
                                onChange={this.handleFileChange}
                                cancelOnClick={this.clearFile}
                                emptyText='No file selected'
                            />
                            <Grid container xs={12} style={{justifyContent: 'flex-end'}}>
                                <Grid item>
                                    <FormGroup row >
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    id="recurringInvoice"
                                                    checked={formData.email} 
                                                    onChange={this.handleCheckChange} 
                                                    value={formData.email}
                                                    color="primary"
                                                />
                                            }
                                            label="Email invoices after process?"
                                            style={{marginRight:0}}
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <div className='buttonRow'>
                                <Button variant='outlined' onClick={this.props.closeDialog}>Close</Button>
                                <Button variant='contained' color='primary' 
                                    disabled={!(formData.file && formData.date)}
                                    onClick={this.handleCheck} 
                                >Check</Button>
                            </div>
                            <ConfirmationDialog 
                                open={confirmOpen} 
                                success={this.submit} 
                                close={this.deployConfirmation} 
                                title="Process invoice's" 
                                message={`Are you sure you want to process these ${list.length} invoice's?`}
                            />
                        </>
                    )
                )}
            </>
        );
    }
}
function mapDispatchToProps(dispatch) { return {closeDialog: () => {dispatch(closeDialog())}}}

export default connect(null, mapDispatchToProps)(ProccessRecurringInvoices);