import { Button, ButtonGroup, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import API from 'API';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import {
    ACCOUNTS_EMAIL,
    DEFAULT_EMAIL,
    SALES_EMAIL,
    WEB_SERVICE_EMAIL
} from 'Constants';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { clenyDate } from 'Helpers/TimeHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import ForwardEmailDialog from './Dialogs/ForwardEmailDialog';
import ViewAttachments from './Sections/ViewAttachments';
import ViewLinked from './Sections/ViewLinked';

const initialState = {
    isLoading: true,
    email: null,
    anchorEl: null,
    access: {
        'viewemail-read': false,
        'viewemail-resend': false,
        'viewemail-forward': false,
    }
}

class ViewEmail extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.getAccess();
        this.getEmail();
    }

    getAccess = () => {
        let access = this.state.access;
        Promise.all(_.map(_.keys(access), i => API.get(`/staff/my/access/check/${i}`))).then(res => {
            _.each(res, (i,idx) => {access[_.keys(access)[idx]] = i.data.has_access ?? false;}); 
            this.setState({access});
        })
    }

    componentDidUpdate(prevProps){
        if (prevProps.emailId !== this.props.emailId){
            this.setState(initialState, () => {
                this.getEmail();
            })
        }
    }

    getEmail = () => {
        API.get(`/email/${this.props.emailId}`)
        .then(r => {
            this.setState({
                isLoading: false,
                email: r.data,
            })
        })
    }

    resendEmail = () => {
        API.post(`/email/${this.props.emailId}/resend`)
        .then(r => {
            this.props.refresh &&
                this.props.refresh();
        })
    }

    forwardEmail = () => {
        this.props.deployDialog(
            <ForwardEmailDialog emailId={this.props.emailId} onClose={this.props.closeDialog} onSuccess={()=>{this.props.closeDialog(); this.props.refresh()}}/>,
            <Typography variant='h6' style={{color: colors.black}}>Forward Email</Typography>,
            'sm'
        )
    }

    markEmailAsRead = () => {
        API.post(`/email/${this.props.emailId}/markRead`).then(r => {
            this.props.refresh && this.props.refresh();
        })
    }

    handleLinkClick = event => {
        const target = event.target;
        if (target.tagName === 'A') {
            event.preventDefault();
            //stop links returning with https://192.168.0.205:2411/email/search/www.clenaware.co.uk
            let locationArr = window.location.href.split('/');
            locationArr.pop();
            let url = target.href.replace(locationArr.join('/')+'/', 'http://');
            window.open(url, '_blank');
        }
    }

    render(){

        const { isLoading, email, anchorEl, access } = this.state;

        if (isLoading){
            return <LoadingCircle />
        }

        return (
            <Grid container spacing={1}
                style={{
                    margin: '1em',
                    marginBottom: 0,
                    marginLeft: 0,
                    height: (this.props.height ?? 'calc(1200px - 2em)'),
                    overflowY: 'auto',
                    alignContent: 'flex-start'
                }}
            >
                <Grid item
                    style={{
                        paddingLeft: '1.5em',
                        borderBottom: `1px solid ${colors.primary}10`,
                        paddingTop: '0.9em',
                    }}
                >
                    <AllIcon 
                        icon={email.email_status === 'Complaint' ? IconHelper.emailComplaint : (email.email_status === 'Bounced' ? IconHelper.emailBounced : (email.email_datetime_opened !== '0000-00-00 00:00:00' ? IconHelper.emailOpen : IconHelper.email))} 
                        color={( email.email_status === 'Bounced' || email.email_status === 'Complaint' ) ? colors.red : (email.email_datetime_opened !== '0000-00-00 00:00:00' ? colors.green : colors.orange)} 
                        tooltip={
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <b>Sent:</b> {clenyDate(email.email_datetime_added, true) }
                                    {email.email_datetime_opened !== '0000-00-00 00:00:00' &&
                                        <><br/><b>Opened:</b> {clenyDate(email.email_datetime_opened, true) }</>
                                    }
                                    {email.email_datetime_complaint !== '0000-00-00 00:00:00' &&
                                        <><br/><b>Complaint:</b> {clenyDate(email.email_datetime_complaint, true) }</>
                                    }
                                    
                                </Grid>
                            </Grid>
                        }
                        size='large' 
                        noMargin 
                        noPadding
                        heavy
                    />
                </Grid>
                <Grid item xs={8}
                    style={{
                        borderBottom: `1px solid ${colors.primary}10`,
                        paddingBottom: '0.5em',
                        paddingLeft: '1.0em'
                    }}
                >
                    <Typography variant='body1'
                        style={{
                            fontWeight: 'bold',
                            fontSize: '1.3em',
                        }}
                    >
                        {email.email_subject}
                    </Typography>
                    <Typography variant='body2'
                        style={{
                            
                        }}
                    >
                        To: {email.contact.contact.contact_name} ({email.contact.contact.contact_email})<br/>
                        From: {email?.contact?.staff ? `${email.contact.staff.staff_first_name} ${email.contact.staff.staff_last_name}` : 'System'} ({
                            ( email.email_from === 'Default' && DEFAULT_EMAIL) ||
                            ( email.email_from === 'Sales' && SALES_EMAIL) ||
                            ( email.email_from === 'Accounts' && ACCOUNTS_EMAIL) ||
                            ( email.email_from === 'Service' && WEB_SERVICE_EMAIL)
                        })
                    </Typography>   
                    {email.cc.length > 0 && 
                        <Typography variant='caption'
                            style={{
                                
                            }}
                        >
                            CC: {_.map(email.cc, i => i.ecc_name).join(', ')}
                        </Typography>
                    }
                </Grid>
                <Grid item xs={3}
                    style={{
                        padding:0,
                        borderBottom: `1px solid ${colors.primary}10`,
                        textAlign: 'right',
                        paddingRight: '1.5em',
                        marginLeft: 'auto',
                        overflowY: 'auto'
                    }}
                >
                    <ButtonGroup>
                        {access['viewemail-resend'] &&
                            <Button
                                onClick={
                                    () => this.props.deployConfirmation(
                                        'Are you sure you want to resend this email?',
                                        <Typography variant='h6' style={{color: colors.black}}>Resend Email</Typography>,
                                        () => this.resendEmail()
                                    )
                                }
                            >
                                <AllIcon icon={IconHelper.refresh} size='xsmall' noMargin/>
                            </Button>
                        }
                        {access['viewemail-forward'] &&
                            <Button
                                onClick={this.forwardEmail}
                            >
                                <AllIcon icon={IconHelper.forward} size='xsmall' noMargin/>
                            </Button>
                        }
                        {(access['viewemail-resend'] || access['viewemail-forward'] || (access['viewemail-read'] && email.email_datetime_opened === '0000-00-00 00:00:00') ) && 
                            <Button
                                aria-label="More"
                                aria-owns={anchorEl ? 'email-menu' : undefined}
                                aria-haspopup="true"
                                onClick={(e)=>this.setState({anchorEl: e.currentTarget})}
                            >
                                <AllIcon icon={IconHelper.ellipsis} size='xsmall' noMargin/>
                            </Button>
                        }
                    </ButtonGroup>
                    <Menu
                        id="email-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={()=>this.setState({anchorEl: null})}
                        style={{marginTop: '3em'}}
                    >
                        {access['viewemail-resend'] &&
                            <MenuItem
                                onClick={
                                    () => this.props.deployConfirmation(
                                        'Are you sure you want to resend this email?',
                                        <Typography variant='h6' style={{color: colors.black}}>Resend Email</Typography>,
                                        this.resendEmail
                                    )
                                }
                            >
                                <AllIcon icon={IconHelper.refresh} size='xsmall'/> Resend
                            </MenuItem>
                        }
                        {access['viewemail-forward'] &&
                            <MenuItem
                                onClick={this.forwardEmail}
                            >
                                <AllIcon icon={IconHelper.forward} size='xsmall'/> Forward
                            </MenuItem>
                        }
                        {
                            access['viewemail-read'] && email.email_datetime_opened === '0000-00-00 00:00:00' &&
                            <MenuItem
                                onClick={
                                    () => this.props.deployConfirmation(
                                        'Are you sure you want to mark this email as read?',
                                        <Typography variant='h6' style={{color: colors.black}}>Mark As Read</Typography>,
                                        this.markEmailAsRead
                                    )
                                }
                            >
                                <AllIcon icon={IconHelper.emailOpen} size='xsmall'/> Mark As Read
                            </MenuItem>
                        }
                    </Menu>
                </Grid>
                <ViewLinked email={email} source={email.contact.etc_source} source_data={email.contact.etc_source_data} viewEmail={this.props.viewEmail}/>
                <ViewAttachments emailId={email.email_id} />
                <Grid item xs={12}
                    style={{
                        paddingTop: '1em',
                        overflowY: 'auto',
                    }}
                >
                    <Typography 
                        style={{width: '100%'}}
                        onClick={this.handleLinkClick}
                        dangerouslySetInnerHTML={{__html: email.email_raw_html}}
                    />
                </Grid>
{/*
                <Grid item xs={12} style={{marginTop: 'auto', borderTop: `1px solid ${colors.grey}10`, paddingRight: '1.5em', height: 30}}>
                    <Grid container
                        style={{
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Grid item>
                            Sent: {clenyDate(email.email_datetime_added, true) }
                            {email.email_datetime_opened !== '0000-00-00 00:00:00' && ` | Opened: ${clenyDate(email.email_datetime_opened, true)}`  }
                        </Grid>
                    </Grid>
                </Grid>
*/}
            </Grid>
        )
    }
}


const mapDispatchToProps = dispatch => ({
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    deployDialog:       (content, title, size)    => dispatch(deployDialog(content, title, null, size)),
    closeDialog:        ()                        => dispatch(closeDialog()),
})

export default connect(null, mapDispatchToProps)(ViewEmail);
