import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import _ from 'lodash';
import { statusColour } from 'Helpers/ColourHelper';

 const JobSnapshot = ({jobRef, forms}) => {
    return (
        <>
        <Typography variant="body2" paragraph>
            {jobRef}
        </Typography>
        <Typography variant="caption"><b>Forms</b></Typography><br></br>

        <Grid container spacing={1}>
            {_.map(forms, (f, key) => (
                <React.Fragment key={key} >
                    <Grid item xs={8}>
                        {f?.nm}
                    </Grid>
                    <Grid item xs={4}>
                        <b style={{color: statusColour(f?.s) }}>
                            {f?.s}
                        </b>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
        </>
    )
}

export default JobSnapshot;