//ScanDialog

import React, {Component} from 'react';
import _                  from 'lodash';
import { connect }        from 'react-redux';

import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon            from 'Components/Common/Icons/AllIcon';
import Textarea           from 'Components/Common/Inputs/Textarea';
import Qr                 from 'Components/Common/QrReader/QrReader';
import Image              from 'Components/Common/ImageWithError/ImageWithError';

import { colors }       from 'Helpers/ColourHelper';
import { clenyDate }    from 'Helpers/TimeHelper';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';
import { playSound }                 from 'Actions/Sounds/Sounds';

import { Grid, Typography, TextField, Button, ExpansionPanel, ExpansionPanelSummary, 
    ExpansionPanelDetails, List, ListItem, ListItemText } from '@material-ui/core/';
import { withStyles }   from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import logo from 'Assets/Images/clenaware_logo_icon.png';


const initialState = {
    details: [],
    formData: {
        showQR:     false,
        showQTY:    false,
        activePart: null,
        std_id:     0,
        showError:  false,
        stockQTY:   null,
    },
    formErrors: {},
    stage: 0,
    skipped: 0,
    isLoading: true,
    showNotes: false,
    notes:     '',
}

class ManualStockTake extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.state.details = _.map(this.props.details);
        this.state.notes   = this.props.notes;
    }

    componentDidMount = () => {
        this.getPickData()
    }

    getPickData = () => {
        this.setState({isLoading: true},
        ()=>{
            this.setState({
                formData: {
                    ...this.state.formData,
                    activePart: this.state.details[this.state.stage].part,
                    showQR:     true,
                    stockQty:   false,
                    std_id:     this.state.details[this.state.stage].std_id,
                },
                isLoading:  false
            })
        })

    }

    handleScan = (scannedPartNumber) => {
        if (scannedPartNumber){
            if (scannedPartNumber == this.state.formData.activePart.part_number){
                this.props.playSound('pickSuccess', 1)
                this.setState({
                    formData: {
                        ...this.state.formData,
                        showQR:     false,
                        showError:  false,
                        showQTY:    true,
                    }
                })
            } else {
                this.props.playSound('pickFailed', 1);
                this.setState({
                    formData: {
                        ...this.state.formData,
                        showError: true
                    }
                })
            }
        }
    }

    handleSkip = () => {
        this.setState({
            skipped: this.state.skipped + 1
        },this.nextStage);
    }

    nextStage = () => {
        if (this.state.details[this.state.stage + 1]){
            this.setState({
                formData:   initialState.formData,
                stage:      this.state.stage + 1,
                isLoading:  true
            },this.getPickData)
        } else {
            this.setState({
                showNotes: true
            })
        }

    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                stockQTY: e.target.value
            }
        })
    }

    handleEnterQty = () => {
        this.props.itemScanned(this.state.formData.std_id, this.state.formData.stockQTY);
        this.nextStage();
    }

    handleNoteChange = (e) => {
        this.setState({notes: e.target.value})
    }

    render(){
        const {isLoading, formData, stage, details, showNotes, notes} = this.state;
        const { classes, access, stockTake } = this.props;
        
        if (isLoading) return (<LoadingCircle/>);
        if (showNotes) return (
            <Grid container justify="center" className={`${classes.root} text-center`} spacing={8}>
                <Grid item xs={12} lg={8}>
                    {_.filter(stockTake.logs, i => i.stl_notes && i.stl_notes != "" ).length > 0 &&
                        <ExpansionPanel styles={{width:'100%'}} >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>Previous Notes</Typography> 
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <List dense={true} fullWidth style={{width: '100%'}}>
                                    {_.map(_.filter(stockTake.logs, i => i.stl_notes && i.stl_notes != "" ), (i, idx) => 
                                        <ListItem style={{borderBottom: (idx+1) < _.filter(stockTake.logs, i => i.stl_notes && i.stl_notes != "" ).length && `1px solid ${colors.disabled}`}} fullWidth>
                                            <ListItemText
                                                style={{padding: '1em 0'}}
                                                primary={<>{i.stl_notes}<br/><br/></>}
                                                secondary={`${i.stl_staff_name} - ${clenyDate(i.stl_datetime)}`}
                                            />
                                        </ListItem>
                                    )}
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    }
                    <Textarea
                        id="notes"
                        name="notes"
                        label='Stock Take Notes'
                        value={notes}
                        rows={10}
                        onChange={this.handleNoteChange}
                        noMargin
                    />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Button onClick={()=>{this.props.save(notes)}} variant="contained" color="primary">Complete Stock Take</Button>
                </Grid>
            </Grid>
        )
        return (
            <Grid container justify="center" className={`${classes.root} text-center`} spacing={8}>
                <Grid container item className={classes.qrRow}>
                    <Grid item xs={6}>
                        { formData.showQR && <Qr handleScan={this.handleScan} width="30%" /> }
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" className="float-right">
                            Part {stage + 1} of {details.length}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Grid container justify="center" className="text-center" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h3">
                                {formData.activePart.part_number}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {formData.activePart.part_description}<br></br>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="center" className="text-center" spacing={3}>
                        <Grid item xs={12}>
                            <Image src={formData.activePart.default_image?.file_path} className={classes.img} />
                        </Grid>
                    </Grid>
                </Grid>
                {formData.showError &&
                    <Grid item xs={12}>
                        <span className={classes.errorText}>Incorrect Part Please Try Again</span>
                    </Grid>
                }
                {formData.showQTY &&
                    <Grid container item justify="center" spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                id="qty"
                                name="qty"
                                label="Confirm Quantity *"
                                value={formData.stockQTY}
                                type="number"
                                variant="outlined"
                                onChange={this.handleChange}
                                margin="normal"
                                className={classes.confirmQtyInputContainer}
                                inputProps={{
                                    className: classes.confirmQtyInput,
                                    min: 0,
                                    pattern: "\d*"
                                }}
                                autoFocus
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                variant="contained" 
                                color="primary"
                                size="large"
                                onClick={this.handleEnterQty}
                                disabled={formData.stockQTY === null && true}
                                className={classes.largeButton}
                            >
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Button 
                        variant="outlined"
                        endIcon={<AllIcon icon="step-forward" />}
                        className="mt-3 mr-1"
                        onClick={this.handleSkip}
                        style={{border: '1px solid black'}}
                    >
                        Skip
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

const styles = theme => ({
    root: {
        height: '80vh',
        padding: theme.spacing(1)
    },
    img: {
        maxHeight: 330,
        maxWidth: '100%'
    },
    qrRow: {
        maxHeight: 60
    },
    qtyRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    actionText: {
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: 35,
        width: '60%',
        margin: '0 auto'
    },
    connectionText : {
        margin: '1em auto',
        width:  '40%',
        border: `1px solid ${colors.red}`,
    },
    errorText: {
        color: theme.palette.secondary.main
    },
    button: {
        marginTop: 35
    },
    confirmQtyInputContainer: {
        maxWidth: 160,
    },
    confirmQtyInput: {
        textAlign: 'center',
        maxWidth: 140,
        padding: 30,
        fontSize: 50,
    },
    largeButton: {
        fontSize: 24
    },
    successTick: {
        color: colors.green
    },
    [theme.breakpoints.down('sm')]: {
        img: {
            maxHeight: 240,
            maxWidth:  240
        },
        actionText: {
            fontSize: '2rem',
        },
        confirmQtyInputContainer: {
            maxWidth: 120,
        },
        confirmQtyInput: {
            maxWidth: 100,
            padding: 20,
            fontSize: 30,
        },
        largeButton: {
            fontSize: 18
        }
    }
});

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success, cancel) => {
            dispatch(deployConfirmation(message, title, success, cancel))
        },
        playSound: (type, state) => {
            dispatch(playSound(type, state));
        },
        deploySnackBar: (variant, message) => {
            dispatch(deploySnackBar(variant, message));
        }
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ManualStockTake));