import React from 'react';
import API   from 'API';
import _     from 'lodash';

import FALightIcon   from 'Components/Common/Icons/FontAwesome/FALightIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import { FormGroup, Grid, Checkbox, CardContent, Card, FormControlLabel } from '@material-ui/core';

const initialState = {
    allNotificationTypes: {},
    staffNotificationTypes: {},
}

class ViewStaffNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() {
        this.getComponentData();
    }
    getComponentData = () => {
        Promise.all([ 
            API.get(`/misc/notifications/types`),
            API.get(`/staff/${this.props.staffData.staff_id}/notifications`),
        ])
        .then(([typesRes, notifRes]) =>  {
            this.setState({
                allNotificationTypes: typesRes.data,
            });
            _.map(notifRes.data, staffNotif => {
                this.setState({
                    staffNotificationTypes: {
                        ...this.state.staffNotificationTypes,
                        [staffNotif.notification_type_id]: true
                    }
                })
            })
        });
    }

    render() {
        const { allNotificationTypes, staffNotificationTypes } = this.state;
        return(
            <React.Fragment>
                <Card>
                    {(this.props.isLoading && (
                        <LoadingCircle />
                    )) || (
                        <React.Fragment>
                            <CardContent>
                                <Grid container item alignItems="center" align="center" spacing={1}>
                                    <Grid item xs={1}>
                                        <FALightIcon icon='bell' size='large' />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <FormGroup row>
                                            {_.map(allNotificationTypes, notificationType => {
                                                return (
                                                    <FormControlLabel
                                                        key={notificationType.notification_type_id}
                                                        control={
                                                            <Checkbox 
                                                                name={notificationType.notification_type_id.toString()}
                                                                checked={staffNotificationTypes.hasOwnProperty(notificationType.notification_type_id)}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={notificationType.notification_type_name}
                                                        labelPlacement="end"
                                                    />
                                                )
                                            })}
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </React.Fragment>
                    )}
                </Card>
            </React.Fragment>
        );
    }
}

export default ViewStaffNotifications;