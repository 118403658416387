import API                  from 'API';
import _                    from 'lodash';
import React, { Component } from 'react';
import { connect }          from 'react-redux';

import { Button, Checkbox, Grid, Typography } from '@material-ui/core';

import { closeDialog, deployDialog }        from 'Actions/Dialog/Dialog';
import { deploySnackBar }                   from 'Actions/SnackBar/SnackBar';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import { pdfFromBase64 } from "Helpers/PDFHelper";

import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import DataTable          from 'Components/Common/DataTables/CiDataTable';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import DownloadCSV        from 'Components/Common/Buttons/DownloadCSV';

const initialState = () => ({
    formData: {
        type:     'All',
        part:     null,
        discount: null,
        exclude: [],
    },
    parts: [],
    spareParts: [],
    discounts: [],
    isLoading: true,
    csvData: [],
    creatingPdf: false,
});

class SparePartsCalculator extends Component {
    constructor(props) {
        super(props);    

        this.state = initialState();
    }

    componentDidMount = () => {
        this.getLists();
    }

    getLists = () => {
        Promise.all([
            API.get('/parts/all', {params: {hasSpares: 1}}),
            API.get('/accounts/customerDiscounts/byName')
        ]).then(([parts, discounts]) => {
            this.setState({
                parts:     _.map(parts.data, i => ({ value: i.part_id, label: `${i.part_number} - ${i.part_description}`, part: i })),
                discounts: _.map(discounts.data, i => ({value: i.cdn_id, label: _.first(i.discount_values).discount_value_percent, decimal: _.first(i.discount_values).discount_value_decimal})),
                isLoading: false,
            });
        })
    }
    
    handleSelectChange = (name) => (e) => {
        const { formData } = this.state;
        formData[name] = e?.value;
        this.setState({ formData }, this.getData);
    }

    getData = () => {
        let { formData } = this.state;
        if (formData.type === 'All' ? ( formData.discount ) : ( formData.part && formData.discount )) {
            API.get('/prices/spareParts', {params: {
                ...formData,
                discount: _.find(this.state.discounts, {value: formData.discount}).decimal,
                part: formData.type === 'All' ? null : formData.part,   
            }})
            .then(res => {
                this.setState({ spareParts: res.data }, this.createCsvData);
            });
        }
    }

    createCsvData = () => {
        if (this.state.formData.type === 'All') {
            this.setState({ csvData: _.map(_.filter(_.orderBy(this.state.spareParts, 'num', 'asc'), i => !_.find(this.state.formData.exclude, j => parseInt(j) === parseInt(i.id))), i => ( parseInt(this.state.formData.discount) === 11 ?
            {
                'Part Number'      : i.num,
                'Part Description' : i.desc,
                'List Price'       : parseFloat(i.price).toFixed(2),
            } : {
                'Part Number'      : i.num,
                'Part Description' : i.desc,
                'List Price'       : parseFloat(i.price).toFixed(2),
                'Discount %'       : i.discount,
                'New Price'        : parseFloat(i.newPrice).toFixed(2),
                'Discount £'       : parseFloat(i.discountedPrice).toFixed(2),
            }))});
        } else {
            let spareFore = _.find(this.state.parts, {value: this.state.formData.part}).label;
            this.setState({ csvData: _.map(_.filter(_.orderBy(this.state.spareParts, 'num', 'asc'), i => !_.find(this.state.formData.exclude, j => parseInt(j) === parseInt(i.id))), i => ( parseInt(this.state.formData.discount) === 11 ?
            {
                'Spare For'        : spareFore,
                'Part Number'      : i.num,
                'Part Description' : i.desc,
                'List Price'       : parseFloat(i.price).toFixed(2),
            } : {
                'Spare For'        : spareFore,
                'Part Number'      : i.num,
                'Part Description' : i.desc,
                'List Price'       : parseFloat(i.price).toFixed(2),
                'Discount %'       : i.discount,
                'New Price'        : parseFloat(i.newPrice).toFixed(2),
                'Discount £'       : parseFloat(i.discountedPrice).toFixed(2),
            }))});
        }
    }

    downloadPdf = () => {
        this.setState({ creatingPdf: true }, ()=> {
            let { formData } = this.state;
            if (formData.type === 'All' ? ( formData.discount ) : ( formData.part && formData.discount )) {
                API.get('/prices/spareParts', {params: {
                    ...formData,
                    discount: _.find(this.state.discounts, {value: formData.discount}).decimal,
                    part: formData.type === 'All' ? null : formData.part,   
                    pdf: true,
                    exclude: JSON.stringify(this.state.formData.exclude),
                }})
                .then(res => {
                    pdfFromBase64(res.data.pdf, `${formData.type === 'All' ? '' : `${_.find(this.state.parts, {value: parseInt(formData.part)})?.part?.part_number}_`}spare_parts_${_.first(this.state.spareParts)?.discount}_discount.pdf`);
                    this.setState({ creatingPdf: false });
                });
            } else {
                this.setState({ creatingPdf: false })
            }
        });
    }

    handleExcludeChange = id => e => {
        let { exclude } = this.state.formData;
        if (exclude.includes(id)) {
            exclude = _.without(exclude, id);
        } else {
            exclude.push(parseInt(id));
        }
        this.setState({ formData: {...this.state.formData, exclude} }, this.createCsvData);
    }

    render() {

        const { formData, parts, spareParts, isLoading, discounts, csvData, creatingPdf } = this.state;

        if (isLoading || creatingPdf) return (<LoadingCircle message={creatingPdf ? 'Creating PDF...' : null} />);

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">Spare Parts Price List</Typography>
                </Grid>
                <Grid item xs={6}>
                    <PaddedPaper>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    options={[
                                        {value: 'All', label: 'All'},
                                        {value: 'Part', label: 'Part'},
                                    ]}
                                    label="Type *"
                                    value={formData.type}
                                    fullWidth
                                    onChange={this.handleSelectChange('type')}
                                    noClear
                                />
                            </Grid>
                            {formData.type === 'Part' && 
                                <Grid item xs={12}>
                                    <AutoCompleteSelect
                                        options={parts}
                                        label="Part *"
                                        value={formData.part}
                                        fullWidth
                                        onChange={this.handleSelectChange('part')}
                                        noClear
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <AutoCompleteSelect
                                    options={discounts}
                                    label="Discount *"
                                    value={formData.discount}
                                    fullWidth
                                    onChange={this.handleSelectChange('discount')}
                                    noClear
                                />
                            </Grid>
                            <Grid item xs={12} className='buttonRow' >
                                <Button 
                                    variant='contained' 
                                    color='primary' 
                                    disabled={!(spareParts.length > 0)}
                                    style={{marginBottom: '1em'}}
                                    onClick={this.downloadPdf}
                                >Download PDF</Button>
                                <DownloadCSV 
                                    data    ={csvData} 
                                    color='primary' 
                                    variant='contained' 
                                    filename={`${formData.type === 'All' ? '' : `${_.find(parts, {value: parseInt(formData.part)})?.part?.part_number}_`}spare_parts_${_.first(spareParts)?.discount}_discount`} 
                                    style   ={{marginBottom: '1em'}} 
                                    disabled={!(spareParts.length > 0)}
                                >download CSV</DownloadCSV>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                {spareParts.length > 0 && 
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Typography variant='h6' style={{textAlign: 'right', margin: '0.5em'}}>{spareParts.length} Spare Parts</Typography>
                            <DataTable
                                config={{
                                    key: 'id',
                                }}
                                rows={_.orderBy(spareParts, 'num', 'asc')}
                                columns={_.filter([
                                    {
                                        heading:       '',
                                        field:         'img',
                                        fieldFormat:   'image',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Part Number',
                                        field:         'num',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading:       'Part Description',
                                        field:         'desc'
                                    },
                                    {
                                        heading:       'List Price',
                                        field:         'price',
                                        fieldFormat:   'currency',
                                        sizeToContent: true,
                                        alignment:     'right',
                                    },
                                    parseInt(formData.discount) !== 11 && 
                                    {
                                        heading:       'Discount %',
                                        field:         'discount',
                                        sizeToContent: true,
                                        alignment:     'center',
                                    },
                                    parseInt(formData.discount) !== 11 && 
                                    {
                                        heading:       'New Price',
                                        field:         'newPrice',
                                        fieldFormat:   'currency',
                                        sizeToContent: true,
                                        alignment:     'right',
                                    },
                                    parseInt(formData.discount) !== 11 && 
                                    {
                                        heading:       'Discount £',
                                        field:         'discountedPrice',
                                        fieldFormat:   'currency',
                                        sizeToContent: true,
                                        alignment:     'right',
                                    },
                                    {
                                        heading:       'Exclude',
                                        field:         i => <Checkbox 
                                                                color='secondary'
                                                                checked={_.find(formData.exclude,i.id)}
                                                                onClick={this.handleExcludeChange(i.id)}
                                                            />,
                                        sizeToContent: true,
                                        alignment:     'center',
                                    }
                                ], i => i)}
                            />
                        </PaddedPaper>
                    </Grid>
                }
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    deployDialog:       (content, title, variant, size) => {dispatch(deployDialog(content, title, variant, size)) },
    closeDialog:       (content, title, variant, size) => {dispatch(closeDialog(content, title, variant, size)) },
    deploySnackBar:     (variant, message)              => {dispatch(deploySnackBar(variant, message))},
    clearPersistence:   (key)                           => {dispatch(clearPersistence(key))},
    setPersistence:     (key, state)                    => {dispatch(setPersistence(key, state))}
})

export default connect(mapStateToProps, mapDispatchToProps)(SparePartsCalculator);