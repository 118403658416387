import React, { Component } from 'react';
import _                    from 'lodash';

import { Typography, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DataTable   from 'Components/Common/DataTables/DataTable';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';

class AssociatedParts extends Component {

    downloadFile = rowData => {
        window.open(rowData.current_part_file_pdf?.file_path, '_blank');
    }

    render() {
        const { parts, associatedPartFor, originalPart } = this.props;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Associated Parts - Technical Library</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <br />
                            <Typography variant="subtitle1" style={{display:'flex', alignItems: 'center'}} gutterBottom>
                                <FALightIcon icon='info-circle'/> Associated parts belonging to {originalPart}
                            </Typography>
                            <DataTable 
                                config={{
                                    key: 'part_id',
                                    pagination: false,
                                    plainHeader: true
                                }}
                                columns={[
                                    {
                                        heading: 'Part Number',
                                        field: row => row.part_number,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Description',
                                        field: row => row.part_description
                                    },
                                    {
                                        heading: 'Latest PDF Drawing',
                                        field: row => row.current_part_file_pdf?.part_doc_url
                                    },
                                    {
                                        heading: 'Available in Technical Library',
                                        field: row => row.part_file_technical_library_access
                                    },
                                    {
                                        actions: row => {
                                            return [
                                                {name: 'View', icon: 'search', link: '/parts/view/' + row.part_id},
                                                {name: 'Download', icon: 'download', onClick: this.downloadFile}
                                            ]
                                        }
                                    }
                                ]}
                                rows={parts}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br></br>
                            <Typography variant="subtitle1" style={{display:'flex', alignItems: 'center'}} gutterBottom>
                             <FALightIcon icon='info-circle'/> Associated parts {originalPart} belongs to
                            </Typography>
                            <DataTable 
                                config={{
                                    key: 'part_id',
                                    pagination: false,
                                    plainHeader: true
                                }}
                                columns={[
                                    {
                                        heading: 'Part Number',
                                        field: row => row.part_number,
                                        sizeToContent: true
                                    },
                                    {
                                        heading: 'Description',
                                        field: row => row.part_description
                                    },
                                    {
                                        heading: 'Latest PDF Drawing',
                                        field: row => row.current_part_file_pdf?.part_doc_url
                                    },
                                    {
                                        actions: row => {
                                            return [
                                                {name: 'View', icon: 'search', link: '/parts/view/' + row.part_id},
                                                {name: 'Download', icon: 'download', onClick: this.downloadFile}
                                            ]
                                        }
                                    }
                                ]}
                                rows={_.sortBy(associatedPartFor, function (row) { return row.part_number })}
                            />
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default AssociatedParts;
