import { Typography, FormControl, InputLabel, Input, Grid, IconButton, Tooltip } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import ImageWithError from '../../../Common/ImageWithError/ImageWithError';
import FALightIcon from '../../../Common/Icons/FontAwesome/FALightIcon';
import { downloadS3File } from 'Functions/MiscFunctions';

class BarcodeInformation extends Component {

    render() {
        const { barcodeNumber, barcodeURL } = this.props;
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Barcode Information</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {barcodeNumber || barcodeURL ? 
                        <>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel htmlFor="barcodeNumber" shrink={!!barcodeNumber}>Barcode Number</InputLabel>
                                            <Input id="barcodeNumber" value={barcodeNumber || ''} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <FormControl margin="normal" fullWidth>
                                            <InputLabel shrink={true}>Current Barcode Image</InputLabel>
                                            <br></br>
                                            {barcodeURL ? 
                                            <>
                                                <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', width: '170px'}}>
                                                    <ImageWithError alt='Barcode' width="175" src={barcodeURL} type='WHITESPACE' /> 
                                                    <Tooltip title="Print">
                                                        <div>{/* Need tag for tooltip */}
                                                        <IconButton edge="bottom" onClick={() => downloadS3File(barcodeURL)} >
                                                            <FALightIcon icon='download' button noMargin/>
                                                        </IconButton>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </>
                                            : '-'}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        </>
                    :
                        <Typography>There are 0 Barcode details found.</Typography>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default BarcodeInformation;
