import React  from 'react';
import _      from 'lodash';
import moment from 'moment';
import API    from 'API';

import { Grid, Typography } from '@material-ui/core/';

import DataTable     from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper   from 'Components/Common/Paper/PaddedPaper';
import AllIcon       from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';

import DateRangeDialog from './DateRangeDialog';

import { pdfFromBase64 }          from 'Helpers/PDFHelper';
import icons                      from 'Helpers/IconHelper';
import { colorNumbers, currency } from 'Helpers/NumberHelper';
import { Divider } from '@material-ui/core';
import {getFiscalYearTimestamps}  from 'Helpers/ReportsHelper';
import { colors } from 'Helpers/ColourHelper';

const initialState = { 
    filter:      {
        type:       'fyear',      
        from:       getFiscalYearTimestamps().start.format('YYYY-MM-DD'), 
        to:         getFiscalYearTimestamps().end.format('YYYY-MM-DD'), 
    },
    stock:          [],
    isLoading:      true,
    fixed: {
        due: 0,
        received: 0,
        stock: 0,
        expected: 0,
        total: 0,
        dueCost: 0,
        receivedCost: 0,
        stockCost: 0,
        expectedCost: 0,
        totalCost: 0,
        dueParts: 0,
        receivedParts: 0,
        stockParts: 0,
        expectedParts: 0,
        totalParts: 0,
    }
}

const InfoBlock = ({title, icon, value, xs=3}) => {
    return (
        <Grid item xs={xs}>
            <PaddedPaper  style={{textAlign: 'center', height: '100%'}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}><Typography variant="h6" style={{textAlign: 'center'}}>{title}</Typography></Grid>
                    <Grid item xs={12}><AllIcon icon={icon} noMargin size='xlarge' /></Grid>
                    <Grid item xs={12}><Typography variant="h6"  style={{textAlign: 'center'}}>{value}</Typography></Grid>
                </Grid>
            </PaddedPaper>
        </Grid>
    )
} 
class StockReportsDeliveries extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }   

    componentDidMount() {
        this.getData();
        this.getStaticData();
    }

    getStaticData = () => {
        Promise.all([
            API.get('/reports/stocksReport/deliveries', {params: {...{to: '9999-12-31', from: '1970-01-01',type: 'all'}, partId: this.props.partId}}),
            API.get('/reports/stocksReport/stock', {params: {...{to: '9999-12-31', from: '1970-01-01',type: 'all'}, partId: this.props.partId}}),
        ]).then(([dRes, sRes]) => {
            this.setState({
                fixed: {
                    due:        _.sumBy(dRes.data,  i => parseFloat(i.a)),
                    dueCost:    _.sumBy(dRes.data,  i => parseFloat(i.s)),
                    dueParts:   _.filter(dRes.data, i => parseFloat(i.a) > 0).length,

                    received:       _.sumBy(dRes.data,  i => parseFloat(i.o)),
                    receivedCost:   _.sumBy(dRes.data,  i => parseFloat(i.d)), 
                    receivedParts:  _.filter(dRes.data, i => parseFloat(i.o) > 0).length,

                    stock:      _.sumBy(sRes.data,  i => parseFloat(i.h)),
                    stockCost:  _.sumBy(sRes.data,  i => parseFloat(i.h) * parseFloat(i.t)),
                    stockParts: sRes.data.length,

                    expected:      _.sumBy(dRes.data,  i => parseFloat(i.u)),
                    expectedCost:  _.sumBy(dRes.data,  i => parseFloat(i.g)),
                    expectedParts: dRes.data.length,

                    total:      _.sumBy(sRes.data,  i => parseFloat(i.h)) + _.sumBy(dRes.data,  i => parseFloat(i.a)),
                    totalCost:  _.sumBy(sRes.data,  i => parseFloat(i.h) * parseFloat(i.t))  + _.sumBy(dRes.data,  i => parseFloat(i.s)),
                    totalParts: sRes.data.length,
                },
            })
        });
    }

    getData = () => {
        API.get('/reports/stocksReport/deliveries', {params: {...this.state.filter, partId: this.props.partId}})
        .then(res => {
            this.setState({
                stock: res.data,
                isLoading: false
            });
        });
    }

    handleSetFilterParams = (filter) => { this.setState({filter, isLoading: true}, this.getData)} 

    downloadPPO = ppoId => {
        API.get('/partsOrdering/' + ppoId + '/pdfPartsOrder')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    render() {
        const {isLoading, stock, table, formErrors, filter, fixed } = this.state;

        const columns = [
            {
                field:         'r',
                fieldFormat:   'image',
                sizeToContent: true,
                filter:         'custom',
                filterMod: {
                    dataRef:   (search, rowData) => {return `${rowData.e}|${rowData.w}`.toLowerCase().includes(search.toLowerCase()) > 0;},
                    label:     'Search Parts'
                },
            },
            {
                heading: 'Part/Description',
                field:   i => <>{i.w}<br/>{i.e}</>,
                dataRef: 'e',
                truncate: true
            },
            {
                heading: 'Supplier',
                field:   't',
                dataRef: 't',
            },
            {
                heading:      'PPOs',
                field:        r => r.v.length,
                dataRef:      r => r.v.length,
                sizeToContent: true,
                alignment:    'center',
            },
            {
                heading:      '',
                field:        r => <>In Period<br/>Period Cost<br/><b>Total<br/>Total Cost</b></>,
                sizeToContent: true,
            },
            {
                heading:      'PO Scheduled',
                field:        r => <>
                    {(parseFloat(r.b)).toFixed(3)}<br/>
                    {currency(parseFloat(r.g))}<br/>
                    <b>
                    {(parseFloat(r.u)).toFixed(3)}<br/>
                    {currency(parseFloat(r.l))}</b>
                </>,
                dataRef:      'u',
                sizeToContent: true,
                alignment:    'right',
            },
            {
                heading:      'Current Scheduled',
                field:        r => <>
                    { moment().isBetween(this.state.filter.from, this.state.filter.to) ? 
                        <>
                            {((parseFloat(r.a) < 0 || parseFloat(r.p) < 0 ? 0 : parseFloat(r.a)) + parseFloat(r.o)).toFixed(3)}<br/>
                            {currency((parseFloat(r.a) < 0 || parseFloat(r.p) < 0 ? 0 : (parseFloat(r.s))) + parseFloat(r.d))}
                            <br/><b>
                            {(parseFloat(r.u)).toFixed(3)}<br/>
                            {currency(parseFloat(r.l))}</b>
                        </> : 
                        <>-<br/>-<br/><b>-<br/>-</b></>
                    }
                    
                </>,
                dataRef:      'u',
                sizeToContent: true,
                alignment:    'right',
            },
            {
                heading:      'Outstanding',
                field:        r => <>
                    {(parseFloat(r.a) < 0 || parseFloat(r.p) < 0 ? 0 : parseFloat(r.a)).toFixed(3)}<br/>
                    {currency(parseFloat(r.a) < 0 || parseFloat(r.p) < 0 ? 0.000 : (parseFloat(r.s)))}
                    <br/><b>
                    {(parseFloat(r.p) < 0 ? 0 : parseFloat(r.p)).toFixed(3)}<br/>
                    {currency(parseFloat(r.p) < 0 ? 0 : parseFloat(r.z))}</b>
                </>,
                dataRef:      'p',
                alignment:    'right',
                sizeToContent: true,
            },
            {
                heading:      'Received',
                field:        r => <>
                        {parseFloat(r.o).toFixed(3)}<br/>
                        {currency(parseFloat(r.d))}<br/><b>
                        {parseFloat(r.i).toFixed(3)}<br/>
                        {currency(parseFloat(r.x))}
                    </b></>,
                dataRef:      'o',
                sizeToContent: true,
                alignment:    'right',
            },
            {
                actions: r => {
                    return [
                        {name: 'View Part', icon: icons.search, link: `/parts/view/${r.q}`}
                    ]
                }
            }
        ];

        return (
            <Grid container xs={12} spacing={2} style={{width: '100%'}}>
                <Grid item xs={4}>
                    <PaddedPaper style={{height: '100%'}}>
                        <DateRangeDialog 
                            callback={this.handleSetFilterParams} 
                            error={formErrors} 
                            formData={filter}
                        />
                    </PaddedPaper>
                </Grid>
                {isLoading ? <Grid item xs={8}></Grid>:
                    <Grid item xs={8}>
                        <Grid container spacing={2} style={{alignItems: 'center'}}>
                            <Grid item style={{width: 200}}><Typography><b>Based On Search Criteria</b></Typography></Grid>
                            <Grid item style={{width: 'calc(100% - 200px)'}}><Divider/></Grid>
                            <InfoBlock 
                                title='PO Scheduled' 
                                icon={icons.calendarExclamation} 
                                value={<>
                                    <AllIcon icon={icons.parts}/>{colorNumbers(stock.length)}<br/>
                                    <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(stock, r => parseFloat(r.b)).toFixed(3))}<br/>
                                    <AllIcon icon={icons.poundSign}/>{currency(_.sumBy(stock, r => parseFloat(r.g)))}
                                </>}
                            />
                            <InfoBlock 
                                title='Current Scheduled' 
                                icon={icons.calendarExclamation} 
                                value={ moment().isBetween(this.state.filter.from, this.state.filter.to) ? <>
                                    <AllIcon icon={icons.parts}/>{colorNumbers(stock.length)}<br/>
                                    <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(stock, r => parseFloat((parseFloat(r.a) < 0 || parseFloat(r.p) < 0 ? 0 : parseFloat(r.a)) + parseFloat(r.o))).toFixed(3))}<br/>
                                    <AllIcon icon={icons.poundSign}/>{currency(_.sumBy(stock, r => (parseFloat(r.a) < 0 || parseFloat(r.p) < 0 ? 0 : (parseFloat(r.s))) + parseFloat(r.d)))}
                                </> : <> <AllIcon icon={icons.parts}/>{colorNumbers(stock.length)}<br/><AllIcon icon={icons.qty}/>-<br/><AllIcon icon={icons.poundSign}/>-</>}
                            />
                            <InfoBlock 
                                title='Outstanding' 
                                icon={icons.truck} 
                                value={<>
                                    <AllIcon icon={icons.parts}/>{colorNumbers(_.filter(stock, r => parseFloat(r.a) > 0).length)}<br/>
                                    <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(stock, r => parseFloat(r.a)).toFixed(3))}<br/>
                                    <AllIcon icon={icons.poundSign}/>{currency(_.sumBy(stock, r => parseFloat(r.s)))}
                                </>}
                            />
                            <InfoBlock 
                                title='Received' 
                                icon={icons.unpackTruck} 
                                value={<>
                                    <AllIcon icon={icons.parts}/>{colorNumbers(_.filter(stock, r => parseFloat(r.o) > 0).length)}<br/>
                                    <AllIcon icon={icons.qty}/>{colorNumbers(_.sumBy(stock, r => parseFloat(r.o)).toFixed(3))}<br/>
                                    <AllIcon icon={icons.poundSign}/>{currency(_.sumBy(stock, r => parseFloat(r.d)))}
                                </>}
                            />
                        </Grid>
                    </Grid>
                }
                <InfoBlock 
                    title='PO Scheduled' 
                    icon={icons.calendarExclamation} 
                    xs={4}
                    value={<>
                        <AllIcon icon={icons.parts}/>{colorNumbers(fixed.expectedParts)}<br/>
                        <AllIcon icon={icons.qty}/>{colorNumbers(fixed.expected.toFixed(3))}<br/>
                        <AllIcon icon={icons.poundSign}/>{currency(fixed.expectedCost)}
                    </>}
                />
                <InfoBlock 
                    title='Outstanding' 
                    icon={icons.truck} 
                    xs={4}
                    value={<>
                        <AllIcon icon={icons.parts}/>{colorNumbers(fixed.dueParts)}<br/>
                        <AllIcon icon={icons.qty}/>{colorNumbers(fixed.due.toFixed(3))}<br/>
                        <AllIcon icon={icons.poundSign}/>{currency(fixed.dueCost)}
                    </>}
                />
                <InfoBlock 
                    title='Received' 
                    icon={icons.unpackTruck} 
                    xs={4}
                    value={<>
                        <AllIcon icon={icons.parts}/>{colorNumbers(fixed.receivedParts)}<br/>
                        <AllIcon icon={icons.qty}/>{colorNumbers(fixed.received.toFixed(3))}<br/>
                        <AllIcon icon={icons.poundSign}/>{currency(fixed.receivedCost)}
                    </>}
                />
                
                {isLoading ? <Grid item xs={12}><LoadingCircle /></Grid> : 
                    <> 
                        <Grid item xs={12} style={{paddingTop: '1.5em'}}> 
                            <DataTable 
                                rows={_.orderBy(stock, 'w')}
                                columns={columns}
                                config={{
                                    key: 'q',
                                    pagination: true,
                                    responsiveimportant: true,
                                    rowsPerPage: 25,
                                    filter: true,
                                    filterMod: {
                                        filterInPagination:     true,
                                        clearable:              true,
                                        colSpan:                3,
                                    },
                                    sticky:true,
                                    dropRow: {
                                        droppable: true,
                                        style: {fontWeight: 'bold'},
                                        columns: [
                                            {
                                                colSpan: 4,
                                                field: 'w',
                                            },
                                            {
                                                colSpan: 1,
                                                field: 'e',
                                                fieldFormat: 'clenydate'
                                            },
                                            {
                                                colSpan: 1,
                                                field:       i => <>{parseFloat(i.t).toFixed(3)}<br/>{currency(parseFloat(i.y))}</>,
                                                alignment:   'right',
                                            },
                                            {
                                                colSpan: 3,
                                                field: '',
                                            },
                                            {
                                                actions: r => ([
                                                    {
                                                        icon: icons.partsOrdering,
                                                        name: 'view Order',
                                                        link: `/parts-order/view/${r.q}`,
                                                    }
                                                ])
                                            }
                                        ],
                                        data: 'v',
                                    }
                                }}
                            />
                        </Grid>
                        
                    </>
                }
            </Grid>
        )
    }
}

export default StockReportsDeliveries;