import { Button, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import API from 'API';
import { closeDialog } from 'Actions/Dialog/Dialog';
import AllIcon from 'Components/Common/Icons/AllIcon';

import WYSIWYG from 'Components/Common/Inputs/WYSIWYG';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AutoCompleteSelect from 'Components/Common/Selects/AutoCompleteSelect';
import { ACCOUNTS_EMAIL, DEFAULT_EMAIL, SALES_EMAIL, WEB_EMAIL, WEB_PHONE } from 'Constants';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import InputHelper from 'Helpers/InputHelper';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import {getMediaTypeIcon} from 'Helpers/FileHelper';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import uuidv4 from 'uuid/v4';

const initialBody = props => {

    let ret = (props.body) ? props.body : '';

    if (props.noFooter) return ret;

    if (props.footer) return ret + props.footer;

    let name = props.fromUser ? 
        (props.fromUser === 'clenaware' ? 'Clenaware Systems' : props.fromUser ) :
        `${props.loggedInStaff.firstName} ${props.loggedInStaff.lastName}`;

    return (
`${ret}<br/><table class="body-wrap">
                <td colspan="2">
                    <p>
Best Regards
<b id='fromUser'>${name}</b>

www.clenaware.co.uk
Office:&nbsp;&nbsp;${WEB_PHONE}
Email:&nbsp;&nbsp;<a href="mailto:${WEB_EMAIL}">${WEB_EMAIL}</a><br/>

<span style="font-size:8.0pt;font-family:"Arial",sans-serif">This email and any files transmitted with it are confidential and intended solely for the use by the recipient(s) named above. If you have received this e.mail 
in error please notify us at <a href="mailto:${WEB_EMAIL}">${WEB_EMAIL}</a>, please also note that any disclosure, copying or any unauthorised use of this e.mail or any attachments is prohibited. 
<a href="www.clenaware.co.uk">Clenaware Systems</a> takes every step possible to ensure that any emails and any attachments are free from computer viruses, but we advise that all recipients should adopt 
sensible computing practices and verify that they are actually free from any computer viruses.

Clenaware Systems Ltd. 44 Huxley Close. Park Farm Industrial Estate. Wellingborough. Northants. NN8 6AB
Place of registration is England & Wales - 7029596.</span>
                    </p>
                </td>
            </tr>
        </table>`
    )
}

const initialState = props => {

    let to = [];

    if (props.to){
        if (_.isArray(props.to)){
            to = _.map(props.to, i => `contact:${i}`);
        } else {
            to = [`contact:${props.to}`]
        }
    }

    return {
        formData: {
            to: to,
            from: props.from || (props.defaultFrom || 'Default'),
            fromUser: props.fromUser || ( props.loggedInStaff.id ),

            cc: props.cc || '',

            subject: props.subject || '',
            attachments: props.attachments || [],
            files: [],
            body: initialBody(props),

            source: props.source || 'Manual',
            sourceData: props.sourceData || null,
        }, 
        _formData: {
            //not sending formdata
            customerId: props.customerId || null,
            showAdv: false,
            showFooter: false,
            dragOver: false,
        },
        show: {
            from: !props.from,
            customers: !props.customerId && !props.to,
            contact: !props.to,
            subject: !props.subject,
        },
        lists: {
            customers: [],
            contacts: [],
            staff: [],
            from: [
                {value: 'Default', label: `Default (${DEFAULT_EMAIL})`},
                {value: 'Accounts', label: `Accounts (${ACCOUNTS_EMAIL})`},
                {value: 'Web', label: `Web (${WEB_EMAIL})`},
                {value: 'Sales', label: `Sales (${SALES_EMAIL})`}
            ],
            fromUser: [
                {value: props.loggedInStaff.id, label:  `${props.loggedInStaff.firstName} ${props.loggedInStaff.lastName}`},
                {value: 'clenaware', label: 'Clenaware Systems'},
            ]
        },
        isLoading: true,
        refreshWYSIWYG: uuidv4(),
    }
}

class SendEmail extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState(props);
        this.inputHelper = new InputHelper(this);
        this.fileInputRef = React.createRef();
    }

    componentDidMount(){
        this.getLists();
    }

    submit = () => {
        let formData =  new FormData();
        _.each(_.keys(this.state.formData), key => {
            switch (key){
                case 'to':
                    formData.append('contacts', JSON.stringify(_.map(_.filter(this.state.formData[key], i => i.includes('contact:')), i => i.replace('contact:', ''))));
                    formData.append('staff', JSON.stringify(_.map(_.filter(this.state.formData[key], i => i.includes('staff:')), i => i.replace('staff:', ''))));
                    break;
                case 'cc':
                    formData.append('contactsCC', JSON.stringify(_.map(_.filter(this.state.formData[key], i => i.includes('contact:')), i => i.replace('contact:', ''))));
                    formData.append('staffCC', JSON.stringify(_.map(_.filter(this.state.formData[key], i => i.includes('staff:')), i => i.replace('staff:', ''))));
                    break;
                case 'files':
                    this.state.formData[key].length > 0 &&
                        _.each(this.state.formData[key], i => {
                            formData.append('files[]', i);
                        })
                    break;
                default:
                    if (_.isArray(this.state.formData[key]) || _.isObject(this.state.formData[key])){
                        formData.append(key, JSON.stringify(this.state.formData[key]));
                    } else {
                        formData.append(key, this.state.formData[key]);
                    }
                    break;
            }
        })
        API.post('/email/manuelEmail', formData).then(res => {
            this.props.onSend();
        })
    }

    getLists = () => {
        Promise.all([
            this.props.customerId ?  API.get(`/customers/${this.props.customerId}`) : API.get('/marketing/campaigns/mailingLists/eligibleContacts', {params: {breakdown: true}}) ,
            API.get('/customers'),
            API.get('/staff/all', {params: {active :true}})
        ]).then(([contact, customer, staff]) => {            
            this.setState({
                lists: {
                    ...this.state.lists,
                    customers: customer.data,
                    contacts:  _.map(_.filter(this.props.customerId ? contact.data.active_contacts : contact.data.contacts, 'contact_email'), i => ({label: `${i.contact_name} (${i.contact_email})`, value: `contact:${i.contact_id}`, contact: i})),
                    staff: _.map(staff.data, i => ({ value: `staff${i.staff_id}`, label: i.staff_first_name + ' ' + i.staff_last_name + ' (' + i.staff_email + ')', staff: i })),
                },
                isLoading: false,
            })
        })
    }

    handleDragFile = (drop) => (event) => {
        if(drop) event.preventDefault();     
        let newFiles = drop === true ? event.dataTransfer.files : event.target.files;
        let existingFiles = this.state.formData.files;
        if (!_.isArray(existingFiles)){existingFiles=[];}
        let allFiles = Array.prototype.slice.call(existingFiles).concat(Array.prototype.slice.call(newFiles))

        this.setState({
            formData: {
                ...this.state.formData,
                files: allFiles,
                attachments: [...(this.props.attachments || []),..._.map(allFiles, (i,idx) => ({
                        name: i.name,
                        type: 'upload',
                        idx: idx,
                        icon: getMediaTypeIcon(i.name),
                    }))
                ]
            },
            _formData: {
                ...this.state._formData,
                dragOver: false
            }
        });
    }
    removeAttachment = (idx) => () => {
        let { attachments, files } = this.state.formData;
        let attachment = attachments[idx];
        if (attachment.type === 'upload'){
            files.splice(attachment.idx, 1);
        }
        attachments.splice(idx, 1);
        this.setState({
            formData: {
                ...this.state.formData,
                files: files,
                attachments: attachments,
            }
        })
    }
    changeFromUser = ({value, label}) => {
        let body = this.state.formData.body;
        let prevFromUser = _.find(this.state.lists.fromUser, {value:this.state.formData.fromUser}).label;
        if (body.includes(prevFromUser)){
            body = body.replace(prevFromUser,label);
        }
        this.setState({
            formData: {
                ...this.state.formData,
                fromUser: value,
                body: body
            },
            refreshWYSIWYG: uuidv4(),
        }) 
    }
    render () {
        const { formData, _formData, lists, isLoading } = this.state;

        if (isLoading){
            return <LoadingCircle />
        }

        return (
            <div
                onDragOver={this.inputHelper.handleButtonChange('_formData.dragOver', true)}
                onDragLeave={this.inputHelper.handleButtonChange('_formData.dragOver', false)}
                onDrop={this.handleDragFile(true)} 
            >
                <Grid container spacing={1} style={{alignItems: 'flex-end'}}>
                    <Grid item style={{height: 32, width: 50}}>
                        <Typography variant="body1" style={{marginBottom: 0}}>To *</Typography>
                    </Grid>
                    <Grid item style={{width: 'calc(100% - 90px)'}}>
                        <AutoCompleteMultiSelect
                            options={[...lists.contacts]}
                            value={formData.to}
                            onChange={this.inputHelper.handleMultiSelectChange('formData.to')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        To
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item style={{width: 40}}>
                        <Button
                            variant="outlined"
                            onClick={this.inputHelper.handleButtonChange('_formData.showAdv', !_formData.showAdv)}
                            fullWidth
                            style={{
                                height: 32,
                                width: 40,
                                padding: 0,
                                minWidth: 40,
                            }}
                        ><AllIcon icon={IconHelper[_formData.showAdv ? 'arrowUp' : 'arrowDown']} noMargin size='small'/></Button>
                    </Grid>
                    {_formData.showAdv &&
                        <>
                            <Grid item xs={12}></Grid>
                            <Grid item style={{height: 32, width: 50}}>
                                <Typography variant="body1" style={{marginBottom: 0}}>From</Typography>
                            </Grid>
                            <Grid item style={{width: 'calc(25% - 25px)'}}>
                                <AutoCompleteSelect
                                    isSearchable={false}    
                                    options={lists.fromUser}
                                    value={formData.fromUser}
                                    onChange={this.changeFromUser}
                                    noClear
                                    fullWidth
                                />
                            </Grid>
                            <Grid item style={{width: 'calc(75% - 25px)'}}>
                                <AutoCompleteSelect
                                    isSearchable={false}    
                                    options={lists.from}
                                    value={formData.from}
                                    onChange={this.inputHelper.handleSelectChange('formData.from')}
                                    noClear
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item style={{height: 32, width: 50}}>
                                <Typography variant="body1" style={{marginBottom: 0}}>CC</Typography>
                            </Grid>
                            <Grid item style={{width: 'calc(100% - 50px)'}}>
                                <AutoCompleteMultiSelect
                                    options={_.filter([...lists.contacts, ...lists.staff], i => !_.includes(formData.to, i.value))}
                                    value={formData.cc}
                                    onChange={this.inputHelper.handleMultiSelectChange('formData.cc')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                To
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </>
                    }
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}
                        style={{
                            border: `1px solid ${colors.disabled}`,
                            padding: '0.5em',
                            borderRadius: 5,
                        }}
                    >
                        {_formData.dragOver ?
                            <Grid container spacing={1} style={{alignItems: 'center', alignContent: 'center', flexDirection: 'column'}}>
                                <Grid item >
                                    <AllIcon icon={IconHelper.paperclip} noMargin size='xxxlarge'/>
                                </Grid>
                                <Grid item >
                                    <Typography style={{textAlign: 'center'}} variant='h6'>Attach File</Typography>
                                    <Typography style={{textAlign: 'center'}} variant='caption'>Please Note: Max 20MB</Typography>
                                </Grid>
                            </Grid> :
                            <Grid container spacing={1} style={{alignItems: 'center'}}>
                                <Grid item style={{width: 'calc(100% - 50px)'}}>
                                    <TextField
                                        placeholder="Add A Subject"
                                        value={formData.subject}
                                        onChange={e=>this.setState(this.inputHelper.setNestedValue(e.target.name, e.target.value))}
                                        name="formData.subject"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item style={{width: 40}}>
                                    <Button
                                        variant="outlined"
                                        onClick={()=>this.fileInputRef.current.click()}
                                        fullWidth
                                        style={{
                                            height: 32,
                                            width: 40,
                                            padding: 0,
                                            minWidth: 40,
                                        }}
                                    ><AllIcon icon={IconHelper.paperclip} noMargin size='small' /></Button>
                                </Grid>
                                {formData.attachments.length > 0 &&
                                    <Grid xs={12}>
                                        <Grid container spacing={2} style={{padding:'0.75em', paddingBottom:'0.25em'}}>
                                            {_.map(formData.attachments, (i,idx) =>
                                                <Grid item key={idx}
                                                    style={{
                                                        backgroundColor: colors.primary,
                                                        padding: '4px 6px',
                                                        borderRadius: 3
                                                    }}
                                                    className='hover'
                                                    onClick={this.removeAttachment(idx)}
                                                >
                                                    <Grid container style={{padding:0}}>
                                                        
                                                        <Grid item >
                                                            <Typography variant='body2' style={{color: 'white'}}>{i.name}</Typography>
                                                        </Grid>
                                                        <Grid item
                                                            style={{
                                                                backgroundColor: `${colors.primary}20`,
                                                                marginLeft: 5,
                                                            }}
                                                        >
                                                            <AllIcon
                                                                icon={IconHelper.close}
                                                                white
                                                                size='small'
                                                                noMargin
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12} >
                                    <WYSIWYG
                                        value={formData.body}
                                        onChange={this.inputHelper.handleValueChange('formData.body')}
                                        update={this.state.refreshWYSIWYG}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} className='buttonRow'>
                        <Button
                            variant='outlined'
                            onClick={this.props.closeDialog}
                        >Close</Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={()=>this.props.deployConfirmation(
                                'Are you sure you want to send this email?',
                                <Typography variant="h6" style={{color: colors.black}}>Send Email?</Typography>, 
                                this.submit
                            )}
                            disabled={
                                formData.to.length === 0 ||
                                !formData.subject ||
                                !formData.body
                            }
                        >Send</Button>
                    </Grid>
                    <div style={{display: 'none'}}>
                        <input 
                            onChange={this.handleDragFile(false)}
                            ref={this.fileInputRef}
                            type="file"
                            multiple
                        />
                    </div>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}

const mapDispatchToProps = dispatch => ({
    closeDialog:  ()                              => dispatch(closeDialog()),
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SendEmail);