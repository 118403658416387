import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const ThreadImage = ({classes, image, handleClearImage}) => (
    <Box mt={1}>
        <Grid container alignItems='center' spacing={1}>
            <Grid item xs onClick={() => !handleClearImage && window.open(image)} style={{cursor: !handleClearImage ? 'pointer' : undefined}}>
                <img src={image} alt={image} className={classes.image} />
            </Grid>
            {handleClearImage && (
                <Grid item>
                    <IconButton onClick={handleClearImage}>
                        <FAIcon type="light" icon="times" noMargin button />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    </Box>
)

export default ThreadImage;