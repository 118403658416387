import React from 'react'
import { Grid, Tooltip, Typography } from '@material-ui/core'
import PaddedPaper from '../Paper/PaddedPaper'
import AllIcon from '../Icons/AllIcon'

export default (props) => {

    const {
        icon, value, title, color, rowSize, info=null, border = null, onClick = null, iconType = 'default'
    } = props;

    function InfoBox ({
        icon, value, title, color, border = null, onClick = null, iconType = 'default'
    }){
        return (
            <PaddedPaper
                style={{
                    border: border ,
                    cursor: onClick ? 'pointer' : 'default',
                }}
                onClick={onClick}
            >
                <Grid container direction="column" alignItems="center" spacing={1}
                    style={{
                        position: 'relative',
                    }}
                >
                    {onClick && 
                        <AllIcon icon={'hand-pointer'} size='small'
                            style={{
                                position: 'absolute',
                                zIndex: 999,
                                top: -10,
                                right: -15,
                            }}
                        />
                    }
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center">{title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <AllIcon
                            icon={icon}
                            color={color}
                            noMargin
                            size='xlarge'
                            heavy={iconType === 'solid'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">{value}</Typography>
                    </Grid>
                </Grid>
            </PaddedPaper>
        )
    }
    

    if (info) {
        return (
            <Grid item style={{width: `${100/(rowSize || 5)}%`}}>
                <Tooltip title={<Typography>{info}</Typography>} placement="bottom">
                    <div>
                        <InfoBox {...{icon, value, title, color, border, onClick, iconType}}/>
                    </div>
                </Tooltip>
            </Grid>
        )
    }
    return (
        <Grid item style={{width: `${100/(rowSize || 5)}%`}}>
            <InfoBox {...{icon, value, title, color, border, onClick, iconType}}/>
        </Grid>
    )

}
    