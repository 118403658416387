import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaddedPaper from '../../Common/Paper/PaddedPaper';
import Button from '@material-ui/core/Button';
import API from '../../../API';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import FALightIcon from '../../Common/Icons/FontAwesome/FALightIcon';
import {statusColour} from './../../../Helpers/ColourHelper'
import { pdfFromBase64 } from './../../../Helpers/PDFHelper';
import SnackBar from '../../Common/SnackBars/SnackBar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { formatValidationErrors } from '../../../Helpers/ErrorHelper';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { connect } from 'react-redux';
import DragFileInput from '../../Common/Inputs/DragFileInput';
import StatusDataTable from '../StatusDataTable';
import { MAX_UPLOAD_LIMIT_SIZE } from './../../../Constants';
import Textarea from '../../Common/Inputs/Textarea';
import {
    clearPersistence,
    setPersistence
} from 'Actions/StatePersistence/StatePersistence';

import {
    getInitialState,
    hasPageState,
    savePageState,
    clearPageState
} from 'Functions/StatePersistenceFunctions';

const initialState = () => ({
    formData: {
        po_id: '',
        notes:'',
        deliveryNote:''
    },
    keywords: '',
    searchResults: {},
    formErrors: [],
    dialogOpen: false,
    snackbarOpen: false,
    statuses: ['Approval', 'Dispatch', 'Confirmation', 'Delivery', 'Complete'],
    isLoading: true
});

class PurchaseOrderDelivery extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = 'PurchaseOrder:Delivery';
        this.state              = this.getInitialState(initialState());
    }

    componentDidMount(){
        this.getSearchData();
    }

    getSearchData = () => {
        const params = {
            keywords: this.state.keywords,
            status: 'Delivery'
        }
        this.setState({
            isLoading: true
        }, () => {
            API.get('/purchaseOrders', {
                params,
                props: {
                    cancellation: true
                }
            })
            .then((result) => {
                if(result?.data) {
                    _.map(result.data, (po) => {
                        po.colour = statusColour(po.po_status);
                    });
                    this.setState({
                        ...this.state,
                        searchResults: result.data,
                        isLoading: false
                    })
                }
            })
        })
    }

    handleSearchKeyword = (e) => {
        this.setState({
            keywords: e.target.value
        }, 
        () => {
            this.savePageState();
            this.getSearchData();
        });
    };

    resetSearch = () => {
        this.clearPageState();
        this.setState({
            keywords: '',
        }, 
        () => {
            this.getSearchData();
        });
    }

    downloadFile = rowData => {
        API.get('/purchaseOrders/' + rowData.po_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false }); 
    };
    handleDialogClose = () => {this.setState({dialogOpen: false});};
    handleDialogOpen = rowData => {
        this.setState({
            dialogOpen: true,
            statuses: ['Approval', 'Confirmation',  'Delivery', 'Invoice', 'Complete'],
            formData: {
                ...this.state.formData,
                po_id: rowData.po_id,
                
            }
        });
    };

    handleSuccess = () => {
        this.setState({ dialogOpen: false });
        this.submit();
    }

    submit = () => {
        let newFormData = new FormData();
        Object.keys(this.state.formData).forEach(key => {
            newFormData.append(key, this.state.formData[key])
        });
        API.post(`/purchaseOrders/${this.state.formData.po_id}/delivery`, newFormData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.getSearchData();
            }
        });
    }

    clearFile = () => {
        this.setState({
            formData: {
                ...this.state.formData,
                deliveryNote: initialState().formData.deliveryNote
            }
        });
    };

    handleFileChange = (drop, name, event) => {
        const file = drop === true ? event.dataTransfer.files[0] : event.target.files[0];
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: file
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }


    render() {
        const { formErrors } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Purchase Order Delivery
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <PaddedPaper>
                            <form noValidate autoComplete="off">
                                <FormControl margin="normal" fullWidth>
                                    <TextField label="Keywords" fullWidth value={this.state.keywords} onChange={this.handleSearchKeyword} />
                                </FormControl>
                                <div className='buttonRow'>
                                    <Button onClick={this.resetSearch}
                                            variant="outlined"
                                            color="default"
                                            >
                                        <FALightIcon icon='undo' button />
                                        Reset Search
                                    </Button>
                                </div>
                            </form>
                        </PaddedPaper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PaddedPaper>
                        <StatusDataTable 
                            searchResults={this.state.searchResults}
                            actions={ rowData => {
                                return [
                                    {name: 'Download', icon: 'download', onClick: this.downloadFile},
                                    {name: 'Confirm', icon: 'check', onClick: this.handleDialogOpen}
                                ]
                            }}
                            isLoading={this.state.isLoading}
                        />
                    </PaddedPaper>
                </Grid>
                <Dialog 
                    open={this.state.dialogOpen} 
                    onClose={this.handleDialogClose} 
                    fullWidth={true} 
                    maxWidth="sm" 
                    scroll="body"
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle id="form-dialog-title" color="secondary">Confirm Delivery</DialogTitle>
                    <DialogContent>
                        <Stepper activeStep={2} alternativeLabel >
                            {this.state.statuses.map(label => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <DragFileInput
                            id="deliveryNote"
                            name="deliveryNote"
                            label="Delivery Note"
                            file={this.state.formData.deliveryNote}
                            errorText={formErrors && formErrors['deliveryNote']}
                            onChange={this.handleFileChange}
                            cancelOnClick={this.clearFile}
                            emptyText='No file selected'
                        />
                        <Textarea
                            id="notes"
                            name="notes"
                            label="Notes"
                            value={this.state.formData.notes}
                            rows={5}
                            error={formErrors && formErrors['notes']}
                            onChange={this.handleChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Typography variant="subtitle2" style={{flex: 1}}>
                            <FALightIcon icon='info-circle' size='small' noMargin /> Combined maximum upload limit {MAX_UPLOAD_LIMIT_SIZE}
                        </Typography>
                        <Button onClick={this.handleDialogClose} variant="outlined" color="default">Cancel</Button>
                        <Button onClick={this.handleSuccess} color="primary" autoFocus variant="contained">Confirm</Button>
                    </DialogActions>
                </Dialog>
                <SnackBar
                    variant="success"
                    anchorOriginVertical='bottom'
                    anchorOriginHorizontal='right'
                    open={this.state.snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    message='You have successfully confirmed this delivery.'
                />
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence:   state.statePersistence,
    loggedInStaff:      state.staffAuth.staff
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)    => dispatch(setPersistence(key, state))
})

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderDelivery);
