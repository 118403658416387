import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import {
    AppBar, Button,
    Card, CardContent,
    CardHeader, Checkbox,
    Dialog, DialogActions, DialogContent,
    DialogContentText,
    DialogTitle,
    Divider, Grid,
    IconButton,
    Tab, Tabs,
    Tooltip,
    Typography
} from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import { withStyles } from '@material-ui/styles';

import BackButton from 'Components/Common/Buttons/BackButton';
import DataTable from 'Components/Common/DataTables/DataTable';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import EmailDialog from 'Components/Common/Dialogs/EmailDialog';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import FASolidIcon from 'Components/Common/Icons/FontAwesome/FASolidIcon';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SnackBar from 'Components/Common/SnackBars/SnackBar';
import TypographyAllowHtml from 'Components/Common/Typography/TypographyAllowHtml';
import FileDialog from 'Components/Sales/Order/AddFileDialog/AddFileDialog';
import Quotation from 'Components/Sales/Quotation/ViewQuotation/Quotation';

import EngineerMedia from 'Components/Common/EngineerMedia/ViewEngineerMedia';
import NoteBlock from './../../NoteBlock';
import FilesDialog from './dialogs/FilesDialog';
import EngineerMediaDialog from './sections/EngineerMediaDialog';

import { colors, cqActivityLog } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons from 'Helpers/IconHelper';
import { pdfFromBase64 } from 'Helpers/PDFHelper';

import 'Assets/timeLine.css';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { clearPersistence, setPersistence } from 'Actions/StatePersistence/StatePersistence';

import {
    clearPageState,
    getInitialState,
    hasPageState,
    savePageState
} from 'Functions/StatePersistenceFunctions';
import IconHelper from 'Helpers/IconHelper';
import InputHelper from 'Helpers/InputHelper';

import ViewEmails from 'Components/Common/Email/ViewEmails/ViewEmails';
import TabBarSwitcher from 'Components/Common/Navigation/TabBarSwitcher';
import { downloadS3File } from 'Functions/MiscFunctions';
import RepairOrder from './sections/RepairOrder';
import StickyNotes from 'Components/Common/StickyNotes/StickyNotes';

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    btnRow: {
        textAlign: 'center', 
    },
    btnRowBtn: {   
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        }
    },
    statusHeading: {
        display: 'flex',
        alignItems: 'center'
    }
});

const initialState = () => ({
    formData: {
        notesText: ''
    },
    formErrors: [],
    currentTab: 0,
    cq: {},
    quoteId: null,
    redirect: false,
    emailDialogOpen: false,
    emailDialogOrderId: '',
    emailDialogCustomerId: '',
    emailDialogContactId: '',
    isLoading: true,
    snackbarOpen: false,
    succesMsg: '',
    dialogCloseOpen: false,
    snackbarCloseOpen: false,
    dialogOrderOpen: false,
    snackbarOrderOpen: false,
    dialogNotesOpen: false,
    snackbarNotesOpen: false,
    dialogQuotationNotificationOpen: false,
    snackbarQuotationNotificationOpen: false,
    confirmNoteDelete: 0,
    access: {
        'extend-quotation': false,
        'revive-quotation': false,
        'repeat-quotation': false,
        'sales-quote-create-sticky-note': false,
        'sales-quote-view-sticky-note': false,
    }
});

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

class ViewQuotation extends Component {
    constructor(props) {
        super(props);
        this.clearPageState     = clearPageState.bind(this);
        this.getInitialState    = getInitialState.bind(this);
        this.hasPageState       = hasPageState.bind(this);
        this.savePageState      = savePageState.bind(this);
        this.persistenceId      = `Quotation:View:${this.props.id ? this.props.id : this.props.match.params.id}`;
        this.state              = this.getInitialState(initialState());
        this.inputHelper        =  new InputHelper(this);
    }
    componentDidMount(){
        this.setState({
            quoteId: this.props.id ? this.props.id : this.props.match.params.id,
        }, () => this.getLatestQuoteId());
        this.inputHelper.handleSetAccess(_.keys(this.state.access))
    }
    

    getLatestQuoteId = () => {
        API.get('/sales/quotations/' + this.state.quoteId + '/mostRecentCQLinked')
        .then(result => {
            if(result.data.latestQuoteId !== parseInt(this.state.quoteId)){
                window.location.href = `/sales/quotation/view/${result.data.latestQuoteId}`;
            } else {
                this.getQuote();
            }
        });
    }
    getQuote = () => {
        this.setState({
            quoteId: this.props.id ? this.props.id : this.props.match.params.id
        }, () => {
            API.get('/sales/quotations/' + this.state.quoteId)
            .then(result => {
                if(result.data) {
                    // ACTIVITY
                    _.map(result.data.activity, (log) => {
                        let data = cqActivityLog(log.cq_log_type);
                        log.color = data['color'];
                        log.icon = data['icon'];
                        log.time = moment(log.cq_log_datetime_added).format("DD/MM/YYYY");
                    });
    
                    let quotationNotifications = this.getQuotationNotifications(result);
                    let stopRevival =  this.checkQuotationRevival(result);
                    let dialogQuotationNotificationOpen = (quotationNotifications.length > 0 && !result.data.repair) ? true : false;
                   
                    this.setState({
                        placeOrderFormData: {
                            notesText: result.data.quote_notes
                        },
                        cq: result.data,
                        isLoading: false,
                        dialogQuotationNotificationOpen,
                        quotationNotifications,
                        stopRevival
                    });
                }
                else {
                    this.props.history.push(`/sales/quotation/search`);
                }
            });
        });
    }
    checkQuotationRevival = result => {
        let quotationNotifications = [];
        if(result.data.quote_status === 'Expired'){
            let notificationIdx = 0;
            // Check price changes
            if(result.data.quote_details.length > 0){
                result.data.quote_details.forEach(el => {
                    if(el.qd_unit_price !== el.part.default_price.price_total){
                        quotationNotifications.push({
                            index: notificationIdx++,
                            id: el.qd_part_id,
                            checked: true,
                            disabled: false,
                            type: 'Price',
                            from: '£' + el.qd_unit_price,
                            to: '£' + el.part.default_price.price_total,
                            info: el.qd_part_number + ' - ' + el.qd_part_description 
                        });
                    }
                });
            }
            // If the discount value has changed on the customer and it is not the same as the quote discount value on the quote 
            // User will be prompted to change all values that equal the discount value on the quote to the default discount value on the customer
            if(result.data.quote_discount_value_id !== result.data.customers.discount_name.default_discount_value.cdv_id && result.data.quote_type === "With Discount"){
                quotationNotifications.push({
                    index: notificationIdx++,
                    id: result.data.customers.discount_name.default_discount_value.cdv_id,
                    checked: true,
                    disabled: false,
                    type: 'Discount',
                    from: result.data.discount_value.cdv_percentage + '%',
                    to: result.data.customers.discount_name.default_discount_value.cdv_percentage + '%',
                    info: 'The discount value has changed from ' + result.data.discount_value.cdv_percentage + '% (' + result.data.discount_value.discount_name.cdn_name + ') to ' + 
                            result.data.customers.discount_name.default_discount_value.cdv_percentage + '% (' + result.data.customers.discount_name.cdn_name + ') since this quotation was created.'
                });
            }
            // Check if vat value is different on the customer to the quote, prompts user to update vat
            if(result.data.quote_vat_value_id !== result.data.customers.vat_type.default_vat_value.vat_value_id){
                quotationNotifications.push({
                    index: notificationIdx++,
                    id: result.data.customers.vat_type.default_vat_value.vat_value_id,
                    checked: true,
                    disabled: true,
                    type: 'VAT',
                    from: result.data.vat_value.vat_value_percentage + '%',
                    to: result.data.customers.vat_type.default_vat_value.vat_value_percentage + '%',
                    info: 'The VAT value has changed from ' + result.data.vat_value.vat_value_percentage + '% (' + result.data.vat_value.vat_type.vat_type_name + ') to ' +
                            result.data.customers.vat_type.default_vat_value.vat_value_percentage + '% (' + result.data.customers.vat_type.vat_type_name + ') since this quotation was created.'
                });
            }
        }

        return quotationNotifications.length > 0 ? true : false;
    }
    getQuotationNotifications = result => {
        let quotationNotifications = [];
        if(result.data.quote_status === 'Open'){
            let notificationIdx = 0;
            // Check price changes
            if(result.data.quote_details.length > 0){
                result.data.quote_details.forEach(el => {
                    if(el.qd_unit_price !== el.part.default_price.price_total){
                        quotationNotifications.push({
                            index: notificationIdx++,
                            id: el.qd_part_id,
                            checked: true,
                            disabled: false,
                            type: 'Price',
                            from: '£' + el.qd_unit_price,
                            to: '£' + el.part.default_price.price_total,
                            info: el.qd_part_number + ' - ' + el.qd_part_description 
                        });
                    }
                });
            }
            // If the discount value has changed on the customer and it is not the same as the quote discount value on the quote 
            // User will be prompted to change all values that equal the discount value on the quote to the default discount value on the customer
            if(result.data.quote_discount_value_id !== result.data.customers.discount_name.default_discount_value.cdv_id && result.data.quote_type === "With Discount"){
                quotationNotifications.push({
                    index: notificationIdx++,
                    id: result.data.customers.discount_name.default_discount_value.cdv_id,
                    checked: true,
                    disabled: false,
                    type: 'Discount',
                    from: result.data.discount_value.cdv_percentage + '%',
                    to: result.data.customers.discount_name.default_discount_value.cdv_percentage + '%',
                    info: 'The discount value has changed from ' + result.data.discount_value.cdv_percentage + '% (' + result.data.discount_value.discount_name.cdn_name + ') to ' + 
                            result.data.customers.discount_name.default_discount_value.cdv_percentage + '% (' + result.data.customers.discount_name.cdn_name + ') since this quotation was created.'
                });
            }
            // Check if vat value is different on the customer to the quote, prompts user to update vat
            if(result.data.quote_vat_value_id !== result.data.customers.vat_type.default_vat_value.vat_value_id){
                quotationNotifications.push({
                    index: notificationIdx++,
                    id: result.data.customers.vat_type.default_vat_value.vat_value_id,
                    checked: true,
                    disabled: true,
                    type: 'VAT',
                    from: result.data.vat_value.vat_value_percentage + '%',
                    to: result.data.customers.vat_type.default_vat_value.vat_value_percentage + '%',
                    info: 'The VAT value has changed from ' + result.data.vat_value.vat_value_percentage + '% (' + result.data.vat_value.vat_type.vat_type_name + ') to ' +
                            result.data.customers.vat_type.default_vat_value.vat_value_percentage + '% (' + result.data.customers.vat_type.vat_type_name + ') since this quotation was created.'
                });
            }
        }

        return quotationNotifications;
    }
    downloadCQ = (quote_id) => {
        API.get('/sales/quotations/' + quote_id + '/pdf')
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
    handleTabChange = (event, value) => {
        this.setState({
            currentTab: value
        }, ()=>this.savePageState());
    };
    handleChange = (e) => { 
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }
    handleOrderNotesChange = (e) => { 
        this.setState({
            placeOrderFormData: {
                ...this.state.placeOrderFormData,
                [e.target.name]: e.target.value
            }
        });
    }
    // EMAIL
    handleEmailQuotation = () => {
        const dialog = <EmailDialog
                            handleEmailDialogClose={this.handleEmailClose}
                            customerId={this.state.cq.quote_cust_id}
                            customerContactId={this.state.cq.quote_cust_contact_id}
                            type='quote'
                            id={this.state.cq.quote_id}
                        />
        this.props.deployDialog(dialog, 'You Are About To Email - Quotation', 'success');
    }
    handleEmailClose = () => {
        this.props.closeDialog();
    }
    handleEmailOrder = () => {
        const dialog = <EmailDialog
                            handleEmailDialogClose={this.handleEmailClose}
                            customerId={this.state.emailDialogCustomerId}
                            customerContactId={this.state.emailDialogContactId}
                            type='acknowledgement'
                            id={this.state.emailDialogOrderId}
                            redirectPath='/sales/quotation/search'
                        />
        this.props.deployDialog(dialog, 'You Are About To Email - Order Acknowledgment', 'success');
    }

    // NOTES
    handleNotesOpen = () => {this.setState({dialogNotesOpen: true});};
    handleNotesClose = () => {this.setState({dialogNotesOpen: false});};
    handleNotesSuccess = () => {
        this.setState({ dialogNotesOpen: false });
        this.notesSubmit();
    }
    handleNotesSnackbarClose = () => {this.setState({ snackbarNotesOpen: false }); };
    notesSubmit = () => {
        API.post(`/sales/quotations/${this.state.quoteId}/notes`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogNotesOpen: true
                });
            }
            else {
                this.setState({
                    snackbarNotesOpen   : true,
                    formData            : initialState().formData
                }, () => {
                    this.softUpdate();
                });
            }
        });
    }
    handleDeleteNote = (id) => {
        this.setState({
            confirmNoteDelete: id
        });
    }
    handleCloseDeleteNote = () => {
        this.setState({
            confirmNoteDelete: 0
        });
    }
    deleteNote = () => {
        API.post(`/misc/notes/${this.state.confirmNoteDelete}/delete`, {type: "quotation-notes"})
            .then(result => {
                if (!result.data.error) this.softUpdate()
                this.setState({
                    confirmNoteDelete: 0
                });
            })
        this.handleCloseDeleteNote();
    }
    softUpdate = () => {
        Promise.all([
            API.get(`/sales/quotations/${this.state.quoteId}/notes`),
            API.get(`/sales/quotations/${this.state.quoteId}/activityLog`)
        ]).then( ([notes, acl]) => {
            if (!notes.data.error && !acl.data.error){
                _.map(acl.data.activityLog, (log) => {
                    let data    = cqActivityLog(log.cq_log_type);
                    log.color   = data['color'];
                    log.icon    = data['icon'];
                    log.time    = moment(log.cq_log_datetime_added).format("DD/MM/YYYY");
                });
                this.setState({
                    cq: {
                        ...this.state.cq,
                        notes       : notes.data,
                        activity    : acl.data.activityLog,
                    }
                })
            }
        } )
    }
    // NOTIFICATIONS
    handleCheckNotification = idx => {
        let quotationNotifications = [...this.state.quotationNotifications];
        quotationNotifications[idx] = {
            ...quotationNotifications[idx],
            checked: !this.state.quotationNotifications[idx].checked
        };
        this.setState({quotationNotifications});
    }
    handleDialogQuotationNotificationSuccess = () => {
        API.post(`/sales/quotations/${this.state.quoteId}/amendChanges`, this.state.quotationNotifications)
        .then(result => {
            let update = _.find(this.state.quotationNotifications, ['checked', true]);

            this.setState({
                dialogQuotationNotificationOpen: false, 
                snackbarQuotationNotificationOpen: update ? true : false, 
            }, () => {
                if(update){
                    this.getLatestQuoteId();
                }
            });
        });
    }
    handleDialogQuotationNotificationClose = () => this.setState({dialogQuotationNotificationOpen: false});
    handleQuotationNotificationSnackbarClose = () => this.setState({snackbarQuotationNotificationOpen: false});
    // CLOSE
    handleCloseOpen = () => {this.setState({dialogCloseOpen: true});};
    handleCloseClose = () => {this.setState({dialogCloseOpen: false});};
    handleCloseSuccess = () => {
        this.setState({ dialogCloseOpen: false });
        this.closeSubmit();
    }
    handleCloseSnackbarClose = () => {this.setState({ snackbarCloseOpen: false }); };
    closeSubmit = () => {
        API.post(`/sales/quotations/${this.state.quoteId}/close`, this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                    dialogCloseOpen: true
                });
            }
            else {
                this.setState({
                    ...initialState(),
                    snackbarCloseOpen: true
                });
                this.getQuote();
            }
        });
    }
    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false }); 
    };
    // FILES
    handleAddFiles = () => {
        const dialog = <FileDialog
                            orderId={this.state.emailDialogOrderId}
                            handleEmailOrder={this.handleEmailOrder}
                        />
        this.props.deployDialog(dialog, 'Add Files', '', 'sm');
    }

    handleAddEngineerMedia = () => {
        const dialog = <EngineerMediaDialog
                            quoteId={this.state.quoteId}
                            customerId={this.state.cq.quote_cust_id }
                            close={this.props.closeDialog}
                        />
        this.props.deployDialog(dialog, 'Add Engineer Media', '', 'sm');
    }

    extendQuote = () => {
        API.get('/sales/quotations/' + this.state.quoteId + '/extend')
        .then(result => {
            if(result.data) {
                this.getQuote();
            }
        }) 
    }

    reviveQuote = () => {
        API.get('/sales/quotations/' + this.state.quoteId + '/revive')
        .then(result => {
            if(result.data) {
                this.getQuote();
            }
        }) 
    }

    handleFilesOpen = () => {
        this.props.deployDialog(
            <FilesDialog
                quotationId={this.state.quoteId}
                onClose={()=>{
                    this.getQuote();
                    this.props.closeDialog();
                }}
            />,
            'Add Files',
            null,
            'md'
        );
    }

    deleteQuoteFile = id => () => {
        this.props.deployConfirmation(
            'Are you sure you want to delete this file?',
            'Delete File',
            () => {
                API.post(`/sales/quotations/${this.state.quoteId}/files/${id}/delete`)
                .then(this.getQuote)
            }
        )
       
    }

    render() {
        const { classes } = this.props;
        const { currentTab, cq, isLoading, formErrors, access, stopRevival } = this.state;
        
        if (this.state.redirect === true) {
            return <Redirect 
                        to={{
                            pathname:`/sales/quotation/view/${this.state.latestSrId}`,
                            state: {snackbarAmendOpen: true}
                        }}
                    />
        }
        
        return (            
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Quotation
                    </Typography>
                </Grid>
                    {isLoading ? (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    ) : (
                        <React.Fragment>
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={9}>
                                    <PaddedPaper>
                                        <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25}}>
                                            {cq.full_reference}
                                        </Typography>
                                        <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                            Quotation (v{parseInt(cq.quote_reference_amendment)})
                                            <Button variant="outlined"
                                                color="default"
                                                size="small"
                                                className={classes.btn}
                                                onClick={() => this.downloadCQ(cq.quote_id)}
                                            >
                                                <FALightIcon icon='download' />
                                                Download
                                            </Button> 
                                        </Typography>
                                        <br className='xs-only' /> 
                                        <div style={{marginTop: '25px'}}></div> 
                                        <TabBarSwitcher 
                                            tabs={[
                                                {
                                                    label:   'Quote',
                                                    content: 
                                                    <>
                                                        <Quotation cq={cq} />
                                                    </>,
                                                    noPad: true
                                                },
                                                {
                                                    label:   `Notes (${cq.notes.length})`,
                                                    content: 
                                                        <>
                                                            <Grid container spacing={3} >
                                                                {cq.notes && 
                                                                    cq.notes.map(note => (
                                                                        <Grid key={note.cq_note_id} item  xs={12}>
                                                                            <NoteBlock 
                                                                                prefix          = {'cq'}
                                                                                deployDialog    = {this.props.deployDialog} 
                                                                                note            = {note} 
                                                                                editCallback    = {this.softUpdate}
                                                                                deleteCallback  = {this.handleDeleteNote}
                                                                            />
                                                                        </Grid>
                                                                    ))
                                                                }
                                                                {!cq.notes.length > 0 && (
                                                                    <Grid item xs={12}>
                                                                        <Card>
                                                                            <CardHeader                                                       
                                                                                title='No notes'
                                                                            />
                                                                            <CardContent>
                                                                                <Typography component="p">
                                                                                    There are no notes to show for this quotation
                                                                                </Typography>
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </>,
                                                },
                                                cq.repair && {
                                                    label:   'Repair',
                                                    content: <RepairOrder cq={cq}/>
                                                },
                                                {
                                                    label:   'Activity Log',
                                                    content: 
                                                        <>
                                                            <>
                                                                <>
                                                                    <VerticalTimeline layout="1-column">
                                                                        {cq.activity && cq.activity.map((log, key) => (
                                                                            <VerticalTimelineElement key={key}
                                                                                className="vertical-timeline-element--work"
                                                                                date={moment(log.cq_log_datetime_added).format("DD/MM/YYYY") + ' - ' + log.cq_log_staff}
                                                                                iconStyle={{ backgroundColor: log.color, color: 'white' }}
                                                                                icon={<FASolidIcon icon={log.icon} style={{color:'white'}} />}
                                                                            >
                                                                                <h3 className="vertical-timeline-element-title">{log.cq_log_type}</h3>
                                                                                {log.cq_log_text && log.cq_log_text.split('\n').map((item, key) => {
                                                                                    return <span key={key}><TypographyAllowHtml body={ `${item} <br/>`} /></span>
                                                                                })}
                                                                            </VerticalTimelineElement>
                                                                        ))}
                                                                    </VerticalTimeline>
                                                                </>
                                                            </>
                                                        </>
                                                },
                                                {
                                                    label:   'Engineer Media',
                                                    content: 
                                                        <>
                                                            <>
                                                                <EngineerMedia quoteId={this.state.quoteId} customerId={cq.quote_cust_id} slim/>
                                                            </>
                                                        </>
                                                },
                                                {
                                                    label:   `Emails`,
                                                    content: 
                                                        <>
                                                            <ViewEmails
                                                                quotationId={this.state.quoteId}
                                                                smallMode
                                                                sendProps={
                                                                    {
                                                                        to: [cq.quote_delivery_cust_contact_id],
                                                                        customerId: cq.quote_cust_id ,
                                                                    }
                                                                }
                                                            />
                                                        </>
                                                }
                                            ]}
                                        />
                                        <Divider style={{marginBottom:20}} />
                                            <div className={`buttonRow ${classes.btnRow}`}>
                                                <Grid container justify='center' spacing={1}>
                                                    <Grid item xs={12} md={2}>
                                                        <BackButton style={{width:'100%'}} props={this.props} />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleNotesOpen}><FALightIcon icon='plus' buttonPrimary /> Add Notes</Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleFilesOpen}><FALightIcon icon='plus' buttonPrimary /> Add Files</Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleAddEngineerMedia}><FALightIcon icon='plus' buttonPrimary /> Add Engineer Media</Button>
                                                    </Grid>
                                                    {cq.quote_status === 'Open' &&
                                                        <React.Fragment>
                                                            <Grid item xs={12} md={2}>
                                                                <Button variant="contained" className={classes.btnRowBtn} color="primary" onClick={this.handleEmailQuotation} ><FALightIcon icon='envelope' buttonPrimary /> Email</Button>
                                                            </Grid>
                                                            <Grid item xs={12} md={2}>
                                                                <Link to={`/sales/quotation/amend/${cq.quote_id}`} className={classes.link}>
                                                                    <Button variant="contained" color="primary" className={classes.btnRowBtn}><FALightIcon icon='pen' buttonPrimary /> Amend Quote</Button>
                                                                </Link>
                                                            </Grid>
                                                            {access['repeat-quotation'] &&
                                                                <Grid item xs={12} md={2}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={()=>{
                                                                            this.props.history.push(
                                                                                `/sales/quotation/add/repeat/${cq.quote_id}`
                                                                            )
                                                                        }}
                                                                        className={classes.btnRowBtn}>
                                                                            <FALightIcon icon={IconHelper.repeat} buttonPrimary />
                                                                            Repeat Quote
                                                                        </Button>
                                                                </Grid>
                                                            }
                                                            <Grid item xs={12} md={2}>
                                                                {cq.customers.account_status.cas_allow_orders ? 
                                                                    (_.filter(cq.quote_details, i => i.part.part_locked).length === 0 ?
                                                                        <Link to={`/sales/order/add/${cq.quote_id}`} className={classes.link}>
                                                                            <Button variant="contained" className={classes.btnRowBtn} color="primary"><FALightIcon icon='check' buttonPrimary /> Place Order</Button>
                                                                        </Link> :
                                                                        <Button variant="contained" className={classes.btnRowBtn} color="primary" disabled><FALightIcon icon={icons.lock} buttonPrimary /> Place Order</Button>)
                                                                :
                                                                    <Tooltip 
                                                                        title={
                                                                            <Typography variant="body2">The customer account status does not allow orders</Typography>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            <Button variant="contained" className={classes.btnRowBtn} color="primary" disabled><FALightIcon icon='check' buttonPrimary /> Place Order</Button>
                                                                        </span>
                                                                    </Tooltip>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12} md={2}>
                                                                <Button variant="contained" className={classes.btnRowBtn} color="secondary" onClick={this.handleCloseOpen}><FALightIcon icon='ban' buttonPrimary /> Close Quote</Button>
                                                            </Grid>
                                                        </React.Fragment>
                                                    }                                                     
                                                </Grid>
                                            </div>
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Grid container item spacing={3}>
                                        <Grid item xs={12}>
                                            <PaddedPaper>
                                                {(cq.quote_status === 'Closed' || cq.quote_status === 'Expired') &&
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom style={{color:colors.red}} className={classes.statusHeading}>
                                                            <FALightIcon icon='ban' style={{color:colors.red}}/>{cq.quote_status}
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary">
                                                            {cq.quote_status === 'Closed' ? 
                                                                `This quote was closed on ${moment(cq.quote_closed_datetime,"YYYY-MM-DD").format("Do MMMM YYYY")} by ${cq.quote_closed_by_staff_name}`
                                                            :
                                                                `This quote expired on ${moment(cq.quote_closed_datetime,"YYYY-MM-DD").format("Do MMMM YYYY")}`}
                                                        </Typography>
                                                        {access['revive-quotation'] && (
                                                            (stopRevival || cq.quote_status === 'Closed') ?
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={()=>{
                                                                        this.props.history.push(
                                                                            `/sales/quotation/add/repeat/${cq.quote_id}`
                                                                        )
                                                                    }}
                                                                    className={classes.btnRowBtn}
                                                                >
                                                                    Repeat Quote
                                                                </Button> :
                                                                <Button
                                                                    style={{marginTop: 15}}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    fullWidth
                                                                    onClick={this.reviveQuote}
                                                                >Revive Quote</Button>
                                                            )
                                                        }
                                                    </React.Fragment>
                                                }
                                                {cq.quote_status === 'Open' && 
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom>
                                                            Expires in <strong>{cq.quote_date && moment(cq.quote_date,"YYYY-MM-DD").add(cq.quote_valid_for + 1, 'days').diff(moment(), 'days')}</strong> Days
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary">
                                                            This quote will expire on {cq.quote_date && moment(cq.quote_date,"YYYY-MM-DD").add(cq.quote_valid_for, 'days').format("Do MMMM YYYY")}
                                                        </Typography>
                                                        {access['extend-quotation'] &&
                                                            <Button
                                                                style={{marginTop: 15}}
                                                                variant="contained"
                                                                color="primary"
                                                                fullWidth
                                                                onClick={
                                                                    ()=>this.props.deployConfirmation(
                                                                        'Are you sure you want to extend this quote by 7 days?',
                                                                        'Extend Quote',
                                                                        this.extendQuote
                                                                    )
                                                                }
                                                            >Extend By 7 Days</Button>
                                                        }
                                                    </React.Fragment>
                                                }
                                                {cq.quote_status === 'Ordered' &&
                                                    <React.Fragment>
                                                        <Typography variant="h5" gutterBottom style={{color:'#009933'}}>
                                                            <FALightIcon icon='check' style={{color:'#009933'}}/>{cq.quote_status}
                                                        </Typography>
                                                        <Typography variant="body1" color="textSecondary">
                                                            This quote was ordered on {moment(cq.quote_ordered_datetime,"YYYY-MM-DD").format("Do MMMM YYYY")}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            </PaddedPaper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PaddedPaper>
                                                <Typography variant="h5">
                                                    Previous Quotes
                                                </Typography>
                                                {cq.revisions && cq.revisions.length > 0 ?
                                                    cq.revisions.map((rev, idx) => (
                                                        <React.Fragment key={idx}>
                                                            <Link to="" onClick={(e) => { e.preventDefault(); this.downloadCQ(rev.quote_id)}} className='blueLink'>
                                                                {rev.full_reference || '-'}
                                                            </Link>
                                                            <br></br>
                                                        </React.Fragment>
                                                    ))
                                                :
                                                    <Typography variant="body1" gutterBottom>
                                                        0 previous quotes
                                                    </Typography>
                                                }
                                            </PaddedPaper>
                                        </Grid>
                                        {!!cq.quote_repeat_id &&
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Typography variant="h5">
                                                        Quote Repeated From
                                                    </Typography>
                                                    <Typography variant="body1" gutterBottom  onClick={()=>{ window.location.href = `/sales/quotation/view/${cq?.original_quote?.quote_id}`; }} className='blueLink'>    
                                                        {cq.original_quote.quote_reference}-{cq.original_quote.quote_reference_amendment}
                                                    </Typography>
                                                </PaddedPaper>
                                            </Grid>
                                        }
                                        {cq?.repeated_quotes?.length > 0 &&
                                            <Grid item xs={12}>
                                                <PaddedPaper>
                                                    <Typography variant="h5">
                                                        Quote Repeated In
                                                    </Typography>
                                                    {cq.repeated_quotes.map((rev, idx) => (
                                                        <Typography variant="body1" gutterBottom  onClick={()=>{ window.location.href = `/sales/quotation/view/${rev.quote_id}`; }} className='blueLink'>    
                                                            {rev.quote_reference}-{rev.quote_reference_amendment}
                                                        </Typography>
                                                    ))}
                                                </PaddedPaper>
                                            </Grid>
                                        }
                                        {cq.files.length > 0 &&
                                            _.map(_.uniqBy(cq.files, 'qf_type'), i => 
                                                <Grid item xs={12}>
                                                    <PaddedPaper>
                                                        <Grid container spacing={1} style={{alignItems: 'center'}}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h5">{i.qf_type}</Typography>
                                                            </Grid>
                                                            {_.map(_.filter(cq.files, {qf_type: i.qf_type}), j => 
                                                                <>
                                                                    <Grid item xs={1}>
                                                                        <Tooltip title={'Delete'}>
                                                                            <IconButton size='small' onClick={this.deleteQuoteFile(i.qf_id)}>
                                                                                <FALightIcon icon='trash-alt' size="small" noMargin button /> 
                                                                            </IconButton> 
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item xs={11} style={{padding:'2px'}}>
                                                                        <MuiLink component="div" variant="body2" className='blueLink' style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={() => {downloadS3File(j.file_url)}}>
                                                                            {_.last(j.file_url.split('/')) || '-'}
                                                                        </MuiLink>
                                                                    </Grid>
                                                                </>
                                                            )}
                                                        </Grid>
                                                    </PaddedPaper>
                                                </Grid>
                                            )
                                        }
                                        <Grid item xs={12}>
                                            {(access['sales-quote-create-sticky-note'] || (access['sales-quote-view-sticky-note'] && cq.sticky_notes.length > 0) ) &&
                                                <PaddedPaper style={{backgroundColor: colors.stickyNotes}}>
                                                    <Typography variant="h5">
                                                        Sticky Notes
                                                    </Typography>
                                                    <StickyNotes edit={1} quoteId={cq.quote_id}/>
                                                </PaddedPaper>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarOpen}
                                onClose={this.handleSnackbarClose}
                                message={this.state.succesMsg}
                            />
                            {/* CLOSE QUOTE */}
                            <Dialog 
                                open={this.state.dialogCloseOpen} 
                                onClose={this.handleCloseClose} 
                                fullWidth={true} 
                                scroll="body"
                                disableBackdropClick
                                disableEscapeKeyDown
                            >
                                <DialogTitle>Close This Quotation?</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Are you sure you want to close this new quotation?
                                    </DialogContentText>
                                    <Textarea
                                        id="notesText"
                                        name="notesText"
                                        label="Notes *"
                                        value={this.state.formData.notesText}
                                        rows={5}
                                        error={formErrors && formErrors['notesText']}
                                        onChange={this.handleChange}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleCloseClose} variant="outlined" color="default">Cancel</Button>
                                    <Button onClick={this.handleCloseSuccess} color="secondary" autoFocus variant="contained">Close Quote</Button>
                                </DialogActions>
                            </Dialog>
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarCloseOpen}
                                onClose={this.handleCloseSnackbarClose}
                                message='You have successfully closed this quote'
                            />
                            {/* PLACE ORDER */}
                            <Dialog 
                                open={this.state.dialogOrderOpen} 
                                onClose={this.handleORderClose} 
                                scroll="body"
                                disableBackdropClick
                                disableEscapeKeyDown
                            >
                                <DialogTitle>Place Order For This Quotation?</DialogTitle>
                                <DialogContent>
                                    <DialogContentText className="mb-1">
                                        Are you sure you want to place an order for this quotation?
                                    </DialogContentText>
                                    <Typography variant="body2" className="textError">
                                        Order notes below will be pre-filled with the quotation notes. Amend as required.
                                    </Typography>
                                    <Textarea
                                        id="notesText"
                                        name="notesText"
                                        label="Order Notes"
                                        value={this.state.placeOrderFormData.notesText}
                                        rows={5}
                                        error={formErrors && formErrors['notesText']}
                                        onChange={this.handleOrderNotesChange}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleOrderClose} variant="outlined" color="default">Close</Button>
                                    <Button onClick={this.handleOrderSuccess} color="primary" autoFocus variant="contained">Place Order</Button>
                                </DialogActions>
                            </Dialog>
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarOrderOpen}
                                onClose={this.handleOrderSnackbarClose}
                                message='You have successfully ordered this quote'
                            />
                            {/* NOTES */}
                            <Dialog 
                                open={this.state.dialogNotesOpen} 
                                onClose={this.handleNotesClose} 
                                fullWidth={true} 
                                maxWidth="sm" 
                                scroll="body"
                                disableBackdropClick
                                disableEscapeKeyDown
                            >
                                <DialogTitle id="form-dialog-title">Add Notes</DialogTitle>
                                <DialogContent>
                                    <Textarea
                                        id="notesText"
                                        name="notesText"
                                        label="Notes *"
                                        value={this.state.formData.notesText}
                                        rows={5}
                                        error={formErrors && formErrors['notesText']}
                                        onChange={this.handleChange}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleNotesClose} variant="outlined" color="default">Cancel</Button>
                                    <Button onClick={this.handleNotesSuccess} autoFocus variant="contained" color="primary">Save</Button>
                                </DialogActions>
                            </Dialog>
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarNotesOpen}
                                onClose={this.handleNotesSnackbarClose}
                                message='You have successfully added new notes'
                            />
                            {/* NOTIFICATION */}
                            <Dialog 
                                open={this.state.dialogQuotationNotificationOpen} 
                                fullWidth={true} 
                                maxWidth="lg" 
                                scroll="body"
                                disableBackdropClick
                                disableEscapeKeyDown
                            >
                                <DialogTitle id="form-dialog-title">Quotation Notifications</DialogTitle>
                                <DialogContent>
                                    <DataTable  
                                        config={{
                                            key: 'index',
                                            alternatingRowColours: true,
                                        }}
                                        columns={[
                                            {
                                                field: rowData => (<Checkbox color="primary" checked={rowData.checked} disabled={rowData.disabled} value={1} onClick={() => this.handleCheckNotification(rowData.index)} />),
                                            },
                                            {
                                                heading: 'Type',
                                                field: rowData => rowData.type
                                            },
                                            {
                                                heading: 'From',
                                                field: rowData => rowData.from,
                                            },
                                            {
                                                heading: 'To',
                                                field: rowData => rowData.to,
                                            },
                                            {
                                                heading: 'Info',
                                                field: rowData => rowData.info
                                            }
                                        ]}
                                        rows={this.state.quotationNotifications}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Grid container style={{margin: '0 15px'}}>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" style={{float:'left'}}>
                                                <FASolidIcon icon='info-circle' size='small' style={{color: 'rgba(0, 0, 0, 0.38)'}}/>
                                                Any checked changes will be applied to this quote and will create a new amendment
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div style={{float: 'right'}}>
                                                <Button onClick={this.handleDialogQuotationNotificationClose} variant="contained" color="secondary" disabled={_.find(this.state.quotationNotifications, ['checked', true]) ? true : false} style={{margin: '0 5px'}}>Ignore</Button>
                                                <Button onClick={this.handleDialogQuotationNotificationSuccess} variant="contained" color="primary" disabled={_.find(this.state.quotationNotifications, ['checked', true]) ? false : true}>Apply</Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </Dialog>
                            <ConfirmationDialog 
                                close   = {this.handleCloseDeleteNote} 
                                open    = {this.state.confirmNoteDelete}
                                title   = "Delete Note?"
                                message = "Are you sure you want to delete this note?"
                                success = {this.deleteNote}
                            />
                            <SnackBar
                                variant="success"
                                anchorOriginVertical='bottom'
                                anchorOriginHorizontal='right'
                                open={this.state.snackbarQuotationNotificationOpen}
                                onClose={this.handleQuotationNotificationSnackbarClose}
                                message='You have successfully updated the quotation'
                            />
                        </React.Fragment>
                    )}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    clearPersistence:   (key)                           => dispatch(clearPersistence(key)),
    setPersistence:     (key, state)                    => dispatch(setPersistence(key, state)),
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                              => dispatch(closeDialog()),
    deployConfirmation: (content, title, success)       => dispatch(deployConfirmation(content, title, success)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewQuotation));