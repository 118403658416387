import React, {Component} from 'react';
import _                  from 'lodash';
import { connect }        from 'react-redux';

import AllIcon     from 'Components/Common/Icons/AllIcon';
import DataTable   from 'Components/Common/DataTables/CiDataTable';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

import icons from 'Helpers/IconHelper';
import { colors } from 'Helpers/ColourHelper';

import {Grid, Typography} from '@material-ui/core/';
import { Hidden } from '@material-ui/core';

function InfoBox({heading, value, icon, width='calc(100% / 6)', color}) {
    return (
        <Grid item style={{width}}>
            <PaddedPaper style={{textAlign:'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <AllIcon icon={icon} size='xlarge' />
                <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>{heading}</Typography>
                <Typography variant="h4" style={{textAlign:'center', marginTop: 'auto', color}}>{value}</Typography>
            </PaddedPaper>
        </Grid>
    )
}
class Picks extends Component {
    constructor(props) {
        super(props);
    }
    
    render(){

        const { worksOrder, shortage } = this.props;

        let allParts =  
            [   ..._.map(worksOrder.details, i => {
                    let required         = 0;
                    let additional       = 0;
                    let returned         = 0;
                    let writeoff         = 0;
                    let writeoffReturned = 0;
                    let perBuild         = 0;
                    let picked           = 0;
                    let short            = 0;
                    let notRequired      = 0;
                    let logs             = _.filter(worksOrder.part_logs, {wopl_part_id: i.part.part_id});
                    let pickStatus       = null;
                    let pickComplete     = false;
                    let shortStatus      = null;    

                    required = parseFloat(i.wod_qty_required);
                    perBuild = parseFloat( parseFloat(i.wod_qty_required) / parseFloat(worksOrder.wo_qty) );
                    
                    _.each(_.filter(worksOrder.additionals, {wobas_part_id: i.part.part_id}), i => {
                        additional += parseFloat(i.wobas_qty);
                    });

                    _.each(_.filter(worksOrder.returns, {wobsr_part_id: i.part.part_id}), i => {
                        returned += parseFloat(i.wobsr_qty);
                    });

                    _.each(_.filter(worksOrder.write_offs, {wobwo_part_id: i.part.part_id}), i => {
                        writeoff += parseFloat(i.wobwo_qty);
                        writeoffReturned += parseFloat(i.wobwo_picked);
                    });

                    if (worksOrder.pick){
                        const pickDetail = _.find(worksOrder.pick?.details, {wopd_part_id: i.part.part_id});
                        picked      = pickDetail.wopd_status === 'Not Required' ? 0 : parseFloat(pickDetail.wopd_picked);
                        notRequired = (pickDetail.wopd_status === 'Not Required')
                        pickStatus  = pickDetail.wopd_status;
                        pickComplete = (pickDetail.wopd_status === 'Not Required') || (pickDetail.wopd_status === 'Complete');
                        if (worksOrder.shortage){
                            const shortDetail = _.find(worksOrder.shortage.details, {wossd_part_id: i.part.part_id});
                            if (shortDetail){
                                short = parseFloat(shortDetail.wossd_qty_picked) - picked;
                                notRequired = (shortDetail.wossd_status === 'Not Required');
                                pickStatus  = shortDetail.wossd_status;
                                shortStatus = shortDetail.wossd_status;
                                pickComplete = (shortDetail.wossd_status === 'Not Required') || (shortDetail.wossd_status === 'Complete');
                                if (pickStatus === 'Pending') {
                                    pickStatus = parseFloat(shortDetail.wossd_qty_picked) > 0 ? 'Partial' : 'Pending';
                                }
                            }
                        }
                    }

                    return { 
                        id               : i.part.part_id,
                        img              : i.part?.default_image?.file_path,
                        number           : i.part.part_number, 
                        desc             : i.part.part_description,
                        picked           : picked,
                        short            : short,
                        perBuild         : perBuild, 
                        required         : required, 
                        additional       : additional, 
                        returned         : 0 - returned, 
                        writeoff         : writeoff,
                        total            : picked + additional - returned + writeoffReturned + short,
                        diff             : additional - returned,
                        logs             : _.reverse(logs),
                        nrAdd            : notRequired && parseFloat(additional) > 0,  
                        pickStatus,
                        notRequired,
                        pickComplete,
                        shortStatus
                    }
                }),
                ..._.map(_.uniqBy(_.filter(worksOrder.additionals, i => !_.find(worksOrder.details, {wod_part_id:i.wobas_part_id})),'wobas_part_id'), i => {

                    let required         = 0;
                    let additional       = 0;
                    let returned         = 0;
                    let writeoff         = 0;
                    let writeoffReturned = 0;
                    let perBuild         = 0;
                    let picked           = 0;
                    let short            = 0;
                    let logs             = _.filter(worksOrder.part_logs, {wopl_part_id: i.wobas_part_id});

                    _.each(_.filter(worksOrder.additionals, {wobas_part_id: i.part.part_id}), s => {
                        additional += parseFloat(s.wobas_qty);
                    })

                    _.each(_.filter(worksOrder.returns, {wobsr_part_id: i.part.part_id}), j => {
                        returned += parseFloat(j.wobsr_qty);
                    });

                    _.each(_.filter(worksOrder.write_offs, {wobwo_part_id: i.part.part_id}), j => {
                        writeoff += parseFloat(j.wobwo_qty);
                        writeoffReturned += parseFloat(j.wobwo_picked);
                    });


                    return { 
                        id               : i.part.part_id,
                        img              : i.part?.default_image?.file_path,
                        number           : i.part.part_number, 
                        desc             : i.part.part_description,
                        picked           : picked,
                        short            : short,
                        perBuild         : perBuild, 
                        required         : required, 
                        additional       : additional, 
                        returned         : 0 - returned, 
                        writeoff         : writeoff,
                        total            : picked + additional - returned + writeoffReturned + short,
                        diff             : additional - returned,
                        notRequired      : false,
                        logs             : _.reverse(logs),
                        pickComplete     : false,
                        nrAdd            : false,
                    }
                }),
            ];

        

        return (
            <PaddedPaper>
                <Grid container spacing={3} style={{alignContent: 'stretch'}}>
                    <Hidden mdDown>
                        <InfoBox heading='Required Parts'     icon={icons.worksOrder} value={ parseFloat(_.filter(allParts, 'required').length) } />
                        <InfoBox heading='Picked Parts'       icon={icons.picking}    value={ parseFloat(_.filter(allParts, i => parseFloat(i.picked) > 0 || parseFloat(i.short) > 0).length)}   />
                        <InfoBox heading='Not Required Parts' icon={icons.false}      value={ parseFloat( _.filter(allParts, 'notRequired').length) }    />
                        <InfoBox heading='Added Parts'        icon={icons.plus}       value={ _.uniqBy(worksOrder.additionals, 'wobas_part_id').length } color={parseFloat( _.sumBy(allParts, 'additional')) > 0 && colors.green}/>
                        <InfoBox heading='Returned Parts'     icon={icons.plus}       value={ _.uniqBy(worksOrder.returns, 'wobsr_part_id').length } color={parseFloat( _.sumBy(allParts, 'returned')) < 0 && colors.orange}/>
                        <InfoBox heading='Total Parts'        icon={icons.stock}      value={ parseFloat( parseFloat( allParts.length) ) - parseFloat(_.filter(allParts, i => i.notRequired && !i.nrAdd).length) } />

                        <InfoBox heading='Required Qty'       icon={icons.worksOrder} value={ parseFloat(_.sumBy(allParts, 'required')).toFixed(3) } />
                        <InfoBox heading='Picked Qty'         icon={icons.picking}    value={ parseFloat(_.sumBy(allParts,  r=> parseFloat(r.picked) + parseFloat(r.short))).toFixed(3) }   />
                        <InfoBox heading='Not Required Qty'   icon={icons.false}      value={ parseFloat( _.sumBy(_.filter(allParts, 'notRequired'), 'required')).toFixed(3) }   />
                        <InfoBox heading='Additional Qty'     icon={icons.plus}       value={ `+${parseFloat( _.sumBy(allParts, 'additional')).toFixed(3)}` } color={parseFloat( _.sumBy(allParts, 'additional')) > 0 && colors.green}/>
                        <InfoBox heading='Returned Qty'       icon={icons.minus}      value={ parseFloat( _.sumBy(allParts, 'returned')).toFixed(3) } color={parseFloat( _.sumBy(allParts, 'returned')) < 0 && colors.orange}/>
                        <InfoBox heading='Total Qty'          icon={icons.stock}      value={ parseFloat( parseFloat(_.sumBy(allParts, r => parseFloat(r.picked) + parseFloat(r.short))) + parseFloat(_.sumBy(allParts, 'diff'))).toFixed(3) }    />
                    </Hidden>
                    <Hidden mdUp>
                        <InfoBox width='50%' heading='Required Parts'     icon={icons.worksOrder} value={ parseFloat(_.filter(allParts, 'required').length)} />
                        <InfoBox width='50%' heading='Picked Parts'       icon={icons.picking}    value={ parseFloat(_.filter(allParts, i => parseFloat(i.picked) > 0 || parseFloat(i.short) > 0).length)}   />
                        <InfoBox width='50%' heading='Not Required Parts' icon={icons.false}      value={ parseFloat( _.filter(allParts, 'notRequired').length) }   />
                        <InfoBox width='50%' heading='Added Parts'        icon={icons.plus}       value={ _.uniqBy(worksOrder.additionals, 'wobas_part_id').length } color={parseFloat( _.sumBy(allParts, 'additional')) > 0 && colors.green}/>
                        <InfoBox width='50%' heading='Returned Parts'     icon={icons.minus}      value={ _.uniqBy(worksOrder.returns, 'wobsr_part_id').length } color={parseFloat( _.sumBy(allParts, 'returned')) < 0 && colors.orange}/>
                        <InfoBox width='50%' heading='Total Parts'        icon={icons.stock}      value={ parseFloat( parseFloat( allParts.length) ) - parseFloat(_.filter(allParts, i => i.notRequired && !i.nrAdd).length) } />

                        <InfoBox width='50%' heading='Required Qty'       icon={icons.worksOrder} value={ parseFloat(_.sumBy(allParts, 'required')).toFixed(3) } />
                        <InfoBox width='50%' heading='Picked Qty'         icon={icons.picking}    value={ parseFloat(_.sumBy(allParts, 'picked')).toFixed(3) }   />
                        <InfoBox width='50%' heading='Not Required Qty'   icon={icons.false}      value={ parseFloat( _.sumBy(_.filter(allParts, 'notRequired'), 'required')).toFixed(3) }   />
                        <InfoBox width='50%' heading='Additional Qty'     icon={icons.plus}       value={ `+${parseFloat( _.sumBy(allParts, 'additional')).toFixed(3)}` }     color={parseFloat( _.sumBy(allParts, 'additional')) > 0 && colors.green}/>
                        <InfoBox width='50%' heading='Returned Qty'       icon={icons.minus}      value={ parseFloat( _.sumBy(allParts, 'returned')).toFixed(3) }     color={parseFloat( _.sumBy(allParts, 'returned')) < 0 && colors.orange}/>
                        <InfoBox width='50%' heading='Total Qty'          icon={icons.stock}      value={ parseFloat( parseFloat(_.sumBy(allParts, r => parseFloat(r.picked) + parseFloat(r.short))) + parseFloat(_.sumBy(allParts, 'diff'))).toFixed(3) }    />
                    </Hidden>
                    <Grid item xs={12}>
                        <PaddedPaper>
                            <Grid container spacing={3}>
                                <Grid item xs={12}><Typography variant='h5'>Picks</Typography></Grid>
                                <Grid item xs={12}>
                                    <DataTable
                                        config={{
                                            sticky: true,
                                            key: 'id',
                                            dropRow: {
                                                droppable:     i => i.logs.length > 0,
                                                style:         {fontWeight: 'bold'},
                                                columns:       [
                                                    {
                                                        colSpan:     2,
                                                        field:       'wopl_datetime',
                                                        fieldFormat: 'clenydate',
                                                        alignment:   'right'
                                                    },
                                                    {
                                                        colSpan:   3,
                                                        field:     'wopl_type',
                                                        alignment: 'left'
                                                    },
                                                    {
                                                        colSpan:   2,
                                                        field:     'wopl_note',
                                                        alignment: 'left'
                                                    },
                                                    {
                                                        colSpan: 4,
                                                        field:   'wopl_staff',
                                                        alignment: 'right'
                                                    },
                                                ],
                                                data:          'logs' 
                                            }
                                        }}
                                        columns={[
                                            {
                                                heading:       '',
                                                field:         'img',
                                                fieldFormat:   'image',
                                                alignment:     'center',
                                                sizeToContent: true,
                                            },
                                            {
                                                heading:       'Part/Description',
                                                field:         r => <>{r.number}<br/>{r.desc}</>,
                                                truncate:      150
                                            },
                                            {
                                                heading:       'Per Build',
                                                field:         'perBuild',
                                                sizeToContent: true,
                                                fieldFormat:   'decimal:3',
                                                alignment:     'center',
                                            },
                                            {
                                                heading:       'Required',
                                                field:         'required',
                                                sizeToContent: true,
                                                fieldFormat:   'decimal:3',
                                                alignment:     'center',
                                            },
                                            {
                                                heading:       'Picked',
                                                field:         i => i.notRequired ? ( i.shortStatus ? '0.00' : 'Not Required' ) : parseFloat(i.picked).toFixed(3),
                                                sizeToContent: true,
                                                alignment:     'center',
                                                style:         i => ({fontWeight: i.notRequired && 'bold'})
                                            },
                                            {
                                                heading:       'Short Picked',
                                                field:         i => i.notRequired ? ( i.shortStatus ? 'Not Required' : '-' ) : parseFloat(i.short).toFixed(3),
                                                sizeToContent: true,
                                                alignment:     'center',
                                            },
                                            {
                                                heading:       'Additional',
                                                field:         'additional',
                                                sizeToContent: true,
                                                fieldFormat:   'realNumber:colorNumbers:decimal:3',
                                                alignment:     'center',
                                                style:         i => ({color: parseFloat(i.additional) > 0 && colors.green})
                                            },
                                            {
                                                heading:       'Returned',
                                                field:         'returned',
                                                sizeToContent: true,
                                                fieldFormat:   'decimal:3',
                                                alignment:     'center',
                                                style:         i => ({color: parseFloat(i.returned) < 0 && colors.orange})
                                            },
                                            {
                                                heading:       'Written Off',
                                                field:         'writeoff',
                                                sizeToContent: true,
                                                fieldFormat:   'decimal:3',
                                                style:         i => ({color: parseFloat(i.writeoff) > 0 && colors.red}),
                                                alignment:     'center'
                                            },
                                            {
                                                heading:       'Total',
                                                field:         'total',
                                                sizeToContent: true,
                                                fieldFormat:   'decimal:3',
                                                alignment:     'center',
                                            },
                                            {
                                                heading:        '',
                                                field:          rowData => 
                                                    <>
                                                        {!!rowData.pickStatus && 
                                                            <AllIcon noMargin icon={icons.picking} color={
                                                                (rowData.pickStatus === 'Complete' && colors.green) || (rowData.pickStatus === 'Not Required' ?
                                                                    colors.disabled : ( rowData.pickStatus === 'Partial' ? colors.orange : colors.red ))
                                                        } tooltip={rowData.pickStatus}/>}
                                                        {!!rowData.additional && !rowData.pickStatus &&
                                                            <AllIcon noMargin icon={icons.plus} color={colors.green} tooltip={'Additional'}/>
                                                        }
                                                    </>
                                                
                                                ,
                                                alignment:      'center',
                                                sizeToContent:  true,
                                            },
                                        ]}
                                        rows={_.orderBy(allParts, 'number')}
                                    />
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                </Grid>
            </PaddedPaper>
        )
    }
}


function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}

export default connect(mapStateToProps, null)(Picks)