import React from 'react';
import { DialogContent, DialogActions, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid, Typography, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import Textarea from 'Components/Common/Inputs/Textarea';
import MediaTable from 'Components/Common/DataTables/MediaTable';
import DragFileInputMulti from 'Components/Common/Inputs/DragFileInputMulti';
import IconHelper from 'Helpers/IconHelper';
import { connect } from 'react-redux';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import API from 'API';

const initialState = ({note}) => ({
    formData: {
        id: note.ron_id,
        note: note.ron_note,
        existingFiles: _.map(note.files, i => parseInt(i.ronf_id)),
        files: []
    }
})

class EditNoteDialog extends React.Component {
    constructor(props){
        super(props);
        this.state = initialState(props);
    }

    submit = () => {
        let newFormData = new FormData();
        newFormData.append('id', this.state.formData.id);
        newFormData.append('note', this.state.formData.note);
        _.each(this.state.formData.files, i => newFormData.append('files[]', i));
        newFormData.append('existingFiles', JSON.stringify(this.state.formData.existingFiles));

        API.post(`/customerReturns/repairOrders/${this.props.repairOrder.ro_group_id}/${this.props.repairOrder.ro_id}/editNote`, newFormData).then(()=>{
            this.props.onClose();
        })
    }

    render () {

        const { formData } = this.state;

        return (
            <>
                 <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Textarea
                                value={formData.note}
                                onChange={e => this.setState({formData: {...formData, note: e.target.value}})}
                                label='Note'
                                fullWidth
                                rows={4}
                            />
                        </Grid>
                        {formData.existingFiles.length > 0 && 
                            <Grid item xs={12}>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant='body2'>{formData.existingFiles.length} Existing Files</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <MediaTable
                                            config={{ 
                                                itemPerRow: 3,
                                                mobileItemPerRow: 3,
                                            }}
                                            media='file_path'
                                            items={_.map(formData.existingFiles, i => _.find(this.props.note.files, {ronf_id: i}))}
                                            fields={[
                                                {
                                                    actions: i => ([
                                                        {
                                                            name:    'Delete',
                                                            icon:    IconHelper.delete,
                                                            onClick: (e) => 
                                                                this.props.deployConfirmation("Are you sure you want to delete this file?", 'Delete File', ()=>{
                                                                    this.setState({formData: {...formData, existingFiles: formData.existingFiles.filter(id => id !== i.ronf_id)}})
                                                                })
                                                        }
                                                    ])
                                                }
                                            ]}
                                        />
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <DragFileInputMulti
                                id="files"
                                name="files[]"
                                file={formData.files}
                                type="file"
                                onChange={(drop, name, event) => this.setState({formData: {...formData, files: Array.prototype.slice.call(formData.files).concat(Array.prototype.slice.call(drop === true ? event.dataTransfer.files : event.target.files))}})}
                                cancelOnClick={idx=>this.setState({formData: {...formData, files: formData.files.filter((file, index) => index !== idx)}})}
                                emptyText='No files uploaded'
                                noMargin
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='outlined'
                        onClick={this.props.onClose}
                    >Close</Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={this.submit}
                    >Submit</Button>
                </DialogActions>
            </>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deployConfirmation: (message, title, success, variant = null) => { dispatch(deployConfirmation(message, title, success,false, variant)) },
    }
}
export default connect(null, mapDispatchToProps)(EditNoteDialog);