import React from 'react';
import API   from 'API';
import _     from 'lodash';

import Button     from '@material-ui/core/Button';
import Grid       from '@material-ui/core/Grid';
import TextField  from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { formatValidationErrors } from 'Helpers/ErrorHelper';

import PaddedPaper        from 'Components/Common/Paper/PaddedPaper';
import DataTable          from 'Components/Common/DataTables/CiDataTable';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import SnackBar           from 'Components/Common/SnackBars/SnackBar';
import LoadingCircle      from 'Components/Common/LoadingCircle/LoadingCircle';

const initialState = {
    formData: {
        reason: ''
    },
    defaultList: {},
    formErrors: {},
    snackbarOpen: false,
    confirmationOpen: false,
    access: {
        addReason: false
    },
    isLoading: true
}

class StockMovementReasons extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount(){
        this.checkAccess();
        this.getReasons(); 
    }

    getReasons = () => {
        API.get('/stock/movement/reasons/all')
        .then(result => {
            if(result.data) {
                this.setState({
                    defaultList: result.data,
                    isLoading: false
                });
            }
        });
    };

    checkAccess = () => {
        API.get('/staff/my/access/check/stock-movement-reasons:add')
        .then((res) =>  {
            res.data &&
                this.setState({
                    access: {
                        ...this.state.access,
                        addReason: res.data.has_access
                    }
                });
        });
    }

    submit = () => {
        API.post('/stock/movement/reasons/add', this.state.formData)
        .then((result) => {
            if(result.data.errors && result.data.errors.length > 0){           
                this.setState({
                    formErrors: formatValidationErrors(result.data.errors),
                });
            }
            else {
                this.setState({
                    ...initialState,
                    snackbarOpen: true
                });
                this.checkAccess();
                this.getReasons();
            }
        });
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    };

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false
        });
    };

    handleConfirmationOpen = () => {
        this.setState({
            confirmationOpen: true
        });
    };

    handleConfirmationClose = () => {
        this.setState({
            confirmationOpen: false
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({
            confirmationOpen: false
        });
        this.submit();
    };

    handleSelectChange = name => res => {
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: res.value
            }
        });
    };

    render() {
        const { defaultList, access, formData, formErrors, isLoading, snackbarOpen, confirmationOpen } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Stock Movement Reasons
                    </Typography>
                </Grid>                
                {(isLoading && (
                        <Grid container item spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <PaddedPaper>
                                    <LoadingCircle />
                                </PaddedPaper>
                            </Grid>
                        </Grid>                    
                )) || (
                    <React.Fragment>
                        {access.addReason && (
                            <Grid container item spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <PaddedPaper>
                                        <form onChange={this.handleChange} noValidate autoComplete="off">
                                            <TextField
                                                id="reason"
                                                name="reason"
                                                label="Reason *"
                                                value={formData.reason}
                                                error={formErrors && formErrors['reason'] && true}
                                                helperText={formErrors && formErrors['reason']}
                                                margin="normal"
                                                fullWidth
                                            />
                                            <div className='buttonRow'>
                                                <Button 
                                                    onClick={this.handleConfirmationOpen}
                                                    variant="contained" 
                                                    color="primary"
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                            <ConfirmationDialog 
                                                open={confirmationOpen} 
                                                success={this.handleConfirmationSuccess} 
                                                close={this.handleConfirmationClose} 
                                                title="Add A Stock Movement Reason?" 
                                                message="Are you sure you want to add a new stock movement reason?"
                                            />
                                            <SnackBar
                                                variant="success"
                                                anchorOriginVertical='bottom'
                                                anchorOriginHorizontal='right'
                                                open={snackbarOpen}
                                                onClose={this.handleSnackbarClose}
                                                message="You have successfully added a new stock movement reason"
                                            />
                                        </form>
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <DataTable  
                                    config={{
                                        key: 'smr_id',
                                        alternatingRowColours: true
                                    }}
                                    columns={[
                                        {
                                            heading: 'Existing Reasons',
                                            field: rowData => rowData.smr_reason
                                        }
                                    ]}
                                    rows={_.orderBy(defaultList, ['smr_reason'],['asc'])}
                                />
                            </PaddedPaper>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

export default StockMovementReasons;